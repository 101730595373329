import cn from 'classnames';
import React, { useMemo } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import {
  FifthStep,
  FirstStep,
  FourthStep,
  SecondStep,
  SixthStep,
  ThirdStep,
} from './components';

import styles from './Onboarding.module.css';

interface StepProps {
  step: number;
  nextStep: () => void;
  prevStep: () => void;
  closeOnboardingModal: () => void;
  openMoneyTransferModal: () => void;
  openSecuritiesTransferModal: () => void;
  openSettingsModal: () => void;
}

export const ONBOARDING_STEPS = [
  <FirstStep key={1} />,
  <SecondStep key={2} />,
  <ThirdStep key={3} />,
  <FourthStep key={4} />,
  <FifthStep key={5} />,
  <SixthStep key={6} />,
];

export const OnboardingPlayer = ({
  step,
  nextStep,
  prevStep,
  closeOnboardingModal,
  openMoneyTransferModal,
  openSecuritiesTransferModal,
  openSettingsModal,
}: StepProps) => {
  const dotsNumber = new Array(ONBOARDING_STEPS.length).fill(0);

  const carouselStepScreens = useMemo(
    () =>
      ONBOARDING_STEPS.map((Screen, index) => {
        const props = {
          key: index,
          style: {
            transform: `translate(${(index + 1 - step) * 100}%, 0)`,
          },
        };

        if (index + 1 === 5) {
          Object.assign(props, { openSettingsModal });
        }

        if (index + 1 === 6) {
          Object.assign(props, {
            openMoneyTransferModal,
            openSecuritiesTransferModal,
          });
        }

        return React.cloneElement(Screen, props);
      }),
    [
      openMoneyTransferModal,
      openSecuritiesTransferModal,
      openSettingsModal,
      step,
    ]
  );

  return (
    <>
      <header className={styles.playerHeader}>
        <Typography.Text view="secondary-large" weight="bold">
          Начало работы
        </Typography.Text>
        <IconButton
          size="xs"
          view="secondary"
          icon={CrossMIcon}
          onClick={closeOnboardingModal}
        />
      </header>
      <section className={styles.playerSection}>{carouselStepScreens}</section>
      <footer className={styles.playerFooter}>
        <div className={styles.dotsContainer}>
          {dotsNumber.map((dot, index) => (
            <div
              key={index}
              className={cn(
                styles.stepDot,
                index + 1 === step && styles.stepDotActive
              )}
            />
          ))}
        </div>
        <div className={styles.buttons}>
          {step !== 1 && (
            <ButtonDesktop view="ghost" size="xxs" onClick={prevStep}>
              Назад
            </ButtonDesktop>
          )}
          <ButtonDesktop view="accent" size="xxs" onClick={nextStep}>
            {step === ONBOARDING_STEPS.length ? 'Завершить' : 'Далее'}
          </ButtonDesktop>
        </div>
      </footer>
    </>
  );
};
