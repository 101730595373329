import { useCallback, useEffect, useState } from 'react';

import { captchaValidate, getNewCaptcha } from '@terminal/core/lib/rest/lkAuth';
import { shallow, useStore } from '@terminal/core/store';
import { AppState } from '@terminal/core/types/app';

export const useLoginCaptchaForm = () => {
  const [credentials, setAppState, logout] = useStore(
    (state) => [state.credentials, state.setAppState, state.logout],
    shallow
  );

  const [captchaCode, setCaptchaCode] = useState('');
  const [captchaImg, setCaptchaImg] = useState('');
  const [captchaPending, setCaptchaPending] = useState(false);
  const [captchaValidating, setCaptchaValidating] = useState(false);
  const [captchaError, setCaptchaError] = useState('');

  const requestCaptcha = useCallback(async (username: string) => {
    setCaptchaPending(true);

    try {
      const res = await getNewCaptcha(username);

      if ('captchaImage' in res) {
        setCaptchaImg(res.captchaImage);
      } else {
        setCaptchaError(res.message || 'Произошла ошибка при загрузке капчи');
      }
    } catch (err) {
      setCaptchaError('Произошла ошибка при загрузке капчи');
    }

    setCaptchaPending(false);
  }, []);

  useEffect(() => {
    if (credentials?.login) {
      requestCaptcha(credentials?.login);
    }
  }, [credentials, requestCaptcha]);

  const changeCaptcha = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaptchaCode(e.target.value);
    setCaptchaError('');
  };

  const requestNewCaptcha = (e: any) => {
    e.preventDefault();

    if (credentials?.login) {
      requestCaptcha(credentials?.login);
    }
  };

  const onCaptchaValidate = (e: any) => {
    e.preventDefault();

    if (captchaCode && credentials?.login) {
      setCaptchaValidating(true);
      captchaValidate(credentials?.login, captchaCode)
        .then((res) => {
          if ('captchaImage' in res) {
            setCaptchaImg(res.captchaImage);
          } else if (res.code === 0) {
            // Успех, возвращаемся к авторизации
            setAppState(AppState.AUTHORIZING_LK);
          } else {
            setCaptchaError(
              res.message || 'Произошла ошибка при проверке капчи'
            );
          }
        })
        .catch(() => {
          setCaptchaError('Произошла ошибка при проверке капчи');
        })
        .finally(() => setCaptchaValidating(false));
    }
  };

  const cancelCaptcha = () => {
    logout();
  };

  return {
    captchaPending,
    captchaImg,
    captchaCode,
    captchaError,
    captchaValidating,
    onCaptchaValidate,
    requestNewCaptcha,
    changeCaptcha,
    cancelCaptcha,
  };
};
