import React from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { ReactComponent as ChainIcon } from '@terminal/common/components/icons/chain.svg';
import { ReactComponent as IcebergIcon } from '@terminal/common/components/icons/iceberg.svg';
import { OrderItem } from '@terminal/core/types/order';

import { useWidgetContext } from '../../../../shared';

import styles from './OrderListItemLabel.module.css';

export const OrderListItemLabel = React.memo(
  ({
    idFI,
    isLinkedOrder,
    isIcebergOrder,
    order,
  }: {
    idFI: number;
    isLinkedOrder?: boolean;
    isIcebergOrder?: boolean;
    order?: OrderItem;
  }) => {
    const { useSettings } = useWidgetContext();
    const { objectsTable, marketBoardsTable, finInstrumentsTable } =
      useAlfaDirectContext();

    const { idObject, idMarketBoard } = finInstrumentsTable.get('idFI', idFI);

    const object = objectsTable.get('idObject', idObject);
    const marketBoard = marketBoardsTable.get('idMarketBoard', idMarketBoard);
    const {
      defaultValues: { selectedDisplayInstrumentType },
    } = useSettings();

    const displayedName =
      selectedDisplayInstrumentType === 'name' ? (
        <div className={styles.ticker}>{object?.nameObject}</div>
      ) : (
        <>
          <div className={styles.ticker}>{object?.symbolObject}</div>
          <div className={styles.market}>{marketBoard?.nameMarketBoard}</div>
        </>
      );

    return (
      <div className={styles.labelCenter}>
        {displayedName}
        {isLinkedOrder && (
          <div className={styles.linkedOrderIcon}>
            <ChainIcon
              height={12}
              width={12}
              color="var(--color-dark-graphic-secondary)"
            />
            <Typography.Text
              className={styles.baseOrderId}
              view="secondary-large"
            >
              {order?.numEDocumentBase}
            </Typography.Text>
          </div>
        )}
        {isIcebergOrder && (
          <div className={styles.linkedOrderIcon}>
            <IcebergIcon
              height={12}
              width={12}
              color="var(--color-dark-graphic-secondary)"
            />
          </div>
        )}
      </div>
    );
  }
);
