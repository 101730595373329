import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { NotificationType } from '@terminal/core/types/ui';

import { useAlfaDirectContext, useWidgetContext } from '../../../shared';

import { useCertificateEnrollStore } from '../model/store';

export const useCheckSms = ({ successMsg, errorMsg, onSuccess }) => {
  const { smsSignCodeCheck } = useAlfaDirectContext();
  const { addNotification } = useWidgetContext();

  const [
    operation,
    setOperation,
    assignment,
    setAssignment,
    setError,
    setCountStart,
  ] = useCertificateEnrollStore((store) => [
    store.operation,
    store.setOperation,
    store.assignment,
    store.setAssignment,
    store.setError,
    store.setCountStart,
  ]);

  const reset = useCallback(() => {
    setAssignment(null);
    setOperation(null);
    setError(null);
  }, [setAssignment, setOperation, setError]);

  const checkSmsQuery = useMutation(smsSignCodeCheck, {
    onSuccess: (result) => {
      if (result.code === 0) {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'positive',
          title: successMsg?.title || 'Успех',
          text: successMsg?.text,
        });
        setCountStart(null);
        onSuccess?.();
        reset();
      } else if ([1, 2].includes(result.code)) {
        setError({
          code: result.code,
          message: result.message,
        });
      } else {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'attention',
          ...errorMsg,
        });
        reset();
      }
    },
    onError: () => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'attention',
        ...errorMsg,
      });
      reset();
    },
  });

  const checkSmsCode = useCallback(
    (code: string) => {
      if (operation && assignment) {
        checkSmsQuery.mutate({
          smsReference: assignment.reference,
          smsCode: code,
          opText: assignment.opText,
          operationUrl: operation.url,
        });
      } else {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: 'При исполнении операции произошла ошибка',
        });
      }
    },
    [operation, assignment, checkSmsQuery, addNotification]
  );

  return {
    checkSmsCode,
    isLoading: checkSmsQuery.isLoading,
  };
};
