export const TRANSFERABLE_CURRENCIES = [
  'RUB',
  'RUR',
  'BYN',
  'CNY',
  'KZT',
  'USD',
  'EUR',
  'AMD',
];

export const CURRENCIES_COMMISSION: { [s: string]: string } = {
  BYN: '0,6%',
  CNY: '0,6%',
  KZT: '0,6%',
  USD: '1%',
  EUR: '1%',
  AMD: '0,6%',
};

export const MARGIN_TEST_ID = 2;
