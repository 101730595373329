import { useContext } from 'react';

import { context } from './context';

/**
 * @returns контекст управления темой приложения
 * */
export function useThemeContext() {
  return useContext(context);
}
