import cn from 'classnames';
import {
  BottomSheet as BottomSheetOriginal,
  BottomSheetProps,
} from '@alfalab/core-components/bottom-sheet';

import styles from './BottomSheet.module.css';

export const BottomSheet = ({ className, ...props }: BottomSheetProps) => {
  return (
    <BottomSheetOriginal
      className={cn(styles.customBottomSheet, className)}
      headerClassName={styles.customHeader}
      containerClassName={styles.container}
      {...props}
    />
  );
};
