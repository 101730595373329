import {
  MIDDLE_API_INVEST_PUBLIC_URI,
  REMOTE_CONFIG_PATH,
  REMOTE_CONFIG_TOKEN,
} from '@terminal/core/env';

import { FetchRemoteConfigParams, RemoteConfig } from './types/RemoteConfig';

export const fetchRemoteConfig = async <T>({
  os = '',
  appVersion = '',
  packageName = '',
  delay = 1500,
}: FetchRemoteConfigParams): Promise<RemoteConfig<T>> => {
  const controller = new AbortController();

  const timeoutId = setTimeout(() => {
    controller.abort();
  }, delay);

  const response = await fetch(
    MIDDLE_API_INVEST_PUBLIC_URI + REMOTE_CONFIG_PATH,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atob(REMOTE_CONFIG_TOKEN)}`,
      },
      body: JSON.stringify({
        os,
        appVersion,
        packageName,
      }),
      signal: controller.signal,
    }
  );

  clearTimeout(timeoutId);

  if (response.ok) {
    return response.json();
  } else {
    if (response.status === 419) {
      // TODO нужно сделать корректную обработку протухшего токена
      // Возможно, стоит подумать, как его обновить
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
};
