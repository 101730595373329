import { FinInfoEntity, FinInfoExtEntity } from '../lib/client/entities';

import { FinInfo, FinInfoExt } from '../types/quotes';

export const finInfoMap = (entity: FinInfoEntity): FinInfo => ({
  idFI: entity.IdFI,
  idTradePeriodStatus: entity.IdTradePeriodStatus,
  last: entity.Last,
  lastQty: entity.LastQty,
  lastTime: entity.LastTime,
  bid: entity.Bid,
  ask: entity.Ask,
  yield: entity.Yield,
  duration: entity.Duration,
  bidQty: entity.BidQty,
  askQty: entity.AskQty,
  sumBid: entity.SumBid,
  sumAsk: entity.SumAsk,
  numBids: entity.NumBids,
  numAsks: entity.NumAsks,
  high: entity.High,
  low: entity.Low,
  wAPrice: entity.WAPrice,
  yieldWAPrice: entity.YieldWAPrice,
  numTrades: entity.NumTrades,
  volToday: entity.VolToday,
  valToday: entity.ValToday,
  pos: entity.Pos,
  highBid: entity.HighBid,
  lowOffer: entity.LowOffer,
  quote: entity.Quote,
  quoteTime: entity.QuoteTime,
  exTime: entity.ExTime,
  idSession: entity.IdSession,
  idBoard: entity.IdBoard,
  idGate: entity.IdGate,
  revision: entity.Revision,
  flags: entity.Flags,
  minCurLast: entity.MinCurLast,
  open: entity.Open,
  prevCloseYield: entity.PrevCloseYield,
  prevPos: entity.PrevPos,
  prevWAPrice: entity.PrevWAPrice,
});

export const finInfoExtMap = (entity: FinInfoExtEntity): FinInfoExt => ({
  idFI: entity.IdFI,
  idGate: entity.IdGate,
  idMarket: entity.IdMarket,
  idBoard: entity.IdBoard,
  idTradePeriodStatus: entity.IdTradePeriodStatus,
  idAllowOrderStatus: entity.IdAllowOrderStatus,
  idSession: entity.IdSession,
  sessionDate: entity.SessionDate,
  lot: entity.Lot,
  priceStep: entity.PriceStep,
  priceStepCost: entity.PriceStepCost,
  idObjectCurrency: entity.IdObjectCurrency,
  rate: entity.Rate,
  PSTNKD: entity.PSTNKD,
  upPrice: entity.UpPrice,
  downPrice: entity.DownPrice,
  gtBuy: entity.GtBuy,
  gtSell: entity.GtSell,
  agentCode: entity.AgentCode,
  auctActivationDate: entity.AuctActivationDate,
  counterPrice: entity.CounterPrice,
  prevSessionId: entity.PrevSessionId,
  prevSessionDate: entity.PrevSessionDate,
  prevQuote: entity.PrevQuote,
  prevLastDate: entity.PrevLastDate,
  prevLast: entity.PrevLast,
  nextCoupon: entity.NextCoupon,
  buyBackDate: entity.BuyBackDate,
  couponPeriod: entity.CouponPeriod,
  duration: entity.Duration,
  accruedInt: entity.AccruedInt,
  couponValue: entity.CouponValue,
  closeYield: entity.CloseYield,
  yieldAtPrevWaPrice: entity.YieldAtPrevWaPrice,
  version: entity.Version,
  operation: entity.Operation,
  fairPrice: entity.FairPrice,
  prevFairPrice: entity.PrevFairPrice,
  prevFairPriceDate: entity.PrevFairPriceDate,
  icebergMinQuantity: entity.IcebergMinQuantity,
  icebergMinOpenQuantity: entity.IcebergMinOpenQuantity,
});
