import cn from 'classnames';
import { useMemo } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { TextProps, Typography } from '@alfalab/core-components/typography';

import { MINORITY } from '@terminal/core/constants/ui';
import { TradingLimitsShowMode } from '@terminal/core/types/layout';
import { PositionItem } from '@terminal/core/types/position';

import styles from './NPUBlock.module.css';

export const NPUBlock = ({
  position,
  block = false,
  textView = 'secondary-large',
  className,
  isDayTheme,
  displaySetting = TradingLimitsShowMode.MONEY,
}: {
  position: PositionItem;
  block?: boolean;
  isDayTheme?: boolean;
  className?: string;
  textView?: TextProps['view'];
  displaySetting?: TradingLimitsShowMode;
}) => {
  const { NPLtoMarketCurPrice, currency, NPLPercent, NPLPoints } = position;

  const dislayedValue = useMemo(() => {
    switch (displaySetting) {
      case TradingLimitsShowMode.MONEY:
        return NPLtoMarketCurPrice;
      case TradingLimitsShowMode.QUANTITY:
        return NPLPercent;
      case TradingLimitsShowMode.POINTS:
        return NPLPoints;

      default:
        return NPLtoMarketCurPrice;
    }
  }, [NPLPercent, NPLPoints, NPLtoMarketCurPrice, displaySetting]);

  const assetUnits = useMemo(() => {
    switch (displaySetting) {
      case TradingLimitsShowMode.QUANTITY:
        return '%';
      case TradingLimitsShowMode.POINTS:
        return 'pt';

      default:
        return;
    }
  }, [displaySetting]);

  const minority =
    displaySetting === TradingLimitsShowMode.POINTS ? 1 : MINORITY;

  return (
    <Typography.Text
      view={textView}
      className={cn(
        className,
        isDayTheme && styles.white,
        styles.amountWrapper,
        block && styles.growText
      )}
    >
      <span className={cn(isDayTheme && styles.white, styles.secondaryText)}>
        P&L
      </span>
      &nbsp;
      {dislayedValue !== null && (
        <Amount.Pure
          className={cn(isDayTheme && styles.white, styles.amount)}
          value={dislayedValue * minority}
          minority={minority}
          view="withZeroMinorPart"
          currency={
            displaySetting === TradingLimitsShowMode.MONEY
              ? currency
              : undefined
          }
        />
      )}
      {Boolean(assetUnits) && <>&nbsp;{assetUnits}</>}
    </Typography.Text>
  );
};
