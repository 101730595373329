import cn from 'classnames';

import styles from './StrategyDifficulty.module.css';

interface StrategyDifficultyProps {
  difficulty: number;
}

export const StrategyDifficulty = ({ difficulty }: StrategyDifficultyProps) => {
  return (
    <div className={styles.difficulty}>
      {[1, 2, 3].map((lvl) => (
        <div
          key={lvl}
          className={cn(styles.item, { [styles.active]: lvl <= difficulty })}
        />
      ))}
    </div>
  );
};
