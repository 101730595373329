import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getMoneyHistorySettings } from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';

export const useMoneyHistoryFilterSettings = (treaty: number | undefined) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.MoneyHistorySettings, { treaty }],
    () => getMoneyHistorySettings(treaty || 0),
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе фильтров',
        });
      },
    }
  );
};
