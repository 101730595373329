import React, { useContext } from 'react';

import { AlfaDirectAPI } from '../../types/AlfaDirectAPI';

export function createAlfaDirectContext() {
  const defaultValue = {} as AlfaDirectAPI;
  const context = React.createContext<AlfaDirectAPI>(defaultValue);

  const useCreatedWidgetContext = (): AlfaDirectAPI => useContext(context);

  return {
    ...context,
    useAlfaDirectContext: useCreatedWidgetContext,
  };
}

export const { Provider, Consumer, useAlfaDirectContext } =
  createAlfaDirectContext();
