import {
  orderTypeAbbrMap,
  orderTypeNameMap,
} from '@terminal/core/constants/orders';
import { OrderType } from '@terminal/core/lib/client/entities';
import { OrderSide } from '@terminal/core/types/trading';

import { getBracketPercent } from './getBracketPercent';

import { SLTPInstruction } from '../model/types';

export const getSLTPButtonShortText = (
  sltp: SLTPInstruction,
  mainPrice: number,
  side: OrderSide,
  priceStep?: number
): string => {
  const buttonContent: string[] = [];

  if (sltp.isSlEnabled && sltp.slPrice !== undefined) {
    const slPercent = getBracketPercent(
      sltp.slPriceType,
      sltp.slPrice,
      mainPrice,
      priceStep
    );

    buttonContent.push(
      `${
        (sltp.isTpEnabled
          ? orderTypeAbbrMap
          : {
              ...orderTypeNameMap,
              [OrderType.TRS]: orderTypeAbbrMap[OrderType.TRS],
            })[sltp.slOrderType]
      } ${side === OrderSide.BUY ? '-' : ''}${slPercent}%`
    );
  }

  if (sltp.isTpEnabled && sltp.tpPrice !== undefined) {
    const tpPercent = getBracketPercent(
      sltp.tpPriceType,
      sltp.tpPrice,
      mainPrice,
      priceStep
    );

    buttonContent.push(
      `${sltp.isSlEnabled ? 'ТП' : 'Тейк-профит'} ${
        side === OrderSide.BUY ? '' : '-'
      }${tpPercent}%`
    );
  }

  if (buttonContent.length) {
    return buttonContent.join(' | ').replaceAll('.', ',');
  }

  return '';
};
