import cn from 'classnames';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import styles from './AddAccountVariant.module.css';

interface AddAccountVariantProps {
  title: string;
  text: string;
  tooltip?: React.ReactNode;
  className?: string;
  onSelect?: () => void;
}

export const AddAccountVariant = ({
  title,
  text,
  tooltip,
  className,
  onSelect,
}: AddAccountVariantProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.head}>
        <Typography.Text
          view="primary-small"
          tag="p"
          weight="bold"
          color="primary"
          defaultMargins={false}
        >
          {title}
        </Typography.Text>
        {tooltip && (
          <TooltipDesktop
            content={tooltip}
            targetClassName={styles.tooltip}
            contentClassName={styles.tooltipContent}
            position="top"
          >
            <InformationCircleLineSIcon height={16} width={16} />
          </TooltipDesktop>
        )}
      </div>
      <Gap size="xs" />
      <Typography.Text
        view="secondary-small"
        tag="p"
        color="secondary"
        defaultMargins={false}
      >
        {text}
      </Typography.Text>
      <Gap size="s" />
      <ButtonDesktop size="s" block onClick={onSelect}>
        Выбрать
      </ButtonDesktop>
    </div>
  );
};
