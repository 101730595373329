import React, { useEffect } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { AddMIcon } from '@alfalab/icons-glyph/AddMIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { ShieldMIcon } from '@alfalab/icons-glyph/ShieldMIcon';

import { useRemoteConfig } from '@terminal/common/entities/RemoteConfig';
import {
  ServiceNotifications,
  TECH_MESSAGE_CODE,
} from '@terminal/common/entities/ServiceNotifications';
import { LOCATIONS } from '@terminal/core/constants/analytics';
import { LK_CERTIFICATES } from '@terminal/core/env';
import { useCertificates } from '@terminal/core/hooks/useCertificates';
import { trackMainMenu } from '@terminal/core/lib/analytics';

import { CertificatesAll } from './CertificatesAll';

import styles from './Certificates.module.css';

export const Certificates = React.memo(() => {
  const {
    workingCertificate,
    iconColor,
    endDate,
    onClickCertificateConfirm,
    onClickCertificateReject,
    onClickCertificateIssue,
    isNeedIssueCertificate,
    isNeedConfirmCertificate,
    isEnrollStepInProgress,
    isEnrollStepSuccess,
    isAllowIssueCertificate,
  } = useCertificates();

  useEffect(() => {
    trackMainMenu(LOCATIONS.LK_SIGN);
  }, []);

  return (
    <>
      <ServiceNotifications
        code={TECH_MESSAGE_CODE.LK_ELECTRONIC_SIGNATURE}
        variant="banner"
        containerClassName={styles.notificationsContainer}
        useRemoteConfig={useRemoteConfig}
      />
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        color="primary"
        defaultMargins={false}
      >
        Действующий сертификат
      </Typography.Text>
      <Gap size="m" />
      <div className={styles.certificate}>
        <div className={styles.certificateIcon}>
          <SuperEllipse size={48}>
            <ShieldMIcon color={iconColor} />
          </SuperEllipse>
        </div>
        <div className={styles.certificateRows}>
          {Boolean(workingCertificate) && (
            <>
              <Typography.Text
                view="secondary-small"
                color="positive"
                className={styles.rowTitle}
              >
                Сертификат электронной подписи
              </Typography.Text>
              <div>
                <Typography.Text view="secondary-small" color="primary">
                  {workingCertificate?.idCertificate},&nbsp;
                  {workingCertificate?.description}
                </Typography.Text>
                <br />
                <Typography.Text view="secondary-small" color="secondary">
                  Действителен до&nbsp;{endDate}
                </Typography.Text>
              </div>
            </>
          )}
          {isNeedIssueCertificate && (
            <div>
              <Typography.Text view="secondary-small" color="attention">
                В данный момент у вас нет действующего сертификата
              </Typography.Text>
            </div>
          )}
          {isNeedConfirmCertificate && (
            <Typography.Text view="secondary-small" color="attention">
              Подтвердите сертификат с помощью СМС
            </Typography.Text>
          )}

          <div className={styles.buttons}>
            {isNeedIssueCertificate && (
              <ButtonDesktop
                view="secondary"
                size="s"
                leftAddons={<AddMIcon width={18} height={18} />}
                onClick={onClickCertificateIssue}
                disabled={
                  isEnrollStepInProgress ||
                  isEnrollStepSuccess ||
                  !isAllowIssueCertificate
                }
                loading={isEnrollStepInProgress || isEnrollStepSuccess}
              >
                Выпустить новый
              </ButtonDesktop>
            )}
            {isNeedConfirmCertificate && (
              <ButtonDesktop
                view="secondary"
                size="s"
                leftAddons={<CheckmarkMIcon width={18} height={18} />}
                onClick={onClickCertificateConfirm}
                disabled={isEnrollStepInProgress || isEnrollStepSuccess}
                loading={isEnrollStepInProgress || isEnrollStepSuccess}
              >
                Подтвердить
              </ButtonDesktop>
            )}
            {(Boolean(workingCertificate) || isNeedConfirmCertificate) &&
              LK_CERTIFICATES && (
                <ButtonDesktop
                  view="secondary"
                  size="s"
                  leftAddons={<CrossMIcon width={18} height={18} />}
                  onClick={onClickCertificateReject}
                  disabled={isEnrollStepInProgress}
                  loading={isEnrollStepInProgress}
                >
                  Отозвать
                </ButtonDesktop>
              )}
          </div>
        </div>
      </div>
      {!isAllowIssueCertificate && (
        <Typography.Text view="secondary-large" color="secondary">
          Чтобы выпустить новый сертификат, удалите ненужный, выпущенный для
          другого браузера или устройства
        </Typography.Text>
      )}
      <Gap size="2xl" />
      <CertificatesAll />
    </>
  );
});
