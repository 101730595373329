import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { makePositionRows } from '../../lib/domain/makePositionRows';
import { useSingleFORTSMarket } from '../useSingleFORTSMarket';

import {
  PositionItem,
  PositionOptions,
  PositionRowsRawNew,
} from '../../types/position';

// Новый API для usePosition
// objectsTable и objectTypeTable пока не используется, но нужны чтоб закрепить новый API
export function usePositionNew(
  {
    positions,
    quotes,
    finInfoExts,
    subAccountRazdels,
    ordersMap,
    currenciesQuotes,
    currenciesFinInfoExts,
    positionsToIdFiBalances,
  }: PositionRowsRawNew,
  { selectedSubAccounts, isUnifyRubPositions }: PositionOptions
): PositionItem[] {
  const { objectsTable, objectExtsTable, marketBoardsTable, objectTypesTable } =
    useAlfaDirectContext();
  const isSingleFORTSMarketAvailable = useSingleFORTSMarket();

  return useMemo(
    () =>
      makePositionRows(
        {
          positions,
          quotes,
          objectsMap: objectsTable.getColumn('idObject'),
          objectExtsMap: objectExtsTable.getColumn('idObject'),
          finInfoExts,
          marketBoardMap: marketBoardsTable.getColumn('idMarketBoard'),
          subAccountRazdels,
          objectTypesMap: objectTypesTable.getColumn('idObjectType'),
          ordersMap,
          currenciesQuotes,
          currenciesFinInfoExts,
          positionsToIdFiBalances,
        },
        {
          selectedSubAccounts,
          isSingleFORTSMarketAvailable,
          isUnifyRubPositions,
        }
      ),
    [
      positions,
      quotes,
      objectsTable,
      objectExtsTable,
      finInfoExts,
      marketBoardsTable,
      subAccountRazdels,
      objectTypesTable,
      ordersMap,
      currenciesQuotes,
      currenciesFinInfoExts,
      positionsToIdFiBalances,
      selectedSubAccounts,
      isSingleFORTSMarketAvailable,
      isUnifyRubPositions,
    ]
  );
}
