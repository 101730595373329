import cn from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import { ActionButtons, ActionButtonsProps } from './ActionButtons';

import styles from './ModalFooter.module.css';

export type ModalFooterProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Список кнопок, отображаемых в нижней части окна слева
   * @default []
   * */
  actionButtonsStart?: ActionButtonsProps['buttons'];
  /**
   * Список кнопок, отображаемых в нижней части окна справа
   * @default []
   * */
  actionButtonsEnd?: ActionButtonsProps['buttons'];
  /**
   * Если `true`, то добавляются отрицательные отступы
   * */
  withinBody?: boolean;
};

export const ModalFooter: FC<ModalFooterProps> = (props) => {
  const {
    actionButtonsStart = [],
    actionButtonsEnd = [],
    className,
    withinBody,
    ...restProps
  } = props;
  const rootClassName = cn(className, styles.root, {
    [styles.root_withNegativeMargins]: withinBody,
  });

  if (actionButtonsStart.length + actionButtonsEnd.length === 0) {
    return null;
  }

  return (
    <footer className={rootClassName} {...restProps}>
      <ActionButtons buttons={actionButtonsStart} />
      <ActionButtons buttons={actionButtonsEnd} />
    </footer>
  );
};
