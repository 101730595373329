import { Button } from '@alfalab/core-components/button/modern';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import styles from './XmlUploadStatus.module.css';

interface XmlUploadStatusProps {
  title: string;
  percent: number;
  className?: string;
  onCancel?: () => void;
}

export const XmlUploadStatus = ({
  title,
  percent,
  className,
  onCancel,
}: XmlUploadStatusProps) => (
  <div className={className}>
    <Typography.Text view="secondary-large" tag="p">
      {title}
    </Typography.Text>
    <Space fullWidth>
      <ProgressBar value={percent} view="link" />
      <Row className={styles.cancel}>
        <Col width={6}>
          <Button block view="tertiary" size="xxs" onClick={onCancel}>
            Отмена
          </Button>
        </Col>
      </Row>
    </Space>
  </div>
);
