import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { DotsHorizontalMIcon } from '@alfalab/icons-glyph/DotsHorizontalMIcon';
import { NavigationMarketplaceMIcon } from '@alfalab/icons-glyph/NavigationMarketplaceMIcon';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';
import { RepeatOffMIcon } from '@alfalab/icons-glyph/RepeatOffMIcon';

import { DropdownMenu } from '@terminal/common/components/DropdownMenu';
import { MenuGroup } from '@terminal/common/components/Menu';
import { addElemIfExists } from '@terminal/core/lib/addElemIfExist';
import { getStringDate } from '@terminal/core/lib/format';
import { getBrowserInfo } from '@terminal/core/lib/info';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';
import { inactiveConfigurationsSelector } from '@terminal/core/store/selectors/workspaceConfigurations/inactiveConfigurationsSelector';

import { PickerButton } from '../../../../shared/ui/PickerButton';
import { exportConfiguration } from '../../lib/exportConfiguration';
import { WorkspaceConfigurationModal } from '../WorkspaceConfigurationModal';
import { WorkspaceRenameModal } from '../WorkspaceConfigurationModal/WorkspaceRenameModal';
import { WorkspaceConfigurationsTabs } from '../WorkspaceConfigurationsTabs';

import { WorkspaceConfigurationScreen } from '../../model/types';

import styles from './WorkspaceConfigurationList.module.css';

const exportImportAvailable = ['Chrome', 'Safari'].includes(
  getBrowserInfo().name
);

export const WorkspaceConfigurationList = ({
  setScreen,
}: {
  setScreen: (screen: WorkspaceConfigurationScreen) => void;
}) => {
  const [renameModalConfig, setRenameModalConfig] = useState({
    isOpen: false,
    value: '',
  });
  const [resetModalVisible, setResetModalVisible] = useState<boolean>(false);

  const {
    workspaceConfigurationActiveId,
    manualSaveConfiguration,
    resetConfigurationById,
    renameConfiguration,
  } = useStore(
    (state) => ({
      workspaceConfigurationActiveId: state.workspaceConfigurationActiveId,
      manualSaveConfiguration: state.manualSaveConfiguration,
      resetConfigurationById: state.resetConfigurationById,
      renameConfiguration: state.renameConfiguration,
    }),
    shallow
  );

  const activeConfiguration = useStore(activeConfigurationSelector);
  const otherConfigurations = useStore(inactiveConfigurationsSelector);

  const autoSavedDateStr = getStringDate(
    new Date(activeConfiguration.lastManualSavedConfig.timeStamp)
  );

  const isChanged = useMemo(
    () =>
      activeConfiguration.lastManualSavedConfig.timeStamp !==
      activeConfiguration.lastAutoSavedConfig.timeStamp,
    [activeConfiguration]
  );

  const changedDate = useMemo(
    () =>
      isChanged
        ? getStringDate(
            new Date(activeConfiguration.lastAutoSavedConfig.timeStamp)
          )
        : null,
    [activeConfiguration, isChanged]
  );

  const isAllowManualSaveConfiguration =
    activeConfiguration.lastManualSavedConfig.timeStamp !==
    activeConfiguration.lastAutoSavedConfig.timeStamp;

  const onClickManualSaveConfiguration = useCallback(() => {
    manualSaveConfiguration(workspaceConfigurationActiveId);
  }, [manualSaveConfiguration, workspaceConfigurationActiveId]);

  const onClickResetConfiguration = useCallback(
    (id: string) => {
      resetConfigurationById(id);
    },
    [resetConfigurationById]
  );

  const onClickSaveNewConfiguration = useCallback(() => {
    setScreen(WorkspaceConfigurationScreen.Add);
  }, [setScreen]);

  return (
    <div>
      <Typography.Text view="secondary-large" color="secondary">
        Конфигурация — сохраненный набор виджетов и их настроек на вашем рабочем
        столе. Позволяет быстро изменять рабочие столы при работе с различными
        торговыми стратегиями.
      </Typography.Text>

      <Gap size="m" />

      {activeConfiguration && (
        <>
          <Typography.Text
            view="primary-small"
            color="primary"
            tag="div"
            weight="bold"
            className={styles.graphic}
          >
            Активная конфигурация
          </Typography.Text>

          <Gap size="xs" />

          <div className={styles.activeConfiguration}>
            <SuperEllipse size={48}>
              <NavigationMarketplaceMIcon />
            </SuperEllipse>

            <div>
              <Gap size="xs" />

              <Typography.Text
                view="secondary-large"
                color="primary"
                weight="bold"
                tag="div"
                className={styles.graphic}
              >
                {activeConfiguration.name}
              </Typography.Text>
              <Typography.Text
                view="secondary-small"
                color="secondary"
                tag="div"
                weight="medium"
              >
                Сохранено {autoSavedDateStr}
                {isChanged && changedDate && <>, изменено {changedDate}</>}
              </Typography.Text>

              <Gap size="m" />

              <div className={styles.buttons}>
                <DropdownMenu
                  groups={[
                    {
                      key: 'save-group',
                      items: [
                        {
                          key: 'save',
                          type: 'button',
                          label: 'Сохранить текущую конфигурацию',
                          hideMenu: true,
                          onClick: onClickManualSaveConfiguration,
                        },
                        {
                          key: 'save-as',
                          type: 'button',
                          label: 'Сохранить конфигурацию как ...',
                          hideMenu: true,
                          onClick: onClickSaveNewConfiguration,
                        },
                      ],
                    },
                    ...addElemIfExists<boolean, MenuGroup>(
                      exportImportAvailable,
                      {
                        key: 'export',
                        items: [
                          {
                            key: 'to-file',
                            type: 'button',
                            label: 'Выгрузить в файл',
                            hint: 'Вы сможете загрузить конфигурацию на другом устройстве или в другом браузере.',
                            hideMenu: true,
                            onClick: () =>
                              exportConfiguration(activeConfiguration),
                          },
                        ],
                      }
                    ),
                  ]}
                >
                  {(ref) => (
                    <PickerButton ref={ref} size="xs" className={styles.button}>
                      {isAllowManualSaveConfiguration
                        ? 'Сохранить изменения...'
                        : 'Все изменения сохранены'}
                    </PickerButton>
                  )}
                </DropdownMenu>

                <DropdownMenu
                  groups={[
                    {
                      key: 'other',
                      items: [
                        {
                          key: 'rename',
                          type: 'button',
                          label: 'Переименовать',
                          Icon: PencilMIcon,
                          hideMenu: true,
                          onClick: () =>
                            setRenameModalConfig({
                              value: activeConfiguration.name,
                              isOpen: true,
                            }),
                        },
                        {
                          key: 'reset',
                          type: 'button',
                          label: 'Сбросить изменения',
                          hint: 'Конфигурация будет сброшена к последнему состоянию, сохраненному вами',
                          Icon: RepeatOffMIcon,
                          hideMenu: true,
                          onClick: () => setResetModalVisible(true),
                        },
                      ],
                    },
                  ]}
                >
                  {(ref) => (
                    <PickerButton
                      ref={ref}
                      size="xs"
                      className={cn(styles.button, styles.moreButton)}
                    >
                      <IconButton
                        size="xs"
                        view="secondary"
                        icon={DotsHorizontalMIcon}
                      />
                    </PickerButton>
                  )}
                </DropdownMenu>
              </div>
            </div>
          </div>
        </>
      )}

      <Gap size="2xl" />

      <Typography.Text
        view="primary-small"
        color="primary"
        tag="div"
        weight="bold"
        className={styles.graphic}
      >
        Другие конфигурации
      </Typography.Text>

      <Gap size="2xs" />

      <Typography.Text
        view="secondary-large"
        color="secondary"
        tag="div"
        weight="medium"
      >
        Только для этого устройства и браузера.&nbsp;
        <span
          onClick={() => setScreen(WorkspaceConfigurationScreen.Export)}
          className={styles.hintText}
        >
          Как открыть на другом устройстве?
        </span>
      </Typography.Text>

      <Gap size="m" />

      <WorkspaceConfigurationsTabs otherConfigurations={otherConfigurations} />

      <WorkspaceRenameModal
        open={renameModalConfig.isOpen}
        value={renameModalConfig.value}
        onChange={(value) =>
          setRenameModalConfig({ ...renameModalConfig, value })
        }
        onClose={() => setRenameModalConfig({ value: '', isOpen: false })}
        onSubmit={() =>
          renameConfiguration(activeConfiguration.id, renameModalConfig.value)
        }
      />

      <WorkspaceConfigurationModal
        open={resetModalVisible}
        onClose={() => setResetModalVisible(false)}
        title="Сбросить изменения?"
        actionButtonsEnd={[
          {
            children: 'Отмена',
            onClick: () => setResetModalVisible(false),
          },
          {
            children: 'Сбросить',
            view: 'secondary',
            onClick: () => {
              onClickResetConfiguration(activeConfiguration.id);
              setResetModalVisible(false);
            },
          },
        ]}
        className={styles.resetModal}
      >
        Вы действительно хотите сбросить все несохраненные изменения текущей
        конфигурации?
      </WorkspaceConfigurationModal>
    </div>
  );
};
