import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Input } from '@alfalab/core-components/input';
import { Loader } from '@alfalab/core-components/loader';
import { Select, SelectProps } from '@alfalab/core-components/select';
import { Field } from '@alfalab/core-components/select/components/field';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';
import { Switch } from '@alfalab/core-components/switch';

import { SecuritiesPlaceItem } from '@terminal/core/lib/rest/lkSecurities';

import {
  TooltipConterpartyName,
  TooltipConterpartyPlace,
} from '../../components/Tooltips';
import {
  COUNTERAGENT_TYPES,
  isAccountTypeRequired,
  isContrAlfaBankShow,
  isCounteragentTypeShow,
  isCounterpartyRequired,
  SecurityPlaceCode,
  securityPlaceKey,
} from '../../shared';
import { getDepoAccountErrorMsg } from '../../validation';

interface CounterpartyReestrFormProps {
  counteragentAlfaBank: boolean;
  onCounteragentAlfaBankChange: (check: boolean) => void;
  selectedCounterpartyPlace: SecuritiesPlaceItem | null;
  handlePlaceChange: SelectProps['onChange'];
  ownershipChange: boolean;
  marketAlfa: string;
  depoAccount: string;
  counteragentType?: COUNTERAGENT_TYPES | undefined;
  handleCounteragentTypeChange?: SelectProps['onChange'];
  transferType: 'in' | 'out';
  placeListOptions: { key: string; content: string }[];
  placeListPending: boolean;
  userTypeOptions: { key: string; content: string }[];
  userType: string;
  userTypeListPending: boolean;
  handleUserTypeChange: SelectProps['onChange'];
  conterpartyRequisitesEnabled: boolean;
  counterpartyPlace: string;

  requisitesSection?: React.ReactNode;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleBlur: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  touched: { [s: string]: boolean };
  errors: { [s: string]: string | boolean };
}

export const CounterpartyReestrForm = ({
  counteragentAlfaBank,
  onCounteragentAlfaBankChange,
  selectedCounterpartyPlace,
  handlePlaceChange,
  ownershipChange,
  marketAlfa,
  depoAccount,
  counteragentType,
  handleCounteragentTypeChange,
  transferType,
  placeListOptions,
  placeListPending,
  userTypeOptions,
  userType,
  userTypeListPending,
  handleUserTypeChange,
  conterpartyRequisitesEnabled,
  counterpartyPlace,

  requisitesSection,
  handleChange,
  handleBlur,
  touched,
  errors,
}: CounterpartyReestrFormProps) => {
  const counterpartyMarket = selectedCounterpartyPlace
    ? selectedCounterpartyPlace.placeCode
    : '';

  const showAccountType = isAccountTypeRequired(
    counterpartyMarket,
    counteragentAlfaBank
  );

  const showCounterparty = isCounterpartyRequired(
    counterpartyMarket,
    userType,
    counteragentAlfaBank
  );

  const showConteragentType = isCounteragentTypeShow(
    ownershipChange,
    counterpartyMarket
  );

  const showRestFields =
    !showConteragentType ||
    (showConteragentType &&
      counteragentType !== COUNTERAGENT_TYPES.NOT_SELECTED);

  const conteragentTypeOptions = [
    {
      key: COUNTERAGENT_TYPES.PERSON,
      content: 'Физическое лицо',
    },
    {
      key: COUNTERAGENT_TYPES.LEGAL,
      content: 'Юридическое лицо',
    },
  ];

  const contragentNameNode = (
    <Input
      label="Наименование контрагента"
      labelView="outer"
      block
      autoComplete="off"
      size="s"
      name="counterpartyPlace"
      value={counterpartyPlace}
      disabled={!conterpartyRequisitesEnabled}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched.counterpartyPlace ? errors.counterpartyPlace : ''}
      rightAddons={<TooltipConterpartyName />}
    />
  );

  const restFieldsNode = (
    <>
      {showAccountType && (
        <Row>
          <Col width={6}>
            <Select
              label="Тип счёта"
              labelView="outer"
              placeholder="Выберите тип счёта"
              size="s"
              block
              options={userTypeOptions}
              selected={userType}
              onChange={handleUserTypeChange}
              Field={(props: FieldProps) => (
                <Field
                  {...props}
                  leftAddons={userTypeListPending && <Loader />}
                />
              )}
            />
          </Col>
          <Col width={6}>
            <Input
              label="Номер лицевого счёта"
              labelView="outer"
              block
              autoComplete="off"
              size="s"
              name="depoAccount"
              value={depoAccount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.depoAccount ? errors.depoAccount : ''}
              hint={getDepoAccountErrorMsg(counterpartyMarket)}
            />
          </Col>
        </Row>
      )}

      {showCounterparty && showConteragentType && (
        <Row>
          <Col width={6}>{contragentNameNode}</Col>
        </Row>
      )}

      {requisitesSection}
    </>
  );

  return (
    <Space fullWidth direction="vertical">
      {isContrAlfaBankShow(ownershipChange, transferType) && (
        <Row>
          <Col width={12}>
            <Switch
              label="Контрагент в Go Invest"
              checked={counteragentAlfaBank}
              disabled={
                counterpartyMarket === SecurityPlaceCode.REESTR ||
                marketAlfa === SecurityPlaceCode.EUROTRADE
              }
              onChange={(_, payload) =>
                onCounteragentAlfaBankChange(payload ? payload.checked : false)
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col width={6}>
          <Select
            label="Место хранения"
            labelView="outer"
            placeholder="Выберите место хранения"
            size="s"
            block
            options={placeListOptions}
            selected={securityPlaceKey(selectedCounterpartyPlace)}
            onChange={handlePlaceChange}
            Field={(props: FieldProps) => (
              <Field
                {...props}
                leftAddons={placeListPending && <Loader />}
                rightAddons={<TooltipConterpartyPlace />}
              />
            )}
          />
        </Col>
        {showCounterparty && !showConteragentType && (
          <Col width={6}>{contragentNameNode}</Col>
        )}
        {showConteragentType && (
          <Col width={6}>
            <Select
              label="Контрагент"
              labelView="outer"
              placeholder="Выберите контрагента"
              size="s"
              block
              options={conteragentTypeOptions}
              selected={counteragentType}
              onChange={handleCounteragentTypeChange}
              Field={(props: FieldProps) => (
                <Field {...props} rightAddons={<TooltipConterpartyPlace />} />
              )}
            />
          </Col>
        )}
      </Row>

      {showRestFields && restFieldsNode}
    </Space>
  );
};
