import { format } from 'date-fns';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { countDecimals } from '@terminal/core/lib/format';
import { MoneyTaxes } from '@terminal/core/lib/rest/lkMoney';

import { FormSectionHeader } from '../../../../shared/ui/FormSectionHeader';
import { MoneyTaxDetailAccrued } from './MoneyTaxDetailAccrued';

import styles from './MoneyTaxDetail.module.css';

export interface MoneyTaxDetailProps {
  taxes?: MoneyTaxes;
  className?: string;
}

export const MoneyTaxDetail = ({ taxes, ...props }: MoneyTaxDetailProps) => {
  if (!taxes) {
    return null;
  }

  const accuredDecimals = countDecimals(taxes.taxDetails?.taxAccrued);
  const accuredMinority = Math.pow(10, accuredDecimals);
  const accuredFormated = formatAmount({
    value: (taxes.taxDetails?.taxAccrued || 0) * accuredMinority,
    currency: 'RUR' as CurrencyCodes,
    minority: accuredMinority,
    view: 'withZeroMinorPart',
  });

  const withheldDecimals = countDecimals(taxes.taxDetails?.taxWithheld);
  const withheldMinority = Math.pow(10, withheldDecimals);
  const withheldFormated = formatAmount({
    value: (taxes.taxDetails?.taxWithheld || 0) * withheldMinority,
    currency: 'RUR' as CurrencyCodes,
    minority: withheldMinority,
    view: 'withZeroMinorPart',
  });

  const dueDecimals = countDecimals(taxes.taxDetails?.taxDue);
  const dueMinority = Math.pow(10, dueDecimals);
  const dueFormated = formatAmount({
    value: (taxes.taxDetails?.taxDue || 0) * dueMinority,
    currency: 'RUR' as CurrencyCodes,
    minority: dueMinority,
    view: 'withZeroMinorPart',
  });

  return (
    <div className={props.className}>
      <FormSectionHeader
        tooltip={
          <TooltipDesktop
            contentClassName={styles.tooltipContent}
            position="top"
            preventOverflow={false}
            content={
              <>
                Налоговая ставка по&nbsp;операциям с&nbsp;ценными бумагами
                и&nbsp;ПФИ:
                <br />
                <br />
                – 13% для налоговых резидентов РФ
                <br />– 30% для налоговых нерезидентов РФ
                <br />
              </>
            }
            fallbackPlacements={['left', 'right']}
          >
            <InformationCircleLineMIcon
              height={18}
              width={18}
              color="var(--color-light-graphic-secondary)"
            />
          </TooltipDesktop>
        }
      >
        Справочно: НДФЛ на {format(new Date(), 'dd.MM.yyyy')}
      </FormSectionHeader>
      <MoneyTaxDetailAccrued
        formattedAccured={accuredFormated.formattedWithCurrency}
        formattedDue={dueFormated.formattedWithCurrency}
        formattedwithheld={withheldFormated.formattedWithCurrency}
        taxRate={taxes.taxRate}
      />
    </div>
  );
};
