/**
  @deprecated Используй packages/core/src/lib/plural.ts

  Функция для численного склонения существительных

  Примеры использования: 
  declension([ 'день', 'дня', 'дней' ], 1) -> 'день'
  declension([ 'день', 'дня', 'дней' ], 2) -> 'дня'
  declension([ 'день', 'дня', 'дней' ], 5) -> 'дней'
*/
export function declension(forms: string[], value: number) {
  const cases = [2, 0, 1, 1, 1, 2];
  const formsIndex =
    value % 100 > 4 && value % 100 < 20
      ? 2
      : cases[value % 10 < 5 ? value % 10 : 5];

  return forms[formsIndex];
}
