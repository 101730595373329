import UAParser from 'ua-parser-js';

import { APP_BUILD_HASH, APP_VERSION } from '@terminal/core/env';

const uaParser = new UAParser();
const pardesUA = uaParser.getResult();

export const DEFAULT_ANALYTICS_DATA = {
  deviceType: pardesUA.device.type || 'desktop',
  deviceBrowserName: pardesUA.browser.name,
  deviceBrowserVersion: pardesUA.browser.version,
};

export const DEFAULT_ANALYTICS_DIMENSIONS = [
  {
    schema: 'iglu:com.snowplowanalytics.mobile/application/jsonschema/1-0-0',
    data: { build: APP_BUILD_HASH, version: APP_VERSION },
  },
  {
    schema:
      'iglu:com.snowplowanalytics.snowplow/mobile_context/jsonschema/1-0-1',
    data: {
      osType: pardesUA.os.name,
      osVersion: pardesUA.os.version,
      deviceModel: pardesUA.device.model,
    },
  },
];
