import { useCallback, useEffect, useRef, useState } from 'react';

import { useGetQuestion } from './useGetQuestion';
import { usePostCheckAnswer } from './usePostCheckAnswer';
import { useTestStartSession } from './useTestStartSession';
import { useTestStatus } from './useTestStatus';

/**
 * Хук для оркестровки процесса тестирования неквалифицированных инвесторов, принимает id выбранного теста
 * @param selectedTestId
 */
export const useQualTesting = (selectedTestId: number) => {
  const [selectedAnswerId, setSelectedAnswerId] = useState<number | null>(null);
  const allAnswers = useRef<number[][]>([]);
  const [testStep, setTestStep] = useState<number>(0);
  const [isTestFailed, setIsTestFailed] = useState(false);
  const [isAnswerChosen, setIsAnswerChosen] = useState(false);

  const {
    data: startSessionToken,
    isFetching: isStartSessionLoading,
    refetch: refetchSessionToken,
    isError: isStartSessionError,
  } = useTestStartSession(selectedTestId);

  const {
    data: testStatusData,
    isLoading: isTestStatusIsLoading,
    isError: isTestStatusError,
  } = useTestStatus(
    selectedTestId,
    startSessionToken?.sessionId,
    isStartSessionLoading
  );

  const isTestAvailable =
    !!testStatusData &&
    !testStatusData?.testStatus?.completed &&
    !isStartSessionLoading;

  const {
    data: testQuestion,
    isFetching: isQuestionLoading,
    refetch: getNextQuestion,
    isError: isGetQuestionError,
  } = useGetQuestion(
    selectedTestId,
    testStep,
    startSessionToken?.sessionId,
    isTestAvailable
  );

  const {
    mutateAsync: checkAnswer,
    isLoading: isCheckAnswerLoading,
    data: checkResult,
    isError: isPostCheckAnswerError,
  } = usePostCheckAnswer(
    selectedTestId,
    testQuestion?.question.questionId,
    startSessionToken?.sessionId,
    testStep,
    [selectedAnswerId],
    allAnswers.current
  );

  useEffect(() => {
    if (startSessionToken?.sessionId) {
      setTestStep(1);
    }
  }, [startSessionToken?.sessionId]);

  useEffect(() => {
    if (checkResult?.nextQuestionNumber) {
      setTestStep(checkResult?.nextQuestionNumber);
    }
  }, [checkResult?.nextQuestionNumber]);

  useEffect(() => {
    if (testStep > 1) {
      void getNextQuestion();
    }
  }, [getNextQuestion, testStep]);

  useEffect(() => {
    if (testQuestion) {
      setSelectedAnswerId(null);
    }
  }, [testQuestion]);

  useEffect(() => {
    if (isAnswerChosen) {
      void checkAnswer();
      setIsAnswerChosen(false);
    }
  }, [isAnswerChosen, checkAnswer]);

  useEffect(() => {
    if (checkResult?.wrongQuestions?.length) {
      setIsTestFailed(true);
      allAnswers.current = [];
    }
  }, [checkResult?.wrongQuestions?.length]);

  const isLoaderDisplayed = isTestStatusIsLoading || isStartSessionLoading;
  const isFatalError =
    isStartSessionError ||
    isTestStatusError ||
    isGetQuestionError ||
    isPostCheckAnswerError;

  const onAnswerSelect = useCallback((payload: number) => {
    if (payload) {
      setSelectedAnswerId(payload);
    }
  }, []);

  const onAnswerConfirm = () => {
    if (selectedAnswerId) {
      allAnswers.current = [...allAnswers.current, [selectedAnswerId]];
      setIsAnswerChosen(true);
    }
  };

  let percentage =
    testStatusData?.questionCount && testQuestion?.question?.questionNumber
      ? Math.round(
          ((testQuestion?.question?.questionNumber - 1) * 100) /
            testStatusData?.questionCount
        )
      : 0;

  const resetTest = useCallback(() => {
    setTestStep(0);
    setIsTestFailed(false);
    refetchSessionToken();
  }, [refetchSessionToken]);

  return {
    isLoaderDisplayed,
    testStatusData,
    isTestFailed,
    checkResult,
    resetTest,
    testQuestion,
    percentage,
    isQuestionLoading,
    onAnswerSelect,
    selectedAnswerId,
    isCheckAnswerLoading,
    onAnswerConfirm,
    isFatalError,
  };
};
