/* eslint-disable no-restricted-syntax */

import { Actions, DockLocation } from 'flexlayout-react';
import isUndefined from 'lodash/isUndefined';
import { useCallback, useMemo, useState } from 'react';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { getNews, useNews } from '@terminal/common/entities/News';
import { useRemoteConfig } from '@terminal/common/entities/RemoteConfig';
import { useCoupons } from '@terminal/common/hooks/useCoupons';
import { useDividends } from '@terminal/common/hooks/useDividends';
import { useIssuer } from '@terminal/common/hooks/useIssuer';
import { useIssuerFinancials } from '@terminal/common/hooks/useIssuerFinancials';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import useNotification from '@terminal/core/hooks/useNotification';
import {
  trackOrder,
  trackOrderEditSource,
  trackOrderEditType,
  trackTradingFormOpened,
} from '@terminal/core/lib/analytics';
import {
  logWidget,
  logWidgetLayoutChange,
} from '@terminal/core/lib/analytics/performance';
import { FrontEndType } from '@terminal/core/lib/client/entities';
import { getInstrumentIconURL } from '@terminal/core/lib/icons';
import { createMarginParamsFetcher } from '@terminal/core/lib/rest/fetchMarginParams';
import { shallow, useStore } from '@terminal/core/store';
import { selectIsConnectionError } from '@terminal/core/store/selectors';
import { ElementType, Widget } from '@terminal/core/types/layout';
import { useTopUpModal } from '@terminal/desktop/shared/hooks/useTopUpModal';
import { useTreaties } from '@terminal/lk/legacy/hooks';
import { useMoneyHistory } from '@terminal/lk/legacy/transfer/hooks';
import { useTradeLimits, WidgetApi } from '@terminal/widgets';

import { useEnrollCertificate } from './useEnrollCertificate';
import { useLastAutoSavedConfig } from './useLastAutoSavedConfig';
import { useRejectCertificateEffect } from './useRejectCertificateEffect';

const useSettings = () => useStore((state) => state.settings);
const useWatchLists = () => useStore((state) => state.watchLists);

const useConnectionError = () =>
  useStore(selectIsConnectionError(FrontEndType.OperServer));

const useInstrumentIcons = () =>
  useStore((state) => state.instrumentIcons, shallow);

const useLogWidgetPerformance = (activeTab: number, widgetName: Widget) =>
  useMemo(() => logWidget(activeTab, widgetName), [activeTab, widgetName]);

const useLogWidgetUpdatePerformance = (activeTab: number, widgetName: Widget) =>
  useMemo(
    () => logWidgetLayoutChange(activeTab, widgetName),
    [activeTab, widgetName]
  );

// selectedDisplayInstrumentType = state.settings.defaultValues.selectedDisplayInstrumentType
export const useWidgetProps = () => {
  const [loadedWidgets, setLoadedWidgets] = useState<string[]>([]);

  const [updateNode, getActiveLayoutKey] = useStore(
    (state) => [state.updateNode, state.getActiveLayoutKey],
    shallow
  );

  const config = useLastAutoSavedConfig();

  const unlinkFromGroup = useStore((store) => store.unlinkFromGroup);
  const setTradeFeedSettings = useStore((store) => store.setTradeFeedSettings);
  const setSetting = useStore((store) => store.setSetting);

  const addNotification = useNotification();

  const [addToWatchList, deleteFromWatchList] = useStore(
    (state) => [state.addToWatchList, state.deleteFromWatchList],
    shallow
  );

  const { endpoints } = useAppContext();

  const getNewsCallback = useCallback(
    (params) => getNews(endpoints.news, params),
    [endpoints.news]
  );

  const marginParamsFetcher = useMemo(
    () => createMarginParamsFetcher(endpoints.marginParams),
    [endpoints.marginParams]
  );

  const onWidgetLoad = useCallback((key: string) => {
    setLoadedWidgets((loadedWidgets) => [...loadedWidgets, key]);
  }, []);

  const resetLoadedWidgets = useCallback(() => setLoadedWidgets([]), []);

  const openTopUpModal = useTopUpModal();

  const addWidget = useCallback<WidgetApi['addWidget']>(
    (widgetName, widgetType, widgetConfig, nodeId) => {
      const model = config.models[getActiveLayoutKey()];

      const activeTabSet = model.getActiveTabset();
      const activeTabSetId = activeTabSet?.getId();
      const children = activeTabSet?.getChildren().length ?? 0;

      if (nodeId && activeTabSetId) {
        const component = {
          type: ElementType.TAB,
          name: widgetName,
          component: widgetType,
          config: widgetConfig,
        };

        model.doAction(
          Actions.addNode(
            component,
            activeTabSetId,
            DockLocation.CENTER,
            children,
            true
          )
        );
      }
    },
    [config, getActiveLayoutKey]
  );

  const deleteWidget = useCallback<WidgetApi['deleteWidget']>(
    (nodeId, link, attributes) => {
      const model = config.models[getActiveLayoutKey()];
      const node = model.getNodeById(nodeId || '');
      const tabSet = node?.getParent();

      if (nodeId) {
        if (!isUndefined(link)) {
          unlinkFromGroup(nodeId, link);
        }

        if (tabSet && attributes) {
          model.doAction(
            Actions.updateNodeAttributes(tabSet.getId(), attributes)
          );
        }

        model.doAction(Actions.deleteTab(nodeId));
      }
    },
    [config, unlinkFromGroup, getActiveLayoutKey]
  );

  const getNode = useCallback<WidgetApi['getNode']>(
    (nodeId) => {
      const model = config.models[getActiveLayoutKey()];
      const node = model.getNodeById(nodeId || '');

      return (
        node && {
          getRect: () => node.getRect(),
        }
      );
    },
    [config, getActiveLayoutKey]
  );

  const getTradesFeedSettings = useCallback<WidgetApi['getTradesFeedSettings']>(
    () => config.tradesFeedsSettings,
    [config]
  );

  const widgetProps: WidgetApi = {
    // watchlists
    useWatchLists,
    addToWatchList,
    deleteFromWatchList,

    // system
    addNotification,
    // layout
    getActiveLayoutKey,
    unlinkFromGroup,
    addWidget,
    deleteWidget,
    getNode,
    // settings
    updateNode,
    useEnrollCertificate,
    useRejectCertificateEffect,
    setSetting,
    useSettings,
    getTradesFeedSettings,
    // ui
    openTopUpModal,

    // other
    setTradeFeedSettings,
    useConnectionError,
    useInstrumentIcons,
    // fetch handler
    useMoneyHistory,
    useTreaties,
    useNews: (params) => useNews(getNewsCallback, params),
    useIssuer,
    useIssuerFinancials,
    useCoupons,
    useDividends,

    getInstrumentIconURL,

    loadedWidgets,
    onWidgetLoad,
    resetLoadedWidgets,

    analytics: {
      trackOrder,
      trackOrderEditSource,
      trackOrderEditType,
      trackTradingFormOpened,
    },
    useLogWidgetPerformance,
    useLogWidgetUpdatePerformance,
    marginParamsFetcher,
    DropdownComponent: Dropdown,
    useRemoteConfig,
    useTradeLimits,
  };

  return widgetProps;
};
