import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';
import { getManager } from '../api/settings';

export const useManager = () => {
  const addNotification = useNotification();

  return useQuery([LkQueryKeys.Manager], getManager, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса профиля',
        text: error?.message || '',
      });
    },
  });
};
