import { EventEmitter } from 'eventemitter3';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import log from 'loglevel';
import { useEffect, useMemo, useReducer, useRef } from 'react';

import { finInfoMap } from '../../mapping/quotesMapping';
import { FinInfoEntity, FrontEndType } from '../client/entities';
import { EntityType } from '../client/entityTypes';
import throttleRaf from '../throttleRaf';
import { StreamingService, SubscribeReturnData } from './streaming';

import { FinInfo } from '../../types/quotes';

// иначе подписанты начинают помирать от ререндеров, мб потом вынести в параметр подписки
const THROTTLE_TIMEOUT = 100;
const cache: Map<number, FinInfo> = new Map();

/* Сервис подписывается на поток обновлений FinInfo с сервера, где FinInfo это рилтайм айпдейты
  с биржи о состоянии финансового инструмента. 
  Для подписки на инстансе сервиса нужно вызвать subscribe

  const quotes = new QuotesService();
  // передается массив id инструментов
  quotes.subscribe([100, 200, 300], (assets) => {
    // do stuff
  });


  Количество вызовов в секунду ограничено параметром THROTTLE_TIMEOUT чтобы подписчики не задохнулись

  Что еще нужно знать: у FinInfo информация приходит кусками. Те в одном апдейте может прилететь часть полей
  Потом в другом еще часть и поэтому в локальной мапе cache мы "копим" изменения и возвращаем их в колбэк

  Еще надо знать что котировки возвращают два сервера RealTimeBirzInfo и RealTimeBirzInfoDelayedServer
  Первые дает весь поток с биржи как есть, а второй "Прореживает"
*/

class QuotesService extends EventEmitter {
  /**
   * @returns Метод отписки от FI
   */
  subscribeToFI(
    fi: number[] | number,
    updater: (message: Record<string, FinInfo>) => void,
    throttleTimeout?: number
  ): () => void {
    const fis = Array.isArray(fi) ? fi : [fi];

    const throtledUpdater = throttle(
      throttleRaf(updater),
      throttleTimeout || THROTTLE_TIMEOUT
    );
    const fireUpdate = () => {
      // берем idIF на которые была подписка, а не все
      // проверяем что есть хотя бы какие-нибудь данные

      // оптимизируем горячее место
      const res: Record<number, FinInfo> = {};
      let empty = true;

      for (let i = 0; i < fis.length; i++) {
        let el = cache.get(fis[i]);

        if (el) {
          empty = false;
          res[fis[i]] = el;
        }
      }

      if (empty) {
        return;
      }

      throtledUpdater(res);
    };

    // сразу отдадим данные из кэша если они есть
    fireUpdate();

    const unsubscribeData = StreamingService.subscribe(
      {
        fi: fis,
        entity: EntityType.FinInfoEntity,
        frontend: FrontEndType.RealTimeBirzInfoDelayedServer,
      },
      (message) => {
        message.data.forEach((obj) => {
          this.parseObject(obj);
        });
        fireUpdate();
      }
    );

    return () => {
      throtledUpdater.cancel();
      this.unsubscribe(fi, unsubscribeData);
    };
  }

  parseObject(data: object): FinInfo {
    const finInfo = pickBy(finInfoMap(data as FinInfoEntity), (v, k) => {
      return !isUndefined(v);
    }) as FinInfo;

    if (!cache.has(finInfo.idFI)) {
      cache.set(finInfo.idFI, finInfo);
    } else {
      const old = cache.get(finInfo.idFI);

      // проверяем ситуацию что прилетел объект с более старыми данными чем у нас сейчаc
      let update = true;

      // логика такая: когда прилетает полный объект то Revision есть
      // когда прилетают апдейты, то Revision пустой
      // с какой-то периодичностью падают полный апдейт
      if (finInfo.revision) {
        // @ts-expect-error
        if (old.revision > finInfo.Revision) {
          update = false;
          log.warn('Get fin info revision >0');
        }
      }

      if (update) {
        const newObj = { ...old, ...finInfo };

        cache.set(finInfo.idFI, newObj);
      }
    }

    return cache.get(finInfo.idFI) as FinInfo;
  }

  unsubscribe(fi: number[] | number, subscribeReturnData: SubscribeReturnData) {
    StreamingService.unsubscribe(
      {
        fi: Array.isArray(fi) ? fi : [fi],
        entity: EntityType.FinInfoEntity,
        frontend: FrontEndType.RealTimeBirzInfoDelayedServer,
      },
      subscribeReturnData
    );
  }
}

export const quotesService = new QuotesService();

type UseQuotesOptions =
  | {
      throttleTimeout?: number;
      selector?: (quote: Partial<FinInfo>) => Partial<FinInfo>;
    }
  | undefined;

// !важно чтобы fi был мемоизириован
export function useQuotes(
  fi: number | number[],
  { throttleTimeout = 1000, selector }: UseQuotesOptions = {
    throttleTimeout: 1000,
  }
): Record<number, Partial<FinInfo>> {
  const [, forceRender] = useReducer((x) => x + 1, 0);
  const quotes = useRef<Record<number, Partial<FinInfo>>>({});

  const fis = useMemo(() => (Array.isArray(fi) ? fi : [fi]), [fi]);

  useEffect(() => {
    if (!fis.length) {
      return;
    }

    const unsubscribe = quotesService.subscribeToFI(
      fis,
      (data: Record<string, Partial<FinInfo>>) => {
        const result = {};

        for (let fi of fis) {
          if (data[fi]) {
            result[fi] = selector ? selector(data[fi]) : data[fi];
          } else if (quotes.current[fi]) {
            result[fi] = quotes.current[fi];
          }
        }

        if (!isEqual(result, quotes.current)) {
          quotes.current = result;
          forceRender();
        }
      },
      throttleTimeout
    );

    return () => unsubscribe();
  }, [fis, selector, throttleTimeout]);

  return quotes.current;
}

// Нужно для тестов
export function clearCache() {
  cache.clear();
}
