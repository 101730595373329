import { ComponentType, SVGProps } from 'react';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ClockLineMIcon } from '@alfalab/icons-glyph/ClockLineMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { FreeOrderStatuses } from '@terminal/core/lib/rest/lkFreeOrders';

export const statusIcons: Record<
  FreeOrderStatuses,
  ComponentType<SVGProps<SVGSVGElement>>
> = {
  S: ClockLineMIcon,
  P: ClockLineMIcon,
  E: CheckmarkMIcon,
  R: CrossMIcon,
};

export const ACCOUNTING_EXPENSES_THEME_ID = 36;
export const ACCOUNTING_EXPENSES_TEXT =
  'Прошу учесть фактически осуществленные и документально подтвержденные расходы, связанные с приобретением и хранением ценных бумаг, зачисленных на мой счет депо, согласно представленным документам.';

export const OVER_WITHELD_TAX_REFUND_THEME_ID = 48;
export const OVER_WITHELD_TAX_REFUND_TEXT_BROKER =
  'Прошу вернуть излишне удержанный налог в сумме #AMOUNT# за #YEAR# год на мой брокерский счет №#TREATY#, открытый в ООО «ГО ИНВЕСТ».';
export const OVER_WITHELD_TAX_REFUND_TEXT_REQUISITES =
  'Прошу вернуть излишне удержанный налог в сумме #AMOUNT# за #YEAR# год на следующие реквизиты: #REQUISITES#.';

export const TERMINATION_TAX_REFUND_THEME_ID = 49;
export const TERMINATION_TAX_REFUND_TEXT =
  'В связи с расторжением брокерского договора ГС#TREATY# прошу вернуть излишне удержанный налог в сумме #AMOUNT# за #YEAR# год. Сумму налога перечислить по следующим реквизитам: #REQUISITES#';

export const WITHOLDING_NDFL_FOR_UNCOVERED_THEME_ID = 50;
export const WITHOLDING_NDFL_FOR_UNCOVERED_TEXT =
  'Поручаю ООО «Го Инвест» произвести удержание НДФЛ за #YEAR# год из рублевых денежных средств на моем брокерском счете, полученных за счет увеличения непокрытой позиции.';
