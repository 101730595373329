import React, { useState } from 'react';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop/Component.desktop';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { CurrencyCodes } from '@alfalab/data';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import { AdditionalInstructionData } from '@terminal/core/types/additionalInstruction';
import { OrderItem } from '@terminal/core/types/order';

import { LinkedOrder } from './LinkedOrder';

interface Props extends BaseModalProps {
  idFi: number;
  isBond: boolean;
  selectedSubAccountId: number;
  priceStep?: number;
  currencyCode?: CurrencyCodes;
  onSubmit: (data: AdditionalInstructionData['data']) => void;
  onClose: () => void;
}

export const LinkedOrderModal = ({
  idFi,
  isBond,
  selectedSubAccountId,
  priceStep,
  currencyCode,
  onSubmit,
  onClose,
  ...baseModalProps
}: Props) => {
  const [linkedOrder, setLinkedOrder] = useState<OrderItem | undefined>(
    undefined
  );

  const handleSave = () => {
    onSubmit(linkedOrder);
  };

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <FunctionalModal
        backdrop={false}
        width={270}
        title="Связанная заявка"
        paddingSize="xs"
        onClose={onClose}
        drawerId="linked-order"
        footer={
          <Space direction="horizontal" size="m" align="center">
            <Button block view="ghost" size="xs" onClick={onClose}>
              <Typography.Text view="secondary-large">Отмена</Typography.Text>
            </Button>
            <Button
              disabled={!linkedOrder}
              block
              view="secondary"
              size="xs"
              onClick={handleSave}
            >
              Применить
            </Button>
          </Space>
        }
      >
        <LinkedOrder
          idFi={idFi}
          linkedOrder={linkedOrder}
          setLinkedOrder={setLinkedOrder}
          isBond={isBond}
          selectedSubAccountId={selectedSubAccountId}
          priceStep={priceStep}
          currencyCode={currencyCode}
        />
      </FunctionalModal>
    </BaseModal>
  );
};
