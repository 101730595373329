import { useCallback } from 'react';

import { TableColumnSetting } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { useWidgetContext } from '../context';

export const useSaveColumnSettings = (nodeId?: string) => {
  const { updateNode } = useWidgetContext();

  return useCallback(
    (columns: TableColumnSetting[]) => {
      if (nodeId) {
        if (
          !(
            columns[0].key === TableColumnKey.SymbolObject ||
            columns[0].key === TableColumnKey.NameObject
          )
        ) {
          return;
        }

        updateNode(nodeId, { tableProps: { columns } });
      }
    },
    [updateNode, nodeId]
  );
};
