import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getTestStatus } from '@terminal/core/lib/rest/lkTesting';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../queryKeys';

export const useTestStatus = (
  testId: number,
  sessionId: string | undefined,
  isStartSessionLoading: boolean
) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.TestStatus, sessionId],
    async () => getTestStatus(testId, sessionId),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса статуса теста',
          text: error?.message || '',
        });
      },
      enabled: Boolean(sessionId) && !isStartSessionLoading,
    }
  );
};
