import { FC } from 'react';
import { Link } from '@alfalab/core-components/link';

import {
  CertificateEnrollStep,
  useCertificateEnrollStore,
} from '@terminal/widgets/features/CertificateEnroll';

import { InfoHeading } from '../../../InfoHeading';

export const CertificateHeading: FC = () => {
  const [step, setStep, rejectingCertificate] = useCertificateEnrollStore(
    (store) => [store.step, store.setStep, store.rejectingCertificate]
  );

  return (
    <>
      {step === CertificateEnrollStep.SelectRejecting && (
        <InfoHeading
          title={
            <>
              Выпустите сертификат электронной подписи. <br />
              Но сначала нужно отозвать старый
            </>
          }
          description={
            <>
              Выпущено максимальное количество сертификатов. Отзовите ненужный,
              выпущенный <br />
              для другого браузера или устройства
            </>
          }
        />
      )}
      {step === CertificateEnrollStep.Confirm && (
        <InfoHeading
          title={
            <>
              Введите код из сообщения, чтобы
              <br />
              выпустить сертификат
            </>
          }
          description="Без него не получится торговать на бирже и работать с личным кабинетом."
        />
      )}
      {step === CertificateEnrollStep.Reject && (
        <InfoHeading
          title={
            <>
              Введите код из сообщения, <br />
              чтобы отозвать сертификат
            </>
          }
          description={
            <>
              {rejectingCertificate?.description}
              <br />
              Если хотите удалить другой сертификат,{' '}
              <Link
                pseudo
                view="secondary"
                onClick={() => setStep(CertificateEnrollStep.SelectRejecting)}
              >
                нажмите здесь
              </Link>
            </>
          }
        />
      )}
    </>
  );
};
