import { z } from 'zod';

import { FeatureFlags } from '@terminal/core/lib/featureFlags';

import { setFeatureFlags as nativeSetFeatureFlags } from '../../RemoteConfig';

import { TECH_MESSAGE_CODE } from '../model/types';

const scheme = z.object({
  /**
   * Текст тех. сообщения для рабочего стола
   */
  [TECH_MESSAGE_CODE.MAIN]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для виджетов с торговой формой (торговый приказ, стакан, график)
   */
  [TECH_MESSAGE_CODE.TRADE]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для виджетов Позиции, Балансы
   */
  [TECH_MESSAGE_CODE.POSITIONS_BALANCES]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для окна Перевод денежных средств
   */
  [TECH_MESSAGE_CODE.MONEY_TRANSFERS]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для вкладки Электронная подпись в Личном кабинете терминала
   */
  [TECH_MESSAGE_CODE.LK_ELECTRONIC_SIGNATURE]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения на экране Авторизация
   */
  [TECH_MESSAGE_CODE.AUTHORIZATION]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для окна Открытие счета
   */
  [TECH_MESSAGE_CODE.OPEN_ACCOUNT]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для Поддержки
   */
  [TECH_MESSAGE_CODE.SUPPORT]: z.coerce.string().trim(),
});

type FeatureFlagsType = z.infer<typeof scheme>;

const defaultFeatureFlags: FeatureFlagsType = {
  [TECH_MESSAGE_CODE.MAIN]: '',
  [TECH_MESSAGE_CODE.TRADE]: '',
  [TECH_MESSAGE_CODE.POSITIONS_BALANCES]: '',
  [TECH_MESSAGE_CODE.MONEY_TRANSFERS]: '',
  [TECH_MESSAGE_CODE.LK_ELECTRONIC_SIGNATURE]: '',
  [TECH_MESSAGE_CODE.AUTHORIZATION]: '',
  [TECH_MESSAGE_CODE.OPEN_ACCOUNT]: '',
  [TECH_MESSAGE_CODE.SUPPORT]: '',
};

export const featureFlags = new FeatureFlags(
  scheme,
  defaultFeatureFlags,
  '__setGoFeatureFlags'
);

export const setFeatureFlags = nativeSetFeatureFlags(featureFlags);
