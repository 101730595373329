import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { ContestPublicDigest } from '../model/types/ContestPublicDigest';

export async function getContestPublicDigest(data: {
  digestId: number;
}): Promise<ContestPublicDigest> {
  const result = await fetchLkResult<ContestPublicDigest | LKResult>(
    qs.stringifyUrl({
      url: '/api/contest/publicdigest',
      query: { ...data },
    })
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result;
}
