import React, { FC } from 'react';

import { SymbolCell as BaseSymbolCell } from '@terminal/common/features-DEPRECATED/Table';
import { useStore } from '@terminal/core/store';
import { objectByIdFiSelector } from '@terminal/core/store/selectors/core/objectByIdFiSelector';
import { TrackPrice } from '@terminal/core/types/trackPrice';

export type SymbolCellProps = {
  trackPrice: TrackPrice;
};

export const SymbolCell: FC<SymbolCellProps> = (props) => {
  const { trackPrice } = props;
  const object = useStore(objectByIdFiSelector)(trackPrice.IdFI);

  return (
    <BaseSymbolCell
      idObject={object?.idObject}
      symbolObject={object?.symbolObject}
    />
  );
};
