import { useEffect, useState } from 'react';

import { ThemeQuestion } from '../../../entities/Education';

export const useTestAnswer = (question: ThemeQuestion | undefined) => {
  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    setSelected([]);
  }, [question]);

  const setAnswer = (id: number) => {
    if (!question) {
      return;
    }

    if (question.type === 'checkbox') {
      if (selected.indexOf(id) > -1) {
        setSelected((prev) => [...prev.filter((answer) => answer !== id)]);
      } else {
        setSelected((prev) => [...prev, id]);
      }
    } else {
      setSelected([id]);
    }
  };

  return {
    selected,
    setAnswer,
  };
};
