import sumBy from 'lodash/sumBy';

import { ObjectGroup } from '../../lib/client/entities';

import { PositionItem } from '../../types/position';

/**
 * @returns Сумму стоимостей всех положительных позиций(лонгов) по ликвидным активам
 * */
export function calculateLongTorgPosLiquedRurSum(
  positions: PositionItem[]
): number {
  const futures: PositionItem[] = [];
  const otherPositions: PositionItem[] = [];

  positions.forEach((position) => {
    if (position.objectType?.idObjectGroup === ObjectGroup.Futures) {
      return futures.push(position);
    }

    if (
      position.objectType?.idObjectGroup === ObjectGroup.Currency &&
      position.torgPos > 0
    ) {
      return otherPositions.push(position);
    }

    if (
      position.marginDiscountBuyD0 > 0 &&
      position.marginDiscountBuyD0 < 1 &&
      position.torgPos > 0
    ) {
      return otherPositions.push(position);
    }
  });

  return (
    sumBy(futures, 'dailyPLtoMarketCurPrice') +
    sumBy(otherPositions, 'torgPosCostRur')
  );
}
