import { CurrencyCodes } from '@alfalab/core-components/amount';

import { ObjectGroup } from '../lib/client/entities';

/**
 * номера FI валют в массиве
 */
export const currenciesFI = [149262, 149270, 169639, 216627, 1963534, 1960389];

export const FIModalGroups = [
  {
    name: 'Все инструменты',
    idObjectGroup: -1,
  },
  {
    name: 'Акции',
    idObjectGroup: [1, 6],
  },
  {
    name: 'Валюты',
    idObjectGroup: 8,
  },
  {
    name: 'Фьючерсы',
    idObjectGroup: 4,
  },
  {
    name: 'Облигации',
    idObjectGroup: 2,
  },
  {
    name: 'Фонды',
    idObjectGroup: 3,
  },
  {
    name: 'Индексы',
    idObjectGroup: 7,
  },
  {
    name: 'Металлы',
    idObjectGroup: ObjectGroup.Metals,
  },
];

export const defaultFIs = [
  {
    idObject: 414100,
    idFI: 218182,
    idMarketBoard: 119,
    idMarketType: 10,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'SPFPA',
    nameMarketBoard: 'СПБ',
    descMargetBoard: 'Основной режим торгов T+',
    placeCode: 'SPBEX_FSHR',
    universalMarketCode: 'SPBEX_USD',
    rCode: 'SPBEX',
    idObjectCurrency: 174366,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174366,
    idObjectBase: 413937,
    nameObject: 'Apple',
    symbolObject: 'AAPL',
    descObject: 'Акции обыкновенные Apple Inc., Рег№ US0378331005',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0.00001,
    isin: 'US0378331005',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 439981,
    idFI: 218190,
    idMarketBoard: 119,
    idMarketType: 10,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'SPFPA',
    nameMarketBoard: 'СПБ',
    descMargetBoard: 'Основной режим торгов T+',
    placeCode: 'SPBEX_FSHR',
    universalMarketCode: 'SPBEX_USD',
    rCode: 'SPBEX',
    idObjectCurrency: 174366,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174366,
    idObjectBase: 439980,
    nameObject: 'Bank of America Corporation',
    symbolObject: 'BAC',
    descObject:
      'Акции обыкновенные Bank of America Corporation, Рег№ US0605051046',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0.01,
    isin: 'US0605051046',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 174422,
    idFI: 146986,
    idMarketBoard: 92,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQxx',
    nameMarketBoard: 'МБ ЦК',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+2)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174368,
    idObjectBase: 283659,
    nameObject: 'ГАЗПРОМ',
    symbolObject: 'GAZP',
    descObject: 'Акции обыкновенные Gazprom, Рег№ 1-02-00028-A',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 5,
    isin: 'RU0007661625',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 174425,
    idFI: 144942,
    idMarketBoard: 92,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQxx',
    nameMarketBoard: 'МБ ЦК',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+2)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174368,
    idObjectBase: 174373,
    nameObject: 'ЛУКОЙЛ',
    symbolObject: 'LKOH',
    descObject: 'Акции обыкновенные LUKOIL, Рег№ 1-01-00077-A',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0.025,
    isin: 'RU0009024277',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 385612,
    idFI: 145164,
    idMarketBoard: 92,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQxx',
    nameMarketBoard: 'МБ ЦК',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+2)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174368,
    idObjectBase: 385610,
    nameObject: 'Московская Биржа',
    symbolObject: 'MOEX',
    descObject: 'Акции обыкновенные ПАО Московская Биржа, Рег№ 1-05-08443-H',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 1,
    isin: 'RU000A0JR4A1',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 441269,
    idFI: 218307,
    idMarketBoard: 119,
    idMarketType: 10,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'SPFPA',
    nameMarketBoard: 'СПБ',
    descMargetBoard: 'Основной режим торгов T+',
    placeCode: 'SPBEX_FSHR',
    universalMarketCode: 'SPBEX_USD',
    rCode: 'SPBEX',
    idObjectCurrency: 174366,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174366,
    idObjectBase: 441268,
    nameObject: 'Netflix',
    symbolObject: 'NFLX',
    descObject: 'Акции обыкновенные Netflix, Inc., Рег№ US64110L1061',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0.001,
    isin: 'US64110L1061',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 414116,
    idFI: 229163,
    idMarketBoard: 119,
    idMarketType: 10,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'SPFPA',
    nameMarketBoard: 'СПБ',
    descMargetBoard: 'Основной режим торгов T+',
    placeCode: 'SPBEX_FSHR',
    universalMarketCode: 'SPBEX_USD',
    rCode: 'SPBEX',
    idObjectCurrency: 174366,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174366,
    idObjectBase: 414046,
    nameObject: 'NVIDIA Corporation',
    symbolObject: 'NVDA',
    descObject: 'Акции обыкновенные NVIDIA CORPORATION, Рег№ US67066G1040',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0.001,
    isin: 'US67066G1040',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 174434,
    idFI: 146989,
    idMarketBoard: 92,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQxx',
    nameMarketBoard: 'МБ ЦК',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+2)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174368,
    idObjectBase: 174378,
    nameObject: 'Роснефть',
    symbolObject: 'ROSN',
    descObject: 'Акции обыкновенные ПАО "НК "Роснефть", Рег№ 1-02-00122-A',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0.01,
    isin: 'RU000A0J2Q06',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 285270,
    idFI: 144950,
    idMarketBoard: 92,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQxx',
    nameMarketBoard: 'МБ ЦК',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+2)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174368,
    idObjectBase: 284396,
    nameObject: 'Сбербанк',
    symbolObject: 'SBER',
    descObject: 'Акции обыкновенные ПАО Сбербанк, Рег№ 10301481B',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 3,
    isin: 'RU0009029540',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 441283,
    idFI: 224425,
    idMarketBoard: 119,
    idMarketType: 10,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'SPFPA',
    nameMarketBoard: 'СПБ',
    descMargetBoard: 'Основной режим торгов T+',
    placeCode: 'SPBEX_FSHR',
    universalMarketCode: 'SPBEX_USD',
    rCode: 'SPBEX',
    idObjectCurrency: 174366,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174366,
    idObjectBase: 441282,
    nameObject: 'Tesla',
    symbolObject: 'TSLA',
    descObject: 'Акции обыкновенные Tesla, Inc., Рег№ US88160R1014',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0.001,
    isin: 'US88160R1014',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 414276,
    idFI: 218251,
    idMarketBoard: 119,
    idMarketType: 10,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'SPFPA',
    nameMarketBoard: 'СПБ',
    descMargetBoard: 'Основной режим торгов T+',
    placeCode: 'SPBEX_FSHR',
    universalMarketCode: 'SPBEX_USD',
    rCode: 'SPBEX',
    idObjectCurrency: 174366,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174366,
    idObjectBase: 413978,
    nameObject: 'Exxon Mobil Corporation',
    symbolObject: 'XOM',
    descObject: 'Акции обыкновенные EXXON MOBIL CORPORATION, Рег№ US30231G1022',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    isin: 'US30231G1022',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 400406,
    idFI: 162016,
    idMarketBoard: 92,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQxx',
    nameMarketBoard: 'МБ ЦК',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+2)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectType: 1,
    idObjectGroup: 1,
    codeObjectType: 'AO',
    nameObjectType: 'Акции обыкновенные',
    shortNameObjectType: 'обыкновенные',
    idObjectFaceUnit: 174367,
    idObjectBase: 662502,
    nameObject: 'Яндекс',
    symbolObject: 'YNDX',
    descObject: 'Акции обыкновенные Yandex N.V., Рег№ NL0009805522',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0.01,
    isin: 'NL0009805522',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 174367,
    idFI: 149270,
    idMarketBoard: 114,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'CETS_TOM',
    nameMarketBoard: 'ВР TOM',
    descMargetBoard: 'Валютный рынок с расчетами завтра',
    placeCode: 'MICEX_SELT_TOM',
    universalMarketCode: 'MOEX_SELT_TOM_RUB',
    rCode: 'SELT',
    idObjectCurrency: 174368,
    idObjectType: 27,
    idObjectGroup: 8,
    codeObjectType: 'CC',
    nameObjectType: 'Валюта',
    shortNameObjectType: 'наличная',
    idObjectFaceUnit: 0,
    idObjectBase: 541565,
    nameObject: 'EUR',
    symbolObject: 'EUR',
    descObject: 'EUR, Рег№ 978',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 1,
    isin: 'EUR',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 174366,
    idFI: 149262,
    idMarketBoard: 114,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'CETS_TOM',
    nameMarketBoard: 'ВР TOM',
    descMargetBoard: 'Валютный рынок с расчетами завтра',
    placeCode: 'MICEX_SELT_TOM',
    universalMarketCode: 'MOEX_SELT_TOM_RUB',
    rCode: 'SELT',
    idObjectCurrency: 174368,
    idObjectType: 27,
    idObjectGroup: 8,
    codeObjectType: 'CC',
    nameObjectType: 'Валюта',
    shortNameObjectType: 'наличная',
    idObjectFaceUnit: 0,
    idObjectBase: 541563,
    nameObject: 'USD',
    symbolObject: 'USD',
    descObject: 'USD, Рег№ 840',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 1,
    isin: 'USD',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idFI: 706219,
    idObject: 622479,
    idObjectFaceUnit: 174366,
    idObjectBase: 174412,
    idObjectType: 17,
    nameObject: 'BR-5.22',
    symbolObject: 'BRK2',
    descObject: 'Расчетный фьючерс с исполнением 03.05.2022 на Нефть  BRENT',
    matDateObject: new Date('2022-05-02T21:00:00.000Z'),
    nominal: 0,
    isin: 'BR-5.22',
    idMarketBoard: 56,
    idMarketType: 5,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'RTFU',
    nameMarketBoard: 'ФОРТС',
    descMargetBoard: 'ФОРТС Фьючерсы – все режимы торгов',
    placeCode: 'FORTS',
    universalMarketCode: 'MOEX_FOM_FUT',
    rCode: 'FORTS',
    idObjectCurrency: 174368,
    idObjectGroup: 4,
    codeObjectType: 'FR',
    nameObjectType: 'Расчетный фьючерс',
    shortNameObjectType: 'расчетный',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idFI: 694027,
    idObject: 611448,
    idObjectFaceUnit: 174366,
    idObjectBase: 174388,
    idObjectType: 17,
    nameObject: 'GOLD-3.22',
    symbolObject: 'GDH2',
    descObject:
      'Расчетный фьючерс с исполнением 17.03.2022 на Золото в слитках',
    matDateObject: new Date('2022-03-16T21:00:00.000Z'),
    nominal: 0,
    isin: 'GOLD-3.22',
    idMarketBoard: 56,
    idMarketType: 5,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'RTFU',
    nameMarketBoard: 'ФОРТС',
    descMargetBoard: 'ФОРТС Фьючерсы – все режимы торгов',
    placeCode: 'FORTS',
    universalMarketCode: 'MOEX_FOM_FUT',
    rCode: 'FORTS',
    idObjectCurrency: 174368,
    idObjectGroup: 4,
    codeObjectType: 'FR',
    nameObjectType: 'Расчетный фьючерс',
    shortNameObjectType: 'расчетный',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idFI: 712778,
    idObject: 627412,
    idObjectFaceUnit: 174366,
    idObjectBase: 174388,
    idObjectType: 17,
    nameObject: 'GOLD-6.22',
    symbolObject: 'GDM2',
    descObject:
      'Расчетный фьючерс с исполнением 16.06.2022 на Золото в слитках',
    matDateObject: new Date('2022-06-15T21:00:00.000Z'),
    nominal: 0,
    isin: 'GOLD-6.22',
    idMarketBoard: 56,
    idMarketType: 5,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'RTFU',
    nameMarketBoard: 'ФОРТС',
    descMargetBoard: 'ФОРТС Фьючерсы – все режимы торгов',
    placeCode: 'FORTS',
    universalMarketCode: 'MOEX_FOM_FUT',
    rCode: 'FORTS',
    idObjectCurrency: 174368,
    idObjectGroup: 4,
    codeObjectType: 'FR',
    nameObjectType: 'Расчетный фьючерс',
    shortNameObjectType: 'расчетный',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idFI: 736019,
    idObject: 646953,
    idObjectFaceUnit: 174366,
    idObjectBase: 550672,
    idObjectType: 17,
    nameObject: 'NG-3.22',
    symbolObject: 'NGH2',
    descObject: 'Расчетный фьючерс с исполнением 29.03.2022 на Природный газ',
    matDateObject: new Date('2022-03-28T21:00:00.000Z'),
    nominal: 0,
    isin: 'NG-3.22',
    idMarketBoard: 56,
    idMarketType: 5,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'RTFU',
    nameMarketBoard: 'ФОРТС',
    descMargetBoard: 'ФОРТС Фьючерсы – все режимы торгов',
    placeCode: 'FORTS',
    universalMarketCode: 'MOEX_FOM_FUT',
    rCode: 'FORTS',
    idObjectCurrency: 174368,
    idObjectGroup: 4,
    codeObjectType: 'FR',
    nameObjectType: 'Расчетный фьючерс',
    shortNameObjectType: 'расчетный',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idFI: 750402,
    idObject: 652262,
    idObjectFaceUnit: 174366,
    idObjectBase: 550672,
    idObjectType: 17,
    nameObject: 'NG-4.22',
    symbolObject: 'NGJ2',
    descObject: 'Расчетный фьючерс с исполнением 27.04.2022 на Природный газ',
    matDateObject: new Date('2022-04-26T21:00:00.000Z'),
    nominal: 0,
    isin: 'NG-4.22',
    idMarketBoard: 56,
    idMarketType: 5,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'RTFU',
    nameMarketBoard: 'ФОРТС',
    descMargetBoard: 'ФОРТС Фьючерсы – все режимы торгов',
    placeCode: 'FORTS',
    universalMarketCode: 'MOEX_FOM_FUT',
    rCode: 'FORTS',
    idObjectCurrency: 174368,
    idObjectGroup: 4,
    codeObjectType: 'FR',
    nameObjectType: 'Расчетный фьючерс',
    shortNameObjectType: 'расчетный',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 558436,
    idFI: 621130,
    idMarketBoard: 56,
    idMarketType: 5,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'RTFU',
    nameMarketBoard: 'ФОРТС',
    descMargetBoard: 'ФОРТС Фьючерсы – все режимы торгов',
    placeCode: 'FORTS',
    universalMarketCode: 'MOEX_FOM_FUT',
    rCode: 'FORTS',
    idObjectCurrency: 174368,
    idObjectType: 17,
    idObjectGroup: 4,
    codeObjectType: 'FR',
    nameObjectType: 'Расчетный фьючерс',
    shortNameObjectType: 'расчетный',
    idObjectFaceUnit: 282886,
    idObjectBase: 174435,
    nameObject: 'RTS-3.22',
    symbolObject: 'RIH2',
    descObject: 'Расчетный фьючерс с исполнением 17.03.2022 на индекс РТС',
    matDateObject: new Date('2022-03-16T21:00:00.000Z'),
    nominal: 0,
    isin: 'RTS-3.22',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idObject: 558434,
    idFI: 621128,
    idMarketBoard: 56,
    idMarketType: 5,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'RTFU',
    nameMarketBoard: 'ФОРТС',
    descMargetBoard: 'ФОРТС Фьючерсы – все режимы торгов',
    placeCode: 'FORTS',
    universalMarketCode: 'MOEX_FOM_FUT',
    rCode: 'FORTS',
    idObjectCurrency: 174368,
    idObjectType: 17,
    idObjectGroup: 4,
    codeObjectType: 'FR',
    nameObjectType: 'Расчетный фьючерс',
    shortNameObjectType: 'расчетный',
    idObjectFaceUnit: 174368,
    idObjectBase: 174438,
    nameObject: 'Si-3.22',
    symbolObject: 'SiH2',
    descObject:
      'Расчетный фьючерс с исполнением 17.03.2022 на Курс USD/RUR (доллар рубль)',
    matDateObject: new Date('2022-03-16T21:00:00.000Z'),
    nominal: 0,
    isin: 'Si-3.22',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idFI: 694030,
    idObject: 611451,
    idObjectFaceUnit: 174366,
    idObjectBase: 174407,
    idObjectType: 17,
    nameObject: 'SILV-3.22',
    symbolObject: 'SVH2',
    descObject:
      'Расчетный фьючерс с исполнением 17.03.2022 на Серебро в слитках',
    matDateObject: new Date('2022-03-16T21:00:00.000Z'),
    nominal: 0,
    isin: 'SILV-3.22',
    idMarketBoard: 56,
    idMarketType: 5,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'RTFU',
    nameMarketBoard: 'ФОРТС',
    descMargetBoard: 'ФОРТС Фьючерсы – все режимы торгов',
    placeCode: 'FORTS',
    universalMarketCode: 'MOEX_FOM_FUT',
    rCode: 'FORTS',
    idObjectCurrency: 174368,
    idObjectGroup: 4,
    codeObjectType: 'FR',
    nameObjectType: 'Расчетный фьючерс',
    shortNameObjectType: 'расчетный',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idFI: 712781,
    idObject: 627415,
    idObjectFaceUnit: 174366,
    idObjectBase: 174407,
    idObjectType: 17,
    nameObject: 'SILV-6.22',
    symbolObject: 'SVM2',
    descObject:
      'Расчетный фьючерс с исполнением 16.06.2022 на Серебро в слитках',
    matDateObject: new Date('2022-06-15T21:00:00.000Z'),
    nominal: 0,
    isin: 'SILV-6.22',
    idMarketBoard: 56,
    idMarketType: 5,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'RTFU',
    nameMarketBoard: 'ФОРТС',
    descMargetBoard: 'ФОРТС Фьючерсы – все режимы торгов',
    placeCode: 'FORTS',
    universalMarketCode: 'MOEX_FOM_FUT',
    rCode: 'FORTS',
    idObjectCurrency: 174368,
    idObjectGroup: 4,
    codeObjectType: 'FR',
    nameObjectType: 'Расчетный фьючерс',
    shortNameObjectType: 'расчетный',
    currency: 'RUB' as CurrencyCodes,
  },
  {
    idFI: 611255,
    idObject: 549645,
    idObjectFaceUnit: 174368,
    idObjectBase: 295404,
    idObjectType: 7,
    nameObject: 'ОФЗ 24021',
    symbolObject: 'OFZ PD 24021',
    descObject:
      'Облигации федерального займа Российская Федерация с погашением 24.04.24, Рег.№ 24021RMFS',
    matDateObject: new Date('2024-04-23T21:00:00.000Z'),
    nominal: 1000,
    idMarketBoard: 150,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQXXT1',
    nameMarketBoard: 'МБ ЦК T+1',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+1)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ1_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectGroup: 2,
    codeObjectType: 'BG',
    nameObjectType: 'Федеральные облигации',
    shortNameObjectType: 'федеральные',
    isin: 'RU000A101CK7',
  },
  {
    idFI: 147047,
    idObject: 295867,
    idObjectFaceUnit: 174368,
    idObjectBase: 295404,
    idObjectType: 7,
    nameObject: 'ОФЗ 46022',
    symbolObject: 'OFZ-AD 46022',
    descObject:
      'Облигации федерального займа Российская Федерация с погашением 19.07.23, Рег.№ 46022RMFS',
    matDateObject: new Date('2023-07-18T21:00:00.000Z'),
    nominal: 1000,
    idMarketBoard: 150,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQXXT1',
    nameMarketBoard: 'МБ ЦК T+1',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+1)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ1_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectGroup: 2,
    codeObjectType: 'BG',
    nameObjectType: 'Федеральные облигации',
    shortNameObjectType: 'федеральные',
    isin: 'RU000A0JPLH5',
  },
  {
    idFI: 615724,
    idObject: 553528,
    idObjectFaceUnit: 174368,
    idObjectBase: 295404,
    idObjectType: 7,
    nameObject: 'ОФЗ 25084',
    symbolObject: 'OFZ-PD 25084',
    descObject:
      'Облигации федерального займа Российская Федерация с погашением 04.10.23, Рег.№ 25084RMFS',
    matDateObject: new Date('2023-10-03T21:00:00.000Z'),
    nominal: 1000,
    idMarketBoard: 150,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQXXT1',
    nameMarketBoard: 'МБ ЦК T+1',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+1)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ1_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectGroup: 2,
    codeObjectType: 'BG',
    nameObjectType: 'Федеральные облигации',
    shortNameObjectType: 'федеральные',
    isin: 'RU000A101FA1',
  },
  {
    idFI: 265114,
    idObject: 479554,
    idObjectFaceUnit: 174368,
    idObjectBase: 295404,
    idObjectType: 7,
    nameObject: 'ОФЗ 26223',
    symbolObject: 'OFZ-PD 26223',
    descObject:
      'Облигации федерального займа Российская Федерация с погашением 28.02.24, Рег.№ 26223RMFS',
    matDateObject: new Date('2024-02-27T21:00:00.000Z'),
    nominal: 1000,
    idMarketBoard: 150,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQXXT1',
    nameMarketBoard: 'МБ ЦК T+1',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+1)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ1_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectGroup: 2,
    codeObjectType: 'BG',
    nameObjectType: 'Федеральные облигации',
    shortNameObjectType: 'федеральные',
    isin: 'RU000A0ZYU88',
  },
  {
    idFI: 686011,
    idObject: 604489,
    idObjectFaceUnit: 174368,
    idObjectBase: 517529,
    idObjectType: 61,
    nameObject: 'Управляемые Российские Акции Альфа-Капитал',
    symbolObject: 'AKME',
    descObject:
      'Управляемые Российские Акции Альфа-Капитал под управлением ООО УК Альфа-Капитал, ISIN RU000A102E78',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    idMarketBoard: 92,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQxx',
    nameMarketBoard: 'МБ ЦК',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+2)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectGroup: 3,
    codeObjectType: 'PB',
    nameObjectType: 'Паи',
    shortNameObjectType: 'биржевой',
    isin: 'RU000A102E78',
  },
  {
    idFI: 572503,
    idObject: 517530,
    idObjectFaceUnit: 174366,
    idObjectBase: 517529,
    idObjectType: 61,
    nameObject: 'Технологии 100 Альфа-Капитал',
    symbolObject: 'AKNX',
    descObject:
      'Технологии 100 Альфа-Капитал под управлением ООО УК Альфа-Капитал, ISIN RU000A0ZZTQ4',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    idMarketBoard: 139,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQusd',
    nameMarketBoard: 'МБ ЦК USD',
    descMargetBoard:
      'МБ (Основной режим анонимных торгов на условиях Т+2 с расчетами в USD)',
    placeCode: 'MICEX_SHR_T_USD',
    universalMarketCode: 'MOEX_TQ_USD',
    rCode: 'MICEX',
    idObjectCurrency: 174366,
    idObjectGroup: 3,
    codeObjectType: 'PB',
    nameObjectType: 'Паи',
    shortNameObjectType: 'биржевой',
    isin: 'RU000A0ZZTQ4',
  },
  {
    idFI: 572517,
    idObject: 517531,
    idObjectFaceUnit: 174366,
    idObjectBase: 517529,
    idObjectType: 61,
    nameObject: 'S&P 500 Альфа-Капитал',
    symbolObject: 'AKSP',
    descObject:
      'S&P 500 Альфа-Капитал под управлением ООО УК Альфа-Капитал, ISIN RU000A1006V3',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    idMarketBoard: 139,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQusd',
    nameMarketBoard: 'МБ ЦК USD',
    descMargetBoard:
      'МБ (Основной режим анонимных торгов на условиях Т+2 с расчетами в USD)',
    placeCode: 'MICEX_SHR_T_USD',
    universalMarketCode: 'MOEX_TQ_USD',
    rCode: 'MICEX',
    idObjectCurrency: 174366,
    idObjectGroup: 3,
    codeObjectType: 'PB',
    nameObjectType: 'Паи',
    shortNameObjectType: 'биржевой',
    isin: 'RU000A1006V3',
  },
  {
    idFI: 197984,
    idObject: 424322,
    idObjectFaceUnit: 174368,
    idObjectBase: 505212,
    idObjectType: 61,
    nameObject: 'FinEx MSCI CHINA UCITS ETF п.',
    symbolObject: 'FXCN',
    descObject:
      'FinEx MSCI CHINA UCITS ETF п. под управлением FinEx Investment Management LLP, ISIN IE00BD3QFB18',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    idMarketBoard: 92,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQxx',
    nameMarketBoard: 'МБ ЦК',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+2)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectGroup: 3,
    codeObjectType: 'PB',
    nameObjectType: 'Паи',
    shortNameObjectType: 'биржевой',
    isin: 'IE00BD3QFB18',
  },
  {
    idFI: 197492,
    idObject: 424341,
    idObjectFaceUnit: 174366,
    idObjectBase: 505212,
    idObjectType: 61,
    nameObject: 'FinEx MSCI USA IT UCITS ETF п.',
    symbolObject: 'FXIT',
    descObject:
      'FinEx MSCI USA IT UCITS ETF п. под управлением FinEx Investment Management LLP, ISIN IE00BD3QJ757',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    idMarketBoard: 92,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQxx',
    nameMarketBoard: 'МБ ЦК',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+2)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectGroup: 3,
    codeObjectType: 'PB',
    nameObjectType: 'Паи',
    shortNameObjectType: 'биржевой',
    isin: 'IE00BD3QJ757',
  },
  {
    idFI: 197498,
    idObject: 424314,
    idObjectFaceUnit: 378207,
    idObjectBase: 505212,
    idObjectType: 61,
    nameObject: 'FinEx MSCI USA UCITF ETF п.',
    symbolObject: 'FXUS',
    descObject:
      'FinEx MSCI USA UCITF ETF п. под управлением FinEx Investment Management LLP, ISIN IE00BD3QHZ91',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    idMarketBoard: 92,
    idMarketType: 2,
    idBoardType: {
      value: 2,
      name: 'Основной торговый режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'TQxx',
    nameMarketBoard: 'МБ ЦК',
    descMargetBoard: 'МБ (Основной режим анонимных торгов на условиях Т+2)',
    placeCode: 'MICEX_SHR_T',
    universalMarketCode: 'MOEX_TQ_RUB',
    rCode: 'MICEX',
    idObjectCurrency: 174368,
    idObjectGroup: 3,
    codeObjectType: 'PB',
    nameObjectType: 'Паи',
    shortNameObjectType: 'биржевой',
    isin: 'IE00BD3QHZ91',
  },
  {
    idFI: 108897,
    idObject: 290876,
    idObjectFaceUnit: 282886,
    idObjectBase: 0,
    idObjectType: 24,
    nameObject: 'Индекс МосБиржи',
    symbolObject: 'IMOEX',
    descObject: 'Индекс МосБиржи',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    idMarketBoard: 11,
    idMarketType: 2,
    idBoardType: {
      value: 1,
      name: 'Информационный режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'MICI',
    nameMarketBoard: 'ММВБ Индексы',
    descMargetBoard: 'МБ (Индексы)',
    universalMarketCode: 'MOEX_INDICES',
    idObjectCurrency: 0,
    idObjectGroup: 7,
    codeObjectType: 'IF',
    nameObjectType: 'Индекс',
    shortNameObjectType: 'фондовый',
    isin: 'RU000A0JP7K5',
  },
  {
    idFI: 229047,
    idObject: 451967,
    idObjectFaceUnit: 282886,
    idObjectBase: 0,
    idObjectType: 24,
    nameObject: 'Индекс гос.обл. ММВБ',
    symbolObject: 'RGBI',
    descObject: 'Индекс гос.обл. ММВБ',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    idMarketBoard: 11,
    idMarketType: 2,
    idBoardType: {
      value: 1,
      name: 'Информационный режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'MICI',
    nameMarketBoard: 'ММВБ Индексы',
    descMargetBoard: 'МБ (Индексы)',
    universalMarketCode: 'MOEX_INDICES',
    idObjectCurrency: 0,
    idObjectGroup: 7,
    codeObjectType: 'IF',
    nameObjectType: 'Индекс',
    shortNameObjectType: 'фондовый',
    isin: 'RU000A0JQV61',
  },
  {
    idFI: 2236,
    idObject: 174435,
    idObjectFaceUnit: 282886,
    idObjectBase: 0,
    idObjectType: 24,
    nameObject: 'индекс РТС',
    symbolObject: 'RTS',
    descObject: 'индекс РТС',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    idMarketBoard: 55,
    idMarketType: 5,
    idBoardType: {
      value: 1,
      name: 'Информационный режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'RTIN',
    nameMarketBoard: 'ФОРТС индексы',
    descMargetBoard: 'ФОРТС Индексы',
    placeCode: 'FORTS',
    universalMarketCode: 'FORTS_INDICES',
    idObjectCurrency: 282886,
    idObjectGroup: 7,
    codeObjectType: 'IF',
    nameObjectType: 'Индекс',
    shortNameObjectType: 'фондовый',
    isin: 'RU000A0JPEB3',
  },
  {
    idFI: 108878,
    idObject: 290856,
    idObjectFaceUnit: 282886,
    idObjectBase: 0,
    idObjectType: 24,
    nameObject: 'Индекс ММВБ10',
    symbolObject: 'ММВБ10    ',
    descObject: 'Индекс ММВБ10',
    matDateObject: new Date('2099-12-31T21:00:00.000Z'),
    nominal: 0,
    idMarketBoard: 11,
    idMarketType: 2,
    idBoardType: {
      value: 1,
      name: 'Информационный режим',
    },
    type: {
      value: 0,
      name: 'основной режим торгов, анонимные торги, биржевые торги',
    },
    codeMarketBoard: 'MICI',
    nameMarketBoard: 'ММВБ Индексы',
    descMargetBoard: 'МБ (Индексы)',
    universalMarketCode: 'MOEX_INDICES',
    idObjectCurrency: 0,
    idObjectGroup: 7,
    codeObjectType: 'IF',
    nameObjectType: 'Индекс',
    shortNameObjectType: 'фондовый',
    isin: 'RU0006216587',
  },
];
