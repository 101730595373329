import cn from 'classnames';
import React, { useCallback, useRef } from 'react';
import { Button } from '@alfalab/core-components/button';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { ChevronUpMIcon } from '@alfalab/icons-glyph/ChevronUpMIcon';

import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';

import { Dropdown } from '../../components/Dropdown';

import styles from './ListPicker.module.css';

export type ListPickerProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  isCloseOnDropDownClick?: boolean;
  onPickerClick?: (isOpen: boolean) => void;
  onCloseDropdown?: () => void;
  leftAddons?: React.ReactNode;
  dropDownHeader?: React.ReactNode;
  children?: React.ReactNode;
  popperClassName?: string;
  wrapperClassName?: string;
  buttonClassName?: string;
};

export const ListPicker = ({
  isOpen,
  setIsOpen,
  title,
  isCloseOnDropDownClick,
  onPickerClick,
  leftAddons,
  dropDownHeader,
  children,
  popperClassName,
  wrapperClassName,
  buttonClassName,
  onCloseDropdown,
}: ListPickerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(isCloseOnDropDownClick ? ref : [ref, dropdownRef], () => {
    setIsOpen(false);
    onCloseDropdown?.();
  });

  const handlePickerClick = useCallback(() => {
    const newValue = !isOpen;

    onPickerClick?.(newValue);

    if (!newValue) {
      onCloseDropdown?.();
    }

    setIsOpen(newValue);
  }, [isOpen, onPickerClick, onCloseDropdown, setIsOpen]);

  return (
    <div ref={ref} className={cn(styles.listPickerWrapper, wrapperClassName)}>
      <Button
        size="xxs"
        className={cn(styles.listPicker, buttonClassName)}
        leftAddons={leftAddons}
        rightAddons={
          isOpen ? (
            <ChevronUpMIcon
              height={16}
              width={16}
              color="var(--color-light-graphic-secondary)"
              className={styles.chevron}
            />
          ) : (
            <ChevronDownMIcon
              height={16}
              width={16}
              color="var(--color-light-graphic-secondary)"
              className={styles.chevron}
            />
          )
        }
        view="secondary"
        onClick={handlePickerClick}
      >
        {title}
      </Button>

      <Dropdown
        ref={dropdownRef}
        anchorElement={ref.current}
        open={isOpen}
        offset={[0, 2]}
        position="bottom-start"
        header={dropDownHeader}
        popperClassName={popperClassName}
      >
        {children}
      </Dropdown>
    </div>
  );
};
