import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';

import styles from './MarginBanner.module.css';

interface MarginBannerProps {
  title: string;
  text?: string;
  btnLabel: string;
  btnIcon?: React.ReactNode;
  onClick?: () => void;
}

export const MarginBanner = ({
  title,
  text,
  btnLabel,
  btnIcon,
  onClick,
}: MarginBannerProps) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.content}>
        <Typography.Text
          className={styles.title}
          view="secondary-large"
          weight="bold"
          tag="div"
        >
          {title}
        </Typography.Text>
        {text && (
          <Typography.Text
            className={styles.text}
            view="secondary-large"
            color="secondary"
            tag="div"
          >
            {text}
          </Typography.Text>
        )}
      </div>
      <div className={styles.action}>
        <ButtonDesktop block size="s" leftAddons={btnIcon}>
          {btnLabel}
        </ButtonDesktop>
      </div>
    </div>
  );
};
