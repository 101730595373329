import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';

import { useRequirement } from '@terminal/alfadirect/hooks';
import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';

export const useWidgetRequirement = () => {
  const { subAccountRazdels } = useAlfaDirectContext();

  const subAccounts = useMemo(
    () =>
      Object.keys(
        groupBy(
          subAccountRazdels.filter((item) => item),
          'codeSubAccount'
        )
      ),
    [subAccountRazdels]
  );

  return useRequirement(subAccounts);
};
