import { ObjectGroup, RazdelType } from '../../lib/client/entities';

import { SubAccountPositionItem } from '../../types/subAccountPosition';

/**
 * @returns текущую позицию в портфеле
 * @param positionItem элемент в портфеле
 * @see https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=503369004
 * */
export function getTorgPos(
  positionItem: SubAccountPositionItem,
  BackPos: number,
  idObjectGroup?: number,
  idRazdelType?: number
): number {
  const { trnIn, trnOut, sessionBuyQty, sessionSellQty } = positionItem;

  if (
    idObjectGroup === ObjectGroup.Currency &&
    idRazdelType === RazdelType.Derivatives
  ) {
    return BackPos + trnIn - trnOut;
  } else {
    return BackPos + sessionBuyQty - sessionSellQty + trnIn - trnOut;
  }
}
