import log from 'loglevel';

import { LIMIT_SERVICE_UNAVAILABLE_KEY } from '../../constants/balance';
import { alfaDirectClient } from '../client/client';
import {
  ClientMessageEntity,
  FrontEndType,
  Messages,
  MessageUnitedType,
  OrderDirection,
  OrderType,
  TradeLimitRequestEntity,
  TradeLimitResponseEntity,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import { getId } from './id';

import { TradeLimitsResult } from '../../types/tradeLimits';
import { OrderRequest } from '../../types/trading';

const setLimitServiceUnavailable = () => {
  sessionStorage.setItem(LIMIT_SERVICE_UNAVAILABLE_KEY, 'true');
  setTimeout(
    () => sessionStorage.removeItem(LIMIT_SERVICE_UNAVAILABLE_KEY),
    30000
  );
};

const LIMIT_REQUEST_TIMEOUT = 20000;

// quantity количество доступное для покупки/продажи с учетом марижнальной торговли
// если маржиналки нет, то будет равно qunatityForOwnAssets

// Возвращает инфу кол доступном пользователю количеству и стоимости
// операций с выбранным фининструментом
// Для маркетных заявок нужно цену 0 передавать
export async function checkTradeLimits(
  buy: boolean,
  price: number,
  params: OrderRequest
): Promise<TradeLimitsResult> {
  // Цена может не заполняться только для маркетной заявки
  if (price === 0 && params.idOrderType !== OrderType.MKT) {
    throw RangeError('Цена для проверки лимита не может быть 0');
  }

  log.debug(
    `Запрос лимитов по инструменту ${params.fullFI.idFI} для цены ${price} с параметрами ${params}`
  );

  return new Promise((resolve, reject) => {
    const idRequest = getId();
    let promiseSettled = false;

    const onTradeLimitResponse = (message: MessageUnitedType) => {
      const messages = message.data as TradeLimitResponseEntity[];

      messages.forEach((message) => {
        if (message.IdRequest === idRequest) {
          // нашли наш ответ по IdRequest
          cleanUp();
          resolve({
            quantity: message.Quantity,
            quantityForOwnAssets: message.QuantityForOwnAssets,
            amount: message.Amount,
            freeMoney: message.FreeMoney,
          });
        }
      });
    };

    const onClientMessage = (message: MessageUnitedType) => {
      const messages = message.data as ClientMessageEntity[];

      messages.forEach((m) => {
        if (m.MessageId === Messages.LimitRequestFailed) {
          cleanUp();
          setLimitServiceUnavailable();
          reject('Ошибка запроса торговых лимитов.');
        }

        if (
          m.MessageId === Messages.LimitRequestRejected_NoConnectedLimitServices
        ) {
          cleanUp();
          setLimitServiceUnavailable();
          reject('Ошибка. Нет доступных лимит сервисов');
        }
      });
    };

    const cleanUp = () => {
      promiseSettled = true;
      alfaDirectClient.removeListener(
        EntityType.TradeLimitResponseEntity,
        onTradeLimitResponse
      );
      alfaDirectClient.removeListener(
        EntityType.ClientMessageEntity,
        onClientMessage
      );
    };

    setTimeout(() => {
      if (!promiseSettled) {
        promiseSettled = true;
        cleanUp();
        setLimitServiceUnavailable();
        reject('Превышено время ожидания запроса доступных лимитов');
      }
    }, LIMIT_REQUEST_TIMEOUT);

    const tradeLimitRequest = new TradeLimitRequestEntity();

    tradeLimitRequest.BuySell = buy ? OrderDirection.Buy : OrderDirection.Sell;
    tradeLimitRequest.IdAccount = params.idAccount;
    tradeLimitRequest.IdRazdel = params.razdel.idRazdel;
    tradeLimitRequest.IdObject = params.fullFI.idObject;
    tradeLimitRequest.IdMarketBoard = params.fullFI.idMarketBoard;
    tradeLimitRequest.IdDocumentType = params.orderParams.idDocumentType;
    tradeLimitRequest.RepoRate = 0;
    tradeLimitRequest.RepoDays = 0;
    tradeLimitRequest.Price = price;
    tradeLimitRequest.IdOrderType = params.idOrderType;
    tradeLimitRequest.IdRequest = idRequest;

    alfaDirectClient.addListener(
      EntityType.TradeLimitResponseEntity,
      onTradeLimitResponse
    );

    alfaDirectClient.addListener(
      EntityType.ClientMessageEntity,
      onClientMessage
    );

    alfaDirectClient.send({
      frontend: FrontEndType.OperServer,
      isArray: false,
      payload: {
        type: EntityType.TradeLimitRequestEntity,
        data: tradeLimitRequest,
      },
    });
  });
}
