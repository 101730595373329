import { useCallback, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Loader } from '@alfalab/core-components/loader';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';
import { QrCodeMIcon } from '@alfalab/icons-glyph/QrCodeMIcon';

import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import { BankAccount } from '@terminal/core/lib/rest/lkMoney';
import { useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';

import { FormControlSummary } from '../../../../shared/ui/FormControlSummary';
import { QrModal } from '../../../components/QrModal/QrModal';
import { LK_PAGES } from '../../../const/externalUrls';
import { MoneyRequisitesFooter } from '../../../transfer/tabs/MoneyRequisites/MoneyRequisitesFooter';
import {
  BrokerRequisitesInfoEur,
  BrokerRequisitesInfoGbp,
  BrokerRequisitesInfoRub,
  BrokerRequisitesInfoUsd,
} from './RequisitesInfo';

import { useBrokerRequisitesFile } from '../../../hooks';
import { useSendBrokerRequisites } from '../../../transfer/hooks';

import formstyles from '../../../styles/forms.module.css';
import styles from './DetailRequisites.module.css';

interface DetailBrokerRequisitesProps {
  account: BankAccount;
  treaty: number;
  email: string;
  emailConfirmed: boolean;
  isMobile?: boolean;
}

export const DetailBrokerRequisites = ({
  account,
  treaty,
  email,
  emailConfirmed,
  isMobile,
}: DetailBrokerRequisitesProps) => {
  const [qrOpen, setQrOpen] = useState(false);
  const [qrFileUrl, setQrFileUrl] = useState('');
  const [pdfFile, setPdfFile] = useState<DownloadedFile | null>(null);

  const { mutateAsync: sendBrokerRequisites, isLoading: sending } =
    useSendBrokerRequisites();

  const { isLoading: qrLoading, mutateAsync: loadQrFile } =
    useBrokerRequisitesFile();
  const { isLoading: pdfLoading, mutateAsync: loadPdfFile } =
    useBrokerRequisitesFile();

  const setHeaderMenuOpen = useStore((state) => state.setHeaderMenuOpen);

  const sendRequisites = async () => {
    if (treaty && email) {
      sendBrokerRequisites({
        treaty: treaty.toString(),
        placeCode: 'MICEX_SHR',
        currency: 'RUB',
        email: email,
      });
    }
  };

  const openQr = async () => {
    setQrOpen(true);
    const qr = await loadQrFile({
      type: 'qr',
      treaty,
      currency: account.currency,
    });
    setQrFileUrl(qr.url);
  };

  const closeQr = () => {
    setQrOpen(false);
  };

  const loadPdf = async () => {
    const pdf = await loadPdfFile({
      type: 'pdf',
      treaty,
      currency: account.currency,
    });
    setPdfFile(pdf);
  };

  let numberTitle = 'Номер счета';
  let recipientTitle = 'Получатель';
  let recipient = account.name;
  if (account.currency !== 'RUB') {
    numberTitle = 'Номер счета / Beneficiary account number';
    recipientTitle = 'Получатель / Beneficiary';
    recipient = account.nameLatin;
  }

  const onChangeEmail = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.PROFILE_SETTINS);
  }, [setHeaderMenuOpen]);

  const renderInfo = (currency: string) => {
    switch (currency) {
      case 'RUB':
        return <BrokerRequisitesInfoRub />;
      case 'USD':
        return <BrokerRequisitesInfoUsd />;
      case 'EUR':
        return <BrokerRequisitesInfoEur />;
      case 'GBP':
        return <BrokerRequisitesInfoGbp />;
    }
  };
  return (
    <div className={styles.container}>
      <div className={formstyles.header}>
        <Typography.Text view="secondary-large" className={formstyles.title}>
          Реквизиты
        </Typography.Text>
      </div>
      <Space fullWidth={true} direction="vertical">
        <Row>
          <Col
            className={styles.mobileColumn}
            width={{ desktop: 6, mobile: 12, tablet: 12 }}
          >
            <FormControlSummary label={numberTitle}>
              {account.moneyAccount}
              <span>{account.currency}</span>
            </FormControlSummary>
          </Col>
          <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
            <FormControlSummary label={recipientTitle}>
              {recipient}
            </FormControlSummary>
          </Col>
        </Row>
        {renderInfo(account.currency)}
        <div className={styles.actions}>
          <Row gutter={16}>
            <Col
              className={styles.mobileColumn}
              width={{ desktop: 6, mobile: 12, tablet: 12 }}
            >
              <Button
                className={formstyles.buttonXS}
                size={isMobile ? 'xs' : 'xxs'}
                view="tertiary"
                block
                onClick={openQr}
                leftAddons={
                  <QrCodeMIcon
                    width={18}
                    height={18}
                    className={formstyles.icon}
                  />
                }
              >
                QR код
              </Button>
            </Col>
            <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
              <Button
                className={formstyles.buttonXS}
                size={isMobile ? 'xs' : 'xxs'}
                view="tertiary"
                block
                onClick={pdfFile ? undefined : loadPdf}
                href={pdfFile?.url}
                target={pdfFile ? '_blank' : undefined}
                loading={pdfLoading}
                leftAddons={
                  <OutsideMIcon
                    width={18}
                    height={18}
                    className={formstyles.icon}
                  />
                }
              >
                {pdfFile ? 'Открыть в PDF' : 'Скачать PDF'}
              </Button>
            </Col>
          </Row>
        </div>

        <MoneyRequisitesFooter
          email={email}
          emailConfirmed={emailConfirmed}
          changeEmailUrl={LK_PAGES.CONTACTS}
          sending={sending}
          sendRequisites={sendRequisites}
          isMobile={isMobile}
          onChangeEmail={onChangeEmail}
        />
      </Space>

      <QrModal open={qrOpen} onClose={closeQr}>
        {qrLoading && <Loader />}
        {!!qrOpen && !qrLoading && qrFileUrl && (
          <img className={styles.qrImage} src={qrFileUrl} alt="QR реквизиты" />
        )}
      </QrModal>
    </div>
  );
};
