import isUndefined from 'lodash/isUndefined';

import { DropdownMode } from '@terminal/common/features-DEPRECATED/Table/Filters/PositionType/DropdownMode';
import { PUFilters } from '@terminal/common/features-DEPRECATED/Table/Filters/PositionType/PUFilters';
import { TypeFilters } from '@terminal/common/features-DEPRECATED/Table/Filters/PositionType/TypeFilters';
import { ObjectGroup } from '@terminal/core/lib/client/entities';
import { BaseWidgetConfig } from '@terminal/core/types/layout';
import { PUShowMode } from '@terminal/core/types/subAccountPosition';

import { useWidgetContext } from '../../../../shared';

import styles from './PositionTypeFilters.module.css';

const minChipsFiltersWidth = 1005;
const minUnionFiltersWidth = 620;

interface Props {
  updateNode: (
    nodeId: string,
    config: BaseWidgetConfig,
    newName?: string | undefined
  ) => void;
  nodeId?: string;
  selectedTypes: ObjectGroup[];
  selectedPUMode: PUShowMode;
}

export const PositionTypeFilters = ({
  nodeId,
  selectedTypes,
  updateNode,
  selectedPUMode,
}: Props) => {
  const { getNode } = useWidgetContext();
  const nodeWidth = getNode(nodeId)?.getRect().width || 0;
  const isShowChips = nodeWidth > minChipsFiltersWidth;
  const isShowDropdown = nodeWidth < minUnionFiltersWidth;

  const saveTypeFilters = (positionTypes: ObjectGroup[]) => {
    if (!isUndefined(nodeId)) {
      updateNode(nodeId, { tableProps: { positionTypes } });
    }
  };

  const savePUMode = (selectedPUMode: PUShowMode) => {
    if (!isUndefined(nodeId)) {
      updateNode(nodeId, { tableProps: { selectedPUMode } });
    }
  };

  return isShowDropdown ? (
    <DropdownMode
      selectedTypes={selectedTypes}
      isShowChips={isShowChips}
      saveTypes={saveTypeFilters}
      selectedPUMode={selectedPUMode}
      savePUMode={savePUMode}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.filtersGrid}>
        <div className={styles.filters}>
          <TypeFilters
            selectedTypes={selectedTypes}
            isShowChips={isShowChips}
            saveTypes={saveTypeFilters}
          />
        </div>
        <PUFilters
          selectedPUMode={selectedPUMode}
          savePUMode={savePUMode}
          isShowChips={isShowChips}
        />
      </div>
    </div>
  );
};
