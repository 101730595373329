import { useState } from 'react';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';

import { BottomSheet } from '@terminal/common/components/BottomSheet';
import { DepoAccountInfo } from '@terminal/core/lib/rest/lkAccountInfo';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { BankAccount } from '@terminal/core/lib/rest/lkMoney';

import { AccountRequisitesLink } from './AccountRequisitesLink';
import { DetailBrokerRequisites } from './DetailBrokerRequisites';
import { DetailDepoRequisites } from './DetailDepoRequisites';
import { DetailRequisites } from './DetailRequisites';
import { sortDepoAccounts } from './helpers';

import { useInit } from '../../../hooks';

// eslint-disable-next-line no-restricted-imports
import formstyles from '../../../styles/forms.module.css';
import styles from './AccountRequisites.module.css';

interface AccountRequisitesProps {
  bankAccounts?: BankAccount[];
  depoAccounts?: DepoAccountInfo[];
  treaty: Treaty | null;
  openDate: string;
  spbClientField: string;
  isMobile?: boolean;
}

export const AccountRequisites = ({
  bankAccounts,
  depoAccounts,
  treaty,
  openDate,
  spbClientField,
  isMobile,
}: AccountRequisitesProps) => {
  const [broker, setBroker] = useState<BankAccount | null>(null);
  const [depo, setDepo] = useState<DepoAccountInfo | null>(null);

  const { data: init, isFetching: loading } = useInit();

  const closeDetail = () => {
    setBroker(null);
    setDepo(null);
  };

  const isGoInvest = !isMobile;

  if (!bankAccounts && !depoAccounts) {
    return null;
  }

  return (
    <>
      <div className={styles.container}>
        <Typography.Text view="primary-small" weight="bold" tag="p">
          Реквизиты
        </Typography.Text>
        <div className={styles.accounts}>
          <Row>
            {bankAccounts && !isGoInvest && (
              <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
                <Typography.Text
                  view="secondary-large"
                  className={formstyles.label}
                >
                  Номер
                </Typography.Text>
                {bankAccounts.map((acc) => (
                  <AccountRequisitesLink
                    key={`broker-account-${acc.moneyAccount}`}
                    className={styles.requisitesItem}
                    name={acc.moneyAccount}
                    info={acc.currency}
                    onClick={() => setBroker(acc)}
                  />
                ))}
              </Col>
            )}
            {depoAccounts && (
              <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
                <Typography.Text
                  view="secondary-large"
                  className={formstyles.label}
                >
                  Депо счёта
                </Typography.Text>
                {depoAccounts.sort(sortDepoAccounts).map((acc) => (
                  <AccountRequisitesLink
                    key={`depo-account-${acc.placeName}`}
                    className={styles.requisitesItem}
                    name={acc.depoAccount}
                    info={acc.placeName}
                    onClick={
                      acc.placeCode !== 'EUROTRADE'
                        ? () => setDepo(acc)
                        : undefined
                    }
                  />
                ))}
              </Col>
            )}
          </Row>
        </div>
        {!isMobile && (
          <DetailRequisites
            brokerAccount={broker}
            depoAccount={depo}
            treaty={treaty}
            openDate={openDate}
            spbClientField={spbClientField}
            onClose={closeDetail}
          />
        )}
      </div>
      {isMobile && (
        <BottomSheet
          open={Boolean(broker || depo)}
          onClose={closeDetail}
          initialHeight="full"
          hasCloser
        >
          {loading && <Loader />}
          {broker && (
            <DetailBrokerRequisites
              account={broker}
              treaty={treaty?.treaty || 0}
              email={init?.email || ''}
              emailConfirmed={init?.emailConfirmed || false}
              isMobile={isMobile}
            />
          )}
          {depo && (
            <DetailDepoRequisites
              account={depo}
              treaty={treaty?.treaty || 0}
              openDate={openDate}
              spbClientField={spbClientField}
              email={init?.email || ''}
              emailConfirmed={init?.emailConfirmed || false}
              isMobile={isMobile}
            />
          )}
        </BottomSheet>
      )}
    </>
  );
};
