import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import {
  InputDesktop,
  InputDesktopProps,
} from '@alfalab/core-components/input/desktop';

interface HardcopyFieldsProps {
  count: string;
  onCountChange: (count: string) => void;
  comment: string;
  onCommentChange: (count: string) => void;
  hideCount?: boolean;
  hideComment?: boolean;
}

export const HardcopyFields = ({
  count,
  onCountChange,
  comment,
  onCommentChange,
  hideCount,
  hideComment,
}: HardcopyFieldsProps) => {
  const handleCountChange: InputDesktopProps['onChange'] = (e, { value }) => {
    const count = value.replace(/[^\d]/g, '');

    onCountChange(count);
  };

  return (
    <Row>
      {!hideCount && (
        <Col width={6}>
          <InputDesktop
            label="Количество экземпляров"
            labelView="outer"
            block
            size="s"
            value={count}
            onChange={handleCountChange}
            maxLength={4}
          />
        </Col>
      )}
      {!hideComment && (
        <Col width={6}>
          <InputDesktop
            label="Дополнительная информация"
            labelView="outer"
            block
            size="s"
            value={comment}
            onChange={(_, { value }) => onCommentChange(value)}
          />
        </Col>
      )}
    </Row>
  );
};
