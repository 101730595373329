import { ReactNode } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import itemStyles from '../components/rooms/RoomListItem.module.css';

export const getTitleNode = (title: string, mobile: boolean): ReactNode =>
  mobile ? (
    <Typography.TitleMobile
      tag="div"
      view="xsmall"
      className={itemStyles.itemTitle}
    >
      {title}
    </Typography.TitleMobile>
  ) : (
    <Typography.Text
      tag="div"
      view="secondary-large"
      weight="medium"
      className={itemStyles.itemTitle}
    >
      {title}
    </Typography.Text>
  );
