import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useMemo } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { Dividends } from '@terminal/core/types/dividents';

import { InstrumentDataRow } from '../../ui';

import classes from './NextPaymentBlock.module.css';

interface Props {
  items: Dividends['dividendPlan'];
}

export const NextDividendsPaymentBlock = ({ items = [] }: Props) => {
  const [nextPayment] = items;
  const datePay = format(
    new Date(nextPayment?.payDate ?? Date.now()),
    'dd MMMM yyyy',
    {
      locale: ru,
    }
  );
  const dateBuy = format(
    new Date(nextPayment?.lastBuyDate ?? Date.now()),
    'dd MMMM yyyy',
    {
      locale: ru,
    }
  );
  const { currency, minorUnits } = nextPayment?.amount ?? {};
  const { value: totalValue, yield: totalYield } = useMemo(() => {
    return items.reduce(
      (acc, val) => ({
        value: acc.value + val.amount.value,
        yield: acc.yield + val.yield,
      }),
      { value: 0, yield: 0 }
    );
  }, [items]);

  return (
    <div className={classes.nextPaymentContainer}>
      <Typography.TitleMobile
        view="xsmall"
        tag="div"
        weight="bold"
        color="secondary"
        className={classes.nextPaymentTitle}
      >
        Ближайшая выплата
      </Typography.TitleMobile>
      <InstrumentDataRow title="Дата выплаты" value={datePay} />
      <InstrumentDataRow title="Последний день покупки" value={dateBuy} />
      <InstrumentDataRow
        title="Период"
        value={nextPayment.period?.toLocaleLowerCase()}
      />
      <InstrumentDataRow
        title="Доходность"
        value={
          <Typography.Text
            color={totalYield > 0 ? 'positive' : 'negative'}
            view="secondary-large"
          >
            {totalYield}%
          </Typography.Text>
        }
      />
      <InstrumentDataRow
        className={classes.beforeInfoMessage}
        title="Выплата на акцию"
        value={
          <Amount.Pure
            value={Math.floor((totalValue * 100) / minorUnits)}
            currency={currency as 'RUB'}
            minority={100}
            view="withZeroMinorPart"
          />
        }
      />
      <Typography.Text view="secondary-small" color="secondary">
        Зачисление на счет происходит в срок до 2-х месяцев после закрытия
        реестра
      </Typography.Text>
    </div>
  );
};
