import { useEffect, useMemo, useState } from 'react';

import {
  ThemeQuestion,
  ThemeQuestionAnswers,
} from '../../../entities/Education';
import { useTestAnswer } from './useTestAnswer';

type TestAnswers = { [key: number]: number[] };

export const useTestProcess = (
  questions: ThemeQuestion[] | undefined,
  onAllAnswers: (anwers: ThemeQuestionAnswers[]) => void
) => {
  const [current, setCurrent] = useState<number | null>(null);
  const [testAnswers, setTestAnswers] = useState<TestAnswers>({});

  useEffect(() => {
    if (questions) {
      setCurrent(0);
    }
  }, [questions]);

  const currentQuestion = useMemo(() => {
    if (questions && current !== null) {
      return questions[current];
    }
  }, [questions, current]);

  const { selected: currentAnswers, setAnswer: setCurrentAnswer } =
    useTestAnswer(currentQuestion);

  const handleNext = () => {
    if (!currentQuestion || !questions) {
      return;
    }

    const allAnswers = {
      ...testAnswers,
      [currentQuestion.id]: currentAnswers,
    };

    setTestAnswers(allAnswers);

    const nextQuestionIndex = (current || 0) + 1;

    if (questions[nextQuestionIndex]) {
      setCurrent(nextQuestionIndex);
    } else {
      const questionsId = Object.keys(allAnswers);
      const answers = questionsId.map((questionId) => ({
        questionId: parseInt(questionId),
        answers: allAnswers[questionId],
      }));

      onAllAnswers(answers);
    }
  };

  return {
    current,
    setCurrent,
    testAnswers,
    setTestAnswers,
    currentQuestion,
    currentAnswers,
    setCurrentAnswer,
    handleNext,
  };
};
