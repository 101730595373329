import cn from 'classnames';
import { Typography } from '@alfalab/core-components/typography';

import property1Icon from './img/property-1.png';
import property1Icon2x from './img/property-1@2x.png';
import property2Icon from './img/property-2.png';
import property2Icon2x from './img/property-2@2x.png';
import property3Icon from './img/property-3.png';
import property3Icon2x from './img/property-3@2x.png';
import property4Icon from './img/property-4.png';
import property4Icon2x from './img/property-4@2x.png';

import styles from './StrategyEnrollProperty.module.css';

interface StrategyEnrollPropertyProps {
  index: 1 | 2 | 3 | 4;
  className?: string;
  children?: React.ReactNode;
}

const PROP_ICONS: Record<StrategyEnrollPropertyProps['index'], string> = {
  1: property1Icon,
  2: property2Icon,
  3: property3Icon,
  4: property4Icon,
};

const PROP_ICONS_2X: Record<StrategyEnrollPropertyProps['index'], string> = {
  1: property1Icon2x,
  2: property2Icon2x,
  3: property3Icon2x,
  4: property4Icon2x,
};

export const StrategyEnrollProperty = ({
  index,
  className,
  children,
}: StrategyEnrollPropertyProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.icon}>
        <img
          className={styles.image}
          src={PROP_ICONS[index]}
          srcSet={`${PROP_ICONS_2X[index]} 2x`}
          alt=""
        />
      </div>
      <Typography.Text
        className={styles.content}
        tag="div"
        view="secondary-small"
      >
        {children}
      </Typography.Text>
    </div>
  );
};
