import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { groupBy } from 'lodash';
import { useCallback } from 'react';

import { CorporateActionHeader } from '@terminal/core/lib/rest/lkCorporateActions';

import { MessageItem } from './MessageItem';

import styles from './MessagesList.module.css';

interface MessagesListProps {
  messages: CorporateActionHeader[] | null;
  onItemOpen?: (item: CorporateActionHeader) => void;
}

export const MessagesList = ({ messages, onItemOpen }: MessagesListProps) => {
  const dateGroups = groupBy(messages, (item) => {
    return format(parseISO(item.date), 'd MMMM yyyy', { locale: ru });
  });

  const getKeyTitle = useCallback((key: string) => {
    return key === format(new Date(), 'd MMMM yyyy', { locale: ru })
      ? 'Сегодня'
      : key;
  }, []);

  return (
    <div className={styles.container}>
      {Object.keys(dateGroups).map((key) => (
        <div className={styles.group} key={key}>
          <div className={styles.groupTitle}>{getKeyTitle(key)}</div>
          {dateGroups[key].map((item, index) => (
            <MessageItem
              key={`message-header-${item.id}`}
              item={item}
              odd={(index + 1) % 2 !== 0}
              onClick={onItemOpen}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
