import React, { FC, memo, useMemo } from 'react';

import { context } from './context';

import { TradingContext } from './types/TradingContext';

export const TradingContextProvider: FC<TradingContext> = memo(
  ({ children, ...props }) => {
    const value = useMemo<TradingContext>(() => props, [props]);

    return <context.Provider value={value}>{children}</context.Provider>;
  }
);
