import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getSecuritiesPositions } from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesPositions = () => {
  const addNotification = useNotification();

  return useQuery([LkQueryKeys.SecuritiesPositions], getSecuritiesPositions, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Произошла ошибка запросе позиций',
      });
    },
  });
};
