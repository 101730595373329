import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';

import { ChangeTariffParams } from './types/ChangeTariffParams';

export async function changeTariffAI(data: ChangeTariffParams) {
  return await signOperation(
    '/api/client/accounts/v2/change-tariff',
    'POST',
    data.params,
    {
      ...data.signOptions,
      confirmation: {
        codes: [2],
      },
    }
  );
}
