import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { sortObjKeys } from '../../lib/sortObjKeys';
import { useFilteredSubAccounts } from './useFilteredSubAccounts';

/**
 * Возвращает строковое значение выбранного субсчета (в массиве) для отображения
 */
export const useSelectedSubAccounts = () => {
  const { usePwaGlobalSubAccount } = useAlfaDirectContext();
  const selectedSubAccount = usePwaGlobalSubAccount?.();
  const selectedSubAccountCode = selectedSubAccount?.code;

  const filteredSubAccounts = useFilteredSubAccounts();

  return useMemo(() => {
    if (!selectedSubAccountCode) {
      // По умолчанию выбираем первый субсчет, который не инвесткопилка (сортируем по генсоглу)
      return [Object.keys(sortObjKeys(filteredSubAccounts))[0]];
    } else {
      return [selectedSubAccountCode];
    }
  }, [selectedSubAccountCode, filteredSubAccounts]);
};
