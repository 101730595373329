import { useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Divider } from '@alfalab/core-components/divider';
import { Loader } from '@alfalab/core-components/loader';
import {
  PickerButtonDesktop,
  PickerButtonDesktopProps,
} from '@alfalab/core-components/picker-button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownBoldContainerMIcon } from '@alfalab/icons-glyph/ArrowDownBoldContainerMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';

import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import {
  SecuritiesHistoryDetail,
  SecuritiesHistoryItem,
  SecuritiesHistoryStatusCode,
} from '@terminal/core/lib/rest/lkSecurities';

import { useSecuritiesHistoryDetailPdf } from '../../../../entities/Securities';
import { SECURITIES_TRANSFER_TYPE_TEXT } from '../HistoryList/constants';
import { SummaryField } from '../Summary';
import { OperationBasis } from './OperationBasis';
import { TargetPlaceInfo } from './TargetPlaceInfo';

import {
  SecuritiesHistoryFileType,
  useSecuritiesHistoryDetailFile,
} from '../../hooks';

import styles from './HistoryItemDetail.module.css';

interface HistoryItemDetailProps {
  item: SecuritiesHistoryDetail | null;
  listItem: SecuritiesHistoryItem | null;
  treaty: Treaty | null;
  pending: boolean;
  onCancel?: (item: SecuritiesHistoryItem) => void;
}

const DOWNLOAD_OPTIONS: {
  key: SecuritiesHistoryFileType;
  content: string;
}[] = [
  { key: 'xml', content: 'XML' },
  { key: 'pdf', content: 'PDF' },
];

export const HistoryItemDetail = ({
  item,
  listItem,
  treaty,
  pending,
  onCancel,
}: HistoryItemDetailProps) => {
  const [load, setLoad] = useState(false);
  const [type, setType] = useState<SecuritiesHistoryFileType | undefined>();
  const [rejectPdfFile, setRejectPdfFile] = useState<DownloadedFile>();

  const isCancelableOrder =
    item?.info.deponet &&
    ![
      SecuritiesHistoryStatusCode.Executed,
      SecuritiesHistoryStatusCode.Declined,
      SecuritiesHistoryStatusCode.Rejected,
    ].includes(item.info.status);

  const { isLoading: pdfLoading, mutateAsync: loadRejectPdfFile } =
    useSecuritiesHistoryDetailPdf();

  const { isFetching: fileLoading, data: file } =
    useSecuritiesHistoryDetailFile(
      {
        type,
        treaty: treaty?.treaty,
        trnNo: listItem?.trnNo,
        trnSrc: listItem?.trnSrc,
        aDate: listItem?.aDate,
        deponet: listItem?.deponet,
      },
      load
    );

  const onFileSelect: PickerButtonDesktopProps['onChange'] = ({ selected }) => {
    if (selected?.key) {
      setType(selected.key as SecuritiesHistoryFileType);
      setLoad(true);
    }
  };

  const loadRejectPdf = async () => {
    if (item?.info.isRejectId && treaty?.treaty) {
      const pdf = await loadRejectPdfFile({
        trnNo: item?.info.isRejectId || 0,
        treaty: treaty?.treaty,
      });

      setRejectPdfFile(pdf);
    }
  };

  const onDownload = () => {
    setLoad(false);
  };

  if (pending) {
    return <Loader />;
  }

  return !item ? null : (
    <div className={styles.body}>
      <Typography.Text
        view="primary-small"
        tag="p"
        color="primary"
        weight="bold"
        className={styles.title}
      >
        {SECURITIES_TRANSFER_TYPE_TEXT[item.info.tranType] || ''}
      </Typography.Text>

      <div className={styles.section}>
        <SummaryField label="Статус" value={item.info.statusDescription} />
        {item.info.textDescriptions ? (
          <SummaryField
            label="Детализация статуса"
            value={item.info.textDescriptions}
          />
        ) : null}
      </div>

      {item.info.status === SecuritiesHistoryStatusCode.Rejected &&
      item.info.isRejectId ? (
        <div className={styles.section}>
          <div className={styles.field}>
            {rejectPdfFile ? (
              <ButtonDesktop
                size="xs"
                view="tertiary"
                href={rejectPdfFile.url}
                download={rejectPdfFile.filename}
                target="_blank"
                block
                leftAddons={<OutsideMIcon width={16} height={16} />}
              >
                Открыть поручение на отмену
              </ButtonDesktop>
            ) : (
              <ButtonDesktop
                size="xs"
                view="tertiary"
                loading={pdfLoading}
                onClick={loadRejectPdf}
                block
                leftAddons={
                  <ArrowDownBoldContainerMIcon width={16} height={16} />
                }
              >
                Скачать поручение на отмену
              </ButtonDesktop>
            )}
          </div>
        </div>
      ) : null}

      <Divider className={styles.divider} />

      {item.info.tranType === 'EXTRA_IN' ? (
        <TargetPlaceInfo info={item.target} title="Куда" />
      ) : (
        <TargetPlaceInfo info={item.source} title="Откуда" />
      )}

      <Divider className={styles.divider} />

      {item.info.tranType === 'EXTRA_IN' ? (
        <TargetPlaceInfo info={item.source} title="Откуда" />
      ) : (
        <TargetPlaceInfo info={item.target} title="Куда" />
      )}

      <Divider className={styles.divider} />

      <Typography.Text
        view="primary-small"
        tag="p"
        color="primary"
        weight="bold"
        className={styles.sectionTitle}
      >
        Ценная бумага
      </Typography.Text>
      <div className={styles.section}>
        <SummaryField label="Наименование" value={item.info.paperInfo.pName} />
        <SummaryField label="ISIN" value={item.info.paperInfo.isin} />
        <SummaryField label="Количество" value={item.info.volume} />
      </div>

      <Divider className={styles.divider} />

      <OperationBasis operationBasis={item.operationBasis} />

      <Divider className={styles.divider} />

      <div className={styles.section}>
        {isCancelableOrder && (
          <div className={styles.field}>
            <ButtonDesktop
              size="s"
              view="tertiary"
              block
              onClick={() => listItem && onCancel?.(listItem)}
              leftAddons={<CrossMIcon width={16} height={16} />}
            >
              Отменить поручение
            </ButtonDesktop>
          </div>
        )}

        <div className={styles.field}>
          {file ? (
            <ButtonDesktop
              size="s"
              view="accent"
              block
              href={file.url}
              download={file.filename}
              target="_blank"
              onClick={onDownload}
              leftAddons={<OutsideMIcon width={16} height={16} />}
            >
              Открыть
            </ButtonDesktop>
          ) : (
            <PickerButtonDesktop
              options={DOWNLOAD_OPTIONS}
              block
              view="accent"
              size="s"
              label="Скачать поручение"
              loading={fileLoading}
              onChange={onFileSelect}
              popoverPosition="top"
              leftAddons={
                <ArrowDownBoldContainerMIcon width={16} height={16} />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
