import { Link } from '@alfalab/core-components/link';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { MailMIcon } from '@alfalab/icons-glyph/MailMIcon';

import styles from './FeedbackModal.module.css';

interface FeedbackModalModalProps {
  onClose: () => void;
}

export const FeedbackModal = ({ onClose }: FeedbackModalModalProps) => (
  <>
    <div className={styles.backdrop} onClick={onClose}></div>
    <div className={styles.container}>
      <div className={styles.header}>
        <CrossMIcon className={styles.headerCloseIcon} onClick={onClose} />
      </div>
      <div className={styles.main}>
        <div className={styles.mailIconContainer}>
          <MailMIcon
            color="var(--color-light-graphic-secondary)"
            width={27}
            height={27}
          />
        </div>
        <div className={styles.textHeader}>
          Обратиться в чат&nbsp;поддержки&nbsp;брокера
        </div>
        <Link
          view="primary"
          rel="noopener"
          target="_blank"
          href="https://t.me/go_invest_support_bot"
          className={styles.link}
        >
          Oткрыть чат
        </Link>
      </div>
    </div>
  </>
);
