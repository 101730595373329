import React, { FC, memo } from 'react';

import {
  ColumnSettingModal,
  DoubleColumnSettingModal,
} from '@terminal/common/ui/Table';
import { OrderStatus } from '@terminal/core/lib/client/entities';
import { TableColumnSetting, Widget } from '@terminal/core/types/layout';
import { OrderItem } from '@terminal/core/types/order';
import { CommonSettings } from '@terminal/core/types/ui';

import { AccountFilters } from '../../../../features/AccountFilters';
import { WidgetHeader } from '../../../../features/WidgetHeader';
import {
  TECH_MESSAGE_CODE,
  WidgetServiceNotification,
} from '../../../../features/WidgetServiceNotification';
import { useWidgetContext } from '../../../../shared';
import { OrderStatusFilters } from '../../features/OrderStatusFilters';

import { useSettings as useTableSettings } from '../../hooks/useSettings';

interface Props {
  nodeId?: string;
  setIsOpenColumnSettings: (open: boolean) => void;
  isDoubleRowMode: boolean;
  switchDoubleRowMode: () => void;
  isOpenColumnSettings: boolean;
  columnsSetting: TableColumnSetting[];
  defaultOrderTableColumnSetting: TableColumnSetting[];
  selectedSubAccounts: string[];
  orders: OrderItem[];
  orderStatus: OrderStatus[];
  selectedDisplayInstrumentType: CommonSettings['defaultValues']['selectedDisplayInstrumentType'];
}

const OrderTableHeader: FC<Props> = ({
  nodeId,
  setIsOpenColumnSettings,
  isDoubleRowMode,
  switchDoubleRowMode,
  isOpenColumnSettings,
  columnsSetting,
  defaultOrderTableColumnSetting,
  selectedSubAccounts,
  orders,
  orderStatus,
  selectedDisplayInstrumentType,
}) => {
  const { updateNode } = useWidgetContext();

  const widgetSettings = useTableSettings({
    isDoubleRowMode,
    setIsOpenColumnSettings,
    switchDoubleRowMode,
  });

  return (
    <>
      <WidgetHeader
        inputs={
          <AccountFilters
            selectedSubAccounts={selectedSubAccounts}
            updateNode={updateNode}
            nodeId={nodeId}
          />
        }
        filters={
          <OrderStatusFilters
            nodeId={nodeId}
            updateNode={updateNode}
            selectedFilters={orderStatus}
            orders={orders}
          />
        }
        widgetSettings={widgetSettings}
      />
      {!isDoubleRowMode && (
        <ColumnSettingModal
          isOpen={isOpenColumnSettings}
          setIsOpen={setIsOpenColumnSettings}
          columnsSetting={columnsSetting}
          updateNode={updateNode}
          nodeId={nodeId}
          widgetType={Widget.ORDERS}
        />
      )}
      {isDoubleRowMode && (
        <DoubleColumnSettingModal
          isOpen={isOpenColumnSettings}
          setIsOpen={setIsOpenColumnSettings}
          columnsSetting={columnsSetting}
          defaultColumnSetting={defaultOrderTableColumnSetting}
          updateNode={updateNode}
          nodeId={nodeId}
          widgetType={Widget.ORDERS}
          selectedDisplayInstrumentType={selectedDisplayInstrumentType}
        />
      )}
      <WidgetServiceNotification
        code={TECH_MESSAGE_CODE.TRADE}
        variant="line"
        canBeShort
      />
    </>
  );
};

export const Header = memo(OrderTableHeader);
