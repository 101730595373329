import { MutableRefObject, RefObject, useEffect, useRef } from 'react';

type ResizeObserverCallback = (
  entries: ResizeObserverEntry[],
  observer: ResizeObserver
) => void;

// Хук позволяет отслеживать изменение размеров элемента и выполнять обработчик
// requestAnimationFrame используется, чтобы выполнить обработчик после repaint, иначе получается resize loop
export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  handler: ResizeObserverCallback
) => {
  const resizeObserver: MutableRefObject<ResizeObserver | undefined> = useRef();
  const handlerRef = useRef<ResizeObserverCallback>(handler);

  useEffect(() => {
    const observerCallback: ResizeObserverCallback = (...props) => {
      window.requestAnimationFrame(() => handlerRef.current(...props));
    };

    resizeObserver.current = new ResizeObserver(observerCallback);

    if (ref.current) {
      resizeObserver.current.observe(ref.current);

      return () => {
        if (resizeObserver.current) {
          resizeObserver.current.disconnect();
        }
      };
    }
  }, [ref]);
};
