import { useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Space } from '@alfalab/core-components/space';
import { CheckmarkCompactMIcon } from '@alfalab/icons-glyph/CheckmarkCompactMIcon';

import {
  ReasonDocumentType,
  SecuritiesDocumentType,
  SecuritiesReasonDocument,
} from '@terminal/core/lib/rest/lkSecurities';

import { isDocumentValid } from '../../validation';
import { DocumentFields } from './DocumentFields';

interface DocumentEditorProps {
  document?: SecuritiesReasonDocument;
  documentTypes?: SecuritiesDocumentType[];
  documentTypesPending: boolean;
  onSave: (doc: SecuritiesReasonDocument) => void;
}

export const DocumentEditor = ({
  document,
  documentTypes,
  documentTypesPending,
  onSave,
}: DocumentEditorProps) => {
  const [id, setId] = useState<number>(document?.id || 0);
  const [docType, setDocType] = useState<ReasonDocumentType>(
    document?.docType || 'BASE'
  );
  const [docName, setDocName] = useState<string>(document?.docName || '');
  const [docNumber, setDocNumber] = useState<string>(document?.number || '');
  const [docDate, setDocDate] = useState<string>(document?.date || '');

  const redactedDoc: SecuritiesReasonDocument = {
    id,
    docType: docType,
    docName: docName,
    name: docName,
    number: docNumber,
    date: docDate,
  };

  const canSave: boolean = isDocumentValid(redactedDoc, documentTypes || []);

  return (
    <Space fullWidth direction="vertical">
      <DocumentFields
        document={redactedDoc}
        documentTypes={documentTypes}
        documentTypesPending={documentTypesPending}
        onTypeChange={(id, docType, docName) => {
          setId(id);
          setDocType(docType);
          setDocName(docName);
        }}
        onNameChange={(name) => setDocName(name)}
        onNumberChange={(number) => setDocNumber(number)}
        onDateChange={(date) => setDocDate(date)}
      />
      <Row>
        <Col width={4}>
          <Button
            block
            size="s"
            disabled={!canSave}
            onClick={() => onSave(redactedDoc)}
            leftAddons={<CheckmarkCompactMIcon />}
          >
            Сохранить
          </Button>
        </Col>
      </Row>
    </Space>
  );
};
