import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import keyBy from 'lodash/keyBy';

import { Operation } from './client/entities';

type KeyIteratee = string;
type FuncIteratee<E> = (entity: E) => string;
interface Entity {
  version: string;
  operation: Operation;
}

export const updateVersionedEntities = <E extends Entity>(
  current: E[],
  updating: E[],
  iteratee: KeyIteratee | FuncIteratee<E>
): E[] => {
  const isIterateeFunction = isFunction(iteratee);

  const entitiesMap = isArray(current) ? keyBy(current, iteratee) : current;

  for (let entity of updating) {
    const key = isIterateeFunction ? iteratee(entity) : get(entity, iteratee);

    if (entity.operation === Operation.Deleted) {
      delete entitiesMap[key];
    } else if (
      !entitiesMap[key] ||
      !entity.version ||
      BigInt(entity.version) > BigInt(entitiesMap[key].version || '0')
    ) {
      // Если энтити нет, либо в новом объекте не проставлена версия - заведемо обновляющая, либо версия выше
      entitiesMap[key] = entity;
    }
  }

  return Object.values(entitiesMap);
};
