export const replacementBondsList = [
  'RU000A1059A6',
  'RU000A105KU0',
  'RU000A105SG2',
  'RU000A105R62',
  'RU000A105RG4',
  'RU000A105GN3',
  'RU000A105QW3',
  'RU000A105H64',
  'RU000A1056U0',
  'RU000A105JT4',
  'RU000A105JH9',
  'RU000A1059Q2',
  'RU000A1059N9',
  'RU000A105A87',
  'RU000A1059P4',
  'RU000A1059R0',
  'RU000A105A04',
  'RU000A105146',
  'RU000A105A95',
  'RU000A105RH2',
  'RU000A105XA5',
  'RU000A105RZ4',
  'RU000A105WH2',
  'RU000A105WJ8',
  'RU000A105VL6',
  'RU000A105QX1',
  'RU000A105BY1',
  'RU000A105GZ7',
  'RU000A105C51',
  'RU000A105BL8',
];
