import { useFullFI } from './useFullFI';
import { usePositions } from './usePositions';

// Возвращает позицию из портфеля с idFI
export const usePositionByIdFI = (idFI: number) => {
  const positions = usePositions();
  const fullFI = useFullFI(idFI);

  return positions.find((pos) => pos.idObject === fullFI?.idObject);
};
