import { useQuery } from 'react-query';

import { getCodesSign } from '@terminal/core/lib/rest/lkCodeTable';
import { LkSignOptions } from '@terminal/core/store/slices/lkSignSlice';

import { LkQueryKeys } from '../queryKeys';

export const useCodeTable = (options?: LkSignOptions) => {
  const query = useQuery([LkQueryKeys.CodeTable], async () =>
    getCodesSign(options)
  );

  return query;
};
