export interface ContestPublicDigestProfitAndTop {
  isAntitop: boolean;
  marketType: number;
  emCode: string;
  isin: string;
  idObject: number;
  idObjectBase: number;
  value: number;
  hasIcon: boolean;
  iconType?: string;
}

export enum ContestParticipantType {
  TOP_TRADER = 'top_trader',
  LEAD_TRADER = 'lead_trader',
  LOST_LEAD = 'lost_lead',
  CHANCE_TRADER = 'chance_trader',
  PROFIT_TRADER = 'profit_trader',
  PROFIT_PERCENT_TRADER = 'profit_percent_trader',
  MONTH_TRADER = 'month_trader',
  QUARTER_TRADER = 'quarter_trader',
}

export const participantTypeMap: Record<string, string> = {
  [ContestParticipantType.TOP_TRADER]: 'Самый быстрый взлёт',
  [ContestParticipantType.LEAD_TRADER]: 'Удержал лидерство',
  [ContestParticipantType.LOST_LEAD]: 'Уступил лидерство',
  [ContestParticipantType.CHANCE_TRADER]: 'Заработал больше всего шансов',
  [ContestParticipantType.PROFIT_TRADER]: 'Показал больший заработок',
  [ContestParticipantType.PROFIT_PERCENT_TRADER]: 'Показал большую доходность',
  [ContestParticipantType.MONTH_TRADER]: 'Выиграл месячный супер-приз',
  [ContestParticipantType.QUARTER_TRADER]: 'Выиграл квартальный супер-приз',
};

export interface ContestPublicDigestChart {
  participantType: ContestParticipantType;
  nickname: string;
  value: number;
}

export interface ContestPublicDigest {
  isEnabled: boolean;
  fact?: string;
  avgProfit: number;
  avgLoss: number;
  maxProfit: number;
  maxLoss: number;
  totalProfit: number;
  totalLoss: number;
  turnovers: ContestPublicDigestProfitAndTop[];
  profitsLosses: ContestPublicDigestProfitAndTop[];
  maxprofitsLosses: ContestPublicDigestProfitAndTop[];
  tradersChart: ContestPublicDigestChart[];
}
