import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';
import { getAddAccountsConstraints } from '../api';

export const useAddAccountConstraints = () => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.AddAccountConstraints],
    () => getAddAccountsConstraints(),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'При запросе заявления произошла ошибка',
        });
      },
    }
  );
};
