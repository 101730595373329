/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */

import log from 'loglevel';

import { EntityType } from './entityTypes';

export const UNKNOWN_ENTITY_MSG = `Entity is not registred for serialization`;

export const EPOCH_TICKS = 621355968000000000;
export const TICKS_PER_MILLISECOND = 10000;

export enum FieldType {
  StringField = 'string',
  Int16Field = 'int16',
  UInt32Field = 'uint32',
  Int32Field = 'int32',
  Int64Field = 'int64',
  EnumField = 'enum',
  DateTimeFiled = 'datetime',
  UnknownField = 'unknown',
  DoubleField = 'double',
  BooleanField = 'boolean',
  ArrayField = 'array',
  ByteArrayField = 'bytearray',
  VariantField = 'variant',
  ConditionField = 'condition',
}

export interface BaseFieldMeta {
  type: FieldType | string;
}

export interface StringFieldMeta extends BaseFieldMeta {
  size: number;
}

export interface EnumFieldMeta extends BaseFieldMeta {
  width: number;
}

export interface UnknownFieldMeta extends BaseFieldMeta {
  width: number;
}

export interface ArrayFieldMeta extends BaseFieldMeta {
  size: number;
  width: number;
  elements: string;
  isPrimitives: boolean;
}

export interface ConditionFieldMeta extends BaseFieldMeta {
  condition: Function;
}

export type FieldMeta =
  | BaseFieldMeta
  | StringFieldMeta
  | EnumFieldMeta
  | UnknownFieldMeta
  | ArrayFieldMeta
  | ConditionFieldMeta;

export interface EntityMeta {
  [field: string]: FieldMeta;
}

// это мапы для хранения метаинформации о полях
// почему var'ы - функции декораторов будут вызваны до кода тела модуля, поэтому let и const
// будут падать с ошибкой доступа к неинициализированной переменной
// мапы будут созданы при первом вызове декораторов

// В entitiesMap лежит метинформация доступная по строковому названию энтити
// При заполнении этого мапа есть неочевидная особенность, связанная с тем что
// в релизном билде имена классов/функций будут минифицированы.
// Декораторы вызываются в установленом порядке: сначала поля и только потом декоратор класса
// Поэтому при вызове декоратора на поле в entityMap кладется инфа по ключу object.constructor
// А когда вызывается декоратор класса, то ключ-функция заменяется на строкое значение, которое явно
// было передано в декораторе
// TLDR: имена классов в JS ненадежная вещь, не полагайся на них

// eslint-disable-next-line no-var
export var entitiesMap: Map<EntityType | Object, EntityMeta>;
// eslint-disable-next-line no-var
export var maskedEntitesMap: Set<string>;
// это мап где по id энтити из ad.xml возрващается ее типа
// eslint-disable-next-line no-var
export var messagesById: Map<number, EntityType>;
// eslint-disable-next-line no-var
export var messagesByName: Map<EntityType, number>;

// Декораторы
export function ADEntity(typeId: number, className: EntityType): Function {
  log.trace(`ADEntity typeId = ${typeId} and ${className}`);
  return (ctor: Function) => {
    initMaps();

    const meta = entitiesMap.get(ctor);
    if (meta === undefined) {
      throw new Error('Unexpected error while initializing metadata');
    }
    entitiesMap.set(className, meta);
    entitiesMap.delete(ctor);

    messagesById.set(typeId, className);
    messagesByName.set(className, typeId);
  };
}

// ВАЖНО: есть так называемые энтити с фейковой маской. Это атрибут fake-mask="true"
// их надо все равно помечать ADMaskedEntity, тк в них есть пустой байт маски
export function ADMaskedEntity(
  typeId: number,
  className: EntityType
): Function {
  log.trace(`ADMaskedEntity typeId = ${typeId} and ${className}`);
  initMaps();
  maskedEntitesMap.add(className);
  return ADEntity(typeId, className);
}

export function StringField(size = 256) {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, {
      type: 'string',
      size: size,
    });
  };
}

export function UInt32Field() {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'uint32' });
  };
}

export function Int16Field() {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'int16' });
  };
}

export function Int32Field() {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'int32' });
  };
}

export function Int64Field() {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'int64' });
  };
}

export function VariantField() {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'variant' });
  };
}

export function ConditionField(condition: Function) {
  return (target: Object, propertyKey: string | symbol) => {
    registerField(target, propertyKey, {
      type: 'condition',
      condition,
    });
  };
}

export const getArrayFieldMeta = (
  width: number,
  size: number,
  elements: string,
  isPrimitives = false
) => ({
  type: 'array',
  width,
  size,
  isPrimitives,
  elements: elements,
});

// смотри ридми про разъяснение за параметр isPrimitives
export function ArrayField(
  width: number,
  size: number,
  elements: string,
  isPrimitives = false
) {
  return (target: Object, propertyKey: string | symbol) => {
    registerField(
      target,
      propertyKey,
      getArrayFieldMeta(width, size, elements, isPrimitives)
    );
  };
}

export function ByteArray() {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'bytearray' });
  };
}

export function BooleanField() {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'boolean' });
  };
}

export function DateTimeField() {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'datetime' });
  };
}

export function DoubleField() {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'double' });
  };
}

export function EnumField(width: number) {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'enum', width });
  };
}

// Это для отладки/разработки когда нужно пропустить в сообщении width байт для этого поля
export function UnknownField(width: number) {
  return (target: Object, propertyKey: string | symbol): void => {
    registerField(target, propertyKey, { type: 'unknown', width });
  };
}

// Функция создает инстанс энтити по ее типу или номеру в протоколе
export function createEntity(entity: string | number): Record<string, any> {
  let entityType: EntityType | string | undefined;

  if (typeof entity === 'string') {
    entityType = entity;
  }

  if (typeof entity === 'number') {
    entityType = messagesById.get(entity);
  }

  if (!entityType) {
    throw new Error(UNKNOWN_ENTITY_MSG);
  } else {
    const entity: Record<string, any> = {};
    const meta = getMeta(entityType);
    if (!meta) {
      throw new Error(UNKNOWN_ENTITY_MSG);
    }
    // Раньше здесь создавались инстансы классов наследников от Entity
    // Но выявилось проблема что создание инсансов для классов с дектораторами
    // Работает в разы дольше чем POCO обектов и поэтому тут просто возвращается объект
    return entity;
  }
}

export function getMeta(
  entity: string | number
): EntityMeta | null | undefined {
  let result: EntityMeta | null | undefined;

  if (typeof entity === 'string') {
    result = entitiesMap.get(entity);
  }

  if (typeof entity === 'number') {
    const entitiyType = messagesById.get(entity);
    if (entitiyType) {
      result = entitiesMap.get(entitiyType);
    }
  }

  if (!result) {
    log.warn(`Entity ${entity} is not registred for serialization`);
  }

  return result;
}

export function getEntityNameById(id: number): EntityType | undefined {
  return messagesById.get(id);
}

export function getEntityIdByName(name: EntityType): number | undefined {
  return messagesByName.get(name);
}

export function isEntityMasked(entity: EntityType | number): boolean {
  if (typeof entity === 'string') {
    return maskedEntitesMap.has(entity);
  } else {
    const entityName = getEntityNameById(entity);
    if (entityName) {
      return maskedEntitesMap.has(entityName);
    } else {
      return false;
    }
  }
}

function initMaps() {
  if (!entitiesMap) {
    entitiesMap = new Map();
  }
  if (!messagesByName) {
    messagesByName = new Map();
  }
  if (!messagesById) {
    messagesById = new Map();
  }

  if (!maskedEntitesMap) {
    maskedEntitesMap = new Set();
  }
}

function registerField(
  target: object,
  propertyKey: string | symbol,
  fieldMeta: FieldMeta
): void {
  if (typeof target === 'function') {
    throw new Error('Static properties not supported');
  }

  initMaps();

  const classFn = target.constructor;
  if (entitiesMap.has(classFn)) {
    // добавляем поле
    const entityData = entitiesMap.get(classFn);
    if (entityData && entityData[String(propertyKey)] === undefined) {
      entityData[String(propertyKey)] = fieldMeta;
    }
  } else {
    // или создаем объект
    entitiesMap.set(classFn, {
      [String(propertyKey)]: fieldMeta,
    });
  }
}
