import cn from 'classnames';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import React from 'react';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import { FilterIconButton } from '../../shared/ui/FilterIconButton';
import { FilterButton } from '../transfer/FilterButton';

import { FilterActions, FilterActionsTypes, FilterState } from './types';

import styles from './FreeOrders.module.css';

interface FreeOrderFilterProps {
  filter: FilterState;
  dispatch: React.Dispatch<FilterActions>;
}

export const FreeOrderFilter = ({ filter, dispatch }: FreeOrderFilterProps) => {
  const isAnyFilterActive =
    filter.status.length ||
    filter.endDate ||
    filter.startDate ||
    filter.text?.trim();

  return (
    <div className={cn(styles.filterRow, styles.filterRowWrapper)}>
      <FilterIconButton
        onClick={() => dispatch({ type: FilterActionsTypes.ToggleSideBar })}
        checked={!!isAnyFilterActive}
      />
      <FilterButton
        checked={filter.status.includes('S')}
        onClick={() =>
          dispatch({ type: FilterActionsTypes.ToggleStatus, payload: 'S' })
        }
      >
        Принято
      </FilterButton>
      <FilterButton
        checked={filter.status.includes('E')}
        onClick={() =>
          dispatch({ type: FilterActionsTypes.ToggleStatus, payload: 'E' })
        }
      >
        Исполнено
      </FilterButton>
      <FilterButton
        checked={filter.status.includes('R')}
        onClick={() =>
          dispatch({ type: FilterActionsTypes.ToggleStatus, payload: 'R' })
        }
      >
        Отклонено
      </FilterButton>
      <FilterButton
        checked={!!filter.startDate && !!filter.endDate}
        rightAddons={<ChevronDownMIcon width={18} height={18} />}
        onClick={() => dispatch({ type: FilterActionsTypes.ToggleSideBar })}
      >
        {!!filter.startDate && !!filter.endDate
          ? `${format(filter.startDate, 'dd.MM.yyyy', {
              locale: ru,
            })} - ${format(filter.endDate, 'dd.MM.yyyy', { locale: ru })}`
          : 'Выберите период'}
      </FilterButton>
    </div>
  );
};
