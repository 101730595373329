type AnyFunction<T> = (...args: any[]) => Promise<T>;
type CatchFn<E> = (err: any) => E;

export const tryCatch = async <T, E = any>(
  fn: AnyFunction<T>,
  catchFn?: CatchFn<E>
): Promise<T | E | null> => {
  try {
    return await fn();
  } catch (err) {
    if (catchFn) {
      return catchFn(err);
    }

    return null;
  }
};
