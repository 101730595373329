import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { isSingleFORTSMarketAvailable } from '../lib/domain/isSingleFORTSMarketAvailable';

export const useSingleFORTSMarket = () => {
  const {
    accounts,
    subAccountsList: subAccounts,
    usePwaGlobalSubAccount,
  } = useAlfaDirectContext();

  const pwaGlobalSubAccount = usePwaGlobalSubAccount?.();

  return useMemo(() => {
    if (!pwaGlobalSubAccount) {
      return isSingleFORTSMarketAvailable(accounts);
    }

    const selectedSubAccount = subAccounts.find(
      (subAccount) => subAccount.idSubAccount === pwaGlobalSubAccount?.id
    );

    const isSelectedAccountWithEFR = Boolean(
      accounts.find(
        (account) =>
          account.idAccount === selectedSubAccount?.idAccount &&
          account.isSingleFORTSMarket
      )
    );

    return isSelectedAccountWithEFR;
  }, [accounts, pwaGlobalSubAccount, subAccounts]);
};
