import { ObjectGroup } from '../client/entities';

import { ObjectTypeItem } from '../../types/core';

/**
 * @returns Базу для рассчета ПУ(дн) в процентах
 * */
export function getBaseDailyPLtoMarketCurPrice(
  objectType: ObjectTypeItem,
  Price: number | null,
  nominal: number,
  NominalCurPrice: number,
  MarketCurPrice: number,
  BackPosCost: number,
  DailyBuyVolume: number | null,
  TrnIn: number
): number | null {
  if (Price === null || DailyBuyVolume === null) {
    return null;
  }

  switch (objectType?.idObjectGroup) {
    case ObjectGroup.Bonds: {
      return (
        Math.abs(BackPosCost) +
        TrnIn * ((Price * nominal) / 100) +
        DailyBuyVolume / NominalCurPrice
      );
    }
    case ObjectGroup.Futures: {
      return Math.abs(BackPosCost) + DailyBuyVolume;
    }
    case ObjectGroup.Currency:
    case ObjectGroup.Metals: {
      return Math.abs(BackPosCost) + TrnIn * Price + DailyBuyVolume;
    }
    case ObjectGroup.Stocks:
    case ObjectGroup.EquityParticipations:
    case ObjectGroup.Receipts: {
      return (
        Math.abs(BackPosCost) + TrnIn * Price + DailyBuyVolume / MarketCurPrice
      );
    }

    default: {
      return 0;
    }
  }
}
