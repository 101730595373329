import { RootStore } from '../../useStore';

import { MarketBoardItem } from '../../../types/core';

/**
 * Выбирает торговую площадку по идентификатору торговой площадки
 * */
export function marketBoardById(
  store: RootStore
): (
  idMarketBoard: MarketBoardItem['idMarketBoard']
) => MarketBoardItem | undefined {
  return (idMarketBoard) =>
    store.marketBoardsTable.get('idMarketBoard', idMarketBoard);
}
