import { FreeOrderStatuses } from '@terminal/core/lib/rest/lkFreeOrders';

export interface FilterState {
  text: string | null;
  endDate: Date | undefined;
  startDate: Date | undefined;
  status: Array<FreeOrderStatuses>;
  isOpen: boolean;
  page: number;
}

export enum FilterActionsTypes {
  ToggleStatus = 'toggle-status',
  ToggleSideBar = 'toggle-sidebar',
  SetText = 'set-text',
  SetDates = 'set-dates',
  SetBlueprint = 'set-blueprint',
  Reset = 'reset',
  SetPage = 'set-page',
}

export interface FilterBlueprint {
  text: string | null;
  endDate: Date | undefined;
  startDate: Date | undefined;
  status: Array<FreeOrderStatuses>;
}

export interface Action {
  type: FilterActionsTypes;
  payload?: number | string | FilterBlueprint;
}

export interface ToggleStatusAction extends Action {
  type: FilterActionsTypes.ToggleStatus;
  payload: FreeOrderStatuses;
}

export interface ToggleSideBarAction extends Action {
  type: FilterActionsTypes.ToggleSideBar;
}

export interface SetTextAction extends Action {
  type: FilterActionsTypes.SetText;
  payload: string;
}

export interface SetBluePrintAction extends Action {
  type: FilterActionsTypes.SetBlueprint;
  payload: FilterBlueprint;
}

export interface ResetAction extends Action {
  type: FilterActionsTypes.Reset;
}

export interface SetPageAction extends Action {
  type: FilterActionsTypes.SetPage;
  payload: number;
}

export type FilterActions =
  | ToggleStatusAction
  | ToggleSideBarAction
  | SetTextAction
  | SetBluePrintAction
  | ResetAction
  | SetPageAction;
