import { Spinner } from '@alfalab/core-components/spinner';

import {
  getEducationThemeDocumentUrl,
  StrategyThemeInfo,
  ThemeDetail,
  useStrategyThemeInfo,
} from '../../../../entities/Education';

interface StrategyThemeDetailProps {
  themeId: number;
  onTest?: (theme: StrategyThemeInfo) => void;
}

export const StrategyThemeDetail = ({
  themeId,
  onTest,
}: StrategyThemeDetailProps) => {
  const { data: theme, isFetching: loading } = useStrategyThemeInfo(themeId);

  return (
    <>
      {theme && (
        <ThemeDetail
          name={theme.name}
          description={theme.details}
          onTest={() => onTest && onTest(theme)}
          passPercent={theme.passPercent}
          recommendations={theme.recommendations}
          videoMaterials={theme.videoMaterials}
          bookMaterials={theme.bookMaterials}
          getThemeDocumentUrl={getEducationThemeDocumentUrl}
        />
      )}

      <Spinner visible={loading} />
    </>
  );
};
