import get from 'lodash/get';

import { TableColumnKey } from '@terminal/core/types/tableColumn';

export const onTableOrder = (item: any, key = '') => {
  let operationHistoryData: number | string | Date | undefined = get(item, key);

  if (key === TableColumnKey.OperationDate) {
    operationHistoryData = item.operation.timeOperation;
  }

  return operationHistoryData;
};
