import { useEffect, useMemo, useState } from 'react';
import { Divider } from '@alfalab/core-components/divider';
import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';
import { TrashCanLineMIcon } from '@alfalab/icons-glyph/TrashCanLineMIcon';

import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import {
  SecuritiesDocumentType,
  SecuritiesDraftDetails,
  SecuritiesManager,
  SecuritiesPlaceItem,
  SecuritiesPosition,
  SecuritiesReasonDocument,
  SecuritiesReferenceInfo,
  SecuritiesUserType,
} from '@terminal/core/lib/rest/lkSecurities';

import { ConfirmModal } from '../../../components/ConfirmModal';
import { EXTERNAL_LINKS } from '../../../const/externalUrls';
import { SECURITY_DEPONENT_PLACE_TO_ALTA } from '../../shared';
import { StepFooter } from '../StepFooter';
import {
  SummaryCounterparty,
  SummaryField,
  SummaryPosition,
  SummaryReason,
} from '../Summary';

import {
  useSecuritiesDraftConfirm,
  useSecuritiesDraftReject,
} from '../../hooks';

import { DraftCompleteResult } from './types';

import styles from './DraftItemDetail.module.css';

interface DraftItemDetailProps {
  draft: SecuritiesDraftDetails | undefined;
  pending: boolean;
  treaty: Treaty | null;
  placeList: SecuritiesPlaceItem[];
  onComplete?: (params: DraftCompleteResult) => void;
}

export const DraftItemDetail = ({
  draft,
  pending,
  treaty,
  placeList,
  onComplete,
}: DraftItemDetailProps) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const {
    mutateAsync: rejectDraft,
    isLoading: rejectDraftPending,
    isSuccess: rejectDraftSuccess,
  } = useSecuritiesDraftReject();

  const {
    mutateAsync: confirmSecuritiesDraft,
    isLoading: securitiesDraftConfirmPending,
  } = useSecuritiesDraftConfirm();

  const handleDelete = () => {
    if (draft && treaty) {
      rejectDraft({
        treaty: treaty.treaty,
        id: draft.id,
      });
    }
  };

  const handleConfirm = () => {
    if (draft && treaty) {
      confirmSecuritiesDraft({
        params: {
          treaty: treaty.treaty,
          ...draft,
        },
        signOptions: {
          successTitle: `Поручение принято к исполнению`,
          successCallback: (status) => {
            onComplete &&
              onComplete({
                type: 'confirm',
                result: 'success',
                status: status?.data?.orderStatus,
              });
          },
          errorCallback: (err) => {
            onComplete &&
              onComplete({
                type: 'confirm',
                result: 'error',
                errorCode: err?.code,
                message: err?.message,
              });
          },
        },
      });
    }
  };

  useEffect(() => {
    if (rejectDraftSuccess) {
      setDeleteModal(false);
      onComplete &&
        onComplete({
          type: 'reject',
          result: 'success',
          draft,
        });
    }
  }, [rejectDraftSuccess, onComplete, draft]);

  const [clientPlaceName, contrPlaceName] = useMemo(() => {
    if (placeList && draft) {
      const clientPlace = placeList.find(
        (place) =>
          place.deponetPlaceCode === draft.order.client.place.toUpperCase()
      );
      const contrPlace = placeList.find(
        (place) =>
          place.deponetPlaceCode === draft.order.counter.place.toUpperCase()
      );

      return [
        clientPlace ? clientPlace.name : '',
        contrPlace ? contrPlace.name : '',
      ];
    }

    return ['', ''];
  }, [placeList, draft]);

  if (pending) {
    return <Loader />;
  }

  if (!draft || !draft.order || !treaty) {
    return null;
  }

  const { order } = draft;
  const isIn = draft.order.transferType === 'in';
  const isReestr = draft.order.counter.place.toUpperCase() === 'REESTR';
  const isInternal = draft.order.isInternal;

  const position: SecuritiesPosition = {
    treaty: treaty.treaty,
    accCode: isIn ? order.input.subAccountId : order.output.subAccountId,
    nickName: '',
    placeCode:
      SECURITY_DEPONENT_PLACE_TO_ALTA[draft.order.client.place.toUpperCase()] ||
      draft.order.client.place.toUpperCase(),
    deponetPlaceCode: draft.order.client.place,
    placeName: clientPlaceName,
    placeDescription: clientPlaceName,
    depoAccount: isInternal
      ? draft.order.client.accDepo
      : draft.order.client.account,
    deponent: order.register.name,
    depositary: draft.order.client.acceptor,
    dpName: clientPlaceName,
    existActive: false,
  };
  const referenceInfo: SecuritiesReferenceInfo = {
    clientRequisites: {
      clientCode: draft.order.client.code,
      clientAcc: draft.order.client.account,
      clientDvn: draft.order.client.dvn,
    },
    counteragentRequisites: {
      clientCode: draft.order.counter.code,
      clientAcc: draft.order.counter.account,
      clientDvn: draft.order.counter.dvn,
    },
    swift: '',
    reference: order.reference,
    comission: order.comission,
  };
  const counterpartyPlace = {
    id: 1,
    name: contrPlaceName,
    placeCode:
      SECURITY_DEPONENT_PLACE_TO_ALTA[
        draft.order.counter.place.toUpperCase()
      ] || draft.order.counter.place.toUpperCase(),
    deponetPlaceCode: draft.order.counter.place,
  };
  const userList: SecuritiesUserType[] = [
    {
      id: 1,
      name: 'OWNER',
      description: 'Счёт владельца',
    },
  ];
  const manager: SecuritiesManager = {
    managerDocType: order.document.docType,
    managerDocSerNo: order.document.docSeria + ' ' + order.document.docNum,
    managerDocOrg: order.document.docOrg,
    managerDocDate: order.document.docDate,
    managerFullName: order.register.name,
    isFirm: false,
    requisites: null,
    reference: '',
  };
  const documentTypes: SecuritiesDocumentType[] = [
    {
      id: 1,
      docType: 'BASE',
      name: order.contract.type,
      isChangeOfQwnerDoc: order.isTrans,
      isNoChangeOfQwnerDoc: order.isTrans,
      isTradeVolumeRequired: false,
      forReestr: isReestr,
      sortNumber: 1,
    },
  ];
  const reasonDocuments: SecuritiesReasonDocument[] = [
    {
      id: 1,
      docType: 'BASE',
      docName: order.contract.type,
      name: order.contract.type,
      number: order.contract.number,
      date: order.contract.createdDate,
    },
  ];

  return (
    <div className={styles.body}>
      <Typography.Title view="small" className={styles.title} tag="div">
        {draft.order.transferType === 'in'
          ? 'Поручение на зачисление'
          : 'Поручение на списание'}
      </Typography.Title>
      <Divider className={styles.divider} />
      <SummaryPosition
        title={isIn ? 'Куда' : 'Откуда'}
        position={position}
        clientReferenceInfo={
          referenceInfo ? referenceInfo.clientRequisites : undefined
        }
        counteragentAlfaBank={order.isInternal}
      />
      <Divider className={styles.divider} />
      <SummaryCounterparty
        title={isIn ? 'Откуда' : 'Куда'}
        selectedCounterpartyPlace={counterpartyPlace}
        ownershipChange={order.isTrans}
        userTypeList={userList}
        userTypeListPending={false}
        marketAlfa={position ? position.placeCode : ''}
        accountType="OWNER"
        depoAccount={
          isReestr ? order.register.acc : draft.order.counter.account
        }
        depoSection={draft.order.counter.dvn}
        depoCode={draft.order.counter.code}
        counterpartyPlace={draft.order.counter.acceptor}
        counterpartyPlaceOther=""
        conteragentAccDepo={draft.order.counter.accDepo}
        counteragentAlfaBank={order.isInternal}
        counteragentReferenceInfo={
          referenceInfo ? referenceInfo.counteragentRequisites : undefined
        }
        swift={order.swiftCode}
        manager={manager}
        docNumber={manager.managerDocSerNo || ''}
        docDate={manager.managerDocDate || ''}
        docWhom={manager.managerDocOrg || ''}
        orgName=""
        orgOGRN=""
        orgWhen=""
        orgWhom=""
      />
      <Divider className={styles.divider} />
      <section className={styles.detailSection}>
        <Typography.Text className={styles.title} weight="bold" tag="div">
          Ценная бумага
        </Typography.Text>
        <div className={styles.section}>
          <SummaryField label="Наименование" value={order.assetName} />
          <SummaryField label="Количество" value={order.qty.toLocaleString()} />
        </div>
      </section>
      {(Boolean(order.comment) ||
        Boolean(order.contract.type) ||
        order.comission.amount > 0) && (
        <SummaryReason
          documentTypes={documentTypes}
          reasonDocuments={reasonDocuments}
          ownershipChange={order.isTrans}
          reasonOther={order.comment}
          referenceInfo={referenceInfo}
          comissionMultiplier={1}
          operationDirection={order.transferType}
        />
      )}
      {Boolean(order.reference) && (
        <div className={styles.section}>
          <SummaryField label="Референс" value={order.reference} />
        </div>
      )}
      <StepFooter
        next={{
          action: handleConfirm,
          label: 'Подать поручение',
          loading: securitiesDraftConfirmPending,
          hint: (
            <Typography.Text
              view="secondary-small"
              className={styles.agree}
              tag="div"
            >
              Продолжая, я подтверждаю, что ознакомлен с&nbsp;
              <Link
                className={styles.agreeLink}
                href={EXTERNAL_LINKS.SECURITIES_DEPO_TARIFFS}
                target="_blank"
              >
                депозитарными тарифами
              </Link>
            </Typography.Text>
          ),
        }}
        prev={{
          action: () => setDeleteModal(true),
          label: 'Отклонить поручение',
        }}
      />

      <ConfirmModal
        open={deleteModal}
        title="Отклонить поручение?"
        text={
          <>
            После отклонения, поручение на {isIn ? 'зачисление' : 'списание'}{' '}
            {order.assetName} {order.qty} шт будет удалено из списка
          </>
        }
        cancelLabel="Оставить"
        confirmLabel="Отклонить"
        confirmIcon={<TrashCanLineMIcon width={18} height={18} />}
        confirmLoading={rejectDraftPending}
        onClose={() => setDeleteModal(false)}
        onConfirm={handleDelete}
      />
    </div>
  );
};
