import cn from 'classnames';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { ChevronForwardMIcon } from '@alfalab/icons-glyph/ChevronForwardMIcon';
import { LockClosedMIcon } from '@alfalab/icons-glyph/LockClosedMIcon';

import styles from './ThemePlate.module.css';

interface ThemePlateProps {
  index?: number;
  title: React.ReactNode;
  comment?: React.ReactNode;
  disabled?: boolean;
  addons?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const ThemePlate = ({
  index,
  title,
  comment,
  disabled = false,
  addons,
  className,
  onClick,
}: ThemePlateProps) => {
  const lock = comment ? (
    <TooltipDesktop content={comment}>
      <LockClosedMIcon width={12} height={12} />
    </TooltipDesktop>
  ) : (
    <LockClosedMIcon width={12} height={12} />
  );

  return (
    <div
      className={cn(styles.container, className, {
        [styles.disabled]: disabled,
      })}
      onClick={!disabled ? onClick : undefined}
      role={onClick ? 'button' : undefined}
    >
      {index && <div className={styles.index}>{index}</div>}
      <div className={styles.title}>{title}</div>
      {addons && <div className={styles.addons}>{addons}</div>}
      <div className={styles.actionIcon}>
        {disabled ? lock : <ChevronForwardMIcon width={12} height={12} />}
      </div>
    </div>
  );
};
