import isEqual from 'lodash/isEqual';
import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { CheckmarkSIcon } from '@alfalab/icons-glyph/CheckmarkSIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { ChevronUpCompactMIcon } from '@alfalab/icons-glyph/ChevronUpCompactMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { PlusMIcon } from '@alfalab/icons-glyph/PlusMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside, usePrevious } from '@terminal/core/hooks';
import { trackOnAddDesktop } from '@terminal/core/lib/analytics';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';

import { featureFlags } from '../../../../shared/lib/featureFlags';
import { Tab } from '../Tab';
import { TabButton } from '../TabButton';
import { TabContextMenu } from '../TabContextMenu';

import { useTabsOverflow } from '../../hooks/useTabsOverflow';

import { ContextMenuHeaderTabConfig } from '../../model/types';

import styles from './TabsRow.module.css';

const childWindowTabId = parseInt(
  window.sessionStorage.getItem('child_window_tab_id') || ''
);
const isParentWindow = Number.isNaN(childWindowTabId);

export const TabsRow = () => {
  const [overflowingTabsMenuVisible, setOverflowingTabsMenuVisible] =
    useState(false);

  const [headerTabContextMenuConfig, setHeaderTabContextMenuConfig] =
    useState<ContextMenuHeaderTabConfig>({
      isShow: false,
      tabId: 0,
      clientX: 0,
      clientY: 0,
    });

  const tabsRowRef = useRef<HTMLDivElement>(null);
  const allTabsButtonRef = useRef<HTMLDivElement>(null);
  const tabsDropdownRef = useRef<HTMLDivElement>(null);
  const headerContextMenuRef = useRef<HTMLDivElement>(null);

  const [
    getActiveLayoutKey,
    createNewLayout,
    removeLayout,
    setActiveLayoutKey,
  ] = useStore(
    (state) => [
      state.getActiveLayoutKey,
      state.createNewLayout,
      state.removeLayout,
      state.setActiveLayoutKey,
    ],
    shallow
  );

  const activeLayoutKey = getActiveLayoutKey();

  const { lastAutoSavedConfig } = useStore(activeConfigurationSelector);
  const { layoutNames, models } = lastAutoSavedConfig;

  const layoutTabs = layoutNames.map((_, key) => key);

  const overflowingIndices = useTabsOverflow(
    tabsRowRef,
    layoutTabs,
    activeLayoutKey
  );

  useOnClickOutside(tabsDropdownRef, () =>
    setOverflowingTabsMenuVisible(false)
  );

  useOnClickOutside(headerContextMenuRef, () =>
    setHeaderTabContextMenuConfig({
      isShow: false,
      tabId: 0,
      clientX: 0,
      clientY: 0,
    })
  );

  const prevOverflowingIndices = usePrevious(overflowingIndices);

  useEffect(() => {
    if (!isEqual(prevOverflowingIndices, overflowingIndices)) {
      setOverflowingTabsMenuVisible(false);
    }
  }, [prevOverflowingIndices, overflowingIndices]);

  return (
    <div className={styles.headerTabsContainer}>
      {featureFlags.get('WORKSPACE_TAB_CONTEXT_MENU_ENABLED') &&
        isParentWindow &&
        headerTabContextMenuConfig.isShow && (
          <TabContextMenu
            ref={headerContextMenuRef}
            setHeaderTabContextMenuConfig={setHeaderTabContextMenuConfig}
            tabId={headerTabContextMenuConfig.tabId}
            clientX={headerTabContextMenuConfig.clientX}
            clientY={headerTabContextMenuConfig.clientY}
          />
        )}

      <div ref={tabsRowRef} className={styles.layoutTabs}>
        {isParentWindow ? (
          layoutTabs.map((key) => (
            <Tab
              key={key}
              tabKey={key}
              setHeaderTabContextMenuConfig={setHeaderTabContextMenuConfig}
            />
          ))
        ) : (
          <Tab
            key={models[childWindowTabId].getRoot().getId()}
            tabKey={childWindowTabId}
            setHeaderTabContextMenuConfig={setHeaderTabContextMenuConfig}
            isChildWindow
          />
        )}

        {isParentWindow && (
          <div className={styles.auxButtons}>
            {isParentWindow && (
              <>
                <TabButton
                  ref={allTabsButtonRef}
                  label={`+ ${overflowingIndices.length}`}
                  rightAddons={
                    overflowingTabsMenuVisible ? (
                      <ChevronUpCompactMIcon width={18} height={18} />
                    ) : (
                      <ChevronDownCompactMIcon width={18} height={18} />
                    )
                  }
                  onClick={() => setOverflowingTabsMenuVisible(true)}
                />
                <Dropdown
                  ref={tabsDropdownRef}
                  anchorElement={allTabsButtonRef.current}
                  open={overflowingTabsMenuVisible}
                  offset={[0, 12]}
                  position="bottom-start"
                  className={styles.tabsMenu}
                >
                  {layoutTabs
                    .filter((key) => overflowingIndices.includes(key))
                    .map((key) => (
                      <Dropdown.Item
                        key={key}
                        content={layoutNames[key]}
                        rightIcon={
                          <>
                            {key === activeLayoutKey && (
                              <CheckmarkSIcon
                                height={18}
                                width={18}
                                color="var(--color-dark-graphic-secondary)"
                              />
                            )}
                            <IconButton
                              size="xxs"
                              view="secondary"
                              icon={CrossMIcon}
                              onClick={(event) => {
                                event.stopPropagation();
                                removeLayout(key);
                                setOverflowingTabsMenuVisible(false);
                              }}
                            />
                          </>
                        }
                        onClick={() => {
                          setActiveLayoutKey(key);
                          setOverflowingTabsMenuVisible(false);
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          setHeaderTabContextMenuConfig({
                            isShow: true,
                            tabId: key,
                            clientX: e.clientX,
                            clientY: e.clientY,
                          });
                        }}
                      />
                    ))}
                </Dropdown>
              </>
            )}
          </div>
        )}
      </div>

      <div className={styles.auxButtons}>
        <TabButton
          icon
          label={<IconButton size="xs" view="secondary" icon={PlusMIcon} />}
          onClick={() => {
            trackOnAddDesktop();
            createNewLayout();
          }}
        />
      </div>
    </div>
  );
};
