import { useEffect, useMemo } from 'react';

import { FrontEndType } from '../../client/entities';
import { EntityType } from '../../client/entityTypes';
import { StreamingService } from '../streaming';

import { useStore } from '../../../store';

import { FinInfoExt as FinInfoExtType } from '../../../types/quotes';
import { fi, FinInfoExtRecord } from './types';

/*
 Работа с энтитями FinInfoExt

 Фактически эти данные живут в сторе зюстанда. Ниже есть листенер который при получении этих записей
 кладет их в стор.
*/

/*
  Хук возвращает набор записей FinInfoExt 
  {
    1444950: { //id сбера
      idGate: 123,
      idFI: 1444950,
      prevLast: 132.7
    }
  }

*/
export function useFinInfoExt(
  fi: number | number[]
): Record<fi, FinInfoExtType | undefined> {
  const fis = useMemo(() => (Array.isArray(fi) ? fi : [fi]), [fi]);

  // На самом деле это по сути просто селектор по стору
  // Но есть наюнс в том что на маунте мы открываем подписку и на клине закрываем
  const finInfoExts = useStore((store) => store.finInfoExt);

  // Важно что бы здесь было useMemo иначе можно словить бексонечный рендер в компонентах
  // Потому что на каждый рендер будет создаваться новый объект и если этот объект будет использован
  // как зависимость useEffect который в свою очередь вызывае setState будет бексонечный цикл
  const result = useMemo(() => {
    return fis.reduce<FinInfoExtRecord>(
      (acc, fi) => ({ ...acc, [fi]: finInfoExts[fi] }),
      {}
    );
  }, [fis, finInfoExts]);

  useEffect(() => {
    const unsubscribe = StreamingService.subscribe({
      frontend: FrontEndType.AuthAndOperInitServer,
      entity: EntityType.FinInfoExtEntity,
      fi: fis,
    });

    return () => {
      StreamingService.unsubscribe(
        {
          frontend: FrontEndType.AuthAndOperInitServer,
          entity: EntityType.FinInfoExtEntity,
          fi: fis,
        },
        unsubscribe
      );
    };
  }, [fis]);

  return result;
}
