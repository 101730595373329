import { format } from 'date-fns';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Loader } from '@alfalab/core-components/loader';
import { Space } from '@alfalab/core-components/space';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { DocumentsList } from '../../components/DocumentsList';

import { useAccountInfo, useAccountInfoGI } from '../../../accountsInfo/hooks';

import formstyles from '../../../styles/forms.module.css';
import styles from './TaxInfo.module.css';

interface TaxInfoProps {
  treaty: Treaty | null;
  isMobile?: boolean;
}

export const TaxInfo = ({ treaty, isMobile }: TaxInfoProps) => {
  const isGoInvest = !isMobile;

  const { data: accountInfo, isFetching: accountInfoPending } = useAccountInfo(
    treaty?.treaty,
    !isGoInvest
  );

  const { data: accountInfoGI, isFetching: accountInfoPendingGI } =
    useAccountInfoGI(treaty?.treaty, isGoInvest);

  if (accountInfoPending || accountInfoPendingGI) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  const openDate = accountInfoGI ? new Date(accountInfoGI?.openDate) : null;

  return (
    <div className={styles.container}>
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        color="primary"
      >
        Генеральное соглашение
      </Typography.Text>
      <Space fullWidth={true} direction="vertical" className={styles.info}>
        <Row>
          <Col width={6}>
            <Typography.Text
              view="secondary-large"
              className={formstyles.label}
            >
              Номер
            </Typography.Text>
            <Typography.Text
              view="secondary-large"
              className={formstyles.staticValue}
            >
              {treaty?.treaty}
              {openDate ? ` от ${format(openDate, 'dd.MM.yyyy')}` : null}
            </Typography.Text>
          </Col>
          <Col width={6}>
            <Typography.Text
              view="secondary-large"
              className={formstyles.label}
            >
              Владелец
            </Typography.Text>
            <Typography.Text
              view="secondary-large"
              className={formstyles.staticValue}
            >
              {isGoInvest ? accountInfo?.owner : accountInfoGI?.owner}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col width={6}>
            <Typography.Text
              view="secondary-large"
              className={formstyles.label}
            >
              Налоговая ставка
            </Typography.Text>
            <Typography.Text
              view="secondary-large"
              className={formstyles.staticValue}
            >
              <span>
                {isGoInvest ? accountInfoGI?.taxRate : accountInfo?.taxRate}%
              </span>
              <Tooltip
                targetClassName={styles.tooltipTrigger}
                contentClassName={styles.tooltipContent}
                position="top"
                preventOverflow={false}
                trigger="hover"
                content={
                  <>
                    Налоговая ставка по&nbsp;операциям с&nbsp;ценными бумагами
                    и&nbsp;ПФИ:
                    <br />– 13% для налоговых резидентов РФ
                    <br />– 30% для налоговых нерезидентов РФ
                  </>
                }
                fallbackPlacements={['left', 'right']}
              >
                <InformationCircleLineSIcon
                  height={16}
                  width={16}
                  color="#8D8D93"
                />
              </Tooltip>
            </Typography.Text>
          </Col>
        </Row>
      </Space>

      <DocumentsList
        treaty={treaty}
        className={styles.documentList}
        isMobile={isMobile}
      />
    </div>
  );
};
