import React from 'react';
import { Divider } from '@alfalab/core-components/divider';
import { Typography } from '@alfalab/core-components/typography';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { OrderType } from '@terminal/core/lib/client/entities';
import { SlippageType, StopOrderType } from '@terminal/core/types/ui';

import { useOrderBookContext } from '../../../../context';

import { useIsLotCount } from '../../../../../../shared/hooks/useIsLotCount';
import { useOrderBookFinInfoExt } from '../../../../hooks/useOrderBookFinInfoExt';
import { useSettings } from '../../../../hooks/useSettings';

import styles from './OrderBookSettings.module.css';

interface Props {
  idFi: number;
  saveForm: (
    form: boolean,
    spread: boolean,
    empty: boolean,
    tralling: boolean,
    centering: boolean,
    stopOrderType: StopOrderType,
    showYield: boolean
  ) => void;
  setIsOpenLimitsModal: (isOpen: boolean) => void;
  setIsOpenContextMenuModal: (isOpen: boolean) => void;
  setIsOpenSlippageModal: (isOpen: boolean) => void;
  setIsOpenQuantityModal: (isOpen: boolean) => void;
  open: boolean;
  hideForm?: boolean;
  showSpread?: boolean;
  showYield?: boolean;
  showEmptyPrice?: boolean;
  sendTrallingOrders?: boolean;
  autoCenter?: boolean;
}

export const OrderBookSettings = React.forwardRef(
  ({
    idFi,
    showSpread,
    showYield,
    showEmptyPrice,
    saveForm,
    hideForm,
    autoCenter,
    setIsOpenLimitsModal,
    setIsOpenContextMenuModal,
    setIsOpenQuantityModal,
    setIsOpenSlippageModal,
    sendTrallingOrders,
  }: Props) => {
    const finInfoExt = useOrderBookFinInfoExt(idFi);
    // //Не можем показывать разреженный стакан и спред без шага цен (он в finInfoExt)
    const isShowSpread = finInfoExt && showSpread;

    const isShowEmptyPrice = finInfoExt && showEmptyPrice;

    const { stopOrderType, defaultQuantity, slippageValue, slippageType } =
      useOrderBookContext();

    const isLotCount = useIsLotCount();

    const pcsVol = finInfoExt?.lot
      ? { quantity: finInfoExt.lot, assetUnits: 'шт' }
      : undefined;

    const { groups } = useSettings({
      autoCenter: autoCenter ?? false,
      hideForm: hideForm ?? false,
      showSpread: isShowSpread ?? false,
      showYield: showYield ?? true,
      showEmptyPrice: isShowEmptyPrice ?? false,
      stopOrderType: stopOrderType || OrderType.STL,
      saveForm,
      setIsOpenLimitsModal,
      setIsOpenContextMenuModal,
      setIsOpenQuantityModal,
      setIsOpenSlippageModal,
      defaultQuantity:
        defaultQuantity ||
        (isLotCount ? { quantity: 1, assetUnits: 'лот' } : pcsVol),
      sendTrallingOrders: sendTrallingOrders ?? false,
      slippageType: slippageType || SlippageType.TICK,
      slippageValue: slippageValue || 1,
    });

    return (
      <div>
        {groups.map((group, i) => (
          <div key={group.id}>
            {group.title ? (
              <Typography.Text
                weight="bold"
                tag="div"
                view="secondary-large"
                className={styles.groupTitle}
              >
                {group.title}
              </Typography.Text>
            ) : null}
            {group.items.map((item: JSX.Element) => {
              return item ? (
                <Dropdown.Item
                  key={item.key}
                  className={styles.dropdownItem}
                  content={item}
                />
              ) : null;
            })}
            {i < groups.length - 1 && <Divider className={styles.divider} />}
          </div>
        ))}
      </div>
    );
  }
);
