import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { FreeOrder } from '@terminal/core/lib/rest/lkFreeOrders';

import { FormatedText } from '../../shared';
import { statusIcons } from './constants';

import styles from './FreeOrders.module.css';

interface FreeOrderHistoryDetailsProps {
  order: FreeOrder;
}

export const FreeOrderHistoryDetails = ({
  order,
}: FreeOrderHistoryDetailsProps) => {
  const StatusIcon = statusIcons[order.status] || CrossMIcon;

  return (
    <div className={styles.detailsContainer}>
      <div className={styles.detailsTitle}>
        <Typography.Text
          view="primary-small"
          weight="bold"
          color="primary"
          tag="p"
        >
          Произвольное поручение
        </Typography.Text>
        <span>
          <StatusIcon
            fill="var(--color-light-graphic-secondary)"
            width={12}
            height={12}
          />
          <Typography.Text view="secondary-large" color="secondary">
            {order.statusDescription}
          </Typography.Text>
        </span>
      </div>
      <div className={styles.detailsSubtitle}>
        <Typography.Text view="secondary-small" color="secondary">
          {format(new Date(order.date), 'dd MMMM yyyy, EEEE', { locale: ru })}
        </Typography.Text>
      </div>
      <Gap size="xl" />
      <FormatedText text={order.text} />
    </div>
  );
};
