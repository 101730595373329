import { Button } from '@alfalab/core-components/button';
import { Divider } from '@alfalab/core-components/divider';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Space } from '@alfalab/core-components/space';

interface ActionProps {
  action: () => void;
  label?: string;
  hint?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

interface StepFooterProps {
  next: ActionProps;
  prev?: ActionProps;
}

export const StepFooter = ({ next, prev }: StepFooterProps) => {
  const nextBtn = (
    <Button
      block
      size="s"
      disabled={next.disabled}
      onClick={next.action}
      loading={next.loading}
    >
      {next.label || 'Продолжить'}
    </Button>
  );

  return (
    <Space fullWidth direction="vertical" size={32}>
      <Divider />
      <Row>
        {prev && (
          <Col width={6}>
            <Button
              block
              view="tertiary"
              size="s"
              disabled={prev.disabled}
              onClick={prev.action}
              loading={prev.loading}
            >
              {prev.label || 'Назад'}
            </Button>
            {prev.hint}
          </Col>
        )}
        <Col width={6}>
          {nextBtn}
          {next.hint}
        </Col>
      </Row>
    </Space>
  );
};
