import { InstructionThemeDTO } from '../api/types/InstructionThemeDTO';
import { InstructionTheme } from '../model/types/InstructionTheme';

export function mapInstructionTheme(
  dto: InstructionThemeDTO
): InstructionTheme {
  return {
    id: dto.id,
    name: dto.name,
    details: dto.details,
    bookMaterials: dto.bookMaterials || [],
    videoMaterials: dto.videoMaterials || [],
    hasQuestions: dto.hasQuestions || false,
  };
}
