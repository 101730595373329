import {
  addDays,
  endOfDay,
  isBefore,
  isValid,
  isWithinInterval,
  parse,
  parseISO,
  startOfDay,
} from 'date-fns';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import { DocumentDateType } from '@terminal/core/lib/rest/lkDocuments';

export function isPeriodValid(
  dateType: DocumentDateType,
  periodStart: string,
  periodEnd: string,
  year: string,
  minDate?: string | null,
  maxDate?: string | null
): boolean {
  const minPeriodStart = startOfDay(
    minDate ? parseISO(minDate) : parseISO('2001-01-01T00:00:00')
  );
  const maxPeriodStart = addDays(maxDate ? parseISO(maxDate) : new Date(), -1);
  const maxPeriodEnd = addDays(maxDate ? parseISO(maxDate) : new Date(), -1);

  if (dateType === 'year') {
    return Boolean(year);
  } else if (dateType === 'date') {
    return isDateValid(periodStart, undefined, {
      minDate: minPeriodStart,
      maxDate: maxPeriodStart,
    });
  } else if (dateType === 'period') {
    return isIntervalValid(periodStart, periodEnd, undefined, {
      minDate: minPeriodStart,
      maxDate: maxPeriodEnd,
    });
  }

  return true;
}

export const getDateFromString = (
  date: string,
  format = DEFAULT_VIEW_DATE_FORMAT
) => {
  return parse(date, format, new Date());
};

export const isDateValid = (
  dateString: string,
  format = DEFAULT_VIEW_DATE_FORMAT,
  validationInterval: {
    minDate: Date;
    maxDate: Date;
  }
): boolean => {
  if (validationInterval) {
    const date = getDateFromString(dateString, format);

    const minDate = validationInterval.minDate;
    const maxDate = validationInterval.maxDate;

    try {
      return (
        isValid(date) &&
        isWithinInterval(date, { start: minDate, end: maxDate })
      );
    } catch (e) {
      return false;
    }
  }

  return isValid(getDateFromString(dateString, format));
};

export const isIntervalValid = (
  start: string,
  end: string,
  format = DEFAULT_VIEW_DATE_FORMAT,
  validationInterval: {
    minDate: Date;
    maxDate: Date;
  }
): boolean => {
  const startDate = startOfDay(getDateFromString(start, format));
  const endDate = endOfDay(getDateFromString(end, format));

  const minDate = validationInterval?.minDate;
  const maxDate = validationInterval?.maxDate;

  return (
    isDateValid(start, undefined, { minDate, maxDate: endDate }) &&
    isDateValid(end, undefined, { minDate: startDate, maxDate }) &&
    isBefore(startDate, endDate)
  );
};

export function isCountValid(count: string) {
  return parseInt(count) > 0;
}
