import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Input } from '@alfalab/core-components/input';
import { Loader } from '@alfalab/core-components/loader';
import { Select, SelectProps } from '@alfalab/core-components/select';
import { Field } from '@alfalab/core-components/select/components/field';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';
import { Switch } from '@alfalab/core-components/switch';
import { Textarea } from '@alfalab/core-components/textarea';

import {
  SecuritiesContragent,
  SecuritiesPlaceItem,
} from '@terminal/core/lib/rest/lkSecurities';

import { FormControlSummary } from '../../../../shared/ui/FormControlSummary';
import {
  TooltipConteragent,
  TooltipConterpartyDepoAccount,
  TooltipConterpartyDepoCode,
  TooltipConterpartyDepoSection,
  TooltipConterpartyName,
  TooltipConterpartyPlace,
} from '../../components/Tooltips';
import {
  getDepoAccountLabel,
  isConteragentAccDepoRequired,
  isConteragentShow,
  isContrAlfaBankShow,
  isContrTreatyRequired,
  isCounterpartyRequired,
  isDepoCodeCounterpartyRequired,
  isDepoSectionCounterpartyRequired,
  isOtherPlaceNameRequired,
  SecurityPlaceCode,
  securityPlaceKey,
} from '../../shared';

interface CounterpartyCommonFormProps {
  counteragentAlfaBank: boolean;
  onCounteragentAlfaBankChange: (check: boolean) => void;
  conteragents?: SecuritiesContragent[];
  conteragentsPending?: boolean;
  selectedCounterpartyPlace: SecuritiesPlaceItem | null;
  conteragentAccDepo: string;
  market: string;
  marketAlfa: string;
  selectedConteragentKey: string;
  userType: string;
  userTypeListPending: boolean;
  depoAccount: string;
  depoSection: string;
  depoCode: string;
  counterpartyPlace: string;
  counterpartyPlaceOther: string;
  selectedRequisiteKey: string;
  ownershipChange: boolean;
  transferType: 'in' | 'out';

  placeListOptions: { key: string; content: string }[];
  placeListPending: boolean;
  conteragentsOptions: { key: string; content: string }[];
  conteragentRequisitesOptions: { key: string; content: string }[];
  counterAlfaTreatyOptions: { key: string; content: string }[];
  conterpartyNameShow: boolean;
  conterpartyRequisitesEnabled: boolean;
  conterpartyDepoAccountShow: boolean;
  handlePlaceChange: SelectProps['onChange'];
  handleConteragentChange: SelectProps['onChange'];
  handleRequisiteChange: SelectProps['onChange'];
  handleCounterAlfaTreatyChange: SelectProps['onChange'];

  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleBlur: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  touched: { [s: string]: boolean };
  errors: { [s: string]: string | boolean };
}

export const CounterpartyCommonForm = ({
  counteragentAlfaBank,
  onCounteragentAlfaBankChange,
  conteragents,
  conteragentsPending,
  selectedCounterpartyPlace,
  conteragentAccDepo,
  marketAlfa,
  market,
  selectedConteragentKey,
  userType,
  depoAccount,
  depoSection,
  depoCode,
  counterpartyPlace,
  counterpartyPlaceOther,
  selectedRequisiteKey,
  ownershipChange,
  transferType,

  placeListOptions,
  placeListPending,
  conteragentsOptions,
  conteragentRequisitesOptions,
  counterAlfaTreatyOptions,
  conterpartyNameShow,
  conterpartyRequisitesEnabled,
  conterpartyDepoAccountShow,
  handlePlaceChange,
  handleConteragentChange,
  handleRequisiteChange,
  handleCounterAlfaTreatyChange,

  handleChange,
  handleBlur,
  touched,
  errors,
}: CounterpartyCommonFormProps) => {
  const counterpartyMarket = selectedCounterpartyPlace
    ? selectedCounterpartyPlace.placeCode
    : '';

  const showConteragentAccDepo =
    isConteragentAccDepoRequired(counteragentAlfaBank) &&
    selectedCounterpartyPlace;
  const showDepoAcc = !showConteragentAccDepo && selectedCounterpartyPlace;
  const showContragent =
    isConteragentShow(counterpartyMarket, marketAlfa, counteragentAlfaBank) &&
    selectedCounterpartyPlace;
  const showCounterparty =
    isCounterpartyRequired(
      counterpartyMarket,
      userType,
      counteragentAlfaBank
    ) && selectedCounterpartyPlace;
  const showOtherPlaceName =
    isOtherPlaceNameRequired(counterpartyMarket, counteragentAlfaBank) &&
    selectedCounterpartyPlace;
  const showDepoSectionCounterparty =
    isDepoSectionCounterpartyRequired(
      market,
      marketAlfa,
      counteragentAlfaBank
    ) && selectedCounterpartyPlace;
  const showDepoCodeCounterparty =
    isDepoCodeCounterpartyRequired(market, marketAlfa, counteragentAlfaBank) &&
    selectedCounterpartyPlace;
  const showContrTreaty =
    isContrTreatyRequired(
      ownershipChange,
      transferType,
      counteragentAlfaBank
    ) && selectedCounterpartyPlace;

  const contragentNameNode = (
    <Textarea
      label={
        counterpartyMarket === 'REESTR'
          ? 'Полное наименование контрагента'
          : 'Наименование контрагента'
      }
      labelView="outer"
      block
      autoComplete="off"
      size="s"
      name="counterpartyPlace"
      value={counterpartyPlace}
      disabled={!conterpartyRequisitesEnabled}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched.counterpartyPlace ? errors.counterpartyPlace : ''}
      rightAddons={<TooltipConterpartyName />}
    />
  );

  return (
    <Space fullWidth direction="vertical">
      {isContrAlfaBankShow(ownershipChange, transferType) && (
        <Row>
          <Col width={12}>
            <Switch
              label="Контрагент в Go Invest"
              checked={counteragentAlfaBank}
              disabled={
                counterpartyMarket === SecurityPlaceCode.REESTR ||
                marketAlfa === SecurityPlaceCode.EUROTRADE
              }
              onChange={(_, payload) =>
                onCounteragentAlfaBankChange(payload ? payload.checked : false)
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col width={6}>
          <Select
            label="Место хранения"
            labelView="outer"
            placeholder="Выберите место хранения"
            size="s"
            block
            options={placeListOptions}
            selected={securityPlaceKey(selectedCounterpartyPlace)}
            onChange={handlePlaceChange}
            Field={(props: FieldProps) => (
              <Field
                {...props}
                leftAddons={placeListPending && <Loader />}
                rightAddons={<TooltipConterpartyPlace />}
              />
            )}
          />
        </Col>
        {showContrTreaty && (
          /** Выбирая Ген. соглашение - выбираем депо аккаунт */
          <Col width={6}>
            <Select
              label="Генеральное соглашение"
              labelView="outer"
              placeholder="Выберите генеральное соглашение"
              size="s"
              block
              options={counterAlfaTreatyOptions}
              selected={conteragentAccDepo}
              onChange={handleCounterAlfaTreatyChange}
            />
          </Col>
        )}
        {!showContrTreaty && showConteragentAccDepo && (
          <Col width={6}>
            <Input
              label="Счёт депо в Go Invest"
              labelView="outer"
              block
              autoComplete="off"
              size="s"
              name="conteragentAccDepo"
              value={conteragentAccDepo}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={9}
              error={
                touched.conteragentAccDepo ? errors.conteragentAccDepo : ''
              }
            />
          </Col>
        )}
        {Boolean(conteragents) && showContragent && (
          <Col width={6}>
            <Select
              label="Контрагент"
              labelView="outer"
              placeholder="Выберите контрагента"
              size="s"
              block
              options={conteragentsOptions}
              selected={selectedConteragentKey}
              onChange={handleConteragentChange}
              Field={(props: FieldProps) => (
                <Field
                  {...props}
                  leftAddons={conteragentsPending && <Loader />}
                  rightAddons={<TooltipConteragent />}
                />
              )}
            />
          </Col>
        )}

        {showCounterparty && !conteragents && conterpartyNameShow && (
          <Col width={6}>{contragentNameNode}</Col>
        )}
      </Row>

      {showContrTreaty &&
        Boolean(conteragentAccDepo) &&
        showConteragentAccDepo && (
          <Row>
            <Col width={6}>
              <FormControlSummary label="Счёт депо в Go Invest">
                {conteragentAccDepo}
              </FormControlSummary>
            </Col>
          </Row>
        )}

      {showCounterparty && Boolean(conteragents) && conterpartyNameShow && (
        <Row>
          <Col width={12}>{contragentNameNode}</Col>
        </Row>
      )}

      {showOtherPlaceName && (
        <Textarea
          label="Наименование места хранения"
          labelView="outer"
          block
          autoComplete="off"
          size="s"
          name="counterpartyPlaceOther"
          value={counterpartyPlaceOther}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.counterpartyPlaceOther ? errors.counterpartyPlaceOther : ''
          }
        />
      )}

      {(showDepoAcc || showDepoSectionCounterparty) &&
        conterpartyDepoAccountShow && (
          <Row>
            {showDepoAcc && (
              <Col width={6}>
                {conteragentRequisitesOptions &&
                conteragentRequisitesOptions.length > 1 ? (
                  <Select
                    label="Счёт депо"
                    labelView="outer"
                    placeholder="Выберите счёт"
                    size="s"
                    block
                    options={conteragentRequisitesOptions}
                    selected={selectedRequisiteKey}
                    onChange={handleRequisiteChange}
                    Field={(props: FieldProps) => (
                      <Field
                        {...props}
                        rightAddons={
                          <TooltipConterpartyDepoAccount
                            counterpartyMarket={
                              counterpartyMarket as SecurityPlaceCode
                            }
                          />
                        }
                      />
                    )}
                  />
                ) : (
                  <Input
                    label={getDepoAccountLabel(marketAlfa)}
                    labelView="outer"
                    block
                    autoComplete="off"
                    size="s"
                    name="depoAccount"
                    placeholder="Введите номер"
                    value={depoAccount}
                    disabled={!conterpartyRequisitesEnabled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.depoAccount || !conterpartyRequisitesEnabled
                        ? errors.depoAccount
                        : ''
                    }
                    rightAddons={
                      <TooltipConterpartyDepoAccount
                        counterpartyMarket={
                          counterpartyMarket as SecurityPlaceCode
                        }
                      />
                    }
                  />
                )}
              </Col>
            )}
            {showDepoSectionCounterparty && (
              <Col width={6}>
                <Input
                  label="Раздел счёта депо"
                  labelView="outer"
                  block
                  autoComplete="off"
                  size="s"
                  name="depoSection"
                  placeholder="Введите раздел"
                  value={depoSection}
                  disabled={!conterpartyRequisitesEnabled}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.depoSection || !conterpartyRequisitesEnabled
                      ? errors.depoSection
                      : ''
                  }
                  rightAddons={
                    <TooltipConterpartyDepoSection
                      counterpartyMarket={
                        counterpartyMarket as SecurityPlaceCode
                      }
                    />
                  }
                />
              </Col>
            )}
          </Row>
        )}

      {showDepoCodeCounterparty && (
        <Row>
          <Col width={6}>
            <Input
              label="Идентификатор"
              labelView="outer"
              block
              autoComplete="off"
              size="s"
              name="depoCode"
              placeholder="Введите идентификатор"
              value={depoCode}
              disabled={!conterpartyRequisitesEnabled}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.depoCode || !conterpartyRequisitesEnabled
                  ? errors.depoCode
                  : ''
              }
              rightAddons={
                <TooltipConterpartyDepoCode
                  counterpartyMarket={counterpartyMarket as SecurityPlaceCode}
                />
              }
            />
          </Col>
        </Row>
      )}
    </Space>
  );
};
