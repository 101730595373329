import { useMemo } from 'react';

import { ObjectGroup } from '../../lib/client/entities';

import { TradeLimitsResult } from '../../types/tradeLimits';

/*
https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=1592145072

Сумма пополнения = (сумма для выполнения заявки - доступный лимит по маржинальной торговле + комиссия за сделку) + 5%,

где сумма для выполнения заявки равна:
- для фьючерсов: ГО * кол-во
- для облигаций: ((цена * цена валюты номинала||цена валюты ликвидного рынка) * номинал / 100 + НКД) * кол-во
- для остальных активов: цена * кол-во
*/
export const useMissingAmount = (
  total: number,
  fee?: number,
  idObjectGroup?: ObjectGroup,
  tradeLimit?: TradeLimitsResult | null,
  warranty?: number
) => {
  const orderAmount = useMemo(() => {
    if (idObjectGroup === ObjectGroup.Futures) {
      return (
        (warranty ?? 0) - (tradeLimit ? tradeLimit.amount : 0) + (fee ?? 0)
      );
    } else {
      return total - (tradeLimit ? tradeLimit.amount : 0) + (fee ?? 0);
    }
  }, [fee, idObjectGroup, total, tradeLimit, warranty]);

  const isEnoughMoney = useMemo(
    () =>
      // Временно скрыты требования для всех инструментов
      // https://jira.moscow.alfaintra.net/browse/ADIRWEB-4698
      orderAmount <= 0 || !tradeLimit || true,
    [orderAmount, tradeLimit]
  );

  const missingAmount = useMemo(
    () => (isEnoughMoney ? 0 : Math.abs(orderAmount) * 1.05),
    [isEnoughMoney, orderAmount]
  );

  return { missingAmount, isEnoughMoney };
};
