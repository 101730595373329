import { isWithinInterval } from 'date-fns';
import get from 'lodash/get';
import { useCallback, useMemo, useState } from 'react';

import { Filter, FilterTypes } from '../types/layout';

interface Props<T> {
  filterState?: Filter;
  data: T[];
  saveFilter?: (filter: Filter | undefined) => void;
  findFilteredKey?: (data: T, filter: Filter) => unknown;
}

//Хук для фильтрации данных в колонке таблицы
export const useFilterTable = <T>({
  filterState,
  data,
  saveFilter,
  findFilteredKey,
}: Props<T>) => {
  const [filter, setFilter] = useState<Filter | undefined>(filterState);

  const onFilter = useCallback(
    (newFilter?: Filter) => {
      setFilter(newFilter);

      if (saveFilter) {
        saveFilter(newFilter);
      }
    },
    [saveFilter]
  );

  const filteredData = useMemo(() => {
    if (filter) {
      return data.filter((row) => {
        const filteredKey =
          findFilteredKey?.(row, filter) ?? get(row, filter.key);

        switch (filter.type) {
          case FilterTypes.Text:
            return (filteredKey as string)
              ?.toLowerCase()
              .includes((filter.value as string)?.toLowerCase());
          case FilterTypes.Array:
            return (filter.value as any[]).includes(filteredKey);
          case FilterTypes.DateRange:
            return isWithinInterval(filteredKey, {
              start: filter.value.dateFrom,
              end: filter.value.dateTo,
            });
          case FilterTypes.NumberRange:
            return (
              filteredKey >= filter.value.from && filteredKey <= filter.value.to
            );
          default:
            return true;
        }
      });
    } else {
      return data;
    }
  }, [filter, data, findFilteredKey]);

  return { filter, onFilter, filteredData };
};
