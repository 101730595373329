import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { ConfigExperience } from '../types/configExperience';
import { ConfigSize } from '../types/configSize';
import { ConfigStep } from '../types/configStep';
import { ConfigStyle } from '../types/configStyle';

type OnboardingConfigStore = {
  step: ConfigStep;
  configSize: ConfigSize;
  configStyle: ConfigStyle;
  configExperience: ConfigExperience;
};

type OnboardingConfigStoreActions = {
  setStep: (step: ConfigStep) => void;
  setConfigSize: (size: ConfigSize) => void;
  setConfigStyle: (style: ConfigStyle) => void;
  setConfigExperience: (experience: ConfigExperience) => void;
};

const initialState: OnboardingConfigStore = {
  step: ConfigStep.Experience,
  configExperience: ConfigExperience.Beginner,
  configStyle: ConfigStyle.BEGINNER, // т.к. по умолчанию configExperience Beginner
  configSize: ConfigSize.SMALL,
};

export const useOnboardingConfigStore = createWithEqualityFn<
  OnboardingConfigStore & OnboardingConfigStoreActions
>()(
  (set) => ({
    ...initialState,
    setStep: (step: ConfigStep) => set({ step }),
    setConfigSize: (size: ConfigSize) => set({ configSize: size }),
    setConfigStyle: (style: ConfigStyle) => set({ configStyle: style }),
    setConfigExperience: (experience: ConfigExperience) =>
      set({ configExperience: experience }),
  }),
  shallow
);
