import { format } from 'date-fns';

import { MAX_CERTIFICATES_COUNT } from '../../constants/certificates';
import { certificateMap } from '../../mapping/certificateMapping';
import {
  trackCertificateEnrollError,
  trackCertificateEnrollStarted,
  trackCertificateEntitySent,
  trackCertificateKeysCreated,
  trackReadyToSendCertificate,
} from '../analytics';
import {
  CertificateRequestEntity,
  ClientCertificateEntity,
  ClientMessageEntity,
  FrontEndType,
  InitFinishedEntity,
  Messages,
  MessageUnitedType,
  Operation,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import { getEntityIdByName } from '../client/serialization';
import AdirClientService from '../client/service';
import { createPKCS10 } from '../crypto/csr';
import { addRSAKeyPair, removeRSAPair } from '../db/rsaKeys';
import { getBrowserInfo, getVisitorId } from '../info';
import log from '../loglevel-mobile-debug';
import { visitorIdKey } from './constants';
import { getId } from './id';

import { AccountItem } from '../../types/account';
import { CertificateEnrollStep } from '../../types/certificate';
import { UserCredentials } from '../../types/user';

export enum CertificatesEvent {
  ENROLL_ERROR = 'ENROLL_ERROR',
  ENROLL_SUCCESS = 'ENROLL_SUCCESS',
  CERTIFICATE_REJECT_SUCCESS = 'CERTIFICATED_REJECT_SUCCESS',
}

const CERTIFICATE_PREFIX = 'WT';

/*

  Это синглтон сервис для управления сертификатами пользователя и подписями.

  Умеет получать список сертификатов с сервера и перекладывать их в стор зюстанда. Для этого надо вызвать fetchCertificates()

  Так же умеет выпускать новые сертификаты, метод enrollCertificate()

  Cервис умеет генерить такие события:

  CertificatesEvent.ENROLL_ERROR файрится при выпуске сертификата, аргумент строка с инфой об ошибке
  CertificatesEvent.ENROLL_SUCCESS фейрится при успшеном выпуске сертификата, аргумент id сертификата
  CertificatesEvent.CERTIFICATED_REJECT_SUCCESS фейрится после успешного отзыва сертификата
*/

class CertifcatesService extends AdirClientService<CertificatesEvent> {
  // коллекция сертификатов, которые у нас есть
  certifiactes = new Map<BigInt, ClientCertificateEntity>();

  initDone = false;
  private isSubscribedToClient = false;

  // Тут есть хитрый замут, связаный с тем что когда мы генерим RSA ключевую пару
  // у нас на руках фактически просто байты публичного ключа и враппер приватного ключа
  // а в бизнес логике используется certificateID а это id из базы данных, который появляется
  // после того как сертификат был успешно выпущен и у нас появляется проблема: у нас на руках байты ключей и надо сопоставить с id из базы
  // Алгоритм такой:
  // 1) генерим ключи, формируем CSR и в поле Alias(это строка) фио + время с миллисекундами и в этот мап сохраняем строку Alias и ключи
  // 2) Если сервер нам выпустил сертификат, он прилетит в листнере onClientCertificateEntity
  // 3) Там уже будет серверный id и если в мапе висят ключи то ищем среди запией сертифкатов по строке Alias и если находим, то сохраняем ключи в indexedDB
  pendingKeys = new Map<string, CryptoKeyPair>();

  override init() {
    this.addClientListener(
      EntityType.ClientCertificateEntity,
      this.onClientCertificateEntity
    );

    this.addClientListener(
      EntityType.InitFinishedEntity,
      this.onInitFinishedEntity
    );
    this.addClientListener(
      EntityType.CertificateResponseEntity,
      this.onCertificateResponseEntity
    );

    this.addClientListener(
      EntityType.ClientMessageEntity,
      this.onClientMessage
    );

    return () => this.unsubscribe();
  }

  public onReInit = () => {
    this.initDone = false;
  };

  public fetchCertificates() {
    this.certifiactes = new Map<BigInt, ClientCertificateEntity>();
    this.initDone = false;

    this.isSubscribedToClient = true;
    this.client.sockets
      .get(FrontEndType.AuthAndOperInitServer)
      ?.subscribe(EntityType.ClientCertificateEntity);
  }

  // Вызов этого метода сгенерирует ключевую пару RSA и отправит на сервер
  // запрос на генерацию сертификата. В случае успеха будет событие CertificatesEvent.ENROLL_SUCCESS или CertificatesEvent.ENROLL_ERROR
  public async enrollCertificate(
    user: UserCredentials,
    selectedAccount: AccountItem
  ) {
    void trackCertificateEnrollStarted(this.certifiactes.size);

    if (this.certifiactes.size >= MAX_CERTIFICATES_COUNT) {
      this.emit(
        CertificatesEvent.ENROLL_ERROR,
        'Достигнуто максимальное количество сертификатов. Отзовите сертификат в личном кабинете.'
      );

      return;
    }

    const idAccount = selectedAccount?.idAccount;

    if (!idAccount) {
      throw new Error('No active account selected');
    }

    try {
      const results = await createPKCS10({
        enrollmentID: user?.fullName,
      });

      void trackCertificateKeysCreated();

      const now = new Date();
      const nowShort = format(now, 'yyyy.MM.dd HH:mm:ss');
      const nowLong = format(now, 'yyyy.MM.dd HH:mm:ss:SSS');

      const browserInfo = getBrowserInfo();
      const visitorId = await getVisitorId();
      const description = `${CERTIFICATE_PREFIX} ${browserInfo.name} ${browserInfo.version}, ${nowShort}`;
      const alias = `${user?.fullName} ${nowLong} ${visitorIdKey}${visitorId}`;

      this.pendingKeys.set(alias, results.keys);

      const request = new CertificateRequestEntity();

      request.CertificateRequest = results.binary;
      request.IdAccount = idAccount;
      request.IdRequest = getId();
      request.Alias = alias;
      request.Description = description;

      void trackReadyToSendCertificate();

      this.client.send({
        frontend: FrontEndType.OperServer,
        isArray: false,
        payload: {
          type: EntityType.CertificateRequestEntity,
          data: request,
        },
      });

      void trackCertificateEntitySent();
    } catch (error) {
      void trackCertificateEnrollError(error);

      throw new Error('Certificate enroll error');
    }
  }

  public unsubscribe() {
    if (this.isSubscribedToClient) {
      this.client.sockets
        .get(FrontEndType.AuthAndOperInitServer)
        ?.unsubscribe(EntityType.ClientCertificateEntity);
    }

    this.removeClientListener(
      EntityType.ClientCertificateEntity,
      this.onClientCertificateEntity
    );
    this.removeClientListener(
      EntityType.InitFinishedEntity,
      this.onInitFinishedEntity
    );

    this.removeClientListener(
      EntityType.CertificateResponseEntity,
      this.onCertificateResponseEntity
    );
    this.removeClientListener(
      EntityType.ClientMessageEntity,
      this.onClientMessage
    );
  }

  private onClientCertificateEntity = async (message: MessageUnitedType) => {
    const certificates = message.data as ClientCertificateEntity[];

    for (const cert of certificates) {
      const addOrUpdate =
        cert.Operation === Operation.Inserted ||
        cert.Operation === Operation.Updated;

      if (addOrUpdate) {
        this.certifiactes.set(cert.IdCertificate, cert);

        if (this.initDone) {
          // это значит прилетел сертификат после выпуска нового или еще какой-то апдейт был
          await this.store
            .getState()
            .addOrUpdateCertificate(certificateMap(cert));
        }
      } else if (cert.Operation === Operation.Deleted) {
        // если пользователь отозвал сертификат. Причем это может произойти если скажем сертификат отозвали
        // из другого приложения или ЛК
        const id = cert.IdCertificate;

        this.certifiactes.delete(id);
        this.store.getState().removeCertificate(Number(cert.IdCertificate));
        await removeRSAPair(Number(id));

        if (
          this.store.getState().certificateEnrollId ===
          Number(cert.IdCertificate)
        ) {
          this.store.getState().setCertificateEnrollId(undefined);
          this.store
            .getState()
            .setCertificateEnrollStep(CertificateEnrollStep.Idle);
        }

        this.emit(
          CertificatesEvent.CERTIFICATE_REJECT_SUCCESS,
          cert.IdCertificate
        );
      }

      if (this.pendingKeys.size > 0) {
        // начинаем искать подходящий сертификат, ключи от которого нужено сохранить в бд
        if (this.pendingKeys.has(cert.Alias)) {
          // уии - нашли
          const keys = this.pendingKeys.get(cert.Alias);

          try {
            if (!keys) {
              throw new Error('RSA key pair not found');
            }

            this.pendingKeys.delete(cert.Alias);
            await addRSAKeyPair(keys, Number(cert.IdCertificate));
            this.emit(
              CertificatesEvent.ENROLL_SUCCESS,
              Number(cert.IdCertificate)
            );
          } catch (err) {
            log.error(`Error saving rsa pair. ${err}`);
            this.emit(
              CertificatesEvent.ENROLL_ERROR,
              'Ошибка при сохранении ключевой пары RSA в БД'
            );
          }
        }
      }
    }

    this.store.getState().setCertificateEnrollStep(CertificateEnrollStep.Idle);
  };

  private onInitFinishedEntity = async (message: MessageUnitedType) => {
    const inits = message.data as InitFinishedEntity[];

    for (let i of inits) {
      if (i.Command === getEntityIdByName(EntityType.ClientCertificateEntity)) {
        if (!this.initDone) {
          const store = this.store.getState();

          await store.initCertificates(
            Array.from(this.certifiactes.values()).map((c) => certificateMap(c))
          );
          this.initDone = true;
        }
      }
    }
  };

  private onCertificateResponseEntity = (message: MessageUnitedType) => {
    // в mesage прилетит бинарный x509 сертикифат. Толку нам на самом деле мало от этого события
    log.debug('Certificate issued');
  };

  private onClientMessage = (message: MessageUnitedType) => {
    const messages = message.data as ClientMessageEntity[];

    messages.forEach((m) => {
      switch (m.MessageId) {
        case Messages.CertificateRequestRejected:
        case Messages.CertificateRequestRejected_NoSignServiceConnected:
          log.error(`Certificate enroll error ${m.MessageId}`);
          this.emit(
            CertificatesEvent.ENROLL_ERROR,
            `Произошла ошибка при выпуске сертификата. Код ошибки: ${m.MessageId} `
          );
          break;
      }
    });
  };
}

const instance = new CertifcatesService();

export { instance as CertifcatesService };
