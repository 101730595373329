import cn from 'classnames';
import React, { VFC } from 'react';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { ReceiptLineMIcon } from '@alfalab/icons-glyph/ReceiptLineMIcon';

import { OrderType } from '@terminal/core/lib/client/entities';
import { getDimensionedUnit } from '@terminal/core/lib/format';
import { OrderItem } from '@terminal/core/types/order';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

import { useOrderBookFinInfoExt } from '../../../hooks/useOrderBookFinInfoExt';

import styles from './OrdersCountLabel.module.css';

export type OrdersCountLabelProps = {
  idFI: number;
  price: number;
  orders: OrderItem[];
  desktopVersion: boolean;
  setSelectedOrdersIds?: (orders: string[]) => void;
};

export const OrdersCountLabel: VFC<OrdersCountLabelProps> = (props) => {
  const { idFI, orders, desktopVersion, price, setSelectedOrdersIds } = props;
  const finInfoExt = useOrderBookFinInfoExt(idFI);
  const wrapperClassName = cn(styles.wrapper, {
    [styles.desktopVersion]: desktopVersion,
  });

  if (orders.length === 1) {
    const [order] = orders;
    const { quantity, rest, idOrderType, stopPrice } = order;
    const bOrderTypeName =
      price === stopPrice
        ? `B-ST${idOrderType === OrderType.BRS ? 'P' : 'L'}`
        : 'B-LMT';
    const name = [OrderType.BRS, OrderType.BSL].includes(idOrderType)
      ? bOrderTypeName
      : OrderType[idOrderType];
    const quantityLots = getDimensionedUnit(
      quantity,
      finInfoExt?.lot,
      OrderDimensionEnum.LOT
    );
    const executedLots = getDimensionedUnit(
      quantity - rest,
      finInfoExt?.lot,
      OrderDimensionEnum.LOT
    );

    return (
      <div className={wrapperClassName}>
        <ReceiptLineMIcon className={styles.icon} height={16} width={16} />
        <div className={styles.count}>
          {executedLots === 0
            ? quantityLots
            : `${executedLots}/${quantityLots}`}
        </div>
        {desktopVersion && <div className={styles.count}>{name}</div>}
      </div>
    );
  }

  if (!desktopVersion) {
    return (
      <div className={wrapperClassName}>
        <ReceiptLineMIcon className={styles.icon} height={16} width={16} />
        <div className={styles.count}>{orders.length}</div>
      </div>
    );
  }

  return (
    <div
      className={wrapperClassName}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedOrdersIds?.(
          orders.map(({ clientNumEDocument }) => clientNumEDocument)
        );
      }}
    >
      <ReceiptLineMIcon className={styles.icon} height={16} width={16} />
      <div className={styles.count}>{orders.length}</div>
      <ChevronDownCompactMIcon
        height={16}
        width={16}
        color="currentColor"
        className={styles.chevron}
      />
    </div>
  );
};
