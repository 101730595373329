import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getInit } from '@terminal/core/lib/rest/lkCommon';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../queryKeys';

export const useInit = () => {
  const addNotification = useNotification();

  return useQuery([LkQueryKeys.InitObject], getInit, {
    staleTime: 60 * 60 * 1000,
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса настроек ЛК',
        text: error?.message || '',
      });
    },
  });
};
