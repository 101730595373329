import React, { FC, memo } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronBackMIcon } from '@alfalab/icons-glyph/ChevronBackMIcon';

import { useOnboardingConfigStore } from '@terminal/desktop/entities/OnboardingConfig';

import { useOnboardingSteps } from '../../hooks/useOnboardingSteps';

import styles from './BackButton.module.css';

export const BackButton: FC = memo(() => {
  const [step, setStep] = useOnboardingConfigStore((store) => [
    store.step,
    store.setStep,
  ]);
  const onboardingSteps = useOnboardingSteps();

  const handleBack = () => {
    const currentStepIndex = onboardingSteps.findIndex((el) => el === step);
    const prevStep = onboardingSteps[currentStepIndex - 1];

    if (typeof prevStep !== 'undefined') {
      setStep(prevStep);
    }
  };

  return (
    <ButtonDesktop
      view="ghost"
      className={styles.back}
      onClick={handleBack}
      leftAddons={
        <ChevronBackMIcon className={styles.backIcon} width={11} height={11} />
      }
    >
      <Typography.Text view="secondary-large" color="secondary">
        Назад
      </Typography.Text>
    </ButtonDesktop>
  );
});
