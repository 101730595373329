import sumBy from 'lodash/sumBy';

import { PositionItem } from '../../types/position';

/**
 * @returns Сумма Прибылей/убытков  по всем позициям в портфеле в рублевом эквиваленте
 * */
export function calculateNPLRur(positions: PositionItem[]) {
  return sumBy(positions, 'NPLRur');
}
