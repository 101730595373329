import cn from 'classnames';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';
import { formatAmount } from '@alfalab/utils';

import { countDecimals } from '@terminal/core/lib/format';
import { ActiveTransfer } from '@terminal/core/lib/rest/lkSecurities';

import { AMOUNT_MINORITY, securityPaperDescription } from '../../shared';

import styles from './Active.module.css';
import liststyles from './ActivesList.module.css';

interface ActiveProps {
  active: ActiveTransfer;
  onRedact?: () => void;
  onDelete?: () => void;
  className?: string;
}

export const Active = ({
  active,
  onRedact,
  onDelete,
  className,
}: ActiveProps) => {
  const decimals = countDecimals(active.count / AMOUNT_MINORITY);
  const minority = Math.pow(10, decimals);

  const countFormated = formatAmount({
    value: (active.count / AMOUNT_MINORITY) * minority,
    minority: minority,
  });

  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(liststyles.cell, liststyles.cellName)}>
        {securityPaperDescription(active.paper)}
      </div>
      <div className={cn(styles.volume, liststyles.cell, liststyles.cellCount)}>
        {countFormated.formatted}
      </div>
      {(Boolean(onRedact) || Boolean(onDelete)) && (
        <div
          className={cn(styles.actions, liststyles.cell, liststyles.cellAction)}
        >
          {Boolean(onRedact) && (
            <button className={styles.button} onClick={onRedact}>
              <PencilMIcon />
            </button>
          )}
          {Boolean(onDelete) && (
            <button
              className={cn(styles.button, styles.buttonDelete)}
              onClick={onDelete}
            >
              <CrossCircleMIcon />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
