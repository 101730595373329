import React, { useMemo } from 'react';

import {
  ConfigSize,
  isConfigAvailable,
  useOnboardingConfigStore,
} from '@terminal/desktop/entities/OnboardingConfig';

import { ReactComponent as ConfigLarge } from '../../assets/config_large.svg';
import { ReactComponent as ConfigMedium } from '../../assets/config_medium.svg';
import { ReactComponent as ConfigSmall } from '../../assets/config_small.svg';
import { ConfigChoiceButton } from '../ConfigChoiceButton';
import { InfoHeading } from '../InfoHeading';

import styles from './OnboardingScreenSize.module.css';

const choiceIcons = {
  configSmall: ConfigSmall,
  configMedium: ConfigMedium,
  configLarge: ConfigLarge,
};

interface ChoiceButton {
  title: string;
  subtitle: string;
  icon: keyof typeof choiceIcons;
  id: ConfigSize;
}

const choiceButtons: ChoiceButton[] = [
  {
    title: '13-14 дюймов',
    subtitle: 'Для ноутбука',
    icon: 'configSmall',
    id: ConfigSize.SMALL,
  },
  {
    title: '15-21 дюймов',
    subtitle: 'Для стандартного монитора',
    icon: 'configMedium',
    id: ConfigSize.MEDIUM,
  },
  {
    title: 'Более 21 дюйма',
    subtitle: 'Для большого монитора',
    icon: 'configLarge',
    id: ConfigSize.LARGE,
  },
];

export const OnboardingScreenSize = () => {
  const [configSize, setConfigSize, configStyle] = useOnboardingConfigStore(
    (store) => [store.configSize, store.setConfigSize, store.configStyle]
  );
  const availableChoices = useMemo(() => {
    return choiceButtons.filter((button) =>
      isConfigAvailable(button.id, configStyle)
    );
  }, [configStyle]);

  return (
    <>
      <InfoHeading
        title="Конфигурация рабочего стола"
        description={
          <>
            Укажите ваш размер экрана, чтобы мы правильно расположили
            <br />
            нужные виджеты.
          </>
        }
      />

      <div className={styles.firstStepChoice}>
        {availableChoices.map((button) => (
          <ConfigChoiceButton
            key={button.id}
            title={button.title}
            subtitle={button.subtitle}
            Content={choiceIcons[button.icon]}
            isActive={configSize === button.id}
            onClick={() => setConfigSize(button.id)}
          />
        ))}
      </div>
    </>
  );
};
