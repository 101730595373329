import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getStrategyThemeQuestions } from '../api';

export function useStrategyThemeQuestions(themeId: number) {
  const addNotification = useNotification();

  return useQuery(
    ['lk-education-strategy-theme-questions', { themeId }],
    () => getStrategyThemeQuestions({ themeId }),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса вопросов',
          text: error?.message || '',
        });
      },
      enabled: Boolean(themeId),
    }
  );
}
