import { createContext, useContext } from 'react';

export type IntegratedTestingType = {
  backButton?: {
    label: string;
    icon?: React.ReactNode;
    onClick?: () => void;
  };
};
export const IntegratedTestingContext = createContext<IntegratedTestingType>(
  {}
);

/** Контекст для интегрированного тестирования. */
export const useIntegratedTesting = () => useContext(IntegratedTestingContext);
