import React, { FC, ReactNode } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import styles from './Footer.module.css';

export type FooterProps = {
  /**
   * Текст копирайта
   * */
  copyright: ReactNode;
};

export const Footer: FC<FooterProps> = (props) => {
  const { copyright, children } = props;

  return (
    <div className={styles.root}>
      <Typography.Text tag="div" view="secondary-small" weight="medium">
        &nbsp;{children}&nbsp;
      </Typography.Text>
      <Typography.Text tag="div" view="secondary-small" color="secondary">
        {copyright}
      </Typography.Text>
    </div>
  );
};
