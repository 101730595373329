import { Typography } from '@alfalab/core-components/typography';

import instr from './img/instructions.png';
import instr2x from './img/instructions@2x.png';

import styles from './InstructionSupportBanner.module.css';

export const InstructionSupportBanner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Typography.Text tag="div">Как устроен веб-терминал</Typography.Text>
        <Typography.Text view="secondary-small" color="secondary">
          Руководство по использованию
        </Typography.Text>
      </div>
      <img
        className={styles.image}
        src={instr}
        srcSet={`${instr2x} 2x`}
        alt=""
      />
    </div>
  );
};
