import { roundPrice } from '@terminal/core/lib/format';
import { BracketPriceType } from '@terminal/core/types/ui';

export const getBracketPercent = (
  priceType: BracketPriceType,
  price: number,
  mainPrice: number,
  priceStep = 0.01
) => {
  if (priceType === BracketPriceType.PRICE) {
    return roundPrice((Math.abs(mainPrice - price) * 100) / mainPrice, 0.01);
  } else if (priceType === BracketPriceType.TICK) {
    return roundPrice((price * priceStep * 100) / mainPrice, 0.01);
  } else {
    return price;
  }
};
