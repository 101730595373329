import debounce from 'lodash/debounce';

import { finInfoExtMap } from '../../../mapping/quotesMapping';
import {
  FinInfoExtEntity,
  FrontEndType,
  MessageUnitedType,
} from '../../client/entities';
import { EntityType } from '../../client/entityTypes';
import AdirClientService from '../../client/service';

import { useStore } from '../../../store';

import { FinInfoExt as FinInfoExtType } from '../../../types/quotes';
import { fi, FinInfoExtRecord } from './types';

/*
 Работа с энтитями FinInfoExt

 Фактически эти данные живут в сторе зюстанда. Ниже есть листенер который при получении этих записей
 кладет их в стор.
*/

class FinInfoExt extends AdirClientService {
  override init() {
    // Что здесь происходит: мы откалыдваем обнволения стора до тех пор,
    // пока сервак нам не отдаст все фин инфо эксты
    // Предположим ситуацию, пользователь запросил снп 500 список
    // те будет запрошено 500 энтитей
    // каждая энтитя при получении обновит стор и вызовет каскадные ререндеры вообще всего
    // дебаунс же позволяет обновить стор только когда сервер закончит слать апдейты
    let collector = [] as FinInfoExtEntity[];
    const debouncedUpdater = debounce(() => {
      useStore
        .getState()
        .setFinInfoExt((collector as FinInfoExtEntity[]).map(finInfoExtMap));
      collector = [];
    }, 200);

    this.addClientListener(
      EntityType.FinInfoExtEntity,
      (message: MessageUnitedType) => {
        collector.push(...(message.data as FinInfoExtEntity[]));
        debouncedUpdater();
      }
    );
  }
  request(fi: number[]) {
    this.client.subscribe(
      FrontEndType.AuthAndOperInitServer,
      EntityType.FinInfoExtEntity,
      fi
    );
  }

  subscribe(
    fis: number[],
    callback: (data: Record<fi, FinInfoExtType | undefined>) => void,
    fireImmediately: boolean = true
  ): () => void {
    const unsubscribe = useStore.subscribe(
      (state) => state.finInfoExt,
      (finInfoExt, prevFinInfoExt) => {
        // здесь мы проверяем, есть изменения в fi,
        // которые нам интересны и только в том случае
        // собираем мапку и вызываем обработчик

        const result: FinInfoExtRecord = {};
        let update = false;

        fis.forEach((fi) => {
          result[fi] = prevFinInfoExt[fi] ?? finInfoExt[fi];

          if (!update) {
            const prevVersion = prevFinInfoExt[fi]?.version;
            const newVersion = finInfoExt[fi]?.version;

            if (!prevVersion || !newVersion || prevVersion < newVersion) {
              update = true;
            }
          }
        });

        if (update) {
          callback(result);
        }
      },
      { fireImmediately }
    );

    this.request(fis);

    return unsubscribe;
  }
}

export const FinInfoExtService = new FinInfoExt();
