import { Typography } from '@alfalab/core-components/typography';

import { SecuritiesTargetPlace } from '@terminal/core/lib/rest/lkSecurities';

import { SummaryField } from '../Summary';

import styles from './HistoryItemDetail.module.css';

interface TargetPlaceInfoProps {
  info: SecuritiesTargetPlace;
  title: string;
}

export const TargetPlaceInfo = ({ info, title }: TargetPlaceInfoProps) => {
  return (
    <>
      <Typography.Text
        view="primary-small"
        tag="p"
        color="primary"
        weight="bold"
        className={styles.sectionTitle}
      >
        {title || 'Откуда'}
      </Typography.Text>
      <div className={styles.section}>
        {info.account && <SummaryField label="Счёт" value={info.account} />}
        {info.placeName && (
          <SummaryField label="Место хранения" value={info.placeName} />
        )}
        {info.deponentCode && (
          <SummaryField label="Код депонента" value={info.deponentCode} />
        )}
        {info.depoAccount && (
          <SummaryField label="Счёт депо клиента" value={info.depoAccount} />
        )}
        {info.depoAccountPart && (
          <SummaryField
            label="Раздел счёта депо"
            value={info.depoAccountPart}
          />
        )}
        {info.bankDepoAccount && (
          <SummaryField
            label="Счёт депо в Go Invest"
            value={info.bankDepoAccount}
          />
        )}
        {info.contragent && (
          <SummaryField label="Контрагент" value={info.contragent} />
        )}
      </div>
    </>
  );
};
