import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getTradingPlatformsState } from '../api/getTradingPlatformsState';

export const useTradingPlatformsState = (treaty?: number) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'tradingPlatform/registrationState', { treaty }],
    async () => getTradingPlatformsState({ treaty: treaty || 0 }),
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message ||
            'При запросе информации о торговых площадках произошла ошибка',
        });
      },
    }
  );
};
