import { mapStrategyThemeAnswer } from './mapStrategyThemeAnswer';

import { ThemeQuestionDTO } from '../api/types/ThemeQuestionDTO';
import { ThemeQuestion } from '../model/types/ThemeQuestion';

export function mapThemeQuestion(dto: ThemeQuestionDTO): ThemeQuestion {
  return {
    id: dto.id,
    text: dto.text,
    type: dto.type,
    picture: dto.picture,
    answers: dto.answers.map(mapStrategyThemeAnswer),
  };
}
