import { FC } from 'react';
import { Spinner as BaseSpinner } from '@alfalab/core-components/spinner';

import styles from './Spinner.module.css';

type SpinnerProps = {
  isVisible: boolean;
};

export const Spinner: FC<SpinnerProps> = (props) => {
  const { isVisible } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.spinnerWrapper}>
      <BaseSpinner size="m" visible />
    </div>
  );
};
