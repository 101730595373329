import React, { useState } from 'react';
import { CurrencyCodes } from '@alfalab/core-components/amount';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop/Component.desktop';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { useFullFI } from '@terminal/alfadirect/hooks';
import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import {
  OrderStopPriceDirection,
  PriceControlType,
} from '@terminal/core/lib/client/entities';

import { Condition } from './Condition';

import { ConditionInstructionState } from '../model/types';

interface Props extends BaseModalProps {
  idFi: number;
  currencyCode?: CurrencyCodes;
  onSubmit: (data: ConditionInstructionState['data']) => void;
  onClose: () => void;
}

export const ConditionModal = ({
  idFi,
  currencyCode,
  onSubmit,
  onClose,
  ...baseModalProps
}: Props) => {
  const [controlledIdFi, setControlledIdFi] = useState<number>(idFi);
  const [stopPrice, setStopPrice] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [idPriceControlType, setIdPriceControlType] =
    useState<PriceControlType>(PriceControlType.LAST);
  const [priceDirection, setPriceDirection] = useState<OrderStopPriceDirection>(
    OrderStopPriceDirection.Lower
  );

  const fullFi = useFullFI(controlledIdFi);

  const handleSave = () => {
    fullFi &&
      stopPrice &&
      onSubmit({
        activationPriceDirection: priceDirection,
        idPriceControlType,
        stopPrice,
        idFIActivate: controlledIdFi,
      });
  };

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <FunctionalModal
        backdrop={false}
        width={270}
        title="Если выполнится условие"
        paddingSize="xs"
        onClose={onClose}
        drawerId="condition"
        footer={
          <Space direction="horizontal" size="m" align="center">
            <Button block view="ghost" size="xs" onClick={onClose}>
              <Typography.Text view="secondary-large">Отмена</Typography.Text>
            </Button>
            <Button
              disabled={!fullFi || !stopPrice || hasError}
              block
              view="secondary"
              size="xs"
              onClick={handleSave}
            >
              Применить
            </Button>
          </Space>
        }
      >
        <Condition
          controlledIdFi={controlledIdFi}
          setControlledIdFi={setControlledIdFi}
          stopPrice={stopPrice}
          setStopPrice={setStopPrice}
          idPriceControlType={idPriceControlType}
          setIdPriceControlType={setIdPriceControlType}
          priceDirection={priceDirection}
          setPriceDirection={setPriceDirection}
          currencyCode={currencyCode}
          setHasError={setHasError}
        />
      </FunctionalModal>
    </BaseModal>
  );
};
