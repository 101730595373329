import { defaultWatchList } from '../../../constants/WatchList';
import { WatchListsTypeLast } from '../../../lib/db/watchLists';
import { localStorageBackup } from '../../../lib/localStorageBackup';
import log from '../../../lib/loglevel-mobile-debug';
import { useStore } from '../../useStore';

import { WatchList } from '../../../types/watchList';

const keyLS = 'watchLists';

export const getWatchListsFromLS = () => {
  const backup = localStorageBackup.getWatchListsBackup(
    useStore.getState().finInstrumentsTable,
    useStore.getState().objectsTable,
    useStore.getState().marketBoardsTable
  );

  if (backup.length > 0) {
    localStorageBackup.cleanupWatchListsBackup();

    return backup;
  }

  const stringConfig = localStorage.getItem(keyLS);

  if (stringConfig) {
    try {
      const parsedLS = JSON.parse(stringConfig) as WatchList[];

      return parsedLS;
    } catch (e) {
      log.error('WatchLists is not valid object string');
    }
  }

  return [defaultWatchList];
};

export const saveWatchListsToLS = (watchListsTypeLast: WatchListsTypeLast) => {
  window?.requestAnimationFrame(() => {
    const watchLists = watchListsTypeLast.lists;

    localStorage.setItem(keyLS, JSON.stringify(watchLists));
  });
};
