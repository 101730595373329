import { format, parseISO } from 'date-fns';
import { useCallback, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Divider } from '@alfalab/core-components/divider';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownBoldContainerMIcon } from '@alfalab/icons-glyph/ArrowDownBoldContainerMIcon';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';

import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import { DocumentsHistoryItem } from '@terminal/core/lib/rest/lkDocuments';

import { LK_API } from '../../../../legacy/const/externalUrls';
import { StaticField } from '../../../../shared/ui/StaticField';

import { useDownloadFile } from '../../../../legacy/hooks';

import styles from './HistoryItemDetails.module.css';

interface HistoryItemDetailsProps {
  item?: DocumentsHistoryItem;
}

export const HistoryItemDetails = ({ item }: HistoryItemDetailsProps) => {
  const [file, setFile] = useState<DownloadedFile | null>(null);

  const { mutateAsync: downloadFile, isLoading: filePending } =
    useDownloadFile();

  const date = item?.docDate ? parseISO(item.docDate) : undefined;
  const periodStartDate = item?.data?.dateFrom
    ? parseISO(item?.data?.dateFrom)
    : undefined;
  const periodEndDate = item?.data?.dateTo
    ? parseISO(item?.data?.dateTo)
    : undefined;

  const onRequestFile = useCallback(async () => {
    if (item?.scan && !file && !filePending) {
      const url = `${LK_API.DOCUMENT_SCAN}/${item.scan?.scanId}/${item.scan?.filename}`;
      const file = await downloadFile(url);

      setFile(file);
    }
  }, [downloadFile, file, filePending, item?.scan]);

  if (!item) {
    return null;
  }

  return (
    <div className={styles.detailsContainer}>
      <Typography.Text
        view="primary-small"
        weight="bold"
        color="primary"
        tag="p"
        className={styles.detailsTitle}
      >
        {item.theme}
      </Typography.Text>
      <Space size="m" fullWidth>
        <StaticField label="Статус">{item.status}</StaticField>
        {item.data && item.data.lkNumber && (
          <StaticField label="Номер заявки">{item.data?.lkNumber}</StaticField>
        )}
        {date && (
          <StaticField label="Дата и время заявки">
            {format(date, 'dd.MM.yyyy HH:mm:ss')}
          </StaticField>
        )}
        {periodStartDate && (
          <StaticField label="Начало периода">
            {format(periodStartDate, 'dd.MM.yyyy')}
          </StaticField>
        )}
        {periodEndDate && (
          <StaticField label="Конец периода">
            {format(periodEndDate, 'dd.MM.yyyy')}
          </StaticField>
        )}
        {item.comment && (
          <StaticField label="Комментарий">{item.comment}</StaticField>
        )}
        {item.scan && (
          <>
            <Divider className={styles.divider} />
            <ButtonDesktop
              className={styles.downloadButton}
              size="xs"
              view="accent"
              block
              onClick={file ? undefined : onRequestFile}
              href={file?.url}
              download={file?.filename}
              target={file ? '_blank' : undefined}
              loading={filePending}
              leftAddons={
                file ? (
                  <OutsideMIcon
                    width={18}
                    height={18}
                    className={styles.glyph}
                  />
                ) : (
                  <ArrowDownBoldContainerMIcon
                    width={18}
                    height={18}
                    className={styles.glyph}
                  />
                )
              }
            >
              {file ? 'Открыть' : 'Скачать'}
            </ButtonDesktop>
          </>
        )}
      </Space>
    </div>
  );
};
