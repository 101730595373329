import log from 'loglevel';
import React, { FC, useMemo, useRef } from 'react';
import { useEvent, useToggle } from 'react-use';

import { context } from './context';

import { SoundManagerContext } from './types/SoundManagerContext';
import { Sounds } from './types/Sounds';

/**
 * Создает провайдер для контекста управления звуками
 * @param sounds список доступных звуков
 * */
export function createSoundManagerProvider(sounds: Sounds): FC {
  const soundIndexByName = sounds.reduce<Record<string, number>>(
    (acc, [name], index) => Object.assign(acc, { [name]: index }),
    {}
  );

  return (props) => {
    const { children } = props;
    const [isInitialized, setIsInitialized] = useToggle(false);
    const audioRefs = useRef<Array<HTMLAudioElement | null>>([]);

    const value = useMemo<SoundManagerContext>(
      () => ({
        play: async (name) => {
          const audio = audioRefs.current[soundIndexByName[name]];

          if (audio) {
            await audio.play().catch((error) => {
              log.warn(error);
            });
          }
        },
        stop: (name) => {
          const audio = audioRefs.current[soundIndexByName[name]];

          if (audio) {
            audio.currentTime = 0;
            audio.pause();
          }
        },
      }),
      []
    );

    // Инициализируем звуки при первом клике на window
    useEvent('click', setIsInitialized, window, { once: true });

    return (
      <context.Provider value={value}>
        {isInitialized &&
          sounds.map(([name, audioProps], index) => (
            <audio
              key={name}
              ref={(element) => (audioRefs.current[index] = element)}
              playsInline
              {...audioProps}
            />
          ))}
        {children}
      </context.Provider>
    );
  };
}
