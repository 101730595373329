import { Input, InputProps } from 'formik-alfalab-core-components/Input';
import React, { FC } from 'react';

export type TitleInputProps = Omit<InputProps, 'name'>;

export const TitleInput: FC<TitleInputProps> = (props) => {
  return (
    <Input
      name="title"
      block
      label="Текст"
      labelView="outer"
      size="s"
      {...props}
    />
  );
};
