import orderBy from 'lodash/orderBy';
import { useMemo, useState } from 'react';

import { useStore } from '../store';

import { Sort } from '../types/layout';
import { TableColumnKey } from '../types/tableColumn';

interface Props<T> {
  sort?: Sort;
  data: T[];
  setSort?: (sort?: Sort) => void;
  onOrder?: (data: T, key?: string) => void;
}

/**
 * @deprecated Используйте `packages/widgets/src/shared/hooks/useSortTable.ts`
 */
export const useSortTable = <T>({ sort, data, setSort, onOrder }: Props<T>) => {
  const [localSort, setLocalSort] = useState<Sort | undefined>(sort);
  const isLocalSort = !setSort;
  const sortState = useMemo(
    () => (isLocalSort ? localSort : sort),
    [isLocalSort, localSort, sort]
  );
  const selectedDisplayInstrumentType = useStore(
    (state) => state.settings.defaultValues.selectedDisplayInstrumentType
  );

  const sortKey = useMemo(() => {
    return sortState?.key === TableColumnKey.SymbolObject &&
      selectedDisplayInstrumentType === 'name'
      ? TableColumnKey.NameObject
      : sortState?.key;
  }, [selectedDisplayInstrumentType, sortState?.key]);

  const onSort = (key: string) => {
    if (sortState?.key === key) {
      setSort
        ? setSort({ key, asc: !sortState?.asc })
        : setLocalSort({ key, asc: !sortState?.asc });
    } else {
      setSort ? setSort({ key, asc: true }) : setLocalSort({ key, asc: true });
    }
  };

  const sortedData = useMemo(() => {
    if (sortState) {
      return orderBy(
        data,
        onOrder ? (item) => onOrder(item, sortKey) : sortKey,
        sortState.asc ? 'asc' : 'desc'
      );
    } else {
      return data;
    }
  }, [sortState, sortKey, data, onOrder]);

  return { sort: sortState, onSort, sortedData };
};
