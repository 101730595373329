import { format, isBefore, parseISO, startOfDay, subMonths } from 'date-fns';
import get from 'lodash/get';
import { useEffect, useMemo, useState } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Loader } from '@alfalab/core-components/loader';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { BlueprintTable, CellRenderProps } from '@terminal/common/ui/Table';
import { getDefaultOperationshistorySetting } from '@terminal/core/constants/tableProps';
import { useFilterTable, useSortTable } from '@terminal/core/hooks';
import {
  getMinority,
  getStringDate,
  getStringTime,
} from '@terminal/core/lib/format';
import {
  dateToRequestFormat,
  DEFAULT_VIEW_DATE_FORMAT,
} from '@terminal/core/lib/rest/lkApi';
import {
  MoneyHistoryItem,
  MoneyHistoryRequest,
} from '@terminal/core/lib/rest/lkMoney';
import { Sort } from '@terminal/core/types/layout';
import {
  DateFilterType,
  DateFilterValue,
  OperationHistoryItem,
} from '@terminal/core/types/operation';
import { OperationCommonItem } from '@terminal/core/types/operation';
import { OrderDirectionMap } from '@terminal/core/types/order';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { SymbolCell } from '../../../../features/SymbolCell';
import {
  defaultColumnFilterRender,
  useWidgetContext,
} from '../../../../shared';
import { HistoryHeader } from '../HistoryHeader';
import { onTableOrder } from './helpers';

import {
  OperationsHistoryParams,
  useOperationsHistory,
} from '../../hooks/useOperationsHistory';

import styles from './OperationsHistory.module.css';

const MONEY_TRANSFER_TYPE_TEXT = {
  EXTRA_IN: 'Зачисление',
  EXTRA_OUT: 'Списание',
  INTRA: 'Внутренний перевод',
};

const TODAY = startOfDay(new Date());
const defaultDateFilter: DateFilterValue = {
  type: DateFilterType['30DAYS'],
  valueFrom: {
    value: format(subMonths(TODAY, 1), DEFAULT_VIEW_DATE_FORMAT),
    date: subMonths(TODAY, 1).getTime(),
  },
  valueTo: {
    value: format(TODAY, DEFAULT_VIEW_DATE_FORMAT),
    date: TODAY.getTime(),
  },
};

export const isInvestOperation = (
  props: OperationCommonItem
): props is OperationHistoryItem => props.type === 'INVEST';

export interface Props {
  parentNodeId?: string;
  selectedSubAccounts: string[];
}

export const OperationsHistory = ({
  parentNodeId,
  selectedSubAccounts,
}: Props) => {
  const [columnSetting, setColumnSetting] = useState(
    getDefaultOperationshistorySetting()
  );
  const [sort, setSort] = useState<Sort | undefined>();

  const [dateFilter, setDateFilter] = useState<DateFilterValue | null>(
    defaultDateFilter
  );
  const { updateNode, useSettings, useMoneyHistory } = useWidgetContext();
  const { objectsTable } = useAlfaDirectContext();
  const {
    defaultValues: { selectedDisplayInstrumentType },
  } = useSettings();

  useEffect(
    () =>
      setColumnSetting(
        getDefaultOperationshistorySetting(selectedDisplayInstrumentType)
      ),
    [selectedDisplayInstrumentType]
  );

  const historyParams = useMemo<OperationsHistoryParams>(() => {
    return {
      subAccount: selectedSubAccounts[0],
      dateFrom: dateFilter?.valueFrom?.date
        ? new Date(dateFilter?.valueFrom?.date).toISOString()
        : undefined,
      dateTo: dateFilter?.valueTo?.date
        ? new Date(dateFilter?.valueTo?.date).toISOString()
        : undefined,
    };
  }, [dateFilter, selectedSubAccounts]);

  const { data, isFetching } = useOperationsHistory(historyParams);

  const historyMoneyParams = useMemo<MoneyHistoryRequest>(() => {
    return {
      types: ['TRADES', 'TRANSFERS'],
      treaty: parseInt(selectedSubAccounts[0].split('-')[0]),
      startDate: dateToRequestFormat(
        dateFilter?.valueFrom?.date
          ? format(
              new Date(dateFilter?.valueFrom?.date),
              DEFAULT_VIEW_DATE_FORMAT
            )
          : undefined
      ),
      endDate: dateToRequestFormat(
        dateFilter?.valueTo?.date
          ? format(
              new Date(dateFilter?.valueTo?.date),
              DEFAULT_VIEW_DATE_FORMAT
            )
          : undefined
      ),
      page: 1,
      count: 100,
      subAccount: selectedSubAccounts[0],
    };
  }, [
    dateFilter?.valueFrom?.date,
    dateFilter?.valueTo?.date,
    selectedSubAccounts,
  ]);
  const {
    data: history,
    isFetching: historyPending,
    //TODO: Оставил пока так, у нас сейчас таблицы без пагинации
    // isFetchingNextPage: historyPendingNext,
    // hasNextPage: hasMoreHistory,
    // fetchNextPage: fetchMoreHistory,
  } = useMoneyHistory(historyMoneyParams);
  const historyItems = useMemo(() => {
    if (history) {
      const items = history.pages.flatMap((page) => page.transfers);

      return items.map((item) => ({
        ...item,
        operation: { idOperation: item.id, timeOperation: new Date(item.date) },
        operationDate: new Date(item.date).toDateString(),
      }));
    }

    return null;
  }, [history]);

  const tableData = useMemo(
    () => [...(data ?? []), ...(historyItems ?? [])],
    [data, historyItems]
  );

  const { filteredData, filter, onFilter } = useFilterTable({
    data: tableData,
  });
  const { sortedData, onSort } = useSortTable({
    data: filteredData,
    sort,
    setSort,
    onOrder: onTableOrder,
  });

  const cellRender: CellRenderProps<OperationCommonItem> = (
    rowIndex,
    columnIndex,
    columnKey,
    data
  ) => {
    const row = data[rowIndex];

    if (isInvestOperation(row)) {
      const { operation, object } = row;

      switch (columnKey) {
        case TableColumnKey.NameObject:
        case TableColumnKey.SymbolObject:
          return (
            <SymbolCell
              idObject={object?.idObject}
              symbolObject={object?.symbolObject}
            />
          );
        case TableColumnKey.OperationCurrency:
          return operation.instrumentValue?.unit;
        case TableColumnKey.OperationAccount:
        case TableColumnKey.MarketNameMarketBoard:
        case TableColumnKey.OperationQuantity:
          return get(data[rowIndex], columnKey);
        case TableColumnKey.OperationTime:
          return getStringTime(operation.timeOperation);
        case TableColumnKey.OperationDate:
          return getStringDate(operation.timeOperation);
        case TableColumnKey.OperationSettleDate:
          return getStringDate(get(data[rowIndex], columnKey));
        case TableColumnKey.OperationBuySell:
          return (
            <span
              className={operation.buySell === 1 ? styles.buy : styles.sell}
            >
              {OrderDirectionMap.get(operation.buySell)}
            </span>
          );
        case TableColumnKey.OperationPrice:
          const value = operation.price;
          //Подсчитываем минорные единицы
          const minority = getMinority(value);

          return (
            <Amount.Pure
              value={value * minority}
              minority={minority}
              view="withZeroMinorPart"
            />
          );
        case TableColumnKey.OperationValue:
          return (
            <div className={operation.buySell === 1 ? styles.buy : styles.sell}>
              <Amount.Pure
                value={operation.value * 100}
                minority={100}
                view="withZeroMinorPart"
              />
            </div>
          );
        case TableColumnKey.OperationIsSettled:
          return operation.settleDate && isBefore(operation.settleDate, TODAY)
            ? 'Да'
            : 'Нет';

        default:
          return null;
      }
    } else {
      const operation = row as MoneyHistoryItem;
      const target = operation?.target || operation?.source;
      const object = objectsTable
        .toArray()
        .find(({ symbolObject }) => symbolObject === operation.currency);

      switch (columnKey) {
        case TableColumnKey.NameObject:
        case TableColumnKey.SymbolObject:
          return (
            <SymbolCell
              idObject={object?.idObject}
              symbolObject={operation.currency}
            />
          );
        case TableColumnKey.OperationCurrency:
          return operation.currency;
        case TableColumnKey.MarketNameMarketBoard:
          return target?.placeName;
        case TableColumnKey.OperationDate:
          return format(parseISO(operation.date), 'dd.LL.uuuu');
        case TableColumnKey.OperationTime:
          return format(parseISO(operation.date), 'HH:mm:ss');
        case TableColumnKey.OperationBuySell:
          return <span>{MONEY_TRANSFER_TYPE_TEXT[operation.type]}</span>;
        case TableColumnKey.OperationValue:
          return (
            <div
              className={(target?.amount ?? 0) >= 0 ? styles.buy : styles.sell}
            >
              <Amount.Pure
                value={(target?.amount ?? 0) * 100}
                minority={100}
                view="withZeroMinorPart"
              />
            </div>
          );

        default:
          return null;
      }
    }
  };

  return (
    <div className={styles.container}>
      <HistoryHeader
        updateNode={updateNode}
        nodeId={parentNodeId}
        selectedSubAccounts={selectedSubAccounts}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
      />
      {isFetching || historyPending ? (
        <Loader className={styles.loader} />
      ) : (
        <BlueprintTable<OperationCommonItem>
          filter={filter}
          onFilter={onFilter}
          sort={sort}
          onSort={onSort}
          data={sortedData}
          columnSetting={columnSetting}
          columnFilterRender={defaultColumnFilterRender}
          cellRender={cellRender}
          onReorder={setColumnSetting}
          onChangeWidth={setColumnSetting}
        />
      )}
    </div>
  );
};
