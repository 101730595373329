import { useState } from 'react';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { Switch } from '@alfalab/core-components/switch';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import { Stepper } from '@terminal/common/components/Stepper';

import styles from './TradeFeedTimerModal.module.css';

interface Props {
  onClose: () => void;
  timer?: number;
  timerActive: boolean;
  updateConfig: (config: any) => void;
}

export const TradeFeedTimerModal = ({
  onClose,
  timer = 0,
  timerActive = false,
  updateConfig,
}: Props) => {
  const [tempTimerActive, setTempTimerActive] = useState<boolean>(timerActive);
  const [tempTimerMinutes, setTempTimerMinutes] = useState<number>(
    Math.floor(timer / 60)
  );
  const [tempTimerSeconds, setTempTimerSeconds] = useState<number>(timer % 60);

  return (
    <FunctionalModal
      title="Автоочистка ленты"
      onClose={onClose}
      width={300}
      footer={
        <div className={styles.footer}>
          <Button view="ghost" size="xxs" onClick={onClose}>
            Отменить
          </Button>
          <Button
            disabled={
              tempTimerMinutes === Math.floor(timer / 60) &&
              tempTimerSeconds === timer % 60 &&
              tempTimerActive === timerActive
            }
            size="xxs"
            onClick={() => {
              updateConfig({
                timer: tempTimerMinutes * 60 + tempTimerSeconds,
                timerActive: tempTimerActive,
              });
              onClose();
            }}
          >
            Применить
          </Button>
        </div>
      }
    >
      <div className={styles.firstRow}>
        <Switch
          checked={tempTimerActive}
          reversed
          block
          onChange={() => setTempTimerActive((prev) => !prev)}
          label={<b>Очищать ленту, каждые</b>}
        />
      </div>
      <div className={styles.secondRow}>
        <AmountInput
          block
          bold={false}
          integersOnly
          integerLength={2}
          positiveOnly
          minority={1}
          value={String(tempTimerMinutes).padStart(2, '0')}
          suffix=" мин"
          onChange={(_, { value }) => setTempTimerMinutes(value || 0)}
          rightAddons={
            <Stepper
              onAdd={() =>
                setTempTimerMinutes((prev) => {
                  const newValue = prev + 1;

                  if (newValue <= 99) {
                    return prev + 1;
                  }

                  return prev;
                })
              }
              onSub={() => setTempTimerMinutes((prev) => Math.max(0, prev - 1))}
            />
          }
        />
        <AmountInput
          block
          bold={false}
          integersOnly
          integerLength={2}
          positiveOnly
          minority={1}
          value={String(tempTimerSeconds).padStart(2, '0')}
          suffix=" сек"
          onChange={(_, { value }) =>
            setTempTimerSeconds(Math.min(value || 0, 59))
          }
          rightAddons={
            <Stepper
              onAdd={() =>
                setTempTimerSeconds((prev) => (prev === 59 ? 0 : prev + 1))
              }
              onSub={() =>
                setTempTimerSeconds((prev) => (prev === 0 ? 59 : prev - 1))
              }
            />
          }
        />
      </div>
    </FunctionalModal>
  );
};
