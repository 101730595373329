import { QueryFunctionContext, useInfiniteQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  securitiesActivesInSearch,
  SecuritiesActivesInSearchRequest,
} from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesInSearch = (
  params: SecuritiesActivesInSearchRequest,
  enable: boolean = true
) => {
  const { page, ...otherParams } = params;

  const addNotification = useNotification();

  const fetchResult = ({ pageParam = page }: QueryFunctionContext) => {
    return securitiesActivesInSearch({
      ...otherParams,
      page: pageParam,
    });
  };

  return useInfiniteQuery(
    [LkQueryKeys.SecuritiesHistory, { otherParams }],
    fetchResult,
    {
      getNextPageParam: (lastPage, pages) => {
        const pageNum = pages.length;

        if (pageNum < lastPage.pageCount) {
          return pageNum + 1;
        }
      },
      enabled: enable && params.searchString !== undefined,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при поиске активов',
        });
      },
    }
  );
};
