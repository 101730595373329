import { subscribe, unsubscribe } from '../services/streaming';
import { FrontEndType } from './entities';
import { EntityType } from './entityTypes';
import AdirClientService from './service';

export abstract class EntityService extends AdirClientService {
  abstract defaultEntity: EntityType;
  abstract frontend: FrontEndType;
  abstract operationIdField: string;

  operations = new Map<BigInt, (data: unknown) => void>();
  private apiTimeout: number = 5000;
  subscribed: boolean = false;

  subscribe<T>(updater: (message: T[]) => void) {
    const subscribeReturnData = subscribe<object>(
      {
        fi: [],
        entity: this.defaultEntity,
        frontend: this.frontend,
      },
      (message) => {
        for (const msg of message.data) {
          const handler = this.operations.get(msg[this.operationIdField]);

          handler?.(msg);
        }

        updater(message.data as T[]);
      }
    );

    this.subscribed = true;

    return () => {
      unsubscribe(
        {
          fi: [],
          entity: this.defaultEntity,
          frontend: this.frontend,
        },
        subscribeReturnData
      );
      this.subscribed = false;
    };
  }

  public postEntity(data: unknown, entity: EntityType = this.defaultEntity) {
    this.client.send({
      frontend: this.frontend,
      isArray: false,
      payload: {
        type: entity,
        // @ts-expect-error
        data: data,
      },
    });
  }

  public postEntityWithResult<T>(
    id: BigInt,
    data: unknown,
    entity: EntityType = this.defaultEntity
  ): Promise<T> {
    if (!this.subscribed) {
      throw Error(`${entity}: cannot post entity without subscribe`);
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(`${entity}: response timeout`);
        this.operations.delete(id);
      }, this.apiTimeout);

      const successHandler = (responseData: T) => {
        resolve(responseData);
        this.operations.delete(id);
      };

      // @ts-expect-error TODO нужно придумать как написать тип
      this.operations.set(id, successHandler);

      this.client.send({
        frontend: this.frontend,
        isArray: false,
        payload: {
          type: entity,
          // @ts-expect-error
          data: data,
        },
      });
    });
  }
}
