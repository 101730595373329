import { createPortal } from 'react-dom';

import { usePortal } from '@terminal/core/hooks/usePortal';

interface PortalProps {
  id: string;
  children: React.ReactNode;
}

export const Portal = ({ id, children }: PortalProps) => {
  const target = usePortal(id);

  return createPortal(children, target);
};
