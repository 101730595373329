import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { ModalMobile } from '@alfalab/core-components/modal/mobile';
import { Typography } from '@alfalab/core-components/typography';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';

import loveEmojiFace1x from './loveEmojiFace1x.png';
import loveEmojiFace2x from './loveEmojiFace2x.png';

import styles from './BrokerageOpeningModal.module.css';

export const BrokerageOpeningModal = (props) => {
  return (
    <ModalMobile
      open={props.open}
      onClose={props.onClose}
      contentClassName={styles.wrapper}
      transitionProps={{ timeout: 0 }}
    >
      <div className={styles.header}>
        <div className={styles.closeWrapper} onClick={props.onClose}>
          <CrossCircleMIcon width={24} height={24} color="#4F4F52" />
        </div>
      </div>
      <ModalMobile.Content className={styles.content}>
        <div>
          <Gap size="m" />
          <img
            width={48}
            height={48}
            src={loveEmojiFace1x}
            srcSet={`${loveEmojiFace2x} 2x`}
            alt=""
          />
          <Gap size="m" />
          <Gap size="xl" />
          <Typography.Title
            view="xsmall"
            tag="div"
            weight="bold"
            className={styles.title}
          >
            Открываем вам счёт,
            <br />
            это займет около 10 минут
          </Typography.Title>
          <Gap size="s" />

          <Typography.Text tag="div" className={styles.title}>
            Пришлём смс, когда всё будет готово.
          </Typography.Text>
          <Gap size="s" />
          <Typography.Text tag="div" className={styles.title}>
            А пока узнайте последние новости об инвестициях в нашем каналев в
            Telegram
          </Typography.Text>
        </div>
        <div>
          <Button
            view="primary"
            size="m"
            href="https://t.me/alfa_investments"
            className={styles.customButton}
            color="black"
            target="_blank"
          >
            Перейти в Telegram
          </Button>
          <Gap size="m" />
        </div>
      </ModalMobile.Content>
    </ModalMobile>
  );
};
