import { useMemo } from 'react';

import { ChatMessage } from '../types';

interface SeparatedMessages {
  readMessages: ChatMessage[];
  unreadMessages: ChatMessage[];
}

export const useSeparateSupportUnreadMessages = (
  messages: ChatMessage[]
): SeparatedMessages => {
  return useMemo(() => {
    // TODO Реализовать разделение непрочитанных сообщений после подключения к WS поддержки

    return {
      readMessages: messages,
      unreadMessages: [],
    };
  }, [messages]);
};
