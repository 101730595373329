import { ChatMessageEntity } from '@terminal/core/lib/client/entities';

import { brokerRecommendationMap } from './brokerRecommendationMapping';

import { BrokerChatMessage } from '../types';

export const brokerChatMessageMap = (
  entity: ChatMessageEntity
): BrokerChatMessage => ({
  idChatMessage: entity.IdChatMessage,
  idSender: entity.IdSender,
  senderNickname: entity.SenderNickname,
  idRecipient: entity.IdRecipient,
  recipientNickname: entity.RecipientNickname,
  idChatGroup: entity.IdChatGroup.toString(),
  dtChatMessage: entity.DtChatMessage,
  text: entity.Text,
  version: entity.Version.toString() ?? '',
  operation: entity.Operation,
  idBrokerRecommendation: entity.IdBrokerRecommendation,
  brokerRecommendations: (entity.BrokerRecommendation || []).map(
    brokerRecommendationMap
  ),
});
