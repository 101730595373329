export function getProcessedCurrency(currency: string): string {
  if (currency === 'RUB') {
    return 'RUR';
  } else if (currency === 'HKD') {
    return 'HK$';
  } else if (currency === 'CNY') {
    return '元';
  }

  return currency;
}
