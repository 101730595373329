import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { logAuthorizationPerformance } from '@terminal/core/lib/analytics/performance';
import { useStore } from '@terminal/core/store';
import { AppState } from '@terminal/core/types/app';

import { isPassportPath } from '../lib/isPassportPath';
import { useLoginPinCodeStore } from '../lib/storage';
import { updateAuthPhoneLoginState } from '../lib/updateAuthPhoneLoginState';

import { PhoneLoginState } from '../model/types/PhoneLoginState';

// Идея в том что когда пользователь открывает новую вкладку,
// мы сразу его редиректим на паспортную авторизаци. Но если он попал на страницу логина по иной причине
// например дисконект сервера или ошибка при авторизации, то он будет видеть страницу с кнопкой для повторного логина
const SESSION_STORAGE_KEY = 'terminal_last_passport_auth';
const authPerformanceLogger = logAuthorizationPerformance();

export const useLoginWithPassport = (
  redirect: string,
  isMobile: boolean,
  url: string,
  inProgress?: boolean
) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const error = useStore((store) => store.connectionError);
  const connectPassport = useStore((store) => store.loginWithPassport);
  const setAppState = useStore((store) => store.setAppState);
  const hasPinCode = useLoginPinCodeStore((store) => store.hasPinCode);

  useEffect(() => {
    if (location.pathname === (isMobile ? '/auth' : '/')) {
      if (sessionStorage && !sessionStorage.getItem(SESSION_STORAGE_KEY)) {
        sessionStorage.setItem(SESSION_STORAGE_KEY, new Date().toISOString());
        // специально тут стейт ставится чтоб пользователь не видел блинк кнопки после редиректа
        setAppState(AppState.AUTHORIZING_PASSPORT);
        authPerformanceLogger.authStarted();
        //@ts-expect-error
        window.location = url;
      }
    }
  }, [location, url, isMobile, navigate, setAppState, redirect]);

  useEffect(() => {
    const code = searchParams.get('code');

    if (isPassportPath(location) && code) {
      (async () => {
        connectPassport(code, false, await hasPinCode());
        updateAuthPhoneLoginState(PhoneLoginState.CONNECT_PASSPORT);
        authPerformanceLogger.authStarted();
        navigate('/', { state: { redirect } });
      })();
    }
  }, [searchParams, location, connectPassport, navigate, redirect, hasPinCode]);

  const isLoading = inProgress || isPassportPath(location);

  return { isLoading, error, authPerformanceLogger };
};
