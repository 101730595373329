import { Node } from 'flexlayout-react';

import { ElementType } from '../types/layout';

export const findLayoutWidgets = (nodes: Node[]) => {
  let widgets: Node[] = [];

  for (let node of nodes) {
    const type = node.getType();

    if (type === ElementType.TAB) {
      widgets.push(node);
    } else if (type === ElementType.TABSET || type === ElementType.ROW) {
      widgets = [...widgets, ...findLayoutWidgets(node.getChildren())];
    }
  }

  return widgets;
};
