import { currency as currencies, CurrencyCodes } from '@alfalab/data';

import { ObjectGroup } from './client/entities';

import { ObjectItemsTable } from '../types/core';

export const getFICurrency = (
  FI: { idObjectCurrency?: number } | undefined,
  objectsTable: ObjectItemsTable
) => {
  if (!FI?.idObjectCurrency) {
    return;
  }

  const object = objectsTable.get('idObject', FI.idObjectCurrency);

  return object?.symbolObject as CurrencyCodes;
};

/**
 * Проверяет, является ли `currencyCode` действительным кодом валюты
 * */
export function isValidCurrency(currency: string): currency is CurrencyCodes {
  const currencyCodes = Object.keys(currencies['CURRENCY_SYMBOLS']);

  // если ты видишь этот код, то его можно удалить если версия core-components > 32
  // нужно было для хотфикса
  if (currency === 'AED') {
    return true;
  }
  // удалять до сюда

  return currencyCodes.includes(currency);
}

// Если нет кода валюты, то отображаем без него
export const getAvailableCurrency = (
  currency: string | undefined
): CurrencyCodes | '' => {
  if (currency && isValidCurrency(currency)) {
    return currency;
  }

  return '';
};

export const getSymbolToShow = (
  currency: string | undefined,
  objectGroup?: ObjectGroup
) => {
  const isShown = objectGroup
    ? ![ObjectGroup.Bonds, ObjectGroup.Futures].includes(objectGroup)
    : true;

  return (isShown ? getAvailableCurrency(currency) : '') as CurrencyCodes;
};
