import { ObjectGroup } from '../client/entities';

import { ObjectTypeItem } from '../../types/core';

/**
 * @returns Стоимость положительных позиций ликвидных активов и отрицательных позиций по всем активам с типом валюта, облигация, акция, пай, ETF.
 * */
export function getLiquedTorgPosCostRur(
  objectType: ObjectTypeItem,
  TorgPos: number,
  TorgPosCost: number | null,
  marginDiscountBuyD0: number,
  NominalCurPrice: number,
  MarketCurPrice: number
): number | null {
  if (objectType?.idObjectGroup === ObjectGroup.Futures) {
    return 0;
  }

  if (TorgPosCost === null) {
    return null;
  }

  if (objectType?.idObjectGroup === ObjectGroup.Currency) {
    return TorgPosCost;
  }

  const CurPriceByObjectGroup =
    objectType?.idObjectGroup === ObjectGroup.Bonds
      ? NominalCurPrice
      : MarketCurPrice;

  if (TorgPos > 0 && 0 < marginDiscountBuyD0 && marginDiscountBuyD0 < 1) {
    return TorgPosCost * CurPriceByObjectGroup;
  }

  if (TorgPos < 0) {
    return TorgPosCost * CurPriceByObjectGroup;
  }

  return 0;
}
