import { Row } from '../model/types/row';
import { Tab } from '../model/types/tab';
import { TabSet } from '../model/types/tabSet';

/***
 * Рекурсивно обходит конфигурацию и собирает настройки отображения графиков для
 * передачи в свойство chart конфигурации
 * @param element
 */
export const getCharts = (element: Row) => {
  let chart = {};
  const findCharts = (element: Tab | TabSet | Row): void => {
    if (element instanceof Tab) {
      if (element.chartSettings) {
        chart = { ...chart, [element.id]: element.chartSettings };
      }
    } else {
      element.children.forEach((child) => {
        findCharts(child);
      });
    }
  };

  findCharts(element);

  return chart;
};
