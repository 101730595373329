import { CorporateActionsHistoryFilter } from '@terminal/core/lib/rest/lkCorporateActions';

import { areDatesOverlapping, isDateValid } from '../../../components/Filter';

export const isFilterValid = (
  filter: CorporateActionsHistoryFilter
): boolean => {
  return (
    isDateValid(filter.from) &&
    isDateValid(filter.to) &&
    !areDatesOverlapping(filter.from, filter.to)
  );
};

export const STATUS_VARIANTS = [
  {
    key: 'S',
    name: 'Принято',
  },
  {
    key: 'E',
    name: 'Исполнено',
  },
  {
    key: 'R',
    name: 'Отклонено',
  },
];
