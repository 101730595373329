import React, { FC, useState } from 'react';

import { useStore } from '@terminal/core/store';
import { trackPriceForEditSelector } from '@terminal/core/store/selectors/trackPrice/trackPriceForEditSelector';

import { FinInstrumentForm as BaseFinInstrumentForm } from '../../../../legacy/components/FinInstrumentForm';
import { Form, FormProps } from './Form';

export type FinInstrumentFormProps = Pick<FormProps, 'children'>;

export const FinInstrumentForm: FC<FinInstrumentFormProps> = (props) => {
  const [trackPrice] = useState(useStore(trackPriceForEditSelector));

  if (trackPrice && !Number.isNaN(trackPrice.IdFI)) {
    return <Form idFi={trackPrice.IdFI} {...props} />;
  }

  return (
    <BaseFinInstrumentForm>
      {(idFi) => <Form idFi={idFi} withGap {...props} />}
    </BaseFinInstrumentForm>
  );
};
