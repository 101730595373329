import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { AlertCircleMIcon } from '@alfalab/icons-glyph/AlertCircleMIcon';
import { DocumentPdfMIcon } from '@alfalab/icons-glyph/DocumentPdfMIcon';
import { RemarksMIcon } from '@alfalab/icons-glyph/RemarksMIcon';

import { LOCATIONS } from '@terminal/core/constants/analytics';
import { trackMainMenu } from '@terminal/core/lib/analytics';
import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import { getURLDownloadPDF } from '@terminal/core/lib/rest/lkCodeTable';

import { useDownloadFile } from '../hooks';
import { useCodeTable } from './hooks';

import styles from './CodeTable.module.css';

const CodesTableGen = ({
  onClickGenCodes,
}: {
  onClickGenCodes: () => void;
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <SuperEllipse size={80}>
          <RemarksMIcon />
        </SuperEllipse>
      </div>
      <Typography.Text
        view="primary-small"
        color="primary"
        weight="bold"
        className={styles.title}
      >
        Для получения кодов сформируйте <br /> новую таблицу
      </Typography.Text>
      <div className={styles.buttons}>
        <Button view="secondary" size="s" onClick={onClickGenCodes} block>
          Сформировать
        </Button>
      </div>
    </div>
  );
};

const Table = () => {
  const [isSigned, setIsSigned] = useState(false);
  const isFileWasRequested = useRef(false);
  const [file, setFile] = useState<DownloadedFile>();

  const { mutateAsync: downloadFile } = useDownloadFile();

  const { data, isFetching, refetch } = useCodeTable({
    successCallback: () => {
      setIsSigned(true);
    },
  });

  useEffect(() => {
    if (isSigned && data?.id && !file && !isFileWasRequested.current) {
      isFileWasRequested.current = true;
      downloadFile(getURLDownloadPDF(data.id))
        .then((file) => {
          setFile(file);
        })
        .catch((e) => {
          isFileWasRequested.current = false;
        });
    }
  }, [data?.id, file, isSigned, downloadFile]);

  const onClickGenCodes = useCallback(() => {
    refetch();
  }, [refetch]);

  if (isFetching) {
    return null;
  }

  if (!isSigned) {
    return (
      <div className={styles.wrapperEmpty}>
        <Typography.Text view="secondary-large" className={styles.title}>
          Необходимо подписать поручение
        </Typography.Text>
        <div className={styles.buttons}>
          <Button view="secondary" size="xs" onClick={onClickGenCodes} block>
            Сформировать новую таблицу
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Typography.Text view="secondary-large" className={styles.title}>
        Таблица кодов голосового доступа
      </Typography.Text>
      <div className={styles.secondRow}>
        <div className={styles.tableIcon}>
          <AlertCircleMIcon
            width={18}
            height={18}
            color="var(--color-light-graphic-attention)"
          />
        </div>
        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={styles.tableSubTitle}
        >
          Внимание! Таблица показывается однократно. Для дальнейшего
          <br />
          использования настоятельно рекомендуем вам сохранить её.
        </Typography.Text>
      </div>

      {data && (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Номер ячейки</th>
                <th>Пароль</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map(({ passNo, password }) => {
                return (
                  <tr key={passNo}>
                    <td>{passNo}</td>
                    <td>{password}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={styles.buttons}>
            <Button
              view="tertiary"
              size="s"
              leftAddons={<DocumentPdfMIcon width={18} height={18} />}
              disabled={!file || isFetching}
              loading={!file || isFetching}
              href={file?.url}
              download={file?.filename}
              target="_blank"
            >
              Скачать PDF
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export const CodeTable = () => {
  const [isShowTable, setIsShowTable] = useState(false);

  useEffect(() => {
    trackMainMenu(LOCATIONS.LK_CODES);
  }, []);

  if (isShowTable) {
    return <Table />;
  }

  return <CodesTableGen onClickGenCodes={() => setIsShowTable(true)} />;
};
