import { ReactNode, useState } from 'react';
import { BottomSheet } from '@alfalab/core-components/bottom-sheet';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { PureCell } from '@alfalab/core-components/pure-cell';
import { Typography } from '@alfalab/core-components/typography';
import { CallMIcon } from '@alfalab/icons-glyph/CallMIcon';
import { PhoneMIcon } from '@alfalab/icons-glyph/PhoneMIcon';

import styles from './NavigationBar.module.css';

const TEL_RUSSIA = '8 800 707-55-55';
const TEL_MOSCOW = '+7 (495) 786-48-86';

interface Props {
  leftButton?: ReactNode;
}

export const NavigationBar = (props: Props) => {
  const { leftButton } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.leftButton}>{leftButton}</div>
        <IconButton
          icon={PhoneMIcon}
          size="s"
          onClick={() => setIsOpen(true)}
        />
      </div>
      <BottomSheet open={isOpen} onClose={() => setIsOpen(false)}>
        <Typography.Text view="primary-large" color="primary" weight="bold">
          Позвонить в банк
        </Typography.Text>
        <Gap size="l" />
        <PureCell
          direction="horizontal"
          verticalPadding="default"
          href={`tel:${TEL_RUSSIA.replace(/\s+/g, '')}`}
        >
          <PureCell.Graphics>
            <SuperEllipse size={48}>
              <CallMIcon />
            </SuperEllipse>
          </PureCell.Graphics>
          <PureCell.Content>
            <PureCell.Main>
              <PureCell.Text
                titleColor="secondary"
                valueColor="secondary"
                view="primary-small"
              >
                Россия
              </PureCell.Text>
              <PureCell.Text
                titleColor="link"
                valueColor="secondary"
                view="primary-medium"
              >
                {TEL_RUSSIA}
              </PureCell.Text>
            </PureCell.Main>
          </PureCell.Content>
        </PureCell>
        <PureCell
          direction="horizontal"
          verticalPadding="default"
          href={`tel:${TEL_MOSCOW.replace(/\s+/g, '')}`}
        >
          <PureCell.Graphics>
            <SuperEllipse size={48}>
              <CallMIcon />
            </SuperEllipse>
          </PureCell.Graphics>
          <PureCell.Content>
            <PureCell.Main>
              <PureCell.Text
                titleColor="secondary"
                valueColor="secondary"
                view="primary-small"
              >
                Москва и область
              </PureCell.Text>
              <PureCell.Text
                titleColor="link"
                valueColor="secondary"
                view="primary-medium"
              >
                {TEL_MOSCOW}
              </PureCell.Text>
            </PureCell.Main>
          </PureCell.Content>
        </PureCell>
        <Gap size="m" />
      </BottomSheet>
    </>
  );
};
