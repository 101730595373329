import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';

import { SpbexRegistrationParams } from './types/SpbexRegistrationParams';

export async function spbexRegistration(data: SpbexRegistrationParams) {
  return await signOperation(
    `/api/trading-platform/register-on-spbex/${data.params.treaty}`,
    'GET',
    undefined,
    data.signOptions
  );
}
