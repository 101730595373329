import { useEffect, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';
import { SignMIcon } from '@alfalab/icons-glyph/SignMIcon';

import {
  AddAccountDocumentType,
  addGoInvestAccountRegister,
  ApplicationText,
  useAddAccountDocument,
} from '../../../../entities/AddAccount';
import {
  SmsSignDialog,
  useSignSmsDialog,
} from '../../../../legacy/components/SmsSignDialog';

import styles from './AddAccountForm.module.css';

export interface AddAccountFormProps {
  type: AddAccountDocumentType;
  onSuccess: () => void;
  onFailed: () => void;
}

export const AddAccountForm = ({
  type,
  onSuccess,
  onFailed,
}: AddAccountFormProps) => {
  const [agree, setAgree] = useState(false);
  const [textOpen, setTextOpen] = useState(false);

  const {
    data: document,
    isFetching: pending,
    isError,
  } = useAddAccountDocument(type);

  const onAgreeChange = () => {
    setAgree(!agree);
  };

  const {
    create: registrateNewAccount,
    createPending,
    isCreateError,
    smsSending,
    isSendSmsError,
    resendSms,
    openDialog: openSignDialog,
    checkSmsCode,
    isCheckError,
    isSuccess,
    error,
    cancel: cancelAccountRegistration,
    assignment,
  } = useSignSmsDialog({
    initAction: addGoInvestAccountRegister,
    successMsg: {
      title: 'Вы открыли Брокерский счет!',
    },
    errorMsg: {
      title: 'Мы не смогли открыть новый счёт',
      text: 'Попробуйте ещё раз или напишите нам в чат',
    },
  });

  useEffect(() => {
    if (isCreateError || isError) {
      onFailed();
    }
  }, [isCreateError, onFailed, isError]);

  const signAddAccount = async () => {
    if (document?.id) {
      registrateNewAccount(document?.id);
    }
  };

  return (
    <div className={styles.container}>
      <Typography.Text
        view="primary-small"
        weight="bold"
        color="primary"
        tag="p"
        defaultMargins={false}
      >
        {type === 'REPO'
          ? 'Для открытия счёта РЕПО вам необходимо подписать всего один документ:'
          : 'Для открытия брокерского счёта вам необходимо подписать всего один документ:'}
      </Typography.Text>
      <Gap size="xl" />
      <Space fullWidth={true} direction="vertical">
        {pending ? (
          <Loader />
        ) : (
          <Link
            leftAddons={
              <DocumentLinesMIcon
                className={styles.docIcon}
                width={18}
                height={18}
              />
            }
            onClick={() => setTextOpen(true)}
          >
            Заявление на обслуживание на финансовых рынках
          </Link>
        )}

        <Checkbox
          onChange={onAgreeChange}
          checked={agree}
          size="s"
          align="center"
          label="С Заявлением ознакомлен"
        />

        <div className={styles.submit}>
          <Button
            size="s"
            block
            leftAddons={<SignMIcon width={18} height={18} />}
            disabled={!agree}
            onClick={signAddAccount}
            loading={createPending || smsSending}
          >
            Подписать
          </Button>
        </div>
      </Space>

      {document && (
        <ApplicationText
          text={document.document}
          backLabel="Открытие нового счета"
          open={textOpen}
          onClose={() => setTextOpen(false)}
        />
      )}

      <SmsSignDialog
        open={openSignDialog}
        codeSending={smsSending}
        error={error}
        isError={isCreateError || isSendSmsError || isCheckError}
        isSuccess={isSuccess}
        assignment={assignment}
        onCancel={cancelAccountRegistration}
        onResend={resendSms}
        signCheck={checkSmsCode}
        onComplete={onSuccess}
      />
    </div>
  );
};
