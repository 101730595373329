import { LineBanner } from '../LineBanner';
import { ShortLineBanner } from '../ShortLineBanner';

interface ServiceLineBannerProps {
  message: string;
  short?: boolean;
  className?: string;
  shortText?: string;
  closable?: boolean;
}

export const ServiceLineBanner = ({
  short,
  message,
  className,
  shortText,
  closable,
}: ServiceLineBannerProps) =>
  closable ? (
    <LineBanner message={message} />
  ) : (
    <ShortLineBanner
      message={message}
      className={className}
      shortText={shortText}
      short={short}
    />
  );
