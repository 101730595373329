import React, { useState } from 'react';
import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { HttpStatusError } from '@terminal/core/lib/rest/lkErrors';
import { SignOrderStatus } from '@terminal/core/lib/rest/lkSignOperation';
import {
  smsSignCodeCheck,
  SmsSignCreated,
  SmsSigningInit,
  SmsSignResult,
  smsSignSendSms,
} from '@terminal/core/lib/rest/lkSmsSignOperation';
import { NotificationType } from '@terminal/core/types/ui';

export function useSignSmsDialog<TCreate>(params: {
  initAction: (arg: TCreate) => Promise<SmsSignCreated>;
  successMsg?: {
    title: string;
    text?: string;
    content?: React.ReactNode;
  };
  errorMsg?: {
    title: string;
    text?: string;
    content?: React.ReactNode;
  };
}) {
  const addNotification = useNotification();

  const [openDialog, setOpenDialog] = useState(false);
  const [operation, setOperation] = useState<SmsSignCreated | null>(null);
  const [assignment, setAssignment] = useState<SmsSigningInit | null>(null);
  const [error, setError] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [orderStatus, setOrderStatus] = useState<SignOrderStatus | null>(null);

  const initMutation = useMutation(params.initAction, {
    onSuccess: async (data) => {
      setOperation(data);

      if (data.code !== 0) {
        setError({
          code: data.code,
          message: data.message,
        });

        throw new Error(data.message || '');
      }

      try {
        await sendSmsMutation.mutateAsync(data.url);
      } catch (_) {
        // Ловим ошибку, чтобы избежать uncatch. Сообщение об ошибке покажет onError мутации
      }
    },
    onError: (error: any) => {
      if (error instanceof HttpStatusError) {
        if (error.data) {
          setOperation({
            url: '',
            id: '',
            data: error.data,
            code: error.code,
            message: error.message,
          });
          setError({
            code: error.code,
            message: error.message,
          });

          return;
        }
      }

      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При создании операции произошла ошибка',
      });
    },
  });

  const sendSmsMutation = useMutation(smsSignSendSms, {
    onSuccess: (assignment) => {
      if (assignment.code !== 0) {
        setError({
          code: assignment.code,
          message: assignment.message,
        });

        throw new Error(assignment.message || '');
      }

      setAssignment(assignment);
      setOpenDialog(true);
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Произошла ошибка при отправке смс',
      });
      setAssignment(null);
      setOpenDialog(false);
    },
  });

  const codeCheckMutation = useMutation(smsSignCodeCheck, {
    onSuccess: (result: SmsSignResult) => {
      if (result.code === 0) {
        if (params.successMsg) {
          addNotification({
            type: NotificationType.SYSTEM,
            badge: 'positive',
            title: params.successMsg.title,
            text: params.successMsg.text,
          });
        }

        setOrderStatus(result?.data?.orderStatus || null);
        setIsSuccess(true);
        setOpenDialog(false);
      } else if (result.code === 1) {
        setError({
          code: result.code,
          message: result.message,
        });
      } else {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: params.errorMsg?.title || 'Ошибка',
          text: result.message || params.errorMsg?.text,
        });
        reset();
      }
    },
    onError: () => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'attention',
        title: params.errorMsg?.title || 'Ошибка',
        text: params.errorMsg?.text,
      });
      reset();
    },
  });

  const create = async (arg: TCreate) => {
    reset();

    try {
      return await initMutation.mutateAsync(arg);
    } catch (_) {
      // Ловим ошибку, чтобы избежать uncatch. Сообщение об ошибке покажет onError мутации
    }
  };

  const resendSms = async () => {
    if (operation) {
      try {
        await sendSmsMutation.mutateAsync(operation.url);
      } catch (_) {
        // Ловим ошибку, чтобы избежать uncatch. Сообщение об ошибке покажет onError мутации
      }
    } else {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса СМС',
        text: `Не были получены данные с сервера`,
      });
    }
  };

  const checkSmsCode = async (code: string) => {
    if (operation && assignment) {
      try {
        await codeCheckMutation.mutateAsync({
          smsReference: assignment.reference,
          smsCode: code,
          opText: assignment.opText,
          operationUrl: operation.url,
        });
      } catch (_) {
        // Ловим ошибку, чтобы избежать uncatch. Сообщение об ошибке покажет onError мутации
      }
    } else {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: 'При исполнении операции произошла ошибка',
      });
    }
  };

  const reset = () => {
    setOpenDialog(false);
    setAssignment(null);
    setOperation(null);
    setError(null);
    setIsSuccess(false);
  };

  const cancel = () => {
    reset();
  };

  return {
    create,
    createPending: initMutation.isLoading,
    createError: initMutation.error,
    isCreateError: initMutation.isError,
    smsSending: sendSmsMutation.isLoading,
    sendSmsError: sendSmsMutation.error,
    isSendSmsError: sendSmsMutation.isError,
    resendSms,
    openDialog,
    checkSmsCode,
    checkError: codeCheckMutation.error,
    isCheckError: codeCheckMutation.isError,
    isSuccess,
    orderStatus,
    error,
    cancel,
    assignment,
    operation,
  };
}
