import { useState } from 'react';
import { Select } from '@alfalab/core-components/select';
import { Space } from '@alfalab/core-components/space';

import { FreeOrderThemes } from '@terminal/core/lib/rest/lkFreeOrders';

import { FreeOrderFormDefault } from './components/FreeOrderFormDefault';
import { FreeOrderFormTaxRefund } from './components/FreeOrderFormTaxRefund';
import {
  OVER_WITHELD_TAX_REFUND_THEME_ID,
  TERMINATION_TAX_REFUND_THEME_ID,
} from './constants';

import { useSendFreeOrder } from '../hooks/useSendFreeOrder';

interface NewFreeOrderProps {
  orderThemes: Array<FreeOrderThemes> | undefined;
  treaty: number | undefined;
}

export const NewFreeOrder = ({ orderThemes, treaty }: NewFreeOrderProps) => {
  const [themeId, setThemeId] = useState<string>('');
  const { isLoading, mutateAsync: sendFreeOrder } = useSendFreeOrder();

  if (!orderThemes || !orderThemes.length) {
    return null;
  }

  const themesOptions = orderThemes.map((theme) => ({
    key: theme.id.toString(),
    content: theme.theme,
  }));

  const onOrderSend = (text: string) => {
    if (themeId && text && treaty) {
      void sendFreeOrder({
        params: { text, treaty, themeId },
        signOptions: {
          successText: 'Отследить статус запроса можно в разделе История',
          successCallback: () => {
            setThemeId('');
          },
        },
      });
    }
  };

  return (
    <Space fullWidth size="l">
      <Select
        label="Тема"
        labelView="outer"
        size="s"
        optionsSize="s"
        block
        options={themesOptions}
        selected={themeId}
        placeholder="Выберите тему поручения"
        onChange={(payload) => setThemeId(payload.selected?.key || '')}
      />
      {(() => {
        const theme = parseInt(themeId);

        if (
          theme === OVER_WITHELD_TAX_REFUND_THEME_ID ||
          theme === TERMINATION_TAX_REFUND_THEME_ID
        ) {
          return (
            <FreeOrderFormTaxRefund
              themeId={theme}
              sending={isLoading}
              onSend={onOrderSend}
              treaty={treaty || 0}
            />
          );
        }

        return (
          <FreeOrderFormDefault
            themeId={parseInt(themeId)}
            sending={isLoading}
            onSend={onOrderSend}
          />
        );
      })()}
    </Space>
  );
};
