import React, { FC } from 'react';

import { useThemeContext } from '../../../shared/lib/themeContext';

interface Props {
  styles: string;
}

/**
 * Переопределяет токены для темной темы, если необходимо
 * */
export const DarkStyles: FC<Props> = ({ styles }) => {
  const { theme } = useThemeContext();

  if (theme === 'dark') {
    return <style>{styles}</style>;
  }

  return null;
};
