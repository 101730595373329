import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { LockClosedMIcon } from '@alfalab/icons-glyph/LockClosedMIcon';

import styles from './TradingClosed.module.css';

export const TradingClosed = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <SuperEllipse size={48}>
          <LockClosedMIcon />
        </SuperEllipse>
      </div>
      <Typography.TitleMobile
        view="small"
        tag="div"
        color="primary"
        className={styles.title}
      >
        Торги закрыты
      </Typography.TitleMobile>
      <Typography.TitleMobile
        view="small"
        tag="div"
        color="secondary"
        className={styles.subTitle}
      >
        Очередь заявок отображается
        <br />
        только в торговое время
      </Typography.TitleMobile>
    </div>
  );
};
