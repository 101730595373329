import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';

/**
 *
 * @param account - торговый аккаунт
 * @param limit - фактически доступные средства
 */
export function modifyTrandAccountLimit(
  account: TradeAccount,
  limit: number
): TradeAccount {
  return {
    ...account,
    assetValue: limit,
  };
}
