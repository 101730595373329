import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { moneyTransferToAlfa } from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

export const useMoneyTransferToAlfa = () => {
  const addNotification = useNotification();

  return useMutation(moneyTransferToAlfa, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Произошла ошибка при попытке перевода',
      });
    },
  });
};
