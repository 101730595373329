import cn from 'classnames';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { IconButton } from '@alfalab/core-components/icon-button';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { BottomSheet } from '@terminal/common/components/BottomSheet';

import styles from './InfoModal.module.css';

interface InfoModalProps {
  open: boolean;
  rounded?: boolean;

  mobile?: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export const InfoModal = ({
  open,
  rounded,
  mobile,
  onClose,
  children,
}: InfoModalProps) =>
  mobile ? (
    <BottomSheet
      title="Пополнение"
      titleAlign="center"
      open={open}
      initialHeight="full"
      onClose={onClose}
      swipeable
      hasCloser
    >
      {children}
    </BottomSheet>
  ) : (
    <BaseModal open={open} onClose={onClose} Backdrop={() => null}>
      <div className={styles.backdrop} onClick={onClose}></div>
      <div className={cn(styles.container, rounded && styles.containerRounded)}>
        <div className={styles.header}>
          <IconButton
            size="xs"
            view="secondary"
            icon={CrossMIcon}
            onClick={onClose}
            className={styles.close}
          />
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </BaseModal>
  );
