import { ECurrencyId } from '../../types/currencyId';

export function getCurrencyCode(idObjectCurrency: number): string {
  switch (idObjectCurrency) {
    case ECurrencyId.USD:
      return 'USD';
    case ECurrencyId.EUR:
      return 'EUR';
    case ECurrencyId.GBP:
      return 'GBP';
    case ECurrencyId.CHF:
      return 'CHF';
    case ECurrencyId.CNY:
      return 'CNY';
    case ECurrencyId.HKD:
      return 'HKD';
  }

  return 'RUB';
}
