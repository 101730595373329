import { endOfDay, parseISO, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { CalendarInput } from '@alfalab/core-components/calendar-input';
import { Checkbox, CheckboxProps } from '@alfalab/core-components/checkbox';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Input } from '@alfalab/core-components/input';
import { Space } from '@alfalab/core-components/space';
import { Textarea } from '@alfalab/core-components/textarea';

import { SecuritiesManager } from '@terminal/core/lib/rest/lkSecurities';

import {
  isDateValid,
  isFieldFilled,
  removeUnwantedChar,
} from '../../validation';

import { useValidation } from '../../hooks';

export interface RequisitesPersonalProps {
  manager: SecuritiesManager | undefined;
  docNumber: string;
  onDocNumberChange: (num: string) => void;
  docDate: string;
  onDocDateChange: (date: string) => void;
  docWhom: string;
  onDocWhomChange: (date: string) => void;
  requisitesCorrect: boolean;
  onRequisitesCorrectChange: (check: boolean) => void;
}

export const RequisitesPersonal = ({
  manager,
  docNumber,
  onDocNumberChange,
  docDate,
  onDocDateChange,
  docWhom,
  onDocWhomChange,
  requisitesCorrect,
  onRequisitesCorrectChange,
}: RequisitesPersonalProps) => {
  const values = useMemo(
    () => ({
      docNumber,
      docDate,
      docWhom,
    }),
    [docNumber, docDate, docWhom]
  );
  const validators = useMemo(
    () => ({
      docNumber: isFieldFilled,
      docDate: isDateValid,
      docWhom: isFieldFilled,
    }),
    []
  );

  const { handleChange, handleBlur, touched, errors } = useValidation({
    values: values,
    transforms: {
      docNumber: (value) => removeUnwantedChar(value),
      docDate: (value) => removeUnwantedChar(value),
      docWhom: (value) => removeUnwantedChar(value),
    },
    validators: validators,
    handlers: {
      docNumber: onDocNumberChange,
      docDate: onDocDateChange,
      docWhom: onDocWhomChange,
    },
  });

  const handleRequisitesCorrect: CheckboxProps['onChange'] = (e, payload) => {
    onRequisitesCorrectChange(payload?.checked || false);
  };

  const minDocDate = useMemo(
    () => startOfDay(parseISO('1900-01-01T00:00:00')).getTime(),
    []
  );
  const maxDocDate = useMemo(() => endOfDay(new Date()).getTime(), []);

  return (
    <Space fullWidth direction="vertical">
      <Input
        label="ФИО"
        labelView="outer"
        block
        size="s"
        readOnly
        value={manager?.managerFullName ?? ''}
      />
      <Row>
        <Col width={6}>
          <Input
            label="Серия и номер паспорта РФ"
            labelView="outer"
            block
            size="s"
            name="docNumber"
            value={docNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.docNumber ? errors.docNumber : ''}
          />
        </Col>
        <Col width={6}>
          <CalendarInput
            label="Дата выдачи документа"
            labelView="outer"
            size="s"
            block
            minDate={minDocDate}
            maxDate={maxDocDate}
            name="docDate"
            value={docDate}
            onChange={(e, { value }) => onDocDateChange(value)}
            onInputChange={(e, { value }) => onDocDateChange(value)}
            onBlur={handleBlur}
            error={touched.docDate ? errors.docDate : ''}
          />
        </Col>
      </Row>
      <Textarea
        label="Кем выдан документ"
        labelView="outer"
        block={true}
        size="s"
        name="docWhom"
        value={docWhom}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.docWhom ? errors.docWhom : ''}
        maxLength={200}
      />
      <Checkbox
        onChange={handleRequisitesCorrect}
        checked={requisitesCorrect}
        size="s"
        label="Паспортные данные указаны верно"
        align="center"
      />
    </Space>
  );
};
