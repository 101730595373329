import { FC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { ChevronBackMIcon } from '@alfalab/icons-glyph/ChevronBackMIcon';

import styles from './BackButton.module.css';

export interface BackButtonProps {
  text: string;
  onClick: () => void;
}

export const BackButton: FC<BackButtonProps> = ({ text, onClick }) => {
  return (
    <ButtonDesktop
      className={styles.back}
      view="ghost"
      onClick={onClick}
      leftAddons={
        <div className={styles.backCircle}>
          <ChevronBackMIcon width={18} height={18} />
        </div>
      }
    >
      {text}
    </ButtonDesktop>
  );
};
