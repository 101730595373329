import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { ContestProfile } from '../model/types/ContestProfile';
import { ContestProfileParams } from './types/ContestProfileParams';

export async function getContestProfile(
  data: ContestProfileParams
): Promise<ContestProfile> {
  const result = await fetchLkResult<ContestProfile | LKResult>(
    qs.stringifyUrl({
      url: '/api/contest/participant/profile',
      query: { ...data },
    })
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result;
}
