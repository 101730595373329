import { Fragment, memo, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Spinner } from '@alfalab/core-components/spinner';
import { Typography } from '@alfalab/core-components/typography';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';

import {
  EDUCATION_MODAL_DRAWER_ID,
  StrategyProfitNote,
  useEducationStrategies,
} from '../../entities/Education';
import {
  EducationStrategyDetail,
  EducationStrategyItem,
} from '../../features/Education';

import styles from './Education.module.css';

export const Education = memo(() => {
  const [strategyId, setStrategyId] = useState(0);

  const { data: strategies, isFetching: strategiesPending } =
    useEducationStrategies();

  return (
    <div className={styles.container}>
      <Typography.Title tag="h2" view="xsmall">
        Стратегии Go Invest
      </Typography.Title>
      <Gap size="xs" />
      <Typography.Text tag="p" view="secondary-small">
        Go Invest помогает освоить&nbsp;3 метода торговли разной степени
        сложности: дневная торговля, свинговая торговля или&nbsp;технический
        анализ, профиль аукциона
      </Typography.Text>
      <Gap size="l" />

      <Spinner visible={strategiesPending} />

      {strategies?.map((strategy, index) => (
        <Fragment key={`strategy-${strategy.id}`}>
          {index > 0 && <Gap size="s" />}
          <EducationStrategyItem
            item={strategy}
            onClick={() => setStrategyId(strategy.id)}
          />
        </Fragment>
      ))}

      <Gap size="l" />
      <StrategyProfitNote />

      <Portal id={EDUCATION_MODAL_DRAWER_ID}>
        <FunctionalModalSidebar
          open={Boolean(strategyId)}
          backLabel="Стратегии"
          onClose={() => setStrategyId(0)}
        >
          <EducationStrategyDetail strategyId={strategyId} />
        </FunctionalModalSidebar>
      </Portal>
    </div>
  );
});
