import cn from 'classnames';
import { ChevronForwardMIcon } from '@alfalab/icons-glyph/ChevronForwardMIcon';

import { SecuritiesDraftListItem } from '@terminal/core/lib/rest/lkSecurities';

import { Direction } from '../Direction';
import { DIRECTION_BY_DRAFT_OP_TYPE } from './helpers';

import styles from './DraftItem.module.css';
import draftstable from './draftsTable.module.css';

interface HistoryItemProps {
  item: SecuritiesDraftListItem;
  odd?: boolean;
  onClick?: (item: SecuritiesDraftListItem) => void;
}

export const DraftItem = ({ item, odd, onClick }: HistoryItemProps) => (
  <div
    className={cn(styles.item, draftstable.row, {
      [draftstable.rowOdd]: odd,
    })}
    onClick={onClick ? () => onClick(item) : undefined}
  >
    <div
      className={cn(
        draftstable.cell,
        draftstable.cellFade,
        draftstable.cellName
      )}
    >
      <div className={styles.name}>{item.activeName}</div>
      <div className={styles.isin}>{item.isin}</div>
    </div>
    <div
      className={cn(
        draftstable.cell,
        draftstable.cellFade,
        draftstable.cellType
      )}
    >
      <Direction type={DIRECTION_BY_DRAFT_OP_TYPE[item.transferType]} />
    </div>
    <div className={cn(draftstable.cell, draftstable.cellAmount)}>
      <div
        className={cn(styles.amount, {
          [styles.amountIn]: item.transferType === 'in',
        })}
      >
        <span className={styles.amountSign}>
          {item.transferType === 'in' ? '+' : '-'}
        </span>
        <span className={styles.amountValue}>{item.qty.toLocaleString()}</span>
        <span className={styles.amountUnit}>шт</span>
      </div>
    </div>
    <div className={cn(draftstable.cell, draftstable.cellAction)}>
      <ChevronForwardMIcon
        width={14}
        height={14}
        className={styles.actionIcon}
      />
    </div>
  </div>
);
