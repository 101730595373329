let timeoutWorker: Worker | null = null;

/*
  Регулярные хартбиты к серверу выполняют несколько функций, среди которых
  поддержка юзера в залогиненном состоянии.
  Проблема возникла при сворачивании вкладки - юзера разлогинивало из системы.
  Порговое значение таймаута между хартбитами составляет 130с, а большинство браузеров
  замедляет интервалы в неактивных вкладках до 60с, чего должно хватать,
  но эмпирический опыт показал, что не хватает, поэтому решено времено вынести
  функционал в воркер, который на момент написания кода не троттлится в неактивных вкладках

  upd. тротлится, но не так сильно, как интервалы в основном потоке
 */
export const setHeartbeatTimeout = (cb: () => void, timeout: number) => {
  if (Worker) {
    const id = Math.random().toString();

    if (!timeoutWorker) {
      timeoutWorker = new Worker(
        new URL('./backgroundTimeoutWorker.js', import.meta.url)
      );
    }

    const handleMessage = (e) => {
      if (e.data[1] === id) {
        cb();
        timeoutWorker?.removeEventListener('message', handleMessage);
      }
    };

    timeoutWorker.addEventListener('message', handleMessage);
    timeoutWorker.postMessage(['set', timeout, id]);

    return () => {
      timeoutWorker?.postMessage(['clear', id]);
      timeoutWorker?.removeEventListener('message', handleMessage);
    };
  } else {
    cb();
    const timer = setTimeout(cb, timeout);

    return () => clearInterval(timer);
  }
};
