import { Tooltip } from '@alfalab/core-components/tooltip';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkCircleMIcon } from '@alfalab/icons-glyph/CheckmarkCircleMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

import { Spinner } from '@terminal/common/components/Spinner';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { useStore } from '@terminal/core/store';

import { Result } from '../../shared/ui/Result';
import { TestStatusItem } from './QualTesting';

import { useTestingList } from '../hooks/useTestingList';

import styles from './QualTesting.module.css';

interface QualTestListProps {
  setSelectedTestId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const TooltipContent = () => (
  <section className={styles.tooltipContent}>
    Предлагаем пройти тесты, которые откроют возможность:
    <br />— Совершать необеспеченные (маржинальные) операции
    <br />— Производить вложения в производные финансовые инструменты
    <br />— Приобретать структурные продукты
    <br />— Приобретать определенный тип иностранных бумаг и многое другое...
  </section>
);

export function QualTestList({ setSelectedTestId }: QualTestListProps) {
  const { tests } = useAppContext();
  const isAccount = useStore((state) => state.user?.idAccount);
  const { data: testingData, isFetching: isTestsFetching } = useTestingList(
    isAccount,
    true
  );
  const testItemClickHandle = (testItem: TestStatusItem) => {
    if (!testItem.completed) {
      setSelectedTestId(testItem.testId);
    }
  };

  if (testingData && testingData.qualifiedInvestorDate) {
    return (
      <Result
        title="Тестирование не требуется, т.к. вы квалифицированный инвестор"
        maxWidth={500}
      />
    );
  }

  return (
    <>
      <header>
        <div className={styles.header}>
          <Typography.Text
            view="primary-small"
            weight="bold"
            tag="p"
            color="primary"
            defaultMargins={false}
          >
            Тестирование&nbsp;&nbsp;
          </Typography.Text>
          <Tooltip
            content={<TooltipContent />}
            position="bottom"
            trigger="click"
          >
            <InformationCircleLineMIcon className={styles.tooltipIcon} />
          </Tooltip>
        </div>
        <Typography.Text
          view="secondary-large"
          color="secondary"
          tag="p"
          defaultMargins={false}
        >
          По требованию ЦБ РФ с 1 октября 2021 года для совершения отдельных
          видов сделок неквалифицированным инвесторам необходимо пройти
          тестирование
        </Typography.Text>
      </header>
      {isTestsFetching && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      {testingData && !isTestsFetching && (
        <section className={styles.testsListContainer}>
          {testingData.testStatusItems?.map((singleTest) => (
            <div
              key={singleTest.testId}
              className={styles.testListItem}
              onClick={() => testItemClickHandle(singleTest)}
            >
              <div
                className={styles.testIcon}
                style={{ backgroundImage: `url(${tests[singleTest.testId]})` }}
              />
              <div className={styles.testDescription}>
                <Typography.Text view="secondary-large" weight="bold">
                  {singleTest.testName}
                </Typography.Text>
              </div>
              <div className={styles.checkmarkIcon}>
                {singleTest.completed && (
                  <CheckmarkCircleMIcon
                    width={14}
                    height={14}
                    color="var(--color-light-graphic-secondary)"
                  />
                )}
              </div>
              <Typography.Text
                className={styles.testStatus}
                view="secondary-small"
                color={singleTest.completed ? 'positive' : 'secondary'}
              >
                {singleTest.completed ? 'Доступно' : 'Не пройден'}
              </Typography.Text>
            </div>
          ))}
        </section>
      )}
    </>
  );
}
