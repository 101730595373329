import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useState } from 'react';
import { v4 } from 'uuid';

import { TableColumnSetting, Widget } from '../types/layout';
import { TableColumnKey } from '../types/tableColumn';

export type Column = TableColumnSetting & { id: UniqueIdentifier };

export const makeUniqColumns = (columns: TableColumnSetting[]) => {
  const uniqKeys = new Set<string>();

  const uniqColumns = columns.filter((column) => {
    if (!uniqKeys.has(column.key)) {
      uniqKeys.add(column.key);

      return true;
    }

    return false;
  });

  return uniqColumns;
};

export const flattenColumns = (
  columns: TableColumnSetting[],
  widgetType: Widget
) => {
  const result: TableColumnSetting[] = [];

  for (let i = 0; i < columns.length; i++) {
    if (columns[i].secondRow) {
      const { secondRow, ...firstRowOnly } = columns[i];

      result.push(firstRowOnly);
      result.push(columns[i].secondRow!!);
    } else {
      result.push(columns[i]);
    }
  }

  return makeUniqColumns(result);
};

export const makeDoubleColumns = (
  settings: TableColumnSetting[],
  widgetType: Widget
) => {
  const selectedColumns = settings.filter((column) => {
    if (widgetType === Widget.BALANCE) {
      return column.selected && column.key !== TableColumnKey.NameBalanceGroup;
    } else {
      return column.selected && column.key !== TableColumnKey.ShortDescription;
    }
  });

  //Запоминает смещение при добавление фиксированных колонок
  let offsetIndex = 0;

  const doubleColumns = selectedColumns.reduce((acc, column, index) => {
    let secondRow: TableColumnSetting | undefined;

    //Для таблицы Балансов фиксированная вторая строка Рынок для колонки Счёт
    if (
      column.key === TableColumnKey.IdAccount &&
      widgetType === Widget.BALANCE
    ) {
      secondRow = settings.find(
        (column) => column.key === TableColumnKey.NameBalanceGroup
      );
      //Для таблиц с Тикером или Наименованием фиксированная вторая строка Описание
    } else if (
      column.key === TableColumnKey.NameObject ||
      column.key === TableColumnKey.SymbolObject
    ) {
      secondRow = settings.find(
        (column) => column.key === TableColumnKey.ShortDescription
      );
    } else if ((index + offsetIndex) % 2 === 0) {
      secondRow = selectedColumns[index + 1];
    } else {
      return acc;
    }

    if (
      secondRow?.selected ||
      (secondRow?.key === TableColumnKey.NameBalanceGroup &&
        widgetType === Widget.BALANCE) ||
      secondRow?.key === TableColumnKey.ShortDescription
    ) {
      //Всем колонкам нужны айдишники для либы днд
      acc.push({
        ...column,
        secondRow: { ...secondRow, selected: true },
        id: v4(),
      });

      if (
        (secondRow?.key === TableColumnKey.NameBalanceGroup &&
          widgetType === Widget.BALANCE) ||
        secondRow?.key === TableColumnKey.ShortDescription
      ) {
        offsetIndex++;
      }
    } else {
      //Если вторая строка не выбрана в плоском списке то skip
      acc.push({ ...column, id: v4() });
    }

    return acc;
  }, [] as Column[]);

  let result = cloneDeep(doubleColumns);

  if (widgetType === Widget.BALANCE) {
    //В балансах Счет всегда первый
    const accountColumnIndex = doubleColumns.findIndex(
      (column) => column.key === TableColumnKey.IdAccount
    );

    if (accountColumnIndex !== -1 && accountColumnIndex !== 0) {
      result = arrayMove(doubleColumns, accountColumnIndex, 0);
    }
  } else {
    //В остальных таблицах Тикер/Наименование всегда первое
    const tickerColumnIndex = doubleColumns.findIndex(
      (column) =>
        column.key === TableColumnKey.SymbolObject ||
        column.key === TableColumnKey.NameObject
    );

    if (tickerColumnIndex !== -1 && tickerColumnIndex !== 0) {
      result = arrayMove(doubleColumns, tickerColumnIndex, 0);
    }
  }

  return result;
};

export const useDoubleRowMode = (
  widgetType: Widget,
  columnsSetting: TableColumnSetting[],
  defaultColumnSetting: TableColumnSetting[],
  updateNode: <T>(config: T) => void
) => {
  const [isDoubleRowMode, setIsDoubleRowMode] = useState(
    columnsSetting.some((setting) => setting.secondRow)
  );

  const switchDoubleRowMode = useCallback(() => {
    const saveColumnSetting = (columns: TableColumnSetting[]) => {
      updateNode({ tableProps: { columns } });
    };

    if (isDoubleRowMode) {
      const flatten = flattenColumns(columnsSetting, widgetType);

      // Возвращаем обратно все дефолтные столбцы в неактивном состоянии
      defaultColumnSetting.forEach((column) => {
        if (flatten.find((flattenCol) => flattenCol.key === column.key)) {
          return;
        } else {
          flatten.push({
            ...column,
            selected: false,
          });
        }
      });
      saveColumnSetting(flatten);
    } else {
      saveColumnSetting(makeDoubleColumns(columnsSetting, widgetType));
    }

    setIsDoubleRowMode(!isDoubleRowMode);
  }, [
    updateNode,
    isDoubleRowMode,
    columnsSetting,
    widgetType,
    defaultColumnSetting,
  ]);

  return [isDoubleRowMode, switchDoubleRowMode] as [boolean, () => void];
};
