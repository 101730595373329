import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { ObjectTypeCategory } from '@terminal/core/types/objectTypes';

export function useBaseIsin(
  idFI: number,
  objectTypeCategory?: ObjectTypeCategory
): string | undefined {
  const { finInstrumentsTable: finInstruments, objectsTable } =
    useAlfaDirectContext();
  const fiObject = finInstruments.get('idFI', idFI);
  const fullObject = fiObject?.idObject
    ? objectsTable.get('idObject', fiObject?.idObject)
    : null;

  const baseObject = fullObject?.idObjectBase
    ? objectsTable.get('idObject', fullObject?.idObjectBase)
    : null;
  const isFuture = objectTypeCategory === ObjectTypeCategory.Future;
  const baseIsin = isFuture ? baseObject?.isin : fullObject?.isin;

  return baseIsin;
}
