import { ObjectGroup } from '../client/entities';

import { ObjectTypeItem } from '../../types/core';
import { SubAccountPositionItem } from '../../types/subAccountPosition';

/**
 * @returns дневной объем на покупку
 * @param positionItem элемент в портфеле
 * */
export function getDailyBuyVolume(
  position: SubAccountPositionItem,
  objectType: ObjectTypeItem,
  TorgPos: number,
  BackPos: number,
  PrevPrice: number,
  Price: number | null,
  nominal: number,
  NominalCurPrice: number,
  MarketCurPrice: number
): number | null {
  if (position?.sessionBuyVal !== 0) {
    return position?.sessionBuyVal;
  } else {
    if (Price === null) {
      return null;
    }

    switch (objectType?.idObjectGroup) {
      case ObjectGroup.Futures:
      case ObjectGroup.Currency:
      case ObjectGroup.Metals: {
        return TorgPos > BackPos
          ? ((TorgPos - BackPos) * (PrevPrice + Price)) / 2
          : 0;
      }
      case ObjectGroup.Bonds: {
        return TorgPos > BackPos
          ? ((TorgPos - BackPos) *
              (PrevPrice + Price) *
              nominal *
              NominalCurPrice) /
              200
          : 0;
      }
      case ObjectGroup.Stocks:
      case ObjectGroup.EquityParticipations:
      case ObjectGroup.Receipts: {
        return TorgPos > BackPos
          ? ((TorgPos - BackPos) * (PrevPrice + Price) * MarketCurPrice) / 2
          : 0;
      }

      default: {
        return 0;
      }
    }
  }
}
