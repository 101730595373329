import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { useAppContext } from '@terminal/core/hooks/useAppContext';

import { setOnboardigLocalStorage } from '../../entities/OnboardingConfig';
import { useThemeContext } from '../../shared/lib/themeContext';

import styles from './Onboarding.module.css';

interface OnboardingWelcomeProps {
  nextStep: () => void;
  lastStep: () => void;
}

export const OnboardingWelcome = ({
  nextStep,
  lastStep,
}: OnboardingWelcomeProps) => {
  const { theme } = useThemeContext();
  const { onboarding, terminalName } = useAppContext();
  const welcome = onboarding?.images?.[theme]?.welcome ?? '';
  const handleClick = (action: () => void) => {
    setOnboardigLocalStorage('passed', true);
    action();
  };

  return (
    <div className={styles.welcomeContainer}>
      <section
        className={styles.welcomeTop}
        style={{ backgroundImage: `url(${welcome})` }}
      />
      <section className={styles.welcomeBottom}>
        <Space fullWidth>
          <Typography.Text view="primary-large" weight="bold">
            Добро пожаловать в терминал {terminalName}!
          </Typography.Text>
          <Typography.Text view="primary-medium">
            Кратко расскажем вам о том, как устроен терминал
          </Typography.Text>
          <ButtonDesktop
            size="m"
            view="accent"
            block
            onClick={() => handleClick(nextStep)}
          >
            Начать
          </ButtonDesktop>
          <ButtonDesktop
            size="m"
            view="ghost"
            block
            onClick={() => handleClick(lastStep)}
          >
            Пропустить
          </ButtonDesktop>
        </Space>
      </section>
    </div>
  );
};
