import { differenceInMinutes, isBefore } from 'date-fns';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CurrencyCodes } from '@alfalab/core-components/amount';
import { usePrevious } from '@alfalab/core-components/select/utils';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import {
  ActivationOptionShape,
  activationOptionsMap,
  ActivationTime,
  lifeTimeOptions,
  LifeTimeOptionShape,
} from '../../constants/orderBook';
import { LifeTime, ObjectGroup } from '../../lib/client/entities';
import { getInstrumentId } from '../../lib/domain/getInstrumentId';
import { getStepDecimals } from '../../lib/format';
import {
  getEditOrderQuantity,
  getOrderDisplayPrice,
  getPrice,
} from '../../lib/formulas';
import { useFullFI } from './useFullFI';

import { AccountItem } from '../../types/account';
import { ExtendedFI } from '../../types/extendedFI';
import { OrderItem } from '../../types/order';
import { FinInfoExt } from '../../types/quotes';

interface EditOrder {
  idFiProp?: number;
  order?: OrderItem | null;
  isLotCount?: boolean;
  selectedAccount?: AccountItem;
  slPrice?: number;
  tpPrice?: number;
}

interface HookReturnValue {
  currencyCode: CurrencyCodes;
  priceDecimals: number;
  symbolObject: string | null;
  idFi: number;
  price: number;
  slippage: number;
  getQuantity: () => number;
  decimalsNumber: number;
  finInfoExt: FinInfoExt | undefined;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  setSlippage: React.Dispatch<React.SetStateAction<number>>;
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  openQuantity: number;
  setOpenQuantity: React.Dispatch<React.SetStateAction<number>>;
  lifeTime: LifeTimeOptionShape;
  setLifeTime: React.Dispatch<React.SetStateAction<LifeTimeOptionShape>>;
  activation: ActivationOptionShape;
  setActivation: React.Dispatch<React.SetStateAction<ActivationOptionShape>>;
  isConfirmDisabled: boolean;
  takeLastPrice: () => number | null;
  fee: number | undefined;
  secondPrice: number;
  setSecondPrice: React.Dispatch<React.SetStateAction<number>>;
  nominal: number | null;
  accruedInt: number | undefined;
  stopPrice: number;
  setStopPrice: React.Dispatch<React.SetStateAction<number>>;
  limitPrice: number;
  setLimitPrice: React.Dispatch<React.SetStateAction<number>>;
  isBond: boolean;
  fullFi: ExtendedFI | undefined;
}

type EditOrderHook = (data: EditOrder) => HookReturnValue;

/**
 * Взвращает набор данных и методы для редактирования заявки
 * @param idFiProp - id инструмента
 * @param order - объект заявки
 * @param isLotCount - подсчет в лотах/штуках
 * @param selectedAccount - выбранный аккаунт
 */
export const useEditOrder: EditOrderHook = ({
  idFiProp,
  order,
  isLotCount,
  selectedAccount,
  slPrice,
  tpPrice,
}) => {
  const {
    objectsTable,
    finInstrumentsTable,
    useQuotes,
    useFinInfoExt,
    useBrokerageFee,
  } = useAlfaDirectContext();

  const idFi = useMemo(() => {
    if (!idFiProp) {
      if (order) {
        return getInstrumentId(
          order.idObject,
          order.idMarketBoard,
          finInstrumentsTable
        );
      } else {
        return 0;
      }
    } else {
      return idFiProp;
    }
  }, [finInstrumentsTable, idFiProp, order]);

  const finInfoExtRecord = useFinInfoExt(idFi);
  const finInfoExt = finInfoExtRecord[idFi];

  //Получаем код валюты
  const currencyCode = useMemo(() => {
    return finInfoExt?.idObjectCurrency
      ? (objectsTable.get('idObject', finInfoExt.idObjectCurrency)
          ?.symbolObject as CurrencyCodes)
      : 'ALL';
  }, [finInfoExt?.idObjectCurrency, objectsTable]);

  const fullFi = useFullFI(idFi);
  const [price, setPrice] = useState(0);
  const [stopPrice, setStopPrice] = useState(0);
  const [limitPrice, setLimitPrice] = useState(0);
  const [secondPrice, setSecondPrice] = useState(0);
  const [slippage, setSlippage] = useState<number>(0);
  const [quantity, setQuantity] = useState(
    order ? getEditOrderQuantity(order) : 0
  );
  const [openQuantity, setOpenQuantity] = useState(order?.openQuantity || 0);
  const [lifeTime, setLifeTime] = useState(
    () =>
      lifeTimeOptions.find(
        (opt) => opt.value === (order?.idLifeTime ?? LifeTime.D30)
      )!
  );
  const [activation, setActivation] = useState(
    activationOptionsMap[ActivationTime.NOW]
  );

  useEffect(() => {
    let activationValue = activationOptionsMap[ActivationTime.NOW];

    if (order?.activationTime) {
      const difference = differenceInMinutes(
        order?.activationTime,
        order?.acceptTime
      );

      if (difference === 59) {
        activationValue = activationOptionsMap[ActivationTime.HOUR];
      }

      if (difference === 1439) {
        activationValue = activationOptionsMap[ActivationTime.DAY];
      }

      if (difference === 10079) {
        activationValue = activationOptionsMap[ActivationTime.WEEK];
      }
    }

    setActivation(activationValue);
  }, [order?.activationTime, order?.acceptTime, setActivation]);

  const prevOrder = usePrevious(order);

  useEffect(() => {
    if (order && !isEqual(prevOrder, order)) {
      setPrice(getOrderDisplayPrice(order) || 0);
      setStopPrice(order.stopPrice || 0);
      setLimitPrice(order.limitPrice || 0);
      setSecondPrice(order.stopPrice || 0);
      setSlippage(Math.abs(order.limitLevelAlternative - order.price));
      const lifeTime = lifeTimeOptions.find(
        (opt) => opt.value === order.idLifeTime
      )!;

      setLifeTime(lifeTime);
    }
  }, [prevOrder, order]);

  useEffect(() => {
    //Для правильного отображения объема заявки мы должны учитывать настройку размерности из торгового виджета
    if (order) {
      const orderQuantity = getEditOrderQuantity(order);
      const lotSize = (isLotCount && finInfoExt?.lot) || 1;

      setQuantity(orderQuantity / lotSize);
      setOpenQuantity(order.openQuantity / lotSize);
    }
  }, [finInfoExt?.lot, isLotCount, order]);

  const { decimalsNumber, priceDecimals } = getStepDecimals(
    finInfoExt?.priceStep
  );

  const sameActivation = useMemo(() => {
    switch (activation.value) {
      case ActivationTime.NOW:
        return (
          !order?.activationTime ||
          isBefore(order?.activationTime, order?.acceptTime)
        );
      case ActivationTime.HOUR:
        return order?.activationTime
          ? differenceInMinutes(order?.activationTime, order?.acceptTime) === 59
          : false;
      case ActivationTime.DAY:
        return order?.activationTime
          ? differenceInMinutes(order?.activationTime, order?.acceptTime) ===
              1439
          : false;
      case ActivationTime.WEEK:
        return order?.activationTime
          ? differenceInMinutes(order?.activationTime, order?.acceptTime) ===
              10079
          : false;
    }

    return true;
  }, [activation.value, order?.activationTime, order?.acceptTime]);

  const getQuantity = useCallback(
    () => (isLotCount ? (finInfoExt?.lot || 0) * quantity : quantity),
    [finInfoExt?.lot, isLotCount, quantity]
  );
  const getOpenQuantity = useCallback(
    () => (isLotCount ? (finInfoExt?.lot || 0) * openQuantity : openQuantity),
    [finInfoExt?.lot, isLotCount, openQuantity]
  );
  //Обновление цены
  const quotes = useQuotes(idFi);

  const takeLastPrice = useCallback(() => {
    const last = quotes[idFi]?.last;

    if (finInfoExt) {
      const { prevLastDate, prevFairPriceDate, fairPrice, prevLast } =
        finInfoExt;

      const isLastAvaliable =
        prevLastDate &&
        prevFairPriceDate &&
        !isUndefined(fairPrice) &&
        !isUndefined(fullFi?.idObjectGroup);

      if (isLastAvaliable) {
        return getPrice(
          prevLastDate,
          prevFairPriceDate,
          fairPrice,
          last,
          prevLast,
          fullFi!.idObject,
          fullFi!.idObjectGroup
        );
      }
    }

    return last ?? null;
  }, [finInfoExt, fullFi, idFi, quotes]);

  //Состояние доступности кнопки отправки новой заявки
  const isConfirmDisabled = useMemo(
    //TODO: Добавить activationTime
    () =>
      order?.price === price &&
      order?.stopPrice === secondPrice &&
      order?.stopPrice === stopPrice &&
      order?.limitPrice === limitPrice &&
      Math.abs(order?.limitLevelAlternative - order?.price || 0) === slippage &&
      order?.quantity === getQuantity() &&
      order?.openQuantity === getOpenQuantity() &&
      sameActivation &&
      order?.idLifeTime === lifeTime.value &&
      !slPrice &&
      !tpPrice,
    [
      order?.price,
      order?.stopPrice,
      order?.limitPrice,
      order?.quantity,
      order?.openQuantity,
      order?.idLifeTime,
      order?.limitLevelAlternative,
      price,
      secondPrice,
      stopPrice,
      limitPrice,
      slippage,
      getQuantity,
      getOpenQuantity,
      sameActivation,
      lifeTime.value,
      slPrice,
      tpPrice,
    ]
  );

  const symbolObject = useMemo(
    () =>
      order ? objectsTable.get('idObject', order.idObject)?.symbolObject : '',
    [objectsTable, order]
  );

  const nominal = order
    ? objectsTable.get('idObject', order.idObject)?.nominal
    : null;
  const accruedInt = finInfoExt?.accruedInt;

  const fee = useBrokerageFee(idFi, price, getQuantity(), selectedAccount);

  return {
    currencyCode,
    priceDecimals,
    symbolObject,
    idFi,
    price,
    slippage,
    getQuantity,
    decimalsNumber,
    finInfoExt,
    setPrice,
    setSlippage,
    quantity,
    setQuantity,
    openQuantity,
    setOpenQuantity,
    lifeTime,
    setLifeTime,
    activation,
    setActivation,
    isConfirmDisabled,
    takeLastPrice,
    fee,
    secondPrice,
    setSecondPrice,
    nominal,
    accruedInt,
    stopPrice,
    setStopPrice,
    limitPrice,
    setLimitPrice,
    isBond: fullFi?.idObjectGroup === ObjectGroup.Bonds,
    fullFi,
  };
};
