import qs from 'query-string';

export function isYoutube(src: string) {
  return src.indexOf('youtube.com') >= 0;
}

export function proccessYoutubeSrc(src: string) {
  const [, search] = src.split('?');
  const parsed = qs.parse(search);

  const videoId = parsed['v'];

  if (videoId === undefined) {
    // Id видео не найдено, возвращаем как есть
    return src;
  }

  return `https://www.youtube.com/embed/${videoId}`;
}

export function isRutube(src: string) {
  return src.indexOf('rutube.ru') >= 0;
}

export function proccessRutubeSrc(src: string) {
  const clear = src.replace(/^https?:\/\//, '');
  const [, seg1, videoId] = clear.split('/');

  if (seg1 === 'video' && videoId) {
    return `https://rutube.ru/play/embed/${videoId}`;
  }

  // Не нашли нужного, возвращаем как есть
  return src;
}
