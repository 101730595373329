import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { addContestLotteryChances } from '../api/addContestLotteryChances';

export function useAddContestLotteryChances() {
  const addNotification = useNotification();

  return useMutation(addContestLotteryChances, {
    onSuccess: () => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'positive',
        title: 'Вероятность выигрыша изменена',
      });
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка изменения вероятности выигрыша',
        text: error?.message || '',
      });
    },
  });
}
