import isEqual from 'lodash/isEqual';
import { useCallback, useMemo, useState } from 'react';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Switch } from '@alfalab/core-components/switch';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { ContextMenuProps } from '@terminal/core/types/layout';

import { useWidgetContext } from '../../../../../../shared';

import styles from './ContextMenuSettings.module.css';

interface Props extends BaseModalProps {
  nodeId?: string;
  onClose: () => void;
  contextMenuProps?: ContextMenuProps;
}

export const ContextMenuSettings = ({
  nodeId,
  onClose,
  contextMenuProps,
  ...baseModalProps
}: Props) => {
  const [showBestBuy, setShowBestBuy] = useState(
    contextMenuProps?.showBestBuy ?? true
  );
  const [showMarketBuy, setShowMarketBuy] = useState(
    contextMenuProps?.showMarketBuy ?? true
  );
  const { updateNode } = useWidgetContext();

  const newContextMenuProps: ContextMenuProps = useMemo(
    () => ({
      showBestBuy,
      showMarketBuy,
    }),
    [showBestBuy, showMarketBuy]
  );

  const saveSettings = useCallback(() => {
    if (nodeId) {
      updateNode(nodeId, { contextMenuProps: newContextMenuProps });
    }

    onClose();
  }, [newContextMenuProps, nodeId, onClose, updateNode]);

  const isSaveDisabled = useMemo(() => {
    return isEqual(newContextMenuProps, contextMenuProps);
  }, [newContextMenuProps, contextMenuProps]);

  return (
    <BaseModal
      {...baseModalProps}
      className={styles.contextMenuSettings}
      onClose={onClose}
    >
      <div className={styles.settingsHeader}>
        <Typography.Text view="secondary-large" weight="bold">
          Контекстное меню
        </Typography.Text>
        <IconButton
          size="xs"
          view="secondary"
          icon={CrossMIcon}
          onClick={onClose}
        />
      </div>
      <div className={styles.settingsContent}>
        <Switch
          className={styles.customSwitch}
          checked={showBestBuy}
          reversed
          label={
            <Typography.Text view="secondary-large" weight="medium">
              Покупка и продажа по лучшим ценам
            </Typography.Text>
          }
          hint={
            <Typography.Text view="secondary-small">
              Включает и выключает отображение торговых кнопок по Best Bid и
              Best Ask
            </Typography.Text>
          }
          onChange={() => setShowBestBuy((prevState) => !prevState)}
          block
        />
        <Switch
          className={styles.customSwitch}
          checked={showMarketBuy}
          reversed
          label={
            <Typography.Text view="secondary-large" weight="medium">
              Покупка и продажа по рыночным ценам
            </Typography.Text>
          }
          hint={
            <Typography.Text view="secondary-small">
              Включает и выключает отображение торговых кнопок по рынку
            </Typography.Text>
          }
          onChange={() => setShowMarketBuy((prevState) => !prevState)}
          block
        />
        <div className={styles.buttonToolbar}>
          <Button
            className={styles.customButton}
            view="ghost"
            size="xxs"
            onClick={() => onClose()}
          >
            Отмена
          </Button>
          <Button
            className={styles.customButton}
            size="xxs"
            view="secondary"
            disabled={isSaveDisabled}
            onClick={() => saveSettings()}
          >
            Применить
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
