import { fetchLkResult } from '@terminal/core/lib/rest/lkApi';

import { AddAccountAdvisorPreregister } from './types/AddAccountAdvisorPreregister';
import { SubscribeAdvisorType } from './types/SubscribeAdvisorType';

export async function subscribeAdvisor(
  data: SubscribeAdvisorType
): Promise<AddAccountAdvisorPreregister> {
  const res = await fetchLkResult<AddAccountAdvisorPreregister>(
    `/api/subscription/subscribe-adviser`,
    'POST',
    data
  );

  return res;
}
