import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { ArrowLeftMIcon } from '@alfalab/icons-glyph/ArrowLeftMIcon';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { Spinner } from '@terminal/common/components/Spinner';
import { TradingStub } from '@terminal/common/components/TradingStub';
import { NewsItem as NewsItemType } from '@terminal/common/entities/News';
import { Widget } from '@terminal/core/types/layout';

import { FinInstrAutocomplete } from '../../../../features/FinInstrAutocomplete';
import { WidgetHeader } from '../../../../features/WidgetHeader';
import { useWidgetContext, WidgetProps } from '../../../../shared';
import { getIsinByIdFi } from '../../lib/getIsinByIdFi';
import { NewsItem } from '../NewsItem/NewsItem';
import { NewsPage } from '../NewsPage/NewsPage';

import styles from './News.module.css';

export function News({ idFi, nodeId }: WidgetProps) {
  const bodyRef = useRef<HTMLDivElement>(null);
  const { useNews, onWidgetLoad } = useWidgetContext();
  const { finInstrumentsTable, objectsTable } = useAlfaDirectContext();

  const isin = getIsinByIdFi(idFi as number, finInstrumentsTable, objectsTable);

  const {
    isLoading,
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useNews({
    isins: isin ? [isin] : [],
    count: 20,
    refetchInterval: 30000,
  });

  const [loaderRef, loaderInView] = useInView();

  useEffect(() => {
    if (loaderInView) {
      fetchNextPage();
    }
  }, [loaderInView, fetchNextPage]);

  const [currentNews, setCurrentNews] = useState<NewsItemType | undefined>(
    undefined
  );

  const onClickNewsPlate = useCallback((news?: NewsItemType) => {
    bodyRef.current?.scrollTo(0, 0);
    setCurrentNews(news);
  }, []);

  const toCloseActiveNews = useCallback(() => {
    setCurrentNews(undefined);
  }, [setCurrentNews]);

  const items = useMemo(() => {
    return (data?.pages || []).flatMap((page) => page.news) as NewsItemType[];
  }, [data]);

  const WidgetHeaderInputs = useMemo(
    () => (
      <FinInstrAutocomplete idFi={idFi} nodeId={nodeId} widget={Widget.NEWS} />
    ),
    [idFi, nodeId]
  );

  useEffect(() => {
    if (nodeId) {
      onWidgetLoad(nodeId);
    }
  }, [nodeId, onWidgetLoad]);

  if (currentNews) {
    return (
      <div className={styles.container}>
        <div className={styles.newsPageHeader} onClick={toCloseActiveNews}>
          <Button
            size="xxs"
            view="ghost"
            leftAddons={
              <ArrowLeftMIcon width={16} height={16} color="#85858D" />
            }
          >
            <div className={styles.newsPageButtonText}>К списку новостей</div>
          </Button>
        </div>

        <div className={styles.body} ref={bodyRef}>
          <NewsPage
            currentNews={currentNews}
            allNews={items}
            setCurrentNews={onClickNewsPlate}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <WidgetHeader inputs={WidgetHeaderInputs} />

      <div className={styles.body} ref={bodyRef}>
        {!isLoading && items.length === 0 ? (
          <TradingStub
            size="s"
            icon={<DocumentLinesMIcon color="#8D8D93" />}
            title="Новостей не найдено"
            description={
              <div>
                Попробуйте изменить <br />
                параметры поиска
              </div>
            }
          />
        ) : null}
        {items.map((item) => (
          <NewsItem key={item.newsId} {...item} onClick={onClickNewsPlate} />
        ))}

        {(isFetching || hasNextPage) && (
          <div
            ref={!(isFetching || isFetchingNextPage) ? loaderRef : null}
            className={styles.spinner}
          >
            <Spinner size="36" color="var(--text-color-secondary)" />
          </div>
        )}
      </div>
    </div>
  );
}
