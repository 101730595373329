import cn from 'classnames';
import { isBefore } from 'date-fns';
import { ReactNode, useState } from 'react';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { AddMIcon } from '@alfalab/icons-glyph/AddMIcon';
import { ShieldMIcon } from '@alfalab/icons-glyph/ShieldMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { TradingStub } from '@terminal/common/components/TradingStub';
import { MAX_CERTIFICATES_COUNT } from '@terminal/core/constants/certificates';
import { CertificateStateType } from '@terminal/core/lib/client/entities';
import { CertificateEnrollStep } from '@terminal/core/types/certificate';

import { CertificateEnrollModal } from '../CertificateEnroll';

import styles from './TradingCertificateLock.module.css';

interface Props {
  children?: ReactNode;
  //Когда нужно просто скрыть элемент и ничего не показывать
  hideMode?: boolean;
  size?: 's' | 'xs';
}

// HOC который не рендерит чилдренов и вместо них выводит
// кнопку выпустить сертификать, если у пользователя нет валидного сертификата
// <TradingCertificateLock>
//    <MyAwesomeTradeForm /> <--- не отрендерится если нет серта
// </TradingCertificateLock>
// На кмопнент есть интеграционный тест /src/mobile/components/__tests__/CertificateProvider.tsx
export const TradingCertificateLock = ({
  children,
  hideMode,
  size = 's',
}: Props) => {
  const [certificateEnrollModalVisible, setCertificateEnrollModalVisible] =
    useState<boolean>(false);
  const isSizeS = size === 's';
  const isSizeXs = size === 'xs';

  const { useCertificates, useCurrentCertificate } = useAlfaDirectContext();
  const certificates = useCertificates();
  const { workingCertificate, certificateEnrollStep } = useCurrentCertificate();
  const isExpired = isBefore(
    workingCertificate?.parsedPayload?.endDate || new Date(),
    new Date()
  );
  const isAllowIssueCertificate = certificates.length < MAX_CERTIFICATES_COUNT;

  const tradingEnabled =
    workingCertificate &&
    workingCertificate.state === CertificateStateType.Ready &&
    !isExpired;

  if (tradingEnabled) {
    return <>{children}</>;
  }

  if (hideMode) {
    return null;
  }

  let title: ReactNode = (
    <>
      Необходим сертификат
      <br />
      электронной подписи
    </>
  );

  if (isExpired) {
    title = (
      <>
        Закончился срок действия
        <br />
        сертификата электронной подписи
      </>
    );
  }

  let description: string =
    'Без него не получится торговать на бирже и работать с личным кабинетом. Выпускается за минуту, действует год.';

  if (isExpired) {
    description =
      'Срок действия вашего сертификата истек. Необходимо выпустить новый. Выпускается за минуту, действует год.';
  } else if (!isAllowIssueCertificate) {
    description =
      'Чтобы выпустить новый сертификат, отзовите ненужный, выпущенный для другого браузера или устройства';
  }

  let buttonText: string;

  if (isSizeXs) {
    buttonText = 'Выпустить';
  } else if (!isAllowIssueCertificate || isExpired) {
    buttonText = 'Отозвать и выпустить новый';
  } else {
    buttonText = 'Выпустить сертификат';
  }

  const isDisabled =
    certificateEnrollStep === CertificateEnrollStep.InProgress ||
    certificateEnrollStep === CertificateEnrollStep.Success;

  return (
    <>
      <TradingStub
        size={size}
        icon={<ShieldMIcon />}
        title={title}
        description={description}
        actions={
          <>
            <div
              className={cn(styles.buttonWrapper, {
                [styles.buttonWrapper_size_s]: isSizeS,
                [styles.buttonWrapper_size_xs]: isSizeXs,
              })}
            >
              <Button
                onClick={() => setCertificateEnrollModalVisible(true)}
                disabled={isDisabled}
                loading={isDisabled}
                size="xs"
                block
                nowrap
                leftAddons={
                  isAllowIssueCertificate && !isSizeXs && !isExpired ? (
                    <AddMIcon
                      width={16}
                      height={16}
                      color="var(--color-light-text-secondary)"
                    />
                  ) : undefined
                }
              >
                {buttonText}
              </Button>
            </div>
          </>
        }
      />
      <CertificateEnrollModal
        isOpen={certificateEnrollModalVisible}
        onClose={() => setCertificateEnrollModalVisible(false)}
      />
    </>
  );
};
