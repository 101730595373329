import { useCallback, useEffect } from 'react';

import { LIMIT_SERVICE_UNAVAILABLE_KEY } from '../constants/balance';
import { initPerfomanceAnalytics } from '../lib/analytics/performance';
import { IDB } from '../lib/db';
import { initRsaKeysDB } from '../lib/db/rsaKeys';
import { Settings } from '../lib/db/settings';
import { WatchLists } from '../lib/db/watchLists';
import { WorkspaceConfigurations } from '../lib/db/workspaceConfiguration';

import { shallow, useStore } from '../store';
import { initWorkspaceSubscribes, unsubscribeAll } from '../store/subscribes';

import { AppState } from '../types/app';

const setLimitServiceAvailable = () =>
  sessionStorage.removeItem(LIMIT_SERVICE_UNAVAILABLE_KEY);

initRsaKeysDB();

IDB.init();

export const useInitApp = () => {
  const [
    initWorkspaceConfigurations,
    initWatchLists,
    initSettings,
    objectsReady,
    credentials,
    appState,
  ] = useStore(
    (state) => [
      state.workspaceConfigurationsInit,
      state.initWatchLists,
      state.initSettings,
      state.objectsReady,
      state.credentials,
      state.appState,
    ],
    shallow
  );
  const init = useCallback(async () => {
    if (credentials && appState === AppState.READY) {
      setLimitServiceAvailable();

      WorkspaceConfigurations.init().then((workspaceConfigurations) => {
        initWorkspaceConfigurations(workspaceConfigurations);
      });

      Settings.init().then((settings) => {
        initSettings(settings);
      });
    }
  }, [credentials, appState, initWorkspaceConfigurations, initSettings]);

  const initLists = useCallback(async () => {
    if (objectsReady && credentials && appState === AppState.READY) {
      WatchLists.init().then((watchLists) => {
        initWatchLists(watchLists);
      });
    }
  }, [appState, credentials, initWatchLists, objectsReady]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    initLists();
  }, [initLists]);

  useEffect(() => {
    initWorkspaceSubscribes();
    initPerfomanceAnalytics(10000);

    return () => {
      unsubscribeAll();
    };
  }, []);
};
