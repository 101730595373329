import { useContext } from 'react';

import { Context } from '../context';

/**
 * @returns контекст параметров окна чата
 * */
export const useChatContext = () => {
  return useContext(Context);
};
