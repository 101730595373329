import React, { FC, useCallback, useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { useCountdown } from '@alfalab/core-components/confirmation/desktop';

import { Countdown } from '../Countdown';

import { useCertificateEnrollStore } from '../../model/store';

import styles from './ResendCodeButton.module.css';

interface Props {
  disabled: boolean;
  onClick: () => void;
}

const MAX_TIMER = 30000;

export const ResendCodeButton: FC<Props> = ({ disabled, onClick }) => {
  const [operation, assignment, countStart, setCountStart] =
    useCertificateEnrollStore((store) => [
      store.operation,
      store.assignment,
      store.countStart,
      store.setCountStart,
    ]);

  const [initial, setInitial] = useState<number>(() => {
    if (!operation) {
      return MAX_TIMER;
    }

    if (countStart) {
      return Math.max(0, MAX_TIMER - (Date.now() - countStart));
    }

    return MAX_TIMER;
  });

  const [countdown, startTimer] = useCountdown(initial, 500);

  const handleButtonClick = useCallback(() => {
    startTimer();
    onClick();
    setInitial(MAX_TIMER);
    setCountStart(Date.now());
  }, [startTimer, setCountStart, onClick]);

  useMount(() => {
    if (!assignment) {
      setCountStart(Date.now());
    }
  });

  useEffect(() => {
    startTimer();
  }, [startTimer]);

  return (
    <div className={styles.root}>
      {countdown === 0 && (
        <ButtonDesktop
          disabled={disabled}
          view="secondary"
          size="xs"
          onClick={handleButtonClick}
        >
          Отправить ещё раз
        </ButtonDesktop>
      )}
      {countdown > 0 && <Countdown countdown={countdown} />}
    </div>
  );
};
