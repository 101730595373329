import cn from 'classnames';
import { FC } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { SubAccount } from '@terminal/core/lib/rest/lkAccountInfo';

import { SubaccountTableItem } from './SubaccountTableItem';

import styles from './SubaccountTable.module.css';

export interface SubaccountTableProps {
  items: SubAccount[];
  showPortfolioNumber?: boolean;
  onReadct?: (acc: SubAccount) => void;
}

export const SubaccountTable: FC<SubaccountTableProps> = ({
  items,
  showPortfolioNumber = false,
  onReadct,
}) => (
  <table className={styles.table}>
    <thead className={styles.header}>
      <tr className={styles.row}>
        <th className={styles.cell}>
          <Typography.Text view="secondary-small">
            Номер / Наименование
          </Typography.Text>
        </th>
        <th className={styles.cell}>
          <Typography.Text view="secondary-small">
            FORTS / Лимит
          </Typography.Text>
        </th>
        <th className={cn(styles.cell, styles.actionCell)}>
          <Typography.Text view="secondary-small">Действия</Typography.Text>
        </th>
      </tr>
    </thead>
    <tbody className={styles.body}>
      {items.map((subaccount) => (
        <SubaccountTableItem
          key={subaccount.accCode}
          item={subaccount}
          showPortfolioNumber={showPortfolioNumber}
          onRedact={onReadct ? () => onReadct(subaccount) : undefined}
        />
      ))}
    </tbody>
  </table>
);
