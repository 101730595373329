import qs from 'query-string';
import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { fetchLkFile } from '@terminal/core/lib/rest/lkApi';
import { NotificationType } from '@terminal/core/types/ui';

import { LK_API } from '../../../legacy/const/externalUrls';

interface SecuritiesHistoryDetailPdfParams {
  trnNo: number;
  treaty: number;
}

export const useSecuritiesHistoryDetailPdf = () => {
  const addNotification = useNotification();

  return useMutation(
    async (data: SecuritiesHistoryDetailPdfParams) => {
      const request = qs.stringify(data);

      return await fetchLkFile(
        `${LK_API.SECURITIES_HISTORY_DETAIL_BASE}?${request}`
      );
    },
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса детализации',
          text: error?.message || '',
        });
      },
    }
  );
};
