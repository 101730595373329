import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { IS_MOBILE_PWA } from '@terminal/core/env';
import { useLoginWithPassport } from '@terminal/lk-core';

import { formatLoginError, getPassportUrl } from '../../entities/Login';

import styles from './LoginWithPassport.module.css';

function LoginWithPassport({
  redirect,
  inProgress,
}: {
  redirect: string;
  inProgress?: boolean;
}) {
  const { isLoading, error, authPerformanceLogger } = useLoginWithPassport(
    redirect,
    IS_MOBILE_PWA,
    getPassportUrl(redirect),
    inProgress
  );

  return (
    <div className={styles.container}>
      <ButtonDesktop
        view="accent"
        type="submit"
        size="m"
        loading={isLoading}
        href={!isLoading ? getPassportUrl(redirect) : undefined}
        disabled={isLoading}
        onClick={authPerformanceLogger.loginClicked}
      >
        Войти в терминал
      </ButtonDesktop>
      {error && (
        <Typography.Text view="primary-small" color="negative">
          {formatLoginError(error)}
        </Typography.Text>
      )}
    </div>
  );
}

export { LoginWithPassport };
