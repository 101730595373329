import get from 'lodash/get';

import { PositionItem } from '@terminal/core/types/position';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

export const onPositionTableOrder = (item: PositionItem, key = '') => {
  let positionData: number | string | Date | undefined = get(item, key);

  if (key === TableColumnKey.NextCoupon) {
    positionData = item.finInfoExt?.nextCoupon;
  }

  if (key === TableColumnKey.MatDate) {
    positionData = item.object?.matDateObject;
  }

  return positionData;
};
