export enum ContestTaskTypes {
  // оборот
  TURNOVER = 'TURNOVER',
  TURNOVER_FOND = 'TURNOVER_FOND',
  // сделки
  TRADES = 'TRADES',
  // шансы
  CHANCES = 'CHANCES',
  // доходность
  PROFIT_PERCENT = 'PROFIT_PERCENT',
  // пополнения
  TRANSFERS = 'TRANSFERS',
  // авторизации
  AUTHORIZATION = 'AUTHORIZATION',
}

export enum ContestTaskStatus {
  PROGRESS = 'PROGRESS',
  COMPLETE = 'COMPLETE',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  NEW = 'NEW',
}

export type ContestParticipantTask = {
  taskId: number;
  type: string;
  fulfillmentConditionTypeName: string;
  fulfillmentConditionType: ContestTaskTypes;
  frontName: string;
  iconUrl: string;
  rewardValue: number;
  conditionValue: number;
  completeTaskCount: number;
  rewardType: 'NUMBER_OF_CHANSES' | 'COEFFICIENT';
  status: ContestTaskStatus | null;
  maxNumberOfCompleted: null | number;
  numberOfParticipants: null | number;
  isActive: boolean;
  parentTaskId?: number;
  progress?: number;
  value?: string;
  description?: string;
};
