import cn from 'classnames';
import React from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { ChevronBackMIcon } from '@alfalab/icons-glyph/ChevronBackMIcon';

import styles from './FunctionalModalSidebar.module.css';

export interface FunctionalModalSidebarProps {
  backLabel?: string;
  open?: boolean;
  showHead?: boolean;
  headAddon?: React.ReactNode;
  zIndex?: number;
  children: React.ReactNode;
  onClose?: () => void;
}

export const FunctionalModalSidebar = ({
  backLabel = 'Назад',
  showHead = true,
  headAddon,
  open,
  zIndex,
  children,
  onClose,
}: FunctionalModalSidebarProps) => {
  const boxStyle = zIndex ? { zIndex } : undefined;

  return (
    <div
      className={cn(styles.sidebar, { [styles.sidebarOpen]: open })}
      style={boxStyle}
    >
      <div className={styles.inner}>
        {showHead && (
          <div className={styles.head}>
            <ButtonDesktop
              className={styles.back}
              view="ghost"
              onClick={onClose}
              leftAddons={
                <div className={styles.backCircle}>
                  <ChevronBackMIcon width={18} height={18} />
                </div>
              }
            >
              {backLabel}
            </ButtonDesktop>
            {headAddon && <div className={styles.headAddon}>{headAddon}</div>}
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
