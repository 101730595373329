import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Select } from '@alfalab/core-components/select';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';
import { ShieldMIcon } from '@alfalab/icons-glyph/ShieldMIcon';

import { LK_CERTIFICATES } from '@terminal/core/env';
import { useCertificatesAll } from '@terminal/core/hooks/useCertificates';
import { CertificateStateType } from '@terminal/core/lib/client/entities';

import { FormControlSummary } from '../../shared/ui/FormControlSummary';

import styles from './CertificatesAll.module.css';

export const CertificatesAll = () => {
  const {
    certificates,
    iconColor,
    isEnrollStepInProgress,
    certificatesCountRest,
    certificatesOptions,
    selectedOptionKey,
    selectedCertificateStateStr,
    onClickOptionSelect,
    onClickCertificateConfirm,
    onClickCertificateReject,
    selectedCertificate,
    startDate,
    endDate,
  } = useCertificatesAll();

  const selectedOption = certificatesOptions.find(
    (option) => option.key === selectedOptionKey
  );

  if (!certificates || certificates.length === 0) {
    return null;
  }

  return (
    <div>
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        color="primary"
        defaultMargins={false}
      >
        Другие сертификаты{' '}
        <Tooltip
          trigger="hover"
          position="right"
          content="Сертификаты, выпущенные для других устройств"
          targetClassName={styles.tooltipTarget}
        >
          <InformationCircleLineMIcon className={styles.tooltipIcon} />
        </Tooltip>
      </Typography.Text>
      <Gap size="m" />
      <div className={styles.container}>
        <SuperEllipse size={48} className={styles.icon}>
          <ShieldMIcon color={iconColor} />
        </SuperEllipse>
        <div>
          <Select
            label="Сертификат электронной подписи"
            labelView="outer"
            className={styles.select}
            options={certificatesOptions}
            selected={selectedOption}
            onChange={onClickOptionSelect}
            block
            hint={
              certificatesCountRest > 0
                ? `Вы можете создать ещё ${certificatesCountRest}`
                : 'Достигнуто максимальное количество сертификатов'
            }
          />
          {selectedCertificate && (
            <>
              <Gap size="s" />
              <FormControlSummary label="Статус">
                {selectedCertificateStateStr}
              </FormControlSummary>
              <Gap size="s" />
              <FormControlSummary label="Дата действия">
                {startDate}
                &nbsp;–&nbsp;
                {endDate}
              </FormControlSummary>
              <Gap size="s" />
              <FormControlSummary label="Владелец">
                {selectedCertificate?.parsedPayload?.owner}
              </FormControlSummary>
              <Gap size="l" />
              <div className={styles.buttons}>
                {selectedCertificate.state ===
                  CertificateStateType.Certifying && (
                  <Button
                    view="secondary"
                    size="s"
                    leftAddons={<CheckmarkMIcon width={18} height={18} />}
                    onClick={onClickCertificateConfirm}
                    disabled={isEnrollStepInProgress}
                    loading={isEnrollStepInProgress}
                    block
                  >
                    Подтвердить
                  </Button>
                )}
                {LK_CERTIFICATES && (
                  <Button
                    view="tertiary"
                    size="s"
                    leftAddons={<CrossMIcon width={18} height={18} />}
                    onClick={onClickCertificateReject}
                    disabled={isEnrollStepInProgress}
                    loading={isEnrollStepInProgress}
                    block
                  >
                    Отозвать
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
