import React, { FC, ReactNode } from 'react';
import { v4 } from 'uuid';
import { ButtonProps } from '@alfalab/core-components/button';

import {
  AlertSettingsForm,
  Cell,
  LineOpacitySelect,
  LineTypeSelect,
  NotifySwitch,
  TitlePositionSelect,
} from '../../../../../legacy/components/AlertSettingsForm';
import { AmountInput } from './AmountInput';
import { TitleInput } from './TitleInput';
import { useForm } from './useForm';

export type FormProps = {
  idFi: number;
  withGap?: boolean;
  children?: (submitButtonProps: ButtonProps) => ReactNode;
};

const FORM_ID = v4();

export const Form: FC<FormProps> = (props) => {
  const { idFi, withGap = false, children } = props;
  const { formikConfig, isLoading } = useForm(idFi);

  return (
    <>
      <AlertSettingsForm formikConfig={formikConfig} id={FORM_ID}>
        {withGap && <Cell span />}
        <Cell span>
          <AmountInput idFi={idFi} />
        </Cell>
        <Cell>
          <LineTypeSelect />
        </Cell>
        <Cell>
          <LineOpacitySelect />
        </Cell>
        <Cell span>
          <TitleInput />
        </Cell>
        <Cell>
          <TitlePositionSelect />
        </Cell>
        <Cell justify="center">
          <NotifySwitch />
        </Cell>
      </AlertSettingsForm>
      {children?.({ loading: isLoading, form: FORM_ID })}
    </>
  );
};
