import React, { useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { IconButton } from '@alfalab/core-components/icon-button';
import { TagDesktop } from '@alfalab/core-components/tag/desktop';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowRightSquareMIcon } from '@alfalab/icons-glyph/ArrowRightSquareMIcon';

import { WORKSPACE_CONFIGURATION_MAX_COUNT } from '@terminal/core/constants/workspaces';
import { shallow, useStore } from '@terminal/core/store';
import {
  ConfigSize,
  ConfigStyle,
  configurationMatrix,
  createConfiguration,
  isConfigAvailable,
} from '@terminal/desktop/entities/OnboardingConfig';
import { ConfigurationTooltip } from '@terminal/desktop/features/OnboardingConfig';

import styles from './WorkspaceConfigurationDefaultList.module.css';

const CONFIGS_TEXTS: Record<
  ConfigStyle,
  { name: string; description: string }
> = {
  [ConfigStyle.SWING]: {
    name: 'Среднесрочная торговля',
    description: 'В диапазоне от дня до недели',
  },
  [ConfigStyle.DAYTRADE]: {
    name: 'Дневная торговля',
    description: 'Совершение сделок в рамках торгового дня',
  },
  [ConfigStyle.SCALPING]: {
    name: 'Скальпинг',
    description: 'Совершение небольших сделок в большом количестве',
  },
  [ConfigStyle.BEGINNER]: {
    name: 'Базовая конфигурация',
    description: '',
  },
};

interface DefaultConfiguration {
  id: string;
  name: string;
  description: string;
  size: ConfigSize;
  style: ConfigStyle;
}

// функция возвращает набор вариантов конфигураций описанных енамами ConfigStyle и ConfigSizes
const createdDefaultConfigsMap = () => {
  const configMap: DefaultConfiguration[] = [];
  const configStyles: ConfigStyle[] = [
    ConfigStyle.BEGINNER,
    ConfigStyle.SWING,
    ConfigStyle.DAYTRADE,
    ConfigStyle.SCALPING,
  ];
  const configSizes: ConfigSize[] = [
    ConfigSize.SMALL,
    ConfigSize.MEDIUM,
    ConfigSize.LARGE,
  ];

  configStyles.forEach((style) => {
    configSizes.forEach((size) => {
      configMap.push({
        id: v4(),
        name: CONFIGS_TEXTS[style].name,
        description: CONFIGS_TEXTS[style].description,
        size,
        style,
      });
    });
  });

  return configMap;
};

const defaultConfigurations = createdDefaultConfigsMap();

const sizeFilterTags = [
  {
    id: v4(),
    title: '13-14 дюймов',
    value: ConfigSize.SMALL,
  },
  {
    id: v4(),
    title: '15-21 дюймов',
    value: ConfigSize.MEDIUM,
  },
  {
    id: v4(),
    title: 'Более 21 дюйма',
    value: ConfigSize.LARGE,
  },
];

export const WorkspaceConfigurationDefaultList = () => {
  const [workspaceConfigurations, createNewConfiguration, setHeaderMenuOpen] =
    useStore(
      (state) => [
        state.workspaceConfigurations,
        state.createNewConfiguration,
        state.setHeaderMenuOpen,
      ],
      shallow
    );
  const [selectedSize, setSelectedSize] = useState(ConfigSize.SMALL);
  const handleConfigSelect = (id: string) => {
    const configuration = defaultConfigurations.find(
      (config) => config.id === id
    );

    if (configuration) {
      // получаем конструктор конфигурации, соответствующий стилю и размеру экрана
      const configurationFactory =
        configurationMatrix[configuration.size][configuration.style];

      if (configurationFactory && typeof configurationFactory === 'function') {
        const newConfig = createConfiguration(configurationFactory());

        createNewConfiguration(newConfig);
        setHeaderMenuOpen(null);
      }
    }
  };

  const filteredConfigs = defaultConfigurations.filter(
    (config) => config.size === selectedSize
  );

  const isCountValid = useMemo(() => {
    return workspaceConfigurations.length < WORKSPACE_CONFIGURATION_MAX_COUNT;
  }, [workspaceConfigurations.length]);

  return (
    <div>
      <div className={styles.sizeFilter}>
        {sizeFilterTags.map((tag) => {
          return (
            <TagDesktop
              key={tag.id}
              size="s"
              view="filled"
              checked={tag.value === selectedSize}
              onClick={() => setSelectedSize(tag.value)}
            >
              {tag.title}
            </TagDesktop>
          );
        })}
      </div>

      <div className={styles.otherConfigurationsTable}>
        {filteredConfigs.map(({ name, id, description, size, style }) => {
          // если в матрице конфигураций нет функции для создания конфигураций
          if (!isConfigAvailable(size, style)) {
            return null;
          }

          return (
            <div className={styles.otherConfigurationsRow} key={id}>
              <div>
                <Typography.Text
                  view="secondary-large"
                  color="primary"
                  tag="p"
                  defaultMargins={false}
                >
                  {name}
                  <ConfigurationTooltip
                    style={style}
                    iconColor="var(--color-light-text-secondary)"
                  />
                </Typography.Text>
                <Typography.Text
                  view="secondary-small"
                  color="secondary"
                  tag="p"
                  defaultMargins={false}
                >
                  {description}
                </Typography.Text>
              </div>
              <div className={styles.otherConfigurationsButtons}>
                <TooltipDesktop
                  position="top"
                  trigger="hover"
                  content={
                    isCountValid ? (
                      'Применить'
                    ) : (
                      <>
                        Достигнуто максимальное количество
                        конфигураций&nbsp;-&nbsp;
                        {WORKSPACE_CONFIGURATION_MAX_COUNT}
                      </>
                    )
                  }
                  fallbackPlacements={['left', 'right']}
                >
                  <IconButton
                    size="xs"
                    view="secondary"
                    disabled={!isCountValid}
                    onClick={() => handleConfigSelect(id)}
                    icon={ArrowRightSquareMIcon}
                  />
                </TooltipDesktop>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
