import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { ReactNode } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { validateFullNameForm } from '../../../../entities/Login';
import { PersonalDataInput } from '../PersonalDataInput';
import { SmileStatus } from '../SmileStatus';

import styles from './FullName.module.css';

interface FullNameForm {
  firstName: string;
  lastName: string;
  surName?: string;
}

interface RenderControlsProps {
  hasValidationErrors: boolean;
  values: FullNameForm;
}

export interface FullNameProps {
  renderControls: (props: RenderControlsProps) => ReactNode;
}

export const FullName = (props: FullNameProps) => {
  const { renderControls } = props;
  const { values, errors, touched } = useFormikContext<FullNameForm>();

  const hasValidationErrors = Object.values(validateFullNameForm(values)).some(
    (value) => !isEmpty(value)
  );

  const hasFocusValidationErrors = Object.entries(touched).some(
    ([key, value]) => value && !isEmpty(errors[key])
  );

  return (
    <div className={styles.wrapper}>
      <SmileStatus status={hasFocusValidationErrors ? 'sad' : 'happy'} />
      <Gap size="2xl" />
      <Typography.Title tag="div" view="xsmall" className={styles.title}>
        Представьтесь, пожалуйста
      </Typography.Title>
      <Gap size="s" />
      <Typography.Text view="primary-medium">Как в паспорте</Typography.Text>
      <Gap size="xl" />
      <div className={styles.form}>
        <div className={styles.field}>
          <PersonalDataInput
            autoFocus
            name="lastName"
            placeholder="Фамилия"
            block
          />
        </div>
        <div className={styles.field}>
          <PersonalDataInput name="firstName" placeholder="Имя" block />
        </div>
        <div className={styles.field}>
          <PersonalDataInput
            name="surName"
            placeholder="Отчество, если есть"
            block
          />
        </div>
      </div>
      {renderControls({ hasValidationErrors, values })}
    </div>
  );
};
