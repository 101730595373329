import { useState } from 'react';

import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { DraftDetail } from './components/DraftDetail';
import { DraftsList } from './components/DraftsList';

export const Drafts = ({ treaty }: { treaty: Treaty | null }) => {
  const [draftId, setDraftId] = useState<number | undefined>();
  const Component = draftId ? DraftDetail : DraftsList;

  return (
    <Component draftId={draftId} setDraftId={setDraftId} treaty={treaty} />
  );
};
