import { OrderType } from '../../lib/client/entities';

/**
  Корректировка типа ордера при наличии secondPrice
*/
export const checkOrderType = (orderType: OrderType, secondPrice?: number) => {
  if (orderType === OrderType.MKT && secondPrice) {
    return OrderType.MIT;
  }

  if (orderType === OrderType.LMT && secondPrice) {
    return OrderType.LIT;
  }

  return orderType;
};
