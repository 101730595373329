import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkOnCircleMIcon } from '@alfalab/icons-glyph/CheckmarkOnCircleMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import { SelectionMaskMIcon } from '@alfalab/icons-glyph/SelectionMaskMIcon';

import { WidgetsNameMap } from '@terminal/core/constants/Layout'; // Функция для изменения порядка в списке
import { getColumnDescription } from '@terminal/core/lib/helpers/getColumnDescription';
import { getColumnName } from '@terminal/core/lib/helpers/getColumnName';
import { getIsColumnStatic } from '@terminal/core/lib/helpers/getIsColumnStatic';
import {
  BaseWidgetConfig,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';

import { ReactComponent as DragIcon } from '../../../../icons/drag_s.svg';

import styles from './ColumnSettingModal.module.css';

// Функция для изменения порядка в списке
const reorder = (
  list: TableColumnSetting[],
  startIndex: number,
  endIndex: number,
  widgetType: Widget
) => {
  let result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  //Тикер всегда первый
  const symbolSettingIndex = result.findIndex((col) =>
    getIsColumnStatic(col, widgetType)
  );

  if (symbolSettingIndex > 0 && symbolSettingIndex) {
    const [removed] = result.splice(symbolSettingIndex, 1);

    result.splice(0, 0, removed);
  }

  return result;
};

interface ColumnSettingModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  columnsSetting: TableColumnSetting[];
  updateNode: (
    nodeId: string,
    config: BaseWidgetConfig,
    newName?: string | undefined
  ) => void;
  nodeId?: string;
  widgetType: Widget;
}

export const ColumnSettingModal = React.memo(
  ({
    isOpen,
    setIsOpen,
    columnsSetting,
    widgetType,
    nodeId,
    updateNode,
  }: ColumnSettingModalProps) => {
    const [columns, setColumns] =
      useState<TableColumnSetting[]>(columnsSetting);

    useEffect(() => {
      setColumns(columnsSetting);
    }, [columnsSetting]);

    const handleSelection = (column: TableColumnSetting) => {
      setColumns((prevColumns) =>
        prevColumns.map((col) => {
          if (col.key === column.key && !column.isDragDisabled) {
            return { ...column, selected: !column.selected };
          } else {
            return col;
          }
        })
      );
    };

    const saveColumnsSetting = (columns: TableColumnSetting[]) => {
      if (!isUndefined(nodeId)) {
        updateNode(nodeId, { tableProps: { columns } });
      }
    };

    const onDragEnd = (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const newOrder = reorder(
        columns,
        result.source.index,
        result.destination.index,
        widgetType
      );

      setColumns(newOrder);
    };

    return (
      <BaseModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        wrapperClassName={styles.containerModal}
      >
        <div className={styles.content}>
          <Typography.Text
            view="secondary-large"
            weight="bold"
            className={styles.title}
          >
            Настройка колонок {WidgetsNameMap.get(widgetType)}
          </Typography.Text>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={styles.columns}
                >
                  {columns.map((column, index) => (
                    <Draggable
                      key={column.key}
                      draggableId={column.key}
                      index={index}
                      isDragDisabled={
                        column.isDragDisabled ||
                        getIsColumnStatic(column, widgetType)
                      }
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Option
                              isDragging={snapshot.isDragging}
                              column={column}
                              handleSelection={handleSelection}
                              key={column.key}
                              widgetType={widgetType}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className={styles.buttoms}>
            <ButtonDesktop
              onClick={() => {
                setColumns(columnsSetting);
                setIsOpen(false);
              }}
              view="ghost"
              size="xxs"
              block
              className={styles.customButton}
            >
              Отмена
            </ButtonDesktop>
            <ButtonDesktop
              onClick={() => {
                saveColumnsSetting(columns);
                setIsOpen(false);
              }}
              size="xxs"
              block
              disabled={isEqual(columns, columnsSetting)}
              className={styles.customButton}
              view="primary"
            >
              Сохранить
            </ButtonDesktop>
          </div>
        </div>
      </BaseModal>
    );
  },
  (prev, next) => {
    if (!isEqual(prev, next)) {
      return false;
    }

    return isEqual(prev.columnsSetting, next.columnsSetting);
  }
);

const Option = ({
  column,
  handleSelection,
  isDragging,
  widgetType,
}: {
  column: TableColumnSetting;
  handleSelection: (column: TableColumnSetting) => void;
  isDragging: boolean;
  widgetType: Widget;
}) => {
  const columnName = getColumnName(column);
  const columnDescription = getColumnDescription(column);
  const isColumnStatic = getIsColumnStatic(column, widgetType);

  return (
    <div
      className={cn(
        styles.setting,
        isDragging && styles.draggingSetting,
        (column.isDragDisabled || isColumnStatic) && styles.disabledSetting
      )}
    >
      {column.selected ? (
        <CheckmarkOnCircleMIcon
          widths={18}
          height={18}
          color="#7938e0"
          onClick={isColumnStatic ? undefined : () => handleSelection(column)}
        />
      ) : (
        <SelectionMaskMIcon
          widths={18}
          height={18}
          color="#8D8D93"
          onClick={isColumnStatic ? undefined : () => handleSelection(column)}
        />
      )}
      <div className={styles.settingNameWrapper}>
        <Typography.Text view="secondary-large">{columnName}</Typography.Text>
        {columnDescription && (
          <TooltipDesktop
            popoverClassName={styles.tooltip}
            arrowClassName={styles.tooltipArrow}
            content={
              <div className={styles.tooltipContent}>
                <Typography.Text view="secondary-small">
                  {columnDescription}
                </Typography.Text>
              </div>
            }
            trigger="hover"
            position="top-start"
          >
            <InformationCircleLineSIcon
              height={12}
              width={12}
              color="#8D8D93"
            />
          </TooltipDesktop>
        )}
      </div>
      {!isColumnStatic && <DragIcon className={styles.dragIcon} />}
    </div>
  );
};
