import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';

import { useCertificatesAll } from '@terminal/core/hooks';
import {
  CertificateEnroll,
  CertificateEnrollStep,
  useCertificateEnrollStore,
} from '@terminal/widgets/features/CertificateEnroll';

import { CertificateHeading } from '../CertificateHeading';

import styles from './OnboardingCertificate.module.css';

interface OnboardingLastStepProps {
  closeOnboardingModal: () => void;
}

export const OnboardingCertificate = ({
  closeOnboardingModal,
}: OnboardingLastStepProps) => {
  const [setStep, setRejectingCertificate] = useCertificateEnrollStore(
    (store) => [store.setStep, store.setRejectingCertificate]
  );

  const { selectedCertificate } = useCertificatesAll();

  const handleButtonClick = () => {
    if (selectedCertificate) {
      setRejectingCertificate(selectedCertificate);
      setStep(CertificateEnrollStep.Reject);
    }
  };

  return (
    <CertificateEnroll
      onSuccess={closeOnboardingModal}
      headerSlot={<CertificateHeading />}
      rejectButtonSlot={
        <>
          <Gap size="3xl" />
          <ButtonDesktop size="m" view="accent" onClick={handleButtonClick}>
            Отозвать и выпустить новый
          </ButtonDesktop>
        </>
      }
      bottomSlot={
        <ButtonDesktop
          size="s"
          view="ghost"
          className={styles.skipButton}
          block
          onClick={closeOnboardingModal}
        >
          Пропустить
        </ButtonDesktop>
      }
    />
  );
};
