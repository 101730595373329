import { CreateAttorneyFormValues } from '../model';
import { IDENTITY_DOCUMENT_TYPE_MAP } from './const/mappings';

import { CreateAttorneyParams } from '../api/types/CreateAttorneyParams';

export const createAttorneyRequestFromValues = (
  values: CreateAttorneyFormValues & {
    accCode: string;
  }
): CreateAttorneyParams => {
  return {
    attorneySysName: values.login,
    accCode: values.accCode,
    accessLevel: Number(values.accessLevel),
    attorneyName: values.fullName,
    docType: IDENTITY_DOCUMENT_TYPE_MAP[Number(values.identityDocumentType)],
    docNumber: values.identityDocumentNumber,
    attorneyDateEnd: values.endDate,
  };
};
