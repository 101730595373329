import { useCallback, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Space } from '@alfalab/core-components/space';
import { AddMIcon } from '@alfalab/icons-glyph/AddMIcon';
import { TrashCanLineMIcon } from '@alfalab/icons-glyph/TrashCanLineMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import {
  SecuritiesDocumentType,
  SecuritiesReasonDocument,
} from '@terminal/core/lib/rest/lkSecurities';

import { ConfirmModal } from '../../../components/ConfirmModal';
import { buildReasonDocumentStr, isDocumentsRequired } from '../../shared';
import { DocumentEditor } from './DocumentEditor';
import { DocumentsList } from './DocumentsList';

interface ReasonDocumentsProps {
  ownershipChange: boolean;
  documentTypes?: SecuritiesDocumentType[];
  documentTypesPending: boolean;
  reasonDocuments: SecuritiesReasonDocument[];
  onDocumentChange: (index: number, doc: SecuritiesReasonDocument) => void;
  onAddDocument: (doc: SecuritiesReasonDocument) => void;
  onDeleteDocument: (index: number) => void;
  operationDirection: 'in' | 'out';
}

const MAX_REASON_DOCUMENTS = 3;

export const ReasonDocuments = ({
  ownershipChange,
  documentTypes,
  documentTypesPending,
  reasonDocuments,
  onDocumentChange,
  onAddDocument,
  onDeleteDocument,
  operationDirection,
}: ReasonDocumentsProps) => {
  const [redactIndex, setRedactIndex] = useState<number | null>(null);
  const [showEditor, setShowEditor] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const isSecuritiesIn = operationDirection === 'in';

  const handleDocumentSave = (index: number, doc: SecuritiesReasonDocument) => {
    onDocumentChange(index, doc);
    closeEditor();
  };

  const handleAddDocument = (doc: SecuritiesReasonDocument) => {
    onAddDocument(doc);
    closeEditor();
  };

  const closeEditor = useCallback(() => {
    setShowEditor(false);
    setRedactIndex(null);
  }, []);

  const handleRedact = useCallback((index: number) => {
    setShowEditor(true);
    setRedactIndex(index);
  }, []);

  const handleDelete = useCallback((index: number) => {
    setDeleteIndex(index);
  }, []);

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      onDeleteDocument(deleteIndex);
    }
    setDeleteIndex(null);
  };

  if (!isDocumentsRequired(ownershipChange)) {
    return null;
  }

  return (
    <>
      <Space fullWidth direction="vertical">
        <DocumentsList
          documents={reasonDocuments}
          documentTypes={documentTypes}
          onRedact={handleRedact}
          onDelete={handleDelete}
          maxDocumentsCount={MAX_REASON_DOCUMENTS}
        />

        {reasonDocuments.length < MAX_REASON_DOCUMENTS && (
          <Button
            block
            view="tertiary"
            size="s"
            onClick={() => setShowEditor(true)}
            leftAddons={<AddMIcon width={18} height={18} />}
          >
            Добавить документ
          </Button>
        )}
      </Space>

      <Portal id="securities-transfer-modals">
        <FunctionalModalSidebar
          open={showEditor}
          backLabel={isSecuritiesIn ? 'Зачисление' : 'Списание'}
          onClose={closeEditor}
        >
          {showEditor && (
            <DocumentEditor
              document={
                redactIndex !== null ? reasonDocuments[redactIndex] : undefined
              }
              documentTypes={documentTypes}
              documentTypesPending={documentTypesPending}
              onSave={(doc) =>
                redactIndex !== null
                  ? handleDocumentSave(redactIndex, doc)
                  : handleAddDocument(doc)
              }
            />
          )}
        </FunctionalModalSidebar>
      </Portal>

      <ConfirmModal
        open={
          deleteIndex !== null && reasonDocuments[deleteIndex] !== undefined
        }
        title="Удалить документ"
        text={
          <>
            Вы уверены, что хотите удалить{' '}
            {deleteIndex !== null &&
              Boolean(reasonDocuments[deleteIndex]) &&
              buildReasonDocumentStr(reasonDocuments[deleteIndex])}
            ?
          </>
        }
        confirmLabel="Удалить"
        confirmIcon={<TrashCanLineMIcon width={18} height={18} />}
        onClose={() => setDeleteIndex(null)}
        onConfirm={handleDeleteConfirm}
      />
    </>
  );
};
