import sumBy from 'lodash/sumBy';

import { ObjectGroup } from '../client/entities';

import { ECurrencyId } from '../../types/currencyId';
import { PositionItem } from '../../types/position';

/**
 * @returns Стоимость лонговых позиций без учета позиций по рублям и фьючерсам в рублевом эквиваленте
 * */
export function calculateLongBalance(positions: PositionItem[]) {
  return sumBy(
    positions.filter(
      (tr) =>
        tr.position?.idObject !== ECurrencyId.RUR &&
        tr.objectType?.idObjectGroup !== ObjectGroup.Futures &&
        Number(tr.torgPos) > 0
    ),
    'torgPosCostRur'
  );
}
