export enum AdirApiEvent {
  SEND_DATA_FLOW_SUBSCRIBE_ENTITY = 'SEND_DATA_FLOW_SUBSCRIBE_ENTITY',
  SEND_DATA_FLOW_UNSUBSCRIBE_ENTITY = 'SEND_DATA_FLOW_UNSUBSCRIBE_ENTITY',
  SOCKET_ERROR = 'SOCKET_ERROR',
  SOCKET_CLOSE = 'SOCKET_CLOSE',
  SOCKETS_READY = 'SOCKETS_READY',
  SOCKET_READY = 'SOCKET_READY',
}

export interface AdirClientOptions {
  // код приложения для подключения к торговым сервисам
  readonly developerCode: string;
  // перечисляем URI для подключения к фронтендам
  readonly feURI: Readonly<{
    FE1: string;
    FE2: string;
    FE3: string;
    FE4: string;
    FE31: string;
  }>;
  // интервал отсылки heartbeat во фронтенды
  readonly heartBeatInterval: number;
  // через сколько соединение будет считаться потерянным
  //  если не был получен хартбит
  readonly lostConnectionTimeout: number;
  /**
  @deprecated
  НЕ ИСПОЛЬЗОВАТЬ ДЛЯ НОВОГО КОДА
  флаг того что это альфа-инвестиции
  */
  readonly isAI: boolean;
}
