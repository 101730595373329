import cn from 'classnames';
import { useMemo, useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { Popover } from '@alfalab/core-components/popover';
import { Typography } from '@alfalab/core-components/typography';
import { PlusCompactSIcon } from '@alfalab/icons-glyph/PlusCompactSIcon';

import { MAX_CERTIFICATES_COUNT } from '@terminal/core/constants/certificates';
import { trackClickOnConnectionStatus } from '@terminal/core/lib/analytics';
import { shallow, useStore } from '@terminal/core/store';
import { ConnectionStatus } from '@terminal/core/types/connection';
import {
  TECH_MESSAGE_CODE,
  WidgetServiceNotification,
} from '@terminal/widgets';

import styles from './Status.module.css';

enum TooltipStatus {
  OK = 'green',
  WARNING = 'orange',
  ERROR = 'red',
}

export const Status = () => {
  const [
    certificates,
    workingCertificate,
    connectionStatus,
    enrollCertificate,
    certificateEnrollId,
  ] = useStore(
    (state) => [
      state.certificates,
      state.workingCertificate,
      state.connectionStatus,
      state.enrollCertificate,
      state.certificateEnrollId,
    ],
    shallow
  );

  const buttonRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isOpen, toggleIsOpen] = useToggle(false);

  const daysToExpireCertificate = useMemo(() => {
    if (workingCertificate && workingCertificate.parsedPayload) {
      const now = Date.now();
      const endDate = workingCertificate.parsedPayload?.endDate;
      const endDateTime = endDate?.getTime();

      if (endDateTime && now) {
        return Math.floor((endDateTime - now) / (1000 * 60 * 60 * 24));
      }
    }

    return undefined;
  }, [workingCertificate]);

  const connectionStatusData = useMemo(() => {
    switch (connectionStatus) {
      case ConnectionStatus.CONNECTED:
        return {
          status: TooltipStatus.OK,
          title: 'Подключение к серверам',
          description: 'Соединение установлено',
        };
      case ConnectionStatus.OFFLINE:
        return {
          status: TooltipStatus.ERROR,
          title: 'Подключение к серверам',
          description: 'Нет подключения',
        };
      default:
        return {
          status: TooltipStatus.WARNING,
          title: 'Подключение к серверам',
          description: 'Идет попытка соединения...',
        };
    }
  }, [connectionStatus]);

  const certificateStatusData = useMemo(() => {
    if (workingCertificate && workingCertificate.parsedPayload) {
      const now = Date.now();
      const endDate = workingCertificate.parsedPayload?.endDate!;
      const endDateTime = endDate.getTime()!;
      const twoWeek = 1000 * 60 * 60 * 24 * 14;

      if (endDateTime - now < twoWeek) {
        return {
          status: TooltipStatus.WARNING,
          title: 'Сертификат',
          description: `Закончится через ${daysToExpireCertificate} дн.`,
        };
      }

      if (endDateTime > now) {
        return {
          status: TooltipStatus.OK,
          title: 'Сертификат',
          description: `Закончится через ${daysToExpireCertificate} дн.`,
        };
      }
    }

    return {
      status: TooltipStatus.ERROR,
      title: 'Сертификат',
      description: 'Истек срок действия',
    };
  }, [daysToExpireCertificate, workingCertificate]);

  const commonStatusData = useMemo(() => {
    if (
      connectionStatusData.status === TooltipStatus.OK &&
      certificateStatusData.status === TooltipStatus.OK
    ) {
      return {
        status: TooltipStatus.OK,
        title: 'Онлайн',
        description: 'Функционал терминала доступен в полном объеме',
      };
    }

    if (
      connectionStatusData.status !== TooltipStatus.OK &&
      certificateStatusData.status === TooltipStatus.OK
    ) {
      return {
        status: TooltipStatus.WARNING,
        title: 'Функционал ограничен',
        description: 'Нет подключения к одному из серверов – данные устарели',
      };
    }

    if (
      connectionStatusData.status === TooltipStatus.OK &&
      certificateStatusData.status !== TooltipStatus.OK
    ) {
      return {
        status: TooltipStatus.WARNING,
        title: 'Функционал ограничен',
        description:
          'Устарел сертификат электронной подписи – отправка торговых приказов ограничена',
      };
    }

    return {
      status: TooltipStatus.ERROR,
      title: 'Оффлайн',
      description: 'Нет подключения к торговым  серверам – данные устарели',
    };
  }, [certificateStatusData.status, connectionStatusData.status]);

  const statusTooltip = useMemo<TooltipStatus>(() => {
    if (connectionStatusData.status === TooltipStatus.ERROR) {
      return TooltipStatus.ERROR;
    }

    if (
      certificateStatusData.status === TooltipStatus.OK &&
      connectionStatusData.status === TooltipStatus.OK
    ) {
      return TooltipStatus.OK;
    }

    return TooltipStatus.WARNING;
  }, [certificateStatusData.status, connectionStatusData.status]);

  useClickAway(popoverRef, (event) => {
    if (
      buttonRef.current === event.target ||
      buttonRef.current?.contains(event.target as Node)
    ) {
      return;
    }

    toggleIsOpen(false);
  });

  const isAllowIssueCertificate =
    Boolean(certificateEnrollId) ||
    certificates.length < MAX_CERTIFICATES_COUNT;

  return (
    <>
      <div
        ref={buttonRef}
        role="button"
        className={styles.statusWrapper}
        onClick={() => {
          trackClickOnConnectionStatus();
          toggleIsOpen((prev) => !prev);
        }}
      >
        <div className={cn(styles.status, styles[statusTooltip])} />
      </div>
      <Popover
        ref={popoverRef}
        open={isOpen}
        anchorElement={buttonRef.current}
        position="bottom-start"
        offset={[0, 15]}
      >
        <div className={styles.root}>
          {[commonStatusData, connectionStatusData, certificateStatusData].map(
            ({ status, title, description }) => (
              <div key={`${status}-${title}`} className={styles.content}>
                <div className={styles.statusWrapper}>
                  <div className={cn(styles.status, styles[status])} />
                </div>
                <div className={styles.contentCell}>
                  <Typography.Text
                    view="secondary-large"
                    color="primary"
                    weight="medium"
                    tag="p"
                    defaultMargins={false}
                    className={styles.title}
                  >
                    {title}
                  </Typography.Text>
                  <Typography.Text view="secondary-small" color="secondary">
                    {description}
                  </Typography.Text>
                </div>
              </div>
            )
          )}

          {isAllowIssueCertificate &&
            certificateStatusData?.status === TooltipStatus.ERROR && (
              <div
                className={styles.certificateEnrollWrapper}
                onClick={() => {
                  toggleIsOpen(false);
                  enrollCertificate();
                }}
              >
                <div className={styles.certificateEnrollIcon}>
                  <PlusCompactSIcon />
                </div>
                <div className={styles.contentCell}>
                  <Typography.Text
                    view="secondary-large"
                    color="primary"
                    weight="medium"
                  >
                    Выпустить новый сертификат
                  </Typography.Text>
                </div>
              </div>
            )}
          <WidgetServiceNotification
            containerClassName={styles.serviceNotifications}
            code={[
              TECH_MESSAGE_CODE.MAIN,
              TECH_MESSAGE_CODE.TRADE,
              TECH_MESSAGE_CODE.POSITIONS_BALANCES,
            ]}
            variant="message"
          />
        </div>
      </Popover>
    </>
  );
};
