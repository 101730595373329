import { groupBy } from 'lodash';

import { CurrenciesRequisites } from '@terminal/core/lib/rest/lkMoney';

export const CURRENCIES_ORDER = ['RUB', 'RUR', 'USD', 'EUR', 'CNY'];

export const CURRENCIES_GROUP_NAME = {
  RUB: 'Реквизиты для пополнения в рублях',
  RUR: 'Реквизиты для пополнения в рублях',
  USD: 'Реквизиты для пополнения в долларах США',
  EUR: 'Реквизиты для пополнения в евро',
  CNY: 'Реквизиты для пополнения в юанях',
};

const RUB_FIELDS_SCHEME: Array<keyof CurrenciesRequisites | 'divider'> = [
  'bank',
  'beneficiary',
  'account',
  'inn',
  'bic',
  'kpp',
  'corrAccount',
  'paymentPurpose',
];

export const REQUISITES_FIELDS_SCHEME: {
  [key: string]: Array<keyof CurrenciesRequisites | 'divider'>;
} = {
  RUB: RUB_FIELDS_SCHEME,
  RUR: RUB_FIELDS_SCHEME,
  USD: [
    'bankIntermediary',
    'bankIntermediarySwift',
    'divider',
    'bankCorrName',
    'bankCorrSwift',
    'bankCorrAccount',
    'divider',
    'beneficiary',
    'recAdress',
    'bankRecSwift',
    'account',
    'divider',
    'paymentPurpose',
  ],
  EUR: [
    'bank',
    'bankRecSwift',
    'beneficiary',
    'account',
    'recAdress',
    'divider',
    'bankCorrName',
    'bankCorrSwift',
    'bankCorrAccount',
    'paymentPurpose',
  ],
  CNY: [
    'bank',
    'bankRecSwift',
    'beneficiary',
    'account',
    'recAdress',
    'divider',
    'bankCorrName',
    'bankCorrSwift',
    'bankCorrAccount',
    'paymentPurpose',
  ],
};

export const REQUISITES_IGNORE_FIELDS: Array<keyof CurrenciesRequisites> = [
  'id',
  'priority',
  'error',
];

export const REQUISITES_FILED_DESCRIPTION: { [s: string]: string } = {
  account: 'Счёт получателя',
  corrAccount: 'Корреспондентский счет',
  beneficiary: 'Получатель платежа',
  bic: 'БИК',
  bank: 'Банк получателя',
  inn: 'ИНН получателя',
  kpp: 'КПП',
  bankIntermediary: 'Банк-посредник',
  bankIntermediarySwift: 'Код банка-посредника',
  bankCorrName: 'Банк-корреспондент',
  bankCorrSwift: 'Код банка-корреспондента',
  bankCorrAccount: 'Счёт банка-корреспондента',
  recAdress: 'Адрес получателя',
  bankRecSwift: 'Код банка получателя',
  paymentPurpose: 'Назначение платежа',
};

export const REQUISITES_FILED_TOOLTIP: { [s: string]: string } = {
  account: 'Beneficiary’s account',
  beneficiary: 'Beneficiary',
  bank: 'Beneficiary’s bank',
  bankIntermediary: 'Intermediary bank',
  bankIntermediarySwift: 'S.W.I.F.T',
  bankCorrName: 'Correspondent bank',
  bankCorrSwift: 'S.W.I.F.T.',
  bankCorrAccount: 'Correspondent account',
  recAdress: 'Beneficiary’s address',
  bankRecSwift: 'S.W.I.F.T.',
  paymentPurpose: 'Payment details',
};

export const sortRequisites = (
  a: CurrenciesRequisites,
  b: CurrenciesRequisites
) => a.priority - b.priority;

export function getCurrenciesGroups(requisites: CurrenciesRequisites[]) {
  const ordered = requisites.filter(
    (req) => CURRENCIES_ORDER.indexOf(req.currency) >= 0
  );
  const other = requisites.filter(
    (req) => CURRENCIES_ORDER.indexOf(req.currency) < 0
  );

  const orderedGroups = groupBy(ordered, 'currency');
  const otherGroups = groupBy(other, 'currency');

  for (let cur in orderedGroups) {
    orderedGroups[cur].sort(sortRequisites);
  }
  for (let cur in otherGroups) {
    otherGroups[cur].sort(sortRequisites);
  }

  return {
    ordered: orderedGroups,
    other: otherGroups,
  };
}
