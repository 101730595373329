// текущий список - https://rusbonds.ru/markets/876287/structure от 24.03.2023
// nickname вместо isin - потому что @todo: а почему, кстати?

export const RGBITRList = [
  'OFZ-PD 26234', // RU000A101QE0
  'OFZ-PD 26229', // RU000A100EG3
  'OFZ-PD 26236', // RU000A102BT8
  'OFZ-PD 26239', // RU000A103901
  'OFZ-PD 26228', // RU000A100A82
  'OFZ-PD 26232', // RU000A1014N4
  'OFZ-PD 26227', // RU000A1007F4
  'OFZ-PD 26241', // RU000A105FZ9
  'OFZ-PD 26235', // RU000A1028E3
  'OFZ-PD 26237', // RU000A1038Z7
  'OFZ-PD 26226', // RU000A0ZZYW2
  'OFZ-PD 26222', // RU000A0JXQF2
  'OFZ-PD 26240', // RU000A103BR0
  'OFZ-PD 26207', // RU000A0JS3W6
  'OFZ-PD 26219', // RU000A0JWM07
  'OFZ-PD 26223', // RU000A0ZYU88
  'OFZ-PD 26233', // RU000A101F94
  'OFZ-PD 26212', // RU000A0JTK38
  'OFZ-PD 26224', // RU000A0ZYUA9
  'OFZ-PD 26221', // RU000A0JXFM1
  'OFZ-PD 26225', // RU000A0ZYUB7
  'OFZ-PD 26230', // RU000A100EF5
  'OFZ-PD 26218', // RU000A0JVW48
  'OFZ-PD 26238', // RU000A1038V6
];
