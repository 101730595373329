import { ServiceAttentionIcon } from '../ServiceAttentionIcon';
import { ServiceBanner } from '../ServiceBanner';
import { ServiceBannerModal } from '../ServiceBannerModal';
import { ServiceLineBanner } from '../ServiceLineBanner';
import { ServiceMessage } from '../ServiceMessage';

const STUB = 'Важная информация';

interface ServiceNotificationItemProps {
  variant: 'line' | 'banner' | 'modal' | 'message' | 'icon';
  message?: string;
  className?: string;
  short?: boolean;
  closable?: boolean;
}

export const ServiceNotificationItem = ({
  variant,
  className,
  short,
  message,
  closable,
}: ServiceNotificationItemProps) => {
  if (!message) {
    return null;
  }

  switch (variant) {
    case 'icon':
      return <ServiceAttentionIcon className={className} />;

    case 'banner':
      return <ServiceBanner className={className} message={message} />;
    case 'line':
      return (
        <ServiceLineBanner
          className={className}
          message={message}
          shortText={STUB}
          short={short}
          closable={closable}
        />
      );
    case 'modal':
      return <ServiceBannerModal className={className} message={message} />;

    default:
      return (
        <ServiceMessage
          className={className}
          message={message}
          shortText={STUB}
        />
      );
  }
};
