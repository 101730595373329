import cn from 'classnames';
import { Form, Formik, FormikConfig } from 'formik';
import React, { HTMLAttributes, ReactElement } from 'react';

import { TrackPriceCommonSettings } from '@terminal/core/types/trackPrice';

import styles from './AlertSettingsForm.module.css';

export type AlertSettingsFormProps<V> = HTMLAttributes<HTMLFormElement> & {
  formikConfig: FormikConfig<V>;
};

export function AlertSettingsForm<V extends TrackPriceCommonSettings>(
  props: AlertSettingsFormProps<V>
): ReactElement {
  const { formikConfig, className, children, ...restProps } = props;
  const formClassName = cn(className, styles.root);

  return (
    <Formik {...formikConfig}>
      <Form className={formClassName} {...restProps}>
        {children}
      </Form>
    </Formik>
  );
}
