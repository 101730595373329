import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { mapEducationStrategy } from '../lib/mapEducationStrategy';

import { EducationStrategyDTO } from './types/EducationStrategyDTO';

export async function getEducationStrategies() {
  const res = await fetchLkResult<EducationStrategyDTO[] | LKResult>(
    `/api/education/strategies`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res.map(mapEducationStrategy);
}
