import { FC, useEffect, useRef } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { CodeInputDesktop } from '@alfalab/core-components/code-input/desktop';
import { CustomInputRef } from '@alfalab/core-components/code-input/typings';
import { Typography } from '@alfalab/core-components/typography';

import styles from './ConfirmationEnterPinCode.module.css';

interface Props {
  onValidate: (code: string) => Promise<void>;
  onForgot: () => void;
  error?: string;
  clearError: () => void;
}

export const ConfirmationEnterPinCode: FC<Props> = ({
  onValidate,
  onForgot,
  error,
  clearError,
}) => {
  const inputRef = useRef<CustomInputRef>(null);

  useEffect(() => {
    inputRef.current?.focus?.();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Typography.Title
        view="xsmall"
        tag="div"
        font="styrene"
        className={styles.title}
      >
        Введите код
        <br />
        для быстрого входа
      </Typography.Title>

      <CodeInputDesktop
        ref={inputRef}
        fields={4}
        onChange={clearError}
        onComplete={onValidate}
        className={styles.codeInput}
        error={error}
      />

      <Typography.Text view="secondary-small" tag="div">
        Установленный код можно использовать только
        <br /> в рамках текущего устройства и браузера
      </Typography.Text>

      <ButtonDesktop
        view="tertiary"
        size="m"
        onClick={onForgot}
        className={styles.button}
      >
        Не помню код
      </ButtonDesktop>
    </div>
  );
};
