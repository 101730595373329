import { useMemo, useState } from 'react';

import { SettingsItem, SwitchSettings } from '../../../features/WidgetSettings';

interface Settings {
  isDoubleRowMode: boolean;
  switchDoubleRowMode: () => void;
  setIsOpenColumnSettings: (state: boolean) => void;
  setIsOpenBalanceSettings: (state: boolean) => void;
}

export const useSettings = ({
  isDoubleRowMode,
  switchDoubleRowMode,
  setIsOpenColumnSettings,
  setIsOpenBalanceSettings,
}: Settings) => {
  const [isOpen, setIsOpen] = useState(false);

  const settingsGroups = useMemo(
    () => [
      {
        id: 'common',
        title: 'Общие',
        items: [
          <SwitchSettings
            key="doubleRow"
            checked={isDoubleRowMode}
            label="Двустрочное отображение"
            reversed
            onChange={switchDoubleRowMode}
          />,
          <SettingsItem
            key="info"
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => setIsOpenBalanceSettings(true)}
          >
            Информационная строка...
          </SettingsItem>,
        ],
      },
      {
        id: 'columns',
        title: 'Колонки',
        items: [
          <SettingsItem
            key="configureColumns"
            onClick={() => setIsOpenColumnSettings(true)}
          >
            Настроить...
          </SettingsItem>,
        ],
      },
    ],
    [
      isDoubleRowMode,
      setIsOpenBalanceSettings,
      setIsOpenColumnSettings,
      switchDoubleRowMode,
    ]
  );

  return useMemo(
    () => ({
      open: isOpen,
      groups: settingsGroups,
      onShowRequest: () => setIsOpen(true),
      onHideRequest: () => setIsOpen(false),
    }),
    [isOpen, settingsGroups]
  );
};
