import { FC, useMemo } from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { MINORITY } from '@terminal/core/constants/ui';
import { getStringDate } from '@terminal/core/lib/format';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfo } from '@terminal/core/types/quotes';

import { InstrumentDataRow } from '../../ui';

import classes from './BondIssueInformation.module.css';

interface Props {
  fullFI: ExtendedFI;
  quoteData?: Partial<FinInfo>;
}

export const BondIssueInformation: FC<Props> = ({ fullFI, quoteData }) => {
  const { objectsTable } = useAlfaDirectContext();

  const baseCurrency = useMemo(() => {
    const currencyObject = objectsTable.get(
      'idObject',
      fullFI?.idObjectFaceUnit
    );

    return currencyObject?.symbolObject;
  }, [fullFI?.idObjectFaceUnit, objectsTable]);

  const currencyCode =
    fullFI?.idObjectCurrency === fullFI?.idObjectFaceUnit
      ? fullFI?.currencyCode
      : (baseCurrency as CurrencyCodes);

  return (
    <div className={classes.bondIssueContainer}>
      <Typography.TitleMobile
        view="xsmall"
        tag="div"
        weight="bold"
        color="secondary"
        className={classes.bondIssueTitle}
      >
        О выпуске
      </Typography.TitleMobile>
      {fullFI?.nominal && (
        <InstrumentDataRow
          title="Номинал"
          value={
            <Amount.Pure
              value={fullFI.nominal * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency={currencyCode}
              dataTestId="BondInfoNominal"
            />
          }
        />
      )}
      {quoteData?.yield !== undefined && (
        <InstrumentDataRow
          title="Доходность к погашению"
          value={<>{quoteData.yield || 0} %</>}
        />
      )}
      {fullFI?.matDateObject && (
        <InstrumentDataRow
          title="Дата погашения"
          value={getStringDate(fullFI.matDateObject)}
        />
      )}
    </div>
  );
};
