import cn from 'classnames';
import { Button } from '@alfalab/core-components/button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';

import styles from './Advantage.module.css';

export interface AdvantageProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  text: React.ReactNode;
  link?: string;
  btnLabel?: string;
  className?: string;
}

export const Advantage = ({
  icon,
  title,
  text,
  link,
  btnLabel,
  className,
}: AdvantageProps) => (
  <div className={cn(styles.box, className)}>
    <div className={styles.icon}>
      <SuperEllipse size={64}>{icon}</SuperEllipse>
    </div>
    <Typography.Text view="primary-small" tag="div" className={styles.title}>
      {title}
    </Typography.Text>
    <Typography.Text view="secondary-small" tag="div" className={styles.text}>
      {text}
    </Typography.Text>
    {Boolean(btnLabel) && Boolean(link) && (
      <Button href={link} size="xxs">
        {btnLabel}
      </Button>
    )}
  </div>
);
