import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useFullFI } from '@terminal/alfadirect/hooks';
import { OrderType } from '@terminal/core/lib/client/entities';
import { MarketBoard } from '@terminal/core/types/core';
import { OrderBookProps, Widget } from '@terminal/core/types/layout';

import { SLTPInstruction } from '../../../../features';
import { CancelOrderModal, EditOrderModal } from '../../../../features/Orders';
import { useWidgetContext } from '../../../../shared';
import { OrderBookContextProvider } from '../../context';
import { INSTRUMENT_CHANGED_METRIC } from './model/constants';
import { OrderBookContent, OrderBookContentProps } from './OrderBookContent';
import { ContextMenuSettings, DefaultQuantitySettings, Header } from './ui';
import { DefaultSlippageSettings } from './ui/DefaultSlippageSettings';

import { useSelectedAccount } from '../../../../shared/hooks/useSelectedAccount';
import { useDefaultQuantity } from '../../hooks/useDefaultQuantity';
import { useOnElemRectHover } from '../../hooks/useOnElemRectHover';
import { useOrderBookFinInfoExt } from '../../hooks/useOrderBookFinInfoExt';
import { useOrderBookTrading } from '../../hooks/useOrderBookTrading';

import styles from './OrderBook.module.css';

export const OrderBook = ({
  idFi,
  nodeId,
  tableProps,
  autoCenter = true,
  hideForm,
  showEmptyPrice,
  showSpread,
  showYield = true,
  sendTrallingOrders,
  stopOrderType,
  slippageType,
  slippageValue,
  limitsProps,
  contextMenuProps,
}: OrderBookProps) => {
  const { useSettings, useLogWidgetUpdatePerformance, getActiveLayoutKey } =
    useWidgetContext();
  const containerRef = useRef<HTMLDivElement>(null);

  const finInfoExt = useOrderBookFinInfoExt(idFi);

  const { selectedAccount, selectedSubAccountId, selectedSubAccounts } =
    useSelectedAccount(tableProps);
  const selectedSubAccount = useMemo(
    () => [selectedSubAccounts[0]],
    [selectedSubAccounts]
  );

  const settings = useSettings();
  const isAlwaysConfirmOrders = settings.defaultValues.alwaysConfirmOrders;

  const updatePerfomanceMetric = useLogWidgetUpdatePerformance(
    getActiveLayoutKey(),
    Widget.ORDERBOOK
  );

  const [contextPrice, setContextPrice] = useState(0);
  const [isAutoScrollPaused, setIsAutoScrollPaused] = useState(false);

  const [isOpenLimitsModal, setIsOpenLimitsModal] = useState(false);
  const [isOpenContextMenuModal, setIsOpenContextMenuModal] = useState(false);
  const [isOpenQuantityModal, setIsOpenQuantityModal] = useState(false);
  const [isOpenSlippageModal, setIsOpenSlippageModal] = useState(false);

  const onHover = useCallback(() => setIsAutoScrollPaused(true), []);
  const onMouseOut = useCallback(() => setIsAutoScrollPaused(false), []);

  useOnElemRectHover(containerRef, onHover, onMouseOut);

  const [isOpenContext, setIsOpenContext] = useState(false);

  const [sltp, setSltp] = useState<SLTPInstruction | null>(null);

  useEffect(() => {
    setSltp(null);
  }, [idFi, sendTrallingOrders]);

  useEffect(() => {
    if (!isOpenContext) {
      setContextPrice?.(0);
    }
  }, [isOpenContext, setContextPrice]);

  const fullFi = useFullFI(idFi);

  const defaultQuantity = useDefaultQuantity(idFi);

  const {
    orderToEdit,
    setOrderToEdit,
    orderToCancel,
    setOrderToCancel,
    isOpenCancelModal,
    setIsOpenCancelModal,
    isLoadingCancelModal,
    setIsLoadingCancelModal,
    isOpenEditModal,
    setIsOpenEditModal,
    isLoadingEditModal,
    setIsLoadingEditModal,
    sendOrder,
    cancelOrder,
    replaceOrder,
  } = useOrderBookTrading();

  useEffect(
    () => () => {
      updatePerfomanceMetric.start(INSTRUMENT_CHANGED_METRIC);
    },
    [idFi, updatePerfomanceMetric]
  );

  const uiActions: OrderBookContentProps['uiActions'] = {
    setOrderToCancel,
    setIsOpenCancelModal,
    setOrderToEdit,
    setIsOpenEditModal,
    setIsOpenLimitsModal,
    setIsOpenContext,
    setIsOpenContextMenuModal,
    setContextPrice,
    setIsOpenSlippageModal,
    setIsOpenQuantityModal,
  };

  return (
    <OrderBookContextProvider
      autoCenter={autoCenter}
      showSpread={showSpread}
      showYield={showYield}
      showEmptyPrice={showEmptyPrice}
      sendTrallingOrders={sendTrallingOrders}
      stopOrderType={stopOrderType || OrderType.STL}
      defaultQuantity={defaultQuantity}
      slippageType={slippageType}
      slippageValue={slippageValue}
    >
      <div ref={containerRef} className={styles.container}>
        <Header
          nodeId={nodeId}
          idFi={idFi}
          selectedSubAccounts={selectedSubAccount}
          hideForm={hideForm || fullFi?.idMarketBoard === MarketBoard.EUCLR}
          finInfoExt={finInfoExt}
          setIsOpenLimitsModal={setIsOpenLimitsModal}
          setIsOpenContextMenuModal={setIsOpenContextMenuModal}
          setIsOpenQuantityModal={setIsOpenQuantityModal}
          setIsOpenSlippageModal={setIsOpenSlippageModal}
        />
        <div
          id={`trading-limits-${nodeId?.slice(1)}`}
          className={styles.limitsPortal}
        />
        <OrderBookContent
          idFi={idFi}
          selectedSubAccountId={selectedSubAccountId}
          cancelOrder={cancelOrder}
          isAutoScrollPaused={isAutoScrollPaused}
          sltp={sltp}
          setSltp={setSltp}
          selectedAccount={selectedAccount}
          hideForm={hideForm}
          isOpenLimitsModal={isOpenLimitsModal}
          limitsProps={limitsProps}
          selectedSubAccounts={selectedSubAccounts}
          nodeId={nodeId}
          isOpenContext={isOpenContext}
          sendOrder={sendOrder}
          contextPrice={contextPrice}
          contextMenuProps={contextMenuProps}
          uiActions={uiActions}
        />
        <CancelOrderModal
          isLoading={isLoadingCancelModal}
          setIsLoading={setIsLoadingCancelModal}
          isOpen={isOpenCancelModal}
          cancelOrder={cancelOrder}
          order={orderToCancel}
          onCloseRequest={() => {
            setOrderToCancel(null);
            setIsOpenCancelModal(false);
          }}
        />
        <EditOrderModal
          isOpen={isOpenEditModal}
          idFi={idFi}
          order={orderToEdit}
          onClose={() => {
            setOrderToEdit(null);
          }}
          selectedAccount={selectedAccount}
          onCancelOrder={() => {
            if (isAlwaysConfirmOrders) {
              setOrderToCancel(orderToEdit);
              setIsOpenCancelModal(true);
            } else {
              orderToEdit && cancelOrder(orderToEdit);
            }
          }}
          selectedSubAccountId={selectedSubAccountId}
          replaceOrder={replaceOrder}
          isLoading={isLoadingEditModal}
          setIsLoading={setIsLoadingEditModal}
        />
      </div>
      {isOpenContextMenuModal && (
        <ContextMenuSettings
          nodeId={nodeId}
          open={isOpenContextMenuModal}
          onClose={() => setIsOpenContextMenuModal(false)}
          contextMenuProps={contextMenuProps}
        />
      )}
      {isOpenQuantityModal && (
        <DefaultQuantitySettings
          idFi={idFi}
          nodeId={nodeId}
          open={isOpenQuantityModal}
          finInfoExt={finInfoExt}
          onClose={() => setIsOpenQuantityModal(false)}
        />
      )}
      {isOpenSlippageModal && (
        <DefaultSlippageSettings
          idFi={idFi}
          nodeId={nodeId}
          open={isOpenSlippageModal}
          finInfoExt={finInfoExt}
          onClose={() => setIsOpenSlippageModal(false)}
        />
      )}
    </OrderBookContextProvider>
  );
};
