/**
 * @returns Прибыль/убыток по позиции в пунктах
 * */
export function getNPLPoints(
  TorgPos: number,
  Price: number | null,
  UchPrice: number,
  PriceStep: number
): number | null {
  if (Price === null) {
    return null;
  }

  return TorgPos >= 0
    ? (Price - UchPrice) / PriceStep
    : (UchPrice - Price) / PriceStep;
}
