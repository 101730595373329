import { fetchThroughProxy } from '../../url';
import { getAuthorizationHeader } from '../investApi';
import { mapMarginParams } from './mapMarginParams';
import { MarginParamsDTO } from './MarginParamsDTO';

import { MarginParams } from '../../../types/MarginParams';

export type MarginParamsFetcher = (
  idAccount: number,
  isin: string,
  universalMarketCode: string
) => Promise<MarginParams>;

export function createMarginParamsFetcher(url: string): MarginParamsFetcher {
  return async function fetchMarginParams(
    idAccount,
    isin,
    universalMarketCode
  ) {
    const response = await fetchThroughProxy(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthorizationHeader(),
      },
      body: JSON.stringify({
        accountIds: [idAccount],
        assetCodes: [isin],
        marketCodes: [universalMarketCode],
      }),
    });

    if (!response.ok) {
      throw new Error('Произошла ошибка при запросе к серверу');
    }

    const data: MarginParamsDTO = await response.json();

    if (!Array.isArray(data) || data.length === 0) {
      throw new Error('Неверный формат данных');
    }

    return mapMarginParams(data);
  };
}
