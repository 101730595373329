import cn from 'classnames';
import { Attach } from '@alfalab/core-components/attach';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Typography } from '@alfalab/core-components/typography';

import styles from './XmlUpload.module.css';

interface XmlUploadProps {
  pending?: boolean;
  onFilesChange?: (files: File[]) => void;
}

export const XmlUpload = ({ pending, onFilesChange }: XmlUploadProps) => (
  <Row>
    <Col width={6}>
      <Attach
        multiple
        className={cn(styles.attach, styles.attachBlock)}
        size="s"
        buttonContent="Загрузить"
        buttonProps={{
          block: true,
          loading: pending,
        }}
        onChange={(_, { files }) => onFilesChange && onFilesChange(files)}
      />
    </Col>
    <Col width={6}>
      <Typography.Text view="secondary-small" color="secondary">
        Вы можете загрузить один или сразу несколько XML&nbsp;файлов
      </Typography.Text>
    </Col>
  </Row>
);
