import {
  clientMessageError,
  defaultUserData,
  userCredentialsErrors,
  userCredentialsLoginDataError,
} from '../../constants/auth';
import {
  ClientMessageEntity,
  FrontEndType,
  Messages,
  UserCredentialsEntity,
} from './entities';
import { EntityType } from './entityTypes';

import { UserCredentials } from '../../types/user';

export function checkMessageId(messageId: number): never | void {
  if (userCredentialsLoginDataError === messageId) {
    throw new Error(
      'Ошибка авторизации. Некорректно введены имя пользователя/пароль.'
    );
  }

  // Ошибка авторизации может вернуться внутри UserCredentialsEntity или ClientMessageEntity
  if (
    userCredentialsErrors.includes(messageId) ||
    clientMessageError === messageId
  ) {
    throw new Error(`Ошибка авторизации. Код ошибки ${messageId}.`);
  }
}

export function isConnectionError(
  message: ClientMessageEntity | UserCredentialsEntity
) {
  return (
    message.MessageId === Messages.FrontEndDisconnectBecauseOfClientInactivity
  );
}

function getConnectionInfoIndex(fe: FrontEndType) {
  switch (fe) {
    case FrontEndType.AuthAndOperInitServer:
      return 0;
    case FrontEndType.OperServer:
      return 1;
    case FrontEndType.RealTimeBirzInfoServer:
      return 2;
    case FrontEndType.RealTimeBirzInfoDelayedServer:
      return 3;
    case FrontEndType.BirzArchAndMediaServer:
      return 4;
  }

  throw new Error('Неизвестный FrontEndType: ' + fe);
}

export function makeAuthMessage(
  userCredentials: UserCredentials,
  frontEndType: FrontEndType,
  idDevice: string
) {
  const index = getConnectionInfoIndex(frontEndType);

  return {
    frontend: frontEndType,
    isArray: false,
    payload: {
      type: EntityType.AuthorizeWithConnectionKeyEntity,
      data: {
        Login: userCredentials.login,
        ConnectionKey: BigInt(
          userCredentials.zoneConnectionInfos[index].ConnectionKey
        ),
        ProtocolVersion: defaultUserData.ProtocolVersion,
        IdDevice: idDevice,
      },
    },
  };
}
