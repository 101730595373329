import { memo } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { PUBLIC_URL } from '@terminal/core/env';
import { useStore } from '@terminal/core/store';

import { BrokerRecommendationBubble } from './BrokerRecommendationBubble';
import { Message } from './Message';

import { BrokerRecommendation, ChatMessage, ChatMessagesGroup } from '../types';

import styles from './MessagesGroup.module.css';

interface MessagesGroupProps {
  messagesGroup: ChatMessagesGroup;
  onContext: (message: ChatMessage) => void;
  mobile: boolean;
}

export const MessagesGroup = memo(
  ({ messagesGroup, onContext, mobile }: MessagesGroupProps) => {
    const myId = useStore((store) => store.credentials?.idAccount);
    const { idSender, senderNickname } =
      messagesGroup[messagesGroup.length - 1];

    const isMe = idSender === myId;

    return (
      <div className={styles.group}>
        {!isMe && (
          <>
            <div className={styles.avatar}>
              <img
                src={`${PUBLIC_URL}/chatAvatar.svg`}
                alt="Аватарка пользователя"
              />
            </div>
            <div className={styles.author}>
              <Typography.Text
                view={mobile ? 'secondary-medium' : 'secondary-small'}
                color="secondary"
              >
                {senderNickname}
              </Typography.Text>
            </div>
          </>
        )}
        <div className={styles.messages}>
          {messagesGroup.map((message, index) => (
            <div key={`${index}-${message.idChatMessage}`}>
              <Message
                key={'message-' + message.idChatMessage}
                mobile={mobile}
                isMe={isMe}
                message={message}
                onContext={onContext}
              />
              {message.brokerRecommendations?.map(
                (recommendation: BrokerRecommendation) => (
                  <BrokerRecommendationBubble
                    key={
                      'recommendation-' + recommendation.idBrokerRecommendation
                    }
                    mobile={mobile}
                    isMe={isMe}
                    recommendation={recommendation}
                  />
                )
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
);
