import { v4 as uuidv4 } from 'uuid';

import { IS_DEVELOPMENT } from '../../env';
import { FrontEndType, MessageUnitedType } from '../client/entities';
import { EntityType } from '../client/entityTypes';
import AdirClientService from '../client/service';

export interface SubscribeOptions {
  entity: EntityType;
  fi: number[];
  frontend?: FrontEndType;
}

export interface SubscribeReturnData<T = {}> {
  uuid: string;
  subscribeHandler?: (data: MessageUnitedType<T>) => void;
}

export function makeKey(entity: string, fi: number): string {
  return `${entity}-${fi}`;
}

class Streaming extends AdirClientService {
  // Стор, хранящий информацию на что подписаны конкретные (uuid) виджеты
  // значение - массив с uuid, контролирующий количество подписчиков
  // ключ - генерируется в утилите makeKey(entity: string, fi: number): string
  // export const storage: Record<string, string[]> = {};
  storage: Record<string, string[]> = {};

  // отправляет при необходимости сигнал серверу присылать данные по связке entity fi
  // в обработчик отправляет унифицированный в массив ответ сервера
  // возвращает объект, который необходимо передавать в unsubscribe
  subscribe<T extends object = {}>(
    { entity, fi, frontend }: SubscribeOptions,
    callback?: (data: MessageUnitedType<T>) => void
  ): SubscribeReturnData<T> {
    const frontEndType = frontend ?? FrontEndType.RealTimeBirzInfoServer;

    // фильтруем уже подписанные fi
    // чтобы отправлять сигнал подписки только по не подписанным
    const fiToSubscribe = fi.filter(
      (fiItem) => !this.storage[makeKey(entity, fiItem)]
    );

    // генерируем сигнал подписки
    // если есть новые entity+fi
    if (fi.length === 0 || fiToSubscribe.length) {
      this.client.subscribe(frontEndType, entity, fiToSubscribe);
    }

    if (callback) {
      this.addClientListener(entity, callback);
    }

    const uuid = uuidv4();

    // для надёжности сохраняем не просто числовой счётчик подписок entity+fi
    // а массив с генерируемыми uuid на каждую подписку
    // чтобы в дальнейшем контролировать какие компоненты были подписаны
    fi.forEach((fiItem) => {
      const key = makeKey(entity, fiItem);

      if (this.storage[key]) {
        this.storage[key].push(uuid);
      } else {
        this.storage[key] = [uuid];
      }
    });

    // возвращаем uuid и обработчик
    // который необходимо вернуть в unsubscribe
    return { uuid, subscribeHandler: callback };
  }

  // уменьшает счётчик подписок по uuid
  // при необходимости отправляет сигнал о прекращении присылки данных с сервера
  unsubscribe<T>(
    { fi, frontend, entity }: SubscribeOptions,
    subscribeReturnData: SubscribeReturnData<T>
  ) {
    const frontEndType = frontend ?? FrontEndType.RealTimeBirzInfoServer;
    const { uuid, subscribeHandler } = subscribeReturnData || {};
    const fiToRemove: number[] = [];

    // уменьшаем массив подписанных uuid
    // удаляем ключ entity+fi если это последний uuid в массиве и добавляем fi в массив на отписку от сервера
    fi.forEach((fiItem) => {
      const key = makeKey(entity, fiItem);

      if (!this.storage[key]) {
        if (IS_DEVELOPMENT) {
          // eslint-disable-next-line no-console
          console.warn(
            `already removed fi=${fiItem} from ${entity} send to unsubscribe`
          );
        }

        fiToRemove.push(fiItem);

        return;
      }

      const newStorageItem = this.storage[key].filter(
        (savedUUID) => savedUUID !== uuid
      );

      // последний uuid, удаляем ключ
      if (newStorageItem.length === 0) {
        fiToRemove.push(fiItem);

        delete this.storage[key];

        // уменьшаем массив подписанных uuid
      } else {
        this.storage[key] = newStorageItem;
      }
    });

    // отправляем сигнал на отписку от получения новых данных
    // если больше нет виджетов, нуждающихся в этом entity+fi
    if (fi.length === 0 || fiToRemove.length !== 0) {
      this.client.unsubscribe(frontEndType, entity, fiToRemove);
    }

    // так же удаляем слушателя на entity,
    // чтобы не вызывать мёртвые функции при новых сообщениях с другими fi
    if (subscribeHandler) {
      this.removeClientListener(entity, subscribeHandler);
    }
  }
  getDebugInfo(): Map<string, number> {
    const result = new Map<string, number>();

    for (const [k, v] of Object.entries(this.storage)) {
      result.set(
        k,
        v.reduce((acc) => acc + 1, 0)
      );
    }

    return result;
  }
}

export const StreamingService = new Streaming();
export const { subscribe, unsubscribe } = StreamingService;
