import cn from 'classnames';
import {
  format,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
// eslint-disable-next-line no-restricted-imports
import { CalendarInputProps } from '@alfalab/core-components/calendar-input';
import { CalendarRange } from '@alfalab/core-components/calendar-range';
import { IconButton } from '@alfalab/core-components/icon-button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import { ToolsMIcon } from '@alfalab/icons-glyph/ToolsMIcon';

import {
  useAlfaDirectContext,
  useBalance,
  useComputedPositions,
  usePositions,
} from '@terminal/alfadirect/hooks';
import { Dropdown } from '@terminal/common/components/Dropdown';
import { currenciesFI } from '@terminal/core/constants/FIModal';
import { ChartPieLineMIcon } from '@terminal/core/constants/icons';
import { MINORITY } from '@terminal/core/constants/ui';
import { useOnClickOutside } from '@terminal/core/hooks';
import { quoteLastSelector } from '@terminal/core/lib/domain/quoteSelector';
import { getStringDate } from '@terminal/core/lib/format';
import { PortfolioAnalyticsResult } from '@terminal/core/types/account';
import {
  DateFilterType,
  DateFilterValue,
  FiltersNameMap,
} from '@terminal/core/types/operation';
import { SubAccountRazdelItem } from '@terminal/core/types/subAccountRazdel';

import { AccountPicker } from '../../../../features/AccountPicker';
import { useWidgetContext } from '../../../../shared';

import { usePortfolioAnalytics } from '../../hooks/usePortfolioAnalytics';

import styles from './PortfolioCost.module.css';

const TODAY = startOfDay(new Date());
const defaultDateFilter: DateFilterValue = {
  type: DateFilterType['YESTERDAY'],
  valueFrom: {
    value: getStringDate(subDays(TODAY, 1)),
    date: subDays(TODAY, 1).getTime(),
  },
  valueTo: {
    value: getStringDate(TODAY),
    date: TODAY.getTime(),
  },
};

interface Props {
  onClose: () => void;
  subAccounts: Record<string | number, SubAccountRazdelItem[]>;
}

const currencyOptions = [
  { key: 'RUR', content: 'в рублях', value: 'RUR' as CurrencyCodes },
  { key: 'USD', content: 'в долларах', value: 'USD' as CurrencyCodes },
  { key: 'EUR', content: 'в евро', value: 'EUR' as CurrencyCodes },
];

export const PortfolioCost = ({ onClose, subAccounts }: Props) => {
  const currencyDropdown = useRef<HTMLDivElement>(null);
  const isFirstOpening = useRef(true);

  useOnClickOutside(currencyDropdown, () => setCurrencyOpen(false));

  const { subGTAccounts, useQuotes, useFinInfoExt } = useAlfaDirectContext();
  const { DropdownComponent } = useWidgetContext();
  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);

  const currenciesQuotes = useQuotes(currenciesFI, {
    selector: quoteLastSelector,
  });

  const [currencyOpen, setCurrencyOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(currencyOptions[0]);
  const [dateFilter, setDateFilter] = useState<DateFilterValue | null>(
    defaultDateFilter
  );

  const [selectedSubAccounts, setSelectedSubAccounts] = useState<string[]>([
    Object.keys(subAccounts)[0],
  ]);

  const selectedAccountId = subAccounts[selectedSubAccounts[0]]?.[0]?.idAccount;

  const positions = usePositions();
  const computedPositions = useComputedPositions(
    selectedSubAccounts,
    positions
  );

  const accountBalances = useBalance(
    {
      positions: computedPositions,
      currenciesQuotes,
      currenciesFinInfoExts,
      subGTAccounts,
    },
    { selectedSubAccounts }
  );

  const amountOfPortfolio = useMemo(() => {
    switch (selectedCurrency.value) {
      case 'USD':
        return accountBalances[0]?.balanceUsd ?? 0;
      case 'EUR':
        return accountBalances[0]?.balanceEur ?? 0;

      default:
        return accountBalances[0]?.balance ?? 0;
    }
  }, [accountBalances, selectedCurrency.value]);

  const {
    data: portfolioAnalytics,
    mutateAsync: getPortfolioAnalytics,
    isLoading,
    isError,
    isSuccess,
  } = usePortfolioAnalytics();

  useEffect(() => {
    if (
      selectedAccountId &&
      dateFilter?.valueFrom?.date &&
      dateFilter?.valueTo?.date &&
      isFirstOpening.current
    ) {
      getPortfolioAnalytics({
        accountId: selectedAccountId,
        dateFrom: format(dateFilter?.valueFrom?.date, 'yyyy-MM-dd'),
        dateTo: format(dateFilter?.valueTo?.date, 'yyyy-MM-dd'),
      });
      isFirstOpening.current = false;
    }
  }, [
    dateFilter?.valueFrom?.date,
    dateFilter?.valueTo?.date,
    getPortfolioAnalytics,
    selectedAccountId,
  ]);

  const saveFilter = (
    type: DateFilterType,
    dateFrom?: number | null,
    dateTo?: number | null,
    from?: string,
    to?: string
  ) => {
    let valueTo = dateFilter?.valueTo,
      valueFrom = dateFilter?.valueFrom;
    const currentDate = startOfDay(new Date());

    switch (type) {
      case DateFilterType.YESTERDAY:
        valueFrom = {
          value: getStringDate(subDays(currentDate, 1)),
          date: subDays(currentDate, 1).getTime(),
        };
        valueTo = {
          value: getStringDate(currentDate),
          date: currentDate.getTime(),
        };
        break;
      case DateFilterType.WEEK:
        valueFrom = {
          value: getStringDate(subWeeks(currentDate, 1)),
          date: subWeeks(currentDate, 1).getTime(),
        };
        valueTo = {
          value: getStringDate(currentDate),
          date: currentDate.getTime(),
        };
        break;
      case DateFilterType['30DAYS']:
        valueFrom = {
          value: getStringDate(subMonths(currentDate, 1)),
          date: subMonths(currentDate, 1).getTime(),
        };
        valueTo = {
          value: getStringDate(currentDate),
          date: currentDate.getTime(),
        };
        break;
      case DateFilterType.YEAR:
        valueFrom = {
          value: getStringDate(subYears(currentDate, 1)),
          date: subYears(currentDate, 1).getTime(),
        };
        valueTo = {
          value: getStringDate(currentDate),
          date: currentDate.getTime(),
        };
        break;
      case DateFilterType.RANGE:
        if (dateTo) {
          valueTo = { value: to, date: dateTo };
        } else {
          valueTo = { value: to };
        }

        if (dateFrom) {
          valueFrom = { value: from, date: dateFrom };
        } else {
          valueFrom = { value: from };
        }

        break;
      default:
        setDateFilter(null);

        return;
    }

    setDateFilter({ type, valueTo, valueFrom });
  };

  const renderedFilters = () =>
    [
      DateFilterType.YESTERDAY,
      DateFilterType.WEEK,
      DateFilterType['30DAYS'],
      DateFilterType.YEAR,
      DateFilterType.RANGE,
    ].map((type) => {
      const checked = type === dateFilter?.type;

      return (
        <Tag
          checked={checked}
          key={type}
          onClick={() => {
            if (checked) {
              saveFilter(DateFilterType.NONE);
            } else {
              saveFilter(type);
            }
          }}
          size="xs"
          view="filled"
        >
          {FiltersNameMap.get(type)}
        </Tag>
      );
    });

  const getInputProps = (inputType: 'from' | 'to'): CalendarInputProps => ({
    size: 's',
    calendarProps: {
      minDate: inputType === 'to' ? dateFilter?.valueFrom?.date : undefined,
      maxDate:
        inputType === 'from'
          ? dateFilter?.valueTo?.date ?? TODAY.getTime()
          : TODAY.getTime(),
      selectedFrom: dateFilter?.valueFrom?.date,
      selectedTo: dateFilter?.valueTo?.date,
    },
  });

  const portfolioAnalyticsHandler = useCallback(() => {
    if (
      selectedAccountId &&
      dateFilter?.valueFrom?.date &&
      dateFilter?.valueTo?.date
    ) {
      getPortfolioAnalytics({
        accountId: selectedAccountId,
        dateFrom: format(dateFilter?.valueFrom?.date, 'yyyy-MM-dd'),
        dateTo: format(dateFilter?.valueTo?.date, 'yyyy-MM-dd'),
      });
    }
  }, [
    dateFilter?.valueFrom?.date,
    dateFilter?.valueTo?.date,
    getPortfolioAnalytics,
    selectedAccountId,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Text view="secondary-large" weight="bold">
          Доходность портфеля
        </Typography.Text>
        <IconButton
          size="xs"
          view="secondary"
          icon={CrossMIcon}
          onClick={onClose}
        />
      </div>
      <div className={styles.content}>
        <span className={styles.accountLabel}>Счёт</span>
        <AccountPicker
          selectedSubAccounts={selectedSubAccounts}
          onSubAccountSelect={(subAccount) =>
            setSelectedSubAccounts([subAccount])
          }
        />
        <div className={styles.costWrapper}>
          <Typography.Text view="secondary-large" className={styles.costName}>
            Стоимость
            <div ref={currencyDropdown}>
              <div
                onClick={() => setCurrencyOpen((prev) => !prev)}
                className={styles.currencyName}
              >
                <Typography.Text view="secondary-large">
                  {selectedCurrency.content}
                </Typography.Text>
                <ChevronDownCompactMIcon
                  height={16}
                  width={16}
                  color="currentColor"
                  className={cn(
                    styles.chevron,
                    currencyOpen && styles.chevronUp
                  )}
                />
              </div>
              <DropdownComponent
                anchorElement={currencyDropdown.current}
                open={currencyOpen}
                offset={[0, 4]}
                position="bottom"
                headerDivider={false}
              >
                {currencyOptions.map((currency) => {
                  const isSelected = currency.key === selectedCurrency.key;

                  return (
                    <Dropdown.Item
                      key={currency.key}
                      content={
                        <div className={styles.dropDownItemContent}>
                          {currency.content}
                        </div>
                      }
                      onClick={() => setSelectedCurrency(currency)}
                      rightIcon={
                        isSelected ? (
                          <CheckmarkMIcon height={18} width={18} />
                        ) : undefined
                      }
                    />
                  );
                })}
              </DropdownComponent>
            </div>
          </Typography.Text>
          <Typography.Text view="primary-large" weight="bold">
            <Amount
              value={amountOfPortfolio * MINORITY}
              currency={selectedCurrency.value}
              minority={MINORITY}
            />
          </Typography.Text>
        </div>
        <div className={styles.tagWrapper}>{renderedFilters()}</div>
        <CalendarRange
          className={styles.datePicker}
          valueTo={dateFilter?.valueTo?.value}
          valueFrom={dateFilter?.valueFrom?.value}
          defaultMonth={startOfMonth(new Date()).getTime()}
          calendarPosition="popover"
          onChange={({ dateFrom, dateTo, valueFrom, valueTo }) => {
            if (
              !(
                dateFilter?.valueFrom?.value === valueFrom &&
                dateFilter?.valueTo?.value === valueTo
              )
            ) {
              saveFilter(
                DateFilterType.RANGE,
                dateFrom,
                dateTo,
                valueFrom,
                valueTo
              );
            }
          }}
          inputFromProps={getInputProps('from')}
          inputToProps={getInputProps('to')}
        />
        <Button
          className={styles.customButton}
          size="xxs"
          view="secondary"
          disabled={
            !(dateFilter?.valueFrom?.date && dateFilter?.valueTo?.date) ||
            !selectedAccountId
          }
          loading={isLoading}
          block
          onClick={portfolioAnalyticsHandler}
        >
          Сформировать отчет
        </Button>
        <Report
          isError={isError}
          isSuccess={isSuccess}
          report={portfolioAnalytics}
          onRetry={portfolioAnalyticsHandler}
        />
      </div>
    </div>
  );
};

interface ReportProps {
  isError: boolean;
  isSuccess: boolean;
  onRetry: () => void;
  report?: PortfolioAnalyticsResult;
}

const Report = ({ isError, isSuccess, onRetry, report }: ReportProps) => {
  if (isError) {
    return (
      <div className={styles.emptyWrapper}>
        <SuperEllipse size={48}>
          <ToolsMIcon />
        </SuperEllipse>
        <Typography.Text view="primary-small" weight="bold" color="secondary">
          Не получилось загрузить отчет
        </Typography.Text>
        <Typography.Text view="secondary-small" color="secondary">
          Уже знаем, в чём дело, и чиним.
          <br />
          Попробуйте обновить или зайти позже
        </Typography.Text>
        <Button
          onClick={onRetry}
          size="xxs"
          block={false}
          className={styles.emptyWrapperButton}
        >
          Повторить
        </Button>
      </div>
    );
  } else if (isSuccess && !report) {
    return (
      <div className={styles.emptyWrapper}>
        <SuperEllipse size={48}>
          <ChartPieLineMIcon />
        </SuperEllipse>
        <Typography.Text view="primary-small" weight="bold" color="secondary">
          Нет данных
        </Typography.Text>
        <Typography.Text view="secondary-small" color="secondary">
          На вашем счёте нет активов
        </Typography.Text>
      </div>
    );
  } else if (isSuccess && report) {
    const {
      portfolioRevenue,
      commissions,
      marginTrade,
      taxes,
      beginNAV,
      moneyInputsTotal,
      moneyOutputsTotal,
      returnOnAssets,
    } = report;

    // Валовый доход
    const valValue =
      (portfolioRevenue?.value ?? 0) +
      (commissions?.value ? Math.abs(commissions?.value) : 0) +
      (marginTrade?.value ? Math.abs(marginTrade?.value) : 0) +
      (taxes?.value ? taxes?.value : 0);

    // Все расходы
    const allTaxes =
      (commissions?.value ? commissions?.value : 0) +
      (marginTrade?.value ? marginTrade?.value : 0) +
      (taxes?.value ? taxes?.value : 0);

    return (
      <div className={styles.reportWrapper}>
        <Typography.Text
          view="primary-medium"
          weight="bold"
          className={styles.reportGropuName}
        >
          Финансовый результат
        </Typography.Text>
        <div className={styles.reportValue}>
          <div className={styles.reportValueName}>
            <Typography.Text view="secondary-large">
              Валовый доход
            </Typography.Text>
            <Tooltip
              targetClassName={styles.tooltipTarget}
              contentClassName={styles.tooltipContent}
              content="Заработок за период без учета расходов"
              trigger="hover"
              position="top"
            >
              <InformationCircleLineSIcon
                height={12}
                width={12}
                color="var(--color-light-graphic-secondary)"
              />
            </Tooltip>
          </div>
          <div className={styles.reportValueGroup}>
            {portfolioRevenue ? (
              <Typography.Text view="secondary-large">
                <Amount.Pure
                  value={valValue * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={portfolioRevenue.currency}
                />
              </Typography.Text>
            ) : (
              '–'
            )}
            {returnOnAssets ? (
              <Typography.Text view="secondary-small">
                <Amount.Pure
                  className={styles.reportValueSecondary}
                  value={returnOnAssets.value * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  rightAddons=" %"
                />
              </Typography.Text>
            ) : (
              '–'
            )}
          </div>
        </div>
        <div className={styles.reportValue}>
          <div className={styles.reportValueName}>
            <Typography.Text view="secondary-large">
              Чистый доход
            </Typography.Text>
            <Tooltip
              targetClassName={styles.tooltipTarget}
              contentClassName={styles.tooltipContent}
              content="Заработок за период с учетом всех расходов. Чистый доход = валовый доход - комиссии - стоимость марж. кредита - налоги"
              trigger="hover"
              position="top"
            >
              <InformationCircleLineSIcon
                height={12}
                width={12}
                color="var(--color-light-graphic-secondary)"
              />
            </Tooltip>
          </div>
          <div className={styles.reportValueGroup}>
            {portfolioRevenue ? (
              <Typography.Text view="secondary-large">
                <Amount.Pure
                  value={portfolioRevenue.value * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={portfolioRevenue.currency}
                />
              </Typography.Text>
            ) : (
              '–'
            )}
          </div>
        </div>
        <Typography.Text
          view="primary-medium"
          weight="bold"
          className={styles.reportGropuName}
        >
          Расходы
        </Typography.Text>
        <div className={styles.reportValue}>
          <div className={styles.reportValueName}>
            <Typography.Text view="secondary-large">
              Всего расходов
            </Typography.Text>
            <Tooltip
              targetClassName={styles.tooltipTarget}
              contentClassName={styles.tooltipContent}
              content="Комиссии + стоимость марж. кредита + налоги"
              trigger="hover"
              position="top"
            >
              <InformationCircleLineSIcon
                height={12}
                width={12}
                color="var(--color-light-graphic-secondary)"
              />
            </Tooltip>
          </div>
          <div className={styles.reportValueGroup}>
            {portfolioRevenue && allTaxes ? (
              <Typography.Text view="secondary-large">
                <Amount.Pure
                  value={allTaxes * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={portfolioRevenue.currency}
                />
              </Typography.Text>
            ) : (
              '–'
            )}
          </div>
        </div>
        <div className={styles.reportValue}>
          <div className={styles.reportValueName}>
            <Typography.Text view="secondary-large">Комиссии</Typography.Text>
          </div>
          <div className={styles.reportValueGroup}>
            {commissions ? (
              <Typography.Text view="secondary-large">
                <Amount.Pure
                  value={commissions.value * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={commissions.currency}
                />
              </Typography.Text>
            ) : (
              '–'
            )}
          </div>
        </div>
        <div className={styles.reportValue}>
          <div className={styles.reportValueName}>
            <Typography.Text view="secondary-large">
              Маржинальное кредитование
            </Typography.Text>
          </div>
          <div className={styles.reportValueGroup}>
            {marginTrade ? (
              <Typography.Text view="secondary-large">
                <Amount.Pure
                  value={marginTrade.value * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={marginTrade.currency}
                />
              </Typography.Text>
            ) : (
              '–'
            )}
          </div>
        </div>
        <div className={styles.reportValue}>
          <div className={styles.reportValueName}>
            <Typography.Text view="secondary-large">Налоги</Typography.Text>
          </div>
          <div className={styles.reportValueGroup}>
            {taxes ? (
              <Typography.Text view="secondary-large">
                <Amount.Pure
                  value={taxes.value * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={taxes.currency}
                />
              </Typography.Text>
            ) : (
              '–'
            )}
          </div>
        </div>
        <Typography.Text
          view="primary-medium"
          weight="bold"
          className={styles.reportGropuName}
        >
          Движение средств
        </Typography.Text>
        <div className={styles.reportValue}>
          <div className={styles.reportValueName}>
            <Typography.Text view="secondary-large">
              Активы на начало периода
            </Typography.Text>
          </div>
          <div className={styles.reportValueGroup}>
            {beginNAV ? (
              <Typography.Text view="secondary-large">
                <Amount.Pure
                  value={beginNAV.value * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={beginNAV.currency}
                />
              </Typography.Text>
            ) : (
              '–'
            )}
          </div>
        </div>
        <div className={styles.reportValue}>
          <div className={styles.reportValueName}>
            <Typography.Text view="secondary-large">Пополнения</Typography.Text>
            <Tooltip
              targetClassName={styles.tooltipTarget}
              contentClassName={styles.tooltipContent}
              content="Сколько денег вы внесли на счет в рублях или валюте"
              trigger="hover"
              position="top"
            >
              <InformationCircleLineSIcon
                height={12}
                width={12}
                color="var(--color-light-graphic-secondary)"
              />
            </Tooltip>
          </div>
          <div className={styles.reportValueGroup}>
            {moneyInputsTotal ? (
              <Typography.Text view="secondary-large">
                <Amount.Pure
                  value={moneyInputsTotal.value * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={moneyInputsTotal.currency}
                />
              </Typography.Text>
            ) : (
              '–'
            )}
          </div>
        </div>
        <div className={styles.reportValue}>
          <div className={styles.reportValueName}>
            <Typography.Text view="secondary-large">Выводы</Typography.Text>
            <Tooltip
              targetClassName={styles.tooltipTarget}
              contentClassName={styles.tooltipContent}
              content="Сколько денег вы вывели со счета в рублях или валюте"
              trigger="hover"
              position="top"
            >
              <InformationCircleLineSIcon
                height={12}
                width={12}
                color="var(--color-light-graphic-secondary)"
              />
            </Tooltip>
          </div>
          <div className={styles.reportValueGroup}>
            {moneyOutputsTotal ? (
              <Typography.Text view="secondary-large">
                <Amount.Pure
                  value={moneyOutputsTotal.value * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={moneyOutputsTotal.currency}
                />
              </Typography.Text>
            ) : (
              '–'
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
