import { Textarea } from '@alfalab/core-components/textarea';

interface ReceiveByMailProps {
  address?: string;
}

export const ReceiveByMail = ({ address }: ReceiveByMailProps) => {
  return (
    <>
      <Textarea
        label="Адрес"
        labelView="outer"
        block
        value={address}
        disabled={true}
        hint="Для изменения почтового адреса, пожалуйста, обратитесь в службу поддержки"
      />
    </>
  );
};
