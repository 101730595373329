import { format } from 'date-fns';
import React, { useEffect, useState, VFC } from 'react';

import { getServerTime } from '@terminal/core/lib/services/time';

export const Clock: VFC = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setTime(getServerTime()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <>{format(time, 'HH:mm:ss')}&nbsp;MSK</>;
};
