import { SecuritiesHistoryStatusCode } from '@terminal/core/lib/rest/lkSecurities';

import styles from './HistoryItem.module.css';

export const SECURITIES_TRANSFER_TYPE_TEXT = {
  EXTRA_IN: 'Зачисление',
  EXTRA_OUT: 'Списание',
  INTRA: 'Внутренний перевод',
};

export const SECURITIES_TRANSFER_STATUS_MAP = {
  [SecuritiesHistoryStatusCode.Submitted]: {
    className: styles.neutral,
  },
  [SecuritiesHistoryStatusCode.Transmitted]: {
    className: styles.neutral,
  },
  [SecuritiesHistoryStatusCode.OrderIsAbsent]: {
    className: styles.attention,
  },
  [SecuritiesHistoryStatusCode.ParametersDiffer]: {
    className: styles.attention,
  },
  [SecuritiesHistoryStatusCode.Executed]: {
    className: styles.positive,
  },
  [SecuritiesHistoryStatusCode.Declined]: {
    className: styles.negative,
  },
  [SecuritiesHistoryStatusCode.Rejected]: {
    className: styles.negative,
  },
};
