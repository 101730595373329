export enum MainMenuType {
  Accounts = 'accounts',
  Documents = 'documents',
  Certificates = 'certificates',
  QualTesting = 'testing',
  CorporateActions = 'corporate-actions',
  FreeOrders = 'free-orders',
  Notifications = 'notifications',
  CodesTable = 'codes-table',
}
