import { makeGetParams } from '../url';
import { callLkApi, fetchLkResult, LKResult } from './lkApi';

export interface Notification {
  id: number;
  subject: string;
  body: string;
  dbTime: string;
  isRead: boolean;
  readTime: string;
}

export interface NotificationsPage {
  notifications: Notification[];
  pageCount: number;
}

export interface NotificationsFilter {
  unreadOnly: boolean;
  startDate: string;
  endDate: string;
}

export interface NotificationsRequest extends NotificationsFilter {
  treaty: number;
  page: number;
  count: number;
}

export async function getNotifications(
  data: NotificationsRequest
): Promise<NotificationsPage> {
  const request = makeGetParams(data);
  const res = await fetchLkResult<NotificationsPage | LKResult>(
    `/api/notifications/list?${request}`
  );
  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export async function setReadNotification(id: number): Promise<void> {
  return await callLkApi(`/api/notifications/set-as-read/${id}`, 'POST');
}

export interface NotificationsCount {
  count: number;
}

export async function getNotificationsCount(): Promise<NotificationsCount> {
  const res = await fetchLkResult<NotificationsCount | LKResult>(
    `/api/notifications/count`
  );
  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}
