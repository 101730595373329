import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';
import { UserStarMIcon } from '@alfalab/icons-glyph/UserStarMIcon';

import { QualStatus } from '@terminal/core/lib/rest/lkAccountInfo';
import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import { SendFreeOrderParams } from '@terminal/core/lib/rest/lkFreeOrders';
import { useStore } from '@terminal/core/store';

import { getQualOrderText } from './getQualOrderText';

import { useSendFreeOrder } from '../hooks/useSendFreeOrder';
import { useSecuritiesManager } from '../securities/hooks';
import { useGetQualPdf, useSetQualInvestor } from './hooks';

import styles from './QualInvestor.module.css';

interface QualInvestorProps {
  qualifiedInvestorDate: string | undefined;
  qualStatus: QualStatus | undefined;
  treaty: number;
  onAccountSave: () => void;
}

const QUAL_INVESTOR_FREE_ORDER_ID = '5';

export const QualTooltipContent = () => {
  return (
    <>
      Достаточно соответствовать любому из перечисленных условий:
      <br />— владеть активами на сумму от 6 млн. рублей;
      <br />— иметь высшее экономическое образование, свидетельство о
      квалификации в сфере финансовых рынков или один из международных
      сертификатов: Chartered Financial Analyst, Certified International
      Investment Analyst, Financial Risk Manager;
      <br />— иметь опыт работы от двух лет, связанный с совершением сделок с
      финансовыми инструментами, подготовкой индивидуальных инвестиционных
      рекомендаций, управлением рисками;
      <br />— совершить сделки с ценными бумагами на сумму от 6 млн. рублей за
      последние четыре квартала в среднем не реже 10 раз в квартал, но не реже
      одного раза в месяц;
      <br />— иметь статус квалифицированного инвестора у другого брокера от
      трех лет.
      <br />
      <br />
      Если вы соответствуете любому из условий, пожалуйста, пришлите
      подтверждающие документы в чат службы поддержки. На проверку документов
      потребуется 1-2 рабочих дня. Когда статус будет присвоен, мы пришлём вам
      push-уведомление.
    </>
  );
};

export const QualInvestor = ({
  qualifiedInvestorDate,
  qualStatus,
  treaty,
  onAccountSave,
}: QualInvestorProps) => {
  const lkSignDialogOpen = useStore((state) => state.lkSignDialogOpen);
  const [pdfDoc, setPdfDoc] = useState<DownloadedFile>();
  const [orderParams, setOrderParams] = useState<SendFreeOrderParams>();
  const { mutateAsync: sendRequest, isLoading } = useSetQualInvestor();
  const { mutateAsync: getPDF, isLoading: isPDFLoading } = useGetQualPdf();
  const { data: requisitesData, isFetching: isRequisitesLoading } =
    useSecuritiesManager(treaty);
  const {
    mutateAsync: sendFreeOrder,
    isLoading: sendingFreeOrder,
    isSuccess,
  } = useSendFreeOrder();

  const getPdfFile = useCallback(async () => {
    const pdf = await getPDF(treaty);

    setPdfDoc(pdf);
  }, [getPDF, treaty]);

  const sendQualRequest = () => {
    void sendRequest({ treaty });
  };

  useEffect(() => {
    if (isSuccess && !lkSignDialogOpen) {
      onAccountSave();
    }
  }, [isSuccess, lkSignDialogOpen, onAccountSave]);

  useEffect(() => {
    if (requisitesData?.managerFullName && requisitesData?.managerDocSerNo) {
      setOrderParams({
        params: {
          themeId: QUAL_INVESTOR_FREE_ORDER_ID,
          treaty,
          text: getQualOrderText(
            requisitesData?.managerFullName,
            requisitesData?.managerDocSerNo
          ),
        },
      });
    }
  }, [
    requisitesData?.managerDocSerNo,
    requisitesData?.managerFullName,
    treaty,
  ]);

  if (isRequisitesLoading) {
    return <Loader />;
  }

  switch (qualStatus) {
    case QualStatus.DEFAULT: {
      return (
        <>
          <Button
            onClick={() => sendFreeOrder(orderParams as SendFreeOrderParams)}
            leftAddons={
              <UserStarMIcon
                width={15}
                height={15}
                fill="var(--color-light-graphic-secondary)"
              />
            }
            size="xxs"
            view="outlined"
            className={styles.entryPointButton}
            loading={sendingFreeOrder}
          >
            Подать заявление на квалификацию
          </Button>
          <Tooltip
            targetClassName={styles.infoLink}
            arrowClassName={styles.tooltipArrowHide}
            contentClassName={styles.tooltipContent}
            content={<QualTooltipContent />}
            trigger="click"
            position="bottom"
          >
            <Link
              underline
              leftAddons={<InformationCircleLineMIcon width="18" height="18" />}
              view="secondary"
            >
              Условия подключения статуса «Квалифицированный инвестор»
            </Link>
          </Tooltip>
        </>
      );
    }
    case QualStatus.IN_PROGRESS: {
      return (
        <>
          <Button
            onClick={sendQualRequest}
            disabled
            leftAddons={
              <UserStarMIcon
                width={15}
                height={15}
                fill="var(--color-light-graphic-secondary)"
              />
            }
            size="s"
            view="outlined"
            className={styles.entryPointButton}
          >
            Подать заявление на квалификацию
          </Button>
          <div className={styles.sentStatus}>
            <CheckmarkMIcon
              width={15}
              height={15}
              fill="var(--color-light-graphic-secondary)"
            />
            <Typography.Text view="secondary-small" weight="medium">
              Заявление отправлено
            </Typography.Text>
          </div>
        </>
      );
    }
    case QualStatus.CANDIDATE: {
      return (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <Link
              onClick={sendQualRequest}
              view="primary"
              leftAddons={
                <UserStarMIcon
                  width={18}
                  height={18}
                  fill="var(--color-light-graphic-secondary)"
                />
              }
            >
              Запросить статус квалифицированного инвестора
            </Link>
          )}
        </>
      );
    }
    case QualStatus.QUAL_INVESTOR: {
      const formattedDate = qualifiedInvestorDate
        ? format(new Date(qualifiedInvestorDate), 'dd.MM.yyyy')
        : null;

      return (
        <>
          <div className={styles.statusContainer}>
            <UserStarMIcon
              width={18}
              height={18}
              fill="var(--color-light-text-attention)"
            />
            <Typography.Text view="secondary-large">
              Квалифицированный инвестор
            </Typography.Text>
          </div>
          <Link
            onClick={getPdfFile}
            className={styles.acceptionLink}
            view="primary"
          >{`Уведомление о признании от ${formattedDate}`}</Link>
          {isPDFLoading && <Loader />}
          {Boolean(pdfDoc) && (
            <Link
              href={pdfDoc?.url || ''}
              leftAddons={<OutsideMIcon width={14} height={14} />}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.acceptionLink}
              view="primary"
            >
              Открыть
            </Link>
          )}
        </>
      );
    }

    default:
      return null;
  }
};
