import React, { useEffect } from 'react';
import { Button } from '@alfalab/core-components/button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ReplacementMIcon } from '@alfalab/icons-glyph/ReplacementMIcon';
import { SquareAcademicCapMIcon } from '@alfalab/icons-glyph/SquareAcademicCapMIcon';

import {
  RiskProfile,
  UpdateRiskProfileResult,
} from '@terminal/core/lib/rest/lkRiskProfile';
import { useStore } from '@terminal/core/store';

import { useConfirmProfile } from './hooks';

import styles from './RiskProfile.module.css';

interface RiskProfileDetailsProps {
  profile: RiskProfile | (UpdateRiskProfileResult & { accepted: boolean });
  openDocument: () => void;
  openTestModal: () => void;
  treaty: number;
  refetchProfile: () => void;
  isLoading: boolean;
}

export const RiskProfileDetails = ({
  profile,
  openDocument,
  openTestModal,
  refetchProfile,
  isLoading,
}: RiskProfileDetailsProps) => {
  const lkSignDialogOpen = useStore((state) => state.lkSignDialogOpen);
  const {
    isLoading: isConfirming,
    mutateAsync: confirmRiskProfile,
    isSuccess,
  } = useConfirmProfile();

  useEffect(() => {
    if (isSuccess && !lkSignDialogOpen) {
      void refetchProfile();
    }
  }, [isSuccess, lkSignDialogOpen, refetchProfile]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.detailsContainer}>
      <SuperEllipse className={styles.detailsIcon} size={64}>
        <SquareAcademicCapMIcon />
      </SuperEllipse>
      <Typography.Text
        className={styles.detailsTitle}
        view="primary-large"
        weight="bold"
      >{`Ваш тип инвестора - "${profile.profileTitle}"`}</Typography.Text>
      <Typography.Text
        className={styles.detailsDescription}
        view="secondary-large"
        color="secondary"
      >
        {profile.profileDescription}
      </Typography.Text>
      {!profile.accepted && (
        <Typography.Text
          className={styles.detailsAcceptText}
          view="secondary-large"
          weight="bold"
          color="secondary"
        >
          Чтобы иметь возможность получать индивидуальные инвестиционные
          рекомендации, подтвердите свой риск-профиль
        </Typography.Text>
      )}
      <div className={styles.entryPointButtonsBlock}>
        <Button
          onClick={openTestModal}
          leftAddons={<ReplacementMIcon width={15} height={15} />}
          size="xxs"
          view="outlined"
          className={styles.entryPointButton}
        >
          Пройти тест заново
        </Button>
        {!profile.accepted && (
          <Button
            leftAddons={<CheckmarkMIcon width={15} height={15} />}
            size="xxs"
            view="secondary"
            className={styles.entryPointButton}
            loading={isConfirming}
            onClick={() => confirmRiskProfile()}
          >
            Подтвердить
          </Button>
        )}
      </div>
      {!profile.accepted && (
        <Typography.Text
          className={styles.detailsFooterText}
          view="secondary-small"
          color="secondary"
        >
          Нажимая “Подтвердить”, вы соглашаетесь с условиями получения
          рекомендации,{' '}
          <Link onClick={openDocument}>изложенными в документе</Link>
        </Typography.Text>
      )}
    </div>
  );
};
