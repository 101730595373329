import cn from 'classnames';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronForwardMIcon } from '@alfalab/icons-glyph/ChevronForwardMIcon';

import styles from './RequisitesListItem.module.css';

interface RequisitesListItemProps {
  name: string;
  code?: string;
  className?: string;
  onClick?: () => void;
}

export const RequisitesListItem = ({
  name,
  code,
  className,
  onClick,
}: RequisitesListItemProps) => (
  <div className={cn(styles.item, className)} onClick={onClick}>
    <div className={styles.content}>
      <Typography.Text view="secondary-large" className={styles.name}>
        {name || 'Alfa-Bank'}
      </Typography.Text>
      {!!code && (
        <Typography.Text view="secondary-large" className={styles.code}>
          {code === 'RUR' ? 'RUB' : code}
        </Typography.Text>
      )}
    </div>
    <div className={styles.rightAddon}>
      <ChevronForwardMIcon className={styles.arrow} width={18} height={18} />
    </div>
  </div>
);
