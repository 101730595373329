import { Location } from 'history';

import { IS_MOBILE_PWA } from '@terminal/core/env';

export const isPassportPath = (location: Location) => {
  // кейс для десктопа АИ
  if (location.pathname === '/desktop/passport') {
    return true;
  }

  // TODO убрать после переносв компонента в pwa
  // @ts-ignore
  return location.pathname === (IS_MOBILE_PWA ? '/auth/passport' : '/passport');
};
