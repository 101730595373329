import { useMemo } from 'react';
import { Row } from 'react-table';
import { useLatest } from 'react-use';
import { CurrencyCodes } from '@alfalab/core-components/amount';

import { Table } from '@terminal/common/ui/Table';
import { getStepDecimals } from '@terminal/core/lib/format';
import { OrderItem } from '@terminal/core/types/order';

import { useWidgetContext } from '../../../../shared';
import { getOrderBookColumns } from '../../../../shared/components/getOrderBookColumns';

import styles from './OrdersTable.module.css';

interface OrdersTableProps {
  orders: OrderItem[];
  currencyCode?: CurrencyCodes;
  priceStep?: number;
  lot?: number;
  isBond: boolean;
  onCancelOrder?: (order: OrderItem) => void;
  onEditOrder?: (order: OrderItem) => void;
  fromInstructions?: boolean;
  onRowClick?: (origin: OrderItem) => void;
}

export const OrdersTable = ({
  orders,
  currencyCode,
  priceStep,
  lot,
  isBond,
  onCancelOrder,
  onEditOrder,
  fromInstructions,
  onRowClick,
}: OrdersTableProps) => {
  const { useSettings } = useWidgetContext();
  const settings = useSettings();
  const orderDimension = settings.defaultValues.orderDimension;
  const { priceDecimals } = getStepDecimals(priceStep);

  const onCancelOrderLatest = useLatest(onCancelOrder);
  const onEditOrderLatest = useLatest(onEditOrder);

  const columns = useMemo(
    () =>
      getOrderBookColumns(
        priceDecimals,
        orderDimension,
        lot,
        isBond,
        currencyCode,
        onCancelOrderLatest.current,
        onEditOrderLatest.current,
        undefined,
        fromInstructions
      ),
    [
      orderDimension,
      priceDecimals,
      lot,
      isBond,
      currencyCode,
      onCancelOrderLatest,
      onEditOrderLatest,
      fromInstructions,
    ]
  );

  return (
    <Table
      columns={columns}
      data={orders}
      tableClassname={styles.infoTable}
      wrapperClassname={styles.infoTableWrapper}
      getRowProps={(row) => {
        const { getRowProps, original } = row as Row<OrderItem>;

        return {
          key: getRowProps().key,
          onClick: () => onRowClick?.(original),
        };
      }}
    />
  );
};
