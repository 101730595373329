import { BrokerRecommendationEntity } from '@terminal/core/lib/client/entities';

import { BrokerRecommendation } from '../types';

export const brokerRecommendationMap = (
  entity: BrokerRecommendationEntity
): BrokerRecommendation => ({
  version: entity.Version?.toString() ?? '',
  operation: entity.Operation,
  idBrokerRecommendation: entity.IdBrokerRecommendation,
  acceptTime: entity.AcceptTime,
  idClientStrategy: entity.IdClientStrategy,
  idFi: entity.IdFi,
  idMarketBoard: entity.IdMarketBoard,
  buySell: entity.BuySell,
  idOrderType: entity.IdOrderType,
  price: entity.Price,
  quantity: entity.Quantity,
  idLifeTime: entity.IdLifeTime,
  comment: entity.Comment,
  flags: entity.Flags,
  idChatGroup: entity.IdChatGroup,
});
