import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';

import { SignOrderStatus } from '@terminal/core/lib/rest/lkSignOperation';

import { ALFABANK_CONTRAGENT_NAME } from '../../shared';

import styles from './TransferResult.module.css';

interface TransferResultProps {
  contragentName?: string | null;
  orderStatus?: SignOrderStatus | null;
  multiple?: boolean;
  onAction?: () => void;
  actionLabel?: string;
  content?: React.ReactNode;
}

export const TransferResult = ({
  contragentName,
  orderStatus,
  multiple,
  onAction,
  actionLabel = 'Подать новое поручение',
  content = null,
}: TransferResultProps) => {
  let text: React.ReactNode = content;

  if (orderStatus === 'separated') {
    if (multiple) {
      text = (
        <>
          Предоставьте подтверждающие документы в&nbsp;офис Go&nbsp;Invest
          по&nbsp;адресу: 125009, Москва, Тверской бульвар, д.&nbsp;26.
          Подробнее можно уточнить по&nbsp;тел:
          8&nbsp;800&nbsp;101&nbsp;41&nbsp;01
        </>
      );
    } else {
      text = (
        <>
          Ценные бумаги будут зачислены на&nbsp;обособленный счёт. Требование
          обособленного учёта установлено Указом Президента Российской Федерации
          №&nbsp;138 от&nbsp;03.03.2023 и&nbsp;письмом Банка России
          от&nbsp;17.03.2023 №&nbsp;018-34/220.
          <br />
          <br />
          Передайте, пожалуйста, брокерский отчёт по&nbsp;бумагам, которые
          вы&nbsp;переводите в&nbsp;Go&nbsp;Invest, и&nbsp;выписку
          о&nbsp;движении этих ценных бумаг. Отчёт и&nbsp;выписка нужны
          с&nbsp;даты покупки такой бумаги до&nbsp;даты её&nbsp;перевода
          в&nbsp;Go&nbsp;Invest.
          <br />
          <br />
          Если по&nbsp;ценным бумагам у&nbsp;вас есть договоры купли-продажи,
          дарения или&nbsp;свидетельство о&nbsp;праве наследования, их&nbsp;тоже
          нужно передать.
          <br />
          Оригиналы или заверенные копии документов нужно передать в&nbsp;офис
          Go&nbsp;Invest по&nbsp;адресу: 125009, Москва, Тверской бульвар,
          д.&nbsp;26.
          <br />
          <br />
          На основании этих документов можно будет подтвердить, что&nbsp;бумаги
          приобретены у&nbsp;резидентов или&nbsp;нерезидентов
          из&nbsp;дружественных стран. После этого бумаги будут переведены
          на&nbsp;торговый счёт, и&nbsp;вы&nbsp;сможете свободно ими торговать.
        </>
      );
    }
  } else if (contragentName === ALFABANK_CONTRAGENT_NAME) {
    text =
      'XML файлы встречных поручений доступны для скачивания в Переводах ценных бумаг. Вкладка История';
  } else if (contragentName) {
    text = (
      <>
        Реквизиты для&nbsp;заполнения встречного поручения доступны
        для&nbsp;скачивания в&nbsp;Истории
      </>
    );
  }

  return (
    <div className={styles.container}>
      {text && (
        <Typography.Text
          view="secondary-small"
          color="secondary"
          defaultMargins={false}
          className={styles.text}
          tag="p"
        >
          {text}
        </Typography.Text>
      )}
      <div className={styles.submit}>
        <Button block size="s" onClick={onAction}>
          {actionLabel}
        </Button>
      </div>
    </div>
  );
};
