import { OrderType } from '../../client/entities';

import { BracketOrderParams } from '../../../types/trading';
import { StopOrderType } from '../../../types/ui';

interface Props {
  slPrice?: number;
  slOrderType?: StopOrderType | OrderType.TRS;
  slSlippagePrice?: number;
  tpPrice?: number;
  idOrderType?: OrderType;
  limitLevelAlternative?: number;
}

export const getBracketOrderParams = ({
  slPrice,
  tpPrice,
  slOrderType,
  slSlippagePrice,
  idOrderType,
  limitLevelAlternative,
}: Props): BracketOrderParams => {
  let linkedOrderType = OrderType.None;

  if (idOrderType) {
    linkedOrderType = idOrderType;
  } else if (slPrice && tpPrice) {
    if (slOrderType === OrderType.STP) {
      linkedOrderType = OrderType.BRS;
    } else if (slOrderType === OrderType.TRS) {
      linkedOrderType = OrderType.TBRS;
    } else if (slOrderType === OrderType.STL) {
      linkedOrderType = OrderType.BSL;
    }
  } else if (slPrice && !tpPrice) {
    if (!slOrderType) {
      throw new Error('Не указан тип заявки стоп-лосс');
    }

    linkedOrderType = slOrderType;
  } else if (!slPrice && tpPrice) {
    linkedOrderType = OrderType.LMT;
  }

  let limitPrice: number | undefined = tpPrice ?? undefined;

  let stopPrice: number | undefined = slPrice ?? undefined;

  let limitLevelAlternativeResult: number | undefined =
    limitLevelAlternative ?? slSlippagePrice;

  return {
    idOrderType: linkedOrderType,
    limitPrice,
    stopPrice,
    limitLevelAlternative: limitLevelAlternativeResult,
  };
};
