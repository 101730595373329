import { addDays, getTime, startOfDay } from 'date-fns';
import { Form, FormikConfig, FormikProvider, useFormik } from 'formik';
import { Input } from 'formik-alfalab-core-components/Input';
import { MaskedInput } from 'formik-alfalab-core-components/MaskedInput';
import { Select } from 'formik-alfalab-core-components/Select';
import { FC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { CalendarDesktop } from '@alfalab/core-components/calendar/desktop';
import { Divider } from '@alfalab/core-components/divider';
import { Grid } from '@alfalab/core-components/grid';
import { Link } from '@alfalab/core-components/link';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { PenNibMIcon } from '@alfalab/icons-glyph/PenNibMIcon';

import { UniversalDateInput } from '@terminal/lk/shared';

import { IDENTITY_DOCUMENT_NUMBER_MASK } from '../../lib/const/identityDocumentNumberMask';
import {
  ACCESS_LEVELS_MAP,
  IDENTITY_DOCUMENT_TYPE_MAP,
} from '../../lib/const/mappings';
import { CreateAttorneyFormValues, IdentityDocumentType } from '../../model';
import { validate } from './validators';

import styles from './AttorneyForm.module.css';

const identityDocumentTypesOptions = Object.entries(
  IDENTITY_DOCUMENT_TYPE_MAP
).map(([key, content]) => ({
  key,
  value: key,
  content,
}));

const accessLevelsOptions = Object.entries(ACCESS_LEVELS_MAP).map(
  ([key, content]) => ({
    key,
    value: key,
    content,
  })
);

interface AttorneyFormProps {
  treaty: number;
  onAccessLevelsClick: () => void;
  onSubmit: FormikConfig<CreateAttorneyFormValues>['onSubmit'];
  disabled?: boolean;
}

export const AttorneyForm: FC<AttorneyFormProps> = ({
  treaty,
  onAccessLevelsClick,
  onSubmit,
  disabled,
}) => {
  const form = useFormik<CreateAttorneyFormValues>({
    initialValues: {
      fullName: '',
      login: '',
      identityDocumentType: '',
      identityDocumentNumber: '',
      accessLevel: '',
      endDate: '',
    },
    onSubmit,
    validate,
  });

  return (
    <>
      <Typography.Text
        view="primary-small"
        weight="bold"
        className={styles.title}
      >
        Оформление доверенности на управление счётом {treaty}
      </Typography.Text>
      <FormikProvider value={form}>
        <Form>
          <Space size={16} direction="vertical" fullWidth>
            <Grid.Row gutter={16}>
              <Grid.Col width="6">
                <Input
                  name="fullName"
                  label="Ф.И.О. уполномоченного лица"
                  placeholder="Введите Ф.И.О."
                  labelView="outer"
                  block
                  size="s"
                />
              </Grid.Col>
              <Grid.Col width="6">
                <Input
                  name="login"
                  label="Логин уполномоченного лица"
                  placeholder="Введите логин"
                  labelView="outer"
                  block
                  size="s"
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row gutter={16}>
              <Grid.Col width="6">
                <Select
                  name="identityDocumentType"
                  type="select"
                  label="Документ уполномоченного лица"
                  placeholder="Выберите документ"
                  labelView="outer"
                  block
                  size="s"
                  options={identityDocumentTypesOptions}
                />
              </Grid.Col>
              <Grid.Col width="6">
                <MaskedInput
                  mask={
                    IDENTITY_DOCUMENT_NUMBER_MASK[
                      Number(
                        form.values.identityDocumentType
                      ) as IdentityDocumentType
                    ]
                  }
                  name="identityDocumentNumber"
                  label="Серия и номер документа"
                  placeholder={
                    Number(form.values.identityDocumentType) ===
                    IdentityDocumentType.USSRPassport
                      ? 'XI-АА 123456'
                      : 'Введите серию и номер'
                  }
                  labelView="outer"
                  block
                  size="s"
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row gutter={16}>
              <Grid.Col width="6">
                <Select
                  name="accessLevel"
                  type="select"
                  label="Уровень доступа"
                  placeholder="Выберите уровень доступа"
                  labelView="outer"
                  block
                  options={accessLevelsOptions}
                  size="s"
                  hint={
                    <Typography.Text view="secondary-small">
                      <Link onClick={onAccessLevelsClick} view="secondary">
                        Подробнее об уровнях доступа
                      </Link>
                    </Typography.Text>
                  }
                />
              </Grid.Col>
              <Grid.Col width="6">
                <UniversalDateInput
                  name="endDate"
                  label="Срок действия доверенности"
                  placeholder="Выберите дату"
                  labelView="outer"
                  block
                  size="s"
                  minDate={getTime(startOfDay(addDays(new Date(), 1)))}
                  view="date"
                  picker
                  Calendar={CalendarDesktop}
                  calendarProps={{
                    selectorView: 'full',
                  }}
                />
              </Grid.Col>
            </Grid.Row>
          </Space>
          <Divider className={styles.divider} />
          <Grid.Row gutter={16}>
            <Grid.Col width="6">
              <ButtonDesktop
                size="xs"
                block
                leftAddons={<PenNibMIcon width={18} height={18} />}
                loading={form.isSubmitting}
                disabled={!(form.isValid && form.dirty) || disabled}
                type="submit"
              >
                Подписать
              </ButtonDesktop>
            </Grid.Col>
            <Grid.Col width="6">
              <div />
            </Grid.Col>
          </Grid.Row>
        </Form>
      </FormikProvider>
    </>
  );
};
