import { format, sub } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';
import { Loader } from '@alfalab/core-components/loader';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import {
  dateToRequestFormat,
  DEFAULT_VIEW_DATE_FORMAT,
} from '@terminal/core/lib/rest/lkApi';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import {
  Notification,
  NotificationsFilter,
  NotificationsRequest,
} from '@terminal/core/lib/rest/lkNotifications';

import { Result } from '../../../../shared/ui/Result';
import { NotificationDetail } from '../../components/NotificationDetail';
import { NotificationsFilterLine } from '../../components/NotificationsFilter';
import { NotificationsList } from '../../components/NotificationsList';

import { useNotifications } from '../../hooks';

import styles from './NotificationsMain.module.css';

interface NotificationsMainProps {
  treaty: Treaty | null;
  notificationsCount?: number;
  onNotificationRead?: () => void;
}

const PAGE_SIZE = 20;

export const defaultFilter: NotificationsFilter = {
  startDate: format(sub(new Date(), { years: 1 }), DEFAULT_VIEW_DATE_FORMAT),
  endDate: format(new Date(), DEFAULT_VIEW_DATE_FORMAT),
  unreadOnly: false,
};

export const NotificationsMain = ({
  treaty,
  notificationsCount = 0,
  onNotificationRead,
}: NotificationsMainProps) => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<NotificationsFilter>(defaultFilter);
  const [selected, setSelected] = useState<Notification | null>(null);
  const [openedItems, setOpenedItems] = useState<number[]>([]);

  const notificationsParams = useMemo((): NotificationsRequest => {
    return {
      ...filter,
      startDate: dateToRequestFormat(filter.startDate),
      endDate: dateToRequestFormat(filter.endDate),
      treaty: treaty?.treaty || 0,
      page,
      count: PAGE_SIZE,
    };
  }, [filter, page, treaty]);

  const [debouncedNotificationsParams] = useDebounce<NotificationsRequest>(
    notificationsParams,
    250
  );

  const {
    data: notifications,
    isFetching: notificationsPending,
    isFetchingNextPage: notificationsPendingNext,
    hasNextPage: hasMoreNotifications,
    fetchNextPage: fetchMoreNotifications,
  } = useNotifications(debouncedNotificationsParams);

  const notificationsItems = useMemo(() => {
    if (notifications) {
      const items = notifications.pages.flatMap((page) => page.notifications);
      return items.map((item) => ({
        ...item,
        isRead: item.isRead || openedItems.indexOf(item.id) >= 0,
      }));
    }
    return null;
  }, [notifications, openedItems]);

  const [loaderRef, loaderInView] = useInView();
  useEffect(() => {
    if (loaderInView) {
      fetchMoreNotifications();
    }
  }, [loaderInView, fetchMoreNotifications]);

  // При изменении фильтра устанавливаем 1-ю страницу
  useEffect(() => {
    setPage(1);
  }, [filter]);

  const onItemOpen = (item: Notification) => {
    setSelected(item);
    setOpenedItems((prevIds) => {
      if (prevIds.indexOf(item.id) >= 0) {
        return [...prevIds];
      } else {
        return [...prevIds, item.id];
      }
    });
  };

  const onItemClose = () => {
    setSelected(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <NotificationsFilterLine
          filter={filter}
          defaultFilter={defaultFilter}
          unreaded={notificationsCount}
          onSetFilter={(filter) => setFilter(filter)}
        />
      </div>

      <div className={styles.results}>
        {!!notificationsItems && (
          <NotificationsList
            notifications={notificationsItems}
            onItemOpen={onItemOpen}
          />
        )}

        {(notificationsPending || notificationsPendingNext) && <Loader />}

        {!!notificationsItems &&
          notificationsItems.length === 0 &&
          !(notificationsPending || notificationsPendingNext) && (
            <Result
              title="Результатов нет :("
              text={<>По заданным параметрам ни&nbsp;чего не&nbsp;найдено</>}
              icon={<FilterMIcon />}
            />
          )}

        {hasMoreNotifications &&
          !(notificationsPending || notificationsPendingNext) && (
            <div className={styles.loader} ref={loaderRef}></div>
          )}
      </div>

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={selected !== null}
          backLabel="Уведомления"
          onClose={onItemClose}
        >
          {!!selected && (
            <NotificationDetail
              item={selected}
              onNotificationRead={onNotificationRead}
            />
          )}
        </FunctionalModalSidebar>
      </Portal>
    </div>
  );
};
