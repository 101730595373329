import cn from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { Tag } from '@alfalab/core-components/tag';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkOnCircleMIcon } from '@alfalab/icons-glyph/CheckmarkOnCircleMIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { SelectionMaskMIcon } from '@alfalab/icons-glyph/SelectionMaskMIcon';

import { useOnClickOutside } from '@terminal/core/hooks';
import { ObjectGroup } from '@terminal/core/lib/client/entities';

import { Dropdown } from '../../../../components/Dropdown';

// eslint-disable-next-line no-restricted-imports
import styles from '../AccountFilters.module.css';

const filtersByType = [
  {
    name: 'Акции',
    idObjectGroup: [ObjectGroup.Stocks, ObjectGroup.Receipts],
  },
  {
    name: 'Облигации',
    idObjectGroup: [ObjectGroup.Bonds],
  },
  {
    name: 'Фьючерсы',
    idObjectGroup: [ObjectGroup.Futures],
  },
  {
    name: 'Фонды',
    idObjectGroup: [ObjectGroup.EquityParticipations],
  },
  {
    name: 'Валюты',
    idObjectGroup: [ObjectGroup.Currency],
  },
  {
    name: 'Металлы',
    idObjectGroup: [ObjectGroup.Metals],
  },
];

const allTypes = [
  ObjectGroup.Stocks,
  ObjectGroup.Receipts,
  ObjectGroup.Bonds,
  ObjectGroup.Futures,
  ObjectGroup.EquityParticipations,
  ObjectGroup.Currency,
];

interface TypeFiltersProps {
  selectedTypes: ObjectGroup[];
  isShowChips: boolean;
  saveTypes: (positionTypes: ObjectGroup[]) => void;
}

export const TypeFilters = ({
  selectedTypes,
  isShowChips,
  saveTypes,
}: TypeFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside([dropdownRef, filtersButtonRef], () => setIsOpen(false));

  const isEverySelected = useMemo(
    () =>
      !selectedTypes.length ||
      allTypes.every((idObjectGroup) => selectedTypes.includes(idObjectGroup)),
    [selectedTypes]
  );

  const filtersCount = useMemo(() => {
    if (selectedTypes.length) {
      const firstType = selectedTypes[0];
      const firstName = filtersByType.find(({ idObjectGroup }) =>
        idObjectGroup.includes(firstType)
      )?.name;

      const restTypes =
        selectedTypes.length -
        1 -
        (selectedTypes.includes(ObjectGroup.Receipts) ? 1 : 0);

      if (restTypes) {
        return `${firstName} + ${restTypes}`;
      } else {
        return firstName ?? '';
      }
    } else {
      return '';
    }
  }, [selectedTypes]);

  return isShowChips ? (
    <TypeOptions
      isShowChips={isShowChips}
      selectedTypes={selectedTypes}
      saveTypes={saveTypes}
    />
  ) : (
    <>
      <Tag
        ref={filtersButtonRef}
        size="xs"
        view="filled"
        checked={selectedTypes.length > 0}
        onClick={() => setIsOpen((prev) => !prev)}
        onMouseDown={(e) => e.stopPropagation()}
        rightAddons={
          <ChevronDownCompactMIcon
            height={16}
            width={16}
            className={cn(styles.chevron, isOpen && styles.chevronUp)}
          />
        }
      >
        {isEverySelected ? 'Все инструменты' : filtersCount}
      </Tag>
      <Dropdown
        ref={dropdownRef}
        anchorElement={filtersButtonRef.current}
        open={isOpen}
        offset={[0, 4]}
        position="bottom-start"
        popperClassName={styles.positionTypeSelectPoppover}
      >
        <TypeOptions
          isShowChips={isShowChips}
          selectedTypes={selectedTypes}
          saveTypes={saveTypes}
        />
      </Dropdown>
    </>
  );
};

interface TypeOptionsProps {
  isShowChips: boolean;
  selectedTypes: ObjectGroup[];
  saveTypes: (positionTypes: ObjectGroup[]) => void;
}

export const TypeOptions = ({
  isShowChips,
  selectedTypes,
  saveTypes,
}: TypeOptionsProps) =>
  isShowChips ? (
    <>
      {filtersByType.map(({ name, idObjectGroup }) => {
        const checked = idObjectGroup.some((id) => selectedTypes.includes(id));

        return (
          <Tag
            checked={checked}
            key={name}
            size="xs"
            view="filled"
            onClick={() => {
              if (checked) {
                saveTypes(
                  selectedTypes.filter(
                    (statusFilter) => !idObjectGroup.includes(statusFilter)
                  )
                );
              } else {
                saveTypes([...selectedTypes, ...idObjectGroup]);
              }
            }}
            className={cn(
              !isShowChips && styles.orderStatusFiltersFilterInSelect
            )}
          >
            {name}
          </Tag>
        );
      })}
    </>
  ) : (
    <>
      {filtersByType.map(({ name, idObjectGroup }) => {
        const checked = idObjectGroup.some((id) => selectedTypes.includes(id));

        return (
          <Dropdown.Item
            key={name}
            leftIcon={
              checked ? (
                <CheckmarkOnCircleMIcon
                  color="var(--control-accent-bg-color)"
                  height={18}
                  width={18}
                />
              ) : (
                <SelectionMaskMIcon
                  color="var(--color-dark-graphic-secondary)"
                  height={18}
                  width={18}
                />
              )
            }
            content={
              <Typography.Text view="secondary-large">{name}</Typography.Text>
            }
            onClick={() => {
              if (checked && selectedTypes.length !== 0) {
                saveTypes(
                  selectedTypes.filter(
                    (statusFilter) => !idObjectGroup.includes(statusFilter)
                  )
                );
              } else {
                saveTypes([...selectedTypes, ...idObjectGroup]);
              }
            }}
          />
        );
      })}
    </>
  );
