import { CONFIG } from 'app/config';
import { TextOrderService } from 'app/services/textOrderService';
import log, { LogLevelDesc } from 'loglevel';
import React from 'react';
import ReactDOM from 'react-dom';

import darkStyles from '@terminal/common/styles/desktop/go/variables/dark';
import { ServicesProvider } from '@terminal/core/app/providers/ServicesProvider';
import { DEV_LOG_LEVEL } from '@terminal/core/env';
import { OrderChannels } from '@terminal/core/lib/client/entities';
import { TradingService } from '@terminal/core/lib/services/trading/tradingService';
import App from '@terminal/desktop/app/App';

import 'app/index.css';

// идея в том что бы была возможность поменять уровень отладочных логов налету
// поэтому в window явно пробрасывается эта функция
if (window) {
  window['setLogLevel'] = function (logLevel: string, persist: boolean) {
    log.setLevel(logLevel as LogLevelDesc, persist);
  };
}

const loglevelDefault = DEV_LOG_LEVEL || 'WARN';
// либа сохраняет в локал сторейдж уровень логов
const overridenLogLevel = localStorage.getItem('loglevel');

if (!overridenLogLevel) {
  // ставим дефолтный из env переменной или просто warn
  log.setLevel(loglevelDefault as LogLevelDesc);
}

const tradingService = new TradingService(
  new TextOrderService(),
  OrderChannels.WEBDT
);

ReactDOM.render(
  <React.StrictMode>
    <ServicesProvider tradingService={tradingService}>
      <App config={CONFIG} darkStyles={darkStyles} />
    </ServicesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
