import { Typography } from '@alfalab/core-components/typography';

import {
  SecuritiesClientReference,
  SecuritiesPosition,
} from '@terminal/core/lib/rest/lkSecurities';

// TODO: Заменить на FormControlSummary
import { SummaryField } from './SummaryField';

import styles from './Summary.module.css';

interface SummaryPositionProps {
  position: SecuritiesPosition | null;
  counteragentAlfaBank?: boolean;
  clientReferenceInfo?: SecuritiesClientReference;
  title?: string;
}

export const SummaryPosition = ({
  position,
  counteragentAlfaBank,
  clientReferenceInfo,
  title,
}: SummaryPositionProps) => {
  if (!position) {
    return null;
  }

  return (
    <>
      <Typography.Text weight="bold" tag="p">
        {title || 'Откуда'}
      </Typography.Text>

      <div className={styles.section}>
        <SummaryField label="Счёт" value={position.accCode} />
        <SummaryField
          label="Место хранения"
          value={position.placeDescription}
        />
        {Boolean(clientReferenceInfo) && (
          <>
            <SummaryField
              label="Счёт депо"
              value={clientReferenceInfo?.clientAcc}
            />
            <SummaryField
              label="Раздел счёта депо"
              value={clientReferenceInfo?.clientDvn}
            />
          </>
        )}
        {Boolean(counteragentAlfaBank) && (
          <SummaryField
            label="Счёт депо в Go Invest"
            value={position.depoAccount}
          />
        )}
        {Boolean(clientReferenceInfo) && (
          <SummaryField
            label="Идентификатор"
            value={clientReferenceInfo?.clientCode}
          />
        )}
      </div>
    </>
  );
};
