import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getInstructionsList } from '../api';

export function useInstructionsList() {
  const addNotification = useNotification();

  return useQuery(['lk-instructions-list'], getInstructionsList, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса списка руководств',
        text: error?.message || '',
      });
    },
  });
}
