import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { fetchLkFile } from '@terminal/core/lib/rest/lkApi';
import { NotificationType } from '@terminal/core/types/ui';

interface DownloadParams {
  url: string;
  filename?: string;
}

export const useDownloadAndOpenFile = () => {
  const addNotification = useNotification();

  return useMutation(
    async ({ url, filename = '' }: DownloadParams) => {
      const file = await fetchLkFile(url);
      const link = document.createElement('a');

      link.href = file.url;
      link.download = file.filename || filename;
      link.click();
      link.remove();
      file.destroy();
    },
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка загрузки файла',
          text: error?.message || '',
        });
      },
    }
  );
};
