import cn from 'classnames';
import { FC, HTMLAttributes } from 'react';

import styles from './Spinner.module.css';

export type SpinnerProps = Pick<HTMLAttributes<HTMLDivElement>, 'className'> & {
  /**
   * Размер в пикселях
   * @default 1.5em
   * */
  size?: string;
  /**
   * Цвет
   * @default #FFFFFF
   * */
  color?: string;
};

export const Spinner: FC<SpinnerProps> = (props) => {
  const { size = '1.5em', color, className } = props;

  return (
    <div
      className={cn(styles.root, className)}
      style={{
        width: size,
        height: size,
        color,
      }}
    />
  );
};
