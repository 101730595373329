import { ChatGroup, ChatMessage } from './chat';
import { BrokerChatMessage, BrokerRecommendation } from './personalBroker';

export enum ESupportConnectionState {
  IDLE,
  CONNECTING,
  UPDATING,
  OFFLINE,
  OK,
  ERROR,
}

export interface ChatSlice {
  chatGroups: Record<string, ChatGroup>;
  setChatGroups: (groups: ChatGroup[]) => void;
  openedChatId: string | null;
  setOpenedChatId: (chatId: string | null) => void;
  unreadMsgCount: Record<string, number>;
  setUnreadMsgCount: (chatId: string, count: number) => void;
  messages: Record<string, ChatMessage[]>;
  setNewMessages: (chatId: string, messages: ChatMessage[]) => void;
  messagesLoading: boolean;
  historyError: boolean;
  allMessagesLoad: boolean;
}

export interface SupportSlice {
  supportConnectionState: ESupportConnectionState;
  setSupportConnectionState: (state: ESupportConnectionState) => void;
  getSupportMessage: (message: ChatMessage) => void;
  connectToChat: () => void;
  disconnectFromChat: () => void;
  getChatHistory: () => Promise<void>;
}

export interface PersonalBrokerSlice {
  setBrokerMessages: (messages: BrokerChatMessage[]) => void;
  brokerRecommendations: BrokerRecommendation[];
  setBrokerRecommendations: (recommendations: BrokerRecommendation[]) => void;
}
