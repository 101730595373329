import { MarginParamsDTO } from './MarginParamsDTO';

import { MarginParams } from '../../../types/MarginParams';

export function mapMarginParams(dto: MarginParamsDTO): MarginParams {
  return {
    short: {
      margin: dto[0].marginShort,
      mkStart: dto[0].mkShortStart ?? 0,
      mkMin: dto[0].mkShortMin ?? 0,
      mkCurrent: dto[0].mkShortCurrent,
    },
    long: {
      margin: dto[0].marginLong,
      mkStart: dto[0].mkLongStart ?? 0,
      mkMin: dto[0].mkLongMin ?? 0,
      mkCurrent: dto[0].mkLongCurrent,
    },
    lot: dto[0].lot,
    priceStep: dto[0].priceStep,
    priceStepCost: dto[0].priceStepCost,
  };
}
