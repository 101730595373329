import { fetchLkResult } from '@terminal/core/lib/rest/lkApi';

import { TradingPlatformsState } from '../model/types/TradingPlatformsState';
import { TradingPlatformsStateParams } from './types/TradingPlatformsStateParams';

export async function getTradingPlatformsState(
  params: TradingPlatformsStateParams
): Promise<TradingPlatformsState> {
  const res = await fetchLkResult<TradingPlatformsState>(
    `/api/trading-platform/registration-state/${params.treaty}`
  );

  return res;
}
