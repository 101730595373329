import { fetchLkResult, LKResult } from './lkApi';
import { signOperation } from './lkSignOperation';

export interface AnswerOptionValues {
  id: string;
  title: string;
  checked: boolean | null;
  hint: string | null;
}

export interface Answer {
  _class_: string;
  id: string;
  type: 'radio' | 'checkbox';
  hint: string | null;
  validation: {
    required: boolean;
  };
  options: {
    values: Array<AnswerOptionValues>;
  };
}

export enum Footnote {
  KeyRateNote = 'CB_KEY_RATE_NOTE',
}

export interface FormItem {
  hint: string | null;
  id: number;
  title: string;
  answers: Array<Answer>;
  footnote?: Footnote | null;
}

export interface RiskProfile {
  pin: string;
  segment: string | null;
  businessUserId: string | null;
  formVersion: string | null;
  answers: {};
  insertDate: string | null;
  riskProfile: number | null;
  expectedIncome: string | null;
  riskCapacity: number | null;
  riskTolerance: number | null;
  investmentHorizon: number | null;
  iteration: number;
  outdated: boolean;
  accepted: boolean | null;
  modified: boolean | null;
  signType: string | null;
  skdDocumentInfo: string | null;
  rejectPfpReason: string | null;
  updateDate: string | null;
  profileTitle: string;
  profileDescription: string;
}

export type RiskProfileForm = Array<FormItem>;

export interface RiskProfileAnket {
  description: string;
  id: string;
  title: string | null;
  form: RiskProfileForm;
}

export interface ClientRiskProfileResponse {
  code: number;
  message: string | null;
  anket: RiskProfileAnket;
  profile: RiskProfile;
  unsignedProfile?: RiskProfile;
}

/**
 * Получает список вопросов по риск профилированию из ЛК
 * */
export async function getClientProfile(): Promise<ClientRiskProfileResponse> {
  const res = await fetchLkResult<ClientRiskProfileResponse | LKResult>(
    `/api/riskprofiling/client-profile`
  );

  if ('code' in res && res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res as ClientRiskProfileResponse;
}

export async function getProfileAgreement(
  isUnsigned: boolean
): Promise<LKResult & { agreement: string }> {
  const res = await fetchLkResult<LKResult & { agreement: string }>(
    `/api/riskprofiling/agreement${isUnsigned ? '/unsigned' : ''}`,
    'GET'
  );

  if (res?.code && res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface UpdateRiskProfileResult extends LKResult {
  riskProfile: number;
  profileTitle: string;
  profileDescription: string;
  expectedIncome: string;
}

/**
 * Отправляет ответы на вопросы по риск профилированию
 * */
export async function updateRiskProfile(
  params: Partial<RiskProfile>
): Promise<UpdateRiskProfileResult> {
  const res = await fetchLkResult<UpdateRiskProfileResult>(
    '/api/riskprofiling/update-profile',
    'POST',
    params
  );

  if (res?.code && res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export async function confirmProfile(resetCb?: () => void) {
  return new Promise((resolve, reject) => {
    signOperation('/api/riskprofiling/accept-profile', 'POST', undefined, {
      resetCallback: () => {
        resetCb?.();
      },
      successCallback: resolve,
      errorCallback: reject,
    });
  });
}
