import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { getFullFI } from '../../lib/getFullFI';

// Возвращает полный fi по idFi
export const useFullFI = (idFI?: number) => {
  const {
    objectsTable: objects,
    objectTypesTable: objectTypes,
    finInstrumentsTable: finInstruments,
    marketBoardsTable: marketBoards,
  } = useAlfaDirectContext();

  const fullFI = useMemo(
    () => getFullFI(idFI, objects, objectTypes, finInstruments, marketBoards),
    [idFI, objects, objectTypes, finInstruments, marketBoards]
  );

  return fullFI;
};
