import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { mapContestLadder } from '../lib/mapContestLadder';

import { ContestLadder } from '../model/types/ContestLadder';
import { ContestLadderParams } from '../model/types/ContestLadderParams';

export async function getContestLadder(
  data: ContestLadderParams
): Promise<ContestLadder> {
  const result = await fetchLkResult<ContestLadder | LKResult>(
    qs.stringifyUrl(
      {
        url: '/api/contest/ladder',
        query: { ...data },
      },
      { arrayFormat: 'bracket' }
    )
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return mapContestLadder(result);
}
