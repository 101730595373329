/**
 * Преобразует ФИО в формат с инициалами.
 *
 * Данная функция принимает строку с ФИО, обрезает пробелы по краям,
 * разделяет имя на части по пробелам и преобразует все части, кроме фамилии,
 * в инициалы (первая буква части слова, за которой следует точка).
 * Возвращается новая строка, где фамилия часть остаётся без изменений,
 * а все последующие преобразуются в инициалы.
 *
 * @param {string} fullName - Полное имя, которое нужно преобразовать.
 * @returns {string} Строка, где фамилия остаётся полной, а все остальное сокращено до инициалов.
 */
export const formatFullNameWithInitials = (fullName: string) => {
  return fullName
    .trim()
    .split(' ')
    .map((part, index, [firstPart]) =>
      index === 0 ? firstPart : `${part[0]}.`
    )
    .join(' ');
};
