import React, { FC } from 'react';

import { AlertEditModal } from '../../../legacy/components/AlertEditModal';
import { AlertNotifications } from '../../../legacy/components/AlertNotifications';
import { AlertSettingsModal } from '../../../legacy/components/AlertSettingsModal';
import { AlertsListModal } from '../../../legacy/components/AlertsListModal';

export const Alerts: FC = () => {
  return (
    <>
      <AlertNotifications />
      <AlertsListModal />
      <AlertEditModal />
      <AlertSettingsModal />
    </>
  );
};
