import { format } from 'date-fns';
import log from 'loglevel';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import type { FreeOrdersHistoryParams } from '@terminal/core/lib/rest/lkFreeOrders';
import { getFreeOrdersHistory } from '@terminal/core/lib/rest/lkFreeOrders';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../queryKeys';

type HookParams = Partial<FreeOrdersHistoryParams>;

const getDate = (
  dateInStringFormat: string | undefined,
  dateType: 'START' | 'END'
) => {
  const fallbackDate =
    dateType === 'END'
      ? format(new Date(), 'yyyyMMdd')
      : format(
          new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
          'yyyyMMdd'
        );

  if (!dateInStringFormat) {
    return fallbackDate;
  }

  if (!/^[0-9]{8}/.test(dateInStringFormat)) {
    log.debug(`Неверный формат даты ${dateInStringFormat}`);

    return fallbackDate;
  }

  return dateInStringFormat;
};

export const useGetFreeOrdersHistory = ({
  treaty,
  count,
  endDate,
  page,
  startDate,
  statuses,
  searchString,
}: HookParams) => {
  const addNotification = useNotification();

  const startDateString = useMemo(
    () => getDate(startDate, 'START'),
    [startDate]
  );
  const endDateString = useMemo(() => getDate(endDate, 'END'), [endDate]);

  return useQuery(
    [
      LkQueryKeys.FreeOrdersHistory,
      { treaty, endDate, startDate, statuses, searchString, page },
    ],
    async () =>
      getFreeOrdersHistory({
        treaty,
        count: count || 30,
        endDate: endDateString,
        page,
        startDate: startDateString,
        statuses: statuses,
        searchString,
      }),
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message || 'При запросе истории поручений произошла ошибка',
        });
      },
    }
  );
};
