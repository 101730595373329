import cn from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import UAParser from 'ua-parser-js';
import { Loader } from '@alfalab/core-components/loader';

import { APP_VERSION } from '@terminal/core/env';
import { useResizeObserver } from '@terminal/core/hooks/useResizeObserver';

import { FetchRemoteConfigParams, RemoteConfig } from '../../RemoteConfig';
import { featureFlags, setFeatureFlags } from '../lib/featureFlags';
import { ServiceNotificationItem } from '../ui/ServiceNotificationItem';

import { RemoteConfigResponse, TECH_MESSAGE_CODE } from '../model/types';

import styles from './ServiceNotifications.module.css';

export interface ServiceNotificationsProps {
  variant: 'line' | 'banner' | 'modal' | 'message' | 'icon';
  code: TECH_MESSAGE_CODE | TECH_MESSAGE_CODE[];
  className?: string;
  containerClassName?: string;
  canBeShort?: boolean;
  closable?: boolean;
  useRemoteConfig: <T>(
    params: FetchRemoteConfigParams,
    onSuccess: (onSuccess: RemoteConfig<T>) => void
  ) => UseQueryResult<RemoteConfig<T>, unknown>;
}

function getTextWidth(text: string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = 'normal 13px sans-serif';
    const metrics = context?.measureText(text);

    canvas.remove();

    return metrics.width;
  }
}

const getMessage = (
  code: TECH_MESSAGE_CODE | TECH_MESSAGE_CODE[],
  flags: typeof featureFlags
): string | undefined => {
  const isCodeArray = Array.isArray(code);

  if (isCodeArray) {
    // Если компонент подписан на несколько сообщений, то отдаем первое заданное по приоритету
    for (let index = 0; index < code.length; index++) {
      const element = code[index];
      const message = flags.get(element);

      if (message) {
        return message;
      }
    }
  } else {
    return flags.get(code);
  }
};

export const ServiceNotifications = ({
  variant,
  className,
  containerClassName,
  code,
  canBeShort,
  closable,
  useRemoteConfig,
}: ServiceNotificationsProps) => {
  const [short, setShort] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const uaParser = new UAParser();

  const { isLoading } = useRemoteConfig<RemoteConfigResponse>(
    {
      packageName: 'web-trade',
      os: uaParser.getBrowser().name?.toLowerCase(),
      appVersion: APP_VERSION,
    },
    setFeatureFlags
  );

  const message = getMessage(code, featureFlags);
  const textWidth = useMemo(
    () => (message ? getTextWidth(message) : 0),
    [message]
  );

  useResizeObserver(containerRef, () => {
    if (containerRef.current && textWidth && canBeShort) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;

      setShort(containerWidth < textWidth + 100);
    }
  });

  return (
    <div
      className={cn(styles.container, containerClassName)}
      ref={containerRef}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <ServiceNotificationItem
          variant={variant}
          className={className}
          short={short}
          message={message}
          closable={closable}
        />
      )}
    </div>
  );
};
