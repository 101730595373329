import { FC, PropsWithChildren, useMemo } from 'react';

import { TradingService } from '../../lib/services/trading';

import { Context, ServicesContext } from '../../hooks/useServicesContext';

interface Props {
  tradingService: TradingService;
}

/**
 * Контекст сервисов приложения
 * */
export const ServicesProvider: FC<PropsWithChildren<Props>> = ({
  children,
  tradingService,
}) => {
  const contextValue = useMemo<ServicesContext>(
    () => ({
      tradingService,
    }),
    [tradingService]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
