import { accountMap } from '../../mapping/accountMapping';
import { operationMap } from '../../mapping/operationMapping';
import { orderMap } from '../../mapping/orderMapping';
import {
  subAccountMap,
  subGTAccountMap,
} from '../../mapping/subAccountMapping';
import { subAccountPositionMap } from '../../mapping/subAccountPositionMapping';
import { subAccountRazdelMap } from '../../mapping/subAccountRazdelMapping';
import {
  ClientAccountEntity,
  ClientGTSubAccountEntity,
  ClientOperationEntity,
  ClientSubAccountEntity,
  ClientSubAccPositionEntity,
  FrontEndType,
  InitFinishedEntity,
  MessageUnitedType,
  OrderEntity,
  SubAccountRazdelEntity,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import { getEntityNameById } from '../client/serialization';
import AdirClientService from '../client/service';
import { uiSettings } from './storage';

import { SubAccountsState } from '../../types/subAccount';

// portofolio - это сервис для запроса всего, что связанно с аккаунтом пользователя:
// ClientAccountEntity, ClientSubAccountEntity, .

// В методе init мы подписываемся на сокеты,
// он запускается при старте приложения в момент создания AlfaDirectClient

// В методе request мы отправляем запрос на получение аккаунтов
// Он вызывается после авторизации пользователя

class Portfolio extends AdirClientService {
  override init() {
    this.addClientListener(EntityType.ClientAccountEntity, (message) => {
      this.store
        .getState()
        .setAccounts((message.data as ClientAccountEntity[]).map(accountMap));
    });
    this.addClientListener(EntityType.ClientSubAccountEntity, (message) => {
      const state = this.store.getState();

      const mappedSubAccounts = (message.data as ClientSubAccountEntity[]).map(
        subAccountMap
      );

      const storedSubAccountState = uiSettings.subAccountsState;

      const subAccountsState = mappedSubAccounts.reduce<SubAccountsState>(
        (acc, { idSubAccount }) => {
          acc[idSubAccount] = storedSubAccountState[idSubAccount] ?? {
            isDailyParamsSelected: false,
          };

          return acc;
        },
        {}
      );

      state.setSubAccountsState(subAccountsState);

      state.setSubAccounts(mappedSubAccounts);
    });
    this.addClientListener(EntityType.ClientGTSubAccountEntity, (message) => {
      this.store
        .getState()
        .setSubGTAccounts(
          (message.data as ClientGTSubAccountEntity[]).map(subGTAccountMap)
        );
    });
    this.addClientListener(EntityType.ClientSubAccPositionEntity, (message) => {
      this.store
        .getState()
        .setSubAccountPositions(
          (message.data as ClientSubAccPositionEntity[]).map(
            subAccountPositionMap
          )
        );
    });
    this.addClientListener(EntityType.OrderEntity, (message) => {
      this.store
        .getState()
        .setOrders((message.data as OrderEntity[]).map(orderMap));
    });
    this.addClientListener(EntityType.ClientOperationEntity, (message) => {
      this.store
        .getState()
        .setOperations(
          (message.data as ClientOperationEntity[]).map(operationMap)
        );
    });
    this.addClientListener(EntityType.SubAccountRazdelEntity, (message) => {
      this.store
        .getState()
        .setSubAccountRazdel(
          (message.data as SubAccountRazdelEntity[]).map(subAccountRazdelMap)
        );
    });
  }

  async request() {
    const mainPromise = new Promise<void>((resolve, reject) => {
      // данные которые позволяют зажечь ивент когда портфолио загружено
      const initsState = {
        [EntityType.ClientAccountEntity]: false,
        [EntityType.SubAccountRazdelEntity]: false,
        [EntityType.ClientSubAccountEntity]: false,
        [EntityType.ClientGTSubAccountEntity]: false,
        [EntityType.ClientSubAccPositionEntity]: false,
        [EntityType.OrderEntity]: false,
        [EntityType.ClientOperationEntity]: false,
      };

      // таймаут запроса
      const requestTimeout = setTimeout(() => {
        reject(new Error('request timeout'));
        this.removeClientListener(EntityType.InitFinishedEntity, initListener);
      }, 300);

      const initListener = (message: MessageUnitedType) => {
        message.data.forEach((value: object) => {
          const init = value as InitFinishedEntity;
          const entityName = getEntityNameById(init.Command);

          if (!entityName) {
            return;
          }

          if (initsState.hasOwnProperty(entityName)) {
            // Странно, но тайпскрипт ругается даже если есть такая проверка
            initsState[entityName] = true;
          }

          let allInitsDone = true;

          for (let [, v] of Object.entries(initsState)) {
            allInitsDone = allInitsDone && v;
          }

          if (allInitsDone) {
            this.removeClientListener(
              EntityType.InitFinishedEntity,
              initListener
            );
            clearTimeout(requestTimeout);
            resolve();
          }
        });
      };

      this.addClientListener(EntityType.InitFinishedEntity, initListener);
    });

    this.sendRequest(EntityType.ClientAccountEntity);
    this.sendRequest(EntityType.ClientSubAccountEntity);
    this.sendRequest(EntityType.ClientGTSubAccountEntity);
    this.sendRequest(EntityType.SubAccountRazdelEntity);
    this.sendRequest(EntityType.ClientSubAccPositionEntity);
    this.sendRequest(EntityType.OrderEntity);
    this.sendRequest(EntityType.ClientOperationEntity);

    await mainPromise;
  }
  sendRequest(
    entityName: EntityType,
    frontend: FrontEndType = FrontEndType.AuthAndOperInitServer
  ) {
    this.client.subscribe(frontend, entityName);
  }
}

export const PortfolioService = new Portfolio();
