import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { MarketBoardItemsTable } from '../../types/core';

export function useMarketBoardByIdMap() {
  const { marketBoardsTable } = useAlfaDirectContext();

  return (marketBoardsTable as MarketBoardItemsTable).getColumn(
    'idMarketBoard'
  );
}
