import { RootStore } from '../../useStore';
import { marketBoardByIdFi } from './marketBoardByIdFi';
import { objectById } from './objectById';

import { InstrumentItem, ObjectItem } from '../../../types/core';

/**
 * Выбирает валюту (ценную бумагу) по идентификатору финансового инструмента
 * */
export function currencyObjectByIdFi(
  store: RootStore
): (idFi: InstrumentItem['idFI']) => ObjectItem | undefined {
  return (idFi) => {
    const marketBoard = marketBoardByIdFi(store)(idFi);

    return objectById(store)(marketBoard?.idObjectCurrency ?? NaN);
  };
}
