import differenceInDays from 'date-fns/differenceInDays';

import { ObjectGroup } from '../../lib/client/entities';
import { PREV_LAST_DAYS_COMPARE } from './constants';

import { ECurrencyId } from '../../types/currencyId';

/**
 * @returns Входящую цену (ВхЦена)
 * @param isDerivativesMarket если `true`, то расчет производится для срочного рынка
 * Формула описана тут - https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=503369004
 * */
export const getPrevPrice = (
  PrevLastDate?: Date,
  PrevFairPriceDate?: Date,
  PrevFairPrice = 0,
  PrevLast = 0,
  prevQuote = 0,
  idObject?: number,
  idObjectGroup?: number
): number => {
  // Если рубль возвращаем единицу
  if (idObject === ECurrencyId.RUR) {
    return 1;
  }

  if (idObjectGroup === ObjectGroup.Futures) {
    return prevQuote;
  }

  if (
    PrevLast !== 0 &&
    PrevLastDate &&
    PrevFairPriceDate &&
    Math.abs(differenceInDays(PrevLastDate, PrevFairPriceDate)) <
      PREV_LAST_DAYS_COMPARE
  ) {
    return PrevLast;
  } else {
    return PrevFairPrice;
  }
};
