import { BaseWidgetConfig } from '@terminal/core/types/layout';
import { DateFilterValue } from '@terminal/core/types/operation';

import { AccountFilters } from '../../../../features/AccountFilters';
import { WidgetHeader } from '../../../../features/WidgetHeader';
import { DateFilter } from '../DateFilter';

interface Props {
  nodeId?: string;
  updateNode: (
    nodeId: string,
    config: BaseWidgetConfig,
    newName?: string | undefined
  ) => void;
  selectedSubAccounts: string[];
  dateFilter: DateFilterValue | null;
  setDateFilter: (value: DateFilterValue | null) => void;
}

export const HistoryHeader = ({
  nodeId,
  updateNode,
  selectedSubAccounts,
  dateFilter,
  setDateFilter,
}: Props) => {
  return (
    <WidgetHeader
      inputs={
        <AccountFilters
          nodeId={nodeId}
          selectedSubAccounts={selectedSubAccounts}
          updateNode={updateNode}
          isOrderBookVersion
        />
      }
      controls={
        <DateFilter dateFilter={dateFilter} setDateFilter={setDateFilter} />
      }
    />
  );
};
