import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { AddAccountPreregisterGI } from '../model/types/AddAccountPreregisterGI';
import { AddAccountTypeGI } from '../model/types/AddAccountTypeGI';

export const addAccountPreregisterGI = async (accType: AddAccountTypeGI) => {
  const res = await fetchLkResult<AddAccountPreregisterGI | LKResult>(
    `/api/client/accounts/registration-document`,
    'POST',
    accType ? { accType } : undefined
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};
