import { ThemeCheckResultDTO } from '../api/types/ThemeCheckResultDTO';
import { ThemeCheckResult } from '../model/types/ThemeCheckResult';

export function mapThemeCheckResult(
  dto: ThemeCheckResultDTO
): ThemeCheckResult {
  return {
    percent: dto.percent || 0,
    nextThemeAvailable: dto.nextThemeAvailable,
    comment: dto.comment || '',
  };
}
