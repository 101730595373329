import React from 'react';

import { Spinner } from '@terminal/common/components/Spinner';

import classes from './InstrumentLoader.module.css';

export const InstrumentLoader = () => {
  return (
    <div className={classes.loader}>
      <Spinner
        className={classes.loader}
        size="36"
        color="var(--text-color-secondary)"
      />
    </div>
  );
};
