/*eslint-disable no-extend-native*/

// В протоколе используется трехбайтовый размер сообщения
function getUint24(this: DataView, pos: number) {
  // конвертит три байта в little endian число
  return (
    (this.getUint8(pos + 2) << 16) +
    (this.getUint8(pos + 1) << 8) +
    this.getUint8(pos)
  );
}

function setUint24(this: DataView, offset: number, value: number) {
  this.setUint8(offset + 2, (value & 0xff0000) >>> 16);
  this.setUint8(offset + 1, (value & 0x00ff00) >>> 8);
  this.setUint8(offset + 0, value & 0x0000ff);
}

function setBigInt64(
  this: DataView,
  byteOffset: number,
  value: bigint,
  littleEndian: boolean
) {
  const wh = Number((value >> 32n) & 0xffffffffn);
  const wl = Number(value & 0xffffffffn);
  const [h, l] = littleEndian ? [4, 0] : [0, 4];
  this.setUint32(byteOffset + h, wh, littleEndian);
  this.setUint32(byteOffset + l, wl, littleEndian);
}

function getBigInt64(
  this: DataView,
  byteOffset: number,
  littleEndian: boolean
) {
  const [h, l] = littleEndian ? [4, 0] : [0, 4];
  const wh = BigInt(this.getUint32(byteOffset + h, littleEndian));
  const wl = BigInt(this.getUint32(byteOffset + l, littleEndian));
  return (wh << 32n) + wl;
}

function initDataViewExtensions() {
  DataView.prototype.getUint24 = getUint24;
  DataView.prototype.setUint24 = setUint24;
  // Это полифилы для safari
  // TODO ОНИ НЕПРАВИЛЬНЫЕ ЭТО КОПИПАСТА ОТСЮДА https://github.com/GoogleChromeLabs/jsbi/issues/4
  // ТАМ БЫЛИ функции для UInt64 а не для Int64
  if (!DataView.prototype.setBigInt64) {
    DataView.prototype.setBigInt64 = setBigInt64;
  }
  if (!DataView.prototype.getBigInt64) {
    DataView.prototype.getBigInt64 = getBigInt64;
  }
}

export { initDataViewExtensions, setUint24, getUint24 };
