import { useMemo } from 'react';

import { usePositions } from './usePositions';

export const useFilteredPositions = (
  idFi: number,
  selectedSubAccountId: number
) => {
  const positions = usePositions();

  // Фильтруем заявки, которые относятся к счетам с типом Инвесткопилка
  return useMemo(
    () =>
      positions.filter(
        (position) =>
          position.idSubAccount === selectedSubAccountId &&
          position.idFIBalance === idFi
      ),
    [idFi, positions, selectedSubAccountId]
  );
};
