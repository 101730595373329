import { useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Spinner } from '@alfalab/core-components/spinner';
import { ClockMIcon } from '@alfalab/icons-glyph/ClockMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';

import {
  EDUCATION_MODAL_DRAWER_ID,
  StrategyAddon,
  StrategyDifficulty,
  StrategyIcon,
  StrategyPlate,
  StrategyProfitNote,
  StrategyProperty,
  StrategyThemeInfo,
  ThemePassBadge,
  ThemePassPercent,
  ThemePlate,
  useEducationStrategyDetails,
} from '../../../../entities/Education';
import { StrategyEnrollment } from '../StrategyEnrollment';
import { StrategyThemeDetail } from '../StrategyThemeDetail';
import { StrategyThemeTest } from '../StrategyThemeTest';

import styles from './EducationStrategyDetail.module.css';

interface EducationStrategyDetailProps {
  strategyId: number;
}

export const EducationStrategyDetail = ({
  strategyId,
}: EducationStrategyDetailProps) => {
  const [themeId, setThemeId] = useState(0);
  const [enrollModal, setEnrollModal] = useState(false);
  const [testTheme, setTestTheme] = useState<StrategyThemeInfo | null>(null);

  const {
    data: strategy,
    isFetching: loading,
    refetch: refetchStrategy,
  } = useEducationStrategyDetails(strategyId);

  const closeEnroll = () => {
    setEnrollModal(false);
    refetchStrategy();
  };

  const handleNextTheme = () => {
    refetchStrategy();
    setTestTheme(null);

    if (strategy) {
      const currentIndex = strategy.themes.findIndex(
        (theme) => theme.id === themeId
      );
      const lastIndex = strategy.themes.length - 1;

      if (currentIndex < lastIndex) {
        return setThemeId(strategy.themes[currentIndex + 1].id);
      }
    }

    return setThemeId(0);
  };

  const handleToThemeList = () => {
    setTestTheme(null);
    setThemeId(0);
    refetchStrategy();
  };

  const handleRecomendation = () => {
    const currentTheme = themeId;

    setTestTheme(null);
    // Сбрасывает id темы, чтобы перезагрузить
    setThemeId(0);
    setTimeout(() => setThemeId(currentTheme), 1);
  };

  return (
    <>
      {strategy && (
        <>
          <StrategyPlate
            className={styles.strategy}
            name={strategy.name}
            text={
              <div className={styles.time}>
                <ClockMIcon className={styles.timeGlyph} />
                {strategy.progressTime}
              </div>
            }
            icon={<StrategyIcon strategyId={strategy.id} />}
            addons={
              <ButtonDesktop
                className={styles.enrollBtn}
                size="s"
                view="accent"
                onClick={() => setEnrollModal(true)}
                disabled={!strategy.registrationEnabled || strategy.registered}
              >
                {strategy.registered
                  ? 'Заявка отправлена'
                  : 'Записаться в группу'}
              </ButtonDesktop>
            }
          />

          <div className={styles.properties}>
            <StrategyProperty
              label={
                <>
                  Сделок
                  <br />в год
                </>
              }
            >
              ~{strategy.dealsPerYear}
            </StrategyProperty>
            <StrategyProperty label="Прибыльных сделок">
              {Math.round((strategy.profitDeals / strategy.dealsPerYear) * 100)}
              %
            </StrategyProperty>
            <StrategyProperty label="Ориентир доходности" special>
              {strategy.profitability}%*
            </StrategyProperty>
            <StrategyProperty label="Сложность метода">
              <StrategyDifficulty
                difficulty={strategy.complexity as 1 | 2 | 3}
              />
            </StrategyProperty>
          </div>

          {strategy.themes.map((theme, index) => (
            <ThemePlate
              key={`theme-${theme.id}`}
              className={styles.themeItem}
              index={index + 1}
              title={theme.name}
              comment={theme.comment}
              disabled={!theme.enabled}
              addons={
                theme.passPercent !== null && (
                  <>
                    <ThemePassPercent
                      percent={theme.passPercent}
                      className={styles.themeItemPercent}
                    />
                    <ThemePassBadge percent={theme.passPercent} />
                  </>
                )
              }
              onClick={() => setThemeId(theme.id)}
            />
          ))}
        </>
      )}

      <StrategyProfitNote className={styles.note} />

      <Spinner visible={loading} />

      <Portal id={EDUCATION_MODAL_DRAWER_ID}>
        <FunctionalModalSidebar
          open={Boolean(themeId)}
          backLabel={strategy?.name}
          onClose={() => setThemeId(0)}
          zIndex={10}
        >
          <StrategyThemeDetail themeId={themeId} onTest={setTestTheme} />
        </FunctionalModalSidebar>
      </Portal>

      <Portal id={EDUCATION_MODAL_DRAWER_ID}>
        <FunctionalModalSidebar
          open={enrollModal}
          backLabel={strategy?.name}
          onClose={closeEnroll}
          zIndex={10}
        >
          <StrategyEnrollment
            strategyId={strategyId}
            onComplete={closeEnroll}
          />
        </FunctionalModalSidebar>
      </Portal>

      <Portal id={EDUCATION_MODAL_DRAWER_ID}>
        <FunctionalModalSidebar
          open={Boolean(testTheme)}
          headAddon={<StrategyAddon name={strategy?.name || ''} />}
          onClose={() => setTestTheme(null)}
          zIndex={10}
        >
          {testTheme && (
            <StrategyThemeTest
              themeId={testTheme.id}
              title={testTheme.name}
              onNextTheme={handleNextTheme}
              onThemeList={handleToThemeList}
              onRecomendation={handleRecomendation}
            />
          )}
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
