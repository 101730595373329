import isNull from 'lodash/isNull';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import orderBy from 'lodash/orderBy';

import { DEFAULT_LINE, DEFAULT_LINES } from '../../constants/orderBook';
import { roundFloatNumber } from '../format';

import { OrderBookLine } from '../../types/orderBook';
import { FinInfoExt } from '../../types/quotes';

type getTableLinesProps = {
  serverLines: OrderBookLine[];
  MIN_LINES_LENGTH?: number;
  defaultLines?: OrderBookLine[];
  DEFAULT_SPREAD_INDEX?: number;
  //Отсюда берем шаг цены
  finInfoExt?: FinInfoExt;
  //Параметр отвечающий за отображение цен без заявок
  isShowEmptyPrice?: boolean;
  //Параметр отвечающий за отображение спреда
  isShowSpread?: boolean;
};

interface OrberbookTableLines {
  lines: OrderBookLine[];
  upSpreadIndex: number;
  downSpreadIndex: number;
}

//Функция для построения линий стакана
export const getOrderBookTableLines = ({
  serverLines,
  MIN_LINES_LENGTH = 40,
  defaultLines = DEFAULT_LINES,
  DEFAULT_SPREAD_INDEX = 20,
  finInfoExt,
  isShowEmptyPrice,
  isShowSpread,
}: getTableLinesProps): OrberbookTableLines => {
  const priceStep = finInfoExt?.priceStep;
  let lines: OrderBookLine[] = [...defaultLines];
  let upSpreadIndex = DEFAULT_SPREAD_INDEX;
  let downSpreadIndex = DEFAULT_SPREAD_INDEX;

  //Аукцион - состояние при котором для одной цены могут выставляться заявки как на продажу, так и на покупку
  if (serverLines.some((order) => order.SellQty > 0 && order.BuyQty > 0)) {
    lines = lines.filter(
      ({ Price, SellQty, BuyQty }) =>
        Boolean(Price) || (!Price && (Boolean(SellQty) || Boolean(BuyQty)))
    );
    lines = orderBy(serverLines, 'Price', 'desc');

    //Код ниже взят из ДТ и переписан на js
    let buyNearPivot = -1;
    let sellNearPivot = lines.length;

    lines.forEach((order, i) => {
      if (!isNull(order.SellQty)) {
        sellNearPivot = Math.min(sellNearPivot, i);
      }

      if (!isNull(order.BuyQty)) {
        buyNearPivot = Math.max(buyNearPivot, i);
      }
    });

    const spreadIndex = Math.round((buyNearPivot + 1 + sellNearPivot) / 2);
    //Код выше взят из ДТ и переписан на js

    //Чтобы стакан корректно отображался нужно добавить до 40 строк
    if (lines.length < 40) {
      //Считаем сколько строк нужно добавить
      const addLinesCount = 40 - lines.length;
      const emptyArr = new Array(addLinesCount).fill(DEFAULT_LINE);

      lines = [...lines, ...emptyArr];
    }

    return {
      lines,
      upSpreadIndex: spreadIndex,
      downSpreadIndex: spreadIndex,
    };
  }

  const upPrices = orderBy(
    serverLines.filter((order) => order.SellQty > 0 && order.Price > 0),
    'Price',
    'asc'
  );

  const downPrices = orderBy(
    serverLines.filter((order) => order.BuyQty > 0 && order.Price > 0),
    'Price',
    'desc'
  );

  //Если не нужно отображать спред и разреженный вид
  if (!isShowEmptyPrice && !isShowSpread) {
    //Заполняем 20 линий и в конце разворачиваем массив, так как пустые линии должны находиться в начале
    const upLines = new Array(MIN_LINES_LENGTH / 2)
      .fill(DEFAULT_LINE)
      .map((line, index) => upPrices[index] || line)
      .reverse();
    //Пустые линии в конце, разворачивать не нужно
    const downLines = new Array(MIN_LINES_LENGTH / 2)
      .fill(DEFAULT_LINE)
      .map((line, index) => downPrices[index] || line);

    lines = [...upLines, ...downLines];
  }

  //Если нужно показывать только спред
  if (isShowSpread && priceStep && !isShowEmptyPrice) {
    //Верхняя граница спреда (по минимальной верхней цене)
    const minUpPrice = minBy(upPrices, 'Price')?.Price;
    //Нижняя граница спреда (по максимальной нижней цене)
    const maxDownPrice = maxBy(downPrices, 'Price')?.Price;

    //Заполняем цены спреда
    let spreadPrices: OrderBookLine[] = [];

    if (
      minUpPrice &&
      maxDownPrice &&
      roundFloatNumber(minUpPrice - maxDownPrice - priceStep) > 0
    ) {
      for (
        let i = roundFloatNumber(minUpPrice - priceStep);
        i > maxDownPrice;
        i = roundFloatNumber(i - priceStep)
      ) {
        spreadPrices.push({
          Price: roundFloatNumber(i),
          BuyQty: 0,
          SellQty: 0,
          LineId: -1,
          Revision: 0n,
          Yield: 0,
        });
      }
    }

    //Заполняем 20 линий и в конце разворачиваем массив, так как пустые линии должны находиться в начале
    const upLines = new Array(MIN_LINES_LENGTH / 2)
      .fill(DEFAULT_LINE, 0, MIN_LINES_LENGTH / 2)
      .map((line, index) => upPrices[index] || line)
      .reverse();
    //Пустые линии в конце, разворачивать не нужно
    const downLines = new Array(MIN_LINES_LENGTH / 2)
      .fill(DEFAULT_LINE, 0, MIN_LINES_LENGTH / 2)
      .map((line, index) => downPrices[index] || line);

    //Спред цены вставляем между
    lines = [...upLines, ...spreadPrices, ...downLines];
    //Нижняя граница смещается на кол-во спред цен
    downSpreadIndex = downSpreadIndex + spreadPrices.length;
  }

  //Если нужно показывать разреженный вид
  if (isShowEmptyPrice && priceStep) {
    const maxPrice = maxBy(upPrices, 'Price')?.Price;
    const minPrice = minBy(downPrices, 'Price')?.Price;
    //Верхняя граница спреда (по минимальной верхней цене)
    const minUpPrice = minBy(upPrices, 'Price')?.Price;
    //Нижняя граница спреда (по максимальной нижней цене)
    const maxDownPrice = maxBy(downPrices, 'Price')?.Price;

    if (maxPrice && minPrice) {
      //Идем от самой максимальной цены заявки к минимальной с шагом равным шагу цены
      for (
        let price = maxPrice, index = 0;
        price >= minPrice;
        price = roundFloatNumber(price - priceStep), index++
      ) {
        //Если заявка с такой ценой есть
        const currentLine = serverLines.find((line) => line.Price === price);
        //Иначе просто цена с учетом шага
        const defaultLine = {
          Price: roundFloatNumber(price),
          BuyQty: 0,
          SellQty: 0,
          LineId: -1,
          Revision: 0n,
          Yield: 0,
        };

        if (!isShowSpread && minUpPrice && maxDownPrice) {
          //Если не нужно показывать спред в разреженном стакане,то пропускаем цену из спреда
          if (price < minUpPrice && price > maxDownPrice) {
            //Нужно уменьшить индекс, так как мы  пропустили шаг цены (удаляем строку)
            index--;
            continue;
          }
        }

        // Так как изначально в линиях первые 40 элементов всегда предзаполнены, то для этих индексов производим замену
        if (index < 40) {
          lines.splice(index, 1, currentLine ?? defaultLine);
          // Далее просто добавляем новые строки
        } else {
          lines.push(currentLine ?? defaultLine);
        }
      }
    }

    upSpreadIndex = lines.findIndex((line) => line.Price === minUpPrice) + 1;
    downSpreadIndex = lines.findIndex((line) => line.Price === maxDownPrice);
  }

  return {
    lines,
    upSpreadIndex,
    downSpreadIndex,
  };
};
