import { Amount } from '@alfalab/core-components/amount';
import {
  AmountInput,
  AmountInputProps,
} from '@alfalab/core-components/amount-input';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Space } from '@alfalab/core-components/space';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

import styles from './MoneyInCards.module.css';

interface Props {
  amount: number | null;
  amountMinority: number;
  onAmountChange: AmountInputProps['onChange'];
  amountError: boolean;
}

export const MoneyInCardsParameters = ({
  amount,
  amountMinority,
  onAmountChange,
  amountError,
}: Props) => (
  <div>
    <Space fullWidth={true} direction="vertical">
      <Row gutter={16}>
        <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
          <AmountInput
            autoFocus
            label="Сумма"
            labelView="outer"
            id="amount"
            block={true}
            className={styles.inputSmall}
            autoComplete="off"
            size="s"
            currency="RUR"
            value={amount}
            minority={amountMinority}
            placeholder="0"
            onChange={onAmountChange}
            integerLength={12}
            error={amountError ? 'Лимит пополнения: 200 000 ₽' : undefined}
            hint={
              <>
                С одной карты: до 200 000 ₽ в день <br />
                По всем картам: до 600 000 ₽ в день
              </>
            }
          />
        </Col>
        <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
          <Typography.Text view="secondary-small" className={styles.label}>
            &nbsp;
          </Typography.Text>
          <div className={styles.plate}>
            <Typography.Text view="secondary-large" color="secondary">
              <span className={styles.feeInputContent}>
                Комиссия
                <TooltipDesktop
                  position="top"
                  content="Возможна комиссия Банка, выпустившего карту"
                >
                  <InformationCircleLineMIcon
                    className={styles.tooltipIcon}
                    width={15}
                    height={15}
                  />
                </TooltipDesktop>
              </span>
            </Typography.Text>

            <Amount.Pure
              minority={0}
              value={0}
              view="withZeroMinorPart"
              currency="RUR"
            />
          </div>
        </Col>
      </Row>
    </Space>
  </div>
);
