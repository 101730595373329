import isEqual from 'lodash/isEqual';
import { useMemo, useState } from 'react';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { ToggleOff, ToggleOn } from '@terminal/core/constants/icons';
import { isSingleFORTSMarketAvailable } from '@terminal/core/lib/domain/isSingleFORTSMarketAvailable';
import { BalanceSettingsProps } from '@terminal/core/types/layout';

import { useWidgetContext } from '../../../../shared';

import styles from './PortfolioBalanceSettings.module.css';

interface Props extends BaseModalProps {
  onClose: () => void;
  balanceSettingsProps?: BalanceSettingsProps;
  nodeId?: string;
}

export const PortfolioBalanceSettings = ({
  onClose,
  balanceSettingsProps,
  nodeId,
  ...baseModalProps
}: Props) => {
  const { updateNode } = useWidgetContext();
  const { accounts } = useAlfaDirectContext();

  const isEFRAccount = isSingleFORTSMarketAvailable(accounts);

  // Настройки отображения
  const [showBalance, setShowBalance] = useState(
    balanceSettingsProps?.showBalance ?? true
  );
  const [showPU, setShowPU] = useState(balanceSettingsProps?.showPU ?? true);
  const [showGO, setShowGO] = useState(
    balanceSettingsProps?.showGO ?? isEFRAccount
  );

  const newBalanceSettingsProps: BalanceSettingsProps = useMemo(
    () => ({
      showBalance,
      showPU,
      showGO,
    }),
    [showBalance, showGO, showPU]
  );

  const saveSettings = () => {
    if (nodeId) {
      updateNode(nodeId, { balanceSettingsProps: newBalanceSettingsProps });
    }

    onClose();
  };

  const isSaveDisabled = useMemo(() => {
    return isEqual(newBalanceSettingsProps, balanceSettingsProps);
  }, [balanceSettingsProps, newBalanceSettingsProps]);

  return (
    <BaseModal
      {...baseModalProps}
      className={styles.contextMenuSettings}
      onClose={onClose}
    >
      <div className={styles.settingsHeader}>
        <Typography.Text view="secondary-large" weight="bold">
          Информационная строка
        </Typography.Text>
        <IconButton
          size="xs"
          view="secondary"
          icon={CrossMIcon}
          onClick={onClose}
        />
      </div>
      <div className={styles.settingsContent}>
        <div
          onClick={() => setShowBalance((prev) => !prev)}
          className={styles.settingWrapper}
        >
          <Typography.Text view="secondary-large" weight="medium">
            Суммарная стоимость
          </Typography.Text>
          {showBalance ? <ToggleOn /> : <ToggleOff />}
        </div>
        <Typography.Text
          view="secondary-small"
          className={styles.hintTextForSwitch}
        >
          Суммарная стоимость портфеля <br />в рублях
        </Typography.Text>

        <div
          onClick={() => setShowPU((prev) => !prev)}
          className={styles.settingWrapper}
        >
          <Typography.Text view="secondary-large" weight="medium">
            Доходность
          </Typography.Text>
          {showPU ? <ToggleOn /> : <ToggleOff />}
        </div>
        <Typography.Text
          view="secondary-small"
          className={styles.hintTextForSwitch}
        >
          Прибыль или убыток по отображаемым
          <br />
          позициям за день и за все время
        </Typography.Text>

        {isEFRAccount && (
          <>
            <div
              onClick={() => setShowGO((prev) => !prev)}
              className={styles.settingWrapper}
            >
              <Typography.Text view="secondary-large" weight="medium">
                Гарантийное обеспечение
              </Typography.Text>
              {showGO ? <ToggleOn /> : <ToggleOff />}
            </div>
            <Typography.Text
              view="secondary-small"
              className={styles.hintTextForSwitch}
            >
              Гарантийное обеспечение по фьючерсам. <br />
              Отображается только при наличии данного <br />
              типа бумаг в позициях
            </Typography.Text>
          </>
        )}

        <div className={styles.buttonToolbar}>
          <Button
            className={styles.customButton}
            view="ghost"
            size="xxs"
            onClick={() => onClose()}
          >
            Отмена
          </Button>
          <Button
            className={styles.customButton}
            size="xxs"
            view="secondary"
            disabled={isSaveDisabled}
            onClick={saveSettings}
          >
            Применить
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
