import { RootStore } from '../../useStore';
import { finInstrumentByIdSelector } from './finInstrumentByIdSelector';
import { objectById } from './objectById';

import { InstrumentItem, ObjectItem } from '../../../types/core';

/**
 * Выбирает ценную бумагу по идентификатору финансового инструмента
 * */
export function objectByIdFiSelector(
  store: RootStore
): (idFi?: InstrumentItem['idFI']) => ObjectItem | undefined {
  return (idFi) => {
    const finInstrument = finInstrumentByIdSelector(store)(idFi);

    return objectById(store)(finInstrument?.idObject ?? NaN);
  };
}
