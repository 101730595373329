import { isBefore } from 'date-fns';
import minBy from 'lodash/minBy';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';
import { MAX_CERTIFICATES_COUNT } from '@terminal/core/constants/certificates';
import { usePrevious } from '@terminal/core/hooks/usePrevious';

import { useWidgetContext } from '../../../../shared';
import { CertificateHeading } from '../CertificateHeading';
import { SelectRejectingCertificate } from '../SelectRejectingCertificate';
import { SignCertificate } from '../SignCertificate';
import { Spinner } from '../Spinner';

import { useCertificateEnrollStore } from '../../model/store';

import { CertificateEnrollStep } from '../../model/types';

import styles from './CertificateEnroll.module.css';

interface Props {
  onSuccess?: () => void;
  headerSlot?: ReactNode;
  rejectButtonSlot?: ReactNode;
  bottomSlot?: ReactNode;
  signBottomSlot?: ReactNode;
}

export const CertificateEnroll: FC<Props> = ({
  onSuccess,
  headerSlot,
  rejectButtonSlot,
  bottomSlot,
  signBottomSlot,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isContinue, setIsContinue] = useState(false);

  const rejectEffectDone = useRef(false);
  const isCheckLoading = useRef<boolean>(false);

  const { useEnrollCertificate, useRejectCertificateEffect } =
    useWidgetContext();

  const [step, setStep, rejectingCertificate, setRejectingCertificate] =
    useCertificateEnrollStore((store) => [
      store.step,
      store.setStep,
      store.rejectingCertificate,
      store.setRejectingCertificate,
    ]);

  const {
    useCertificates,
    useCurrentCertificate,
    useConfirmCertificateSignOperation,
    useRejectCertificateSignOperation,
  } = useAlfaDirectContext();
  const certificates = useCertificates();
  const { certificateEnrollId, workingCertificate } = useCurrentCertificate();
  const { enrollCertificate } = useEnrollCertificate();

  const confirmCert = useConfirmCertificateSignOperation();
  const rejectCert = useRejectCertificateSignOperation();

  const isExpired =
    workingCertificate &&
    isBefore(
      workingCertificate.parsedPayload?.endDate || new Date(),
      new Date()
    );

  const [isCertificateRejectNeed, setIsCertificateRejectNeed] = useState(
    () => certificates.length === MAX_CERTIFICATES_COUNT || isExpired
  );

  useMount(() => {
    if (certificateEnrollId) {
      setStep(CertificateEnrollStep.Confirm);

      return;
    }

    if (rejectingCertificate) {
      setStep(CertificateEnrollStep.Reject);
      setIsContinue(true);

      return;
    }

    if (isExpired) {
      setRejectingCertificate(workingCertificate);
      setStep(CertificateEnrollStep.Reject);

      return;
    }

    if (isCertificateRejectNeed) {
      const certificateToReject =
        minBy(certificates, (c) => c.parsedPayload?.endDate) ||
        certificates.at(-1);

      if (certificateToReject) {
        setRejectingCertificate(certificateToReject);
        setStep(CertificateEnrollStep.Reject);
      } else {
        setStep(CertificateEnrollStep.SelectRejecting);
      }

      return;
    }

    enrollCertificate();
  });

  const prevRejectingCertificateId = usePrevious(
    rejectingCertificate?.idCertificate
  );

  useEffect(() => {
    if (
      prevRejectingCertificateId &&
      rejectingCertificate?.idCertificate &&
      prevRejectingCertificateId !== rejectingCertificate?.idCertificate
    ) {
      setIsContinue(false);
    }
  }, [prevRejectingCertificateId, rejectingCertificate?.idCertificate]);

  useEffect(() => {
    setIsLoading(false);
  }, [isCertificateRejectNeed]);

  useRejectCertificateEffect(() => {
    rejectEffectDone.current = true;
    setIsCertificateRejectNeed(false);
    setStep(CertificateEnrollStep.Confirm);
    setIsLoading(false);
    setRejectingCertificate(null);
    enrollCertificate();
  });

  return (
    <div>
      <div className={styles.content}>
        {headerSlot || <CertificateHeading />}

        {step === CertificateEnrollStep.Confirm && (
          <SignCertificate
            certificateId={certificateEnrollId}
            onSuccess={onSuccess}
            initAction={confirmCert}
            successMsg={{
              title: 'Успех',
              text: 'Сертификат подтвержден',
            }}
            errorMsg={{
              title: 'Ошибка',
              text: 'Не удалось подтвердить сертификат',
            }}
            bottomSlot={signBottomSlot}
          />
        )}
        {step === CertificateEnrollStep.Reject && (
          <SignCertificate
            certificateId={rejectingCertificate?.idCertificate}
            onSuccess={() => {
              // Переходим в режим ожидания, и ждём пока отработает useRejectCertificateEffect
              if (!rejectEffectDone.current) {
                setIsLoading(true);
              }
            }}
            initAction={(cId) => {
              if (!isContinue) {
                return rejectCert(cId);
              }
            }}
            successMsg={{
              title: 'Успех',
              text: 'Сертификат отозван',
            }}
            errorMsg={{
              title: 'Ошибка',
              text: 'Не удалось отозван сертификат',
            }}
            bottomSlot={signBottomSlot}
          />
        )}

        {step === CertificateEnrollStep.SelectRejecting && (
          <SelectRejectingCertificate rejectButtonSlot={rejectButtonSlot} />
        )}

        {(isLoading || isCheckLoading.current) && (
          <div className={styles.spinnerWrapper}>
            <Spinner isVisible />
          </div>
        )}
      </div>

      {bottomSlot}
    </div>
  );
};
