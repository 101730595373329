import { useCallback } from 'react';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { Gap } from '@alfalab/core-components/gap';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Input } from '@alfalab/core-components/input';
import { Switch } from '@alfalab/core-components/switch';

import { Stepper } from '@terminal/common/components/Stepper';
import { shallow, useStore } from '@terminal/core/store';

export const Notifications = () => {
  const [
    setSetting,
    isShowNotifications,
    isShowTradeNotifications,
    isShowBrokerNotifications,
    isShowSystemNotifications,
    maxCount,
    time,
  ] = useStore(
    (state) => [
      state.setSetting,
      state.settings.notifications.isShow,
      state.settings.notifications.isShowTradeNotifications,
      state.settings.notifications.isShowBrokerNotifications,
      state.settings.notifications.isShowSystemNotifications,
      state.settings.notifications.maxCount,
      state.settings.notifications.time,
    ],
    shallow
  );

  const setMaxCount = useCallback(
    (value: number) => {
      const minCount = 1;
      const maxCount = 10;

      if (value <= minCount) {
        value = minCount;
      }

      if (value >= maxCount) {
        value = maxCount;
      }

      setSetting('notifications', 'maxCount', value);
    },
    [setSetting]
  );

  const setTime = useCallback(
    (value: number) => {
      const minCount = 1000;
      const maxCount = 10000;

      if (value <= minCount) {
        value = minCount;
      }

      if (value >= maxCount) {
        value = maxCount;
      }

      setSetting('notifications', 'time', value);
    },
    [setSetting]
  );

  return (
    <div>
      <Switch
        checked={isShowNotifications}
        label="Показывать нотификации"
        onChange={() => {
          setSetting('notifications', 'isShow', !isShowNotifications);
        }}
        block
      />
      <Gap size="m" />
      <Row>
        <Col width={6}>
          <Input
            labelView="outer"
            label="Количество, Max"
            hint="Одновременно на рабочем экране"
            value={`${maxCount} шт`}
            disabled={!isShowNotifications}
            rightAddons={
              <div>
                <Stepper
                  onAdd={() => {
                    setMaxCount(maxCount + 1);
                  }}
                  onSub={() => {
                    setMaxCount(maxCount - 1);
                  }}
                />
              </div>
            }
            block
          />
        </Col>
        <Col width={6}>
          <Input
            labelView="outer"
            label="Время отображения сообщений"
            value={`${Math.round(time / 1000)} сек`}
            disabled={!isShowNotifications}
            rightAddons={
              <div>
                <Stepper
                  onAdd={() => {
                    setTime(time + 1000);
                  }}
                  onSub={() => {
                    setTime(time - 1000);
                  }}
                />
              </div>
            }
            block
          />
        </Col>
      </Row>
      <Gap size="2xl" />
      <Checkbox
        block
        key="isShowTradeNotifications"
        label="Торговые уведомления"
        hint="Изменение статуса заявок, операций, переводов и поручений"
        checked={isShowTradeNotifications}
        disabled={!isShowNotifications}
        onChange={() => {
          setSetting(
            'notifications',
            'isShowTradeNotifications',
            !isShowTradeNotifications
          );
        }}
      />
      <Gap size="xl" />
      <Checkbox
        block
        key="isShowSystemNotifications"
        label="Системные уведомления"
        hint="Результирующие сообщения о взаимодействии с интерфейсом"
        checked={isShowSystemNotifications}
        disabled={!isShowNotifications}
        onChange={() => {
          setSetting(
            'notifications',
            'isShowSystemNotifications',
            !isShowSystemNotifications
          );
        }}
      />
      <Gap size="xl" />
      <Checkbox
        block
        key="isShowBrokerNotifications"
        label="Уведомления от брокера"
        hint="Сообщения от брокера об изменениях режима торгов, работы бирж и тд"
        checked={isShowBrokerNotifications}
        disabled={!isShowNotifications}
        onChange={() => {
          setSetting(
            'notifications',
            'isShowBrokerNotifications',
            !isShowBrokerNotifications
          );
        }}
      />
    </div>
  );
};
