import { useState } from 'react';

import { trackPwaPasswordSend } from '@terminal/core/lib/analytics';
import { shallow, useStore } from '@terminal/core/store';

import { useLoginPinCodeStore } from '../lib/storage';

export const useLoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [loginWithPassword, clientState, error, lkAuthError] = useStore(
    (state) => [
      state.loginWithPassword,
      state.appState,
      state.connectionError,
      state.lkAuthError,
    ],
    shallow
  );

  const hasPinCode = useLoginPinCodeStore((store) => store.hasPinCode);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (username && password) {
      trackPwaPasswordSend();
      loginWithPassword(username, password, await hasPinCode());
    }
  };

  return {
    clientState,
    password,
    error,
    lkAuthError,
    username,
    onSubmit,
    setUsername,
    setPassword,
  };
};
