import { SubAccountPositionItem } from '../../types/subAccountPosition';

export const getPositionQty = (position?: SubAccountPositionItem): number =>
  position
    ? position?.backPos +
      position?.buyQty -
      position?.sellQty +
      position?.trnIn -
      position?.trnOut
    : 0;
