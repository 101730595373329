import { useEffect, useState } from 'react';

import { CONTEST_LADDER_DEFAULT_PAGE_SIZE } from '../lib/const/ladder';

import { ContestLadderFilter } from '../model/types/ContestLadderFilter';
import {
  ContestLadderSortDirection,
  ContestLadderSortField,
} from '../model/types/ContestLadderSortParams';

export function useContestLadderPage(startPage: number = 1) {
  const [page, setPage] = useState(startPage);
  const [pageSize, setPageSize] = useState(CONTEST_LADDER_DEFAULT_PAGE_SIZE);
  const [sort, setSort] = useState<ContestLadderSortDirection>('asc');
  const [sortby, setSortby] = useState<ContestLadderSortField>('position');
  const [filter, setFilter] = useState<ContestLadderFilter>({});

  useEffect(() => {
    setPage(1);
  }, [filter, sort, sortby, pageSize]);

  useEffect(() => {
    setPage(startPage);
  }, [startPage]);

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    sort,
    setSort,
    sortby,
    setSortby,
    filter,
    setFilter,
  };
}
