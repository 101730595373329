import { useCallback, useEffect, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { AddMIcon } from '@alfalab/icons-glyph/AddMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import {
  ActiveTransfer,
  SecuritiesPosition,
} from '@terminal/core/lib/rest/lkSecurities';

import { ActivesList } from '../ActivesList';
import { SecurityActiveEditor } from '../SecurityActiveEditor';

import styles from './SecuritySection.module.css';

interface SecuritySectionProps {
  type: 'in' | 'out' | 'intra';
  position?: SecuritiesPosition | null;
  actives?: ActiveTransfer[];
  alfaMarket: string;
  counterpartyMarket: string;
  contrAlfaBank: boolean;
  // Несколько бумаг
  multiply?: boolean;
  onSaveSecurity?: (sec: ActiveTransfer, index?: number | null) => void;
  onDeleteSecurity?: (index: number) => void;
  onDeleteSecuritySilent?: (index: number) => void;
  // Параметры сделки
  dealParams: boolean;
  generatedReference?: string;
}

export const SecuritySection = (props: SecuritySectionProps) => {
  const [showList, setShowList] = useState(
    Boolean(props.multiply && props.actives && props.actives?.length > 0)
  );
  const [redactorOpen, setRedactorOpen] = useState(false);
  const [redactSecurityIndex, setRedactSecurityIndex] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (showList && props.actives && props.actives.length === 0) {
      setShowList(false);
    }
  }, [props.actives, showList]);

  const closeRedactor = useCallback(() => {
    setRedactorOpen(false);
    setRedactSecurityIndex(null);
  }, []);

  const handleRedact = useCallback((index: number) => {
    setRedactorOpen(true);
    setRedactSecurityIndex(index);
  }, []);

  const { onSaveSecurity, onDeleteSecuritySilent } = props;
  const handleSaveFirst = useCallback(
    (active: ActiveTransfer) => {
      if (onSaveSecurity) {
        onSaveSecurity(active, 0);
      }
    },
    [onSaveSecurity]
  );

  const handleUnfilledFirst = useCallback(() => {
    if (onDeleteSecuritySilent) {
      onDeleteSecuritySilent(0);
    }
  }, [onDeleteSecuritySilent]);

  const handleRedactSave = (active: ActiveTransfer) => {
    if (onSaveSecurity) {
      onSaveSecurity(active, redactSecurityIndex);
    }

    closeRedactor();
  };

  return (
    <>
      {props.multiply && showList && (
        <>
          <Typography.Text
            view="primary-small"
            weight="bold"
            tag="p"
            defaultMargins={false}
          >
            Ценная бумага
          </Typography.Text>
          <ActivesList
            className={styles.activeList}
            actives={props.actives || []}
            onRedact={handleRedact}
            onDelete={(i) =>
              props.onDeleteSecurity && props.onDeleteSecurity(i)
            }
          />
        </>
      )}
      <Space fullWidth direction="vertical">
        {!showList && (
          <SecurityActiveEditor
            active={
              !props.multiply && props.actives ? props.actives[0] : undefined
            }
            transferType={props.type}
            position={props.position}
            alfaMarket={props.alfaMarket}
            counterpartyMarket={props.counterpartyMarket}
            contrAlfaBank={props.contrAlfaBank}
            generatedReference={props.generatedReference}
            autosave={true}
            onSave={handleSaveFirst}
            onUnfilled={handleUnfilledFirst}
          />
        )}

        {props.multiply && !showList && (
          <Row>
            <Col width={12}>
              <Button
                block
                view="tertiary"
                size="s"
                disabled={!props.actives || props.actives.length === 0}
                onClick={() => setShowList(true)}
                leftAddons={<AddMIcon width={18} height={18} />}
              >
                Добавить ценные бумаги
              </Button>
            </Col>
          </Row>
        )}

        {props.multiply && showList && (
          <Row>
            <Col width={6}>
              <Button
                block
                size="s"
                onClick={() => setRedactorOpen(true)}
                leftAddons={<AddMIcon width={18} height={18} />}
              >
                Добавить ценную бумагу
              </Button>
            </Col>
          </Row>
        )}
      </Space>

      <Portal id="securities-transfer-modals">
        <FunctionalModalSidebar
          open={redactorOpen}
          backLabel="Зачисление"
          onClose={closeRedactor}
        >
          {redactorOpen && (
            <SecurityActiveEditor
              transferType={props.type}
              position={props.position}
              active={
                redactSecurityIndex !== null && props.actives
                  ? props.actives[redactSecurityIndex]
                  : undefined
              }
              alfaMarket={props.alfaMarket}
              counterpartyMarket={props.counterpartyMarket}
              contrAlfaBank={props.contrAlfaBank}
              generatedReference={props.generatedReference}
              onSave={handleRedactSave}
            />
          )}
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
