import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC } from 'react';
import { Spinner } from '@alfalab/core-components/spinner';
import { Typography } from '@alfalab/core-components/typography';

import styles from './Countdown.module.css';

interface Props {
  countdown: number;
}

export const Countdown: FC<Props> = (props) => {
  const { countdown } = props;
  const formatedTime = format(countdown, 'mm:ss', { locale: ru });

  return (
    <div className={styles.root}>
      <Spinner visible size="s" />
      <Typography.Text view="secondary-small">{formatedTime}</Typography.Text>
    </div>
  );
};
