import cn from 'classnames';

import { CorporateActionHistoryHeader } from '@terminal/core/lib/rest/lkCorporateActions';

import { HistoryStatus } from '../HistoryStatus';

import styles from './HistoryItem.module.css';

interface HistoryItemProps {
  item: CorporateActionHistoryHeader;
  odd?: boolean;
  onClick?: (item: CorporateActionHistoryHeader) => void;
}

export const HistoryItem = ({ item, odd, onClick }: HistoryItemProps) => {
  return (
    <div
      className={cn(styles.container, {
        [styles.odd]: !!odd,
        [styles.even]: !odd,
      })}
      onClick={() => onClick && onClick(item)}
    >
      <div className={styles.content}>
        <div className={styles.description}>
          <div className={styles.title}>{item.title}</div>
          <div className={styles.subtitle}>{item.subTitle}</div>
        </div>
        <div className={styles.count}>{item.value}</div>
      </div>
      <div className={styles.status}>
        <HistoryStatus type={item.status} text={item.statusText} />
      </div>
    </div>
  );
};
