import { useInfiniteQuery } from 'react-query';

import { getOperationsHistory, InvestQueryKeys } from '../lib/rest/investApi';
import { USE_PASSPORT } from '../lib/rest/lkApi';
import useNotification from './useNotification';

import { OperationHistoryType } from '../types/operation';
import { NotificationType } from '../types/ui';

export enum AssetType {
  ID_OBJECT = 'ID_OBJECT',
  SYMBOL_OBJECT = 'SYMBOL_OBJECT',
}

/**
 *   subAccount: string; 456454-000
 *   dateFrom?: string; UTC Date - 2022-01-26T05:34:20.680Z
 *   dateTo?: string; UTC Date - 2022-02-26T05:34:20.680Z
 *   assetId?: string;
 */
export interface InfiniteOperationsHistoryParams {
  subAccount: string;
  dateFrom?: string;
  dateTo?: string;
  assetId?: string;
}

export const useInfiniteOperationsHistory = (
  params: InfiniteOperationsHistoryParams
) => {
  const addNotification = useNotification();

  const assetIds = params?.assetId ? [params?.assetId?.toUpperCase()] : [];

  return useInfiniteQuery(
    [InvestQueryKeys.OperationsHistory, params],
    ({ pageParam: cursor }) => {
      return getOperationsHistory({
        ...params,
        assetIds,
        cursor,
        assetIdType: assetIds ? AssetType.SYMBOL_OBJECT : AssetType.ID_OBJECT,
        maxElements: 100,
        operationTypes: [
          OperationHistoryType.E,
          OperationHistoryType.D,
          OperationHistoryType.S,
        ].join(','),
      });
    },
    {
      enabled: USE_PASSPORT,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе лимита',
        });
      },
      getNextPageParam: (lastPage) => {
        return lastPage.cursor;
      },
    }
  );
};
