import { Divider } from '@alfalab/core-components/divider';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';

import { QualStatus } from '@terminal/core/lib/rest/lkAccountInfo';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { Test } from '@terminal/core/lib/rest/lkTesting';

import moexLogo from './images/logo_moex.svg';
import spbLogo from './images/logo_spb.svg';
import { PlatformForts } from './PlatformForts';
import { PlatformSelt } from './PlatformSelt';
import { PlatformSpbex } from './PlatformSpbex';
import { RegistrationState } from './RegistrationState';
import { getStateLabel } from './shared';

import {
  useFortsRegistration,
  useSpbexRegistration,
  useTradingPlatformsState,
} from '../../hooks';

import styles from './Platforms.module.css';

interface PlatformsProps {
  treaty: Treaty;
  testPassed: boolean;
  qualStatus: QualStatus;
  testItems: Test[];
  fortsDisabled?: boolean;
}

export const Platforms = ({
  treaty,
  testPassed,
  qualStatus,
  testItems,
  fortsDisabled,
}: PlatformsProps) => {
  const {
    isFetching: platformsPending,
    data: platforms,
    refetch: platformsStateRefetch,
  } = useTradingPlatformsState(treaty?.treaty);

  const { isLoading: fortsRegPending, mutateAsync: fortsRegistration } =
    useFortsRegistration();

  const { isLoading: spbexRegPending, mutateAsync: spbexRegistration } =
    useSpbexRegistration();

  const registrateFortsSubaccount = (subAccount: string) => {
    fortsRegistration({
      params: {
        accCode: subAccount,
      },
      signOptions: {
        successText: 'Регистрация на срочном рынке FORTS прошла успешно',
        successCallback: () => {
          platformsStateRefetch();
        },
      },
    });
  };

  const registrateOnSpbex = () => {
    if (!treaty) {
      return;
    }

    spbexRegistration({
      params: {
        treaty: treaty.treaty,
      },
      signOptions: {
        successText: 'Регистрация на СПБ Бирже прошла успешно',
        successCallback: () => {
          platformsStateRefetch();
        },
      },
    });
  };

  if (platformsPending) {
    return <Loader />;
  }

  if (!platforms) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Typography.Text view="primary-small" className={styles.title}>
        Регистрация на площадках
      </Typography.Text>

      <div className={styles.logo}>
        <img src={moexLogo} alt="MOEX logo" />
      </div>

      <div className={styles.platforms}>
        <div className={styles.platformItem}>
          <div className={styles.platformLabel}>Фондовый рынок</div>
          <div className={styles.platformStatus}>
            <RegistrationState
              state="Registred"
              label={getStateLabel('Registred')}
            />
          </div>
        </div>

        <PlatformSelt seltStatus={platforms.selt} />

        {treaty.isForeignMarket && (
          <div className={styles.platformItem}>
            <div className={styles.platformLabel}>Срочный рынок FORTS</div>
            <div className={styles.platformStatus}>
              <RegistrationState
                state="NotAvailable"
                label={getStateLabel('NotAvailable', 'Иностранный рынок')}
              />
            </div>
          </div>
        )}

        {!treaty.isRepo && !treaty.isForeignMarket && (
          <PlatformForts
            fortsState={platforms.forts}
            treaty={treaty}
            testPassed={testPassed}
            qualStatus={qualStatus}
            registrationPending={fortsRegPending}
            onRegistrateAccount={registrateFortsSubaccount}
            testItems={testItems}
            fortsDisabled={fortsDisabled}
          />
        )}
      </div>

      <Divider className={styles.divider} />

      <div className={styles.logo}>
        <img src={spbLogo} alt="SPB logo" />
      </div>

      {treaty.isForeignMarket && platforms.spbex === 'NotRegistred' && (
        <div className={styles.platformItem}>
          <div className={styles.platformLabel}>Срочный рынок FORTS</div>
          <div className={styles.platformStatus}>
            <RegistrationState
              state="NotAvailable"
              label={getStateLabel('NotAvailable', 'Иностранный рынок')}
            />
          </div>
        </div>
      )}

      {(!treaty.isForeignMarket || platforms.spbex !== 'NotRegistred') && (
        <div className={styles.platforms}>
          <PlatformSpbex
            spbexStatus={platforms.spbex}
            registrationPending={spbexRegPending}
            onRegistration={registrateOnSpbex}
          />
        </div>
      )}
    </div>
  );
};
