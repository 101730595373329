import cn from 'classnames';
import React from 'react';

import styles from './DotsPagination.module.css';

interface Props {
  step: number;
  stepsNumber: number;
}

export const DotsPagination = ({ step, stepsNumber }: Props) => {
  const dotsNumber = new Array(stepsNumber).fill(0);

  return (
    <div className={styles.dotsContainer}>
      {dotsNumber.map((dot, index) => (
        <div
          key={index}
          className={cn(
            styles.stepDot,
            index + 1 === step && styles.stepDotActive
          )}
        />
      ))}
    </div>
  );
};
