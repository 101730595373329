import { ObjectGroup } from '../client/entities';

import { ObjectTypeItem } from '../../types/core';

/**
 * @returns стоимость актива
 * */
export function getTorgPosCostRur(
  objectType: ObjectTypeItem,
  TorgPosCost: number | null,
  NominalCurPrice: number,
  MarketCurPrice: number
): number | null {
  if (TorgPosCost === null) {
    return null;
  }

  if (objectType?.idObjectGroup === ObjectGroup.Bonds) {
    return TorgPosCost * NominalCurPrice;
  }

  return TorgPosCost * MarketCurPrice;
}
