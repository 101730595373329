import { Actions, Model, TabNode } from 'flexlayout-react';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import set from 'lodash/set';

import { WidgetsNameMap, WidgetWithName } from '../constants/Layout';

import {
  BaseWidgetConfig,
  Link,
  LinkGroups,
  LinkName,
  TableProps,
  Widget,
  WidgetLinkProps,
} from '../types/layout';

/**
 * Функция для привязки таба к группе линковки
 * @param nodeId string,
 * @param activeLayoutKey number,
 * @param linkName LinkName,
 * @param layoutLinks LinkGroups[],
 * @param updateNode (nodeId: string, config: BaseWidgetConfig) => void
 * @param params: LinkProps | null,
 * @return layoutLinks LinkGroups[];
 */
export const linkToGroup = (
  nodeId: string,
  activeLayoutKey: number,
  linkName: LinkName,
  layoutLinks: LinkGroups[],
  updateNode: (
    nodeId: string,
    config: BaseWidgetConfig,
    symbol?: string
  ) => void,
  //Пропсы таба
  params: WidgetLinkProps | null
): LinkGroups[] => {
  //Если выбираем линковку по умолчанию = удаляем привязку
  if (
    linkName === LinkName.DEFAULT &&
    !isNull(params) &&
    !isUndefined(params.link)
  ) {
    return unlinkFromGroup(
      nodeId,
      activeLayoutKey,
      params.link,
      layoutLinks,
      updateNode
    );
  }

  //Если виджет был привязан к другой группе, то нужно его удалить оттуда
  let newLayoutLinks =
    !isNull(params) &&
    !isUndefined(params?.link) &&
    params?.link !== LinkName.DEFAULT
      ? unlinkFromGroup(
          nodeId,
          activeLayoutKey,
          params.link,
          layoutLinks,
          updateNode,
          false
        )
      : [...layoutLinks];

  let newLink: Link = { ...newLayoutLinks[activeLayoutKey][linkName] };

  //Если у линковки нет пропсов, то устанавливаем их
  if (
    isUndefined(newLink.tableProps?.accounts) &&
    !isNull(params) &&
    !isUndefined(params.tableProps?.accounts)
  ) {
    newLink = set(newLink, 'tableProps.accounts', params.tableProps?.accounts);
  }

  if (
    isUndefined(newLink.idFi) &&
    !isNull(params) &&
    !isUndefined(params.idFi)
  ) {
    newLink = set(newLink, 'idFi', params.idFi);
  }

  if (
    isUndefined(newLink.symbol) &&
    !isNull(params) &&
    !isUndefined(params.symbol)
  ) {
    newLink = set(newLink, 'symbol', params.symbol);
  }

  //Добавляем айдишники виджета в группу
  if (!newLink.nodeIds.includes(nodeId)) {
    newLink = set(newLink, 'nodeIds', [...newLink.nodeIds, nodeId]);
  }

  const { idFi, tableProps, symbol } = newLink;
  //Обновляем конфиг виджета
  const newConfig = {
    ...(!isUndefined(idFi) && { idFi }),
    ...(!isUndefined(symbol) && { symbol }),
    ...(!isUndefined(tableProps) && { tableProps }),
    link: linkName,
  };

  updateNode(nodeId, newConfig as BaseWidgetConfig, symbol);

  //Сохраняем новые настройки группы линковки
  newLayoutLinks.splice(activeLayoutKey, 1, {
    ...newLayoutLinks[activeLayoutKey],
    [linkName]: newLink,
  });

  return newLayoutLinks;
};

/**
 * Функция для отвязки таба от группы линковки
 * @param nodeId: string,
 * @param activeLayoutKey number,
 * @param linkName LinkName,
 * @param layoutLinks LinkGroups[],
 * @return layoutLinks LinkGroups[];
 */
export const unlinkFromGroup = (
  nodeId: string,
  activeLayoutKey: number,
  linkName: LinkName,
  layoutLinks: LinkGroups[],
  updateNode: (nodeId: string, config: BaseWidgetConfig) => void,
  withUpdate: boolean = true
): LinkGroups[] => {
  const prevLayoutLinks = [...layoutLinks];
  let newLink: Link = { ...prevLayoutLinks[activeLayoutKey][linkName] };

  //Удаляем айдишник виджета из группы
  newLink = set(
    newLink,
    'nodeIds',
    newLink.nodeIds.filter((id) => id !== nodeId)
  );

  //Если в группе не осталось виджетов, то  сбрасываем "управляющие" пропсы
  if (newLink.nodeIds.length === 0) {
    newLink = set(newLink, 'idFi', undefined);
    newLink = set(newLink, 'tableProps', undefined);
    newLink = set(newLink, 'symbol', undefined);
  }

  //Сохраняем новые настройки группы линковки
  prevLayoutLinks.splice(activeLayoutKey, 1, {
    ...layoutLinks[activeLayoutKey],
    [linkName]: newLink,
  });

  //Удаляем привязку пропсов виджета к группе
  if (withUpdate) {
    updateNode(nodeId, { link: undefined } as BaseWidgetConfig);
  }

  return prevLayoutLinks;
};

/**
 * Функция для обновления пропсов виджета группы
 * @param activeLayoutKey: number,
 * @param linkName: LinkName,
 * @param layoutLinks: LinkGroups[],
 * @param config: WidgetLinkProps,
 * @param model: Model
 * @return layoutLinks LinkGroups[];
 */
export const updateLinkProps = (
  activeLayoutKey: number,
  linkName: LinkName,
  layoutLinks: LinkGroups[],
  config: WidgetLinkProps,
  model: Model,
  symbol?: string
): LinkGroups[] => {
  let prevLayoutLinks = [...layoutLinks];
  const widgetLink = { ...prevLayoutLinks[activeLayoutKey][linkName] };

  //Обновляем конфиг виджета
  const newConfig = {
    ...(!isUndefined(config.idFi) && { idFi: config.idFi }),
    ...(!isUndefined(config.symbol) && { symbol: config.symbol }),
    ...(!isUndefined(config.linkedPrice) && {
      linkedPrice: config.linkedPrice,
    }),
    ...(!isUndefined(config.tableProps) && { tableProps: config.tableProps }),
  };

  //Апдейтим пропсы виджетов
  widgetLink.nodeIds.forEach((id) => {
    //@ts-expect-error
    const node: TabNode = model.getNodeById(id || '');

    if (!node) {
      return;
    }

    const prevConfig: null | WidgetLinkProps = node?.getConfig();
    const tableProps: TableProps = {
      ...prevConfig?.tableProps,
      ...newConfig.tableProps,
    };

    let name = node?.getName();
    const widget = node?.getComponent() as Widget;

    if (symbol && widget && WidgetWithName.includes(widget)) {
      name = WidgetsNameMap.get(widget) || 'Вкладка';
    }

    if (node && name && id) {
      model.doAction(
        Actions.updateNodeAttributes(id, {
          name,
          config: {
            ...prevConfig,
            ...newConfig,
            tableProps,
          },
        })
      );
    }
  });

  prevLayoutLinks.splice(activeLayoutKey, 1, {
    ...prevLayoutLinks[activeLayoutKey],
    [linkName]: { ...widgetLink, ...newConfig },
  });

  return prevLayoutLinks;
};
