import React, { useEffect, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { CheckboxGroup } from '@alfalab/core-components/checkbox-group';
import { Divider } from '@alfalab/core-components/divider';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
import { Radio } from '@alfalab/core-components/radio';
import { RadioGroup } from '@alfalab/core-components/radio-group';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowForwardMIcon } from '@alfalab/icons-glyph/ArrowForwardMIcon';

import {
  ClientRiskProfileResponse,
  FormItem,
  UpdateRiskProfileResult,
} from '@terminal/core/lib/rest/lkRiskProfile';

import styles from './RiskProfile.module.css';

export interface RiskProfileTestProps {
  riskProfile: ClientRiskProfileResponse;
  refetchProfile: () => {};
  openDocument: () => void;
  data: UpdateRiskProfileResult | undefined;
  isUpdating: boolean;
  updateProfile: () => void;
  answers: Record<string, string[]>;
  setAnswers: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  treaty: number;
  isProfileUpdated: boolean;
  openDetails: () => void;
}

export interface QuestionFormProps {
  question: FormItem;
  setAnswers: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  answers: Record<string, string[]>;
}

export const RiskProfileQuestion = ({
  question,
  setAnswers,
  answers,
}: QuestionFormProps) => {
  const handleAnswer = (
    event?:
      | React.ChangeEvent
      | React.MouseEvent<Element, MouseEvent>
      | undefined,
    payload?:
      | { checked: boolean; name?: string | undefined }
      | { value: string; name?: string | undefined }
      | undefined
  ) => {
    if (payload && 'value' in payload) {
      setAnswers((prev) => ({
        ...prev,
        [question.answers[0].id]: [payload.value],
      }));
    }
    if (payload && 'checked' in payload) {
      setAnswers((prev) => {
        if (payload.name && !prev[question.answers[0].id]) {
          return {
            ...prev,
            [question.answers[0].id]: [payload.name],
          };
        }
        return {
          ...prev,
          [question.answers[0].id]:
            payload.name &&
            prev[question.answers[0].id] &&
            !prev[question.answers[0].id].includes(payload.name)
              ? [...prev[question.answers[0].id], payload.name]
              : prev[question.answers[0].id].filter(
                  (ans) => ans !== payload.name
                ),
        };
      });
    }
  };

  const renderAnswers = () => {
    return question.answers.map((anketAnswers) => {
      switch (anketAnswers.type) {
        case 'radio':
          return (
            <RadioGroup
              direction="vertical"
              name="radioGroup"
              onChange={handleAnswer}
              value={answers[anketAnswers.id]?.[0]}
            >
              {anketAnswers.options.values.map((opt) => (
                <Radio
                  key={opt.id}
                  label={`${opt.title}${opt.hint ? ` (${opt.hint})` : ''}`}
                  value={opt.id}
                />
              ))}
            </RadioGroup>
          );
        case 'checkbox':
          return (
            <CheckboxGroup onChange={handleAnswer}>
              {anketAnswers.options.values.map((opt) => (
                <Checkbox
                  label={`${opt.title}${opt.hint ? ` (${opt.hint})` : ''}`}
                  name={opt.id}
                  checked={
                    !!answers[anketAnswers.id] &&
                    answers[anketAnswers.id].includes(opt.id)
                  }
                />
              ))}
            </CheckboxGroup>
          );
      }
      return null;
    });
  };
  return (
    <div className={styles.questionContainer}>
      <Typography.Text
        className={styles.testQuestionTitle}
        view="secondary-large"
        weight="bold"
      >
        {question.title}
      </Typography.Text>
      {renderAnswers().map((AnswerGroup, index, allAnswers) => (
        <Space fullWidth>
          {AnswerGroup}
          {index !== allAnswers.length - 1 && (
            <Divider className={styles.divider} />
          )}
        </Space>
      ))}
    </div>
  );
};

export const RiskProfileTest = ({
  riskProfile,
  refetchProfile,
  isUpdating,
  updateProfile,
  answers,
  setAnswers,
  isProfileUpdated,
  openDetails,
}: RiskProfileTestProps) => {
  const [testStep, setTestStep] = useState(0);

  const { anket } = riskProfile;

  const { form } = anket;

  useEffect(() => {
    if (isProfileUpdated) {
      refetchProfile();
      setTestStep(0);
      openDetails();
    }
  }, [isProfileUpdated, openDetails, refetchProfile]);

  const testLength = form.length;
  const percentage = Math.round((100 * testStep) / testLength);
  const handleNextClick = () => {
    const isLastQuestion = testStep + 1 === testLength;
    if (isLastQuestion) {
      updateProfile();
    } else {
      setTestStep((prev) => prev + 1);
    }
  };

  return (
    <div className={styles.entryPointContent}>
      <Typography.Text view="primary-medium" weight="bold">
        Риск-профилирование
      </Typography.Text>
      <Typography.Text
        className={styles.testSubtitle}
        view="secondary-small"
        color="secondary"
      >
        Пройдите тест, который определит ваш тип инвестора, и какие активы стоит
        рассмотреть для вложения денег
      </Typography.Text>
      <Typography.Text view="secondary-small">{`Вопрос ${
        testStep + 1
      } из ${testLength}: Тест пройден на ${percentage}%`}</Typography.Text>
      <ProgressBar view="accent" value={percentage} />
      <RiskProfileQuestion
        answers={answers}
        setAnswers={setAnswers}
        question={form[testStep]}
      />
      {
        <Button
          className={styles.textNextButton}
          rightAddons={<ArrowForwardMIcon />}
          size="s"
          onClick={handleNextClick}
          loading={isUpdating}
          disabled={!answers[form[testStep].answers[0].id]}
        >
          Далее
        </Button>
      }
    </div>
  );
};
