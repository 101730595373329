import { PlateDesktop } from '@alfalab/core-components/plate/desktop';
import { StatusBadge } from '@alfalab/core-components-status-badge';

import styles from './ServiceBanner.module.css';

interface ServiceBannerProps {
  message: string;
  className?: string;
}

export const ServiceBanner = ({ message, className }: ServiceBannerProps) => {
  return (
    <PlateDesktop
      className={className}
      border={false}
      leftAddons={
        <StatusBadge className={styles.badge} view="attention-alert" />
      }
      view="attention"
    >
      {message}
    </PlateDesktop>
  );
};
