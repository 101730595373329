import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import groupBy from 'lodash/groupBy';
import { useCallback, useState } from 'react';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { SecuritiesHistoryItem } from '@terminal/core/lib/rest/lkSecurities';

import {
  ConfirmCancelSecuritiesTransfer,
  useCancelSecuritiesTransfer,
} from '../../../../entities/Securities';
import { HistoryItemDetail } from '../HistoryItemDetail';
import { HistoryItem } from './HistoryItem';

import { useSecuritiesHistoryDetail } from '../../hooks';

// eslint-disable-next-line no-restricted-imports
import tablestyles from '../../../styles/tables.module.css';
import historyTabStyles from './historyTables.module.css';

interface HistoryListProps {
  history: SecuritiesHistoryItem[];
  treaty: Treaty | null;
  refetchHistory: () => Promise<void>;
}

export const HistoryList = ({
  history,
  treaty,
  refetchHistory,
}: HistoryListProps) => {
  const [detail, setDetail] = useState<SecuritiesHistoryItem | null>(null);
  const [rejectItem, setRejectItem] = useState<SecuritiesHistoryItem | null>(
    null
  );

  const {
    isFetching: detailItemPending,
    data: detailItem,
    refetch: refetchDetailItem,
  } = useSecuritiesHistoryDetail(
    detail?.trnNo || 0,
    detail?.aDate || '',
    detail?.trnSrc || 0,
    treaty?.treaty || 0,
    detail?.deponet || false
  );

  const { mutateAsync: cancelSecuritiesTransfer } =
    useCancelSecuritiesTransfer();

  const dateGroups = groupBy(history, (item) => {
    return format(parseISO(item.depoDate), 'd MMMM yyyy', { locale: ru });
  });

  const getKeyTitle = useCallback((key: string) => {
    return key === format(new Date(), 'd MMMM yyyy', { locale: ru })
      ? 'Сегодня'
      : key;
  }, []);

  const requestCancelSecuritiesTransfer = () => {
    if (treaty && rejectItem) {
      cancelSecuritiesTransfer({
        params: {
          treaty: treaty.treaty,
          trnNo: rejectItem.trnNo,
          aDate: rejectItem.aDate,
        },
        signOptions: {
          successTitle: 'Поручение отменено',
          successCallback: () => {
            refetchDetailItem();
            refetchHistory();
            setRejectItem(null);
          },
        },
      });
    }
  };

  return (
    <>
      <div className={tablestyles.table}>
        <div className={tablestyles.head}>
          <div className={cn(historyTabStyles.row, tablestyles.rowHead)}>
            <div
              className={cn(
                historyTabStyles.cell,
                tablestyles.cellHead,
                historyTabStyles.cellType
              )}
            >
              Направление
            </div>
            <div
              className={cn(
                historyTabStyles.cell,
                tablestyles.cellHead,
                historyTabStyles.cellName
              )}
            >
              Ценная бумага
            </div>
            <div
              className={cn(
                historyTabStyles.cell,
                tablestyles.cellHead,
                historyTabStyles.cellAmount
              )}
            >
              Кол-во
            </div>
            <div
              className={cn(
                historyTabStyles.cell,
                tablestyles.cellHead,
                historyTabStyles.cellTime
              )}
            >
              Время
            </div>
            <div
              className={cn(
                historyTabStyles.cell,
                tablestyles.cellHead,
                historyTabStyles.cellStatus
              )}
            >
              Статус
            </div>
          </div>
        </div>
        <div className={tablestyles.body}>
          {Object.keys(dateGroups).map((key) => (
            <div className={tablestyles.group} key={key}>
              <div className={tablestyles.groupTitle}>{getKeyTitle(key)}</div>
              {dateGroups[key].map((item, index) => (
                <HistoryItem
                  key={`securities-history-item-${item.trnNo}`}
                  item={item}
                  odd={(index + 1) % 2 !== 0}
                  onClick={(item) => setDetail(item)}
                />
              ))}
            </div>
          ))}
        </div>
      </div>

      <Portal id="securities-transfer-modals">
        <FunctionalModalSidebar
          open={Boolean(rejectItem && detailItem)}
          backLabel="Детализация"
          onClose={() => setRejectItem(null)}
        >
          <ConfirmCancelSecuritiesTransfer
            onPrimaryButtonClick={requestCancelSecuritiesTransfer}
            onSecondaryButtonClick={() => setRejectItem(null)}
          />
        </FunctionalModalSidebar>
      </Portal>

      <Portal id="securities-transfer-modals">
        <FunctionalModalSidebar
          open={Boolean(detail && !rejectItem)}
          backLabel="История"
          onClose={() => setDetail(null)}
        >
          <HistoryItemDetail
            item={detailItem || null}
            listItem={detail}
            treaty={treaty}
            pending={detailItemPending}
            onCancel={setRejectItem}
          />
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
