import { Select, SelectProps } from 'formik-alfalab-core-components/Select';
import React, { FC } from 'react';

import {
  TrackPriceSettingLineOpacity,
  TrackPriceSettings,
} from '@terminal/core/types/trackPrice';

export type LineOpacitySelectProps = Omit<SelectProps, 'options' | 'name'>;

const OPTIONS: SelectProps['options'] = [
  {
    key: TrackPriceSettingLineOpacity.Opacity100,
    value: TrackPriceSettingLineOpacity.Opacity100,
    content: '100%',
  },
  {
    key: TrackPriceSettingLineOpacity.Opacity75,
    value: TrackPriceSettingLineOpacity.Opacity75,
    content: '75%',
  },
  {
    key: TrackPriceSettingLineOpacity.Opacity50,
    value: TrackPriceSettingLineOpacity.Opacity50,
    content: '50%',
  },
];

const NAME: keyof TrackPriceSettings = 'lineOpacity';

export const LineOpacitySelect: FC<LineOpacitySelectProps> = (props) => {
  return (
    <Select
      name={NAME}
      block
      label="Непрозрачность линии"
      labelView="outer"
      size="s"
      options={OPTIONS}
      {...props}
    />
  );
};
