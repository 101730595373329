import {
  formatNumber,
  formatNumberOrNull,
  roundFloatNumber,
} from '../../lib/format';

import { ECurrencyId } from '../../types/currencyId';
import { PositionItem } from '../../types/position';

/**
 * @returns Позиции с объединенной рублевой позицией
 * */
export const unifiedRubPositions = (positions: PositionItem[]) => {
  const rubMoneyPositions: PositionItem[] = [];
  const otherPositions: PositionItem[] = [];

  positions.forEach((position) => {
    if (position.object?.idObject === ECurrencyId.RUR) {
      rubMoneyPositions.push(position);
    } else {
      otherPositions.push(position);
    }
  });

  if (!rubMoneyPositions.length) {
    return positions;
  }

  const universalRubPosition = rubMoneyPositions.reduce(
    (universalPosition, position) => {
      universalPosition.money += position.money;
      universalPosition.moneyInitial += position.moneyInitial;
      universalPosition.torgPos += position.torgPos;

      if (
        universalPosition.torgPosCost !== null &&
        position.torgPosCost !== null
      ) {
        universalPosition.torgPosCost += position.torgPosCost;
      }

      if (
        universalPosition.torgPosCostRur !== null &&
        position.torgPosCostRur !== null
      ) {
        universalPosition.torgPosCostRur += position.torgPosCostRur;
      }

      universalPosition.torgPosUchCost += position.torgPosUchCost;
      universalPosition.torgPosUchCostRur += position.torgPosUchCostRur;
      universalPosition.backPos += position.backPos;
      universalPosition.backPosCost += position.backPosCost;
      universalPosition.backPosCostRur += position.backPosCostRur;

      if (
        universalPosition.baseDailyPLtoMarketCurPrice !== null &&
        position.baseDailyPLtoMarketCurPrice !== null
      ) {
        universalPosition.baseDailyPLtoMarketCurPrice +=
          position.baseDailyPLtoMarketCurPrice;
      }

      if (
        universalPosition.liquedTorgPosCostRur !== null &&
        position.liquedTorgPosCostRur !== null
      ) {
        universalPosition.liquedTorgPosCostRur += position.liquedTorgPosCostRur;
      }

      universalPosition.assetsPart += position.assetsPart;

      return universalPosition;
    }
  );

  universalRubPosition.backPos = roundFloatNumber(universalRubPosition.backPos);
  universalRubPosition.backPosCost = formatNumber(
    universalRubPosition.backPosCost,
    2
  );
  universalRubPosition.backPosCostRur = formatNumber(
    universalRubPosition.backPosCostRur,
    2
  );

  universalRubPosition.baseDailyPLtoMarketCurPrice = formatNumberOrNull(
    universalRubPosition.baseDailyPLtoMarketCurPrice,
    2
  );
  universalRubPosition.liquedTorgPosCostRur = formatNumberOrNull(
    universalRubPosition.liquedTorgPosCostRur,
    2
  );
  universalRubPosition.assetsPart = formatNumber(
    universalRubPosition.assetsPart,
    2
  );
  universalRubPosition.torgPos = roundFloatNumber(universalRubPosition.torgPos);
  universalRubPosition.torgPosCost = formatNumberOrNull(
    universalRubPosition.torgPosCost,
    2
  );
  universalRubPosition.torgPosUchCost = formatNumber(
    universalRubPosition.torgPosUchCost,
    2
  );
  universalRubPosition.torgPosCostRur = formatNumberOrNull(
    universalRubPosition.torgPosCostRur,
    2
  );
  universalRubPosition.torgPosUchCostRur = formatNumber(
    universalRubPosition.torgPosUchCostRur,
    2
  );

  return [universalRubPosition, ...otherPositions];
};
