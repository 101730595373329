import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import {
  ExecutionType,
  LifeTime,
  OrderType,
  QuantityType,
} from '../../lib/client/entities';
import { getAllowedOrderParamsId } from '../../lib/domain/getAllowedOrderParamsId';

import { MarketBoard } from '../../types/core';

export interface IFITradingAvailabilityProps {
  orderType: OrderType;
  executionType: ExecutionType;
  lifeTime: LifeTime;
  idMarketBoard?: number;
  idObjectGroup?: number;
  idObject?: number;
  linkedOrderId?: string;
  isMobileVersion?: boolean;
}

export const useFITradingAvailability = ({
  orderType,
  executionType,
  lifeTime,
  idMarketBoard,
  idObjectGroup,
  idObject,
  linkedOrderId,
  isMobileVersion,
}: IFITradingAvailabilityProps) => {
  const { useOrderParams } = useAlfaDirectContext();
  const orderParams = useOrderParams();

  return useMemo(() => {
    if (
      (!idMarketBoard && !idObjectGroup) ||
      // https://jira.moscow.alfaintra.net/browse/ADIRWEB-2226
      // Заблокировали отдельный OTC Рынок в ВТ ГИ
      (idMarketBoard === MarketBoard.EUCLR && !isMobileVersion)
    ) {
      return false;
    }

    const idQuantityType = linkedOrderId ? QuantityType.OTO : QuantityType.None;
    const allowedOrderParams = getAllowedOrderParamsId(
      { idMarketBoard: idMarketBoard!, idObjectGroup: idObjectGroup! },
      orderParams,
      orderType,
      executionType,
      lifeTime,
      idQuantityType
    );

    return Boolean(allowedOrderParams);
  }, [
    isMobileVersion,
    executionType,
    idMarketBoard,
    idObjectGroup,
    lifeTime,
    linkedOrderId,
    orderParams,
    orderType,
  ]);
};
