import { useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { AddMIcon } from '@alfalab/icons-glyph/AddMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { MoneyBankTemplate } from '@terminal/core/lib/rest/lkMoney';

import { useBankTemplates } from '../../../entities/TransferGoinvest';
import { ConfirmModal } from '../../components/ConfirmModal';
import { TemplateRedact } from './TemplateRedact';
import { TemplatesList } from './TemplatesList';

import styles from './Templates.module.css';

export interface TemplatesProps {
  treaty: number;
  backLabel?: string;
  open?: boolean;
  onClose?: () => void;
  onTemplateChoose?: (temp: MoneyBankTemplate) => void;
}

export const Templates = ({ treaty, ...props }: TemplatesProps) => {
  const [openRedact, setOpenRedact] = useState(false);
  const [redacting, setRedacting] = useState<MoneyBankTemplate | null>(null);
  const [templateChanged, setTemplateChanged] = useState(false);
  const [closeRedactorWarning, setCloseRedactorWarning] = useState(false);

  const {
    data: templates,
    isFetching: templatesPending,
    refetch: refetchTemplates,
  } = useBankTemplates(treaty, props.open);

  const onTemplateRedact = (temp: MoneyBankTemplate) => {
    setRedacting(temp);
    openRedactor();
  };

  const onRedactClose = () => {
    if (templateChanged) {
      setCloseRedactorWarning(true);
    } else {
      closeRedactor();
    }
  };

  const openRedactor = () => {
    setOpenRedact(true);
    setTemplateChanged(false);
  };

  const closeRedactor = () => {
    setRedacting(null);
    setOpenRedact(false);
    setCloseRedactorWarning(false);
  };

  const onTemplateSaved = () => {
    closeRedactor();
    refetchTemplates();
  };

  return (
    <div className={styles.container}>
      <Portal id="money-transfer-modals">
        <FunctionalModalSidebar
          open={props.open}
          backLabel={props.backLabel}
          onClose={props.onClose}
        >
          <div className={styles.header}>
            <Typography.Text
              view="primary-small"
              tag="p"
              color="primary"
              weight="bold"
              defaultMargins={false}
            >
              Шаблоны
            </Typography.Text>
            <div>
              <ButtonDesktop
                size="s"
                block
                leftAddons={
                  <AddMIcon width={18} height={18} className={styles.addIcon} />
                }
                onClick={openRedactor}
              >
                Новый шаблон
              </ButtonDesktop>
            </div>
          </div>
          <div className={styles.body}>
            <TemplatesList
              templates={templates || null}
              pending={templatesPending}
              onTemplateSelect={(temp) =>
                props.onTemplateChoose && props.onTemplateChoose(temp)
              }
              onTemplateRedact={onTemplateRedact}
            />
          </div>
        </FunctionalModalSidebar>
      </Portal>

      <Portal id="money-transfer-modals">
        <FunctionalModalSidebar
          open={openRedact}
          backLabel="Шаблоны"
          onClose={onRedactClose}
        >
          <TemplateRedact
            template={redacting}
            treaty={treaty}
            existCount={templates?.length}
            onSaved={onTemplateSaved}
            onTemplateRedacted={() => setTemplateChanged(true)}
          />
        </FunctionalModalSidebar>
      </Portal>

      <ConfirmModal
        open={closeRedactorWarning}
        title={`Шаблон ${
          redacting ? '«' + redacting.title + '»' : ' '
        } не сохранен`}
        text={<>Внесённые вами изменения не&nbsp;будут применены</>}
        confirmLabel="Выйти без сохранения"
        onClose={() => setCloseRedactorWarning(false)}
        onConfirm={closeRedactor}
      />
    </div>
  );
};
