import { ReactNode } from 'react';

export enum AdditionalInstruction {
  SLTP = 'stopLossAndTakeProfit',
  ICEBERG = 'iceberg',
  LINKEDORDER = 'linkedOrder',
  CONDITION = 'condition',
}

export interface AdditionalInstructionData {
  data?: null | Record<string, any>;
  buttonContent?: ReactNode;
}

export interface CurrentAdditionalInstruction
  extends AdditionalInstructionData {
  name: AdditionalInstruction;
}

export type CurrentAdditionalInstructions = null | Partial<
  Record<AdditionalInstruction, CurrentAdditionalInstruction>
>;

export type AdditionalInstructions = {
  name: AdditionalInstruction;
  available: boolean;
}[];

export type SetCurrentAdditionalInstructions = (
  type: AdditionalInstruction | null,
  data: CurrentAdditionalInstruction['data'] | 'reset'
) => void;
