import { IS_TEST } from '../env';

const throttleRaf = (callback) => {
  // в тестах не юзаем RAF
  // пока не получилось замокать RAF в jest
  if (IS_TEST) {
    return callback;
  }

  let requestId: number | null = null;

  let lastArgs;

  const later = (context) => () => {
    requestId = null;
    callback.apply(context, lastArgs);
  };

  const throttled = function (this: any, ...args) {
    lastArgs = args;

    if (requestId === null) {
      requestId = requestAnimationFrame(later(this));
    }
  };

  throttled.cancel = () => {
    if (requestId !== null) {
      cancelAnimationFrame(requestId);
      requestId = null;
    }
  };

  return throttled;
};

export default throttleRaf;
