import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { IS_AI } from '../env';
import { MarketBoardType } from '../lib/client/entities';
import { MultimarketsItem } from '../lib/rest/investApi';
import { loadMultimarkets } from '../lib/services/multimarkets';
import { useFinInstrumentByIdMap } from './domain/useFinInstrumentByIdMap';
import { useMarketBoardByIdMap } from './domain/useMarketBoardByIdMap';
import { useObjectByIdMap } from './domain/useObjectByIdMap';

import { useStore } from '../store';

// Для каждого idFi выдает количество доступных рынков
export const useMultimarkets = (idFIs: number[]) => {
  // Для кадого idFi находим объект и ISIN

  const objects = useStore((store) => store.objects);
  const finInstruments = useStore((store) => store.finInstruments);

  const objectByIdMap = useObjectByIdMap();
  const finInstrumentByIdMap = useFinInstrumentByIdMap();
  const marketBoardByIdMap = useMarketBoardByIdMap();

  const isins = useMemo(() => {
    const myObjectIds = idFIs.map(
      (idFi) => finInstrumentByIdMap.get(idFi)?.idObject ?? 0
    );

    return myObjectIds.map((objId) => objectByIdMap.get(objId)?.isin ?? '');
  }, [idFIs, finInstrumentByIdMap, objectByIdMap]);

  const fetcher = useCallback(() => {
    return loadMultimarkets(isins);
  }, [isins]);

  const selector = useCallback(
    (data: MultimarketsItem[]) => {
      // сканируем ответ мультимаркетов и собираем все рынки по каждому isin
      // попутно собираем все рынки по всем isin
      const allInstruments = data.reduce((accInst, item) => {
        const allMarkets = item.exchanges.reduce((accExchanges, exchange) => {
          const markets = exchange.markets.reduce((accMarkets, market) => {
            accMarkets.push(market.id);

            return accMarkets;
          }, [] as string[]);

          accExchanges = [...accExchanges, ...markets];

          return accExchanges;
        }, [] as string[]);

        accInst[item.isin] = allMarkets;

        return accInst;
      }, {} as Record<string, string[]>);

      // Фильтруем только "наши" объекты
      const myObjects = new Map(
        objects
          .filter((obj) => isins.includes(obj?.isin ?? ''))
          .map((obj) => [obj.idObject, obj])
      );

      // Выкидываем объекты, которые не торгуются у нас
      return finInstruments.reduce((acc, fi) => {
        if (!myObjects.has(fi.idObject)) {
          return acc;
        }

        const obj = myObjects.get(fi.idObject);
        const marketBoard = marketBoardByIdMap.get(fi.idMarketBoard);

        const aiCheck = IS_AI
          ? marketBoard?.type.value !== MarketBoardType.OTC
          : true;

        if (
          aiCheck &&
          (allInstruments[obj?.isin ?? ''] || []).includes(
            marketBoard?.universalMarketCode ?? ''
          )
        ) {
          acc[obj?.isin ?? ''] = (acc[obj?.isin ?? ''] ?? 0) + 1;
        }

        return acc;
      }, {} as Record<string, number>);
    },
    [objects, finInstruments, isins, marketBoardByIdMap]
  );

  return useQuery(`multimarkets:${isins.join(':')}`, fetcher, {
    select: selector,
  });
};
