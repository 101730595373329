import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { groupBy } from 'lodash';
import { useCallback } from 'react';

import { CorporateActionHistoryHeader } from '@terminal/core/lib/rest/lkCorporateActions';

import { HistoryItem } from './HistoryItem';

import styles from './HistoryList.module.css';

interface HistoryListProps {
  history: CorporateActionHistoryHeader[] | null;
  onItemOpen?: (item: CorporateActionHistoryHeader) => void;
}

export const HistoryList = ({ history, onItemOpen }: HistoryListProps) => {
  const dateGroups = groupBy(history, (item) => {
    return format(parseISO(item.date), 'd MMMM yyyy', { locale: ru });
  });

  const getKeyTitle = useCallback((key: string) => {
    return key === format(new Date(), 'd MMMM yyyy', { locale: ru })
      ? 'Сегодня'
      : key;
  }, []);

  return (
    <div className={styles.container}>
      {Object.keys(dateGroups).map((key) => (
        <div className={styles.group} key={key}>
          <div className={styles.groupTitle}>{getKeyTitle(key)}</div>
          {dateGroups[key].map((item, index) => (
            <HistoryItem
              key={`corporate-actions-history-item-${item.orderNumber}`}
              item={item}
              odd={(index + 1) % 2 !== 0}
              onClick={onItemOpen}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
