import cn from 'classnames';
import React, { memo, MouseEventHandler, useMemo } from 'react';
import { ListChildComponentProps } from 'react-window';
import { formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { getMinority } from '@terminal/core/lib/format';
import { OrderItem } from '@terminal/core/types/order';
import { OrderBookLine } from '@terminal/core/types/orderBook';

import { BuySellCell } from '../../../BuySellCell';

import styles from './OrderBookTable.module.css';

export type TableRowProps = {
  lines: OrderBookLine[];
  nodeId: string | undefined;
  maxVolume: number;
  idFI: number;
  getClassName: (index: number, Price: number) => string;
  onRowClick?: (originalPrice: number, side: 'buy' | 'sell') => void;
  setContextPrice?: (originalPrice: number) => void;
  setAnchorPoint?: (state: { x: number; y: number }) => void;
  setIsOpenContext?: (state: boolean) => void;
  mobileVersion?: boolean;
  onOrderClick?: MouseEventHandler;
  buyOrdersMap: Record<string, OrderItem[]>;
  sellOrdersMap: Record<string, OrderItem[]>;
  hideOrders: boolean;
  setSelectedOrdersIds?: (orders: string[]) => void;
  isBond: boolean;
  showYield?: boolean;
};

export const TableRow = memo<ListChildComponentProps<TableRowProps>>(
  ({ style, index, data }) => {
    const {
      nodeId,
      maxVolume,
      lines,
      getClassName,
      onRowClick,
      setContextPrice,
      setAnchorPoint,
      setIsOpenContext,
      mobileVersion,
      onOrderClick,
      buyOrdersMap,
      sellOrdersMap,
      hideOrders,
      setSelectedOrdersIds,
      idFI,
      isBond,
      showYield,
    } = data;

    const { Price, SellQty, BuyQty, Yield } = lines[index] ?? {};
    const id = `${nodeId}-${index}`;
    const buyOrders = buyOrdersMap[Price] ?? [];
    const sellOrders = sellOrdersMap[Price] ?? [];
    const hasBuyOrders = !hideOrders && buyOrders.length > 0;
    const hasSellOrders = !hideOrders && sellOrders.length > 0;

    const volumeHistogramRatio = useMemo(() => {
      const volume = SellQty || BuyQty || 0;

      return Math.round((volume / maxVolume) * 100);
    }, [BuyQty, SellQty, maxVolume]);

    const volumeHistogramComponent = useMemo(() => {
      return (
        <div
          className={styles.histogram}
          style={{ width: `${volumeHistogramRatio}%` }}
        />
      );
    }, [volumeHistogramRatio]);

    const { formatted: formattedYeild } = useMemo(
      () =>
        formatAmount({
          value: Yield * MINORITY,
          minority: MINORITY,
          view: 'withZeroMinorPart',
        }),
      [Yield]
    );

    const { formatted: formattedPrice } = useMemo(
      () =>
        formatAmount({
          value: Price * getMinority(Price),
          minority: getMinority(Price),
          view: 'withZeroMinorPart',
        }),
      [Price]
    );

    return (
      <div
        id={id}
        style={style}
        onClick={() => {
          onRowClick && onRowClick(Price, SellQty ? 'sell' : 'buy');
        }}
        onContextMenu={(
          e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
        ) => {
          e.preventDefault();
          setContextPrice?.(Price);
          setAnchorPoint?.({ x: e.pageX, y: e.pageY + 10 });
          setIsOpenContext?.(true);
        }}
        className={getClassName(index, Price)}
      >
        <div className={styles.tableRowAside}>
          {hasBuyOrders && (
            <BuySellCell
              className={cn(styles.orderCell, styles.cell)}
              idFI={idFI}
              price={Price}
              orders={buyOrders}
              onClick={onOrderClick}
              desktopVersion={!mobileVersion}
              setSelectedOrdersIds={setSelectedOrdersIds}
            />
          )}
          {!hasBuyOrders && <div />}
          {Boolean(BuyQty) && volumeHistogramComponent}
          <div className={cn(styles.cell, styles.buyQtyCell)}>
            {BuyQty || ''}
          </div>
        </div>
        <div className={cn(styles.cell, styles.priceCell)}>
          <span className={styles.price}>{Price ? formattedPrice : ''}</span>
          {isBond && showYield && (
            <span className={styles.yield}>
              {Yield ? `${formattedYeild}%` : ''}
            </span>
          )}
        </div>
        <div className={styles.tableRowAside}>
          {Boolean(SellQty) && volumeHistogramComponent}
          <div className={cn(styles.cell, styles.sellQtyCell)}>
            {SellQty || ''}
          </div>
          {hasSellOrders && (
            <BuySellCell
              className={cn(styles.orderCell, styles.cell)}
              idFI={idFI}
              price={Price}
              orders={sellOrders}
              onClick={onOrderClick}
              desktopVersion={!mobileVersion}
              setSelectedOrdersIds={setSelectedOrdersIds}
            />
          )}
        </div>
      </div>
    );
  }
);
