import uniqWith from 'lodash/uniqWith';
import { v4 } from 'uuid';
import { GetState } from 'zustand';

import { RootStore, StoreSet } from '../useStore';

import { FIModalItem } from '../../types/FIModal';
import { WatchList, WatchListItem, WatchListType } from '../../types/watchList';

export interface WatchListSlice {
  watchLists: WatchList[];
  initWatchLists: (lists: WatchList[]) => void;
  importWatchLists: (watchLists: WatchList[]) => void;
  createWatchList: (
    name: string,
    nodeId?: string,
    items?: WatchListItem[],
    withSelection?: boolean
  ) => string;
  editWatchList: (list: WatchList) => void;
  duplicateWatchList: (list: WatchList, nodeId?: string) => string;
  deleteWatchList: (list: WatchList) => void;
  addToWatchList: (id: string, item: WatchListItem) => void;
  addToWatchListMultiple: (id: string, items: WatchListItem[]) => void;
  deleteFromWatchList: (id: string, idFi: number) => void;
  replaceInWatchlist: (
    id: string,
    oldIdFi: number,
    item: WatchListItem
  ) => void;
  saveLists: (list: WatchList[]) => void;
  addInitialWatchLists: (lists: WatchList[]) => void;
}

export const createWatchListSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): WatchListSlice => ({
  watchLists: [],
  initWatchLists: (lists) => {
    set((state) => {
      state.watchLists = lists;
    });
  },
  importWatchLists: (watchLists: WatchList[]) => {
    set((state) => {
      const newWatchLists: WatchList[] = [];

      for (let wl of watchLists) {
        if (state.watchLists.some((existedW) => existedW.id === wl.id)) {
          continue;
        }

        newWatchLists.push({
          ...wl,
          name: state.watchLists.some((existedW) => existedW.name === wl.name)
            ? `${wl.name}-2`
            : wl.name,
        });
      }

      state.watchLists = [...state.watchLists, ...newWatchLists];
    });
  },
  createWatchList: (name, nodeId, instruments, withSelection = true) => {
    const id = v4();

    set((state) => {
      const newList: WatchList = {
        id,
        name,
        type: WatchListType.CUSTOM,
        instruments: instruments ?? [],
      };

      state.watchLists.push(newList);

      if (nodeId && withSelection) {
        get().updateNode(nodeId, { selectedListId: id });
      }
    });

    return id;
  },
  editWatchList: (list) =>
    set((state) => {
      const index = state.watchLists.findIndex((item) => item.id === list.id);

      if (index >= 0) {
        state.watchLists.splice(index, 1, list);
      }
    }),
  duplicateWatchList: (list, nodeId) => {
    const id = v4();

    set((state) => {
      state.watchLists.push({
        ...list,
        id,
        name: list.name + ' (копия)',
        type: WatchListType.CUSTOM,
      });

      if (nodeId) {
        get().updateNode(nodeId, { selectedListId: id });
      }
    });

    return id;
  },
  deleteWatchList: ({ id, type }) =>
    set((state) => {
      const index = state.watchLists.findIndex((list) => list.id === id);

      if (type === WatchListType.AFLA || type === WatchListType.DEFAULT) {
        return;
      }

      state.watchLists.splice(index, 1);
    }),
  addToWatchList: (id, item) =>
    set((state) => {
      const index = state.watchLists.findIndex((list) => list.id === id);

      if (index >= 0) {
        const prevList = state.watchLists[index];
        const instrIndex = prevList.instruments.findIndex(
          (instr) => instr.idFI === item.idFI
        );

        //Если инструмента еще не в списке
        if (instrIndex < 0) {
          state.watchLists.splice(index, 1, {
            ...prevList,
            instruments: [...prevList.instruments, item],
          });
        }
      }
    }),
  addToWatchListMultiple: (id, items) =>
    set((state) => {
      const currentWatchListsIndex = state.watchLists.findIndex(
        (list) => list.id === id
      );

      if (currentWatchListsIndex < 0) {
        return;
      }

      const prevList = state.watchLists[currentWatchListsIndex];

      const newInstruments = uniqWith(
        [...prevList.instruments, ...items] as FIModalItem[],
        (a: FIModalItem, b: FIModalItem) => a.idFI === b.idFI
      );

      state.watchLists.splice(currentWatchListsIndex, 1, {
        ...prevList,
        instruments: newInstruments,
      });
    }),
  deleteFromWatchList: (id, idFi) =>
    set((state) => {
      const index = state.watchLists.findIndex((list) => list.id === id);

      if (index >= 0) {
        const prevList = state.watchLists[index];

        state.watchLists.splice(index, 1, {
          ...prevList,
          instruments: prevList.instruments.filter(
            (instr) => instr.idFI !== idFi
          ),
        });
      }
    }),
  replaceInWatchlist: (id, oldIdFi, item) =>
    set((state) => {
      const index = state.watchLists.findIndex((list) => list.id === id);

      if (index < 0) {
        return;
      }

      const prevList = state.watchLists[index];
      const newInstruments = [...prevList.instruments];
      const instrumentIndex = prevList.instruments.findIndex(
        (inst) => inst.idFI === oldIdFi
      );

      if (instrumentIndex >= 0) {
        newInstruments[instrumentIndex] = item;
      }

      state.watchLists.splice(index, 1, {
        ...prevList,
        instruments: newInstruments,
      });
      get().saveLists(state.watchLists);
    }),
  saveLists: (lists) => {
    localStorage.setItem('watchLists', JSON.stringify(lists));
  },
  addInitialWatchLists: (lists) => {
    set((state) => {
      state.watchLists = lists;
    });
  },
});
