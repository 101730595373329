import { z } from 'zod';

import { FeatureFlags } from '@terminal/core/lib/featureFlags';

const scheme = z.object({
  /**
   * Включает логирование количества рендеров в PRO
   * */
  PRO_RENDER_COUNT_PROFILER_ENABLED: z.coerce.boolean(),
  /**
   * ADIRWEB-3049 Отключает контекстное меню для вкладок рабочих областей
   */
  WORKSPACE_TAB_CONTEXT_MENU_ENABLED: z.coerce.boolean(),
});

const defaultFeatureFlags: z.infer<typeof scheme> = {
  PRO_RENDER_COUNT_PROFILER_ENABLED: false,
  WORKSPACE_TAB_CONTEXT_MENU_ENABLED: false,
};

export const featureFlags = new FeatureFlags(
  scheme,
  defaultFeatureFlags,
  '__setProFeatureFlags'
);
