import { fetchLkFile } from './lkApi';
import { signOperation } from './lkSignOperation';

interface SetQualInvestorParams {
  treaty: number;
}

export async function setQualInvestor(data: SetQualInvestorParams) {
  return await signOperation(`/api/qual-investor/set`, 'POST', data);
}

export async function getQualPdf(treaty: number) {
  return await fetchLkFile(`/api/qual-investor/pdf/${treaty}`);
}
