// Функция для сортировки ключей объекта. Пример: { z: 15, g: 23, a: 3 } -> { a: 3, g: 23, z: 15 }
export const sortObjKeys = (
  unordered: Record<string, any>,
  comparator?: (a: any, b: any) => number
) =>
  Object.keys(unordered)
    .sort(comparator)
    .reduce((obj: Record<string, any>, key: string) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
