import {
  isRutube,
  isYoutube,
  proccessRutubeSrc,
  proccessYoutubeSrc,
} from './helpers';

interface EmbendedVideoProps {
  src: string;
  width?: number;
  height?: number;
}

export const EmbendedVideo = ({ src, width, height }: EmbendedVideoProps) => {
  if (isYoutube(src)) {
    const proccessed = proccessYoutubeSrc(src);

    return (
      <iframe
        width={width}
        height={height}
        src={proccessed}
        title="YouTube видео плеер"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    );
  }

  if (isRutube(src)) {
    const proccessed = proccessRutubeSrc(src);

    return (
      <iframe
        width={width}
        height={height}
        src={proccessed}
        title="Rutube видео плеер"
        frameBorder="0"
        allow="clipboard-write; autoplay"
        allowFullScreen
      ></iframe>
    );
  }

  return null;
};
