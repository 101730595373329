import cn from 'classnames';
import { IconButton } from '@alfalab/core-components/icon-button';
import { MinusMIcon } from '@alfalab/icons-glyph/MinusMIcon';
import { PlusMIcon } from '@alfalab/icons-glyph/PlusMIcon';

import styles from './SpinnerButtons.module.css';

interface SpinnerButtonsProps {
  size?: 's' | 'm' | 'l';
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  onIncrement?: () => void;
  onDecrement?: () => void;
}

/**
 * Компонент кнопок +/- для NumberInput.
 * Можно будет удалить, когда версия NumberInput позволит использовать
 * встроенное решение
 */
export const SpinnerButtons = ({
  size,
  className,
  onDecrement,
  onIncrement,
}: SpinnerButtonsProps) => {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.size_s]: size === 's',
        [styles.size_m]: size === 'm',
        [styles.size_l]: size === 'l',
      })}
    >
      <IconButton
        className={styles.btn}
        icon={MinusMIcon}
        onClick={onDecrement}
      />
      <IconButton
        className={styles.btn}
        icon={PlusMIcon}
        onClick={onIncrement}
      />
    </div>
  );
};
