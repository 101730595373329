import cn from 'classnames';
import { Button } from '@alfalab/core-components/button/modern';
import { Input } from '@alfalab/core-components/input/modern';
import { Link } from '@alfalab/core-components/link/modern';
import { Spinner } from '@alfalab/core-components/spinner';

import { useLoginCaptchaForm } from '@terminal/lk-core';

import styles from './LoginCaptchaForm.module.css';

export const LoginCaptchaForm = () => {
  const {
    captchaPending,
    captchaImg,
    captchaCode,
    captchaError,
    captchaValidating,
    onCaptchaValidate,
    requestNewCaptcha,
    changeCaptcha,
    cancelCaptcha,
  } = useLoginCaptchaForm();

  return (
    <form onSubmit={onCaptchaValidate}>
      <div className={styles.captha}>
        {captchaPending ? (
          <div className={styles.spinnerContainer}>
            <Spinner visible size="m" />
          </div>
        ) : (
          <img
            className={cn(styles.capthaImg, {
              [styles.capthaImgPending]: captchaPending,
            })}
            src={'data:image/png;base64, ' + captchaImg}
            alt="Captcha"
          />
        )}
      </div>

      <div className={styles.capthaRefresh}>
        <Link onClick={requestNewCaptcha}>Обновить</Link>
      </div>

      <Input
        value={captchaCode}
        block
        required
        size="m"
        label="Введите символы с картинки"
        onChange={changeCaptcha}
        error={captchaError}
        className={styles.captchaField}
      />

      <div>
        <div className={styles.submitItem}>
          <Button
            block
            view="accent"
            type="submit"
            size="m"
            loading={captchaValidating}
          >
            Далее
          </Button>
        </div>

        <div className={styles.submitItem}>
          <Button
            block
            view="secondary"
            type="button"
            size="m"
            onClick={cancelCaptcha}
          >
            Отмена
          </Button>
        </div>
      </div>
    </form>
  );
};
