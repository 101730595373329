import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getContestBestTrades } from '../api/getContestBestTrades';

export const useContestBestTrades = (
  contestid: number,
  participantid: number
) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'contest/best-trades', { contestid, participantid }],
    () => getContestBestTrades({ contestid, participantid }),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message ||
            'При запросе списка лучших сделок участника конкурса произошла ошибка',
        });
      },
    }
  );
};
