import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getEducationStrategyDetails } from '../api';

export function useEducationStrategyDetails(strategyId: number) {
  const addNotification = useNotification();

  return useQuery(
    ['lk-education-strategy-detail', { strategyId }],
    () => getEducationStrategyDetails({ strategyId }),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса информации о стратегии',
          text: error?.message || '',
        });
      },
      enabled: Boolean(strategyId),
    }
  );
}
