import { FC, useEffect, useMemo, useState } from 'react';
import {
  Tab,
  TabsDesktop as Tabs,
} from '@alfalab/core-components/tabs/desktop';
import { TabProps } from '@alfalab/core-components/tabs/typings';

import { useFullFI } from '@terminal/alfadirect/hooks';
import {
  getObjectTypeCategory,
  ObjectTypeCategory,
} from '@terminal/core/types/objectTypes';

import { InstrumentEventsTab } from '../../tabs/events/InstrumentEventsTab';
import { FinancesTab } from '../../tabs/finances/FinancesTab';
import { IndicatorsTab } from '../../tabs/indicators/IndicatorsTab';
import { InstrumentInfoTab } from '../../tabs/info/InstrumentInfoTab';

import classes from './InstrumentTabs.module.css';

interface Props {
  idFi: number;
}

const InstrumentTabs: FC<Props> = ({ idFi }) => {
  const fullFI = useFullFI(idFi);

  const tabs: TabProps[] | undefined = useMemo(() => {
    if (!fullFI?.idObjectType?.value) {
      return [];
    }

    const objectTypeCategory = getObjectTypeCategory(
      fullFI?.idObjectType?.value
    );

    const isStock = objectTypeCategory === ObjectTypeCategory.Stock;
    const isBond = objectTypeCategory === ObjectTypeCategory.Bond;
    const tabProps: TabProps[] = [
      {
        title: 'Инфо',
        id: 'info',
        children: <InstrumentInfoTab idFi={idFi} />,
      },
      {
        title: 'Показатели',
        id: 'indicators',
        children: <IndicatorsTab idFi={idFi} />,
      },
    ];
    const financesTab = {
      title: 'Финансы',
      id: 'finance',
      children: <FinancesTab idFi={idFi} />,
    };
    const eventsTab = {
      title: 'События',
      id: 'events',
      children: <InstrumentEventsTab idFi={idFi} />,
    };

    if (isBond) {
      tabProps.push(eventsTab);
    }

    if (isStock) {
      tabProps.push(financesTab, eventsTab);
    }

    return tabProps;
  }, [fullFI?.idObjectType?.value, idFi]);

  const [selectedId, setSelectedId] = useState('info');

  const handleChange = (event, { selectedId }) => {
    setSelectedId(selectedId);
  };

  useEffect(() => {
    const tabIds = tabs.map(({ id }) => id);

    if (!tabIds.includes(selectedId)) {
      setSelectedId('info');
    }
  }, [selectedId, tabs]);

  return (
    <Tabs
      selectedId={selectedId}
      onChange={handleChange}
      scrollable
      mobile
      size="s"
      containerClassName={classes.tabsWrapper}
    >
      {tabs.map(({ id, title, children }) => (
        <Tab key={id} id={id} title={title} className={classes.tab}>
          {children}
        </Tab>
      ))}
    </Tabs>
  );
};

export default InstrumentTabs;
