import { useContext } from 'react';

import { context } from './context';

/**
 * @returns контекст для управления звуками
 * */
export function useSoundManager() {
  return useContext(context);
}
