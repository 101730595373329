import { MutableRefObject, RefObject } from 'react';

import { useEventListener } from './useEventListener';

type Handler = (event: MouseEvent) => void;

// Функция вызывает обработчик если место срабатывания события не содержащится ни в одном из переданных элементов
export function useOnClickOutside(
  ref:
    | RefObject<HTMLElement>
    | RefObject<HTMLElement>[]
    | MutableRefObject<HTMLElement>,
  handler: Handler,
  capture = true,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
): void {
  useEventListener(
    mouseEvent,
    (event) => {
      const refs = Array.isArray(ref) ? ref : [ref];

      if (
        refs.some(
          (ref) => ref.current && ref.current.contains(event.target as Node)
        )
      )
        return;

      // Explicit type for "mousedown" event.
      handler(event as unknown as MouseEvent);
    },
    {
      capture,
    }
  );
}
