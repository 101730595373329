import qs from 'query-string';

import { APP_URI, IS_AI, IS_DESKTOP } from '@terminal/core/env';
import { joinPath } from '@terminal/core/lib/joinPath';

export const getAppRedirectUri = (redirect: string) => {
  const redirectUri = qs.stringifyUrl(
    {
      url: joinPath(
        APP_URI,
        IS_DESKTOP && IS_AI ? '/desktop/passport' : '/passport'
      ),
      query: { redirect },
    },
    {
      encode: false,
    }
  );

  return encodeURIComponent(redirectUri);
};
