import { TrackPriceEntity } from '../lib/client/entities';

export enum TrackPriceType {
  None = 0,
  Value = 1,
  OffsetByPercent = 2,
}

export enum TrackPriceState {
  None = 0,
  Active = 1,
  Deleted = 2,
  Fired = 3,
  Expired = 4,
}

export interface TrackPrice
  extends Pick<
    TrackPriceEntity,
    | 'IdTrackPrice'
    | 'IdType'
    | 'IdState'
    | 'IdFI'
    | 'InitialPrice'
    | 'TargetPrice'
    | 'Version'
    | 'Operation'
    | 'TrackNum'
    | 'SymbolSpec'
  > {
  ExpirationDate: Date;
  LastUpdate: Date;
  IdDevice?: string;
  Caption?: string;
  Text?: string;
  settings?: TrackPriceSettings;
}

export enum TrackPriceSettingLineType {
  Solid = 'solid',
  Dashed = 'dashed',
}

export enum TrackPriceSettingLineOpacity {
  Opacity100 = '100',
  Opacity75 = '75',
  Opacity50 = '50',
}

export enum TrackPriceSettingTitlePosition {
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
}

export interface TrackPriceCommonSettings {
  lineType: TrackPriceSettingLineType;
  lineOpacity: TrackPriceSettingLineOpacity;
  titlePosition: TrackPriceSettingTitlePosition;
  notify: boolean;
}

export interface TrackPriceSettings extends TrackPriceCommonSettings {
  title: string;
}
