import { CorporateActionsFilter } from '@terminal/core/lib/rest/lkCorporateActions';

import { areDatesOverlapping, isDateValid } from '../../../components/Filter';

export const isFilterValid = (filter: CorporateActionsFilter): boolean => {
  return (
    isDateValid(filter.from) &&
    isDateValid(filter.to) &&
    !areDatesOverlapping(filter.from, filter.to)
  );
};
