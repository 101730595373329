import { signOperation } from './lkSignOperation';

import { LkSignOptions } from '../../store/slices/lkSignSlice';

/**
 * Запрос кодов
 */

export interface CodeTable {
  code: number;
  data: {
    passNo: number;
    password: string;
  }[];
  id: string;
  message?: string;
  url: string;
}

export function getCodesSign(options?: LkSignOptions) {
  return signOperation(
    '/api/settings/codetable/sign',
    'GET',
    undefined,
    options
  ) as Promise<CodeTable | undefined>;
}

/**
 * Ссылка для скачивания файла
 */

export function getURLDownloadPDF(operationId: string) {
  return `/api/settings/codetable/pdf?operationId=${operationId}`;
}
