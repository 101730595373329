import React from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { CategoryDocumentMIcon } from '@alfalab/icons-glyph/CategoryDocumentMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { FreeOrder } from '@terminal/core/lib/rest/lkFreeOrders';

import { statusIcons } from './constants';

import styles from './FreeOrders.module.css';

interface FreeOrderListItemProps {
  freeOrder: FreeOrder;
  setOrderDetails: React.Dispatch<React.SetStateAction<FreeOrder | null>>;
}

export const FreeOrderListItem = ({
  freeOrder,
  setOrderDetails,
}: FreeOrderListItemProps) => {
  const StatusIcon = statusIcons[freeOrder.status] || CrossMIcon;

  return (
    <div
      className={styles.orderItemContainer}
      onClick={() => setOrderDetails(freeOrder)}
    >
      <div className={styles.orderItemIcon}>
        <CategoryDocumentMIcon
          fill="var(--color-light-graphic-secondary)"
          width={15}
          height={15}
        />
      </div>
      <div className={styles.orderItemTitle}>
        <Typography.Text view="secondary-small">
          {freeOrder.theme}
        </Typography.Text>
      </div>
      <div className={styles.orderItemStatus}>
        <StatusIcon
          fill="var(--color-light-graphic-secondary)"
          width={12}
          height={12}
        />
        <Typography.Text view="secondary-small" color="secondary">
          {freeOrder.statusDescription}
        </Typography.Text>
      </div>
    </div>
  );
};
