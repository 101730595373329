import React from 'react';

import { PreferredTheme } from '@terminal/desktop/shared/lib/themeContext';

import { ReactComponent as AutoThemeImage } from '../../assets/theme-auto.svg';
import { ReactComponent as DarkThemeImage } from '../../assets/theme-dark.svg';
import { ReactComponent as LightThemeImage } from '../../assets/theme-light.svg';

interface ThemeOption {
  title: string;
  Image: React.FC;
  theme: PreferredTheme;
}

const MAP_THEME_TO_IMAGE: Record<PreferredTheme, typeof AutoThemeImage> = {
  auto: AutoThemeImage,
  light: LightThemeImage,
  dark: DarkThemeImage,
};

export const THEME_OPTIONS: ThemeOption[] = [
  {
    title: 'Темная',
    Image: MAP_THEME_TO_IMAGE['dark'],
    theme: 'dark',
  },
  {
    title: 'Светлая',
    Image: MAP_THEME_TO_IMAGE['light'],
    theme: 'light',
  },
  {
    title: 'Системная',
    Image: MAP_THEME_TO_IMAGE['auto'],
    theme: 'auto',
  },
];
