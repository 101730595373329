import { Spinner } from '@alfalab/core-components/spinner';

import {
  getInstructionThemeDocumentUrl,
  InstructionTheme,
  ThemeDetail,
  useInstructionTheme,
} from '../../../../entities/Education';

interface InstructionThemeDetailProps {
  themeId: number;
  onTest?: (theme: InstructionTheme) => void;
}

export const InstructionThemeDetail = ({
  themeId,
  onTest,
}: InstructionThemeDetailProps) => {
  const { data: theme, isFetching: loading } = useInstructionTheme(themeId);

  return (
    <>
      {theme && (
        <ThemeDetail
          name={theme.name}
          description={theme.details}
          hasTest={theme.hasQuestions}
          onTest={() => onTest && onTest(theme)}
          showRecomendations={false}
          videoMaterials={theme.videoMaterials}
          bookMaterials={theme.bookMaterials}
          getThemeDocumentUrl={getInstructionThemeDocumentUrl}
        />
      )}

      <Spinner visible={loading} />
    </>
  );
};
