import unionBy from 'lodash/unionBy';
import log from 'loglevel';

import {
  InstrumentItemMap,
  marketBoardMap,
  ObjectExtMap,
  objectMap,
  ObjectTypeMap,
} from '../../mapping/coreMapping';
import {
  FinInstrumentEntity,
  FrontEndType,
  MarketBoardEntity,
  MessageUnitedType,
  ObjectEntity,
  ObjectExtEntity,
  ObjectTypeEntity,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import { getEntityIdByName } from '../client/serialization';
import AdirClientService from '../client/service';
import { DictionariesDB, DictionariesDBVersion } from '../db/dictionaries';

import {
  CorePartType,
  InstrumentItem,
  MarketBoardItem,
  ObjectExtItem,
  ObjectItem,
  ObjectTypeItem,
} from '../../types/core';

const dbNameByCorePartTypeMap: Record<
  CorePartType,
  keyof DictionariesDBVersion
> = {
  [CorePartType.INSTRUMENTS]: 'FinInstrument',
  [CorePartType.OBJECTS]: 'Object',
  [CorePartType.OBJECT_TYPES]: 'ObjectType',
  [CorePartType.MARKET_BOARDS]: 'MarketBoard',
  [CorePartType.OBJECT_EXTS]: 'ObjectExt',
};

const corePartTypeByEntityNumberByMap: Record<number, CorePartType> = {
  [getEntityIdByName(EntityType.FinInstrumentEntity) ?? -1]:
    CorePartType.INSTRUMENTS,
  [getEntityIdByName(EntityType.ObjectEntity) ?? -1]: CorePartType.OBJECTS,
  [getEntityIdByName(EntityType.ObjectTypeEntity) ?? -1]:
    CorePartType.OBJECT_TYPES,
  [getEntityIdByName(EntityType.MarketBoardEntity) ?? -1]:
    CorePartType.MARKET_BOARDS,
  [getEntityIdByName(EntityType.ObjectExtEntity) ?? -1]:
    CorePartType.OBJECT_EXTS,
};

type InitialDataType = {
  [key in CorePartType]: {
    lastDataVersion: BigInt;
    data: (
      | InstrumentItem
      | ObjectItem
      | ObjectTypeItem
      | MarketBoardItem
      | ObjectExtItem
    )[];
  };
};

// Заметка про иниты справочников - когда подписываешься, то будешь получать массивы энтитей
// и когда тебе передадут все энтити, то придет в сообщении InitFinishedEntity
class CoreService extends AdirClientService {
  subscribed = false;
  initialData: InitialDataType = {
    [CorePartType.INSTRUMENTS]: {
      lastDataVersion: BigInt(0n),
      data: [] as InstrumentItem[],
    },
    [CorePartType.OBJECTS]: {
      lastDataVersion: BigInt(0n),
      data: [] as ObjectItem[],
    },
    [CorePartType.OBJECT_TYPES]: {
      lastDataVersion: BigInt(0n),
      data: [] as ObjectTypeItem[],
    },
    [CorePartType.MARKET_BOARDS]: {
      lastDataVersion: BigInt(0n),
      data: [] as MarketBoardItem[],
    },
    [CorePartType.OBJECT_EXTS]: {
      lastDataVersion: BigInt(0n),
      data: [] as ObjectExtItem[],
    },
  };

  async fetch() {
    if (!this.store.getState().user) {
      throw new Error('ADClient is not ready');
    }

    try {
      const dbNames = Object.entries(dbNameByCorePartTypeMap);
      const storedDataArray = await Promise.allSettled(
        dbNames.map(([type, dbName]) => {
          type DataType = InitialDataType[keyof InitialDataType]['data'];

          return DictionariesDB.get<DataType>(dbName);
        })
      );

      storedDataArray.forEach((storedData, index) => {
        if (
          storedData.status === 'fulfilled' &&
          storedData.value?.lastDataVersion &&
          storedData.value?.data
        ) {
          let data = storedData.value.data as ObjectItem[];

          if (index === CorePartType.OBJECTS) {
            for (let i = 0; i < data.length; i++) {
              data[i].matDateObject = new Date(data[i].matDateObject);
            }
          }

          this.initialData[index] = {
            lastDataVersion: storedData.value.lastDataVersion,
            data,
          };
        }
      });
    } catch (e) {
      log.warn(e);
    }

    this.client.subscribe(
      FrontEndType.AuthAndOperInitServer,
      EntityType.MarketBoardEntity,
      [],
      this.initialData[CorePartType.MARKET_BOARDS].lastDataVersion
    );

    this.client.subscribe(
      FrontEndType.AuthAndOperInitServer,
      EntityType.FinInstrumentEntity,
      [],
      this.initialData[CorePartType.INSTRUMENTS].lastDataVersion
    );

    this.client.subscribe(
      FrontEndType.AuthAndOperInitServer,
      EntityType.ObjectEntity,
      [],
      this.initialData[CorePartType.OBJECTS].lastDataVersion
    );

    this.client.subscribe(
      FrontEndType.AuthAndOperInitServer,
      EntityType.ObjectTypeEntity,
      [],
      this.initialData[CorePartType.OBJECT_TYPES].lastDataVersion
    );

    this.client.subscribe(
      FrontEndType.AuthAndOperInitServer,
      EntityType.ObjectExtEntity,
      [],
      this.initialData[CorePartType.OBJECT_EXTS].lastDataVersion
    );
  }

  override init() {
    if (!this.subscribed) {
      this.addClientListener(
        EntityType.ObjectTypeEntity,
        (message: MessageUnitedType) =>
          this.onEntity<ObjectTypeEntity>(CorePartType.OBJECT_TYPES, message)
      );

      this.addClientListener(
        EntityType.FinInstrumentEntity,
        (message: MessageUnitedType) =>
          this.onEntity<FinInstrumentEntity>(CorePartType.INSTRUMENTS, message)
      );

      this.addClientListener(
        EntityType.ObjectEntity,
        (message: MessageUnitedType) =>
          this.onEntity<ObjectEntity>(CorePartType.OBJECTS, message)
      );

      this.addClientListener(
        EntityType.ObjectExtEntity,
        (message: MessageUnitedType) =>
          this.onEntity<ObjectExtEntity>(CorePartType.OBJECT_EXTS, message)
      );

      this.addClientListener(
        EntityType.MarketBoardEntity,
        (message: MessageUnitedType) =>
          this.onEntity<MarketBoardEntity>(CorePartType.MARKET_BOARDS, message)
      );

      this.addClientListener(
        EntityType.InitFinishedEntity,
        (message: MessageUnitedType) => this.onInitFinishedEntity(message)
      );

      this.addClientListener(EntityType.ResetDataRequestEntity, this.onReset);
    }
  }

  /**
   * Собираем все данные в стеке пока не получим сообщение InitFinishedEntity (все данные получены)
   * После этого сохраняем все данные в стор
   */
  onEntity<T extends { Version: BigInt }>(
    type: CorePartType,
    message: MessageUnitedType
  ) {
    let maxDataVersion: BigInt = 0n;
    const dataArr = message.data as T[];

    dataArr.forEach((data) => {
      if (data.Version > maxDataVersion) {
        maxDataVersion = data.Version;
      }
    });

    switch (type) {
      case CorePartType.INSTRUMENTS:
        this.initialData[type].data = [
          ...this.initialData[type].data,
          ...(message.data as FinInstrumentEntity[]).map((el) =>
            InstrumentItemMap(el)
          ),
        ];
        break;

      case CorePartType.OBJECTS:
        this.initialData[type].data = [
          ...this.initialData[type].data,
          ...(message.data as ObjectEntity[]).map((el) => objectMap(el)),
        ];
        break;

      case CorePartType.OBJECT_TYPES:
        this.initialData[type].data = [
          ...this.initialData[type].data,
          ...(message.data as ObjectTypeEntity[]).map((el) =>
            ObjectTypeMap(el)
          ),
        ];
        break;

      case CorePartType.OBJECT_EXTS:
        // Динамический справочник. Могут приходить обновления,
        // поэтому объединяем данные по ключу idObject
        this.initialData[type].data = unionBy(
          this.initialData[type].data,
          (message.data as ObjectExtEntity[]).map((el) => ObjectExtMap(el)),
          'idObject'
        );
        break;

      case CorePartType.MARKET_BOARDS:
        this.initialData[type].data = [
          ...this.initialData[type].data,
          ...(message.data as MarketBoardEntity[]).map((el) =>
            marketBoardMap(el)
          ),
        ];
        break;
    }

    if (maxDataVersion > this.initialData[type].lastDataVersion) {
      this.initialData[type].lastDataVersion = maxDataVersion;
    }
  }

  onInitFinishedEntity(message: MessageUnitedType) {
    message.data.forEach((subgroup) => {
      this.setInitFinishedType(subgroup as { Command: number; Id: number });
    });
  }

  private onReset = (message: { DataType: number }) => {
    const type = corePartTypeByEntityNumberByMap[message.DataType];

    if (this.initialData[type]) {
      this.initialData[type].data = [];
      this.initialData[type].lastDataVersion = 0n;
      this.store.getState().setCorePartReady(type, false);
    }
  };

  setInitFinishedType(message: { Command: number; Id: number }) {
    switch (corePartTypeByEntityNumberByMap[message.Command]) {
      case CorePartType.INSTRUMENTS:
        this.store
          .getState()
          .setFinInstruments(
            this.initialData[CorePartType.INSTRUMENTS].data as InstrumentItem[]
          );
        this.store.getState().setCorePartReady(CorePartType.INSTRUMENTS, true);
        break;
      case CorePartType.OBJECTS:
        this.store
          .getState()
          .setObjects(
            this.initialData[CorePartType.OBJECTS].data as ObjectItem[]
          );
        this.store.getState().setCorePartReady(CorePartType.OBJECTS, true);
        break;
      case CorePartType.MARKET_BOARDS:
        this.store
          .getState()
          .setMarketBoards(
            this.initialData[CorePartType.MARKET_BOARDS]
              .data as MarketBoardItem[]
          );
        this.store
          .getState()
          .setCorePartReady(CorePartType.MARKET_BOARDS, true);
        break;
      case CorePartType.OBJECT_TYPES:
        this.store
          .getState()
          .setObjectTypes(
            this.initialData[CorePartType.OBJECT_TYPES].data as ObjectTypeItem[]
          );
        this.store.getState().setCorePartReady(CorePartType.OBJECT_TYPES, true);
        break;
      case CorePartType.OBJECT_EXTS:
        this.store
          .getState()
          .setObjectExts(
            this.initialData[CorePartType.OBJECT_EXTS].data as ObjectExtItem[]
          );
        this.store.getState().setCorePartReady(CorePartType.OBJECT_EXTS, true);
        break;

      default:
        break;
    }
  }

  public save = (type: CorePartType, data: object[]) => {
    const dbName = dbNameByCorePartTypeMap[type];

    DictionariesDB.save(dbName, this.initialData[type].lastDataVersion, data);
  };
}

const instance = new CoreService();

export { instance as CoreService };
