import React, { useEffect } from 'react';

import { getOnboardingStateFromLocaleStorage } from '../../entities/OnboardingConfig';
import { OnboardingModal } from './OnboardingModal';

interface OnboardingModalProps {
  setShowOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
  showOnboarding: boolean;
}

export const Onboarding = (props: OnboardingModalProps) => {
  const { setShowOnboarding, showOnboarding } = props;

  useEffect(() => {
    const isPassed = getOnboardingStateFromLocaleStorage('passed');

    setShowOnboarding(!isPassed);
  }, [setShowOnboarding]);

  return <>{showOnboarding && <OnboardingModal {...props} />}</>;
};
