import { makeGetParams } from '../url';
import { fetchLkResult, LKResult } from './lkApi';
import { signOperation } from './lkSignOperation';
import { smsSignOperation } from './lkSmsSignOperation';

import { LkSignOptions } from '../../store/slices/lkSignSlice';

export interface SecuritiesActivesInSearchRequest {
  searchString?: string;
  placeCode?: string;
  actAtCode?: string;
  countryCode?: string;
  page?: number;
  count?: number;
}

export interface SecuritiesActiveBase {
  placeCode: string;
  placeName: string;
  placeDescription: string;
  pCode: string;
  pName: string;
  regCode: string;
  isin: string;
  transferEnabled: boolean;
}

export interface SecuritiesActive extends SecuritiesActiveBase {
  treaty: number;
  accCode: string;
  shortName: string;
  qty: number;
  nominal: number;
  typeName: string;
}

export interface SecuritiesActiveIn extends SecuritiesActiveBase {
  emName: string;
  actAtCode: string;
  actFullName: string;
}

export interface ActiveTransfer {
  paper: SecuritiesActiveIn | SecuritiesActive;
  count: number;
  referenceNeed?: boolean;
  reference?: string;
  referenceState?: string;
  dealDate?: string;
  shipDate?: string;
  gbox?: string;
}

export interface SecuritiesActivesFilterItem {
  name: string;
  code: string;
}

export interface SecuritiesActivesInPage {
  actives: SecuritiesActiveIn[];
  pageCount: number;
  pageNumber: number;
}

export async function securitiesActivesInSearch(
  params: SecuritiesActivesInSearchRequest
): Promise<SecuritiesActivesInPage> {
  const request = makeGetParams(params);
  const res = await fetchLkResult<SecuritiesActivesInPage | LKResult>(
    `/api/v2/transfers/securities/in/search-actives?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface SecuritiesSearchParams {
  treaty: number;
  search: string;
}

export async function securitiesActivesSearch(
  data: SecuritiesSearchParams
): Promise<SecuritiesActive[]> {
  const request = makeGetParams(data);
  const res = await fetchLkResult<SecuritiesActivesResponse & LKResult>(
    `/api/v2/transfers/securities/search-actives?${request}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res.actives;
}

export interface SecuritiesActivesParams {
  treaty: number;
  accCode: string;
  placeCode: string;
}

interface SecuritiesActivesResponse {
  actives: SecuritiesActive[];
}

export async function getSecuritiesActives(
  params: SecuritiesActivesParams
): Promise<SecuritiesActive[]> {
  const request = makeGetParams(params);
  const res = await fetchLkResult<SecuritiesActive[] | LKResult>(
    `/api/v2/transfers/securities/actives?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface SecuritiesLimit {
  code: number;
  message: string;
  limit: number;
  taxPay: number;
  proz: number;
  calcTaxes: boolean;
  taxMessage: string;
}

export interface SecuritiesLimitParams {
  accCodeFrom: string;
  placeCodeFrom: string;
  isin: string;
}

export async function getSecuritiesLimitOut(
  params: SecuritiesLimitParams
): Promise<SecuritiesLimit> {
  const request = makeGetParams(params);
  const res = await fetchLkResult<SecuritiesLimit & LKResult>(
    `/api/v2/transfers/securities/out/limit?${request}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface SecuritiesPosition {
  treaty: number;
  accCode: string;
  nickName: string;
  placeCode: string;
  deponetPlaceCode: string;
  placeName: string;
  placeDescription: string;
  depoAccount: string;
  deponent: string;
  depositary: string;
  dpName: string;
  existActive: boolean;
}

export async function getSecuritiesPositions(): Promise<SecuritiesPosition[]> {
  const res = await fetchLkResult<SecuritiesPosition[] | LKResult>(
    '/api/v2/transfers/securities/positions',
    'GET'
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface SecuritiesActivePlacesParams {
  pCode: string;
  treaty: number;
}

export interface SecuritiesActivePlaceItem {
  placeCode: string;
  placeDescription: string;
}

interface SecuritiesActivePlaceListResponse {
  items: SecuritiesActivePlaceItem[];
}

export async function getSecuritiesActivePlaceList(
  data: SecuritiesActivePlacesParams
): Promise<SecuritiesActivePlaceItem[]> {
  const params = makeGetParams(data);
  const res = await fetchLkResult<SecuritiesActivePlaceListResponse & LKResult>(
    `/api/v2/transfers/securities/active-places?${params}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res.items;
}

export interface SecuritiesPlaceItem {
  id: number;
  name: string;
  placeCode: string;
  deponetPlaceCode: string;
}

interface SecuritiesPlaceListResponse {
  items: SecuritiesPlaceItem[];
}

export interface SecuritiesPlaceListRequest {
  treaty: number;
}

export async function getSecuritiesPlaceList(
  params: SecuritiesPlaceListRequest
): Promise<SecuritiesPlaceItem[]> {
  const request = makeGetParams(params);
  const res = await fetchLkResult<SecuritiesPlaceListResponse & LKResult>(
    `/api/v2/transfers/securities/place-list?${request}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res.items;
}

export interface SecuritiesUserType {
  id: number;
  name: 'OWNER' | 'HOLDER' | 'MANAGER';
  description: string;
}

interface SecuritiesUserTypeListResponse {
  items: SecuritiesUserType[];
}

export async function getSecuritiesUserTypeList(): Promise<
  SecuritiesUserType[]
> {
  const res = await fetchLkResult<SecuritiesUserTypeListResponse & LKResult>(
    `/api/v2/transfers/securities/user-type-list`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res.items;
}

export interface SecuritiesManager {
  managerDocType: string | null;
  managerDocSerNo: string | null;
  managerDocOrg: string | null;
  managerDocDate: string | null;
  managerFullName: string | null;
  isFirm: boolean;
  requisites: SecuritiesOrgRequisites | null;
  reference: string | null;
}

export interface SecuritiesOrgRequisites {
  orgName: string;
  ogrn: string | null;
  regDate: string;
  regOrg: string;
}

export async function getSecuritiesManager(
  treaty: number
): Promise<SecuritiesManager> {
  const res = await fetchLkResult<SecuritiesManager & LKResult>(
    `/api/requisites/firm-requisites?treaty=${treaty}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface SecuritiesReferenceInfoRequest {
  clientAccDepo: string;
  clientDeponetPlaceCode: string;
  contrDeponetPlaceCode: string;
  isin: string;
  isTrans: boolean;
  counteragentAccDepo?: string;
  targetDeponentCode?: string;
  transferType: 'in' | 'out';
  isInternal: boolean;
}

export interface SecuritiesClientReference {
  clientCode: string;
  clientAcc: string;
  clientDvn: string;
}

export interface SecuritiesReferenceInfo {
  clientRequisites: SecuritiesClientReference;
  counteragentRequisites: SecuritiesClientReference;
  swift: string;
  reference: string;
  comission: {
    amount: number;
    currency: string;
  };
}

export async function getSecuritiesReferenceInfo(
  params: SecuritiesReferenceInfoRequest
): Promise<SecuritiesReferenceInfo> {
  const request = makeGetParams(params);
  const res = await fetchLkResult<SecuritiesReferenceInfo & LKResult>(
    `/api/v2/transfers/securities/reference-info?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export type ReasonDocumentType = 'BASE' | 'OTHER';

export interface SecuritiesReasonDocument {
  id: number;
  docType: ReasonDocumentType;
  docName: string;
  name: string;
  number: string;
  date: string;
}

export interface SecuritiesDocumentType {
  id: number;
  docType: ReasonDocumentType;
  name: string;
  isChangeOfQwnerDoc: boolean;
  isNoChangeOfQwnerDoc: boolean;
  isTradeVolumeRequired: boolean;
  forReestr: boolean;
  sortNumber: number;
}

interface SecuritiesDocumentTypesResponce {
  documentTypes: SecuritiesDocumentType[];
}

export async function getSecuritiesDocumentTypes(): Promise<
  SecuritiesDocumentType[]
> {
  const res = await fetchLkResult<SecuritiesDocumentTypesResponce & LKResult>(
    `/api/v2/transfers/securities/document-type-list`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res.documentTypes;
}

export interface Currency {
  code: string;
  name: string;
}

interface CurrencyResponce {
  items: Currency[];
}

export async function getSecuritiesCurrencyList(): Promise<Currency[]> {
  const res = await fetchLkResult<CurrencyResponce & LKResult>(
    `/api/v2/transfers/securities/currency-list`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res.items;
}

export interface SecuritiesContragentRequisites {
  srcContragentAccount: string;
  srcDepoAccountPart: string | null;
  srcDeponentCode: string | null;
  swiftCode: string | null;
  placeCode?: string;
  chain?: string;
}

export interface SecuritiesContragent {
  srcContragent: string;
  srcPlaceCode: string;
  requisites: SecuritiesContragentRequisites[];
}

export type SecuritiesConteragentsType = 'in' | 'out';
export interface SecuritiesConteragentsParams {
  type?: SecuritiesConteragentsType;
}

export async function getSecuritiesConteragents(
  data: SecuritiesConteragentsParams
): Promise<SecuritiesContragent[]> {
  const params = makeGetParams(data);
  const res = await fetchLkResult<SecuritiesContragent[] & LKResult>(
    `/api/v2/transfers/securities/contragents?${params}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface SecuritiesTransferInActiveBase {
  treaty: number;
  targetAccCode: string;
  targetPlaceCode: string;
  targetDeponetPlaceCode: string;
  targetPlaceName: string;
  targetDepoAccount: string;
  targetDepositary: string;
  targetDpName: string;

  changeOfQwner: boolean;
  srcPlaceCode: string;
  srcDeponetPlaceCode: string;
  srcPlaceName: string;
  srcOtherPlace?: string;
  srcContragent?: string;
  srcDeponentCode?: string;
  srcContragentAccount: string;
  srcDepoAccountPart?: string;
  srcContragentAccDepo?: string;
  swiftCode?: string;
  gbox?: string;
  isInternal: boolean;

  // Реестр
  srcAccountType?: string;
  srcAccountTypeDescription?: string;
  srcPersonalName?: string;
  srcPersonalDocNumber?: string;
  srcPersonalDocDate?: string;
  srcPersonalDocOrg?: string;
  srcOrgName?: string;
  srcOrgOgrn?: string;
  srcOrgRegDate?: string;
  srcOrgRegOrg?: string;

  comment: string;
  documents?: SecuritiesReasonDocument[];

  tradeVolume?: number;
  tradeCurrCode?: string;
}

export interface SecuritiesTransferInActive
  extends SecuritiesTransferInActiveBase {
  pCode: string;
  isin: string;
  qty: number;
  tradeDate: string;
  tradeDateDelivery: string;
  reference?: string;
}

export interface SecuritiesTransferInRequest
  extends SecuritiesTransferInActive {}

export interface SecuritiesTransferInParams {
  params: SecuritiesTransferInRequest;
  signOptions?: LkSignOptions;
}

export async function securitiesTransferIn(data: SecuritiesTransferInParams) {
  return await signOperation(
    '/api/v2/transfers/securities/in',
    'POST',
    data.params,
    data.signOptions
  );
}

export interface SecuritiesTransferPackageInParams {
  params: SecuritiesTransferInActive[];
  signOptions?: LkSignOptions;
}

export async function securitiesTransferPackageIn(
  data: SecuritiesTransferPackageInParams
) {
  return await signOperation(
    '/api/v2/transfers/securities/package/in',
    'POST',
    data.params,
    data.signOptions
  );
}

export async function securitiesTransferInSms(
  data: SecuritiesTransferInRequest
) {
  return await smsSignOperation(
    `/api/v2/transfers/securities/in`,
    'POST',
    data
  );
}

export interface SecuritiesTransferOutBase {
  treaty: number;
  srcAccCode: string;
  srcPlaceCode: string;
  srcDeponetPlaceCode: string;
  srcPlaceName: string;
  srcDepoAccount: string;
  srcDepositary: string;
  srcDpName: string;

  changeOfQwner: boolean;
  targetPlaceCode: string;
  targetDeponetPlaceCode: string;
  targetPlaceName: string;
  targetOtherPlace?: string;
  targetContragent?: string;
  targetDeponentCode?: string;
  targetContragentAccount: string;
  targetDepoAccountPart?: string;
  targetContragentAccDepo?: string;
  targetTreaty?: number;
  swiftCode?: string;
  gbox?: string;
  isInternal: boolean;

  // Реестр
  targetAccountType?: string;
  targetAccountTypeDescription?: string;
  targetPersonalName?: string;
  targetPersonalDocNumber?: string;
  targetPersonalDocDate?: string;
  targetPersonalDocOrg?: string;
  targetOrgName?: string;
  targetOrgOgrn?: string;
  targetOrgRegDate?: string;
  targetOrgRegOrg?: string;

  comment: string;
  documents?: SecuritiesReasonDocument[];

  tradeVolume?: number;
  tradeCurrCode?: string;
}

export interface SecuritiesTransferOutActive extends SecuritiesTransferOutBase {
  pCode: string;
  isin: string;
  qty: number;
  tradeDate: string;
  tradeDateDelivery: string;
  reference?: string;
}

export interface SecuritiesTransferOutRequest
  extends SecuritiesTransferOutActive {}

export interface SecuritiesTransferOutParams {
  params: SecuritiesTransferOutRequest;
  signOptions?: LkSignOptions;
}

export async function securitiesTransferOut(data: SecuritiesTransferOutParams) {
  return await signOperation(
    '/api/v2/transfers/securities/out',
    'POST',
    data.params,
    data.signOptions
  );
}

export async function securitiesTransferOutSms(
  data: SecuritiesTransferOutRequest
) {
  return await smsSignOperation(
    `/api/v2/transfers/securities/out`,
    'POST',
    data
  );
}

export interface SecuritiesTransferChangeBase {
  treaty: number;
  srcAccCode: string;
  srcPlaceCode: string;
  targetAccCode: string;
  targetPlaceCode: string;
  targetDepoAccount: string;
  targetDepositary: string;
  srcPlaceName: string;
  targetPlaceName: string;
  srcDpName: string;
  targetDpName: string;
  srcDepoAccount: string;
}

export interface SecuritiesTransferChangeActive
  extends SecuritiesTransferChangeBase {
  pCode: string;
  qty: number;
  nominalVolume: number;
}

export interface SecuritiesTransferChangeParams {
  params: SecuritiesTransferChangeActive;
  signOptions?: LkSignOptions;
}

export async function securitiesTransferChange(
  data: SecuritiesTransferChangeParams
) {
  return await signOperation(
    '/api/v2/transfers/securities/change',
    'POST',
    data.params,
    data.signOptions
  );
}

// История
export type SecuritiesOperationType = 'INTRA' | 'EXTRA_IN' | 'EXTRA_OUT';

export interface SecuritiesHistoryFilter {
  treaty?: number;
  searchString?: string;
  tranType?: SecuritiesOperationType[];
  startDate?: string;
  endDate?: string;
  statuses?: string;
}

export interface SecuritiesHistoryParams extends SecuritiesHistoryFilter {
  page?: number;
  count?: number;
}

export const enum SecuritiesHistoryStatusCode {
  Submitted = 'S', // Принято
  Transmitted = 'T', // Отправлено на исполнение
  OrderIsAbsent = 'O', // Нет встречного поручения
  ParametersDiffer = 'P', // Параметры поручения не совпали со встречным
  Executed = 'E', // Исполнено
  Declined = 'D', // Отвергнуто
  Rejected = 'R', // Отменено
}

export interface SecuritiesHistoryItem {
  depoDate: string;
  accCode: string;
  nickName: string;
  accCodeSource: string;
  nickNameSource: string;
  tranType: SecuritiesOperationType;
  trnNo: number;
  volume: number;
  volumeSource: number;
  pName: string;
  status: SecuritiesHistoryStatusCode;
  statusDescription: string;
  trnSrc: number;
  aDate: string;
  orderReference: string | null;
  deponet: boolean;
}

export interface SecuritiesHistoryPage {
  transfers: SecuritiesHistoryItem[];
  pageCount: number;
  pageNumber: number;
}

export async function getSecuritiesHistory(
  data: SecuritiesHistoryParams
): Promise<SecuritiesHistoryPage> {
  const params = makeGetParams(data);
  const res = await fetchLkResult<SecuritiesHistoryPage | LKResult>(
    `/api/v2/transfers/securities/history?${params}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface SecuritiesHistoryDetail {
  info: SecuritiesHistoryDetailInfo;
  source: SecuritiesTargetPlace;
  target: SecuritiesTargetPlace;
  operationBasis: SecuritiesOperationBasis;
  controlInfo: SecuritiesControlInfo;
}

export interface SecuritiesHistoryDetailInfo {
  paperInfo: SecuritiesPaper;
  depoDate: string;
  deponet: boolean;
  tranType: SecuritiesOperationType;
  trnNo: number;
  volume: number;
  status: SecuritiesHistoryStatusCode;
  statusDescription: string;
  isRejectId?: number;
  textDescriptions?: string;
  signText?: string;
}

export interface SecuritiesPaper {
  pCode: string;
  pName: string;
  emName: string;
  regCode: string;
  actAtCode: string;
  actFullName: string;
  isin: string;
}

export interface SecuritiesTargetPlace {
  account: string | null;
  placeName: string | null;
  deponentCode: string | null;
  depoAccount: string | null;
  depoAccountPart: string | null;
  contragent: string | null;
  nickName: string | null;
  bankDepoAccount: string | null;
}

export interface SecuritiesOperationBasis {
  comment: string;
  documents: string[];
}

export interface SecuritiesControlInfo {
  dealVolume: number;
}

export interface SecuritiesHistoryDetailParams {
  trnNo: number;
  trnSrc?: number;
  aDate: string;
  treaty: number;
  deponet: boolean;
}

export async function getSecuritiesHistoryDetail(
  data: SecuritiesHistoryDetailParams
): Promise<SecuritiesHistoryDetail> {
  const params = makeGetParams(data);
  const res = await fetchLkResult<SecuritiesHistoryDetail | LKResult>(
    `/api/v2/transfers/securities/history/details?${params}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

// XML поручения
export interface SecuritiesTransferXMLParams {
  params: FormData;
  signOptions?: LkSignOptions;
}

export async function securitiesTranferXml(data: SecuritiesTransferXMLParams) {
  const headers = new Headers();

  headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
  headers.append('Platform', 'WT');

  return await signOperation(
    '/api/v2/transfers/securities/from-file',
    'POST',
    data.params,
    data.signOptions,
    headers
  );
}

// Несколько файлов
type SecuritiesTransferXMLInitStatus =
  | 'Default'
  | 'InProgress'
  | 'Error'
  | 'Done';

export interface SecuritiesTransferXMLUploadStatus {
  id: string;
  state: SecuritiesTransferXMLInitStatus;
  currentItem: number;
  count: number;
}

export async function securitiesTranferXmlMultipleUpload(
  data: FormData
): Promise<SecuritiesTransferXMLUploadStatus> {
  const headers = new Headers();

  headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
  headers.append('Platform', 'WT');

  const res = await fetchLkResult<SecuritiesTransferXMLUploadStatus & LKResult>(
    `/api/v2/transfers/securities/multiple/validate`,
    'POST',
    data,
    false,
    headers
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export async function getSecuritiesTranferXmlMultipleStatus(
  id: string
): Promise<SecuritiesTransferXMLUploadStatus> {
  const res = await fetchLkResult<SecuritiesTransferXMLUploadStatus & LKResult>(
    `/api/v2/transfers/securities/multiple/status/${id}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface SecuritiesTranferXmlMultipleOperation extends LKResult {
  model: {
    header: {
      metadata: {
        objectId: string;
      };
    };
    body: {
      direction: string;
      book: string;
      from: string;
      to: string;
      isin: string;
      quantity: number;
      cagent: string;
      depoAccount: string;
      rightsTransition: string;
      transitionGroundType: string;
      transitionGroundNo: string;
      transitionGroundDate: string;
      transitionGroundAmount: number;
      transitionGroundCurr: string;
      tradeDate: string;
      deliveryDate: string;
    };
  };
}

export interface SecuritiesTranferXmlMultipleResilt {
  operations: SecuritiesTranferXmlMultipleOperation[];
  count: number;
  countErrors: number;
}

export async function getSecuritiesTranferXmlMultipleOperations(
  id: string
): Promise<SecuritiesTranferXmlMultipleResilt> {
  const res = await fetchLkResult<
    SecuritiesTranferXmlMultipleResilt & LKResult
  >(`/api/v2/transfers/securities/multiple/validate/${id}`);

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export function cancelSecuritiesTranferXmlMultipleUpload(
  id: string
): Promise<void> {
  return fetchLkResult(
    `/api/v2/transfers/securities/multiple/cancel/${id}`,
    'POST'
  );
}

export interface SecuritiesTranferXmlMultipleSubmitRequest {
  operations: SecuritiesTranferXmlMultipleOperation[];
}

export interface SecuritiesTranferXmlMultipleSubmitParams {
  params: SecuritiesTranferXmlMultipleSubmitRequest;
  id: string;
  signOptions?: LkSignOptions;
}

export async function securitiesTranferXmlMultipleSubmit(
  data: SecuritiesTranferXmlMultipleSubmitParams
) {
  return await signOperation(
    `/api/v2/transfers/securities/multiple/move/${data.id}`,
    'POST',
    data.params,
    data.signOptions
  );
}

// Драфты
export type SecuritiesDraftOpType = 'in' | 'out';
export type SecuritiesDraftOpTypeFilter = 'all' | SecuritiesDraftOpType;

export interface SecuritiesDraftsFilter {
  treaty?: number;
  transferType?: SecuritiesDraftOpTypeFilter;
}

export interface SecuritiesDraftsParams extends SecuritiesDraftsFilter {
  page?: number;
  count?: number;
}

export interface SecuritiesDraftListItem {
  id: number;
  isin: string;
  activeName: string;
  qty: number;
  transferType: SecuritiesDraftOpType;
}

export interface SecuritiesDrafts {
  drafts: SecuritiesDraftListItem[];
  pageCount: number;
  pageNumber: number;
}

/** Запрос списка драфтов */
export async function getSecuritiesDrafts(
  data: SecuritiesDraftsParams
): Promise<SecuritiesDrafts> {
  const params = makeGetParams(data);
  const res = await fetchLkResult<SecuritiesDrafts | LKResult>(
    `/api/v2/transfers/securities/draft/list?${params}`,
    'GET'
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

/** Отмена драфта */
export interface SecuritiesDraftsRejectParams {
  treaty: number;
  id: number;
}

export async function rejectSecuritiesDraft(
  data: SecuritiesDraftsRejectParams
): Promise<LKResult> {
  const res = await fetchLkResult<LKResult>(
    `/api/v2/transfers/securities/draft/reject`,
    'POST',
    data
  );

  if ('code' in res && res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

/** Детали драфта */
export interface SecuritiesDraftDetailsParams {
  treaty: number;
  id: number;
}

export interface SecuritiesDraftDetailsCounteragent {
  accDepo: string;
  place: string;
  code: string;
  account: string;
  dvn: string;
  acceptor: string;
}

export interface SecuritiesDraftDetails {
  id: number;
  order: {
    isTrans: boolean;
    isInternal: boolean;
    transferType: SecuritiesDraftOpType;
    client: SecuritiesDraftDetailsCounteragent;
    calcPlace: string;
    counter: SecuritiesDraftDetailsCounteragent;
    isin: string;
    assetName: string;
    qty: number;
    swiftCode: string;
    checkDate: string;
    execDate: string;
    reference: string;
    gbox: string;
    register: {
      name: string;
      acc: string;
      accType: string;
    };
    document: {
      docType: string;
      docSeria: string;
      docNum: string;
      docDate: string;
      docOrg: string;
    };
    output: {
      accountId: string;
      subAccountId: string;
      marketId: string;
    };
    input: {
      accountId: string;
      subAccountId: string;
      marketId: string;
    };
    contract: {
      type: string;
      number: string;
      createdDate: string;
      amount: string;
      currency: string;
    };
    signText: string;
    comment: string;
    comission: {
      amount: number;
      currency: string;
    };
  };
}

export async function getSecuritiesDraftDetails(
  data: SecuritiesDraftDetailsParams
): Promise<SecuritiesDraftDetails> {
  const params = makeGetParams(data);
  const res = await fetchLkResult<SecuritiesDraftDetails | LKResult>(
    `/api/v2/transfers/securities/draft/detail?${params}`,
    'GET'
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

/** Подпись драфта */
export interface SecuritiesDraftConfirmRequest extends SecuritiesDraftDetails {
  treaty: number | undefined;
}

export interface SecuritiesDraftConfirmParams {
  params: SecuritiesDraftConfirmRequest;
  signOptions?: LkSignOptions;
}

export async function confirmSecuritiesDraft(
  data: SecuritiesDraftConfirmParams
) {
  return await signOperation(
    `/api/v2/transfers/securities/draft/confirm`,
    'POST',
    data.params,
    data.signOptions
  );
}
