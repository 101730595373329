import { endOfYear, format, setYear, startOfYear } from 'date-fns';

import {
  dateToRequestFormat,
  DEFAULT_VIEW_DATE_FORMAT,
} from '@terminal/core/lib/rest/lkApi';
import {
  DocumentReceiving,
  DocumentReceivingType,
  ReportsMultiplyGenerateParams,
  TReportFormat,
} from '@terminal/core/lib/rest/lkDocuments';
import { makeGetParams } from '@terminal/core/lib/url';

import { ActiveFormType } from '../../entities/Documents';
import { LK_API } from '../const/externalUrls';

const obtainOrder: DocumentReceivingType[] = [
  'online',
  'inlk',
  'electronic',
  'inbank',
  'bymail',
];

export function sortObtain(a: DocumentReceiving, b: DocumentReceiving) {
  if (obtainOrder.indexOf(a.type) < 0 && obtainOrder.indexOf(b.type) >= 0) {
    return 1;
  } else if (
    obtainOrder.indexOf(a.type) >= 0 &&
    obtainOrder.indexOf(b.type) < 0
  ) {
    return -1;
  }

  return obtainOrder.indexOf(a.type) - obtainOrder.indexOf(b.type);
}

export function obtainRename(obtain: DocumentReceiving): DocumentReceiving {
  if (obtain.type === 'electronic') {
    return {
      ...obtain,
      description: 'На электронную почту',
    };
  }

  return obtain;
}

export function getObtainHint(
  type: DocumentReceivingType,
  formType?: ActiveFormType
): string {
  if (type === 'bymail' && formType === 'depoReport') {
    return 'Подготовим в течение 30 дней. Срок доставки зависит от почтовой службы.';
  }

  switch (type) {
    case 'inbank':
      return 'Срок изготовления: до 20 дней';
    case 'inlk':
      return 'Справка будет добавлена в раздел Налоговая информация';
    case 'bymail':
      return 'Срок изготовления: до 20 дней';
    case 'electronic':
    default:
      return '';
  }
}

export function getPeriodFromYear(
  year: string,
  dateFormat: string = DEFAULT_VIEW_DATE_FORMAT
) {
  const date = setYear(new Date(), parseInt(year));

  return {
    periodStart: format(startOfYear(date), dateFormat),
    periodEnd: format(endOfYear(date), dateFormat),
  };
}

export const enum REPORT_TYPE {
  MyPortfolio = 17,
  MyBroker = 18,
  Taxes = 19,
  TaxAppendix = 20,
  MyMoneyMove = 21,
  SecuritiesMovementStatementDepoAccount = 23,
  DepoAccountStatusStatement = 24,
  IncomePaymentsReportOnSecurities = 25,
}

export const enum REPORT_FORMAT {
  Excel = 'EXCEL',
  Pdf = 'Pdf',
  Xml = 'XML',
  XmlImport = 'XML_IMPORT',
}

export function getReportType(theme: REPORT_TYPE) {
  switch (theme) {
    case REPORT_TYPE.MyPortfolio:
      return 'MyPortfolio';
    case REPORT_TYPE.MyBroker:
      return 'MyBroker';
    case REPORT_TYPE.Taxes:
      return 'Taxes';
    case REPORT_TYPE.TaxAppendix:
      return 'TaxAppendix';
    case REPORT_TYPE.MyMoneyMove:
      return 'MyMoneyMove';
    case REPORT_TYPE.IncomePaymentsReportOnSecurities:
      return 'IncomePaymentsReportOnSecurities';
    case REPORT_TYPE.SecuritiesMovementStatementDepoAccount:
      return 'SecuritiesMovementStatementDepoAccount';
    case REPORT_TYPE.DepoAccountStatusStatement:
      return 'DepoAccountStatusStatement';
  }
}

export function generateReportUrl(params: {
  report: REPORT_TYPE;
  format: REPORT_FORMAT;
  start: string;
  end: string;
  treaty?: string;
  hideSections?: string;
  multiple?: boolean;
  inline?: boolean;
}) {
  const defaults = {
    treaty: '0',
    hideSections: '0',
    multiple: false,
  };
  const opt = {
    ...defaults,
    ...params,
    report: getReportType(params.report),
  };

  if (opt.format === REPORT_FORMAT.XmlImport) {
    const request = makeGetParams({
      treaty: opt.treaty,
      startDate: dateToRequestFormat(opt.start),
      endDate: dateToRequestFormat(opt.end),
      isMultiple: opt.multiple.toString(),
    });

    return `${LK_API.REPORT_FILE_BASE}/broker-report?${request}`;
  } else {
    let url = LK_API.REPORT_FILE_BASE + '/';

    if (opt.inline) {
      url += 'inline/';
    }

    url += opt.report + '/';
    url += dateToRequestFormat(opt.start) + '/';
    url += dateToRequestFormat(opt.end) + '/';
    url += opt.format + '/';
    url += opt.hideSections + '/';
    url += opt.treaty;

    if (opt.inline) {
      url += '?report.' + opt.format.toLowerCase();
    }

    return url;
  }
}

export function prepareMultiplyReportsParams(params: {
  themeId: string;
  periodStart: string;
  periodEnd: string;
  format: TReportFormat;
}): ReportsMultiplyGenerateParams {
  return {
    report: getReportType(parseInt(params.themeId)),
    strDateStart: params.periodStart,
    strDateEnd: params.periodEnd,
    format: params.format,
    hideSection: 0,
  };
}
