import React, { FC, PropsWithChildren, useMemo } from 'react';

import { ChatContext, Context } from '../../../shared/lib/ChatContext';

export const ChatProvider: FC<PropsWithChildren<ChatContext>> = ({
  supportChat,
  supportPhone,
  supportEmail,
  feedbackEmail,
  children,
}) => {
  const value: ChatContext = useMemo(
    () => ({
      supportChat,
      supportPhone,
      supportEmail,
      feedbackEmail,
    }),
    [supportChat, supportPhone, supportEmail, feedbackEmail]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
