import { Button } from '@alfalab/core-components/button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { SquareAcademicCapMIcon } from '@alfalab/icons-glyph/SquareAcademicCapMIcon';

import styles from './TestRequired.module.css';

interface TestRequiredProps {
  title?: React.ReactNode;
  text?: React.ReactNode;
  testUrl?: string;
  onTestRedirect?: () => void;
  additionalButtonProps?: {
    text: string;
    onClick: () => void;
  };
}

export const TestRequired = ({
  title,
  text,
  testUrl,
  onTestRedirect,
  additionalButtonProps,
}: TestRequiredProps) => (
  <div className={styles.container}>
    <div className={styles.icon}>
      <SuperEllipse size={64}>
        <SquareAcademicCapMIcon />
      </SuperEllipse>
    </div>
    <Typography.TitleResponsive
      view="xsmall"
      color="secondary"
      tag="h3"
      weight="bold"
      defaultMargins={false}
      className={styles.title}
    >
      {title || 'Необходимо пройти тестирование'}
    </Typography.TitleResponsive>
    <Typography.Text
      view="secondary-small"
      color="secondary"
      tag="p"
      defaultMargins={false}
      className={styles.text}
    >
      {text}
    </Typography.Text>
    {!!testUrl && (
      <div className={styles.action}>
        <Button href={testUrl} target="_blank" block size="s">
          Пройти тестирование
        </Button>
      </div>
    )}
    <Space direction="horizontal" size={8}>
      {!!additionalButtonProps && (
        <div className={styles.action}>
          <Button onClick={additionalButtonProps.onClick} block size="s">
            {additionalButtonProps.text}
          </Button>
        </div>
      )}
      {!!onTestRedirect && (
        <div className={styles.action}>
          <Button
            onClick={onTestRedirect}
            block
            size="s"
            view={additionalButtonProps ? 'outlined' : undefined}
          >
            Пройти тестирование
          </Button>
        </div>
      )}
    </Space>
  </div>
);
