import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import { useMemo, useRef, useState } from 'react';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkSIcon } from '@alfalab/icons-glyph/CheckmarkSIcon';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { ToggleOff, ToggleOn } from '@terminal/core/constants/icons';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import {
  LimitsProps,
  TradingLimitsShowMode,
} from '@terminal/core/types/layout';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

import { useWidgetContext } from '../../../../shared';

import styles from './TradingLimitsSettings.module.css';

interface Props extends BaseModalProps {
  onClose: () => void;
  limitsProps?: LimitsProps;
  nodeId?: string;
}

const npuOptions = [
  {
    key: TradingLimitsShowMode.MONEY,
    content: 'Деньги',
  },
  {
    key: TradingLimitsShowMode.QUANTITY,
    content: 'Проценты',
  },
  {
    key: TradingLimitsShowMode.POINTS,
    content: 'Пункты',
  },
];

export const TradingLimitsSettings = ({
  onClose,
  limitsProps,
  nodeId,
  ...baseModalProps
}: Props) => {
  // Настройки отображения
  const [showPosition, setShowPosition] = useState(
    limitsProps?.showPosition ?? true
  );
  const [showLimits, setShowLimits] = useState<TradingLimitsShowMode>(
    limitsProps?.showLimits ?? TradingLimitsShowMode.MONEY
  );
  const [showNPU, setShowNPU] = useState<TradingLimitsShowMode>(
    limitsProps?.showNPU ?? TradingLimitsShowMode.MONEY
  );

  const limitsContainerRef = useRef<HTMLDivElement>(null);
  const limitsDropdownRef = useRef<HTMLDivElement>(null);
  const npuContainerRef = useRef<HTMLDivElement>(null);
  const npuDropdownRef = useRef<HTMLDivElement>(null);
  const [isLimitsSelectOpen, setIsLimitsSelectOpen] = useState(false);
  const [isNpuSelectOpen, setIsNpuSelectOpen] = useState(false);

  useOnClickOutside(limitsDropdownRef, (e) => {
    const anchor = limitsContainerRef.current;

    //Закрываем только если это НЕ клик в кнопку управляющую дропдауном
    if (anchor && !anchor?.contains(e.target as Node)) {
      setIsLimitsSelectOpen(false);
    }
  });
  useOnClickOutside(npuDropdownRef, (e) => {
    const anchor = npuContainerRef.current;

    //Закрываем только если это НЕ клик в кнопку управляющую дропдауном
    if (anchor && !anchor?.contains(e.target as Node)) {
      setIsNpuSelectOpen(false);
    }
  });
  const { useSettings, updateNode, DropdownComponent } = useWidgetContext();
  const {
    defaultValues: { orderDimension },
  } = useSettings();

  const limitOptions = useMemo(
    () => [
      {
        key: TradingLimitsShowMode.MONEY,
        content: 'Деньги',
      },
      {
        key: TradingLimitsShowMode.QUANTITY,
        content: orderDimension === OrderDimensionEnum.LOT ? 'Лоты' : 'Штуки',
      },
    ],
    [orderDimension]
  );

  const limitsName = useMemo(
    () => limitOptions.find((type) => type.key === showLimits)?.content || '',
    [limitOptions, showLimits]
  );
  const npuName = useMemo(
    () => npuOptions.find((type) => type.key === showNPU)?.content || '',
    [showNPU]
  );

  const newLimitsProps: LimitsProps = useMemo(
    () => ({
      showLimits,
      showNPU,
      showPosition,
    }),
    [showLimits, showNPU, showPosition]
  );

  const handleCancel = () => {
    setShowPosition(limitsProps?.showPosition ?? true);
    setShowLimits(limitsProps?.showLimits ?? TradingLimitsShowMode.MONEY);
    setShowNPU(limitsProps?.showNPU ?? TradingLimitsShowMode.MONEY);
    onClose();
  };

  const saveSettings = () => {
    if (nodeId) {
      updateNode(nodeId, { limitsProps: newLimitsProps });
    }

    onClose();
  };

  const isSaveDisabled = useMemo(() => {
    return isEqual(newLimitsProps, limitsProps);
  }, [limitsProps, newLimitsProps]);

  return (
    <BaseModal
      {...baseModalProps}
      onClose={handleCancel}
      className={styles.contextMenuSettings}
    >
      <div className={styles.settingsHeader}>
        <Typography.Text view="secondary-large" weight="bold">
          Информационная строка
        </Typography.Text>
        <IconButton
          size="xs"
          view="secondary"
          icon={CrossMIcon}
          onClick={handleCancel}
        />
      </div>
      <div className={styles.settingsContent}>
        <div
          onClick={() => setShowPosition((prev) => !prev)}
          className={styles.settingWrapper}
        >
          <Typography.Text view="secondary-large" weight="medium">
            Показывать позицию
          </Typography.Text>
          {showPosition ? <ToggleOn /> : <ToggleOff />}
        </div>

        {/* Настройка отображения лимитов */}
        <div
          onClick={() =>
            setShowLimits((prev) =>
              prev === TradingLimitsShowMode.NONE
                ? TradingLimitsShowMode.MONEY
                : TradingLimitsShowMode.NONE
            )
          }
          className={styles.settingWrapper}
        >
          <Typography.Text view="secondary-large" weight="medium">
            Показывать лимиты
          </Typography.Text>
          {showLimits !== TradingLimitsShowMode.NONE ? (
            <ToggleOn />
          ) : (
            <ToggleOff />
          )}
        </div>
        {showLimits !== TradingLimitsShowMode.NONE && (
          <>
            <Typography.Text
              view="secondary-small"
              color="secondary"
              className={styles.label}
            >
              Отображение
            </Typography.Text>
            <div
              ref={limitsContainerRef}
              className={styles.dropdownRef}
              onClick={() => {
                setIsLimitsSelectOpen((prev) => !prev);
              }}
            >
              <div className={styles.dropDownContent}>
                <Typography.Text
                  view="secondary-large"
                  className={styles.optionName}
                >
                  {limitsName}
                </Typography.Text>
                <ChevronDownMIcon
                  height={18}
                  width={18}
                  className={cn(
                    styles.chevron,
                    isLimitsSelectOpen && styles.chevronUp
                  )}
                />
              </div>
              <DropdownComponent
                useAnchorWidth
                ref={limitsDropdownRef}
                anchorElement={limitsContainerRef.current}
                open={isLimitsSelectOpen}
                offset={[0, 4]}
                position="bottom"
                popperClassName={styles.settingsSelect}
              >
                {limitOptions.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    content={
                      <div className={styles.dropDownItemContent}>
                        {option.content}
                      </div>
                    }
                    onClick={() => {
                      if (option) {
                        setShowLimits(option.key);
                      }
                    }}
                    rightIcon={
                      showLimits === option.key ? (
                        <CheckmarkSIcon
                          height={18}
                          width={18}
                          color="var(--color-dark-graphic-secondary)"
                        />
                      ) : undefined
                    }
                  />
                ))}
              </DropdownComponent>
            </div>
          </>
        )}

        {/* Настройка отображения NPU */}
        <div
          onClick={() =>
            setShowNPU((prev) =>
              prev === TradingLimitsShowMode.NONE
                ? TradingLimitsShowMode.MONEY
                : TradingLimitsShowMode.NONE
            )
          }
          className={styles.settingWrapper}
        >
          <Typography.Text view="secondary-large" weight="medium">
            Показывать P&L
          </Typography.Text>
          {showNPU !== TradingLimitsShowMode.NONE ? (
            <ToggleOn />
          ) : (
            <ToggleOff />
          )}
        </div>
        {showNPU !== TradingLimitsShowMode.NONE && (
          <>
            <Typography.Text
              view="secondary-small"
              color="secondary"
              className={styles.label}
            >
              Отображение
            </Typography.Text>
            <div
              ref={npuContainerRef}
              className={styles.dropdownRef}
              onClick={() => {
                setIsNpuSelectOpen((prev) => !prev);
              }}
            >
              <div className={styles.dropDownContent}>
                <Typography.Text
                  view="secondary-large"
                  className={styles.optionName}
                >
                  {npuName}
                </Typography.Text>
                <ChevronDownMIcon
                  height={18}
                  width={18}
                  className={cn(
                    styles.chevron,
                    isNpuSelectOpen && styles.chevronUp
                  )}
                />
              </div>
              <DropdownComponent
                ref={npuDropdownRef}
                anchorElement={npuContainerRef.current}
                useAnchorWidth
                open={isNpuSelectOpen}
                offset={[0, 4]}
                position="bottom"
                popperClassName={styles.settingsSelect}
              >
                {npuOptions.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    content={
                      <div className={styles.dropDownItemContent}>
                        {option.content}
                      </div>
                    }
                    onClick={() => {
                      if (option) {
                        setShowNPU(option.key);
                      }
                    }}
                    rightIcon={
                      showNPU === option.key ? (
                        <CheckmarkSIcon
                          height={18}
                          width={18}
                          color="var(--color-dark-graphic-secondary)"
                        />
                      ) : undefined
                    }
                  />
                ))}
              </DropdownComponent>
            </div>
          </>
        )}

        <div className={styles.buttonToolbar}>
          <Button
            className={styles.customButton}
            view="ghost"
            size="xxs"
            onClick={() => handleCancel()}
          >
            Отмена
          </Button>
          <Button
            className={styles.customButton}
            size="xxs"
            view="secondary"
            disabled={isSaveDisabled}
            onClick={() => saveSettings()}
          >
            Применить
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
