import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  getSecuritiesReferenceInfo,
  SecuritiesReferenceInfoRequest,
} from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesReferenceInfo = (
  params?: SecuritiesReferenceInfoRequest
) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.SecuritiesManager, { params }],
    async () => getSecuritiesReferenceInfo(params!),
    {
      // При переводе внутри контрагента или при смене прав собственности "counteragentAccDepo" обязателен
      enabled: Boolean(
        params &&
          params.isin &&
          (!params.isTrans || params.counteragentAccDepo) &&
          (!params.isInternal || params.counteragentAccDepo)
      ),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || '',
        });
      },
    }
  );
};
