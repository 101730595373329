import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Loader } from '@alfalab/core-components/loader';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
import { Radio } from '@alfalab/core-components/radio';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowBackMIcon } from '@alfalab/icons-glyph/ArrowBackMIcon';
import { ArrowsRightLeftCurvedMIcon } from '@alfalab/icons-glyph/ArrowsRightLeftCurvedMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

import { Spinner } from '@terminal/common/components/Spinner';

import { ResultScreen } from './ResultScreen';
import { decapitalize } from './shared';

import { useQualTesting } from '../hooks';

import styles from './QualTestPlayer.module.css';

interface QualTestPlayerProps {
  onCloseTest?: () => void;
  selectedTestId: number;
  redirectToOperations?: () => void;
}

export function QualTestPlayer({
  selectedTestId,
  onCloseTest,
  redirectToOperations,
}: QualTestPlayerProps) {
  const {
    testStatusData,
    checkResult,
    testQuestion,
    selectedAnswerId,
    percentage,
    resetTest,
    onAnswerSelect,
    isQuestionLoading,
    isTestFailed,
    isLoaderDisplayed,
    isCheckAnswerLoading,
    isFatalError,
    onAnswerConfirm,
  } = useQualTesting(selectedTestId);

  if (isLoaderDisplayed) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  if (
    checkResult?.nextQuestionNumber === 0 &&
    !checkResult?.wrongQuestions?.length
  ) {
    return (
      <ResultScreen
        onBackToList={onCloseTest}
        testName={testStatusData?.testStatus?.testName}
        testId={selectedTestId}
        redirectToOperations={redirectToOperations}
      />
    );
  }

  if (isFatalError && onCloseTest) {
    onCloseTest();
  }

  if (isTestFailed) {
    return (
      <ResultScreen isFailed>
        <div className={styles.resultButtonBlock}>
          <ButtonDesktop
            size="s"
            block
            view="secondary"
            leftAddons={<ArrowsRightLeftCurvedMIcon width={18} height={18} />}
            onClick={resetTest}
          >
            Попробовать ещё раз
          </ButtonDesktop>
        </div>
      </ResultScreen>
    );
  }

  return (
    <div className={styles.container}>
      <Typography.Text
        view="primary-medium"
        weight="bold"
        className={styles.title}
        tag="div"
      >
        {testStatusData?.testStatus?.testName}
      </Typography.Text>
      <Typography.Text view="secondary-small" color="secondary">
        {`После успешного прохождения теста вам будут доступны ${decapitalize(
          testStatusData?.testStatus?.testName
        )}`}
      </Typography.Text>
      {testQuestion?.question?.questionNumber && testStatusData && (
        <div className={styles.progress}>
          <Typography.Text
            view="secondary-small"
            color="secondary"
            className={styles.progressLabel}
            tag="div"
          >
            {`Вопрос ${testQuestion?.question?.questionNumber} из ${testStatusData?.questionCount}. Тест пройден на ${percentage} %`}
          </Typography.Text>
          <ProgressBar value={percentage} view="accent" />
        </div>
      )}
      <div className={styles.question}>
        {isQuestionLoading ? (
          <Loader />
        ) : (
          <>
            <Typography.Text
              view="secondary-large"
              color="secondary"
              weight="bold"
              className={styles.questionTitle}
              tag="div"
            >
              {testQuestion?.question.questionName}
            </Typography.Text>
            {testQuestion?.answers.map((answer) => (
              <div key={answer.answerId} className={styles.answerItem}>
                <Radio
                  onClick={() => onAnswerSelect(answer.answerId)}
                  checked={selectedAnswerId === answer.answerId}
                  label={answer.answerName}
                  value={answer.answerId.toString()}
                />
                {answer.noteText && (
                  <TooltipDesktop
                    contentClassName={styles.tooltipContent}
                    targetClassName={styles.tooltipIcon}
                    content={answer.noteText}
                    position="bottom"
                    arrowClassName={styles.hideArrow}
                  >
                    <InformationCircleLineMIcon width={16} height={16} />
                  </TooltipDesktop>
                )}
              </div>
            ))}
            <div className={styles.next}>
              <ButtonDesktop
                block
                rightAddons={<ArrowBackMIcon className={styles.nextArrow} />}
                size="s"
                disabled={!selectedAnswerId}
                loading={isCheckAnswerLoading}
                onClick={onAnswerConfirm}
              >
                Далее
              </ButtonDesktop>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
