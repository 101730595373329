import qs from 'query-string';

import { smsSignOperation } from '@terminal/core/lib/rest/lkSmsSignOperation';

export async function addAccountRegisterGI(operationId: string) {
  return await smsSignOperation(
    qs.stringifyUrl({
      url: '/api/client/accounts/register-account',
      query: { operationId },
    }),
    'POST'
  );
}
