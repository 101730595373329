import cn from 'classnames';
import React, { FC } from 'react';
// eslint-disable-next-line no-restricted-imports
import type { ButtonProps } from '@alfalab/core-components/button';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';

import styles from './ActionButtons.module.css';

export type ActionButtonsProps = {
  /**
   * Список кнопок
   * */
  buttons: ButtonProps[];
};

export const ActionButtons: FC<ActionButtonsProps> = (props) => {
  const { buttons } = props;

  return (
    <div className={styles.root}>
      {buttons.map(({ className, ...buttonProps }, index) => (
        <ButtonDesktop
          key={index}
          size="xs"
          view="ghost"
          className={cn(className, styles.button)}
          {...buttonProps}
        />
      ))}
    </div>
  );
};
