import { useMutation, useQueryClient } from 'react-query';

import { addBankCard } from '@terminal/core/lib/rest/lkBankCard';

import { LkQueryKeys } from '../../queryKeys';

export const useCardAdd = () => {
  const client = useQueryClient();

  const mutation = useMutation(addBankCard, {
    onSettled: () => {
      client.invalidateQueries([LkQueryKeys.CardList]);
    },
  });

  return mutation;
};
