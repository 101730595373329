import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';

import { FormatedText } from '../../../../shared';

import { useCorporateAction } from '../../hooks';

import styles from './MessageDetail.module.css';

interface MessageDetailProps {
  id: string;
}

export const MessageDetail = ({ id }: MessageDetailProps) => {
  const { data: corporateAction, isFetching: corporateActionPending } =
    useCorporateAction(id);

  if (corporateActionPending) {
    return <Loader />;
  }

  if (!corporateAction) {
    return null;
  }

  return (
    <div className={styles.container}>
      {corporateAction.parameters && (
        <div className={styles.details}>
          <Typography.Title
            view="xsmall"
            color="primary"
            className={styles.title}
            tag="div"
          >
            Информация о ценной бумаге
          </Typography.Title>
          <div className={styles.parameters}>
            {corporateAction.parameters.map((param) => (
              <div className={styles.field} key={`detail-param-${param.text}`}>
                <Typography.Text view="secondary-large" color="secondary">
                  {param.text}
                </Typography.Text>
                <Typography.Text view="secondary-large">
                  {param.value}
                </Typography.Text>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.content}>
        <Typography.Title
          view="xsmall"
          color="primary"
          className={styles.title}
          tag="div"
        >
          {corporateAction.title}
        </Typography.Title>
        <FormatedText text={corporateAction.content} />
      </div>
    </div>
  );
};
