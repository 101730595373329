import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { mapThemeCheckResult } from '../lib/mapThemeCheckResult';

import { ThemeCheckAnswerParams } from './types/ThemeCheckAnswerParams';
import { ThemeCheckResultDTO } from './types/ThemeCheckResultDTO';

export async function checkInstructionThemeAnswers(
  data: ThemeCheckAnswerParams
) {
  const res = await fetchLkResult<ThemeCheckResultDTO | LKResult>(
    `/api/instructions/check-answers`,
    'POST',
    data
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return mapThemeCheckResult(res);
}
