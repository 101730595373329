import { useMemo, useState } from 'react';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Loader } from '@alfalab/core-components/loader';
import { Select, SelectProps } from '@alfalab/core-components/select';
import { Field } from '@alfalab/core-components/select/components/field';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { MINORITY } from '@terminal/core/constants/ui';
import { Currency } from '@terminal/core/lib/rest/lkSecurities';

import styles from './SecurityControlSection.module.css';

interface SecurityControlSectionProps {
  dealSummRequired: boolean;
  dealSummShow: boolean;
  currencyList?: Currency[];
  currencyListPending: boolean;
  dealSumm: number | null;
  onDealSummChange: (summ: number | null) => void;
  dealCurrency: string;
  onDealCurrencyChange: (currency: string) => void;
}

export const SecurityControlSection = ({
  dealSummRequired,
  dealSummShow,
  currencyList,
  currencyListPending,
  dealSumm,
  onDealSummChange,
  dealCurrency,
  onDealCurrencyChange,
}: SecurityControlSectionProps) => {
  const [dealSummTouch, setDealSummTouch] = useState(false);

  const handleDealCurrencyChange: SelectProps['onChange'] = ({ selected }) => {
    onDealCurrencyChange(selected?.key || '');
  };

  const currencyOptions = useMemo(() => {
    if (currencyList) {
      return currencyList.map((curr) => ({
        key: curr.code,
        content: curr.name,
      }));
    }

    return [];
  }, [currencyList]);

  if (!dealSummShow) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Typography.Text
        view="secondary-large"
        color="primary"
        weight="bold"
        tag="p"
      >
        Контрольная информация
      </Typography.Text>
      <Space fullWidth direction="vertical">
        <Row>
          <Col width={6}>
            <AmountInput
              label="Сумма сделки"
              labelView="outer"
              block
              autoComplete="off"
              size="s"
              name="dealSumm"
              value={dealSumm}
              suffix=""
              minority={MINORITY}
              placeholder="0"
              onChange={(_, payload) => onDealSummChange(payload.value)}
              onBlur={() => setDealSummTouch(true)}
              error={
                (!dealSumm || dealSumm <= 0) &&
                Boolean(dealSummTouch) &&
                dealSummRequired
              }
            />
          </Col>
          <Col width={6}>
            <Select
              label="Валюта сделки"
              labelView="outer"
              size="s"
              block
              options={currencyOptions}
              selected={dealCurrency}
              onChange={handleDealCurrencyChange}
              Field={(props: FieldProps) => (
                <Field
                  {...props}
                  leftAddons={currencyListPending && <Loader />}
                />
              )}
            />
          </Col>
        </Row>
      </Space>
    </div>
  );
};
