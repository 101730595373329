import { useEffect, useMemo, useState } from 'react';

import { OrderType, QuantityType } from '../../lib/client/entities';
import { plural } from '../../lib/plural';

import { usePrevious } from '../../hooks/usePrevious';

import {
  AdditionalInstruction,
  AdditionalInstructions,
  CurrentAdditionalInstructions,
} from '../../types/additionalInstruction';

interface AdditionalInstructionAvailability {
  orderTypes: OrderType[];
  inChart: boolean;
}

export const availableAdditionalInstructions: Record<
  AdditionalInstruction,
  AdditionalInstructionAvailability
> = {
  [AdditionalInstruction.SLTP]: {
    orderTypes: [OrderType.MKT, OrderType.LMT, OrderType.STP, OrderType.STL],
    inChart: true,
  },
  [AdditionalInstruction.ICEBERG]: {
    orderTypes: [OrderType.LMT],
    inChart: true,
  },
  [AdditionalInstruction.LINKEDORDER]: {
    orderTypes: [OrderType.MKT, OrderType.LMT, OrderType.STP, OrderType.BRS],
    inChart: false,
  },
  [AdditionalInstruction.CONDITION]: {
    orderTypes: [OrderType.MKT, OrderType.LMT],
    inChart: false,
  },
};

interface AdditionalInstructionProps {
  idFi: number;
  side: 'buy' | 'sell';
  orderType: OrderType;
  isChart: boolean;
  isLotCount: boolean;
  lot?: number;
}

export const useAdditionalInstruction = ({
  idFi,
  side,
  orderType,
  isChart,
  isLotCount,
  lot = 1,
}: AdditionalInstructionProps) => {
  const [currentAdditionalInstructions, setCurrentAdditionalInstructions] =
    useState<CurrentAdditionalInstructions>(null);

  const [quantityType, setQuantityType] = useState<QuantityType>(
    QuantityType.QTY
  );

  const prevOrderType = usePrevious(orderType);

  // Саша Чаркин: Как только мы меняем направление (покупка/продажа), тип заявки в основной форме или инструмент, любая доп инструкция должна сбрасываться
  useEffect(() => {
    setCurrentAdditionalInstructions(null);
  }, [idFi]);

  useEffect(() => {
    if (!isChart) {
      setCurrentAdditionalInstructions(null);
    }
  }, [side, isChart]);

  useEffect(() => {
    if (
      (prevOrderType !== orderType && !isChart) ||
      (isChart && orderType === OrderType.MKT)
    ) {
      setCurrentAdditionalInstructions(null);
    }
  }, [prevOrderType, orderType, isChart]);

  useEffect(() => {
    if (currentAdditionalInstructions?.[AdditionalInstruction.ICEBERG]) {
      setQuantityType(QuantityType.ICE);
    } else {
      setQuantityType(QuantityType.QTY);
    }
  }, [currentAdditionalInstructions]);

  const prevLotCount = usePrevious(isLotCount);

  useEffect(() => {
    if (prevLotCount === isLotCount || !lot) {
      return;
    }

    setCurrentAdditionalInstructions((currentAdditionalInstructions) => {
      if (
        !currentAdditionalInstructions?.[AdditionalInstruction.ICEBERG]?.data
      ) {
        return currentAdditionalInstructions;
      }

      const newQuantity =
        currentAdditionalInstructions[AdditionalInstruction.ICEBERG].data
          .visibleQuantity * (isLotCount ? 1 / lot : lot);

      return {
        ...currentAdditionalInstructions,
        [AdditionalInstruction.ICEBERG]: {
          name: AdditionalInstruction.ICEBERG,
          data: { visibleQuantity: newQuantity },
          buttonContent: `по ${newQuantity} ${
            isLotCount ? plural(['лоту', 'лота', 'лотов'], newQuantity) : 'шт'
          }`,
        },
      };
    });
  }, [prevLotCount, isLotCount, lot]);

  const instructionsAvailability: AdditionalInstructions = useMemo(
    () =>
      Object.entries(availableAdditionalInstructions)
        .filter(([_, { inChart }]) => !isChart || inChart)
        .map(([name, { orderTypes }]) => ({
          name: name as AdditionalInstruction,
          available: orderTypes.includes(orderType),
        })),
    [orderType, isChart]
  );

  return {
    instructionsAvailability,
    currentAdditionalInstructions,
    setCurrentAdditionalInstructions,
    quantityType,
  };
};
