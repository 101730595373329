import { ClientCertificateEntity } from '../lib/client/entities';
import { parseCertificate } from '../lib/crypto/csr';
import { visitorIdKey } from '../lib/services/constants';

import { Certificate } from '../types/certificate';

export const certificateMap = (
  entity: ClientCertificateEntity
): Certificate => {
  const cert = parseCertificate(entity.Certificate);
  const owner = cert?.subject?.typesAndValues?.find(
    (item: { type: string }) => item.type === '2.5.4.3'
  )?.value?.valueBlock?.value;

  let alias = entity.Alias;
  let visitorId;

  // Если есть visitorIdKey - вытаскиваем его из Alias
  const visitorIdIdxStart = entity.Alias?.indexOf(visitorIdKey);
  if (visitorIdIdxStart !== -1) {
    alias = entity.Alias?.substring(0, visitorIdIdxStart)?.trim();
    visitorId = entity.Alias?.substring(
      visitorIdIdxStart + visitorIdKey.length
    );
  }

  return {
    idLogin: entity.IdLogin,
    idCertificate: Number(entity.IdCertificate),
    state: entity.State,
    type: entity.Type,
    alias: alias,
    description: entity.Description,
    certificate: entity.Certificate,
    idDevice: entity.IdDevice,
    parsedPayload: {
      startDate: cert?.notBefore?.value,
      endDate: cert?.notAfter?.value,
      owner,
      visitorId,
    },
  };
};
