import React, { useMemo, useState } from 'react';
import {
  Tab,
  TabsDesktop,
  TabsDesktopProps,
} from '@alfalab/core-components/tabs/desktop';
import { ThumbUpMIcon } from '@alfalab/icons-glyph/ThumbUpMIcon';

import { TradingCertificateLock } from '@terminal/common/features-DEPRECATED/TradingCertificateLock';

import { Result } from '../../shared/ui/Result';
import {
  DraftCompleteResult,
  DraftCompleteScreen,
} from './components/DraftsList';
import { TransferResult } from './components/TransferResult';
import { SecuritiesDrafts } from './tabs/SecuritiesDrafts';
import { SecuritiesHistory } from './tabs/SecuritiesHistory';
import { SecuritiesIn } from './tabs/SecuritiesIn';
import { SecuritiesOut } from './tabs/SecuritiesOut';
import { SecuritiesXml } from './tabs/SecuritiesXml';

import { useInit } from '../hooks';

import { TransferCompleteParams } from './types';

enum Subpage {
  Drafts = 'drafts',
  In = 'in',
  Out = 'out',
  Xml = 'xml',
  History = 'history',
}

export const Securities = React.memo(() => {
  const [subpage, setSubpage] = useState<Subpage>(Subpage.In);
  const [success, setSuccess] = useState<TransferCompleteParams | null>(null);
  const [draftComplete, setDraftComplete] =
    useState<DraftCompleteResult | null>(null);

  const { data: init } = useInit();

  const accessToXmlDepoUpload = useMemo(() => {
    return init && init.accessToXmlDepoUpload;
  }, [init]);

  const handleChange: TabsDesktopProps['onChange'] = (_, { selectedId }) =>
    setSubpage(selectedId as Subpage);

  const handleSuccess = (data: TransferCompleteParams) => {
    setSuccess(data);
  };

  if (success) {
    let title = 'Поручение успешно подано';

    if (success.status === 'separated') {
      if (success.multiple) {
        title =
          'Ценные бумаги будут зачислены и обособлены пока вы не подтвердите их приобретение у резидентов или нерезидентов из дружественных стран';
      } else {
        title = 'Ценные бумаги будут зачислены на обособленный счёт';
      }
    }

    return (
      <Result title={title} icon={<ThumbUpMIcon />}>
        <TransferResult
          onAction={() => setSuccess(null)}
          contragentName={success.counteragent}
          orderStatus={success.status}
          multiple={success.multiple}
        />
      </Result>
    );
  }

  if (draftComplete) {
    return (
      <DraftCompleteScreen
        draftComplete={draftComplete}
        onAction={() => setDraftComplete(null)}
      />
    );
  }

  return (
    <TabsDesktop
      selectedId={subpage}
      onChange={handleChange}
      scrollable={true}
      size="s"
    >
      <Tab title="На подпись" id={Subpage.Drafts}>
        <TradingCertificateLock>
          <SecuritiesDrafts onComplete={(res) => setDraftComplete(res)} />
        </TradingCertificateLock>
      </Tab>
      <Tab title="Зачисление" id={Subpage.In}>
        <TradingCertificateLock>
          <SecuritiesIn onSuccess={handleSuccess} />
        </TradingCertificateLock>
      </Tab>
      <Tab title="Списание" id={Subpage.Out}>
        <TradingCertificateLock>
          <SecuritiesOut onSuccess={handleSuccess} />
        </TradingCertificateLock>
      </Tab>
      <Tab
        title="Поручение XML"
        id={Subpage.Xml}
        hidden={!accessToXmlDepoUpload}
      >
        <TradingCertificateLock>
          <SecuritiesXml onSuccess={handleSuccess} />
        </TradingCertificateLock>
      </Tab>
      <Tab title="История" id={Subpage.History}>
        <SecuritiesHistory />
      </Tab>
    </TabsDesktop>
  );
});
