import { FC, PropsWithChildren } from 'react';

import { AppContext, context } from '@terminal/core/hooks/useAppContext';

interface Props {
  config: AppContext;
}

/**
 * Контекст приложения
 * */
export const AppProvider: FC<PropsWithChildren<Props>> = ({
  children,
  config,
}) => <context.Provider value={config}>{children}</context.Provider>;
