import { useState } from 'react';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';

import { WorkspaceConfigurationAdd } from '../WorkspaceConfigurationAdd';
import { WorkspaceConfigurationExportDoc } from '../WorkspaceConfigurationExportDoc';
import { WorkspaceConfigurationList } from '../WorkspaceConfigurationList';

import { WorkspaceConfigurationScreen } from '../../model/types';

export const WorkspaceConfiguration = () => {
  const [screen, setScreen] = useState<WorkspaceConfigurationScreen>(
    WorkspaceConfigurationScreen.List
  );

  return (
    <>
      <WorkspaceConfigurationList setScreen={setScreen} />
      <Portal id="common-settings-modals">
        <FunctionalModalSidebar
          open={screen === WorkspaceConfigurationScreen.Add}
          onClose={() => setScreen(WorkspaceConfigurationScreen.List)}
          backLabel="Конфигурации"
        >
          <WorkspaceConfigurationAdd setScreen={setScreen} />
        </FunctionalModalSidebar>

        <FunctionalModalSidebar
          open={screen === WorkspaceConfigurationScreen.Export}
          onClose={() => setScreen(WorkspaceConfigurationScreen.List)}
          backLabel="Конфигурации"
        >
          <WorkspaceConfigurationExportDoc />
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
