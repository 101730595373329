import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowRightSquareMIcon } from '@alfalab/icons-glyph/ArrowRightSquareMIcon';
import { ArrowsRightLeftCurvedMIcon } from '@alfalab/icons-glyph/ArrowsRightLeftCurvedMIcon';
import { BubbleLinesMIcon } from '@alfalab/icons-glyph/BubbleLinesMIcon';
import { ListBulletedMIcon } from '@alfalab/icons-glyph/ListBulletedMIcon';

import { isThemePassed } from '../../lib/isThemePassed';
import { ResultModal } from '../ResultModal';

import styles from './TestResultModal.module.css';

interface TestResultModalProps {
  percent: number;
  open: boolean;
  modalTitle?: string;
  onClose: () => void;
  onNext?: () => void;
  onRetry?: () => void;
  onList?: () => void;
  onRecomendation?: () => void;
  successText?: React.ReactNode;
  errorText?: React.ReactNode;
  nextAvailable?: boolean;
}

export const TestResultModal = ({
  percent,
  open,
  modalTitle,
  onClose,
  onNext,
  onRetry,
  onList,
  onRecomendation,
  successText,
  errorText,
  nextAvailable = false,
}: TestResultModalProps) => {
  const resultType = isThemePassed(percent) ? 'success' : 'error';

  const toListBtn = (
    <ButtonDesktop
      block
      size="s"
      view="tertiary"
      leftAddons={<ListBulletedMIcon className={styles.glyph} />}
      onClick={onList}
    >
      К списку тем
    </ButtonDesktop>
  );

  const retryBtn = (
    <ButtonDesktop
      block
      size="s"
      view={resultType === 'success' ? 'tertiary' : 'secondary'}
      leftAddons={<ArrowsRightLeftCurvedMIcon className={styles.glyph} />}
      onClick={onRetry}
    >
      Попробовать ещё раз
    </ButtonDesktop>
  );

  return (
    <ResultModal
      title={`${percent}% правильных ответов`}
      type={resultType || 'error'}
      modalTitle={modalTitle}
      open={open}
      onClose={onClose}
      text={
        resultType === 'success'
          ? successText && (
              <Typography.Text view="secondary-small" color="secondary">
                {successText}
              </Typography.Text>
            )
          : errorText && (
              <Typography.Text view="secondary-small" color="secondary">
                {errorText}
              </Typography.Text>
            )
      }
    >
      <div className={styles.buttons}>
        {resultType === 'success' && (
          <>
            {nextAvailable && (
              <ButtonDesktop
                block
                size="s"
                view="secondary"
                leftAddons={<ArrowRightSquareMIcon className={styles.glyph} />}
                onClick={onNext}
              >
                К следующей теме
              </ButtonDesktop>
            )}
            {!nextAvailable && toListBtn}
            {onRecomendation && percent < 100 && (
              <ButtonDesktop
                block
                size="s"
                view="tertiary"
                leftAddons={<BubbleLinesMIcon className={styles.glyph} />}
                onClick={onRecomendation}
              >
                Рекомендации
              </ButtonDesktop>
            )}
            {!onRecomendation && onRetry && percent < 100 && retryBtn}
          </>
        )}

        {resultType === 'error' && (
          <>
            {retryBtn}
            {toListBtn}
          </>
        )}
      </div>
    </ResultModal>
  );
};
