import cn from 'classnames';
import { FC } from 'react';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { Typography } from '@alfalab/core-components/typography';

import { ServiceAttentionIcon } from '../ServiceAttentionIcon';

import styles from './ServiceMessage.module.css';

interface ServiceLineBannerProps {
  message: string;
  className?: string;
  shortText?: string;
}

export const ServiceMessage: FC<ServiceLineBannerProps> = ({
  message,
  className,
  shortText,
}) => {
  return (
    <GenericWrapper className={cn(styles.body, className)}>
      <div className={styles.icon}>
        <ServiceAttentionIcon />
      </div>
      <div className={styles.message}>
        <Typography.Text
          view="secondary-large"
          color="primary"
          weight="medium"
          tag="p"
          defaultMargins={false}
          className={styles.title}
        >
          {shortText}
        </Typography.Text>
        <Typography.Text view="secondary-small" color="secondary">
          {message}
        </Typography.Text>
      </div>
    </GenericWrapper>
  );
};
