import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { NewAccountConstraints } from './types/NewAccountConstraints';

export const getAddAccountsConstraints = async () => {
  const res = await fetchLkResult<NewAccountConstraints | LKResult>(
    `/api/client/accounts/can-open-new-account`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};
