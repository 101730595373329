import { Dispatch, memo, useCallback, useRef, useState } from 'react';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronForwardMIcon } from '@alfalab/icons-glyph/ChevronForwardMIcon';
import { DotsHorizontalMIcon } from '@alfalab/icons-glyph/DotsHorizontalMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { OrderType } from '@terminal/core/lib/client/entities';

import { useWidgetContext } from '../../../../shared';

import { LimitPriceAdditionalType } from '../../model/types';

import styles from './AdditionalPriceSettings.module.css';

interface IProps {
  isChart: boolean;
  side?: 'buy' | 'sell';
  orderType: OrderType;
  priceAdditionalType: LimitPriceAdditionalType;
  setPriceAdditionalType: Dispatch<LimitPriceAdditionalType>;
  priceAdditionalAsk?: number | null;
  setPriceAdditionalAsk?: Dispatch<number | null>;
  priceAdditionalBid?: number | null;
  setPriceAdditionalBid?: Dispatch<number | null>;
  priceAdditionalMkt: number | null;
  setPriceAdditionalMkt: Dispatch<number | null>;
}

export const AdditionalPriceSettings = memo(
  ({
    isChart,
    orderType,
    side,
    priceAdditionalType,
    setPriceAdditionalType,
    priceAdditionalAsk,
    setPriceAdditionalAsk,
    priceAdditionalBid,
    setPriceAdditionalBid,
    priceAdditionalMkt,
    setPriceAdditionalMkt,
  }: IProps) => {
    const [additionalPriceVisible, setAdditionalPriceVisible] =
      useState<boolean>(false);
    const [additionalAskMenuVisible, setAdditionalAskMenuVisible] =
      useState<boolean>(false);
    const [additionalBidMenuVisible, setAdditionalBidMenuVisible] =
      useState<boolean>(false);

    const additionalPriceRef = useRef<HTMLDivElement>(null);
    const additionalPriceDropdownRef = useRef<HTMLDivElement>(null);
    const additionalAskRef = useRef<HTMLDivElement>(null);
    const additionalBidRef = useRef<HTMLDivElement>(null);

    const { DropdownComponent } = useWidgetContext();

    useOnClickOutside(additionalPriceRef, (e) => {
      const anchor = additionalPriceRef.current;

      if (anchor && !anchor?.contains(e.target as Node)) {
        setAdditionalPriceVisible(false);
      }
    });

    const handleClearAllAdditionals = useCallback(() => {
      setPriceAdditionalType(null);

      if (orderType === OrderType.LMT) {
        setPriceAdditionalAsk && setPriceAdditionalAsk(null);
        setPriceAdditionalBid && setPriceAdditionalBid(null);
      } else {
        setPriceAdditionalMkt(null);
      }
    }, [
      orderType,
      setPriceAdditionalType,
      setPriceAdditionalAsk,
      setPriceAdditionalBid,
      setPriceAdditionalMkt,
    ]);

    return (
      <div ref={additionalPriceRef} className={styles.otherButton}>
        <DotsHorizontalMIcon
          width={12}
          height={12}
          color="var(--color-dark-graphic-secondary)"
          onClick={() => setAdditionalPriceVisible((prev) => !prev)}
        />
        {orderType === OrderType.LMT || isChart ? (
          <DropdownComponent
            ref={additionalPriceDropdownRef}
            anchorElement={additionalPriceRef.current}
            open={additionalPriceVisible}
            offset={[0, 12]}
            position="bottom-end"
            popperClassName={styles.popover}
          >
            <Dropdown.Item
              content="Last"
              onClick={() => {
                if (priceAdditionalType === 'last') {
                  handleClearAllAdditionals();
                } else {
                  setPriceAdditionalType('last');
                  setAdditionalPriceVisible(false);
                }
              }}
              rightIcon={
                priceAdditionalType === 'last' ? (
                  <CheckmarkMIcon height={18} width={18} />
                ) : undefined
              }
            />
            <Dropdown.Item
              content="Mid"
              onClick={() => {
                if (priceAdditionalType === 'mid') {
                  handleClearAllAdditionals();
                } else {
                  setPriceAdditionalType('mid');
                  setAdditionalPriceVisible(false);
                }
              }}
              rightIcon={
                priceAdditionalType === 'mid' ? (
                  <CheckmarkMIcon height={18} width={18} />
                ) : undefined
              }
            />
            <Dropdown.Item
              content="Ask"
              onClick={() => {
                if (priceAdditionalType === 'ask') {
                  handleClearAllAdditionals();
                } else {
                  setPriceAdditionalType('ask');
                  setAdditionalPriceVisible(false);
                }
              }}
              rightIcon={
                priceAdditionalType === 'ask' ? (
                  <CheckmarkMIcon height={18} width={18} />
                ) : undefined
              }
            />
            <Dropdown.Item
              content="Ask + %"
              rightIcon={
                <ChevronForwardMIcon
                  height={18}
                  width={18}
                  color="var(--color-dark-graphic-secondary)"
                />
              }
              ref={additionalAskRef}
              onMouseEnter={() => setAdditionalAskMenuVisible(true)}
              onMouseLeave={() => setAdditionalAskMenuVisible(false)}
              onClick={() => setAdditionalAskMenuVisible(false)}
            >
              <DropdownComponent
                anchorElement={additionalAskRef.current}
                open={additionalAskMenuVisible}
                position="right-start"
                offset={[0, 4]}
                popperClassName={styles.popover}
              >
                {[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map((ask) => (
                  <Dropdown.Item
                    key={ask}
                    rightIcon={
                      priceAdditionalType === 'ask+' &&
                      priceAdditionalAsk === ask ? (
                        <CheckmarkMIcon height={18} width={18} />
                      ) : undefined
                    }
                    content={
                      <div className={styles.dropDownItemContent}>
                        {ask.toFixed(1)} %
                      </div>
                    }
                    onClick={() => {
                      if (
                        priceAdditionalType === 'ask+' &&
                        priceAdditionalAsk === ask
                      ) {
                        handleClearAllAdditionals();
                      } else {
                        setPriceAdditionalType('ask+');
                        setPriceAdditionalAsk && setPriceAdditionalAsk(ask);
                        setAdditionalPriceVisible(false);
                      }
                    }}
                  />
                ))}
              </DropdownComponent>
            </Dropdown.Item>
            <Dropdown.Item
              content="Bid"
              onClick={() => {
                if (priceAdditionalType === 'bid') {
                  handleClearAllAdditionals();
                } else {
                  setPriceAdditionalType('bid');
                  setAdditionalPriceVisible(false);
                }
              }}
              rightIcon={
                priceAdditionalType === 'bid' ? (
                  <CheckmarkMIcon height={18} width={18} />
                ) : undefined
              }
            />
            <Dropdown.Item
              content="Bid - %"
              rightIcon={
                <ChevronForwardMIcon
                  height={18}
                  width={18}
                  color="var(--color-dark-graphic-secondary)"
                />
              }
              ref={additionalBidRef}
              onMouseEnter={() => setAdditionalBidMenuVisible(true)}
              onMouseLeave={() => setAdditionalBidMenuVisible(false)}
              onClick={() => setAdditionalBidMenuVisible(false)}
            >
              <DropdownComponent
                anchorElement={additionalBidRef.current}
                open={additionalBidMenuVisible}
                position="right-start"
                offset={[0, 4]}
                popperClassName={styles.popover}
              >
                {[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map((bid) => (
                  <Dropdown.Item
                    key={bid}
                    rightIcon={
                      priceAdditionalType === 'bid-' &&
                      priceAdditionalBid === bid ? (
                        <CheckmarkMIcon height={18} width={18} />
                      ) : undefined
                    }
                    content={
                      <div className={styles.dropDownItemContent}>
                        {bid.toFixed(1)} %
                      </div>
                    }
                    onClick={() => {
                      if (
                        priceAdditionalType === 'bid-' &&
                        priceAdditionalBid === bid
                      ) {
                        handleClearAllAdditionals();
                      } else {
                        setPriceAdditionalType('bid-');
                        setPriceAdditionalBid && setPriceAdditionalBid(bid);
                        setAdditionalPriceVisible(false);
                      }
                    }}
                  />
                ))}
              </DropdownComponent>
            </Dropdown.Item>
          </DropdownComponent>
        ) : (
          <DropdownComponent
            ref={additionalPriceDropdownRef}
            anchorElement={additionalPriceRef.current}
            open={additionalPriceVisible}
            offset={[0, 12]}
            position="bottom-end"
            popperClassName={styles.popover}
          >
            {[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map((mkt) => (
              <Dropdown.Item
                key={mkt}
                rightIcon={
                  priceAdditionalType === 'mkt' &&
                  Math.abs(priceAdditionalMkt || 0) === mkt ? (
                    <CheckmarkMIcon height={18} width={18} />
                  ) : undefined
                }
                content={
                  <div className={styles.dropDownItemContent}>
                    {side === 'buy' ? '+' : '-'} {mkt.toFixed(1)} %
                  </div>
                }
                onClick={() => {
                  if (
                    priceAdditionalType === 'mkt' &&
                    Math.abs(priceAdditionalMkt || 0) === mkt
                  ) {
                    handleClearAllAdditionals();
                  } else {
                    setPriceAdditionalType('mkt');
                    setPriceAdditionalMkt(side === 'buy' ? mkt : -mkt);
                    setAdditionalPriceVisible(false);
                  }
                }}
              />
            ))}
          </DropdownComponent>
        )}
      </div>
    );
  }
);
