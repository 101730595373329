import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { mapStrategyThemeInfo } from '../lib/mapStrategyThemeInfo';

import { StrategyThemeInfoDTO } from './types/StrategyThemeInfoDTO';
import { StrategyThemeInfoParams } from './types/StrategyThemeInfoParams';

export async function getStrategyThemeInfo(data: StrategyThemeInfoParams) {
  const res = await fetchLkResult<StrategyThemeInfoDTO | LKResult>(
    qs.stringifyUrl({
      url: '/api/education/theme',
      query: { ...data },
    })
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return mapStrategyThemeInfo(res);
}
