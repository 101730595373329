import React, { useRef, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Button } from '@alfalab/core-components/button';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Popover } from '@alfalab/core-components/popover';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import CrossMIcon from '@alfalab/icons-glyph/CrossMIcon';
import QuestionMIcon from '@alfalab/icons-glyph/QuestionMIcon';

import { useOnClickOutside } from '@terminal/core/hooks';
import {
  trackClickOnHelp,
  trackClickOnManual,
  trackOnOnboarding,
} from '@terminal/core/lib/analytics';

import { Onboarding } from '../../../../../legacy/onboarding';
import notebookIcon from './img/notebook.png';
import startIcon from './img/start.png';

import styles from './HelpButton.module.css';

interface Props {
  contestWelcomeBanner: React.ReactNode;
  isManualAllowed: boolean;
  hasPopover: boolean;
  educationDocumentLink?: string;
}

export const HelpButton = ({
  contestWelcomeBanner,
  isManualAllowed,
  hasPopover,
  educationDocumentLink,
}: Props) => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, (e) => {
    setPopoverOpen(false);
  });

  const onHelpButtonClick = () => {
    trackClickOnHelp();

    if (hasPopover) {
      setPopoverOpen((prev) => !prev);
    } else {
      setShowOnboarding(true);
    }
  };

  return (
    <>
      <TooltipDesktop
        position="bottom-start"
        contentClassName={styles.tooltipContent}
        popoverClassName={styles.tooltip}
        arrowClassName={styles.tooltipArrow}
        content={
          <Typography.Text view="secondary-small">Помощь</Typography.Text>
        }
      >
        <IconButton
          size="xs"
          view="secondary"
          icon={QuestionMIcon}
          onClick={onHelpButtonClick}
          ref={anchorRef}
        />
      </TooltipDesktop>
      {hasPopover && (
        <Popover
          open={isPopoverOpen}
          ref={dropdownRef}
          popperClassName={styles.popper}
          anchorElement={anchorRef.current}
          position="bottom-start"
          offset={[0, 6]}
        >
          <div className={styles.popoverChildrenWrapper}>
            <IconButton
              size="xs"
              view="secondary"
              icon={CrossMIcon}
              className={styles.closeButton}
              onClick={() => {
                setPopoverOpen((prev) => !prev);
              }}
            />
            <Typography.Text view="primary-medium" weight="bold">
              Помощь
            </Typography.Text>

            {isManualAllowed && educationDocumentLink && (
              <Button
                className={styles.guide}
                onClick={trackClickOnManual}
                target="_blank"
                href={educationDocumentLink}
              >
                <div className={styles.guideText}>
                  <Typography.Text weight="medium" view="secondary-large">
                    Руководство пользователя
                  </Typography.Text>
                  <Typography.Text color="secondary" view="secondary-small">
                    В формате PDF
                  </Typography.Text>
                </div>
                <img
                  className={styles.guideImage}
                  src={notebookIcon}
                  alt="Руководство пользователя"
                />
              </Button>
            )}
            <Button
              onClick={() => {
                trackOnOnboarding();
                setShowOnboarding(true);
                setPopoverOpen(false);
              }}
              className={styles.guide}
            >
              <div className={styles.guideText}>
                <Typography.Text weight="medium" view="secondary-large">
                  Начало работы
                </Typography.Text>
                <Typography.Text color="secondary" view="secondary-small">
                  Как настроить терминал
                </Typography.Text>
              </div>
              <img
                className={styles.guideImage}
                src={startIcon}
                alt="Начало работы"
              />
            </Button>
          </div>
        </Popover>
      )}
      <Onboarding
        showOnboarding={showOnboarding}
        setShowOnboarding={setShowOnboarding}
      />
      {!showOnboarding && contestWelcomeBanner}
    </>
  );
};
