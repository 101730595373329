import { add } from 'date-fns';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { ActivationTime } from '@terminal/core/constants/orderBook';

export const useActivationTime = () => {
  const { getServerTime } = useAlfaDirectContext();

  return (type: ActivationTime) => {
    switch (type) {
      case ActivationTime.NOW:
        return;
      case ActivationTime.HOUR:
        return add(getServerTime(), { hours: 1 });
      case ActivationTime.DAY:
        return add(getServerTime(), { days: 1 });
      case ActivationTime.WEEK:
        return add(getServerTime(), { weeks: 1 });

      default:
        return;
    }
  };
};
