interface FeatureFlags {
  /**
   * Включает агрегацию для volumeProfile
   */
  VOLUME_PROFILE_AGGREGATION: boolean;
  /**
   * Включает кеширование справочников
   */
  DICTIONARIES_CACHE_ENABLED: boolean;
  /**
   * Включает пункт меню тестирования в меню личного кабинета
   */
  PRO_CLIENT_TESTING_ENABLED: boolean;
  /**
   * Включает отладочный вывод трекинга аналитики в консоль
   */
  DEBUG_ANALYTICS: boolean;
  /**
   * Имитируем реконнекты при первом логине
   */
  RECONNECTS_LOGIN_ENABLED: boolean;
  /**
   * Включает синхронизацию
   */
  SYNCHRONIZATION_ENABLED: boolean;
  /**
   * Включает авторизацию по телефону
   */
  AUTH_BY_PHONE_ENABLED: boolean;
  /**
   * ADIRWEB-3129 включает американские индексы
   */
  AMERICAN_INDICIES_ENABLED: boolean;
  /**
   * ADIRWEB-3121 Включает отображение баннера миграции счетов на СР+ЕФР
   */
  SR_EFR_ACCOUNTS_MIGRATION_BANNER: boolean;
  /**
   * Включает веб-пуши в режиме прода и теста
   */
  WEB_PUSH?: boolean;
}

/**
 * @deprecated
 */
export const featureFlags: FeatureFlags = {
  VOLUME_PROFILE_AGGREGATION: true,
  DICTIONARIES_CACHE_ENABLED: false,
  PRO_CLIENT_TESTING_ENABLED: true,
  DEBUG_ANALYTICS: false,
  RECONNECTS_LOGIN_ENABLED: false,
  SYNCHRONIZATION_ENABLED: false,
  AUTH_BY_PHONE_ENABLED: true,
  AMERICAN_INDICIES_ENABLED: false,
  SR_EFR_ACCOUNTS_MIGRATION_BANNER: true,
  WEB_PUSH: true,
};
