import cn from 'classnames';
import React, {
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributes,
  ReactNode,
  RefAttributes,
} from 'react';
import { SetOptional } from 'type-fest';
import { Divider } from '@alfalab/core-components/divider';
import { Popover, PopoverProps } from '@alfalab/core-components/popover';
import { Typography } from '@alfalab/core-components/typography';

import styles from './Dropdown.module.css';

export interface DropdownProps
  extends SetOptional<PopoverProps, 'anchorElement'> {
  toggle?: React.ReactElement;
  header?: ReactNode;
  subHeader?: ReactNode;
  headerDivider?: boolean;
  view?: 'default' | 'select';
}

export interface CompoundedDropdown
  extends ForwardRefExoticComponent<
    DropdownProps & RefAttributes<HTMLDivElement>
  > {
  Item: CompoundedDropdownItem;
  Block: CompoundedDropdownBlock;
  Divider: CompoundedDropdownDivider;
}

/**
 * @deprecated использовать компоненты `DropdownMenu`, `Select`, `ContextMenu`
 * */
export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      popperClassName,
      children,
      toggle,
      header,
      subHeader,
      headerDivider = true,
      view = 'default',
      anchorElement,
      ...props
    },
    ref
  ) => {
    return (
      <>
        {toggle}
        <Popover
          popperClassName={cn(
            styles.popper,
            { [styles.popperViewSelect]: view === 'select' },
            popperClassName
          )}
          anchorElement={anchorElement || null}
          {...props}
          ref={ref}
        >
          {header ? (
            <div className={styles.header}>
              <Typography.Text
                view="secondary-large"
                weight="bold"
                color="secondary"
              >
                {header}
                {subHeader ? (
                  <>
                    &nbsp;
                    <Typography.Text
                      view="secondary-large"
                      weight="bold"
                      color="tertiary"
                    >
                      {subHeader}
                    </Typography.Text>
                  </>
                ) : null}
              </Typography.Text>
            </div>
          ) : null}
          {header && headerDivider ? <Divider /> : null}
          <div className={styles.popoverChildrenWrapper}>{children}</div>
        </Popover>
      </>
    );
  }
) as CompoundedDropdown;

export interface DropdownItemProps extends HTMLAttributes<HTMLDivElement> {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  content?: ReactNode;
  suffix?: ReactNode;
  align?: 'start' | 'center' | 'end';
  withDivider?: boolean;
  withGap?: number | boolean;
  header?: ReactNode;
  headerDivider?: boolean;
  textClassName?: string;
  renderContent?: (
    props: Pick<DropdownItemProps, 'className' | 'children'>
  ) => ReactNode;
  disabled?: boolean;
}

export type CompoundedDropdownItem = ForwardRefExoticComponent<
  DropdownItemProps & RefAttributes<HTMLDivElement>
>;

Dropdown.Item = forwardRef<HTMLDivElement, DropdownItemProps>((props, ref) => {
  const {
    leftIcon,
    rightIcon,
    content,
    suffix,
    onClick,
    className,
    align = 'center',
    withDivider,
    withGap,
    children,
    header,
    headerDivider,
    textClassName,
    disabled,
    renderContent,
    ...restProps
  } = props;
  const rootClassName = cn(styles.dropdownItem, styles[align], className, {
    [styles.disabled]: disabled,
  });
  const contentChildren = (
    <>
      {leftIcon ? leftIcon : null}
      {content ? (
        <Typography.Text view="secondary-large" className={textClassName}>
          {content}
          {suffix && (
            <Typography.Text view="secondary-large" color="secondary">
              &nbsp;&nbsp;{suffix}
            </Typography.Text>
          )}
        </Typography.Text>
      ) : null}
      {rightIcon ? rightIcon : null}
      {children}
    </>
  );
  const handleClick = disabled ? undefined : onClick;

  const renderDivider = () => {
    if (withDivider) {
      return <Divider className={styles.divider} />;
    }

    if (withGap) {
      return (
        <div style={{ height: typeof withGap === 'number' ? withGap : 8 }} />
      );
    }

    return null;
  };

  return (
    <>
      {header && (
        <>
          {header}
          {headerDivider && <Divider />}
        </>
      )}
      {renderContent && (
        <div onClick={handleClick}>
          {renderContent({
            className: rootClassName,
            children: contentChildren,
          })}
        </div>
      )}
      {!renderContent && (
        <div
          ref={ref}
          className={rootClassName}
          onClick={handleClick}
          {...restProps}
        >
          {contentChildren}
        </div>
      )}
      {renderDivider()}
    </>
  );
}) as CompoundedDropdownItem;

interface DropdownBlockProps {
  children: ReactNode;
}

export type CompoundedDropdownBlock = ForwardRefExoticComponent<
  DropdownBlockProps & RefAttributes<HTMLDivElement>
>;

Dropdown.Block = forwardRef<HTMLDivElement, DropdownBlockProps>(
  ({ children }: DropdownBlockProps, ref) => {
    return (
      <div className={styles.dropdownBlock} ref={ref}>
        {children}
      </div>
    );
  }
) as CompoundedDropdownBlock;

interface DropdownDividerProps {
  size?: 's' | 'm' | 'l';
}

type CompoundedDropdownDivider = FC<DropdownDividerProps>;

Dropdown.Divider = (({ size = 'm' }) => {
  return <Divider className={cn(styles.dropdownDivider, styles[size])} />;
}) as CompoundedDropdownDivider;
