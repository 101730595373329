import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  getMoneyLimit,
  TMoneyTransfeLimitType,
  TradeAccount,
} from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';

export interface MoneyLimitQueryParams {
  treaty?: number;
  accountFrom: TradeAccount | null;
  accountTo?: TradeAccount | null;
  type: TMoneyTransfeLimitType;
  amount: number;
}

export const useMoneyLimits = (params: MoneyLimitQueryParams) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.MoneyLimits, params],
    async () =>
      getMoneyLimit({
        treaty: params.treaty || 0,
        currency: params.accountFrom?.currency || '',
        accCodeFrom: params.accountFrom?.subAccount || '',
        placeCodeFrom: params.accountFrom?.placeCode || '',
        accCodeTo: params.accountTo?.subAccount,
        placeCodeTo: params.accountTo?.placeCode,
        type: params.type,
        amount: params.amount,
      }),
    {
      enabled:
        Boolean(params.accountFrom) &&
        Boolean(params.treaty) &&
        (params.accountTo === undefined || Boolean(params.accountTo)),
      placeholderData: {
        limit: 0,
        marginalLimit: 0,
        marginalOn: false,
        taxes: {
          taxSum: 0.0,
          taxRate: '13%',
          taxDetails: {
            taxAccrued: 0.0,
            taxWithheld: 0.0,
            taxDue: 0.0,
          },
          factWithdraw: 0,
        },
      },
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе лимита',
        });
      },
    }
  );
};
