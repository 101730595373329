import { useStore } from '@terminal/core/store';
import { objectByIdFiSelector } from '@terminal/core/store/selectors/core/objectByIdFiSelector';
import { trackPriceForEditSelector } from '@terminal/core/store/selectors/trackPrice/trackPriceForEditSelector';

export function useModalTitle(): string {
  const trackPrice = useStore(trackPriceForEditSelector);
  const objectByIdFi = useStore(objectByIdFiSelector);

  if (!trackPrice || trackPrice.IdTrackPrice === 0n) {
    return 'Новый алерт';
  }

  const { symbolObject = '' } = objectByIdFi(trackPrice.IdFI) || {};

  return `Настройка алерта ${symbolObject}`;
}
