import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { confirmProfile } from '@terminal/core/lib/rest/lkRiskProfile';
import { NotificationType } from '@terminal/core/types/ui';

export const useConfirmProfile = () => {
  const addNotification = useNotification();

  return useMutation(() => confirmProfile(), {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text:
          error?.message || 'При подтверждении риск-профиля произошла ошибка',
      });
    },
  });
};
