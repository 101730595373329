import { Amount } from '@alfalab/core-components/amount';
import { CurrencyCodes } from '@alfalab/core-components/amount/types';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';

import { getStepDecimals } from '@terminal/core/lib/format';
import {
  LifeTimeMap,
  OrderItem,
  OrderTypeMap,
} from '@terminal/core/types/order';

import { OrderListItemLabel } from '../OrderListItemLabel';

import styles from './OrderContent.module.css';

type OrderContentProps = {
  idFI: number;
  priceStep: number;
  currencyCode?: CurrencyCodes;
  order: OrderItem;
};

export const OrderContent = ({
  idFI,
  priceStep,
  currencyCode,
  order,
}: OrderContentProps) => {
  const { priceDecimals } = getStepDecimals(priceStep);

  const icon =
    order.buySell === -1 ? (
      <ArrowDownCompactXsIcon width={18} height={18} color="#d91d0b" />
    ) : (
      <ArrowUpCompactXsIcon width={18} height={18} color="#2fc26e" />
    );

  const orderType =
    OrderTypeMap.get(order.idOrderType) +
    ' ' +
    LifeTimeMap.get(order.idLifeTime);

  return (
    <div className={styles.wrapper} key={order.price}>
      <div className={styles.icon}>{icon}</div>
      <span>{order.quantity}</span>
      &nbsp;
      <OrderListItemLabel idFI={idFI} />
      &nbsp;
      <span className={styles.currency}>
        <Amount.Pure
          value={order.price * priceDecimals}
          currency={currencyCode}
          minority={priceDecimals}
        />
      </span>
      &nbsp;
      <div className={styles.subtitleRight}>
        <span>{orderType}</span>
        &nbsp;
        <span>{order.numEDocument}</span>
      </div>
    </div>
  );
};
