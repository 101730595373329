import cn from 'classnames';
import { Typography } from '@alfalab/core-components/typography';

import styles from './StrategyProperty.module.css';

interface StrategyPropertyProps {
  label: React.ReactNode;
  special?: boolean;
  view?: 'vertical' | 'horizontal';
  children?: React.ReactNode;
}

export const StrategyProperty = ({
  label,
  special = false,
  view = 'vertical',
  children,
}: StrategyPropertyProps) => {
  return (
    <div
      className={cn(styles.container, {
        [styles.horizontal]: view === 'horizontal',
      })}
    >
      <Typography.Text
        view="secondary-small"
        color="secondary"
        className={styles.title}
      >
        {label}
      </Typography.Text>
      <Typography.Text
        view="secondary-large"
        tag="div"
        weight="bold"
        className={cn(styles.content, { [styles.special]: special })}
      >
        {children}
      </Typography.Text>
    </div>
  );
};
