import cn from 'classnames';
import { useMemo } from 'react';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';

import {
  SecuritiesDocumentType,
  SecuritiesReasonDocument,
} from '@terminal/core/lib/rest/lkSecurities';

import styles from './DocumentItem.module.css';
import liststyles from './DocumentsList.module.css';

interface DocumentItemProps {
  document: SecuritiesReasonDocument;
  documentType?: SecuritiesDocumentType;
  onRedact: () => void;
  onDelete: () => void;
  className?: string;
}

export const DocumentItem = ({
  document,
  documentType,
  onRedact,
  onDelete,
  className,
}: DocumentItemProps) => {
  const docName = useMemo(() => {
    if (documentType) {
      return documentType.docType === 'BASE'
        ? documentType.name
        : document.docName;
    }
  }, [document, documentType]);

  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(styles.name, liststyles.cell, liststyles.cellName)}>
        {docName}
      </div>
      <div
        className={cn(styles.actions, liststyles.cell, liststyles.cellAction)}
      >
        <button className={styles.button} onClick={onRedact}>
          <PencilMIcon />
        </button>
        <button
          className={cn(styles.button, styles.buttonDelete)}
          onClick={onDelete}
        >
          <CrossCircleMIcon />
        </button>
      </div>
    </div>
  );
};
