import { RootStore } from '../../useStore';
import { trackPricesSelector } from './trackPricesSelector';

import { TrackPriceState } from '../../../types/trackPrice';

/**
 * Выбирает состояние отображения модального окна «Редактирование алерта»
 * */
export function isEditTrackPriceModalOpenSelector(store: RootStore): boolean {
  const trackPrices = trackPricesSelector(store);
  const { trackPriceForEdit, isTrackPriceEditModalOpen } = store;

  if (!trackPriceForEdit || !isTrackPriceEditModalOpen) {
    return false;
  }

  // Окно с формой создания
  if (trackPriceForEdit.IdTrackPrice === 0n) {
    return !!trackPriceForEdit;
  }

  // Окно с формой редактирования
  return trackPrices.some(
    ({ IdTrackPrice, IdState }) =>
      IdTrackPrice === trackPriceForEdit.IdTrackPrice &&
      IdState === TrackPriceState.Active
  );
}
