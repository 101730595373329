import { useField } from 'formik';
import {
  MaskedInput,
  MaskedInputProps,
} from 'formik-alfalab-core-components/MaskedInput';

import { formatTextBeforeDisplay, formatTextOnBlur } from '@terminal/lk-core';

export const PersonalDataInput = (props: MaskedInputProps) => {
  const { name, ...rest } = props;

  const [field, , helpers] = useField(name);

  const handleBlur: MaskedInputProps['onBlur'] = (e) => {
    const formattedText = formatTextOnBlur(field.value);

    helpers.setValue(formattedText);

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  return (
    <MaskedInput
      name={name}
      {...rest}
      onBlur={handleBlur}
      mask={(rawValue) => rawValue.split('').map(() => /./)}
      onBeforeDisplay={formatTextBeforeDisplay}
    />
  );
};
