import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { ValidateResult } from '../../../../shared/hooks/useValidation';

export function validateBank(value: string): ValidateResult {
  if (!value.length) {
    return { code: 1, message: 'Введите банк получателя' };
  }

  return { code: 0, message: '' };
}

export function validateBankSwift(value: string): ValidateResult {
  if (!value.length) {
    return { code: 1, message: 'Введите SWIFT / Код Банка получателя' };
  }

  return { code: 0, message: '' };
}

export function validateBeneficiary(value: string): ValidateResult {
  if (!value.length) {
    return { code: 1, message: 'Введите получателя' };
  }

  return { code: 0, message: '' };
}

export const validateBeneficiaryAccount = (treaty?: Treaty) => {
  return (value: string): ValidateResult => {
    if (!value.length) {
      return { code: 1, message: 'Введите счёт получателя' };
    } else if (treaty && !treaty.isFirm) {
      if (treaty.isCurrResident) {
        if (!value.startsWith('40817') && !value.startsWith('42301')) {
          return {
            code: 2,
            message: 'Вывод средств возможен только на собственный счёт',
          };
        }
      } else {
        if (!value.startsWith('40820') && !value.startsWith('42601')) {
          return {
            code: 2,
            message: 'Вывод средств возможен только на собственный счёт',
          };
        }
      }
    } else if (!/^[0-9]{20}$/.test(value)) {
      return { code: 3, message: '20 цифр' };
    }

    return { code: 0, message: '' };
  };
};

export function validateBeneficiaryAddress(value: string): ValidateResult {
  if (!value.length) {
    return { code: 1, message: 'Введите адрес получателя' };
  } else if (!/^[0-9a-zA-Z\s]+$/.test(value)) {
    return { code: 1, message: 'Только латинские буквы и цифры' };
  }

  return { code: 0, message: '' };
}

export function validateCorrBank(value: string): ValidateResult {
  if (!value.length) {
    return { code: 1, message: 'Введите банк посредника' };
  }

  return { code: 0, message: '' };
}

export function validateCorrBankSwift(value: string): ValidateResult {
  if (!value.length) {
    return { code: 1, message: 'Введите SWIFT / код банка посредника' };
  }

  return { code: 0, message: '' };
}

export function validateCorrAccount(value: string): ValidateResult {
  if (!value.length) {
    return { code: 1, message: 'Введите cчёт посредника' };
  }

  return { code: 0, message: '' };
}

export function validatePaymentPurpose(value: string): ValidateResult {
  if (!value.length) {
    return { code: 1, message: 'Введите назначение платежа' };
  }

  return { code: 0, message: '' };
}
