import { isBefore, isValid, parse } from 'date-fns';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';

/**
 * Устанавливает значение параметра фильтра
 * @param filter - объект фильтра
 * @param key - ключ свойства
 * @param value - значение свойства
 * @param accumulate - обработка строки как массива, для буквенных фильтров
 * @param resettableKeys - список ключей, для которых разрешён сброс значения
 * @returns новый объект фильтра
 */
export function setFilterProp<TFilter extends { [key: string]: any }>(
  filter: TFilter,
  key: keyof TFilter,
  value: string | number | boolean | undefined,
  accumulate: boolean = false,
  resettableKeys: (keyof TFilter)[] = []
): TFilter {
  const prop = filter[key];

  if (Array.isArray(prop)) {
    const newProp = [...prop];

    const index = newProp.indexOf(value);

    if (index >= 0) {
      newProp.splice(index, 1);
    } else {
      newProp.push(value);
    }

    return {
      ...filter,
      [key]: newProp,
    };
  } else if (accumulate && typeof filter[key] === 'string') {
    const arrProp = filter[key].split('');
    const index = arrProp.indexOf(value);

    if (index >= 0) {
      arrProp.splice(index, 1);
    } else {
      arrProp.push(value);
    }

    return {
      ...filter,
      [key]: arrProp.join(''),
    };
  } else {
    if (resettableKeys.includes(key) && prop === value) {
      const newFilter = { ...filter };

      delete newFilter[key];

      return newFilter;
    } else {
      return {
        ...filter,
        [key]: value,
      };
    }
  }
}

export const isDateValid = (date: string = ''): boolean => {
  return isValid(parse(date, DEFAULT_VIEW_DATE_FORMAT, new Date()));
};

export const areDatesOverlapping = (
  startDate: string = '',
  endDate: string = ''
): boolean => {
  const parsedStartDate = parse(
    startDate,
    DEFAULT_VIEW_DATE_FORMAT,
    new Date()
  );
  const parsedEndDate = parse(endDate, DEFAULT_VIEW_DATE_FORMAT, new Date());

  return isBefore(parsedEndDate, parsedStartDate);
};
