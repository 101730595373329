import cn from 'classnames';
import { Duration, format, sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { CalendarInput } from '@alfalab/core-components/calendar-input';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import { NotificationsFilter } from '@terminal/core/lib/rest/lkNotifications';

import {
  FilterButton,
  isDateValid,
  setFilterProp,
} from '../../../components/Filter';
import { isFilterValid } from './helpers';

import formstyles from '../../../styles/forms.module.css';
import styles from './NotificationsFilterFull.module.css';

export interface NotificationsFilterFullProps {
  filter: NotificationsFilter;
  onSetFilter: (filter: NotificationsFilter) => void;
  onResetFilter: () => void;
  onClose: () => void;
}

export const NotificationsFilterFull = ({
  filter,
  onSetFilter,
  onResetFilter,
  onClose,
}: NotificationsFilterFullProps) => {
  const [localFilter, setLocalFilter] = useState<NotificationsFilter>(filter);

  useEffect(() => {
    setLocalFilter(filter);
  }, [filter]);

  const setPeriod = (period: Duration) => {
    const endDate = new Date();
    const startDate = sub(endDate, period);
    setLocalFilter((prevFilter) => ({
      ...prevFilter,
      startDate: format(startDate, DEFAULT_VIEW_DATE_FORMAT),
      endDate: format(endDate, DEFAULT_VIEW_DATE_FORMAT),
    }));
  };

  const isPeriod = (period: Duration) => {
    const endDate = new Date();
    const startDate = sub(endDate, period);
    return (
      format(startDate, DEFAULT_VIEW_DATE_FORMAT) === localFilter.startDate &&
      format(endDate, DEFAULT_VIEW_DATE_FORMAT) === localFilter.endDate
    );
  };

  const setProp = (
    key: keyof NotificationsFilter,
    value: string | number | boolean
  ) => {
    const newFilter = setFilterProp(localFilter, key, value);
    setLocalFilter(newFilter);
  };

  const applyFilter = () => {
    onSetFilter(localFilter);
    onClose();
  };

  const resetFilter = () => {
    onResetFilter();
    onClose();
  };

  return (
    <div className={styles.body} data-test-id="history-filter-body">
      <Typography.Title view="xsmall" className={styles.title} tag="div">
        Фильтры
      </Typography.Title>

      <div className={styles.section}>
        <Typography.Text
          view="secondary-large"
          className={cn(formstyles.subtitle, styles.subtitle)}
          tag="div"
        >
          Статус
        </Typography.Text>
        <div className={cn(styles.types, styles.values)}>
          <FilterButton
            checked={!!localFilter.unreadOnly}
            onClick={() => setProp('unreadOnly', !localFilter.unreadOnly)}
          >
            Непрочитанные
          </FilterButton>
        </div>
      </div>

      <div className={styles.section}>
        <Typography.Text
          view="secondary-large"
          className={cn(formstyles.subtitle, styles.subtitle)}
          tag="div"
        >
          Период
        </Typography.Text>
        <div className={cn(styles.types, styles.values)}>
          <FilterButton
            onClick={() => setPeriod({ weeks: 1 })}
            checked={isPeriod({ weeks: 1 })}
          >
            Неделя
          </FilterButton>
          <FilterButton
            onClick={() => setPeriod({ months: 1 })}
            checked={isPeriod({ months: 1 })}
          >
            Месяц
          </FilterButton>
        </div>
        <Row className={styles.fields} justify="left" gutter={16}>
          <Col width={5}>
            <Typography.Text
              view="secondary-small"
              className={formstyles.label}
            >
              Начало периода
            </Typography.Text>
            <CalendarInput
              size="s"
              block
              value={localFilter.startDate}
              className={formstyles.datePicker}
              inputClassName={formstyles.calendarInput}
              fieldClassName={formstyles.calendarInputField}
              addonsClassName={formstyles.calendarInputAddons}
              popoverClassName={formstyles.calendarPopover}
              onChange={(e, { value }) => setProp('startDate', value)}
              error={!isDateValid(localFilter.startDate || '')}
            />
          </Col>
          <Col width={5}>
            <Typography.Text
              view="secondary-small"
              className={formstyles.label}
            >
              Конец периода
            </Typography.Text>
            <CalendarInput
              size="s"
              block
              value={localFilter.endDate}
              className={formstyles.datePicker}
              inputClassName={formstyles.calendarInput}
              fieldClassName={formstyles.calendarInputField}
              addonsClassName={formstyles.calendarInputAddons}
              popoverClassName={formstyles.calendarPopover}
              onChange={(e, { value }) => setProp('endDate', value)}
              error={!isDateValid(localFilter.endDate || '')}
            />
          </Col>
        </Row>
      </div>

      <div className={cn(styles.submit, styles.wide)}>
        <Row className={styles.fields} justify="left" gutter={16}>
          <Col width={6}>
            <Button
              className={cn(formstyles.buttonSmall)}
              size="xxs"
              block
              view="secondary"
              leftAddons={<CheckmarkMIcon width={18} height={18} />}
              onClick={applyFilter}
              disabled={!isFilterValid(localFilter)}
            >
              Применить
            </Button>
          </Col>
          <Col width={6}>
            <Button
              className={cn(formstyles.buttonSmall)}
              size="xxs"
              block
              view="tertiary"
              leftAddons={<CrossMIcon width={18} height={18} />}
              onClick={resetFilter}
            >
              Сбросить
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
