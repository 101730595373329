import {
  PASSPORT_CLIENT_ID,
  PASSPORT_URI,
  USE_PASSPORT_CERBERUS_AUTH,
} from '@terminal/core/env';
import { getRefreshToken } from '@terminal/core/lib/rest/investApi';
import { doNoCORSFetch } from '@terminal/core/utils/dom/doNoCORSFetch';

export async function passportLogout() {
  if (USE_PASSPORT_CERBERUS_AUTH) {
    // т.к. это промис дадим возможность выполниться цепочке then а затем уже сделаем редирект
    setTimeout(() => {
      window.location.href = `/openid/logout?client_id=${PASSPORT_CLIENT_ID}`;
    }, 0);

    return;
  }

  return doNoCORSFetch({
    url: PASSPORT_URI.replace('/authorize', '/logout'),
    method: 'post',
    body: { refresh_token: getRefreshToken() },
  });
}
