import { SteppedProgressBar } from '@alfalab/core-components/stepped-progress-bar';
import { SteppedProgressBarView } from '@alfalab/core-components/stepped-progress-bar/Component';
import { Typography } from '@alfalab/core-components/typography';

import { TitleWithHint } from './TitleWithHint';

import classes from './FinanceProgressBar.module.css';

const getColorByStep: { [K in number]: SteppedProgressBarView } = {
  1: 'negative',
  2: 'negative',
  3: 'attention',
  4: 'attention',
  5: 'primary',
  6: 'primary',
  7: 'positive',
  8: 'positive',
  9: 'positive',
  10: 'positive',
};

const MAX_STEP_COUNT = 10;

export const FinanceProgressBar = ({ title, tooltip, step, description }) => {
  return (
    <div className={classes.progressBarContainer}>
      <div className={classes.progressBarHeader}>
        <TitleWithHint
          title={
            <Typography.TitleMobile
              view="xsmall"
              tag="div"
              weight="bold"
              color="secondary"
            >
              {title}
            </Typography.TitleMobile>
          }
          gap="var(--gap-s)"
          contentClassName={classes.tooltipContent}
          hintContent={tooltip}
          iconSize={15}
        />
        <Typography.TitleMobile
          view="xsmall"
          tag="div"
          weight="bold"
          color="secondary"
        >
          {`${step} из ${MAX_STEP_COUNT}`}
        </Typography.TitleMobile>
      </div>

      <SteppedProgressBar
        step={step}
        maxStep={MAX_STEP_COUNT}
        view={getColorByStep[step]}
        className={classes.progressBar}
      />
      <Typography.Text className={classes[`color_${step}_step`]}>
        {description}
      </Typography.Text>
    </div>
  );
};
