import { RootStore } from '../../useStore';

import { ObjectItem } from '../../../types/core';

/**
 * Выбирает ценную бумагу по идентификатору ценной бумаги
 * */
export function objectById(
  store: RootStore
): (idObject: ObjectItem['idObject']) => ObjectItem | undefined {
  return (idObject) => store.objectsTable.get('idObject', idObject);
}
