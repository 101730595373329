import { GetState } from 'zustand';

import { RootStore, StoreSet } from '../useStore';

import { FinInfoExtRecord } from '../../lib/services/finInfoExt/types';
import { FinInfoExt } from '../../types/quotes';

export interface FinInfoExtSlice {
  finInfoExt: FinInfoExtRecord;
  setFinInfoExt: (finInfoExts: FinInfoExt[]) => void;
}

export const createFinInfoExtSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): FinInfoExtSlice => ({
  finInfoExt: {},
  setFinInfoExt: (finInfoExts: FinInfoExt[]) => {
    const newFinInfoExts = {
      ...get().finInfoExt,
    };

    finInfoExts.forEach((item) => {
      const prevVersion = newFinInfoExts[item.idFI]?.version;

      // прилетел новый extFinInfo или получили обновления
      if (!prevVersion || !item.version || prevVersion < item.version) {
        // добавили в общий пул
        newFinInfoExts[item.idFI] = item;
      }
    });

    set((state) => {
      state.finInfoExt = newFinInfoExts;
    });
  },
});
