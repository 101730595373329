import { format, startOfMonth } from 'date-fns';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';
import { Loader } from '@alfalab/core-components/loader';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import {
  dateToRequestFormat,
  DEFAULT_VIEW_DATE_FORMAT,
} from '@terminal/core/lib/rest/lkApi';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import {
  CorporateActionHistoryHeader,
  CorporateActionsHistoryFilter,
  CorporateActionsHistoryHeadersRequest,
} from '@terminal/core/lib/rest/lkCorporateActions';

import { Result } from '../../../../shared/ui/Result';
import { HistoryDetail } from '../../components/HistoryDetail';
import { HistoryFilterLine } from '../../components/HistoryFilter';
import { HistoryFilter } from '../../components/HistoryFilter';
import { HistoryList } from '../../components/HistoryList';

import { useCorporateActionHistoryHeaders } from '../../hooks/';

import styles from './History.module.css';

interface HistoryProps {
  treaty: Treaty | null;
}

const PAGE_SIZE = 20;

export const defaultFilter: CorporateActionsHistoryFilter = {
  from: format(startOfMonth(new Date()), DEFAULT_VIEW_DATE_FORMAT),
  to: format(new Date(), DEFAULT_VIEW_DATE_FORMAT),
  search: '',
};

export const History = ({ treaty }: HistoryProps) => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] =
    useState<CorporateActionsHistoryFilter>(defaultFilter);
  const [openFilter, setOpenFilter] = useState(false);
  const [openOrder, setOpenOrder] = useState('');

  const historyParams = useMemo<CorporateActionsHistoryHeadersRequest>(() => {
    return {
      ...filter,
      from: dateToRequestFormat(filter.from),
      to: dateToRequestFormat(filter.to),
      treaty: treaty?.treaty || 0,
      page,
      count: PAGE_SIZE,
    };
  }, [filter, page, treaty]);

  const [debouncedHistoryParams] =
    useDebounce<CorporateActionsHistoryHeadersRequest>(historyParams, 250);

  const {
    data: history,
    isFetching: historyPending,
    isFetchingNextPage: historyPendingNext,
    hasNextPage: hasMoreHistory,
    fetchNextPage: fetchMoreHistory,
  } = useCorporateActionHistoryHeaders(debouncedHistoryParams);

  const historyItems = useMemo(() => {
    if (history) {
      return history.pages.flatMap((page) => page.headers);
    }
    return null;
  }, [history]);

  const [loaderRef, loaderInView] = useInView();
  useEffect(() => {
    if (loaderInView) {
      fetchMoreHistory();
    }
  }, [loaderInView, fetchMoreHistory]);

  // При изменении фильтра устанавливаем 1-ю страницу
  useEffect(() => {
    setPage(1);
  }, [filter]);

  const isFilterActive = useMemo<boolean>(() => {
    return !isEqual(filter, defaultFilter);
  }, [filter]);

  const onCloseFilter = () => {
    setOpenFilter(false);
  };

  const onItemOpen = (item: CorporateActionHistoryHeader) => {
    setOpenOrder(item.orderNumber);
  };

  const onItemClose = () => {
    setOpenOrder('');
  };

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <HistoryFilterLine
          filter={filter}
          active={isFilterActive}
          onFilterOpen={() => setOpenFilter(true)}
          onSetFilter={(filter) => setFilter(filter)}
        />
      </div>

      <div className={styles.results}>
        {!!historyItems && (
          <HistoryList history={historyItems} onItemOpen={onItemOpen} />
        )}

        {(historyPending || historyPendingNext) && (
          <Loader dataTestId="history-loader" />
        )}

        {!!historyItems &&
          historyItems.length === 0 &&
          !(historyPending || historyPendingNext) && (
            <Result
              title="Результатов нет :("
              text={<>По заданным параметрам ни&nbsp;чего не&nbsp;найдено</>}
              icon={<FilterMIcon />}
            />
          )}

        {hasMoreHistory && !(historyPending || historyPendingNext) && (
          <div ref={loaderRef}></div>
        )}
      </div>

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={openFilter}
          backLabel="История"
          onClose={onCloseFilter}
        >
          <HistoryFilter
            filter={filter}
            onSetFilter={(filter) => setFilter(filter)}
            onResetFilter={() => setFilter(defaultFilter)}
            onClose={onCloseFilter}
          />
        </FunctionalModalSidebar>
      </Portal>

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={!!openOrder}
          backLabel="Сообщения"
          onClose={onItemClose}
        >
          <HistoryDetail orderNumber={openOrder} treaty={treaty?.treaty} />
        </FunctionalModalSidebar>
      </Portal>
    </div>
  );
};
