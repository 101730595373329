import log from 'loglevel';
import { useMutation } from 'react-query';

import { fetchLkFile } from '@terminal/core/lib/rest/lkApi';

export const useDownloadFile = () => {
  return useMutation(
    async (url: string) => {
      return await fetchLkFile(url);
    },
    {
      onError: (error: any) => {
        log.error('Ошибка загрузки файла');
      },
    }
  );
};
