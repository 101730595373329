import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { requestMoneyBankTemplates } from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useBankTemplates = (treaty: number, open: boolean = false) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.BankTemplates, { treaty }],
    () => requestMoneyBankTemplates(treaty),
    {
      enabled: Boolean(treaty) && open,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'При запросе счетов произошла ошибка',
        });
      },
    }
  );
};
