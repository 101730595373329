import sumBy from 'lodash/sumBy';

import { ObjectGroup } from '../client/entities';

import { PositionItem } from '../../types/position';

const groups = [
  ObjectGroup.Stocks,
  ObjectGroup.Bonds,
  ObjectGroup.EquityParticipations,
  ObjectGroup.Receipts,
  ObjectGroup.Currency,
  ObjectGroup.Metals,
];

/**
 * @returns Абсолютное значение суммы стоимостей всех отрицательных позиций(шортов) в рублевом эквиваленте.
 * */
export function calculateShortTogPosLiquedRurSum(
  positions: PositionItem[]
): number {
  return sumBy(
    positions.filter(
      (position) =>
        position.torgPos < 0 &&
        groups.includes(position.objectType?.idObjectGroup)
    ),
    (position: PositionItem) => Math.abs(position.torgPosCostRur || 0)
  );
}
