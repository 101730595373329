import { StrategyThemeDTO } from '../api/types/StrategyThemeDTO';
import { StrategyTheme } from '../model/types/StrategyTheme';

export function mapStrategyTheme(dto: StrategyThemeDTO): StrategyTheme {
  return {
    id: dto.id,
    name: dto.name,
    enabled: dto.enabled,
    passPercent: dto.passPercent,
    comment: dto.comment,
  };
}
