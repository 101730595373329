import cn from 'classnames';
import { FC, HTMLAttributes, ReactNode } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import styles from './FormSectionHeader.module.css';

export type FormSectionHeaderProps = HTMLAttributes<HTMLDivElement> & {
  tooltip?: ReactNode;
  rightAddons?: ReactNode;
};

/** Заголовок секции формы с слотами для подсказки и контента справа */
export const FormSectionHeader: FC<FormSectionHeaderProps> = ({
  children,
  tooltip,
  rightAddons,
  className,
  ...restProps
}) => {
  return (
    <div {...restProps} className={cn(styles.header, className)}>
      <div className={styles.content}>
        <Typography.Text view="primary-small" weight="bold">
          {children}
        </Typography.Text>
        {tooltip && <div className={styles.tooltip}>{tooltip}</div>}
      </div>

      {rightAddons && <div className={styles.addons}>{rightAddons}</div>}
    </div>
  );
};
