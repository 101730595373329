import { useCallback, useEffect } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';
import useNotification from '@terminal/core/hooks/useNotification';
import {
  CertifcatesService,
  CertificatesEvent,
} from '@terminal/core/lib/services/certificates';
import { NotificationType } from '@terminal/core/types/ui';

export function useEnrollCertificate() {
  const { useCurrentCertificate } = useAlfaDirectContext();
  const { certificateEnrollId, generateCertificate, setCertificateEnrollId } =
    useCurrentCertificate();
  const addNotification = useNotification();

  const onError = useCallback(
    (errorMsg: string) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка выпуска сертификата',
        text: errorMsg,
      });
    },
    [addNotification]
  );

  const onSuccess = useCallback(
    (idCertificate: number) => {
      setCertificateEnrollId(idCertificate);
    },
    [setCertificateEnrollId]
  );

  const enrollCertificate = useCallback(() => {
    if (!certificateEnrollId) {
      generateCertificate();
    }
  }, [certificateEnrollId, generateCertificate]);

  useEffect(() => {
    CertifcatesService.on(CertificatesEvent.ENROLL_ERROR, onError);
    CertifcatesService.on(CertificatesEvent.ENROLL_SUCCESS, onSuccess);

    return () => {
      CertifcatesService.off(CertificatesEvent.ENROLL_ERROR, onError);
      CertifcatesService.off(CertificatesEvent.ENROLL_SUCCESS, onSuccess);
    };
  }, [onError, onSuccess]);

  return {
    enrollCertificate,
  };
}
