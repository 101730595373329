import { useEffect } from 'react';

import { trackPriceService } from '../trackPriceService';

import { useStore } from '../../../../store';

import { AppState } from '../../../../types/app';

/**
 * Подписывается на обновления алертов и обновляет данные в сторе
 * */
export function useTrackPriceSubscription() {
  const appState = useStore((store) => store.appState);
  const updateTrackPrices = useStore((store) => store.updateTrackPrices);

  useEffect(() => {
    if (appState !== AppState.READY) {
      return;
    }

    const unsubscribeData = trackPriceService.subscribe(updateTrackPrices);

    return () => trackPriceService.unsubscribe(unsubscribeData);
  }, [appState, updateTrackPrices]);
}
