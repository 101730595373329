import { formatOrderDate } from '@terminal/core/lib/format';

export function formatPeriod(from?: number, to?: number): string | undefined {
  const fromDate = from ? formatOrderDate(new Date(from), 'yyyy') : undefined;

  const toDate = to ? formatOrderDate(new Date(to), 'yyyy') : undefined;

  if (fromDate && toDate) {
    return `${fromDate} - ${toDate}`;
  } else if (!fromDate && !toDate) {
    return 'Выберите период';
  }

  return fromDate || toDate;
}

export interface DatePeriod {
  dateFrom?: number;
  dateTo?: number;
}
