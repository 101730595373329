import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { SignCreated } from '@terminal/core/lib/rest/lkSignOperation';
import { NotificationType } from '@terminal/core/types/ui';

import { ChangeTariffParams } from '../api/types/ChangeTariffParams';

export const useChangeTariff = (
  requestChangeTariff: (
    params: ChangeTariffParams
  ) => Promise<SignCreated | undefined>
) => {
  const addNotification = useNotification();

  return useMutation(requestChangeTariff, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При смене тарифа произошла ошибка',
      });
    },
  });
};
