import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getSecuritiesActives } from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesActives = (
  treaty: number = 0,
  accCode: string = '',
  placeCode: string = '',
  enable: boolean = true
) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.SecuritiesActives, { treaty, accCode, placeCode }],
    async () => getSecuritiesActives({ treaty, accCode, placeCode }),
    {
      enabled: Boolean(enable && treaty && accCode && placeCode),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка запросе активов',
        });
      },
    }
  );
};
