import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';
import { Loader } from '@alfalab/core-components/loader';
import { TagDesktop } from '@alfalab/core-components/tag/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';

import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import {
  SecuritiesDraftOpTypeFilter,
  SecuritiesDraftsFilter,
  SecuritiesDraftsParams,
} from '@terminal/core/lib/rest/lkSecurities';

import { Plug, Result } from '../../../../shared';
import {
  SelectTreaty,
  SelectTreatyProps,
} from '../../../components/SelectTreaty';
import {
  DraftCompleteResult,
  DraftsList,
  TRANSACTION_NAMES,
  TRANSACTION_TYPES,
} from '../../components/DraftsList';

import { useSecuritiesDrafts } from '../../hooks';

import styles from './SecuritiesDrafts.module.css';

const PAGE_SIZE = 20;

export const defaultFilter: SecuritiesDraftsFilter = {
  transferType: 'all',
};

interface SecuritiesDraftsProps {
  onComplete?: (params: DraftCompleteResult) => void;
}

export const SecuritiesDrafts = ({ onComplete }: SecuritiesDraftsProps) => {
  const [treaty, setTreaty] = useState<Treaty | null>(null);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<SecuritiesDraftsFilter>(defaultFilter);

  const draftsParams = useMemo((): SecuritiesDraftsParams => {
    return {
      ...filter,
      treaty: treaty?.treaty || 0,
      page,
      count: PAGE_SIZE,
    };
  }, [page, treaty, filter]);

  const [debouncedDraftsParams] = useDebounce<SecuritiesDraftsParams>(
    draftsParams,
    250
  );

  const {
    data: drafts,
    isFetching: draftsPending,
    isFetchingNextPage: draftsPendingNext,
    hasNextPage: hasMoreDrafts,
    fetchNextPage: fetchMoreDrafts,
  } = useSecuritiesDrafts(debouncedDraftsParams);

  const [loaderRef, loaderInView] = useInView();

  useEffect(() => {
    if (loaderInView) {
      fetchMoreDrafts();
    }
  }, [loaderInView, fetchMoreDrafts]);

  const draftsItems = useMemo(() => {
    if (drafts) {
      return drafts.pages.flatMap((page) => page.drafts);
    }

    return null;
  }, [drafts]);

  const onSelectTreatyChange: SelectTreatyProps['onSelect'] = (treaty) => {
    setTreaty(treaty);
    setPage(1);
  };

  const onTransferTypeChange = (type: SecuritiesDraftOpTypeFilter) => {
    setFilter((prev) => {
      return {
        ...prev,
        transferType: type,
      };
    });
  };

  const isForeignMarket = Boolean(treaty?.isForeignMarket);

  return (
    <div className={styles.container}>
      <div className={styles.treaty}>
        <SelectTreaty onSelect={onSelectTreatyChange} />
      </div>

      {isForeignMarket && (
        <Plug
          title={
            <>
              Переводы ценных бумаг на счёте
              <br /> Иностранный рынок недоступны
            </>
          }
        />
      )}

      {!isForeignMarket && (
        <>
          <div className={styles.filter}>
            {TRANSACTION_TYPES.map((type) => (
              <TagDesktop
                key={`filter-type-button-${type}`}
                size="xxs"
                className={styles.tagFilter}
                checked={filter.transferType === type}
                onClick={() => onTransferTypeChange(type)}
              >
                <Typography.Text view="secondary-large">
                  {TRANSACTION_NAMES[type]}
                </Typography.Text>
              </TagDesktop>
            ))}
          </div>

          <div className={styles.drafts}>
            {draftsItems && draftsItems.length === 0 && (
              <Result
                title="Результатов нет :("
                text={
                  <>
                    По заданным параметрам не&nbsp;найдено ни&nbsp;одного
                    перевода
                  </>
                }
                icon={<FilterMIcon />}
              />
            )}

            {draftsItems && draftsItems.length !== 0 && (
              <DraftsList
                drafts={draftsItems}
                treaty={treaty}
                onComplete={onComplete}
              />
            )}

            {(draftsPending || draftsPendingNext) && <Loader />}

            {hasMoreDrafts && !(draftsPending || draftsPending) && (
              <div className={styles.loader} ref={loaderRef}></div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
