import cn from 'classnames';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { Typography } from '@alfalab/core-components/typography';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import { SecuritiesTranferXmlMultipleOperation } from '@terminal/core/lib/rest/lkSecurities';

import { getDirectionName } from './helpers';

import tablestyles from '../../../styles/tables.module.css';
import styles from './Operation.module.css';
import liststyles from './XmlOperations.module.css';

interface OperationProps {
  item: SecuritiesTranferXmlMultipleOperation;
  checked: boolean;
  odd?: boolean;
  onChange?: (id: string) => void;
}

export const Operation = ({ item, checked, odd, onChange }: OperationProps) => (
  <div
    className={cn(styles.item, tablestyles.row, {
      [tablestyles.rowOdd]: odd,
    })}
  >
    <div className={cn(tablestyles.cell, liststyles.cellCheck)}>
      {item.code === 0 ? (
        <Checkbox
          onChange={() =>
            onChange && onChange(item.model.header.metadata.objectId)
          }
          checked={checked}
          size="s"
        />
      ) : (
        <Tooltip
          targetClassName={styles.tooltipTrigger}
          contentClassName={styles.tooltipContent}
          position="top"
          trigger="hover"
          content={
            <Typography.Text view="secondary-small" color="secondary" tag="div">
              {item.message}
            </Typography.Text>
          }
          fallbackPlacements={['left', 'right']}
        >
          <ExclamationCircleMIcon width={18} height={18} color="#EF3124" />
        </Tooltip>
      )}
    </div>
    <div
      className={cn(
        tablestyles.cell,
        tablestyles.cellFade,
        liststyles.cellType
      )}
    >
      {getDirectionName(item.model.body.direction)}
    </div>
    <div className={cn(tablestyles.cell, liststyles.cellIsin)}>
      {item.model.body.isin}
    </div>
    <div className={cn(tablestyles.cell, liststyles.cellCount)}>
      {item.model.body.quantity}
    </div>
  </div>
);
