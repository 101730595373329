export function parseMiddleUrl(url: string) {
  const version = url.match(/\/(v\d+)\//)?.[1] as string;

  if (!url.includes(`/${version}/`)) {
    throw new Error(
      `The provided URL does not contain the required Version (v1|v2) segment: ${url}`
    );
  }

  const [, servicePath] = url.split(`/${version}/`);
  let service;
  let path;

  if (servicePath.includes('/')) {
    [service] = servicePath.split('/', 2);
    path = servicePath.slice(service.length + 1);
  } else {
    [service] = servicePath.split('?', 2);
    path = servicePath.slice(service.length + 1);

    if (servicePath.includes('?')) {
      path = `?${path}`;
    }
  }

  return { version, service, path };
}
