import { Button, ButtonProps } from '@alfalab/core-components/button';
import { SendMIcon } from '@alfalab/icons-glyph/SendMIcon';

export const SendButton = ({ className, children, ...props }: ButtonProps) => {
  return (
    <Button
      size="s"
      leftAddons={
        <SendMIcon
          width={15}
          height={15}
          color="var(--color-light-graphic-secondary)"
        />
      }
      {...props}
    >
      {children || 'Направить поручение'}
    </Button>
  );
};
