import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { CalendarInput } from '@alfalab/core-components/calendar-input';
import { Input } from '@alfalab/core-components/input';
import { InputAutocompleteDesktop } from '@alfalab/core-components/input-autocomplete/desktop';
import { OptionShape } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';

import { FreeOrderStatuses } from '@terminal/core/lib/rest/lkFreeOrders';

import { FilterButton } from '../transfer/FilterButton';

import { FilterActions, FilterActionsTypes, FilterState } from './types';

import styles from './FreeOrders.module.css';

interface FreeOrderDetailedFilterProps {
  filter: FilterState;
  dispatch: React.Dispatch<FilterActions>;
}

const makeYears = () => {
  let i = new Date().getFullYear();
  const yearsList: { value: string; key: string }[] = [];

  while (i >= 1970) {
    yearsList.push({ value: i.toString(), key: i.toString() });
    i--;
  }

  return yearsList;
};

export const FreeOrderDetailedFilter = ({
  filter,
  dispatch,
}: FreeOrderDetailedFilterProps) => {
  const [text, setText] = useState<string | null>(null);
  const [period, setPeriod] = useState<'week' | 'month' | null>(null);
  const [dates, setDates] = useState<{
    startDate: { date: Date | undefined; value: string } | null;
    endDate: { date: Date | undefined; value: string } | null;
  }>({ startDate: null, endDate: null });
  const [year, setYear] = useState<OptionShape | null>(null);
  const [statuses, setStatuses] = useState<Array<FreeOrderStatuses>>([]);

  const yearsList = useMemo(makeYears, []);

  useEffect(() => {
    setText(filter.text);
    setStatuses([...filter.status]);
    setDates({
      endDate: {
        date: filter.endDate,
        value: filter.endDate ? format(filter.endDate, 'dd.MM.yyy') : '',
      },
      startDate: {
        date: filter.startDate,
        value: filter.startDate ? format(filter.startDate, 'dd.MM.yyy') : '',
      },
    });
  }, [filter]);

  useEffect(() => {
    if (period) {
      const today = new Date();
      const dateAgo =
        period === 'week'
          ? new Date(new Date().setDate(new Date().getDate() - 7))
          : new Date(new Date().setMonth(new Date().getMonth() - 1));

      setDates({
        endDate: {
          date: today,
          value: format(today, 'dd.MM.yyy'),
        },
        startDate: {
          date: dateAgo,
          value: format(dateAgo, 'dd.MM.yyy'),
        },
      });
    }
  }, [period]);

  const handleDateChange = (
    payload: { date: Date; value: string },
    type: 'startDate' | 'endDate'
  ) => {
    setYear(null);
    setPeriod(null);
    setDates((prev) => ({
      ...prev,
      [type]: {
        ...payload,
      },
    }));
  };

  const handlePeriodClick = (periodType: 'week' | 'month') => {
    if (periodType === period) {
      setPeriod(null);
    } else {
      setPeriod(periodType);
    }

    setYear(null);
  };

  const handleTextInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    payload: { value: string }
  ) => {
    setText(payload.value);
  };

  const handleYearChange = (payload: {
    selected: OptionShape | null;
    selectedMultiple: OptionShape[];
    initiator: OptionShape | null;
    name?: string | undefined;
  }) => {
    setYear(payload.selected);
    const endDate =
      payload.selected?.value === new Date().getFullYear().toString()
        ? new Date()
        : new Date(Number(payload.selected?.value), 11, 31);
    const startDate = new Date(payload.selected?.value);

    setDates({
      endDate: {
        date: endDate,
        value: format(endDate, 'dd.MM.yyy'),
      },
      startDate: {
        date: startDate,
        value: format(startDate, 'dd.MM.yyy'),
      },
    });
    setPeriod(null);
  };

  const handleStatusChange = (status: FreeOrderStatuses) => {
    setStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((prevStat) => prevStat !== status)
        : [...prev, status]
    );
  };

  const handleReset = () => {
    setYear(null);
    setPeriod(null);
    setDates({ startDate: null, endDate: null });
    dispatch({ type: FilterActionsTypes.Reset });
  };

  return (
    <Space fullWidth>
      <Typography.Text view="primary-small" weight="bold" tag="p">
        Фильтры
      </Typography.Text>
      <div>
        <Typography.Text
          view="secondary-large"
          tag="p"
          color="secondary"
          weight="bold"
        >
          Текст
        </Typography.Text>
        <Input
          placeholder="Все"
          value={text || ''}
          onChange={handleTextInput}
          block
          rightAddons={
            <MagnifierMIcon
              width={15}
              height={15}
              fill="var(--color-light-graphic-secondary)"
            />
          }
          size="s"
        />
      </div>
      <div>
        <Typography.Text
          view="secondary-large"
          tag="p"
          color="secondary"
          weight="bold"
        >
          Период
        </Typography.Text>
        <div className={styles.filterRow}>
          <FilterButton
            checked={period === 'week'}
            onClick={() => handlePeriodClick('week')}
          >
            Неделя
          </FilterButton>
          <FilterButton
            checked={period === 'month'}
            onClick={() => handlePeriodClick('month')}
          >
            Месяц
          </FilterButton>
        </div>
      </div>
      <div>
        <InputAutocompleteDesktop
          label="Год"
          labelView="outer"
          placeholder="Укажите год"
          onChange={handleYearChange}
          value={year?.value}
          options={yearsList}
          Arrow={ChevronDownMIcon}
          closeOnSelect
        />
      </div>
      <div className={styles.filterColumns}>
        <div className={styles.datePickerContainer}>
          <CalendarInput
            label="Начало периода"
            labelView="outer"
            value={dates.startDate?.value}
            onChange={(evt, payload) => handleDateChange(payload, 'startDate')}
          />
        </div>
        <div className={styles.datePickerContainer}>
          <CalendarInput
            label="Конец периода"
            labelView="outer"
            value={dates.endDate?.value}
            onChange={(_, payload) => handleDateChange(payload, 'endDate')}
          />
        </div>
      </div>
      <div>
        <Typography.Text
          view="secondary-large"
          tag="p"
          color="secondary"
          weight="bold"
        >
          Статус поручения
        </Typography.Text>
        <div className={styles.filterRow}>
          <FilterButton
            onClick={() => handleStatusChange('S')}
            checked={statuses.includes('S')}
          >
            Принято
          </FilterButton>
          <FilterButton
            onClick={() => handleStatusChange('E')}
            checked={statuses.includes('E')}
          >
            Исполнено
          </FilterButton>
          <FilterButton
            onClick={() => handleStatusChange('R')}
            checked={statuses.includes('R')}
          >
            Отклонено
          </FilterButton>
        </div>
      </div>
      <div className={styles.filterColumns}>
        <Button
          size="s"
          leftAddons={
            <CheckmarkMIcon
              width={15}
              height={15}
              color="var(--color-light-graphic-secondary)"
            />
          }
          onClick={() =>
            dispatch({
              type: FilterActionsTypes.SetBlueprint,
              payload: {
                text,
                status: statuses,
                endDate: dates.endDate?.date,
                startDate: dates.startDate?.date,
              },
            })
          }
          block
        >
          Применить
        </Button>
        <Button
          leftAddons={
            <CrossMIcon
              width={15}
              height={15}
              color="var(--color-light-graphic-secondary)"
            />
          }
          view="outlined"
          size="s"
          onClick={handleReset}
          block
        >
          Сбросить
        </Button>
      </div>
    </Space>
  );
};
