import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Loader } from '@alfalab/core-components/loader';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import { useRequirement } from '@terminal/alfadirect/hooks';
import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';

import {
  TradingAccountOption,
  TradingAccountSelector,
  TradingAccountSelectorProps,
} from '../../../../entities/TransferGoinvest';
import { getTradeAccountMargin } from '../../../../entities/TransferGoinvest';
import { RequirementHint } from './RequirementHint';

interface SelectTradeAccountProps {
  /** Список счетов для выбора */
  accounts: TradeAccount[] | undefined | null;
  /** Выбранный счёт */
  selected: TradeAccount | undefined | null;
  /** Подменит значение средств на счёте */
  limitValue?: number;
  /** Лейбл */
  label?: TradingAccountSelectorProps['label'];
  /** Вид лейбла */
  labelView?: TradingAccountSelectorProps['labelView'];
  /** Размер селекта */
  size?: TradingAccountSelectorProps['size'];
  /** Загрузка счетов */
  accountsPending?: boolean;
  /** Есть ещё счета для загрузки */
  hasMore?: boolean;
  /** Подсказка */
  hint?: React.ReactNode;
  /** Обработчик выбора счёта */
  onAccountSelect: (acc: TradeAccount) => void;
  /** Функция загрузки следующего списка счетов */
  accountsFetcher?: () => void;
}

export const SelectTradeAccount = ({
  accounts,
  selected,
  limitValue,
  label,
  labelView,
  size,
  accountsPending,
  hasMore,
  hint,
  onAccountSelect,
  accountsFetcher,
}: SelectTradeAccountProps) => {
  const selectedSubAccounts = useMemo(
    () => (accounts || []).map(({ subAccount }) => subAccount),
    [accounts]
  );

  const marginCall = useRequirement(selectedSubAccounts);

  const selectedRequirement = useMemo(() => {
    return getTradeAccountMargin(false, selected, marginCall);
  }, [marginCall, selected]);

  const [loaderRef, loaderInView] = useInView();

  useEffect(() => {
    if (loaderInView && accountsFetcher) {
      accountsFetcher();
    }
  }, [loaderInView, accountsFetcher, accounts]);

  const optionRenderer = (acc: TradeAccount) => {
    const requirement = getTradeAccountMargin(false, acc, marginCall);

    return (
      <TradingAccountOption
        account={acc}
        leftAddons={
          requirement.requirementType ? (
            <ExclamationCircleMIcon
              width={18}
              height={18}
              color={
                requirement.requirementType === 'immediateRequirements'
                  ? '#ef3124'
                  : '#cb830f'
              }
            />
          ) : undefined
        }
      />
    );
  };

  return (
    <TradingAccountSelector
      block
      label={label}
      labelView={labelView}
      size={size}
      accounts={accounts}
      limitValue={limitValue}
      selected={selected}
      optionRenderer={optionRenderer}
      valueRenderer={optionRenderer}
      onChange={onAccountSelect}
      hint={
        selectedRequirement.requirementType ? (
          <RequirementHint requirements={selectedRequirement} />
        ) : (
          hint
        )
      }
      optionsListProps={{
        footer: (
          <>
            {accountsPending && <Loader />}
            {hasMore && <div ref={loaderRef}></div>}
          </>
        ),
      }}
    />
  );
};
