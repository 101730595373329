import cn from 'classnames';
import React, { ElementType, forwardRef, HTMLAttributes } from 'react';
import {
  CustomButton,
  CustomButtonProps,
} from '@alfalab/core-components/custom-button';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import styles from './PickerButton.module.css';

type View = 'secondary' | 'link';

export type PickerButtonProps = HTMLAttributes<HTMLButtonElement> &
  Pick<CustomButtonProps, 'size' | 'block'> & {
    /**
     * Если `true`, то иконка шеврона отображается в перевернутом виде.
     * Если не задано, то иконка шеврона не отображается
     * */
    isOpen?: boolean;
    /**
     * Иконка, отображаемая слева
     * */
    Icon?: ElementType<Pick<HTMLAttributes<HTMLElement>, 'className'>>;
    /**
     * Вариант кнопки
     * @default `secondary'`
     * */
    view?: View;
  };

const BG_COLOR_BY_VIEW: Record<View, string> = {
  secondary: 'var(--form-control-bg-color)',
  link: 'transparent',
};

/**
 * Кнопка-пикер. Используется в связке с выпадающим меню либо самостоятельно
 * */
export const PickerButton = forwardRef<HTMLButtonElement, PickerButtonProps>(
  (props, ref) => {
    const {
      isOpen,
      children,
      Icon,
      className,
      view = 'secondary',
      ...restProps
    } = props;
    const rootClassName = cn(className, styles.root, {
      [styles.inactive]: isOpen,
    });
    const bgColor = BG_COLOR_BY_VIEW[view];

    return (
      <CustomButton
        ref={ref}
        size="xs"
        backgroundColor={
          isOpen ? 'var(--color-light-border-key-alpha-15)' : bgColor
        }
        nowrap
        className={rootClassName}
        leftAddons={Icon && <Icon className={styles.icon} />}
        rightAddons={
          isOpen !== undefined &&
          children !== undefined && (
            <ChevronDownMIcon
              className={cn(styles.chevron, {
                [styles.chevronRotate]: isOpen,
              })}
            />
          )
        }
        {...restProps}
      >
        {children}
      </CustomButton>
    );
  }
);
