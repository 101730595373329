import { makeGetParams } from '../url';
import { fetchLkResult, LKResult } from './lkApi';
import { signOperation } from './lkSignOperation';

import { LkSignOptions } from '../../store/slices/lkSignSlice';

export interface DraftPreview {
  readonly draftId: number;
  readonly cabinetThemeId: number;
  readonly orderDraftThemeName: string;
}

export interface Draft {
  readonly cabinetThemeId: number;
  readonly orderDraftThemeName: string;
  readonly statusCode: string;
  readonly treaty: number;
  readonly dtInsert: string;
  readonly textOrder: string;
}

export async function getDraftList(treaty: number) {
  const request = makeGetParams({ treaty });
  const res = await fetchLkResult<DraftPreview[] | LKResult>(
    `/api/free-order/draft/list?${request}`
  );
  if ('code' in res && res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res as DraftPreview[];
}

export async function getDraftDetail(treaty: number, orderDraftId: number) {
  const request = makeGetParams({ treaty, orderDraftId });
  const res = await fetchLkResult<Draft | LKResult>(
    `/api/free-order/draft/detail?${request}`
  );
  if ('code' in res && res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res as Draft;
}

export interface DraftConfirmParams {
  treaty: number;
  orderDraftId: number;
}

export async function confirmDraft(
  data: DraftConfirmParams,
  options: LkSignOptions
) {
  return signOperation('/api/free-order/draft/confirm', 'POST', data, options);
}

export interface DraftRejectParams {
  treaty: number;
  orderDraftId: number;
}

export async function rejectDraft(data: DraftRejectParams) {
  const res = await fetchLkResult<LKResult>(
    `/api/free-order/draft/reject`,
    'POST',
    data
  );
  if ('code' in res && res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}
