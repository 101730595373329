import React from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { useAppContext } from '@terminal/core/hooks/useAppContext';

import styles from './NoSmsScreen.module.css';

interface Props {
  onBack: () => void;
}

export const NoSmsScreen = ({ onBack }: Props) => {
  const { branchName, supportPhone, supportLandlinePhone } = useAppContext();

  return (
    <div className={styles.container}>
      <Typography.Title tag="h3" font="styrene" view="xsmall">
        Не приходит сообщение?
      </Typography.Title>
      <Gap size="xl" />
      <Typography.Text view="secondary-large" tag="div">
        Если у вас изменился номер телефона, позвоните нам или обратитесь в{' '}
        {branchName}.
      </Typography.Text>
      <Gap size="xl" />
      <Typography.Text view="secondary-large" tag="div" weight="bold">
        {supportPhone}&nbsp;—&nbsp;
        <Typography.Text tag="span" view="secondary-large" color="secondary">
          для звонков по России
        </Typography.Text>
      </Typography.Text>
      <Gap size="xs" />
      <Typography.Text view="secondary-large" tag="div" weight="bold">
        {supportLandlinePhone}&nbsp;—&nbsp;
        <Typography.Text tag="span" view="secondary-large" color="secondary">
          в Москве и за границей
        </Typography.Text>
      </Typography.Text>
      <Gap size="xl" />
      <Typography.Text view="secondary-large" tag="div">
        Если номер не менялся, возможно, перегружен сервис отправки сообщений.
        Попробуйте повторить действие через несколько минут
      </Typography.Text>
      <Gap size="xl" />
      <ButtonDesktop view="secondary" size="xs" onClick={onBack}>
        Вернуться к вводу кода
      </ButtonDesktop>
    </div>
  );
};
