import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { GetSelfTransferAccountsDTO } from './types/GetSelfTransferAccountsDTO';

export const getSelfTransferAccounts = async (): Promise<
  GetSelfTransferAccountsDTO['accounts']
> => {
  const res = await fetchLkResult<GetSelfTransferAccountsDTO | LKResult>(
    '/api/self-transfer/accounts'
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res.accounts;
};
