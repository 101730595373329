import React, { VFC } from 'react';
import { Badge } from '@alfalab/core-components/badge';
import { Tag, TagProps } from '@alfalab/core-components/tag';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';

import styles from './FilterIconButton.module.css';

export type FilterIconButtonProps = Pick<TagProps, 'onClick' | 'checked'>;

export const FilterIconButton: VFC<FilterIconButtonProps> = (props) => {
  const { onClick, checked } = props;

  return (
    <Tag
      size="xs"
      view="filled"
      onClick={onClick}
      className={styles.root}
      style={{ minWidth: 0, paddingLeft: 3, paddingRight: 3 }}
    >
      <FilterMIcon className={styles.icon} />
      {checked && (
        <Badge view="count" visibleIconOutline className={styles.badge} />
      )}
    </Tag>
  );
};
