import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import { AlertCircleMIcon } from '@alfalab/icons-glyph/AlertCircleMIcon';

import styles from './ChatHistoryError.module.css';

interface ChatHistoryErrorProps {
  onRetry: () => void;
}

export const ChatHistoryError = ({ onRetry }: ChatHistoryErrorProps) => (
  <div className={styles.container}>
    <div className={styles.topRow}>
      <AlertCircleMIcon
        width={24}
        height={24}
        color="#F6BF65"
        className={styles.icon}
      />
      <Typography.Text view="primary-small">
        Не удалось загрузить историю переписки
      </Typography.Text>
    </div>
    <div className={styles.bottomRow}>
      <Button size="xs" onClick={onRetry}>
        Обновить
      </Button>
    </div>
  </div>
);
