import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';

/**
 * Выбирает приоритетный счёт из списка
 * @param accounts - список счетов
 * @returns выбранный рынок
 */
export function getPriorityTradeAccount(
  accounts: TradeAccount[]
): TradeAccount | undefined {
  if (!accounts.length) {
    return undefined;
  }

  // Выбираем все рублевые
  const filtered = accounts.filter((account) => account.currency === 'RUB');

  filtered.sort((a, b) => {
    if (a.placeCode === b.placeCode) {
      return 0;
    }

    // Среди рублевых в приоритете счета на рынке ценных бумаг
    if (a.placeCode === 'MICEX_SHR') {
      return -1;
    }

    return 1;
  });

  if (filtered.length) {
    return filtered[0];
  }

  return accounts[0];
}
