import { WidgetsNameMap } from '@terminal/core/constants/Layout';
import { ElementType, LinkName, Widget } from '@terminal/core/types/layout';

import { BaseLayoutBlock } from './baseLayoutBlock';

export class Tab extends BaseLayoutBlock {
  readonly name: string;
  readonly component: Widget;
  readonly config: Record<string, unknown> & { link?: LinkName };
  readonly chartSettings?: unknown;

  constructor(
    component: Widget,
    config: Record<string, unknown> & { link?: LinkName },
    chartSettings?: unknown
  ) {
    super(ElementType.TAB);
    this.name = WidgetsNameMap.get(component) || '';
    this.component = component;
    this.config = config;
    this.chartSettings = chartSettings;
  }

  render() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      component: this.component,
      config: this.config,
    };
  }
}
