import { MouseEventHandler, useRef } from 'react';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

import { useOnClickOutside } from '@terminal/core/hooks';
import { LinkName, LinkNamesMap } from '@terminal/core/types/layout';

import { Dropdown } from '../Dropdown';
import { ReactComponent as DefaultLink } from '../icons/DefaultLink.svg';

import styles from './Link.module.css';

interface LinkIconProps {
  linkName: LinkName;
  onClick?: MouseEventHandler<SVGSVGElement>;
  withTooltip?: boolean;
  iconSize?: number;
}

export const getLinkIcon = ({
  linkName,
  onClick,
  withTooltip,
  iconSize = 16,
}: LinkIconProps) => {
  let icon = (
    <DefaultLink
      color="var(--color-light-graphic-secondary)"
      height={iconSize}
      width={iconSize}
      onClick={onClick}
    />
  );

  switch (linkName) {
    case LinkName.BLUE:
      icon = (
        <DefaultLink
          height={iconSize}
          width={iconSize}
          color="#4F84BA"
          onClick={onClick}
        />
      );
      break;
    case LinkName.YELLOW:
      icon = (
        <DefaultLink
          height={iconSize}
          width={iconSize}
          color="#DB723C"
          onClick={onClick}
        />
      );
      break;
    case LinkName.RED:
      icon = (
        <DefaultLink
          height={iconSize}
          width={iconSize}
          color="#FF5C5C"
          onClick={onClick}
        />
      );
      break;
    case LinkName.GREEN:
      icon = (
        <DefaultLink
          height={iconSize}
          width={iconSize}
          color="#0F9C8C"
          onClick={onClick}
        />
      );
      break;
  }

  return withTooltip ? (
    <Tooltip
      view="hint"
      offset={[0, 4]}
      targetClassName={styles.tooltipTarget}
      content={LinkNamesMap.get(linkName)}
      trigger="hover"
      onOpenDelay={500}
      position="top"
      preventOverflow={false}
    >
      {icon}
    </Tooltip>
  ) : (
    icon
  );
};

interface Props {
  isOpen: boolean;
  anchor: React.RefObject<HTMLElement>;
  currentLink: LinkName;
  onClose: () => void;
  onChooseLink: (linkName: LinkName) => void;
}

export const LinkDropdown = ({
  isOpen,
  anchor,
  currentLink,
  onClose,
  onChooseLink,
}: Props) => {
  //Реф для отлавливания кликов вне компонента
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => {
    isOpen && onClose();
  });

  return (
    <Dropdown
      ref={dropdownRef}
      popperClassName={styles.popperClassName}
      anchorElement={anchor.current}
      open={isOpen}
      offset={[-8, 16]}
      position="bottom-start"
    >
      <div className={styles.titleWrapper}>
        <Typography.Text
          view="secondary-large"
          weight="bold"
          className={styles.linkTitle}
        >
          Связать виджеты
        </Typography.Text>
        <Tooltip
          view="hint"
          offset={[0, 4]}
          targetClassName={styles.tooltipTarget}
          contentClassName={styles.tooltipContent}
          content="В связанных виджетах данные
          по бумаге, выбранной в одном
          из виджетов, будут отображаться
          во всех виджетах группы"
          trigger="hover"
          position="top"
          preventOverflow={false}
        >
          <InformationCircleLineMIcon
            height={16}
            width={16}
            color="var(--color-light-text-secondary)"
          />
        </Tooltip>
      </div>

      <Dropdown.Item
        withDivider
        content={LinkNamesMap.get(currentLink)!}
        leftIcon={getLinkIcon({ linkName: currentLink })}
        rightIcon={<CheckmarkMIcon height={16} width={16} />}
        onClick={onClose}
      />

      {[
        LinkName.DEFAULT,
        LinkName.BLUE,
        LinkName.GREEN,
        LinkName.RED,
        LinkName.YELLOW,
      ]
        .filter((linkName) => linkName !== currentLink)
        .map((linkName) => (
          <Dropdown.Item
            key={linkName}
            content={LinkNamesMap.get(linkName)!}
            leftIcon={getLinkIcon({ linkName })}
            onClick={() => {
              onChooseLink(linkName);
              onClose();
            }}
          />
        ))}
    </Dropdown>
  );
};
