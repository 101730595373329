import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import { useMemo } from 'react';

import { BalanceGroupNames } from '../../constants/balance';
import { formatNumber, formatNumberOrNull } from '../../lib/format';
import { calculateBalance } from '../../lib/formulas/calculateBalance';
import { calculateImmediateRequirements } from '../../lib/formulas/calculateImmediateRequirements';
import { calculateInitialMargin } from '../../lib/formulas/calculateInitialMargin';
import { calculateLiquedBalance } from '../../lib/formulas/calculateLiquedBalance';
import { calculateLongBalance } from '../../lib/formulas/calculateLongBalance';
import { calculateMinimumMargin } from '../../lib/formulas/calculateMinimumMargin';
import { calculateNKDBalance } from '../../lib/formulas/calculateNKDBalance';
import { calculateNPLRur } from '../../lib/formulas/calculateNPLRur';
import { calculatePrevBalance } from '../../lib/formulas/calculatePrevBalance';
import { calculateRequirements } from '../../lib/formulas/calculateRequirements';
import { calculateShortBalance } from '../../lib/formulas/calculateShortBalance';
import { getCurrencyPrice } from '../../lib/formulas/getCurrencyPrice';
import { Treaty } from '../../lib/rest/lkCommon';
import { sumByOrNull } from '../../lib/sumByOrNull';
import { useSingleFORTSMarket } from '../useSingleFORTSMarket';

import { FinInfoExtRecord } from '../../lib/services/finInfoExt/types';
import {
  BalanceItem,
  BalanceOptions,
  BalanceRowsRaw,
} from '../../types/balance';
import { ECurrencyId } from '../../types/currencyId';
import { PositionItem } from '../../types/position';
import { FinInfo } from '../../types/quotes';
import { SubGTAccountItem } from '../../types/subAccount';

const RUB_ID = ECurrencyId.RUR;
const HUNDRED_PERCENT = 100;

export function useBalance(
  {
    positions,
    currenciesFinInfoExts,
    currenciesQuotes,
    subGTAccounts,
    treaties,
  }: BalanceRowsRaw,
  { selectedSubAccounts, groupType, filterNullBalance }: BalanceOptions
): BalanceItem[] {
  const isSingleFORTSMarketAvailable = useSingleFORTSMarket();

  return useMemo(
    () =>
      makeRows(
        {
          positions,
          currenciesFinInfoExts,
          currenciesQuotes,
          subGTAccounts,
          treaties,
        },
        {
          selectedSubAccounts,
          groupType,
          isSingleFORTSMarketAvailable,
          filterNullBalance,
        }
      ),
    [
      positions,
      currenciesFinInfoExts,
      currenciesQuotes,
      selectedSubAccounts,
      subGTAccounts,
      groupType,
      isSingleFORTSMarketAvailable,
      filterNullBalance,
      treaties,
    ]
  );
}

export function makeRows(
  {
    positions,
    currenciesFinInfoExts,
    currenciesQuotes,
    subGTAccounts,
    treaties,
  }: BalanceRowsRaw,
  {
    selectedSubAccounts,
    groupType = 'account',
    isSingleFORTSMarketAvailable = false,
    filterNullBalance = false,
  }: BalanceOptions
): BalanceItem[] {
  //Учитываем фильтры по субсчетам
  const filteredData = positions.filter((tempRow) =>
    selectedSubAccounts.includes(
      String(tempRow.subAccountRazdel.codeSubAccount)
    )
  );

  // Считаем балансы на счетах
  const rows = finalRowsByAccounts(
    subGTAccounts,
    filteredData,
    currenciesQuotes,
    currenciesFinInfoExts,
    groupType,
    isSingleFORTSMarketAvailable,
    treaties
  );

  if (filterNullBalance) {
    const fieldsToFilter = [
      'balance',
      'liquedBalance',
      'requirements',
      'immediateRequirements',
      'portfolioValueWithOrders',
    ];

    return rows.filter((row) => !fieldsToFilter.some((i) => row[i] === null));
  }

  return rows;
}

/** Расчитываем процент по дневному ПУ */
export const getDailyPLRurPrecent = (marketGroup: BalanceItem[]) => {
  const daylyPlSum = sumBy(marketGroup, 'dailyPLRur');
  const baseDailyPl = sumBy(marketGroup, 'baseDailyPLtoMarketCurPrice');

  return baseDailyPl === 0
    ? 0
    : formatNumber((HUNDRED_PERCENT * daylyPlSum) / baseDailyPl, 2);
};

/** Расчитываем процент по НПУ */
export const getNPLRurPrecent = (marketGroup: BalanceItem[]) => {
  const NPlSum = sumBy(marketGroup, 'NPLRur');
  const baseNPl = sumBy(marketGroup, 'torgPosUchCostRur');

  return baseNPl === 0
    ? 0
    : formatNumber((HUNDRED_PERCENT * NPlSum) / baseNPl, 2);
};

const finalRowsByAccounts = (
  subGTAccounts: SubGTAccountItem[],
  filteredData: PositionItem[],
  currenciesQuotes: Record<number, Partial<FinInfo>>,
  currenciesFinInfoExts: FinInfoExtRecord,
  groupType: 'account' | 'market' | 'subAccount',
  isUnifiedMarket = false,
  treaties?: Treaty[]
): BalanceItem[] => {
  // Группируем позиции по idAccount или по idSubAccount если groupType = 'subAccount'
  const groupedPossitionsBySubAccounts = groupBy(
    filteredData,
    groupType === 'subAccount'
      ? 'subAccountRazdel.idSubAccount'
      : 'subAccountRazdel.idAccount'
  );

  if (isEmpty(groupedPossitionsBySubAccounts)) {
    return [];
  } else {
    //Чтобы подсчитать значения для субаккаунат, нужно сложить суммы по рынкам
    return Object.entries(groupedPossitionsBySubAccounts).reduce(
      (acc, [id, value]) => {
        const marketsGroup = finalRowsByMarkets(
          subGTAccounts,
          value,
          currenciesQuotes,
          currenciesFinInfoExts,
          isUnifiedMarket
        );
        const razdel = value[0]?.subAccountRazdel;
        const accountId =
          groupType === 'subAccount' ? String(razdel.idAccount) : id;
        const treaty = treaties?.find(
          ({ treaty }) => treaty.toString() === accountId
        );
        const isIis = Boolean(treaty && treaty.isIis && !treaty.isFirm);

        if (groupType === 'account' || groupType === 'subAccount') {
          const requirementsByMarket = marketsGroup.map(
            ({ idRazdelGroup, requirements, immediateRequirements }) => ({
              idRazdelGroup: idRazdelGroup!,
              requirements,
              immediateRequirements,
            })
          );

          acc.push({
            balance: sumByOrNull(marketsGroup, 'balance'),
            liquedBalance: sumByOrNull(marketsGroup, 'liquedBalance'),
            balanceUsd: sumBy(marketsGroup, 'balanceUsd'),
            balanceEur: sumBy(marketsGroup, 'balanceEur'),
            prevBalance: sumBy(marketsGroup, 'prevBalance'),
            dailyPLRur: sumBy(marketsGroup, 'dailyPLRur'),
            dailyPLUsd: sumBy(marketsGroup, 'dailyPLUsd'),
            dailyPLEur: sumBy(marketsGroup, 'dailyPLEur'),
            dailyPLRurPrecent: getDailyPLRurPrecent(marketsGroup),
            NPLRur: sumBy(marketsGroup, 'NPLRur'),
            NPLUsd: sumBy(marketsGroup, 'NPLUsd'),
            NPLEur: sumBy(marketsGroup, 'NPLEur'),
            NPLRurPercent: getNPLRurPrecent(marketsGroup),
            longBalance: sumBy(marketsGroup, 'longBalance'),
            shortBalance: sumBy(marketsGroup, 'shortBalance'),
            money: sumBy(marketsGroup, 'money'),
            initialMoney: sumBy(marketsGroup, 'initialMoney'),
            initialMargin: sumBy(marketsGroup, 'initialMargin'),
            minimumMargin: sumBy(marketsGroup, 'minimumMargin'),
            initialMarginWithOrders: sumBy(
              marketsGroup,
              'initialMarginWithOrders'
            ),
            requirements: sumByOrNull(marketsGroup, 'requirements'),
            immediateRequirements: sumByOrNull(
              marketsGroup,
              'immediateRequirements'
            ),
            portfolioValueWithOrders: sumByOrNull(
              marketsGroup,
              'portfolioValueWithOrders'
            ),
            //При фильтре по счетам идет группировка по счетам, маркеты (рынки) могут быть разные - не показываем
            nameBalanceGroup: '',
            idAccount: accountId,
            idSubAccount: groupType === 'subAccount' ? Number(id) : undefined,
            codeSubAccount:
              groupType === 'subAccount' ? razdel.codeSubAccount : undefined,
            requirementsByMarket,
            isIis,
          });
        } else {
          const marketsGroupByAcc = marketsGroup.map((market) => ({
            ...market,
            idAccount: accountId,
            isIis,
          }));

          acc = [...acc, ...marketsGroupByAcc];
        }

        return acc;
      },
      [] as BalanceItem[]
    );
  }
};

const finalRowsByMarkets = (
  subGTAccounts: SubGTAccountItem[],
  filteredData: PositionItem[],
  currenciesQuotes: Record<number, Partial<FinInfo>>,
  currenciesFinInfoExts: FinInfoExtRecord,
  isUnifiedMarket = false
): BalanceItem[] => {
  //Во всех формулах производятся рассчеты относительно рынка (портфеля), на котором представлены позиции
  const groupedPossitionsByMarket = groupBy(
    filteredData,
    'subAccountRazdel.idRazdelGroup'
  );

  if (isEmpty(groupedPossitionsByMarket)) {
    return [];
  } else {
    return Object.entries(groupedPossitionsByMarket).map(
      ([idRazdelGroup, value]) => {
        const razdel = value[0].subAccountRazdel;
        const nameBalanceGroup =
          (isUnifiedMarket ? 'ЕФР' : BalanceGroupNames[idRazdelGroup]) || 'N/A';

        const NKDBalance = calculateNKDBalance(value);
        const balance = calculateBalance(value, NKDBalance);
        const lastUsd = getCurrencyPrice(
          currenciesQuotes,
          currenciesFinInfoExts,
          ECurrencyId.USD
        );
        const lastEur = getCurrencyPrice(
          currenciesQuotes,
          currenciesFinInfoExts,
          ECurrencyId.EUR
        );
        let balanceUsd: number | null = null;
        let balanceEur: number | null = null;

        if (balance !== null) {
          balanceUsd = lastUsd ? balance / lastUsd : 0;
          balanceEur = lastEur ? balance / lastEur : 0;
        }

        const prevBalance = calculatePrevBalance(value);
        let dailyPLUsd: number | null = null;
        let dailyPLEur: number | null = null;
        // Сумма Прибылей/убытков за текущую сессию по всем позициям в портфеле в рублевом эквиваленте (ПУ(дн))
        const dailyPLRur = value.reduce<number | null>((acc, pos) => {
          if (acc === null || pos.dailyPLRur === null) {
            return null;
          }

          return acc + pos.dailyPLRur;
        }, 0);
        // Прибыль (дн) портфеля, выраженная в процентном отношении к суммарной входящей стоимости всех позиций в портфеле (ПУ(дн)%)
        let dailyPLRurPrecent: number | null = null;

        if (dailyPLRur !== null) {
          dailyPLUsd = lastUsd ? dailyPLRur / lastUsd : 0;
          dailyPLEur = lastEur ? dailyPLRur / lastEur : 0;

          dailyPLRurPrecent = Math.abs(prevBalance)
            ? (100 * dailyPLRur) / Math.abs(prevBalance)
            : 0;
        }

        // Сумма Прибылей/убытков  по всем позициям в портфеле в рублевом эквиваленте (НПУ)
        const NPLRur = calculateNPLRur(value);

        const NPLUsd = lastUsd ? NPLRur / lastUsd : 0;
        const NPLEur = lastEur ? NPLRur / lastEur : 0;

        const torgPosUchCostRur = Math.abs(
          sum(value.map((pos) => pos.torgPosUchCostRur))
        );
        // Прибыль/убыток по портфелю в процентах (НПУ%)
        const NPLRurPercent = Math.abs(torgPosUchCostRur)
          ? (100 * NPLRur) / Math.abs(torgPosUchCostRur)
          : 0;

        const longBalance = calculateLongBalance(value);
        const shortBalance = calculateShortBalance(value);

        const initialMargin = calculateInitialMargin(
          Number(idRazdelGroup),
          subGTAccounts,
          razdel?.idSubAccount,
          isUnifiedMarket
        );
        const minimumMargin = calculateMinimumMargin(
          Number(idRazdelGroup),
          subGTAccounts,
          razdel?.idSubAccount,
          isUnifiedMarket
        );

        const initialMarginWithOrders = initialMargin;

        //Рублевые позиции
        const rubPositions = value.filter(
          ({ position }) => position?.idObject === RUB_ID
        );
        const money = sumBy(rubPositions, 'money');
        const initialMoney = sumBy(rubPositions, 'moneyInitial');

        const liquedBalance = calculateLiquedBalance(
          Number(idRazdelGroup),
          subGTAccounts,
          value,
          razdel?.idSubAccount,
          isUnifiedMarket
        );

        const requirements = calculateRequirements(
          initialMargin,
          liquedBalance
        );

        const immediateRequirements = calculateImmediateRequirements(
          minimumMargin,
          liquedBalance
        );

        /** суммируем базы для расчета ПУ(дн) для использования в формуле ПУ(дн) по разделам */
        const baseDailyPLtoMarketCurPrice = sum(
          value.map((pos) => pos.baseDailyPLtoMarketCurPrice || 0)
        );

        const portfolioValueWithOrders = liquedBalance;

        return {
          balance: formatNumberOrNull(balance, 2),
          liquedBalance: formatNumberOrNull(liquedBalance, 2),
          balanceUsd: formatNumberOrNull(balanceUsd, 2),
          balanceEur: formatNumberOrNull(balanceEur, 2),
          prevBalance: formatNumber(prevBalance, 2),
          dailyPLRur: formatNumberOrNull(dailyPLRur, 2),
          dailyPLUsd: formatNumberOrNull(dailyPLUsd, 2),
          dailyPLEur: formatNumberOrNull(dailyPLEur, 2),
          dailyPLRurPrecent: formatNumberOrNull(dailyPLRurPrecent, 2),
          NPLRur: formatNumber(NPLRur, 2),
          NPLUsd: formatNumber(NPLUsd, 2),
          NPLEur: formatNumber(NPLEur, 2),
          NPLRurPercent: formatNumber(NPLRurPercent, 2),
          longBalance: formatNumber(longBalance, 2),
          shortBalance: formatNumber(shortBalance, 2),
          money: formatNumber(money, 2),
          initialMoney: formatNumber(initialMoney, 2),
          initialMargin: formatNumber(initialMargin, 2),
          minimumMargin: formatNumber(minimumMargin, 2),
          initialMarginWithOrders: formatNumber(initialMarginWithOrders, 2),
          requirements: formatNumberOrNull(requirements, 2),
          immediateRequirements: formatNumberOrNull(immediateRequirements, 2),
          portfolioValueWithOrders: formatNumberOrNull(
            portfolioValueWithOrders,
            2
          ),
          nameBalanceGroup,
          idRazdelGroup: Number(idRazdelGroup),
          //При фильтре по субсчетам идет группировка по маркетам, айди аккаунта может быть разный - не показываем
          idAccount: '',
          torgPosUchCostRur: torgPosUchCostRur,
          baseDailyPLtoMarketCurPrice: baseDailyPLtoMarketCurPrice,
        };
      }
    );
  }
};
