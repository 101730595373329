import { EducationStrategyDTO } from '../api/types/EducationStrategyDTO';
import { EducationStrategy } from '../model/types/EducationStrategy';

export function mapEducationStrategy(
  dto: EducationStrategyDTO
): EducationStrategy {
  return {
    id: dto.id,
    name: dto.name,
    profitability: dto.profitability || 0,
    complexity: dto.complexity || 1,
  };
}
