import { Form, Formik, FormikProps } from 'formik';
import noop from 'lodash/noop';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ButtonMobile } from '@alfalab/core-components/button/mobile';
import { IconButton } from '@alfalab/core-components/icon-button';
import { ChevronBackMIcon } from '@alfalab/icons-glyph/ChevronBackMIcon';
import { ToolsMIcon } from '@alfalab/icons-glyph/ToolsMIcon';

import { ActivitySpinner } from '@terminal/common/components/ActivitySpinner';
import {
  AuthorizationStatus,
  AuthorizationStatusRequest,
} from '@terminal/core/lib/rest/investApi';
import {
  isPassportPath,
  PhoneLoginState,
  updateAuthPhoneLoginState,
} from '@terminal/lk-core';

import {
  formatPhone,
  formatSurname,
  getLKPasswrodResetUrl,
  getPassportUrl,
  validateFullNameForm,
  validatePhoneForm,
} from '../../entities/Login';
import { BrokerageOpeningModal } from '../../widgets/BrokerageOpeningModal';
import { OPEN_ACCOUNT } from '../const/externalUrls';
import { FullName, WarningMessage } from './components/Login';
import { NavigationBar } from './components/NavigationBar';
import { NetworkErrorModal } from './components/NetworkErrorModal';
import { FormContainer } from './FormContainer';
import { Login as BaseLogin, LoginMethod } from './Login';
import { LoginWithPhone } from './LoginWithPhone';

import { usePhoneLogin } from '../hooks/usePhoneLogin';

import styles from './LoginByPhone.module.css';

const fullNameInitialValues = {
  firstName: '',
  lastName: '',
  surName: '',
  phone: '',
};

const phoneInitialValues = {
  phone: '',
};

interface LoginByPhoneProps {
  appLogo: JSX.Element | null;
  copyright: string;
}

export const LoginByPhone = ({ appLogo, copyright }: LoginByPhoneProps) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isOldVersion, setIsOldVersion] = useState(false);
  const { state = {} }: { state?: { redirect?: string; search?: string } } =
    location;
  const redirect = state?.redirect || searchParams.get('redirect') || '/';
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isBrokerageModalOpen, setBrokerageModalOpen] = useState(false);
  const [isNetworkErrorModalOpen, setNetworkErrorOpen] = useState(false);

  const [screenState, setScreenState] = useState<AuthorizationStatus | null>(
    null
  );
  const formikRef = useRef<FormikProps<typeof fullNameInitialValues>>(null);
  const validationResultRef = useRef(Promise.resolve({}));

  const [requestData, setRequestData] = useState<AuthorizationStatusRequest>({
    phone: '',
  });

  const handlePhoneChange = (value: string) => {
    setPhoneNumber(formatPhone(value));
  };

  const { isFetching, data, refetch } = usePhoneLogin(requestData, () => {
    setNetworkErrorOpen(true);
  });

  // TODO: костыль, надо отрефакторить
  useEffect(() => {
    if (data?.status === 'OPEN_BROKERAGE_ACC_WAITING') {
      setBrokerageModalOpen(true);
    }

    if (data?.status) {
      setScreenState(data.status);
    }

    isPassportPath(location)
      ? updateAuthPhoneLoginState('PASSPORT')
      : updateAuthPhoneLoginState(data?.status || '');
  }, [data, location, isFetching]);

  if (isOldVersion) {
    return <BaseLogin appLogo={appLogo} copyright={copyright} />;
  }

  const handleSubmitPhone = (data: string) => {
    setRequestData({
      phone: formatPhone(data),
    });
  };

  const handleSubmitFullName = (
    data: Omit<AuthorizationStatusRequest, 'phone'>
  ) => {
    setRequestData({
      phone: phoneNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      surName: data.surName ? formatSurname(data.surName) : '',
    });

    refetch();
  };

  if (data) {
    switch (data.status) {
      case 'OPEN_BROKERAGE_ACC': {
        window.location.href = OPEN_ACCOUNT;

        return <ActivitySpinner />;
      }
      case 'OPEN_BROKERAGE_ACC_NATIVE': {
        window.location.href = OPEN_ACCOUNT;

        return <ActivitySpinner />;
      }
      case 'AUTHORIZATION_NATIVE': {
        window.location.href = `${getPassportUrl(redirect)}&login=${
          data.login
        }`;

        return <ActivitySpinner />;
      }
      case 'PASSWORD_RECOVERY': {
        window.location.href = getLKPasswrodResetUrl(phoneNumber, redirect);

        return <ActivitySpinner />;
      }
      case 'AUTHORIZATION_PASSPORT': {
        window.location.href = getPassportUrl(redirect);

        return <ActivitySpinner />;
      }
      case 'FINAL_STATUS': {
        return (
          <WarningMessage
            icon={<ToolsMIcon color="#8D8D93" />}
            title="Не можем вас найти"
            subtitle={
              <>
                К сожалению, не смогли вас найти в базе наших клиентов.
                Попробуйте другой способ авторизации.
              </>
            }
          />
        );
      }
      case 'FATAL_ERROR': {
        return (
          <WarningMessage
            icon={<ToolsMIcon color="#8D8D93" />}
            title="Не получилось загрузить"
            subtitle={
              <>
                Уже знаем, в чём дело, и чиним. Попробуйте обновить экран или
                зайти позже
              </>
            }
          />
        );
      }
    }
  }

  if (isPassportPath(location)) {
    return (
      <FormContainer appLogo={appLogo} copyright={copyright}>
        <LoginMethod redirect={redirect} />
      </FormContainer>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.navigationContainer}>
        <NavigationBar
          leftButton={
            data?.status === 'NAME_NEEDED' &&
            screenState === 'NAME_NEEDED' && (
              <IconButton
                icon={ChevronBackMIcon}
                size="s"
                onClick={() => {
                  setScreenState(null);

                  if (formikRef.current) {
                    formikRef.current.resetForm();
                  }
                }}
              />
            )
          }
        />
      </div>
      {data?.status === 'NAME_NEEDED' && screenState === 'NAME_NEEDED' ? (
        <Formik
          innerRef={formikRef}
          initialValues={fullNameInitialValues}
          onSubmit={noop}
          validate={(values) => {
            const result = validateFullNameForm(values);

            validationResultRef.current = validationResultRef.current.then(
              () => result
            );

            return validationResultRef.current;
          }}
        >
          <Form className={styles.form}>
            <FullName
              renderControls={({ hasValidationErrors, values }) => {
                return (
                  <div className={styles.controlls}>
                    <ButtonMobile
                      size="m"
                      colors="default"
                      view="primary"
                      disabled={hasValidationErrors}
                      onClick={() => {
                        handleSubmitFullName(values);
                      }}
                      block
                    >
                      Продолжить
                    </ButtonMobile>
                  </div>
                );
              }}
            />
          </Form>
        </Formik>
      ) : (
        <Formik
          initialValues={phoneInitialValues}
          onSubmit={noop}
          validate={(values) => {
            const formattedData = {
              phone: formatPhone(values.phone),
            };
            const result = validatePhoneForm(formattedData);

            validationResultRef.current = validationResultRef.current.then(
              () => result
            );

            return validationResultRef.current;
          }}
        >
          <Form className={styles.form}>
            <LoginWithPhone
              onPhoneChange={handlePhoneChange}
              renderControls={({ hasValidationErrors }) => {
                return (
                  <div className={styles.controlls}>
                    <ButtonMobile
                      size="m"
                      colors="default"
                      view="primary"
                      disabled={hasValidationErrors}
                      loading={isFetching}
                      onClick={() => {
                        handleSubmitPhone(phoneNumber);
                      }}
                      block
                    >
                      Войти
                    </ButtonMobile>
                    <ButtonMobile
                      disabled={isFetching}
                      size="xs"
                      view="link"
                      onClick={() => {
                        setIsOldVersion(true);
                        updateAuthPhoneLoginState(PhoneLoginState.BASE_LOGIN);
                      }}
                      block
                    >
                      Войти по-другому
                    </ButtonMobile>
                  </div>
                );
              }}
            />
          </Form>
        </Formik>
      )}
      <BrokerageOpeningModal
        open={isBrokerageModalOpen}
        onClose={() => {
          setBrokerageModalOpen(false);
        }}
      />
      <NetworkErrorModal
        open={isNetworkErrorModalOpen}
        onClose={() => {
          setNetworkErrorOpen(false);
        }}
        header={
          <div className={styles.navigationContainer}>
            <NavigationBar
              leftButton={
                <IconButton
                  icon={ChevronBackMIcon}
                  size="s"
                  onClick={() => {
                    setNetworkErrorOpen(false);
                  }}
                />
              }
            />
          </div>
        }
      />
    </div>
  );
};
