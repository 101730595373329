export const reliableBonds = ['RU000A0ZYU88', 'RU000A1007F4', 'RU000A0JXQF2'];

export const oilAndGasStocks = ['RU000A0J2Q06', 'RU0009033591', 'RU0009024277'];

export const taxFreeIncome = [
  'RU000A105EX7',
  'RU000A0JNAB6',
  'RU000A100GC7',
  'RU000A1052T1',
  'RU000A106A86',
  'RU000A101XD8',
  'RU000A102GY7',
  'RU000A102DK3',
  'RU000A106HB4',
  'RU000A104WS2',
  'RU000A102SG9',
];

export const currencyIncomeBonds = [
  'RU000A106G31',
  'RU000A105RG4',
  'RU000A1054W1',
  'RU000A1060Q0',
];

export const clientChoice = ['RU000A102E78', 'RU000A101PN3', 'RU000A1045N8'];
