import { AccountOptions, ClientAccountEntity } from '../lib/client/entities';

import { AccountItem } from '../types/account';

export const accountMap = (entity: ClientAccountEntity): AccountItem => ({
  idAccount: entity.IdAccount,
  margKoef: entity.MargKoef,
  idTarif: entity.IdTarif,
  taxRate: entity.TaxRate,
  backTaxSaldo: entity.BackTaxSaldo,
  idScheme: entity.IdScheme,
  backDate: entity.BackDate,
  version: entity.Version.toString(),
  operation: entity.Operation,
  qLevel: entity.QLevel,
  iIAType: entity.IIAType,
  mICEX_FeeRate: entity.MICEX_FeeRate,
  sELT_FeeRate: entity.SELT_FeeRate,
  sPBEX_RPSFeeRate: entity.SPBEX_RPSFeeRate,
  sPBEX_FeeRate: entity.SPBEX_FeeRate,
  sPBEX_OneCentFeeFlag: entity.SPBEX_OneCentFeeFlag,
  oTC_FeeRate: entity.OTC_FeeRate,
  eFR: entity.EFR,
  microInvest: entity.MicroInvest,
  isSingleFORTSMarket: Boolean(
    Number(entity.Options) & AccountOptions.FortsInEFR
  ),
  isUnderage: Boolean(Number(entity.Options) & AccountOptions.Underage),
});
