import React, { FC } from 'react';

import { useStore } from '@terminal/core/store';
import { trackPriceListModalStateSelector } from '@terminal/core/store/selectors/trackPrice/trackPriceListModalStateSelector';
import { trackPricesSelector } from '@terminal/core/store/selectors/trackPrice/trackPricesSelector';

import { Modal } from '../../../legacy/components/Modal';
import { Empty } from './Empty';
import { ListTable } from './ListTable';
import { useModalActionButtons } from './useModalActionButtons';

import styles from './AlertsListModal.module.css';

export const AlertsListModal: FC = () => {
  const { isOpen, close } = useStore(trackPriceListModalStateSelector);
  const trackPrices = useStore(trackPricesSelector);
  const isEmpty = trackPrices.length === 0;
  const modalActionButtons = useModalActionButtons(isEmpty);

  return (
    <Modal
      open={isOpen}
      onClose={close}
      title="Таблица алертов"
      className={styles.root}
      isRawContent
      {...modalActionButtons}
    >
      {isEmpty && <Empty />}
      {!isEmpty && <ListTable />}
    </Modal>
  );
};
