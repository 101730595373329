import {
  OVER_WITHELD_TAX_REFUND_TEXT_BROKER,
  OVER_WITHELD_TAX_REFUND_TEXT_REQUISITES,
  OVER_WITHELD_TAX_REFUND_THEME_ID,
  TERMINATION_TAX_REFUND_TEXT,
  TERMINATION_TAX_REFUND_THEME_ID,
} from '../../constants';

export const enum REFUND_TYPE {
  BROKER = 'BROKER',
  REQUISITES = 'REQUISITES',
}

export function getStaticText(themeId: number, treaty: number) {
  if (themeId === OVER_WITHELD_TAX_REFUND_THEME_ID) {
    return 'Прошу вернуть излишне удержанный налог';
  }

  if (themeId === TERMINATION_TAX_REFUND_THEME_ID) {
    return `В связи с расторжением брокерского договора ГС${treaty} прошу вернуть
    излишне удержанный налог`;
  }

  return '';
}

export function getTemplate(themeId: number, type: REFUND_TYPE) {
  if (themeId === TERMINATION_TAX_REFUND_THEME_ID) {
    return TERMINATION_TAX_REFUND_TEXT;
  }

  if (themeId === OVER_WITHELD_TAX_REFUND_THEME_ID) {
    if (type === REFUND_TYPE.BROKER) {
      return OVER_WITHELD_TAX_REFUND_TEXT_BROKER;
    } else if (type === REFUND_TYPE.REQUISITES) {
      return OVER_WITHELD_TAX_REFUND_TEXT_REQUISITES;
    }
  }

  return '';
}
