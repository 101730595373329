import cn from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import styles from './Cell.module.css';

export type CellProps = HTMLAttributes<HTMLDivElement> & {
  span?: boolean;
  justify?: 'start' | 'end' | 'center';
};

export const Cell: FC<CellProps> = (props) => {
  const { className, span = false, justify, children, ...restProps } = props;
  const rootClassName = cn(className, styles.root, {
    [styles.root_span]: span,
    [styles.root_justify_start]: justify === 'start',
    [styles.root_justify_end]: justify === 'end',
    [styles.root_justify_center]: justify === 'center',
  });

  return (
    <div className={rootClassName} {...restProps}>
      {children}
    </div>
  );
};
