export enum IntervalIds {
  YESTERDAY = 'yesterday',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  CUSTOM = 'custom',
}

export const INTERVALS = [
  {
    id: IntervalIds.YESTERDAY,
    title: 'Вчера',
  },
  {
    id: IntervalIds.WEEK,
    title: 'Неделя',
  },
  {
    id: IntervalIds.MONTH,
    title: 'Месяц',
  },
  {
    id: IntervalIds.QUARTER,
    title: 'Квартал',
  },
  {
    id: IntervalIds.YEAR,
    title: 'Год',
  },
  {
    id: IntervalIds.CUSTOM,
    title: 'Выбрать период',
  },
];
