import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';

import { Result } from '../../../../shared/ui/Result';

import styles from './MoneyRequisitesError.module.css';

interface MoneyRequisitesErrorProps {
  supportPhone?: string;
  supportLandlinePhone?: string;
  onAction?: () => void;
}

export const MoneyRequisitesError = ({
  supportPhone,
  supportLandlinePhone,
  onAction,
}: MoneyRequisitesErrorProps) => {
  const phones = (() => {
    if (supportPhone && supportLandlinePhone) {
      return (
        <>
          Обратитесь в поддержку по одному из телефонов:
          <br />
          {supportPhone} для России, {supportLandlinePhone} - для Москвы и МО.
        </>
      );
    }

    if (supportPhone || supportLandlinePhone) {
      return (
        <>
          Обратитесь в поддержку по телефону{' '}
          {supportPhone || supportLandlinePhone}
        </>
      );
    }

    return <>Обратитесь в поддержку</>;
  })();

  return (
    <Result title="Реквизиты не определены" icon={<ExclamationMIcon />}>
      <Typography.Text
        view="secondary-small"
        color="secondary"
        tag="p"
        defaultMargins={false}
      >
        {phones}
      </Typography.Text>
      <div className={styles.submit}>
        <Button block size="s" onClick={onAction}>
          Вернуться назад
        </Button>
      </div>
    </Result>
  );
};
