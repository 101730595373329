import { ProgressBar } from '@alfalab/core-components/progress-bar';
import { Typography } from '@alfalab/core-components/typography';

import styles from './StepProgress.module.css';

interface StepProgressProps {
  percentage: number;
  title: string;
}

export const StepProgress = ({ percentage, title }: StepProgressProps) => {
  return (
    <div>
      <Typography.Text
        view="secondary-small"
        color="secondary"
        tag="p"
        defaultMargins={false}
        className={styles.title}
      >
        {title}
      </Typography.Text>
      <ProgressBar value={percentage} view="accent" />
    </div>
  );
};
