import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { useFullFI } from '@terminal/alfadirect/hooks';
import {
  TradingLimitsMarkdownOptions,
  useTradingLimitsMarkdown as useTradingLimitsMarkdownBase,
} from '@terminal/alfadirect/hooks';
import { isSingleFORTSMarketAvailable } from '@terminal/core/lib/domain/isSingleFORTSMarketAvailable';
import { InstrumentItem } from '@terminal/core/types/core';
import { TradeLimits } from '@terminal/core/types/tradeLimits';

import { useWidgetContext } from '../../../shared';

type Options = Omit<
  TradingLimitsMarkdownOptions,
  | 'orderDimension'
  | 'lot'
  | 'currencyCode'
  | 'idObjectGroup'
  | 'isSingleFORTSMarket'
  | 'formatLong'
  | 'formatShort'
>;

export function useTradingLimitsMarkdown(
  idFi: InstrumentItem['idFI'],
  tradeLimits: TradeLimits,
  options: Options
) {
  const { useSettings } = useWidgetContext();
  const { useFinInfoExt, accounts } = useAlfaDirectContext();

  const { defaultValues } = useSettings();
  const { currencyCode, idObjectGroup } = useFullFI(idFi) || {};
  const finInfoExt = useFinInfoExt(idFi);
  const finInfoExtItem = finInfoExt[idFi];

  return useTradingLimitsMarkdownBase(tradeLimits, {
    orderDimension: defaultValues.orderDimension,
    lot: finInfoExtItem?.lot,
    currencyCode,
    idObjectGroup,
    isSingleFORTSMarket: isSingleFORTSMarketAvailable(accounts),
    formatLong: (amount, ownAmount) =>
      ownAmount
        ? `&nbsp;В&nbsp;лонг&nbsp;${ownAmount} (${amount})`
        : `&nbsp;В&nbsp;лонг&nbsp;${amount}`,
    formatShort: (amount) => `&nbsp;В&nbsp;шорт&nbsp;${amount}`,
    showForFutures: true,
    ...options,
  });
}
