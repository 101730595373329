import isEqual from 'lodash/isEqual';
import unset from 'lodash/unset';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { Stepper } from '@terminal/common/components/Stepper';
import { ToggleOff, ToggleOn } from '@terminal/core/constants/icons';
import { getAssetUnits } from '@terminal/core/lib/format';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { useWidgetContext } from '../../../../../../shared';
import { useOrderBookContext } from '../../../../context';

import { useIsLotCount } from '../../../../../../shared/hooks/useIsLotCount';

// import { Stepper } from '../../../components/Stepper';
import styles from './DefaultQuantitySettings.module.css';

interface Props extends BaseModalProps {
  idFi: number;
  nodeId?: string;
  onClose: () => void;
  finInfoExt?: FinInfoExt;
}

export const DefaultQuantitySettings = ({
  idFi,
  nodeId,
  onClose,
  finInfoExt,
  ...baseModalProps
}: Props) => {
  const { useSettings, setSetting } = useWidgetContext();

  const { defaultQuantity } = useOrderBookContext();

  const settings = useSettings();
  const { quantityMap, orderDimension } = settings.defaultValues;

  const isLotCount = useIsLotCount();

  const [enableQuantity, setEnableQuantity] = useState<boolean>(
    Boolean(defaultQuantity?.quantity)
  );

  const [quantity, setQuantity] = useState(
    defaultQuantity?.quantity ?? finInfoExt?.lot ?? 0
  );

  useEffect(() => {
    if (finInfoExt) {
      if (isLotCount) {
        setQuantity(defaultQuantity?.quantity ?? 1);
      } else {
        setQuantity(defaultQuantity?.quantity ?? finInfoExt.lot);
      }
    }
  }, [defaultQuantity, finInfoExt, idFi, isLotCount]);

  const isQuantityNotMultipleOfALot = useMemo(
    () =>
      !isLotCount &&
      Boolean(finInfoExt) &&
      quantity % (finInfoExt?.lot ?? 0) !== 0,
    [finInfoExt, isLotCount, quantity]
  );

  const getQuantity = useCallback(
    () => (isLotCount ? (finInfoExt?.lot || 0) * quantity : quantity),
    [finInfoExt?.lot, isLotCount, quantity]
  );

  const saveSettings = useCallback(() => {
    let newQuantityMap = { ...quantityMap };

    if (enableQuantity) {
      setSetting('defaultValues', 'quantityMap', {
        ...newQuantityMap,
        [idFi]: getQuantity(),
      });
    } else {
      // unset мутирует объект и возвращает boolean
      unset(newQuantityMap, idFi);

      setSetting('defaultValues', 'quantityMap', newQuantityMap);
    }

    onClose();
  }, [enableQuantity, getQuantity, idFi, onClose, quantityMap, setSetting]);

  const isSaveDisabled = useMemo(() => {
    const prevQuantity = defaultQuantity?.quantity ?? finInfoExt?.lot ?? 0;

    return (
      (isEqual(prevQuantity, getQuantity()) && enableQuantity) ||
      isQuantityNotMultipleOfALot
    );
  }, [
    defaultQuantity?.quantity,
    enableQuantity,
    finInfoExt?.lot,
    getQuantity,
    isQuantityNotMultipleOfALot,
  ]);

  return (
    <BaseModal
      {...baseModalProps}
      className={styles.contextMenuSettings}
      onClose={onClose}
    >
      <div className={styles.settingsHeader}>
        <Typography.Text view="secondary-large" weight="bold">
          Объем по умолчанию
        </Typography.Text>
        <IconButton
          size="xs"
          view="secondary"
          icon={CrossMIcon}
          onClick={onClose}
        />
      </div>
      <div className={styles.settingsContent}>
        <div
          onClick={() => setEnableQuantity((prev) => !prev)}
          className={styles.settingWrapper}
        >
          <Typography.Text view="secondary-large" weight="medium">
            Подставлять указанный обьем
          </Typography.Text>
          {enableQuantity ? <ToggleOn /> : <ToggleOff />}
        </div>
        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={styles.label}
        >
          Объем
        </Typography.Text>
        <AmountInput
          //Проверяем кратность лоту
          className={styles.customInput}
          fieldClassName={styles.customInputField}
          error={isQuantityNotMultipleOfALot ? <>Не кратно лоту</> : undefined}
          block
          suffix={getAssetUnits(quantity, orderDimension)}
          value={quantity}
          minority={1}
          integersOnly
          onChange={(e, { value }) => {
            setQuantity(value || 0);
          }}
          onBlur={(e) => {
            if (finInfoExt && !isLotCount && quantity < finInfoExt?.lot) {
              setQuantity(finInfoExt.lot);
            }
          }}
          rightAddons={
            <Stepper
              onAdd={() => {
                if (isLotCount) {
                  setQuantity((prevQuantity) => ++prevQuantity);
                } else if (finInfoExt) {
                  setQuantity(quantity + finInfoExt.lot);
                }
              }}
              onSub={() => {
                if (isLotCount) {
                  setQuantity((prevQuantity) =>
                    prevQuantity > 1 ? --prevQuantity : prevQuantity
                  );
                } else if (finInfoExt) {
                  setQuantity(
                    quantity > finInfoExt.lot
                      ? quantity - finInfoExt.lot
                      : finInfoExt.lot
                  );
                }
              }}
            />
          }
        />
        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={styles.hintText}
        >
          Выбранный объем будет автоматически подставляться в торговую форму
          и запоминаться для выбранного тикера
        </Typography.Text>
        <div className={styles.buttonToolbar}>
          <Button
            className={styles.customButton}
            view="ghost"
            size="xxs"
            onClick={() => onClose()}
          >
            Отмена
          </Button>
          <Button
            className={styles.customButton}
            size="xxs"
            view="secondary"
            disabled={isSaveDisabled}
            onClick={() => saveSettings()}
          >
            Применить
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
