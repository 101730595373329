import isUndefined from 'lodash/isUndefined';
import sumBy from 'lodash/sumBy';

import { ObjectGroup, RazdelGroupType } from '../client/entities';

import { PositionItem } from '../../types/position';
import { SubGTAccountItem } from '../../types/subAccount';

/**
 * @returns Сумма стоимостей всех положительных позиций(лонгов) по ликвидным активам с учетом НКД
 * и стоимостей всех отрицательных позиций(шортов) в рублевом эквиваленте (ЛиквСтоимость)
 * */
export function calculateLiquedBalance(
  idRazdelGroup: RazdelGroupType,
  subGTAccounts: SubGTAccountItem[],
  positions: PositionItem[],
  IdSubAccount?: number,
  isUnifiedMarket = false
) {
  if (isUndefined(IdSubAccount)) {
    return 0;
  }

  const subGTAccountItem = subGTAccounts.find(
    (gtAccount) =>
      gtAccount.idSubAccount === IdSubAccount &&
      gtAccount.idRazdelGroup === Number(idRazdelGroup)
  );
  const portfolioCost = subGTAccountItem?.portfolioCost ?? 0;

  if (isUnifiedMarket) {
    return portfolioCost;
  }

  switch (idRazdelGroup) {
    case RazdelGroupType.DerivativesMarket:
      return (
        sumBy(
          positions.filter(
            (position) =>
              position.objectType?.idObjectGroup === ObjectGroup.Futures
          ),
          'dailyPLtoMarketCurPrice'
        ) +
        sumBy(
          positions.filter(
            (position) =>
              position.objectType?.idObjectGroup === ObjectGroup.Currency &&
              position.torgPos > 0
          ),
          'torgPosCostRur'
        )
      );
    case RazdelGroupType.CurrencyMarket:
    case RazdelGroupType.StocksAndBondsMarket:
    case RazdelGroupType.RDC:
      return portfolioCost;
    default:
      return 0;
  }
}
