import { useMutation } from 'react-query';

import { LKResult } from '@terminal/core/lib/rest/lkApi';
import {
  BankCardTransactionExecute,
  bankCardTransactionExecute,
  BankCardTransactionExecuteParams,
} from '@terminal/core/lib/rest/lkBankCard';

export const useCardTransactionExecute = () => {
  const mutation = useMutation<
    BankCardTransactionExecute | LKResult,
    Error,
    BankCardTransactionExecuteParams
  >(bankCardTransactionExecute);
  return mutation;
};
