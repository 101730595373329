export const CrossIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.58586 6.00009L0 10.5L1.5 12L6.00008 7.41431L10.5 12L12 10.5L7.41429 6.00009L12 1.5L10.5 0L6.00008 4.58588L1.5 0L0 1.5L4.58586 6.00009Z"
      fill="currentColor"
    />
  </svg>
);
