import React from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { CrossHeavyMIcon } from '@alfalab/icons-glyph/CrossHeavyMIcon';
import { DocumentArrowRightMIcon } from '@alfalab/icons-glyph/DocumentArrowRightMIcon';

import { useAppContext } from '@terminal/core/hooks/useAppContext';

import { ResultType } from '../../model/types/ResultType';

import styles from './AddAccountResult.module.css';

interface AddAccountRejectProps {
  onNewAccountModalClose: () => void;
  contentType: ResultType;
}

export const AddAccountResult = ({
  onNewAccountModalClose,
  contentType,
}: AddAccountRejectProps) => {
  const { supportPhone, supportLandlinePhone } = useAppContext();

  const resultContent: Record<
    ResultType,
    { icon: React.ReactNode; content: React.ReactNode }
  > = {
    [ResultType.Rejected]: {
      icon: <CrossHeavyMIcon />,
      content: (
        <>
          <Typography.Text
            view="primary-medium"
            weight="bold"
            className={styles.title}
          >
            У вас открыто максимальное количество счетов
          </Typography.Text>
          <ButtonDesktop size="s" onClick={onNewAccountModalClose}>
            Понятно
          </ButtonDesktop>
        </>
      ),
    },
    [ResultType.Success]: {
      icon: <DocumentArrowRightMIcon />,
      content: (
        <>
          <Typography.Text
            view="primary-small"
            weight="bold"
            className={styles.title}
          >
            Заявление на открытие счёта находится в обработке
          </Typography.Text>
          <Typography.Text
            view="secondary-small"
            color="secondary"
            className={styles.description}
          >
            Ожидайте уведомления об открытии счёта в SMS сообщении
          </Typography.Text>
          <div className={styles.actions}>
            <ButtonDesktop block size="s" onClick={onNewAccountModalClose}>
              Понятно
            </ButtonDesktop>
          </div>
        </>
      ),
    },
    [ResultType.Failed]: {
      icon: <CrossHeavyMIcon />,
      content: (
        <>
          <Typography.Text
            view="primary-small"
            weight="bold"
            className={styles.title}
          >
            Мы не смогли открыть вам новый счёт
          </Typography.Text>
          <Typography.Text
            view="secondary-small"
            color="secondary"
            className={styles.description}
          >
            По вопросам звоните:
            <br />
            {supportPhone} (бесплатно по России)
            {supportLandlinePhone ? (
              <>
                <br />
                {supportLandlinePhone} (для Москвы и Московской области)
              </>
            ) : null}
          </Typography.Text>
          <div className={styles.actions}>
            <ButtonDesktop block size="s" onClick={onNewAccountModalClose}>
              Понятно
            </ButtonDesktop>
          </div>
        </>
      ),
    },
  };

  return (
    <div className={styles.container}>
      <SuperEllipse className={styles.icon}>
        {resultContent[contentType].icon}
      </SuperEllipse>
      {resultContent[contentType].content}
    </div>
  );
};
