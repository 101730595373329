import log from 'loglevel';

type DataKeyType = number | string;

type DataRelationType = 'one' | 'many';

type DataTableColumnsMap<T> = {
  [key in OnlyStringKeys<T>]?: DataRelationType;
};

export type DataTableMutation<T, K extends string = string> =
  | Record<K, (item: T) => boolean>
  | undefined;

type DataTableColumnDefinition<T> = {
  key: T;
  relation: DataRelationType;
};

type OnlyStringKeys<T> = Extract<keyof T, string>;

type Result<
  K extends keyof DataTableColumnsMap<T>,
  M extends DataTableColumnsMap<T>,
  T
> = M[K] extends 'one' ? T : M[K] extends 'many' ? T[] : never;

export class DataTable<
  T,
  M extends DataTableColumnsMap<T>,
  K extends string = never
> {
  private columns: DataTableColumnDefinition<OnlyStringKeys<T>>[];
  private columnsMap: DataTableColumnsMap<T>;
  private initialState: T[];
  private state: Record<keyof M, Map<DataKeyType, T | T[]>>;
  private tableName: string;
  private mutations: DataTableMutation<T, string>;
  private mutatedTables: Record<string, DataTable<T, M>> = {};
  private readonly emptyColumn = new Map();

  constructor(
    columns: DataTableColumnDefinition<OnlyStringKeys<T>>[],
    tableName?: string,
    mutations?: DataTableMutation<T, K>
  ) {
    this.columns = columns;
    this.state = {} as Record<keyof M, Map<DataKeyType, T | T[]>>;

    this.columnsMap = columns.reduce<DataTableColumnsMap<T>>((acc, next) => {
      acc[next.key] = next.relation;

      return acc;
    }, {});
    this.mutations = mutations;
    this.tableName = tableName ?? `${columns[0]?.key ?? ''}Table`;
  }

  public uploadData(items: T[]): void {
    this.initialState = items;
    this.columns.forEach((column) => {
      this.state[column.key] = new Map();
    });

    items.forEach((item) => {
      this.columns.forEach((column) => {
        if (item[column.key]) {
          let columnMap = this.state[column.key];
          const valueKey = item[column.key] as DataKeyType;

          if (column.relation === 'many') {
            if (columnMap.has(valueKey)) {
              (columnMap.get(valueKey) as T[]).push(item);
            } else {
              columnMap.set(valueKey, [item]);
            }
          } else {
            columnMap.set(valueKey, item);
          }
        }
      });
    });

    if (this.mutations) {
      Object.entries<(item: T) => boolean>(this.mutations).forEach(
        ([mutationName, mutationFunc]) => {
          const mutatedItems = items.filter((item) => mutationFunc(item));

          this.mutatedTables[mutationName] = new DataTable<T, M, K>(
            this.columns,
            mutationName
          );
          this.mutatedTables[mutationName].uploadData(mutatedItems);
        }
      );
    }

    log.debug(this.state, this.tableName);
  }

  public get<K extends keyof DataTableColumnsMap<T>>(
    columnName: K,
    key: T[K]
  ): Result<K, M, T> {
    const relation = this.columnsMap[columnName];
    const column = this.state[columnName];
    const value = column?.get(key as DataKeyType);

    if (relation === 'one' || relation === 'many') {
      return value as Result<K, M, T>;
    }

    throw new Error('Column not found');
  }

  public toArray(): T[] {
    return this.initialState ?? [];
  }

  public getColumn<K extends keyof DataTableColumnsMap<T>>(
    columnName: K
  ): Map<T[K] | undefined, Result<K, M, T>> {
    return (this.state[columnName] ?? this.emptyColumn) as Map<
      T[K] | undefined,
      Result<K, M, T>
    >;
  }

  public getMutation(name: K) {
    return this.mutatedTables[name] ?? new DataTable<T, M>(this.columns);
  }
}
