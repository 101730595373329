import { GetState } from 'zustand';

import { getFullFI } from '../../lib/getFullFI';
import { getIdeas, getIdeasLinks } from '../../lib/rest/directApi';
import { loadMultimarkets } from '../../lib/services/multimarkets';
import { selectFiByIsinAndCode } from '../selectors';
import { RootStore, StoreSet } from '../useStore';

import {
  Idea,
  IdeasArticle,
  IdeasLink,
  IdeasRequest,
  LoadIdeasOptions,
} from '../../types/ideas';
import { NotificationType } from '../../types/ui';

export interface IdeasSlice {
  isLoadingIdeas: boolean;
  loadIdeas: (
    params?: IdeasRequest,
    options?: LoadIdeasOptions
  ) => Promise<void>;
  loadIdeasTime: Date | null;
  ideasStatus?: 'Success' | 'Error';
  activeIdeas?: Idea[];
  closedIdeas?: Idea[];
  ideasLinks?: IdeasLink[];
  ideasLastSeem?: string;
  ideasWasLoaded?: boolean;
  setIdeasWasLoaded: () => void;
  setLastSeemIdeas: () => void;
}

const getLastTimeSeemIdeas = () => {
  if (typeof localStorage.getItem('ideasLastSeem') === 'string') {
    return JSON.parse(
      localStorage.getItem('ideasLastSeem') as string
    ) as string;
  }
};

export const createIdeasSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): IdeasSlice => ({
  isLoadingIdeas: false,
  ideasWasLoaded: false,
  loadIdeasTime: null,
  loadIdeas: async (
    params,
    { withoutLastTimeCheck = false, onlyActiveIdeas = false } = {}
  ) => {
    if (get().loadIdeasTime && !withoutLastTimeCheck) {
      return;
    }

    const { code, limit, status } = params ?? {};

    set((state) => {
      state.ideasWasLoaded = false;
      state.isLoadingIdeas = true;
    });

    try {
      const { articles } = await getIdeas({
        code,
        limit,
        status,
      });

      const activeIdeas = articles.filter(
        ({ brokerPlus: { status } }) => status === 'active'
      );
      //В этой сущности содержатся ссылки на полный текст идеи
      const { articles: ideasLinks } = await getIdeasLinks({});

      //Делаем запрос на ликвидные рынки, для дальнейшего формирования FullFI
      const isins = articles.map(({ company: { isin } }) => isin);
      const liquidMarkets = await loadMultimarkets(isins).then((result) =>
        // Map ликвидных рынков. Для каждого isin находится ликвидный universalMarketCode
        result.reduce((acc, inst) => {
          inst.exchanges.forEach((exchange) => {
            const isLiquidExchange = inst.defaultExchangeId === exchange.id;

            exchange.markets.forEach((market) => {
              const isLiquidMarket = exchange.liquidMarketId === market.id;
              const isLiquid = isLiquidExchange && isLiquidMarket;

              if (isLiquid) {
                acc.set(inst.isin, market.id);
              }
            });
          });

          return acc;
        }, new Map<string, string>())
      );

      set((state) => {
        state.activeIdeas = transformArticles(
          activeIdeas,
          liquidMarkets,
          state as RootStore
        );

        if (!onlyActiveIdeas) {
          const closedIdeas = articles.filter(
            ({ brokerPlus: { status } }) => status === 'closed'
          );

          state.closedIdeas = transformArticles(
            closedIdeas,
            liquidMarkets,
            state as RootStore
          );
        }

        state.ideasLinks = ideasLinks;
        state.ideasStatus = 'Success';
      });
    } catch (error) {
      get().addNotification({
        type: NotificationType.SYSTEM,
        id: new Date().toString(),
        badge: 'negative',
        title: 'Ошибка',
        text: 'Произошла ошибка при запросе идей',
      });
      set((state) => {
        state.ideasStatus = 'Error';
      });
    } finally {
      set((state) => {
        state.isLoadingIdeas = false;

        if (withoutLastTimeCheck) {
          state.loadIdeasTime = new Date();
        }
      });
    }
  },
  ideasLastSeem: getLastTimeSeemIdeas(),
  setLastSeemIdeas: () => {
    const now = new Date().toISOString();

    set((state) => {
      state.ideasLastSeem = now;
    });
    localStorage.setItem('ideasLastSeem', JSON.stringify(now));
  },
  setIdeasWasLoaded: () => {
    set((state) => {
      state.ideasWasLoaded = true;
    });
  },
});

const transformArticles = (
  articles: IdeasArticle[],
  liquidMarkets: Map<string, string>,
  {
    objectsTable,
    objectTypesTable,
    marketBoardsTable,
    finInstrumentsTable,
  }: RootStore
): Idea[] =>
  articles.reduce((acc, { company, ...article }) => {
    const idFi = selectFiByIsinAndCode(
      objectsTable,
      marketBoardsTable,
      finInstrumentsTable,
      company.isin,
      liquidMarkets.get(company.isin)
    );

    if (idFi) {
      const fullFi = getFullFI(
        idFi,
        objectsTable,
        objectTypesTable,
        finInstrumentsTable,
        marketBoardsTable
      );

      acc.push({
        ...article,
        ...fullFi,
        company,
        date: new Date(article.publicDate).getTime(),
      });
    }

    return acc;
  }, [] as Idea[]);
