import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

import styles from './MarginTooltip.module.css';

interface MarginTooltipProps {
  className?: string;
}

export const MarginTooltip = ({ className }: MarginTooltipProps) => {
  return (
    <TooltipDesktop
      position="right-end"
      targetClassName={className}
      contentClassName={styles.content}
      content={
        <Typography.Text view="secondary-medium">
          Вы можете быстро вывести с брокерского счета нужную сумму. В качестве
          залога можно использовать ценные бумаги, которые есть в вашем
          портфеле. <br />
          Обратите внимание, что при таком выводе образуется задолженность как
          при сделках с ценными бумагами в режиме маржинальной торговли
        </Typography.Text>
      }
    >
      <InformationCircleLineMIcon
        width={18}
        height={18}
        className={styles.glyph}
      />
    </TooltipDesktop>
  );
};
