import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { GetSelfTransferLimitDTO } from './types/GetSelfTransferLimitDTO';
import { GetSelfTransferLimitParams } from './types/GetSelfTransferLimitParams';

export const getSelfTransferLimit = async (
  data: GetSelfTransferLimitParams
): Promise<GetSelfTransferLimitDTO> => {
  const url = qs.stringifyUrl({
    url: '/api/self-transfer/limit',
    query: { ...data },
  });

  const res = await fetchLkResult<GetSelfTransferLimitDTO | LKResult>(url);

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};
