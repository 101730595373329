import React, { FC } from 'react';
import { v4 } from 'uuid';

import { useStore } from '@terminal/core/store';
import { trackPriceSettingsModalStateSelector } from '@terminal/core/store/selectors/trackPrice/trackPriceSettingsModalStateSelector';

import { Modal } from '../../../legacy/components/Modal';
import { Form, useForm } from './Form';
import { useModalActionButtons } from './useModalActionButtons';

const FORM_ID = v4();

export const AlertSettingsModal: FC = () => {
  const { initialValues, onSubmit } = useForm();
  const modalActionButtons = useModalActionButtons({
    form: FORM_ID,
  });
  const { isOpen, close } = useStore(trackPriceSettingsModalStateSelector);

  return (
    <Modal
      open={isOpen}
      onClose={close}
      title="Общие настройки"
      {...modalActionButtons}
    >
      <Form formikConfig={{ initialValues, onSubmit }} id={FORM_ID} />
    </Modal>
  );
};
