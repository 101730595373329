import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getReportsTheme } from '@terminal/core/lib/rest/lkDocuments';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';

export const useReportsTheme = (treaty: number | undefined) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.ReportsTheme, treaty],
    async () => {
      if (!treaty) {
        return;
      }

      return await getReportsTheme({ treaty });
    },
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса списка отчетов',
          text: error?.message || '',
        });
      },
    }
  );
};
