import { RootStore } from '../../useStore';
import { finInstrumentByIdSelector } from './finInstrumentByIdSelector';
import { marketBoardById } from './marketBoardById';

import { InstrumentItem, MarketBoardItem } from '../../../types/core';

/**
 * Выбирает торговую площадку по идентификатору финансового инструмента
 * */
export function marketBoardByIdFi(
  store: RootStore
): (idFi: InstrumentItem['idFI']) => MarketBoardItem | undefined {
  return (idFi) => {
    const finInstrument = finInstrumentByIdSelector(store)(idFi);

    return marketBoardById(store)(finInstrument?.idMarketBoard ?? NaN);
  };
}
