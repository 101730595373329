import { SecuritiesTranferXmlMultipleOperation } from '@terminal/core/lib/rest/lkSecurities';

export function getOperationKey(
  operation: SecuritiesTranferXmlMultipleOperation
) {
  return operation.model.header.metadata.objectId;
}

export function getDirectionName(direction: string) {
  switch (direction) {
    case 'RE': {
      return 'Зачисление';
    }
    case 'DE': {
      return 'Списание';
    }
    default: {
      return '';
    }
  }
}
