import cn from 'classnames';
import React, { FC } from 'react';

import { TrackPrice, TrackPriceState } from '@terminal/core/types/trackPrice';

import styles from './StatusCell.module.css';

export type StatusCellProps = {
  trackPrice: TrackPrice;
};

const LABEL_BY_STATUS: Record<TrackPriceState, string> = {
  [TrackPriceState.Active]: 'В ожидании',
  [TrackPriceState.Deleted]: 'Удалён',
  [TrackPriceState.Expired]: 'Просрочен',
  [TrackPriceState.Fired]: 'Сработал',
  [TrackPriceState.None]: '',
};

export const StatusCell: FC<StatusCellProps> = (props) => {
  const { trackPrice } = props;
  const {
    IdState: state,
    TargetPrice: targetPrice,
    InitialPrice: initialPrice,
  } = trackPrice;
  const label = LABEL_BY_STATUS[state];
  const isNone = state === TrackPriceState.None;
  const isActive = state === TrackPriceState.Active;
  const isExpired = state === TrackPriceState.Expired;
  const isFired = state === TrackPriceState.Fired;
  const isDeleted = state === TrackPriceState.Deleted;
  const className = cn({
    [styles.grey]: isNone || isActive || isExpired || isDeleted,
    [styles.green]: isFired && targetPrice <= initialPrice,
    [styles.red]: isFired && targetPrice > initialPrice,
  });

  return <span className={className}>{label}</span>;
};
