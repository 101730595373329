import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import { CorporateActionsFilter } from '@terminal/core/lib/rest/lkCorporateActions';

import { FilterIconButton } from '../../../../shared/ui/FilterIconButton';
import { FilterButton, setFilterProp } from '../../../components/Filter';

import styles from './MessagesFilterLine.module.css';

interface MessagesFilterLineProps {
  filter: CorporateActionsFilter;
  active: boolean;
  onFilterOpen?: () => void;
  onSetFilter?: (filter: CorporateActionsFilter) => void;
}

export const MessagesFilterLine = ({
  filter,
  active,
  onFilterOpen,
  onSetFilter,
}: MessagesFilterLineProps) => {
  const setFilter = (
    key: keyof CorporateActionsFilter,
    value: string | boolean
  ) => {
    if (onSetFilter) {
      const newFilter = setFilterProp(filter, key, value);
      onSetFilter(newFilter);
    }
  };
  const periodFormat = (): string => {
    if (!!filter.from && !!filter.to && filter.from === filter.to) {
      return filter.from;
    } else if (filter.from || filter.to) {
      return `${filter.from || '...'} – ${filter.to || '...'}`;
    } else {
      return 'Выберите период';
    }
  };

  return (
    <div className={styles.container}>
      <FilterIconButton onClick={onFilterOpen} checked={active} />

      <FilterButton
        checked={!!filter.unreadOnly}
        onClick={() => setFilter('unreadOnly', !filter.unreadOnly)}
      >
        Непрочитанные
      </FilterButton>

      <FilterButton
        checked={!!filter.from || !!filter.to}
        rightAddons={<ChevronDownMIcon width={18} height={18} />}
        onClick={onFilterOpen}
      >
        {periodFormat()}
      </FilterButton>
    </div>
  );
};
