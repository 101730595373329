import { useMutation } from 'react-query';

import { trackPriceService } from '../trackPriceService';

import useNotification from '../../../../hooks/useNotification';

import { useStore } from '../../../../store';

import { TrackPrice, TrackPriceState } from '../../../../types/trackPrice';
import { NotificationType } from '../../../../types/ui';

export const useTrackPriceDelete = () => {
  const addNotification = useNotification();
  const dismissTrackPrice = useStore((store) => store.dismissTrackPrice);

  return useMutation(
    async (trackPrice: TrackPrice) => {
      if (trackPrice.IdState === TrackPriceState.Fired) {
        dismissTrackPrice(trackPrice);
      } else {
        await trackPriceService.deleteAlert(trackPrice);
      }
    },
    {
      onError: (error: Error) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка удаления уведомления о цене',
          text: error?.message,
        });
      },
    }
  );
};
