import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { currenciesFI } from '../../constants/FIModal';
import { ObjectGroup } from '../../lib/client/entities';
import { quoteLastSelector } from '../../lib/domain/quoteSelector';
import { getCurrencyPrice } from '../../lib/formulas';

import { ECurrencyId } from '../../types/currencyId';
import { ExtendedFI } from '../../types/extendedFI';

/**
 * @typedef {Object} ReducedPrice
 * @property {number} displayedTotalPrice - Значение для отображения в компоненте Amount
 * @property {number} reducedPrice - Цена, приведенная к валюте рынка
 */

/**
 * Хук возвращает отображаемую цену для торговли с учетом позиций и приводит цену
 * к валюте рынка (только для рублей), на котором торгуется (например, для облигаций номинированных в юанях,
 * а торгуемых в рублях)
 * @return {ReducedPrice}
 */
export const usePriceToMarketCurrency = (
  fullFi: ExtendedFI | undefined,
  price: number,
  priceDecimals: number,
  nominal: number | null | undefined,
  quantity: number,
  priceStep = 1,
  priceStepCost = 0
) => {
  const { objectExtsTable, useQuotes, useFinInfoExt } = useAlfaDirectContext();
  const currenciesQuotes = useQuotes(currenciesFI, {
    throttleTimeout: 1000,
    selector: quoteLastSelector,
  });
  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);

  const getCurrency = (
    nominalCurrencyPrice: number,
    marketCurrencyPrice: number
  ) => {
    switch (true) {
      case !(
        fullFi?.idObjectCurrency !== fullFi?.idObjectFaceUnit &&
        nominalCurrencyPrice
      ):
        return 1;

      case fullFi?.idObjectCurrency === ECurrencyId.RUR:
        // если рынок рублевый, умножаем на курс валюты к рублю
        return nominalCurrencyPrice || 1;

      default:
        // иначе - на кросс курс (валюта номинала/валюта рынка)
        return nominalCurrencyPrice / (marketCurrencyPrice || 1);
    }
  };

  if (fullFi?.idObjectGroup === ObjectGroup.Futures) {
    return {
      displayedTotalPrice:
        ((price * priceStepCost) / priceStep) * quantity * priceDecimals,
      reducedPrice: (price * priceStepCost) / priceStep,
    };
  } else if (fullFi?.idObjectGroup === ObjectGroup.Bonds) {
    /** Цена валюты номинала */
    const nominalCurrencyPrice = getCurrencyPrice(
      currenciesQuotes,
      currenciesFinInfoExts,
      fullFi?.idObjectFaceUnit
    );

    /** Цена валюты ликвидного рынка */
    const marketCurrencyPrice = getCurrencyPrice(
      currenciesQuotes,
      currenciesFinInfoExts,
      fullFi?.idObjectCurrency
    );

    /** Цена валюты торгов */
    const currency = getCurrency(nominalCurrencyPrice, marketCurrencyPrice);

    const instPrice = price * currency;
    const objectExt = objectExtsTable.get('idObject', fullFi?.idObject ?? 0);
    const NKD = (objectExt?.accruedIntT0 ?? 0) * currency;

    return {
      displayedTotalPrice:
        ((instPrice * (nominal ?? 0)) / 100 + NKD) * quantity * priceDecimals,
      reducedPrice: price,
    };
  } else {
    return {
      displayedTotalPrice: price * quantity * priceDecimals,
      reducedPrice: price,
    };
  }
};
