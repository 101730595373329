import { useEffect, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import {
  InputDesktop,
  InputDesktopProps,
} from '@alfalab/core-components/input/desktop';
import { Loader } from '@alfalab/core-components/loader';
import { Space } from '@alfalab/core-components/space';
import { Textarea, TextareaProps } from '@alfalab/core-components/textarea';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { TrashCanLineMIcon } from '@alfalab/icons-glyph/TrashCanLineMIcon';

import { MoneyBankTemplate } from '@terminal/core/lib/rest/lkMoney';

import {
  isBankAccountNumberValid,
  isBicValid,
  useBankInfo,
  useBankTemplateDelete,
  useBankTemplateSave,
  validateBankAccountNumber,
  validateBic,
} from '../../../entities/TransferGoinvest';
import { FormControlSummary } from '../../../shared/ui/FormControlSummary';
import { ConfirmModal } from '../../components/ConfirmModal';

import styles from './TemplateRedact.module.css';

interface TemplateRedactProps {
  template: MoneyBankTemplate | null;
  treaty: number;
  existCount?: number;
  onSaved?: () => void;
  onTemplateRedacted?: () => void;
}

export const TemplateRedact = ({
  template,
  treaty,
  existCount,
  onSaved,
  onTemplateRedacted,
}: TemplateRedactProps) => {
  const [title, setTitle] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountNumberErrorShow, setAccountNumberErrorShow] = useState(false);
  const [bic, setBic] = useState('');
  const [bicErrorShow, setBicErrorShow] = useState(false);
  const [comments, setComments] = useState('');
  const [deleteWarning, setDeleteWarning] = useState(false);

  const {
    data: bankInfo,
    isFetching: bankInfoPending,
    remove: clearBankInfo,
  } = useBankInfo(bic);

  const { mutateAsync: moneyBankTemplateSave, isLoading: savePending } =
    useBankTemplateSave();

  const { mutateAsync: moneyBankTemplateDelete, isLoading: deletePending } =
    useBankTemplateDelete();

  useEffect(() => {
    if (template) {
      setTitle(template.title);
      setAccountNumber(template.accountNumber);
      setBic(template.bic);
      setComments(template.other?.comments || '');
    } else {
      setTitle(`Шаблон ${(existCount || 0) + 1}`);
      setAccountNumber('');
      setBic('');
      setComments('');
    }
  }, [template, existCount]);

  const titleChange: InputDesktopProps['onChange'] = (e, { value }) => {
    setTitle(value);
    onTemplateRedacted && onTemplateRedacted();
  };

  const accountNumberChange: InputDesktopProps['onChange'] = (e, { value }) => {
    setAccountNumber(value.replace(/[^0-9a-zA-Zа-яА-Я]/g, ''));
    onTemplateRedacted && onTemplateRedacted();
  };

  const bicChange: InputDesktopProps['onChange'] = (e, { value }) => {
    setBic(value.replace(/[^0-9]/g, ''));
    clearBankInfo();
    onTemplateRedacted && onTemplateRedacted();
  };

  const commentChange: TextareaProps['onChange'] = (e, { value }) => {
    setComments(value);
    onTemplateRedacted && onTemplateRedacted();
  };

  const isReadyToGo = (): boolean => {
    return (
      isBankAccountNumberValid(accountNumber) &&
      isBicValid(bic) &&
      Boolean(bankInfo)
    );
  };

  const clear = () => {
    setTitle('');
    setAccountNumber('');
    setBic('');
    setComments('');
  };

  const saveTemplate = async () => {
    const res = await moneyBankTemplateSave({
      id: template?.id,
      treaty: treaty,
      title,
      accountNumber,
      bic,
      comments,
    });

    if (res.code === 0) {
      clear();
      onSaved?.();
    }
  };

  const onDelete = () => {
    setDeleteWarning(true);
  };

  const deleteTemplate = async () => {
    setDeleteWarning(false);

    if (!template) {
      return;
    }

    await moneyBankTemplateDelete({
      id: template.id,
      treaty: treaty,
    });

    onSaved && onSaved();
  };

  const accountNumberValid = validateBankAccountNumber(accountNumber);
  const accountNumberError: string =
    accountNumberValid.code !== 0 && accountNumberErrorShow
      ? accountNumberValid.message
      : '';

  const bicValid = validateBic(bic);
  const bicError: string =
    bicValid.code !== 0 && bicErrorShow ? bicValid.message : '';

  return (
    <div>
      <Typography.Text
        tag="p"
        view="primary-small"
        color="primary"
        weight="bold"
      >
        {template ? 'Редактирование шаблона' : 'Новый шаблон'}
      </Typography.Text>

      <Space fullWidth={true} direction="vertical">
        <Row>
          <Col width={10}>
            <InputDesktop
              label="Название"
              labelView="outer"
              block
              size="s"
              placeholder="Укажите название шаблона"
              value={title}
              onChange={titleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col width={10}>
            <InputDesktop
              label="Счёт"
              labelView="outer"
              block
              size="s"
              placeholder="Укажите номер счёта"
              value={accountNumber}
              onChange={accountNumberChange}
              onBlur={() => setAccountNumberErrorShow(true)}
              maxLength={20}
              hint="Состоит из 20 цифр"
              error={accountNumberError}
            />
          </Col>
        </Row>
        <Row>
          <Col width={10}>
            <InputDesktop
              label="БИК"
              labelView="outer"
              block
              size="s"
              placeholder="Укажите БИК банка"
              value={bic}
              onChange={bicChange}
              onBlur={() => setBicErrorShow(true)}
              maxLength={9}
              hint="Состоит из 9 цифр"
              error={bicError}
            />
          </Col>
        </Row>
        <Row justify="left">
          <Col width={5}>
            <FormControlSummary label="Банк">
              {bankInfo ? bankInfo.name : 'Определяется по БИК'}
            </FormControlSummary>
          </Col>
          <Col width={5}>
            <FormControlSummary label="Корреспондентский счет">
              {bankInfo ? bankInfo.corrAccount : 'Определяется по БИК'}
            </FormControlSummary>
          </Col>
          {bankInfoPending && (
            <Col width={2}>
              <Loader />
            </Col>
          )}
        </Row>
        <Row>
          <Col width={10}>
            <Textarea
              label="Дополнительные реквизиты"
              labelView="outer"
              block
              size="s"
              placeholder="Оставьте комментарий"
              value={comments}
              onChange={commentChange}
            />
          </Col>
        </Row>

        <Row className={styles.submit} justify="left">
          <Col width={5}>
            <ButtonDesktop
              size="s"
              block
              leftAddons={<CheckmarkMIcon width={18} height={18} />}
              loading={savePending}
              disabled={!isReadyToGo()}
              onClick={saveTemplate}
            >
              Сохранить
            </ButtonDesktop>
          </Col>
          {Boolean(template) && (
            <Col width={5}>
              <ButtonDesktop
                size="s"
                view="tertiary"
                block
                leftAddons={<TrashCanLineMIcon width={18} height={18} />}
                loading={deletePending}
                onClick={onDelete}
              >
                Удалить
              </ButtonDesktop>
            </Col>
          )}
        </Row>
      </Space>

      <ConfirmModal
        open={deleteWarning}
        title="Удаление шаблона"
        text={
          <>
            {`Шаблон ${
              template ? '«' + template.title + '»' : ' '
            } будет удалён`}
          </>
        }
        confirmLabel="Удалить"
        confirmIcon={<TrashCanLineMIcon width={18} height={18} />}
        onClose={() => setDeleteWarning(false)}
        onConfirm={deleteTemplate}
      />
    </div>
  );
};
