import { useCallback, useEffect } from 'react';

import {
  CertifcatesService,
  CertificatesEvent,
} from '../lib/services/certificates';
import useNotification from './useNotification';

import { shallow, useStore } from '../store';

import { CertificateEnrollStep } from '../types/certificate';
import { NotificationType } from '../types/ui';

// Хук отвечает за обработку соыбтий по выпуску сертификата
// На успешный выпуск переводит состояние в зюстанде на шаг конфирмейшена
// и показывает модалку подтверждения, на ошибку создает нотификацию
export const useCertificateEnrollFlow = () => {
  const [setStep, setCertificateEnrollId] = useStore(
    (state) => [state.setCertificateEnrollStep, state.setCertificateEnrollId],
    shallow
  );
  const addNotification = useNotification();

  const onError = useCallback(
    (errorMsg: string) => {
      setStep(CertificateEnrollStep.Error);
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка выпуска сертификата',
        text: errorMsg,
      });
    },
    [setStep, addNotification]
  );

  const onSuccess = useCallback(
    (idCertificate: number) => {
      // короче это искусственный таймаут который я добавил потому что сервер тупит
      // типа может быть выпущен сертфикат и если сразу запросить код подтверждения
      // то сервер может вернуть ошибку что типа не найден такой сертик
      // видимо чето там не мгновенно синхронизируется
      setTimeout(() => {
        setCertificateEnrollId(idCertificate);
        setStep(CertificateEnrollStep.OnConfirmation);
      }, 600);
    },
    [setCertificateEnrollId, setStep]
  );

  useEffect(() => {
    CertifcatesService.on(CertificatesEvent.ENROLL_ERROR, onError);
    CertifcatesService.on(CertificatesEvent.ENROLL_SUCCESS, onSuccess);

    return () => {
      CertifcatesService.off(CertificatesEvent.ENROLL_ERROR, onError);
      CertifcatesService.off(CertificatesEvent.ENROLL_SUCCESS, onSuccess);
    };
  }, [onError, onSuccess]);
};
