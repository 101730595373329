import keys from 'lodash/keys';
import memoize from 'lodash/memoize';
import sumBy from 'lodash/sumBy';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { OrderDirection, RazdelGroupType } from '../../lib/client/entities';
import { getOrderMap } from '../../lib/domain/getOrderMap';
import { makeOrderKey } from '../../lib/domain/makeOrderKey';
import { getPositionQty } from '../../lib/formulas';
import { useSubAccountRazdel } from './useSubAccountRazdel';

import { AccountItem } from '../../types/account';
import { ECurrencyId } from '../../types/currencyId';
import { OrderItem } from '../../types/order';
import { SubAccountPositionItem } from '../../types/subAccountPosition';
import { SubAccountRazdelItem } from '../../types/subAccountRazdel';

const RUB_ID = ECurrencyId.RUR;

// https://jira.moscow.alfaintra.net/browse/ADIRWEB-1247 - фильтр
// Мы столкнулись с глобальной проблемой что у клиентов по ФОРТСу и РЦБ отображаются маржин колы когда их на самом деле нет.
// Проблема оказалась в том что мы не правильно работаем с данными, приходящими с сервера по закрытым позициям после клиринга.
// Чтобы избежать этой ситуации в дальнейшем, мы фильтруем позиции по правилу:
// Показывать, если позиция - валюта или позиция ненулевая, или дневной оборот ненулевой, или заказана покупка/продажа, и позиция не из инвесткопилки
const getFilteredPositions = memoize(
  (
    subAccountPositions: SubAccountPositionItem[],
    ordersMap: Record<string, OrderItem[]>,
    accounts: AccountItem[],
    subAccountRazdels: SubAccountRazdelItem[]
  ) =>
    subAccountPositions.filter((position) => {
      const subAccountRazdel = subAccountRazdels.find(
        (razdel) => razdel.idRazdel === position.idRazdel
      );
      /** дневной оборот */
      const dailyBuySell = position.sessionBuyQty + position.sessionSellQty;
      /** заявки на покупку */
      const orderLong =
        ordersMap[makeOrderKey(position, OrderDirection.Buy)] ?? [];
      /** заявки на продажу */
      const orderShort =
        ordersMap[makeOrderKey(position, OrderDirection.Sell)] ?? [];
      /** сумма всех заявок */
      const planLong = sumBy(orderLong, 'rest');
      const planShort = sumBy(orderShort, 'rest');
      /** Признак рубля */
      const isRUR = position?.idObject === RUB_ID;
      /** текущая позиция */
      let TorgPos: number;

      if (position) {
        TorgPos =
          subAccountRazdel?.idRazdelGroup ===
            RazdelGroupType.DerivativesMarket && isRUR
            ? position.backPos + position.trnIn - position.trnOut
            : getPositionQty(position);
      } else {
        TorgPos = 0;
      }

      //Проверка на Инвест копилку
      const notInvestAcc = accounts.some(
        (account) => account.idAccount === position.idAccount
      );

      return (
        (subAccountRazdel?.idRazdelGroup === RazdelGroupType.CurrencyMarket ||
          dailyBuySell !== 0 ||
          planLong !== 0 ||
          planShort !== 0 ||
          TorgPos !== 0) &&
        notInvestAcc
      );
    }),
  //memoize By default, the first argument provided to the memoized function is used as the map cache key
  //Собираем кастомный cache key, чтобы остальные аргументы (не только первый) влияли на пересчет
  (subAccountPositions, ordersMap, accounts, subAccountRazdels) =>
    `${JSON.stringify(subAccountPositions)} ${keys(ordersMap).join(
      '-'
    )} ${accounts
      .map(({ idAccount }) => idAccount)
      .join('-')} ${subAccountRazdels
      .map(({ idRazdel }) => idRazdel)
      .join('-')}`
);

/**
 * Селектор корректных позиций без инвест копилок
 * https://jira.moscow.alfaintra.net/browse/ADIRWEB-1247 - фильтр
 */
export const selectSubAccountPositions = (
  orders: OrderItem[],
  accounts: AccountItem[],
  subAccountPositions: SubAccountPositionItem[],
  subAccountRazdels: SubAccountRazdelItem[]
) => {
  //Функция вызывается только если изменяются аргументы
  const ordersMap = getOrderMap(orders, accounts);

  //Функция вызывается только если изменяются аргументы
  return getFilteredPositions(
    subAccountPositions,
    ordersMap,
    accounts,
    subAccountRazdels
  );
};

export const usePositions = (): SubAccountPositionItem[] => {
  const {
    accounts,
    useOrders,
    useSubAccountPosition,
    useExtendSubAccountPosition,
  } = useAlfaDirectContext();

  let positions = useSubAccountPosition();
  const extendPositions = useExtendSubAccountPosition?.();
  const orders = useOrders();
  const subAccountRazdel = useSubAccountRazdel();

  if (extendPositions?.length) {
    positions = positions.concat(extendPositions);
  }

  return selectSubAccountPositions(
    orders,
    accounts,
    positions,
    subAccountRazdel
  );
};
