import { FC } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import styles from './AccessLevels.module.css';

export const AccessLevels: FC = () => (
  <>
    <Typography.Text
      view="primary-small"
      weight="bold"
      tag="p"
      defaultMargins={false}
      className={styles.title}
    >
      Уровни доступа
    </Typography.Text>

    <div className={styles.section}>
      <Typography.Text
        view="secondary-small"
        weight="bold"
        className={styles.sectionTitle}
      >
        Старший трейдер
      </Typography.Text>
      <Typography.Text view="secondary-small" className={styles.sectionText}>
        — Получает информацию о сделках, поручениях, активах доверителя.
        Полномочия не распространяются на получение документов, содержащих
        налоговую информацию; <br />
        — Подаёт поручения на совершение сделок; <br />
        — Подаёт поручения на совершение операций по счету депо; <br />— Подаёт
        поручения на отзыв (вывод) денежных средств.
      </Typography.Text>
    </div>

    <div className={styles.section}>
      <Typography.Text
        view="secondary-small"
        weight="bold"
        className={styles.sectionTitle}
      >
        Трейдер
      </Typography.Text>
      <Typography.Text view="secondary-small" className={styles.sectionText}>
        — Получает информацию о сделках, поручениях, активах доверителя.
        Полномочия не распространяются на получение документов, содержащих
        налоговую информацию; <br />
        — Подаёт поручения на совершение сделок. <br />
      </Typography.Text>
    </div>

    <div className={styles.section}>
      <Typography.Text
        view="secondary-small"
        weight="bold"
        className={styles.sectionTitle}
      >
        Контролёр
      </Typography.Text>
      <Typography.Text view="secondary-small" className={styles.sectionText}>
        — Получает информацию о сделках, поручениях, активах доверителя.
        Полномочия не распространяются на получение документов, содержащих
        налоговую информацию.
      </Typography.Text>
    </div>
  </>
);
