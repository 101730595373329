export const MOEXBMIList = [
  /* AFKS */ 'RU000A0DQZE3',
  /* AFLT */ 'RU0009062285',
  /* AKRN */ 'RU0009028674',
  /* ALRS */ 'RU0007252813',
  /* APTK */ 'RU0008081765',
  /* AQUA */ 'RU000A0JQTS3',
  /* ASTR */ 'RU000A106T36',
  /* BANEP */ 'RU0007976965',
  /* BELU */ 'RU000A0HL5M1',
  /* BSPB */ 'RU0009100945',
  /* CBOM */ 'RU000A0JUG31',
  /* CHMF */ 'RU0009046510',
  /* CHMK */ 'RU0007665170',
  /* DELI */ 'RU000A107J11',
  /* DVEC */ 'RU000A0JP2W1',
  /* ELFV */ 'RU000A0F5UN3',
  /* ENPG */ 'RU000A100K72',
  /* ETLN */ 'US29760G1031',
  /* EUTR */ 'RU000A1002V2',
  /* FEES */ 'RU000A0JPNN9',
  /* FESH */ 'RU0008992318',
  /* FIVE@GS(наше) или FIVE(биржи) */ 'US98387E2054',
  /* FIXP */ 'US33835G2057',
  /* FLOT */ 'RU000A0JXNU8',
  /* GAZP */ 'RU0007661625',
  /* GEMC */ 'RU000A107JE2',
  /* GLTR@GS(наше) или GLTR(биржи) */ 'US37949E2046',
  /* GMKN */ 'RU0007288411',
  /* HHR(наше) или HHRU(биржи) */ 'US42207L1061',
  /* HNFG */ 'RU000A106XF2',
  /* HYDR */ 'RU000A0JPKH7',
  /* IRAO */ 'RU000A0JPNM1',
  /* KAZT */ 'RU000A0B9BV2',
  /* KZOS */ 'RU0009089825',
  /* KZOSP */ 'RU0006753456',
  /* LENT */ 'RU000A102S15',
  /* LKOH */ 'RU0009024277',
  /* LSNGP */ 'RU0009092134',
  /* LSRG */ 'RU000A0JPFP0',
  /* MAGN */ 'RU0009084396',
  /* MDMG(наше) или MDDG(биржи) */ 'US55279C2008',
  /* MGNT */ 'RU000A0JKQU8',
  /* MGTSP */ 'RU0009036479',
  /* MOEX */ 'RU000A0JR4A1',
  /* MRKC */ 'RU000A0JPPL8',
  /* MRKP */ 'RU000A0JPN96',
  /* MRKU */ 'RU000A0JPPT1',
  /* MRKV */ 'RU000A0JPPN4',
  /* MSNG */ 'RU0008958863',
  /* MSRS */ 'RU000A0ET7Y7',
  /* MTLR */ 'RU000A0DKXV5',
  /* MTLRP */ 'RU000A0JPV70',
  /* MTSS */ 'RU0007775219',
  /* MVID */ 'RU000A0JPGA0',
  /* NKHP */ 'RU000A0BLWD7',
  /* NKNC */ 'RU0009100507',
  /* NKNSP(наше) или NKNCP(биржи) */ 'RU0006765096',
  /* NLMK */ 'RU0009046452',
  /* NMTP */ 'RU0009084446',
  /* NVTK */ 'RU000A0DKVS5',
  /* OGKB */ 'RU000A0JNG55',
  /* OZON */ 'US69269L1044',
  /* PHOR */ 'RU000A0JRKT8',
  /* PIKK */ 'RU000A0JP7J7',
  /* PLZL */ 'RU000A0JNAA8',
  /* POSI */ 'RU000A103X66',
  /* RAGRdr(наше) или AGRO(биржи) */ 'US7496552057',
  /* RASP */ 'RU000A0B90N8',
  /* RENI */ 'RU000A0ZZM04',
  /* RKKE */ 'RU0009095939',
  /* RNFT */ 'RU000A0JSE60',
  /* ROSN */ 'RU000A0J2Q06',
  /* RTKM */ 'RU0008943394',
  /* RTKMP */ 'RU0009046700',
  /* RUAL */ 'RU000A1025V3',
  /* SBER */ 'RU0009029540',
  /* SBERP */ 'RU0009029557',
  /* SELG */ 'RU000A0JPR50',
  /* SFIN */ 'RU000A0JVW89',
  /* SGZH */ 'RU000A102XG9',
  /* SMLT */ 'RU000A0ZZG02',
  /* SNGS */ 'RU0008926258',
  /* SNGSP */ 'RU0009029524',
  /* SPBE */ 'RU000A0JQ9P9',
  /* SVAV */ 'RU0006914488',
  /* TATN */ 'RU0009033591',
  /* TATNP */ 'RU0006944147',
  /* TCSG */ 'RU000A107UL4',
  /* TGKA */ 'RU000A0JNUD0',
  /* TGKB */ 'RU000A0JNGS7',
  /* TRMK */ 'RU000A0B6NK6',
  /* TRNFP */ 'RU0009091573',
  /* TTLK */ 'RU000A0HM5C1',
  /* UGLD */ 'RU000A0JPP37',
  /* UPRO */ 'RU000A0JNGA5',
  /* VKCO */ 'RU000A106YF0',
  /* VSMO */ 'RU0009100291',
  /* VTBR */ 'RU000A0JP5V6',
  /* WUSH */ 'RU000A105EX7',
];
