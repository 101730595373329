import React from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { SmsSigningInit } from '@terminal/core/lib/rest/lkSmsSignOperation';

import styles from './AssignmentScreen.module.css';

interface Props {
  assignment: SmsSigningInit;
}

export const AssignmentScreen = ({ assignment }: Props) => {
  return (
    <div className={styles.container}>
      <Typography.TitleResponsive
        view="xsmall"
        tag="h3"
        weight="medium"
        font="styrene"
      >
        Текстовое поручение
      </Typography.TitleResponsive>

      <Gap size="xs" />

      <Typography.Text view="secondary-small" className={styles.assignmentText}>
        {assignment.opText}
      </Typography.Text>
    </div>
  );
};
