import { roundToStep } from '@terminal/core/lib/format';
import { BracketPriceType } from '@terminal/core/types/ui';

export const getBracketPrice = (
  price: number,
  bracketPriceType: BracketPriceType,
  bracketPrice: number,
  sideMultiplicator: 1 | -1,
  priceStep = 0.01
) => {
  switch (bracketPriceType) {
    case BracketPriceType.PRICE: {
      return roundToStep(bracketPrice, priceStep);
    }
    case BracketPriceType.PERCENT: {
      return roundToStep(
        price + (sideMultiplicator * (price * bracketPrice)) / 100,
        priceStep
      );
    }
    case BracketPriceType.TICK: {
      return roundToStep(
        price + sideMultiplicator * bracketPrice * priceStep,
        priceStep
      );
    }
  }

  return roundToStep(bracketPrice, priceStep);
};
