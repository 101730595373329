import React, { FC } from 'react';

import { TrackPrice } from '@terminal/core/types/trackPrice';

import { EditButton } from './EditButton';
import { RemoveButton } from './RemoveButton';

type ActionsCellProps = {
  trackPrice: TrackPrice;
};

export const ActionsCell: FC<ActionsCellProps> = (props) => {
  const { trackPrice } = props;

  return (
    <>
      <EditButton trackPrice={trackPrice} />
      <RemoveButton trackPrice={trackPrice} />
    </>
  );
};
