import cn from 'classnames';
import { Typography } from '@alfalab/core-components/typography';

import { CurrenciesRequisites } from '@terminal/core/lib/rest/lkMoney';

import { CURRENCIES_GROUP_NAME } from './helpers';
import { RequisitesListItem } from './RequisitesListItem';

import styles from './RequisitesGroup.module.css';

interface RequisitesGroupProps {
  items: CurrenciesRequisites[];
  currency: string;
  className?: string;
  onRequisitesClick?: (id: number) => void;
}

export const RequisitesGroup = ({
  items,
  currency,
  className,
  onRequisitesClick,
}: RequisitesGroupProps) => (
  <div className={cn(styles.group, className)}>
    <Typography.Text view="secondary-large" tag="div" className={styles.title}>
      {CURRENCIES_GROUP_NAME[currency as keyof typeof CURRENCIES_GROUP_NAME] ||
        `Реквизиты для пополнения в ${currency}`}
    </Typography.Text>
    <div className={styles.groupBody}>
      {items.map((requisites) => (
        <RequisitesListItem
          key={`requisites-${requisites.id}`}
          className={styles.item}
          name={requisites.requisitesName}
          code={requisites.currency}
          onClick={() => onRequisitesClick && onRequisitesClick(requisites.id)}
        />
      ))}
    </div>
  </div>
);
