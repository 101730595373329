import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { useOrders } from './useOrders';
import { useSubAccountRazdel } from './useSubAccountRazdel';

import { OperationRow } from '../../types/operation';

interface Props {
  selectedSubAccounts: string[];
}

/**
 * Хук используется для получения "собранного" объекта операции с FE по выбранному счету
 * @param selectedSubAccounts - массив выбранных счетов
 */
//TODO: Дописать тесты
export const useOperations = ({ selectedSubAccounts }: Props) => {
  const { useOperations: useOperation } = useAlfaDirectContext();

  const operations = useOperation();
  const subAccountRazdels = useSubAccountRazdel();

  const {
    objectsTable,
    marketBoardsTable,
    objectTypesTable,
    finInstrumentsTable: finInstruments,
  } = useAlfaDirectContext();

  const orders = useOrders();

  return useMemo(() => {
    if (isEmpty(selectedSubAccounts)) {
      return [];
    }

    return operations
      .map<OperationRow>((operation) => {
        const object = objectsTable.get('idObject', operation.idObject);

        return {
          idFi: finInstruments
            .toArray()
            .find(
              (item) =>
                item.idObject === operation.idObject &&
                item.idMarketBoard === operation.idMarketBoard
            )?.idFI,
          operation: { ...operation, dateOperation: operation.timeOperation },
          object,
          currency: objectsTable.get('idObject', operation.idObjectCurrency),
          market: marketBoardsTable.get(
            'idMarketBoard',
            operation.idMarketBoard
          ),
          subAccountRazdel: subAccountRazdels.find(
            (subAccount) => subAccount.idRazdel === operation.idRazdel
          ),
          order: orders.find(
            (order) => order.numEDocument === operation.numEDocument
          ),
          operationDate: operation.timeOperation.toDateString(),
          type: 'FE',
          shortDescription: `${
            object?.nameObject
          } ${object?.idObjectType?.name?.toLowerCase()}${object?.idObjectType?.name?.slice(
            1
          )}`,
          nameObject: object?.nameObject,
          idObjectGroup: objectTypesTable.get(
            'idObjectType',
            object?.idObjectType.value
          )?.idObjectGroup,
        };
      })
      .filter((row) =>
        selectedSubAccounts.includes(
          String(row.subAccountRazdel?.codeSubAccount)
        )
      );
  }, [
    objectTypesTable,
    finInstruments,
    operations,
    objectsTable,
    marketBoardsTable,
    subAccountRazdels,
    orders,
    selectedSubAccounts,
  ]);
};
