import { Entity } from './entities';
import { EntityType } from './entityTypes';
import {
  ArrayFieldMeta,
  entitiesMap,
  EnumFieldMeta,
  EPOCH_TICKS,
  FieldMeta,
  FieldType,
  messagesByName,
  TICKS_PER_MILLISECOND,
  UNKNOWN_ENTITY_MSG,
  UnknownFieldMeta,
} from './serialization';

// расшаренный энкодер
const textEncoder = new TextEncoder();

function encode(entity: Entity, className: string): ArrayBuffer {
  let bytesLength = 0;

  bytesLength = 4; // Заголовок 4 байта

  const entityType = className;
  const entityLength = calculateEntitySize(entityType, entity, true);

  bytesLength += entityLength;

  const buffer = new ArrayBuffer(bytesLength);

  // устаналиваем заголовок
  const dv = new DataView(buffer);

  dv.setUint24(0, bytesLength); // количество байт во всем пакете включая заголовк

  encodeEntity(buffer, entity, entityType as EntityType, 4, entityLength);

  return buffer;
}

// возвращает размер энтити в байтах
function calculateEntitySize(
  entityType: string,
  entity: Entity,
  includeHeader: boolean
): number {
  let bytesLength = 0;

  if (includeHeader) {
    bytesLength += 2 + 2; // Размер и тип
  }

  // сама энтити
  const meta = entitiesMap.get(entityType);

  if (!meta) {
    throw new Error(
      `Entity type=${entityType} is not registred for serialization`
    );
  }

  for (const field of Object.keys(meta)) {
    bytesLength += getFieldSize(field, meta[field], entity);
  }

  return bytesLength;
}

// Возвращает размер поля в байтах
function getFieldSize(
  key: string,
  fieldMeta: FieldMeta,
  entity: Entity
): number {
  switch (fieldMeta.type) {
    case FieldType.BooleanField:
      return 1;
    case FieldType.StringField:
      return getStringSize(entity[key]);
    case FieldType.UInt32Field:
    case FieldType.Int32Field:
      return 4;
    case FieldType.Int64Field:
    case FieldType.DoubleField:
    case FieldType.DateTimeFiled:
      return 8;
    case FieldType.EnumField: {
      const meta = fieldMeta as EnumFieldMeta;

      return meta.width;
    }
    case FieldType.UnknownField: {
      const meta = fieldMeta as UnknownFieldMeta;

      return meta.width;
    }
    case FieldType.ArrayField: {
      return getArraySize(fieldMeta as ArrayFieldMeta, entity, key);
    }
    case FieldType.ByteArrayField: {
      const buffer = entity[key] as ArrayBuffer;

      if (buffer.byteLength === 0) {
        return 1;
      } else {
        return 3 + buffer.byteLength;
      }
    }

    default:
      throw new Error('Unsupported field size');
  }
}

// Возвращает размер строки. Размер строки равен размеру в байтах плюс 1 байт признак массива
// плюс два байта размер
function getStringSize(field: string): number {
  // считаем сколько будет байт в строке
  let result = 0;

  if (!field || field.length === 0) {
    return 1; // пустая строка
  } else {
    result = 1 + 2; // 1 байт это признак массива, 2 байта это размер строки
  }

  // TODO это не прикольно что все строки будут кодироваться два раза
  // сначала для рассчета размера и второй раз уже для данных
  // надо куда-нибудь сохранять и резюзать в какой-то мемоизированный словарь

  // UPD: несколько месяцев супстя стало понятно что мы почти не энкодим строки от слова совсем и на пратике вообще пофигу на эту оптимизацию
  const stringBytes = textEncoder.encode(field);

  result += stringBytes.byteLength;

  return result;
}

function getArraySize(
  fieldMeta: ArrayFieldMeta,
  entity: Entity,
  key: string
): number {
  const arrayProperty = entity[key] as Array<any>;

  if (arrayProperty.length === 0) {
    return 1;
  } else {
    let summ = 3;

    for (let i = 0; i < arrayProperty.length; i++) {
      summ += calculateEntitySize(fieldMeta.elements, arrayProperty[i], false);
    }

    return summ;
  }
}

function encodeEntity(
  buffer: ArrayBuffer,
  entity: Entity,
  entityType: EntityType,
  offset: number,
  entitySize: number
): void {
  const dv = new DataView(buffer, offset);
  let currentOffset = 0;

  // запишем размер и тип
  dv.setInt16(currentOffset, entitySize, true);

  const entityId = messagesByName.get(entityType);

  if (entityId) {
    dv.setInt16(currentOffset + 2, entityId, true);
    currentOffset += 4;
  } else {
    throw new Error(UNKNOWN_ENTITY_MSG);
  }

  const meta = entitiesMap.get(entityType);

  if (!meta) {
    throw new Error(UNKNOWN_ENTITY_MSG);
  }

  // поехали по полям
  for (const field of Object.keys(meta)) {
    currentOffset += encodeField(dv, field, meta[field], entity, currentOffset);
  }
}

function encodeField(
  dv: DataView,
  key: string,
  fieldMeta: FieldMeta,
  entity: Entity,
  offset: number
): number {
  // В случае массивов в entity могут падать скалярные значения типа number'ов
  // это функцию могут падать поэтому там дальше везде проверки передали скаляр или
  // объект с ключом

  // TODO подумат что делать когда пытаемся сериализовать отсутсвующее поле
  switch (fieldMeta.type) {
    case FieldType.StringField: {
      const stringValue = typeof entity === 'string' ? entity : entity[key];

      if (stringValue) {
        const stringBytes = textEncoder.encode(stringValue);

        dv.setInt8(offset, 1); // признак массива
        dv.setInt16(offset + 1, stringBytes.byteLength, true);

        // нам надо записать байты строки
        // в данном случае offset это отступ от начала энтити и buffer
        // от начала сообщения поэтому добавляем 4 байта заголовка сообщения и 3 байта заголовок строки
        const bytes = new Uint8Array(dv.buffer, offset + 3 + 4);

        bytes.set(stringBytes, 0);

        return stringBytes.byteLength + 3;
      } else {
        dv.setInt8(offset, 0);

        return 1;
      }
    }
    case FieldType.Int64Field: {
      dv.setBigInt64(
        offset,
        typeof entity === 'bigint' ? entity : entity[key],
        true
      );

      return 8;
    }
    case FieldType.UInt32Field: {
      dv.setUint32(
        offset,
        typeof entity === 'object' ? entity[key] : entity,
        true
      );

      return 4;
    }
    case FieldType.Int32Field: {
      dv.setInt32(
        offset,
        typeof entity === 'object' ? entity[key] : entity,
        true
      );

      return 4;
    }
    case FieldType.Int16Field: {
      dv.setInt16(
        offset,
        typeof entity === 'object' ? entity[key] : entity,
        true
      );

      return 2;
    }
    case FieldType.UnknownField: {
      const meta = fieldMeta as UnknownFieldMeta;

      return meta.width;
    }
    case FieldType.EnumField: {
      const enumMeta = fieldMeta as EnumFieldMeta;

      if (enumMeta.width === 1) {
        dv.setUint8(offset, typeof entity === 'object' ? entity[key] : entity);

        return 1;
      } else if (enumMeta.width === 2) {
        dv.setUint16(
          offset,
          typeof entity === 'object' ? entity[key] : entity,
          true
        );

        return 2;
      } else if (enumMeta.width === 4) {
        dv.setUint32(
          offset,
          typeof entity === 'object' ? entity[key] : entity,
          true
        );

        return 4;
      } else if (enumMeta.width === 8) {
        dv.setBigInt64(
          offset,
          typeof entity === 'object' ? entity[key] : entity,
          true
        );
      }

      break;
    }
    case FieldType.DateTimeFiled: {
      const isScalar =
        Object.prototype.toString.call(entity) === '[object Date]';
      const time = jsDateToTicks(isScalar ? entity : entity[key]);

      dv.setBigInt64(offset, BigInt(time), true);

      return 8;
    }
    case FieldType.BooleanField: {
      dv.setUint8(
        offset,
        typeof entity === 'boolean' ? entity : entity[key] === true ? 1 : 0
      );

      return 1;
    }
    case FieldType.DoubleField: {
      dv.setFloat64(
        offset,
        typeof entity === 'object' ? entity[key] : entity,
        true
      );

      return 8;
    }
    case FieldType.ByteArrayField: {
      const binary = entity[key] as ArrayBuffer;

      if (binary.byteLength === 0) {
        dv.setInt8(offset, 0);

        return 1;
      } else {
        dv.setInt8(offset, 1);
        dv.setInt16(offset + 1, binary.byteLength, true);
        let currentOffset = 3;

        const bytes = new Uint8Array(binary);
        const bufferBytes = new Uint8Array(dv.buffer);

        bufferBytes.set(bytes, dv.byteOffset + currentOffset + offset);
        currentOffset += bytes.byteLength;

        return currentOffset;
      }
    }
    case FieldType.ArrayField: {
      const meta = fieldMeta as ArrayFieldMeta;
      const arrayProperty = entity[key] as Array<any>;

      if (arrayProperty.length === 0) {
        dv.setInt8(offset, 0);

        return 1;
      } else {
        dv.setInt8(offset, 1); // признак массива
        dv.setInt16(offset + 1, arrayProperty.length, true);

        let currentOffset = 3;

        const arrayElementMeta = entitiesMap.get(meta.elements);

        if (!arrayElementMeta) {
          throw new Error(UNKNOWN_ENTITY_MSG);
        }

        for (let i = 0; i < arrayProperty.length; i++) {
          for (const field of Object.keys(arrayElementMeta)) {
            currentOffset += encodeField(
              dv,
              field,
              arrayElementMeta[field],
              arrayProperty[i],
              currentOffset + offset
            );
          }
        }

        return currentOffset;
      }
    }

    default:
      throw new Error('Unsuported field type');
  }

  return 0;
}

const jsDateToTicks = (date?: Date | string): number => {
  if (date) {
    const inputDate = typeof date === 'string' ? new Date(date) : date;
    const offset = inputDate.getTimezoneOffset() * 60000;

    return (inputDate.getTime() - offset) * TICKS_PER_MILLISECOND + EPOCH_TICKS;
  } else {
    return 0;
  }
};

export { encode, jsDateToTicks };
