import { ObjectGroup } from '../client/entities';

import { ObjectTypeItem } from '../../types/core';

/**
 * @returns Текущую стоимость (Стоимость)
 * */
export function getTorgPosCost(
  objectType: ObjectTypeItem,
  TorgPos: number,
  Price: number | null,
  nominal: number,
  PriceStep: number,
  PriceStepCost: number
): number | null {
  if (Price === null) {
    return null;
  }

  const SavedPriceStep = PriceStep === 0 ? 1 : PriceStep;

  switch (objectType?.idObjectGroup) {
    case ObjectGroup.Bonds: {
      return TorgPos * ((Price * nominal) / 100);
    }
    case ObjectGroup.Futures: {
      return TorgPos * ((Price * PriceStepCost) / SavedPriceStep);
    }
    case ObjectGroup.Currency:
    case ObjectGroup.Metals:
    case ObjectGroup.Stocks:
    case ObjectGroup.EquityParticipations:
    case ObjectGroup.Receipts:
    default:
      return TorgPos * Price;
  }
}
