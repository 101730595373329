import { ComponentProps, ComponentType, ElementType } from 'react';

/**
 * @param wrappers массив компонентов-оберток
 * */
export function wrapWithComponents(wrappers: ComponentType[]) {
  return function withComponent<W extends ElementType>(WrappedComponent: W) {
    return (props: ComponentProps<W>) => {
      let children = <WrappedComponent {...props} />;

      for (let i = wrappers.length - 1; i >= 0; i--) {
        const Wrapper = wrappers[i];

        children = <Wrapper>{children}</Wrapper>;
      }

      return children;
    };
  };
}
