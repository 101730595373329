import FingerprintJS from '@fingerprintjs/fingerprintjs';

export function getIsStandalone() {
  return (
    window.hasOwnProperty('matchMedia') &&
    window.matchMedia('(display-mode: standalone)')?.matches
  );
}

export async function getVisitorId() {
  const fp = await FingerprintJS.load();
  const { visitorId } = await fp.get();
  const isStandalone = getIsStandalone();

  if (!isStandalone) {
    return visitorId;
  } else {
    return `${visitorId}-standalone`;
  }
}

// код из интренета. Используется при выпуске сертификата, чтобы подставить в description
// информацию наглядную пользователю. Те он видит в интерфейсах в каком браузере был выпущен сертификат и когда
export function getBrowserInfo() {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];

    return { name: 'IE', version: tem[1] || '' };
  }

  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);

    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return {
    name: M[0],
    version: M[1],
  };
}

// код из интренета. Используется при запросе за раблсами.
// Раблсам нужно название операционной системы и версия этой оси.
export function getOsInfo(): { name?: string; version?: string } {
  const ua = navigator.userAgent;
  const NAME = 'name';
  const VERSION = 'version';
  const OBJ_TYPE = 'object';
  const FUNC_TYPE = 'function';
  const regexes = [
    [
      // iOS/macOS
      /ip[honead]{2,4}\b(?:.*os ([\w]+) like mac|; opera)/i, // iOS
      /cfnetwork\/.+darwin/i,
    ],
    [
      [VERSION, /_/g, '.'],
      [NAME, 'iOS'],
    ],
    [
      // Mobile OSes
      /droid ([\w.]+)\b.+(android[- ]x86)/i, // Android-x86
    ],
    [VERSION, NAME],
  ];

  const result = {
    name: undefined,
    version: undefined,
  };

  var i = 0,
    j,
    k,
    p,
    q,
    matches,
    match;

  // loop through all regexes maps
  while (i < regexes.length && !matches) {
    var regex = regexes[i], // even sequence (0,2,4,..)
      props = regexes[i + 1]; // odd sequence (1,3,5,..)

    j = k = 0;

    // try matching uastring with regexes
    while (j < regex.length && !matches) {
      // @ts-expect-error

      matches = regex[j++].exec(ua);

      if (matches) {
        for (p = 0; p < props.length; p++) {
          match = matches[++k];
          q = props[p];

          // check if given property is actually array
          if (typeof q === OBJ_TYPE && q.length > 0) {
            if (q.length === 2) {
              if (typeof q[1] == FUNC_TYPE) {
                // assign modified match
                result[q[0]] = q[1].call(result, match);
              } else {
                // assign given value, ignore regex match
                result[q[0]] = q[1];
              }
            } else if (q.length === 3) {
              // check whether function or regex
              if (typeof q[1] === FUNC_TYPE && !(q[1].exec && q[1].test)) {
                // call function (usually string mapper)
                result[q[0]] = match
                  ? q[1].call(result, match, q[2])
                  : undefined;
              } else {
                // sanitize match using given regex
                result[q[0]] = match ? match.replace(q[1], q[2]) : undefined;
              }
            } else if (q.length === 4) {
              result[q[0]] = match
                ? q[3].call(result, match.replace(q[1], q[2]))
                : undefined;
            }
          } else {
            result[q] = match ? match : undefined;
          }
        }
      }
    }

    i += 2;
  }

  return result;
}
