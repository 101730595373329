import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getEducationStrategies } from '../api';

export function useEducationStrategies() {
  const addNotification = useNotification();

  return useQuery(['lk-education-strategies'], getEducationStrategies, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса стратегий',
        text: error?.message || '',
      });
    },
  });
}
