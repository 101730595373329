import cn from 'classnames';
import { useMemo } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { LightningSIcon } from '@alfalab/icons-glyph/LightningSIcon';

import { Spinner } from '@terminal/common/components/Spinner';
import { NewsItem as NewsType } from '@terminal/common/entities/News';

import { useWidgetContext } from '../../../../shared';
import { formatNewsDate } from '../../lib/formatNewsDate';
import { NewsItem } from '../NewsItem/NewsItem';

import styles from './NewsPage.module.css';

export type NewsPageProps = {
  currentNews: NewsType;
  allNews: NewsType[];
  setCurrentNews: (NewsItemType) => void;
};

export const NewsPage = ({
  currentNews,
  allNews,
  setCurrentNews,
}: NewsPageProps) => {
  const { useNews } = useWidgetContext();

  const { isLoading: otherNewsIsLoading, data: otherNewsData } = useNews({
    isins: currentNews.isins ?? [],
    count: 5,
  });

  const otherNews = useMemo(() => {
    const newsList = (otherNewsData?.pages || []).flatMap(
      (page) => page.news
    ) as NewsType[];

    if (newsList.length < 4) {
      newsList.push(...allNews.slice(0, 4 - newsList.length));
    }

    return newsList.filter((news) => news.newsId !== currentNews.newsId);
  }, [allNews, currentNews.newsId, otherNewsData?.pages]);

  const dateFormatted = formatNewsDate(currentNews.date);

  const paragraphs = useMemo(() => {
    return currentNews.body.split('\r\n');
  }, [currentNews.body]);

  const isImportant = ['EXPRESS', 'FLASH'].includes(currentNews.importance);

  return (
    <>
      <div className={styles.newsPageContent}>
        <div
          className={cn(styles.newsItemHeader, {
            [styles.newsItemHeaderSimple]: !isImportant,
            [styles.newsItemHeaderImportant]: isImportant,
          })}
        >
          {isImportant ? (
            <Typography.Text
              view="secondary-small"
              className={styles.attentionIcon}
              color="attention"
            >
              <LightningSIcon />
            </Typography.Text>
          ) : null}

          <div
            className={cn(
              styles.newsItemHeaderItem,
              styles.newsItemHeaderItemSource,
              { [styles.newsImportantColor]: isImportant }
            )}
          >
            {currentNews.source}
          </div>

          {isImportant ? (
            <div className={styles.importantText}>Срочная</div>
          ) : null}

          <div
            className={cn(
              styles.newsItemHeaderItem,
              styles.newsItemHeaderItemDate,
              { [styles.newsImportantColor]: isImportant }
            )}
          >
            {dateFormatted}
          </div>
        </div>
        <Typography.Text
          tag="div"
          view="primary-medium"
          weight="bold"
          className={styles.newsPageTitle}
        >
          {currentNews.title}
        </Typography.Text>
        {paragraphs.map((paragraph, index) => (
          <Typography.Text
            key={index}
            tag="div"
            view="secondary-large"
            weight="medium"
            className={styles.newsParagraph}
          >
            {paragraph}
          </Typography.Text>
        ))}
      </div>

      <div>
        {otherNewsIsLoading ? (
          <div className={styles.spinner}>
            <Spinner size="36" color="var(--text-color-secondary)" />
          </div>
        ) : null}
        {!otherNewsIsLoading && otherNews.length !== 0 ? (
          <Typography.Text
            tag="div"
            view="primary-large"
            weight="bold"
            className={styles.newsPageOtherNewsTitle}
          >
            Читайте так же
          </Typography.Text>
        ) : null}
        {otherNews.map((item) => (
          <NewsItem key={item.newsId} {...item} onClick={setCurrentNews} />
        ))}
      </div>
    </>
  );
};
