import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { IS_AI } from '../env';
import { MarketBoardType } from '../lib/client/entities';
import { MultimarketsItem } from '../lib/rest/investApi';
import { loadMultimarkets } from '../lib/services/multimarkets';
import { useObjectByIdMap } from './domain';
import { useFullFI } from './domain/useFullFI';

import { InstrumentItem, MarketBoardItem, ObjectItem } from '../types/core';

export type MultimarketItem = {
  universalMarketCode: string;
  name: string;
  marketBoard?: MarketBoardItem;
  object?: ObjectItem;
  finInstrument?: InstrumentItem;
};

export type WeightedMultimarketItem = MultimarketItem & {
  exchangeWeight: number;
  marketWeight: number;
};

// Возвращает мультирынки для данного инструмента
export const useMultimarket = (idFI: number) => {
  const fullFi = useFullFI(idFI);
  const { marketBoardsTable, finInstrumentsTable } = useAlfaDirectContext();

  const objectById = useObjectByIdMap();
  const object = objectById.get(fullFi?.idObject);

  const objectInstuments = useMemo(
    () =>
      finInstrumentsTable
        .toArray()
        .filter((fi) => fi.idObject === object?.idObject),
    [finInstrumentsTable, object]
  );

  const { isin } = object ?? {};

  const fetcher = useCallback(() => {
    return isin ? loadMultimarkets([isin]) : ([] as MultimarketsItem[]);
  }, [isin]);

  const selector = useCallback(
    (data: MultimarketsItem[]) => {
      const [item] = data;

      if (!item) {
        return [];
      }

      const result: WeightedMultimarketItem[] = item.exchanges.reduce(
        (acc, exchange, eIndex) => {
          const primaryExchange = item.defaultExchangeId === exchange.id;

          exchange.markets.forEach((market) => {
            const primaryMarket = exchange.liquidMarketId === market.id;
            const marketBoard = marketBoardsTable
              .toArray()
              .find((board) => board.universalMarketCode === market.id);
            const fi = objectInstuments.find(
              (fi) => fi.idMarketBoard === marketBoard?.idMarketBoard
            );

            if (fi) {
              // Добавляем в мультирынки только инструменты которые есть у нас
              // Сервис мультирынков может возвращать то, чего у нас нет
              acc.push({
                universalMarketCode: market.id,
                name: market.name,
                marketBoard,
                object,
                finInstrument: fi,
                exchangeWeight: eIndex + (primaryExchange ? -1000 : 0),
                marketWeight: primaryMarket ? 0 : 1,
              });
            }
          });

          return acc;
        },
        [] as WeightedMultimarketItem[]
      );

      return result
        .sort((m1, m2) => {
          if (m1.exchangeWeight !== m2.exchangeWeight) {
            return m1.exchangeWeight - m2.exchangeWeight;
          }

          return m1.marketWeight - m2.marketWeight;
        })
        .filter((item) => {
          const { exchangeWeight, marketWeight, ...rest } = item;

          if (IS_AI) {
            return (
              item.marketBoard?.type.value !== MarketBoardType.OTC &&
              (rest as MultimarketItem)
            );
          }

          return rest as MultimarketItem;
        });
    },
    [marketBoardsTable, object, objectInstuments]
  );

  return useQuery(`pwa:multimarket:${isin ?? 'null'}`, fetcher, {
    select: selector,
  });
};
