import { useStore } from '../../store';

export const getMockData = (roots: number[] = []) => {
  const { objects, objectTypes, finInstruments, marketBoards } =
    useStore.getState();

  // fin instruments
  const myFinInstruments = finInstruments.filter((fi) =>
    roots.includes(fi.idFI)
  );
  // objects
  const myObjectIds = myFinInstruments.map((i) => i.idObject);
  const myObjects = objects.filter((ob) => myObjectIds.includes(ob.idObject));
  // marker boards
  const myMarketBoardIds = myFinInstruments.map((i) => i.idMarketBoard);
  const myMarketBoards = marketBoards.filter((mb) =>
    myMarketBoardIds.includes(mb.idMarketBoard)
  );
  // object types
  const myObjectTypeIds = myObjects.map((obj) => obj.idObjectType.value);
  const myObjectTypes = objectTypes.filter((ot) =>
    myObjectTypeIds.includes(ot.idObjectType)
  );

  const output = `
    // Этот файл сгенерирован автоматически! ***** Не редактировать *****!
    // ${new Date().toLocaleString()}
    // finObjects = ${JSON.stringify(roots)}
    
    import {InstrumentItem, MarketBoardItem, ObjectItem, ObjectTypeItem,} from 'types/core';

    /***** ObjectTypes. DO NOT EDIT! *****/
    export const mockObjectTypes: ObjectTypeItem[] = ${JSON.stringify(
      myObjectTypes,
      null,
      2
    )}  as ObjectTypeItem[];

    /***** Objects. DO NOT EDIT! *****/
    export const mockObjects: ObjectItem[] =${JSON.stringify(
      myObjects,
      null,
      2
    )}  as ObjectItem[];

    /***** MarketBoards. DO NOT EDIT! *****/
    export const mockMarketBoards: MarketBoardItem[] =${JSON.stringify(
      myMarketBoards,
      null,
      2
    )}  as MarketBoardItem[];
    
    /***** FinInstruments. DO NOT EDIT! *****/
    export const mockFinInstruments: InstrumentItem[] =${JSON.stringify(
      myFinInstruments,
      null,
      2
    )}  as InstrumentItem[];
  `;

  console.log(output);
};
