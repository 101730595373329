import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getSecuritiesDraftDetails } from '@terminal/core/lib/rest/lkSecurities';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesDraftDetails = (treaty: number, id: number) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.SecuritiesDraftDetails, { treaty, id }],
    async () => getSecuritiesDraftDetails({ treaty, id }),
    {
      enabled: Boolean(treaty) && Boolean(id),
      onError: (error: any) => {
        addNotification({
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе деталей драфта',
        });
      },
    }
  );
};
