import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { Treaty } from '../model/types/Treaty';

export async function getTreatiesAI(): Promise<Treaty[]> {
  const res = await fetchLkResult<Treaty[] | LKResult>(
    '/api/account/treaties-list'
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}
