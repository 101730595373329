import { ReactNode, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Loader } from '@alfalab/core-components/loader';
import { CrossMediumMIcon } from '@alfalab/icons-glyph/CrossMediumMIcon';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';
import { PfmCoffeCupMIcon } from '@alfalab/icons-glyph/PfmCoffeCupMIcon';
import { ThumbUpMIcon } from '@alfalab/icons-glyph/ThumbUpMIcon';
import { TrashCanMIcon } from '@alfalab/icons-glyph/TrashCanMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal/FunctionalModalSidebar';
import { Portal } from '@terminal/common/components/Portal';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import {
  SignOperationStatus,
  SignOperationStatusResponse,
  SignOrderStatus,
} from '@terminal/core/lib/rest/lkSignOperation';

import { Result } from '../../../shared';
import { DraftModal } from './DraftModal';

import { useDraft } from '../hooks/useDraft';
import { useDraftConfirm } from '../hooks/useDraftConfirm';
import { useDraftReject } from '../hooks/useDraftReject';

import styles from './DraftDetail.module.css';

export interface DraftDetailProps {
  treaty: Treaty | null;
  draftId?: number;
  setDraftId: (id?: number) => void;
}

type SignOperationStatusResponseData = {
  orderStatus?: SignOrderStatus;
  changeDraftStatus?: string;
  errorType?: string;
};

export const DraftDetail = ({
  treaty,
  draftId,
  setDraftId,
}: DraftDetailProps) => {
  const [statusInfo, setStatusInfo] = useState<
    SignOperationStatusResponse<SignOperationStatusResponseData> | undefined
  >();
  const { data, isFetching, isError } = useDraft(
    treaty?.treaty || 0,
    draftId || 0
  );

  const handleCallback = (status?: SignOperationStatusResponse) =>
    setStatusInfo(
      status as SignOperationStatusResponse<SignOperationStatusResponseData>
    );
  const confirm = useDraftConfirm({
    successCallback: handleCallback,
    failureCallback: handleCallback,
  });
  const reject = useDraftReject();

  if (isFetching) {
    return <Loader />;
  }

  let modal: ReactNode | null = null;

  const backBtn = (
    <ButtonDesktop
      onClick={() => setDraftId(undefined)}
      size="xxs"
      view="secondary"
    >
      К списку поручений
    </ButtonDesktop>
  );

  if (isError || confirm.isError || reject.isError) {
    modal = (
      <Result
        icon={<PfmCoffeCupMIcon />}
        title="Временные неполадки"
        text="Извините, уже исправляем. Попробуйте вернуться позже."
      >
        {backBtn}
      </Result>
    );
  }

  if (confirm.isSuccess && statusInfo) {
    const isDraftStatusChanged =
      statusInfo?.data?.changeDraftStatus === 'success';

    modal =
      statusInfo?.operationStatus !== SignOperationStatus.Executed ? (
        <Result icon={<CrossMediumMIcon />} title="Не удалось подать поручение">
          <ButtonDesktop
            onClick={() => setDraftId(undefined)}
            size="xxs"
            view="secondary"
          >
            Попробовать ещё раз
          </ButtonDesktop>
        </Result>
      ) : (
        <Result
          icon={isDraftStatusChanged ? <ThumbUpMIcon /> : <ExclamationMIcon />}
          title={
            isDraftStatusChanged
              ? 'Поручение успешно подано'
              : 'Ваше поручение уже принято в работу'
          }
          text={
            isDraftStatusChanged
              ? 'Проверить статус вы можете в Истории произвольных поручений'
              : 'Скоро оно перестанет отображаться в списке. Подписывать поручение снова не нужно.'
          }
        >
          {backBtn}
        </Result>
      );
  }

  if (reject.isSuccess) {
    modal = (
      <Result icon={<TrashCanMIcon />} title="Поручение отклонено">
        {backBtn}
      </Result>
    );
  }

  return (
    <Portal id="main-window-modals">
      {modal ? (
        <FunctionalModalSidebar showHead={false} open>
          <div className={styles.body}>{modal}</div>
        </FunctionalModalSidebar>
      ) : (
        <DraftModal
          isConfirmLoading={confirm.isLoading}
          isRejectLoading={reject.isLoading}
          data={data}
          onClose={() => setDraftId(undefined)}
          onConfirm={() =>
            confirm.mutate({
              orderDraftId: draftId || 0,
              treaty: treaty?.treaty || 0,
            })
          }
          onReject={() =>
            reject.mutate({
              orderDraftId: draftId || 0,
              treaty: treaty?.treaty || 0,
            })
          }
        />
      )}
    </Portal>
  );
};
