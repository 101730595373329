import { GetState } from 'zustand';

import { AllowedOrderParamsService } from '../../lib/services/allowedOrderParams';
import { RootStore, StoreSet } from '../useStore';

import { AllowedOrderParams } from '../../types/allowedOrderParams';

// В этом слайсе хранится матрица действий с заявками
// это такая штука, по которой надо найти нужный id'шник и засунуть его
// в параметры энтити ордера при отправке. Подробнее смотри в selectors.ts
export interface OrderParamsSlice {
  orderParams: AllowedOrderParams[];
  orderParamsReady: boolean;
  // префикс юзается при формировании текст поручения
  // он приходит когда пользователь логинится
  orderPrefix: string;
  initOrderParams: (allowedOrderParams: AllowedOrderParams[]) => void;
  resetOrderParams: () => void;
  addOrUpdateOrderParam: (orderParam: AllowedOrderParams) => void;
  removeOrderParam: (orderParam: AllowedOrderParams) => void;
  setOrderPrefix: (orderPrefix: string) => void;
}

export const createOrderParamsSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): OrderParamsSlice => ({
  orderParams: [],
  orderPrefix: '',
  orderParamsReady: false,
  initOrderParams: (allowedOrderParams: AllowedOrderParams[]) => {
    set((state) => {
      state.orderParams = allowedOrderParams;
      state.orderParamsReady = true;
    });

    AllowedOrderParamsService.save(get().orderParams);
    get().isCoreReady();
  },
  resetOrderParams: () => {
    set((state) => {
      state.orderParams = [];
      state.orderParamsReady = false;
    });

    AllowedOrderParamsService.save(get().orderParams);
  },
  addOrUpdateOrderParam: (orderParam: AllowedOrderParams) => {
    set((state) => {
      const id = orderParam.idAllowedOrderParams;

      const old = get().orderParams.findIndex(
        (o) => o.idAllowedOrderParams === id
      );
      if (old >= 0) {
        state.orderParams[old] = {
          ...orderParam,
        };
      } else {
        state.orderParams.push(orderParam);
      }
    });

    AllowedOrderParamsService.save(get().orderParams);
  },
  removeOrderParam: (orderParam: AllowedOrderParams) => {
    set((state) => {
      const old = get().orderParams.findIndex(
        (o) => o.idAllowedOrderParams === orderParam.idAllowedOrderParams
      );
      if (old >= 0) {
        state.orderParams.splice(old, 1);
      }
    });

    AllowedOrderParamsService.save(get().orderParams);
  },
  setOrderPrefix: (orderPrefix: string) => {
    set((state) => {
      state.orderPrefix = orderPrefix;
    });
  },
});
