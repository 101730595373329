export const calculateDirection = (
  currentPrice: number,
  prevPrice: number
): 'up' | 'down' | 'equal' => {
  switch (true) {
    case currentPrice > prevPrice:
      return 'up';

    case currentPrice < prevPrice:
      return 'down';

    default:
      return 'equal';
  }
};
