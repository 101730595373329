import inRange from 'lodash/inRange';

import { RootStore } from '../../useStore';

/**
 * Выбирает активную конфигурацию
 * */
export function activeConfigurationSelector(store: RootStore) {
  const { workspaceConfigurationActiveId: index, workspaceConfigurations } =
    store;

  if (inRange(index, 0, workspaceConfigurations.length)) {
    return workspaceConfigurations[index];
  }

  return workspaceConfigurations[0];
}
