//Получаемый полный FI (с object, objectType и marketBoard)
import { CurrencyCodes } from '@alfalab/utils';

import { getAvailableMarketBoards } from './domain/getAvailableMarketBoards';
import { getObjectInfo } from './domain/getObjectInfo';

import {
  InstrumentItemsTable,
  MarketBoardItemsTable,
  ObjectItemsTable,
  ObjectTypeItemsTable,
} from '../types/core';
import { ExtendedFI } from '../types/extendedFI';

export const getFullFI = (
  idFi: number | undefined,
  objects: ObjectItemsTable,
  objectTypes: ObjectTypeItemsTable,
  finInstruments: InstrumentItemsTable,
  marketBoards: MarketBoardItemsTable,
  filterUnavailableObjects: boolean = true
): ExtendedFI | undefined => {
  if (!idFi) {
    return;
  }

  const filtredMarketBoards = getAvailableMarketBoards(marketBoards);

  const instrument = finInstruments.get('idFI', idFi);

  if (instrument) {
    const marketBoard = filtredMarketBoards.get(
      'idMarketBoard',
      instrument.idMarketBoard
    )!;

    const currencyCode = objects.get('idObject', marketBoard?.idObjectCurrency)
      ?.symbolObject as CurrencyCodes;

    const { object, objectType } = getObjectInfo(
      instrument.idObject,
      objects,
      objectTypes,
      filterUnavailableObjects
    );

    return {
      ...instrument,
      ...marketBoard,
      ...objectType,
      ...object,
      currencyCode,
    };
  }
};
