import { AllowedOrderParamEntity } from '../lib/client/entities';

import { AllowedOrderParams } from '../types/allowedOrderParams';

export const allowedOrderParamsMap = (
  entity: AllowedOrderParamEntity
): AllowedOrderParams => ({
  idAllowedOrderParams: entity.IdAllowedOrderParams,
  idObjectGroup: entity.IdObjectGroup,
  idMarketBoard: entity.IdMarketBoard,
  idDocumentType: entity.IdDocumentType,
  idOrderType: entity.IdOrderType,
  idQuantityType: entity.IdQuantityType,
  idPriceType: entity.IdPriceType,
  idLifeTime: entity.IdLifeTime,
  idExecutionType: entity.IdExecutionType,
});
