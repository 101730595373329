import cn from 'classnames';
import React, { FC } from 'react';
import { Switch, SwitchProps } from '@alfalab/core-components/switch';

import { SettingsItem } from '../SettingsItem';

import styles from './SwitchSettings.module.css';

export type SwitchSettingsProps = SwitchProps;

export const SwitchSettings: FC<SwitchSettingsProps> = (props) => {
  const { className, ...restProps } = props;

  return (
    <SettingsItem
      Component={Switch}
      className={cn(styles.root, className)}
      {...restProps}
    />
  );
};
