import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useEvent } from 'react-use';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { Loader } from '@alfalab/core-components/loader';
import { Tab, TabsDesktop } from '@alfalab/core-components/tabs/desktop';
import { SelectedId } from '@alfalab/core-components/tabs/typings';
import { InternetOffMIcon } from '@alfalab/icons-glyph/InternetOffMIcon';

import { LOCATIONS } from '@terminal/core/constants/analytics';
import { trackMainMenu } from '@terminal/core/lib/analytics';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { Result } from '../../shared';
import { SelectTreaty } from '../components/SelectTreaty';
import { Drafts } from '../drafts';
import { FreeOrderHistory } from './FreeOrderHistory';
import { NewFreeOrder } from './NewFreeOrder';

import { useGetFreeOrderSettings } from '../hooks/useGetFreeOrderSettings';

import styles from './FreeOrders.module.css';

enum TabsId {
  DRAFTS = 'drafts',
  NEW = 'tab-new',
  HISTORY = 'tab-history',
}

type HandleTabSelected = (
  evt: MouseEvent,
  prop: { selectedId: SelectedId }
) => void;

export const FreeOrders = () => {
  const [treaty, setTreaty] = useState<Treaty | null>(null);
  const [isOffline, setOffline] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState<string>(TabsId.DRAFTS);

  useEffect(() => {
    if (selectedTabId === TabsId.NEW) {
      trackMainMenu(LOCATIONS.LK_FREE_ORDER_NEW);
    } else if (selectedTabId === TabsId.HISTORY) {
      trackMainMenu(LOCATIONS.LK_FREE_ORDER_HISTORY);
    }
  }, [selectedTabId]);

  const {
    data: freeOrderSettings,
    isLoading: isFreeOrderSettingsLoading,
    isFetching: isFreeOrderSettingsFetching,
  } = useGetFreeOrderSettings(treaty?.treaty);

  const onNetworkChangeStatus = useCallback((event) => {
    setOffline(event.type === 'offline');
  }, []);

  useEvent('offline', onNetworkChangeStatus);
  useEvent('online', onNetworkChangeStatus);

  const handleTabChange: HandleTabSelected = (evt, { selectedId }) => {
    if (typeof selectedId === 'string') {
      setSelectedTabId(selectedId);
    }
  };

  if (isOffline) {
    return (
      <div className={styles.screen}>
        <Result
          icon={<InternetOffMIcon />}
          text="Проверьте Wi-Fi или мобильную сеть"
          title="Нет интернета"
        >
          <ButtonDesktop
            onClick={() => window.location.reload()}
            size="xxs"
            view="secondary"
          >
            Перезагрузить
          </ButtonDesktop>
        </Result>
      </div>
    );
  }

  return (
    <div className={styles.freeOrdersContainer}>
      <div>
        <SelectTreaty
          zeros={true}
          hint="По нему создаются поручения ниже"
          onSelect={(treaty) => setTreaty(treaty)}
        />
      </div>
      {isFreeOrderSettingsLoading || isFreeOrderSettingsFetching ? (
        <Loader />
      ) : (
        <>
          <Gap size="xl" />
          <TabsDesktop
            size="s"
            selectedId={selectedTabId}
            onChange={handleTabChange}
          >
            <Tab title="На подпись" id={TabsId.DRAFTS} className={styles.tab}>
              <Drafts treaty={treaty} />
            </Tab>
            <Tab title="Новое" id={TabsId.NEW} className={styles.tab}>
              <NewFreeOrder
                treaty={treaty?.treaty}
                orderThemes={freeOrderSettings?.themes}
              />
            </Tab>
            <Tab title="История" id={TabsId.HISTORY} className={styles.tab}>
              <FreeOrderHistory treaty={treaty} />
            </Tab>
          </TabsDesktop>
        </>
      )}
    </div>
  );
};
