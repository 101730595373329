import { ClientSubAccPositionEntity } from '../lib/client/entities';

import { SubAccountPositionItem } from '../types/subAccountPosition';

export const subAccountPositionMap = (
  entity: ClientSubAccPositionEntity
): SubAccountPositionItem => ({
  idAccount: entity.IdAccount,
  idSubAccount: entity.IdSubAccount,
  idObject: entity.IdObject,
  idPosition: entity.IdPosition,
  idFIBalance: entity.IdFIBalance,
  backDate: entity.BackDate,
  backUchPriceRur: entity.BackUchPriceRur,
  backPos: entity.BackPos,
  awPriceBuy: entity.AwPriceBuy,
  awPriceSell: entity.AwPriceSell,
  curRate: entity.CurRate,
  PSTNKD: entity.PSTNKD,
  trnIn: entity.TrnIn,
  trnOut: entity.TrnOut,
  buyQty: entity.BuyQty,
  sellQty: entity.SellQty,
  trdPL: entity.TrdPL,
  uchPriceRur: entity.UchPriceRur,
  uchPriceEUR: entity.UchPriceEUR,
  uchPriceGBP: entity.UchPriceGBP,
  uchPriceUSD: entity.UchPriceUSD,
  uchPrice: entity.UchPrice,
  idRazdel: entity.IdRazdel,
  marginDiscountBuyD0: entity.MarginDiscountBuyD0,
  marginDiscountSellD0: entity.MarginDiscountSellD0,
  marginDiscountBuyDX: entity.MarginDiscountBuyDX,
  marginDiscountSellDX: entity.MarginDiscountSellDX,
  variationMargin: entity.VariationMargin,
  sessionBuyQty: entity.SessionBuyQty,
  sessionBuyVal: entity.SessionBuyVal,
  sessionSellQty: entity.SessionSellQty,
  sessionSellVal: entity.SessionSellVal,
  subAccNalPos: entity.SubAccNalPos,
  version: entity.Version.toString(),
  operation: entity.Operation,
});
