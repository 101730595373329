import cn from 'classnames';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { MutableRefObject, RefObject, useEffect, useMemo, useRef } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { MessagesGroup } from './MessagesGroup';

import { ChatMessage, ChatMessagesGroup } from '../types';

import styles from './MessagesDay.module.css';

interface MessagesDayProps {
  showDate: boolean;
  groups: ChatMessagesGroup[];
  date: string;
  onContext: (message: ChatMessage) => void;
  mobile: boolean;
}

export const MessagesDay = ({
  showDate,
  groups,
  date,
  onContext,
  mobile,
}: MessagesDayProps) => {
  const observer: MutableRefObject<IntersectionObserver | undefined> = useRef();
  const dayTopRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([e]) => {
        e.target.toggleAttribute('data-stuck', e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    if (dayTopRef.current) observer.current.observe(dayTopRef.current);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, []);

  const beautyDate = useMemo(() => {
    const today = new Date();
    const yesterday = new Date().setDate(today.getDate() - 1);
    switch (true) {
      case date === format(today, 'd MMMM yyyy', { locale: ru }):
        return 'Сегодня';
      case date === format(yesterday, 'd MMMM yyyy', { locale: ru }):
        return 'Вчера';
      default:
        return date;
    }
  }, [date]);

  return (
    <div>
      <div ref={dayTopRef} className={styles.dayTop} />
      <div
        className={cn(styles.dateWrapper, {
          [styles.dateWrapperVisible]: showDate,
        })}
      >
        <Typography.Text
          view={mobile ? 'secondary-medium' : 'secondary-small'}
          color="secondary"
          className={styles.date}
          onClick={() =>
            dayTopRef?.current?.scrollIntoView({ behavior: 'smooth' })
          }
        >
          {beautyDate}
        </Typography.Text>
      </div>
      {groups.map((group, index) => (
        <MessagesGroup
          key={index}
          mobile={mobile}
          messagesGroup={group}
          onContext={onContext}
        />
      ))}
    </div>
  );
};
