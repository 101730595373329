import { ObjectGroup } from '../client/entities';

import { ObjectTypeItem } from '../../types/core';
import { ECurrencyId } from '../../types/currencyId';

/**
 * @returns Стоимость чистого остатка по учетной цене
 * */
export function getTorgUchPosCost(
  objectType: ObjectTypeItem,
  UchPrice: number,
  TorgPos: number,
  nominal: number,
  PriceStep: number,
  PriceStepCost: number,
  idObject?: number
): number {
  if (idObject === ECurrencyId.RUR) {
    return TorgPos;
  }

  const SavedPriceStep = PriceStep === 0 ? 1 : PriceStep;

  switch (objectType?.idObjectGroup) {
    case ObjectGroup.Bonds: {
      return TorgPos * ((UchPrice * nominal) / 100);
    }
    case ObjectGroup.Futures: {
      return (TorgPos * UchPrice * PriceStepCost) / SavedPriceStep;
    }
    case ObjectGroup.Currency:
    case ObjectGroup.Metals:
    case ObjectGroup.Stocks:
    case ObjectGroup.EquityParticipations:
    case ObjectGroup.Receipts: {
      return TorgPos * UchPrice;
    }

    default: {
      return TorgPos;
    }
  }
}
