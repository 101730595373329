import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { ListAttorneysDTO } from './types/ListAttorneysDTO';
import { ListAttorneysParams } from './types/ListAttorneysParams';

export const listAttorneys = async (
  data: ListAttorneysParams
): Promise<ListAttorneysDTO['items']> => {
  const url = qs.stringifyUrl({
    url: '/api/attorney/list',
    query: data,
  });

  const res = await fetchLkResult<ListAttorneysDTO | LKResult>(url);

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res.items;
};
