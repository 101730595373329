import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export const Timer = ({ targetTime }: { targetTime: number }) => {
  const timeRef = useRef<HTMLSpanElement>(null);

  const isActual = useMemo(() => targetTime > Date.now(), [targetTime]);

  const printTime = useCallback(() => {
    if (timeRef.current) {
      if (!isActual) {
        timeRef.current.innerHTML = '';
      } else {
        const restTime = Math.floor(targetTime - Date.now());

        timeRef.current.innerHTML = format(restTime, 'mm:ss');
      }
    }
  }, [isActual, targetTime]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isActual) {
      intervalId = setInterval(printTime, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isActual, printTime]);

  return <span ref={timeRef} />;
};
