import cn from 'classnames';
import React, { FC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { WalletMIcon } from '@alfalab/icons-glyph/WalletMIcon';
import { formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';

import styles from './MissingAmountTopUp.module.css';

interface Props {
  sum: number;
  onTopUpClick: () => void;
  buttonClassName?: string;
}

export const MissingAmountTopUp: FC<Props> = ({
  sum,
  onTopUpClick,
  buttonClassName,
}) => {
  return (
    <div className={styles.topUpButtonContainer}>
      <ButtonDesktop
        size="xs"
        leftAddons={<WalletMIcon width={16} height={16} />}
        onClick={onTopUpClick}
        className={cn(styles.topUpButton, buttonClassName)}
      >
        Пополнить счёт
      </ButtonDesktop>
      <Typography.Text view="secondary-small" tag="div" className={styles.text}>
        Для&nbsp;совершения&nbsp;сделки не&nbsp;хватает&nbsp;
        {
          formatAmount({
            value: sum * MINORITY,
            minority: MINORITY,
            view: 'withZeroMinorPart',
          }).formatted
        }
        &nbsp;₽
      </Typography.Text>
    </div>
  );
};
