import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getContestInfo } from '../api/getContestInfo';

export const useContestInfo = (contestid?: number) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'contest/info', { contestid }],
    () => getContestInfo({ contestid: contestid! }),
    {
      enabled: typeof contestid === 'number',
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message ||
            'При запросе информации о конкурсе произошла ошибка',
        });
      },
    }
  );
};
