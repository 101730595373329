import { useMemo } from 'react';

import {
  useAlfaDirectContext,
  useFullFI,
  usePositionInfo,
  usePositions,
} from '@terminal/alfadirect/hooks';
import { Portal } from '@terminal/common/components/Portal';
import { getDisplayMode } from '@terminal/core/lib/trade/getDisplayMode';
import {
  TradingLimitsProps,
  TradingLimitsShowMode,
} from '@terminal/core/types/layout';

import { MeasureContent } from '../MeasureContent';

export const TradingLimits = ({
  idFi,
  selectedSubAccountId,
  nodeId,
  selectedSubAccounts,
  limitsProps,
  tradeLimitsLong,
  tradeLimitsShort,
}: TradingLimitsProps) => {
  const { subAccountRazdels } = useAlfaDirectContext();

  // Настройки отображения
  const displaySettings = useMemo(
    () =>
      limitsProps ?? {
        showPosition: true,
        showLimits: TradingLimitsShowMode.MONEY,
        showNPU: TradingLimitsShowMode.MONEY,
      },
    [limitsProps]
  );

  const fullFi = useFullFI(idFi);

  const positions = usePositions();

  const { position } = usePositionInfo(
    fullFi,
    selectedSubAccountId,
    selectedSubAccounts,
    positions,
    subAccountRazdels
  );

  const { allowPosition, allowLimits, allowNPU } = getDisplayMode({
    displaySettings,
    tradeLimitsLong,
    tradeLimitsShort,
    position,
  });

  const isAllowRender = useMemo(
    () => allowLimits || allowPosition || allowNPU,
    [allowLimits, allowPosition, allowNPU]
  );

  return nodeId && isAllowRender ? (
    <Portal id={`trading-limits-${nodeId.slice(1)}`}>
      <MeasureContent
        id={`trading-limits-content-${nodeId.slice(1)}`}
        nodeId={nodeId}
        idFi={idFi}
        position={position}
        tradeLimitsLong={tradeLimitsLong}
        tradeLimitsShort={tradeLimitsShort}
        displaySettings={displaySettings}
      />
    </Portal>
  ) : null;
};
