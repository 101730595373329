import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { currenciesFI } from '../../constants/FIModal';
import { OrderStatus } from '../../lib/client/entities';
import { makeOrderKey } from '../../lib/domain/makeOrderKey';
import { sortObjKeys } from '../../lib/sortObjKeys';
import { useBalance } from './useBalance';
import { useFilteredSubAccounts } from './useFilteredSubAccounts';
import { useIdFiBalances } from './useIdFiBalances';
import { useMarketBoardByIdMap } from './useMarketBoardByIdMap';
import { useObjectByIdMap } from './useObjectByIdMap';
import { useObjectExtByIdMap } from './useObjectExtByIdMap';
import { useObjectTypeByIdMap } from './useObjectTypeByIdMap';
import { useOrders } from './useOrders';
import { usePosition } from './usePosition';
import { usePositions } from './usePositions';
import { useSubAccountRazdel } from './useSubAccountRazdel';

import { OrderItem } from '../../types/order';

/**
 * @returns баланс выбранного субсчета
 */
export const useGetBalance = (selectedSubAccount: string) => {
  const {
    accounts: clientAccounts,
    subGTAccounts,
    usePwaGlobalSubAccount,
    useFinInfoExt,
    useQuotes,
  } = useAlfaDirectContext();
  const selectedSubAccountCode = usePwaGlobalSubAccount?.()?.code;

  const positions = usePositions();
  const objectByIdMap = useObjectByIdMap();
  const objectExtByIdMap = useObjectExtByIdMap();
  const objectTypeByIdMap = useObjectTypeByIdMap();
  const marketBoardByIdMap = useMarketBoardByIdMap();
  const currenciesQuotes = useQuotes(currenciesFI);
  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);
  const positionsToIdFiBalances = useIdFiBalances(positions);

  const fi = useMemo(
    () => Array.from(new Set(positionsToIdFiBalances.values())),
    [positionsToIdFiBalances]
  );
  const finInfoExts = useFinInfoExt(fi);
  const quotes = useQuotes(fi);
  const orders = useOrders();

  const subAccountRazdels = useSubAccountRazdel();
  const filteredSubAccounts = useFilteredSubAccounts();
  const selectedSubAccounts = useMemo(() => {
    // По умолчанию выбираем первый субсчет, который не инвесткопилка (сортируем по генсоглу)
    let result = [Object.keys(sortObjKeys(filteredSubAccounts))[0]];

    if (selectedSubAccountCode) {
      result = [selectedSubAccountCode];
    }

    if (selectedSubAccount) {
      result = [selectedSubAccount];
    }

    return result;
  }, [selectedSubAccountCode, selectedSubAccount, filteredSubAccounts]);

  const filteredOrders = useMemo(
    //clientAccounts - счета пользователя, но без Инвесткопилок
    () =>
      orders.filter(
        (order) =>
          clientAccounts.some(
            (account) => account.idAccount === order.idAccount
          ) &&
          //Также отсеиваем заявки, которые не в статусе Активные и Ожидают, они не учавствуют в рассчетах
          ![
            OrderStatus.Filled,
            OrderStatus.Cancelled,
            OrderStatus.Rejected,
          ].includes(order.idOrderStatus)
      ),
    [clientAccounts, orders]
  );

  const ordersMap = useMemo(() => {
    const map: Record<string, OrderItem[]> = {};

    filteredOrders.forEach((order) => {
      const key = makeOrderKey(order, order.buySell);

      if (!map[key]) {
        map[key] = [];
      }

      map[key].push(order);
    });

    return map;
  }, [filteredOrders]);

  const computedPositions = usePosition(
    {
      positions,
      objectsMap: objectByIdMap,
      objectExtsMap: objectExtByIdMap,
      finInfoExts,
      marketBoardMap: marketBoardByIdMap,
      subAccountRazdels,
      objectTypesMap: objectTypeByIdMap,
      ordersMap,
      currenciesQuotes,
      currenciesFinInfoExts,
      quotes,
      positionsToIdFiBalances,
    },
    { selectedSubAccounts }
  );

  const accountBalances = useBalance(
    {
      positions: computedPositions,
      currenciesQuotes,
      currenciesFinInfoExts,
      subGTAccounts,
    },
    { selectedSubAccounts }
  );

  return accountBalances[0];
};
