import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { fetchLkFile } from '@terminal/core/lib/rest/lkApi';
import { makeGetParams } from '@terminal/core/lib/url';
import { NotificationType } from '@terminal/core/types/ui';

import { LK_API } from '../const/externalUrls';

interface HookParams {
  isGoInvest?: boolean;
}

interface RequisitesParams {
  treaty: number;
  currency: string;
  inline: boolean;
  placeCode?: string;
  requisiteId?: number;
}

export const useBrokerRequisitesFile = (params?: HookParams) => {
  const isGoInvest = params?.isGoInvest;
  const addNotification = useNotification();

  return useMutation(
    async (data: {
      type: 'qr' | 'pdf';
      treaty: number;
      currency: string;
      placeCode?: string;
      requisiteId?: number;
    }) => {
      let baseUrl = LK_API.REQUISITES_BROKER_QR;

      if (data.type === 'pdf') {
        baseUrl = LK_API.REQUISITES_BROKER_PDF;
      }

      const requestParams: RequisitesParams = {
        treaty: data.treaty,
        currency: data.currency,
        inline: true,
        requisiteId: data.requisiteId,
      };

      if (!isGoInvest) {
        requestParams.placeCode = data.placeCode || 'MICEX_SHR';
      }

      const request = makeGetParams(requestParams);

      return await fetchLkFile(`${baseUrl}?${request}`);
    },
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса реквизитов',
          text: error?.message || '',
        });
      },
    }
  );
};
