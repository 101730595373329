import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';
import { subscribeAdvisor, SubscribeAdvisorType } from '../api';

export const useSubscribeAdvisor = (
  enabled: boolean,
  data: SubscribeAdvisorType
) => {
  const addNotification = useNotification();

  return useQuery(
    LkQueryKeys.AddAdvisorPreregister,
    () => subscribeAdvisor(data),
    {
      enabled,
      onError: (error: any) =>
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе',
        }),
    }
  );
};
