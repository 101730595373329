import sumBy from 'lodash/sumBy';

import { ObjectGroup } from '../client/entities';

import { PositionItem } from '../../types/position';

/**
 * @returns Сумму стоимостей НКД позиций по облигациям в рублевом эквиваленте
 * */
export function calculateNKDBalance(positions: PositionItem[]) {
  return sumBy(
    positions.filter(
      (position) => position.objectType?.idObjectGroup === ObjectGroup.Bonds
    ),
    'NKDRur'
  );
}
