import { TradingPlatformStateExtendedType } from './types';

export const getStateLabel = (
  state: TradingPlatformStateExtendedType,
  accountName?: string
) => {
  switch (state) {
    case 'InProgress':
      return 'Регистрация в процессе';
    case 'Registred':
      return 'Счёт зарегистрирован';
    case 'NotAvailable':
      return `Недоступен на счёте ${accountName}`;
  }

  return '';
};
