import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getSecuritiesPlaceList } from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesPlaceList = (treaty?: number) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.SecuritiesPlaceList, { treaty }],
    async () => getSecuritiesPlaceList({ treaty: treaty || 0 }),
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе мест хранения',
        });
      },
    }
  );
};
