import { FC, useEffect } from 'react';

import { InstrumentInfoProps, Widget } from '@terminal/core/types/layout';

import { FinInstrAutocomplete } from '../../features/FinInstrAutocomplete';
import { WidgetHeader } from '../../features/WidgetHeader';
import { useWidgetContext } from '../../shared';
import InstrumentTabs from './ui/InstrumentTabs/InstrumentTabs';
import { InstrumentTitle } from './ui/InstrumentTitle/InstrumentTitle';
import { IntstrumentError } from './ui/IntstrumentError/IntstrumentError';

import { useInstrumentInfo } from './hooks/useInstrumentInfo';

import styles from './InstrumentInfo.module.css';

const InstrumentInfo: FC<InstrumentInfoProps> = ({ idFi, nodeId }) => {
  const { onWidgetLoad, useIssuer } = useWidgetContext();

  const { isin } = useInstrumentInfo(idFi);

  const { isError: isIssuerError, refetch } = useIssuer(isin);

  useEffect(() => {
    if (nodeId) {
      onWidgetLoad(nodeId);
    }
  }, [nodeId, onWidgetLoad]);

  return (
    <div className={styles.wrapper}>
      <WidgetHeader
        inputs={
          <FinInstrAutocomplete
            idFi={idFi}
            nodeId={nodeId}
            widget={Widget.INSTRUMENT_INFO}
            block={false}
          />
        }
      />
      <div className={styles.body}>
        {isIssuerError ? (
          <IntstrumentError onReload={refetch} />
        ) : (
          <>
            <InstrumentTitle idFi={idFi} />
            <InstrumentTabs idFi={idFi} />
          </>
        )}
      </div>
    </div>
  );
};

export { InstrumentInfo };
