import { FC, useMemo } from 'react';
import { Button } from '@alfalab/core-components/button';

import styles from './FilterButtons.module.css';

interface FilterButtonsProps {
  value: any;
  onCancel: () => void;
  onSave: () => void;
}

export const FilterButtons: FC<FilterButtonsProps> = ({
  value,
  children,
  onSave,
  onCancel,
}) => {
  const isDisabled = useMemo(() => {
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }

    return !value;
  }, [value]);

  return (
    <>
      {children}
      <div className={styles.buttonsWrapper}>
        <Button
          view="tertiary"
          size="xxs"
          onClick={onCancel}
          className={styles.customButton}
          disabled={!value}
        >
          Сбросить
        </Button>
        <Button
          size="xxs"
          onClick={onSave}
          className={styles.customButton}
          disabled={isDisabled}
        >
          Применить
        </Button>
      </div>
    </>
  );
};
