import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';

import styles from './Plug.module.css';

interface PlugProps {
  title: React.ReactNode;
  text?: React.ReactNode;
}

export const Plug = ({ title, text }: PlugProps) => {
  return (
    <div className={styles.container}>
      <SuperEllipse size={48}>
        <ExclamationMIcon />
      </SuperEllipse>
      <Typography.Text
        tag="p"
        view="primary-medium"
        defaultMargins={false}
        className={styles.title}
        weight="bold"
      >
        {title}
      </Typography.Text>
      {text && (
        <Typography.Text
          view="secondary-small"
          color="secondary"
          tag="p"
          defaultMargins={false}
        >
          {text}
        </Typography.Text>
      )}
    </div>
  );
};
