import { Typography } from '@alfalab/core-components/typography';

import { LK_API } from '../../../const/externalUrls';
import { FaqFile } from './FaqFile';

import styles from './SecuritiesFaq.module.css';

export const SecuritiesFaq = () => {
  return (
    <>
      <Typography.Text view="primary-medium" weight="bold" tag="p">
        Как перевести ценные бумаги
      </Typography.Text>
      <div className={styles.text}>
        <Typography.Text view="secondary-small" tag="p">
          1. Подайте поручение на&nbsp;зачисление ценных бумаг
          в&nbsp;Go&nbsp;Invest. Инструкция как&nbsp;это сделать приложена ниже.
        </Typography.Text>

        <Typography.Text view="secondary-small" tag="p">
          2. Подайте поручение на&nbsp;вывод ценных бумаг прошлому брокеру.
          На&nbsp;этом шаге вам&nbsp;понадобятся реквизиты Go&nbsp;Invest. Файл
          в&nbsp;реквизитами Go&nbsp;Invest приложен ниже или&nbsp;скачайте файл
          с&nbsp;реквизитами для&nbsp;встречного поручения: Переводы ценных
          бумаг&nbsp;→&nbsp;История.
        </Typography.Text>

        <Typography.Text view="secondary-small" tag="p">
          3. Смотрите статус поручений в веб-терминале Go&nbsp;Invest: Переводы
          ценных бумаг&nbsp;→&nbsp;История.
        </Typography.Text>

        <Typography.Text view="secondary-small" tag="p">
          Поручение действительно:
          <br />
          a) Для НРД в&nbsp;течение 30&nbsp;дней с&nbsp;даты, которую
          вы&nbsp;указали в&nbsp;поле «Дата расчетов»;
          <br />
          b) Для СПБ в&nbsp;течение 30&nbsp;дней с&nbsp;даты подачи поручения
          депозитарием Go&nbsp;Invest в&nbsp;вышестоящий депозитарий
          <br />
        </Typography.Text>

        <Typography.Text view="secondary-small" tag="p">
          4. Когда ваши активы поступят на&nbsp;ваш брокерский счет
          в&nbsp;Go&nbsp;Invest, для&nbsp;корректного учета ваших налогов
          и&nbsp;налоговых льгот запросите у&nbsp;прежнего брокера расходные
          документы. Подробнее про&nbsp;расходные документы можно узнать
          в&nbsp;приложенном ниже файле.
        </Typography.Text>
      </div>
      <div className={styles.list}>
        <FaqFile
          name="Реквизиты GoInvest"
          className={styles.file}
          url={LK_API.SECURITIES_INSTRUCTION_REQUISITES}
        />
        <FaqFile
          name="Расходные документы"
          className={styles.file}
          url={LK_API.SECURITIES_INSTRUCTION_DOCUMENTS}
        />
        <FaqFile
          name="Как зачислить ценные бумаги"
          className={styles.file}
          url={LK_API.SECURITIES_INSTRUCTION_TRANSFER}
        />
      </div>
    </>
  );
};
