import { useField } from 'formik';
import React, { FC } from 'react';
import {
  CalendarInputDesktop,
  CalendarInputDesktopProps,
} from '@alfalab/core-components/calendar-input/desktop';

import { useFieldOkState } from 'formik-alfalab-core-components/hooks/useFieldOkState';

type CalendarInputProps = Omit<
  CalendarInputDesktopProps,
  'onInputChange' | 'onChange' | 'error' | 'name'
> & {
  name: string;
};

export const CalendarInput: FC<CalendarInputProps> = (props) => {
  const { name, ...restProps } = props;
  const [field, , helpers] = useField(name);

  const { error } = useFieldOkState(props);

  return (
    <CalendarInputDesktop
      {...field}
      onInputChange={field.onChange}
      onChange={(_, payload) => helpers.setValue(payload.value)}
      error={error}
      {...restProps}
    />
  );
};
