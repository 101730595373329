import { useState } from 'react';
import {
  AmountInput,
  AmountInputProps,
} from '@alfalab/core-components/amount-input';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import {
  InputDesktop,
  InputDesktopProps,
} from '@alfalab/core-components/input/desktop';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { TradingCertificateLock } from '@terminal/common/features-DEPRECATED/TradingCertificateLock';
import { SubAccount } from '@terminal/core/lib/rest/lkAccountInfo';

import { useUpdateSubaccount } from '../../hooks/useUpdateSubaccount';

import styles from './SubaccountRedact.module.css';

interface SubaccountRedactProps {
  treaty: number;
  item: SubAccount;
  availableLimit: number;
  showPortfolioNumber?: boolean;
  onSaved: () => void;
}

const AMOUNT_MINORITY = 100;

export const SubaccountRedact = ({
  treaty,
  item,
  availableLimit,
  showPortfolioNumber = false,
  onSaved,
}: SubaccountRedactProps) => {
  const [accountName, setAccountName] = useState(item.accName || '');
  const [limit, setLimit] = useState<number | null>(
    (item.indLimit || 0) * AMOUNT_MINORITY
  );

  const { isLoading: savePending, mutateAsync: saveSubaccount } =
    useUpdateSubaccount();

  const maxLimit = (availableLimit + item.indLimit) * AMOUNT_MINORITY;
  const maxLimitFormated = formatAmount({
    value: maxLimit,
    currency: 'RUR' as CurrencyCodes,
    minority: AMOUNT_MINORITY,
    view: 'default',
  });

  const onAccountNameChange: InputDesktopProps['onChange'] = (e, { value }) => {
    setAccountName(value);
  };

  const onLimitChange: AmountInputProps['onChange'] = (e, { value }) => {
    setLimit(value);
  };

  const onSubaccountSave = async () => {
    await saveSubaccount({
      params: {
        treaty,
        subAccount: item.accCode,
        nickName: accountName,
        limit: (limit || 0) / AMOUNT_MINORITY,
      },
      signOptions: {
        successTitle: 'Субсчет обновлен',
        successCallback: () => onSaved(),
      },
    });
  };

  const isChanged =
    item.accName !== accountName ||
    (item.indLimit || 0) * AMOUNT_MINORITY !== limit;
  const saveAvailable =
    isChanged && Boolean(accountName) && (limit || 0) <= maxLimit;

  const availableStr = `Доступный лимит: ${maxLimitFormated.formattedWithCurrency}`;

  return (
    <>
      <Typography.Text
        className={styles.title}
        view="primary-small"
        weight="bold"
        tag="div"
      >
        Редактирование{' '}
        {showPortfolioNumber ? (
          <>счёта {treaty}</>
        ) : (
          <>субсчёта {item.accCode}</>
        )}
      </Typography.Text>
      <Space fullWidth={true} direction="vertical">
        <Row gutter={16}>
          <Col width={6}>
            <InputDesktop
              label="Наименование"
              labelView="outer"
              block
              size="s"
              placeholder="Укажите наименование"
              value={accountName}
              onChange={onAccountNameChange}
              onClear={() => setAccountName('')}
              maxLength={20}
            />
          </Col>
          <Col width={6}>
            <AmountInput
              label="Лимит заёмных средств"
              labelView="outer"
              block
              size="s"
              currency="RUR"
              value={limit}
              minority={AMOUNT_MINORITY}
              placeholder="0"
              onChange={onLimitChange}
              bold={false}
              hint={availableStr}
              error={(limit || 0) > maxLimit ? availableStr : ''}
            />
          </Col>
        </Row>

        <TradingCertificateLock>
          <Row gutter={16}>
            <Col width={6}>
              <ButtonDesktop
                dataTestId="subaccount-save-btn"
                size="s"
                block
                leftAddons={
                  <CheckmarkMIcon
                    width={18}
                    height={18}
                    color="var(--color-light-graphic-secondary)"
                  />
                }
                loading={savePending}
                disabled={!saveAvailable}
                onClick={onSubaccountSave}
              >
                Сохранить
              </ButtonDesktop>
            </Col>
          </Row>
        </TradingCertificateLock>
      </Space>
    </>
  );
};
