import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  BankCard,
  BankCardFee,
  getBankCardFee,
} from '@terminal/core/lib/rest/lkBankCard';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useCardFee = (
  amount: number,
  treaty: number | undefined,
  card: BankCard | null
) => {
  const addNotification = useNotification();

  const cardId = card?.id;

  return useQuery<BankCardFee | null, Error>(
    [LkQueryKeys.CardFee, { amount, treaty, cardId }],
    async () => {
      if (!cardId || !treaty) {
        return null;
      }

      return await getBankCardFee({
        sum: amount,
        treaty,
        cardId: cardId,
      });
    },
    {
      enabled: Boolean(treaty) && Boolean(cardId),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error.message || 'Произошла ошибка при запросе комиссии',
        });
      },
    }
  );
};
