import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getFreeOrdersSettings } from '@terminal/core/lib/rest/lkFreeOrders';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../queryKeys';

export const useGetFreeOrderSettings = (treaty?: number) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.FreeOrdersSettings, { treaty }],
    async () => getFreeOrdersSettings({ treaty: treaty || 0 }),
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса настроек произвольных поручений',
          text: error?.message || '',
        });
      },
    }
  );
};
