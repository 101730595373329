import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';

import { MoneyTransferToBankRequest } from './types/TransferGIMoneyToBank';

export const transferGoInvestMoneyToBank = async (
  data: MoneyTransferToBankRequest
) => {
  return await signOperation(
    '/api/transfers/money/bank',
    'POST',
    data.params,
    data.signOptions
  );
};
