import differenceInMinutes from 'date-fns/differenceInMinutes';
import isBefore from 'date-fns/isBefore';

import {
  activationOptionsMap,
  ActivationTime,
} from '../../constants/orderBook';

export const getSuitableActivationType = (
  acceptTime?: Date,
  activationTime?: Date
) => {
  if (!acceptTime || !activationTime) {
    return activationOptionsMap[ActivationTime.NOW];
  }

  const difference = differenceInMinutes(activationTime, acceptTime);

  switch (true) {
    case isBefore(activationTime, Date.now()):
      return activationOptionsMap[ActivationTime.NOW];
    case difference <= 60:
      return activationOptionsMap[ActivationTime.HOUR];
    case difference <= 1440:
      return activationOptionsMap[ActivationTime.DAY];
    case difference <= 10080:
      return activationOptionsMap[ActivationTime.WEEK];
    default:
      return activationOptionsMap[ActivationTime.NOW];
  }
};
