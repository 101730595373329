import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getAccountInfoGI } from '@terminal/core/lib/rest/lkAccountInfo';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useAccountInfoGI = (
  treaty: number | undefined,
  enabled: boolean = true
) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.AccountInfo, { treaty }],
    async () => getAccountInfoGI({ treaty: treaty || 0 }),
    {
      enabled: Boolean(treaty) && enabled,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message || 'При запросе информации о счете произошла ошибка',
        });
      },
    }
  );
};
