import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { useAppContext } from '@terminal/core/hooks/useAppContext';

import { useThemeContext } from '../../../shared/lib/themeContext';

import { StepScreenProps } from '../types';

import styles from './Steps.module.css';

export const FourthStep = ({ style }: StepScreenProps) => {
  const { theme } = useThemeContext();
  const { onboarding } = useAppContext();
  const { links } = onboarding.images[theme];

  return (
    <div style={style} className={styles.slideContainer}>
      <div className={styles.stepContainer}>
        <Space fullWidth>
          <Typography.Text view="primary-large" weight="bold">
            Связывание виджетов
          </Typography.Text>
          <Typography.Text view="secondary-large">
            В терминале существует возможность связывания виджетов. Благодаря
            этой функции окна, которые содержат табличные данные (инструменты,
            позиции и другие), используются в качестве управляющих и передают
            информацию о выбранном инструменте в другие окна группы.
          </Typography.Text>
          <Typography.Text view="secondary-large">
            Чтобы связать виджеты, необходимо добавить их в соответствующую
            цветовую группу на вкладке виджета.
          </Typography.Text>
        </Space>
      </div>
      <div
        className={styles.fourthStepImage}
        style={{ backgroundImage: `url(${links})` }}
      />
    </div>
  );
};
