export const ROUTE = {
  FULL_PATH: '/chat',
  PATH: '/chat',
  FULL_PATTERN: 'chat/*',
  PATTERN: 'chat/*',
  SUPPORT: {
    FULL_PATH: '/chat/support',
    PATH: '/support',
    FULL_PATTERN: 'chat/support',
    PATTERN: 'support',
  },
  BROKER_CHAT: {
    FULL_PATH: '/chat/:id',
    PATH: '/:id',
    FULL_PATTERN: 'chat/:id',
    PATTERN: ':id',
    INSTRUMENT_TRADES: {
      FULL_PATH: '/instrument/:idFi/trades',
    },
  },
};
