import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getCorporateActionHistoryDetail } from '@terminal/core/lib/rest/lkCorporateActions';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useCorporateActionHistoryDetail = (
  treaty: number | undefined,
  orderNumber: string
) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.CorporateActionHistoryDetail, { treaty, orderNumber }],
    async () => {
      if (!treaty) {
        return;
      }

      return await getCorporateActionHistoryDetail({
        treaty,
        ordNo: orderNumber,
      });
    },
    {
      enabled: Boolean(treaty) && Boolean(orderNumber),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса детальной истории корпоративного действия',
          text: error?.message || '',
        });
      },
    }
  );
};
