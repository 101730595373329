import React, { FC, ReactNode } from 'react';

import { Theme, ThemeContextProvider } from '../../../shared/lib/themeContext';
import { DarkStyles } from './DarkStyles';
import { preferredThemeStorage } from './preferredThemeStorage';

const PREFERRED_THEME = preferredThemeStorage.getValue();

interface Props {
  children: (theme: Theme) => ReactNode;
  darkStyles: string;
}

export const ThemeProvider: FC<Props> = (props) => {
  const { children, darkStyles } = props;

  return (
    <ThemeContextProvider
      preferredThemeDefault={PREFERRED_THEME}
      onPreferredThemeChange={preferredThemeStorage.setValue}
    >
      {(theme) => (
        <>
          <DarkStyles styles={darkStyles} />
          {children(theme)}
        </>
      )}
    </ThemeContextProvider>
  );
};
