import { TransferOfDebtMIcon } from '@alfalab/icons-glyph/TransferOfDebtMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';

import { MARGIN_TEST_ID } from '../../../../entities/TransferGoinvest';
import { QualTestPlayer } from '../../../../legacy/QualTesting/QualTestPlayer';

import { IntegratedTestingContext } from '../../../../legacy/QualTesting/hooks/useIntegratedTesting';

interface MarginIntegratedTestProps {
  open: boolean;
  onClose?: () => void;
  backLabel?: string;
  onComplete?: () => void;
}

export const MarginIntegratedTest = ({
  open,
  onClose,
  backLabel,
  onComplete,
}: MarginIntegratedTestProps) => {
  return (
    <Portal id="money-transfer-modals">
      <FunctionalModalSidebar
        open={open}
        backLabel={backLabel}
        onClose={onClose}
      >
        <IntegratedTestingContext.Provider
          value={{
            backButton: {
              label: 'К переводам',
              icon: <TransferOfDebtMIcon />,
              onClick: onComplete,
            },
          }}
        >
          {open && (
            <QualTestPlayer
              selectedTestId={MARGIN_TEST_ID}
              onCloseTest={onClose}
            />
          )}
        </IntegratedTestingContext.Provider>
      </FunctionalModalSidebar>
    </Portal>
  );
};
