import { useEffect, useMemo, useState } from 'react';

import { useFullFI, useObjectExtByIdMap } from '@terminal/core/hooks/domain';
import { OrderType } from '@terminal/core/lib/client/entities';
import { quoteLastSelector } from '@terminal/core/lib/domain/quoteSelector';
import { MarketBoard } from '@terminal/core/types/core';

import { useAlfaDirectContext } from '../provider/react';

interface StreamPriceProps {
  idFi: number;
  idMarketBoard?: MarketBoard;
  side?: 'buy' | 'sell';
  orderType: OrderType;
  price: number;
  setPrice: (price: number) => void;
  last?: number;
  bestAsk?: number;
  bestBid?: number;
}

export const useStreamPrice = ({
  idFi,
  idMarketBoard,
  side,
  orderType,
  price,
  setPrice,
  last,
  bestAsk,
  bestBid,
}: StreamPriceProps) => {
  const { useQuotes } = useAlfaDirectContext();
  const objectExtsById = useObjectExtByIdMap();
  const fullFi = useFullFI(idFi);

  const [isPriceStreamed, setPriceStreamed] = useState(false);

  // Рынок МБ ВР
  const isMarketMB_VR = useMemo(() => {
    return idMarketBoard === MarketBoard.CETS_TOM_USD_TMS;
  }, [idMarketBoard]);

  const idFIBalance = objectExtsById.get(fullFi?.idObject)?.idFIBalance || 0;

  const idFIBalanceQuotes = useQuotes(idFIBalance, {
    throttleTimeout: 0,
    selector: quoteLastSelector,
  });

  //  Елси заявка по рынку, то в поле цена стримиться ласт с рынка
  useEffect(() => {
    setPriceStreamed(orderType === OrderType.MKT && !isMarketMB_VR);
  }, [isMarketMB_VR, orderType]);

  useEffect(() => {
    if (isMarketMB_VR) {
      return setPrice(idFIBalanceQuotes?.[idFIBalance]?.last || 0);
    }

    if (!isPriceStreamed) {
      return;
    }

    // Когда ставим рыночную заявку, при Покупке ставим Best Ask, при Продаже ставим Best Bid
    if (orderType !== OrderType.MKT) {
      return setPrice(last || 0);
    }

    return setPrice((side === 'buy' ? bestAsk : bestBid) || 0);
  }, [
    side,
    bestAsk,
    bestBid,
    orderType,
    last,
    isPriceStreamed,
    setPrice,
    price,
    isMarketMB_VR,
    idFIBalanceQuotes,
    idFIBalance,
  ]);

  return {
    isPriceStreamed,
    setPriceStreamed,
  };
};
