import { createSoundManagerProvider } from '../../shared/lib/soundManager';

export const SoundManagerProvider = createSoundManagerProvider([
  [
    'alert',
    {
      loop: true,
      // eslint-disable-next-line no-process-env
      src: `${process.env.PUBLIC_URL}/sounds/alert.wav`,
    },
  ],
]);
