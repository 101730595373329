import { GetState } from 'zustand';

import { LKResult } from '../../lib/rest/lkApi';
import {
  ServerCertsShort,
  SignCreated,
  SigningTextResponse,
  SignOperationStatusResponse,
} from '../../lib/rest/lkSignOperation';
import { RootStore, StoreSet } from '../useStore';

export interface LkSignOptions {
  successTitle?: string;
  successText?: string;
  successCallback?: (status?: SignOperationStatusResponse) => void;
  failureCallback?: (status?: SignOperationStatusResponse) => void;
  resetCallback?: (status?: SignOperationStatusResponse) => void;
  errorTitle?: string;
  errorText?: string;
  errorCallback?: (result?: LKResult) => void;
  muteError?: boolean;
  confirmation?: LkSignConfirmation;
}

export interface LkSignConfirmation {
  codes?: number[];
}

export interface LkSignConfirmationDialog {
  open: boolean;
  text: string;
}

export interface LkSignProgress {
  count: number;
  signed: number;
}

export interface LkSignSlice {
  lkSignDialogOpen: boolean;
  lkSignOperation: SignCreated | null;
  lkSignText: string; // Текст поручения
  lkSignMultipleText: { [s: string]: string } | null;
  lkSignProgress: LkSignProgress | null;
  lkSignMultipleErrors: string[];
  lkSignServerCerts: ServerCertsShort[];
  lkSignOptions: LkSignOptions | null;
  lkSignConfirmationDialog: LkSignConfirmationDialog;
  openLkSignDialog: () => void;
  setLkSignOperation: (operation: SignCreated) => void;
  setLkSignOperationText: (data: SigningTextResponse) => void;
  setLkSignProgress: (progress: LkSignProgress) => void;
  setLkSignMultipleErrors: (errors: string[]) => void;
  closeLkSignOperation: () => void;
  resetLkSignStore: () => void;
  setLkSignOptions: (options: LkSignOptions) => void;
  openLkSignConfirmation: (text?: string) => void;
  closeLkSignConfirmation: () => void;
}

export const createLkSignSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): LkSignSlice => ({
  lkSignDialogOpen: false,
  lkSignOperation: null,
  lkSignText: '',
  lkSignMultipleText: null,
  lkSignProgress: null,
  lkSignMultipleErrors: [],
  lkSignServerCerts: [],
  lkSignOptions: null,
  lkSignConfirmationDialog: {
    open: false,
    text: '',
  },
  openLkSignDialog: () => {
    set((state) => {
      state.lkSignDialogOpen = true;
    });
  },
  setLkSignOperation: (operation: SignCreated) => {
    set((state) => {
      state.lkSignOperation = operation;
    });
  },
  setLkSignOperationText: (data: SigningTextResponse) => {
    set((state) => {
      state.lkSignText = data.message;
      state.lkSignMultipleText = data.messages || null;
      state.lkSignServerCerts = data.certificates;
    });
  },
  setLkSignProgress: (progress: LkSignProgress) => {
    set((state) => {
      state.lkSignProgress = progress;
    });
  },
  setLkSignMultipleErrors: (errors: string[]) => {
    set((state) => {
      state.lkSignMultipleErrors = errors;
    });
  },
  closeLkSignOperation: () => {
    set((state) => {
      state.lkSignDialogOpen = false;
    });
    // Ждем окончания анимации закрытия окна
    setTimeout(() => {
      get().resetLkSignStore();
    }, 250);
  },
  resetLkSignStore: () => {
    const lkSignOptions = get().lkSignOptions;

    if (typeof lkSignOptions?.resetCallback !== 'undefined') {
      lkSignOptions.resetCallback();
    }

    set((state) => {
      state.lkSignDialogOpen = false;
      state.lkSignOperation = null;
      state.lkSignText = '';
      state.lkSignMultipleText = null;
      state.lkSignProgress = null;
      state.lkSignMultipleErrors = [];
      state.lkSignServerCerts = [];
      state.lkSignOptions = null;
      state.lkSignConfirmationDialog = {
        open: false,
        text: '',
      };
    });
  },
  setLkSignOptions: (options) => {
    set((state) => {
      state.lkSignOptions = options;
    });
  },
  openLkSignConfirmation: (text) => {
    set((state) => {
      state.lkSignConfirmationDialog = {
        open: true,
        text: text || '',
      };
    });
  },
  closeLkSignConfirmation: () => {
    set((state) => {
      state.lkSignConfirmationDialog.open = false;
    });
  },
});
