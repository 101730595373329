import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import sumBy from 'lodash/sumBy';
import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { currenciesFI } from '../../constants/FIModal';
import { RazdelGroupType } from '../../lib/client/entities';
import { getClientOrders } from '../../lib/domain/getClientOrders';
import { getRazdelRequirement } from '../../lib/domain/getRazdelRequirement';
import {
  quoteLastSelector,
  quotePositionSelector,
} from '../../lib/domain/quoteSelector';
import { useOrdersMap } from '../useOrdersMap';
import { useBalance } from './useBalance';
import { useIdFiBalances } from './useIdFiBalances';
import { useOrders } from './useOrders';
import { usePositionNew } from './usePositionNew';
import { usePositions } from './usePositions';
import { useSubAccountRazdel } from './useSubAccountRazdel';

import { MarginCall, MarginCallInfo } from '../../types/marginCall';

/**
 * @deprecated
 * Функция возвращает Требования и Срочные требования по заданному selectedSubAccount
 * TODO: При редизайне мобилки использовать новый хук
 */
export const useRequirements = (selectedSubAccounts: string[]) => {
  const { useFinInfoExt, useQuotes } = useAlfaDirectContext();

  const positions = usePositions();
  const subAccountRazdels = useSubAccountRazdel();
  const { accounts, subGTAccounts } = useAlfaDirectContext();
  const allOrders = useOrders();
  const orders = getClientOrders(allOrders, accounts);

  const positionsToIdFiBalances = useIdFiBalances(positions);
  const fi = useMemo(
    () => Array.from(new Set(positionsToIdFiBalances.values())),
    [positionsToIdFiBalances]
  );
  const finInfoExts = useFinInfoExt(fi);

  const quotes = useQuotes(fi, { selector: quotePositionSelector });
  const currenciesQuotes = useQuotes(currenciesFI, {
    selector: quoteLastSelector,
  });
  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);
  const ordersMap = useOrdersMap(orders);

  const calcPositions = usePositionNew(
    {
      positions,
      quotes,
      finInfoExts,
      subAccountRazdels,
      ordersMap,
      currenciesQuotes,
      currenciesFinInfoExts,
      positionsToIdFiBalances,
    },
    { selectedSubAccounts }
  );
  const accountBalances = useBalance(
    {
      positions: calcPositions,
      currenciesQuotes,
      currenciesFinInfoExts,
      subGTAccounts,
    },
    { selectedSubAccounts }
  );

  return {
    requirements: isNumber(accountBalances[0]?.requirements)
      ? Math.abs(accountBalances[0]?.requirements)
      : 0,
    immediateRequirements: isNumber(accountBalances[0]?.immediateRequirements)
      ? Math.abs(accountBalances[0]?.immediateRequirements)
      : 0,
    liquedBalance: isNumber(accountBalances[0]?.liquedBalance)
      ? accountBalances[0]?.liquedBalance
      : 0,
    money:
      calcPositions.find((item) => item.isMoney && item.currency === 'RUB')
        ?.torgPos || 0,
    requirementsByMarket: accountBalances[0]?.requirementsByMarket,
  };
};

export const getMarketName = (idRazdelGroup: RazdelGroupType) => {
  switch (idRazdelGroup) {
    case RazdelGroupType.StocksAndBondsMarket:
      return 'по рынку ценных бумаг';
    case RazdelGroupType.DerivativesMarket:
      return 'по срочному рынку';
    case RazdelGroupType.CurrencyMarket:
      return 'по валютному рынку';

    default:
      return '';
  }
};

export const getPlaceName = (idRazdelGroup: RazdelGroupType) => {
  switch (idRazdelGroup) {
    case RazdelGroupType.StocksAndBondsMarket:
      return 'Рынок ценных бумаг';
    case RazdelGroupType.DerivativesMarket:
      return 'Срочный рынок';
    case RazdelGroupType.CurrencyMarket:
      return 'Валютный рынок';

    default:
      return '';
  }
};

/**
 * Хук возвращает Требования и Срочные требования по заданным selectedSubAccounts в том числе в разрезе Рынков
 */
export const useRequirement = (
  selectedSubAccounts: string[],
  throttleTimeout?: number
) => {
  const {
    accounts,
    useQuotes,
    useFinInfoExt,
    subGTAccounts,
    subAccountRazdels,
    useOrders,
  } = useAlfaDirectContext();

  const positions = usePositions();
  const currenciesQuotes = useQuotes(currenciesFI, {
    selector: quoteLastSelector,
    throttleTimeout,
  });
  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);

  const allOrders = useOrders();
  // обязательно проверяем orders через shallow
  const orders = getClientOrders(allOrders, accounts);
  const ordersMap = useOrdersMap(orders);

  const positionsToIdFiBalances = useIdFiBalances(positions);
  const fi = useMemo(
    () => Array.from(new Set(positionsToIdFiBalances.values())),
    [positionsToIdFiBalances]
  );
  const finInfoExts = useFinInfoExt(fi);
  const quotes = useQuotes(fi, {
    selector: quotePositionSelector,
    throttleTimeout,
  });

  const calcPositions = usePositionNew(
    {
      positions,
      quotes,
      finInfoExts,
      subAccountRazdels,
      ordersMap,
      currenciesQuotes,
      currenciesFinInfoExts,
      positionsToIdFiBalances,
    },
    { selectedSubAccounts }
  );

  return useMemo(() => {
    //Учитываем фильтры по субсчетам
    const filteredData = calcPositions.filter((tempRow) =>
      selectedSubAccounts.includes(
        String(tempRow.subAccountRazdel.codeSubAccount)
      )
    );

    //Сначала собираем по аккаутам
    const groupedPossitionsBySubAccounts = groupBy(
      filteredData,
      'subAccountRazdel.codeSubAccount'
    );

    if (isEmpty(groupedPossitionsBySubAccounts)) {
      return {};
    } else {
      return Object.entries(groupedPossitionsBySubAccounts).reduce(
        (acc, [code, positions]) => {
          //Во всех формулах производятся рассчеты относительно рынка (портфеля), на котором представлены позиции
          const groupedPossitionsByMarkets = groupBy(
            positions,
            'subAccountRazdel.idRazdelGroup'
          );

          if (isEmpty(groupedPossitionsByMarkets)) {
            return {};
          } else {
            const marketsMarginCall = Object.entries(
              groupedPossitionsByMarkets
            ).reduce((acc, [idRazdelGroup, positions]) => {
              const idSubAccount =
                head(positions)?.subAccountRazdel?.idSubAccount;

              return {
                ...acc,
                [idRazdelGroup]: getRazdelRequirement({
                  idRazdelGroup: Number(idRazdelGroup),
                  subGTAccounts,
                  idSubAccount,
                  positions,
                }),
              };
            }, {} as { [idRazdelGroup: string]: MarginCall });

            const initialMargin = sumBy(
              Object.values(marketsMarginCall),
              'initialMargin'
            );
            const minimumMargin = sumBy(
              Object.values(marketsMarginCall),
              'minimumMargin'
            );
            const liquedBalance = sumBy(
              Object.values(marketsMarginCall),
              'liquedBalance'
            );
            const requirements = sumBy(
              Object.values(marketsMarginCall),
              'requirements'
            );
            const immediateRequirements = sumBy(
              Object.values(marketsMarginCall),
              'immediateRequirements'
            );

            return {
              ...acc,
              [code]: {
                initialMargin,
                minimumMargin,
                liquedBalance,
                requirements,
                immediateRequirements,
                marketsMarginCall,
              },
            };
          }
        },
        {} as MarginCallInfo
      );
    }
  }, [calcPositions, selectedSubAccounts, subGTAccounts]);
};

export const MarketGroupNamesMap = new Map<number, string>([
  [RazdelGroupType.None, 'N/A'],
  [RazdelGroupType.StocksAndBondsMarket, 'РЦБ'],
  [RazdelGroupType.DerivativesMarket, 'ФОРТС'],
  [RazdelGroupType.CurrencyMarket, 'Валютный рынок'],
]);
