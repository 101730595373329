import cn from 'classnames';
import { ReactNode } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowBackMIcon } from '@alfalab/icons-glyph/ArrowBackMIcon';

import { getTitleNode } from '../../utils';
import { InputControl } from '../InputControl';

// eslint-disable-next-line no-restricted-imports
import styles from '../../screens/ChatScreen.module.css';
// eslint-disable-next-line no-restricted-imports
import layoutMobileStyles from '@terminal/common/styles/mobile/layout.module.css';

interface CHatRoomLayoutProps {
  children: ReactNode;
  chatRoomTitle: string;
  chatRoomConnection: ReactNode;
  sendMessage?: (text: string) => Promise<void>;
  attachFiles?: () => Promise<void>;
  mobile: boolean;
  onBack: () => void;
}

export const ChatRoomLayout = ({
  children,
  chatRoomTitle,
  chatRoomConnection,
  sendMessage,
  attachFiles,
  mobile,
  onBack,
}: CHatRoomLayoutProps) => {
  return (
    <div
      className={cn(layoutMobileStyles.screen, {
        [styles.pcRoomWindow]: !mobile,
      })}
    >
      <div
        className={cn(
          layoutMobileStyles.header,
          mobile ? styles.chatRoomHeaderMobile : styles.chatRoomHeader
        )}
      >
        <ArrowBackMIcon
          height={24}
          width={24}
          color="#8D8D93"
          onClick={onBack}
          className={layoutMobileStyles.backButton}
        />
        <div
          className={cn(layoutMobileStyles.headerTitle, {
            [styles.pcHeaderTitle]: !mobile,
          })}
        >
          <div
            className={cn({
              [styles.chatRoomTitleMobile]: mobile,
            })}
          >
            {getTitleNode(chatRoomTitle, mobile)}
            <Typography.Text
              tag="div"
              view={mobile ? 'secondary-medium' : 'secondary-small'}
              color="secondary"
            >
              {chatRoomConnection}
            </Typography.Text>
          </div>
        </div>
      </div>
      <div className={styles.main}>{children}</div>
      {sendMessage ? (
        <div className={styles.footer}>
          <InputControl
            mobile={mobile}
            attachFiles={attachFiles}
            sendMessage={sendMessage}
            disabled={!mobile}
          />
        </div>
      ) : null}
    </div>
  );
};
