import { useRef, useState } from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import { BankCardTransactionExecuteResult } from '@terminal/core/lib/rest/lkBankCard';
import { CardTransaction } from '@terminal/core/lib/rest/lkMoney';
import { getParamsFromString } from '@terminal/core/lib/url';

import { parseMoney } from '../../../entities/TransferGoinvest';

import styles from './ThreeDS.module.css';

interface ThreeDSProps {
  transaction: CardTransaction;
  onCancel: () => void;
  onComplete: (result: BankCardTransactionExecuteResult) => void;
}

export const ThreeDS = ({
  transaction,
  onCancel,
  onComplete,
}: ThreeDSProps) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const sendForm = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const transactionParams = getParamsFromString(
    new URL(transaction.formUrl).search
  );

  const onIframeLoad = () => {
    try {
      const href = iframeRef?.current?.contentWindow?.location.href;

      if (!href || href === 'about:blank') {
        return;
      }

      const path =
        iframeRef?.current?.contentWindow?.location.pathname.split('/');

      if (!path || path[path.length - 1] !== 'complete') {
        return;
      }

      const params = getParamsFromString(
        iframeRef?.current?.contentWindow?.location.search || ''
      );

      onComplete({
        code: parseInt(decodeURIComponent(params.code)),
        message: decodeURIComponent(params.message),
        amount: parseMoney(decodeURIComponent(params.amount)),
        currency: 'RUR',
      });
    } catch (err: any) {
      setIframeLoaded(true);
    }
  };

  return (
    <BaseModal
      open={true}
      onClose={onCancel}
      className={styles.modal}
      wrapperClassName={styles.modalWrapper}
      onMount={sendForm}
    >
      <FunctionalModal
        title="Подтверждение"
        onClose={onCancel}
        width={540}
        contentStyle={{ padding: 0 }}
      >
        <div className={styles.container}>
          <form
            method="GET"
            action={String(transaction.formUrl)}
            target="three-ds-iframe"
            ref={formRef}
          >
            {Object.keys(transactionParams).map((param) => (
              <input
                type="hidden"
                name={param}
                value={transactionParams[param]}
              />
            ))}
          </form>
          <div className={styles.loading}>
            {iframeLoaded && (
              <Typography.Text
                view="primary-small"
                className={styles.loadingTitle}
                tag="div"
              >
                Операция выполняется.
                <br />
                Пожалуйста, подождите.
              </Typography.Text>
            )}
            <Loader className={styles.loader} />
          </div>
          <iframe
            ref={iframeRef}
            title="ThreeDS proccess"
            name="three-ds-iframe"
            src=""
            className={styles.iframe}
            style={{ overflow: 'hidden', height: '667px', width: '100%' }}
            height="667"
            width="100%"
            frameBorder="0"
            onLoad={onIframeLoad}
          />
        </div>
      </FunctionalModal>
    </BaseModal>
  );
};
