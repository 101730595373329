import { ClientMessage, ClientType, Messages } from '../lib/client/entities';
import { detectOSType } from '../lib/device';

export const userCredentialsErrors = [
  Messages.AuthenticationLoginFailed,
  Messages.AuthenticationFailedTokenExpired,
  Messages.AuthenticationFailedInvalidToken,
  Messages.AuthenticationFailedInternalError,
  Messages.AuthenticationFailedAuthorizationCodeExpired,
  Messages.AuthenticationFailedInvalidAuthorizationCode,
  Messages.AuthenticationFailedUnknownCUS,
  Messages.AuthenticationFailedClientRegistrationInProgress,
];
export const userCredentialsLoginDataError = Messages.AuthenticationLoginFailed;
export const clientMessageError = ClientMessage.FrontEndAuthenticationFailed;
export const clientMessageSuccess = ClientMessage.FrontEndAuthenticationSuccess;

export const defaultUserData = {
  MacAdress: 'a0:78:17:85:5f:e6',
  ProtocolVersion: '9.106',
  ClientVersion: '0.0.1',
  OSVersion: '11.4',
  OSType: detectOSType(),
  DeviceModel: 'my-device',
  ClientType: ClientType.Web,
  HomeDir: '/prod/home',
  FilesDir: '/prod/cache',
  UserName: 'username',
  ConnectionIP: '91.144.140.253',
};
