import {
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  startOfYesterday,
} from 'date-fns';
import { useEffect } from 'react';

import { IntervalIds } from '../constants/filters';

type useFilterDateRangeProps = {
  selectedInterval: IntervalIds;
  onChange: (from: Date, to: Date) => void;
  toggleCalendar: (value: boolean) => void;
};

export const useFilterDateRange = ({
  selectedInterval,
  toggleCalendar,
  onChange,
}: useFilterDateRangeProps) => {
  useEffect(() => {
    let from = new Date();
    let to = new Date();

    switch (selectedInterval) {
      case IntervalIds.YESTERDAY: {
        from = startOfYesterday();
        to = startOfYesterday();
        break;
      }
      case IntervalIds.WEEK: {
        to = addWeeks(from, 1);
        break;
      }
      case IntervalIds.MONTH: {
        to = addMonths(from, 1);
        break;
      }
      case IntervalIds.QUARTER: {
        to = addQuarters(from, 1);
        break;
      }
      case IntervalIds.YEAR: {
        to = addYears(from, 1);
        break;
      }
      case IntervalIds.CUSTOM: {
        toggleCalendar(true);

        return;
      }
      default: {
        return;
      }
    }

    toggleCalendar(false);
    onChange(from, to);
  }, [selectedInterval, toggleCalendar, onChange]);
};
