import {
  TrackPriceCommonSettings,
  TrackPriceSettingLineOpacity,
  TrackPriceSettingLineType,
  TrackPriceSettings,
  TrackPriceSettingTitlePosition,
} from '../types/trackPrice';

export const TRACK_PRICE_COMMON_SETTINGS_DEFAULT: TrackPriceCommonSettings = {
  notify: true,
  lineType: TrackPriceSettingLineType.Solid,
  lineOpacity: TrackPriceSettingLineOpacity.Opacity75,
  titlePosition: TrackPriceSettingTitlePosition.Middle,
};

export const TRACK_PRICE_SETTINGS_DEFAULT: TrackPriceSettings = {
  ...TRACK_PRICE_COMMON_SETTINGS_DEFAULT,
  title: '',
};

/**
 * Фин. инструмент по умолчанию для формы создания алерта (Сбербанк)
 * */
export const NEW_TRACK_PRICE_DEFAULT_ID_FI = 144950;
