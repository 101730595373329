import { useRef } from 'react';
import { Select } from '@alfalab/core-components/select';
import { Switch } from '@alfalab/core-components/switch';
import { Typography } from '@alfalab/core-components/typography';

import {
  trackIssueApprove,
  trackSelectName,
} from '@terminal/core/lib/analytics';
import { shallow, useStore } from '@terminal/core/store';

import { OrderDimension } from './OrderDimension/OrderDimension';

import styles from './DefaultValues.module.css';

const displayInstrumentTypeOptions = [
  {
    key: 'name',
    content: 'Наименование',
    value: 'name',
  },
  {
    key: 'symbol',
    content: 'Тикер',
    value: 'symbol',
  },
];

export const DefaultValues = () => {
  const displayInstrumentTypeContainerRef = useRef<HTMLDivElement>(null);
  const [settings, setSetting] = useStore(
    (state) => [state.settings, state.setSetting],
    shallow
  );

  return (
    <div className={styles.container}>
      <Switch
        checked={settings.defaultValues.alwaysConfirmOrders}
        label="Подтверждать отправку заявки"
        onChange={() => {
          trackIssueApprove(
            settings.defaultValues.alwaysConfirmOrders ? 'ask' : 'notask'
          );
          setSetting(
            'defaultValues',
            'alwaysConfirmOrders',
            !settings.defaultValues.alwaysConfirmOrders
          );
        }}
        block
      />
      <div className={styles.desktop}>
        <Typography.Text view="primary-small" color="primary" weight="bold">
          Отображение инструментов
        </Typography.Text>
        <div className={styles.displayInstrumentTypeSelectTextContainer}>
          <Typography.Text
            view="secondary-small"
            color="secondary"
            className={styles.displayInstrumentTypeSelectText}
          >
            Обозначение инструментов в списках будет производиться по выбранному
            параметру
          </Typography.Text>
        </div>
        <div
          ref={displayInstrumentTypeContainerRef}
          className={styles.selectContainer}
        >
          <Select
            size="s"
            block
            options={displayInstrumentTypeOptions}
            selected={settings.defaultValues.selectedDisplayInstrumentType}
            onChange={({ selected }) => {
              if (selected) {
                trackSelectName(selected.value);
                setSetting(
                  'defaultValues',
                  'selectedDisplayInstrumentType',
                  selected.value
                );
              }
            }}
          />
        </div>
      </div>
      <OrderDimension mobileVersion={false} />
    </div>
  );
};
