import { Gap } from '@alfalab/core-components/gap';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
import { Typography } from '@alfalab/core-components/typography';

interface TestProgressProps {
  current: number;
  count: number;
  className?: string;
}

export const TestProgress = ({
  current,
  count,
  className,
}: TestProgressProps) => {
  const progress = Math.ceil(((current - 1) / count) * 100);

  return (
    <div className={className}>
      <Typography.Text view="secondary-small" color="secondary">
        Вопрос {current} из {count}: Пройдено на {progress}%
      </Typography.Text>
      <Gap size="xs" />
      <ProgressBar value={progress} view="accent" />
    </div>
  );
};
