import { ReactNode } from 'react';

import { ObjectGroup, OrderStatus } from '../lib/client/entities';
import { PUShowMode } from './subAccountPosition';
import { TableColumnKey } from './tableColumn';
import { TradeLimitsResult } from './tradeLimits';
import { SlippageType, StopOrderType } from './ui';

export enum FilterTypes {
  Text = 'text',
  Array = 'array',
  DateRange = 'dateRange',
  NumberRange = 'numberRange',
}

export type ColumnFilterRenderProps = (
  columnKey: TableColumnKey,
  filterType: FilterType,
  filter?: Filter,
  filterSettings?: FilterSettings,
  onFilter?: (filter?: Filter) => void
) => ReactNode;

export type FilterType =
  | FilterTypes.Text
  | FilterTypes.Array
  | FilterTypes.DateRange
  | FilterTypes.NumberRange;
export type FilterSettings = {
  filterItems?: string[];
  min?: number;
  max?: number;
  step?: number;
  maxDate?: Date;
};

export enum Widget {
  WATCHLIST = 'watchlist',
  TRADES = 'trades',
  ORDERBOOK = 'orderbook',
  ASSETS = 'assets',
  CHART = 'chartiq',
  POSITIONS = 'positions',
  OPERATIONS = 'operations',
  ORDERS = 'orders',
  BALANCE = 'balance',
  DEVTOOLS = 'devtools',
  CERTIFICATES = 'certificates',
  HYBRIDORDER = 'hybridorder',
  OPERATIONS_HISTORY = 'operations_history',
  NEWS = 'news',
  INSTRUMENT_INFO = 'instrument_info',
}

export enum ElementType {
  ROW = 'row',
  TABSET = 'tabset',
  TAB = 'tab',
}

export interface IJsonRowNode extends IRowAttributes {
  children: IJsonRowNode[];
  component?: Widget;
}

export interface IRowAttributes {
  height?: number;
  id?: string;
  type: ElementType;
  weight?: number;
  width?: number;
}

export interface WidgetProps extends WidgetLinkProps {
  nodeId?: string;
}

export interface BalanceProps extends WidgetProps {}

export interface ChartProps extends WidgetProps {
  idFi: number;
  isDayTheme?: boolean;
  columnFilterRender?: ColumnFilterRenderProps;
}

export interface HybridOrderProps extends WidgetProps {
  idFi: number;
  hideActiveOrders?: boolean;
}

export interface InstrumentInfoProps extends WidgetProps {
  idFi: number;
}

export interface OperationsProps extends WidgetProps {}

export interface OrderBookProps extends WidgetProps {
  idFi: number;
  autoCenter?: boolean;
  hideForm?: boolean;
  showSpread?: boolean;
  showYield?: boolean;
  showEmptyPrice?: boolean;
  sendTrallingOrders?: boolean;
  stopOrderType?: StopOrderType;
  slippageType?: SlippageType;
  slippageValue?: number;
}

export interface OrderTableProps extends WidgetProps {}

export interface PositionProps extends WidgetProps {}

export interface FiHighlight {
  highlightActive: boolean;
  highlightFrom?: number;
}

export interface TradesProps extends WidgetProps {
  timer?: number;
  timerActive: boolean;
  highlight?: Record<string, FiHighlight>;
}

export interface WatchListProps extends WidgetProps {
  selectedListId?: string;
}

export type BaseWidgetConfig =
  | TradesProps
  | WatchListProps
  | OrderBookProps
  | ChartProps
  | BalanceProps
  | OperationsProps
  | PositionProps
  | WidgetLinkProps
  | HybridOrderProps
  | InstrumentInfoProps;

export interface TableProps {
  columns?: TableColumnSetting[];
  accounts?: string[];
  orderStatus?: OrderStatus[];
  positionTypes?: ObjectGroup[];
  selectedPUMode?: PUShowMode;
  sort?: Sort;
  filter?: Filter;
}

export interface TableColumnSetting {
  key: TableColumnKey;
  // Для случаев когда название колонки не является текстом
  renderHeader?: ReactNode;
  /**
   * @deprecated используйте поле `renderHeader` или key для определения имени: `@terminal/core/lib/helpers/getColumnName`
   */
  name?: string | JSX.Element;
  /**
   * @deprecated для определения описания используйте `@terminal/core/lib/helpers/getColumnDescription`
   */
  description?: string;
  selected?: boolean;
  cellClassName?: string;
  headerCellClassName?: string;
  filterType?: FilterType;
  filterSettings?: FilterSettings;
  width?: number;
  secondRow?: TableColumnSetting;
  isDragDisabled?: boolean;
}

export enum LinkName {
  YELLOW = 'yellow',
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  DEFAULT = 'default',
}

export const LinkNamesMap = new Map<LinkName, string>([
  [LinkName.YELLOW, 'Оранжевая группа'],
  [LinkName.BLUE, 'Синяя группа'],
  [LinkName.RED, 'Красная группа'],
  [LinkName.GREEN, 'Зеленая группа'],
  [LinkName.DEFAULT, 'Без группы'],
]);

export interface Link {
  nodeIds: string[];
  tableProps?: TableProps;
  idFi?: number;
  symbol?: string;
}

export type LinkGroups = Record<string, Link>;

export enum TradingLimitsShowMode {
  NONE = 0,
  MONEY = 1,
  // Для NPU = Проценты, для лимитов = Штуки/Лоты(см. общую настройку отображения)
  QUANTITY = 2,
  // Для NPU = Пункты
  POINTS = 3,
}

export interface LimitsProps {
  showPosition?: boolean;
  showLimits?: TradingLimitsShowMode;
  showNPU?: TradingLimitsShowMode;
}

export interface ContextMenuProps {
  showBestBuy?: boolean;
  showMarketBuy?: boolean;
}

export interface BalanceSettingsProps {
  showBalance?: boolean;
  showPU: boolean;
  showGO?: boolean;
}

export interface WidgetLinkProps {
  limitsProps?: LimitsProps;
  balanceSettingsProps?: BalanceSettingsProps;
  tableProps?: TableProps;
  contextMenuProps?: ContextMenuProps;
  idFi?: number;
  symbol?: string;
  link?: LinkName;
  linkedPrice?: number;
}

export interface Sort {
  key: string;
  asc: boolean;
}

export interface Filter {
  key: string;
  type: FilterType;
  value?: any;
  filterItems?: string[];
}

export type TradingLimitsProps = {
  idFi: number;
  selectedSubAccountId: number;
  selectedSubAccounts: string[];
  nodeId?: string;
  limitsProps?: LimitsProps;
  tradeLimitsLong: TradeLimitsResult | null;
  tradeLimitsShort: TradeLimitsResult | null;
};

export type TradingLimitsRenderProps = (props: TradingLimitsProps) => ReactNode;
