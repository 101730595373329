import { useCallback, useEffect, useRef, useState } from 'react';

interface UseImageProps {
  src?: string;
}

export const loadImage = async (src: string) => {
  const response = await fetch(src);
  return response.blob();
};

export const loadImageToBlob = async (src: string) => {
  const image = await loadImage(src);
  return URL.createObjectURL(image);
};

/**
 * загружает картинку и сохраняет в BLOB url
 * @param src - адрес
 * @returns {
 *   src: string; BLOB урл
 *   showFallback: boolean; ошибка загрузки
 * }
 */

export const useImage = ({ src }: UseImageProps) => {
  const [showFallback, setShowFallback] = useState(!src);
  const refUrl = useRef('');
  const release = useCallback(() => {
    if (refUrl.current) {
      URL.revokeObjectURL(refUrl.current);
      refUrl.current = '';
    }
  }, [refUrl]);
  const retain = useCallback(
    (value: string) => {
      release();
      refUrl.current = value;
    },
    [refUrl, release]
  );
  useEffect(() => {
    let cancelled = false;
    (async () => {
      if (src) {
        setShowFallback(false);
        try {
          const url = await loadImageToBlob(src);
          if (cancelled) return URL.revokeObjectURL(url);
          retain(url);
        } catch (e) {
          setShowFallback(true);
        }
      } else {
        setShowFallback(true);
      }
    })();
    return () => {
      release();
    };
  }, [src, retain, release, setShowFallback]);
  return { src: refUrl.current, showFallback };
};
