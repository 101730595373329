/**
 * Аналог lodash/sumBy, но возвращает null, если хотя бы одно из значений не number
 */
export const sumByOrNull = <K extends string, O extends Record<K, any>>(
  arr: Array<O>,
  field: K
) => {
  let result = 0;

  for (let item of arr) {
    const value = item[field];

    if (typeof value !== 'number' || Number.isNaN(value)) {
      return null;
    }

    result += value;
  }

  return result;
};
