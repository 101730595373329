import { ElementType } from '@terminal/core/types/layout';

import { BaseLayoutBlock } from './baseLayoutBlock';
import { Tab } from './tab';

export class TabSet extends BaseLayoutBlock {
  readonly weight: number;
  readonly children: Array<Tab>;

  constructor(weight: number) {
    super(ElementType.TABSET);
    this.weight = weight;
    this.children = [];
  }

  addChildren(children: Array<Tab>) {
    this.children.push(...children);
  }

  render() {
    return {
      type: this.type,
      id: this.id,
      weight: this.weight,
      children: this.children.map((child) => child.render()),
    };
  }
}
