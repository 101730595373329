import { useEffect } from 'react';

import { useActiveConfigurationModel } from '@terminal/core/hooks/useActiveConfigurationModel';

import { WIDGET_TAB_HEIGHT } from './const';
import { setWidgetTabHeight } from './helpers';

export const useModelMigration = () => {
  const model = useActiveConfigurationModel();

  useEffect(() => {
    if (model._getAttribute('tabSetTabStripHeight') !== WIDGET_TAB_HEIGHT) {
      setWidgetTabHeight(model, WIDGET_TAB_HEIGHT);
    }
  }, [model]);
};
