import { DataTable, DataTableMutation } from '../../entities';

import {
  InstrumentItem,
  MarketBoardItem,
  ObjectExtItem,
  ObjectItem,
  ObjectTypeItem,
} from '../../../types/core';

export const createObjectsTable = (
  mutations?: DataTableMutation<ObjectItem, 'isObjectAvailable'>
) =>
  new DataTable<
    ObjectItem,
    { idObject: 'one'; isin: 'one' },
    'isObjectAvailable'
  >(
    [
      {
        key: 'idObject',
        relation: 'one',
      },
      {
        key: 'isin',
        relation: 'one',
      },
    ],
    'objectsTable',
    mutations
  );

export const createMarketboardsTable = (
  mutations?: DataTableMutation<MarketBoardItem, 'isMarketBoardAvailable'>
) =>
  new DataTable<
    MarketBoardItem,
    { idMarketBoard: 'one'; universalMarketCode: 'one' },
    'isMarketBoardAvailable'
  >(
    [
      {
        key: 'idMarketBoard',
        relation: 'one',
      },
      {
        key: 'universalMarketCode',
        relation: 'one',
      },
    ],
    'marketBoardsTable',
    mutations
  );

export const createObjectTypesTable = (
  mutations?: DataTableMutation<ObjectTypeItem>
) =>
  new DataTable<ObjectTypeItem, { idObjectType: 'one' }>(
    [
      {
        key: 'idObjectType',
        relation: 'one',
      },
    ],
    'objectTypesTable',
    mutations
  );

export const createObjectExtsTable = (
  mutations?: DataTableMutation<ObjectExtItem>
) =>
  new DataTable<ObjectExtItem, { idObject: 'many' }>(
    [
      {
        key: 'idObject',
        relation: 'one',
      },
    ],
    'objectExtsTable',
    mutations
  );

export const createFinInstrumentsTable = (
  mutations?: DataTableMutation<InstrumentItem>
) =>
  new DataTable<
    InstrumentItem,
    { idFI: 'one'; idObject: 'many'; idMarketBoard: 'many' }
  >(
    [
      {
        key: 'idFI',
        relation: 'one',
      },
      {
        key: 'idObject',
        relation: 'many',
      },
      {
        key: 'idMarketBoard',
        relation: 'many',
      },
    ],
    'finInstrumentsTable',
    mutations
  );
