import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { SendSelfTransferOperationDTO } from './types/SendSelfTransferOperationDTO';
import { SendSelfTransferOperationParams } from './types/SendSelfTransferOperationParams';

export const sendSelfTransferOperation = async (
  data: SendSelfTransferOperationParams
): Promise<SendSelfTransferOperationDTO> => {
  const res = await fetchLkResult<SendSelfTransferOperationDTO | LKResult>(
    '/api/self-transfer/operation',
    'POST',
    data
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};
