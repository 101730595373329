import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { postCheckAnswer } from '@terminal/core/lib/rest/lkTesting';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../queryKeys';

export const usePostCheckAnswer = (
  testId: number,
  questionId: number | undefined,
  sessionId: string | undefined,
  questionNumber: number,
  answerId: (number | null)[],
  allAnswers: (number | null)[][]
) => {
  const addNotification = useNotification();

  return useMutation(
    [LkQueryKeys.CheckAnswer],
    async () =>
      postCheckAnswer(
        testId,
        questionId,
        sessionId,
        questionNumber,
        answerId,
        allAnswers
      ),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка проверки ответа',
          text: error?.message || '',
        });
      },
    }
  );
};
