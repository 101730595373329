// Документация https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=1581894027
export enum TECH_MESSAGE_CODE {
  MAIN = 'terminal_main_tech_message', // Текст тех. сообщения для рабочего стола
  TRADE = 'terminal_trade_tech_message', // Текст тех. сообщения для виджетов с торговой формой (торговый приказ, стакан, график)
  POSITIONS_BALANCES = 'terminal_positions_balances_tech_message', // Текст тех. сообщения для виджетов Позиции, Балансы
  MONEY_TRANSFERS = 'terminal_money_transfers_tech_message', // Текст тех. сообщения для окна Перевод денежных средств
  LK_ELECTRONIC_SIGNATURE = 'terminal_lk_electronic_signature_tech_message', // Текст тех. сообщения для вкладки Электронная подпись в Личном кабинете терминала
  AUTHORIZATION = 'terminal_authorization_tech_message', // Текст тех. сообщения на экране Авторизация
  OPEN_ACCOUNT = 'terminal_open_account_tech_message', // Текст тех. сообщения для окна Открытие счета
  SUPPORT = 'terminal_support_tech_message', // Текст тех. сообщения для Поддержки
}

export type RemoteConfigResponse = Record<TECH_MESSAGE_CODE, string>;
