import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getContestLadder } from '../api/getContestLadder';

import type { ContestLadderParams } from '../model/types/ContestLadderParams';

export const useContestLadder = (params: ContestLadderParams) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'contest/ladder-top', params],
    () => getContestLadder(params),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message ||
            'При запросе топа турнирной таблицы произошла ошибка',
        });
      },
    }
  );
};
