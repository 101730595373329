import cn from 'classnames';
import { useCallback, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Space } from '@alfalab/core-components/space';
import { Tab, Tabs, TabsProps } from '@alfalab/core-components/tabs';
import { Typography } from '@alfalab/core-components/typography';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';

import { DepoAccountInfo } from '@terminal/core/lib/rest/lkAccountInfo';
import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import { DepoRequisitesRequest } from '@terminal/core/lib/rest/lkCommon';
import { useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';

import { MoneyRequisitesFooter } from '../../../transfer/tabs/MoneyRequisites/MoneyRequisitesFooter';
import {
  DepoRequisitesInfoEurotrade,
  DepoRequisitesInfoMicexShr,
  DepoRequisitesInfoSpbexFshrBeb,
  DepoRequisitesInfoSpbexFshrClearstream,
  DepoRequisitesInfoSpbexFshrEuroclear,
  DepoRequisitesInfoSpbexFshrNrd,
} from './RequisitesInfo';
import { RequisitesValue } from './RequisitesValue';

import { useDepoRequisitesFile, useSendDepoRequisites } from '../../../hooks';

import lkFormstyles from '../../../styles/forms.module.css';
import styles from './DetailRequisites.module.css';
import formstyles from '@terminal/common/styles/desktop/form.module.css';

interface DetailDepoRequisitesProps {
  account: DepoAccountInfo;
  treaty: number;
  openDate: string;
  spbClientField: string;
  email: string;
  emailConfirmed: boolean;
  isMobile?: boolean;
}

enum SpbCaseType {
  beb = 'beb',
  nrd = 'nrd',
  euroclear = 'euroclear',
  clearstream = 'clearstream',
}

export type PdfFilesCache = Partial<Record<SpbCaseType, DownloadedFile>>;

export const DetailDepoRequisites = ({
  account,
  treaty,
  openDate,
  spbClientField,
  email,
  emailConfirmed,
  isMobile,
}: DetailDepoRequisitesProps) => {
  const [spbCase, setSpbCase] = useState<SpbCaseType>(SpbCaseType.beb);
  const [pdfFile, setPdfFile] = useState<PdfFilesCache>();

  const setHeaderMenuOpen = useStore((state) => state.setHeaderMenuOpen);

  const { mutateAsync: sendDepoRequisites, isLoading: sending } =
    useSendDepoRequisites();

  const { isLoading: pdfLoading, mutateAsync: loadPdfFile } =
    useDepoRequisitesFile();

  const handleChangeTab: TabsProps['onChange'] = (_, { selectedId }) =>
    setSpbCase(selectedId as SpbCaseType);

  const sendRequisites = async () => {
    if (treaty && email) {
      const data: DepoRequisitesRequest = {
        treaty: treaty.toString(),
        depoaccount: account.depoAccount,
        email: email,
      };

      if (account.placeCode === 'SPBEX_FSHR') {
        data.market = spbCase;
      }

      sendDepoRequisites(data);
    }
  };

  const onChangeEmail = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.PROFILE_SETTINS);
  }, [setHeaderMenuOpen]);

  const loadPdf = async () => {
    const pdf = await loadPdfFile({
      treaty,
      depoaccount: account ? account.depoAccount : '',
      market: account.placeCode === 'SPBEX_FSHR' ? spbCase : '',
    });

    setPdfFile((prev) => {
      const prevValue = prev || {};

      return { ...prevValue, [spbCase]: pdf };
    });
  };

  let marketTitle = 'Рынок';

  if (account.placeCode === 'EUROTRADE') {
    marketTitle = 'Площадка';
  }

  const isGoInvest = !isMobile;

  return (
    <div className={styles.container}>
      <div className={lkFormstyles.header}>
        <Typography.Text view="secondary-large" className={lkFormstyles.title}>
          Реквизиты
        </Typography.Text>
      </div>

      <Space fullWidth={true} direction="vertical">
        {account.placeCode === 'SPBEX_FSHR' && (
          <Tabs
            className={cn(!isMobile && formstyles.tabsDesktop)}
            selectedId={spbCase}
            onChange={handleChangeTab}
            scrollable={false}
            size="s"
          >
            <Tab
              title={isGoInvest ? 'ПАО СПБ' : 'ПАО БЭБ'}
              id={SpbCaseType.beb}
            ></Tab>
            <Tab title="НКО АО НРД" id={SpbCaseType.nrd}></Tab>
            <Tab
              title="Euroclear"
              id={SpbCaseType.euroclear}
              hidden={isGoInvest}
            ></Tab>
            <Tab
              title="Clearstream"
              id={SpbCaseType.clearstream}
              hidden={isGoInvest}
            ></Tab>
          </Tabs>
        )}
        <Row>
          <Col
            className={styles.mobileColumn}
            width={{ desktop: 6, mobile: 12, tablet: 12 }}
          >
            <RequisitesValue title="Депо счет" value={account.depoAccount} />
          </Col>
          <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
            <RequisitesValue title={marketTitle} value={account.placeName} />
          </Col>
        </Row>
        {(() => {
          switch (account.placeCode) {
            case 'MICEX_SHR':
              return (
                <DepoRequisitesInfoMicexShr
                  treaty={treaty.toString()}
                  openDate={openDate}
                  isGoInvest={!isMobile}
                />
              );
            case 'EUROTRADE':
              return (
                <DepoRequisitesInfoEurotrade
                  treaty={treaty.toString()}
                  openDate={openDate}
                  isGoInvest={!isMobile}
                />
              );
            case 'SPBEX_FSHR':
              switch (spbCase) {
                case SpbCaseType.beb:
                  return (
                    <DepoRequisitesInfoSpbexFshrBeb
                      treaty={treaty.toString()}
                      openDate={openDate}
                      spbClientField={spbClientField}
                      isGoInvest={!isMobile}
                    />
                  );
                case SpbCaseType.nrd:
                  return (
                    <DepoRequisitesInfoSpbexFshrNrd
                      treaty={treaty.toString()}
                      openDate={openDate}
                      isGoInvest={!isMobile}
                    />
                  );
                case SpbCaseType.euroclear:
                  return (
                    <DepoRequisitesInfoSpbexFshrEuroclear
                      treaty={treaty.toString()}
                      openDate={openDate}
                      isGoInvest={!isMobile}
                    />
                  );
                case SpbCaseType.clearstream:
                  return (
                    <DepoRequisitesInfoSpbexFshrClearstream
                      treaty={treaty.toString()}
                      openDate={openDate}
                      isGoInvest={!isMobile}
                    />
                  );
              }
          }
        })()}
        <div className={styles.actions}>
          <Row gutter={16}>
            <Col
              className={styles.mobileColumn}
              width={{ desktop: 6, mobile: 12, tablet: 12 }}
            >
              <Button
                className={lkFormstyles.buttonXS}
                size={isMobile ? 'xs' : 'xxs'}
                view="tertiary"
                block
                onClick={pdfFile?.[spbCase] ? undefined : loadPdf}
                href={pdfFile?.[spbCase]?.url}
                target={pdfFile?.[spbCase] ? '_blank' : undefined}
                loading={pdfLoading}
                leftAddons={
                  <OutsideMIcon
                    width={18}
                    height={18}
                    className={lkFormstyles.icon}
                  />
                }
              >
                {pdfFile?.[spbCase] ? 'Открыть в PDF' : 'Скачать PDF'}
              </Button>
            </Col>
          </Row>
        </div>

        <MoneyRequisitesFooter
          email={email}
          emailConfirmed={emailConfirmed}
          sending={sending}
          sendRequisites={sendRequisites}
          isMobile={isMobile}
          onChangeEmail={onChangeEmail}
        />
      </Space>
    </div>
  );
};
