import { ReactNode } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { useFITradingAvailability } from '@terminal/alfadirect/hooks';
import { ReactComponent as TradingLockAttention } from '@terminal/common/icons/TradingLockAttention.svg';
import {
  ExecutionType,
  LifeTime,
  OrderType,
} from '@terminal/core/lib/client/entities';

import styles from './TradingParamsLock.module.css';

interface Props {
  orderType: OrderType;
  executionType: ExecutionType;
  lifeTime: LifeTime;
  idMarketBoard?: number;
  idObjectGroup?: number;
  idObject?: number;
  linkedOrderId?: string;
  isMobileVersion?: boolean;
  children?: ReactNode;
  hideMode?: boolean;
}

/**
 * HOC который блокирует чилдренов, если нет AllowedOrderParams
 */
export const TradingParamsLock = ({
  children,
  orderType,
  executionType,
  lifeTime,
  idMarketBoard,
  idObjectGroup,
  idObject,
  linkedOrderId,
  hideMode,
}: Props) => {
  const isTradingEnabled = useFITradingAvailability({
    orderType,
    executionType,
    lifeTime,
    idMarketBoard,
    idObjectGroup,
    idObject,
    linkedOrderId,
  });

  if (isTradingEnabled) {
    return <>{children}</>;
  }

  if (hideMode) {
    return null;
  }

  return (
    <div className={styles.tradingLockAttention}>
      <TradingLockAttention />
      <Typography.Text view="primary-small">
        Торговля инструментом недоступна
      </Typography.Text>
    </div>
  );
};
