import { Loader } from '@alfalab/core-components/loader';

import { SecuritiesManager } from '@terminal/core/lib/rest/lkSecurities';

import {
  COUNTERAGENT_TYPES,
  isLegalFieldsRequired,
  isRequisitesRequired,
} from '../../shared';
import {
  RequisitesOrganization,
  RequisitesOrganizationProps,
} from './RequisitesOrganization';
import {
  RequisitesPersonal,
  RequisitesPersonalProps,
} from './RequisitesPersonal';

export interface SecurityRequisitesSectionProps
  extends RequisitesOrganizationProps,
    RequisitesPersonalProps {
  accountType: string;
  counteragentType?: COUNTERAGENT_TYPES | undefined;
  ownershipChange: boolean;
  market: string;
  manager: SecuritiesManager | undefined;
  managerPending: boolean;
}

export const SecurityRequisitesSection = ({
  counteragentType,
  ownershipChange,
  ...props
}: SecurityRequisitesSectionProps) => {
  if (!isRequisitesRequired(props.market)) {
    return null;
  }

  if (props.managerPending) {
    return <Loader />;
  }

  return isLegalFieldsRequired({
    manager: props.manager,
    counteragentType,
    ownershipChange,
  }) ? (
    <RequisitesOrganization {...props} counteragentType={counteragentType} />
  ) : (
    <RequisitesPersonal {...props} />
  );
};
