import { AmountInput } from '@alfalab/core-components/amount-input';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { Stepper } from '@terminal/common/components/Stepper';
import { plural } from '@terminal/core/lib/plural';

import styles from './Iceberg.module.css';

type Props = {
  icebergQuantity: number;
  setIcebergQuantity: (q: number) => void;
  setTouched: (touched: boolean) => void;
  isLotCount?: boolean;
  lot?: number;
  error: string | null;
};

export const Iceberg = ({
  icebergQuantity,
  setIcebergQuantity,
  setTouched,
  isLotCount,
  lot,
  error,
}: Props) => {
  const inputSuffix = isLotCount
    ? plural(['лот', 'лота', 'лотов'], icebergQuantity)
    : 'шт';

  return (
    <div>
      <div className={styles.textWithTooltip}>
        <Typography.Text view="secondary-large" weight="bold">
          Видимый объем
        </Typography.Text>

        <Tooltip
          contentClassName={styles.tooltipContent}
          content={
            <>
              Объем заявки, выставляемый в очередь заявок и видимый всем
              участникам рынка <br />
              (Объем видимой части должен составлять не менее 100 лотов)
            </>
          }
          trigger="hover"
          position="top"
        >
          <InformationCircleLineSIcon
            height={12}
            width={12}
            color="var(--color-dark-graphic-secondary)"
          />
        </Tooltip>
      </div>

      <AmountInput
        bold={false}
        className={styles.customInput}
        fieldClassName={styles.customInputField}
        size="s"
        error={error}
        block
        suffix={inputSuffix}
        value={icebergQuantity}
        minority={1}
        integersOnly
        onChange={(e, { value }) => {
          setIcebergQuantity(value || 0);
          setTouched(false);
        }}
        onBlur={() => {
          if (lot && !isLotCount && icebergQuantity < lot) {
            setIcebergQuantity(lot);
          }

          setTouched(true);
        }}
        rightAddons={
          <Stepper
            onAdd={() => {
              if (isLotCount) {
                setIcebergQuantity(icebergQuantity + 1);
              } else if (lot) {
                setIcebergQuantity(icebergQuantity + lot);
              }
            }}
            onSub={() => {
              if (isLotCount) {
                setIcebergQuantity(
                  icebergQuantity > 1 ? icebergQuantity - 1 : icebergQuantity
                );
              } else if (lot) {
                setIcebergQuantity(
                  icebergQuantity > lot ? icebergQuantity - lot : lot
                );
              }
            }}
          />
        }
        dataTestId="priceInput"
      />
    </div>
  );
};
