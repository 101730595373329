import { format } from 'date-fns';
import FileSaver from 'file-saver';
import omit from 'lodash/omit';

import { WatchLists } from '@terminal/core/lib/db/watchLists';
import { tryCatch } from '@terminal/core/lib/tryCatch';
import { WorkspaceConfiguration } from '@terminal/core/store/slices/workspaceConfigurationsSlice';

import { findAllWatchlistIds } from './findAllWatchlistIds';

import { WorkspaceConfigurationExport } from '../model/types';

export const exportConfiguration = async (
  configuration: WorkspaceConfiguration
) => {
  const idbWatchlists = await WatchLists.get();

  const configurationWatchlists = findAllWatchlistIds(configuration);
  const watchLists = (idbWatchlists?.lists || []).filter((w) =>
    configurationWatchlists.includes(w.id)
  );

  tryCatch(async () => {
    const exportObj: WorkspaceConfigurationExport = {
      configuration: {
        ...configuration,
        lastAutoSavedConfig: omit(configuration.lastAutoSavedConfig, 'models'),
        lastManualSavedConfig: omit(
          configuration.lastManualSavedConfig,
          'models'
        ),
      },
      watchLists,
    };

    const json = JSON.stringify(exportObj);

    const blob = new Blob([json], { type: 'application/json' });

    // наименование_конфига_ddmmyyyy_hh_mm_ss
    FileSaver.saveAs(
      blob,
      `${configuration.name}_${format(new Date(), 'ddMMyyyy_hh_mm_ss')}`
    );
  });
};
