import cn from 'classnames';
import React, { FC } from 'react';

import { Modal, ModalProps } from '../../../../legacy/components/Modal';

import styles from './WorkspaceConfigurationModal.module.css';

export type WorkspaceConfigurationModalProps = ModalProps;

export const WorkspaceConfigurationModal: FC<
  WorkspaceConfigurationModalProps
> = (props) => {
  const { className, ...restProps } = props;
  const rootClassName = cn(className, styles.root);

  return <Modal className={rootClassName} {...restProps} />;
};
