import { FC } from 'react';

import { useThemeContext } from '@terminal/desktop/shared/lib/themeContext';

import { ConfigChoiceButton } from '../../../ConfigChoiceButton';
import { InfoHeading } from '../../../InfoHeading';
import { THEME_OPTIONS } from '../../lib/const/themeOptions';

import styles from './OnboardingTheme.module.css';

export const OnboardingTheme: FC = () => {
  const { setPreferredTheme, preferredTheme } = useThemeContext();

  return (
    <div>
      <InfoHeading
        title="Выберите тему интерфейса"
        description={
          <>
            Для того, что бы вам было комфортнее работать с системой.
            <br />
            Вы сможете изменить ее в общих настройках.
          </>
        }
      />
      <div className={styles.actions}>
        {THEME_OPTIONS.map(({ theme, title, Image }) => (
          <ConfigChoiceButton
            key={theme}
            title={title}
            Content={Image}
            isActive={preferredTheme === theme}
            onClick={() => setPreferredTheme(theme)}
          />
        ))}
      </div>
    </div>
  );
};
