// Код скопирован с интернета и немного модифицирован
// Поэтому не ts, а просто js
// Создает ключевую пару и csr запрос

import * as asn1js from 'asn1js';
import {
  AttributeTypeAndValue,
  Certificate,
  CertificationRequest,
  getCrypto,
} from 'pkijs/build';
import { arrayBufferToString, toBase64 } from 'pvutils';
//https://github.com/PeculiarVentures/PKI.js/blob/31c10e9bb879cac59d710102adf4fd7bd61cd66c/src/CryptoEngine.js#L1300
const hashAlg = 'SHA-512';

export function parseCertificate(arrayBuffer) {
  if (arrayBuffer !== undefined && arrayBuffer.byteLength > 0) {
    const ber = new Uint8Array(arrayBuffer).buffer;
    const asn1 = asn1js.fromBER(ber);
    return new Certificate({ schema: asn1.result });
  }
  return undefined;
}

export async function createPKCS10({ enrollmentID }) {
  ensureWebCrypto();

  const keyPair = await generateKeyPair();
  const csr = await createCSR(keyPair, hashAlg, {
    enrollmentID,
  });

  return {
    base64: `-----BEGIN CERTIFICATE REQUEST-----\n${formatPEM(
      toBase64(arrayBufferToString(csr))
    )}\n-----END CERTIFICATE REQUEST-----`,
    binary: csr,
    keys: keyPair,
  };

  // оставил на всякий случай, если когда нибудь надо подебажить приватные ключи
  //   privateKey: `-----BEGIN PRIVATE KEY-----\n${toBase64(
  //     arrayBufferToString(await crypto.exportKey('pkcs8', keyPair.privateKey))
  //   )}\n-----END PRIVATE KEY-----`,
}

async function createCSR(keyPair, hashAlg, { enrollmentID }) {
  const pkcs10 = new CertificationRequest();
  pkcs10.version = 0;

  // { enrollmentID, organizationUnit, organization, state, country }
  //list of OID reference: http://oidref.com/2.5.4
  // pkcs10.subject.typesAndValues.push(
  //   new AttributeTypeAndValue({
  //     type: '2.5.4.6', //countryName
  //     value: new asn1js.PrintableString({ value: country }),
  //   })
  // );
  // pkcs10.subject.typesAndValues.push(
  //   new AttributeTypeAndValue({
  //     type: '2.5.4.8', //stateOrProvinceName
  //     value: new asn1js.Utf8String({ value: state }),
  //   })
  // );
  // pkcs10.subject.typesAndValues.push(
  //   new AttributeTypeAndValue({
  //     type: '2.5.4.10', //organizationName
  //     value: new asn1js.Utf8String({ value: organization }),
  //   })
  // );
  // pkcs10.subject.typesAndValues.push(
  //   new AttributeTypeAndValue({
  //     type: '2.5.4.11', //organizationUnitName
  //     value: new asn1js.Utf8String({ value: organizationUnit }),
  //   })
  // );
  pkcs10.subject.typesAndValues.push(
    new AttributeTypeAndValue({
      type: '2.5.4.3', //commonName
      value: new asn1js.Utf8String({ value: enrollmentID }),
    })
  );

  //add attributes to make CSR valid
  //Attributes must be "a0:00" if empty
  pkcs10.attributes = [];

  await pkcs10.subjectPublicKeyInfo.importKey(keyPair.publicKey);
  //signing final PKCS#10 request
  await pkcs10.sign(keyPair.privateKey, hashAlg);

  return pkcs10.toSchema().toBER(false);
}

// add line break every 64th character
function formatPEM(pemString) {
  return pemString.replace(/(.{64})/g, '$1\n');
}

function ensureWebCrypto() {
  const crypto = getCrypto();
  if (typeof crypto === 'undefined')
    throw new Error('No WebCrypto extension found');
  return crypto;
}

async function generateKeyPair() {
  return await crypto.subtle.generateKey(
    {
      name: 'RSASSA-PKCS1-v1_5', //RSASSA-PKCS1-v1_5 //RSA-PSS
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-512',
    },
    false,
    ['sign', 'verify']
  );
}

/**
 * to learn more about asn1, ber & der, attributes & types used in pkcs#10
 * http://luca.ntop.org/Teaching/Appunti/asn1.html
 *
 * guides to SubtleCrypto (which PKIjs is built upon):
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto
 */
