import { makeGetParams } from '../url';
import { fetchLkResult, LKResult } from './lkApi';
import { signOperation } from './lkSignOperation';

import { LkSignOptions } from '../../store/slices/lkSignSlice';

export interface FreeOrderSettingsParams {
  treaty: number;
}

export interface FreeOrderStatus {
  text: string;
  value: string;
}

export interface FreeOrderThemes {
  id: number;
  theme: string;
}

export interface FreeOrderSettingsResponse {
  accountOpenDate: string;
  statuses: Array<FreeOrderStatus>;
  themes: Array<FreeOrderThemes>;
}

export interface FreeOrdersHistoryParams {
  count: number;
  endDate: string;
  page?: number;
  startDate: string;
  statuses?: string;
  treaty?: number;
  searchString?: string;
}

export type FreeOrderStatuses = 'S' | 'P' | 'E' | 'R';
export type FreeOrderDescription =
  | 'Принято'
  | 'В обработке'
  | 'Исполнено'
  | 'Отклонено';

export interface FreeOrder {
  id: string;
  date: string;
  theme: string;
  status: FreeOrderStatuses;
  statusDescription: FreeOrderDescription;
  text: string;
  comment: string | null;
}

export interface FreeOrdersHistoryResponse {
  orders: Array<FreeOrder>;
  pageCount: number;
  pageNumber: number;
  code: number;
  message: string | null;
}

export async function getFreeOrdersSettings(
  params: FreeOrderSettingsParams
): Promise<FreeOrderSettingsResponse> {
  const request = makeGetParams(params);
  const res = await fetchLkResult<FreeOrderSettingsResponse | LKResult>(
    `/api/free-order/settings?${request}`
  );
  if ('code' in res) {
    throw new Error(res.message || '');
  }
  return res;
}

export async function getFreeOrdersHistory(
  params: FreeOrdersHistoryParams
): Promise<FreeOrdersHistoryResponse> {
  const request = makeGetParams(params);
  const res = await fetchLkResult<FreeOrdersHistoryResponse | LKResult>(
    `/api/free-order/history?${request}`
  );
  if ('code' in res && res.code !== 0) {
    throw new Error(res.message || '');
  }
  return res as FreeOrdersHistoryResponse;
}

export interface SendFreeOrderRequest {
  text: string;
  themeId: string;
  treaty: number;
}

export interface SendFreeOrderParams {
  params: SendFreeOrderRequest;
  signOptions?: LkSignOptions;
}

export async function sendFreeOrder(data: SendFreeOrderParams) {
  return await signOperation(
    `/api/free-order/send`,
    'POST',
    data.params,
    data.signOptions
  );
}
