import { useCallback, useEffect, useState } from 'react';

import { useTradingService } from '@terminal/core/lib/services/trading/useTradingService';
import { OrderItem } from '@terminal/core/types/order';
import { NotificationType } from '@terminal/core/types/ui';

import { useWidgetContext } from '../../../shared';

export const useOrderBookTrading = () => {
  const { addNotification } = useWidgetContext();

  //Состояния для модальных окон отмены и редактирования заявок
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isLoadingCancelModal, setIsLoadingCancelModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isLoadingEditModal, setIsLoadingEditModal] = useState(false);
  //модалка редактирования заявки
  const [orderToEdit, setOrderToEdit] = useState<OrderItem | null>(null);
  //модалка отмены заявки
  const [orderToCancel, setOrderToCancel] = useState<OrderItem | null>(null);

  useEffect(() => {
    setIsLoadingCancelModal(false);
  }, [orderToCancel, setIsLoadingCancelModal]);

  useEffect(() => {
    setIsLoadingEditModal(false);
  }, [orderToEdit, setIsLoadingEditModal]);

  const clearAllModals = useCallback(() => {
    setIsOpenCancelModal(false);
    setIsLoadingCancelModal(false);
    setIsOpenEditModal(false);
    setIsLoadingEditModal(false);
  }, []);

  const onOrderError = useCallback(
    (message: string) => {
      addNotification({
        type: NotificationType.SYSTEM,
        id: new Date().toString(),
        badge: 'negative',
        title: 'Ошибка',
        text: message,
      });

      clearAllModals();
    },
    [addNotification, clearAllModals]
  );

  const tradingMethods = useTradingService(onOrderError, clearAllModals);

  return {
    orderToEdit,
    setOrderToEdit,
    orderToCancel,
    setOrderToCancel,
    isOpenCancelModal,
    setIsOpenCancelModal,
    isLoadingCancelModal,
    setIsLoadingCancelModal,
    isOpenEditModal,
    setIsOpenEditModal,
    isLoadingEditModal,
    setIsLoadingEditModal,
    ...tradingMethods,
  };
};
