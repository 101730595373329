import { useState } from 'react';
import { Divider } from '@alfalab/core-components/divider';
import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { ChevronUpMIcon } from '@alfalab/icons-glyph/ChevronUpMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { countDecimals } from '@terminal/core/lib/format';

import { FormatedText } from '../../../../shared';
import { HistoryStatus } from '../HistoryStatus';

import { useCorporateActionHistoryDetail } from '../../hooks';

import styles from './HistoryDetail.module.css';

interface HistoryDetailProps {
  orderNumber: string;
  treaty: number | undefined;
}

export const HistoryDetail = ({ orderNumber, treaty }: HistoryDetailProps) => {
  const [textOpen, setTextOpen] = useState(false);

  const { data: detail, isFetching: detailPending } =
    useCorporateActionHistoryDetail(treaty, orderNumber);

  if (detailPending) {
    return <Loader />;
  }

  if (!detail) {
    return null;
  }

  const decimalsCount = countDecimals(detail.sertQuan);
  const minorityCount = Math.pow(10, decimalsCount);
  const formatedCount = formatAmount({
    value: detail.sertQuan * minorityCount,
    minority: minorityCount,
    view: 'default',
  }).formatted;

  const decimalsPrice = countDecimals(detail.oneSertPrice);
  const minorityPrice = Math.pow(10, decimalsPrice);
  const formatedPrice = formatAmount({
    value: detail.oneSertPrice * minorityPrice,
    minority: minorityPrice,
    currency: detail.oneSertCurrency as CurrencyCodes,
    view: 'default',
  }).formattedWithCurrency;

  return (
    <div className={styles.container}>
      {detail.parameters && (
        <div className={styles.details}>
          <Typography.Title view="xsmall" className={styles.title} tag="div">
            Информация о ценной бумаге
          </Typography.Title>
          <div className={styles.parameters}>
            {detail.parameters.map((param) => (
              <div className={styles.field} key={`detail-param-${param.text}`}>
                <Typography.Text view="secondary-large">
                  {param.text}
                </Typography.Text>
                <Typography.Text view="secondary-large">
                  {param.value}
                </Typography.Text>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.content}>
        <Typography.Title view="xsmall" className={styles.title} tag="div">
          Поручение на выкуп
        </Typography.Title>
        <div className={styles.parameters}>
          <div className={styles.field}>
            <Typography.Text view="secondary-large">
              Наименование ценной бумаги
            </Typography.Text>
            <Typography.Text view="secondary-large">
              {detail.mtgHeader}
            </Typography.Text>
          </div>
          <div className={styles.field}>
            <Typography.Text view="secondary-large">Количество</Typography.Text>
            <Typography.Text view="secondary-large">
              {formatedCount} шт.
            </Typography.Text>
          </div>
          <div className={styles.field}>
            <Typography.Text view="secondary-large">Цена</Typography.Text>
            <Typography.Text view="secondary-large">
              {formatedPrice} / шт.
            </Typography.Text>
          </div>
          <div className={styles.field}>
            <Typography.Text view="secondary-large">Референс</Typography.Text>
            <Typography.Text view="secondary-large">
              {detail.reference}
            </Typography.Text>
          </div>
          <div className={styles.field}>
            <Typography.Text view="secondary-large">
              Телефон для связи
            </Typography.Text>
            <Typography.Text view="secondary-large">
              {detail.phone}
            </Typography.Text>
          </div>
        </div>
        <div className={styles.text}>
          <Link
            className={styles.textToggle}
            pseudo
            onClick={() => setTextOpen((open) => !open)}
            rightAddons={
              textOpen ? (
                <ChevronUpMIcon width={18} height={18} />
              ) : (
                <ChevronDownMIcon width={18} height={18} />
              )
            }
          >
            Текст сообщения
          </Link>
          {textOpen && (
            <div className={styles.textContent}>
              <FormatedText text={detail.additionalInfo} />
            </div>
          )}
        </div>
        <Divider className={styles.divider} />
        <div className={styles.parameters}>
          <div className={styles.field}>
            <Typography.Text view="secondary-large">Статус</Typography.Text>
            <Typography.Text view="secondary-large">
              <HistoryStatus type={detail.status} text={detail.statusText} />
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};
