import cn from 'classnames';
import { useEffect, useRef } from 'react';

import { getImage } from '../../../../shared/';
import { getStrategyIcon } from '../../api';

import styles from './StrategyIcon.module.css';

interface StrategyIconProps {
  strategyId: number;
  size?: 'm' | 's';
}

export const StrategyIcon = ({ strategyId, size = 'm' }: StrategyIconProps) => {
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const loadImg = async () => {
      const img = await getImage(
        getStrategyIcon(strategyId, window.devicePixelRatio)
      );

      if (imageRef.current) {
        imageRef.current.src = img;
      }
    };

    loadImg();
  }, [strategyId]);

  return (
    <div
      className={cn(styles.icon, {
        [styles.size_m]: size === 'm',
        [styles.size_s]: size === 's',
      })}
    >
      <img className={cn(styles.image)} ref={imageRef} alt="" />
    </div>
  );
};
