import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getContestCompetitors } from '../api/getContestCompetitors';

export const useContestCompetitors = (
  contestid: number,
  participantid: number
) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'contest/competitors', { contestid, participantid }],
    () => getContestCompetitors({ contestid, participantid }),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message ||
            'При запросе данных конкурентов участника конкурса произошла ошибка',
        });
      },
    }
  );
};
