import { useState } from 'react';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { Switch } from '@alfalab/core-components/switch';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import { Stepper } from '@terminal/common/components/Stepper';
import { FiHighlight } from '@terminal/core/types/layout';

import styles from './TradeFeedHighlightModal.module.css';

interface Props {
  onClose: () => void;
  active?: boolean;
  highlightFrom?: number;
  updateHighlight: (fiHighlight: FiHighlight) => void;
}

export const TradeFeedHighlightModal = ({
  onClose,
  active = false,
  highlightFrom = 0,
  updateHighlight,
}: Props) => {
  const [highlightActive, setHighlightActive] = useState<boolean>(active);
  const [fromCount, setFromCount] = useState<number>(highlightFrom);

  return (
    <FunctionalModal
      title="Подсветка значений"
      onClose={onClose}
      width={300}
      footer={
        <div className={styles.footer}>
          <Button view="ghost" size="xxs" onClick={onClose}>
            Отменить
          </Button>
          <Button
            disabled={active === highlightActive && highlightFrom === fromCount}
            size="xxs"
            onClick={() => {
              updateHighlight({
                highlightActive,
                highlightFrom: fromCount,
              });
              onClose();
            }}
          >
            Применить
          </Button>
        </div>
      }
    >
      <div className={styles.firstRow}>
        <Switch
          label={<b>Подсвечивать значения</b>}
          checked={highlightActive}
          block
          reversed
          onChange={() => setHighlightActive((prev) => !prev)}
        />
      </div>
      <AmountInput
        block
        label="Объем, от"
        labelView="outer"
        size="s"
        bold={false}
        integersOnly
        onChange={(e, { value }) => setFromCount(value || 0)}
        value={fromCount}
        minority={1}
        suffix=" шт"
        rightAddons={
          <Stepper
            onAdd={() => setFromCount((prev) => prev + 1)}
            onSub={() => setFromCount((prev) => Math.max(0, prev - 1))}
          />
        }
      />
    </FunctionalModal>
  );
};
