import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabsProps } from '@alfalab/core-components/tabs';

import { LOCATIONS } from '@terminal/core/constants/analytics';
import { trackMainMenu } from '@terminal/core/lib/analytics';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { SelectTreaty } from '../components/SelectTreaty';
import { History } from './tabs/History';
import { Messages } from './tabs/Messages';

import styles from './CorporateActions.module.css';

enum Subpage {
  Messages = 'messages',
  History = 'history',
}

export const CorporateActions = React.memo(
  ({ isMobile }: { isMobile?: boolean }) => {
    const [treaty, setTreaty] = useState<Treaty | null>(null);
    const [subpage, setSubpage] = useState<Subpage>(Subpage.Messages);

    useEffect(() => {
      if (subpage === Subpage.Messages) {
        trackMainMenu(LOCATIONS.LK_CORP_MESSAGE);
      } else if (subpage === Subpage.History) {
        trackMainMenu(LOCATIONS.LK_CORP_HISTORY);
      }
    }, [subpage]);

    const handleChange: TabsProps['onChange'] = (_, { selectedId }) =>
      setSubpage(selectedId as Subpage);

    return (
      <div className={styles.container}>
        <div className={styles.treaty}>
          <SelectTreaty
            zeros={true}
            onSelect={(treaty) => setTreaty(treaty)}
            mobile={isMobile}
          />
        </div>
        <Tabs selectedId={subpage} onChange={handleChange} scrollable size="s">
          <Tab title="Сообщения" id={Subpage.Messages} className={styles.tab}>
            <Messages treaty={treaty} />
          </Tab>
          <Tab title="История" id={Subpage.History} className={styles.tab}>
            <History treaty={treaty} />
          </Tab>
        </Tabs>
      </div>
    );
  }
);
