import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getTreatiesByOwner } from '@terminal/core/lib/rest/lkCommon';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../queryKeys';

export const useTreatiesByOwner = (treaty: number | undefined) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.TreatiesByOwner, { treaty }],
    async () => getTreatiesByOwner({ treaty: treaty || 0 }),
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса списка счетов',
          text: error?.message || '',
        });
      },
    }
  );
};
