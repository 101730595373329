import { IDBPDatabase, openDB } from 'idb';
import log from 'loglevel';

import { featureFlags } from '@terminal/core/featureFlags';

type DictionariesDBv1 = {
  AllowedOrderParams: {
    key: number;
    value: string;
  };
  MarketBoard: {
    key: number;
    value: string;
  };
  FinInstrument: {
    key: number;
    value: string;
  };
  Object: {
    key: number;
    value: string;
  };
  ObjectType: {
    key: number;
    value: string;
  };
  ObjectExt: {
    key: number;
    value: string;
  };
};

export type DictionariesDBVersion = DictionariesDBv1;

export class DictionariesDB {
  static readonly DB_NAME = 'dictionaries';
  /** С какой версией работает текущая сборка приложения
   * Используется для чтения и записи данных
   *
   * Увеличение DB_VERSION вызывает миграцию в init() -> upgrade
   * Увеличивать нужно только при добавлении новых таблиц */
  static readonly DB_VERSION = 1;

  /** Версия, которая хранится у клиента в браузере
   * Используется ТОЛЬКО для открытия базы данных
   *
   * Может быть undefined, если база данных не создана
   * Может быть выше (если делали откат релиза)
   * Может быть ниже (если обновили схему данных в новом релизе) */
  static DB_CLIENT_VERSION?: number;
  static db: IDBPDatabase<DictionariesDBVersion>;

  static async init() {
    if (!featureFlags.DICTIONARIES_CACHE_ENABLED) {
      return;
    }

    try {
      const databases = await indexedDB.databases();
      const currentDB = databases.find(
        (db) => db.name === DictionariesDB.DB_NAME
      );

      if (currentDB && currentDB.version !== undefined) {
        // Если был откат приложения и у клиента оказалась версия БД выше
        //  чем в приложении - берем ее для открытия БД
        if (DictionariesDB.DB_VERSION < currentDB.version) {
          DictionariesDB.DB_CLIENT_VERSION = currentDB.version;
        }
      }

      // Если базы данных нет или нужно увеличить версию БД у клиента
      if (DictionariesDB.DB_CLIENT_VERSION === undefined) {
        DictionariesDB.DB_CLIENT_VERSION = DictionariesDB.DB_VERSION;
      }

      DictionariesDB.db = await openDB<DictionariesDBVersion>(
        DictionariesDB.DB_NAME,
        DictionariesDB.DB_CLIENT_VERSION,
        {
          // Чтобы вызвался upgrade нужно увеличить DB_APP_VERSION
          upgrade(db, oldVersion, newVersion) {
            if (newVersion !== null) {
              for (
                let currentVersion = oldVersion;
                currentVersion < newVersion;
                currentVersion++
              ) {
                const migrationVersion = currentVersion + 1;

                try {
                  switch (migrationVersion) {
                    case 1: {
                      db.createObjectStore('AllowedOrderParams', {
                        autoIncrement: false,
                      });
                      db.createObjectStore('MarketBoard', {
                        autoIncrement: false,
                      });
                      db.createObjectStore('FinInstrument', {
                        autoIncrement: false,
                      });
                      db.createObjectStore('Object', {
                        autoIncrement: false,
                      });
                      db.createObjectStore('ObjectType', {
                        autoIncrement: false,
                      });
                      db.createObjectStore('ObjectExt', {
                        autoIncrement: false,
                      });
                      break;
                    }
                    default:
                      break;
                  }
                } catch (e) {
                  log.error('DictionariesDB upgrade error: ', e);
                }
              }
            }
          },
        }
      );
    } catch (e) {
      log.error(
        `Ошибка инициализации базы данных ${DictionariesDB.DB_NAME}, DB_APP_VERSION: ${DictionariesDB.DB_VERSION}, DB_CLIENT_VERSION: ${DictionariesDB.DB_CLIENT_VERSION}. `,
        e
      );
    }
  }

  static async save(
    tableName: keyof DictionariesDBVersion,
    lastDataVersion: BigInt,
    data: object[]
  ) {
    if (!featureFlags.DICTIONARIES_CACHE_ENABLED) {
      return;
    }

    try {
      const json = JSON.stringify({
        lastDataVersion: lastDataVersion.toString(),
        data,
      });

      return await DictionariesDB.db.put(tableName, json, 0);
    } catch (e: Error | any) {
      log.error(`error save to DictionariesDB, tableName: ${tableName} `, e);
    }
  }

  static async get<T>(
    tableName: keyof DictionariesDBVersion
  ): Promise<{ lastDataVersion: BigInt; data: T } | undefined> {
    if (!featureFlags.DICTIONARIES_CACHE_ENABLED) {
      return;
    }

    const stringData = await DictionariesDB.db.get(tableName, 0);
    if (stringData) {
      try {
        const parsedData = JSON.parse(stringData);

        return {
          lastDataVersion: BigInt(parsedData.lastDataVersion),
          data: parsedData.data,
        };
      } catch (e) {
        log.error(`error get from DictionariesDB, tableName: ${tableName} `, e);
      }
    }
  }
}
