import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getCorporateAction } from '@terminal/core/lib/rest/lkCorporateActions';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useCorporateAction = (id: string) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.CorporateAction, { id }],
    async () => getCorporateAction(id),
    {
      enabled: Boolean(id),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса корпоративного действия',
          text: error?.message || '',
        });
      },
    }
  );
};
