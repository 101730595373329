import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { SelectDesktop } from '@alfalab/core-components/select/desktop';
import { OptionShape } from '@alfalab/core-components/select/typings';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';
import { Certificate } from '@terminal/core/types/certificate';

import { useCertificateEnrollStore } from '../../model/store';

import { CertificateEnrollStep } from '../../model/types';

import styles from './SelectRejectingCertificate.module.css';

interface Props {
  rejectButtonSlot?: ReactNode;
}

export const SelectRejectingCertificate: FC<Props> = ({ rejectButtonSlot }) => {
  const { useCertificates } = useAlfaDirectContext();

  const certificates = useCertificates();

  const [selectedCertificate, setSelectedCertificate] = useState<
    Certificate | undefined
  >(certificates[0]);

  const [setStep, setRejectingCertificate] = useCertificateEnrollStore(
    (store) => [store.setStep, store.setRejectingCertificate]
  );

  useEffect(() => {
    setSelectedCertificate(certificates[0]);
  }, [certificates]);

  const certificatesOptions: OptionShape[] = useMemo(
    () =>
      certificates.map((certificate) => {
        const key = certificate.idCertificate.toString();

        return {
          key,
          content: `${key}, ${certificate.description}`,
        };
      }),
    [certificates]
  );

  const selectedOption = certificatesOptions.find(
    (option) => option.key === selectedCertificate?.idCertificate.toString()
  );

  const onClickOptionSelect = useCallback(
    ({ selected }: { selected: OptionShape | null }) => {
      if (!selected) {
        return;
      }

      const certificate = certificates.find(
        (certificate) => certificate.idCertificate.toString() === selected.key
      );

      if (certificate) {
        setSelectedCertificate(certificate);
      }
    },
    [certificates, setSelectedCertificate]
  );

  const handleButtonClick = () => {
    if (selectedCertificate) {
      setRejectingCertificate(selectedCertificate);
      setStep(CertificateEnrollStep.Reject);
    }
  };

  return (
    <div className={styles.root}>
      <SelectDesktop
        label="Сертификат для отзыва"
        labelView="outer"
        options={certificatesOptions}
        selected={selectedOption}
        onChange={onClickOptionSelect}
        block
      />
      {rejectButtonSlot || (
        <>
          <Gap size="m" />
          <ButtonDesktop
            size="xs"
            view="secondary"
            onClick={handleButtonClick}
            className={styles.rejectButton}
          >
            Отозвать и выпустить новый
          </ButtonDesktop>
        </>
      )}
    </div>
  );
};
