import log from 'loglevel';
import { useEffect, useMemo, useState } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { MultimarketsItem } from '../../lib/rest/investApi';

import { SubAccountPositionItem } from '../../types/subAccountPosition';

/*
 * Для каждой записи позиции, находим idFI по которому позиция будет оцениваться
 * В случае, если у объекта есть isin, находим матч по мультирынкам
 * Если isin нет, позиция оценивается по idFiBalance
 * Возвращает Map где ключ idPosition, а значение idFI по которому берутся цены
 */
export const useIdFiBalances = (
  positionItems: SubAccountPositionItem[]
): Map<number, number> => {
  // console.log('===useIdFiBalances===');
  const [multimarkets, setMultimarkets] = useState<
    Map<string, MultimarketsItem>
  >(new Map());

  const {
    subAccountRazdels,
    finInstrumentsTable,
    objectsTable,
    loadMultimarkets,
    marketBoardsTable,
  } = useAlfaDirectContext();

  const isins: string[] = useMemo(
    () =>
      positionItems
        .map((item) => objectsTable.get('idObject', item.idObject)?.isin ?? '')
        .filter((isin) => isin !== ''),
    [objectsTable, positionItems]
  );
  //console.log(isins);

  useEffect(() => {
    // загружаем мультирынки для всех объектов, у которых есть isin
    loadMultimarkets(isins).then((response) => {
      //console.log('===', response, isins);
      setMultimarkets(new Map(response.map((item) => [item.isin, item])));
    });
  }, [isins, loadMultimarkets]);

  return useMemo(() => {
    return positionItems.reduce((acc, item) => {
      const id = item.idObject;
      const obj = objectsTable.get('idObject', id);
      const multimarket = multimarkets.get(obj?.isin ?? '');
      const razdel = subAccountRazdels.find(
        (razd) => razd.idRazdel === item.idRazdel
      );

      if (multimarket && razdel) {
        // делаем оценку позиции по наиболее ликвилному рынку нашей биржи
        // находим SubAccountRazdelItem и достаем оттуда
        const exchange = multimarket.exchanges.find(
          (ex) => ex.id === razdel.universalExchangeCode
        );

        if (!exchange) {
          log.info(`Не найдена биржа ${razdel.universalExchangeCode}`);

          return acc;
        }

        const liquidMarketCode = exchange.liquidMarketId;
        // фильтруем финиструменты с objectId, выбираем тот где нужная борда
        const fis = finInstrumentsTable
          .toArray()
          .filter((fi) => obj.idObject === fi.idObject);

        fis.forEach((fi) => {
          const board = marketBoardsTable.get(
            'idMarketBoard',
            fi.idMarketBoard
          );

          if (board?.universalMarketCode === liquidMarketCode) {
            acc.set(item.idPosition, fi.idFI);
          }
        });
      } else {
        // делаем оценку позиции по idFIBalance
        acc.set(item.idPosition, item.idFIBalance);
      }

      return acc;
    }, new Map());
  }, [
    positionItems,
    objectsTable,
    multimarkets,
    subAccountRazdels,
    finInstrumentsTable,
    marketBoardsTable,
  ]);
};
