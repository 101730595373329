import cn from 'classnames';
import React, { ReactNode, useRef, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { SelectProps } from '@alfalab/core-components/select';
import { OptionShape } from '@alfalab/core-components/select/typings';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import { BottomSheet } from '../BottomSheet';
import { ReactComponent as BackIcon } from './img/BackIcon.svg';

import styles from './MobileSelect.module.css';

export interface MobileSelectOption extends OptionShape {
  description?: string;
  contentSummary?: ReactNode | string;
}

interface MobileSelectProps {
  className?: string;
  options: MobileSelectOption[];
  selectedKey?: string;
  onChange: SelectProps['onChange'];
  hint?: string | ReactNode;
  leftAddon?: ReactNode;
  title?: string;
  disabled?: boolean;
  toolTipTitle?: string;
}

export const MobileSelect = ({
  options,
  selectedKey,
  onChange,
  hint,
  leftAddon,
  title,
  disabled,
  className,
  toolTipTitle,
}: MobileSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const initiatorRef = useRef<OptionShape | null>(null);
  const toggleSelect = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };
  const selectedOption = options.find((opt) => opt.key === selectedKey);

  return (
    <div>
      <div className={cn(styles.inputField, className)} onClick={toggleSelect}>
        {leftAddon && <span className={styles.leftAddon}>{leftAddon}</span>}
        <span className={cn(styles.selectedValue, disabled && styles.disabled)}>
          {selectedOption?.contentSummary
            ? selectedOption.contentSummary
            : selectedOption?.content}
        </span>
        <span className={cn(styles.chevron, isOpen && styles.chevronOpen)}>
          <ChevronDownMIcon width={18} height={18} fill="#747474" />
        </span>
      </div>
      <Typography.Text className={styles.hint} view="secondary-medium">
        {hint}
      </Typography.Text>
      <BottomSheet open={isOpen} onClose={toggleSelect}>
        <Typography.Title tag="h2" view="xsmall">
          {title}
        </Typography.Title>
        <div className={styles.optionList}>
          {options.map((opt, index, list) => {
            initiatorRef.current = opt;

            if (!onChange) {
              return null;
            }

            const isSelected = selectedKey === opt.key;

            return (
              <div
                key={`option-${opt.key}`}
                className={styles.optionListItem}
                onClick={() => {
                  onChange({
                    selected: opt,
                    selectedMultiple: list,
                    initiator: initiatorRef.current,
                  });
                  toggleSelect();
                }}
              >
                <span>{opt.content}</span>
                <span>
                  {isSelected && <CheckmarkMIcon height={18} width={18} />}
                </span>
              </div>
            );
          })}
        </div>
        {Boolean(toolTipTitle?.trim()) && (
          <Button size="xs" onClick={() => setIsTooltipOpen(true)}>
            {toolTipTitle}
          </Button>
        )}
      </BottomSheet>
      {isTooltipOpen && (
        <div className={styles.tooltipContainer}>
          <div className={styles.tooltipHeader}>
            <BackIcon
              className={styles.backIcon}
              onClick={() => {
                setIsTooltipOpen(false);
              }}
            />
            <Typography.TitleMobile tag="div" view="xsmall">
              {title}
            </Typography.TitleMobile>
          </div>
          {options.map((opt, index, list) => {
            const isSelected = selectedKey === opt.key;

            return (
              <div
                className={styles.tooltipItem}
                onClick={() => {
                  if (onChange) {
                    onChange({
                      selected: opt,
                      selectedMultiple: list,
                      initiator: initiatorRef.current,
                    });
                  }
                }}
              >
                <div className={styles.tooltipDescription}>
                  <Typography.Text tag="div" view="primary-medium">
                    {opt.content}
                  </Typography.Text>
                  <Typography.Text color="secondary" view="primary-small">
                    {opt.description}
                  </Typography.Text>
                </div>
                <div className={styles.tooltipCheckedField}>
                  {isSelected && <CheckmarkMIcon height={18} width={18} />}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
