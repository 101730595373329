import { lazy } from 'react';

export const LazyBalance = lazy(() =>
  import('@terminal/widgets/widgets/balance').then((module) => ({
    default: module.Component,
  }))
);
export const LazyHybridOrder = lazy(() =>
  import('@terminal/widgets/widgets/hybridOrder').then((module) => ({
    default: module.Component,
  }))
);
export const LazyInstrumentInfo = lazy(() =>
  import('@terminal/widgets/widgets/instrumentInfo').then((module) => ({
    default: module.Component,
  }))
);
export const LazyNews = lazy(() =>
  import('@terminal/widgets/widgets/news').then((module) => ({
    default: module.Component,
  }))
);
export const LazyOperations = lazy(() =>
  import('@terminal/widgets/widgets/operations/').then((module) => ({
    default: module.Component,
  }))
);
export const LazyOrderBook = lazy(() =>
  import('@terminal/widgets/widgets/orderBook').then((module) => ({
    default: module.Component,
  }))
);
export const LazyOrderTable = lazy(() =>
  import('@terminal/widgets/widgets/orderTable').then((module) => ({
    default: module.Component,
  }))
);
export const LazyPositions = lazy(() =>
  import('@terminal/widgets/widgets/position').then((module) => ({
    default: module.Component,
  }))
);
export const LazyTradesFeed = lazy(() =>
  import('@terminal/widgets/widgets/tradesFeed').then((module) => ({
    default: module.Component,
  }))
);
export const LazyWatchlist = lazy(() =>
  import('../../../widgets/watchlist').then((module) => ({
    default: module.WatchlistWidget,
  }))
);
export const LazyAssets = lazy(() =>
  import('../../../legacy/widgets/Assets').then((module) => ({
    default: module.Assets,
  }))
);
export const LazyCertificates = lazy(() =>
  import('../../../legacy/widgets/certificates').then((module) => ({
    default: module.Certificates,
  }))
);
export const LazyDevtools = lazy(() =>
  import('../../../legacy/widgets/Devtools').then((module) => ({
    default: module.Devtools,
  }))
);
export const LazyChart = lazy(() =>
  import('./Chart').then((module) => ({
    default: module.Chart,
  }))
);
