import { Button, ButtonProps } from '@alfalab/core-components/button';
import { SquareAcademicCapMIcon } from '@alfalab/icons-glyph/SquareAcademicCapMIcon';

export const BacklToListButton = ({ ...props }: ButtonProps) => {
  return (
    <Button
      size="xxs"
      view="tertiary"
      leftAddons={<SquareAcademicCapMIcon width={18} height={18} />}
      {...props}
    >
      К списку тестов
    </Button>
  );
};
