import React, { FC } from 'react';
import { InputDesktop } from '@alfalab/core-components/input/desktop';

import {
  WorkspaceConfigurationModal,
  WorkspaceConfigurationModalProps,
} from './WorkspaceConfigurationModal';

interface Props extends Pick<WorkspaceConfigurationModalProps, 'open'> {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onClose: () => void;
}

export const WorkspaceRenameModal: FC<Props> = ({
  open,
  value,
  onChange,
  onSubmit,
  onClose,
}) => {
  return (
    <WorkspaceConfigurationModal
      open={open}
      onClose={onClose}
      title="Переименовать"
      actionButtonsEnd={[
        {
          children: 'Отмена',
          onClick: onClose,
        },
        {
          children: 'Cохранить',
          view: 'secondary',
          onClick: () => {
            onSubmit();
            onClose();
          },
        },
      ]}
    >
      <InputDesktop
        size="s"
        block
        value={value}
        onChange={(_, { value }) => onChange(value)}
        placeholder="Название конфигурации"
      />
    </WorkspaceConfigurationModal>
  );
};
