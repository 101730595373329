import React from 'react';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';

import styles from './FreeOrders.module.css';

export const FreeOrderEmptyHistory = () => {
  return (
    <>
      <div className={styles.emptyListContainer}>
        <SuperEllipse size={48} className={styles.emptyListIcon}>
          <FilterMIcon />
        </SuperEllipse>
        <Typography.Text
          view="primary-medium"
          weight="bold"
          className={styles.emptyListTitle}
        >
          Результатов нет :(
        </Typography.Text>
        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={styles.emptyListDescription}
        >
          По заданным параметрам не найдено ни одного поручения
        </Typography.Text>
      </div>
    </>
  );
};
