import { differenceInSeconds, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { groupBy, isEqual, pick } from 'lodash';
import { useMemo } from 'react';

import { ChatMessage, ChatMessagesGroup } from '../types';

const canGroup = (prevMessage: ChatMessage, message: ChatMessage): boolean =>
  isEqual(
    pick(message, 'isRead', 'hint', 'author'),
    pick(prevMessage, 'isRead', 'hint', 'author')
  ) &&
  differenceInSeconds(message.dtChatMessage, prevMessage.dtChatMessage) < 120;

const divideMessagesByDate = (
  messages: ChatMessage[]
): Record<string, ChatMessage[]> =>
  groupBy(messages, (item) => {
    return format(item.dtChatMessage, 'd MMMM yyyy', { locale: ru });
  });

const divideMessagesIntoGroups = (
  messages: ChatMessage[]
): ChatMessagesGroup[] => {
  const groups: ChatMessage[][] = [];
  let lastGroup: ChatMessage[] = [messages[0]];

  for (let message of messages.slice(1)) {
    const lastGroupMessage = lastGroup[lastGroup.length - 1];

    if (canGroup(lastGroupMessage, message)) {
      lastGroup.push(message);
    } else {
      groups.push(lastGroup);
      lastGroup = [message];
    }
  }

  groups.push(lastGroup);

  return groups;
};

const divideMessages = (
  messages: ChatMessage[]
): Record<string, ChatMessagesGroup[]> => {
  const byDate = divideMessagesByDate(messages);

  return Object.entries(byDate).reduce(
    (acc, [date, msgs]) => ({ ...acc, [date]: divideMessagesIntoGroups(msgs) }),
    {}
  );
};

type GroupedMessages = Record<string, ChatMessagesGroup[]>;

export const useDivideMessagesIntoGroups = (
  messages: ChatMessage[] = []
): GroupedMessages => {
  return useMemo(() => divideMessages(messages), [messages]);
};
