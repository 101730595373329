import {
  ServiceNotifications,
  ServiceNotificationsProps,
} from '@terminal/common/entities/ServiceNotifications';

import { useWidgetContext } from '../../shared';

type WidgetServiceNotificationProps = Pick<
  ServiceNotificationsProps,
  | 'canBeShort'
  | 'className'
  | 'closable'
  | 'code'
  | 'containerClassName'
  | 'variant'
>;

export const WidgetServiceNotification = (
  props: WidgetServiceNotificationProps
) => {
  const { useRemoteConfig } = useWidgetContext();

  return <ServiceNotifications {...props} useRemoteConfig={useRemoteConfig} />;
};
