import { Node } from 'flexlayout-react';
import isUndefined from 'lodash/isUndefined';

import { useActiveConfigurationModel } from '@terminal/core/hooks/useActiveConfigurationModel';
import { ObjectGroup } from '@terminal/core/lib/client/entities';
import { BaseWidgetConfig } from '@terminal/core/types/layout';
import { PUShowMode } from '@terminal/core/types/subAccountPosition';

import { DropdownMode } from './DropdownMode';
import { PUFilters } from './PUFilters';
import { TypeFilters } from './TypeFilters';

// eslint-disable-next-line no-restricted-imports
import styles from '../AccountFilters.module.css';

const minChipsFiltersWidth = 800;
const minUnionFiltersWidth = 600;

interface Props {
  updateNode: (
    nodeId: string,
    config: BaseWidgetConfig,
    newName?: string | undefined
  ) => void;
  nodeId?: string;
  selectedTypes: ObjectGroup[];
  selectedPUMode: PUShowMode;
}

export const PositionTypeFilters = ({
  nodeId,
  selectedTypes,
  updateNode,
  selectedPUMode,
}: Props) => {
  const model = useActiveConfigurationModel();
  const node: Node = model.getNodeById(nodeId || '');
  const nodeWidth = node.getRect().width;
  const isShowChips = nodeWidth > minChipsFiltersWidth;
  const isShowDropdown = nodeWidth < minUnionFiltersWidth;

  const saveTypeFilters = (positionTypes: ObjectGroup[]) => {
    if (!isUndefined(nodeId)) {
      updateNode(nodeId, { tableProps: { positionTypes } });
    }
  };

  const savePUMode = (selectedPUMode: PUShowMode) => {
    if (!isUndefined(nodeId)) {
      updateNode(nodeId, { tableProps: { selectedPUMode } });
    }
  };

  return isShowDropdown ? (
    <DropdownMode
      selectedTypes={selectedTypes}
      isShowChips={isShowChips}
      saveTypes={saveTypeFilters}
      selectedPUMode={selectedPUMode}
      savePUMode={savePUMode}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.filtersGrid}>
        <div className={styles.filters}>
          <TypeFilters
            selectedTypes={selectedTypes}
            isShowChips={isShowChips}
            saveTypes={saveTypeFilters}
          />
        </div>
        <PUFilters
          selectedPUMode={selectedPUMode}
          savePUMode={savePUMode}
          isShowChips={isShowChips}
        />
      </div>
    </div>
  );
};
