import { OrderStatus, OrderType } from '../lib/client/entities';

export const filtersNames: Record<string, string> = {
  active: 'Активные',
  cancelled: 'Отозваны',
  executed: 'Исполнены',
  rejected: 'Отклонены',
  waiting: 'Ожидают',
};

// Таблица с маппингом тут - http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=1055240476
export const defaultOrderStatusFilters: Record<string, OrderStatus[]> = {
  active: [OrderStatus.HiddenB, OrderStatus.HiddenPrice, OrderStatus.Working],
  cancelled: [
    OrderStatus.ToBeCancelled,
    OrderStatus.ExchangeCancelled,
    OrderStatus.Cancelled,
    OrderStatus.Edit,
    OrderStatus.Moving,
  ],
  executed: [OrderStatus.ExchangeFilled, OrderStatus.Filled],
  rejected: [
    OrderStatus.Rejected,
    OrderStatus.ExchangeRejected,
    OrderStatus.ExchangeRemoved,
  ],
  waiting: [
    OrderStatus.Parked,
    OrderStatus.New,
    OrderStatus.Offer,
    OrderStatus.Sign,
    OrderStatus.HiddenTime,
    OrderStatus.HiddenOrder,
    OrderStatus.CheckLimits,
    OrderStatus.Accepted,
    OrderStatus.PendingEnter,
    OrderStatus.HiddenPeriod,
    OrderStatus.HiddenZ,
    OrderStatus.PendingCancel,
    OrderStatus.Verify,
  ],
};

// http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=393649282
export const orderTypeMap: Record<string, string> = {
  MKT: 'Рыночная',
  LMT: 'Лимит', // Лимитный
  LWA: 'Лимитная "по средней"', //
  NOS: 'Лимитная "по единой"', // Лимитный "по единой"
  MIT: 'Маркет с доп. условием по цене', // Рыночный приказ с активацией при достижении заданной цены указанным фининструментом
  LIT: 'Лимит с доп. условием по цене', // Лимитный приказ с активацией при достижении заданной цены указанным фининструментом
  STP: 'Стоп-маркет', // Стоп
  STL: 'Стоп-лимит', // Стоп-лимит
  TRL: 'Трейлинг лимит', // Лимитный «с трейлингом»
  TRS: 'Трейлинг стоп-маркет', // Стоп «с трейлингом»
  TSL: 'Трейлинг стоп-лимит', // Стоп-лимит «с трейлингом»
  BRS: 'Стоп-маркет+Тейк-профит', // Брекет «лимит- стоп»
  BSL: 'Стоп-лимит+Тейк-профит', // Брекет «лимит- стоп-лимит»
  RPS: 'Адресная', // Адресный
  INT: 'Адресная с участником Альфа-Директ', //  Адресный с участником Альфа-Директ
  SMU: 'Изменить место аналитического учета', // «изменить место аналитического учета (перевод без изменения депо счета и раздела)
  SMX: 'Изменить место хранения', //  «изменить место хранения  (раздела учета в составе депо счета)» если DEP (без изменения депо счета)
  VDP: 'Внутридепозитарный перевод', // «внутридепозитарный перевод» если DEP (перевод без изменения депозитария)
  MDP: 'Междепозитарный перевод', // «междепозитарный перевод» если DEP (перевод с изменением Депозитария)
  REES: 'Списать в реестр/зачислить из реестра', //  «списать в реестр/зачислить из реестра» если DEP (перевод в реестр или из реестра)
  CLMON: 'На банковский счет', // На банковский счет клиента
  DUMON: 'На специальный счет', // На специальный счет открытый для учета ден средств по договору ДУ
  TechRPS: 'Адресная (технический)', // Адресный (технический)
  SWAP: 'СВОП', // СВОП
  TechSWAP: 'СВОП (технический)', // СВОП (технический)
  REPO: 'РЕПО', // РЕПО
  TechREPO: 'РЕПО (технический)', // РЕПО (технический)
  TBRS: 'Трейлинг стоп-маркет и Тейк-профит',
};

export const orderTypeAbbrMap: Partial<Record<OrderType, string>> = {
  [OrderType.MKT]: 'МКТ',
  [OrderType.LMT]: 'ЛМТ',
  [OrderType.STP]: 'СТМ',
  [OrderType.TRL]: 'ТЛМТ',
  [OrderType.TRS]: 'ТСТМ',
  [OrderType.STL]: 'СТЛ',
  [OrderType.TSL]: 'ТСТЛ',
  // TODO Перевести с дизайнером
  [OrderType.BRS]: 'BRS',
  [OrderType.BSL]: 'BSL',
  [OrderType.LWA]: 'LWA',
  [OrderType.NOS]: 'NOS',
  [OrderType.MIT]: 'MIT',
  [OrderType.LIT]: 'LIT',
  [OrderType.RPS]: 'RPS',
  [OrderType.INT]: 'INT',
  [OrderType.SMU]: 'SMU',
  [OrderType.SMX]: 'SMX',
  [OrderType.VDP]: 'VDP',
  [OrderType.MDP]: 'MDP',
  [OrderType.REES]: 'REES',
  [OrderType.CLMON]: 'CLMON',
  [OrderType.DUMON]: 'DUMON',
  [OrderType.TechRPS]: 'TechRPS',
  [OrderType.SWAP]: 'SWAP',
  [OrderType.TechSWAP]: 'TechSWAP',
  [OrderType.REPO]: 'REPO',
  [OrderType.TechREPO]: 'TechREPO',
  [OrderType.TBRS]: 'TBRS',
};

export const orderTypeNameMap: Partial<Record<OrderType, string>> = {
  [OrderType.MKT]: 'Рыночная',
  [OrderType.LMT]: 'Лимит',
  [OrderType.STP]: 'Стоп-маркет',
  [OrderType.TRL]: 'Трейлинг лимит',
  [OrderType.TRS]: 'Трейлинг стоп-маркет',
  [OrderType.STL]: 'Стоп-лимит',
};

export const orderStatusMap: Record<string, string> = {
  active: 'Активна',
  cancelled: 'Отозвана',
  executed: 'Исполнена',
  rejected: 'Отклонена',
  waiting: 'Ожидает',
};

export const UNCANCELLABLE_ORDERS_TYPE = [
  OrderStatus.Cancelled,
  OrderStatus.Filled,
  OrderStatus.Rejected,
];
