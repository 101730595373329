import React, {
  FC,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { CodeInputDesktop } from '@alfalab/core-components/code-input/desktop';
import { CustomInputRef } from '@alfalab/core-components/code-input/typings';
import { Typography } from '@alfalab/core-components/typography';

import { ResendCodeButton } from '../ResendCodeButton';

import { useSignCertificate } from '../../hooks/useSignCertificate';

import { useCertificateEnrollStore } from '../../model/store';

import styles from './SignCertificate.module.css';

export interface SignCertificateProps {
  onSuccess?: () => void;
  certificateId?: number;
  initAction: (certificateId: number) => void;
  successMsg: { title: string; text: string };
  errorMsg: { title: string; text: string };
  bottomSlot?: ReactNode;
}

export const SignCertificate: FC<SignCertificateProps> = forwardRef(
  (
    { onSuccess, certificateId, initAction, successMsg, errorMsg, bottomSlot },
    ref
  ) => {
    const inputRef = useRef<CustomInputRef>(null);

    useEffect(() => {
      inputRef.current?.focus?.();
    }, []);

    const { assignment, operation, error, clearError } =
      useCertificateEnrollStore((store) => ({
        assignment: store.assignment,
        operation: store.operation,
        error: store.error,
        clearError: store.clearError,
      }));

    const { resendSms, checkSmsCode, isLoading } = useSignCertificate({
      certificateId,
      initAction,
      onSuccess,
      successMsg,
      errorMsg,
    });

    useImperativeHandle(ref, () => isLoading);

    return (
      <div className={styles.root}>
        <CodeInputDesktop
          ref={inputRef}
          clearCodeOnError
          disabled={!operation || !assignment || isLoading}
          error={error?.message}
          fields={5}
          onChange={clearError}
          onComplete={checkSmsCode}
          className={styles.codeInput}
        />

        <div>
          <Typography.Text
            tag="div"
            view="secondary-small"
            className={styles.description}
          >
            Код отправлен на номер, указанный при регистрации
            <br />
            Запросить повторно можно через
          </Typography.Text>

          <>
            <ResendCodeButton
              disabled={isLoading}
              key={certificateId}
              onClick={resendSms}
            />

            {bottomSlot}
          </>
        </div>
      </div>
    );
  }
);
