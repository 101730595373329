import cn from 'classnames';
import React, { ReactNode } from 'react';

import styles from './MainMenuButton.module.css';

export interface MainMenuButtonProps {
  name: ReactNode;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  active?: boolean;
  className?: string;
  activeClassName?: string;
  onClick?: () => void;
}

export const MainMenuButton = ({
  name,
  icon,
  rightIcon,
  active,
  className,
  activeClassName,
  onClick,
}: MainMenuButtonProps) => {
  return (
    <button
      className={cn(
        styles.item,
        active && styles.active,
        active && activeClassName,
        className
      )}
      onClick={onClick}
    >
      {Boolean(icon) && <span className={styles.icon}>{icon}</span>}
      {typeof name === 'string' || typeof name === 'number' ? (
        <span className={styles.name}>{name}</span>
      ) : (
        name
      )}
      {Boolean(rightIcon) && (
        <span className={styles.rightIcon}>{rightIcon}</span>
      )}
    </button>
  );
};
