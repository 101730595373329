import { useCallback } from 'react';

import { IS_AI } from '@terminal/core/env';
import useNotification from '@terminal/core/hooks/useNotification';
import { shallow, useStore } from '@terminal/core/store';
import { NotificationType } from '@terminal/core/types/ui';
import { useLoginPinCodeStore } from '@terminal/lk-core';

import { passportLogout } from '../lib/passportLogout';

export function useLogoutAction() {
  const addNotification = useNotification();
  const [logout, clearAuth] = useStore(
    (state) => [state.logout, state.clearAuth],
    shallow
  );
  const resetPinCode = useLoginPinCodeStore((store) => store.resetPinCode);

  return useCallback(() => {
    if (IS_AI) {
      passportLogout()
        .then(() => {
          clearAuth();
          resetPinCode();
        })
        .catch(() => {
          addNotification({
            type: NotificationType.SYSTEM,
            badge: 'negative',
            title: 'Не удалось выйти из приложения. Повторите попытку',
          });
        });
    } else {
      logout(true);
      resetPinCode();
    }
  }, [addNotification, clearAuth, logout, resetPinCode]);
}
