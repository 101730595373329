import { useEffect } from 'react';

import {
  CertifcatesService,
  CertificatesEvent,
} from '@terminal/core/lib/services/certificates';

/**
 * @description Хук для обработки события успешного отзыва сертификата,
 *  если сразу перейти к созданию нового сертификата, то локально всё ещё будет
 *  максимальное количество сертификатов, и вылетит ошибка
 */
export function useRejectCertificateEffect(onReject: () => void) {
  useEffect(() => {
    CertifcatesService.on(
      CertificatesEvent.CERTIFICATE_REJECT_SUCCESS,
      onReject
    );

    return () => {
      CertifcatesService.off(
        CertificatesEvent.CERTIFICATE_REJECT_SUCCESS,
        onReject
      );
    };
  }, [onReject]);
}
