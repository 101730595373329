import produce, { Draft, enableMapSet } from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import create, { GetState, StateCreator } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import shallow from 'zustand/shallow';

import { USE_DEV_TOOLS_FOR_STORE } from '../env';
import { AccountSlice, createAccountSlice } from './slices/accountSlice';
import { AppSlice, createAppSlice } from './slices/appSlice';
import {
  CertificatesSlice,
  createCertificatesSlice,
} from './slices/certificatesSlice';
import { CoreSlice, createCoreSlice } from './slices/coreSlice';
import {
  createFinInfoExtSlice,
  FinInfoExtSlice,
} from './slices/finInfoExtSlice';
import { createGiftSlice, GiftsSlice } from './slices/giftsSlice';
import { createIdeasSlice, IdeasSlice } from './slices/ideasSlice';
import { createLkSignSlice, LkSignSlice } from './slices/lkSignSlice';
import {
  createNotificationSlice,
  NotificationSlice,
} from './slices/notificationSlice';
import {
  createOrderParamsSlice,
  OrderParamsSlice,
} from './slices/orderParamsSlice';
import {
  createTrackPriceSlice,
  TrackPriceSlice,
} from './slices/trackPriceSlice';
import { createUiSlice, UiSlice } from './slices/uiSlice';
import { createUserSlice, UserSlice } from './slices/userSlice';
import { createWatchListSlice, WatchListSlice } from './slices/watchlistSlice';
import {
  createWorkspaceConfigurationsSlice,
  WorkspaceConfigurationsSlice,
} from './slices/workspaceConfigurationsSlice';

export type RootStore = AppSlice &
  FinInfoExtSlice &
  AccountSlice &
  UserSlice &
  WorkspaceConfigurationsSlice &
  CoreSlice &
  WatchListSlice &
  CertificatesSlice &
  LkSignSlice &
  OrderParamsSlice &
  NotificationSlice &
  UiSlice &
  IdeasSlice &
  TrackPriceSlice &
  GiftsSlice;

const immer =
  <T extends RootStore>(
    config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>
  ): StateCreator<T> =>
  (set, get, api) =>
    config((fn) => set(produce<T>(fn)), get, api);

export type StoreSet = (fn: (draft: WritableDraft<RootStore>) => void) => void;

const store = (set: StoreSet, get: GetState<RootStore>) => ({
  ...createFinInfoExtSlice(set, get),
  ...createAccountSlice(set, get),
  ...createUserSlice(set, get),
  ...createAppSlice(set, get),
  ...createWorkspaceConfigurationsSlice(set, get),
  ...createCoreSlice(set, get),
  ...createWatchListSlice(set, get),
  ...createCertificatesSlice(set, get),
  ...createLkSignSlice(set, get),
  ...createOrderParamsSlice(set, get),
  ...createNotificationSlice(set, get),
  ...createUiSlice(set, get),
  ...createIdeasSlice(set, get),
  ...createTrackPriceSlice(set, get),
  ...createGiftSlice(set),
});

enableMapSet();

export const useStore = create(
  subscribeWithSelector(
    USE_DEV_TOOLS_FOR_STORE ? devtools(immer(store)) : immer(store)
  )
);

const { logout } = useStore.getState();

// @ts-expect-error
// Добавляем метод в window, т.к. в LKApi есть усиловаие по которому нужно выполнить выход
// в LKApi нельзя использовать stor, т.к. это приведет к циклическим зависимостям
// нужно часть кода LKApi вынести в сервис и подписывать на этот сервис если происходит событие выхода
window._logout = logout;
// @ts-expect-error
// временно экспозим стор в window пока не вынесем использование стор из services
window.__zsstore = useStore;

export { shallow };
