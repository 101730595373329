import { useState } from 'react';

import { TableColumnSetting } from '@terminal/core/types/layout';

type ReturnType = {
  resizedColumns: TableColumnSetting[];
  onChangeWidth: (newColumnSetting: TableColumnSetting[]) => void;
};

export function useColumnsWidth(columns: TableColumnSetting[]): ReturnType {
  const [resizedColumns, setResizedColumns] =
    useState<TableColumnSetting[]>(columns);

  return { resizedColumns, onChangeWidth: setResizedColumns };
}
