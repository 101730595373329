import { useEffect, useState } from 'react';

import { DICTIONARIES } from '../constants/analytics';
import {
  trackAppIsOpened,
  trackAppIsReady,
  trackDictioanriesLoadingComplete,
  trackDictionaryLoaded,
  trackPortfolioLoaded,
} from '../lib/analytics';

import { shallow, useStore } from '../store';

import { AppState } from '../types/app';

export const useTechMetrics = (clientState: AppState) => {
  const [
    objectsReady,
    objectsLength,
    objectTypesReady,
    objectTypesLength,
    finInstrumentsReady,
    finInstrumentsLength,
    marketBoardsReady,
    marketBoardsLength,
    orderParamsReady,
    orderParamsLength,
    certificatesReady,
    certificatesLength,
    isPortfolioReady,
    ordersLength,
    accountsLength,
    subAccountsLength,
    subGTAccountsLength,
    subAccountRazdelLength,
    subAccountPositionsLength,
  ] = useStore(
    (state) => [
      state.objectsReady,
      state.objectsReady ? state.objects.length : 0,
      state.objectTypesReady,
      state.objectTypesReady ? state.objectTypes.length : 0,
      state.finInstrumentsReady,
      state.finInstrumentsReady ? state.finInstruments.length : 0,
      state.marketBoardsReady,
      state.marketBoardsReady ? state.marketBoards.length : 0,
      state.orderParamsReady,
      // Уберем 1000 рендеров при постепенной загрузке
      state.orderParamsReady ? state.orderParams.length : 0,
      state.certificatesReady,
      state.certificatesReady ? state.certificates.length : 0,
      state.isPortfolioReady,
      state.orders.length,
      state.accounts.length,
      state.subAccounts.length,
      state.subGTAccounts.length,
      state.subAccountRazdel.length,
      state.subAccountPositions.length,
    ],
    shallow
  );

  const [portfolioTracked, setPortfolioTracked] = useState(false);

  const isDictionariesReady =
    finInstrumentsReady &&
    objectsReady &&
    marketBoardsReady &&
    certificatesReady &&
    objectTypesReady;

  useEffect(() => {
    if (objectsReady) {
      trackDictionaryLoaded(DICTIONARIES.OBJECTS, objectsLength);
    }
  }, [objectsLength, objectsReady]);

  useEffect(() => {
    if (objectTypesReady) {
      trackDictionaryLoaded(DICTIONARIES.OBJECT_TYPES, objectTypesLength);
    }
  }, [objectTypesReady, objectTypesLength]);

  useEffect(() => {
    if (finInstrumentsReady) {
      trackDictionaryLoaded(DICTIONARIES.FIN_INSTRUMENTS, finInstrumentsLength);
    }
  }, [finInstrumentsLength, finInstrumentsReady]);

  useEffect(() => {
    if (marketBoardsReady) {
      trackDictionaryLoaded(DICTIONARIES.MARKET_BOARDS, marketBoardsLength);
    }
  }, [marketBoardsLength, marketBoardsReady]);

  useEffect(() => {
    if (orderParamsReady) {
      trackDictionaryLoaded(DICTIONARIES.ORDER_PARAMS, orderParamsLength);
    }
  }, [orderParamsLength, orderParamsReady]);

  useEffect(() => {
    if (certificatesReady) {
      trackDictionaryLoaded(DICTIONARIES.CERTIFICATES, certificatesLength);
    }
  }, [certificatesLength, certificatesReady]);

  useEffect(() => {
    if (isDictionariesReady) {
      trackDictioanriesLoadingComplete();
    }
  }, [isDictionariesReady]);

  useEffect(() => {
    if (isPortfolioReady && !portfolioTracked) {
      trackPortfolioLoaded({
        accountsLength,
        subAccountsLength,
        subGTAccountsLength,
        subAccountRazdelLength,
        subAccountPositionsLength,
        ordersLength,
      });
      setPortfolioTracked(true);
    }
  }, [
    accountsLength,
    isPortfolioReady,
    subAccountPositionsLength,
    subAccountRazdelLength,
    subAccountsLength,
    subGTAccountsLength,
    ordersLength,
    portfolioTracked,
  ]);

  useEffect(() => {
    if (clientState === AppState.NOT_READY) {
      trackAppIsOpened();
    }

    if (clientState === AppState.READY) {
      trackAppIsReady();
    }
  }, [clientState]);
};
