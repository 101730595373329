import { ReactNode, useState } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { Switch } from '@alfalab/core-components/switch';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { orderTypeAbbrMap } from '@terminal/core/constants/orders';
import {
  LifeTime,
  ObjectGroup,
  OrderType,
} from '@terminal/core/lib/client/entities';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { getSuitableActivationType } from '@terminal/core/lib/domain/getSuitableActivationType';
import {
  getAssetUnits,
  getDimensionedUnit,
  getMinority,
} from '@terminal/core/lib/format';
import { getServerTime } from '@terminal/core/lib/services/time';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import {
  InputSendOrderParams,
  SendOrderParams,
} from '@terminal/core/types/trading';

import { useWidgetContext } from '../../../../shared';
import { TextOrder } from '../TextOrder';

import styles from './OrderConfirm.module.css';

interface Props {
  params: SendOrderParams & InputSendOrderParams;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (params: SendOrderParams & InputSendOrderParams) => void;
  priceDecimals: number;
  fullFi?: ExtendedFI;
  additionalInstruction?: JSX.Element;
  total?: ReactNode;
  container?: () => HTMLDivElement;
  askForConfirmChange?: boolean;
}

interface ModalWrapperProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  container?: () => HTMLDivElement;
}

const getDate = (idLifeItem: LifeTime): string => {
  switch (idLifeItem) {
    case LifeTime.DAY:
      return 'DAY';
    case LifeTime.GTD:
      return 'GTC';
    case LifeTime.D30:
      return 'DAY 30';
    default:
      return '';
  }
};

const ModalWrapper = ({
  children,
  isOpen,
  onClose,
  container,
}: ModalWrapperProps) => (
  <BaseModal
    open={isOpen}
    onClose={onClose}
    container={container}
    contentClassName={styles.desktopModalContent}
    className={styles.desktopModal}
  >
    <div className={styles.header}>
      <Typography.Text view="primary-medium" weight="bold">
        Подтверждение
      </Typography.Text>
      <CrossMIcon
        className={styles.closeIcon}
        width={18}
        height={18}
        onClick={() => {
          onClose();
        }}
      />
    </div>
    {children}
  </BaseModal>
);

export const OrderConfirm = ({
  params,
  onConfirm,
  isOpen,
  onClose,
  fullFi,
  priceDecimals,
  additionalInstruction,
  container,
  askForConfirmChange = true,
  total,
}: Props) => {
  const [isOpenTextOrder, setIsOpenTextOrder] = useState(false);

  const { useSettings, setSetting } = useWidgetContext();
  const { useFinInfoExtsList } = useAlfaDirectContext();

  const {
    defaultValues: { alwaysConfirmOrders, orderDimension },
  } = useSettings();
  const finInfoExt = useFinInfoExtsList();
  const [confirmState, setConfirmState] = useState(!alwaysConfirmOrders);
  const icon = params.buy ? (
    <ArrowUpCompactXsIcon color="#2fc26e" width={18} height={18} />
  ) : (
    <ArrowDownCompactXsIcon color="#d91d0b" width={18} height={18} />
  );

  const isBond = fullFi?.idObjectGroup === ObjectGroup.Bonds;

  const getPrice = () => {
    if ([OrderType.MKT, OrderType.MIT].includes(params.idOrderType)) {
      return 'Рыночная цена';
    } else if (
      params.inputPrice &&
      params.secondPrice &&
      (OrderType.BRS === params.idOrderType ||
        OrderType.TBRS === params.idOrderType)
    ) {
      return (
        <>
          <Amount.Pure
            value={params.inputPrice * priceDecimals}
            currency={getSymbolToShow(
              fullFi?.currencyCode,
              fullFi?.idObjectGroup
            )}
            minority={priceDecimals}
          />
          {isBond ? '%' : undefined}
          &nbsp;|&nbsp;
          <Amount.Pure
            value={params.secondPrice * priceDecimals}
            currency={getSymbolToShow(
              fullFi?.currencyCode,
              fullFi?.idObjectGroup
            )}
            minority={priceDecimals}
          />
          {isBond ? '%' : undefined}
        </>
      );
    } else if (params.inputPrice) {
      return (
        <>
          <Amount.Pure
            value={params.inputPrice * priceDecimals}
            currency={getSymbolToShow(
              fullFi?.currencyCode,
              fullFi?.idObjectGroup
            )}
            minority={priceDecimals}
          />
          {isBond ? '%' : undefined}
        </>
      );
    } else {
      return 'Цена неизвестна';
    }
  };

  const finInfoExtItem = params.idFi ? finInfoExt[params.idFi] : undefined;
  const count = getDimensionedUnit(
    params.quantity || 0,
    finInfoExtItem?.lot,
    orderDimension
  );
  const suffix = getAssetUnits(count, orderDimension);

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} container={container}>
      <Typography.Text view="secondary-large" className={styles.confirmText}>
        Вы уверены, что хотите отправить заявку?
        <Gap size="m" />
        <span className={styles.closeParams}>
          {icon}
          &nbsp;
          <Amount.Pure
            value={count * getMinority(count)}
            minority={getMinority(count)}
            view="withZeroMinorPart"
          />
          &nbsp;
          {suffix}
          &nbsp;
          {fullFi?.symbolObject}
          &nbsp;
          {getPrice()}
          &nbsp;
          <Typography.Text view="secondary-large" color="secondary">
            {orderTypeAbbrMap[params.idOrderType]}
          </Typography.Text>
          {additionalInstruction ? (
            <>
              <span className={styles.confirmTextTitle}>С инструкцией</span>
              &nbsp;
              <span className={styles.instructions}>
                {additionalInstruction}
              </span>
            </>
          ) : (
            ''
          )}
        </span>
        {params.idOrderType === OrderType.STL &&
        params.limitLevelAlternative ? (
          <Typography.Text tag="div" view="secondary-large" color="secondary">
            Цена с проскоком не {params.buy ? 'более' : 'менее'}{' '}
            <>
              <Amount.Pure
                value={params.limitLevelAlternative * priceDecimals}
                currency={getSymbolToShow(
                  fullFi?.currencyCode,
                  fullFi?.idObjectGroup
                )}
                minority={priceDecimals}
              />
              {isBond ? '%' : undefined}
            </>
          </Typography.Text>
        ) : null}
        <Typography.Text
          tag="div"
          view="secondary-large"
          color="secondary"
        >{`Активация ${
          getSuitableActivationType(getServerTime(), params.activationTime)
            .content
        },
        ${getDate(params.idLifeTime)}`}</Typography.Text>
        {total ? (
          <>
            <span className={styles.sum}>Сумма</span>
            <span className={styles.closeParams}>{total}</span>
          </>
        ) : null}
        <Gap size="m" />
        <div
          className={styles.orderTextButton}
          onClick={() => setIsOpenTextOrder(true)}
        >
          <DocumentLinesMIcon height={16} width={16} />
          <Typography.Text view="secondary-small">
            Текстовое поручение
          </Typography.Text>
          <ChevronRightMIcon />
        </div>
        <TextOrder
          params={params}
          isOpen={isOpenTextOrder}
          onClose={() => setIsOpenTextOrder(false)}
          onConfirm={() => {
            if (alwaysConfirmOrders && confirmState) {
              setSetting('defaultValues', 'alwaysConfirmOrders', false);
            }

            onConfirm(params);
            setIsOpenTextOrder(false);
            onClose();
          }}
        />
        {alwaysConfirmOrders && askForConfirmChange && (
          <Switch
            checked={confirmState}
            label="Больше не спрашивать"
            onChange={() => setConfirmState((prevState) => !prevState)}
            className={styles.customSwitch}
            block
          />
        )}
      </Typography.Text>
      <div className={styles.closeActionButtons}>
        <Button
          view="tertiary"
          size="xs"
          block
          onClick={onClose}
          leftAddons={
            <CrossMIcon
              width={18}
              height={18}
              color="var(--color-dark-graphic-secondary)"
            />
          }
        >
          Отмена
        </Button>
        <Button
          view="secondary"
          size="xs"
          block
          onClick={() => {
            if (alwaysConfirmOrders && confirmState) {
              setSetting('defaultValues', 'alwaysConfirmOrders', false);
            }

            onConfirm(params);
            onClose();
          }}
          leftAddons={
            <CheckmarkMIcon
              width={18}
              height={18}
              color="var(--color-dark-graphic-secondary)"
            />
          }
        >
          Отправить
        </Button>
      </div>
    </ModalWrapper>
  );
};
