import { Button } from '@alfalab/core-components/button/modern';
import { Input } from '@alfalab/core-components/input/modern';

import { AppState } from '@terminal/core/types/app';
import { useLoginForm } from '@terminal/lk-core';

import { formatLoginError } from '../../entities/Login';

import styles from './LoginForm.module.css';

export const LoginForm = () => {
  const {
    username,
    password,
    error,
    lkAuthError,
    clientState,
    setPassword,
    setUsername,
    onSubmit,
  } = useLoginForm();

  return (
    <>
      <form className={styles.loginForm} onSubmit={onSubmit}>
        <Input
          value={username}
          block
          required
          size="m"
          label="Логин"
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          block
          type="password"
          value={password}
          required
          error={formatLoginError(error, lkAuthError)}
          size="m"
          label="Пароль"
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button
          view="accent"
          type="submit"
          size="m"
          loading={
            clientState === AppState.AUTHORIZING_FRONTENDS ||
            clientState === AppState.AUTHORIZING_LK
          }
          className={styles.submitButton}
        >
          Далее
        </Button>
      </form>
    </>
  );
};
