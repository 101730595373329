import { fetchLkResult, HttpMethod, LKResult } from './lkApi';
import { SignOrderStatus } from './lkSignOperation';

export interface SmsSignCreated extends LKResult {
  url: string;
  id: string;
  data?: any;
}

export async function smsSignOperation(
  url: string,
  method: HttpMethod = 'GET',
  body?: object
): Promise<SmsSignCreated> {
  const created = await fetchLkResult<SmsSignCreated>(url, method, body);

  return created;
}

export interface SmsSigningInit extends LKResult {
  opText: string;
  reference: string;
  phone: string;
}

export async function smsSignSendSms(
  operationUrl: string
): Promise<SmsSigningInit> {
  const res = await fetchLkResult<SmsSigningInit>(
    `${operationUrl}/send-sms`,
    'GET'
  );

  return res;
}

export interface SmsSignCodeCheckParams {
  smsReference: string;
  smsCode: string;
  opText: string;
  operationUrl: string;
}

export interface SmsSignResult extends LKResult {
  data?: {
    orderStatus?: SignOrderStatus;
  };
}

export async function smsSignCodeCheck(
  data: SmsSignCodeCheckParams
): Promise<SmsSignResult> {
  const { operationUrl, ...body } = data;
  const res = await fetchLkResult<SmsSignResult>(
    String(operationUrl),
    'POST',
    body
  );

  return res;
}

export async function certificateReject(certificateId: number) {
  return await smsSignOperation(
    `/api/cert/cancel/${certificateId}?type=sms`,
    'GET'
  );
}

export async function certificateConfirm(certificateId: number) {
  return await smsSignOperation(
    `/api/cert/confirm/${certificateId}?type=sms`,
    'GET'
  );
}
