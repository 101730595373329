import cn from 'classnames';
import { ReactNode } from 'react';

import { MainMenuButton } from './MainMenuButton';

import styles from './MainMenu.module.css';

export interface MainMenuItem<T> {
  name: string;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  type: T;
  hidden?: boolean;
}

interface MainMenuProps<T> {
  items: MainMenuItem<T>[];
  selected: T | null;
  onItemSelect: (item: MainMenuItem<T>) => void;
  itemClassName?: string;
  itemActiveClassName?: string;
}

export const MainMenu = <T extends string>({
  items,
  selected,
  onItemSelect,
  itemClassName,
  itemActiveClassName,
}: MainMenuProps<T>) => {
  return (
    <div className={styles.container}>
      {items
        .filter((item) => !item.hidden)
        .map((item) => (
          <MainMenuButton
            key={`menu-item-${item.type}`}
            className={cn(styles.item, itemClassName)}
            activeClassName={itemActiveClassName}
            name={item.name}
            icon={item.icon}
            rightIcon={item.rightIcon}
            active={item.type === selected}
            onClick={() => onItemSelect(item)}
          />
        ))}
    </div>
  );
};
