import { IJsonModel } from 'flexlayout-react';
import { v4 } from 'uuid';

import {
  WorkspaceConfigLS,
  WorkspaceConfigurationsLS,
} from '../store/slices/workspaceConfigurationsSlice';

import { LinkGroups } from '../types/layout';

export const layoutTabWidth = 200;

export const initialJsons: IJsonModel[] = [
  {
    global: {
      splitterSize: 4,
      splitterExtra: 0,
      tabEnableRename: false,
      tabSetEnableDrag: false,
      tabSetEnableMaximize: false,
      tabSetTabStripHeight: 24,
      tabSetMinWidth: 221.33333333333334,
      tabSetMinHeight: 168.16666666666666,
    },
    borders: [],
    layout: {
      type: 'row',
      id: v4(),
      weight: 3,
      children: [],
    },
  },
];

export const initialLayoutLinks: LinkGroups[] = [
  {
    blue: {
      nodeIds: [],
    },
    green: {
      nodeIds: [],
    },
    red: {
      nodeIds: [],
    },
    yellow: {
      nodeIds: [],
    },
  },
  {
    blue: {
      nodeIds: [],
    },
    green: {
      nodeIds: [],
    },
    red: {
      nodeIds: [],
    },
    yellow: {
      nodeIds: [],
    },
  },
];

export const initialLayoutNames = ['Главный экран'];
export const initialLayoutWindows = [false];

const workspaceConfigDefault: WorkspaceConfigLS = {
  timeStamp: Date.now(),
  layouts: initialJsons,
  layoutLinks: initialLayoutLinks,
  layoutNames: initialLayoutNames,
  layoutWindows: initialLayoutWindows,
  activeLayoutKey: 0,
  chart: {},
};

export const workspaceConfigurationsLSDefault: WorkspaceConfigurationsLS = {
  workspaceConfigurationActiveId: 0,
  workspaceConfigurations: [
    {
      name: 'Конфигурация по умолчанию',
      type: 'custom',
      id: v4(),
      lastAutoSavedConfig: workspaceConfigDefault,
      lastManualSavedConfig: workspaceConfigDefault,
    },
  ],
};
