import { useMutation, useQueryClient } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { moneyBankTemplateSave } from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useBankTemplateSave = () => {
  const client = useQueryClient();

  const addNotification = useNotification();

  return useMutation(moneyBankTemplateSave, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При сохранении шаблона произошла ошибка',
      });
    },
    onSettled: () => {
      client.invalidateQueries([LkQueryKeys.BankTemplates]);
    },
  });
};
