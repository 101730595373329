import { makeGetParams } from '../url';
import { fetchLkResult, LKResult } from './lkApi';

export interface LKBankCardAddParams {
  treaty: number;
  number: string;
  expirationDate: string;
  clientCardName: string;
}

export interface LKBankCardAddResponse {
  id: string;
}

export async function addBankCard(
  params: LKBankCardAddParams
): Promise<LKBankCardAddResponse> {
  const res = await fetchLkResult<LKBankCardAddResponse | LKResult>(
    `/api/transfers/money/deposit/cards/add`,
    'POST',
    params,
    true
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface BankCard {
  id: string;
  shortNumber: string;
  number: string;
  name: string;
  color: string;
  bankIconURL: string;
  skinURL: string;
  bankName: string;
  expirationDate: string;
  alfaCard: boolean;
  paymentSystem: 'VISA' | 'MasterCard';
  cvc: boolean;
  isAlfaDirectCard: boolean;
}

export interface BankCardsResponse {
  cards: BankCard[];
  chosenCardId: string;
}

export interface BankCardsParams {
  treaty: number;
}
export async function getBankCards(
  params: BankCardsParams
): Promise<BankCardsResponse> {
  const request = makeGetParams(params);
  const res = await fetchLkResult<BankCardsResponse | LKResult>(
    `/api/transfers/money/deposit/cards?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface BankCardDeleteParams {
  treaty: number;
  cardId: string;
}
export async function deleteBankCard(
  params: BankCardDeleteParams
): Promise<LKResult> {
  const res = await fetchLkResult<LKResult>(
    `/api/transfers/money/deposit/cards/delete`,
    'POST',
    params
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface BankCardFeeParams {
  treaty: number;
  cardId: string;
  sum: number;
}
export interface BankCardFee {
  fee: number;
  currency: string;
}
export async function getBankCardFee(
  params: BankCardFeeParams
): Promise<BankCardFee> {
  const request = makeGetParams(params);
  const res = await fetchLkResult<BankCardFee | LKResult>(
    `/api/transfers/money/deposit/fee?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface BankCardTransactionCreateParams {
  treaty: number;
  cardId: string;
  cvv: string;
  sum: number;
  redirectUri: string;
}
export interface ThreeDs {
  md: string;
  accessUrl: string;
  paReq: string;
  termUrl: string;
}
export interface BankCardTransaction {
  transferMetaData: string;
  confirmationType: 'SMS' | 'THREE_DS';
  confirmationPasswordLength: string;
  operationId: string;
  threeDs?: ThreeDs;
}
export async function createBankCardTransaction(
  params: BankCardTransactionCreateParams
): Promise<BankCardTransaction> {
  const res = await fetchLkResult<BankCardTransaction | LKResult>(
    `/api/transfers/money/deposit/create`,
    'POST',
    params,
    true
  );

  if ('code' in res) {
    throw new Error(res.message || 'Ошибка при создании транзакции');
  }

  return res;
}

export interface BankCardTransactionExecuteParams {
  code?: string;
  treaty: number;
  transferMetaData: string;
  operationId: string;
  paRes?: string;
  md?: string;
}
export interface BankCardTransactionExecute {
  reference: string;
  description: string | null;
  direction: string;
  amount: {
    value: number;
    currency: string;
    minorUnits: number;
  };
  senderInfo: {
    name: string;
    value: string;
    imageURL: string;
  };
  recipientInfo: {
    name: string;
    value: string;
    imageURL: string;
  };
  availableActions: {
    isAvailableForPDF: boolean;
    isAvailableForCreateTemplate: boolean;
    isAvailableForReceiptShare: boolean;
  };
}
export interface BankCardTransactionExecuteResult {
  code: number;
  message: string;
  amount: number;
  currency: string;
}
export async function bankCardTransactionExecute(
  params: BankCardTransactionExecuteParams
): Promise<BankCardTransactionExecute | LKResult> {
  return await fetchLkResult<BankCardTransactionExecute | LKResult>(
    `/api/transfers/money/deposit/execute`,
    'POST',
    params
  );
}
