import { addKeyIfExists } from '@terminal/core/lib/addKeyIfExists';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';
import { WorkspaceConfig } from '@terminal/core/store/slices/workspaceConfigurationsSlice';
import { HeaderMenuItem } from '@terminal/core/types/ui';
import { OpenTopUpModalFunc } from '@terminal/widgets/shared';

function findMostUsedSubAccount(config: WorkspaceConfig, layout: number) {
  const accountsUsage: Record<string, number> = {};
  let mostUsed;

  function traversal(node) {
    if (Array.isArray(node.config?.tableProps?.accounts)) {
      node.config?.tableProps?.accounts.forEach((subAccount: string) => {
        accountsUsage[subAccount] = (accountsUsage[subAccount] || 0) + 1;

        if (!mostUsed || accountsUsage[subAccount] > accountsUsage[mostUsed]) {
          mostUsed = subAccount;
        }
      });
    }

    if (node.children) {
      node.children.forEach(traversal);
    }
  }

  traversal(config.layouts[layout].layout);

  return mostUsed;
}

export const useTopUpModal = (): OpenTopUpModalFunc => {
  const configuration = useStore(activeConfigurationSelector);

  const [setHeaderMenuOpen, subAccountRazdel, getActiveLayoutKey] = useStore(
    (store) => [
      store.setHeaderMenuOpen,
      // eslint-disable-next-line no-restricted-syntax
      store.subAccountRazdel,
      store.getActiveLayoutKey,
    ],
    shallow
  );

  if (!configuration) {
    return () => {};
  }

  return ({ idSubAccount, selectedSubAccounts, sum } = {}) => {
    let idAccount: number | undefined;

    if (idSubAccount) {
      idAccount = subAccountRazdel.find(
        (razdel) => razdel.idSubAccount === idSubAccount
      )?.idAccount;
    } else if (selectedSubAccounts) {
      const codeSubAccount =
        selectedSubAccounts?.length === 1
          ? selectedSubAccounts[0]
          : findMostUsedSubAccount(
              // Ищем популярный счет из торговых виджетов
              configuration.lastAutoSavedConfig,
              getActiveLayoutKey()
            );

      idAccount = subAccountRazdel.find(
        (razdel) => razdel.codeSubAccount === codeSubAccount
      )?.idAccount;
    }

    setHeaderMenuOpen(HeaderMenuItem.MONEY_TRANSFER, {
      ...addKeyIfExists('defaultSelectedAccountId', idAccount),
      ...addKeyIfExists('topUpSum', sum),
    });
  };
};
