import cn from 'classnames';
import { memo, useCallback, useState } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { getInstrumentIconURL } from '@terminal/core/lib/icons';
import { useStore } from '@terminal/core/store';

import styles from './SymbolCell.module.css';

interface SymbolCellProps {
  nameMarketBoard?: string;
  idObject?: number;
  symbolObject?: string;
  onlyIcon?: boolean;
  isMobile?: boolean;
  isDesktop?: boolean;
  noIcon?: boolean;
  classNameIcon?: string;
}

export const SymbolCell = memo(
  ({
    nameMarketBoard,
    idObject,
    symbolObject,
    onlyIcon,
    isMobile,
    isDesktop,
    noIcon,
    classNameIcon,
  }: SymbolCellProps) => {
    const objectsTable = useStore((store) => store.objectsTable);
    const instrumentIcons = useStore((store) => store.instrumentIcons);

    const [iconError, setIconError] = useState(false);
    const fullObject = objectsTable.get('idObject', idObject!);
    const iconURL = getInstrumentIconURL(
      instrumentIcons,
      objectsTable,
      fullObject
    );

    // Если сервер не смог отдать картинку, несмотря на ее наличие в instrumentIcons,
    // иконка самовосстановится в состояние "Картинка с первой буквой тикера"
    const onError = useCallback(() => setIconError(true), []);

    const selectedDisplayInstrumentType = useStore(
      (state) => state.settings.defaultValues.selectedDisplayInstrumentType
    );
    const displayedName =
      selectedDisplayInstrumentType === 'name'
        ? fullObject?.nameObject
        : symbolObject;

    return (
      <div className={styles.instrumentSymbolCell}>
        {!noIcon && (
          <div
            className={cn(
              styles.instrumentIconMain,
              isMobile && styles.instrumentIconMainMobile,
              isDesktop && styles.instrumentIconMainDesktop,
              classNameIcon
            )}
          >
            {iconURL && !iconError && (
              <img src={iconURL} alt="" onError={onError} />
            )}
            {!(iconURL && !iconError) && symbolObject ? (
              <div
                className={cn(
                  styles.instrumentIconFallback,
                  isMobile && styles.instrumentIconFallbackMobile,
                  isDesktop && styles.instrumentIconFallbackDesktop
                )}
              >
                {symbolObject[0]}
              </div>
            ) : null}
          </div>
        )}
        {!onlyIcon ? (
          <span className={styles.displayedName}>
            {displayedName}&nbsp;
            {nameMarketBoard ? (
              <Typography.Text
                tag="span"
                view="secondary-large"
                color="secondary"
              >
                {nameMarketBoard}
              </Typography.Text>
            ) : null}
          </span>
        ) : null}
      </div>
    );
  },
  (prev, props) => prev.idObject === props.idObject
);
