import { ElementType } from '@terminal/core/types/layout';

import { BaseLayoutBlock } from './baseLayoutBlock';
import { TabSet } from './tabSet';

export class Row extends BaseLayoutBlock {
  readonly weight: number;
  readonly children: Array<TabSet | Row>;

  constructor(weight: number) {
    super(ElementType.ROW);
    this.weight = weight;
    this.children = [];
  }

  addChildren(children: Array<TabSet | Row>) {
    this.children.push(...children);
  }

  render() {
    return {
      type: this.type,
      id: this.id,
      weight: this.weight,
      children: this.children.map((child) => child.render()),
    };
  }
}
