import cn from 'classnames';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import { FC, useMemo, useState } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import {
  useFilteredSubAccounts,
  useSubAccountRazdel,
} from '@terminal/alfadirect/hooks';
import { sortObjKeys } from '@terminal/core/lib/sortObjKeys';

import { AccountDropdown } from '../../../AccountDropdown';

import { useAccountFilter } from '../../../AccountFilters/hooks';

import styles from './AccountPicker.module.css';

interface Props {
  selectedSubAccounts: string[];
  onSubAccountSelect: (subAccount: string) => void;
}

export const AccountPicker: FC<Props> = ({
  selectedSubAccounts,
  onSubAccountSelect,
}) => {
  const [searchText, setSearchText] = useState('');

  const subAccountRazdels = useSubAccountRazdel();
  const subAccounts = useMemo(() => {
    // Счета приходят не сразу, так что фильтруем undefined
    const filteredRazdels = subAccountRazdels.filter((item) => item);

    return Object.keys(groupBy(filteredRazdels, 'codeSubAccount'));
  }, [subAccountRazdels]);
  const isShouldBeVisible = !isEmpty(subAccounts);

  const filteredSubAccounts = useFilteredSubAccounts();
  const [selectedSubAccountLocal, setSelectedSubAccountLocal] = useState<
    string | undefined
  >(Object.keys(sortObjKeys(filteredSubAccounts))[0]);

  const onSave = (accounts: string[]) => {
    setSelectedSubAccountLocal(accounts[0]);
    onSubAccountSelect(accounts[0]);
  };

  const { options, onSelect } = useAccountFilter({
    isShouldBeVisible,
    isOrderBookVersion: true,
    selectedSubAccounts: selectedSubAccounts || selectedSubAccountLocal,
    searchText,
    onSave,
    subAccounts,
  });

  return (
    <>
      <AccountDropdown
        useAnchorWidth
        isOrderBookVersion
        hasRequirements={false}
        position="bottom-start"
        options={options}
        searchText={searchText}
        setSearchText={setSearchText}
        onSelect={onSelect}
        popoverClassName={styles.popover}
      >
        {(ref, isOpen) => (
          <div ref={ref} className={styles.picker}>
            <BriefcaseMIcon
              height={18}
              width={18}
              color="var(--color-light-graphic-secondary)"
            />
            <Typography.Text
              view="secondary-large"
              className={styles.accountName}
            >
              {selectedSubAccountLocal}
            </Typography.Text>
            <ChevronDownMIcon
              height={18}
              width={18}
              color="currentColor"
              className={cn(styles.chevron, isOpen && styles.chevronUp)}
            />
          </div>
        )}
      </AccountDropdown>
    </>
  );
};
