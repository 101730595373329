import { BaseModal } from '@alfalab/core-components/base-modal';
import { Button } from '@alfalab/core-components/button/modern';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import styles from './ConfirmModal.module.css';

interface Props {
  open: boolean;
  title?: React.ReactNode;
  text?: React.ReactNode;
  confirmLabel?: React.ReactNode;
  cancelLabel?: React.ReactNode;
  confirmIcon?: React.ReactNode;
  confirmLoading?: boolean;
  cancelIcon?: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmModal = (props: Props) => (
  <BaseModal open={props.open} onClose={props.onClose}>
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Text view="primary-medium" weight="bold">
          {props.title || 'Подтверждение'}
        </Typography.Text>
        <CrossMIcon
          className={styles.closeIcon}
          width={18}
          height={18}
          onClick={() => {
            props.onClose();
          }}
        />
      </div>
      <div className={styles.info}>
        <Typography.Text view="secondary-large">
          {props.text || 'Вы уверены, что хотите продолжить?'}
        </Typography.Text>
      </div>
      <div className={styles.buttonToolbar}>
        <Button
          view="tertiary"
          type="submit"
          size="s"
          onClick={props.onClose}
          leftAddons={props.cancelIcon}
        >
          {props.cancelLabel || 'Отмена'}
        </Button>
        <Button
          size="s"
          view="secondary"
          onClick={props.onConfirm}
          leftAddons={props.confirmIcon}
          loading={props.confirmLoading}
        >
          {props.confirmLabel || 'Подтвердить'}
        </Button>
      </div>
    </div>
  </BaseModal>
);
