import { ActivationTime } from '../constants/orderBook';
import {
  ExecutionType,
  LifeTime,
  OrderChannels,
  OrderStopPriceDirection,
  OrderType,
  PriceControlType,
  QuantityType,
} from '../lib/client/entities';
import { AllowedOrderParams } from './allowedOrderParams';
import { Certificate } from './certificate';
import { ExtendedFI } from './extendedFI';
import { SubAccountRazdelItem } from './subAccountRazdel';

export enum CommandTypeBase {
  Unknown = 0,
  // Подтвердить параметры нового ордера на сделку/операцию
  NEW = 1,
  // Подтвердить параметры нового сигнала МТС (виртуального ордера на сделку)
  MTS = 2,
  // Подтвердить параметры инструкции (алгоритма) ( т.е. активировать инструкцию(алгоритм))
  ALG = 3,
  // Подтвердить реквизиты для расчетов / установить мастер-сертификат
  SET = 4,
  // Редактировать срок действия ордера/инструкции (без изменения номера)
  EDIT = 5,
  // Подтвердить (дополнительно ранее подтвержденный) ордер, инструкцию или сертификат
  CONF = 6,
  // Отозвать (аннулировать) ордер, инструкцию, алгоритм или сертификат
  KILL = 7,
  // Отозвать (аннулировать) ордера или инструкции (алгоритм) по маске условий
  KILLM = 8,
  // Редактировать комментарий к ордеру или инструкции
  COM = 9,
  // Выставить индикативную котировку
  IND = 10,
  NewPhone = 11,
  KillPhone = 12,
  NewMargincall = 13,
  KillMargincall = 14,
}

export enum OrderSide {
  BUY = 'buy',
  SELL = 'sell',
}

export const TemplateNumMap = new Map<CommandTypeBase, string>([
  [CommandTypeBase.KILL, '01'],
  [CommandTypeBase.NEW, '08'],
  [CommandTypeBase.EDIT, '07'],
]);

export interface OrderRequest {
  fullFI: ExtendedFI;
  fullFIActivate?: ExtendedFI;
  idFIActivate?: number;
  workingCertificate: Certificate;
  rsaKeys: {
    id: number;
    keys: CryptoKeyPair;
    dateCreated: Date;
  };
  razdel: SubAccountRazdelItem;
  idAccount: number;
  orderParams: AllowedOrderParams;
  idOrderType: OrderType;
  idPriceControlType: PriceControlType;
  idLifeTime: LifeTime;
  login: string;
  fullName: string;
}

export interface InputOrderTextParams {
  command: CommandTypeBase;
  login: string;
  fullName: string;
  idAccount: number;
  signTime: Date;
  orderNum: string;
  orderParams: AllowedOrderParams;
  razdel?: SubAccountRazdelItem;
  buy?: boolean;
  fullFI?: ExtendedFI;
  fullFIActivate?: ExtendedFI;
  idLifeTime?: LifeTime;
  idPriceControlType: PriceControlType;
  quantity?: number;
  stopPrice?: number;
  limitPrice?: number;
  limitLevelAlternative?: number;
  openQuantity?: number;
  value?: number;
  withDrawTime?: Date;
  activationTime?: Date;
  clientNumEDocument?: string;
  linkedOrderId?: string;
  activationPriceDirection?: OrderStopPriceDirection;
  idFIActivate?: number;
}

export interface InputCancelOrderTextParams {
  command: CommandTypeBase.KILL;
  login: string;
  fullName: string;
  idAccount: number;
  signTime: Date;
  orderNum: string;
  idOrderType: number;
  clientNumEDocument: string;
  razdel: SubAccountRazdelItem;
}

export interface InputClientEnterOrderReq {
  signedOrderText: any;
  buy: boolean;
  idAccount: number;
  idSubAccount: number;
  idRazdel: number;
  idAllowedOrderParams: number;
  idCertificate: BigInt;
  idObject: number;
  signTime: Date;
  orderNum: number;
  limitPrice?: number;
  limitLevelAlternative?: number;
  openQuantity?: number;
  quantity: number;
  stopPrice?: number;
  withDrawTime?: Date;
  activationTime?: Date;
  idPriceControlType: PriceControlType;
  NumEDocumentBase?: string;
  activationPriceDirection?: OrderStopPriceDirection;
  idFIActivate?: number;
  orderChannel: OrderChannels;
}

export interface BracketOrderParams {
  idOrderType: OrderType;
  limitPrice?: number;
  stopPrice?: number;
  limitLevelAlternative?: number;
}

export interface SendOrderParams {
  idFi: number;
  idSubaccount: number;
  idOrderType: OrderType;
  buy: boolean;
  quantity: number;
  openQuantity?: number;
  idLifeTime: LifeTime;
  idExecutionType: ExecutionType;
  quantityType?: QuantityType;
  idActivationTime: ActivationTime;
  activationTime?: Date;
  stopPrice?: number;
  withDrawTime?: Date;
  limitPrice?: number;
  limitLevelAlternative?: number;
  linkedOrderId?: string;
  numEDocument?: string;
  priceControlType?: PriceControlType;
  activationPriceDirection?: OrderStopPriceDirection;
  idFIActivate?: number;
  bracket?: BracketOrderParams;
  last?: number;
}

export type InputSendOrderParams = {
  inputPrice: number;
  secondPrice?: number;
  last?: number;
};

export interface LimitRequestParams {
  idFi: number;
  orderType: OrderType;
  lifeTime: LifeTime;
  price: number;
  openQuantity?: number;
  secondPrice?: number;
  idPriceControlType?: PriceControlType;
}
