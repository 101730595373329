import cn from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside } from '@terminal/core/hooks';
import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';

import formstyles from '../../styles/forms.module.css';
import styles from './SelectAccount.module.css';

interface SelectAccountProps {
  selected?: string;
  accounts: TradeAccount[];
  accountsPending?: boolean;
  hasMore?: boolean;
  hint?: React.ReactNode;
  onAccountSelect: (acc: string) => void;
  accountsFetcher: () => void;
}

export const SelectAccount = ({
  selected,
  accounts,
  accountsPending,
  hasMore,
  hint,
  onAccountSelect,
  accountsFetcher,
}: SelectAccountProps) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  useOnClickOutside(contentRef, (e) => {
    if (
      e.target !== buttonRef.current &&
      !buttonRef.current?.contains(e.target as Node)
    ) {
      setOpen(false);
    }
  });

  const [loaderRef, loaderInView] = useInView();
  useEffect(() => {
    if (loaderInView) {
      accountsFetcher();
    }
  }, [loaderInView, accountsFetcher]);

  const toggleOpen = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setOpen(!open);
  };

  const accountSelect = (acc: string) => {
    onAccountSelect(acc);
    setOpen(false);
  };

  const accountOptions = useMemo(() => {
    if (!accounts) {
      return [];
    }
    return accounts
      .map((acc: TradeAccount) => acc.subAccount)
      .filter((accCode, index, self) => self.indexOf(accCode) === index) // Distinct
      .sort((a: string, b: string) => parseInt(a) - parseInt(b))
      .map((accCode: string) => ({ key: accCode, content: accCode }));
  }, [accounts]);

  return (
    <div className={styles.container} ref={boxRef}>
      <button
        ref={buttonRef}
        className={styles.button}
        onClick={(e: React.MouseEvent) => toggleOpen(e)}
      >
        <span className={styles.buttonContent}>
          {selected ? (
            <>
              <BriefcaseMIcon
                height={20}
                width={20}
                color="#8D8D93"
                className={styles.buttonIcon}
              />
              <Typography.Text
                view="secondary-large"
                className={styles.buttonText}
              >
                {selected}
              </Typography.Text>
            </>
          ) : (
            'Выберите счёт'
          )}
          <ChevronDownMIcon
            height={18}
            width={18}
            color="currentColor"
            className={cn(styles.chevron, open && styles.chevronUp)}
          />
        </span>
      </button>

      {hint && <div className={formstyles.hint}>{hint}</div>}

      <Dropdown
        useAnchorWidth
        anchorElement={boxRef.current}
        open={open}
        offset={[0, 4]}
        position="bottom-start"
        headerDivider={false}
      >
        <div className={styles.dropdownContent} ref={contentRef}>
          {accountOptions.map((acc) => {
            const isSelected = acc.key === selected;
            return (
              <Dropdown.Item
                key={acc.key}
                content={
                  <div className={styles.dropDownItemContent}>
                    {acc.content}
                  </div>
                }
                onClick={() => accountSelect(acc.key)}
                rightIcon={
                  isSelected ? (
                    <CheckmarkMIcon height={18} width={18} />
                  ) : undefined
                }
              />
            );
          })}
          {accountsPending && <Loader />}
          {hasMore && <div className={styles.loader} ref={loaderRef}></div>}
        </div>
      </Dropdown>
    </div>
  );
};
