import { Switch, SwitchProps } from 'formik-alfalab-core-components/Switch';
import React, { FC } from 'react';
import { FormControl } from '@alfalab/core-components/form-control';

import { TrackPriceSettings } from '@terminal/core/types/trackPrice';

import styles from './NotifySwitch.module.css';

export type NotifySwitchProps = Omit<SwitchProps, 'name'>;

const NAME: keyof TrackPriceSettings = 'notify';

export const NotifySwitch: FC<NotifySwitchProps> = (props) => {
  const { className, ...restProps } = props;

  return (
    <FormControl
      label="&nbsp;"
      labelView="outer"
      size="s"
      className={className}
      fieldClassName={styles.field}
    >
      <Switch
        name={NAME}
        reversed
        label="Звуковое оповещение"
        align="center"
        {...restProps}
      />
    </FormControl>
  );
};
