import { VFC } from 'react';
import { Space } from '@alfalab/core-components/space';
import { TagDesktop } from '@alfalab/core-components/tag/desktop';

import { ATTORNEY_STATUSES_MAP } from '../../lib/const/mappings';
import { AttorneyStatus } from '../../model';

export interface AttorneyTableFiltersProps {
  onChange: (status: AttorneyStatus) => void;
  value: AttorneyStatus;
  className?: string;
}

export const AttorneyTableFilters: VFC<AttorneyTableFiltersProps> = ({
  onChange,
  value,
  ...restProps
}) => (
  <Space direction="horizontal" size={8} {...restProps}>
    {Object.values(AttorneyStatus).map((status) => (
      <TagDesktop
        key={status}
        view="filled"
        size="xs"
        checked={status === value}
        onClick={() => onChange(status)}
      >
        {ATTORNEY_STATUSES_MAP[status]}
      </TagDesktop>
    ))}
  </Space>
);
