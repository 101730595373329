import React, { HTMLAttributes, VFC } from 'react';

import { shallow, useStore } from '@terminal/core/store';

import { CandlesIcon, Form } from '../../../../entities/DesignSetting';

export type DirectionColorFormProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
>;

export const DirectionColorForm: VFC<DirectionColorFormProps> = (props) => {
  const [settings, setSetting] = useStore(
    (state) => [state.settings, state.setSetting],
    shallow
  );
  const currentDirectionColors = settings.design?.directionColors;

  return (
    <Form
      title="Цвета покупки и продажи"
      subtitle={
        <>
          Выбор цветовой схемы для обозначения изменений в котировках,
          <br />
          свечей на графике, а также для кнопок торговли
        </>
      }
      value={currentDirectionColors}
      onChange={(value) => setSetting('design', 'directionColors', value)}
      options={[
        {
          value: 'greenAndRed',
          label: 'Зеленый и красный',
          icon: <CandlesIcon colorBuy="#13A463" colorSell="#D91D0B" />,
        },
        {
          value: 'blueAndRed',
          label: 'Синий и красный',
          icon: <CandlesIcon colorBuy="#0072EF" colorSell="#D91D0B" />,
        },
        {
          value: 'blueAndWhite',
          label: 'Синий и белый',
          icon: <CandlesIcon colorBuy="#0072EF" colorSell="#FFFFFF" />,
        },
      ]}
      {...props}
    />
  );
};
