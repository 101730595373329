import { NumberRange } from '@terminal/core/types/ranges';

export const prepareNumberRange = (value: NumberRange) => {
  let from = value?.from;
  let to = value?.to;

  if (from > to) {
    from = value?.to;
    to = value?.from;
  }

  return { from, to };
};
