import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowRightMIcon } from '@alfalab/icons-glyph/ArrowRightMIcon';

import { TestProgress } from '../TestProgress';
import { TestQuestion } from '../TestQuestion';

import { ThemeQuestion } from '../../model/types/ThemeQuestion';

import styles from './TestPlayer.module.css';

interface TestPlayerProps {
  title: string;
  questions: ThemeQuestion[];
  currentQuestionNum: number;
  currentQuestion: ThemeQuestion;
  currentAnswers: number[];
  setCurrentAnswer: (id: number) => void;
  onNextClick: () => void;
  loading?: boolean;
  getImageUrl?: (fileName: string) => string;
}

export const TestPlayer = ({
  title,
  questions,
  currentQuestionNum,
  currentQuestion,
  currentAnswers,
  setCurrentAnswer,
  onNextClick,
  loading,
  getImageUrl,
}: TestPlayerProps) => {
  return (
    <>
      <Typography.Text className={styles.title} weight="bold" tag="div">
        {title}
      </Typography.Text>

      <TestProgress
        className={styles.progress}
        current={currentQuestionNum}
        count={questions.length}
      />

      <TestQuestion
        question={currentQuestion}
        selectedId={currentAnswers}
        setAnswer={setCurrentAnswer}
        getImageUrl={getImageUrl}
      />

      <div className={styles.action}>
        <ButtonDesktop
          block
          size="s"
          rightAddons={<ArrowRightMIcon />}
          disabled={currentAnswers.length === 0}
          onClick={onNextClick}
          loading={loading}
        >
          Далее
        </ButtonDesktop>
      </div>
    </>
  );
};
