import { DIMENSIONS_SCHEMAS } from '../../../constants/analytics';
import { RequestDimensions, RequestNewDimensions } from './dimensions';

type Schemas = typeof DIMENSIONS_SCHEMAS;

export type LoadStatus = 'Success' | 'Error';

export interface AppVersionDimension {
  schema: Schemas['APP_VERSION'];
  data: {
    version: string;
    build: string;
  };
}

export interface CustomDimension {
  schema: Schemas['CUSTOM'];
  data: Record<string, string | boolean | null>;
}

export interface ExperimentDimension {
  schema: Schemas['EXPERIMENTAL'];
  data: {
    mkt_campaign: string;
    mkt_source: string;
    mkt_medium: string;
    mkt_term: string;
    mkt_content: string;
  };
}

export interface ScreenViewDimension {
  schema: Schemas['SCREEN_VIEW'];
  data: {
    name: string;
  };
}

export interface StrategiesDimension {
  schema: Schemas['STRATEGIES'];
  data: {
    dimension_2?: string; // model_id
    dimension_3?: string; // widget_type
    dimension_4?: string; // mode
    dimension_5?: string; // account type
    dimension_20?: string; // success
  };
}

export interface DefaultDimension {
  schema: Schemas[keyof Schemas];
  data: RequestDimensions | RequestNewDimensions;
}

export type Dimensions =
  | DefaultDimension
  | AppVersionDimension
  | CustomDimension
  | ExperimentDimension
  | ScreenViewDimension
  | StrategiesDimension;

export interface PortfolioParams {
  accountsLength: number;
  subAccountsLength: number;
  subGTAccountsLength: number;
  subAccountRazdelLength: number;
  subAccountPositionsLength: number;
  ordersLength: number;
}

export enum TradingFormSource {
  newWidgetMenu = 'newWidgetMenu',
  chartiqMenu = 'chartiqMenu',
  contextPosition = 'context.Position',
  contextIssue = 'context.Issue',
  contextDom = 'context.Dom',
}
