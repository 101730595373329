import { useMemo, useRef } from 'react';
import { UAParser } from 'ua-parser-js';

type ReturnType = {
  /**
   * Текст ошибки
   * */
  error: string;
  /**
   * Описание ошибки
   * */
  description: string;
  /**
   * Причина ошибки. Если не задано, то ошибка отсутствует
   * */
  reason?: 'dead' | 'temporarily-unsupported';
};

type BrowsersList = string[];

type Options = {
  /**
   * Список неподдерживаемых браузеров
   * @default ['IE', 'IEMobile']
   * */
  deadBrowsers?: BrowsersList;
  /**
   * Список временно неподдерживаемых браузеров
   * @default []
   * */
  temporarilyUnsupportedBrowsers?: BrowsersList;
};

const DEAD_BROWSERS_DEFAULT: string[] = ['IE', 'IEMobile'];

const TEMPORARILY_UNSUPPORTED_BROWSERS_DEFAULT: string[] = [];

const DEAD_BROWSER_ERROR_TEXT =
  'В вашем браузере отсутствуют функции, необходимые для правильной работы терминала';

const DEAD_BROWSER_DESCRIPTION_TEXT =
  'Обновите или смените браузер, чтобы продолжить работу. Мы рекомендуем браузеры Chrome или Safari';

const TEMPORARILY_UNSUPPORTED_BROWSER_ERROR_TEXT =
  'Мы не поддерживаем ваш браузер. Уже работаем, чтобы он был доступен';

const TEMPORARILY_UNSUPPORTED_BROWSER_DESCRIPOTION_TEXT =
  'А пока смените браузер, чтобы продолжить работу. Мы рекомендуем браузеры Chrome или Safari';

/**
 * Проверяет браузер на совместимость
 * */
export function useBadBrowser(options: Options = {}): ReturnType {
  const {
    deadBrowsers = DEAD_BROWSERS_DEFAULT,
    temporarilyUnsupportedBrowsers = TEMPORARILY_UNSUPPORTED_BROWSERS_DEFAULT,
  } = options;
  const browserNameRef = useRef(new UAParser().getBrowser().name || '');

  const isDead = useMemo<boolean>(
    () => deadBrowsers.indexOf(browserNameRef.current) !== -1,
    [deadBrowsers]
  );

  const isTemporarilyUnsupported = useMemo<boolean>(
    () => temporarilyUnsupportedBrowsers.indexOf(browserNameRef.current) !== -1,
    [temporarilyUnsupportedBrowsers]
  );

  if (isDead) {
    return {
      reason: 'dead',
      error: DEAD_BROWSER_ERROR_TEXT,
      description: DEAD_BROWSER_DESCRIPTION_TEXT,
    };
  }

  if (isTemporarilyUnsupported) {
    return {
      reason: 'temporarily-unsupported',
      error: TEMPORARILY_UNSUPPORTED_BROWSER_ERROR_TEXT,
      description: TEMPORARILY_UNSUPPORTED_BROWSER_DESCRIPOTION_TEXT,
    };
  }

  return {
    error: '',
    description: '',
  };
}
