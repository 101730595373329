import {
  DocumentType,
  MarketBoardType,
  OrderType,
} from '@terminal/core/lib/client/entities';
import { formatOrderDate } from '@terminal/core/lib/format';
import {
  GenerateHeaderProps,
  TextOrderService as TextOrderServiceBase,
} from '@terminal/core/lib/services/textOrder';

export class TextOrderService extends TextOrderServiceBase {
  /** Генерация заголовка заявки */
  override generateHeader({
    idOrderType,
    login,
    nameClient,
    fullName,
    idAccount,
    signTime,
    orderNum,
    templateNum,
    fullFI,
    idDocumentType,
  }: GenerateHeaderProps) {
    // В реальности единственные внебиржевые поручения что сейчас мы поддерживаем это борда OTC
    // возможно в дальнейшем здесь нужно будет что-то менять
    const isAdressed =
      idOrderType === OrderType.RPS || idOrderType === OrderType.TechRPS;

    let firstLine = `Электронное ${
      isAdressed ? 'поручение' : 'сообщение'
    } по системе "Go Invest"`;

    // Маркет борда OTC внебиржевая. Супер редкий кейс.
    if (
      idDocumentType === DocumentType.Manual || // инфа была от Белова что для мануальных тоже надо шапку менять
      fullFI?.type.value === MarketBoardType.OTC
    ) {
      firstLine = `Электронный документ: Поручение с дополнительной обработкой по системе "Go Invest"`;
    }

    let orderAuthor = `${
      isAdressed ? 'Инициатор поручения:' : 'Инициатор ордера:'
    } ${fullName} (${login})\r\n`;

    // Кейс борды OTC
    if (fullFI?.type.value === MarketBoardType.OTC) {
      orderAuthor = `Инициатор: ${fullName} (${login})\r\n`;
    }

    return (
      `${firstLine} (#${templateNum})\r\n` +
      `Кому: ООО "Го Инвест"\r\n` +
      (isAdressed
        ? 'Вид документа: «Поручение на приобретение ценных бумаг в процессе размещения эмитентом или при первичном публичном размещении»\r\n'
        : '') +
      `Клиент: ${nameClient}\r\n` +
      `Основание: Генеральное Соглашение № ${idAccount}\r\n` +
      orderAuthor +
      `Подтверждено инициатором: ${formatOrderDate(signTime)} (Мск)\r\n` +
      `Исходящий номер: ${orderNum}`
    );
  }
}
