import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Space } from '@alfalab/core-components/space';

import { Plate } from '../../../../shared/ui/Plate';

interface Props {
  formattedAccured: string;
  formattedDue: string;
  formattedwithheld: string;
  taxRate: string;
}

export const MoneyTaxDetailAccrued = ({
  formattedAccured,
  formattedDue,
  formattedwithheld,
  taxRate,
}: Props) => (
  <Space fullWidth={true} direction="vertical">
    <Row>
      <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
        <Plate label="Исчислено">{formattedAccured}</Plate>
      </Col>
      <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
        <Plate label="К доплате">{formattedDue}</Plate>
      </Col>
    </Row>
    <Row>
      <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
        <Plate label="Удержано">{formattedwithheld}</Plate>
      </Col>
      <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
        <Plate label="Ставка НДФЛ">{taxRate}</Plate>
      </Col>
    </Row>
  </Space>
);
