import { SubAccountPositionItem } from '../../types/subAccountPosition';

/**
 * @returns Входящую позицию в портфеле (ВхПозиция)
 * @param positionItem элемент в портфеле
 * */
export function getBackPos({
  backPos,
  buyQty,
  sellQty,
  sessionBuyQty,
  sessionSellQty,
}: SubAccountPositionItem): number {
  return backPos + buyQty - sellQty - sessionBuyQty + sessionSellQty;
}
