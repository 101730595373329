import { Button } from '@alfalab/core-components/button';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';
import { QrCodeMIcon } from '@alfalab/icons-glyph/QrCodeMIcon';

import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import formstyles from '../../../styles/forms.module.css';
import styles from './MoneyRequisitesActions.module.css';

interface MoneyRequisitesActionsProps {
  onQrOpen: () => void;
  treaty: Treaty | null;
  onPdfDownload: () => void;
  pdfLoading: boolean;
  pdfFile: DownloadedFile | null;
}

export const MoneyRequisitesActions = ({
  onQrOpen,
  treaty,
  onPdfDownload,
  pdfLoading,
  pdfFile,
}: MoneyRequisitesActionsProps) => (
  <div className={styles.actions}>
    <Row gutter={16}>
      <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
        <Button
          className={formstyles.buttonSmall}
          size="s"
          view="tertiary"
          block
          onClick={onQrOpen}
          leftAddons={
            <QrCodeMIcon width={18} height={18} className={formstyles.icon} />
          }
        >
          QR код
        </Button>
      </Col>
      <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
        <Button
          className={formstyles.buttonSmall}
          size="s"
          view="tertiary"
          block
          onClick={pdfFile ? undefined : onPdfDownload}
          href={pdfFile?.url}
          download={pdfFile?.filename}
          target={pdfFile ? '_blank' : undefined}
          loading={pdfLoading}
          leftAddons={
            <OutsideMIcon width={18} height={18} className={formstyles.icon} />
          }
        >
          {pdfFile ? 'Открыть в PDF' : 'Скачать PDF'}
        </Button>
      </Col>
    </Row>
  </div>
);
