import { getDimensionedUnit } from '../../lib/format';

import { TradingLimitsShowMode } from '../../types/layout';
import { OrderDimensionEnum } from '../../types/ui';

export const getLimitsValue = (
  displaySetting: TradingLimitsShowMode,
  amount: number,
  quantity: number,
  lot: number | undefined,
  orderDimension: OrderDimensionEnum
) =>
  displaySetting === TradingLimitsShowMode.MONEY
    ? amount
    : getDimensionedUnit(quantity, lot, orderDimension);
