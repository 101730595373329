import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getTreaties } from '@terminal/core/lib/rest/lkCommon';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../queryKeys';

/**
 * Treaties - список ген. соглашений, к которому у пользователя есть права доступа.
 * Хранит номер ген. соглашения и некотороые вспомогательные признаки
 */

export const useTreaties = () => {
  const addNotification = useNotification();

  return useQuery([LkQueryKeys.Treaties], getTreaties, {
    staleTime: 60 * 60 * 1000,
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса списка счетов',
        text: error?.message || '',
      });
    },
  });
};
