import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import {
  SmsSignCreated,
  SmsSigningInit,
} from '@terminal/core/lib/rest/lkSmsSignOperation';
import { Certificate } from '@terminal/core/types/certificate';

import { CertificateEnrollStep } from './types';

type CertificateEnrollStore = {
  // Текущий шаг
  step: CertificateEnrollStep;
  // Сертификат, выбранный для отзыва
  rejectingCertificate: Certificate | null;
  operation: SmsSignCreated | null;
  assignment: SmsSigningInit | null;
  error: { code: number; message: string | null } | null;
  countStart: number | null;
};

type CertificateEnrollStoreActions = {
  setStep: (step: CertificateEnrollStore['step']) => void;
  setRejectingCertificate: (
    certificate: CertificateEnrollStore['rejectingCertificate']
  ) => void;
  setOperation: (operation: CertificateEnrollStore['operation']) => void;
  setAssignment: (assignment: CertificateEnrollStore['assignment']) => void;
  setError: (error: CertificateEnrollStore['error']) => void;
  clearError: () => void;
  setCountStart: (countStart: CertificateEnrollStore['countStart']) => void;
};

const initialState: CertificateEnrollStore = {
  step: CertificateEnrollStep.Confirm,
  rejectingCertificate: null,
  operation: null,
  assignment: null,
  error: null,
  countStart: null,
};

export const useCertificateEnrollStore = createWithEqualityFn<
  CertificateEnrollStore & CertificateEnrollStoreActions
>(
  (set) => ({
    ...initialState,
    setStep: (step: CertificateEnrollStep) => set({ step }),
    setRejectingCertificate: (rejectingCertificate: Certificate | null) =>
      set({ rejectingCertificate }),
    setOperation: (operation) => {
      set({ operation });
    },
    setAssignment: (assignment) => {
      set({ assignment });
    },
    setError: (error) => {
      set({ error });
    },
    clearError: () => set({ error: null }),
    setCountStart: (countStart) => set({ countStart }),
  }),
  shallow
);
