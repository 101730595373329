import React from 'react';

import styles from './List.module.css';

interface ListProps extends React.HTMLAttributes<HTMLUListElement> {
  children?: React.ReactNode;
}

export const List = ({ children, ...props }: ListProps) => {
  return (
    <ul {...props} className={styles.list}>
      {children}
    </ul>
  );
};
