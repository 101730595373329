import { add } from 'date-fns';

import { alfaDirectClient } from '../../client/client';
import { FrontEndType, Operation } from '../../client/entities';
import { EntityType } from '../../client/entityTypes';
import { StreamingService, SubscribeReturnData } from '../streaming';

import { TrackPrice, TrackPriceState } from '../../../types/trackPrice';

interface NewTrackPrice
  extends Pick<
    TrackPrice,
    'IdFI' | 'TargetPrice' | 'InitialPrice' | 'SymbolSpec'
  > {}

type OperationsMap = Map<BigInt, (v: TrackPrice) => void>;

// TODO переделать на EntityService

class TrackPriceService {
  private apiTimeout: number = 5000;

  private addOperations: OperationsMap = new Map([]);

  private updateOperations: OperationsMap = new Map([]);

  private deleteOperations: OperationsMap = new Map([]);

  subscribe(updater: (message: TrackPrice[]) => void) {
    return StreamingService.subscribe<TrackPrice>(
      {
        fi: [],
        entity: EntityType.TrackPriceEntity,
        frontend: FrontEndType.AuthAndOperInitServer,
      },
      (message) => {
        // Сразу после создания алерта приходит элемент с пустым IdTrackPrice.
        // После записи в БД приходит элемент с настоящим IdTrackPrice
        const alerts = message.data.filter(
          ({ IdTrackPrice }) => IdTrackPrice !== 0n
        );

        // Если для какого-то алерта есть промис, пытаемся разрезолвить его
        for (const alert of alerts) {
          this.getResolver(alert)?.(alert);
        }

        updater(alerts);
      }
    );
  }

  unsubscribe<T>(subscribeReturnData: SubscribeReturnData<T>) {
    StreamingService.unsubscribe(
      {
        fi: [],
        entity: EntityType.TrackPriceEntity,
        frontend: FrontEndType.AuthAndOperInitServer,
      },
      subscribeReturnData
    );
  }

  addAlert(value: NewTrackPrice) {
    const alertData = this.createNewAlert(value);

    return this.postEntity(alertData, this.addOperations);
  }

  updateAlert(alert: TrackPrice) {
    return this.postEntity(
      {
        ...alert,
        Operation: Operation.Updated,
      },
      this.updateOperations
    );
  }

  deleteAlert(alert: TrackPrice) {
    return this.postEntity(
      {
        ...alert,
        IdState: TrackPriceState.Deleted,
        Operation: Operation.Deleted,
      },
      this.deleteOperations
    );
  }

  deleteAlerts(alerts: TrackPrice[]) {
    return Promise.all(alerts.map((alert) => this.deleteAlert(alert)));
  }

  getEmptyAlert(): TrackPrice {
    return {
      IdFI: NaN,
      TargetPrice: NaN,
      InitialPrice: NaN,
      SymbolSpec: '',
      TrackNum: 0n,
      IdType: 1,
      IdState: 0,
      ExpirationDate: new Date(),
      LastUpdate: new Date(),
      Version: 0n,
      Operation: 0,
      IdTrackPrice: 0n,
    };
  }

  private getResolver(value: TrackPrice) {
    if (value.IdState === TrackPriceState.Active) {
      const operations =
        value.Operation === Operation.Inserted
          ? this.addOperations
          : this.updateOperations;

      return operations.get(value.TrackNum);
    }

    if (value.IdState === TrackPriceState.Deleted) {
      return this.deleteOperations.get(value.TrackNum);
    }
  }

  private generateTrackNum() {
    const arr = new BigInt64Array(1);

    return crypto.getRandomValues(arr)[0];
  }

  private createNewAlert(newTrackPrice: NewTrackPrice): TrackPrice {
    const date = new Date();

    return {
      ...this.getEmptyAlert(),
      ...newTrackPrice,
      TrackNum: this.generateTrackNum(),
      ExpirationDate: add(date, { months: 1 }),
      LastUpdate: date,
    };
  }

  private postEntity(
    alertData: TrackPrice,
    operations: OperationsMap
  ): Promise<TrackPrice> {
    const { settings, ...trackPrice } = alertData;

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject('TrackPriceService: response timeout');
        operations.delete(alertData.TrackNum);
      }, this.apiTimeout);

      const successHandler = (responseData: TrackPrice) => {
        resolve(responseData);
        operations.delete(alertData.TrackNum);
      };

      operations.set(alertData.TrackNum, successHandler);

      alfaDirectClient.send({
        frontend: FrontEndType.AuthAndOperInitServer,
        isArray: false,
        payload: {
          type: EntityType.TrackPriceEntity,
          data: trackPrice,
        },
      });
    });
  }
}

export const trackPriceService = new TrackPriceService();
