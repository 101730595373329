import { createContext } from 'react';

import { ChatContext } from './types';

export const Context = createContext<ChatContext>({
  supportChat: false,
  supportEmail: '',
  feedbackEmail: '',
  supportPhone: '',
});
