import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { useAppContext } from '@terminal/core/hooks/useAppContext';

import { useThemeContext } from '../../../shared/lib/themeContext';

import { StepScreenProps } from '../types';

import styles from './Steps.module.css';

export const FirstStep = ({ style }: StepScreenProps) => {
  const { theme } = useThemeContext();
  const { onboarding } = useAppContext();
  const { widgets } = onboarding.images[theme];

  return (
    <div style={style} className={styles.slideContainer}>
      <div className={styles.stepContainer}>
        <Space fullWidth>
          <Typography.Text view="primary-large" weight="bold">
            Виджеты
          </Typography.Text>
          <Typography.Text view="secondary-large">
            Рабочая область терминала состоит из виджетов, с помощью которых
            происходит работа с инструментами.
          </Typography.Text>
          <Typography.Text view="secondary-large">
            Виджеты внутри терминала могут быть расположены в любом месте. Вы
            можете изменять стандартную конфигурацию путем перетаскивания,
            изменения размера и открытия новых виджетов.
          </Typography.Text>
          <div
            className={styles.firstStepImage}
            style={{ backgroundImage: `url(${widgets})` }}
          />
        </Space>
      </div>
    </div>
  );
};
