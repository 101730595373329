import React, { useEffect, useState } from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { List } from '@alfalab/core-components/list';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossSIcon } from '@alfalab/icons-glyph/CrossSIcon';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import { useStore } from '@terminal/core/store';
import { selectIsEFRAccount } from '@terminal/core/store/selectors';

import { useSrEfrBannerStore } from '../../../entities/SrEfrBanner';
import SrEfrImage from '../images/sr-efr.png';

import styles from './SrEfrBanner.module.css';

export const SrEfrBanner = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const isEFRAccount = useStore(selectIsEFRAccount);

  const [getViewed, setViewed, increaseBannerCloseCount] = useSrEfrBannerStore(
    (store) => [
      store.getViewed,
      store.setViewed,
      store.increaseBannerCloseCount,
    ]
  );

  useEffect(() => {
    const viewed = getViewed();

    setShowBanner(isEFRAccount && !viewed);
  }, [isEFRAccount, getViewed]);

  const handleAgree = () => {
    setViewed();
    setShowModal(false);
    setShowBanner(false);
  };

  const handleCloseBanner = () => {
    setShowBanner(false);
    increaseBannerCloseCount();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setViewed();
  };

  return (
    <>
      {showBanner ? (
        <div className={styles.container}>
          <div>
            <Typography.Text
              view="secondary-large"
              weight="bold"
              className={styles.text}
            >
              Теперь ваш счет на едином рынке. Что изменилось?
            </Typography.Text>
            <Gap tag="div" direction="horizontal" size="l" />
            <ButtonDesktop
              size="xxs"
              className={styles.moreButton}
              onClick={() => setShowModal(true)}
            >
              Узнать подробнее
            </ButtonDesktop>
          </div>

          <IconButton
            size="xs"
            view="secondary"
            icon={CrossSIcon}
            onClick={handleCloseBanner}
            className={styles.closeButton}
          />
        </div>
      ) : null}

      <BaseModal
        open={showModal}
        onClose={handleCloseModal}
        Backdrop={() => null}
      >
        <FunctionalModal
          width={520}
          height={640}
          title="Что нового"
          onClose={handleCloseModal}
          drawerId="sr-efr-modal"
          contentStyle={{
            padding: 0,
          }}
        >
          <div className={styles.imageContainer}>
            <img height={232} src={SrEfrImage} alt="Картинка новости" />
          </div>
          <div className={styles.body}>
            <Typography.Text tag="div" view="primary-large" weight="bold">
              Единый рынок: новое решение
              <br />
              для эффективного использования активов
            </Typography.Text>

            <Gap size="m" />

            <Typography.Text
              tag="div"
              view="secondary-large"
              className={styles.text}
            >
              Теперь обеспечением для совершения сделок на Срочном
              <br />
              рынке выступают все маржинальные биржевые активы
              <br /> инвестора на Фондовом и/или Валютном рынках
            </Typography.Text>

            <Gap size="2xl" />

            <Typography.Text tag="div" view="primary-large" weight="bold">
              Что важно знать:
            </Typography.Text>

            <Gap size="xl" />

            <List
              tag="ul"
              marker={
                <Typography.Text view="secondary-large">—</Typography.Text>
              }
              className={styles.list}
            >
              <List.Item>
                <Typography.Text tag="div" view="secondary-large">
                  Для торговли производными инструментами теперь не нужно каждый
                  раз переводить денежные средства на отдельный счёт и
                  возвращать их обратно для сделок с акциями, облигациями или
                  валютой
                </Typography.Text>
              </List.Item>
              <List.Item>
                <Typography.Text tag="div" view="secondary-large">
                  Единый рынок (ЕР) предоставляет единую позицию для
                  маржинального кредитования. Расчёт достаточности собственных
                  средств инвестора производится по всем активам на брокерском
                  счёте в целом
                </Typography.Text>
              </List.Item>
              <List.Item>
                <Typography.Text tag="div" view="secondary-large">
                  В случае недостаточности средств инвестора принудительное
                  закрытие позиций начинается с производных инструментов
                </Typography.Text>
              </List.Item>
              <List.Item>
                <Typography.Text tag="div" view="secondary-large">
                  Открытие любых необеспеченных (маржинальных) позиций в ценных
                  бумагах, иностранной валюте и/или фьючерсах в течение
                  торгового дня для инвестора бесплатно
                </Typography.Text>
              </List.Item>
              <List.Item>
                <Typography.Text tag="div" view="secondary-large">
                  Открытие и сопровождение ЕР для инвестора бесплатное. За
                  сделки и маржинальное кредитование между торговыми днями
                  взимается вознаграждение в соответствии с действующими
                  условиями по брокерскому счёту, которые для ЕР не изменяются
                </Typography.Text>
              </List.Item>
              <List.Item>
                <Typography.Text tag="div" view="secondary-large">
                  ЕР на брокерском счёте доступен и подключается всем инвесторам
                  автоматически. Инвестор вправе отказаться от ЕР, подав брокеру
                  соответствующее Заявление
                </Typography.Text>
              </List.Item>
            </List>

            <Gap size="2xl" />

            <ButtonDesktop
              size="s"
              view="filled"
              onClick={handleAgree}
              leftAddons={<CheckmarkMIcon width={18} height={18} />}
              className={styles.submitButton}
            >
              Понятно
            </ButtonDesktop>
          </div>
        </FunctionalModal>
      </BaseModal>
    </>
  );
};
