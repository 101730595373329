import { useEffect } from 'react';

import { useStore } from '@terminal/core/store';
import { Treaty } from '@terminal/lk-core';

export type UseInitialiseTretiesProps = {
  treaty: Treaty | null;
  treaties: Treaty[] | undefined;
  selectedAccountId?: number;
  setTreaty: (treaty: Treaty) => void;
  treatyFilter?: (acc: Treaty) => boolean;
  onSelect?: (treaty: Treaty) => void;
};

export const useInitialiseTreties = (props: UseInitialiseTretiesProps) => {
  const {
    treaty,
    treaties,
    selectedAccountId,
    setTreaty,
    treatyFilter,
    onSelect,
  } = props;
  const marginCallAccount = useStore((state) => state.marginCallAccount);

  useEffect(() => {
    if (!treaty && treaties && treaties.length && onSelect) {
      const available = treatyFilter ? treaties.filter(treatyFilter) : treaties;
      // сначала выбираем по переданному id, потом по требованиям
      let selected: Treaty | undefined;

      if (selectedAccountId) {
        selected = available.find((acc) => acc.treaty === selectedAccountId);
      } else if (marginCallAccount) {
        selected = available.find((acc) => acc.name === marginCallAccount.code);
      } else {
        selected = available.find((acc) => acc.selected);
      }

      if (selected) {
        setTreaty(selected);
        onSelect(selected);
      } else if (available.length) {
        setTreaty(available[0]);
        onSelect(available[0]);
      }
    }
  }, [
    treaty,
    treaties,
    treatyFilter,
    onSelect,
    setTreaty,
    marginCallAccount,
    selectedAccountId,
  ]);
};
