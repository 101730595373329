import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import classes from './InstrumentDataRowGrid.module.css';

interface Props {
  titleLeft?: ReactNode;
  valueLeft?: ReactNode;
  titleRight?: ReactNode;
  valueRight?: ReactNode;
  blockEnd?: boolean;
}

export const InstrumentDataRowGrid: FC<Props> = ({
  titleLeft,
  titleRight,
  valueLeft,
  valueRight,
  blockEnd = false,
}) => {
  return (
    <div
      className={cn([classes.dataRowContainer, blockEnd && classes.blockEnd])}
    >
      <div className={classes.dataRowPart}>
        {titleLeft && valueLeft && (
          <Typography.Text color="secondary" view="secondary-large">
            {titleLeft}
          </Typography.Text>
        )}
        {valueLeft && (
          <Typography.Text color="secondary" view="secondary-large">
            {valueLeft}
          </Typography.Text>
        )}
      </div>
      <div className={classes.dataRowPart}>
        {titleRight && valueRight && (
          <Typography.Text color="secondary" view="secondary-large">
            {titleRight}
          </Typography.Text>
        )}
        {valueRight && (
          <Typography.Text color="secondary" view="secondary-large">
            {valueRight}
          </Typography.Text>
        )}
      </div>
    </div>
  );
};
