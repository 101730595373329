import log from 'loglevel';
import pako from 'pako';

import { Entity, MessagePayload, UNKNOWN_ENTITY } from './entities';
import { EntityType } from './entityTypes';
import {
  ArrayFieldMeta,
  ConditionFieldMeta,
  createEntity,
  EntityMeta,
  EnumFieldMeta,
  EPOCH_TICKS,
  FieldMeta,
  FieldType,
  getEntityNameById,
  getMeta,
  isEntityMasked,
  TICKS_PER_MILLISECOND,
  UNKNOWN_ENTITY_MSG,
  UnknownFieldMeta,
} from './serialization';

const sharedTextDecoder = new TextDecoder();

// Декодирует содержимое бинарного пакета. Вернет или одну энтити как тип + объект
// или массив тип+объекты для каждого типа сообщения
//
// decode  --> decodeMessageBody -> decodeEntity -> decodeEntity (для каждой в пакете)
//         \-> decodeArchive -> decodeMessageBody -> decodeEntity -> decodeEntity
//
function decode(buffer: ArrayBuffer): MessagePayload {
  const dataView = new DataView(buffer);
  const isArchive = dataView.getInt8(3) !== 0; // этот флаг 0x80 или 128
  const entityTypeId = dataView.getInt16(6, true);

  if (isArchive) {
    return decodeArchive(buffer);
  } else {
    const entityType = getEntityNameById(entityTypeId) || UNKNOWN_ENTITY;

    if (entityType === UNKNOWN_ENTITY) {
      log.warn(`Unknown entity with type ${entityTypeId} recieved`);
    }

    const dv = new DataView(buffer, 4);

    return decodeMessageBody(dv, buffer.byteLength);
  }
}

function decodeArchive(message: ArrayBuffer): MessagePayload {
  const compressed = new Uint8Array(message, 4); // надо дропнуть заголовок

  try {
    const result = pako.inflate(compressed);

    if (!result) {
      throw new Error('Corrupted archived message body');
    }

    const dv = new DataView(result.buffer);
    const entityTypeId = dv.getUint16(2, true);
    const entityName = getEntityNameById(entityTypeId) || UNKNOWN_ENTITY;

    if (entityName === UNKNOWN_ENTITY) {
      log.warn(
        `Unknown entity with type ${entityTypeId} inside archive recieved`
      );
    }

    return decodeMessageBody(dv, result.buffer.byteLength);
  } catch (err) {
    log.error(err);

    return { type: UNKNOWN_ENTITY, data: {} };
  }
}

function decodeMessageBody(dv: DataView, messageSize: number): MessagePayload {
  // берем первую энтити в списке и чекаем сообщение из одной энтити или нескольких
  const firstEntitySize = dv.getUint16(0, true);
  const firstEntityType = dv.getInt16(2, true);

  const firstEntityTypeName = getEntityNameById(firstEntityType) as EntityType;
  const singleEntityMessage = firstEntitySize + 4 === messageSize;

  if (singleEntityMessage) {
    let result = { type: UNKNOWN_ENTITY, data: {} };

    try {
      if (firstEntityTypeName !== undefined) {
        result = {
          type: firstEntityTypeName,
          data: decodeEntity(dv)[1],
        };

        return result;
      }
    } catch (err) {
      log.error(err);
    } finally {
      return result;
    }
  } else {
    let offset = 0;
    let length = firstEntitySize;

    const result: MessagePayload = [];

    // идея в том что энтити придут сгруппированные по типам, а не в разнобой
    // иначе придеться дополнительно их группировать
    const firstEntityGroup = {
      type: firstEntityTypeName,
      data: [],
    } as { type: EntityType; data: object[] };

    let currentGroup = firstEntityGroup;
    let currentEntityType = firstEntityType;

    result.push(firstEntityGroup);

    while (offset + length <= dv.byteLength) {
      try {
        const entity = decodeEntity(
          new DataView(dv.buffer, offset + dv.byteOffset, length)
        );

        if (entity[0] !== currentEntityType) {
          currentGroup = {
            type: getEntityNameById(entity[0]) || UNKNOWN_ENTITY,
            data: [entity[1]],
          };

          result.push(currentGroup);
          currentEntityType = entity[0];
        } else {
          currentGroup.data.push(entity[1]);
        }
      } catch (err) {
        log.error(err);
      }

      if (offset + length === dv.byteLength) {
        break;
      }

      offset += length;
      length = dv.getUint16(offset, true);
    }

    return result;
  }
}

// Возвращает тапл [id энитити, данные энитит]
function decodeEntity(dv: DataView): [number, object] {
  const entityTypeId = dv.getInt16(2, true);

  const entitySize = dv.getUint16(0, true);

  log.trace(`decoding entity type=${entityTypeId} and size=${entitySize}`);
  const entityType = getEntityNameById(entityTypeId);

  if (!entityType) {
    throw new Error(UNKNOWN_ENTITY_MSG + `type = ${entityTypeId}`);
  }

  const entity = createEntity(entityType);
  const meta = getMeta(entityType);

  if (!meta) {
    throw new Error(UNKNOWN_ENTITY_MSG + `type = ${entityType}`);
  }

  let currentOffset = 4;

  let parseMasked = isEntityMasked(entityTypeId);

  // если маска = 0, это значит что переданы все поля
  if (parseMasked && dv.getUint8(currentOffset) === 0) {
    // байт с 0 надо пропустить
    currentOffset += 1;
    parseMasked = false;
  }

  if (parseMasked) {
    currentOffset = decodeMaskedEntity(dv, currentOffset, meta, entity);
  } else {
    for (const field of Object.keys(meta)) {
      currentOffset += decodeField(
        dv,
        field as EntityType,
        meta[field],
        entity,
        currentOffset
      );
    }
  }

  if (currentOffset !== entitySize) {
    log.warn(
      `Decoded message size is not matched with expected type=${entityTypeId} expected size =${entitySize} actual size=${currentOffset}`
    );
  }

  return [entityTypeId, entity];
}

function decodeMaskedEntity(
  dv: DataView,
  offset: number,
  meta: EntityMeta,
  entity: Entity
): number {
  /**
   Пример как парсить маску из логов сервера
   В данном случае маска это десятичные цифры потому что логер бэка так их пишет
   FinInfoEntity:
   mask=0128007801,
   idFI=144950,
   sumBid=207513,
   numBids=1349,
   exTime=28 сентября 2021 19:33,
   idSession=11593,
   idBoard=92,
   idGate=2

   mask=0128007801, это хекс
   в бинарном виде
   0000 0001 0010 1000 0000 0000 0111 1000 0000 0001

   идем по байтам
   0000 0001 - 0 (idFI)
   0010 1000 - 3,5 (3+8=11, 5+8= 13)  sumBid, numBids
   0000 0000 -
   0111 1000 - 3,4,5,6 (3+24=27, 4+24=28, 29 ,30) exTime,idSession,idBoard,idGate
   0000 0001 - 0 ( 32 ) flags
   *  */

  let currentOffset = offset;

  const bitMaskSize = dv.getUint8(currentOffset);

  currentOffset += 1;

  // нужно найти количество целых байт, в которые помещается маска
  // например: если у энтити 38 полей это будет 38 бит, то чтобы сохранить их все нужно 5 байт (8*5 = 40 и 38 < 40).
  const reminder = bitMaskSize % 8;
  let fullBytes = ~~(bitMaskSize / 8); // это делит число нацело

  if (reminder > 0) {
    fullBytes += 1;
  }

  const maskBuffer = new ArrayBuffer(fullBytes);
  const maskBytes = new Uint8Array(maskBuffer);

  //скопируем маску
  for (let i = 0; i < fullBytes; i++) {
    maskBytes[i] = dv.getUint8(currentOffset + i);
  }

  //переместимся на данные
  currentOffset += fullBytes;

  let counter = 0;
  let fieldsReaded = 0;
  let bitIndex = 0;

  for (const field of Object.keys(meta)) {
    if (fieldsReaded >= bitMaskSize) {
      // если вдруг полей больше чем в маске предполагается, то ничего не делаем
      continue;
    }

    // итак, маска у нас живет в массиве байт maskByte
    // в каждом байте есть 8 бит, то есть 1 и 0 для которых нам надо
    // проверять включено ли текущее поле
    // https://en.wikipedia.org/wiki/Mask_(computing)

    //для начала нам нужно понять в каком байте по-счету мы находимся
    const byteMaskIndex = ~~(counter / 8);

    //проверим нужно ли включать текущее поле
    const hit = getBitsFrom(maskBytes[byteMaskIndex], bitIndex);

    if (hit) {
      currentOffset += decodeField(
        dv,
        field as EntityType,
        meta[field],
        entity,
        currentOffset
      );
    }

    if (bitIndex === 7) {
      bitIndex = 0;
    } else {
      bitIndex += 1;
    }

    counter += 1;
    fieldsReaded += 1;
  }

  return currentOffset;
}

// функция декодит поле, которое находиться на offset позиции
// важно: функция возвращает количество байт, насколько нужно сдвинуть offset к след позиции
function decodeField(
  dv: DataView,
  key: EntityType,
  fieldMeta: FieldMeta,
  entity: Entity,
  offset: number
): number {
  switch (fieldMeta.type) {
    case FieldType.ConditionField: {
      const conditionMeta = fieldMeta as ConditionFieldMeta;
      const meta = conditionMeta.condition(entity);

      return decodeField(dv, key, meta, entity, offset);
    }
    case FieldType.EnumField: {
      const enumMeta = fieldMeta as EnumFieldMeta;
      let enumValue: number | BigInt = 0;

      if (enumMeta.width === 1) {
        enumValue = dv.getInt8(offset);
      } else if (enumMeta.width === 2) {
        enumValue = dv.getInt16(offset, true);
      } else if (enumMeta.width === 4) {
        enumValue = dv.getInt32(offset, true);
      } else if (enumMeta.width === 8) {
        enumValue = dv.getBigInt64(offset, true);
      } else {
        log.error('Unsupported enum width');
      }

      entity[key] = enumValue;

      return enumMeta.width;
    }

    case FieldType.Int16Field: {
      entity[key] = dv.getInt16(offset, true);

      return 2;
    }

    case FieldType.Int32Field: {
      entity[key] = dv.getInt32(offset, true);

      return 4;
    }

    case FieldType.UInt32Field: {
      entity[key] = dv.getUint32(offset, true);

      return 4;
    }

    case FieldType.Int64Field: {
      entity[key] = dv.getBigInt64(offset, true);

      return 8;
    }

    case FieldType.DateTimeFiled: {
      entity[key] = ticksDateToJs(dv.getBigInt64(offset, true));

      return 8;
    }

    case FieldType.UnknownField: {
      const meta = fieldMeta as UnknownFieldMeta;

      return meta.width;
    }

    case FieldType.StringField: {
      const isEmpty = dv.getInt8(offset) === 0;

      // нет признака массива
      if (isEmpty) {
        return 1;
      }

      const size = dv.getUint16(offset + 1, true);

      // пустая строка
      if (size === 0) {
        entity[key] = '';

        return 3;
      }

      const stringBytes = dv.buffer.slice(
        dv.byteOffset + offset + 3,
        dv.byteOffset + offset + size + 3
      );

      try {
        entity[key] = sharedTextDecoder.decode(stringBytes);
      } catch (err) {
        log.error(err);
      }

      return size + 3;
    }

    case FieldType.BooleanField: {
      entity[key] = dv.getUint8(offset) === 1 ? true : false;

      return 1;
    }

    case FieldType.DoubleField: {
      // https://en.wikipedia.org/wiki/Floating-point_error_mitigation
      entity[key] = parseFloat(dv.getFloat64(offset, true).toPrecision(12));

      return 8;
    }

    case FieldType.ByteArrayField: {
      let currentOffset = offset;
      const isArray = dv.getInt8(offset);

      currentOffset += 1;

      if (isArray === 0) {
        return 1;
      }

      const arraySize = dv.getInt16(currentOffset, true);

      currentOffset += 2;

      // важно помнить что оффесты dataView идут от начала DataView
      // а операция оригинальным буффером от начала буффреа
      entity[key] = dv.buffer.slice(
        dv.byteOffset + currentOffset,
        dv.byteOffset + currentOffset + arraySize
      );
      currentOffset += arraySize;

      return currentOffset - offset;
    }

    case FieldType.VariantField: {
      return decodeVariantField(dv, key, fieldMeta, entity, offset);
    }

    case FieldType.ArrayField: {
      let currentOffset = offset;
      const isArray = dv.getInt8(offset);

      currentOffset += 1;

      if (isArray === 0) {
        return 1;
      }

      const arraySize = dv.getInt16(currentOffset, true);

      currentOffset += 2;

      const arrayFieldMeta = fieldMeta as ArrayFieldMeta;

      const meta = getMeta(arrayFieldMeta.elements);

      if (!meta) {
        log.error(UNKNOWN_ENTITY_MSG + `type = ${arrayFieldMeta.elements})`);

        return currentOffset;
      }

      entity[key] = [];

      for (let i = 0; i < arraySize; i++) {
        // заполнение массива энтитей
        const elementEntity = createEntity(arrayFieldMeta.elements);

        for (const field of Object.keys(meta)) {
          currentOffset += decodeField(
            dv,
            field as EntityType,
            meta[field],
            elementEntity,
            currentOffset
          );
        }

        if (!arrayFieldMeta.isPrimitives) {
          entity[key].push(elementEntity);
        } else {
          const unboxValue = Object.keys(elementEntity)[0];

          entity[key].push(elementEntity[unboxValue]);
        }
      }

      // ожидается что функция вернет кол-во байт на сколько нужно передвинуть укзатель
      return currentOffset - offset; // конечная позиция минус начало
    }
  }

  return 0;
}

function decodeVariantField(
  dv: DataView,
  key: EntityType,
  fieldMeta: FieldMeta,
  entity: Entity,
  offset: number
): number {
  // в файле docs/variant.png есть картинка как декодится вариант
  const typeByte = dv.getInt8(offset);

  // идея в том что перевызвать функцию decodeField с поддельной метой что бы она
  // декодировала в правильный тип
  switch (typeByte) {
    // Int32 число
    case 1: {
      return (
        decodeField(
          dv,
          key,
          { type: FieldType.Int32Field },
          entity,
          offset + 1
        ) + 1
      );
    }
    // Uint32 число
    case 5: {
      return (
        decodeField(
          dv,
          key,
          { type: FieldType.UInt32Field },
          entity,
          offset + 1
        ) + 1
      );
    }
    // TypeID код энтити
    case 8: {
      return (
        decodeField(
          dv,
          key,
          { type: FieldType.Int16Field },
          entity,
          offset + 1
        ) + 1
      );
    }
  }

  log.warn('Not implemented variant type');

  return 1;
}

/**
 * Паста отсюда https://dev.to/somedood/bitmasks-a-very-esoteric-and-impractical-way-of-managing-booleans-1hlf
 * Проверяет "truthiness" бита переданной позиции ИНДЕКС СПРАВА, те самый правый это 0
 */

function getBitsFrom(binaryNum: number, position: number) {
  // Bit-shifts according to zero-indexed position
  const mask = 1 << position;
  const query = binaryNum & mask;

  return Boolean(query);
}

/**
 * Сервер формирует любые даты согласно количеству тиков между 01.01.0001 00:00 и Московским временем, 1 тик = 1/10000 мс
 *
 * 1. Вычитаем из пришедшего бигинта EPOCH_TICKS - кол-во тиков между 01.01.0001 00:00 и 01.01.1970 00:00 (unix эпоха)
 * 2. Делим на кол-во тиков в мс /10000 - получаем кол-во ms соответствующее UTC+3 времени в js Date
 * 3. Вычитаем 3 лишних часа (та разница, которую сервер зачем-то закладывает в бигинт)
 */
const ticksDateToJs = (date: BigInt): Date => {
  const tickDate = new Date(
    (Number(date) - EPOCH_TICKS) / TICKS_PER_MILLISECOND
  );
  const msOffset = -3 * 60 * 60 * 1000;

  return new Date(tickDate.getTime() + msOffset);
};

export { decode, ticksDateToJs };
