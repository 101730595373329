import type { OrderItem } from '../../types/order';
import type { SubAccountPositionItem } from '../../types/subAccountPosition';

/**
 * собираем заявки по позициям и разделяем по направлению
 * @param item
 * @param direction
 */
export function makeOrderKey(
  item: OrderItem | SubAccountPositionItem,
  direction: number
): string {
  return `${item.idObject}-${item.idSubAccount}-${item.idRazdel}-${direction}`;
}
