import { useMutation } from 'react-query';

import { trackPriceService } from '../trackPriceService';

import useNotification from '../../../../hooks/useNotification';

import { NotificationType } from '../../../../types/ui';

export const useTrackPriceEdit = () => {
  const addNotification = useNotification();

  return useMutation(trackPriceService.updateAlert.bind(trackPriceService), {
    onError: (error: Error) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка изменения уведомления о цене',
        text: error?.message,
      });
    },
  });
};
