// id базовых активов фьючерса (idObjectBase)
export const featuresList = [
  174435, // индекс РТС
  174412, // Brent Oil
  174438, // USD
  285270, // Sber
  174422, // Gazprom
  174388, // Gold
  174407, // Silver
  299571, // MOEX,
  414285, // SPYF (S&P500)
  550672, // Natural Gas
];
