import { fetchLkResult } from '@terminal/core/lib/rest/lkApi';

import { AccountInfoAI } from '../model/types/AccountInfoAI';
import { AccountInfoParams } from './types/AccountInfoParams';

export async function getAccountInfoAI(
  params: AccountInfoParams
): Promise<AccountInfoAI> {
  const res = await fetchLkResult<AccountInfoAI>(
    `/api/client/accounts/info/${params.treaty}`
  );

  return res;
}
