import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { fetchLkFile } from '@terminal/core/lib/rest/lkApi';
import { makeGetParams } from '@terminal/core/lib/url';
import { NotificationType } from '@terminal/core/types/ui';

import { LK_API } from '../../const/externalUrls';
import { LkQueryKeys } from '../../queryKeys';

export type SecuritiesHistoryFileType = 'pdf' | 'xml';

interface DetailHistoryFileParams {
  type?: SecuritiesHistoryFileType;
  treaty?: number;
  trnNo?: number;
  trnSrc?: number;
  aDate?: string;
  deponet?: boolean;
}

export const useSecuritiesHistoryDetailFile = (
  params: DetailHistoryFileParams,
  enabled: boolean
) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.SecuritiesHistoryDetailFile, params],
    async () => {
      const { type, ...requestParams } = params;
      const baseUrl = LK_API.SECURITIES_HISTORY_DETAIL_FILE_BASE;

      const request = makeGetParams(requestParams);

      return await fetchLkFile(`${baseUrl}/${type}?${request}`);
    },
    {
      enabled:
        enabled &&
        Boolean(
          params.trnNo &&
            params.aDate &&
            params.treaty &&
            params.deponet !== undefined
        ),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          title: 'Ошибка запроса файла истории',
          text: error?.message,
        });
      },
    }
  );
};
