import cn from 'classnames';

import {
  SecuritiesDocumentType,
  SecuritiesReasonDocument,
} from '@terminal/core/lib/rest/lkSecurities';

import { DocumentItem } from './DocumentItem';

import styles from './DocumentsList.module.css';

interface DocumentsListProps {
  documents: SecuritiesReasonDocument[];
  documentTypes?: SecuritiesDocumentType[];
  onRedact: (index: number) => void;
  onDelete: (index: number) => void;
  maxDocumentsCount?: number;
  className?: string;
}

export const DocumentsList = ({
  documents,
  documentTypes,
  onRedact,
  onDelete,
  maxDocumentsCount,
  className,
}: DocumentsListProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.head}>
        <div className={cn(styles.cell, styles.cellName)}>
          Документы основания операции
        </div>
        {documents.length > 0 && (
          <div className={cn(styles.cell, styles.cellAction)}>Действия</div>
        )}
      </div>
      {documents.map((doc, index) => (
        <DocumentItem
          key={`document-${index}`}
          document={doc}
          documentType={documentTypes?.find((d) => d.id === doc.id)}
          onRedact={() => onRedact(index)}
          onDelete={() => onDelete(index)}
        />
      ))}
      <div className={styles.footer}>
        <div className={cn(styles.cell, styles.cellFooter)}>
          Документов в списке: {documents.length}{' '}
          {maxDocumentsCount && '(максимум 3)'}
        </div>
      </div>
    </div>
  );
};
