import { v4 } from 'uuid';

import { getDefaultWatchListColumnSetting } from '@terminal/core/constants/tableProps';
import { makeDoubleColumns } from '@terminal/core/hooks/useDoubleRowMode';
import {
  LinkName,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';
import { WatchlistIds } from '@terminal/core/types/watchList';

export const getWatchListConfig = () => {
  const defaultColumns = getDefaultWatchListColumnSetting('name');
  const columnsForBeginner: TableColumnSetting[] = defaultColumns.map(
    (column) => {
      // Для новичков убираем колонку "Рынок"
      if (column.key === TableColumnKey.CodeMarketBoard) {
        return {
          ...column,
          selected: false,
        };
      }

      return column;
    }
  );
  const marketColumnIndex = columnsForBeginner.findIndex(
    (column) => column.key === TableColumnKey.CodeMarketBoard
  );
  const doubleRowColumns = makeDoubleColumns(
    columnsForBeginner,
    Widget.WATCHLIST
  );

  // Добавляем выключенную колонку "Рынок" в конец, иначе она подтянется из дефолтного конфига
  if (marketColumnIndex !== -1) {
    doubleRowColumns.push({
      ...columnsForBeginner[marketColumnIndex],
      id: v4(),
    });
  }

  return {
    link: LinkName.BLUE,
    selectedListId: WatchlistIds.iMOEXList,
    tableProps: {
      columns: doubleRowColumns,
    },
  };
};

export const TRADE_CHARTIQ = {
  customSettings: {
    panels: { chart: { yAxis: { zoom: 50.00000000000001 } } },
    showOHLC: true,
    isTradingActive: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 5.7592592592592595,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: false,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 1,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisRHS: ['chart'],
      },
    },
    setSpan: null,
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'SBER',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: null,
      },
    ],
    range: null,
    studies: {},
  },
};
