import cn from 'classnames';
import { useCallback, useState } from 'react';
import { Loader } from '@alfalab/core-components/loader';

import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { CurrenciesRequisites } from '@terminal/core/lib/rest/lkMoney';
import { useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';

import { RequisitesValue } from '../../../accountsInfo/MainInfo/AccountRequisites/RequisitesValue';
import { QrModal } from '../../../components/QrModal/QrModal';
import {
  REQUISITES_FIELDS_SCHEME,
  REQUISITES_FILED_DESCRIPTION,
  REQUISITES_FILED_TOOLTIP,
  REQUISITES_IGNORE_FIELDS,
} from './helpers';
import { MoneyRequisitesActions } from './MoneyRequisitesActions';
import { MoneyRequisitesFooter } from './MoneyRequisitesFooter';

import { useBrokerRequisitesFile } from '../../../hooks';
import { useSendBrokerRequisites } from '../../hooks';

import styles from './RequisitesDetail.module.css';

interface RequisitesDetailProps {
  treaty: Treaty | null;
  requisites: CurrenciesRequisites;
  email?: string;
  emailConfirmed?: boolean;
}

export const RequisitesDetail = ({
  treaty,
  requisites,
  email = '',
  emailConfirmed = false,
}: RequisitesDetailProps) => {
  const [qrOpen, setQrOpen] = useState<boolean>(false);
  const [qrFileUrl, setQrFileUrl] = useState('');
  const [pdfFile, setPdfFile] = useState<DownloadedFile | null>(null);

  const setHeaderMenuOpen = useStore((state) => state.setHeaderMenuOpen);

  const { mutateAsync: sendBrokerRequisites, isLoading: sending } =
    useSendBrokerRequisites();
  const { isLoading: qrLoading, mutateAsync: loadQrFile } =
    useBrokerRequisitesFile({ isGoInvest: true });
  const { isLoading: pdfLoading, mutateAsync: loadPdfFile } =
    useBrokerRequisitesFile({ isGoInvest: true });
  const sendRequisites = async () => {
    if (treaty && email) {
      sendBrokerRequisites({
        treaty: treaty.treaty.toString(),
        placeCode: 'MICEX_SHR',
        currency: requisites.currency,
        email: email,
        requisiteId: requisites.id,
      });
    }
  };

  const openQr = async () => {
    if (treaty?.treaty) {
      setQrOpen(true);
      const qr = await loadQrFile({
        type: 'qr',
        treaty: treaty.treaty,
        currency: requisites.currency,
        requisiteId: requisites.id,
      });
      setQrFileUrl(qr.url);
    }
  };

  const closeQr = () => {
    setQrOpen(false);
  };

  const loadPdf = async () => {
    if (treaty?.treaty) {
      const pdf = await loadPdfFile({
        type: 'pdf',
        treaty: treaty.treaty,
        currency: requisites.currency,
        requisiteId: requisites.id,
      });
      setPdfFile(pdf);
    }
  };

  const onChangeEmail = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.PROFILE_SETTINS);
  }, [setHeaderMenuOpen]);

  const fieldsMap =
    REQUISITES_FIELDS_SCHEME[requisites.currency] ||
    Object.keys(requisites).filter(
      (key) =>
        REQUISITES_IGNORE_FIELDS.indexOf(key as keyof CurrenciesRequisites) < 0
    );

  return (
    <div>
      <div className={styles.data}>
        {fieldsMap.map((key, index) => {
          if (key === 'divider') {
            // Разделитель
            return (
              <div key={`divider-${index}`} className={styles.divider}></div>
            );
          }

          const feildKey = key as keyof CurrenciesRequisites;
          if (
            !requisites[feildKey] ||
            !REQUISITES_FILED_DESCRIPTION[feildKey]
          ) {
            return null;
          }

          return (
            <RequisitesValue
              key={`field-${key}`}
              className={cn(styles.field, {
                [styles.fieldFull]: key === 'paymentPurpose',
              })}
              title={REQUISITES_FILED_DESCRIPTION[feildKey]}
              tooltip={
                requisites.currency !== 'RUB' && requisites.currency !== 'RUR'
                  ? REQUISITES_FILED_TOOLTIP[feildKey]
                  : ''
              }
              value={requisites[feildKey].toString()}
            />
          );
        })}
      </div>

      <MoneyRequisitesActions
        onQrOpen={openQr}
        treaty={treaty}
        onPdfDownload={loadPdf}
        pdfLoading={pdfLoading}
        pdfFile={pdfFile}
      />

      <MoneyRequisitesFooter
        email={email}
        emailConfirmed={emailConfirmed}
        sending={sending}
        sendRequisites={sendRequisites}
        onChangeEmail={onChangeEmail}
      />

      <QrModal open={qrOpen} onClose={closeQr}>
        {qrLoading && <Loader />}
        {!!qrOpen && !qrLoading && qrFileUrl && (
          <img src={qrFileUrl} alt="QR реквизиты" />
        )}
      </QrModal>
    </div>
  );
};
