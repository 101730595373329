import produce, { Draft } from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import create, { GetState, StateCreator } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

import { USE_DEV_TOOLS_FOR_STORE } from '@terminal/core/env';

import {
  createChatSlice,
  createPersonalBrokerSlice,
  createSupportSlice,
} from './slices';

import { ChatSlice, PersonalBrokerSlice, SupportSlice } from '../types';

export type ChatStore = ChatSlice & PersonalBrokerSlice & SupportSlice;

const immer =
  <T extends ChatStore>(
    config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>
  ): StateCreator<T> =>
  (set, get, api) =>
    config((fn) => set(produce<T>(fn)), get, api);

export type StoreSet = (fn: (draft: WritableDraft<ChatStore>) => void) => void;

const store = (set: StoreSet, get: GetState<ChatStore>) => ({
  ...createChatSlice(set, get),
  ...createPersonalBrokerSlice(set, get),
  ...createSupportSlice(set, get),
});

export const useStore = create(
  subscribeWithSelector(
    USE_DEV_TOOLS_FOR_STORE ? devtools(immer(store)) : immer(store)
  )
);
