import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import {
  AddAccountDocument,
  AddAccountDocumentType,
} from './types/AddAccountDocument';

export const getAddAccountDocument = async (
  accType: AddAccountDocumentType
) => {
  const res = await fetchLkResult<AddAccountDocument | LKResult>(
    `/api/client/accounts/registration-document`,
    'POST',
    accType ? { accType } : undefined
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};
