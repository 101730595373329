import cn from 'classnames';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { OptionShape } from '@alfalab/core-components/select/typings';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { useAlfaDirectContext, useFullFI } from '@terminal/alfadirect/hooks';
import { Dropdown } from '@terminal/common/components/Dropdown';
import { Stepper } from '@terminal/common/components/Stepper';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import {
  OrderStopPriceDirection,
  PriceControlType,
} from '@terminal/core/lib/client/entities';
import { getStepDecimals, roundPrice } from '@terminal/core/lib/format';
import { Widget } from '@terminal/core/types/layout';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { useWidgetContext } from '../../../../shared';
import { FinInstrAutocomplete } from '../../../FinInstrAutocomplete';

import styles from './Condition.module.css';

const priceTypeOptions: OptionShape[] = [
  {
    value: PriceControlType.LAST,
    key: String(PriceControlType.LAST),
    content: 'LAST',
  },
  {
    value: PriceControlType.BID,
    key: String(PriceControlType.BID),
    content: 'BID',
  },
  {
    value: PriceControlType.ASK,
    key: String(PriceControlType.ASK),
    content: 'ASK',
  },
];

type PriceDirectionOption = OptionShape & { text: string };

const priceDirectionOptions: PriceDirectionOption[] = [
  {
    value: OrderStopPriceDirection.Lower,
    key: String(OrderStopPriceDirection.Lower),
    content: '≤',
    text: 'Меньше или равно',
  },
  {
    value: OrderStopPriceDirection.Upper,
    key: String(OrderStopPriceDirection.Upper),
    content: '≥',
    text: 'Больше или равно',
  },
];

interface Props {
  controlledIdFi: number;
  setControlledIdFi: (fi: number) => void;
  stopPrice: number;
  setStopPrice: (price: number) => void;
  idPriceControlType: PriceControlType;
  setIdPriceControlType: (id: PriceControlType) => void;
  priceDirection: OrderStopPriceDirection;
  setPriceDirection: (direction: OrderStopPriceDirection) => void;
  setHasError: (boolean) => void;
  currencyCode?: CurrencyCodes;
}

export const Condition = ({
  controlledIdFi,
  setControlledIdFi,
  stopPrice,
  setStopPrice,
  idPriceControlType,
  setIdPriceControlType,
  priceDirection,
  setPriceDirection,
  currencyCode,
  setHasError,
}: Props) => {
  const [priceTouched, setPriceTouched] = useState(false);

  const { useFinInfoExt } = useAlfaDirectContext();
  const { DropdownComponent } = useWidgetContext();

  const priceControlName = useMemo(
    () =>
      priceTypeOptions.find((opt) => opt.key === String(idPriceControlType))
        ?.content,
    [idPriceControlType]
  );
  const priceDirectionName = useMemo(
    () =>
      priceDirectionOptions.find((opt) => opt.key === String(priceDirection))
        ?.content,
    [priceDirection]
  );

  const priceControlDropdown = useRef<HTMLDivElement>(null);

  useOnClickOutside(priceControlDropdown, () => setIsOpenPriceControl(false));
  const [isOpenPriceControl, setIsOpenPriceControl] = useState(false);

  const priceDirectionDropdown = useRef<HTMLDivElement>(null);

  useOnClickOutside(priceDirectionDropdown, () =>
    setIsOpenPriceDirection(false)
  );
  const [isOpenPriceDirection, setIsOpenPriceDirection] = useState(false);

  useEffect(() => {
    setStopPrice(0);
    setPriceTouched(false);
  }, [setStopPrice, controlledIdFi]);

  const finInfoExtRecord = useFinInfoExt(controlledIdFi);
  const finInfoExt = finInfoExtRecord[controlledIdFi];

  const { decimalsNumber, priceDecimals } = getStepDecimals(
    finInfoExt?.priceStep
  );

  useEffect(() => {
    const isChanged = !isUndefined(finInfoExt) && priceTouched;

    if (!isChanged) {
      setHasError(false);
    } else {
      setHasError(stopPrice !== roundPrice(stopPrice, finInfoExt?.priceStep));
    }
  }, [finInfoExt, priceTouched, stopPrice, setHasError]);

  const getPriceError = (
    priceToCheck: number,
    priceTouchedToCheck: boolean
  ) => {
    const toInteger = Math.pow(10, decimalsNumber);

    if (!isUndefined(finInfoExt) && priceTouchedToCheck) {
      //Проверяем кратность шагу цены
      if (
        //С дробными числами траблы в js, поэтому домножаем до целого
        Math.round(priceToCheck * toInteger) %
          Math.round(finInfoExt?.priceStep * toInteger) !==
        0
      ) {
        return <>Не кратно шагу</>;
      }
    }

    return undefined;
  };

  return (
    <div>
      <div className={styles.textWithTooltip}>
        <Typography.Text view="secondary-large" weight="bold">
          Контрольный инструмент
        </Typography.Text>

        <TooltipDesktop
          contentClassName={styles.tooltipContent}
          content="Заявка будет отправлена, если контрольный инструмент достигнет указанного ценового значения"
          trigger="hover"
          position="top-start"
          offset={[-20, 16]}
        >
          <InformationCircleLineSIcon
            height={12}
            width={12}
            color="var(--color-dark-graphic-secondary)"
          />
        </TooltipDesktop>
      </div>
      <FinInstrAutocomplete
        idFi={controlledIdFi}
        widget={Widget.HYBRIDORDER}
        onChange={(fi) => setControlledIdFi(fi.idFI)}
      />
      <Typography.Text
        view="secondary-large"
        weight="bold"
        className={styles.secondaryTitle}
      >
        Условие
      </Typography.Text>

      <div className={styles.formRow}>
        <div className={styles.form}>
          <Typography.Text
            view="secondary-small"
            className={styles.customInputLabel}
          >
            Цена
          </Typography.Text>
          <div
            ref={priceControlDropdown}
            className={cn(
              styles.dropdownRef,
              styles.additionalInstructionSelectDesktop
            )}
          >
            <div
              className={styles.dropDownContent}
              onClick={() => setIsOpenPriceControl((prev) => !prev)}
            >
              <Typography.Text
                view="secondary-large"
                className={styles.orderName}
              >
                {priceControlName}
              </Typography.Text>
              <ChevronDownMIcon
                height={18}
                width={18}
                color="currentColor"
                className={cn(
                  styles.chevron,
                  isOpenPriceControl && styles.chevronUp
                )}
              />
            </div>
            <DropdownComponent
              useAnchorWidth
              anchorElement={priceControlDropdown.current}
              open={isOpenPriceControl}
              offset={[0, 4]}
              position="top-start"
              headerDivider={false}
            >
              {priceTypeOptions.map((opt) => {
                const isSelected = opt.key === String(idPriceControlType);

                return (
                  <Dropdown.Item
                    key={opt.key}
                    content={
                      <div className={styles.dropDownItemContent}>
                        {opt.content}
                      </div>
                    }
                    onClick={() =>
                      setIdPriceControlType(opt.value as PriceControlType)
                    }
                    rightIcon={
                      isSelected ? (
                        <CheckmarkMIcon height={18} width={18} />
                      ) : undefined
                    }
                  />
                );
              })}
            </DropdownComponent>
          </div>
        </div>
        <div className={styles.form}>
          <Typography.Text
            view="secondary-small"
            className={styles.customInputLabel}
          >
            Условие
          </Typography.Text>
          <div
            ref={priceDirectionDropdown}
            className={cn(
              styles.dropdownRef,
              styles.additionalInstructionSelectDesktop
            )}
          >
            <div
              className={styles.dropDownContent}
              onClick={() => setIsOpenPriceDirection((prev) => !prev)}
            >
              <Typography.Text
                view="secondary-large"
                className={styles.orderName}
              >
                {priceDirectionName}
              </Typography.Text>
              <ChevronDownMIcon
                height={18}
                width={18}
                color="currentColor"
                className={cn(
                  styles.chevron,
                  isOpenPriceDirection && styles.chevronUp
                )}
              />
            </div>
            <DropdownComponent
              useAnchorWidth
              anchorElement={priceDirectionDropdown.current}
              open={isOpenPriceDirection}
              offset={[0, 4]}
              position="top-start"
              headerDivider={false}
            >
              {priceDirectionOptions.map((opt) => {
                const isSelected = opt.key === String(priceDirection);

                return (
                  <Dropdown.Item
                    key={opt.key}
                    content={
                      <div className={styles.dropDownItemContent}>
                        {opt.content} {opt.text}
                      </div>
                    }
                    onClick={() =>
                      setPriceDirection(opt?.value as OrderStopPriceDirection)
                    }
                    rightIcon={
                      isSelected ? (
                        <CheckmarkMIcon height={18} width={18} />
                      ) : undefined
                    }
                  />
                );
              })}
            </DropdownComponent>
          </div>
        </div>
      </div>

      <Typography.Text
        view="secondary-small"
        className={styles.customInputLabel}
      >
        Значение
      </Typography.Text>
      <AmountInput
        className={styles.customInput}
        fieldClassName={styles.customInputField}
        error={getPriceError(stopPrice, priceTouched)}
        block
        suffix={currencyCode || ''}
        currency={currencyCode}
        value={stopPrice * priceDecimals}
        minority={priceDecimals}
        disabled={!currencyCode}
        onBlur={() => !priceTouched && setPriceTouched(true)}
        onChange={(e, { value }) =>
          setStopPrice(
            isNull(value)
              ? 0
              : Number((value / priceDecimals).toFixed(decimalsNumber))
          )
        }
        rightAddons={
          <div className={styles.priceAddons}>
            <Stepper
              onAdd={() => {
                if (finInfoExt) {
                  setStopPrice(
                    Number(
                      (stopPrice + finInfoExt.priceStep).toFixed(decimalsNumber)
                    )
                  );

                  if (!priceTouched) {
                    setPriceTouched(true);
                  }
                }
              }}
              onSub={() => {
                if (finInfoExt) {
                  setStopPrice(
                    stopPrice > finInfoExt.priceStep
                      ? stopPrice - finInfoExt.priceStep
                      : 0
                  );

                  if (!priceTouched) {
                    setPriceTouched(true);
                  }
                }
              }}
            />
          </div>
        }
      />
    </div>
  );
};

interface ConditionButtonContentProps {
  controlledIdFi: number;
  stopPrice: number;
  idPriceControlType: PriceControlType;
  priceDirection: OrderStopPriceDirection;
}

export const ConditionButtonContent = React.memo(
  ({
    priceDirection,
    controlledIdFi,
    stopPrice,
    idPriceControlType,
  }: ConditionButtonContentProps) => {
    const { useFinInfoExt } = useAlfaDirectContext();
    const finInfoExtRecord = useFinInfoExt([controlledIdFi]);

    const finInfoExt: FinInfoExt | undefined = useMemo(
      () => finInfoExtRecord[controlledIdFi],
      [finInfoExtRecord, controlledIdFi]
    );

    const { priceDecimals } = getStepDecimals(finInfoExt?.priceStep);

    const fullFi = useFullFI(controlledIdFi);

    const priceTypeValue = priceTypeOptions.find(
      ({ value }) => value === idPriceControlType
    );
    const priceDirectionValue = priceDirectionOptions.find(
      ({ value }) => value === priceDirection
    );

    return (
      <div className={styles.additionalInstructionSearchValue}>
        {fullFi?.symbolObject}
        &nbsp;
        {fullFi?.nameMarketBoard}
        &nbsp;
        {priceTypeValue?.content}
        &nbsp;
        {priceDirectionValue?.content}
        &nbsp;
        <Amount.Pure
          value={stopPrice * priceDecimals}
          currency={fullFi?.currencyCode}
          minority={priceDecimals}
        />
      </div>
    );
  }
);
