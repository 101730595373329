import React, { useCallback, useEffect, useState } from 'react';
import { ButtonMobile } from '@alfalab/core-components/button/mobile';
import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ReplacementMIcon } from '@alfalab/icons-glyph/ReplacementMIcon';
import { SquareAcademicCapMIcon } from '@alfalab/icons-glyph/SquareAcademicCapMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import {
  RiskProfile,
  UpdateRiskProfileResult,
} from '@terminal/core/lib/rest/lkRiskProfile';
import { useStore } from '@terminal/core/store';

import { RiskProfileDetails } from './RiskProfileDetails';
import { RiskProfileTest } from './RiskProfileTest';

import {
  useClientProfile,
  useConfirmProfile,
  useGetProfileAgreement,
  useUpdateProfile,
} from './hooks';

import styles from './RiskProfile.module.css';

enum RiskProfileStatus {
  EMPTY = 'empty',
  PASSED = 'passed',
  ACCEPTED = 'accepted',
}

const riskProfileTitles = [
  'Консервативный',
  'Умеренно-консервативный',
  'Сбалансированный',
  'Умеренно-агрессивный',
  'Агрессивный',
];

interface EntryPointProps {
  openTestModal?: () => void;
  openDetails?: () => void;
}

interface EntryPointPropsWithProfile extends EntryPointProps {
  riskProfile: number;
}

const EmptyEntryPoint = ({ openTestModal }: EntryPointProps) => {
  return (
    <div className={styles.entryPointContent}>
      <ButtonMobile
        size="s"
        view="outlined"
        className={styles.entryPointButton}
        onClick={openTestModal}
        leftAddons={
          <SquareAcademicCapMIcon width={15} height={15} fill="#C5C5C7" />
        }
      >
        Пройти тестирование
      </ButtonMobile>
      <Typography.Text
        className={styles.entryPointEmptySubtitle}
        view="secondary-small"
        weight="medium"
        color="secondary"
      >
        Пройдите тест, который определит ваш тип инвестора, и какие активы стоит
        рассмотреть для вложения денег
      </Typography.Text>
    </div>
  );
};

const PassedEntryPoint = ({
  riskProfile,
  openTestModal,
  openDetails,
  confirmRiskProfile,
  isConfirming,
}: EntryPointPropsWithProfile & {
  isConfirming: boolean;
  confirmRiskProfile: () => void;
}) => {
  return (
    <div className={styles.entryPointContent}>
      <Typography.Text
        view="secondary-large"
        color="primary"
        tag="p"
        defaultMargins={false}
      >
        {riskProfileTitles[riskProfile - 1]}.{' '}
        <Link
          onClick={(e) => {
            e.preventDefault();

            if (openDetails) {
              openDetails();
            }
          }}
          view="primary"
        >
          Подробнее
        </Link>
      </Typography.Text>
      <div className={styles.entryPointButtonsBlock}>
        <ButtonMobile
          leftAddons={<CheckmarkMIcon width={15} height={15} fill="#C5C5C7" />}
          size="xxs"
          view="secondary"
          className={styles.entryPointButton}
          loading={isConfirming}
          onClick={() => confirmRiskProfile()}
        >
          Подтвердить
        </ButtonMobile>
        <ButtonMobile
          onClick={openTestModal}
          leftAddons={
            <ReplacementMIcon width={15} height={15} fill="#C5C5C7" />
          }
          size="xxs"
          view="outlined"
          className={styles.entryPointButton}
        >
          Пройти тест заново
        </ButtonMobile>
      </div>
    </div>
  );
};

const AcceptedEntryPoint = ({
  riskProfile,
  openDetails,
}: EntryPointPropsWithProfile) => {
  return (
    <Typography.Text
      view="secondary-large"
      color="primary"
      tag="p"
      defaultMargins={false}
    >
      {riskProfileTitles[riskProfile - 1]}.{' '}
      <Link
        onClick={(e) => {
          e.preventDefault();

          if (openDetails) {
            openDetails();
          }
        }}
        view="primary"
      >
        Подробнее
      </Link>
    </Typography.Text>
  );
};

const ProfileDocument = ({
  isDocumentOpen,
  isUnsigned,
}: {
  isDocumentOpen: boolean;
  isUnsigned: boolean;
}) => {
  const { data, isFetching } = useGetProfileAgreement(
    isDocumentOpen,
    isUnsigned
  );

  if (isFetching) {
    return <Loader />;
  }

  return data ? (
    <div
      className={styles.innerDocumentHtml}
      dangerouslySetInnerHTML={{ __html: data.agreement }}
    />
  ) : null;
};

export const RiskProfileEntryPoint = ({ treaty }: { treaty: number }) => {
  const [riskProfileStatus, setRiskProfileStatus] =
    useState<RiskProfileStatus>();
  const [isTestOpen, setIsTestOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isDocumentOpen, setIsDocumentOpen] = useState(false);
  const [isUnsigned, setIsUnsigned] = useState(false);
  const [isProfileUpdated, setIsPorfileUpdated] = useState(false);

  const [answers, setAnswers] = useState<Record<string, Array<string>>>({});
  const [actualProfile, setActualProfile] = useState<
    (UpdateRiskProfileResult & { accepted: boolean }) | RiskProfile | undefined
  >();

  const lkSignDialogOpen = useStore((state) => state.lkSignDialogOpen);

  const { data, isFetching: isLoading, refetch } = useClientProfile(true);
  const {
    isLoading: isConfirming,
    mutateAsync,
    isSuccess,
  } = useConfirmProfile();

  const {
    data: updateData,
    isLoading: isUpdating,
    mutate: updateProfile,
  } = useUpdateProfile({
    formVersion: data?.profile.formVersion || data?.anket.id,
    answers,
  });

  useEffect(() => {
    if (data) {
      setActualProfile(
        data.unsignedProfile?.riskProfile
          ? { ...data.unsignedProfile, accepted: false }
          : data.profile
      );
      setIsUnsigned(Boolean(data.unsignedProfile?.riskProfile));
    }
  }, [data]);

  useEffect(() => {
    if (updateData) {
      setIsPorfileUpdated(true);
    }
  }, [updateData]);

  const refetchProfile = useCallback(() => refetch(), [refetch]);
  const confirmRiskProfile = useCallback(() => {
    void mutateAsync();
  }, [mutateAsync]);

  useEffect(() => {
    if (data?.profile.accepted && !data?.unsignedProfile?.riskProfile) {
      setRiskProfileStatus(RiskProfileStatus.ACCEPTED);
    } else if (data?.unsignedProfile?.riskProfile !== 0) {
      setRiskProfileStatus(RiskProfileStatus.PASSED);
    } else {
      setRiskProfileStatus(RiskProfileStatus.EMPTY);
    }
  }, [
    actualProfile,
    data?.profile.accepted,
    data?.unsignedProfile?.riskProfile,
  ]);

  useEffect(() => {
    if (isSuccess && !lkSignDialogOpen) {
      void refetchProfile();
    }
  }, [isSuccess, lkSignDialogOpen, refetchProfile]);

  const openTestModal = useCallback(() => {
    setAnswers({});
    setIsDetailsOpen(false);
    setIsDocumentOpen(false);
    setIsTestOpen(true);
    setIsPorfileUpdated(false);
  }, []);
  const openDetails = useCallback(() => {
    setIsDetailsOpen(true);
  }, []);
  const openDocument = useCallback(() => {
    setIsDocumentOpen(true);
  }, []);

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    }

    switch (riskProfileStatus) {
      case RiskProfileStatus.EMPTY:
        return <EmptyEntryPoint openTestModal={openTestModal} />;
      case RiskProfileStatus.PASSED:
        return actualProfile && actualProfile.riskProfile ? (
          <PassedEntryPoint
            openDetails={openDetails}
            openTestModal={openTestModal}
            riskProfile={actualProfile.riskProfile as number}
            confirmRiskProfile={confirmRiskProfile}
            isConfirming={isConfirming}
          />
        ) : null;
      case RiskProfileStatus.ACCEPTED:
        return actualProfile && actualProfile.riskProfile ? (
          <AcceptedEntryPoint
            openDetails={openDetails}
            riskProfile={actualProfile?.riskProfile as number}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.entryPointContainer}>
        <Typography.Text view="primary-small" weight="bold" tag="p">
          Риск-профиль
        </Typography.Text>
        <div>{renderContent()}</div>
      </div>
      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={isTestOpen}
          backLabel="Основная информация"
          onClose={() => setIsTestOpen(false)}
        >
          {data && isTestOpen && (
            <RiskProfileTest
              openDocument={openDocument}
              refetchProfile={refetchProfile}
              riskProfile={data}
              data={updateData}
              isUpdating={isUpdating}
              updateProfile={() => updateProfile()}
              answers={answers}
              setAnswers={setAnswers}
              treaty={treaty}
              isProfileUpdated={isProfileUpdated}
              openDetails={openDetails}
            />
          )}
        </FunctionalModalSidebar>
        <FunctionalModalSidebar
          open={isDetailsOpen}
          backLabel="Основная информация"
          onClose={() => {
            setIsTestOpen(false);
            setIsDetailsOpen(false);
          }}
        >
          {actualProfile && (
            <RiskProfileDetails
              openDocument={openDocument}
              openTestModal={openTestModal}
              profile={actualProfile}
              treaty={treaty}
              refetchProfile={refetchProfile}
              isLoading={isLoading || isUpdating}
            />
          )}
        </FunctionalModalSidebar>
        <FunctionalModalSidebar
          open={isDocumentOpen}
          backLabel="Риск профиль"
          onClose={() => setIsDocumentOpen(false)}
        >
          <ProfileDocument
            isDocumentOpen={isDocumentOpen}
            isUnsigned={isUnsigned}
          />
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
