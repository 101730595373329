import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getSecuritiesUserTypeList } from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesUserTypeList = () => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.SecuritiesUserTypeList],
    getSecuritiesUserTypeList,
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message ||
            'Произошла ошибка при запросе типов пользователей',
        });
      },
    }
  );
};
