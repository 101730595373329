import { format, startOfMonth } from 'date-fns';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';
import { DocumentExcelMIcon } from '@alfalab/icons-glyph/DocumentExcelMIcon';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';

import {
  dateToRequestFormat,
  DownloadedFile,
} from '@terminal/core/lib/rest/lkApi';
import {
  MoneyHistoryFilter,
  MoneyHistoryRequest,
} from '@terminal/core/lib/rest/lkMoney';
import { makeGetParams } from '@terminal/core/lib/url';

import {
  getAllBrokerAccountsTo,
  useClientAccounts,
  useMoneyHistory,
  useMoneyHistoryFile,
  useMoneyHistoryFilterSettings,
} from '../../../../entities/TransferGoinvest';
import { Result } from '../../../../shared/ui/Result';
import { LK_API } from '../../../const/externalUrls';
import { HistoryFilter, HistoryFilterLine } from '../../HistoryFilter';
import { HistoryList } from '../../HistoryList';
import { HistoryTotal } from '../../HistoryTotal';
import { ScreenResponsiveContainer } from '../../ScreenResponsiveContainer';
import { SelectAccount } from '../../SelectAccount';

import styles from './MoneyHistory.module.css';

const PAGE_SIZE = 20;

export const defaultHistoryFilter: MoneyHistoryFilter = {
  startDate: format(startOfMonth(new Date()), 'dd.MM.yyyy'),
  endDate: format(new Date(), 'dd.MM.yyyy'),
  subAccount: '',
  searchString: '',
  tranTypes: [],
  incomes: [],
  currencies: [],
};

export const MoneyHistory = React.memo(() => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] =
    useState<MoneyHistoryFilter>(defaultHistoryFilter);
  const [openFilter, setOpenFilter] = useState(false);
  const [historyFile, setHistoryFile] = useState<DownloadedFile | null>(null);

  const {
    data: clientAccounts,
    isFetching: clientAccountsPending,
    isFetchingNextPage: clientAccountsPendingNext,
    hasNextPage: hasMoreClientAccounts,
    fetchNextPage: fetchMoreClienAccounts,
  } = useClientAccounts(false);

  const { data: settings, isFetching: settingsPending } =
    useMoneyHistoryFilterSettings(filter.treaty);

  const historyParams = useMemo<MoneyHistoryRequest>(() => {
    return {
      ...filter,
      startDate: dateToRequestFormat(filter.startDate),
      endDate: dateToRequestFormat(filter.endDate),
      page,
      count: PAGE_SIZE,
    };
  }, [filter, page]);

  const [debouncedHistoryParams] = useDebounce<MoneyHistoryRequest>(
    historyParams,
    250
  );

  const {
    data: history,
    isFetching: historyPending,
    isFetchingNextPage: historyPendingNext,
    hasNextPage: hasMoreHistory,
    fetchNextPage: fetchMoreHistory,
    remove: clearHistory,
  } = useMoneyHistory(debouncedHistoryParams);

  const { isLoading: historyFileLoading, mutateAsync: downloadHistoryFile } =
    useMoneyHistoryFile();

  const historyItems = useMemo(() => {
    if (history) {
      return history.pages.flatMap((page) => page.transfers);
    }

    return null;
  }, [history]);

  useEffect(() => {
    if (clientAccounts && !filter.subAccount) {
      const allAccountsTo = getAllBrokerAccountsTo(clientAccounts);

      if (allAccountsTo.length) {
        const [treaty] = allAccountsTo[0].subAccount.split('-');

        setFilter((prevFilter) => ({
          ...prevFilter,
          treaty: parseInt(treaty),
          subAccount: allAccountsTo[0].subAccount,
        }));
      }
    }
  }, [clientAccounts, filter]);

  const [loaderRef, loaderInView] = useInView();

  useEffect(() => {
    if (loaderInView) {
      fetchMoreHistory();
    }
  }, [loaderInView, fetchMoreHistory]);

  // При изменении фильтра устанавливаем 1-ю страницу
  useEffect(() => {
    setPage(1);
    clearHistory();
  }, [filter, clearHistory]);

  const onSelectAccount = (subAccount: string) => {
    const [treaty] = subAccount.split('-');

    setFilter({
      ...filter,
      treaty: parseInt(treaty),
      subAccount,
    });
  };

  const getXlsLink = (): string => {
    const params: MoneyHistoryRequest = {
      ...filter,
      startDate: dateToRequestFormat(filter.startDate),
      endDate: dateToRequestFormat(filter.endDate),
    };
    const request = makeGetParams(params, true);

    return `${LK_API.MONEY_HISTORY_ECXEL}?${request}`;
  };

  const loadHistory = async () => {
    const file = await downloadHistoryFile(getXlsLink());

    setHistoryFile(file);
  };

  const isFilterActive = useMemo<boolean>(() => {
    return !isEqual(
      omit(filter, 'treaty', 'subAccount'),
      omit(defaultHistoryFilter, 'subAccount')
    );
  }, [filter]);

  const tradeAccounts = useMemo(() => {
    if (!clientAccounts) {
      return [];
    }

    return getAllBrokerAccountsTo(clientAccounts);
  }, [clientAccounts]);

  const setNextTreaty = useCallback(() => {
    fetchMoreClienAccounts();
  }, [fetchMoreClienAccounts]);

  const renderHistoryResult = () => {
    if (historyItems && historyItems.length === 0) {
      return (
        <Result
          title="Результатов нет :("
          text={
            <>По заданным параметрам не&nbsp;найдено ни&nbsp;одного перевода</>
          }
          icon={<FilterMIcon />}
        />
      );
    }

    return (
      <>
        <div className={styles.totals}>
          <HistoryTotal totals={history?.pages[0].totals} />
        </div>

        <Row className={styles.download}>
          <Col width={5}>
            <ButtonDesktop
              size="s"
              view="tertiary"
              block
              leftAddons={<DocumentExcelMIcon width={18} height={18} />}
              loading={historyFileLoading}
              onClick={historyFile ? undefined : loadHistory}
              href={historyFile?.url}
              download={historyFile?.filename}
              target={historyFile ? '_blank' : undefined}
            >
              {historyFile ? 'Открыть Excel' : 'Экспорт в Excel'}
            </ButtonDesktop>
          </Col>
        </Row>

        <div>
          <HistoryList history={historyItems} />
          {(historyPending || historyPendingNext) && (
            <Loader dataTestId="history-loader" />
          )}
          {hasMoreHistory && (
            <div className={styles.loader} ref={loaderRef}></div>
          )}
        </div>
      </>
    );
  };

  return (
    <ScreenResponsiveContainer>
      <div>
        <div className={styles.treaty}>
          <Typography.Text
            view="primary-small"
            color="primary"
            tag="p"
            weight="bold"
          >
            Счёт
          </Typography.Text>
          <SelectAccount
            selected={filter.subAccount}
            accounts={tradeAccounts || []}
            accountsPending={clientAccountsPending || clientAccountsPendingNext}
            onAccountSelect={onSelectAccount}
            accountsFetcher={setNextTreaty}
            hasMore={hasMoreClientAccounts}
            hint="По выбранному счету отображается история ниже"
          />
        </div>

        <div className={styles.filter}>
          <HistoryFilterLine
            filter={filter}
            active={isFilterActive}
            onFilterOpen={() => setOpenFilter(true)}
            onSetFilter={(filter) => setFilter(filter)}
          />
        </div>

        {renderHistoryResult()}

        <HistoryFilter
          open={openFilter}
          filter={filter}
          settings={settings || null}
          settingsPending={settingsPending}
          onSetFilter={(filter) => setFilter(filter)}
          onResetFilter={() => setFilter(defaultHistoryFilter)}
          onClose={() => setOpenFilter(false)}
        />
      </div>
    </ScreenResponsiveContainer>
  );
});
