import cn from 'classnames';
import { ReactNode } from 'react';

import styles from './Cell.module.css';

interface CellProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children?: ReactNode;
  className?: string;
  leftAlign?: boolean;
}

// Так как у таблицы уже есть обработчик контекстного меню, то нужно закостамизировать клик на строку
// (blueprint не дает предотвратить события клика и вместе с событием onSelection вызывает контекстное меню)
// Итого для blueprint onSelection === bodyContextMenuRenderer
export const Cell = ({
  children,
  className,
  leftAlign,
  ...divProps
}: CellProps) => {
  return (
    <div
      className={cn(
        styles.customCell,
        leftAlign && styles.leftAlign,
        className
      )}
      {...divProps}
    >
      {children}
    </div>
  );
};
