import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { mapInstructionTheme } from '../lib/mapInstructionTheme';

import { InstructionThemeDTO } from './types/InstructionThemeDTO';
import { InstructionThemeParams } from './types/InstructionThemeParams';

export async function getInstructionTheme(data: InstructionThemeParams) {
  const res = await fetchLkResult<InstructionThemeDTO | LKResult>(
    qs.stringifyUrl({
      url: '/api/instructions/theme',
      query: { ...data },
    })
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return mapInstructionTheme(res);
}
