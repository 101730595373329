import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { AddAccountConstraintsGI } from '../model/types/AddAccountConstraintsGI';

export const getAddAccountsConstraintsGI = async () => {
  const res = await fetchLkResult<AddAccountConstraintsGI | LKResult>(
    `/api/client/accounts/can-open-new-account`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};
