import { cloneElement } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import styles from './BottomSheetBlock.module.css';

/**
 * Модель элемента списка в блоке.
 *
 * @prop key Ключ для списков в react.
 * @prop text Текст элемента.
 * @prop [leftIcon] Левая иконка элемента.
 * @prop [rightIcon] Правая иконка элемента.
 * @prop [onClick] Функция вызывающаяся при клике
 */
export interface BottomSheetBlockItem {
  key: string;
  text: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  onClick?: () => void;
}

interface BottomSheetBlockProps {
  title?: string;
  items: BottomSheetBlockItem[];
}

export const BottomSheetBlock = ({ title, items }: BottomSheetBlockProps) => {
  return (
    <div>
      {title && (
        <Typography.TitleMobile
          tag="div"
          view="xsmall"
          weight="bold"
          className={styles.listItem}
        >
          {title}
        </Typography.TitleMobile>
      )}
      {items.map(({ key, text, leftIcon, rightIcon, onClick }) => (
        <div className={styles.listItem} onClick={onClick} key={key}>
          {leftIcon &&
            cloneElement(leftIcon, {
              className: styles.leftIcon,
            })}
          <Typography.Text view="primary-medium" className={styles.text}>
            {text}
          </Typography.Text>
          {rightIcon &&
            cloneElement(rightIcon, {
              className: styles.rightIcon,
            })}
        </div>
      ))}
    </div>
  );
};
