import cn from 'classnames';
import { memo } from 'react';
import { AmountInput } from '@alfalab/core-components/amount-input';

import { Stepper } from '@terminal/common/components/Stepper';
import { OrderType } from '@terminal/core/lib/client/entities';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';

import { AdditionalVolumeSettings } from '../AdditionalVolumeSettings';

import { useIsLotCount } from '../../../../shared/hooks/useIsLotCount';
import { useVolumeQuickControls } from '../../hooks';

import { FormType } from '../../types';

import styles from './VolumeInput.module.css';

type VolumeInputProps = {
  formType: FormType;
  orderType: OrderType;
  quantity: number;
  idFi: number;
  side: 'buy' | 'sell';
  price: number;
  tradeLimits: TradeLimitsResult | null;
  lot?: number;
  isHideQuickLimits?: boolean;
  selectedSubAccountId: number;
  setQuantity: (quantity: number) => void;
  containerClassName?: string;
};

export const VolumeInput = memo(
  ({
    formType,
    orderType,
    isHideQuickLimits,
    lot,
    quantity,
    idFi,
    side,
    price,
    tradeLimits,
    selectedSubAccountId,
    setQuantity,
    containerClassName,
  }: VolumeInputProps) => {
    const isLotCount = useIsLotCount();

    const isQuantityError = !isLotCount && lot && quantity % lot !== 0;

    const {
      volumeAdditional,
      clearVolumeAdditional,
      volAdSuffix,
      limitsDisabled,
      positionVolumeDisabled,
      cashVolumeDisabled,
    } = useVolumeQuickControls({
      idFi,
      side,
      orderType,
      lot,
      price,
      setQuantity,
      tradeLimits,
      selectedSubAccountId,
    });

    const handleBlur = () => {
      if (lot && !isLotCount && quantity < lot) {
        setQuantity(lot);
      }
    };

    const handleAdd = () => {
      if (isLotCount) {
        setQuantity(quantity + 1);
      } else if (lot) {
        setQuantity(quantity + lot);
      }

      clearVolumeAdditional();
    };

    const handleSub = () => {
      if (isLotCount) {
        setQuantity(quantity > 1 ? quantity - 1 : quantity);
      } else if (lot) {
        setQuantity(quantity > lot ? quantity - lot : lot);
      }

      clearVolumeAdditional();
    };

    const limitsQuantityError =
      tradeLimits && quantity > (tradeLimits?.quantity || 0) ? (
        <></>
      ) : undefined;

    return (
      <div className={cn(containerClassName)}>
        <AmountInput
          className={styles.customInput}
          fieldClassName={styles.customInputField}
          error={isQuantityError ? <>Не кратно лоту</> : limitsQuantityError}
          block
          suffix={`${isLotCount ? 'лот' : 'шт'} ${
            !isHideQuickLimits ? volAdSuffix : ''
          }`}
          value={quantity}
          minority={1}
          integersOnly
          onChange={(_, { value }) => {
            setQuantity(value || 0);
          }}
          onBlur={handleBlur}
          rightAddons={
            <div className={styles.priceAddons}>
              {formType !== FormType.MOBILE_CHART && (
                <Stepper size="s" onAdd={handleAdd} onSub={handleSub} />
              )}
              <AdditionalVolumeSettings
                limitDisabled={limitsDisabled}
                posDisabled={positionVolumeDisabled}
                cashDisabled={cashVolumeDisabled}
                {...volumeAdditional}
              />
            </div>
          }
          dataTestId="priceInput"
        />
      </div>
    );
  }
);

VolumeInput.displayName = 'VolumeInput';
