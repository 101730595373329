import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { NotificationType } from '@terminal/core/types/ui';

import { useAlfaDirectContext, useWidgetContext } from '../../../shared';

import { useCertificateEnrollStore } from '../model/store';

export const useSendSms = (certificateId?: number) => {
  const { smsSignSendSms } = useAlfaDirectContext();
  const { addNotification } = useWidgetContext();

  const [operation, setAssignment, setError, clearError] =
    useCertificateEnrollStore((store) => [
      store.operation,
      store.setAssignment,
      store.setError,
      store.clearError,
    ]);

  // Отправка SMS
  const sendSmsQuery = useMutation(smsSignSendSms, {
    onSuccess: (assignment) => {
      if (assignment.code === 0) {
        setAssignment(assignment);
      } else if ([1, 2].includes(assignment.code)) {
        setError({
          code: assignment.code,
          message: assignment.message,
        });
      } else {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: assignment?.message || 'Произошла ошибка при отправке смс',
        });
      }
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Произошла ошибка при отправке смс',
      });
    },
  });

  // Повторная отправка сообщения
  const resendSms = useCallback(async () => {
    clearError();

    if (operation) {
      sendSmsQuery.mutate(operation.url);
    } else {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса СМС',
        text: `CertificateId: ${certificateId}`,
      });
    }
  }, [operation, sendSmsQuery, addNotification, certificateId, clearError]);

  return {
    sendSmsQuery,
    resendSms,
  };
};
