import cn from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { Dropdown, DropdownItemProps } from '../Dropdown';
import { ButtonItem } from './ButtonItem';
import { SwitchItem } from './SwitchItem';

import { MenuGroup } from './types/MenuGroup';
import { MenuItem } from './types/MenuItem';

import styles from './Menu.module.css';

export type MenuProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Группы меню
   * @default []
   * */
  groups: MenuGroup[];
  /**
   * Функция, которая вызывается по нажатию на элемент меню
   * */
  onItemClick?: (menuItem: MenuItem) => void;
  withDivider?: boolean;
  withGap?: boolean;
};

/**
 * Базовое меню
 * */
export const Menu: FC<MenuProps> = (props) => {
  const {
    className,
    groups,
    onItemClick,
    withDivider = true,
    withGap = false,
    ...restProps
  } = props;
  const rootClassName = cn(className, styles.root);

  return (
    <div className={rootClassName} {...restProps}>
      {groups
        .filter(
          ({ items }) => items.length > 0 && items.some(({ hidden }) => !hidden)
        )
        .map(({ key, label, items }, groupIndex, groupsArray) => (
          <div key={key} className={styles.group}>
            {label && (
              <Typography.Text
                weight="bold"
                tag="p"
                view="secondary-small"
                color="secondary"
                defaultMargins={false}
                className={styles.groupTitle}
              >
                {label}
              </Typography.Text>
            )}
            {items
              .filter(({ hidden }) => !hidden)
              .map((item, itemIndex, itemsArray) => {
                const { key, Icon, hideMenu, hidden, ...restItem } = item;
                const isWithDivider =
                  groupIndex < groupsArray.length - 1 &&
                  itemIndex === itemsArray.length - 1;

                const dividerProps: Pick<
                  DropdownItemProps,
                  'withDivider' | 'withGap'
                > = {};

                if (isWithDivider && withGap) {
                  dividerProps.withGap = true;
                } else if (isWithDivider && withDivider) {
                  dividerProps.withDivider = true;
                }

                if (restItem.type === 'element') {
                  return item.label;
                }

                return (
                  <Dropdown.Item
                    key={key}
                    {...dividerProps}
                    leftIcon={Icon && <Icon className={styles.icon} />}
                    onClick={() => onItemClick?.(item)}
                    renderContent={(renderProps) => {
                      if (restItem.type === 'button') {
                        return <ButtonItem {...renderProps} {...restItem} />;
                      }

                      if (restItem.type === 'switch') {
                        return <SwitchItem {...renderProps} {...restItem} />;
                      }

                      return null;
                    }}
                  />
                );
              })}
          </div>
        ))}
    </div>
  );
};
