// текущий список - https://rusbonds.ru/markets/116224/structure от 23.03.2023

export const RUCBITRList = [
  'RU000A103WV8',
  'RU000A104Z48',
  'RU000A104XR2',
  'RU000A0ZYLG5',
  'RU000A105KP0',
  'RU000A105GE2',
  'RU000A104B46',
  'RU000A101CQ4',
  'RU000A100HY9',
  'RU000A101GZ6',
  'RU000A105A61',
  'RU000A105JU2',
  'RU000A0ZYXV9',
  'RU000A101LJ0',
  'RU000A100VQ6',
  'RU000A0ZYDD9',
  'RU000A101137',
  'RU000A103943',
  'RU000A0JV219',
  'RU000A105JP2',
  'RU000A105KQ8',
  'RU000A105GW4',
  'RU000A105HC4',
  'RU000A100P85',
  'RU000A1051T3',
  'RU000A100XC2',
  'RU000A1007Z2',
  'RU000A1057P8',
  'RU000A102DB2',
  'RU000A1055Y4',
  'RU000A101QW2',
  'RU000A0ZYU05',
  'RU000A102QP4',
  'RU000A102YG7',
  'RU000A1017J5',
  'RU000A1017H9',
  'RU000A104VS4',
  'RU000A0ZZZ17',
  'RU000A105HJ9',
  'RU000A104XW2',
  'RU000A0JV1X3',
  'RU000A103N84',
  'RU000A103PE8',
  'RU000A103PT6',
  'RU000A1051E5',
  'RU000A105559',
  'RU000A100HU7',
  'RU000A100LL8',
  'RU000A105NA6',
  'RU000A0JQRD9',
  'RU000A0JX1S1',
  'RU000A1036E6',
  'RU000A1038F9',
  'RU000A0JXZB2',
  'RU000A0JXQ44',
  'RU000A0JUAH8',
  'RU000A0ZZET0',
  'RU000A0ZZES2',
  'RU000A103EZ7',
  'RU000A100Z91',
  'RU000A101Q26',
  'RU000A1010M4',
  'RU000A101M04',
  'RU000A103C95',
  'RU000A101QM3',
  'RU000A1010B7',
  'RU000A101FH6',
  'RU000A101FG8',
  'RU000A102986',
  'RU000A101TB0',
  'RU000A101QN1',
  'RU000A102FT9',
  'RU000A103KG4',
  'RU000A102RU2',
  'RU000A102H91',
  'RU000A102SX4',
  'RU000A102S80',
  'RU000A104SX0',
  'RU000A1008D7',
  'RU000A104SU6',
  'RU000A104V75',
  'RU000A0ZZ984',
  'RU000A1005L6',
  'RU000A105C28',
  'RU000A104W17',
  'RU000A1008W7',
  'RU000A0JUV08',
  'RU000A0ZYG52',
  'RU000A0ZYNY4',
  'RU000A0ZYR91',
  'RU000A105666',
  'RU000A100HH4',
  'RU000A104W33',
  'RU000A105L27',
  'RU000A104WJ1',
  'RU000A1053A9',
  'RU000A100FE5',
  'RU000A0JQ7Z2',
  'RU000A103FP5',
  'RU000A0JUMH3',
  'RU000A0JXP45',
  'RU000A0ZYEE5',
  'RU000A0ZYLC4',
  'RU000A0ZYWY5',
  'RU000A0ZYX69',
  'RU000A0ZYYE3',
  'RU000A103DS4',
  'RU000A0ZZ9R4',
  'RU000A1008J4',
  'RU000A101350',
  'RU000A1036H9',
  'RU000A1036M9',
  'RU000A103BE8',
  'RU000A1056S4',
  'RU000A0ZYUY9',
  'RU000A1003A4',
  'RU000A101012',
  'RU000A103AT8',
  'RU000A1023K1',
  'RU000A102VR0',
  'RU000A1038D4',
  'RU000A101GJ0',
  'RU000A0ZYQY7',
  'RU000A0ZZ1J8',
  'RU000A1013P1',
  'RU000A1058K7',
  'RU000A103372',
  'RU000A103PX8',
  'RU000A1031U3',
  'RU000A105K85',
  'RU000A103QH9',
  'RU000A101QL5',
  'RU000A103QL1',
  'RU000A101CL5',
  'RU000A103NY1',
  'RU000A1030Y7',
  'RU000A100A66',
  'RU000A103WQ8',
  'RU000A1030X9',
  'RU000A1030K6',
  'RU000A105HN1',
  'RU000A1051U1',
  'RU000A104Y15',
  'RU000A1016Z3',
  'RU000A103KJ8',
  'RU000A103C53',
  'RU000A102TA0',
  'RU000A1038M5',
  'RU000A103661',
  'RU000A103S14',
  'RU000A1024W4',
  'RU000A103M36',
  'RU000A101GD3',
  'RU000A105518',
  'RU000A100WA8',
  'RU000A0JWGD0',
  'RU000A100UG9',
  'RU000A0JX5W4',
  'RU000A100W60',
  'RU000A105KB0',
  'RU000A100XU4',
  'RU000A102ZH2',
  'RU000A104693',
  'RU000A105NB4',
  'RU000A101PC6',
  'RU000A0ZZV11',
  'RU000A100NS9',
  'RU000A103RT2',
  'RU000A102GU5',
  'RU000A102T63',
  'RU000A101NG2',
  'RU000A101SD8',
  'RU000A101SC0',
];
