import { DepoAccountInfo } from '@terminal/core/lib/rest/lkAccountInfo';

export const depoOrder = ['MICEX_SHR', 'SPBEX_FSHR', 'EUROTRADE'];

export const sortDepoAccounts = (a: DepoAccountInfo, b: DepoAccountInfo) => {
  if (
    depoOrder.indexOf(a.placeCode) < 0 &&
    depoOrder.indexOf(b.placeCode) >= 0
  ) {
    return 1;
  } else if (
    depoOrder.indexOf(a.placeCode) >= 0 &&
    depoOrder.indexOf(b.placeCode) < 0
  ) {
    return -1;
  }
  return depoOrder.indexOf(a.placeCode) - depoOrder.indexOf(b.placeCode);
};
