import { useMemo, useState } from 'react';
import { Loader } from '@alfalab/core-components/loader';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import {
  CURRENCIES_ORDER,
  getCurrenciesGroups,
} from '../../../transfer/tabs/MoneyRequisites/helpers';
import { RequisitesDetail } from '../../../transfer/tabs/MoneyRequisites/RequisitesDetail';
import { RequisitesGroup } from '../../../transfer/tabs/MoneyRequisites/RequisitesGroup';
import { ScreenResponsiveContainer } from '../../ScreenResponsiveContainer';
import { MoneyRequisitesError } from './MoneyRequisitesError';

import { useMoneyRequisitesList } from '../../../transfer/hooks';

import styles from './MoneyRequisites.module.css';

interface MoneyRequisitesProps {
  treaty: Treaty | null;
  email?: string;
  emailConfirmed?: boolean;
  mobile?: boolean;
}

export const MoneyRequisites = ({
  treaty,
  email,
  emailConfirmed,
  mobile,
}: MoneyRequisitesProps) => {
  const [reqisitesId, setReqisitesId] = useState<number | null>(null);
  const [showError, setShowError] = useState(false);

  const { supportPhone, supportLandlinePhone } = useAppContext();

  const { data: requisites, isFetching: requisitesPending } =
    useMoneyRequisitesList(treaty?.treaty);

  const selectedRequisites = useMemo(() => {
    return requisites?.find((req) => req.id === reqisitesId);
  }, [reqisitesId, requisites]);

  const currencies = useMemo(() => {
    if (requisites) {
      return getCurrenciesGroups(requisites);
    }
  }, [requisites]);

  const onRequisitesSelect = (id: number) => {
    const item = requisites?.find((req) => req.id === id);

    if (item) {
      if (item.error) {
        setShowError(true);
      } else {
        setReqisitesId(id);
      }
    }
  };

  const closeError = () => {
    setShowError(false);
  };

  if (requisitesPending) {
    return <Loader />;
  }

  return (
    <>
      <ScreenResponsiveContainer mobile={mobile}>
        <div>
          {/* Сначала выводим валюты, порядок для которых определен */}
          {currencies
            ? CURRENCIES_ORDER.map((cur) =>
                currencies.ordered[cur] ? (
                  <RequisitesGroup
                    className={styles.group}
                    currency={cur}
                    key={`currency-${cur}`}
                    items={currencies.ordered[cur]}
                    onRequisitesClick={onRequisitesSelect}
                  />
                ) : null
              )
            : null}
          {/* Потом все остальные, если есть */}
          {currencies
            ? Object.keys(currencies.other).map((cur) => (
                <RequisitesGroup
                  className={styles.group}
                  currency={cur}
                  key={`currency-${cur}`}
                  items={currencies.other[cur]}
                />
              ))
            : null}
        </div>
      </ScreenResponsiveContainer>

      <Portal id="money-transfer-modals">
        <FunctionalModalSidebar
          open={Boolean(selectedRequisites)}
          backLabel="Пополнение по реквизитам"
          onClose={() => setReqisitesId(null)}
        >
          {selectedRequisites ? (
            <RequisitesDetail
              requisites={selectedRequisites}
              treaty={treaty}
              email={email}
              emailConfirmed={emailConfirmed}
            />
          ) : null}
        </FunctionalModalSidebar>

        <FunctionalModalSidebar
          open={showError}
          onClose={closeError}
          showHead={false}
        >
          <MoneyRequisitesError
            supportPhone={supportPhone}
            supportLandlinePhone={supportLandlinePhone}
            onAction={closeError}
          />
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
