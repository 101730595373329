import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  confirmDraft,
  DraftConfirmParams,
} from '@terminal/core/lib/rest/lkDrafts';
import { LkSignOptions } from '@terminal/core/store/slices/lkSignSlice';
import { NotificationType } from '@terminal/core/types/ui';

export const useDraftConfirm = (options: LkSignOptions) => {
  const addNotification = useNotification();

  return useMutation(
    (data: DraftConfirmParams) => confirmDraft(data, options),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'При подписании поручения произошла ошибка',
        });
      },
    }
  );
};
