// предыдущий список ISIN индкса взят https://iss.moex.com/iss/engines/stock/markets/shares/securities.json?index=IMOEX
// текущий - https://rusbonds.ru/markets/34318/structure от 24.03.2023
export const iMOEXList = [
  /* AFKS */ 'RU000A0DQZE3',
  /* AFLT */ 'RU0009062285',
  /* ALRS */ 'RU0007252813',
  /* BSPB */ 'RU0009100945',
  /* CBOM */ 'RU000A0JUG31',
  /* CHMF */ 'RU0009046510',
  /* ENPG */ 'RU000A100K72',
  /* FEES */ 'RU000A0JPNN9',
  /* FIVE@GS(наше) или FIVE(биржи) */ 'US98387E2054',
  /* FLOT */ 'RU000A0JXNU8',
  /* GAZP */ 'RU0007661625',
  /* GLTR@GS(наше) или GLTR(биржи) */ 'US37949E2046',
  /* GMKN */ 'RU0007288411',
  /* HYDR */ 'RU000A0JPKH7',
  /* IRAO */ 'RU000A0JPNM1',
  /* LKOH */ 'RU0009024277',
  /* MAGN */ 'RU0009084396',
  /* MGNT */ 'RU000A0JKQU8',
  /* MOEX */ 'RU000A0JR4A1',
  /* MSNG */ 'RU0008958863',
  /* MTLR */ 'RU000A0DKXV5',
  /* MTLRP */ 'RU000A0JPV70',
  /* MTSS */ 'RU0007775219',
  /* NLMK */ 'RU0009046452',
  /* NVTK */ 'RU000A0DKVS5',
  /* OZON */ 'US69269L1044',
  /* PHOR */ 'RU000A0JRKT8',
  /* PIKK */ 'RU000A0JP7J7',
  /* PLZL */ 'RU000A0JNAA8',
  /* POSI */ 'RU000A103X66',
  /* RAGRdr(наше) или AGRO(биржи) */ 'US7496552057',
  /* ROSN */ 'RU000A0J2Q06',
  /* RTKM */ 'RU0008943394',
  /* RUAL */ 'RU000A1025V3',
  /* SBER */ 'RU0009029540',
  /* SBERP */ 'RU0009029557',
  /* SELG */ 'RU000A0JPR50',
  /* SGZH */ 'RU000A102XG9',
  /* SMLT */ 'RU000A0ZZG02',
  /* SNGS */ 'RU0008926258',
  /* SNGSP */ 'RU0009029524',
  /* TATN */ 'RU0009033591',
  /* TATNP */ 'RU0006944147',
  /* TCSG */ 'RU000A107UL4',
  /* TRNFP */ 'RU0009091573',
  /* UPRO */ 'RU000A0JNGA5',
  /* VKCO */ 'RU000A106YF0',
  /* VTBR */ 'RU000A0JP5V6',
];
