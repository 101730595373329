import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getContestRestrictedAssets } from '../api/getContestRestrictedAssets';

export const useContestRestrictedAssets = (
  contestid: number,
  enabled: boolean = true
) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'contest/restricted-assets', { contestid }],
    () => getContestRestrictedAssets({ contestid }),
    {
      enabled: enabled,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message ||
            'При запросе списка заблокированных активов произошла ошибка',
        });
      },
    }
  );
};
