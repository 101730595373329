import { Amount } from '@alfalab/core-components/amount';
import { ProgressBar } from '@alfalab/core-components/progress-bar';

import styles from './OrderQtyInfo.module.css';

interface Props {
  totalBuyQty?: number;
  totalSellQty?: number;
}

export const OrderQtyInfo = ({ totalBuyQty, totalSellQty }: Props) => {
  if (!totalBuyQty || !totalSellQty) {
    return null;
  } else {
    return (
      <div className={styles.qtyWrapper}>
        <div className={styles.qtyValues}>
          <Amount.Pure value={totalBuyQty} minority={1} />
          <Amount.Pure value={totalSellQty} minority={1} />
        </div>
        <ProgressBar
          size="s"
          value={(totalBuyQty / (totalBuyQty + totalSellQty)) * 100}
        />
      </div>
    );
  }
};
