import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { useAppContext } from '@terminal/core/hooks/useAppContext';

import { useThemeContext } from '../../../shared/lib/themeContext';

import { StepScreenProps } from '../types';

import styles from './Steps.module.css';

export const SecondStep = ({ style }: StepScreenProps) => {
  const { theme } = useThemeContext();
  const { onboarding } = useAppContext();
  const { widgetsFirstPart, widgetsSecondPart } = onboarding.images[theme];

  return (
    <div style={style} className={styles.slideContainer}>
      <div className={styles.stepContainer}>
        <Space fullWidth>
          <Typography.Text view="primary-large" weight="bold">
            Добавление виджетов
          </Typography.Text>
          <Typography.Text view="secondary-large">
            Добавить виджеты можно с главной панели терминала с помощью кнопки
            «Добавить виджет».
          </Typography.Text>
        </Space>
      </div>
      <div
        className={styles.secondStepImageOne}
        style={{ backgroundImage: `url(${widgetsFirstPart})` }}
      />
      <div className={styles.stepContainer}>
        <Space fullWidth>
          <Typography.Text view="secondary-large">
            Вы также можете добавить виджет на панели вкладок, чтобы расположить
            его рядом с другим виджетом.
          </Typography.Text>
        </Space>
      </div>
      <div
        className={styles.secondStepImageTwo}
        style={{ backgroundImage: `url(${widgetsSecondPart})` }}
      />
    </div>
  );
};
