import cn from 'classnames';
import { FormikTouched, useFormikContext } from 'formik';
import { PhoneInput } from 'formik-alfalab-core-components/PhoneInput';
import isEmpty from 'lodash/isEmpty';
import { ReactNode } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { formatPhone, validatePhoneForm } from '../../entities/Login';
import { SmileStatus } from './components/SmileStatus';

import styles from './LoginWithPhone.module.css';

export interface PhoneForm {
  phone: string;
}

interface RenderControlsProps {
  hasValidationErrors: boolean;
  touched: FormikTouched<PhoneForm>;
  values: { phone: string };
}

export interface LoginWithPhoneProps {
  className?: string;
  onPhoneChange: (phone: string) => void;
  renderControls: (props: RenderControlsProps) => ReactNode;
}

export const LoginWithPhone = (props: LoginWithPhoneProps) => {
  const { className, onPhoneChange, renderControls } = props;
  const { values, touched, errors } = useFormikContext<PhoneForm>();

  const hasValidationErrors =
    Object.values(
      validatePhoneForm({
        phone: formatPhone(values.phone),
      })
    ).some((value) => !isEmpty(value)) || isEmpty(values.phone);

  const hasFocusValidationErrors = Object.values(errors).some(
    (value) => !isEmpty(value)
  );

  return (
    <div className={cn(styles.wrapper, className)}>
      <SmileStatus status={hasFocusValidationErrors ? 'sad' : 'happy'} />
      <Gap size="2xl" />
      <Typography.Title tag="div" view="xsmall" className={styles.title}>
        Привет! Введите телефон и входите скорее
      </Typography.Title>
      <Gap size="xl" />
      <form className={styles.phoneWrapper}>
        <PhoneInput
          name="phone"
          autoFocus
          placeholder="Номер телефона"
          block
          onChange={(_, payload) => {
            onPhoneChange(payload.value);
          }}
        />
      </form>
      <Gap size="xs" />
      {!hasFocusValidationErrors && (
        <Typography.Text view="primary-small" color="secondary">
          Если у вас ещё нет брокерского счёта, откроем бесплатно
        </Typography.Text>
      )}
      {renderControls({
        touched,
        hasValidationErrors,
        values,
      })}
    </div>
  );
};
