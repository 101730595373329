import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { fetchLkFile } from '@terminal/core/lib/rest/lkApi';
import { NotificationType } from '@terminal/core/types/ui';

export const useDownloadFile = () => {
  const addNotification = useNotification();

  return useMutation(
    async (url: string) => {
      return await fetchLkFile(url);
    },
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка загрузки файла',
          text: error?.message || '',
        });
      },
    }
  );
};
