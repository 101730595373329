import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { wrapWithComponents } from '@terminal/core/lib/react/wrapWithComponents';
import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';

import {
  AboutLimits,
  filterTradeAccounts,
  getAllBrokerAccountsFrom,
  MoneyLimitProvider,
  TRANSFERABLE_CURRENCIES,
  TransferCompleteParams,
  useClientAccounts,
  useMoneyLimitContext,
} from '../../../../entities/TransferGoinvest';
import { FormSectionHeader } from '../../../../shared/ui/FormSectionHeader';
import { SelectTradeAccount } from '../SelectTradeAccount';
import { MoneyToOtherCurrency } from './MoneyToOtherCurrency';
import { MoneyToOtherRub } from './MoneyToOtherRub';

import styles from './MoneyToOther.module.css';

interface MoneyToOtherProps {
  onSuccess?: (params: TransferCompleteParams) => void;
}

const MoneyToOtherComponent = ({ onSuccess }: MoneyToOtherProps) => {
  const [account, setAccount] = useState<TradeAccount | undefined>();

  const { limit } = useMoneyLimitContext();

  const {
    data: clientAccounts,
    isFetching: clientAccountsPending,
    isFetchingNextPage: clientAccountsPendingNext,
    hasNextPage: hasMoreClientAccounts,
    fetchNextPage: fetchMoreClienAccounts,
    refetch: refetchClientAccounts,
  } = useClientAccounts(true);

  const availableAccounts = useMemo(() => {
    if (!clientAccounts) {
      return [];
    }

    const allAccounts: TradeAccount[] =
      getAllBrokerAccountsFrom(clientAccounts);

    return allAccounts
      .filter(filterTradeAccounts)
      .filter((acc) => TRANSFERABLE_CURRENCIES.indexOf(acc.currency) >= 0);
  }, [clientAccounts]);

  // Автоматически выбираем первый рублёвый счёт
  useEffect(() => {
    if (availableAccounts && !account) {
      const rubAccount = availableAccounts.find(
        (acc) => acc.currency === 'RUB' || acc.currency === 'RUR'
      );

      if (rubAccount) {
        setAccount(rubAccount);
      }
    }
  }, [availableAccounts, account]);

  const onTradeAccountSelect = (acc: TradeAccount) => {
    setAccount(acc);
  };

  const fetchNextTreatyAccounts = useCallback(() => {
    fetchMoreClienAccounts();
  }, [fetchMoreClienAccounts]);

  const isCurrency = Boolean(
    account && account.currency !== 'RUB' && account.currency !== 'RUR'
  );

  const onSuccessTransfer = (params: TransferCompleteParams) => {
    refetchClientAccounts();
    onSuccess?.(params);
  };

  // Признак того, что сумма на счете и лимит одинаковые
  let limitDiffer = limit && account && limit.limit !== account.assetValue;

  return (
    <>
      <FormSectionHeader>Откуда</FormSectionHeader>

      <div className={styles.position}>
        <SelectTradeAccount
          label="Позиция"
          labelView="outer"
          selected={account}
          limitValue={limit && limit.limit}
          accounts={availableAccounts}
          accountsPending={clientAccountsPending || clientAccountsPendingNext}
          onAccountSelect={onTradeAccountSelect}
          accountsFetcher={fetchNextTreatyAccounts}
          hasMore={hasMoreClientAccounts}
          hint={limitDiffer && <AboutLimits backLabel="Вывод" />}
        />
      </div>

      {isCurrency ? (
        <MoneyToOtherCurrency
          account={account || null}
          onSuccess={onSuccessTransfer}
        />
      ) : (
        <MoneyToOtherRub
          account={account || null}
          onSuccess={onSuccessTransfer}
        />
      )}
    </>
  );
};

export const MoneyToOther = React.memo(
  wrapWithComponents([MoneyLimitProvider])(MoneyToOtherComponent)
);
