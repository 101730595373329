import { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { SETTINGS_STORAGE_KEY } from '@terminal/core/constants/common';
import { PASSPORT_URI, USE_PASSPORT_CERBERUS_AUTH } from '@terminal/core/env';
import { featureFlags } from '@terminal/core/featureFlags';
import { getPassportCrossAuthURI } from '@terminal/core/lib/getPassportCrossAuthURI';
import { localStorage } from '@terminal/core/lib/storages';

import { Login } from './Login';
import { LoginByPhone } from './LoginByPhone';
import { ROUTE } from './routes';

import { Restore } from './Restore';

// TODO: вынести константы localStorage в отдельный файл
const SESSION_STORAGE_KEY = 'authPhone';

const baseRouteMapper = {
  pro: ({
    appLogo,
    copyright,
    theme,
  }: {
    appLogo: JSX.Element | null;
    copyright: string;
    theme: string;
  }) => <Login appLogo={appLogo} copyright={copyright} theme={theme} />,
  pwa: ({
    appLogo,
    copyright,
  }: {
    appLogo: JSX.Element | null;
    copyright: string;
  }) => {
    const isAuthByPhoneEnabled = featureFlags.AUTH_BY_PHONE_ENABLED;

    const isAutorized =
      (localStorage.getItem(SESSION_STORAGE_KEY) ||
        // TODO: убрать привязку к settings (сейчас добавлено, чтобы не показывать уже авторизованным пользователям экран авторизации по номеру телефона)
        localStorage.getItem(SETTINGS_STORAGE_KEY)) ??
      '';

    if (isAuthByPhoneEnabled && !isAutorized) {
      return <LoginByPhone appLogo={appLogo} copyright={copyright} />;
    }

    return <Login appLogo={appLogo} copyright={copyright} />;
  },
};
// заглушка для десктопа, нужно в это место добавить авторизацию по номеру телефона
const RedirectToPassport = () => {
  useEffect(() => {
    // @ts-expect-error
    window.location = getPassportCrossAuthURI(
      PASSPORT_URI,
      window.location.search
    );
  }, []);

  return null;
};

export const renderLoginRoutes = (
  mode: 'pro' | 'pwa' = 'pro',
  appLogo: JSX.Element | null,
  copyright: string,
  theme
) => {
  return (
    <>
      <Route
        path="/"
        element={baseRouteMapper[mode]({ appLogo, copyright, theme })}
      />
      {USE_PASSPORT_CERBERUS_AUTH ? (
        <Route path="/auth" element={<RedirectToPassport />} />
      ) : (
        <>
          <Route
            path="/passport"
            element={<LoginByPhone appLogo={appLogo} copyright={copyright} />}
          />
          <Route
            path="/desktop/passport"
            element={<LoginByPhone appLogo={appLogo} copyright={copyright} />}
          />
          <Route
            path={ROUTE.RESTORE}
            element={<Restore appLogo={appLogo} copyright={copyright} />}
          />
        </>
      )}
    </>
  );
};
