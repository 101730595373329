import { useQuery } from 'react-query';

import {
  getSecuritiesActivePlaceList,
  SecuritiesActivePlacesParams,
} from '@terminal/core/lib/rest/lkSecurities';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesActivePlaces = (
  params?: SecuritiesActivePlacesParams
) => {
  return useQuery(
    [LkQueryKeys.SecuritiesActivePlaces],
    async () => {
      if (!params) {
        return null;
      }

      return await getSecuritiesActivePlaceList(params);
    },
    {
      enabled: Boolean(params),
    }
  );
};
