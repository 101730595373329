import { Typography } from '@alfalab/core-components/typography';

interface StrategyProfitNoteProps {
  className?: string;
}

export const StrategyProfitNote = ({ className }: StrategyProfitNoteProps) => {
  return (
    <Typography.Text
      tag="p"
      view="secondary-small"
      color="secondary"
      className={className}
    >
      * Не является ИИР. Доходы, полученные в прошлом, не являются гарантией
      получения аналогичных доходов в будущем.
    </Typography.Text>
  );
};
