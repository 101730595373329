import faceHappy1x from './faceHappy1x.png';
import faceHappy2x from './faceHappy2x.png';
import faceSad1x from './faceSad1x.png';
import faceSad2x from './faceSad2x.png';

type Status = 'happy' | 'sad';

interface SmileStatusProps {
  status: Status;
}

const IMAGE_BY_STARUS: Record<Status, [src: string, str2x: string]> = {
  happy: [faceHappy1x, faceHappy2x],
  sad: [faceSad1x, faceSad2x],
};

export const SmileStatus = (props: SmileStatusProps) => {
  const { status = 'happy' } = props;
  const [src, src2x] = IMAGE_BY_STARUS[status];

  return <img width={48} height={48} src={src} srcSet={`${src2x} 2x`} alt="" />;
};
