import cn from 'classnames';
import React, { FC } from 'react';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';

import {
  ModalFooter,
  ModalFooterProps,
} from '../../../legacy/components/ModalFooter';
import { ModalHeader } from '../../../legacy/components/ModalHeader';

import styles from './Modal.module.css';

export type ModalProps = Pick<
  BaseModalProps,
  'open' | 'onClose' | 'className'
> &
  ModalFooterProps & {
    /**
     * Заголовок окна
     * */
    title: string;
    /**
     * Если `true`, то контент не оборачивается в контейнер с отступами
     * @default false
     * */
    isRawContent?: boolean;
  };

/**
 * Модальное окно
 * */
export const Modal: FC<ModalProps> = (props) => {
  const {
    open,
    onClose,
    children,
    title,
    className,
    actionButtonsStart = [],
    actionButtonsEnd = [],
    isRawContent,
  } = props;
  const rootClassName = cn(className, styles.root);

  return (
    <BaseModal open={open} onClose={onClose} className={rootClassName}>
      <ModalHeader className={styles.header} onClose={onClose}>
        {title}
      </ModalHeader>
      <div className={cn({ [styles.content]: !isRawContent })}>{children}</div>
      <ModalFooter
        actionButtonsStart={actionButtonsStart}
        actionButtonsEnd={actionButtonsEnd}
      />
    </BaseModal>
  );
};
