import { Select, SelectProps } from 'formik-alfalab-core-components/Select';
import React, { FC } from 'react';

import {
  TrackPriceSettings,
  TrackPriceSettingTitlePosition,
} from '@terminal/core/types/trackPrice';

export type TitlePositionSelectProps = Omit<SelectProps, 'options' | 'name'>;

const OPTIONS: SelectProps['options'] = [
  {
    key: TrackPriceSettingTitlePosition.Middle,
    value: TrackPriceSettingTitlePosition.Middle,
    content: 'Посередине',
  },
  {
    key: TrackPriceSettingTitlePosition.Left,
    value: TrackPriceSettingTitlePosition.Left,
    content: 'Слева',
  },
  {
    key: TrackPriceSettingTitlePosition.Right,
    value: TrackPriceSettingTitlePosition.Right,
    content: 'Справа',
  },
];

const NAME: keyof TrackPriceSettings = 'titlePosition';

export const TitlePositionSelect: FC<TitlePositionSelectProps> = (props) => {
  return (
    <Select
      name={NAME}
      block
      label="Расположение текста на графике"
      labelView="outer"
      size="s"
      options={OPTIONS}
      {...props}
    />
  );
};
