import {
  Operation,
  OperationGroup,
  OperationStatus,
  OperationType,
  OrderDirection,
} from '@terminal/core/lib/client/entities';
import { MoneyHistoryItem } from '@terminal/core/lib/rest/lkMoney';
import { MarketBoardItem, ObjectItem } from '@terminal/core/types/core';
import { OrderItem } from '@terminal/core/types/order';

export interface OperationRow {
  idFi?: number;
  operation: OperationItem;
  currency: ObjectItem;
  market: MarketBoardItem;
  object?: ObjectItem;
  order?: OrderItem;
  shortDescription?: string;
  nameObject?: string;
  idObjectGroup?: number;
}

export interface OperationItem {
  idOperation: number;
  timeOperation: Date;
  extOperNo: string;
  numEDocument: string;
  idObject: number;
  idMarketBoard: number;
  idOperationType: OperationType;
  buySell: OrderDirection;
  quantity: number;
  price: number;
  value: number;
  idObjectCurrency: number;
  yield: number;
  idSubAccount: number;
  idAccount: number;
  description: string;
  idOperationStatus: OperationStatus;
  settleDate: Date;
  deliveryDate: Date;
  repoTerm: number;
  repoDate: Date;
  idRazdel: number;
  accruedInt: number;
  version: string;
  operation: Operation;
  idOperationGroup: OperationGroup;
}

export type OperationCommonItem =
  | OperationRow
  | OperationHistoryItem
  | MoneyHistoryItem;

export const OperationStatusMap = new Map<OperationStatus, string>([
  [OperationStatus.New, 'Новая'],
  [OperationStatus.Clearing, 'Расчеты'],
  [OperationStatus.Repo, 'Выкуп'],
  [OperationStatus.Settled, 'Закрыта'],
  [OperationStatus.Cancelled, 'Отмена'],
  [OperationStatus.Partial, 'Частично'],
  [OperationStatus.Delivered, 'Поставка'],
  [OperationStatus.Payed, 'Аванс'],
  [OperationStatus.Default, 'Дефолт'],
]);

export const OperationTypeMap = new Map<OperationType, string>([
  [OperationType.XZ, ''],
  [OperationType.MON, 'Списание/зачисление денежных средств'],
  [OperationType.DEP, 'Депо списание/зачисление'],
  [OperationType.TRD, 'Сделка купли-продажи'],
  [OperationType.R1, '1-я часть РЕПО'],
  [OperationType.S1, '1-я часть сделки СВОП'],
  [OperationType.RS, 'Нога в составе  сделки СВОП, РЕПО'],
  [OperationType.BS, 'Нога в составе  сделки BSKT'],
  [OperationType.REPO, 'Сделка купли-продажи с обратным выкупом  РЕПО'],
  [OperationType.SWAP, 'Сделка СВОП'],
  [OperationType.BSKT, 'Сделка с корзиной активов'],
  [OperationType.RPS, 'Сделка системная: биржевая с контрагентом'],
  [OperationType.MON_EXTRA, 'Списание/зачисление  денежных средств'],
  [OperationType.MON_INTRA, 'Списание/зачисление  денежных средств'],
  [OperationType.MON_PAYMENT, 'Списание/зачисление  денежных средств'],
  [OperationType.DEP_EXTRA, 'Депо списание/зачисление'],
  [OperationType.DEP_INTRA, 'Депо списание/зачисление'],
  [OperationType.R2, '2-я часть РЕПО'],
  [OperationType.MON_VM, 'Платежи ВМ'],
  [OperationType.MON_EXTRA_DIV, 'Зачисление дивидендов на счет'],
  [
    OperationType.MON_EXTRA_COUPON_RU,
    'Зачисление купонов российских эмитентов на счет',
  ],
  [
    OperationType.MON_EXTRA_COUPON_FOREIGN,
    'Зачисление купонов иностранных эмитентов на счет',
  ],
]);

export enum OperationHistoryType {
  E = 'E',
  D = 'D',
  S = 'S',
  G = 'G',
  CT = 'CT',
  CS = 'CS',
  CP = 'CP',
  CE = 'CE',
  BE = 'BE',
  DE = 'DE',
  DP = 'DP',
  TP = 'TP',
  IE = 'IE',
  OE = 'OE',
  /**
   * Вариационная маржа
   */
  VP = 'VP',
  /**
   * Исполнение фьючерса
   */
  X = 'X',
  /**
   * Перевод между своими счетами
   */
  AI = 'AI',
  /**
   * Перевод между своими счетами (с РЦБ на ФОРТС)
   */
  PI = 'PI',
}

export enum OperationInstrumentType {
  STOCK = 'stock',
  GIFT_STOCK = 'giftStock',
  BOND = 'bond',
  FUND = 'fundShare',
  FUTURES = 'futures',
  OPTION = 'option',
  DEPOSITARY_RECEIPT = 'depositaryReceipt',
  CURRENCY = 'currency',
  REPURCHASE_AGREEMENT = 'repurchaseAgreement',
  SWAP = 'swap',
  OTHER = 'other',
}

// http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=976080404
export const OperationHistoryTypeNameMap = new Map<string, string>([
  [OperationHistoryType.E, 'Биржевая сделка'],
  [OperationHistoryType.D, 'Переговорная сделка'],
  [OperationHistoryType.S, 'Малые лоты'],
  [OperationHistoryType.CT, 'Комиссия брокера за сделку'],
  [OperationHistoryType.CS, 'Комиссия брокера за сделку'],
  [OperationHistoryType.CP, 'Комиссия брокера за сделку'],
  [OperationHistoryType.DE, 'Выплата дивидендов по акции'],
  [OperationHistoryType.DP, 'Компенсация дивидендов по акции'],
  [OperationHistoryType.IE, 'Пополнение брокерского счёта'],
  [OperationHistoryType.OE, 'Вывод с брокерского счёта'],
  // BE,  operations[].subtype = full_repayment Погашение облигаций
  // BE,  operations[].subtype = part_repayment	Частичное погашение облигаций
  // CP по subType
  // BR_FEE_FORTS Комиссия за сделки на срочном рынке
  // BR_FEE_MCX_SHR Комиссия за сделки на фондовом рынке
  // BR_FEE_SELT Комиссия за сделки на валютном рынке
  // BR_FEE_SPB_FSHR Комиссия за расчеты на фондовом рынке
  // BR_FEE_SPB_MICEX Комиссия за расчеты на фондовом рынке
  // DEPO_OPER Комиссия за депозитарные услуги
  // DEPO_REPAY Комиссия за депозитарные услуги
  // DEPO_TRAN_FEE Комиссия за депозитарные услуги
  // EXECUTE_SELT_FEE Комиссия за расчеты на валютном рынке
  // FIX_FEE Косиссия брокера
  // FORTS_REG Комиссия за сделки на срочном рынке
  // FORTS_TS_FEE Комиссия за сделки на срочном рынке
  // MARGE_CLOSE Комиссия брокера
  // MARGE_CLOSE_SELT Комиссия брокера
  // ORDERS_SELT_FEE Комиссия за сделки на валютном рынке
  // PAY_MICEX Комиссия брокера
  // PAY_PRIME Комиссия брокера
  // PORTF_FEE Комиссия брокера
  // VOICE_ORDER Комиссия брокера
  // VOICE_ORDER_SELT Комиссия брокера
]);

export interface OperationHistoryResult {
  id: number;
  type: OperationHistoryType;
  date: string;
  symbolObject?: string;
  objectId?: number;
  isin?: string;
  marketId?: string;
  placeCode?: string;
  name?: string;
  issuerId?: number;
  direction?: OrderDirection;
  instrumentsQuantity?: number;
  totalValue?: {
    amount: number;
    unit: string;
  };
  instrumentValue?: {
    amount: number;
    unit: string;
  };
  settlementDate?: string;
  instrumentType?: string;
  orderId?: string;
  settled?: 'Y' | 'N';
}

export interface OperationHistoryItem {
  market?: MarketBoardItem;
  object?: ObjectItem;
  operationDate: string;
  operation: {
    buySell: OrderDirection;
    idOperation: number;
    quantity: number;
    value: number;
    price: number;
    timeOperation: Date;
    settleDate?: Date;
  } & OperationHistoryResult;
  type: string;
}

export enum DateFilterType {
  NONE = 0,
  TODAY = 1,
  YESTERDAY = 2,
  '5DAYS' = 3,
  '30DAYS' = 4,
  'RANGE' = 5,
  'WEEK' = 6,
  'YEAR' = 7,
}

export const FiltersNameMap = new Map<number, string>([
  [DateFilterType.NONE, 'Все операции'],
  [DateFilterType.TODAY, 'Сегодня'],
  [DateFilterType.YESTERDAY, 'Вчера'],
  [DateFilterType['5DAYS'], '5 дней'],
  [DateFilterType['30DAYS'], 'Месяц'],
  [DateFilterType.RANGE, 'Выбрать период'],
  [DateFilterType.WEEK, 'Неделя'],
  [DateFilterType.YEAR, 'Год'],
]);

export const dateFilters = [
  DateFilterType.TODAY,
  DateFilterType.YESTERDAY,
  DateFilterType['5DAYS'],
  DateFilterType['30DAYS'],
];

export type DateFilterValue = {
  type: DateFilterType;
  valueFrom?: DateFilterState;
  valueTo?: DateFilterState;
};

export type DateFilterState = {
  value?: string;
  date?: number;
};
