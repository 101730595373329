import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getStartTestSession } from '@terminal/core/lib/rest/lkTesting';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../queryKeys';

export const useTestStartSession = (testId: number) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.StartTestSession],
    async () => getStartTestSession(testId),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка начала сессии тестирования',
          text: error?.message || '',
        });
      },
    }
  );
};
