import { useEffect, useState } from 'react';
import { z, ZodObject, ZodRawShape } from 'zod';

import { FeatureFlags } from './featureFlags';

export const makeFeatureFlagConsumer =
  <
    S extends ZodObject<ZodRawShape>,
    T extends Partial<z.infer<S>>,
    N extends keyof T
  >(
    featureFlags: FeatureFlags<S, T>
  ) =>
  <U extends N>(name: U) => {
    const [state, setState] = useState<T[U]>(() => featureFlags.get(name));

    useEffect(() => {
      const callback = (value: T[U]) => setState(value);

      featureFlags.on(name, callback);

      return () => {
        featureFlags.off(name, callback);
      };
    }, [name]);

    return state;
  };
