import { cloneElement, memo, useCallback, useRef, useState } from 'react';
import { Badge } from '@alfalab/core-components/badge';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { AddCircleMIcon } from '@alfalab/icons-glyph/AddCircleMIcon';
import { BellMIcon } from '@alfalab/icons-glyph/BellMIcon';
import { BookMIcon } from '@alfalab/icons-glyph/BookMIcon';
import { CrownMIcon } from '@alfalab/icons-glyph/CrownMIcon';
import { DocumentArrowRightMIcon } from '@alfalab/icons-glyph/DocumentArrowRightMIcon';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';
import { InformationCircleMIcon } from '@alfalab/icons-glyph/InformationCircleMIcon';
import { LogOutMIcon } from '@alfalab/icons-glyph/LogOutMIcon';
import { QuestionCircleMIcon } from '@alfalab/icons-glyph/QuestionCircleMIcon';
import { SettingsMIcon } from '@alfalab/icons-glyph/SettingsMIcon';
import { TileBentoMIcon } from '@alfalab/icons-glyph/TileBentoMIcon';
import { TransferOfDebtMIcon } from '@alfalab/icons-glyph/TransferOfDebtMIcon';
import { UserMIcon } from '@alfalab/icons-glyph/UserMIcon';
import { WalletMIcon } from '@alfalab/icons-glyph/WalletMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import { LOCATIONS } from '@terminal/core/constants/analytics';
import { MarginCallIcon } from '@terminal/core/constants/icons';
import { ENABLE_LK_FEATURES, IS_AI } from '@terminal/core/env';
import { useOnClickOutside } from '@terminal/core/hooks';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import {
  trackClickOnMenu,
  trackClickOnMenuManuelLK,
  trackMainMenu,
  trackOnAddWidget,
  trackOnMoneyTransfer,
} from '@terminal/core/lib/analytics';
import { shallow, useStore } from '@terminal/core/store';
import { ObjectItem } from '@terminal/core/types/core';
import { Widget } from '@terminal/core/types/layout';
import { HeaderMenuItem } from '@terminal/core/types/ui';
import { UserCredentials } from '@terminal/core/types/user';
import { useLogoutAction } from '@terminal/desktop/features/Auth';
import {
  EDUCATION_FEATURE_TOGGLE_KEY,
  EDUCATION_MODAL_DRAWER_ID,
  INSTRUCTION_FEATURE_TOGGLE_KEY,
  MANUAL_MODAL_DRAWER_ID,
} from '@terminal/lk/entities/Education';
import { isFeatureHidden, useFeatureSettings } from '@terminal/lk/shared';
import { Profile } from '@terminal/lk/widgets/Profile';
import {
  TECH_MESSAGE_CODE,
  useAlfaDirectContext,
  useWidgetContext,
  WidgetServiceNotification,
} from '@terminal/widgets';

import { Clock } from '../../../../shared/ui/Clock';
import { PickerButton } from '../../../../shared/ui/PickerButton';
import { WidgetPickerDropdownMenu } from '../../../../shared/ui/WidgetPickerDropdownMenu';
import { CommonSettingsModal } from '../../../CommonSettings/ui/CommonSettingsModal';
import { About } from '../About';
import { ChatButton } from '../ChatButton';
import { FeedbackModal } from '../FeedbackModal';
import { Status } from '../Status';
import { TabsRow } from '../TabsRow';
import { HelpButton } from './HelpButton';

import { CommonSettingMenuType } from '../../../CommonSettings/model/types';

import styles from './Header.module.css';
import helpButtonStyles from './HelpButton/HelpButton.module.css';

interface HeaderProps {
  user: UserCredentials;
  onAddFromMenu: (key: Widget) => void;
}

export const Header = memo(({ user, onAddFromMenu }: HeaderProps) => {
  const {
    textLogo,
    menuIcon,
    withLk,
    moneyTransfer,
    addAccount: addAccountWidget,
    securities,
    lkMain,
    Contest,
    contestWelcomeBanner,
    manuals,
    education,
    brokerLink,
    headerLeftAddons,
    helpButtonWithPopover,
    educationDocumentLink,
  } = useAppContext();
  const { openTopUpModal } = useWidgetContext();
  const [openMenu, setOpenMenu] = useState(false);
  const [defaultSelectedMenuType, setDefaultSelectedMenuType] =
    useState<CommonSettingMenuType | null>(null);

  const { getInstrumentIconURL: getInstrumentIcon, useInstrumentIcons } =
    useWidgetContext();
  const { objectsTable: objects } = useAlfaDirectContext();

  const instrumentIcons = useInstrumentIcons();

  const getInstrumentIconURL = useCallback(
    (object?: ObjectItem) => {
      if (object && object.idObject) {
        const fullObject = objects.get('idObject', object.idObject);

        return getInstrumentIcon(instrumentIcons, objects, fullObject);
      }
    },
    [getInstrumentIcon, instrumentIcons, objects]
  );

  const logoRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => setOpenMenu(false));

  const [
    headerMenu,
    headerMenuParams,
    setHeaderMenuOpen,
    setMarginCallAccount,
    headerMenuBadge,
  ] = useStore(
    (state) => [
      state.headerMenu,
      state.headerMenuParams,
      state.setHeaderMenuOpen,
      state.setMarginCallAccount,
      state.headerMenuBadge,
    ],
    shallow
  );

  const { data: featureSettings } = useFeatureSettings();
  const educationOff = isFeatureHidden(
    EDUCATION_FEATURE_TOGGLE_KEY,
    featureSettings
  );
  const manualOff = isFeatureHidden(
    INSTRUCTION_FEATURE_TOGGLE_KEY,
    featureSettings
  );

  const onMenuClose = useCallback(() => {
    setDefaultSelectedMenuType(null);
    setHeaderMenuOpen(null);
  }, [setHeaderMenuOpen]);

  const logoutAction = useLogoutAction();

  const onNewAccountModalClose = () => {
    setHeaderMenuOpen(null);
  };

  const handleGoTopUp = () => {
    trackOnMoneyTransfer();
    openTopUpModal();
  };

  return (
    <div className={styles.header}>
      <BaseModal
        open={headerMenu === HeaderMenuItem.PROFILE_SETTINS}
        onClose={onMenuClose}
        Backdrop={() => null}
      >
        <Profile onClose={onMenuClose} />
      </BaseModal>
      <BaseModal
        open={headerMenu === HeaderMenuItem.FEEDBACK}
        onClose={onMenuClose}
        Backdrop={() => null}
      >
        <FeedbackModal onClose={onMenuClose} />
      </BaseModal>
      <BaseModal
        open={headerMenu === HeaderMenuItem.COMMON_SETTINGS}
        onClose={onMenuClose}
        Backdrop={() => null}
      >
        <CommonSettingsModal
          defaultSelectedMenuType={defaultSelectedMenuType}
          onClose={onMenuClose}
        />
      </BaseModal>
      <BaseModal
        open={headerMenu === HeaderMenuItem.ABOUT}
        onClose={onMenuClose}
        Backdrop={() => null}
      >
        <About brokerLink={brokerLink} onClose={onMenuClose} />
      </BaseModal>
      {ENABLE_LK_FEATURES && withLk && (
        <>
          {lkMain && (
            <BaseModal
              open={headerMenu === HeaderMenuItem.MAIN_LK}
              onClose={onMenuClose}
              Backdrop={() => null}
              // keepMounted - чтобы срабатывал запрос количества уведомлений внутри
              keepMounted
            >
              {lkMain}
            </BaseModal>
          )}

          {addAccountWidget && (
            <BaseModal
              open={headerMenu === HeaderMenuItem.NEW_ACCOUNT}
              onClose={onMenuClose}
              Backdrop={() => null}
            >
              <FunctionalModal
                title="Открыть новый счёт"
                width={536}
                onClose={onNewAccountModalClose}
                drawerId="add-account-modals"
              >
                {addAccountWidget}
              </FunctionalModal>
            </BaseModal>
          )}
          <BaseModal
            open={headerMenu === HeaderMenuItem.MONEY_TRANSFER}
            onClose={() => {
              setMarginCallAccount(null);
              onMenuClose();
            }}
            Backdrop={() => null}
          >
            <FunctionalModal
              title="Денежные переводы"
              onClose={onMenuClose}
              drawerId="money-transfer-modals"
            >
              <WidgetServiceNotification
                variant="banner"
                containerClassName={styles.serviceContainer}
                code={TECH_MESSAGE_CODE.MONEY_TRANSFERS}
              />
              {moneyTransfer
                ? cloneElement(moneyTransfer, {
                    defaultSelectedAccountId:
                      headerMenuParams?.defaultSelectedAccountId,
                    defaultTopUpSum: headerMenuParams?.topUpSum,
                  })
                : null}
            </FunctionalModal>
          </BaseModal>

          {education?.widget && (
            <BaseModal
              open={headerMenu === HeaderMenuItem.EDUCATION}
              onClose={onMenuClose}
              Backdrop={() => null}
            >
              <FunctionalModal
                title="Методы торговли Go Invest"
                width={520}
                paddingSize="xl"
                onClose={onMenuClose}
                drawerId={EDUCATION_MODAL_DRAWER_ID}
              >
                {education?.widget}
              </FunctionalModal>
            </BaseModal>
          )}
          {manuals?.widget && (
            <BaseModal
              open={headerMenu === HeaderMenuItem.MANUAL}
              onClose={onMenuClose}
              Backdrop={() => null}
            >
              <FunctionalModal
                title="Руководство по использованию веб-терминала"
                width={520}
                paddingSize="xl"
                onClose={onMenuClose}
                drawerId={MANUAL_MODAL_DRAWER_ID}
              >
                {manuals.widget}
              </FunctionalModal>
            </BaseModal>
          )}

          {securities && (
            <BaseModal
              open={headerMenu === HeaderMenuItem.SECURITIES}
              onClose={onMenuClose}
              Backdrop={() => null}
            >
              <FunctionalModal
                title="Переводы ценных бумаг"
                onClose={onMenuClose}
                drawerId="securities-transfer-modals"
              >
                {securities}
              </FunctionalModal>
            </BaseModal>
          )}

          {Contest && (
            <BaseModal
              open={headerMenu === HeaderMenuItem.CONTEST}
              onClose={onMenuClose}
              Backdrop={() => null}
            >
              <Contest getInstrumentIconURL={getInstrumentIconURL} />
            </BaseModal>
          )}
        </>
      )}
      <div className={styles.mainPart}>
        <div
          ref={logoRef}
          style={{ display: 'flex', userSelect: 'none' }}
          onClick={() => {
            trackClickOnMenu();
            setOpenMenu((prev) => !prev);
          }}
          className={styles.logoIcon}
        >
          {headerMenuBadge.length > 0 && (
            <Badge
              view="count"
              className={styles.logoBadge}
              visibleIconOutline
            />
          )}
          {menuIcon}
          <Dropdown
            headerDivider={false}
            ref={dropdownRef}
            anchorElement={logoRef.current}
            open={openMenu}
            offset={[0, 4]}
            position="bottom-start"
            header={<div className={styles.menuTitleWrapper}>{textLogo}</div>}
          >
            <Dropdown.Item
              align="start"
              content={
                <div className={styles.menuItem}>
                  {user?.fullName}&nbsp;
                  <Typography.Text
                    view="secondary-small"
                    tag="span"
                    className={styles.menuDescription}
                  >
                    {user?.login}
                  </Typography.Text>
                  <Typography.Text
                    view="secondary-small"
                    tag="div"
                    className={styles.menuDescription}
                  >
                    Настройки профиля
                  </Typography.Text>
                </div>
              }
              leftIcon={
                <UserMIcon
                  height={18}
                  width={18}
                  color="var(--color-light-graphic-secondary)"
                />
              }
              onClick={() => {
                trackMainMenu(LOCATIONS.PROFILE_SETTINGS);
                setHeaderMenuOpen(HeaderMenuItem.PROFILE_SETTINS);
              }}
            />
            {ENABLE_LK_FEATURES && (
              <>
                {withLk ? (
                  <>
                    {addAccountWidget && (
                      <Dropdown.Item
                        align="start"
                        content="Новый счёт"
                        leftIcon={
                          <AddCircleMIcon
                            height={18}
                            width={18}
                            color="var(--color-light-graphic-secondary)"
                          />
                        }
                        onClick={() => {
                          trackMainMenu(LOCATIONS.NEW_ACCOUNT);
                          setHeaderMenuOpen(HeaderMenuItem.NEW_ACCOUNT);
                        }}
                      />
                    )}
                    <Dropdown.Item
                      align="start"
                      content="Денежные переводы"
                      leftIcon={
                        <TransferOfDebtMIcon
                          height={18}
                          width={18}
                          color="var(--color-light-graphic-secondary)"
                        />
                      }
                      onClick={() => {
                        trackMainMenu(LOCATIONS.MONEY_TRANSFER);
                        setHeaderMenuOpen(HeaderMenuItem.MONEY_TRANSFER);
                      }}
                    />
                    {securities && (
                      <Dropdown.Item
                        align="start"
                        content="Переводы ценных бумаг"
                        leftIcon={
                          <DocumentArrowRightMIcon
                            height={18}
                            width={18}
                            color="var(--color-light-graphic-secondary)"
                          />
                        }
                        onClick={() =>
                          setHeaderMenuOpen(HeaderMenuItem.SECURITIES)
                        }
                      />
                    )}
                  </>
                ) : null}
                <Dropdown.Item
                  align="start"
                  content="Маржинальная торговля"
                  leftIcon={
                    <MarginCallIcon
                      height={18}
                      width={18}
                      color="var(--color-light-graphic-secondary)"
                    />
                  }
                  onClick={() => setHeaderMenuOpen(HeaderMenuItem.MARGIN_CALL)}
                />
                {lkMain && (
                  <Dropdown.Item
                    align="start"
                    content={
                      <div className={styles.menuItem}>
                        Личный кабинет
                        {headerMenuBadge.includes(HeaderMenuItem.MAIN_LK) ? (
                          <Badge
                            view="count"
                            className={styles.menuItemBabge}
                          />
                        ) : null}
                        <Typography.Text
                          view="secondary-small"
                          tag="div"
                          className={styles.menuDescription}
                        >
                          Операции со счетами, документы <br />
                          и&nbsp;налоги, сертификаты, поручения <br />
                          и&nbsp;тестирование
                        </Typography.Text>
                      </div>
                    }
                    leftIcon={
                      <DocumentLinesMIcon
                        height={18}
                        width={18}
                        color="var(--color-light-graphic-secondary)"
                      />
                    }
                    onClick={() => setHeaderMenuOpen(HeaderMenuItem.MAIN_LK)}
                  />
                )}
                {withLk && Contest && (
                  <Dropdown.Item
                    align="start"
                    content="Альфа турнир"
                    leftIcon={
                      <CrownMIcon
                        height={18}
                        width={18}
                        color="var(--color-light-graphic-secondary)"
                      />
                    }
                    onClick={() => {
                      trackMainMenu(LOCATIONS.CONTEST);
                      setHeaderMenuOpen(HeaderMenuItem.CONTEST);
                    }}
                  />
                )}
                {withLk && education?.widget && !educationOff && (
                  <Dropdown.Item
                    align="start"
                    content={
                      <div className={styles.menuItem}>
                        Методы торговли Go Invest
                        <Typography.Text
                          view="secondary-small"
                          tag="div"
                          className={styles.menuDescription}
                        >
                          Узнайте, как эффективно использовать веб-терминал,
                          чтобы зарабатывать на&nbsp;рынке
                        </Typography.Text>
                      </div>
                    }
                    leftIcon={
                      <BookMIcon
                        height={18}
                        width={18}
                        color="var(--color-light-graphic-secondary)"
                      />
                    }
                    onClick={() => setHeaderMenuOpen(HeaderMenuItem.EDUCATION)}
                  />
                )}
                {withLk && manuals?.widget && !manualOff && (
                  <Dropdown.Item
                    align="start"
                    content={
                      <div className={styles.menuItem}>
                        Руководство по использованию веб-терминала
                      </div>
                    }
                    leftIcon={
                      <QuestionCircleMIcon
                        height={18}
                        width={18}
                        color="var(--color-light-graphic-secondary)"
                      />
                    }
                    onClick={() => {
                      trackClickOnMenuManuelLK();
                      setHeaderMenuOpen(HeaderMenuItem.MANUAL);
                    }}
                  />
                )}
              </>
            )}
            <Dropdown.Item
              align="start"
              content="Нотификации"
              leftIcon={
                <BellMIcon
                  height={18}
                  width={18}
                  color="var(--color-light-graphic-secondary)"
                />
              }
              onClick={() => {
                setHeaderMenuOpen(HeaderMenuItem.COMMON_SETTINGS);
                setDefaultSelectedMenuType(CommonSettingMenuType.Notifications);
              }}
            />
            <Dropdown.Item
              align="start"
              content="Общие настройки"
              leftIcon={
                <SettingsMIcon
                  height={18}
                  width={18}
                  color="var(--color-light-graphic-secondary)"
                />
              }
              onClick={() => setHeaderMenuOpen(HeaderMenuItem.COMMON_SETTINGS)}
            />
            <Dropdown.Item
              withGap={4}
              align="start"
              content="О приложении"
              leftIcon={
                <InformationCircleMIcon
                  height={18}
                  width={18}
                  color="var(--color-light-graphic-secondary)"
                />
              }
              onClick={() => setHeaderMenuOpen(HeaderMenuItem.ABOUT)}
            />
            <Dropdown.Item
              align="start"
              content="Выйти"
              leftIcon={
                <LogOutMIcon
                  height={18}
                  width={18}
                  color="var(--color-light-graphic-secondary)"
                />
              }
              onClick={() => {
                logoutAction();
                setOpenMenu(false);
              }}
            />
          </Dropdown>
        </div>
        {headerLeftAddons}
        <div className={styles.clockWrapper}>
          <Typography.Text view="secondary-small">
            <Clock />
          </Typography.Text>
          <div className={styles.clockWrapperItem}>
            <Status />
            <WidgetServiceNotification
              containerClassName={styles.notificationIconContainer}
              variant="icon"
              code={[
                TECH_MESSAGE_CODE.MAIN,
                TECH_MESSAGE_CODE.TRADE,
                TECH_MESSAGE_CODE.POSITIONS_BALANCES,
              ]}
            />
          </div>
          {IS_AI && (
            <WidgetServiceNotification
              variant="modal"
              code={TECH_MESSAGE_CODE.MAIN}
            />
          )}
        </div>
        <TabsRow />
      </div>
      <div className={styles.rightPart}>
        <div className={styles.rightButtons}>
          <WidgetPickerDropdownMenu
            onPick={(widget) => {
              trackOnAddWidget();
              onAddFromMenu(widget);
            }}
          >
            {(ref, isOpen) => (
              <PickerButton ref={ref} isOpen={isOpen} Icon={TileBentoMIcon}>
                Виджеты
              </PickerButton>
            )}
          </WidgetPickerDropdownMenu>
          <ButtonDesktop
            size="xs"
            leftAddons={<WalletMIcon width={16} height={16} />}
            onClick={handleGoTopUp}
            className={styles.topUpButton}
          >
            Пополнить
          </ButtonDesktop>
        </div>
        <div className={styles.rightIcons}>
          <TooltipDesktop
            position="bottom-start"
            contentClassName={helpButtonStyles.tooltipContent}
            popoverClassName={helpButtonStyles.tooltip}
            arrowClassName={helpButtonStyles.tooltipArrow}
            content={
              <Typography.Text view="secondary-small">
                Поддержка
              </Typography.Text>
            }
          >
            <ChatButton />
          </TooltipDesktop>
          <HelpButton
            educationDocumentLink={educationDocumentLink}
            hasPopover={helpButtonWithPopover}
            isManualAllowed={!manualOff}
            contestWelcomeBanner={contestWelcomeBanner}
          />
        </div>
      </div>
    </div>
  );
});
