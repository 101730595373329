import {
  ClientMessageEntity,
  Messages,
  MessageUnitedType,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import AdirClientService from '../client/service';

// orderNum это некий уникальный id в рамках твоей сессии
// когда мы подключаемся на фе2 то тот присылает clientMessage с orderNum который тебе положен
// дальше это число юзается для разных вещей при работе с фе2
// после использования orderNum увеличивается на еденицу
export class OrderNumService extends AdirClientService {
  orderNum: number | undefined = undefined;

  override init() {
    this.addClientListener(
      EntityType.ClientMessageEntity,
      this.onClientMessage
    );
  }

  private onClientMessage = (message: MessageUnitedType) => {
    const messages = message.data as ClientMessageEntity[];
    messages.forEach((m) => {
      if (m.MessageId === Messages.LoginLastExtNumValue) {
        // Ну штош, order num к нам приходит в МАССИВЕ ВАРИАНТОВ
        // потому что у ClientMessage в Objects именно такая вот хрень
        // Я прямо ощущаю что это программировали какими-то дикими костылями
        if (Array.isArray(m.Objects) && m.Objects.length > 0) {
          this.orderNum = m.Objects[0];
        }
      }
    });
  };

  public nextOrderNum() {
    if (this.orderNum === undefined) {
      throw new Error('Ordernum is not initialized');
    }

    this.orderNum += 1;
    return this.orderNum;
  }
}

const OrderNum = new OrderNumService();
export { OrderNum };
