import { useEffect } from 'react';
import shallow from 'zustand/shallow';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';
import { InternetMIcon } from '@alfalab/icons-glyph/InternetMIcon';

import { Spinner } from '@terminal/common/components/Spinner';
import { useEventListener, usePrevious } from '@terminal/core/hooks';

import { SupportService } from '../api/chatService';
import { ErrorStub } from '../components/ErrorStub';
import { MessagesList } from '../components/MessagesList';
import { ChatRoomLayout } from '../components/rooms/ChatRoomLayout';

import { useSeparateSupportUnreadMessages } from '../hooks/useSeparateSupportUnreadMessages';

import { useStore } from '../store/useStore';

import { ChatGroupId, ESupportConnectionState } from '../types';

import styles from './ChatScreen.module.css';

const getRoomStateText = (state: ESupportConnectionState) => {
  switch (state) {
    case ESupportConnectionState.UPDATING:
      return 'Обновление...';
    case ESupportConnectionState.OFFLINE:
      return 'Нет подключения';
    case ESupportConnectionState.ERROR:
      return (
        <>
          <span className={styles.errorBadge}>●</span>Не удалось подключиться
        </>
      );
    case ESupportConnectionState.OK:
      return (
        <>
          <span className={styles.onlineBadge}>●</span>На связи
        </>
      );
    case ESupportConnectionState.IDLE:
    case ESupportConnectionState.CONNECTING:
    default:
      return 'Подключение...';
  }
};

interface Props {
  mobile?: boolean;
  onBack: () => void;
}

export const Support = ({ mobile = false, onBack }: Props) => {
  const [
    supportConnectionState,
    setSupportConnectionState,
    connectToChat,
    disconnectFromChat,
    setUnreadMsgCount,
    allMessagesLoad,
    messagesLoading,
    historyError,
    messages,
    getChatHistory,
  ] = useStore(
    (store) => [
      store.supportConnectionState,
      store.setSupportConnectionState,
      store.connectToChat,
      store.disconnectFromChat,
      store.setUnreadMsgCount,
      store.allMessagesLoad,
      store.messagesLoading,
      store.historyError,
      store.messages[ChatGroupId.TECH_SUPPORT],
      store.getChatHistory,
    ],
    shallow
  );

  const { readMessages, unreadMessages } =
    useSeparateSupportUnreadMessages(messages);

  const prevConnectionState = usePrevious(supportConnectionState);

  useEffect(
    () => SupportService.subscribeToUnreadMsgCount(setUnreadMsgCount),
    [setUnreadMsgCount]
  );

  useEffect(() => {
    connectToChat();

    return disconnectFromChat;
  }, [connectToChat, disconnectFromChat]);

  useEffect(() => {
    if (!window.navigator.onLine)
      setSupportConnectionState(ESupportConnectionState.OFFLINE);
  }, [setSupportConnectionState]);

  useEventListener('offline', () => {
    setSupportConnectionState(ESupportConnectionState.ERROR);
  });

  const renderMain = () => {
    switch (true) {
      case supportConnectionState === ESupportConnectionState.OK:
        return (
          <MessagesList
            mobile={mobile}
            allLoaded={allMessagesLoad}
            loading={messagesLoading}
            moreError={historyError}
            loadMore={getChatHistory}
            readMessages={readMessages}
            unreadMessages={unreadMessages}
          />
        );
      case supportConnectionState === ESupportConnectionState.ERROR:
      case supportConnectionState === ESupportConnectionState.CONNECTING &&
        prevConnectionState === ESupportConnectionState.ERROR:
        return (
          <div className={styles.centeringContainer}>
            <ErrorStub
              title="Ошибка загрузки"
              description="Не удалось загрузить данные"
              icon={ExclamationMIcon}
              loading={
                supportConnectionState === ESupportConnectionState.CONNECTING
              }
              onRetry={() => connectToChat()}
            />
          </div>
        );
      case supportConnectionState === ESupportConnectionState.OFFLINE:
        return (
          <div className={styles.centeringContainer}>
            <ErrorStub
              title="Нет подключения к сети"
              description="Проверьте ваш доступ к интернету"
              icon={InternetMIcon}
              loading={
                supportConnectionState === ESupportConnectionState.CONNECTING
              }
              onRetry={() => connectToChat()}
            />
          </div>
        );
      default:
        return (
          <div className={styles.centeringContainer}>
            <Spinner size="36px" color="#8D8D93" />
          </div>
        );
    }
  };

  return (
    <ChatRoomLayout
      mobile={mobile}
      chatRoomTitle="Клиентский сервис"
      chatRoomConnection={getRoomStateText(supportConnectionState)}
      sendMessage={() => Promise.resolve()}
      onBack={onBack}
    >
      {renderMain()}
    </ChatRoomLayout>
  );
};
