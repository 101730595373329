import { Typography } from '@alfalab/core-components/typography';

import { isThemePassed } from '../../lib/isThemePassed';

interface ThemePassPercentProps {
  percent: number;
  className?: string;
}

export const ThemePassPercent = ({
  percent,
  className,
}: ThemePassPercentProps) => {
  const pass = isThemePassed(percent);
  const color = pass ? 'positive' : 'negative';

  return (
    <Typography.Text
      className={className}
      view="secondary-small"
      color={color}
      weight="bold"
    >
      {Math.round(percent)}%
    </Typography.Text>
  );
};
