import cn from 'classnames';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { plural } from '@terminal/core/lib/plural';
import { BaseWidgetConfig } from '@terminal/core/types/layout';

import { useWidgetContext } from '../../shared';
import { AccountDropdown } from '../AccountDropdown';

import { isOwnSubAccount, useAccountFilter } from './hooks/useAccountFilter';

import styles from './AccountFilters.module.css';

const minFiltersWidth = 400;

interface Props {
  selectedSubAccounts: string[];
  updateNode?: (
    nodeId: string,
    config: BaseWidgetConfig,
    newName?: string | undefined
  ) => void;
  nodeId?: string;
  isOrderBookVersion?: boolean;
  classNames?: Partial<{
    wrapper: string;
    container: string;
    selectButton: string;
  }>;
  onOptionSelect?: (accounts: string[]) => void;
  closeOnSelect?: boolean;
}

export const AccountFilters = ({
  selectedSubAccounts,
  updateNode,
  nodeId,
  isOrderBookVersion,
  classNames,
  onOptionSelect,
  closeOnSelect,
}: Props) => {
  const { getNode } = useWidgetContext();
  const { subAccountRazdels } = useAlfaDirectContext();
  const subAccounts = useMemo(() => {
    // Счета приходят не сразу, так что фильтруем undefined
    const filteredRazdels = subAccountRazdels.filter((item) => item);

    return Object.keys(groupBy(filteredRazdels, 'codeSubAccount'));
  }, [subAccountRazdels]);

  const isEverySubAccountSelected =
    selectedSubAccounts.length === subAccounts.length;
  // Если у пользователя один субсчет или нет субсчетов, то не показываем селект выбора счета
  const isShouldBeVisible = !isEmpty(subAccounts);
  const accountsDeclension = plural(
    ['счет', 'счета', 'счетов'],
    selectedSubAccounts.length
  );

  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [searchText, setSearchText] = useState('');

  const nodeWidth = getNode(nodeId)?.getRect().width || 0;
  const hideName = nodeWidth < minFiltersWidth;

  const onSave = (accounts: string[]) => {
    if (!isUndefined(nodeId)) {
      updateNode?.(nodeId, { tableProps: { accounts } });
    }

    if (onOptionSelect) {
      onOptionSelect(accounts);
    }
  };

  useEffect(() => {
    //Если выбран счет, которого нет у клиента, то ставим все по умолчанию
    if (
      selectedSubAccounts.length &&
      !isOwnSubAccount(selectedSubAccounts, subAccounts) &&
      !isUndefined(nodeId)
    ) {
      updateNode?.(nodeId, { tableProps: { accounts: subAccounts } });
    }
  }, [nodeId, selectedSubAccounts, subAccounts, updateNode]);

  const {
    options,
    onSelect,
    onSelectAll,
    hasRequirements,
    selectedAccRequirement,
  } = useAccountFilter({
    isShouldBeVisible,
    isOrderBookVersion,
    selectedSubAccounts,
    searchText,
    onSave,
    subAccounts,
  });

  const getAccount = () => {
    if (isOrderBookVersion) {
      return selectedSubAccounts[0];
    }

    if (isEverySubAccountSelected) {
      return 'Все счета';
    }

    return `${selectedSubAccounts.length} ${accountsDeclension}`;
  };

  return (
    <div className={cn(styles.wrapper, classNames?.wrapper)} ref={anchorRef}>
      {isShouldBeVisible ? (
        <AccountDropdown
          useAnchorWidth
          position="bottom-start"
          hasRequirements={hasRequirements}
          isOrderBookVersion={isOrderBookVersion}
          isEverySubAccountSelected={isEverySubAccountSelected}
          options={options}
          searchText={searchText}
          setSearchText={setSearchText}
          onSelect={onSelect}
          onSelectAllClick={onSelectAll}
        >
          {(ref, isOpen) => (
            <div className={cn(styles.filters, classNames?.container)}>
              <div
                className={cn(
                  styles.accountsSelectButton,
                  classNames?.selectButton,
                  hideName && styles.accountsSelectButtonSmall
                )}
                ref={ref}
                onMouseDown={(e) => e.stopPropagation()}
              >
                {!selectedAccRequirement ? (
                  <BriefcaseMIcon
                    height={16}
                    width={16}
                    color="var(--color-light-graphic-secondary)"
                    className={styles.accountsSelectButtonIcon}
                  />
                ) : (
                  <ExclamationCircleMIcon
                    width={16}
                    height={16}
                    color={
                      selectedAccRequirement === 'immediateRequirements'
                        ? 'var(--color-light-text-negative)'
                        : 'var(--color-light-text-attention)'
                    }
                    className={styles.accountsSelectButtonIcon}
                  />
                )}
                {!hideName && (
                  <Typography.Text
                    view="secondary-large"
                    weight="medium"
                    className={styles.accountName}
                  >
                    {getAccount()}
                  </Typography.Text>
                )}
                <ChevronDownCompactMIcon
                  height={16}
                  width={16}
                  className={cn(styles.chevron, isOpen && styles.chevronUp)}
                />
              </div>
            </div>
          )}
        </AccountDropdown>
      ) : null}
    </div>
  );
};
