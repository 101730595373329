import { RootStore } from '../../useStore';

/**
 * Выбирает настройку отображения наименования инструмента
 * */
export function displayInstrumentTypeSelector(
  store: RootStore
): 'name' | 'symbol' {
  return store.settings.defaultValues.selectedDisplayInstrumentType;
}
