import head from 'lodash/head';
import { useMemo } from 'react';

import { useComputedPositions } from './useComputedPositions';

import { ExtendedFI } from '../../types/extendedFI';
import { SubAccountPositionItem } from '../../types/subAccountPosition';
import { SubAccountRazdelItem } from '../../types/subAccountRazdel';

const SPB_RUB_BOARD_ID = 331;
const SPB_RUB_RAZDEL_TYPE = 32;

export const usePositionInfo = (
  fullFi: ExtendedFI | undefined,
  selectedSubAccountId: number,
  selectedSubAccounts: string[],
  positions: SubAccountPositionItem[],
  subAccountRazdels: SubAccountRazdelItem[]
) => {
  const filteredPositions = useMemo(
    //accounts - счета пользователя, но без Инвесткопилок
    //выбираем позиции которые относятся к текущему счету и субсчету и соответствуют айди фин инструмента
    () => {
      const subaccounts = subAccountRazdels.filter(
        (i) => i.idSubAccount === selectedSubAccountId
      );

      return positions
        .filter(
          (position) =>
            position.idSubAccount === selectedSubAccountId &&
            position.idObject === fullFi?.idObject
        )
        .filter((position) => {
          // тут костыль для того чтобы разделить позиции на SPB_RUB и MOEX
          // нужно будет заменить на фильтр через position.idMarketBoard,
          // когда он появится в SubAccountPositionItem
          if (fullFi?.idMarketBoard === SPB_RUB_BOARD_ID) {
            return subaccounts.find(
              (i) =>
                i.idRazdel === position.idRazdel &&
                i.idRazdelType === SPB_RUB_RAZDEL_TYPE
            );
          }

          return subaccounts.find(
            (i) =>
              i.idRazdel === position.idRazdel &&
              i.idRazdelType !== SPB_RUB_RAZDEL_TYPE
          );
        });
    },
    [fullFi, positions, selectedSubAccountId, subAccountRazdels]
  );

  const calculatedPossitions = useComputedPositions(
    selectedSubAccounts,
    filteredPositions
  );

  const nonZeroFilteredData = useMemo(
    () => calculatedPossitions.filter((position) => position.torgPos !== 0),
    [calculatedPossitions]
  );

  return {
    position: head(nonZeroFilteredData),
  };
};
