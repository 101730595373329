import isUndefined from 'lodash/isUndefined';

import {
  DocumentType,
  ExecutionType,
  LifeTime,
  MarketBoardType,
  OrderStopPriceDirection,
  OrderType,
  PriceControlType,
  PriceType,
  QuantityType,
} from '../client/entities';
import { formatOrderDate, roundFloatNumber } from '../format';

import { AllowedOrderParams } from '../../types/allowedOrderParams';
import { ExtendedFI } from '../../types/extendedFI';
import { NewOrderType, OrderItem } from '../../types/order';
import {
  CommandTypeBase,
  InputCancelOrderTextParams,
  InputOrderTextParams,
  TemplateNumMap,
} from '../../types/trading';

const RestDateTimeFormatDays = 'yyyy';
const RestDateTimeFormat = 'yyyy HH:mm';
const RestDateTimeFormatSeconds = 'yyyy HH:mm:ss';

// Для информации:
// OrderType RPS, TechRPS, INT - адресные сделки. То есть сделки с конкретным лицом
// Чаркин: Эти режимы пока не поддерживаются у нас. Будет во втором квартале реализовано в части выкупов облигация
// Сейчас пока их не нужно поддерживать, только безадресные поручения

const isKillOrder = (
  inputParams: InputOrderTextParams | InputCancelOrderTextParams
): inputParams is InputCancelOrderTextParams =>
  inputParams.command === CommandTypeBase.KILL;

export interface GenerateHeaderProps {
  idOrderType: OrderType;
  login: string;
  nameClient: string;
  fullName: string;
  idAccount: number;
  signTime: Date;
  orderNum: string;
  templateNum: string;
  fullFI?: ExtendedFI;
  idDocumentType?: DocumentType;
}

export class TextOrderService {
  /** Формирование текста документа поручения (новая заявка, удаление и т.д.) */
  generateTextDocument(
    inputParams: InputOrderTextParams | InputCancelOrderTextParams
  ) {
    // Закрытие заявки
    if (isKillOrder(inputParams)) {
      return this.killOrder(inputParams);
    }

    switch (inputParams.command) {
      // Новая заявка
      case CommandTypeBase.NEW:
        switch (inputParams.orderParams.idDocumentType) {
          case DocumentType.TRD:
          case DocumentType.Manual:
          case DocumentType.Subordinate:
            return this.newOrder(inputParams);
          case DocumentType.REP:
            //TODO
            return;

          default:
            return;
        }
      // Редактирование заявки
      case CommandTypeBase.EDIT:
        return this.editOrder(inputParams);
    }
  }

  /** Генерация текста отмены заявки */
  killOrder({
    command,
    clientNumEDocument,
    razdel,
    ...headerProps
  }: InputCancelOrderTextParams) {
    const templateNum = TemplateNumMap.get(command)!;

    if (!clientNumEDocument) {
      throw new Error('У базового ордера отсутствует исходящий номер');
    }

    if (!razdel) {
      throw new Error('Не задан раздел для финансового инструмента');
    }

    const header = this.generateHeader({
      ...headerProps,
      templateNum,
      nameClient: razdel.nameClient,
    });

    return `${header}\r\nНастоящим поручаю и уполномочиваю:\r\nнезамедлительно прекратить исполнение ордера (исходящий номер): ${clientNumEDocument}`;
  }

  /** Генерация текста отмены заявки */
  editOrder({
    command,
    orderParams,
    clientNumEDocument,
    withDrawTime,
    razdel,
    ...headerProps
  }: InputOrderTextParams) {
    const { idOrderType } = orderParams;
    const templateNum = TemplateNumMap.get(command)!;

    if (!clientNumEDocument) {
      throw new Error('У базового ордера отсутствует исходящий номер');
    }

    if (!razdel) {
      throw new Error('Не задан раздел для финансового инструмента');
    }

    const header = this.generateHeader({
      idOrderType,
      templateNum,
      nameClient: razdel.nameClient,
      ...headerProps,
    });

    const lifeTime = generateLifeTime(orderParams, withDrawTime);

    return `${header}\r\nНастоящим поручаю и уполномочиваю:\r\nпродолжать исполнение ордера (исходящий номер): ${clientNumEDocument}\r\nс учетом настоящих инструкций:\r${lifeTime}`;
  }

  /** Генерация текста новой заявки */
  newOrder(inputParams: InputOrderTextParams) {
    const {
      limitPrice,
      stopPrice,
      razdel,
      buy,
      fullFI,
      command,
      idLifeTime,
      orderParams,
      withDrawTime,
      idPriceControlType,
      activationTime,
      limitLevelAlternative,
      linkedOrderId,
      activationPriceDirection,
      idFIActivate,
      fullFIActivate,
      ...headerProps
    } = inputParams;
    const { idOrderType, idDocumentType } = orderParams;

    const templateNum = TemplateNumMap.get(command)!;

    if (!razdel) {
      throw new Error('Не задан раздел для финансового инструмента');
    }

    if (!fullFI) {
      throw new Error(
        'Не найдена необходимая информация о финансовом инструменте'
      );
    }

    if (isUndefined(buy)) {
      throw new Error('Не задан тип покупки/продажи заявки');
    }

    const header = this.generateHeader({
      idOrderType,
      nameClient: razdel.nameClient,
      templateNum,
      fullFI,
      idDocumentType,
      ...headerProps,
    });

    const isAdressed =
      idOrderType === OrderType.RPS || idOrderType === OrderType.TechRPS;

    const account = generateAccountInfo(
      isAdressed,
      razdel.codeSubAccount,
      razdel.codeDepoAccount
    );

    const market = generateMarketInfo(fullFI);

    let marketLifeTime = '';

    switch (idLifeTime) {
      case LifeTime.CLS:
        marketLifeTime = ' на послеторговом аукционе (в период закрытия)';
        break;
      case LifeTime.OPN:
        marketLifeTime = ' на предторговом аукционе (в период открытия)';
        break;
    }

    const main = generateMainInfo(inputParams);
    const currency = generateCurrency(fullFI);
    const pricePart = generatePriceInfo(
      orderParams,
      limitPrice,
      stopPrice,
      currency,
      limitLevelAlternative
    );
    const instructions = generateInstruction(buy, orderParams, withDrawTime);
    const orderType = generateOrderType(orderParams);
    const additionalInstruction = generateAdditionalInstruction(
      orderParams,
      buy,
      idPriceControlType,
      linkedOrderId,
      stopPrice,
      limitPrice,
      limitLevelAlternative,
      activationTime,
      activationPriceDirection,
      idFIActivate,
      fullFIActivate
    );
    const activationTimePart = activationTime
      ? `приступить к исполнению ордера в заданное время: ${formatOrderDate(
          activationTime,
          RestDateTimeFormat
        )} (Мск)\r\n`
      : '';
    const lifeTime = generateLifeTime(orderParams, withDrawTime);

    return (
      `${header}\r\n` +
      account +
      `Настоящим поручаю и уполномочиваю:${isAdressed ? ' ' : '\r\n'}` +
      String(buy ? 'купить' : 'продать') +
      market +
      marketLifeTime +
      main +
      pricePart +
      instructions +
      orderType +
      additionalInstruction +
      activationTimePart +
      lifeTime
    );
  }

  /**
   * Приложения должны реализовывать свои версии generateHeader.
   */
  generateHeader(_: GenerateHeaderProps): string {
    return '';
  }
}

/** Генерация данных об аккаунте заявки */
export const generateAccountInfo = (
  isAdressed: boolean,
  codeSubAccount: string,
  codeDepoAccount: string
) => {
  return `Субсчет (Портфель) Клиента: ${codeSubAccount}\r\n${
    isAdressed ? `Депо счет: ${codeDepoAccount}\r\n` : ''
  }`;
};

/**
 * Генерация данных по рынку заявки
 */
export const generateMarketInfo = (fullFI: ExtendedFI) => {
  const { nameMarketBoard } = fullFI;

  const market =
    // Раньше здесь была проверка для OTC борады,
    // но это было не правильно.
    fullFI.type.value === MarketBoardType.IPO
      ? ' вне организованных рынков'
      : `\r\nРынок: ${nameMarketBoard}`;

  return market;
};

/**
 * TODO
 * Генерация
 */
export const generateMainInfo = (inputParams: InputOrderTextParams) => {
  const { descObject } = inputParams.fullFI!;
  const { idDocumentType, idQuantityType, idExecutionType } =
    inputParams.orderParams;
  const { withDrawTime, quantity, openQuantity, value, linkedOrderId } =
    inputParams;

  let formatDrawTime: string | undefined;

  switch (idDocumentType) {
    case DocumentType.REP:
      if (!withDrawTime) {
        throw new Error('Не задана дата исполнения');
      } else {
        formatDrawTime = `Дата исполнения 2-й части: ${formatOrderDate(
          withDrawTime,
          RestDateTimeFormatDays
        )}\r\n`;
      }

      break;
  }

  let quantityPart: string | undefined;

  switch (idQuantityType) {
    case QuantityType.QTY:
    case QuantityType.AON:
    case QuantityType.ICE:
    case QuantityType.OTO:
    case QuantityType.QVAL:
      if (!quantity || quantity <= 0) {
        throw new Error('Количество должно быть строго положительным');
      } else {
        quantityPart = `Количество (штук): ${quantity}`;
      }

      break;

    case QuantityType.REV:
      if (!openQuantity || openQuantity <= 0) {
        throw new Error('Открытое количество должно быть строго положительным');
      } else {
        quantityPart = `Количество (штук): ${openQuantity}`;
      }

      break;
  }

  let sumPart: string | undefined;

  switch (idQuantityType) {
    case QuantityType.VAL:
    case QuantityType.QVAL:
    case QuantityType.VDISC:
      sumPart = `\r\nСумма: ${value}`;
      break;
  }

  // if (lookupParams.IdObjectGroupActivate == ObjectGroup.Currency) {
  //   switch (orderEntity.IdQuantityType) {
  //     case QuantityType.VAL:
  //     case QuantityType.QVAL:
  //       switch (orderEntity.IdOrderType) {
  //         case OrderType.LMT:
  //         case OrderType.LIT:
  //         case OrderType.RPS:
  //         case OrderType.TechRPS:
  //         case OrderType.INT:
  //           switch (orderEntity.IdPriceType) {
  //             case PriceType.QUO:
  //             case PriceType.SWAP:
  //               resultOrder.Append(' ');
  //               resultOrder.Append(lookupParams.SymbolObjectForActivateIdFI);
  //               break;
  //           }
  //           break;
  //       }
  //       break;
  //   }
  // }

  switch (idQuantityType) {
    //   case QuantityType.QDISC:
    //   case QuantityType.VDISC:
    //     resultOrder.Append('\r\nДисконт,%: ');
    //     resultOrder.Append(
    //       orderEntity.SDiscount.ToString(
    //         DoubleFormatSmall,
    //         CultureInfo.InvariantCulture
    //       )
    //     );
    //     break;
    //   case QuantityType.AON:
    //     resultOrder.Append('\r\nчастичное исполнение не допускается');
    //     break;
    //   case QuantityType.REV:
    //     if (orderEntity.Quantity <= 0 || orderEntity.OpenQuantity <= 0) {
    //       errorMessage = 'Количество должно быть строго положительным';
    //       return false;
    //     }

    //     resultOrder.Append('\r\nисполнить ордер ');
    //     resultOrder.Append(orderEntity.Quantity / orderEntity.OpenQuantity);
    //     resultOrder.Append(' подряд');
    //     break;
    case QuantityType.ICE:
      if (idExecutionType === ExecutionType.ASL) {
        quantityPart += `\r\nисполнять ордер только частями не более ${openQuantity}`;
      } else {
        quantityPart += `\r\nобъявлять в котировках не более ${openQuantity} штук`;
      }

      break;
    case QuantityType.OTO:
      quantityPart += `\r\nисполнять ордер по частям, пропорционально исполненному количеству ордера №${linkedOrderId}`;
      break;
  }

  //TODO: Тесты
  const main =
    `\r\nВыпуск: ${descObject}\r\n` +
    (formatDrawTime || '') +
    (quantityPart || '') +
    (sumPart || '') +
    '\r\n';

  return main;
};

function getDocumentPrice(order: NewOrderType | OrderItem): number | undefined {
  switch (order.idOrderType) {
    case OrderType.LMT:
    case OrderType.MKT:
    case OrderType.TRL:
    case OrderType.LIT:
    case OrderType.BRS:
    case OrderType.BSL:
    case OrderType.TBRS:
      return order.limitPrice;
    case OrderType.STL:
      return order.limitLevelAlternative;
    case OrderType.STP:
      return order.stopPrice;
    case OrderType.TRS:
    case OrderType.TSL:
      if (order.price) {
        return Number(order.price.toFixed(10));
      }
  }
}

/**
 * Генерация данных по цене
 */
export const generatePriceInfo = (
  orderParams: AllowedOrderParams,
  limitPrice?: number,
  stopPrice?: number,
  currency = '',
  limitLevelAlternative?: number
) => {
  const { idPriceType, idOrderType } = orderParams;
  const price = getDocumentPrice({
    idOrderType,
    limitPrice,
    stopPrice,
    limitLevelAlternative,
  } as OrderItem);
  let pricePart: string = '';

  switch (idOrderType) {
    case OrderType.MKT:
    case OrderType.MIT:
    case OrderType.STP:
    case OrderType.TRS:
      break;
    default:
      switch (idPriceType) {
        case PriceType.QUO:
        case PriceType.SWAP:
        case PriceType.RATE:
          if (!price) {
            throw new Error(
              'Не задана цена/доходность/ставка купона по заявке'
            );
          }

          pricePart += `Цена: ${roundFloatNumber(price)}${
            currency ? ' ' + currency : ''
          }\r\n`; // если передана валюта, то ставится дополнительный пробел
          break;
        case PriceType.YLD:
          if (!price) {
            throw new Error(
              'Не задана цена/доходность/ставка купона по заявке'
            );
          }

          pricePart += `Доходность, %: ${roundFloatNumber(price)}\r\n`;
          break;
        case PriceType.CUP:
          if (!price) {
            throw new Error(
              'Не задана цена/доходность/ставка купона по заявке'
            );
          }

          pricePart += `Ставка купона, %: ${roundFloatNumber(price)}\r\n`;
          break;
      }

      break;
  }

  return pricePart;
};

/**
 * Генерация срока исполнения
 */
export const generateLifeTime = (
  orderParams: AllowedOrderParams,
  withDrawTime?: Date
) => {
  const { idLifeTime } = orderParams;

  let lifeTime = '';

  switch (idLifeTime) {
    case LifeTime.IMM:
      // IMM Это приказ "Немедленно или отменить"
      // По этой причине для IMM lifeTime всегда равен 23:59:59 01 янв 0001
      // see ADIRWEB-1557
      lifeTime = `исполнять ордер до 23:59:59 01 янв 0001 (Мск)\r\nнезамедлительно прекратить исполнение ордера без возобновления, если ордер отклонен организатором торгов\r\n`;
      break;
    case LifeTime.GTD:
      if (!withDrawTime) {
        throw new Error('Не задана дата исполнения');
      } else {
        lifeTime = `исполнять ордер до 23:59:59 ${formatOrderDate(
          withDrawTime,
          RestDateTimeFormatDays
        )} (Мск)\r\n`;
      }

      break;
    case LifeTime.FIN:
      if (!withDrawTime) {
        throw new Error('Не задана дата исполнения');
      } else {
        lifeTime =
          `исполнять ордер до 23:59:59 ${formatOrderDate(
            withDrawTime,
            RestDateTimeFormatDays
          )} (Мск)\r\n` +
          `незамедлительно исполнить ордер по лучшей доступной цене (доходности, ставке) в заданное время ${formatOrderDate(
            withDrawTime,
            RestDateTimeFormatSeconds
          )} (Мск)\r\n`;
      }

      break;
    case LifeTime.DAY:
    case LifeTime.CLS:
      lifeTime = 'исполнять ордер до завершения текущего рабочего дня\r\n';
      break;
    case LifeTime.OPN:
      lifeTime =
        'исполнять ордер до завершения предторгового аукциона/периода открытия торговой сессии\r\n';
      break;
    case LifeTime.AU:
      lifeTime =
        'исполнять ордер до завершения ближайшего аукциона, но не более 30 дней с момента приёма\r\n';
      break;
    case LifeTime.D30:
      lifeTime = 'исполнять ордер в течение 30 дней с момента приёма\r\n';
      break;
    case LifeTime.GTT:
      if (!withDrawTime) {
        throw new Error('Не задана дата исполнения');
      } else {
        lifeTime = `исполнять ордер до ${formatOrderDate(
          withDrawTime,
          RestDateTimeFormatSeconds
        )} (Мск)\r\n`;
      }

      break;
  }

  return lifeTime;
};

/**
 * TODO
 * Генерация части валюты исполнения
 */
export const generateCurrency = (
  fullFI: ExtendedFI | undefined = undefined
) => {
  let currency = '';

  //корнер кейс для ОТС
  if (fullFI?.type.value === MarketBoardType.OTC) {
    currency = fullFI.currencyCode;
  }

  //  if (
  //    orderEntity.IdOrderType == OrderType.RPS ||
  //    orderEntity.IdOrderType == OrderType.TechRPS
  //  ) {
  //    var marketBoadr = dataStorage.MarketBoards(orderEntity.IdMarketBoard);
  //    if (marketBoadr == null) {
  //      errorMessage =
  //        'Маркет боард не найден для: IdMarketBoard = ' +
  //        orderEntity.IdMarketBoard.ToString();
  //      return false;
  //    }
  //    var objectEntity = dataStorage.FinObjects(marketBoadr.IdObjectCurrency);
  //    if (objectEntity == null) {
  //      errorMessage =
  //        'Объект не найден для: IdObject = ' +
  //        marketBoadr.IdObjectCurrency.ToString();
  //      return false;
  //    }
  //    resultOrder.Append('\r\nВалюта цены: ');
  //    resultOrder.Append(objectEntity.NameObject);
  //  }

  // if (
  //   lookupParams.IdObjectGroupActivate == ObjectGroup.Currency &&
  //   lookupParams.marketBoardEntity.IdMarketType == MarketType.OTC
  // ) {
  //   resultOrder.Append(' ');
  //   resultOrder.Append(lookupParams.SymbolObjectForActivateIdFI);
  //   resultOrder.Append(
  //     ' (по официальному курсу (кросс курсу) Банка России на дату урегулирования)'
  //   );
  // }

  return currency;
};

/**
 * Генерация части инструкций (в том числе дополнительных)
 */
export const generateInstruction = (
  buy: boolean,
  orderParams: AllowedOrderParams,
  withDrawTime?: Date
) => {
  const { idOrderType } = orderParams;
  const isAdressed =
    idOrderType === OrderType.RPS || idOrderType === OrderType.TechRPS;

  let instructions = '';

  if (isAdressed) {
    if (!withDrawTime) {
      throw new Error('Не задана дата исполнения');
    }

    const additionalInstructions =
      'Дополнительные инструкции по исполнению поручения:\r\n' +
      `Исполнять поручение по: ${
        buy ? 'указанной цене или меньше' : 'указанной цене или больше'
      }\r\n` +
      `Исполнять поручение до ${formatOrderDate(
        withDrawTime,
        RestDateTimeFormatSeconds
      )}`;

    instructions += additionalInstructions;
  }

  instructions += '\r\nИнструкции:\r\n';

  return instructions;
};

/**
 * Генерация части инструкций по типу ордера
 */
export const generateOrderType = (orderParams: AllowedOrderParams) => {
  const { idOrderType, idPriceType } = orderParams;

  let priceType = '';

  switch (idPriceType) {
    case PriceType.QUO:
    case PriceType.SWAP:
      priceType += 'цене';
      break;
    case PriceType.RATE:
    case PriceType.CUP:
      priceType += 'ставке';
      break;
    case PriceType.YLD:
      priceType += 'доходности';
      break;
  }

  let order = '';

  switch (idOrderType) {
    case OrderType.MKT:
    case OrderType.MIT:
    case OrderType.STP:
    case OrderType.TRS:
      order += `исполнять ордер по лучшей доступной ${priceType}`;
      break;

    case OrderType.LWA:
      order += `исполнять ордер по средней ${priceType}, не хуже указанной`;
      break;

    case OrderType.NOS:
      order += `исполнить ордер по единой ${priceType}, не хуже указанной`;
      break;

    case OrderType.LMT:
    case OrderType.LIT:
    case OrderType.RPS:
    case OrderType.TechRPS:
    case OrderType.INT:
    case OrderType.STL:
    case OrderType.BRS:
    case OrderType.BSL:
    case OrderType.TRL:
    case OrderType.TSL:
    case OrderType.TBRS:
      order += `исполнять ордер по ${priceType}, не хуже указанной`;
      break;
  }

  return order + '\r\n';
};

/**
 * Генерация дополнительной части инструкций по типу ордера
 */
export const generateAdditionalInstruction = (
  orderParams: AllowedOrderParams,
  buy: boolean,
  idPriceControlType: PriceControlType,
  linkedOrderId?: string,
  stopPrice?: number,
  limitPrice?: number,
  limitLevelAlternative?: number,
  activationTime?: Date,
  activationPriceDirection?: OrderStopPriceDirection,
  idFIActivate?: number,
  fullFIActivate?: ExtendedFI
) => {
  const { idOrderType, idPriceType, idQuantityType } = orderParams;

  let text = '';

  let add = false;

  switch (idOrderType) {
    case OrderType.MKT:
    case OrderType.LMT:
    case OrderType.LWA:
    case OrderType.NOS:
    case OrderType.RPS:
    case OrderType.TechRPS:
    case OrderType.INT:
      break;
    case OrderType.TBRS:
      if (isUndefined(stopPrice)) {
        throw new Error('Не задано значение Контролируемого Параметра');
      }

      text += `или исполнить по лучшей доступной цене если на Рынке будет заключена сделка по цене не ${
        buy ? 'ниже' : 'выше'
      } ${stopPrice} или ${
        buy ? 'не ниже минимума' : 'не выше максимума'
      } Цены последней сделки, зафиксированного с ${
        activationTime ? 'начала исполнения' : 'момента приема'
      } поручения, ${
        buy
          ? `увеличенного на разность между ${stopPrice} и объявляемым организатором торгов значением «Цена последней сделки» на момент подачи поручения`
          : `уменьшенного на разность между объявляемым организатором торгов значением «Цена последней сделки» на момент подачи поручения и ${stopPrice}`
      }\r\n`;
      break;
    default:
      switch (idOrderType) {
        case OrderType.MIT:
        case OrderType.LIT:
          add = activationPriceDirection === OrderStopPriceDirection.Upper;
          break;
        case OrderType.STP:
        case OrderType.STL:
        case OrderType.TRS:
        case OrderType.TSL:
          if (buy) {
            add = idPriceType === PriceType.QUO;
            break;
          } else {
            switch (idPriceType) {
              case PriceType.YLD:
              case PriceType.RATE:
              case PriceType.SWAP:
                add = true;
                break;
            }
          }

          break;
      }

      if (add) {
        text += 'если максимум значения Контролируемого Параметра не ниже ';
        break;
      }

      switch (idOrderType) {
        case OrderType.MIT:
        case OrderType.LIT:
          add = activationPriceDirection === OrderStopPriceDirection.Lower;
          break;
        case OrderType.STP:
        case OrderType.STL:
        case OrderType.TRS:
        case OrderType.TSL:
          if (!buy) {
            add = idPriceType === PriceType.QUO;
            break;
          } else {
            switch (idPriceType) {
              case PriceType.YLD:
              case PriceType.RATE:
              case PriceType.SWAP:
                add = true;
                break;
            }
          }

          break;
      }

      if (add) {
        text += 'если минимум значения Контролируемого Параметра не выше ';
        break;
      }

      if (idOrderType === OrderType.TRL) {
        if (buy) {
          add = idPriceType === PriceType.QUO;
        } else {
          switch (idPriceType) {
            case PriceType.YLD:
            case PriceType.RATE:
            case PriceType.SWAP:
              add = true;
              break;
          }
        }

        if (add) {
          text += 'если максимум значения Контролируемого Параметра не выше ';
          break;
        }

        if (!buy) {
          add = idPriceType === PriceType.QUO;
        } else {
          switch (idPriceType) {
            case PriceType.YLD:
            case PriceType.RATE:
            case PriceType.SWAP:
              add = true;
              break;
          }
        }

        if (add) {
          text += 'если минимум значения Контролируемого Параметра не ниже ';
          break;
        }

        add = false;
      }

      switch (idOrderType) {
        case OrderType.BRS:
        case OrderType.BSL:
          if (buy) {
            add = idPriceType === PriceType.QUO;
          } else {
            switch (idPriceType) {
              case PriceType.YLD:
              case PriceType.RATE:
              case PriceType.SWAP:
                add = true;
                break;
            }
          }

          if (add) {
            text += 'если максимум значения Контролируемого Параметра ниже ';
            break;
          }

          if (!buy) {
            add = idPriceType === PriceType.QUO;
          } else {
            switch (idPriceType) {
              case PriceType.YLD:
              case PriceType.RATE:
              case PriceType.SWAP:
                add = true;
                break;
            }
          }

          if (add) {
            text += 'если минимум значения Контролируемого Параметра выше ';
            break;
          }
      }

      break;
  }

  if (add) {
    if (!stopPrice) {
      throw new Error('Не задано значение Контролируемого Параметра');
    }

    text += stopPrice + '\r\n';
  }

  switch (idOrderType) {
    case OrderType.BRS:
    case OrderType.BSL:
    case OrderType.TRS:
    case OrderType.TSL:
    case OrderType.TRL:
      text += 'в ином случае\r\n';
      break;
  }

  switch (idOrderType) {
    case OrderType.TRS:
    case OrderType.BRS:
      switch (idPriceType) {
        case PriceType.QUO:
        case PriceType.SWAP:
          text += 'исполнять ордер по лучшей доступной цене\r\n';
          break;
        case PriceType.RATE:
        case PriceType.CUP:
          text += 'исполнять ордер по лучшей доступной ставке\r\n';
          break;
        case PriceType.YLD:
          text += 'исполнять ордер по лучшей доступной доходности\r\n';
          break;
        default:
          return false;
      }

      break;
    case OrderType.BSL:
      switch (idPriceType) {
        case PriceType.QUO:
        case PriceType.SWAP:
          text += 'исполнять ордер по цене, не хуже ';
          break;
        case PriceType.RATE:
        case PriceType.CUP:
          text += 'исполнять ордер по ставке, не хуже ';
          break;
        case PriceType.YLD:
          text += 'исполнять ордер по доходности, не хуже ';
          break;
        default:
          throw new Error(`Unsuported OrderPriceType ${idPriceType}`);
      }

      if (isUndefined(limitLevelAlternative)) {
        throw new Error(
          'Не задано альтернативное значение Контролируемого Параметра'
        );
      }

      text += limitLevelAlternative + '\r\n';
      break;
    case OrderType.TSL:
    case OrderType.TRL:
      if (
        (idOrderType === OrderType.TSL &&
          idPriceType === PriceType.QUO &&
          buy) ||
        (idOrderType === OrderType.TSL &&
          idPriceType === PriceType.SWAP &&
          !buy) ||
        (idOrderType === OrderType.TRL &&
          idPriceType === PriceType.QUO &&
          !buy) ||
        (idOrderType === OrderType.TRL && idPriceType === PriceType.SWAP && buy)
      ) {
        if (isUndefined(limitLevelAlternative) || isUndefined(limitPrice)) {
          throw new Error(
            'Не задано альтернативное значение Контролируемого Параметра'
          );
        }

        text += `исполнять ордер по цене, выше минимального значения Контролируемого Параметра на ${roundFloatNumber(
          limitLevelAlternative - limitPrice
        )}\r\n`;
      } else if (
        (idOrderType === OrderType.TSL &&
          idPriceType === PriceType.QUO &&
          !buy) ||
        (idOrderType === OrderType.TSL &&
          idPriceType === PriceType.SWAP &&
          buy) ||
        (idOrderType === OrderType.TRL &&
          idPriceType === PriceType.QUO &&
          buy) ||
        (idOrderType === OrderType.TRL &&
          idPriceType === PriceType.SWAP &&
          !buy)
      ) {
        if (isUndefined(limitLevelAlternative) || isUndefined(limitPrice)) {
          throw new Error(
            'Не задано альтернативное значение Контролируемого Параметра'
          );
        }

        text += `исполнять ордер по цене, ниже максимального значения Контролируемого Параметра на ${roundFloatNumber(
          limitPrice - limitLevelAlternative
        )}\r\n`;
      } else if (
        (idOrderType === OrderType.TSL &&
          idPriceType === PriceType.YLD &&
          !buy) ||
        (idOrderType === OrderType.TRL && idPriceType === PriceType.YLD && buy)
      ) {
        if (isUndefined(limitLevelAlternative) || isUndefined(limitPrice)) {
          throw new Error(
            'Не задано альтернативное значение Контролируемого Параметра'
          );
        }

        text += `исполнять ордер по доходности, выше минимального значения Контролируемого Параметра на ${roundFloatNumber(
          limitLevelAlternative - limitPrice
        )}\r\n`;
      } else if (
        (idOrderType === OrderType.TSL &&
          idPriceType === PriceType.YLD &&
          buy) ||
        (idOrderType === OrderType.TRL && idPriceType === PriceType.YLD && !buy)
      ) {
        if (isUndefined(limitLevelAlternative) || isUndefined(limitPrice)) {
          throw new Error(
            'Не задано альтернативное значение Контролируемого Параметра'
          );
        }

        text += `исполнять ордер по доходности, ниже максимального значения Контролируемого Параметра на ${roundFloatNumber(
          limitPrice - limitLevelAlternative
        )}\r\n`;
      } else if (
        (idOrderType === OrderType.TSL &&
          (idPriceType === PriceType.RATE || idPriceType === PriceType.CUP) &&
          !buy) ||
        (idOrderType === OrderType.TRL &&
          (idPriceType === PriceType.RATE || idPriceType === PriceType.CUP) &&
          buy)
      ) {
        if (!limitLevelAlternative || !limitPrice) {
          throw new Error(
            'Не задано альтернативное значение Контролируемого Параметра'
          );
        }

        text += `исполнять ордер по ставке, выше минимального значения Контролируемого Параметра на ${roundFloatNumber(
          limitLevelAlternative - limitPrice
        )}\r\n`;
      } else if (
        (idOrderType === OrderType.TSL &&
          (idPriceType === PriceType.RATE || idPriceType === PriceType.CUP) &&
          buy) ||
        (idOrderType === OrderType.TRL &&
          (idPriceType === PriceType.RATE || idPriceType === PriceType.CUP) &&
          !buy)
      ) {
        if (isUndefined(limitLevelAlternative) || isUndefined(limitPrice)) {
          throw new Error(
            'Не задано альтернативное значение Контролируемого Параметра'
          );
        }

        text += `исполнять ордер по ставке, ниже максимального значения Контролируемого Параметра на ${roundFloatNumber(
          limitPrice - limitLevelAlternative
        )}\r\n`;
      }

      break;
  }

  switch (idOrderType) {
    case OrderType.TRS:
    case OrderType.TSL:
      let add1 = false;
      let add2 = false;

      switch (idPriceType) {
        case PriceType.QUO:
          if (buy) {
            add1 = true;
          } else {
            add2 = true;
          }

          break;
        case PriceType.SWAP:
        case PriceType.YLD:
        case PriceType.RATE:
        case PriceType.CUP:
          if (buy) {
            add2 = true;
          } else {
            add1 = true;
          }

          break;
      }

      if (add1) {
        if (!stopPrice || !limitPrice) {
          throw new Error(
            'Не задано альтернативное значение Контролируемого Параметра'
          );
        }

        text += `если максимум значения Контролируемого Параметра выше ранее зафиксированного минимума не менее чем на ${roundFloatNumber(
          stopPrice - limitPrice
        )}\r\n`;
      } else if (add2) {
        if (!stopPrice || !limitPrice) {
          throw new Error(
            'Не задано альтернативное значение Контролируемого Параметра'
          );
        }

        text += `если минимум значения Контролируемого Параметра ниже ранее зафиксированного максимума не менее чем на ${roundFloatNumber(
          limitPrice - stopPrice
        )}\r\n`;
      }

      break;
  }

  switch (idOrderType) {
    case OrderType.MKT:
    case OrderType.LMT:
    case OrderType.LWA:
    case OrderType.NOS:
    case OrderType.RPS:
    case OrderType.TechRPS:
    case OrderType.INT:
    case OrderType.TBRS:
      break;

    default:
      switch (idPriceControlType) {
        case PriceControlType.LIND:
          text += 'Контролируемый Параметр: расчетное значение';
          break;
        case PriceControlType.QUOTE:
          text +=
            'Контролируемый Параметр: рассчитываемое организатором торгов значение «рыночной котировки»';
          break;
        case PriceControlType.LAST:
        case PriceControlType.GLAST:
        case PriceControlType.LYLD:
        case PriceControlType.GLYLD:
        case PriceControlType.BID:
        case PriceControlType.GBID:
        case PriceControlType.BYLD:
        case PriceControlType.GBYLD:
        case PriceControlType.ASK:
        case PriceControlType.GASK:
        case PriceControlType.AYLD:
        case PriceControlType.GAYLD:
          add = false;

          switch (idOrderType) {
            case OrderType.MIT:
            case OrderType.LIT:
              //     if (
              // !dataStorage.IsContolParameterValid(
              //   lookupParams.marketBoardEntity.IdMarketBoard,
              //   orderEntity.IdPriceControlType
              // )
              //     ) {
              //       errorMessage =
              //         'Контрольный параметр не найден в матрице доступных параметров (1)';
              //       return false;
              //     }
              if (idFIActivate && fullFIActivate) {
                add = true;
              } else {
                throw new Error('Не задан Контролируемый Параметр');
              }

              break;
            default:
              switch (idPriceType) {
                case PriceType.YLD:
                  switch (idPriceControlType) {
                    case PriceControlType.LYLD:
                    case PriceControlType.GLYLD:
                    case PriceControlType.BYLD:
                    case PriceControlType.GBYLD:
                    case PriceControlType.AYLD:
                    case PriceControlType.GAYLD:
                      add = true;
                      break;
                  }

                  break;
                default:
                  switch (idPriceControlType) {
                    case PriceControlType.LAST:
                    case PriceControlType.GLAST:
                    case PriceControlType.BID:
                    case PriceControlType.GBID:
                    case PriceControlType.ASK:
                    case PriceControlType.GASK:
                      add = true;
                      break;
                  }

                  break;
              }

              break;
          }

          if (add) {
            switch (idPriceControlType) {
              case PriceControlType.LAST:
              case PriceControlType.GLAST:
                text +=
                  'Контролируемый Параметр: объявляемое организатором торгов значение «цена последней сделки»';
                break;
              case PriceControlType.LYLD:
              case PriceControlType.GLYLD:
                text +=
                  'Контролируемый Параметр: объявляемое организатором торгов значение «доходность по цене последней сделки»';
                break;
              case PriceControlType.BID:
              case PriceControlType.GBID:
                text +=
                  'Контролируемый Параметр: объявляемое организатором торгов, значение «цена спроса»';
                break;
              case PriceControlType.BYLD:
              case PriceControlType.GBYLD:
                text +=
                  'Контролируемый Параметр: объявляемое организатором торгов значение «доходность по цене спроса»';
                break;
              case PriceControlType.ASK:
              case PriceControlType.GASK:
                text +=
                  'Контролируемый Параметр: объявляемое организатором торгов значение «цена предложения»';
                break;
              case PriceControlType.AYLD:
              case PriceControlType.GAYLD:
                text +=
                  'Контролируемый Параметр: объявляемое организатором торгов значение «доходность по цене предложения»';
                break;
            }
          }

          switch (idOrderType) {
            case OrderType.MIT:
            case OrderType.LIT:
              if (idFIActivate && fullFIActivate) {
                text += ` финансового инструмента «${fullFIActivate.descObject}»\r\n`;
              } else {
                throw new Error('Не задан Контролируемый Параметр');
              }

              break;
            default:
              text += '\r\n';
          }
      }
  }

  switch (idOrderType) {
    case OrderType.MKT:
    case OrderType.LMT:
    case OrderType.LWA:
    case OrderType.NOS:
    case OrderType.RPS:
    case OrderType.TechRPS:
    case OrderType.INT:
      break;
    case OrderType.MIT:
    case OrderType.LIT:
      switch (idPriceControlType) {
        case PriceControlType.QUOTE:
        case PriceControlType.LAST:
        case PriceControlType.BID:
        case PriceControlType.ASK:
        case PriceControlType.LYLD:
        case PriceControlType.BYLD:
        case PriceControlType.AYLD:
          //     if (
          // !dataStorage.IsContolParameterValid(
          //   lookupParams.marketBoardEntity.IdMarketBoard,
          //   orderEntity.IdPriceControlType
          // )
          //     ) {
          //       errorMessage =
          //         'Контрольный параметр не найден в матрице доступных параметров (1)';
          //       return false;
          //     }
          if (idFIActivate && fullFIActivate) {
            text += `на рынке ${fullFIActivate.nameMarketBoard}\r\n`;
          } else {
            throw new Error('Не задан Контролируемый Параметр');
          }

          break;
        case PriceControlType.GLAST:
        case PriceControlType.GBID:
        case PriceControlType.GASK:
        case PriceControlType.GLYLD:
        case PriceControlType.GBYLD:
        case PriceControlType.GAYLD:
          //     if (
          // !dataStorage.IsContolParameterValid(
          //   lookupParams.marketBoardEntity.IdMarketBoard,
          //   orderEntity.IdPriceControlType
          // )
          //     ) {
          //       errorMessage =
          //         'Контрольный параметр не найден в матрице доступных параметров (1)';
          //       return false;
          //     }
          if (idFIActivate && fullFIActivate) {
            text += `в основной период торговой сессии на рынке ${fullFIActivate.nameMarketBoard}\r\n`;
          } else {
            throw new Error('Не задан Контролируемый Параметр');
          }

          break;
      }

      break;
    default:
      switch (idPriceControlType) {
        case PriceControlType.GLAST:
        case PriceControlType.GBID:
        case PriceControlType.GASK:
        case PriceControlType.GLYLD:
        case PriceControlType.GBYLD:
        case PriceControlType.GAYLD:
          //TODO
          // if (
          //   !dataStorage.IsContolParameterValid(
          //     lookupParams.marketBoardEntity.IdMarketBoard,
          //     orderEntity.IdPriceControlType
          //   )
          // ) {
          //   errorMessage =
          //     'Контрольный параметр не найден в матрице доступных параметров (2)';
          //   return false;
          // }
          // resultOrder.Append('\r\nв основной период торговой сессии');
          break;
      }

      break;
  }

  switch (idOrderType) {
    case OrderType.MKT:
    case OrderType.LMT:
    case OrderType.LWA:
    case OrderType.NOS:
    case OrderType.RPS:
    case OrderType.TechRPS:
    case OrderType.INT:
    case OrderType.TBRS:
      break;
    default:
      if (activationTime) {
        text += `зафиксированное после ${formatOrderDate(
          activationTime,
          RestDateTimeFormatSeconds
        )}\r\n`;
      } else {
        text += 'зафиксированное после приёма ордера\r\n';
      }

      break;
  }

  if (idQuantityType === QuantityType.OTO) {
    switch (idOrderType) {
      case OrderType.MKT:
      case OrderType.LMT:
      case OrderType.LWA:
      case OrderType.NOS:
      case OrderType.RPS:
      case OrderType.TechRPS:
      case OrderType.INT:
        break;
      default:
        text += `но не ранее любого (в т.ч. частичного) исполнения ордера №${linkedOrderId}\r\n`;
        break;
    }
  }

  return text;
};
