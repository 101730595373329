import { ObjectTypes } from './core';

export enum ObjectTypeCategory {
  Stock,
  Bond,
  Currency,
  Future,
  Fund,
}

export const ObjectTypeCategoryMap: Record<
  Partial<ObjectTypes> | number,
  ObjectTypeCategory | undefined
> = {
  [ObjectTypes.AO]: ObjectTypeCategory.Stock,
  [ObjectTypes.AP]: ObjectTypeCategory.Stock,
  [ObjectTypes.TECH_BONDS]: ObjectTypeCategory.Bond,
  [ObjectTypes.TECH_FUNDS]: ObjectTypeCategory.Fund,
  [ObjectTypes.TECH_FUTURES]: ObjectTypeCategory.Future,
  [ObjectTypes.TECH_CURRENCY]: ObjectTypeCategory.Currency,
  [ObjectTypes.TECH_STOCKS]: ObjectTypeCategory.Stock,
  [ObjectTypes.TECH_A_GIFTS]: ObjectTypeCategory.Stock,

  [ObjectTypes.BC]: ObjectTypeCategory.Bond,
  [ObjectTypes.BB]: ObjectTypeCategory.Bond,
  [ObjectTypes.BG]: ObjectTypeCategory.Bond,
  [ObjectTypes.BS]: ObjectTypeCategory.Bond,
  [ObjectTypes.BM]: ObjectTypeCategory.Bond,
  [ObjectTypes.BR]: ObjectTypeCategory.Bond,
  [ObjectTypes.BI]: ObjectTypeCategory.Bond,
  [ObjectTypes.BE]: ObjectTypeCategory.Bond,
  [ObjectTypes.SB]: ObjectTypeCategory.Bond,

  [ObjectTypes.PO]: ObjectTypeCategory.Fund,
  [ObjectTypes.PI]: ObjectTypeCategory.Fund,
  [ObjectTypes.PZ]: ObjectTypeCategory.Fund,
  [ObjectTypes.IF]: ObjectTypeCategory.Fund,
  [ObjectTypes.IV]: ObjectTypeCategory.Fund,
  [ObjectTypes.IT]: ObjectTypeCategory.Fund,
  [ObjectTypes.BF]: ObjectTypeCategory.Fund,
  [ObjectTypes.EF]: ObjectTypeCategory.Fund,

  [ObjectTypes.TECH_CC_StocksAndBondsMarket]: ObjectTypeCategory.Currency,
  [ObjectTypes.TECH_CC_DerivativesMarket]: ObjectTypeCategory.Currency,
  [ObjectTypes.TECH_CC_CurrencyMarket]: ObjectTypeCategory.Currency,
  [ObjectTypes.CC]: ObjectTypeCategory.Currency,

  [ObjectTypes.OE]: ObjectTypeCategory.Future,
  [ObjectTypes.VF]: ObjectTypeCategory.Future,
  [ObjectTypes.VM]: ObjectTypeCategory.Future,
  [ObjectTypes.FP]: ObjectTypeCategory.Future,
  [ObjectTypes.PP]: ObjectTypeCategory.Future,
  [ObjectTypes.PM]: ObjectTypeCategory.Future,
  [ObjectTypes.CP]: ObjectTypeCategory.Future,
  [ObjectTypes.CM]: ObjectTypeCategory.Future,
  [ObjectTypes.FR]: ObjectTypeCategory.Future,
  [ObjectTypes.FD]: ObjectTypeCategory.Future,
};

export const getObjectTypeCategory = (idObjectType: ObjectTypes) =>
  ObjectTypeCategoryMap[idObjectType];
