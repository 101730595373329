import { Fragment, memo, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Spinner } from '@alfalab/core-components/spinner';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';

import {
  InstructionTheme,
  MANUAL_MODAL_DRAWER_ID,
  ThemePlate,
  useInstructionsList,
} from '../../entities/Education';
import {
  InstructionThemeDetail,
  InstructionThemeTest,
} from '../../features/Education';
import icon from './img/stage.png';
import icon2x from './img/stage@2x.png';

import styles from './Manual.module.css';

export const Manual = memo(() => {
  const [instructionId, setInstructionId] = useState(0);
  const [testTheme, setTestTheme] = useState<InstructionTheme | null>(null);

  const {
    data: instructions,
    isFetching: instructionsPending,
    refetch: refetchInstructions,
  } = useInstructionsList();

  const handleNextTheme = () => {
    refetchInstructions();
    setTestTheme(null);

    if (instructions) {
      const currentIndex = instructions.findIndex(
        (theme) => theme.id === instructionId
      );
      const lastIndex = instructions.length - 1;

      if (currentIndex < lastIndex) {
        return setInstructionId(instructions[currentIndex + 1].id);
      }
    }

    return setInstructionId(0);
  };

  const handleToThemeList = () => {
    setTestTheme(null);
    setInstructionId(0);
  };

  return (
    <div className={styles.container}>
      <div className={styles.stage}>
        <img
          className={styles.image}
          src={icon}
          srcSet={`${icon2x} 2x`}
          alt=""
        />
      </div>
      <Gap size="m" />

      {instructions?.map((instruciton, index) => (
        <Fragment key={instruciton.id}>
          {index > 0 && <Gap size="s" />}
          <ThemePlate
            title={
              <div className={styles.listItem}>
                <span className={styles.listNumber}>{index + 1}</span>
                {instruciton.name}
              </div>
            }
            onClick={() => setInstructionId(instruciton.id)}
          />
        </Fragment>
      ))}

      <Spinner visible={instructionsPending} />

      <Portal id={MANUAL_MODAL_DRAWER_ID}>
        <FunctionalModalSidebar
          open={Boolean(instructionId)}
          onClose={() => setInstructionId(0)}
          zIndex={10}
        >
          <InstructionThemeDetail
            themeId={instructionId}
            onTest={setTestTheme}
          />
        </FunctionalModalSidebar>
      </Portal>

      <Portal id={MANUAL_MODAL_DRAWER_ID}>
        <FunctionalModalSidebar
          open={Boolean(testTheme)}
          onClose={() => setTestTheme(null)}
          zIndex={10}
        >
          {testTheme && (
            <InstructionThemeTest
              themeId={testTheme.id}
              title={testTheme.name}
              onNextTheme={handleNextTheme}
              onThemeList={handleToThemeList}
            />
          )}
        </FunctionalModalSidebar>
      </Portal>
    </div>
  );
});
