import {
  DIRECTION_TYPES,
  TRANSFER_TYPE_ICON,
  TRANSFER_TYPE_TEXT,
} from './constants';

import styles from './Direction.module.css';

interface DirectionProps {
  type: DIRECTION_TYPES;
}

export const Direction = ({ type }: DirectionProps) => {
  const Icon = TRANSFER_TYPE_ICON[type];
  const text = TRANSFER_TYPE_TEXT[type];

  const iconSize = type === DIRECTION_TYPES.INTRA ? 16 : 18;

  return text && Icon ? (
    <div className={styles.direction}>
      <Icon className={styles.icon} width={iconSize} height={iconSize} />
      <span className={styles.text}>{text}</span>
    </div>
  ) : null;
};
