import log from 'loglevel';
import { useInfiniteQuery } from 'react-query';

import { NewsRequestType } from '../lib/getNews';

import { getNewsFn } from '../model/types';

//
export const useNews = (getNews: getNewsFn, props: NewsRequestType = {}) => {
  return useInfiniteQuery(
    ['news', props.isins],
    ({ pageParam: cursor }) => {
      const defaultProps = {
        count: 10,
      };

      return getNews({ ...defaultProps, ...props, cursor });
    },
    {
      refetchInterval: props.refetchInterval,
      onError: () => {
        log.error('Ошибка запроса новостей по эмитенту');
      },
      getNextPageParam: (lastPage) => lastPage.cursor,
      enabled: true,
    }
  );
};
