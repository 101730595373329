import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getContestProfile } from '../api/getContestProfile';

export const useContestProfile = (contestId: number) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'contest/profile', { contestId }],
    () => getContestProfile({ contestId }),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message ||
            'При запросе профиля участника конкурса произошла ошибка',
        });
      },
    }
  );
};
