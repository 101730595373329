import isNull from 'lodash/isNull';
import noop from 'lodash/noop';
import round from 'lodash/round';
import { FC, SetStateAction, useCallback } from 'react';

import { ObjectGroup, OrderType } from '@terminal/core/lib/client/entities';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { getStepDecimals } from '@terminal/core/lib/format';
import { getPriceError } from '@terminal/core/lib/helpers/getPriceError';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';

import {
  PriceInputComponent,
  PriceInputProps,
} from '../../../../features/PriceInput';
import { usePriceInput } from './usePriceInput';

interface Props
  extends Pick<
    PriceInputProps,
    | 'isChart'
    | 'label'
    | 'orderType'
    | 'side'
    | 'isPriceStreamed'
    | 'onFocus'
    | 'onClick'
    | 'containerClassName'
  > {
  price: number;
  priceTouched: boolean;
  idFi: number;
  fullFi?: ExtendedFI;
  priceStep?: number;
  tradeLimits: TradeLimitsResult | null;
  handleSetPrice: (value?: number, clear?: boolean) => void;
  setPrice: (value: number) => void;
  setSecondPrice?: (value: number) => void;
  setPriceStreamed: (value: SetStateAction<boolean>) => void;
  onPriceChange?: (price: number) => void;
}

export const PriceInput: FC<Props> = ({
  isChart,
  label,
  price,
  priceTouched,
  idFi,
  orderType,
  isPriceStreamed,
  side,
  fullFi,
  priceStep,
  tradeLimits,
  handleSetPrice,
  setPrice,
  setSecondPrice = noop,
  setPriceStreamed,
  onPriceChange,
  onFocus,
  onClick,
  containerClassName,
}) => {
  const { decimalsNumber, priceDecimals } = getStepDecimals(priceStep);

  const error = getPriceError(price, priceTouched, priceStep);

  const isBracket = orderType === OrderType.BRS || orderType === OrderType.TBRS;
  const brsSide = side === 'buy' ? 'sell' : 'buy';
  const isBond = fullFi?.idObjectGroup === ObjectGroup.Bonds;

  const { limitPriceAdditional, clearPricesAdditional, limPAdSuffix } =
    usePriceInput({
      idFi,
      side,
      orderType,
      priceStep,
      setPrice,
      isPriceStreamed,
      setSecondPrice,
      tradeLimits,
      setPriceStreamed,
    });

  const handlePriceChange = useCallback(
    (_, { value }) => {
      const price = isNull(value)
        ? 0
        : round(value / priceDecimals, decimalsNumber);

      handleSetPrice(price, true);
      onPriceChange?.(price);
    },
    [priceDecimals, decimalsNumber, handleSetPrice, onPriceChange]
  );

  const handleAdd = useCallback(() => {
    if (priceStep) {
      handleSetPrice(round(price + priceStep, decimalsNumber), true);
      clearPricesAdditional();
    }
  }, [decimalsNumber, priceStep, price, clearPricesAdditional, handleSetPrice]);

  const handleSub = useCallback(() => {
    if (priceStep) {
      handleSetPrice(price > priceStep ? price - priceStep : 0, true);
      clearPricesAdditional();
    }
  }, [priceStep, price, clearPricesAdditional, handleSetPrice]);

  const handleBlur = useCallback(() => handleSetPrice(), [handleSetPrice]);

  const currency = getSymbolToShow(fullFi?.currencyCode, fullFi?.idObjectGroup);

  return (
    <PriceInputComponent
      withShortcuts
      isChart={isChart}
      label={label}
      disabled={orderType === OrderType.MKT}
      value={price * priceDecimals}
      minority={priceDecimals}
      currency={currency}
      orderType={orderType}
      side={isBracket ? brsSide : side}
      allowStreamPrice={!isBracket}
      suffix={isBond ? '%' : currency + limPAdSuffix}
      limitPriceAdditional={limitPriceAdditional}
      onChange={handlePriceChange}
      onAdd={handleAdd}
      onSub={handleSub}
      isPriceStreamed={isPriceStreamed}
      setPriceStreamed={setPriceStreamed}
      onFocus={onFocus}
      onClick={onClick}
      onBlur={handleBlur}
      error={error}
      containerClassName={containerClassName}
    />
  );
};
