import React, { FC } from 'react';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { BellAddMIcon } from '@alfalab/icons-glyph/BellAddMIcon';

import styles from './Empty.module.css';

export const Empty: FC = () => {
  return (
    <div className={styles.root}>
      <SuperEllipse size={64}>
        <BellAddMIcon />
      </SuperEllipse>
      <Typography.Text
        weight="bold"
        view="primary-medium"
        className={styles.title}
      >
        У вас пока нет ни одного алерта
      </Typography.Text>
      <Typography.Text view="secondary-small" className={styles.description}>
        Создавайте уведомления о различных событиях, <br />
        происходящих на рынке, прямо прямо на графике
      </Typography.Text>
    </div>
  );
};
