import cn from 'classnames';
import React, { FC } from 'react';

import styles from './Option.module.css';

export type OptionProps = {
  variant: 'solid' | 'dashed';
};

export const Option: FC<OptionProps> = (props) => {
  const { variant, children } = props;
  const lineClassName = cn(styles.line, {
    [styles.line_solid]: variant === 'solid',
    [styles.line_dashed]: variant === 'dashed',
  });

  return (
    <div className={styles.root}>
      <div className={lineClassName} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};
