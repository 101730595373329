import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { useOrderBook as useOrderBookCore } from '@terminal/alfadirect/hooks';
import { FinInfoExt } from '@terminal/core/types/quotes';

export const useOrderBook = (
  idFi: number,
  finInfoExt?: FinInfoExt,
  showSpread?: boolean,
  showEmptyPrice?: boolean
) => {
  const { OrderBookService: orderBookService } = useAlfaDirectContext();

  return useOrderBookCore(idFi, {
    showEmptyPrice,
    showSpread,
    finInfoExt,
    orderBookService,
  });
};
