import {
  ClientOperationEntity,
  OrderDirection,
} from '@terminal/core/lib/client/entities';

import {
  OperationHistoryItem,
  OperationHistoryResult,
  OperationItem,
} from '../model/operation';

export const operationMap = (entity: ClientOperationEntity): OperationItem => ({
  idOperation: entity.IdOperation,
  timeOperation: entity.TimeOperation,
  extOperNo: entity.ExtOperNo?.toString() ?? '',
  numEDocument: entity.NumEDocument?.toString() ?? '',
  idObject: entity.IdObject,
  idMarketBoard: entity.IdMarketBoard,
  idOperationType: entity.IdOperationType,
  buySell: entity.BuySell,
  quantity: entity.Quantity,
  price: entity.Price,
  value: entity.Value,
  idObjectCurrency: entity.IdObjectCurrency,
  yield: entity.Yield,
  idSubAccount: entity.IdSubAccount,
  idAccount: entity.IdAccount,
  description: entity.Description,
  idOperationStatus: entity.IdOperationStatus,
  settleDate: entity.SettleDate,
  deliveryDate: entity.DeliveryDate,
  repoTerm: entity.RepoTerm,
  repoDate: entity.RepoDate,
  idRazdel: entity.IdRazdel,
  accruedInt: entity.AccruedInt,
  version: entity.Version.toString(),
  operation: entity.Operation,
  idOperationGroup: entity.IdOperationGroup,
});

export const operationHistoryMap = (
  result: OperationHistoryResult
): OperationHistoryItem => ({
  operation: {
    timeOperation: new Date(result.date),
    buySell: result.direction ?? OrderDirection.Undefined,
    idOperation: result.id,
    quantity: result.instrumentsQuantity ?? 0,
    value: result.totalValue?.amount ?? 0,
    price: result.instrumentValue?.amount ?? 0,
    settleDate: result.settlementDate
      ? new Date(result.settlementDate)
      : undefined,
    ...result,
  },
  operationDate: new Date(result.date).toDateString(),
  type: 'INVEST',
});
