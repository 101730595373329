import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getDividends } from '@terminal/core/lib/rest/investApi';
import { NotificationType } from '@terminal/core/types/ui';

export const useDividends = (isin?: string) => {
  const addNotification = useNotification();

  return useQuery(['getDividends', isin], () => getDividends(isin || ''), {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса информации по дивидендам',
        text: error?.message || '',
      });
    },
    enabled: Boolean(isin),
  });
};
