import { uniqBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { SelectProps } from '@alfalab/core-components/select';
import { Typography } from '@alfalab/core-components/typography';

import {
  SecuritiesContragent,
  SecuritiesContragentRequisites,
  SecuritiesPlaceItem,
  SecuritiesPosition,
  SecuritiesUserType,
} from '@terminal/core/lib/rest/lkSecurities';

import {
  conteragentKey,
  conteragentRequisitesKey,
  COUNTERAGENT_TYPES,
  getMarket,
  isCounteragentTypeShow,
  OTHER_CONTERAGENT,
  SECURITY_PLACES_INNER_BROKER_MAP,
  SECURITY_PLACES_MAP,
  SecurityDeponentPlaceCode,
  SecurityPlaceCode,
  securityPlaceKey,
} from '../../shared';
import {
  removeUnwantedChar,
  toSimilarLat,
  upperCase,
  validateConteragentAccDepo,
  validateCounterpartyPlace,
  validateCounterpartyPlaceOther,
  validateDepoAccount,
  validateDepoCode,
  validateDepoSection,
  validateSwift,
} from '../../validation';
import { CounterpartyCommonForm } from './CounterpartyCommonForm';
import { CounterpartyEuroclearForm } from './CounterpartyEuroclearForm';
import { CounterpartyReestrForm } from './CounterpartyReestrForm';

import { useValidation } from '../../hooks';

interface SecurityCounterpartySectionProps {
  transferType: 'in' | 'out';
  title?: string;
  positions?: SecuritiesPosition[];
  placeList?: SecuritiesPlaceItem[];
  placeListPending: boolean;
  ownershipChange: boolean;
  userTypeList?: SecuritiesUserType[];
  userTypeListPending: boolean;
  conteragents?: SecuritiesContragent[];
  conteragentsPending?: boolean;
  onConteragentRequisiteChange?: (
    conteragent: string,
    requisites: SecuritiesContragentRequisites
  ) => void;
  postionAlfa: SecuritiesPosition | null;
  selectedCounterpartyPlace: SecuritiesPlaceItem | null;
  onSelectedCounterpartyPlaceChange: (item: SecuritiesPlaceItem | null) => void;
  userType: string;
  onUserTypeChange: (type: string) => void;
  counteragentType?: COUNTERAGENT_TYPES | undefined;
  onCounteragentTypeChange?: (type: COUNTERAGENT_TYPES) => void;
  depoAccount: string;
  onDepoAccountChange: (acc: string) => void;
  depoSection: string;
  onDepoSectionChange: (acc: string) => void;
  depoCode: string;
  onDepoCodeChange: (code: string) => void;
  counterpartyPlace: string;
  onCounterpartyPlaceChange: (place: string) => void;
  counterpartyPlaceOther: string;
  onCounterpartyPlaceOtherChange: (place: string) => void;
  conteragentAccDepo: string;
  onConteragentAccDepoChange: (acc: string) => void;
  counteragentAlfaBank: boolean;
  onCounteragentAlfaBankChange: (check: boolean) => void;
  swift: string;
  onSwiftChange: (swift: string) => void;
  selectedConteragentKey: string;
  onConteragentKeyChange: (key: string) => void;
  selectedRequisiteKey: string;
  onRequisiteKeyChange: (key: string) => void;

  requisitesSection?: React.ReactNode;
}

export const SecurityCounterpartySection = ({
  transferType,
  title,
  positions,
  placeList,
  placeListPending,
  ownershipChange,
  userTypeList,
  userTypeListPending,
  conteragents,
  conteragentsPending,
  onConteragentRequisiteChange,
  postionAlfa,
  selectedCounterpartyPlace,
  onSelectedCounterpartyPlaceChange,
  userType,
  onUserTypeChange,
  counteragentType,
  onCounteragentTypeChange,
  depoAccount,
  onDepoAccountChange,
  depoSection,
  onDepoSectionChange,
  depoCode,
  onDepoCodeChange,
  counterpartyPlace,
  onCounterpartyPlaceChange,
  counterpartyPlaceOther,
  onCounterpartyPlaceOtherChange,
  conteragentAccDepo,
  onConteragentAccDepoChange,
  counteragentAlfaBank,
  onCounteragentAlfaBankChange,
  swift,
  onSwiftChange,
  selectedConteragentKey,
  onConteragentKeyChange,
  selectedRequisiteKey,
  onRequisiteKeyChange,

  requisitesSection,
}: SecurityCounterpartySectionProps) => {
  const marketAlfa = postionAlfa ? postionAlfa.placeCode : '';
  const counterpartyMarket = selectedCounterpartyPlace
    ? selectedCounterpartyPlace.placeCode
    : '';
  const market = getMarket(marketAlfa, counterpartyMarket);

  const values = useMemo(
    () => ({
      depoAccount,
      depoSection,
      depoCode,
      counterpartyPlace,
      counterpartyPlaceOther,
      conteragentAccDepo,
      swift,
    }),
    [
      depoAccount,
      depoSection,
      depoCode,
      counterpartyPlace,
      counterpartyPlaceOther,
      conteragentAccDepo,
      swift,
    ]
  );
  const validators = useMemo(
    () => ({
      depoAccount: (value: string) => validateDepoAccount(value, market),
      depoSection: (value: string) =>
        validateDepoSection(
          value,
          counterpartyMarket,
          marketAlfa,
          selectedCounterpartyPlace
            ? (selectedCounterpartyPlace.deponetPlaceCode as SecurityDeponentPlaceCode)
            : SecurityDeponentPlaceCode.EMPTY
        ),
      depoCode: (value: string) => validateDepoCode(value, counterpartyMarket),
      counterpartyPlace: validateCounterpartyPlace,
      counterpartyPlaceOther: validateCounterpartyPlaceOther,
      conteragentAccDepo: validateConteragentAccDepo,
      swift: validateSwift,
    }),
    [market, marketAlfa, counterpartyMarket, selectedCounterpartyPlace]
  );

  const { handleChange, handleBlur, touched, errors } = useValidation({
    values: values,
    transforms: {
      depoAccount: (value) =>
        toSimilarLat(upperCase(removeUnwantedChar(value))),
      depoSection: (value) =>
        toSimilarLat(upperCase(removeUnwantedChar(value))),
      depoCode: (value) => toSimilarLat(upperCase(removeUnwantedChar(value))),
      conteragentAccDepo: (value) =>
        toSimilarLat(upperCase(removeUnwantedChar(value))),
    },
    validators: validators,
    handlers: {
      depoAccount: (value) => onDepoAccountChange(value as string),
      depoSection: (value) => onDepoSectionChange(value as string),
      depoCode: (value) => onDepoCodeChange(value as string),
      counterpartyPlace: (value) => onCounterpartyPlaceChange(value as string),
      counterpartyPlaceOther: (value) =>
        onCounterpartyPlaceOtherChange(value as string),
      conteragentAccDepo: (value) => onConteragentAccDepoChange(value),
      swift: (value) => onSwiftChange(value),
    },
  });

  const placeListOptions = useMemo(() => {
    if (placeList && postionAlfa) {
      return (
        placeList
          .filter((place) => {
            // Фильтруем рынки по матрице
            const MARKETS = counteragentAlfaBank
              ? SECURITY_PLACES_INNER_BROKER_MAP
              : SECURITY_PLACES_MAP;

            return (
              Boolean(MARKETS[postionAlfa.deponetPlaceCode]) &&
              MARKETS[postionAlfa.deponetPlaceCode].indexOf(
                place.deponetPlaceCode as SecurityDeponentPlaceCode
              ) >= 0
            );
          })
          // Зачислять из РДЦ НТР можно только с РДЦ НТР
          .filter(
            (place) =>
              transferType !== 'in' ||
              place.deponetPlaceCode !==
                SecurityDeponentPlaceCode.OTC_SPBEX_NOTRADE ||
              (place.deponetPlaceCode ===
                SecurityDeponentPlaceCode.OTC_SPBEX_NOTRADE &&
                postionAlfa.deponetPlaceCode ===
                  SecurityDeponentPlaceCode.OTC_SPBEX_NOTRADE)
          )
          .map((place) => ({
            key: securityPlaceKey(place),
            content: place.name,
          }))
      );
    }

    return [];
  }, [
    placeList,
    postionAlfa,
    // ownershipChange,
    transferType,
    counteragentAlfaBank,
  ]);

  const handlePlaceChange: SelectProps['onChange'] = ({ selected }) => {
    const selectedPlace = placeList
      ? placeList.find(
          (place) => selected && securityPlaceKey(place) === selected.key
        ) || null
      : null;
    onSelectedCounterpartyPlaceChange(selectedPlace);
  };

  const userTypeOptions = useMemo(() => {
    if (userTypeList) {
      return userTypeList.map((type) => ({
        key: type.name,
        content: type.description,
      }));
    }

    return [];
  }, [userTypeList]);

  const handleUserTypeChange: SelectProps['onChange'] = ({ selected }) => {
    onUserTypeChange(selected ? selected.key : '');
  };

  const handleCounteragentTypeChange: SelectProps['onChange'] = ({
    selected,
  }) => {
    if (onCounteragentTypeChange) {
      onCounteragentTypeChange(
        selected
          ? (selected.key as COUNTERAGENT_TYPES)
          : COUNTERAGENT_TYPES.NOT_SELECTED
      );
    }
  };

  const showConteragentType = useMemo(
    () => isCounteragentTypeShow(ownershipChange, counterpartyMarket),
    [ownershipChange, counterpartyMarket]
  );

  useEffect(() => {
    if (!showConteragentType && onCounteragentTypeChange) {
      onCounteragentTypeChange(COUNTERAGENT_TYPES.NOT_SELECTED);
    }
  }, [showConteragentType, onCounteragentTypeChange]);

  const counterAlfaTreatyOptions = useMemo(() => {
    if (positions && selectedCounterpartyPlace) {
      const options = positions
        .filter((pos) => pos.placeCode === selectedCounterpartyPlace.placeCode)
        .map((pos) => ({
          content: pos.treaty.toString(),
          key: pos.depoAccount,
        }));

      return uniqBy(options, 'key');
    }

    return [];
  }, [positions, selectedCounterpartyPlace]);

  useEffect(() => {
    if (counterAlfaTreatyOptions.length === 0) {
      onConteragentAccDepoChange('');
    }
  }, [counterAlfaTreatyOptions, onConteragentAccDepoChange]);

  const handleCounterAlfaTreatyChange: SelectProps['onChange'] = ({
    selected,
  }) => {
    onConteragentAccDepoChange(selected ? selected.key : '');
  };

  // Контрагенты
  const conterpartyNameShow =
    (selectedConteragentKey === OTHER_CONTERAGENT && counterpartyMarket) ||
    (Boolean(selectedCounterpartyPlace) &&
      marketAlfa === SecurityPlaceCode.EUROTRADE);
  const conterpartyDepoAccountShow =
    (selectedConteragentKey !== '' && counterpartyMarket) || !conteragents;
  const conterpartyRequisitesEnabled =
    selectedConteragentKey === OTHER_CONTERAGENT ||
    selectedConteragentKey === '';

  const conteragentsOptions = useMemo(() => {
    if (
      conteragents &&
      counterpartyMarket &&
      marketAlfa === counterpartyMarket &&
      marketAlfa !== SecurityPlaceCode.EUROTRADE
    ) {
      const options = conteragents
        .filter((c) => c.srcPlaceCode === counterpartyMarket)
        .map((c) => ({
          key: conteragentKey(c),
          content: c.srcContragent,
        }));
      options.push({ key: OTHER_CONTERAGENT, content: 'Иной' });

      return options;
    }

    return [];
  }, [conteragents, counterpartyMarket, marketAlfa]);

  const handleConteragentChange: SelectProps['onChange'] = ({ selected }) => {
    onConteragentKeyChange(selected ? selected.key : '');
  };

  const selectedConteragent = useMemo(() => {
    if (
      conteragents &&
      selectedConteragentKey &&
      selectedConteragentKey !== OTHER_CONTERAGENT
    ) {
      return conteragents.find(
        (agent) => conteragentKey(agent) === selectedConteragentKey
      );
    }

    return;
  }, [conteragents, selectedConteragentKey]);

  const conteragentRequisitesOptions = useMemo(() => {
    if (selectedConteragent && selectedConteragent.requisites) {
      return selectedConteragent.requisites.map((req) => ({
        key: conteragentRequisitesKey(req),
        content: req.srcContragentAccount,
      }));
    }

    return [];
  }, [selectedConteragent]);

  const handleRequisiteChange: SelectProps['onChange'] = ({ selected }) => {
    onRequisiteKeyChange(selected ? selected.key : '');
  };

  useEffect(() => {
    if (conteragentRequisitesOptions && conteragentRequisitesOptions.length) {
      if (
        !conteragentRequisitesOptions.some(
          (opt) => opt.key === selectedRequisiteKey
        )
      ) {
        onRequisiteKeyChange(conteragentRequisitesOptions[0].key);
      }
    } else {
      onRequisiteKeyChange('');
    }
  }, [
    conteragentRequisitesOptions,
    selectedRequisiteKey,
    onRequisiteKeyChange,
  ]);

  useEffect(() => {
    if (!onConteragentRequisiteChange) {
      return;
    }

    if (selectedConteragent && selectedRequisiteKey) {
      const requisites = selectedConteragent.requisites.find(
        (req) => conteragentRequisitesKey(req) === selectedRequisiteKey
      );
      if (requisites) {
        onConteragentRequisiteChange(
          selectedConteragent.srcContragent,
          requisites
        );
      } else {
        onConteragentRequisiteChange('', {
          srcContragentAccount: '',
          srcDeponentCode: '',
          srcDepoAccountPart: '',
          swiftCode: '',
        });
      }
    } else {
      onConteragentRequisiteChange('', {
        srcContragentAccount: '',
        srcDeponentCode: '',
        srcDepoAccountPart: '',
        swiftCode: '',
      });
    }
  }, [selectedConteragent, selectedRequisiteKey, onConteragentRequisiteChange]);

  return (
    <>
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        defaultMargins={false}
      >
        {title || 'Откуда'}
      </Typography.Text>
      {(() => {
        if (
          marketAlfa === SecurityPlaceCode.EUROTRADE ||
          counterpartyMarket === SecurityPlaceCode.EUROTRADE
        ) {
          return (
            <CounterpartyEuroclearForm
              counteragentAlfaBank={counteragentAlfaBank}
              onCounteragentAlfaBankChange={onCounteragentAlfaBankChange}
              conteragents={conteragents}
              conteragentsPending={conteragentsPending}
              selectedCounterpartyPlace={selectedCounterpartyPlace}
              conteragentAccDepo={conteragentAccDepo}
              marketAlfa={marketAlfa}
              selectedConteragentKey={selectedConteragentKey}
              selectedRequisiteKey={selectedRequisiteKey}
              userType={userType}
              depoAccount={depoAccount}
              depoSection={depoSection}
              counterpartyPlace={counterpartyPlace}
              swift={swift}
              ownershipChange={ownershipChange}
              transferType={transferType}
              placeListOptions={placeListOptions}
              placeListPending={placeListPending}
              conteragentsOptions={conteragentsOptions}
              conteragentRequisitesOptions={conteragentRequisitesOptions}
              counterAlfaTreatyOptions={counterAlfaTreatyOptions}
              conterpartyNameShow={Boolean(conterpartyNameShow)}
              conterpartyRequisitesEnabled={conterpartyRequisitesEnabled}
              conterpartyDepoAccountShow={Boolean(conterpartyDepoAccountShow)}
              handlePlaceChange={handlePlaceChange}
              handleConteragentChange={handleConteragentChange}
              handleRequisiteChange={handleRequisiteChange}
              handleCounterAlfaTreatyChange={handleCounterAlfaTreatyChange}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />
          );
        }
        if (counterpartyMarket === SecurityPlaceCode.REESTR) {
          return (
            <CounterpartyReestrForm
              counteragentAlfaBank={counteragentAlfaBank}
              onCounteragentAlfaBankChange={onCounteragentAlfaBankChange}
              selectedCounterpartyPlace={selectedCounterpartyPlace}
              handlePlaceChange={handlePlaceChange}
              ownershipChange={ownershipChange}
              marketAlfa={marketAlfa}
              depoAccount={depoAccount}
              counteragentType={counteragentType}
              handleCounteragentTypeChange={handleCounteragentTypeChange}
              transferType={transferType}
              placeListOptions={placeListOptions}
              placeListPending={placeListPending}
              userTypeOptions={userTypeOptions}
              userType={userType}
              userTypeListPending={userTypeListPending}
              handleUserTypeChange={handleUserTypeChange}
              conterpartyRequisitesEnabled={conterpartyRequisitesEnabled}
              counterpartyPlace={counterpartyPlace}
              requisitesSection={requisitesSection}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />
          );
        }

        return (
          <CounterpartyCommonForm
            counteragentAlfaBank={counteragentAlfaBank}
            onCounteragentAlfaBankChange={onCounteragentAlfaBankChange}
            conteragents={conteragents}
            conteragentsPending={conteragentsPending}
            selectedCounterpartyPlace={selectedCounterpartyPlace}
            conteragentAccDepo={conteragentAccDepo}
            marketAlfa={marketAlfa}
            market={market}
            selectedConteragentKey={selectedConteragentKey}
            userType={userType}
            userTypeListPending={userTypeListPending}
            depoAccount={depoAccount}
            depoSection={depoSection}
            depoCode={depoCode}
            counterpartyPlace={counterpartyPlace}
            counterpartyPlaceOther={counterpartyPlaceOther}
            selectedRequisiteKey={selectedRequisiteKey}
            ownershipChange={ownershipChange}
            transferType={transferType}
            placeListOptions={placeListOptions}
            placeListPending={placeListPending}
            conteragentsOptions={conteragentsOptions}
            conteragentRequisitesOptions={conteragentRequisitesOptions}
            counterAlfaTreatyOptions={counterAlfaTreatyOptions}
            conterpartyNameShow={Boolean(conterpartyNameShow)}
            conterpartyRequisitesEnabled={conterpartyRequisitesEnabled}
            conterpartyDepoAccountShow={Boolean(conterpartyDepoAccountShow)}
            handlePlaceChange={handlePlaceChange}
            handleConteragentChange={handleConteragentChange}
            handleRequisiteChange={handleRequisiteChange}
            handleCounterAlfaTreatyChange={handleCounterAlfaTreatyChange}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
          />
        );
      })()}
    </>
  );
};
