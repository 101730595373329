import { ANALYTICS_TOKEN, ANALYTICS_URL } from '@terminal/core/env';

import { DEFAULT_ANALYTICS_DATA } from './constants';

function paramsToObject(paramsString: string) {
  const params = new URLSearchParams(paramsString);
  const paramsObj = {};

  for (const param of params) {
    const key = param[0];

    paramsObj[key] = param[1];
  }

  return paramsObj;
}

let dataToSend: unknown[] = [];
const SEND_METRICS_INTERVAL = 10000;

setInterval(() => {
  const currentData = dataToSend;

  if (currentData.length === 0) {
    return;
  }

  dataToSend = [];

  const body = {
    data: currentData,
  };

  return fetch(`https://${ANALYTICS_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + ANALYTICS_TOKEN,
    },
    keepalive: true,
    body: JSON.stringify(body),
  });
}, SEND_METRICS_INTERVAL);

export function sendMetricsHandler(url: string, queryParams: string) {
  const data = paramsToObject(queryParams);

  Object.assign(data, DEFAULT_ANALYTICS_DATA);

  dataToSend.push(data);
}
