import React from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { useAppContext } from '@terminal/core/hooks/useAppContext';

import styles from './Crash.module.css';

export const Crash = () => {
  const { appLogo, supportPhone, supportEmail } = useAppContext();

  const reload = () => {
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div>{appLogo}</div>
        <div className={styles.info}>
          <Typography.Title
            view="small"
            weight="medium"
            tag="div"
            color="primary"
          >
            Что-то пошло не так
          </Typography.Title>
          <Typography.Text
            view="primary-medium"
            tag="div"
            color="secondary"
            className={styles.subTitle}
          >
            Попробуйте обновить страницу или зайти позже
          </Typography.Text>
          <ButtonDesktop
            className={styles.button}
            type="submit"
            size="s"
            onClick={reload}
          >
            Обновить
          </ButtonDesktop>
        </div>
        <div className={styles.footer}>
          <Typography.Text view="primary-small" color="secondary">
            Не получается войти?
            <br />
            Обратитесь в службу технической поддержки
          </Typography.Text>
          <div>
            <div>
              <Typography.Text
                view="primary-small"
                color="secondary"
                weight="bold"
              >
                {supportPhone}
              </Typography.Text>
            </div>
            <Typography.Text
              view="primary-small"
              color="secondary"
              weight="bold"
            >
              {supportEmail}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};
