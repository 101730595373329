import { Typography } from '@alfalab/core-components/typography';

import { SecuritiesOperationBasis } from '@terminal/core/lib/rest/lkSecurities';

import { FormatedText } from '../../../../shared';
import { SummaryField } from '../Summary';

import styles from './HistoryItemDetail.module.css';

interface OperationBasisProps {
  operationBasis: SecuritiesOperationBasis;
}

export const OperationBasis = ({ operationBasis }: OperationBasisProps) => {
  const notEmpty: boolean = Object.getOwnPropertyNames(operationBasis).some(
    (key) => Boolean(operationBasis[key as keyof SecuritiesOperationBasis])
  );

  if (!notEmpty) {
    return null;
  }

  const comment = operationBasis.comment
    ? operationBasis.comment.replace(/;/g, ';\n\r')
    : '';

  return (
    <>
      <Typography.Text
        view="primary-small"
        tag="p"
        color="primary"
        weight="bold"
        className={styles.sectionTitle}
      >
        Дополнительно
      </Typography.Text>
      <div className={styles.section}>
        {Boolean(operationBasis.documents) && (
          <SummaryField
            fullWidth
            label="Основание для операции"
            value={operationBasis.documents.map(
              (doc: string, index: number) => (
                <FormatedText key={`document-${index}`} text={doc || ''} />
              )
            )}
          />
        )}

        <SummaryField
          fullWidth
          label="Комментарий"
          value={<FormatedText text={comment} />}
        />
      </div>
    </>
  );
};
