import { FC } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import { ObjectTypeCategory } from '@terminal/core/types/objectTypes';

import { useWidgetContext } from '../../../../shared';
import { AboutFutureBlock } from '../../features/AboutFutureBlock';
import { BondIssueInformation } from '../../features/BondIssueInformation';
import { InstrumentDataRow } from '../../ui';
import { ExchangeInformation } from '../../ui/ExchangeInformation/ExchangeInformation';

import { useInstrumentInfo } from '../../hooks/useInstrumentInfo';
import { useQuote } from '../../hooks/useQuote';

import classes from './InstrumentInfoTab.module.css';

const InstrumentInfoTab: FC<{ idFi: number }> = ({ idFi }) => {
  const { fullFI, finInfoExt, priceDecimals, objectTypeCategory, isin } =
    useInstrumentInfo(idFi);

  const { useIssuer } = useWidgetContext();

  const { quoteData } = useQuote(idFi);
  const { isLoading, data: issuerData } = useIssuer(isin);

  if (isLoading) {
    return null;
  }

  const isBond = objectTypeCategory === ObjectTypeCategory.Bond;
  const isStock = objectTypeCategory === ObjectTypeCategory.Stock;
  const isFund = objectTypeCategory === ObjectTypeCategory.Fund;
  const isFuture = objectTypeCategory === ObjectTypeCategory.Future;

  return (
    <div>
      <div className={classes.mainInfoBlock}>
        <div className={classes.descriptionText}>
          <Typography.Text color="secondary" view="primary-small">
            {issuerData?.description}
          </Typography.Text>
        </div>
        {issuerData?.headcount && isStock && (
          <InstrumentDataRow
            title="Количество сотрудников"
            value={<Amount value={issuerData.headcount} minority={0} />}
          />
        )}
        {issuerData?.fundType && isFund && (
          <InstrumentDataRow title="Тип фонда" value={issuerData?.fundType} />
        )}
        {issuerData?.website && (
          <InstrumentDataRow
            title="Вебсайт"
            value={
              <Link
                href={issuerData.website.url}
                view="default"
                underline={false}
                rel="noreferrer noopener"
              >
                {issuerData.website.hyperlink}
              </Link>
            }
          />
        )}
      </div>
      {isBond && <BondIssueInformation fullFI={fullFI} quoteData={quoteData} />}
      {isFuture && (
        <AboutFutureBlock
          fullFI={fullFI}
          finInfoExt={finInfoExt}
          priceDecimals={priceDecimals}
        />
      )}
      <ExchangeInformation
        isin={isin}
        finInfoExt={finInfoExt}
        fullFI={fullFI}
        priceDecimals={priceDecimals}
      />
    </div>
  );
};

export { InstrumentInfoTab };
