import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getMoneyRequisitesListGoInvest } from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useMoneyRequisitesList = (treaty: number | undefined) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.MoneyRequisitesList, { treaty }],
    () => getMoneyRequisitesListGoInvest(treaty as number),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'При запросе реквизитов произошла ошибка',
        });
      },
      enabled: Boolean(treaty),
    }
  );
};
