import { localStorage } from '@terminal/core/lib/storages';

import { PreferredTheme } from '../../../shared/lib/themeContext';

class PreferredThemeStorage {
  private readonly storageKey = 'PREFERRED_THEME';

  public getValue(): PreferredTheme {
    return localStorage.getItem<PreferredTheme>(this.storageKey, 'dark');
  }

  public setValue = (preferredTheme: PreferredTheme) => {
    localStorage.setItem(this.storageKey, preferredTheme);
  };
}

export const preferredThemeStorage = new PreferredThemeStorage();
