import { useMutation } from 'react-query';

import { trackPriceService } from '../trackPriceService';

import useNotification from '../../../../hooks/useNotification';

import { NotificationType } from '../../../../types/ui';

export const useTrackPriceAdd = () => {
  const addNotification = useNotification();

  return useMutation(trackPriceService.addAlert.bind(trackPriceService), {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка добавления уведомления о цене',
        text: error?.message,
      });
    },
  });
};
