import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useEffect, useMemo, useState } from 'react';
import { Loader } from '@alfalab/core-components/loader';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { TradingCertificateLock } from '@terminal/common/features-DEPRECATED/TradingCertificateLock';
import { CertificateStateType } from '@terminal/core/lib/client/entities';
import {
  AccountInfo,
  AccountInfoGI,
  QualStatus,
} from '@terminal/core/lib/rest/lkAccountInfo';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { shallow, useStore } from '@terminal/core/store';
import { useAlfaDirectContext } from '@terminal/widgets';

import { FormControlSummary } from '../../../shared/ui/FormControlSummary';
import { QualInvestor } from '../../qualinvestor';
import { RiskProfileEntryPoint } from '../../riskprofile';
import { AccountControl } from './AccountControl';
import { AccountRequisites } from './AccountRequisites';
import { Platforms } from './Platforms';

import { useTestingList } from '../../hooks/useTestingList';

import styles from './MainInfo.module.css';

interface MainInfoProps {
  treaty: Treaty | null;
  accountInfo?: AccountInfo | AccountInfoGI;
  accountInfoPending: boolean;
  onAccountSave: () => void;
  isMobile?: boolean;
}

export const MainInfo = ({
  treaty,
  accountInfo,
  accountInfoPending,
  onAccountSave,
  isMobile,
}: MainInfoProps) => {
  const isGoInvest = !isMobile;
  const { data: testingList } = useTestingList(treaty?.treaty, true);
  const [qualStatus, setQualStatus] = useState<QualStatus>();
  const { user } = useAlfaDirectContext();

  const [workingCertificate] = useStore(
    (store) => [store.workingCertificate],
    shallow
  );

  const testItems = testingList?.testStatusItems || [];
  const isTest2Passed = Boolean(
    testItems.find((test) => test.testId === 2 && test.completed)
  );
  const isTest3Passed = Boolean(
    testItems.find((test) => test.testId === 3 && test.completed)
  );
  const isQualifiedInvestor = isGoInvest
    ? Boolean(accountInfo?.qualifiedInvestorDate)
    : Boolean(testingList?.qualifiedInvestorDate);
  const isFirm = isGoInvest
    ? Boolean(treaty?.isFirm)
    : Boolean(testingList?.isFirm);

  const operationModeAvailable: boolean = isGoInvest
    ? isQualifiedInvestor || isFirm
    : isTest2Passed || isQualifiedInvestor || isFirm;
  const fortsRegAvailable: boolean = isGoInvest
    ? (isTest2Passed && isTest3Passed) || isQualifiedInvestor || isFirm
    : isTest3Passed || isQualifiedInvestor || isFirm;

  const qualifiedInvestorDate = accountInfo?.qualifiedInvestorDate || '';

  const userManagerStatus = useMemo(() => {
    if (user) {
      return accountInfo?.treatyManagers?.find(
        (manager) => manager.sysName === user.login
      );
    }

    return undefined;
  }, [accountInfo, user]);

  const isAdministrator = useMemo(() => {
    if (isGoInvest && !isFirm) {
      return userManagerStatus?.accessLevel === 'Администратор';
    }

    return false;
  }, [isFirm, isGoInvest, userManagerStatus]);

  const isTrader = useMemo(() => {
    return userManagerStatus?.accessLevel === 'Трейдер';
  }, [userManagerStatus]);

  useEffect(() => {
    if (accountInfo?.qualInvestorStatus) {
      setQualStatus(accountInfo?.qualInvestorStatus);
    }
  }, [accountInfo?.qualInvestorStatus]);

  if (accountInfoPending) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  if (!accountInfo || !treaty) {
    return (
      <Space>
        <FormControlSummary label={isMobile ? 'Счёт' : 'Номер'}>
          {treaty?.treaty}
        </FormControlSummary>
        <Typography.Text view="secondary-large">
          Данные по счету не найдены
        </Typography.Text>
      </Space>
    );
  }

  // показываем окваливание только физлицам-владельцам в статусе Администратор
  // в ГоИнвесте при наличии сертификата
  const isQualInvestorAvailable =
    isGoInvest &&
    qualStatus &&
    !isFirm &&
    isAdministrator &&
    treaty.isOwner &&
    workingCertificate &&
    workingCertificate.state === CertificateStateType.Ready;

  return (
    <div className={styles.container}>
      <Space fullWidth={true} direction="vertical" className={styles.info}>
        <FormControlSummary label={isMobile ? 'Счёт' : 'Номер'}>
          {treaty?.treaty}
        </FormControlSummary>
        <FormControlSummary label="Владелец">
          {accountInfo?.owner}
        </FormControlSummary>
        {!isMobile && isQualInvestorAvailable && (
          <div className={styles.container}>
            <Typography.Text view="secondary-large" className={styles.label}>
              Статус владельца
            </Typography.Text>
            <QualInvestor
              treaty={treaty.treaty}
              qualifiedInvestorDate={qualifiedInvestorDate}
              qualStatus={qualStatus}
              onAccountSave={onAccountSave}
            />
          </div>
        )}
      </Space>

      {Boolean(accountInfo.bankAccounts || accountInfo.depoAccounts) && (
        <div className={styles.requisites}>
          <AccountRequisites
            bankAccounts={accountInfo.bankAccounts}
            depoAccounts={accountInfo.depoAccounts}
            treaty={treaty}
            openDate={format(parseISO(accountInfo.openDate), 'd MMMM yyyy', {
              locale: ru,
            })}
            spbClientField={accountInfo.spbClientField}
            isMobile={isMobile}
          />
        </div>
      )}

      <TradingCertificateLock>
        <div className={styles.control}>
          <AccountControl
            treaty={treaty}
            accountInfo={accountInfo}
            testPassed={operationModeAvailable}
            onAccountSave={onAccountSave}
            isMobile={isMobile}
          />
        </div>

        {!isMobile && <RiskProfileEntryPoint treaty={treaty.treaty} />}

        {!isMobile && qualStatus !== undefined && (
          <div className={styles.platforms}>
            <Platforms
              treaty={treaty}
              testPassed={fortsRegAvailable}
              qualStatus={qualStatus}
              testItems={testItems}
              fortsDisabled={isTrader}
            />
          </div>
        )}
      </TradingCertificateLock>
    </div>
  );
};
