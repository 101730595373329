import { useEffect, useMemo, useState } from 'react';
import { Badge } from '@alfalab/core-components/badge';
import { IconButton } from '@alfalab/core-components/icon-button';
// eslint-disable-next-line no-restricted-imports
import { Tooltip } from '@alfalab/core-components/tooltip';
import { Typography } from '@alfalab/core-components/typography';
import { CommentWithTextMIcon } from '@alfalab/icons-glyph/CommentWithTextMIcon';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';

import {
  chatWindowEventEmitter,
  ChatWindowEvents,
} from '@terminal/chat/components/ChatWindow';
import { useStore as useChatStore } from '@terminal/chat/store/useStore';
import { usePrevious } from '@terminal/core/hooks';
import { trackClickOnChat } from '@terminal/core/lib/analytics';
import { plural } from '@terminal/core/lib/plural';

import styles from './ChatButton.module.css';

export const ChatButton = () => {
  const [tooltipVisibility, setTooltipVisibility] = useState<boolean>(false);

  const unreadMsgCountMap = useChatStore((state) => state.unreadMsgCount);

  const unreadMsgCount = useMemo<number>(
    () =>
      Object.values(unreadMsgCountMap).reduce((sum, value) => sum + value, 0),
    [unreadMsgCountMap]
  );

  const prevUnreadMsgCount = usePrevious(unreadMsgCount);

  useEffect(() => {
    if (unreadMsgCount > 0 && prevUnreadMsgCount !== unreadMsgCount) {
      setTooltipVisibility(true);
    }
  }, [prevUnreadMsgCount, unreadMsgCount]);

  const handleCloseTooltip = () => {
    setTooltipVisibility(false);
  };

  const handleOpenChatWindow = () => {
    trackClickOnChat();
    handleCloseTooltip();
    chatWindowEventEmitter.emit(ChatWindowEvents.CHAT_WINDOW_OPEN);
  };

  return (
    <Tooltip
      open={tooltipVisibility}
      position="bottom-start"
      trigger="click"
      preventOverflow={false}
      offset={[0, 24]}
      content={
        <div className={styles.tooltipContent} onClick={handleOpenChatWindow}>
          <CommentWithTextMIcon
            width={18}
            height={18}
            fill="var(--color-light-graphic-secondary)"
          />
          <div>
            <Typography.Text tag="div" view="secondary-large" weight="bold">
              Чат поддержки
            </Typography.Text>
            <Typography.Text
              tag="div"
              view="secondary-small"
              className={styles.description}
            >
              {`У вас ${unreadMsgCount} ${plural(
                [
                  'непрочитанное сообщение',
                  'непрочитанных сообщения',
                  'непрочитанных сообщений',
                ],
                unreadMsgCount
              )}. Нажмите на уведомление для
              просмотра`}
            </Typography.Text>
          </div>
          <IconButton
            size="xs"
            view="secondary"
            onClick={(e) => {
              e.stopPropagation();
              handleCloseTooltip();
            }}
            icon={CrossCircleMIcon}
          />
        </div>
      }
      fallbackPlacements={['left', 'right']}
      popoverClassName={styles.tooltipPopover}
    >
      <>
        <IconButton
          size="xs"
          view="secondary"
          onClick={handleOpenChatWindow}
          icon={CommentWithTextMIcon}
        />
        {unreadMsgCount > 0 && (
          <Badge view="count" visibleIconOutline className={styles.chatBadge} />
        )}
      </>
    </Tooltip>
  );
};
