import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';
import { addAccountPreregister, AddAccountType } from '../api';

export const useAddAccountPreregister = (type: AddAccountType) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.AddAccountPreregister, { type }],
    () => addAccountPreregister(type),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'При запросе заявления произошла ошибка',
        });
      },
    }
  );
};
