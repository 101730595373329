import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';

import { CancelSecuritiesTransferRequest } from './types/CancelSecuritiesTransferRequest';

export const cancelSecuritiesTransfer = async (
  data: CancelSecuritiesTransferRequest
) => {
  return await signOperation(
    '/api/v2/transfers/securities/cancel',
    'POST',
    data.params,
    data.signOptions
  );
};
