import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC, Fragment } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import {
  Tab,
  TabsDesktop as Tabs,
} from '@alfalab/core-components/tabs/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { usePositionByIdFI } from '@terminal/alfadirect/hooks';
import { MINORITY } from '@terminal/core/constants/ui';
import { isValidCurrency } from '@terminal/core/lib/currencies';

import { useWidgetContext } from '../../../../shared';
import {
  InstrumentDataRow,
  InstrumentError,
  InstrumentLoader,
  InstrumentsSimpleTable,
} from '../../ui';
import { NextDividendsPaymentBlock } from './NextDividendsPaymentBlock';

import classes from './InstrumentDivedends.module.css';

interface Props {
  isin?: string;
  idFi: number;
}

const columns = [
  {
    title: 'Дата выплаты',
    width: 25,
  },
  {
    title: 'Период',
    width: 28,
  },
  {
    title: 'Доходность',
    width: 20,
  },
  {
    title: 'Выплата на акцию',
    width: 27,
  },
];

export const InstrumentDivedends: FC<Props> = ({ isin = '', idFi }) => {
  const { useDividends } = useWidgetContext();
  const { data: dividendsData, isLoading } = useDividends(isin);
  const position = usePositionByIdFI(idFi);

  if (isLoading) {
    return <InstrumentLoader />;
  }

  if (
    !dividendsData?.dividendHistory?.length &&
    !dividendsData?.dividendPlan?.length
  ) {
    return <InstrumentError />;
  }

  const hasPlanPayments = (dividendsData?.dividendPlan ?? []).length > 0;
  const [recentPayment] = dividendsData?.dividendHistory || [];
  const { value, currency, minorUnits } = recentPayment?.amount;

  const sortedData =
    dividendsData.dividendHistory
      ?.slice()
      .sort((a, b) => {
        return new Date(b.payDate).getTime() - new Date(a.payDate).getTime();
      })
      .map(
        ({
          payDate,
          period,
          yield: yieldValue,
          amount: { value, minorUnits, currency },
        }) => [
          format(new Date(payDate), 'dd.M.yyy', { locale: ru }),
          period?.toLocaleLowerCase(),
          <Fragment key={`percent-${payDate}`}>
            <Amount.Pure
              value={yieldValue * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
            />
            &nbsp;%
          </Fragment>,
          <Amount.Pure
            key={`amount-${payDate}`}
            value={Math.floor((value * MINORITY) / minorUnits)}
            currency={isValidCurrency(currency) ? currency : undefined}
            minority={MINORITY}
            view="withZeroMinorPart"
          />,
        ]
      ) ?? [];

  return (
    <>
      <Tabs containerClassName={classes.tabs} view="secondary" size="xxs">
        {[<Tab key="dividends" id="dividends" title="Дивиденды" />]}
      </Tabs>
      {hasPlanPayments && (
        <NextDividendsPaymentBlock items={dividendsData?.dividendPlan} />
      )}
      {position?.backPos && (
        <div className={classes.positionCount}>
          <Typography.TitleMobile
            view="xsmall"
            tag="div"
            weight="bold"
            color="secondary"
            className={classes.positionTitle}
          >
            В портфеле
          </Typography.TitleMobile>
          <InstrumentDataRow
            title="Количество"
            value={<>{position?.backPos} шт</>}
          />
          {hasPlanPayments && (
            <InstrumentDataRow
              title="Ближайшее начисление"
              value={
                <Amount.Pure
                  value={
                    Math.floor((value * 100) / minorUnits) * position.backPos
                  }
                  currency={currency as 'RUB'}
                  minority={100}
                  view="withZeroMinorPart"
                />
              }
            />
          )}
        </div>
      )}
      {sortedData.length > 0 && (
        <>
          <Typography.TitleMobile
            view="xsmall"
            tag="div"
            weight="bold"
            color="secondary"
            className={classes.dividendsTitle}
          >
            История
          </Typography.TitleMobile>
          <InstrumentsSimpleTable columns={columns} data={sortedData} />
        </>
      )}
    </>
  );
};
