import cn from 'classnames';
import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { Typography } from '@alfalab/core-components/typography';

import { OrderItem } from '@terminal/core/types/order';
import { OrderBookLine } from '@terminal/core/types/orderBook';

import { TableRow, TableRowProps } from './TableRow';

import styles from './OrderBookTable.module.css';

export type OrderBookTableProps = {
  nodeId?: string;
  idFI: number;
  lines: OrderBookLine[];
  upSpreadIndex: number;
  downSpreadIndex: number;
  showSpread?: boolean;
  listRef: React.RefObject<List<TableRowProps>> | null;
  price?: number;
  contextPrice?: number;
  setContextPrice?: (originalPrice: number) => void;
  onRowClick?: (originalPrice: number, side: 'buy' | 'sell') => void;
  onOrderClick?: MouseEventHandler;
  buyOrdersMap: Record<string, OrderItem[]>;
  sellOrdersMap: Record<string, OrderItem[]>;
  setIsOpenContext?: (state: boolean) => void;
  setAnchorPoint?: (state: { x: number; y: number }) => void;
  setSelectedOrdersIds?: (orders: string[]) => void;
  isBond: boolean;
  showYield?: boolean;
};

export const OrderBookTable = ({
  lines,
  listRef,
  nodeId,
  upSpreadIndex,
  downSpreadIndex,
  price,
  contextPrice,
  showSpread,
  isBond,
  showYield,
  ...props
}: OrderBookTableProps) => {
  const maxVolume = useMemo(
    () =>
      lines.reduce((acc, item) => {
        const itemVolume = item.BuyQty || item.SellQty || 0;

        return Math.max(acc, itemVolume);
      }, 0),
    [lines]
  );

  const getClassName = useCallback(
    (index: number, Price: number) =>
      cn(styles.tableRow, {
        [styles.tableRowOdd]: index & 1,
        [styles.dividerRow]:
          index === upSpreadIndex || index === downSpreadIndex,
        [styles.selectedRow]: price && Price === price,
        [styles.contextRow]:
          contextPrice && Price === contextPrice && price !== contextPrice,
        [styles.spreadRow]:
          showSpread && index >= upSpreadIndex && index < downSpreadIndex,
        [styles.upperSpreadRow]: index < upSpreadIndex && Price,
        [styles.lowerSpreadRow]: index >= downSpreadIndex && Price,
        [styles.bestBidAskRow]:
          index === downSpreadIndex || index === upSpreadIndex - 1,
        [styles.bondRow]: isBond && showYield,
      }),
    [
      contextPrice,
      downSpreadIndex,
      isBond,
      price,
      showSpread,
      showYield,
      upSpreadIndex,
    ]
  );

  const getListData = (width: number) => ({
    nodeId,
    maxVolume,
    lines,
    getClassName,
    hideOrders: (width ?? 0) < 380,
    isBond,
    showYield,
    ...props,
  });

  return (
    <Typography.Text
      view="secondary-large"
      weight="bold"
      className={styles.tableWrapper}
    >
      <AutoSizer>
        {({ height, width }) => (
          <List
            width={width ?? 0}
            height={height ?? 0}
            itemCount={lines.length}
            itemSize={20}
            itemKey={(index) => `${lines[index].Price}-${index}`}
            itemData={getListData(width)}
            overscanCount={5}
            ref={listRef}
          >
            {TableRow}
          </List>
        )}
      </AutoSizer>
    </Typography.Text>
  );
};
