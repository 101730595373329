/**
 * Для адаптивных компонентов из @alfalab/core-components
 * необходимо установить пропс breakpoint = 0 по умолчанию,
 * чтобы в PRO-терминале не было адаптивности.
 * Целевое решение — заменить все импорты
 * @alfalab/core-components/<component> на @alfalab/core-components/<component>/desktop.
 * В данный момент так сделать нельзя, потому что некоторые компоненты
 * из @packages/lk и @packages/common используются в PWA и для них нужно оставить адаптив.
 * @see https://jira.moscow.alfaintra.net/browse/ADWTCORE-480
 * */

import { Button } from '@alfalab/core-components/button';
import { Calendar } from '@alfalab/core-components/calendar';
import { CalendarInput } from '@alfalab/core-components/calendar-input';
import { CheckboxGroup } from '@alfalab/core-components/checkbox-group';
import { Confirmation } from '@alfalab/core-components/confirmation';
import { FormControl } from '@alfalab/core-components/form-control';
import { Input } from '@alfalab/core-components/input';
import { InputAutocomplete } from '@alfalab/core-components/input-autocomplete';
import { PickerButton } from '@alfalab/core-components/picker-button';
import { RadioGroup } from '@alfalab/core-components/radio-group';
import { Select } from '@alfalab/core-components/select';
import { Tag } from '@alfalab/core-components/tag';
import { Tooltip } from '@alfalab/core-components/tooltip';

Button.defaultProps = { breakpoint: 0 };
CalendarInput.defaultProps = { breakpoint: 0 };
Calendar.defaultProps = { breakpoint: 0 };
CheckboxGroup.defaultProps = { breakpoint: 0 };
Confirmation.defaultProps = { breakpoint: 0 };
FormControl.defaultProps = { breakpoint: 0 };
InputAutocomplete.defaultProps = { breakpoint: 0 };
Input.defaultProps = { breakpoint: 0 };
PickerButton.defaultProps = { breakpoint: 0 };
RadioGroup.defaultProps = { breakpoint: 0 };
Select.defaultProps = { breakpoint: 0 };
Tag.defaultProps = { breakpoint: 0 };
Tooltip.defaultProps = { breakpoint: 0 };
