import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import {
  cancelGenerateMultiplyReports,
  generateMultiplyReports,
  getMultiplyReportsStatus,
  ReportsMultiply,
} from '@terminal/core/lib/rest/lkDocuments';
import { NotificationType } from '@terminal/core/types/ui';

import { LK_API } from '../../const/externalUrls';

import { useDownloadFile } from '../../hooks';

const CHECK_STATUS_INTERVAL = 1000;

export const useMultiplyReportsGenerator = () => {
  const addNotification = useNotification();

  const [operation, setOperation] = useState<ReportsMultiply | null>(null);
  const [process, setProcess] = useState(false);
  const [file, setFile] = useState<DownloadedFile | null>(null);

  const initMutation = useMutation(generateMultiplyReports, {
    onSuccess: (data) => {
      setOperation(data);
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При создании операции произошла ошибка',
      });
    },
  });

  const statusMutation = useMutation(getMultiplyReportsStatus, {
    onSuccess: (data) => {
      setOperation(data);
    },
    onError: (error: any) => {
      setOperation(null);
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При проверке статуса произошла ошибка',
      });
    },
  });

  const cancelMutation = useMutation(cancelGenerateMultiplyReports, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При отмене операции произошла ошибка',
      });
    },
  });

  const { isLoading: archivePending, mutateAsync: downloadArchive } =
    useDownloadFile();

  const requestDownloadArchive = useCallback(
    async (processId: string) => {
      const url = `${LK_API.REPORTS_MULTIPLY_DOWNLOAD_BASE}/${processId}`;
      const file = await downloadArchive(url);

      setFile(file);
    },
    [downloadArchive]
  );

  useEffect(() => {
    if (operation && operation.state === 'InProgress') {
      setProcess(true);
      const timeout = setTimeout(() => {
        if (process) {
          statusMutation.mutateAsync(operation.id);
        }
      }, CHECK_STATUS_INTERVAL);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [operation, process, statusMutation]);

  useEffect(() => {
    if (operation && operation.state === 'Done') {
      requestDownloadArchive(operation.id);
      setOperation(null);
    }

    setProcess(false);
  }, [operation, requestDownloadArchive]);

  const cancel = async () => {
    reset();

    if (operation) {
      return await cancelMutation.mutateAsync(operation.id);
    }
  };

  const reset = () => {
    setOperation(null);
    setFile(null);
    setProcess(false);
  };

  return {
    create: initMutation.mutateAsync,
    initPending: initMutation.isLoading,
    status: operation,
    statusPending: statusMutation.isLoading,
    cancel: cancel,
    file: file,
    filePending: archivePending,
    processPending: process,
    reset: reset,
  };
};
