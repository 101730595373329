import get from 'lodash/get';

import { RazdelGroupType } from '@terminal/core/lib/client/entities';
import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';
import {
  getRequirementsType,
  MarginCall,
  MarginCallInfo,
  RequirementsType,
} from '@terminal/core/types/marginCall';

export const getTradeAccountMargin = (
  mobile: boolean,
  acc?: TradeAccount | null,
  marginCall?: MarginCallInfo
) => {
  let requirement: MarginCall | undefined;
  let requirementType: RequirementsType;

  if (marginCall && acc && !mobile) {
    let idRazdelGroup = RazdelGroupType.None;

    switch (acc.placeName) {
      case 'Рынок ценных бумаг':
        idRazdelGroup = RazdelGroupType.StocksAndBondsMarket;
        break;
      case 'Срочный рынок':
        idRazdelGroup = RazdelGroupType.DerivativesMarket;
        break;
      case 'Валютный рынок':
        idRazdelGroup = RazdelGroupType.DerivativesMarket;
        break;

      default:
        break;
    }

    const marginForAcc = get(marginCall, acc.subAccount);

    if (marginForAcc) {
      requirement = get(
        marginForAcc.marketsMarginCall,
        String(idRazdelGroup)
      ) as MarginCall | undefined;

      if (requirement) {
        requirementType = getRequirementsType(requirement);
      }
    }
  }

  return { requirement, requirementType };
};
