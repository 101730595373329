import React, { useEffect, useState } from 'react';

interface ValidationProps {
  values: { [s: string]: string };
  transforms?: { [s: string]: (value: string) => string };
  validators: { [s: string]: (value: string) => string | boolean };
  handlers: { [s: string]: ((value: string) => void) | undefined };
}

export const useValidation = ({
  values,
  transforms,
  validators,
  handlers,
}: ValidationProps) => {
  const [touched, setTouched] = useState<{ [s: string]: boolean }>({});
  const [errors, setErrors] = useState<{ [s: string]: string | boolean }>({});

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value;
    if (transforms && transforms[event.target.name]) {
      value = transforms[event.target.name](value);
    }

    if (typeof handlers[event.target.name] === 'function') {
      (handlers[event.target.name] as Function)(value);
    }
  };

  const handleBlur = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!touched[event.target.name]) {
      setTouched((prev) => ({ ...prev, [event.target.name]: true }));
    }
  };

  useEffect(() => {
    Object.keys(values).forEach((key) => {
      if (validators[key]) {
        const res = validators[key](values[key]);
        // В res либо описание ошибки, либо true/false тогда нужно инвертировать
        const error = typeof res === 'boolean' ? !res : res;
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: error,
        }));
      }
    });
  }, [values, validators]);

  return {
    handleChange,
    handleBlur,
    touched,
    errors,
  };
};
