import React from 'react';
import { Typography } from '@alfalab/core-components/typography';

import styles from './StaticField.module.css';

interface StaticFieldProps {
  /** Лейбл поля */
  label?: React.ReactNode;
  /** Содержимое поля */
  children?: React.ReactNode;
  /** Дополнительные классы */
  className?: string;
}

/**
 * Выводит пару лейбл / значение для не редактируемых полей в форме
 * @param label Лейбл поля
 * @param className Дополнительные классы
 * @param children Содержимое поля
 * @returns
 */
export const StaticField = ({
  label,
  className,
  children,
}: StaticFieldProps) => {
  return (
    <div className={(styles.container, className)}>
      <Typography.Text
        className={styles.label}
        view="secondary-small"
        tag="div"
      >
        {label}
      </Typography.Text>
      <Typography.Text view="secondary-large" tag="div">
        {children}
      </Typography.Text>
    </div>
  );
};
