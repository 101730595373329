import { parseISO, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { CalendarInput } from '@alfalab/core-components/calendar-input';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Input } from '@alfalab/core-components/input';
import { Loader } from '@alfalab/core-components/loader';
import { Select, SelectProps } from '@alfalab/core-components/select';
import { Field } from '@alfalab/core-components/select/components/field';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';

import {
  ReasonDocumentType,
  SecuritiesDocumentType,
  SecuritiesReasonDocument,
} from '@terminal/core/lib/rest/lkSecurities';

import {
  isDateValid,
  isDocumentNumberValid,
  isFieldFilled,
  removeUnwantedChar,
} from '../../validation';

import { useValidation } from '../../hooks';

interface DocumentFieldsProps {
  document: SecuritiesReasonDocument;
  documentTypes?: SecuritiesDocumentType[];
  documentTypesPending: boolean;
  onTypeChange: (id: number, type: ReasonDocumentType, name: string) => void;
  onNameChange: (value: string) => void;
  onNumberChange: (value: string) => void;
  onDateChange: (value: string) => void;
  onDelete?: () => void;
}

export const DocumentFields = ({
  document,
  documentTypes,
  documentTypesPending,
  onTypeChange,
  onNameChange,
  onNumberChange,
  onDateChange,
}: DocumentFieldsProps) => {
  const values = useMemo(
    () => ({
      docName: document.docName,
      number: document.number,
      date: document.date,
    }),
    [document]
  );
  const validators = useMemo(
    () => ({
      docName: isFieldFilled,
      number: isDocumentNumberValid,
      date: isDateValid,
    }),
    []
  );

  const { handleChange, handleBlur, touched, errors } = useValidation({
    values: values,
    transforms: {
      docName: (value) => removeUnwantedChar(value),
      number: (value) => removeUnwantedChar(value),
    },
    validators: validators,
    handlers: {
      docName: onNameChange,
      number: onNumberChange,
      orgWhen: onDateChange,
    },
  });

  const handleTypeChange: SelectProps['onChange'] = ({ selected }) => {
    if (documentTypes) {
      const typeKey = parseInt(selected?.key || '');
      const type = documentTypes.find((doc) => doc.id === typeKey);

      if (type) {
        onTypeChange(
          typeKey,
          type.docType,
          type.docType !== 'OTHER' ? type.name : ''
        );
      }
    }
  };

  const documentTypesOptions = useMemo(() => {
    if (documentTypes) {
      return documentTypes.map((doc) => ({
        key: doc.id.toString(),
        content: doc.name,
      }));
    }

    return [];
  }, [documentTypes]);

  const selectedType = useMemo(() => {
    if (documentTypes) {
      return documentTypes.find((doc) => doc.id === document.id);
    }
  }, [documentTypes, document.id]);

  const minDocDate = startOfDay(parseISO('2000-01-01T00:00:00')).getTime();

  return (
    <Space fullWidth direction="vertical">
      <Select
        label="Вид документа"
        placeholder="Выберите вид документа"
        size="s"
        labelView="outer"
        block
        options={documentTypesOptions}
        selected={document.id.toString()}
        onChange={handleTypeChange}
        Field={(props: FieldProps) => (
          <Field {...props} leftAddons={documentTypesPending && <Loader />} />
        )}
      />
      {selectedType?.docType === 'OTHER' && (
        <Input
          block
          size="s"
          name="docName"
          autoComplete="off"
          label="Наименование документа"
          placeholder="Укажите наименование документа"
          labelView="outer"
          value={document.docName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.docName ? errors.docName : ''}
        />
      )}

      <Row>
        <Col width={6}>
          <Input
            block
            label="Номер документа"
            labelView="outer"
            size="s"
            name="number"
            autoComplete="off"
            value={document.number}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.number ? errors.number : ''}
          />
        </Col>
        <Col width={6}>
          <CalendarInput
            size="s"
            block
            minDate={minDocDate}
            name="date"
            label="Дата документа"
            labelView="outer"
            autoComplete="off"
            value={document.date}
            onChange={(e, { value }) => onDateChange(value)}
            onInputChange={(e, { value }) => onDateChange(value)}
            onBlur={handleBlur}
            error={touched.date ? errors.date : ''}
          />
        </Col>
      </Row>
    </Space>
  );
};
