import { Button } from '@alfalab/core-components/button';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { IS_AI } from '@terminal/core/env';
import { TradingPlatformStateType } from '@terminal/core/lib/rest/lkAccountInfo';

import { RegistrationState } from './RegistrationState';
import { getStateLabel } from './shared';

import styles from './Platforms.module.css';

interface PlatformSeltProps {
  seltStatus: TradingPlatformStateType;
}

export const PlatformSelt = ({ seltStatus }: PlatformSeltProps) => (
  <div className={styles.platformItem}>
    <div className={styles.platformLabel}>
      <div>Валютный рынок</div>
      {seltStatus === 'NotRegistred' && IS_AI && (
        <div className={styles.platformTooltip}>
          <Tooltip
            position="top"
            trigger="click"
            content={
              <Typography.Text view="primary-small" color="secondary" tag="p">
                {IS_AI ? (
                  <>
                    Для регистрации на валютном рынке необходимо обратиться в{' '}
                    <br />
                    Отдел по работе с клиентами Альфа-Инвестиции
                    <br />
                    +7&nbsp;495&nbsp;786-48-80&nbsp;(ЮЛ)
                    <br />
                    +7&nbsp;495&nbsp;786-48-88&nbsp;(ФЛ)
                  </>
                ) : (
                  <>
                    Для регистрации на валютном рынке необходимо обратиться в{' '}
                    <br />
                    Отдел по работе с клиентами Go Invest
                    <br />
                    8&nbsp;(800)&nbsp;783-48-86
                    <br />
                    8&nbsp;(800)&nbsp;101-41-01
                  </>
                )}
              </Typography.Text>
            }
            fallbackPlacements={['left', 'right']}
          >
            <InformationCircleLineSIcon
              height={16}
              width={16}
              color="#8D8D93"
            />
          </Tooltip>
        </div>
      )}
    </div>
    <div className={styles.platformStatus}>
      <RegistrationState state={seltStatus} label={getStateLabel(seltStatus)} />
      {seltStatus === 'NotRegistred' && (
        <Button block size="xxs" disabled>
          Зарегистрировать
        </Button>
      )}
    </div>
  </div>
);
