import { RazdelGroupType } from '../lib/client/entities';

export const BalanceGroupNames: Record<number, string> = {
  [RazdelGroupType.None]: 'N/A',
  [RazdelGroupType.StocksAndBondsMarket]: 'РЦБ',
  [RazdelGroupType.DerivativesMarket]: 'ФОРТС',
  [RazdelGroupType.CurrencyMarket]: 'ВР',
  [RazdelGroupType.RDC]: 'НТР',
};

export const LIMIT_SERVICE_UNAVAILABLE_KEY = 'LIMIT_SERVICE_UNAVAILABLE';
