import { createContext, useContext } from 'react';

import { TextOrderService } from '../lib/services/textOrder';
import { TradingService } from '../lib/services/trading/tradingService';

export interface ServicesContext {
  tradingService: TradingService;
}

export const Context = createContext<ServicesContext>({
  tradingService: new TradingService(new TextOrderService()),
});

/**
 * @returns контекст содержащий собственные инстансы сервисов приложений
 * */
export function useServicesContext() {
  return useContext(Context);
}
