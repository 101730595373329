import { DBSchema, deleteDB, IDBPDatabase, openDB } from 'idb';
import { createJSONStorage, persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { hashSHA256 } from '@terminal/core/lib/hashSHA256';

import { AUTH_STORE_KEY } from './const/authStoreKey';

import { LoginPinCodeStore } from '../model/types/LoginPinCodeStore';

interface AuthDb extends DBSchema {
  pinCode: {
    key: number;
    value: string;
  };
  triesLeft: {
    key: number;
    value: number;
  };
}

const DB_VERSION = 1;

class AuthIDB {
  private db: IDBPDatabase<AuthDb>;

  init = async () => {
    this.db = await openDB<AuthDb>(AUTH_STORE_KEY, DB_VERSION, {
      upgrade(db) {
        db.createObjectStore('pinCode');
        db.createObjectStore('triesLeft');
      },
    });
  };

  getItem = async (): Promise<string | null> => {
    return JSON.stringify({
      pinCode: await this.db.get('pinCode', 1),
      triesLeft: await this.db.get('triesLeft', 1),
    });
  };

  setItem = async (_, value: string): Promise<void> => {
    try {
      const pinCode = JSON.parse(value)?.state?.pinCode;
      const triesLeft = JSON.parse(value)?.state?.triesLeft;

      if (!pinCode) {
        await this.db.delete('pinCode', 1);

        return;
      }

      if (!triesLeft) {
        await this.db.delete('triesLeft', 1);

        return;
      }

      await this.db.put('pinCode', pinCode, 1);
      await this.db.put('triesLeft', triesLeft, 1);
    } catch (err) {}
  };

  removeItem = async (): Promise<void> => {
    await this.db.delete('pinCode', 1);
  };
}

const storage = new AuthIDB();

export const useLoginPinCodeStore = createWithEqualityFn<LoginPinCodeStore>()(
  persist(
    (set, get) => ({
      synced: false,
      pinCode: null,
      triesLeft: 0,
      setTriesLeft: (count: number) => {
        set({ triesLeft: count });
      },
      hasPinCode: () => {
        return new Promise((resolve) => {
          if (get().synced) {
            resolve(Boolean(get().pinCode));
          } else {
            const handler = (state) => {
              if (state.synced) {
                unsub();
                resolve(Boolean(get().pinCode));
              }
            };

            const unsub = useLoginPinCodeStore.subscribe(handler);
          }
        });
      },
      resetPinCode: (): void => {
        set({ pinCode: null });
      },
      setPinCode: async (pinCode: string): Promise<void> => {
        const hash = await hashSHA256(pinCode);

        set({ pinCode: hash });
      },
      verifyPinCode: async (pinCode: string): Promise<boolean> => {
        const hash = await hashSHA256(pinCode);

        return hash === get().pinCode;
      },
    }),
    {
      name: AUTH_STORE_KEY,
      storage: createJSONStorage(() => storage),
    }
  ),
  shallow
);

// IDB создается асинхронно, ниже костыль чтобы заперсистить данные из хранилища
storage
  .init()
  .then(() => storage.getItem())
  .catch((e) => deleteDB(AUTH_STORE_KEY))
  .then((pinCodeStore) => {
    const pinCodeStoreObject = pinCodeStore ? JSON.parse(pinCodeStore) : {};

    useLoginPinCodeStore.setState({
      synced: true,
      pinCode: Object.hasOwn(pinCodeStoreObject, 'pinCode')
        ? pinCodeStoreObject.pinCode
        : null,
      triesLeft: Object.hasOwn(pinCodeStoreObject, 'triesLeft')
        ? pinCodeStoreObject.triesLeft
        : 2,
    });
  });
