import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ClockLineMIcon } from '@alfalab/icons-glyph/ClockLineMIcon';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';

import { CorporateActionHistoryHeaderStatus } from '@terminal/core/lib/rest/lkCorporateActions';

import styles from './HistoryStatus.module.css';

interface HistoryStatusProps {
  type: CorporateActionHistoryHeaderStatus;
  text: string;
}

const DOCUMENT_STATUS_ICON = {
  E: CheckmarkMIcon,
  S: ClockLineMIcon,
  R: CrossCircleMIcon,
};

export const HistoryStatus = ({ type, text }: HistoryStatusProps) => {
  const StatusIcon = DOCUMENT_STATUS_ICON[type];

  return (
    <div className={styles.status}>
      {!!StatusIcon && (
        <StatusIcon className={styles.glyph} width={18} height={18} />
      )}
      <span className={styles.name}>{text}</span>
    </div>
  );
};
