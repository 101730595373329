import isNull from 'lodash/isNull';

import { LimitsProps, TradingLimitsShowMode } from '../../types/layout';
import { PositionItem } from '../../types/position';
import { TradeLimitsResult } from '../../types/tradeLimits';

/***
 * функция для получения настройки отображения
 * @param displaySettings
 * @param tradeLimitsLong
 * @param tradeLimitsShort
 * @param position
 */
/*  */
export const getDisplayMode = ({
  displaySettings,
  tradeLimitsLong,
  tradeLimitsShort,
  position,
}: {
  tradeLimitsLong: TradeLimitsResult | null;
  tradeLimitsShort: TradeLimitsResult | null;
  displaySettings: LimitsProps;
  position?: PositionItem;
}) => {
  // Отображаем если есть лимиты и настройка их отображения
  const allowLimits =
    !isNull(tradeLimitsLong) &&
    !isNull(tradeLimitsShort) &&
    displaySettings.showLimits !== TradingLimitsShowMode.NONE;

  // Отображаем если есть позиция и настройка ее отображения
  const allowPosition =
    Boolean(displaySettings.showPosition) && Boolean(position);

  // Отображаем если есть позиция и настройка отображения NPU
  const allowNPU =
    displaySettings.showNPU !== TradingLimitsShowMode.NONE && Boolean(position);

  return { allowLimits, allowPosition, allowNPU };
};
