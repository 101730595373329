import { Loader } from '@alfalab/core-components/loader';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { DepoAccountInfo } from '@terminal/core/lib/rest/lkAccountInfo';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { BankAccount } from '@terminal/core/lib/rest/lkMoney';

import { DetailBrokerRequisites } from './DetailBrokerRequisites';
import { DetailDepoRequisites } from './DetailDepoRequisites';

import { useInit } from '../../../hooks';

interface DetailRequisitesProps {
  brokerAccount: BankAccount | null;
  depoAccount: DepoAccountInfo | null;
  treaty: Treaty | null;
  openDate: string;
  spbClientField: string;
  onClose: () => void;
}

export const DetailRequisites = ({
  brokerAccount,
  depoAccount,
  treaty,
  openDate,
  spbClientField,
  onClose,
}: DetailRequisitesProps) => {
  const { data: init, isFetching: loading } = useInit();

  return (
    <Portal id="main-window-modals">
      <FunctionalModalSidebar
        open={!!brokerAccount || !!depoAccount}
        backLabel="Основная информация"
        onClose={onClose}
      >
        {loading && <Loader />}
        {!!brokerAccount && (
          <DetailBrokerRequisites
            account={brokerAccount}
            treaty={treaty?.treaty || 0}
            email={init?.email || ''}
            emailConfirmed={init?.emailConfirmed || false}
          />
        )}
        {!!depoAccount && (
          <DetailDepoRequisites
            account={depoAccount}
            treaty={treaty?.treaty || 0}
            openDate={openDate}
            spbClientField={spbClientField}
            email={init?.email || ''}
            emailConfirmed={init?.emailConfirmed || false}
          />
        )}
      </FunctionalModalSidebar>
    </Portal>
  );
};
