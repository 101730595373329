import cn from 'classnames';
import { ComponentClass, FC, SVGProps } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { getTitleNode } from '../../utils';

import styles from './ChatRoomListItem.module.css';
import itemStyles from './RoomListItem.module.css';

interface ChatRoomListItemProps {
  id: string;
  mobile: boolean;
  description: string;
  icon: ComponentClass<SVGProps<SVGSVGElement>> | FC<SVGProps<SVGSVGElement>>;
  missedCount: number;
  missedColor?: string;
  title: string;
  onEnterRoom: (id: string) => void;
}

export const ChatRoomListItem = ({
  id,
  mobile,
  description,
  icon: Icon,
  missedCount,
  missedColor = '#D91D0B',
  title,
  onEnterRoom,
}: ChatRoomListItemProps) => {
  return (
    <div
      className={cn(itemStyles.item, {
        [itemStyles.itemMobile]: mobile,
      })}
      onClick={() => onEnterRoom(id)}
    >
      <div className={itemStyles.iconContainer}>
        <Icon width={24} height={24} color="#8D8D93" />
      </div>

      <div className={itemStyles.itemInfo}>
        {getTitleNode(title, mobile)}
        <Typography.Text
          tag="div"
          view={mobile ? 'secondary-medium' : 'secondary-small'}
          color="secondary"
          className={itemStyles.itemDescription}
        >
          {description}
        </Typography.Text>
      </div>

      <div
        data-count={Math.min(missedCount, 99) || ''}
        className={cn(styles.missedBadge, {
          [styles.missedBadgeActive]: missedCount > 0,
        })}
        style={{
          backgroundColor: missedCount > 0 ? missedColor : 'transparent',
        }}
      />
    </div>
  );
};
