import React, { useCallback, useMemo, useState } from 'react';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import { FinInfoExt } from '@terminal/core/types/quotes';
import { OrderSide } from '@terminal/core/types/trading';
import { SlippageType } from '@terminal/core/types/ui';

import { SlippageInput } from '../../../../../../features/SlippageInput';
import { useWidgetContext } from '../../../../../../shared';
import { useOrderBookContext } from '../../../../context';

import styles from './DefaultSlippageSettings.module.css';

interface Props extends BaseModalProps {
  idFi: number;
  nodeId?: string;
  onClose: () => void;
  finInfoExt?: FinInfoExt;
}

export const DefaultSlippageSettings = ({
  idFi,
  nodeId,
  onClose,
  finInfoExt,
  ...baseModalProps
}: Props) => {
  const { updateNode } = useWidgetContext();

  const {
    slippageType: defaultSlippageType,
    slippageValue: defaultSlippageValue,
  } = useOrderBookContext();

  const [slippageType, setSlippageType] = useState<SlippageType>(
    defaultSlippageType || SlippageType.TICK
  );
  const [slippageValue, setSlippageValue] = useState<number>(
    defaultSlippageValue || 1
  );

  const saveSettings = useCallback(() => {
    if (nodeId) {
      updateNode(nodeId, {
        slippageType,
        slippageValue,
      });
    }

    onClose();
  }, [updateNode, nodeId, slippageType, slippageValue, onClose]);

  const isSaveDisabled = useMemo(() => {
    return Boolean(
      defaultSlippageType &&
        defaultSlippageType === slippageType &&
        defaultSlippageValue &&
        defaultSlippageValue === slippageValue
    );
  }, [defaultSlippageType, defaultSlippageValue, slippageType, slippageValue]);

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <FunctionalModal
        backdrop={false}
        width={270}
        height={212}
        title="Проскок"
        onClose={onClose}
        drawerId="slippage-modal"
        contentStyle={{
          padding: '16px 8px 0 8px',
        }}
        footer={
          <Space direction="horizontal" size="m" align="center">
            <Button
              block
              view="ghost"
              size="xs"
              onClick={onClose}
              className={styles.customButton}
            >
              <Typography.Text view="secondary-large">Отмена</Typography.Text>
            </Button>
            <Button
              block
              view="secondary"
              size="xs"
              disabled={isSaveDisabled}
              onClick={saveSettings}
              className={styles.customButton}
            >
              Применить
            </Button>
          </Space>
        }
      >
        <SlippageInput
          side={OrderSide.BUY}
          slippageType={slippageType}
          setSlippageType={setSlippageType}
          slippageValue={slippageValue}
          setSlippageValue={setSlippageValue}
        />

        <Typography.Text
          view="secondary-small"
          color="secondary"
          tag="div"
          className={styles.hintText}
        >
          Установленный параметр будет применяться автоматически при выборе
          заявки типа Стоп-лимит
        </Typography.Text>
      </FunctionalModal>
    </BaseModal>
  );
};
