import { useLast } from '@terminal/alfadirect/hooks';
import { useOrderBookService } from '@terminal/core/hooks/useOrderBookService';

import { usePriceQuickControls } from '../../hooks';

const ASK_BID_DECIMALS = 2;

export const usePriceInput = ({
  idFi,
  side,
  orderType,
  priceStep,
  setPrice,
  isPriceStreamed,
  setSecondPrice,
  tradeLimits,
  setPriceStreamed,
}) => {
  const last = useLast(idFi);
  const { bestBid, bestAsk } = useOrderBookService(idFi, ASK_BID_DECIMALS);

  const { limitPriceAdditional, clearPricesAdditional, limPAdSuffix } =
    usePriceQuickControls({
      side,
      orderType,
      priceStep,
      setPrice,
      isPriceStreamed,
      setSecondPrice,
      last,
      bestAsk: bestAsk?.Price,
      bestBid: bestBid?.Price,
      tradeLimits,
      setPriceStreamed,
    });

  return {
    limitPriceAdditional,
    clearPricesAdditional,
    limPAdSuffix,
  };
};
