import { useCallback } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { ChatRoomList } from '../../components/rooms/ChatRoomList';
import { ROUTE } from '../../routes';
import { BrokerChat } from '../../screens/BrokerChat';
import { Support } from '../../screens/Support';

import styles from './ChatLayoutMobile.module.css';

export const ChatLayoutMobile = () => {
  const navigate = useNavigate();

  const handleEnterRoom = useCallback(
    (id: string) => navigate(ROUTE.BROKER_CHAT.FULL_PATH.replace(':id', id)),
    [navigate]
  );

  const handleCloseRoom = useCallback(
    () => navigate(ROUTE.FULL_PATH),
    [navigate]
  );

  return (
    <div className={styles.layout}>
      <ChatRoomList mobile onEnterRoom={handleEnterRoom} />

      <Routes>
        <Route
          path={ROUTE.SUPPORT.PATTERN}
          element={<Support mobile onBack={handleCloseRoom} />}
        />
        <Route
          path={ROUTE.BROKER_CHAT.PATTERN}
          element={<BrokerChat mobile onBack={handleCloseRoom} />}
        />
      </Routes>
    </div>
  );
};
