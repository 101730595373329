import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { SignOrderStatus } from '@terminal/core/lib/rest/lkSignOperation';

import styles from './TransferResult.module.css';

interface TransferResultProps {
  orderStatus?: SignOrderStatus | null;
  onAction?: () => void;
  actionText?: string;
}

export const TransferResult: React.FC<TransferResultProps> = ({
  onAction,
  children,
  actionText,
}) => {
  return (
    <div className={styles.container}>
      <Typography.Text
        view="secondary-small"
        color="secondary"
        tag="p"
        defaultMargins={false}
        className={styles.text}
      >
        {children}
      </Typography.Text>

      <div className={styles.submit}>
        <ButtonDesktop block size="xxs" onClick={onAction} view="accent">
          {actionText || 'Подать новое поручение'}
        </ButtonDesktop>
      </div>
    </div>
  );
};
