import qs from 'query-string';

import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';

import { FortsRegistrationParams } from './types/FortsRegistrationParams';

export async function fortsRegistration(data: FortsRegistrationParams) {
  return await signOperation(
    qs.stringifyUrl({
      url: '/api/trading-platform/register-on-forts',
      query: { ...data.params },
    }),
    'GET',
    undefined,
    data.signOptions
  );
}
