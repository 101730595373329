import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  getSecuritiesConteragents,
  SecuritiesConteragentsType,
} from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesConteragents = (
  type?: SecuritiesConteragentsType
) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.SecuritiesConteragents, { type }],
    async () => getSecuritiesConteragents({ type }),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе контрагентов',
        });
      },
    }
  );
};
