import isNil from 'lodash/isNil';
import { ReactNode } from 'react';

import { IssuerFinancials } from '@terminal/core/types/issuer';

type UseEventCompanyIndicators = (dataSource: IssuerFinancials) => {
  title: string;
  withPercent?: boolean;
  current?: number;
  average?: number;
  tooltip?: {
    title?: string;
    content: string;
  };
  // Если понадобится отрисовать кастомный кейс
  renderCurrent?: (current: number) => ReactNode;
  renderAverage?: (average: number) => ReactNode;
}[];

export const useEventCompanyIndicators: UseEventCompanyIndicators = (
  dataSource
) => {
  if (!dataSource) {
    return [];
  }

  const {
    returnOnEquity,
    returnOnAssets,
    returnOnInvestment,
    pb,
    priceToSales,
    priceToEarnings,
    debtToEquity,
    debtToEbitda,
    netProfitMargin,
    revenueGrowthRate,
    financialIndustryAvg: financialIndustryAvgArray,
  } = dataSource;

  // С бека приходит массив financialIndustryAvg с одним элементом
  const [financialIndustryAvg] = financialIndustryAvgArray ?? [];

  return [
    {
      title: 'ND/EBITDA',
      current: debtToEbitda,
      average: financialIndustryAvg?.debtVsEbidtaIndAvgRegion,
      tooltip: {
        title:
          'Net Dept To Earnings before interest, taxes, depreciation and amortization',
        content:
          'Отношение чистого долга к показателю EBITDA указывает на количество периодов, которые компании потребуются для погашения долгов при текущих объёмах поступлениях денежных средств',
      },
    },
    {
      title: 'Net Margin',
      withPercent: true,
      current: netProfitMargin,
      average: financialIndustryAvg?.netProfitMarginIndAvgRegion,
      tooltip: {
        title: 'Net Profit Margin',
        content: 'Процентное выражение прибыли от выручки компании',
      },
    },
    {
      title: 'Рост выручки',
      withPercent: true,
      current: revenueGrowthRate,
      average: financialIndustryAvg?.revtrendgrIndAvgRegion,
      tooltip: {
        content: 'За последние пять лет',
      },
    },
    {
      title: 'D/E',
      withPercent: true,
      current: debtToEquity,
      average: financialIndustryAvg?.debtVsEquityIndAvgRegion,
      tooltip: {
        title: 'Debt To Equity',
        content: 'Отношение общего долга к собственному капиталу',
      },
    },
    {
      title: 'P/E',
      current: priceToEarnings,
      average: financialIndustryAvg?.peIndAvgRegion,
      tooltip: {
        title: 'Price To Earnings',
        content:
          'Отношению рыночной стоимости акции к годовой прибыли, полученной на акцию',
      },
    },
    {
      title: 'P/S',
      current: priceToSales,
      average: financialIndustryAvg?.psIndAvgRegion,
      tooltip: {
        title: 'Price To Sales',
        content:
          'Отношение рыночной капитализации компании к ее годовой выручке',
      },
    },
    {
      title: 'P/B',
      current: pb,
      average: financialIndustryAvg?.pbIndAvgRegion,
      tooltip: {
        title: 'Price To Book',
        content: 'Соотношение рыночной и балансовой стоимости компании',
      },
    },
    {
      title: 'ROE',
      withPercent: true,
      current: returnOnEquity,
      average: financialIndustryAvg?.roeIndAvgRegion,
      tooltip: {
        title: 'Return On Equity',
        content:
          'Показатель чистой прибыли в сравнении с собственным капиталом организации',
      },
    },
    {
      title: 'ROA',
      withPercent: true,
      current: returnOnAssets,
      average: financialIndustryAvg?.roaIndAvgRegion,
      tooltip: {
        title: 'Return On Assets',
        content:
          'Отношение чистой прибыли компании без учета процентов по кредитам к её суммарным активам',
      },
    },
    {
      title: 'ROI',
      withPercent: true,
      current: returnOnInvestment,
      average: financialIndustryAvg?.roiIndAvgRegion,
      tooltip: {
        title: 'Return On Investment',
        content: 'Окупаемость инвестиций',
      },
    },
  ].filter(
    ({ current, average }) =>
      !isNil(
        current
      ) /** <Расскомментировать при добавлении столбца "среднее"> || !isNil(average) */
  );
};
