import cn from 'classnames';
import { Typography } from '@alfalab/core-components/typography';

import styles from './Label.module.css';

interface LabelProps {
  className?: string;
}

export const Label: React.FC<LabelProps> = (props) => (
  <Typography.Text
    view="secondary-small"
    color="secondary"
    className={cn(styles.label, props.className)}
    tag="div"
  >
    {props.children}
  </Typography.Text>
);
