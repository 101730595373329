import { Tag } from '@alfalab/core-components/tag';

interface FilterButtonProps {
  children?: React.ReactNode;
  checked?: boolean;
  rightAddons?: React.ReactNode;
  onClick?: () => void;
}

export const FilterButton = ({
  children,
  checked,
  onClick,
  rightAddons,
}: FilterButtonProps) => {
  return (
    <Tag
      size="xs"
      view="filled"
      onClick={onClick}
      checked={checked}
      rightAddons={rightAddons}
    >
      {children}
    </Tag>
  );
};
