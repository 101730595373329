import { RefObject } from 'react';
import { useClickAway } from 'react-use';

type Handler = (event: Event) => void;

/**
 * То же самое, что useClickAway, но не вызывает обработчик по нажатию на target
 * */
export function useClickAwayExceptTarget(
  ref: RefObject<HTMLElement | null>,
  target: RefObject<HTMLElement> | undefined,
  handler: Handler
): void {
  return useClickAway(ref, (event) => {
    if (
      !target?.current ||
      (target.current !== event.target &&
        !target.current?.contains(event.target as Node))
    ) {
      handler(event);
    }
  });
}
