import React, { useMemo } from 'react';
import {
  SelectDesktop,
  SelectDesktopProps,
} from '@alfalab/core-components/select/desktop';

import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';

import { getTradeAccountKey } from '../../lib';
import { modifyTrandAccountLimit } from './helpers';
import { TradingAccountOption } from './TradingAccountOption';

type SelectDesktopPropsInherited = Omit<
  SelectDesktopProps,
  'selected' | 'onChange' | 'options' | 'valueRenderer'
>;

export interface TradingAccountSelectorProps
  extends SelectDesktopPropsInherited {
  /** Список счетов для выбора */
  accounts: TradeAccount[] | undefined | null;
  /** Выбранный счёт */
  selected: TradeAccount | undefined | null;
  /** Подменит значение средств на счёте */
  limitValue?: number;
  /** Обработчик выбора счёта */
  onChange?: (account: TradeAccount) => void;
  /** Кастомный рендер значения на кнопке */
  valueRenderer?: (selected: TradeAccount) => React.ReactNode;
  /** Кастомный рендер элемента списка */
  optionRenderer?: (account: TradeAccount) => React.ReactNode;
}

export interface IconMap {
  [s: string]: React.ReactNode;
}

export const TradingAccountSelector = ({
  accounts,
  selected,
  limitValue,
  onChange,
  valueRenderer,
  optionRenderer,
  ...restProps
}: TradingAccountSelectorProps) => {
  const handleChange: SelectDesktopProps['onChange'] = ({ selected }) => {
    if (selected) {
      const account = (accounts || []).find(
        (acc) => getTradeAccountKey(acc) === selected.key
      );

      if (account && onChange) {
        onChange(account);
      }
    }
  };

  const accountOptions = useMemo<SelectDesktopProps['options']>(() => {
    return (accounts || []).map((acc) => ({
      key: getTradeAccountKey(acc),
      content: optionRenderer ? (
        optionRenderer(acc)
      ) : (
        <TradingAccountOption account={acc} />
      ),
    }));
  }, [accounts, optionRenderer]);

  const renderValue: SelectDesktopProps['valueRenderer'] = ({ selected }) => {
    if (selected) {
      const account = (accounts || []).find(
        (acc) => getTradeAccountKey(acc) === selected.key
      );

      if (account) {
        const modAccount = limitValue
          ? modifyTrandAccountLimit(account, limitValue)
          : account;

        return valueRenderer ? (
          valueRenderer(modAccount)
        ) : (
          <TradingAccountOption account={modAccount} />
        );
      }
    }

    return '';
  };

  const selectedKey = selected ? getTradeAccountKey(selected) : '';

  return (
    <SelectDesktop
      {...restProps}
      options={accountOptions}
      selected={selectedKey}
      onChange={handleChange}
      valueRenderer={renderValue}
    />
  );
};
