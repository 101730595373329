import { format } from 'date-fns';
import { Badge } from '@alfalab/core-components/badge';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import { NotificationsFilter } from '@terminal/core/lib/rest/lkNotifications';

import { FilterButton, setFilterProp } from '../../../components/Filter';
import { DatePeriod, PeriodTag } from '../PeriodTag';
import { stringToDate } from './helpers';

import styles from './NotificationsFilterLine.module.css';

interface NotificationsFilterLineProps {
  filter: NotificationsFilter;
  defaultFilter: NotificationsFilter;
  unreaded: number;
  onSetFilter?: (filter: NotificationsFilter) => void;
}

export const NotificationsFilterLine = ({
  filter,
  defaultFilter,
  unreaded,
  onSetFilter,
}: NotificationsFilterLineProps) => {
  const setFilter = (
    key: keyof NotificationsFilter,
    value: string | boolean
  ) => {
    if (onSetFilter) {
      const newFilter = setFilterProp(filter, key, value);
      onSetFilter(newFilter);
    }
  };

  const onPeriodApply = (period: DatePeriod) => {
    if (onSetFilter) {
      const startDate = period.dateFrom
        ? format(new Date(period.dateFrom), DEFAULT_VIEW_DATE_FORMAT)
        : '';
      const endDate = period.dateTo
        ? format(new Date(period.dateTo), DEFAULT_VIEW_DATE_FORMAT)
        : '';
      onSetFilter({
        ...filter,
        startDate,
        endDate,
      });
    }
  };

  const onPeriodReset = () => {
    if (onSetFilter) {
      onSetFilter({
        ...filter,
        startDate: defaultFilter.startDate,
        endDate: defaultFilter.endDate,
      });
    }
  };

  return (
    <div className={styles.container}>
      {unreaded > 0 && (
        <FilterButton
          checked={!!filter.unreadOnly}
          onClick={() => setFilter('unreadOnly', !filter.unreadOnly)}
        >
          Новые: {unreaded} <Badge view="count" color="accent" />
        </FilterButton>
      )}

      <PeriodTag
        dateForm={stringToDate(filter.startDate)}
        dateTo={stringToDate(filter.endDate)}
        onApply={onPeriodApply}
        onReset={onPeriodReset}
      />
    </div>
  );
};
