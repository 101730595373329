import { OrderType } from '../../lib/client/entities';

import { NewOrderType, OrderItem } from '../../types/order';

// Возвращает ключ-цену для разные типов ордеров
export function getOrderKeyPrice(
  order: NewOrderType | OrderItem
): 'limitPrice' | 'stopPrice' | 'price' {
  switch (order.idOrderType) {
    case OrderType.LMT:
    case OrderType.TRL:
    case OrderType.LIT:
    case OrderType.BRS:
    case OrderType.BSL:
      return 'limitPrice';
    case OrderType.STP:
    case OrderType.STL:
      return 'stopPrice';
    case OrderType.TRS:
    case OrderType.TSL:
    default:
      return 'price';
  }
}
