import { useEffect } from 'react';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';

import { Notification } from '@terminal/core/lib/rest/lkNotifications';

import { useReadNotification } from '../../hooks';

import styles from './NotificationDetail.module.css';

interface NotificationDetailProps {
  item: Notification;
  onNotificationRead?: () => void;
}

export const NotificationDetail = ({
  item,
  onNotificationRead,
}: NotificationDetailProps) => {
  const {
    mutateAsync: readNotification,
    isLoading: readPending,
    isSuccess: isReadSet,
  } = useReadNotification();

  useEffect(() => {
    if (!item.isRead) {
      readNotification(item.id);
    }
  }, [item, readNotification]);

  useEffect(() => {
    if (!item.isRead && isReadSet && onNotificationRead) {
      onNotificationRead();
    }
  }, [item, isReadSet, onNotificationRead]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography.Title
          view="xsmall"
          color="primary"
          className={styles.title}
          tag="div"
        >
          {item.subject}
        </Typography.Title>
        <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
        {readPending && <Loader />}
      </div>
    </div>
  );
};
