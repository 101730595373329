import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { ContestLotteryAddChancesParams } from './types/ContestLotteryAddChancesParams';

export async function addContestLotteryChances(
  data: ContestLotteryAddChancesParams
): Promise<LKResult> {
  const result = await fetchLkResult<LKResult>(
    `/api/contest/lottery/add-chances`,
    'POST',
    data
  );

  if (result.code !== 0) {
    throw new Error(result.message || '');
  }

  return result;
}
