import * as z from 'zod';

import { SettingsZod_V1 } from './version1';

export class SettingsZod_V2 extends SettingsZod_V1 {
  static override object = z.object({
    ...super.object.shape,
    defaultValues: super.object.shape.defaultValues.merge(
      z.object({
        selectedPLMode: z.enum(['money', 'procent', 'points']),
      })
    ),
    notifications: z
      .object({
        isShow: z.boolean(),
        isShowTradeNotifications: z.boolean(),
        isShowSystemNotifications: z.boolean(),
        isShowBrokerNotifications: z.boolean(),
        isShowNewMessages: z.boolean(),
        maxCount: z.number(),
        time: z.number(),
      })
      .partial(),
  });
}
