import { makeGetParams } from '../url';
import { TradingPlatformStateType } from './lkAccountInfo';
import { fetchLkResult, LKResult } from './lkApi';
import { signOperation } from './lkSignOperation';
import { smsSignOperation } from './lkSmsSignOperation';

import { LkSignOptions } from '../../store/slices/lkSignSlice';

export interface RiskDeclaration {
  document: string | null;
  acceptRiskDeclaration: boolean;
}

export async function getRiskDeclarationText(
  treaty: number
): Promise<RiskDeclaration> {
  const res = await fetchLkResult<RiskDeclaration & LKResult>(
    `/api/documents/risk-declaration-text/${treaty}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface ScanItem {
  anketScanId: number;
  year: number;
}

export interface Scans {
  '2ndfl': ScanItem[];
  '1042s': ScanItem[];
  advisor: number | null;
}

export async function getScansList(treaty: number): Promise<Scans> {
  const res = await fetchLkResult<Scans | LKResult>(
    `/api/client/documents/scans?treaty=${treaty}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export type DocumentReceivingType =
  | 'electronic'
  | 'inbank'
  | 'bymail'
  | 'online'
  | 'inlk';

export interface DocumentReceiving {
  type: DocumentReceivingType;
  description: string;
}

export type DocumentDateType = 'period' | 'year' | 'date' | 'none';

export interface DocumentTheme {
  id: number;
  theme: string;
  tooltip: string;
  allowedReceivingTypes: DocumentReceiving[];
  needDepoAccount: boolean;
  dateType: DocumentDateType;
  startDate: string | null;
  endDate: string | null;
}

export interface DocumentsRequestParams {
  treaty: number;
}

export interface DocumentDepoAccount {
  depoAccount: string;
  placeCode: string;
  placeName: string;
}

export interface DocumentsRequestResponse {
  themes: DocumentTheme[];
  depoAccounts: DocumentDepoAccount[];
  openDate: string;
  address: string;
  email: string;
  emailConfirmed: boolean;
}

export async function getDocumentsTheme(
  data: DocumentsRequestParams
): Promise<DocumentsRequestResponse> {
  const request = makeGetParams(data);
  const res = await fetchLkResult<DocumentsRequestResponse | LKResult>(
    `/api/client/documents/order-themes?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface ReportTheme {
  id: number;
  theme: string;
  tooltip: string;
  allowedReceivingTypes: DocumentReceiving[];
  needDepoAccount: boolean;
  dateType: DocumentDateType;
  startDate: string;
  endDate: string;
}

export interface ReportThemesResponse {
  themes: ReportTheme[];
  depoAccounts: DocumentDepoAccount[];
  openDate: string;
  address: string;
  email: string;
  emailConfirmed: boolean;
}

export interface ReportThemesParams {
  treaty: number;
}

export async function getReportsTheme(
  data: ReportThemesParams
): Promise<ReportThemesResponse> {
  const request = makeGetParams(data);
  const res = await fetchLkResult<ReportThemesResponse | LKResult>(
    `/api/client/documents/report-themes?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export type TReportType =
  | 'MyBroker'
  | 'MyPortfolio'
  | 'Taxes'
  | 'TaxAppendix'
  | 'MyMoneyMove'
  | 'SecuritiesMovementStatementDepoAccount'
  | 'DepoAccountStatusStatement'
  | 'IncomePaymentsReportOnSecurities';
export type TReportFormat = 'HTML' | 'EXCEL' | 'Pdf' | 'XML' | 'XML_IMPORT';

export interface ReportsMultiplyGenerateParams {
  report: TReportType;
  strDateStart: string;
  strDateEnd: string;
  format: TReportFormat;
  hideSection: number;
  treaties?: number[];
}

type ReportsMultiplyState = 'Default' | 'InProgress' | 'Error' | 'Done';

export interface ReportsMultiply {
  id: string;
  state: ReportsMultiplyState;
  currentItem: number;
  count: number;
}

export async function generateMultiplyReports(
  data: ReportsMultiplyGenerateParams
): Promise<ReportsMultiply> {
  const res = await fetchLkResult<ReportsMultiply & LKResult>(
    `/api/reports/multiple/generate`,
    'POST',
    data
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export async function getMultiplyReportsStatus(
  id: string
): Promise<ReportsMultiply> {
  const res = await fetchLkResult<ReportsMultiply & LKResult>(
    `/api/reports/multiple/status/${id}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export async function cancelGenerateMultiplyReports(id: string): Promise<void> {
  return await fetchLkResult<void>(
    `/api/reports/multiple/cancel/${id}`,
    'POST'
  );
}

export interface DocumentsOrderRequest {
  treaty: number;
  themeId: number;
  dateType: DocumentDateType;
  startDate?: string;
  endDate?: string;
  year?: string;
  receivingType: string;
  count?: number;
  officeCity?: string;
  office?: string;
  address?: string;
  email?: string;
  depoAccount?: string;
  placeName?: string;
  extraInfo?: string;
}

export interface DocumentsOrderParams {
  params: DocumentsOrderRequest;
  signOptions?: LkSignOptions;
}

export async function documentOrder(data: DocumentsOrderParams) {
  return await signOperation(
    '/api/client/documents/order-create',
    'POST',
    data.params,
    data.signOptions
  );
}

// History
export interface DocumentsHistoryFilter {
  startDate?: string;
  endDate?: string;
  statuses: string;
  theme: number[];
}

export interface DocumentsHistoryParams extends DocumentsHistoryFilter {
  treaty: number;
  page: number;
  count: number;
}

export type DocumentStatusType = 'Исполнено' | 'Принято' | 'Отклонено';

export interface DocumentsHistoryItem {
  docDate: string;
  theme: string;
  status: DocumentStatusType;
  scan: DocumentsScan | null;
  comment: string;
  data?: {
    lkNumber: string;
    dateFrom: string;
    dateTo: string;
  };
}

export interface DocumentsScan {
  scanId: number;
  filename: string;
}

export interface DocumentsHistoryTheme {
  id: number;
  theme: string;
}

export interface DocumentsHistoryStatus {
  text: string;
  value: string;
}

export interface DocumentsHistoryPage {
  items: DocumentsHistoryItem[];
  themes: DocumentsHistoryTheme[];
  statuses: DocumentsHistoryStatus[];
  pageCount: number;
}

export async function getDocumentsHistory(
  data: DocumentsHistoryParams
): Promise<DocumentsHistoryPage> {
  const request = makeGetParams(data, true);
  const res = await fetchLkResult<DocumentsHistoryPage | LKResult>(
    `/api/client/documents/history?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

// Fatca
export interface FatcaText {
  readonly document: string;
  readonly inn: string | null;
  readonly status: TradingPlatformStateType;
}

export async function getFatcaText(treaty: number): Promise<FatcaText> {
  const res = await fetchLkResult<FatcaText & LKResult>(
    `/api/documents/fatca-text/${treaty}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  } else {
    return res as FatcaText;
  }
}

export interface FatcaSignParams {
  readonly treaty: number;
  readonly inn: string;
}

export async function signFatcaSms(data: FatcaSignParams) {
  return await smsSignOperation(`/api/documents/accept-fatca`, 'POST', data);
}
