import { TRACK_PRICE_COMMON_SETTINGS_DEFAULT } from '../../../constants/trackPriceSettings';
import { RootStore } from '../../useStore';
import { activeConfigurationSelector } from './activeConfigurationSelector';

import { TrackPriceCommonSettings } from '../../../types/trackPrice';

/**
 * Выбирает общие настройки алертов из активной конфигурации
 * */
export function trackPriceCommonSettingsSelector(
  store: RootStore
): TrackPriceCommonSettings {
  const { lastAutoSavedConfig } = activeConfigurationSelector(store);

  return (
    lastAutoSavedConfig.trackPriceSettings?.defaultSettings ??
    TRACK_PRICE_COMMON_SETTINGS_DEFAULT
  );
}
