import cn from 'classnames';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownLineDownMIcon } from '@alfalab/icons-glyph/ArrowDownLineDownMIcon';
import { ArrowsRightLeftMIcon } from '@alfalab/icons-glyph/ArrowsRightLeftMIcon';
import { ArrowUpLineDownMIcon } from '@alfalab/icons-glyph/ArrowUpLineDownMIcon';

import {
  SecuritiesHistoryItem,
  SecuritiesOperationType,
} from '@terminal/core/lib/rest/lkSecurities';

import { getTime } from '../../shared';
import {
  SECURITIES_TRANSFER_STATUS_MAP,
  SECURITIES_TRANSFER_TYPE_TEXT,
} from './constants';

// eslint-disable-next-line no-restricted-imports
import tablestyles from '../../../styles/tables.module.css';
import styles from './HistoryItem.module.css';
import historytabtyles from './historyTables.module.css';

interface HistoryItemProps {
  item: SecuritiesHistoryItem;
  odd?: boolean;
  onClick?: (item: SecuritiesHistoryItem) => void;
}

const SECURITIES_TRANSFER_TYPE_ICON = {
  EXTRA_IN: ArrowDownLineDownMIcon,
  EXTRA_OUT: ArrowUpLineDownMIcon,
  INTRA: ArrowsRightLeftMIcon,
};

export const HistoryItem = ({ item, odd, onClick }: HistoryItemProps) => {
  const getDirectionType = (type: SecuritiesOperationType) => {
    const Icon = SECURITIES_TRANSFER_TYPE_ICON[type];
    const text = SECURITIES_TRANSFER_TYPE_TEXT[type];

    return text && Icon ? (
      <span className={styles.direction}>
        <Icon className={styles.directionIcon} height={18} />
        <Typography.Text
          view="secondary-small"
          weight="medium"
          tag="span"
          color="secondary"
        >
          {text}
        </Typography.Text>
      </span>
    ) : null;
  };

  return (
    <div
      className={cn(styles.item, tablestyles.row, {
        [tablestyles.rowOdd]: odd,
      })}
      onClick={onClick ? () => onClick(item) : undefined}
    >
      <div
        className={cn(
          tablestyles.cell,
          tablestyles.cellFade,
          historytabtyles.cellType
        )}
      >
        {getDirectionType(item.tranType)}
      </div>
      <div className={cn(tablestyles.cell, historytabtyles.cellName)}>
        {item.pName}
      </div>
      <div className={cn(tablestyles.cell, historytabtyles.cellAmount)}>
        {item.volume}
      </div>
      <div className={cn(tablestyles.cell, historytabtyles.cellTime)}>
        <Typography.Text view="secondary-small" color="secondary">
          {getTime(item.depoDate)}
        </Typography.Text>
      </div>
      <div className={cn(tablestyles.cell, historytabtyles.cellStatus)}>
        <Typography.Text
          view="secondary-small"
          className={SECURITIES_TRANSFER_STATUS_MAP?.[item.status]?.className}
        >
          {item.statusDescription}
        </Typography.Text>
      </div>
    </div>
  );
};
