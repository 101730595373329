import cn from 'classnames';
import { CellProps } from 'react-table';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { IconButton } from '@alfalab/core-components/icon-button';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';

import { Column } from '@terminal/common/ui/Table';
import { disallowOrderEditByOrderTypeSet } from '@terminal/core/constants/orderTable';
import { OrderDirection, OrderType } from '@terminal/core/lib/client/entities';
import { getDimensionedUnit } from '@terminal/core/lib/format';
import {
  LifeTimeMap,
  OrderItem,
  OrderTypeMap,
} from '@terminal/core/types/order';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

// TODO рефактор стилей
// eslint-disable-next-line
import styles from '../../../widgets/orderBook/features/OrderbookColumns.module.css';

export const getOrderBookColumns = (
  priceDecimals: number,
  orderDimension: OrderDimensionEnum,
  lot: number | undefined,
  isBond: boolean,
  currencyCode?: CurrencyCodes,
  onCancelOrder?: (order: OrderItem) => void,
  onEditOrder?: (order: OrderItem) => void,
  forEdit?: boolean,
  fromInstructions?: boolean,
  idOrderType?: OrderType
): Column<OrderItem>[] => {
  const buySell = {
    Header: 'Направ-е',
    accessor: 'buySell',
    minWidth: 120,
    cellClassName: 'textAlignStart',
    headerCellClassName: 'textAlignStart',
    Cell: ({ value }: CellProps<OrderItem>) => (
      <div
        className={cn(
          styles.directionCell,
          value === OrderDirection.Sell ? styles.sell : styles.buy
        )}
      >
        {value === OrderDirection.Sell ? (
          <>
            <ArrowDownCompactXsIcon width={18} height={18} />
            Продажа
          </>
        ) : (
          <>
            <ArrowUpCompactXsIcon width={18} height={18} />
            Покупка
          </>
        )}
      </div>
    ),
  };
  const quantity = {
    Header: 'Кол-во',
    accessor: 'quantity',
    minWidth: !forEdit ? 110 : 95,
    cellClassName: !forEdit && 'textAlignStart',
    headerCellClassName: !forEdit && 'textAlignStart',
    Cell: ({ row: { original } }: CellProps<OrderItem>) => (
      <Amount.Pure
        value={getDimensionedUnit(original.quantity, lot, orderDimension)}
        minority={0}
        view="withZeroMinorPart"
      />
    ),
  };

  const rest: Column<OrderItem> = {
    Header: 'Неисп. остаток',
    accessor: 'rest',
    minWidth: !forEdit ? 110 : 95,
    cellClassName: !forEdit && 'textAlignStart',
    headerCellClassName: !forEdit && 'textAlignStart',
    Cell: ({ row: { original } }) => (
      <Amount.Pure
        value={getDimensionedUnit(original.rest, lot, orderDimension)}
        minority={0}
        view="withZeroMinorPart"
      />
    ),
  };

  const price = {
    Header: 'Цена',
    // accessor: '',
    minWidth: !forEdit ? 100 : 115,
    Cell: ({ row: { original } }: CellProps<OrderItem>) => {
      const { price } = original;

      if (currencyCode && price) {
        return (
          <>
            <Amount.Pure
              value={original.price * priceDecimals}
              currency={isBond ? undefined : currencyCode}
              minority={priceDecimals}
            />
            {isBond ? '%' : undefined}
          </>
        );
      } else if (!price) {
        return <span>по рынку</span>;
      } else {
        return null;
      }
    },
  };
  const priceStop = {
    Header: 'Цена cтоп',
    // accessor: '',
    minWidth: !forEdit ? 100 : 115,
    Cell: ({ row: { original } }: CellProps<OrderItem>) => {
      const { price } = original;

      if (currencyCode && price) {
        return (
          <>
            <Amount.Pure
              value={original.stopPrice * priceDecimals}
              currency={isBond ? undefined : currencyCode}
              minority={priceDecimals}
            />
            {isBond ? '%' : undefined}
          </>
        );
      } else if (!price) {
        return <span>по рынку</span>;
      } else {
        return null;
      }
    },
  };
  const priceLimit = {
    Header: 'Цена тейк',
    // accessor: '',
    minWidth: !forEdit ? 100 : 115,
    Cell: ({ row: { original } }: CellProps<OrderItem>) => {
      const { price } = original;

      if (currencyCode && price) {
        return (
          <>
            <Amount.Pure
              value={original.limitPrice * priceDecimals}
              currency={isBond ? undefined : currencyCode}
              minority={priceDecimals}
            />
            {isBond ? '%' : undefined}
          </>
        );
      } else if (!price) {
        return <span>по рынку</span>;
      } else {
        return null;
      }
    },
  };
  const orderType = {
    Header: 'Тип',
    accessor: 'idOrderType',
    minWidth: 140,
    Cell: ({ row: { original } }: CellProps<OrderItem>) => {
      const { idOrderType, idLifeTime } = original;

      return (
        <span className={styles.typeCell}>
          {OrderTypeMap.get(idOrderType) + ' ' + LifeTimeMap.get(idLifeTime)}
        </span>
      );
    },
  };
  const action = {
    Header: 'Действия',
    id: 'action',
    // accessor: '',
    minWidth: 125,
    isVisible: !fromInstructions,
    Cell: ({ row: { original } }: CellProps<OrderItem>) => {
      const isAllowEdit = !disallowOrderEditByOrderTypeSet.has(
        original.idOrderType
      );

      return (
        <div className={styles.actionsCell}>
          {isAllowEdit && (
            <IconButton
              size="xs"
              view="secondary"
              icon={PencilMIcon}
              onClick={() => {
                if (onEditOrder) {
                  onEditOrder(original);
                }
              }}
            />
          )}
          <IconButton
            size="xs"
            view="secondary"
            icon={CrossCircleMIcon}
            onClick={() => {
              if (onCancelOrder) {
                onCancelOrder(original);
              }
            }}
          />
        </div>
      );
    },
  };

  if (idOrderType === OrderType.BRS || idOrderType === OrderType.TBRS) {
    return [buySell, quantity, priceStop, priceLimit, orderType, action];
  }

  return [buySell, quantity, rest, price, orderType, action];
};
