import { BaseModal } from '@alfalab/core-components/base-modal';

import { ConfirmEditOrder, ConfirmEditOrderProps } from '../ConfirmEditOrder';

interface Props extends ConfirmEditOrderProps {
  isOpen: boolean;
}

export const ConfirmEditOrderModal = (props: Props) => {
  return (
    <BaseModal open={props.isOpen} onClose={props.onCancel}>
      <ConfirmEditOrder {...props} />
    </BaseModal>
  );
};
