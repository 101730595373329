import { ObjectGroup } from '../client/entities';

import { ObjectItem, ObjectTypeItem } from '../../types/core';

/**
 * @returns Валюту номинала
 * */
export function getMarketCurSymbol(
  objectType: ObjectTypeItem,
  objectsMap: Map<number | undefined, ObjectItem>,
  idObjectFaceUnit = 0,
  idObjectCurrency = 0
) {
  if (objectType?.idObjectGroup === ObjectGroup.Bonds) {
    return objectsMap.get(idObjectFaceUnit)?.symbolObject;
  }

  return objectsMap.get(idObjectCurrency)?.symbolObject;
}
