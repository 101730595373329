import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import { CorporateActionsHistoryFilter } from '@terminal/core/lib/rest/lkCorporateActions';

import { FilterIconButton } from '../../../../shared/ui/FilterIconButton';
import { FilterButton, setFilterProp } from '../../../components/Filter';
import { STATUS_VARIANTS } from './helpers';

import styles from './HistoryFilterLine.module.css';

interface HistoryFilterLineProps {
  filter: CorporateActionsHistoryFilter;
  active: boolean;
  onFilterOpen?: () => void;
  onSetFilter?: (filter: CorporateActionsHistoryFilter) => void;
}

export const HistoryFilterLine = ({
  filter,
  active,
  onFilterOpen,
  onSetFilter,
}: HistoryFilterLineProps) => {
  const setFilter = (
    key: keyof CorporateActionsHistoryFilter,
    value: string
  ) => {
    if (onSetFilter) {
      const newFilter = setFilterProp(filter, key, value, key === 'status');
      onSetFilter(newFilter);
    }
  };
  const periodFormat = (): string => {
    if (!!filter.from && !!filter.to && filter.from === filter.to) {
      return filter.from;
    } else if (filter.from || filter.to) {
      return `${filter.from || '...'} – ${filter.to || '...'}`;
    } else {
      return 'Выберите период';
    }
  };

  return (
    <div className={styles.container}>
      <FilterIconButton onClick={onFilterOpen} checked={active} />

      {STATUS_VARIANTS.map((status) => (
        <FilterButton
          key={`filter-status-${status.key}`}
          checked={!!filter.status && filter.status?.indexOf(status.key) >= 0}
          onClick={() => setFilter('status', status.key)}
        >
          {status.name}
        </FilterButton>
      ))}

      <FilterButton
        checked={!!filter.from || !!filter.to}
        rightAddons={<ChevronDownMIcon width={18} height={18} />}
        onClick={onFilterOpen}
      >
        {periodFormat()}
      </FilterButton>
    </div>
  );
};
