import { RootStore } from '../../useStore';
import { objectByIdFiSelector } from './objectByIdFiSelector';
import { objectTypeById } from './objectTypeById';

import { InstrumentItem, ObjectTypeItem } from '../../../types/core';

/**
 * Выбирает тип ценной бумаги по идентификатору финансового инструмента
 * */
export function objectTypeByIdFi(
  store: RootStore
): (idFi: InstrumentItem['idFI']) => ObjectTypeItem | undefined {
  return (idFi) => {
    const object = objectByIdFiSelector(store)(idFi);

    return objectTypeById(store)(object?.idObjectType.value ?? NaN);
  };
}
