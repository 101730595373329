import cn from 'classnames';
import { useToggle } from 'react-use';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { ModalDesktop } from '@alfalab/core-components/modal/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { CrossIcon } from '../CrossIcon';
import { ServiceBannerBackground } from '../ServiceBannerBackground';

import styles from './ServiceBannerModal.module.css';

interface ServiceBannerModalProps {
  message: string;
  className?: string;
}

export const ServiceBannerModal = ({
  message,
  className,
}: ServiceBannerModalProps) => {
  const [expanded, toggleExpanded] = useToggle(true);

  return (
    <ModalDesktop
      open={expanded}
      fullscreen={false}
      onClose={toggleExpanded}
      size="m"
      backdropProps={{
        invisible: true,
      }}
      className={cn(styles.modal, className)}
    >
      <ModalDesktop.Header
        className={styles.modalHeader}
        closerIcon={CrossIcon}
        hasCloser
      />
      <ServiceBannerBackground className={styles.background} />
      <ModalDesktop.Content className={styles.modalContent}>
        <Typography.Text
          weight="medium"
          color="secondary"
          className={styles.message}
        >
          {message}
        </Typography.Text>
      </ModalDesktop.Content>
      <ModalDesktop.Footer className={styles.modalFooter}>
        <ButtonDesktop block view="accent" size="s" onClick={toggleExpanded}>
          Понятно
        </ButtonDesktop>
      </ModalDesktop.Footer>
    </ModalDesktop>
  );
};
