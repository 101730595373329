import { Button } from '@alfalab/core-components/button';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { MailMIcon } from '@alfalab/icons-glyph/MailMIcon';
import { SendMIcon } from '@alfalab/icons-glyph/SendMIcon';

import styles from './MoneyRequisitesFooter.module.css';

interface MoneyRequisitesFooterProps {
  email: string;
  emailConfirmed: boolean;
  changeEmailUrl?: string;
  onChangeEmail?: () => void;
  sendRequisites?: () => void;
  sending: boolean;
  isMobile?: boolean;
}

export const MoneyRequisitesFooter = ({
  email,
  emailConfirmed,
  changeEmailUrl,
  onChangeEmail,
  sendRequisites,
  sending,
  isMobile,
}: MoneyRequisitesFooterProps) => (
  <div className={styles.container}>
    {!isMobile && (
      <Typography.Text
        view="primary-small"
        color="primary"
        weight="bold"
        tag="p"
      >
        Отправить на почту
      </Typography.Text>
    )}

    <Row gutter={16} align="middle">
      {!isMobile && (
        <Col width={6}>
          <Typography.Text
            view="secondary-large"
            color={emailConfirmed ? 'primary' : 'secondary'}
          >
            {emailConfirmed ? (
              <>
                {email.toLocaleLowerCase()}
                <br />
                {(!!onChangeEmail || !!changeEmailUrl) && (
                  <Typography.Text view="secondary-small" color="secondary">
                    Адрес меняется в{' '}
                    <Link
                      view="secondary"
                      target={changeEmailUrl ? '_blank' : '_self'}
                      href={changeEmailUrl}
                      onClick={onChangeEmail}
                    >
                      Настройках профиля
                    </Link>
                  </Typography.Text>
                )}
              </>
            ) : (
              <>Нет подтвержденного адреса</>
            )}
          </Typography.Text>
        </Col>
      )}
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        {/* TODO: Поменять логику появления кнопки, когда будет
        реализована страница редактирования email */}
        <Button
          dataTestId="email-send-btn"
          size={isMobile ? 'xs' : 'xxs'}
          block
          target={!emailConfirmed && changeEmailUrl ? '_blank' : '_self'}
          href={!emailConfirmed ? changeEmailUrl : ''}
          onClick={emailConfirmed ? sendRequisites : onChangeEmail}
          leftAddons={
            emailConfirmed ? (
              <SendMIcon width={18} height={18} />
            ) : (
              <MailMIcon width={18} height={18} />
            )
          }
          loading={sending}
        >
          {emailConfirmed ? 'Отправить на почту' : 'Указать email'}
        </Button>
      </Col>
      {isMobile && (
        <Col width={12}>
          <Typography.Text
            view="secondary-large"
            color={emailConfirmed ? 'primary' : 'secondary'}
          >
            {emailConfirmed ? (
              <>
                {(!!onChangeEmail || !!changeEmailUrl) && (
                  <Typography.Text
                    view="secondary-small"
                    color="secondary"
                    className={styles.hintMobile}
                  >
                    {`Реквизиты будут отправлены на ${email.toLocaleLowerCase()}. Вы можете поменять адрес в `}
                    <Link
                      view="secondary"
                      target={changeEmailUrl ? '_blank' : '_self'}
                      href={changeEmailUrl}
                      onClick={onChangeEmail}
                    >
                      Настройках профиля
                    </Link>
                  </Typography.Text>
                )}
              </>
            ) : (
              <>Нет подтвержденного адреса</>
            )}
          </Typography.Text>
        </Col>
      )}
    </Row>
  </div>
);
