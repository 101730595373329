import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';
import { LkSignOptions } from '@terminal/core/store/slices/lkSignSlice';

import { CreateAttorneyParams as CreateAttorneyRequest } from './types/CreateAttorneyParams';

export interface CreateAttorneyParams {
  params: CreateAttorneyRequest;
  signOptions?: LkSignOptions;
}

export const createAttorney = async (data: CreateAttorneyParams) => {
  return await signOperation(
    '/api/attorney/set',
    'POST',
    data.params,
    data.signOptions
  );
};
