import { useMemo } from 'react';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';
import { TotalMIcon } from '@alfalab/icons-glyph/TotalMIcon';

import { MoneyHistoryBalance } from '@terminal/core/lib/rest/lkMoney';

import { TotalItem } from './TotalItem';

import styles from './HistoryTotal.module.css';

interface HistoryTotalProps {
  totals?: MoneyHistoryBalance[];
}

export const HistoryTotal = ({ totals }: HistoryTotalProps) => {
  const [totalIn, totalOut, totalBalance] = useMemo(() => {
    const totalIn: React.ReactNode[] = [];
    const totalOut: React.ReactNode[] = [];
    const totalBalance: React.ReactNode[] = [];

    if (totals) {
      totals.forEach((total: MoneyHistoryBalance) => {
        if (total.in !== 0 || total.out !== 0) {
          totalIn.push(
            <TotalItem
              amount={total.in}
              currency={total.currency}
              key={`total-${total.currency}-in`}
              className={styles.item}
            />
          );
          totalOut.push(
            <TotalItem
              amount={total.out}
              currency={total.currency}
              key={`total-${total.currency}-out`}
              className={styles.item}
            />
          );
          totalBalance.push(
            <TotalItem
              amount={total.balance}
              currency={total.currency}
              key={`total-${total.currency}-balance`}
              className={styles.item}
            />
          );
        }
      });
    }

    return [totalIn, totalOut, totalBalance];
  }, [totals]);

  return (
    <div className={styles.container}>
      <Row className={styles.totals}>
        <Col width={4}>
          <span className={styles.directionTitle}>
            <ArrowDownCompactXsIcon
              width={18}
              height={18}
              className={styles.directionIcon}
            />
            <span className={styles.directionText}>Зачисление</span>
          </span>
          {totalIn}
        </Col>
        <Col width={4}>
          <div className={styles.directionTitle}>
            <ArrowUpCompactXsIcon
              width={18}
              height={18}
              className={styles.directionIcon}
            />
            <span className={styles.directionText}>Списание</span>
          </div>
          {totalOut}
        </Col>
        <Col width={4}>
          <div className={styles.directionTitle}>
            <TotalMIcon
              width={18}
              height={18}
              className={styles.directionIcon}
            />
            <span className={styles.directionText}>Сальдо</span>
          </div>
          {totalBalance}
        </Col>
      </Row>
    </div>
  );
};
