import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { ContestCompetitor } from '../model/types/ContestCompetitor';
import { ContestParticipationProfileParams } from './types/ContestParticipationProfileParams';

export async function getContestCompetitors(
  data: ContestParticipationProfileParams
): Promise<ContestCompetitor[]> {
  const result = await fetchLkResult<ContestCompetitor[] | LKResult>(
    qs.stringifyUrl({
      url: '/api/contest/competitors',
      query: { ...data },
    })
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result;
}
