import React from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';

import { MINORITY } from '@terminal/core/constants/ui';
import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';

import { getProcessedCurrency } from '../../lib';

import styles from './TradingAccountOption.module.css';

interface TradingAccountOptionProps {
  account: TradeAccount;
  leftAddons?: React.ReactNode;
}

export const TradingAccountOption = ({
  account,
  leftAddons,
}: TradingAccountOptionProps) => {
  return (
    <div className={styles.container}>
      {leftAddons && <div className={styles.leftAddons}>{leftAddons}</div>}
      <div className={styles.account}>{account.subAccount}</div>
      <div className={styles.place}>{account.placeName}</div>
      <Amount
        className={styles.amount}
        value={account.assetValue * MINORITY}
        minority={MINORITY}
        currency={getProcessedCurrency(account.currency) as CurrencyCodes}
        bold="none"
      />
    </div>
  );
};
