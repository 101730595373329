import { useStore } from '../store/useStore';

import { ChatMessage } from '../types';

interface BrokerChatRoom {
  id: string;
  description: string;
  missedCount: number;
}

/**
 * Хук возвращает список чатов на основе всех сообщений.
 *
 * @param messagesMap Мапа сообщений.
 */
export const useGetBrokerChatRooms = (
  messagesMap: Record<string, ChatMessage[]>
): BrokerChatRoom[] => {
  const unreadMsgCount = useStore((store) => store.unreadMsgCount);

  return Object.entries(messagesMap).map(([chatId, messages]) => {
    return {
      id: chatId,
      description: messages[messages.length - 1].text,
      missedCount: unreadMsgCount[chatId],
    };
  });
};
