import { useMutation } from 'react-query';

import { trackPriceService } from '../trackPriceService';

import useNotification from '../../../../hooks/useNotification';

import { useStore } from '../../../../store';
import { trackPricesSelector } from '../../../../store/selectors/trackPrice/trackPricesSelector';

import { NotificationType } from '../../../../types/ui';

export const useTrackPriceDeleteAll = (idFi?: number) => {
  const addNotification = useNotification();
  const trackPrices = useStore(trackPricesSelector);

  return useMutation(
    async () => {
      const trackPricesToRemove =
        idFi === undefined
          ? trackPrices
          : trackPrices.filter((trackPrice) => trackPrice.IdFI === idFi);

      return trackPriceService.deleteAlerts(trackPricesToRemove);
    },
    {
      onError: (error: Error) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка удаления уведомлений о цене',
          text: error?.message,
        });
      },
    }
  );
};
