import React, { useEffect, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import {
  Tab,
  TabsDesktop,
  TabsDesktopProps,
} from '@alfalab/core-components/tabs/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowsRightLeftCurvedMIcon } from '@alfalab/icons-glyph/ArrowsRightLeftCurvedMIcon';
import { ToolsMIcon } from '@alfalab/icons-glyph/ToolsMIcon';

import { LOCATIONS } from '@terminal/core/constants/analytics';
import { IS_AI, IS_GI } from '@terminal/core/env';
import { trackMainMenu } from '@terminal/core/lib/analytics';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { Management } from '@terminal/lk-desktop-go';

import { SelectTreaty } from '../components/SelectTreaty';
import { MainInfo } from './MainInfo';

import { useAccountInfo, useAccountInfoGI } from './hooks';

import styles from './AccountsInfo.module.css';

enum Subpage {
  Info = 'info',
  Management = 'management',
}

export const AccountsInfo = React.memo(
  ({ isMobile }: { isMobile?: boolean }) => {
    const [treaty, setTreaty] = useState<Treaty | null>(null);
    const [subpage, setSubpage] = useState<Subpage>(Subpage.Info);

    useEffect(() => {
      if (subpage === Subpage.Info) {
        trackMainMenu(LOCATIONS.LK_MAIN_INFO);
      } else {
        trackMainMenu(LOCATIONS.LK_ACCOUNTS);
      }
    }, [subpage]);

    const {
      data: accountInfo,
      isFetching: accountInfoPending,
      refetch: refetchAccountInfo,
      isError: isAccountInfoError,
    } = useAccountInfo(treaty?.treaty, IS_AI);

    const {
      data: accountInfoGI,
      isFetching: accountInfoPendingGI,
      refetch: refetchAccountInfoGI,
      isError: isAccountInfoGIError,
    } = useAccountInfoGI(treaty?.treaty, IS_GI);

    const handleChange: TabsDesktopProps['onChange'] = (_, { selectedId }) =>
      setSubpage(selectedId as Subpage);

    const onAccountSave = () => {
      IS_GI ? refetchAccountInfoGI() : refetchAccountInfo();
    };

    const onSubaccountAdd = () => {
      IS_GI ? refetchAccountInfoGI() : refetchAccountInfo();
    };

    const onSubaccountRedacted = () => {
      IS_GI ? refetchAccountInfoGI() : refetchAccountInfo();
    };

    if (isAccountInfoError || isAccountInfoGIError) {
      return (
        <div className={styles.errorModal}>
          <SuperEllipse
            size={64}
            backgroundColor="var(--color-light-bg-tertiary)"
          >
            <ToolsMIcon />
          </SuperEllipse>
          <Gap size="xl" direction="vertical" />
          <Typography.Text view="primary-large" weight="bold">
            Временные неполадки
          </Typography.Text>
          <Gap size="m" direction="vertical" />
          <Typography.Text color="secondary" view="secondary-large">
            Извините, уже исправляем. Попробуйте обновить <br />
            страницу или вернуться позже.
          </Typography.Text>
          <Gap size="2xl" direction="vertical" />
          <ButtonDesktop
            onClick={() => {
              IS_GI ? refetchAccountInfoGI() : refetchAccountInfo();
            }}
            size="xs"
            view="tertiary"
            leftAddons={<ArrowsRightLeftCurvedMIcon width={18} height={18} />}
            loading={accountInfoPending || accountInfoPendingGI}
            className={styles.refreshButton}
          >
            Обновить страницу
          </ButtonDesktop>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.treaty}>
          <SelectTreaty
            zeros={isMobile}
            hint="Все настройки будут применены к выбранному счету"
            onSelect={(treaty) => setTreaty(treaty)}
            mobile={isMobile}
          />
        </div>
        <div className={styles.content}>
          <TabsDesktop
            selectedId={subpage}
            onChange={handleChange}
            scrollable={true}
            size="s"
          >
            <Tab
              title="Основная информация"
              id={Subpage.Info}
              className={styles.tab}
            >
              <MainInfo
                treaty={treaty}
                accountInfo={IS_GI ? accountInfoGI : accountInfo}
                accountInfoPending={accountInfoPending || accountInfoPendingGI}
                onAccountSave={onAccountSave}
                isMobile={isMobile}
              />
            </Tab>
            <Tab
              title="Управление счетами"
              id={Subpage.Management}
              className={styles.tab}
            >
              <Management
                treaty={treaty}
                accountInfo={IS_GI ? accountInfoGI : accountInfo}
                refetchAccountInfo={
                  IS_GI ? refetchAccountInfoGI : refetchAccountInfo
                }
                accountInfoPending={accountInfoPending || accountInfoPendingGI}
                onSubaccountAdd={onSubaccountAdd}
                onSubaccountRedacted={onSubaccountRedacted}
              />
            </Tab>
          </TabsDesktop>
        </div>
      </div>
    );
  }
);
