import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  RiskProfile,
  updateRiskProfile,
} from '@terminal/core/lib/rest/lkRiskProfile';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useUpdateProfile = (params?: Partial<RiskProfile>) => {
  const addNotification = useNotification();

  return useMutation(
    [LkQueryKeys.UpdateRiskProfile],
    async () => {
      if (params) {
        return updateRiskProfile(params);
      }
    },
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса профиля риска',
          text: error?.message || '',
        });
      },
    }
  );
};
