import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

export function useTreaties<R>(fetchTrearies: () => Promise<R>) {
  const addNotification = useNotification();

  return useQuery(['lk', 'account/treaties'], fetchTrearies, {
    staleTime: 60 * 60 * 1000,
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса списка счетов',
        text: error?.message || '',
      });
    },
  });
}
