import { VFC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';

import { Result } from '../../../../shared';

import styles from './ConfirmCancelSecuritiesTransfer.module.css';

interface FormAppliedProps {
  primaryButtonLabel?: string;
  onPrimaryButtonClick: () => void;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick: () => void;
}

export const ConfirmCancelSecuritiesTransfer: VFC<FormAppliedProps> = ({
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
}) => (
  <Result
    title="Поручение будет отменено"
    text="Вы уверены, что хотите продолжить?"
    icon={
      <SuperEllipse size={48}>
        <ExclamationMIcon />
      </SuperEllipse>
    }
  >
    <div className={styles.buttonGroup}>
      <ButtonDesktop
        size="xs"
        view="tertiary"
        block
        onClick={onSecondaryButtonClick}
      >
        {secondaryButtonLabel || 'Отмена'}
      </ButtonDesktop>
      <ButtonDesktop
        size="xs"
        view="accent"
        block
        onClick={onPrimaryButtonClick}
      >
        {primaryButtonLabel || 'Продолжить'}
      </ButtonDesktop>
    </div>
  </Result>
);
