import { useQuery } from 'react-query';

import { requestSignText } from '@terminal/core/lib/rest/lkSignOperation';

export const useSignText = (
  operationUrl: string | undefined,
  open: boolean
) => {
  const query = useQuery(
    ['sign-text', { operationUrl }],
    () => requestSignText(operationUrl || ''),
    {
      enabled: !!operationUrl && open,
    }
  );
  return query;
};
