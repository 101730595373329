import qs from 'query-string';

import { getAuthorizationHeader } from '@terminal/core/lib/rest/investApi';
import { fetchThroughProxy } from '@terminal/core/lib/url';

export type NewsRequestType = {
  /** Указатель для пагинации */
  cursor?: string;
  /** Список поставщиков новостей (фильтр) */
  sources?: string[];
  /** Список ISIN инструментов (фильтр) */
  isins?: string[];
  /** Количество новостей, ожидаемое в ответе */
  count?: number;
  /** Типы важности новостей (фильтр) */
  importanceTypes?: string[];
  /** Признак важности */
  sentiment?: string;
  /** Время автообновления новостей */
  refetchInterval?: number;
};

export async function getNews(endpoint: string, params: NewsRequestType = {}) {
  const queryParams = qs.stringify(params);

  const response = await fetchThroughProxy(`${endpoint}?${queryParams ?? ''}`, {
    method: 'GET',
    headers: {
      ...getAuthorizationHeader(),
    },
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
}
