import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';
import { LkQueryKeys } from '@terminal/lk/legacy/queryKeys';

import { listAttorneys } from '../api/listAttorneys';

import { ListAttorneysParams } from '../api/types/ListAttorneysParams';

export const useAttorneys = (params: ListAttorneysParams) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.Attorneys, params],
    () => listAttorneys(params),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'При запросе доверенностей произошла ошибка',
        });
      },
    }
  );
};
