import React, { FC } from 'react';

import { useStore } from '@terminal/core/store';
import { currencyObjectByIdFi } from '@terminal/core/store/selectors/core/currencyObjectByIdFi';
import { TrackPrice } from '@terminal/core/types/trackPrice';

import { PriceAmount } from '../../../../../shared/ui/PriceAmount';

export type PriceCellProps = {
  trackPrice: TrackPrice;
};

export const PriceCell: FC<PriceCellProps> = (props) => {
  const { trackPrice } = props;
  const currencyObject = useStore(currencyObjectByIdFi)(trackPrice.IdFI);

  return (
    <PriceAmount
      value={trackPrice.TargetPrice}
      currency={currencyObject?.symbolObject}
    />
  );
};
