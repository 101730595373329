import { Button } from '@alfalab/core-components/button';
import { ConfirmationProps } from '@alfalab/core-components/confirmation';
import { Typography } from '@alfalab/core-components/typography';

import { IS_GI } from '@terminal/core/env';

import styles from './Confirmation.module.css';

// todo: check layout
export const ConfirmationHint = (props: ConfirmationProps) => {
  const onClickReturn = () => {
    props.onChangeScreen('INITIAL');
  };

  const officeTxt = IS_GI ? 'в офис брокера' : 'в любое отделение банка';
  const phone1 = IS_GI ? '8 (800) 101-41-01' : '8 (800) 707-55-55';
  const phone2 = IS_GI ? '8 (495) 009-44-88' : '+7 (495) 786-48-86';

  return (
    <div className={styles.wrapper}>
      <Typography.Title view="small" tag="div" className={styles.title}>
        Не приходит сообщение?
      </Typography.Title>
      <Typography.Title view="xsmall" tag="div" className={styles.subTitle}>
        Если у вас изменился номер телефона, позвоните нам или обратитесь{' '}
        {officeTxt}.
      </Typography.Title>
      <Typography.Title view="xsmall" tag="div" className={styles.phoneRow}>
        <div className={styles.phone}>{phone1}</div>
        <div>&nbsp;—&nbsp;</div>
        <div>для звонков по России</div>
      </Typography.Title>
      <Typography.Title view="xsmall" tag="div" className={styles.phoneRow}>
        <div className={styles.phone}>{phone2}</div>
        <div>&nbsp;—&nbsp;</div>
        <div>в Москве и за границей</div>
      </Typography.Title>
      <Typography.Title view="xsmall" tag="div" className={styles.subTitle}>
        Если номер не менялся, возможно, перегружен сервис отправки сообщений.
        Попробуйте повторить действие через несколько минут
      </Typography.Title>
      <Button
        view="secondary"
        size="s"
        onClick={onClickReturn}
        className={styles.button}
      >
        Вернуться к вводу кода
      </Button>
    </div>
  );
};
