import { memo, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { trackClickOnDesktop } from '@terminal/core/lib/analytics';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';

import { TabButton } from '../TabButton';

import { ContextMenuHeaderTabConfig } from '../../model/types';

export type TabProps = {
  tabKey: number;
  isChildWindow?: boolean;
  setHeaderTabContextMenuConfig: (value: ContextMenuHeaderTabConfig) => void;
};

export const Tab = memo(
  ({ tabKey, isChildWindow, setHeaderTabContextMenuConfig }: TabProps) => {
    const [
      getActiveLayoutKey,
      setActiveLayoutKey,
      removeLayout,
      setLayoutName,
    ] = useStore(
      (state) => [
        state.getActiveLayoutKey,
        state.setActiveLayoutKey,
        state.removeLayout,
        state.setLayoutName,
      ],
      shallow
    );

    const { lastAutoSavedConfig } = useStore(activeConfigurationSelector);
    const { layoutNames, models } = lastAutoSavedConfig;

    const [tabName, setTabName] = useState(layoutNames[tabKey]);
    const storeTabName = layoutNames[tabKey];
    const nodeRef = useRef(null);

    useEffect(() => {
      setTabName(storeTabName);
    }, [storeTabName]);

    const onTabClick = () => {
      trackClickOnDesktop(String(tabKey));
      setActiveLayoutKey(tabKey);
    };

    const onTabCloseClick = (event: SyntheticEvent) => {
      event.stopPropagation();
      removeLayout(tabKey);
    };

    return (
      <TabButton
        ref={nodeRef}
        label={tabName}
        editable
        closable={!isChildWindow && models.length > 1}
        selected={getActiveLayoutKey() === tabKey}
        onClick={onTabClick}
        onRightClick={(e) => {
          e.preventDefault();
          setHeaderTabContextMenuConfig({
            isShow: true,
            tabId: tabKey,
            clientX: e.clientX,
            clientY: e.clientY,
          });
        }}
        onEdit={(name) => {
          setTabName(name);
        }}
        onEditFinish={() => {
          setLayoutName(tabKey, tabName || `Рабочий стол ${tabKey + 1}`);
        }}
        onClose={onTabCloseClick}
        dataTestId={getActiveLayoutKey() === tabKey ? 'Tab--selected' : 'Tab'}
      />
    );
  }
);
