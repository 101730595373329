import React, { useState } from 'react';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop/Component.desktop';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import { AdditionalInstructionData } from '@terminal/core/types/additionalInstruction';

import { Iceberg } from './Iceberg';

interface Props extends BaseModalProps {
  value: number;
  isLotCount: boolean;
  lot?: number;
  onSubmit: (instruction: AdditionalInstructionData['data']) => void;
  onClose: () => void;
}

const isNumberDefined = (value?: number): value is number => Boolean(value);

export const IcebergModal = ({
  value,
  isLotCount,
  lot,
  onSubmit,
  onClose,
  ...baseModalProps
}: Props) => {
  const [icebergQuantity, setIcebergQuantity] = useState(value);
  const [touched, setTouched] = useState(false);
  const isError =
    !isLotCount && isNumberDefined(lot) && icebergQuantity % lot !== 0;
  const lots = isLotCount
    ? icebergQuantity
    : Math.ceil(icebergQuantity / (lot || 1));

  const touchedError =
    touched && lots < 100
      ? `Минимальный видимый объем = ${
          isLotCount ? '100 лотов' : `${100 * (lot || 1)} шт`
        }`
      : null;

  const handleSave = () => {
    onSubmit({ visibleQuantity: icebergQuantity });
  };

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <FunctionalModal
        backdrop={false}
        width={270}
        title="Айсберг заявка"
        paddingSize="xs"
        onClose={onClose}
        drawerId="iceberg"
        footer={
          <Space direction="horizontal" size="m" align="center">
            <Button block view="ghost" size="xs" onClick={onClose}>
              <Typography.Text view="secondary-large">Отмена</Typography.Text>
            </Button>
            <Button
              disabled={isError || lots < 100}
              block
              view="secondary"
              size="xs"
              onClick={handleSave}
            >
              Применить
            </Button>
          </Space>
        }
      >
        <Iceberg
          icebergQuantity={icebergQuantity}
          setIcebergQuantity={setIcebergQuantity}
          setTouched={setTouched}
          isLotCount={isLotCount}
          lot={lot}
          error={
            isError
              ? `1 лот = ${lot || 1} шт. Объем должен быть кратным лоту`
              : touchedError
          }
        />
      </FunctionalModal>
    </BaseModal>
  );
};
