import sumBy from 'lodash/sumBy';

import { ECurrencyId } from '../../types/currencyId';
import { PositionItem } from '../../types/position';

/**
 * @returns Стоимость шортовых позиций без учета позиций по рублям и фьючерсам в рублевом эквиваленте
 * */
export function calculateShortBalance(positions: PositionItem[]) {
  return sumBy(
    positions.filter(
      (tr) =>
        tr.position?.idObject !== ECurrencyId.RUR && Number(tr.torgPos) < 0
    ),
    'torgPosCostRur'
  );
}
