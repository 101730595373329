export const NDXList = [
  'AAPL',
  'ABNB',
  'ADBE',
  'ADI',
  'ADP',
  'ADSK',
  'AEP',
  'ALGN',
  'AMAT',
  'AMD',
  'AMGN',
  'AMZN',
  'ANSS',
  'ASML',
  'ATVI',
  'AVGO',
  'AZN',
  'BIDU',
  'BIIB',
  'BKNG',
  'CDNS',
  'CEG',
  'CHTR',
  'CMCSA',
  'COST',
  'CPRT',
  'CRWD',
  'CSCO',
  'CSX',
  'CTAS',
  'CTSH',
  'DDOG',
  'DLTR',
  'DOCU',
  'DXCM',
  'EA',
  'EBAY',
  'EXC',
  'FAST',
  'FISV',
  'FTNT',
  'GILD',
  'GOOG',
  'GOOGL',
  'HON',
  'IDXX',
  'ILMN',
  'INTC',
  'INTU',
  'ISRG',
  'JD',
  'KDP',
  'KHC',
  'KLAC',
  'LCID',
  'LRCX',
  'LULU',
  'MAR',
  'MCHP',
  'MDLZ',
  'MELI',
  'META',
  'MNST',
  'MRNA',
  'MRVL',
  'MSFT',
  'MTCH',
  'MU',
  'NFLX',
  'NTES',
  'NVDA',
  'NXPI',
  'ODFL',
  'OKTA',
  'ORLY',
  'PANW',
  'PAYX',
  'PCAR',
  'PDD',
  'PEP',
  'PYPL',
  'QCOM',
  'REGN',
  'ROST',
  'SBUX',
  'SGEN',
  'SIRI',
  'SNPS',
  'SPLK',
  'SWKS',
  'TEAM',
  'TMUS',
  'TSLA',
  'TXN',
  'VRSK',
  'VRSN',
  'VRTX',
  'WBA',
  'WDAY',
  'XEL',
];
