import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { groupBy } from 'lodash';
import { useCallback, useState } from 'react';

import { MoneyHistoryItem } from '@terminal/core/lib/rest/lkMoney';

import { HistoryItem } from './HistoryItem';
import { HistoryItemDetail } from './HistoryItemDetail';

import tablestyles from '../tables.module.css';
import historytabtyles from './historyTables.module.css';

interface HistoryListProps {
  history: MoneyHistoryItem[] | null;
}

export const HistoryList = ({ history }: HistoryListProps) => {
  const [detail, setDetail] = useState<MoneyHistoryItem | null>(null);

  const dateGroups = groupBy(history, (item) => {
    return format(parseISO(item.date), 'd MMMM yyyy', { locale: ru });
  });

  const getKeyTitle = useCallback((key: string) => {
    return key === format(new Date(), 'd MMMM yyyy', { locale: ru })
      ? 'Сегодня'
      : key;
  }, []);

  return (
    <>
      <div className={tablestyles.table}>
        <div className={tablestyles.head}>
          <div className={cn(tablestyles.row, tablestyles.rowHead)}>
            <div
              className={cn(
                tablestyles.cell,
                tablestyles.cellHead,
                historytabtyles.cellAccount
              )}
            >
              Субсчет
            </div>
            <div
              className={cn(
                tablestyles.cell,
                tablestyles.cellHead,
                historytabtyles.cellType
              )}
            >
              Направление
            </div>
            <div
              className={cn(
                tablestyles.cell,
                tablestyles.cellHead,
                historytabtyles.cellAmount
              )}
            >
              Сумма
            </div>
            <div
              className={cn(
                tablestyles.cell,
                tablestyles.cellHead,
                historytabtyles.cellPlace
              )}
            >
              Рынок
            </div>
            <div
              className={cn(
                tablestyles.cell,
                tablestyles.cellHead,
                historytabtyles.cellTime
              )}
            >
              Время
            </div>
          </div>
        </div>
        <div className={tablestyles.body}>
          {Object.keys(dateGroups).map((key) => (
            <div className={tablestyles.group} key={key}>
              <div className={tablestyles.groupTitle}>{getKeyTitle(key)}</div>
              {dateGroups[key].map((item, index) => (
                <HistoryItem
                  key={item.id}
                  item={item}
                  odd={(index + 1) % 2 !== 0}
                  onClick={(item) => setDetail(item)}
                />
              ))}
            </div>
          ))}
        </div>
      </div>

      <HistoryItemDetail
        open={Boolean(detail)}
        item={detail}
        onClose={() => setDetail(null)}
      />
    </>
  );
};
