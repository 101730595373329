import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { ValidateResult } from '../../../../shared/hooks/useValidation';

export function validateBankAccountNumber(
  account: string,
  treaty?: Treaty
): ValidateResult {
  if (!account.length) {
    return {
      code: 1,
      message: 'Введите номер счёта',
    };
  } else if (treaty && !treaty.isFirm) {
    if (treaty.isCurrResident) {
      if (!account.startsWith('40817') && !account.startsWith('42301')) {
        return {
          code: 3,
          message: 'Вывод средств возможен только на собственный счёт',
        };
      }
    } else {
      if (!account.startsWith('40820') && !account.startsWith('42601')) {
        return {
          code: 3,
          message: 'Вывод средств возможен только на собственный счёт',
        };
      }
    }
  } else if (!/^[0-9]{20}$/.test(account)) {
    return {
      code: 2,
      message: 'Состоит из 20 цифр',
    };
  }

  return {
    code: 0,
    message: '',
  };
}

export function isBankAccountNumberValid(
  account: string,
  treaty?: Treaty
): boolean {
  return validateBankAccountNumber(account, treaty).code === 0;
}
