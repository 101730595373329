import groupBy from 'lodash/groupBy';

import { plural } from '@terminal/core/lib/plural';
import { useStore } from '@terminal/core/store';
import { Widget, WidgetLinkProps } from '@terminal/core/types/layout';

type WidgetWithSubName = Exclude<
  Widget,
  | Widget.WATCHLIST
  | Widget.ASSETS
  | Widget.DEVTOOLS
  | Widget.CERTIFICATES
  | Widget.OPERATIONS_HISTORY
  | Widget.NEWS
>;

type SelectFunction = (config: WidgetLinkProps | null) => string | undefined;

export const selectSymbol: SelectFunction = (config) => config?.symbol;

export const selectAccount: SelectFunction = (config) => {
  const subAccountRazdels = useStore.getState().subAccountRazdel;
  const selectedSubAccounts = config?.tableProps?.accounts;
  const razdelsBySubAccounts = groupBy(
    subAccountRazdels.filter((item) => item),
    'codeSubAccount'
  );
  const subAccountsKeys = Object.keys(razdelsBySubAccounts);

  const isEverySubAccountSelected =
    selectedSubAccounts?.length === subAccountsKeys.length;

  // Если сабсчетов на аккаунте не больше 1 или не выбран ни один счет - ничего не показываем
  if (
    subAccountsKeys.length <= 1 ||
    (selectedSubAccounts && !selectedSubAccounts.length) ||
    // Проверка на аккаунты с другой учетной записи
    (selectedSubAccounts?.length &&
      !selectedSubAccounts?.every((acc) => subAccountsKeys.includes(acc)))
  ) {
    return;
  }

  // Если выбраны все сабсчета - пишем "Все"
  if (!selectedSubAccounts || isEverySubAccountSelected) {
    return 'Все';
  }

  // Если выбрано больше 1, но не все, пишем "n счетов"
  if (selectedSubAccounts.length > 1) {
    return `${selectedSubAccounts.length} ${plural(
      ['счёт', 'счёта', 'счетов'],
      selectedSubAccounts.length
    )}`;
  }

  // Иначе отображаем номер выбранного сабсчета
  if (selectedSubAccounts.length) {
    const selectedSubAccountCode = selectedSubAccounts[0];

    // Нужно понять один ли сабсчет существует в рамках этого счета, если да - можно стереть -000 в конце
    const hasSiblingSubAccounts =
      subAccountRazdels.filter(
        (razdel) =>
          razdel.codeSubAccount !== selectedSubAccountCode &&
          razdel.idAccount ===
            razdelsBySubAccounts[selectedSubAccountCode][0].idAccount
      ).length > 0;

    return hasSiblingSubAccounts
      ? selectedSubAccountCode
      : selectedSubAccountCode.split('-')[0];
  }
};

const WIDGET_SUB_NAME_MAP: Record<WidgetWithSubName, SelectFunction> = {
  // Отображать в подписи выбранный инструмент для виджетов График, Торговля, Стакан, Лента сделок
  [Widget.CHART]: selectSymbol,
  [Widget.HYBRIDORDER]: selectSymbol,
  [Widget.INSTRUMENT_INFO]: selectSymbol,
  [Widget.ORDERBOOK]: selectSymbol,
  [Widget.TRADES]: selectSymbol,
  // Для виджетов Балансы, Позиции, Операции, Ордера - отображаем выбранные счета
  [Widget.BALANCE]: selectAccount,
  [Widget.POSITIONS]: selectAccount,
  [Widget.OPERATIONS]: selectAccount,
  [Widget.ORDERS]: selectAccount,
};

export const getWidgetSubName = (
  widget: Widget,
  config: null | WidgetLinkProps
) => {
  return WIDGET_SUB_NAME_MAP[widget]?.(config) || '';
};
