import { useEffect, useState } from 'react';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { Radio } from '@alfalab/core-components/radio';
import { Spinner } from '@alfalab/core-components/spinner';
import { Typography } from '@alfalab/core-components/typography';

import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';

import { useDownloadFile } from '../../../../shared';

import { ThemeQuestion } from '../../model/types/ThemeQuestion';

import styles from './TestQuestion.module.css';

interface ImageLoading {
  pending: boolean;
  file: DownloadedFile | null;
}

interface TestQuestionProps {
  question: ThemeQuestion;
  selectedId: number[];
  setAnswer: (id: number) => void;
  getImageUrl?: (fileName: string) => string;
}

export const TestQuestion = ({
  question,
  selectedId,
  setAnswer,
  getImageUrl,
}: TestQuestionProps) => {
  const [image, setImage] = useState<ImageLoading>({
    pending: false,
    file: null,
  });

  const { mutateAsync: downloadFile } = useDownloadFile();

  useEffect(() => {
    const getImage = async () => {
      if (question.picture && getImageUrl) {
        setImage({
          pending: true,
          file: null,
        });

        const file = await downloadFile(getImageUrl(question.picture));

        setImage({
          pending: false,
          file: file,
        });
      }
    };

    getImage();
  }, [question.picture, getImageUrl, downloadFile]);

  return (
    <div className={styles.container}>
      {(image.pending || image.file) && (
        <div className={styles.stage}>
          <Spinner visible={image.pending} />
          {image.file && !image.pending && (
            <img className={styles.image} src={image.file.url} alt="" />
          )}
        </div>
      )}
      <Typography.Text
        className={styles.title}
        view="secondary-large"
        weight="bold"
        tag="div"
      >
        {question.text}
      </Typography.Text>

      <div className={styles.answers}>
        {question.answers.map(
          (answer) =>
            (question.type === 'checkbox' && (
              <Checkbox
                key={answer.id}
                className={styles.answerItem}
                size="s"
                label={answer.text}
                onChange={() => setAnswer(answer.id)}
                checked={selectedId.includes(answer.id)}
              />
            )) ||
            (question.type === 'radio' && (
              <Radio
                key={answer.id}
                className={styles.answerItem}
                size="s"
                label={answer.text}
                onChange={() => setAnswer(answer.id)}
                checked={selectedId.includes(answer.id)}
              />
            ))
        )}
      </div>
    </div>
  );
};
