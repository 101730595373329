import { FC, memo, ReactNode } from 'react';

import { WidgetSettings, WidgetSettingsProps } from '../../../WidgetSettings';

import styles from './WidgetHeader.module.css';

interface Props {
  inputs?: ReactNode;
  filters?: ReactNode;
  widgetSettings?: WidgetSettingsProps;
  controls?: ReactNode;
}

const Header: FC<Props> = ({ inputs, filters, widgetSettings, controls }) => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {inputs && <div className={styles.inputs}>{inputs}</div>}

        {filters && <div className={styles.filters}>{filters}</div>}
      </div>

      {(widgetSettings || controls) && (
        <div className={styles.controls}>
          {controls}

          {widgetSettings && <WidgetSettings {...widgetSettings} />}
        </div>
      )}
    </div>
  );
};

export const WidgetHeader = memo(Header);
