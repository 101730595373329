import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getContestPublicDigest } from '../api/getContestPublicDigest';

export const useContestPublicDigest = (digestId: number) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'contest/publicdigest', { digestId }],
    () => getContestPublicDigest({ digestId }),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message || 'При запросе дайджеста конкурса произошла ошибка',
        });
      },
    }
  );
};
