import { Select, SelectProps } from 'formik-alfalab-core-components/Select';
import React, { FC } from 'react';

import {
  TrackPriceSettingLineType,
  TrackPriceSettings,
} from '@terminal/core/types/trackPrice';

import { Option } from './Option';

export type LineTypeSelectProps = Omit<SelectProps, 'options' | 'name'>;

const OPTIONS: SelectProps['options'] = [
  {
    key: TrackPriceSettingLineType.Solid,
    value: TrackPriceSettingLineType.Solid,
    content: <Option variant="solid">Сплошная</Option>,
  },
  {
    key: TrackPriceSettingLineType.Dashed,
    value: TrackPriceSettingLineType.Dashed,
    content: <Option variant="dashed">Пунктирная</Option>,
  },
];

const NAME: keyof TrackPriceSettings = 'lineType';

export const LineTypeSelect: FC<LineTypeSelectProps> = (props) => {
  return (
    <Select
      name={NAME}
      block
      label="Тип линии"
      labelView="outer"
      size="s"
      options={OPTIONS}
      {...props}
    />
  );
};
