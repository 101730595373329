import { OrderDimensionEnum } from '@terminal/core/types/ui';

import { useWidgetContext } from '../context';

export const useIsLotCount = () => {
  const { useSettings } = useWidgetContext();
  const settings = useSettings();

  return settings.defaultValues.orderDimension === OrderDimensionEnum.LOT;
};
