import { createContext, useContext } from 'react';

import { MoneyLimitResult } from '@terminal/core/lib/rest/lkMoney';

export type MoneyLimitContextType = {
  limit: MoneyLimitResult | undefined;
  setLimit: (c: MoneyLimitResult | undefined) => void;
};
export const MoneyLimitContext = createContext<MoneyLimitContextType>({
  limit: undefined,
  setLimit: () => {},
});
export const useMoneyLimitContext = () => useContext(MoneyLimitContext);
