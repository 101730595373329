import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import {
  useAlfaDirectContext,
  useFilteredSubAccounts,
} from '@terminal/alfadirect/hooks';
import { sortObjKeys } from '@terminal/core/lib/sortObjKeys';
import { AccountItem } from '@terminal/core/types/account';
import { TableProps } from '@terminal/core/types/layout';
import { SubAccountRazdelItem } from '@terminal/core/types/subAccountRazdel';

export const useSelectedAccount = (tableProps?: TableProps) => {
  const { subAccountRazdels, accounts: clientAccounts } =
    useAlfaDirectContext();

  // Группируем для получения всех возможных вариантов выбора в селекте
  const [accounts, subAccounts] = useMemo(() => {
    // Счета приходят не сразу, так что фильтруем undefined
    const filteredRazdels = subAccountRazdels.filter((item) => item);

    return [
      groupBy(filteredRazdels, 'idAccount'),
      groupBy(filteredRazdels, 'codeSubAccount'),
    ];
  }, [subAccountRazdels]);

  const filteredSubAccounts = useFilteredSubAccounts();

  const selectedSubAccounts = useMemo(() => {
    if (tableProps?.accounts) {
      return sortBy(tableProps.accounts);
    } else {
      return Object.keys(sortObjKeys(filteredSubAccounts));
    }
  }, [filteredSubAccounts, tableProps?.accounts]);

  //Ищем информацию по тарифу аккаунта (нужна для рассчета комиссии)
  const [selectedAccount, selectedSubAccountId] = useMemo(() => {
    let selectedSubAccount: SubAccountRazdelItem | undefined;
    let selectedAccount: AccountItem | undefined;
    let selectedSubAccountId = -1;

    if (subAccounts && selectedSubAccounts?.[0]) {
      selectedSubAccount = subAccounts?.[selectedSubAccounts?.[0]]?.[0];
    }

    if (selectedSubAccount) {
      selectedSubAccountId = selectedSubAccount.idSubAccount;
      selectedAccount = clientAccounts.find(
        (account) => account.idAccount === selectedSubAccount?.idAccount
      );
    }

    return [selectedAccount, selectedSubAccountId];
  }, [clientAccounts, selectedSubAccounts, subAccounts]);

  return {
    accounts,
    subAccounts,
    filteredSubAccounts,
    selectedAccount,
    selectedSubAccountId,
    selectedSubAccounts,
  };
};
