import unset from 'lodash/unset';
import { GetState } from 'zustand';

import type { RootStore, StoreSet } from '../useStore';

import { TrackPrice, TrackPriceState } from '../../types/trackPrice';

export interface TrackPriceSlice {
  /**
   * Проинициализированы ли алерты
   * */
  isTrackPriceInitialized: boolean;
  /**
   * Список алертов
   * */
  trackPrices: TrackPrice[];
  /**
   * Список алертов, для которых нужно показать уведомления
   * */
  trackPricesForTrigger: TrackPrice[];
  /**
   * Алерт, редактируемый в данный момент
   * */
  trackPriceForEdit?: TrackPrice;
  /**
   * Если `false`, то модальное окно с формой редактирования алерта не отображается
   * */
  isTrackPriceEditModalOpen: boolean;
  /**
   * Если `true`, то модальное окно «Общие настройки» отображается
   * */
  isTrackPriceSettingsModalOpen: boolean;
  /**
   * Если `true`, то модальное окно «Таблица алертов» отображается
   * */
  isTrackPriceListModalOpen: boolean;
  /**
   * Метод для обновления алертов
   * */
  updateTrackPrices: (trackPricesUpdated: TrackPrice[]) => void;
  /**
   * Удаляет сработавший алерт
   * */
  dismissTrackPrice: (trackPrice: TrackPrice) => void;
  /**
   * Удаляет все сработавшие алерты
   * */
  dismissTrackPriceAll: () => void;
  /**
   * Отмечает сработавший алерт как просмотренный
   * */
  snoozeTrackPrice: (trackPrice: TrackPrice) => void;
  /**
   * Открывает модальное окно с формой редактирования алерта
   * */
  openEditTrackPriceModal: (trackPrice: TrackPrice) => void;
  /**
   * Закрывает модальное окно с формой редактирования алерта
   * */
  closeEditTrackPriceModal: () => void;
  /**
   * Открывает модальное окно «Общие настройки»
   * */
  openTrackPriceSettingsModal: () => void;
  /**
   * Закрывает модальное окно «Общие настройки»
   * */
  closeTrackPriceSettingsModal: () => void;
  /**
   * Открывает модальное окно «Таблица алертов»
   * */
  openTrackPriceListModal: () => void;
  /**
   * Закрывает модальное окно «Таблица алертов»
   * */
  closeTrackPriceListModal: () => void;
}

type CreateSlice = (set: StoreSet, get: GetState<RootStore>) => TrackPriceSlice;

export const createTrackPriceSlice: CreateSlice = (set, get) => ({
  isTrackPriceInitialized: false,
  trackPrices: [],
  trackPricesForTrigger: [],
  trackPriceForEdit: undefined,
  isTrackPriceEditModalOpen: false,
  isTrackPriceSettingsModalOpen: false,
  isTrackPriceListModalOpen: false,

  updateTrackPrices: (trackPricesUpdated) => {
    const active: TrackPrice[] = [];
    const fired: TrackPrice[] = [];
    const deletedIds: TrackPrice['IdTrackPrice'][] = [];
    const expiredIds: TrackPrice['IdTrackPrice'][] = [];

    trackPricesUpdated.forEach((trackPrice) => {
      if (trackPrice.IdState === TrackPriceState.Active) {
        return active.push(trackPrice);
      }

      if (trackPrice.IdState === TrackPriceState.Deleted) {
        return deletedIds.push(trackPrice.IdTrackPrice);
      }

      if (trackPrice.IdState === TrackPriceState.Expired) {
        return expiredIds.push(trackPrice.IdTrackPrice);
      }

      if (trackPrice.IdState === TrackPriceState.Fired) {
        return fired.push(trackPrice);
      }
    });

    if (!get().isTrackPriceInitialized) {
      return set((state) => {
        state.trackPrices = active;
        state.isTrackPriceInitialized = true;
      });
    }

    const currentTrackPrices = get().trackPrices;
    const trackPricesForTrigger = get().trackPricesForTrigger.slice();
    const trackPrices: TrackPrice[] = active.filter(
      (trackPrice) =>
        !currentTrackPrices.some(
          ({ IdTrackPrice }) => IdTrackPrice === trackPrice.IdTrackPrice
        )
    );

    currentTrackPrices.forEach((trackPrice) => {
      if (deletedIds.includes(trackPrice.IdTrackPrice)) {
        return;
      }

      if (expiredIds.includes(trackPrice.IdTrackPrice)) {
        return;
      }

      const activeTrackPrice = active.find(
        ({ IdTrackPrice }) => IdTrackPrice === trackPrice.IdTrackPrice
      );

      if (activeTrackPrice) {
        return trackPrices.push(activeTrackPrice);
      }

      const firedTrackPrice = fired.find(
        ({ IdTrackPrice }) => IdTrackPrice === trackPrice.IdTrackPrice
      );

      if (firedTrackPrice) {
        if (firedTrackPrice.IdState !== trackPrice.IdState) {
          trackPricesForTrigger.push(firedTrackPrice);
        }

        return trackPrices.push(firedTrackPrice);
      }

      trackPrices.push(trackPrice);
    });

    set((state) => {
      state.trackPrices = trackPrices;
      state.trackPricesForTrigger = trackPricesForTrigger;
      state.isTrackPriceInitialized = true;
    });

    // Очищаем настройки для удалённых алертов
    set((state) => {
      deletedIds.forEach((deletedId) => {
        unset(
          state.workspaceConfigurations[state.workspaceConfigurationActiveId]
            .lastAutoSavedConfig.trackPriceSettings?.settingsByTrackPriceId,
          deletedId.toString()
        );
      });
    });
  },

  dismissTrackPrice: (trackPrice) => {
    if (trackPrice.IdState !== TrackPriceState.Fired) {
      return;
    }

    const trackPrices = get().trackPrices.filter(
      ({ IdTrackPrice }) => IdTrackPrice !== trackPrice.IdTrackPrice
    );

    set((state) => {
      state.trackPrices = trackPrices;
    });
  },

  dismissTrackPriceAll: () => {
    const trackPrice = get().trackPrices.filter(
      ({ IdState }) => IdState !== TrackPriceState.Fired
    );

    set((state) => {
      state.trackPrices = trackPrice;
    });
  },

  snoozeTrackPrice: (trackPrice) => {
    const trackPricesForTrigger = get().trackPricesForTrigger.filter(
      ({ IdTrackPrice }) => IdTrackPrice !== trackPrice.IdTrackPrice
    );

    set((state) => {
      state.trackPricesForTrigger = trackPricesForTrigger;
    });
  },

  openEditTrackPriceModal: (trackPrice) => {
    set((state) => {
      state.trackPriceForEdit = trackPrice;
      state.isTrackPriceEditModalOpen = true;
    });
  },

  closeEditTrackPriceModal: () => {
    set((state) => {
      state.isTrackPriceEditModalOpen = false;
    });
  },

  openTrackPriceSettingsModal: () => {
    set((state) => {
      state.isTrackPriceSettingsModalOpen = true;
    });
  },

  closeTrackPriceSettingsModal: () => {
    set((state) => {
      state.isTrackPriceSettingsModalOpen = false;
    });
  },

  openTrackPriceListModal: () => {
    set((state) => {
      state.isTrackPriceListModalOpen = true;
    });
  },

  closeTrackPriceListModal: () => {
    set((state) => {
      state.isTrackPriceListModalOpen = false;
    });
  },
});
