import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import { MoneyHistoryFilter } from '@terminal/core/lib/rest/lkMoney';

import { FilterIconButton } from '../../../shared/ui/FilterIconButton';
import { FilterButton } from '../FilterButton';
import { setFilterProp } from './filterManipulation';

import styles from './HistoryFilterLine.module.css';

interface HistoryFilterLineProps {
  filter: MoneyHistoryFilter;
  active: boolean;
  onFilterOpen?: () => void;
  onSetFilter?: (filter: MoneyHistoryFilter) => void;
}

export const HistoryFilterLine = ({
  filter,
  active,
  onFilterOpen,
  onSetFilter,
}: HistoryFilterLineProps) => {
  const setFilter = (key: keyof MoneyHistoryFilter, value: string) => {
    if (onSetFilter) {
      const newFilter = setFilterProp(filter, key, value);
      onSetFilter(newFilter);
    }
  };
  const periodFormat = (): string => {
    if (
      !!filter.startDate &&
      !!filter.endDate &&
      filter.startDate === filter.endDate
    ) {
      return filter.startDate;
    } else if (filter.startDate || filter.endDate) {
      return `${filter.startDate || '...'} – ${filter.endDate || '...'}`;
    } else {
      return 'Выберите период';
    }
  };

  return (
    <div className={styles.container}>
      <FilterIconButton onClick={onFilterOpen} checked={active} />
      <FilterButton
        checked={
          filter.tranTypes && filter.tranTypes?.indexOf('EXTRA_OUT') >= 0
        }
        onClick={() => setFilter('tranTypes', 'EXTRA_OUT')}
      >
        Списания
      </FilterButton>
      <FilterButton
        checked={filter.tranTypes && filter.tranTypes?.indexOf('EXTRA_IN') >= 0}
        onClick={() => setFilter('tranTypes', 'EXTRA_IN')}
      >
        Зачисления
      </FilterButton>
      <FilterButton
        checked={filter.tranTypes && filter.tranTypes?.indexOf('INTRA') >= 0}
        onClick={() => setFilter('tranTypes', 'INTRA')}
      >
        Перевод
      </FilterButton>

      <FilterButton
        checked={!!filter.startDate || !!filter.endDate}
        rightAddons={<ChevronDownMIcon width={18} height={18} />}
        onClick={onFilterOpen}
      >
        {periodFormat()}
      </FilterButton>
      <FilterButton
        checked={filter.currencies && filter.currencies?.length > 0}
        rightAddons={<ChevronDownMIcon width={18} height={18} />}
        onClick={onFilterOpen}
      >
        Валюта
        {filter.currencies && filter.currencies?.length > 0
          ? `: ${filter.currencies?.length}`
          : ''}
      </FilterButton>
    </div>
  );
};
