import React, { FC, MouseEventHandler } from 'react';
import {
  IconButton,
  IconButtonProps,
} from '@alfalab/core-components/icon-button';
import { CrossCircleSIcon } from '@alfalab/icons-glyph/CrossCircleSIcon';

import { useTrackPriceDelete } from '@terminal/core/lib/services/trackPrice/hooks/useTrackPriceDelete';
import { TrackPrice, TrackPriceState } from '@terminal/core/types/trackPrice';

export type RemoveButtonProps = Omit<
  IconButtonProps,
  'icon' | 'size' | 'title' | 'onClick' | 'loading'
> & {
  trackPrice: TrackPrice;
};

export const RemoveButton: FC<RemoveButtonProps> = (props) => {
  const { trackPrice, ...restProps } = props;
  const { isLoading, mutateAsync } = useTrackPriceDelete();
  const isFired = trackPrice.IdState === TrackPriceState.Fired;

  const handleClick: MouseEventHandler = async () => {
    await mutateAsync(trackPrice);
  };

  return (
    <IconButton
      icon={CrossCircleSIcon}
      size="xs"
      view="secondary"
      title={isFired ? 'Снять' : 'Удалить'}
      onClick={handleClick}
      loading={isLoading}
      {...restProps}
    />
  );
};
