import isNumber from 'lodash/isNumber';
import noop from 'lodash/noop';
import { useCallback, useEffect } from 'react';

import {
  useAlfaDirectContext,
  usePriceToMarketCurrency,
} from '@terminal/alfadirect/hooks';
import { useStreamPrice } from '@terminal/alfadirect/hooks/useStreamPrice';
import { MINORITY } from '@terminal/core/constants/ui';
import { useOrderBookService } from '@terminal/core/hooks/useOrderBookService';
import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { OrderType } from '@terminal/core/lib/client/entities';
import { AccountItem } from '@terminal/core/types/account';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';

import { useAdditionalInstruction } from '../../../features/AdditionalInstructions';
import { usePriceQuickControls } from './usePriceQuickControls';

import { useIsLotCount } from '../../../shared/hooks/useIsLotCount';

import { FormType } from '../types';

interface Props {
  formType: FormType;
  isHideQuickLimits?: boolean;
  idFi: number;
  price: number;
  setPrice: (value: number) => void;
  priceTouched: boolean;
  setPriceTouched: (touched: boolean) => void;
  setSecondPrice?: (value: number) => void;
  secondPriceTouched?: boolean;
  setSecondPriceTouched?: (touched: boolean) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  finInfoExt?: FinInfoExt;
  selectedAccount?: AccountItem;
  fullFi?: ExtendedFI;
  side: 'buy' | 'sell';
  orderType: OrderType;
  last?: number;
  idAccount?: number;
  tradeLimits: TradeLimitsResult | null;
}

export const useTradingForm = ({
  formType,
  isHideQuickLimits = false,
  price,
  setPrice,
  priceTouched,
  setPriceTouched,
  setSecondPrice = noop,
  secondPriceTouched = false,
  setSecondPriceTouched = noop,
  quantity,
  setQuantity,
  idFi,
  finInfoExt,
  selectedAccount,
  fullFi,
  side,
  orderType,
  last,
  tradeLimits,
}: Props) => {
  const { useBrokerageFee } = useAlfaDirectContext();

  const { bestBid, bestAsk } = useOrderBookService(idFi, 2);

  const isLotCount = useIsLotCount();

  const isQuantityError =
    !isLotCount && finInfoExt && quantity % finInfoExt.lot !== 0;

  const { instructionsAvailability } = useAdditionalInstruction({
    idFi,
    side,
    orderType,
    isChart: formType === FormType.CHART,
    isLotCount,
    lot: finInfoExt?.lot,
  });

  const { isPriceStreamed, setPriceStreamed } = useStreamPrice({
    idFi,
    idMarketBoard: fullFi?.idMarketBoard,
    side,
    orderType,
    price,
    setPrice,
    last,
    bestAsk: bestAsk?.Price,
    bestBid: bestBid?.Price,
  });

  const { secondPriceAdditional, secPAdSuffix, clearPricesAdditional } =
    usePriceQuickControls({
      side,
      orderType,
      priceStep: finInfoExt?.priceStep,
      setPrice,
      isPriceStreamed,
      setSecondPrice,
      last,
      bestAsk: bestAsk?.Price,
      bestBid: bestBid?.Price,
      tradeLimits: tradeLimits,
      setPriceStreamed,
    });

  const handleSetPrice = useCallback(
    (value?: number, clear?: boolean) => {
      if (isNumber(value)) {
        setPrice(value);
        setPriceStreamed(false);
      }

      setPriceTouched(true);

      if (clear) {
        clearPricesAdditional();
      }
    },
    [setPrice, clearPricesAdditional, setPriceStreamed, setPriceTouched]
  );

  const handleSetSecondPrice = useCallback(
    (value?: number, clear?: boolean) => {
      if (isNumber(value)) {
        setSecondPrice(value);
      }

      setSecondPriceTouched(true);

      if (clear) {
        clearPricesAdditional();
      }
    },
    [setSecondPrice, setSecondPriceTouched, clearPricesAdditional]
  );

  useEffect(() => {
    setPriceTouched(false);
    setSecondPriceTouched(false);
  }, [idFi, setPriceTouched, setSecondPriceTouched]);

  useEffect(() => {
    if (quantity) {
      return;
    }

    if (isLotCount) {
      setQuantity(1);
    } else if (finInfoExt?.lot) {
      setQuantity(finInfoExt.lot);
    }
  }, [quantity, finInfoExt?.lot, isLotCount, setQuantity]);

  const prevLotCount = usePrevious(isLotCount);

  useEffect(() => {
    if (
      prevLotCount === undefined ||
      prevLotCount === isLotCount ||
      !quantity
    ) {
      return;
    }

    const lot = finInfoExt?.lot || 1;

    if (isLotCount) {
      setQuantity((quantity || lot) / lot);
    } else {
      setQuantity((quantity || 1) * lot);
    }
  }, [quantity, finInfoExt?.lot, idFi, setQuantity, prevLotCount, isLotCount]);

  useEffect(() => {
    switch (orderType) {
      // Если рыночная заявка, то отлипаем цену
      case OrderType.MKT: {
        setPriceTouched(false);
        break;
      }

      // ситуация: открыли форму с дефолтной лимитной заявкой, просто ставим в цену last и залипаем цену
      case OrderType.LMT:
        if (last && !priceTouched) {
          setPrice(last);
          setPriceTouched(true);
        }

        break;
      // STP, TRS - надо заполнять как для маркета (bid\offer - в противоположную сторону)
      case OrderType.STP:
      case OrderType.TRS:
        if (!priceTouched && bestAsk && bestBid) {
          if (side === 'buy') {
            handleSetPrice(bestAsk?.Price);
          } else {
            handleSetPrice(bestBid?.Price);
          }
        }

        break;
      // Для BRS - в лимит цену тянуть bid\offer в сторону направления, а в стоп - в противоположную
      case OrderType.BRS:
      case OrderType.TBRS:
        if (!secondPriceTouched && bestAsk && bestBid) {
          if (side === 'buy') {
            handleSetSecondPrice(bestAsk?.Price);
          } else {
            handleSetSecondPrice(bestBid?.Price);
          }
        }

        if (!priceTouched && bestAsk && bestBid) {
          if (side === 'sell') {
            handleSetPrice(bestAsk?.Price);
          } else {
            handleSetPrice(bestBid?.Price);
          }
        }

        break;

      default:
        break;
    }
  }, [
    idFi,
    last,
    priceTouched,
    orderType,
    handleSetPrice,
    side,
    bestAsk,
    bestBid,
    secondPriceTouched,
    handleSetSecondPrice,
    price,
    setPrice,
    setPriceTouched,
  ]);

  const getQuantity = useCallback(
    () => (isLotCount ? (finInfoExt?.lot || 0) * quantity : quantity),
    [finInfoExt?.lot, isLotCount, quantity]
  );

  const { displayedTotalPrice, reducedPrice } = usePriceToMarketCurrency(
    fullFi,
    price,
    MINORITY,
    fullFi?.nominal,
    getQuantity(),
    finInfoExt?.priceStep,
    finInfoExt?.priceStepCost
  );

  const fee = useBrokerageFee(
    idFi,
    reducedPrice,
    getQuantity(),
    selectedAccount
  );

  return {
    setSecondPrice: handleSetSecondPrice,
    secondPriceAdditional,
    secPAdSuffix,
    instructionsAvailability,
    total: displayedTotalPrice,
    fee,
    hasError: isQuantityError,
    tradeLimits,
    updatePrice: handleSetPrice,
    isPriceStreamed,
    setPriceStreamed,
    isHideQuickLimits,
  };
};
