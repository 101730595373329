import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTimeout } from 'react-use';

import { Portal } from '@terminal/common/components/Portal';
import { useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';

import { ONBOARDING_STEPS, OnboardingPlayer } from './OnboardingPlayer';
import { OnboardingWelcome } from './OnboardingWelcome';

import styles from './Onboarding.module.css';

interface OnboardingModalProps {
  setShowOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
  showOnboarding: boolean;
}

const LAST_STEP = ONBOARDING_STEPS.length;

export const OnboardingModal = ({
  setShowOnboarding,
  showOnboarding,
}: OnboardingModalProps) => {
  const setHeaderMenuOpen = useStore((store) => store.setHeaderMenuOpen);

  const [onboardingStep, setOnboardingStep] = useState(0);
  const [isVisibleFn] = useTimeout(1000);
  const isModalVisible = isVisibleFn();

  const closeOnboardingModal = useCallback(() => {
    setShowOnboarding(false);
  }, [setShowOnboarding]);
  const nextStep = useCallback(() => {
    if (onboardingStep !== LAST_STEP) {
      setOnboardingStep((prevStep) => prevStep + 1);
    } else {
      closeOnboardingModal();
    }
  }, [closeOnboardingModal, onboardingStep]);
  const prevStep = useCallback(() => {
    setOnboardingStep((prevStep) => prevStep - 1);
  }, []);

  const openMoneyTransferModal = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.MONEY_TRANSFER);
  }, [setHeaderMenuOpen]);
  const openSecuritiesTransferModal = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.SECURITIES);
  }, [setHeaderMenuOpen]);
  const openSettingsModal = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.COMMON_SETTINGS);
  }, [setHeaderMenuOpen]);

  const renderContent = () => {
    if (onboardingStep === 0) {
      return (
        <OnboardingWelcome
          nextStep={nextStep}
          lastStep={closeOnboardingModal}
        />
      );
    }

    return (
      <OnboardingPlayer
        nextStep={nextStep}
        prevStep={prevStep}
        step={onboardingStep}
        closeOnboardingModal={closeOnboardingModal}
        openMoneyTransferModal={openMoneyTransferModal}
        openSecuritiesTransferModal={openSecuritiesTransferModal}
        openSettingsModal={openSettingsModal}
      />
    );
  };

  return (
    <>
      {showOnboarding && (
        <Portal id="onboarding-tips">
          <div
            className={cn(styles.wrapper, {
              [styles.wrapperVisible]: isModalVisible,
            })}
          >
            <div
              className={cn(styles.backdrop, {
                [styles.backdropHidden]: onboardingStep !== 0,
              })}
            />
            <div className={styles.mainContainer}>{renderContent()}</div>
          </div>
        </Portal>
      )}
    </>
  );
};
