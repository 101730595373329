import { useCallback, useEffect, useRef, useState } from 'react';

import { useErrorNotification } from './useNotifications';

export const useDownload = () => {
  const refMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const notify = useErrorNotification();
  useEffect(
    () => () => {
      refMounted.current = false;
    },
    [refMounted]
  );
  const download = useCallback(
    async (url: string) => {
      setIsLoading(true);
      try {
        const result = await fetch(url);
        if (!refMounted.current) return;
        if (result.ok) {
          const data = await result.blob();
          if (!refMounted.current) return;
          const dataUrl = URL.createObjectURL(data);
          const link = document.createElement('a');
          try {
            link.href = dataUrl;
            link.download = url.substring(url.lastIndexOf('/') + 1);
            link.click();
          } finally {
            URL.revokeObjectURL(dataUrl);
          }
        }
      } catch (e) {
        if (!refMounted.current) return;
        notify('Ошибка загрузки файла', e);
      } finally {
        if (!refMounted.current) return;
        setIsLoading(false);
      }
    },
    [setIsLoading, refMounted, notify]
  );
  return {
    isLoading,
    download,
  };
};
