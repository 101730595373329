import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';

import { SubAccountRedactParams } from './types/SubAccountRedactParams';

export async function updateSubAccount(data: SubAccountRedactParams) {
  return await signOperation(
    `/api/client/accounts/subaccount/update`,
    'POST',
    data.params,
    data.signOptions
  );
}
