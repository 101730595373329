import { useMemo } from 'react';
import { useFirstMountState } from 'react-use';

import { TableColumnSetting } from '../types/layout';
import { TableColumnKey } from '../types/tableColumn';

// Взаимозаменяемые колонки - Тикер и Название
const NAME_OR_SYMBOL_KEY = [
  TableColumnKey.NameObject,
  TableColumnKey.SymbolObject,
];

export const useTableColumns = (
  columnSetting: TableColumnSetting[] | undefined,
  defaultColumnSetting: TableColumnSetting[]
): TableColumnSetting[] => {
  const isFirstRender = useFirstMountState();

  return useMemo<TableColumnSetting[]>(() => {
    if (!columnSetting || columnSetting.length === 0) {
      return defaultColumnSetting;
    }

    // Оставляем только те колонки, которые есть в дефолтном конфиге
    const actualColumns = columnSetting.filter((tableColumn) =>
      defaultColumnSetting.some(
        (defaultColumn) =>
          (NAME_OR_SYMBOL_KEY.includes(defaultColumn.key) &&
            NAME_OR_SYMBOL_KEY.includes(tableColumn.key)) ||
          defaultColumn.key === tableColumn.key
      )
    );

    const actualColumnsKeys = actualColumns?.reduce<string[]>((acc, column) => {
      acc.push(column.key);

      if (column.secondRow) {
        acc.push(column.secondRow.key);
      }

      return acc;
    }, []);

    // Мержим новые колонки с сохранением порядка
    // делаем один раз за сессию
    const mergedColumns = !isFirstRender
      ? actualColumns
      : defaultColumnSetting.reduce((acc, defaultColumn, index) => {
          const hasActualColumn = actualColumnsKeys.some(
            (actualColumnKey) => actualColumnKey === defaultColumn.key
          );

          if (!hasActualColumn) {
            acc.splice(index, 0, defaultColumn);
          }

          return acc;
        }, actualColumns);

    // Убираем дубли которые возникли и уже существуют у старых пользователей
    const uniqColumns = mergedColumns.reduce((acc, column) => {
      if (!acc[column.key]) {
        acc[column.key] = column;
      }

      return acc;
    }, {});

    return Object.values(uniqColumns);
  }, [isFirstRender, columnSetting, defaultColumnSetting]);
};
