import { useMemo } from 'react';

import { makePositionRows } from '../../lib/domain/makePositionRows';
import { useSingleFORTSMarket } from '../useSingleFORTSMarket';

import {
  PositionItem,
  PositionOptions,
  PositionRowsRaw,
} from '../../types/position';

/**
 *
 * @deprecated use usePositionNew
 */
export function usePosition(
  {
    positions,
    quotes,
    objectsMap,
    objectExtsMap,
    finInfoExts,
    marketBoardMap,
    subAccountRazdels,
    objectTypesMap,
    ordersMap,
    currenciesQuotes,
    currenciesFinInfoExts,
    positionsToIdFiBalances,
  }: PositionRowsRaw,
  { selectedSubAccounts, isUnifyRubPositions }: PositionOptions
): PositionItem[] {
  const isSingleFORTSMarketAvailable = useSingleFORTSMarket();

  return useMemo(
    () =>
      makePositionRows(
        {
          positions,
          quotes,
          objectsMap,
          objectExtsMap,
          finInfoExts,
          marketBoardMap,
          subAccountRazdels,
          objectTypesMap,
          ordersMap,
          currenciesQuotes,
          currenciesFinInfoExts,
          positionsToIdFiBalances,
        },
        {
          selectedSubAccounts,
          isSingleFORTSMarketAvailable,
          isUnifyRubPositions,
        }
      ),
    [
      positions,
      quotes,
      objectsMap,
      objectExtsMap,
      finInfoExts,
      marketBoardMap,
      subAccountRazdels,
      objectTypesMap,
      ordersMap,
      currenciesQuotes,
      currenciesFinInfoExts,
      positionsToIdFiBalances,
      selectedSubAccounts,
      isSingleFORTSMarketAvailable,
      isUnifyRubPositions,
    ]
  );
}
