import * as z from 'zod';

export class SettingsZod_V1 {
  static object = z.object({
    saveTimestamp: z.number(),
    design: z.object({
      directionColors: z.enum(['greenAndRed', 'blueAndRed', 'blueAndWhite']),
    }),
    defaultValues: z.object({
      alwaysConfirmOrders: z.boolean(),
      orderDimension: z.number(),
      selectedDisplayInstrumentType: z.enum(['symbol', 'name']),
      quantityMap: z.record(z.string(), z.number()),
    }),
  });
}
