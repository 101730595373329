import { EventEmitter } from 'eventemitter3';
import { encode } from 'single-byte';

import { alfaDirectClient } from '../client/client';
import {
  CertificateActionType,
  FrontEndType,
  Messages,
  MessageUnitedType,
  RequestForCertificateActionSMSEntity,
  RequestForCertificateActionWithSMSEntity,
  ResponseForCertificateActionSMSEntity,
  ResponseForCertificateActionWithSMSEntity,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import { getId } from './id';
import { OrderNum } from './orderNum';

import { useStore } from '../../store';

/*
  Этот сервис предназначен для подтверждения выпуска/отзыва сертификата смской.

  askConfirmationCode() - запрашивает у сервера код подтверждения
  sendCode() - шлем на сервер то что ввел пользователь

  Инстанс сервиса файрит два ивента:

  certificate-code-success фейрится если мы кодом из смски заапрувили сертик
  certificate-code-error ферится если вернулась ошибка при запросе на код
*/

export class SmsActionsService extends EventEmitter {
  idCertificate: number;
  // в рамках флоу по сертификату этот ордернум сохраняется
  orderNum: number;
  // какие-то данные которые вместе с orderNum тоже надо сохранить
  reference: string;
  orderTextTemplate: ArrayBuffer;
  signTime: Date;

  // это id'шники запросов на отправку кода и запроса с самим кодом
  askCodeRequestId: number;
  sendCodeRequestId: number;

  // штука в том, что сначала ты шлешь RequestForCertificateActionSMSEntity чтоб запросить код
  // тебе если все норм, то придет ResponseForCertificateActionSMSEntity
  // и тебе надо использовать данные которые он прислал вместе с тем кодом что чувак вбил
  // эта переменная не даст отправить код, если не был получен корректный ResponseForCertificateActionSMSEntity
  lock: boolean;

  constructor(idCertificate: number) {
    super();
    this.idCertificate = idCertificate;

    alfaDirectClient.addListener(
      EntityType.ResponseForCertificateActionSMSEntity,
      this.onResponseForCertificateActionSMSEntity
    );

    alfaDirectClient.addListener(
      EntityType.ResponseForCertificateActionWithSMSEntity,
      this.onResponseForCertificateActionWithSMSEntity
    );
  }

  public askCode(action: CertificateActionType) {
    this.lock = true;
    const selectedAccount = useStore.getState().selectedAccount;

    const idAccount = selectedAccount?.idAccount;

    if (!idAccount) {
      throw new Error('No active account selected');
    }

    const requestForConfirm = new RequestForCertificateActionSMSEntity();

    requestForConfirm.IdCertificate = BigInt(this.idCertificate);
    requestForConfirm.Action = action;
    requestForConfirm.IdRequest = getId();
    requestForConfirm.IdAccount = idAccount;
    this.orderNum = OrderNum.nextOrderNum();
    requestForConfirm.ClientOrderNum = this.orderNum;

    this.askCodeRequestId = requestForConfirm.IdRequest;

    alfaDirectClient.send({
      frontend: FrontEndType.OperServer,
      isArray: false,
      payload: {
        type: EntityType.RequestForCertificateActionSMSEntity,
        data: requestForConfirm,
      },
    });
  }

  public sendCode(code: string) {
    if (code.length !== 5) {
      this.emit(
        'certificate-code-error',
        `Код подтверждения выпуска сертификата должен состоять из пяти цифр.`
      );

      return;
    }

    if (this.lock) {
      this.emit(
        'certificate-code-error',
        `Непредвиденная ошибка. Запросите заново код подтверждения.`
      );

      return;
    }

    const selectedAccount = useStore.getState().selectedAccount;

    const idAccount = selectedAccount?.idAccount;

    if (!idAccount) {
      throw new Error('No active account selected');
    }

    const sendCodeRequest = new RequestForCertificateActionWithSMSEntity();

    sendCodeRequest.IdAccount = idAccount;
    sendCodeRequest.ClientOrderNum = this.orderNum;

    // нам присылают файлик с utf-8 текстом
    // нам надо отправить этот файлик обратно но в WINDOWS-1251
    // дефолтный js энкодер не умеет ничего кроме уникода
    // пришлось искать в интернете. Причем я еле нашел подходящий
    // уже думал придется писать свой
    // Пронесло...
    const textDecoder = new TextDecoder('utf-8', { fatal: true });
    const jsUnicodeString = textDecoder.decode(this.orderTextTemplate);
    const win1251Order = encode('windows-1251', jsUnicodeString);

    // засовываем файл в 1251 кодировке
    sendCodeRequest.BinaryEDocument = win1251Order.buffer;
    sendCodeRequest.IdCertificate = BigInt(this.idCertificate);
    sendCodeRequest.SignTime = this.signTime;
    sendCodeRequest.Reference = this.reference;
    sendCodeRequest.SMSCode = code;
    sendCodeRequest.Action = CertificateActionType.Certify;

    alfaDirectClient.send({
      frontend: FrontEndType.OperServer,
      isArray: false,
      payload: {
        type: EntityType.RequestForCertificateActionWithSMSEntity,
        data: sendCodeRequest,
      },
    });
  }

  clear() {
    alfaDirectClient.removeListener(
      EntityType.ResponseForCertificateActionSMSEntity,
      this.onResponseForCertificateActionSMSEntity
    );

    alfaDirectClient.removeListener(
      EntityType.ResponseForCertificateActionWithSMSEntity,
      this.onResponseForCertificateActionWithSMSEntity
    );

    this.removeAllListeners();
  }

  private onResponseForCertificateActionSMSEntity = (
    message: MessageUnitedType
  ) => {
    const msgs = message.data as ResponseForCertificateActionSMSEntity[];

    msgs.forEach((msg) => {
      if (msg.IdRequest === this.askCodeRequestId) {
        if (msg.ErrorCode === Messages.NoErrors) {
          this.reference = msg.Referece;
          this.orderTextTemplate = msg.OrderTextTemplate;
          this.signTime = msg.SignTime;
          this.lock = false;
        } else {
          this.emit(
            'certificate-code-error',
            String(
              msg.ErrorText ||
                'Произошла ошибка при запрос кода подтверждения. ' +
                  msg.ErrorCode
            )
          );
        }
      }
    });
  };

  private onResponseForCertificateActionWithSMSEntity = (
    message: MessageUnitedType
  ) => {
    const msgs = message.data as ResponseForCertificateActionWithSMSEntity[];

    msgs.forEach((msg) => {
      if (msg.ClientOrderNum === this.orderNum) {
        if (msg.ErrorCode !== Messages.NoErrors) {
          this.emit(
            'certificate-code-error',
            String(
              msg.ErrorText ||
                'Произошла ошибка при запрос кода подтверждения. ' +
                  msg.ErrorCode
            )
          );
        } else {
          this.emit('certificate-code-success');
        }
      }
    });
  };
}
