import { useMemo, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Link } from '@alfalab/core-components/link';
import { Select, SelectProps } from '@alfalab/core-components/select';
import { OptionShape } from '@alfalab/core-components/select/typings';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ClockLineMIcon } from '@alfalab/icons-glyph/ClockLineMIcon';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';

import { MobileSelect } from '@terminal/common/components/MobileSelect';
import {
  AccountInfo,
  AccountInfoGI,
  AccountTarriff,
} from '@terminal/core/lib/rest/lkAccountInfo';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { InfoModal } from '../../../../shared/ui/InfoModal';
import { TestRequired } from '../../../../shared/ui/TestRequired';
import { EXTERNAL_LINKS, LK_PAGES } from '../../../const/externalUrls';

import {
  useChangeOperationMode,
  useChangeTariff,
  useChangeTariffGI,
} from '../../hooks';

import styles from './AccountControl.module.css';

interface AccountControlProps {
  treaty: Treaty;
  accountInfo: AccountInfo | AccountInfoGI;
  testPassed: boolean;
  onAccountSave: () => void;
  isMobile?: boolean;
}

export const AccountControl = ({
  treaty,
  accountInfo,
  testPassed,
  onAccountSave,
  isMobile,
}: AccountControlProps) => {
  const isGoInvest = !isMobile;

  const [tariffCode, setTariffCode] = useState<string>(
    isGoInvest ? accountInfo?.tpGroup || '' : accountInfo?.tpCode || ''
  );
  const [operationModeId, setOperationModeId] = useState<string>(
    (accountInfo?.idAccountGroup || 0).toString()
  );
  const [testBlock, setTestBlock] = useState(false);

  const { isLoading: changeTariffPending, mutateAsync: requestChangeTariff } =
    useChangeTariff();

  const {
    isLoading: changeTariffPendingGI,
    mutateAsync: requestChangeTariffGI,
  } = useChangeTariffGI();

  const {
    isLoading: changeOperationModePending,
    mutateAsync: requestChangeOperationMode,
  } = useChangeOperationMode();

  const onChangeTariff: SelectProps['onChange'] = ({ selected }) => {
    setTariffCode(selected?.key || '');
  };

  const onChangeOperationMode: SelectProps['onChange'] = ({ selected }) => {
    const selectedOperationMode = selected?.key || '0';

    setOperationModeId(selected?.key || '0');

    const controlOperaton =
      selectedOperationMode === '1' || selectedOperationMode === '2';

    if (!testPassed && controlOperaton) {
      setTestBlock(true);
    }
  };

  const onTariffSave = async () => {
    const data = {
      params: {
        treaty: accountInfo.treaty,
        tariff: tariffCode,
      },
      signOptions: {
        successCallback: onAccountSave,
      },
    };

    isGoInvest ? requestChangeTariffGI(data) : requestChangeTariff(data);
  };

  const onOperationModeSave = async () => {
    requestChangeOperationMode({
      params: {
        treaty: accountInfo.treaty,
        operationMode: operationModeId,
      },
      signOptions: {
        successCallback: onAccountSave,
      },
    });
  };

  const tariffOptions = useMemo<OptionShape[]>(() => {
    return accountInfo.tarifs.map(
      (t): OptionShape => ({
        key: isGoInvest ? t.tpGroup : (t as AccountTarriff).tpCode,
        content: t.name,
      })
    );
  }, [accountInfo, isGoInvest]);

  const modeOptions = useMemo<OptionShape[]>(() => {
    return accountInfo.marginTypes.map(
      (m): OptionShape => ({
        key: m.idAccountGroup.toString(),
        content: m.nameAccountGroup,
      })
    );
  }, [accountInfo]);

  const isTariffChanged = isGoInvest
    ? accountInfo.tpGroup !== tariffCode
    : accountInfo.tpCode !== tariffCode;
  const isOperationModeChanged =
    accountInfo.idAccountGroup.toString() !== operationModeId;

  return (
    <div className={styles.container}>
      <Typography.Text view="primary-small" weight="bold" tag="p">
        Управление счетом
      </Typography.Text>
      <div className={styles.field}>
        {!isMobile && (
          <Select
            label="Тарифный план"
            labelView="outer"
            placeholder="Выберите тариф"
            size="s"
            block
            disabled={
              isOperationModeChanged ||
              treaty.isMoneybox ||
              treaty.isRepo ||
              !accountInfo.permissions.canChangeTp ||
              Boolean(accountInfo.nextTarifName)
            }
            options={tariffOptions}
            selected={tariffCode}
            onChange={onChangeTariff}
          />
        )}
        {isMobile && (
          <MobileSelect
            options={tariffOptions}
            selectedKey={tariffCode}
            onChange={onChangeTariff}
            title="Тарифный план"
            disabled={
              isOperationModeChanged ||
              treaty.isMoneybox ||
              treaty.isRepo ||
              !accountInfo.permissions.canChangeTp ||
              Boolean(accountInfo.nextTarifName)
            }
            hint={
              <Button
                view="ghost"
                href={EXTERNAL_LINKS.ABOUT_TARIFF}
                size="xxs"
                target="_blank"
                block
                className={styles.mobileButtonLink}
                rightAddons={<OutsideMIcon />}
              >
                Подробнее о тарифном плане
              </Button>
            }
          />
        )}
        {Boolean(accountInfo.nextTarifName) && (
          <div className={styles.tariffPending}>
            <ClockLineMIcon width={18} height={18} />
            <span className={styles.tariffPendingText}>
              Выбранный тарифный план «{accountInfo.nextTarifName}» ожидает
              вступления в силу
            </span>
          </div>
        )}
        {isTariffChanged && (
          <div className={styles.save}>
            <Button
              dataTestId="save-tariff-btn"
              size="s"
              block
              leftAddons={<CheckmarkMIcon width={18} height={18} />}
              loading={changeTariffPending || changeTariffPendingGI}
              onClick={onTariffSave}
            >
              Сохранить изменения
            </Button>
          </div>
        )}
      </div>

      <div className={styles.field}>
        {!isMobile && (
          <Select
            label="Режим операций"
            labelView="outer"
            placeholder="Выберите режим операций"
            size="s"
            block
            disabled={
              !accountInfo.permissions.canChangeAccountGroup ||
              isTariffChanged ||
              treaty.isMoneybox ||
              treaty.isRepo
            }
            options={modeOptions}
            selected={operationModeId}
            onChange={onChangeOperationMode}
            hint={
              <Typography.Text view="secondary-small">
                <Link
                  href={EXTERNAL_LINKS.REQUIRED_SECURITY}
                  target="_blank"
                  view="secondary"
                  underline
                >
                  Условия поддержания непокрытых позиций
                </Link>
              </Typography.Text>
            }
          />
        )}
        {isMobile && (
          <MobileSelect
            options={modeOptions}
            selectedKey={operationModeId}
            onChange={onChangeOperationMode}
            title="Режим операций"
            disabled={
              !accountInfo.permissions.canChangeAccountGroup ||
              isTariffChanged ||
              treaty.isMoneybox ||
              treaty.isRepo
            }
            hint={
              <Button
                view="ghost"
                href={EXTERNAL_LINKS.REQUIRED_SECURITY}
                size="xxs"
                target="_blank"
                block
                className={styles.mobileButtonLink}
                rightAddons={<OutsideMIcon />}
              >
                Условия поддержания непокрытых позиций
              </Button>
            }
          />
        )}
        {isOperationModeChanged && (
          <div className={styles.save}>
            <Button
              dataTestId="save-operation-mode-btn"
              size="s"
              block
              leftAddons={<CheckmarkMIcon width={18} height={18} />}
              loading={changeOperationModePending}
              onClick={onOperationModeSave}
            >
              Сохранить изменения
            </Button>
          </div>
        )}
      </div>

      <InfoModal
        open={testBlock}
        rounded={true}
        onClose={() => setTestBlock(false)}
      >
        <TestRequired
          title={
            isGoInvest ? (
              <>Нужен статус квалифицированного&nbsp;инвестора</>
            ) : (
              'Необходимо пройти тестирование'
            )
          }
          text="Это необходимо для заключения необеспеченных (маржинальных) сделок, в соответствии с законодательством РФ"
          testUrl={isGoInvest ? '' : `${LK_PAGES.TESTING}/2`}
        />
      </InfoModal>
    </div>
  );
};
