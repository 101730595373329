import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { useAppContext } from '@terminal/core/hooks/useAppContext';

import { useThemeContext } from '../../../shared/lib/themeContext';

import { StepScreenProps } from '../types';

import styles from './Steps.module.css';

export const ThirdStep = ({ style }: StepScreenProps) => {
  const { theme } = useThemeContext();
  const { onboarding } = useAppContext();
  const { layout } = onboarding.images[theme];

  return (
    <div style={style} className={styles.slideContainer}>
      <div className={styles.stepContainer}>
        <Space fullWidth>
          <Typography.Text view="primary-large" weight="bold">
            Настройка экрана
          </Typography.Text>
          <Typography.Text view="secondary-large">
            Вы можете создать на экране до 9 виджетов: до 3 в каждой колонке и
            строке. Чтобы переместить виджет, потяните за вкладку виджета и
            перетащите его с помощью мышки в необходимое место.
          </Typography.Text>
          <div
            className={styles.thirdStepImage}
            style={{ backgroundImage: `url(${layout})` }}
          />
        </Space>
      </div>
    </div>
  );
};
