import { FC, memo } from 'react';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { ConfigStyle } from '@terminal/desktop/entities/OnboardingConfig';

import { MAP_STYLE_TO_WIDGETS_LIST } from './helpers';

import styles from './ConfigurationTooltip.module.css';

type ConfigurationTooltipProps = {
  style: ConfigStyle;
  iconColor?: string;
};

export const ConfigurationTooltip: FC<ConfigurationTooltipProps> = memo(
  (props) => {
    const { style, iconColor = 'currentColor' } = props;
    const tooltipList = MAP_STYLE_TO_WIDGETS_LIST[style];

    return (
      <TooltipDesktop
        popoverClassName={styles.popover}
        content={
          <div>
            <Typography.Text
              className={styles.title}
              weight="bold"
              tag="p"
              view="secondary-small"
            >
              Виджеты
            </Typography.Text>
            <Typography.Text view="secondary-small" color="secondary">
              <ul className={styles.list}>
                {tooltipList.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Typography.Text>
          </div>
        }
        trigger="hover"
        position="top"
      >
        <InformationCircleLineSIcon
          className={styles.icon}
          height={12}
          width={12}
          fill={iconColor}
        />
      </TooltipDesktop>
    );
  }
);
