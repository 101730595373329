import { useCallback, useEffect, useState } from 'react';

import { createNotification } from '../lib/notifications';

import { shallow, useStore } from '../store';

import { Notification, NotificationType } from '../types/ui';

type NotificationItem = Notification & {
  closeTime: number;
};

export const useNotifications = () => {
  const [
    notifications,
    clearNotifications,
    removeNotification,
    isShowNotifications,
    isShowTradeNotifications,
    isShowBrokerNotifications,
    isShowSystemNotifications,
    isShowNewMessages,
    maxCount,
    timeToShow,
  ] = useStore(
    (state) => [
      state.notifications,
      state.clearNotifications,
      state.removeNotification,
      state.settings.notifications.isShow,
      state.settings.notifications.isShowTradeNotifications,
      state.settings.notifications.isShowBrokerNotifications,
      state.settings.notifications.isShowSystemNotifications,
      state.settings.notifications.isShowNewMessages,
      state.settings.notifications.maxCount,
      state.settings.notifications.time,
    ],
    shallow
  );

  const [notificationsShowList, setNotificationsShowList] = useState<
    NotificationItem[]
  >([]);

  useEffect(() => {
    if (!isShowNotifications) {
      clearNotifications();
      setNotificationsShowList([]);
    }
  }, [isShowNotifications, clearNotifications]);

  useEffect(() => {
    if (notificationsShowList.length >= maxCount) {
      return;
    }

    const newItem = notifications.slice(0, maxCount).at(-1);
    const isNewItem =
      newItem?.id &&
      !notificationsShowList.find((item) => item.id === newItem.id);

    if (isNewItem) {
      const item = { ...newItem, closeTime: Date.now() + timeToShow };

      setNotificationsShowList(notificationsShowList.concat(item));
    }
  }, [
    isShowBrokerNotifications,
    isShowNewMessages,
    isShowSystemNotifications,
    isShowTradeNotifications,
    maxCount,
    notifications,
    notificationsShowList,
    timeToShow,
  ]);

  const onRemoveNotification = useCallback(
    (id) => {
      removeNotification(id);
      setNotificationsShowList(
        notificationsShowList.filter((item) => item.id !== id)
      );
    },
    [notificationsShowList, removeNotification]
  );

  return {
    isShowNotifications,
    notificationsShowList,
    onRemoveNotification,
  };
};

export const useErrorNotification = () => {
  const addNotification = useStore((state) => state.addNotification);

  return useCallback(
    (title: string, error: unknown) => {
      const text =
        error instanceof Error ? error.message : 'Неизвестная ошибка';

      addNotification(
        createNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title,
          text,
        })
      );
    },
    [addNotification]
  );
};

export const useSuccessNotification = () => {
  const addNotification = useStore((state) => state.addNotification);

  return useCallback(
    (title?: string, text?: string) => {
      addNotification(
        createNotification({
          type: NotificationType.SYSTEM,
          badge: 'positive',
          title: title || '',
          text,
        })
      );
    },
    [addNotification]
  );
};
