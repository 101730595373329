import React, { HTMLAttributes, ReactNode } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Radio } from '@alfalab/core-components/radio';
import { RadioGroup } from '@alfalab/core-components/radio-group';

import { TitleBlock, TitleBlockProps } from '../../../../shared/ui/TitleBlock';

import styles from './Form.module.css';

type Option<T> = {
  value: T;
  label: string;
  icon?: ReactNode;
};

export type FormProps<T> = Pick<TitleBlockProps, 'title' | 'subtitle'> &
  Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
    options: Option<T>[];
    value?: T;
    onChange?: (value: T) => void;
  };

export function Form<T extends string>(props: FormProps<T>) {
  const { title, subtitle, options, value, onChange, ...restProps } = props;

  return (
    <div {...restProps}>
      <TitleBlock title={title} subtitle={subtitle} />
      <Gap size="m" />
      <RadioGroup
        direction="vertical"
        value={value}
        onChange={(_, { value }) => {
          onChange?.(value as T);
        }}
      >
        {options.map((option) => (
          <Radio
            key={option.value}
            label={
              <span className={styles.labelRoot}>
                {option.icon && (
                  <span className={styles.iconWrapper}>{option.icon}</span>
                )}
                <span className={styles.label}>{option.label}</span>
              </span>
            }
            value={option.value}
          />
        ))}
      </RadioGroup>
    </div>
  );
}
