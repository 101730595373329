import * as z from 'zod';

import { WatchListItem, WatchListType } from '../../../types/watchList';

export class WatchListsZod_V1 {
  static object = z.object({
    saveTimestamp: z.number(),
    lists: z.array(
      z.object({
        type: z.nativeEnum(WatchListType),
        name: z.string(),
        id: z.string(),
        instruments: z.array(z.custom<WatchListItem>()),
      })
    ),
  });
}
