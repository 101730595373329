import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

export const useAddAccountConstraints = <R>(
  fetchConstraints: () => Promise<R>
) => {
  const addNotification = useNotification();

  return useQuery(['lk', 'addAccount/constraints'], () => fetchConstraints(), {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При запросе заявления произошла ошибка',
      });
    },
  });
};
