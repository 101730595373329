import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { MINORITY } from '@terminal/core/constants/ui';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { InstrumentDataRow } from '../../ui';

import classes from './AboutFutureBlock.module.css';

interface Props {
  fullFI: ExtendedFI;
  finInfoExt?: FinInfoExt;
  priceDecimals?: number;
}

export const AboutFutureBlock: FC<Props> = ({
  fullFI,
  finInfoExt,
  priceDecimals,
}) => {
  const { objectsTable } = useAlfaDirectContext();

  const fullObject = objectsTable.get('idObject', fullFI?.idObject);
  const baseObject = objectsTable.get('idObject', fullObject?.idObjectBase);

  return (
    <div className={classes.aboutFutureContainer}>
      <Typography.TitleMobile
        view="xsmall"
        tag="div"
        weight="bold"
        color="secondary"
        className={classes.aboutFutureTitle}
      >
        О фьючерсе
      </Typography.TitleMobile>
      {baseObject?.nameObject && (
        <InstrumentDataRow
          title="Базовый актив"
          value={baseObject?.nameObject}
        />
      )}
      {fullObject?.idObjectType.name && (
        <InstrumentDataRow
          title="Тип контракта"
          value={fullObject?.idObjectType.name}
        />
      )}
      {fullObject?.matDateObject && (
        <InstrumentDataRow
          title="Дата исполнения"
          value={format(fullObject?.matDateObject, 'd MMMM yyyy', {
            locale: ru,
          })}
        />
      )}
      {fullFI?.type && (
        <InstrumentDataRow
          title="Гарантийное обеспечение"
          value={
            <Amount.Pure
              value={
                Math.max(finInfoExt?.gtBuy ?? 0, finInfoExt?.gtSell ?? 0) *
                MINORITY
              }
              minority={MINORITY}
              view="withZeroMinorPart"
              currency={fullFI?.currencyCode || 'RUB'}
              dataTestId="sizeInput"
            />
          }
        />
      )}
    </div>
  );
};
