import { NewOrderType, OrderItem } from '../../types/order';

type ReturnType = NewOrderType['quantity'];

/**
 * @returns количество (quantity) для редактирования заявки
 * */
export function getEditOrderQuantity(order: Partial<OrderItem>): ReturnType {
  const { quantity = 0, rest = 0 } = order;

  return rest || quantity;
}
