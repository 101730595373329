import { useMemo } from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { Divider } from '@alfalab/core-components/divider';
import { Space } from '@alfalab/core-components/space';

import {
  SecuritiesDocumentType,
  SecuritiesReasonDocument,
  SecuritiesReferenceInfo,
} from '@terminal/core/lib/rest/lkSecurities';

import { buildReasonDocumentStr, isDocumentsRequired } from '../../shared';
import { SummaryField } from './SummaryField';

import styles from './Summary.module.css';

interface SummaryReasonProps {
  documentTypes?: SecuritiesDocumentType[];
  reasonDocuments: SecuritiesReasonDocument[];
  ownershipChange: boolean;
  reasonOther: string;
  referenceInfo?: SecuritiesReferenceInfo;
  comissionMultiplier?: number;
  operationDirection: 'in' | 'out';
}

const AMOUNT_MINORITY = 100;

export const SummaryReason = ({
  documentTypes,
  reasonDocuments,
  ownershipChange,
  reasonOther,
  referenceInfo,
  comissionMultiplier = 1,
  operationDirection,
}: SummaryReasonProps) => {
  const isSecuritiesIn = operationDirection === 'in';

  const docsToShow = useMemo<SecuritiesReasonDocument[]>(() => {
    if (documentTypes && reasonDocuments.length) {
      return reasonDocuments.map((d) => {
        const type = documentTypes.find((t) => t.id === d.id);
        if (type && type.docType === 'BASE') {
          return { ...d, docName: type.name };
        }

        return d;
      });
    }

    return [];
  }, [reasonDocuments, documentTypes]);

  if (
    !isDocumentsRequired(ownershipChange) &&
    !reasonOther &&
    !(!isSecuritiesIn && referenceInfo && referenceInfo.comission.amount)
  ) {
    return null;
  }

  return (
    <Space fullWidth direction="vertical">
      {Boolean(reasonOther) ||
        (Boolean(referenceInfo) && !isSecuritiesIn && <Divider />)}
      <div className={styles.section}>
        {isDocumentsRequired(ownershipChange) && (
          <SummaryField
            label="Основание для операции"
            value={docsToShow.map((doc, index) => (
              <div key={`reason-document-${index}`}>
                <span>{buildReasonDocumentStr(doc)}</span>
              </div>
            ))}
          />
        )}

        {reasonOther && (
          <SummaryField label="Комментарий" value={reasonOther} />
        )}

        {referenceInfo && !isSecuritiesIn && (
          <SummaryField
            label="Комиссия за перевод"
            value={
              <Amount
                value={
                  referenceInfo.comission.amount *
                  comissionMultiplier *
                  AMOUNT_MINORITY
                }
                minority={AMOUNT_MINORITY}
                currency={
                  (referenceInfo.comission.currency as CurrencyCodes) || 'RUB'
                }
                view="withZeroMinorPart"
              />
            }
          />
        )}
      </div>
    </Space>
  );
};
