import cn from 'classnames';
import { useState } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Button } from '@alfalab/core-components/button/modern';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';

import { useAlfaDirectContext, useFullFI } from '@terminal/alfadirect/hooks';
import { ActivationTime } from '@terminal/core/constants/orderBook';
import { orderTypeAbbrMap } from '@terminal/core/constants/orders';
import {
  LifeTime,
  ObjectGroup,
  OrderDirection,
  OrderType,
} from '@terminal/core/lib/client/entities';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { getSuitableActivationType } from '@terminal/core/lib/domain/getSuitableActivationType';
import {
  getAssetUnits,
  getDimensionedUnit,
  getMinority,
  getStepDecimals,
  roundFloatNumber,
} from '@terminal/core/lib/format';
import { getOrderDisplayPrice } from '@terminal/core/lib/formulas';
import { ReplaceOrderFn } from '@terminal/core/lib/services/trading/useTradingService';
import { NewOrderType, OrderItem } from '@terminal/core/types/order';

import { useWidgetContext } from '../../../../shared';
import { TextOrder } from '../TextOrder';

// eslint-disable-next-line no-restricted-imports
import styles from '../CancelOrder/CancelOrder.module.css';

const getDate = (idLifeItem: LifeTime): string => {
  switch (idLifeItem) {
    case LifeTime.DAY:
      return 'DAY';
    case LifeTime.GTD:
      return 'GTC';
    case LifeTime.D30:
      return 'DAY 30';
    default:
      return '';
  }
};

export interface ConfirmEditOrderProps {
  idFi: number;
  selectedSubAccountId: number;
  prevOrder: OrderItem;
  newOrder: NewOrderType;
  onCancel: () => void;
  onReplace?: () => void;
  onSubmit?: () => void;
  replaceOrder: ReplaceOrderFn;
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
}

export const ConfirmEditOrder = ({
  prevOrder,
  newOrder,
  onCancel,
  onReplace,
  onSubmit,
  idFi,
  selectedSubAccountId,
  replaceOrder,
  isLoading,
  setIsLoading,
}: ConfirmEditOrderProps) => {
  const [isOpenTextOrder, setIsOpenTextOrder] = useState(false);

  const { useSettings } = useWidgetContext();
  const { useFinInfoExtsList, getServerTime, objectsTable } =
    useAlfaDirectContext();
  const fullFi = useFullFI(idFi);

  const {
    defaultValues: { orderDimension },
  } = useSettings();

  const finInfoExt = useFinInfoExtsList();
  const finInfoExtItem = idFi ? finInfoExt[idFi] : undefined;
  const isBond = fullFi?.idObjectGroup === ObjectGroup.Bonds;

  const { priceDecimals } = getStepDecimals(finInfoExtItem?.priceStep);

  const buySell =
    prevOrder.buySell === -1 ? (
      <ArrowDownCompactXsIcon
        color="#d91d0b"
        className={styles.orderBuySellIcon}
      />
    ) : (
      <ArrowUpCompactXsIcon
        color="#2fc26e"
        className={styles.orderBuySellIcon}
      />
    );

  let prevTicker = '';
  let newTicker = '';

  const prevObject = objectsTable.get('idObject', prevOrder.idObject);
  const newObject = objectsTable.get('idObject', newOrder.idObject);

  if (prevObject) {
    prevTicker = prevObject.symbolObject;
  }

  if (newObject) {
    newTicker = newObject.symbolObject;
  }

  let prevOrderPricePart = getOrderDisplayPrice(prevOrder)
    ? roundFloatNumber(getOrderDisplayPrice(prevOrder)!)
    : '';
  let newOrderPricePart = getOrderDisplayPrice(newOrder)
    ? roundFloatNumber(getOrderDisplayPrice(newOrder)!)
    : '';

  if (
    newOrder.idOrderType === OrderType.BRS ||
    newOrder.idOrderType === OrderType.TBRS
  ) {
    prevOrderPricePart = `${roundFloatNumber(
      prevOrder.stopPrice
    )} | ${roundFloatNumber(prevOrder.limitPrice)}`;
    newOrderPricePart = `${roundFloatNumber(
      newOrder.stopPrice || 0
    )} | ${roundFloatNumber(newOrder.limitPrice || 0)}`;
  }

  const prevCount = getDimensionedUnit(
    prevOrder.quantity || 0,
    finInfoExtItem?.lot,
    orderDimension
  );
  const prevSuffix = getAssetUnits(prevCount, orderDimension);
  const prevText = (
    <Typography.Text view="secondary-large">
      <Amount.Pure
        value={prevCount * getMinority(prevCount)}
        minority={getMinority(prevCount)}
        view="withZeroMinorPart"
      />
      &nbsp;
      {prevSuffix}
      &nbsp;
      {prevTicker}
      &nbsp;
      {prevOrderPricePart}
      &nbsp;
      <Typography.Text view="secondary-large" color="secondary">
        {orderTypeAbbrMap[prevOrder.idOrderType]}
      </Typography.Text>
    </Typography.Text>
  );

  const newSltpContent =
    newOrder.bracket?.stopPrice && newOrder.bracket?.limitPrice
      ? `${newOrder.bracket.idOrderType === OrderType.TRS ? 'T' : ''}SL ${
          newOrder.bracket.stopPrice
        } | TP ${newOrder.bracket.limitPrice}`
      : (newOrder.bracket?.stopPrice &&
          `${newOrder.bracket.idOrderType === OrderType.TRS ? 'T' : ''}SL ${
            newOrder.bracket.stopPrice
          }`) ||
        (newOrder.bracket?.limitPrice && `TP ${newOrder.bracket?.limitPrice}`);

  const newCount = getDimensionedUnit(
    newOrder.quantity || 0,
    finInfoExtItem?.lot,
    orderDimension
  );
  const newSuffix = getAssetUnits(newCount, orderDimension);
  const newText = (
    <Typography.Text view="secondary-large">
      <Amount.Pure
        value={newCount * getMinority(newCount)}
        minority={getMinority(newCount)}
        view="withZeroMinorPart"
      />
      &nbsp;
      {newSuffix}
      &nbsp;
      {newTicker}
      &nbsp;
      {newOrderPricePart}
      &nbsp;
      <Typography.Text view="secondary-large" color="secondary">
        {orderTypeAbbrMap[newOrder.idOrderType]}
      </Typography.Text>
      {newSltpContent ? <>&nbsp;{newSltpContent}</> : ''}
    </Typography.Text>
  );

  const prevDescription = (
    <div>
      {prevText}
      <br />
      {prevOrder.idOrderType === OrderType.STL &&
      prevOrder.limitLevelAlternative ? (
        <Typography.Text tag="div" view="secondary-large" color="secondary">
          Цена с проскоком не{' '}
          {prevOrder.buySell === OrderDirection.Buy ? 'более' : 'менее'}{' '}
          <Amount.Pure
            value={prevOrder.limitLevelAlternative * priceDecimals}
            currency={getSymbolToShow(
              fullFi?.currencyCode,
              fullFi?.idObjectGroup
            )}
            minority={priceDecimals}
          />
          {isBond ? '%' : undefined}
        </Typography.Text>
      ) : null}
      <Typography.Text
        tag="div"
        view="secondary-large"
        color="secondary"
      >{`Активация ${
        getSuitableActivationType(
          prevOrder.acceptTime,
          prevOrder.activationTime
        ).content
      },
        ${getDate(prevOrder.idLifeTime)}`}</Typography.Text>
    </div>
  );

  const newDescription = (
    <div>
      {newText}
      <br />
      {newOrder.idOrderType === OrderType.STL &&
      newOrder.limitLevelAlternative ? (
        <Typography.Text tag="div" view="secondary-large" color="secondary">
          Цена с проскоком не{' '}
          {newOrder.buySell === OrderDirection.Buy ? 'более' : 'менее'}{' '}
          <Amount.Pure
            value={newOrder.limitLevelAlternative * priceDecimals}
            currency={getSymbolToShow(
              fullFi?.currencyCode,
              fullFi?.idObjectGroup
            )}
            minority={priceDecimals}
          />
          {isBond ? '%' : undefined}
        </Typography.Text>
      ) : null}
      <Typography.Text tag="div" view="secondary-large" color="secondary">
        {`Активация ${
          getSuitableActivationType(getServerTime(), newOrder.activationTime)
            .content
        },
        ${getDate(newOrder.idLifeTime)}`}
      </Typography.Text>
    </div>
  );

  const handleReplaceOrder = () => {
    setIsLoading(true);

    if (onReplace) {
      onReplace();
    } else {
      replaceOrder(idFi, prevOrder, newOrder);
    }

    onSubmit?.();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Text view="primary-medium" weight="bold">
          Подтверждение
        </Typography.Text>
        <CrossMIcon
          className={styles.closeIcon}
          width={18}
          height={18}
          onClick={onCancel}
        />
      </div>
      <Typography.Text tag="div" view="secondary-large">
        <Typography.Text view="secondary-large" color="secondary">
          Вы уверены, что хотите изменить заявку
        </Typography.Text>
        <div>
          <div className={styles.orderInfoRow}>
            <span className={styles.orderInfoPreposition}>с</span>
            {buySell}
            {prevDescription}
          </div>
          <div className={styles.orderInfoRow}>
            <span className={styles.orderInfoPreposition}>на</span>
            {buySell}
            {newDescription}
          </div>
        </div>
      </Typography.Text>
      <Gap size="m" />
      <div
        className={styles.orderTextButton}
        onClick={() => setIsOpenTextOrder(true)}
      >
        <DocumentLinesMIcon height={16} width={16} />
        <Typography.Text view="secondary-small">
          Текстовое поручение
        </Typography.Text>
        <ChevronRightMIcon />
      </div>
      <TextOrder
        params={{
          idFi,
          idSubaccount: selectedSubAccountId,
          idOrderType: newOrder.idOrderType,
          buy: newOrder.buySell === OrderDirection.Buy,
          quantity: newOrder.quantity,
          stopPrice: newOrder.stopPrice,
          idLifeTime: newOrder.idLifeTime,
          idExecutionType: newOrder.idExecutionType,
          idActivationTime: newOrder.idActivationTime || ActivationTime.NOW,
          withDrawTime:
            //Пока тут обрабатываем GTC
            newOrder.idLifeTime === LifeTime.GTD
              ? new Date(2100, 0, 1)
              : undefined,
          limitPrice: newOrder.limitPrice,
          bracket: newOrder.bracket,
          inputPrice: newOrder.price,
          limitLevelAlternative: newOrder.limitLevelAlternative,
        }}
        prevOrder={prevOrder}
        isOpen={isOpenTextOrder}
        onClose={() => setIsOpenTextOrder(false)}
        onConfirm={handleReplaceOrder}
      />
      <div className={styles.buttonToolbar}>
        <Button
          className={cn(styles.customButton, styles.customCancelButton)}
          view="ghost"
          type="submit"
          size="xxs"
          block
          onClick={onCancel}
          leftAddons={<CrossMIcon width={18} height={18} />}
        >
          Отмена
        </Button>
        <Button
          className={styles.customButton}
          loading={isLoading}
          size="xxs"
          view="secondary"
          block
          onClick={handleReplaceOrder}
          leftAddons={<CheckmarkMIcon width={18} height={18} />}
        >
          Подтвердить
        </Button>
      </div>
    </div>
  );
};
