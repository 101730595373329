import { getUnixTime } from 'date-fns';

import { Message, MessageObjectPayload } from '../client/entities';
import { isArrayMessage, isObjectMessage } from '../client/helpers';

/*

В этом сервисе трекаются сообщения и энтити. Когда интерфейс запрашивает данные,
данные старше 5 секунд дропаются.

Данные сохраняеют по unixTimetamp - это целок количество секнуд.

*/

export type EntityStats = Map<string, number>;

const entitiesCounter = new Map<number, Map<string, number>>();
const messageCounter = new Map<number, number>();

const trackEntity = (message: MessageObjectPayload, now: Date) => {
  const unixTime = getUnixTime(now);
  if (!entitiesCounter.has(unixTime)) {
    const child = new Map();
    child.set(message.type, 1);
    entitiesCounter.set(unixTime, child);
  } else {
    const record = entitiesCounter.get(unixTime);

    if (record?.has(message.type)) {
      const prev = record.get(message.type);
      record.set(message.type, prev! + 1);
    } else {
      record?.set(message.type, 1);
    }
  }
};

export const trackClientMessage = (message: Message): void => {
  const now = new Date();
  const unixNow = getUnixTime(now);

  if (messageCounter.has(unixNow)) {
    const prev = messageCounter.get(unixNow);
    messageCounter.set(unixNow, prev! + 1);
  } else {
    messageCounter.set(unixNow, 1);
  }

  if (isObjectMessage(message.payload)) {
    trackEntity(message.payload, now);
  } else if (isArrayMessage(message.payload)) {
    message.payload.forEach((m) => trackEntity(m, now));
  }
};

export const getMessagesCount = () => {
  const now = getUnixTime(new Date());

  let result = 0;

  for (const [key] of messageCounter.entries()) {
    if (now - key > 5) {
      messageCounter.delete(key);
    } else {
      result = result + messageCounter.get(key)!;
    }
  }

  return result;
};

export const getEntitiesStats = (): EntityStats => {
  const now = getUnixTime(new Date());
  const result = new Map<string, number>();

  for (const [key] of entitiesCounter.entries()) {
    if (now - key > 5) {
      entitiesCounter.delete(key);
    } else {
      const record = entitiesCounter.get(key);

      for (const [entity, count] of record!.entries()) {
        if (!result.has(entity)) {
          result.set(entity, count);
        } else {
          const prev = result.get(entity);
          result.set(entity, prev! + count);
        }
      }
    }
  }
  return result;
};
