// это копипаста с https://raw.githubusercontent.com/PeculiarVentures/PKI.js/master/examples/CMSSignedComplexExample/es6.js
// поичтать на досуге https://habr.com/ru/company/aktiv-company/blog/191866/
import * as asn1js from 'asn1js';
import {
  Certificate,
  ContentInfo,
  EncapsulatedContentInfo,
  IssuerAndSerialNumber,
  SignedData,
  SignerInfo,
} from 'pkijs/build';
export async function createCMS(
  buffer,
  certificateBytes,
  privateKey,
  hashAlgorithm
) {
  const asn1 = asn1js.fromBER(certificateBytes);
  const certificate = new Certificate({ schema: asn1.result });

  //region Creation of a new CMS Signed Data
  const cmsSigned = new SignedData({
    encapContentInfo: new EncapsulatedContentInfo({
      eContentType: '1.2.840.113549.1.7.1', // "data" content type
      eContent: new asn1js.OctetString({ valueHex: buffer }),
    }),
    signerInfos: [
      new SignerInfo({
        sid: new IssuerAndSerialNumber({
          issuer: certificate.issuer,
          serialNumber: certificate.serialNumber,
        }),
      }),
    ],
    certificates: [certificate],
  });

  await cmsSigned.sign(privateKey, 0, hashAlgorithm);

  const cmsSignedSchema = cmsSigned.toSchema(true);

  const cmsContentSimp = new ContentInfo({
    contentType: '1.2.840.113549.1.7.2',
    content: cmsSignedSchema,
  });

  const _cmsSignedSchema = cmsContentSimp.toSchema();

  //region Make length of some elements in "indefinite form"
  _cmsSignedSchema.lenBlock.isIndefiniteForm = true;

  const block1 = _cmsSignedSchema.valueBlock.value[1];
  block1.lenBlock.isIndefiniteForm = true;

  const block2 = block1.valueBlock.value[0];
  block2.lenBlock.isIndefiniteForm = true;

  const block3 = block2.valueBlock.value[2];
  block3.lenBlock.isIndefiniteForm = true;
  block3.valueBlock.value[1].lenBlock.isIndefiniteForm = true;
  block3.valueBlock.value[1].valueBlock.value[0].lenBlock.isIndefiniteForm = true;
  //endregion

  const final = _cmsSignedSchema.toBER(false);
  return final;
}
