import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Input } from '@alfalab/core-components/input/modern';
import { ModalDesktop } from '@alfalab/core-components/modal/desktop';
import { PhoneInput } from '@alfalab/core-components/phone-input';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';

import {
  passwordRestoreChange,
  passwordRestoreCheckSms,
  PasswordRestoreCheckSmsResponse,
  passwordRestoreReSendSms,
  passwordRestoreSendSms,
} from '@terminal/core/lib/rest/lkAuth';

import { SmsSignDialog } from '../components/SmsSignDialog';
import { FormContainer } from './FormContainer';

import styles from './LoginForm.module.css';

enum RestoreStep {
  CODE_CHECK = 'CODE_CHECK',
  NEW_PASSWORD = 'NEW_PASSWORD',
  SUCCESS = 'SUCCESS',
}

const useRestoreSMS = ({
  onSendSmsSuccess,
  onSendSmsError,
  onCheckSmsSuccess,
  onCheckSmsError,
}: {
  onSendSmsSuccess?: () => void;
  onSendSmsError?: () => void;
  onCheckSmsSuccess: (formId: string, login: string) => void;
  onCheckSmsError?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formId, setFormId] = useState<string | undefined>();

  const codeSendError = useCallback(
    (error?: any) => {
      setError({
        code: 1,
        message: error?.message || 'Произошла ошибка во время отправки кода',
      });
    },
    [setError]
  );

  const codeCheckError = useCallback(
    (error: any) => {
      setError({
        code: 1,
        message: error?.message || 'Произошла ошибка во время проверки кода',
      });
    },
    [setError]
  );

  const reset = useCallback(() => {
    setIsOpen(false);
    setFormId(undefined);
    setError(null);
    setIsSuccess(false);
  }, [setFormId, setError, setIsSuccess]);

  const initMutation = useMutation(passwordRestoreSendSms, {
    onSuccess: (data: any) => {
      if (data?.formId) {
        setFormId(data.formId);
        setIsOpen(true);
        onSendSmsSuccess && onSendSmsSuccess();
      } else {
        codeSendError();
        onSendSmsError && onSendSmsError();
      }
    },
    onError: () => {
      codeSendError();
      onSendSmsError && onSendSmsError();
    },
  });

  const codeCheckMutation = useMutation(passwordRestoreCheckSms, {
    onSuccess: (response: PasswordRestoreCheckSmsResponse) => {
      if (response.login && formId) {
        setIsSuccess(true);
        onCheckSmsSuccess && onCheckSmsSuccess(formId, response.login);
        reset();
      } else {
        codeCheckError(error);
        onCheckSmsError && onCheckSmsError();
      }
    },
    onError: (error: any) => {
      codeCheckError(error);
      onCheckSmsError && onCheckSmsError();
    },
  });

  const resendCodeMutation = useMutation(passwordRestoreReSendSms, {
    onSuccess: (response: any) => {
      if (response?.code === 0) {
        setIsSuccess(true);
        onSendSmsSuccess && onSendSmsSuccess();
      } else {
        codeSendError();
        onSendSmsError && onSendSmsError();
      }
    },
    onError: (error: any) => {
      codeSendError(error);
      onSendSmsError && onSendSmsError();
    },
  });

  const resendSmsCode = useCallback(async () => {
    if (formId) {
      resendCodeMutation.mutateAsync(formId);
    } else {
      codeSendError();
      onSendSmsError && onSendSmsError();
    }

    return Promise.resolve();
  }, [formId, resendCodeMutation, onSendSmsError, codeSendError]);

  const checkSmsCode = useCallback(
    (code: string) => {
      if (formId) {
        codeCheckMutation.mutateAsync({
          formId,
          code,
        });
      } else {
        codeCheckError(error);
        onCheckSmsError && onCheckSmsError();
      }
    },
    [formId, codeCheckMutation, onCheckSmsError, codeCheckError, error]
  );

  const onCancel = useCallback(() => {
    reset();
    setIsOpen(false);
  }, [reset, setIsOpen]);

  return {
    initMutation,
    modalProps: {
      isMobile: false,
      title: '',
      open: isOpen,
      codeSending: initMutation.isLoading,
      error,
      isError:
        initMutation.isError ||
        resendCodeMutation.isError ||
        codeCheckMutation.isError,
      isSuccess: isSuccess,
      onCancel,
      onResend: resendSmsCode,
      signCheck: checkSmsCode,
      onComplete: () => undefined,
    },
  };
};

const CodeCheck = ({
  toNewPassword,
  appLogo,
  copyright,
}: {
  toNewPassword: (formId: string, login: string) => void;
  appLogo: JSX.Element | null;
  copyright: string;
}) => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [phone, setPhone] = useState('');

  const isValidForm = useMemo(() => {
    let scope = 0;

    if (lastName.length > 0) {
      scope++;
    }

    if (firstName.length > 0) {
      scope++;
    }

    // Бывают люди без отчества
    // if (middleName.length > 0) {
    //   scope++;
    // }

    if (phone.length >= 16) {
      scope++;
    }

    return scope === 3;
  }, [lastName, firstName, phone]);

  const onSendSmsSuccess = useCallback(() => {
    setIsPending(false);
    setError(undefined);
  }, [setIsPending]);

  const onSendSmsError = useCallback(() => {
    setIsPending(false);
    setError('Произошла ошибка при отправке СМС, попробуйте ещё раз');
  }, [setError]);

  const onCheckSmsSuccess = useCallback(
    (formId: string, login: string) => {
      setError(undefined);
      toNewPassword(formId, login);
    },
    [setError, toNewPassword]
  );

  const onCheckSmsError = useCallback(() => {
    setError('Произошла ошибка при проверке кода, попробуйте ещё раз');
  }, [setError]);

  const { initMutation, modalProps } = useRestoreSMS({
    onSendSmsSuccess,
    onSendSmsError,
    onCheckSmsSuccess,
    onCheckSmsError,
  });

  const onSubmit = useCallback(() => {
    setError(undefined);
    setIsPending(true);

    initMutation.mutateAsync({
      patronymic: middleName,
      phone,
      lastName,
      firstName,
    });
  }, [
    initMutation,
    middleName,
    phone,
    lastName,
    firstName,
    setError,
    setIsPending,
  ]);

  return (
    <>
      <SmsSignDialog {...modalProps} />
      <FormContainer
        title="Восстановление пароля"
        appLogo={appLogo}
        copyright={copyright}
      >
        <div className={styles.loginForm}>
          <Input
            value={lastName}
            block
            required
            size="m"
            label="Фамилия"
            onChange={(e) => setLastName(e.target.value)}
          />
          <Input
            value={firstName}
            block
            required
            size="m"
            label="Имя"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
            value={middleName}
            block
            size="m"
            label="Отчество"
            onChange={(e) => setMiddleName(e.target.value)}
          />
          <PhoneInput
            value={phone}
            block
            required
            size="m"
            label="Номер телефона"
            onChange={(e) => setPhone(e.target.value)}
          />

          {error && (
            <Typography.Text
              view="secondary-large"
              color="negative"
              className={styles.errorLabel}
            >
              {error}
            </Typography.Text>
          )}

          <ButtonDesktop
            view="accent"
            type="submit"
            size="m"
            loading={isPending}
            disabled={isPending || !isValidForm}
            className={styles.submitButton}
            onClick={onSubmit}
          >
            Продолжить
          </ButtonDesktop>
        </div>
      </FormContainer>
    </>
  );
};

interface UserData {
  formId?: string;
  login?: string;
}

const NewPassword = ({
  userData,
  toSuccess,
  appLogo,
  copyright,
}: {
  userData: UserData;
  toSuccess: () => void;
  appLogo: JSX.Element | null;
  copyright: string;
}) => {
  const [error, setError] = useState<string | undefined>();
  const [isPending, setIsPending] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const isValidForm = useMemo(() => {
    return password.length > 0 && passwordRepeat.length > 0;
  }, [password, passwordRepeat]);

  const onClickNext = useCallback(async () => {
    if (password !== passwordRepeat) {
      setError('Пароли не совпадают');
    } else {
      setError(undefined);
      setIsPending(true);

      try {
        const { formId } = userData;

        if (!formId) {
          throw Error('formId не определен');
        }

        const res = await passwordRestoreChange({
          formId,
          password,
          passportClientId: null,
          authorizeInPassport: false,
        });

        if (typeof res.code === 'number' && res.code !== 0) {
          throw Error(`Код ошибки ${res.code}`);
        }

        toSuccess();
      } catch (e) {
        setError('Произошла ошибка, попробуйте ещё раз');
      } finally {
        setIsPending(false);
      }
    }
  }, [password, passwordRepeat, setError, userData, toSuccess]);

  return (
    <FormContainer
      appLogo={appLogo}
      copyright={copyright}
      title={
        <>
          Запомните ваш логин&nbsp;
          <span className={styles.loginString}>{userData.login}</span>
          <br /> и придумайте к нему пароль
        </>
      }
    >
      <div className={styles.loginForm}>
        <Input
          value={password}
          block
          required
          size="m"
          label="Пароль"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
        <Input
          value={passwordRepeat}
          block
          required
          size="m"
          label="Повторите пароль"
          onChange={(e) => setPasswordRepeat(e.target.value)}
          type="password"
        />
        {error && (
          <Typography.Text
            view="secondary-large"
            color="negative"
            className={styles.errorLabel}
          >
            {error}
          </Typography.Text>
        )}
        <ButtonDesktop
          view="primary"
          type="submit"
          size="m"
          loading={isPending}
          disabled={isPending || !isValidForm}
          className={styles.submitButton}
          onClick={onClickNext}
        >
          Продолжить
        </ButtonDesktop>
      </div>
    </FormContainer>
  );
};

const Success = () => {
  return (
    <ModalDesktop
      open
      size="m"
      className={styles.successWrapper}
      contentClassName={styles.successContent}
    >
      <div>
        <SuperEllipse
          size={64}
          backgroundColor="var(--color-light-specialbg-secondary-grouped)"
        >
          <CheckmarkMIcon />
        </SuperEllipse>
      </div>
      <Typography.Title
        view="xsmall"
        tag="div"
        weight="medium"
        color="primary"
        className={styles.successTitle}
      >
        Отлично, мы запомнили <br />
        ваш пароль
      </Typography.Title>
      <ButtonDesktop
        view="primary"
        size="m"
        Component={Link}
        href="/login"
        className={styles.successButton}
      >
        Войти в систему
      </ButtonDesktop>
    </ModalDesktop>
  );
};

interface RestoreProps {
  appLogo: JSX.Element | null;
  copyright: string;
}

export const Restore = ({ appLogo, copyright }: RestoreProps) => {
  const [userData, setUserData] = useState<UserData>({});

  const [step, setStep] = useState<RestoreStep>(RestoreStep.CODE_CHECK);

  const toNewPassword = useCallback((formId: string, login: string) => {
    setUserData({ formId, login });
    setStep(RestoreStep.NEW_PASSWORD);
  }, []);

  const toSuccess = useCallback(() => {
    setStep(RestoreStep.SUCCESS);
  }, []);

  if (step === RestoreStep.SUCCESS) {
    return <Success />;
  }

  if (step === RestoreStep.NEW_PASSWORD) {
    return (
      <NewPassword
        userData={userData}
        toSuccess={toSuccess}
        appLogo={appLogo}
        copyright={copyright}
      />
    );
  }

  return (
    <CodeCheck
      toNewPassword={toNewPassword}
      appLogo={appLogo}
      copyright={copyright}
    />
  );
};
