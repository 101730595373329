import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { FormatedText } from '@terminal/lk/shared';

export interface ApplicationTextProps {
  text: string;
  backLabel?: string;
  open?: boolean;
  onClose?: () => void;
}

export const ApplicationText = (props: ApplicationTextProps) => {
  return (
    <Portal id="add-account-modals">
      <FunctionalModalSidebar
        open={props.open}
        backLabel={props.backLabel}
        onClose={props.onClose}
      >
        <Typography.Text
          view="primary-small"
          color="primary"
          weight="bold"
          tag="p"
          defaultMargins={false}
        >
          Заявление на обслуживание на финансовых рынках
        </Typography.Text>
        <Gap size="m" />
        <Typography.Text view="secondary-small" tag="div" color="primary">
          <FormatedText text={props.text} />
        </Typography.Text>
      </FunctionalModalSidebar>
    </Portal>
  );
};
