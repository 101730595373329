import { ANALYTICS, DIMENSIONS_SCHEMAS } from '../../../constants/analytics';
import { track } from '../core';
import type { LoadStatus } from '../model/main';

export const load = {
  screen: (status: LoadStatus) => {
    track(
      ANALYTICS.CATEGORY.SHOWCASE,
      ANALYTICS.ACTION.LOAD,
      null,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            '20': status,
          },
        },
      ]
    );
  },
  ideas: {
    widget: (status: LoadStatus) => {
      track(
        ANALYTICS.CATEGORY.SHOWCASE,
        ANALYTICS.ACTION.LOAD,
        ANALYTICS.LABEL.IDEAS_WIDGET_CONTENT,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              '20': status,
            },
          },
        ]
      );
    },
  },
};

export const show = {
  ideas: {
    widget: () => {
      track(
        ANALYTICS.CATEGORY.SHOWCASE,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.IDEAS_WIDGET
      );
    },
  },
};

export const click = {
  ideas: {
    widget: {
      header: () => {
        track(
          ANALYTICS.CATEGORY.SHOWCASE,
          ANALYTICS.ACTION.CLICK,
          ANALYTICS.LABEL.IDEAS_WIDGET_HEADER
        );
      },
    },
  },
};
