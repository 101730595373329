import { useMemo } from 'react';
import type { IterableElement } from 'type-fest';

import { useStore } from '@terminal/core/store';
import { trackPriceSettingsModalStateSelector } from '@terminal/core/store/selectors/trackPrice/trackPriceSettingsModalStateSelector';

import { ModalProps } from '../../../legacy/components/Modal';

type ReturnValue = Pick<ModalProps, 'actionButtonsEnd'>;

type SubmitButtonProps = IterableElement<ReturnValue['actionButtonsEnd']>;

export function useModalActionButtons(
  submitButtonProps: SubmitButtonProps
): ReturnValue {
  const { close } = useStore(trackPriceSettingsModalStateSelector);

  return useMemo(
    () => ({
      actionButtonsEnd: [
        {
          children: 'Отмена',
          onClick: close,
        },
        {
          children: 'Сохранить',
          view: 'secondary',
          type: 'submit',
          ...submitButtonProps,
        },
      ],
    }),
    [close, submitButtonProps]
  );
}
