import React, { VFC } from 'react';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { FormControlSummary } from '../../../../../shared/ui/FormControlSummary';

import styles from './RequisitesValue.module.css';

export type RequisitesValueProps = {
  title: string;
  value: string;
  tooltip?: string;
  className?: string;
};

export const RequisitesValue: VFC<RequisitesValueProps> = (props) => {
  const { title, value, tooltip, className } = props;

  return (
    <FormControlSummary
      className={className}
      label={
        <>
          {title}{' '}
          {tooltip && (
            <Tooltip
              position="top"
              content={tooltip}
              targetClassName={styles.tooltipTarget}
            >
              <InformationCircleLineSIcon className={styles.icon} />
            </Tooltip>
          )}
        </>
      }
    >
      {value}
    </FormControlSummary>
  );
};
