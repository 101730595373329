import { ALFADIRECT_API_URI } from '../../env';
import { fetchThroughProxy } from '../url';

import { IdeasLinkResult, IdeasRequest, IdeasResult } from '../../types/ideas';

export const DIRECT_URI = ALFADIRECT_API_URI;

export async function getIdeas({
  limit = 50,
  code = 'brokerPlus',
  offset = 0,
  status,
}: IdeasRequest): Promise<IdeasResult> {
  const response = await fetchThroughProxy(
    ALFADIRECT_API_URI +
      `/api/article?includeFilter[categories_types][code]=broker_plus${
        status ? `&includeFilter[brokerPlus][status]=${status}` : ''
      }&limit=${limit}&offset=${offset}`,
    {
      method: 'GET',
    }
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
}

export async function getIdeasLinks({
  limit = 100,
}: IdeasRequest): Promise<IdeasLinkResult> {
  const response = await fetchThroughProxy(
    ALFADIRECT_API_URI +
      `/api/article/terminal?limit=${limit}&product=broker_plus`,
    {
      method: 'GET',
    }
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
}
