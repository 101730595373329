import { OrderEntity } from '../lib/client/entities';

import { OrderItem } from '../types/order';

export const orderMap = (entity: OrderEntity): OrderItem => ({
  idAccount: entity.IdAccount,
  idSubAccount: entity.IdSubAccount,
  orderStatusRevision: entity.OrderStatusRevision,
  rest: entity.Rest,
  entryRest: entity.EntryRest,
  price: entity.Price,
  extSessId: entity.ExtSessId,
  extOrderNum: entity.ExtOrderNum?.toString() ?? '',
  sessionFilled: entity.SessionFilled,
  orderUpdateTime: entity.OrderUpdateTime?.toString() ?? '',
  filledPrice: entity.FilledPrice,
  brokerComment: entity.BrokerComment,
  brokerErrorCode: entity.BrokerErrorCode,
  bATime: entity.BATime?.toString() ?? '',
  confirmedQuantity: entity.ConfirmedQuantity,
  killNumEDocument: entity.KillNumEDocument?.toString() ?? '',
  sentTime: entity.SentTime?.toString() ?? '',
  sendCompletedTime: entity.SendCompletedTime?.toString() ?? '',
  killTime: entity.KillTime?.toString() ?? '',
  terminatedTime: entity.TerminatedTime?.toString() ?? '',
  exchangeTime: entity.ExchangeTime?.toString() ?? '',
  exchangeTerminatedTime: entity.ExchangeTerminatedTime?.toString() ?? '',
  repoDate: entity.RepoDate?.toString() ?? '',
  clientNumStrategy: entity.ClientNumStrategy,
  idOrderType: entity.IdOrderType,
  idQuantityType: entity.IdQuantityType,
  idPriceType: entity.IdPriceType,
  idLifeTime: entity.IdLifeTime,
  idExecutionType: entity.IdExecutionType,
  idPriceControlType: entity.IdPriceControlType,
  idSettleCode: entity.IdSettleCode,
  idObject: entity.IdObject,
  idMarketBoard: entity.IdMarketBoard,
  idOrderStatus: entity.IdOrderStatus,
  idRazdel: entity.IdRazdel,
  limitPrice: entity.LimitPrice,
  stopPrice: entity.StopPrice,
  limitLevelAlternative: entity.LimitLevelAlternative,
  signTime: entity.SignTime,
  buySell: entity.BuySell,
  contragent: entity.Contragent,
  contragentRef: entity.ContragentRef,
  quantity: entity.Quantity,
  openQuantity: entity.OpenQuantity,
  refundRate: entity.RefundRate,
  sDiscount: entity.SDiscount,
  lowerDiscount: entity.LowerDiscount,
  upperDiscount: entity.UpperDiscount,
  activationTime: entity.ActivationTime,
  oCOGroup: entity.OCOGroup?.toString() ?? '',
  withdrawTime: entity.WithdrawTime,
  idFIActivate: entity.IdFIActivate,
  activationPriceDirection: entity.ActivationPriceDirection,
  value: entity.Value,
  bIC: entity.BIC,
  comment: entity.Comment,
  accountContragent: entity.AccountContragent,
  idAccountTypeContragent: entity.IdAccountTypeContragent,
  tradeNo: entity.TradeNo?.toString() ?? '',
  reason: entity.Reason,
  idCommandType: entity.IdCommandType,
  idDocumentType: entity.IdDocumentType,
  login: entity.Login,
  clientOrderNum: entity.ClientOrderNum,
  clientNumEDocument: entity.ClientNumEDocument,
  numEDocument: entity.NumEDocument?.toString() ?? '',
  acceptTime: entity.AcceptTime,
  numEDocumentBase: entity.NumEDocumentBase?.toString() ?? '',
  operation: entity.Operation,
});
