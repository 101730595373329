import { FC, memo, useCallback, useState } from 'react';

import { ColumnSettingModal } from '@terminal/common/ui/Table';
import { FiHighlight, TradesProps, Widget } from '@terminal/core/types/layout';

import { FinInstrAutocomplete } from '../../../../features/FinInstrAutocomplete';
import { WidgetHeader } from '../../../../features/WidgetHeader';
import { useWidgetContext } from '../../../../shared';
import { TradeFeedHighlightModal } from '../../features/TradeFeedHighlightModal';
import { TradeFeedTimerModal } from '../../features/TradeFeedTimerModal';
import { VolumeFilter } from '../../features/VolumeFilter';

import { useSettings } from '../../hooks/useSettings';
import { useTradesFeedContext } from '../../hooks/useTradesFeedContext';

interface Props {
  container: HTMLDivElement | null;
}

const TradesFeedHeader: FC<Props> = ({ container }) => {
  const [isOpenColumnSettings, setIsOpenColumnSettings] =
    useState<boolean>(false);
  const [timerModalVisible, setTimerModalVisible] = useState<boolean>(false);
  const [highlightModalVisible, setHighlightModalVisible] =
    useState<boolean>(false);

  const {
    nodeId,
    idFi,
    columnsSetting,
    saveColumnSetting,
    highlight,
    timerActive,
    timer,
  } = useTradesFeedContext();

  const { updateNode, getTradesFeedSettings } = useWidgetContext();

  const tradesFeedsSettings = getTradesFeedSettings();
  const tradesFeedSettings = tradesFeedsSettings?.[idFi!];

  const { highlightActive, highlightFrom } = highlight?.[String(idFi)] || {};

  const fitColumns = useCallback(() => {
    if (container) {
      const width = (container.clientWidth - 30) / columnsSetting.length;

      saveColumnSetting(columnsSetting.map((column) => ({ ...column, width })));
    }
  }, [columnsSetting, container, saveColumnSetting]);

  const widgetSettings = useSettings({
    setTimerModalVisible,
    timerActive,
    timer: timer ?? 60,
    setHighlightModalVisible,
    setIsOpenColumnSettings,
    highlightActive: highlightActive ?? true,
    highlightFrom: highlightFrom ?? 0,
    fitColumns,
  });

  const updateConfig = useCallback(
    (config: Partial<TradesProps>) =>
      updateNode(nodeId!, {
        timer,
        timerActive,
        highlight,
        ...config,
      }),
    [highlight, nodeId, timer, timerActive, updateNode]
  );

  const handleUpdateHighlight = useCallback(
    (fiHighlight: FiHighlight) => {
      updateNode(nodeId!, {
        timer,
        timerActive,
        highlight: {
          ...highlight,
          [String(idFi)]: fiHighlight,
        },
      });
    },
    [highlight, idFi, nodeId, timer, timerActive, updateNode]
  );

  return (
    <>
      <WidgetHeader
        inputs={
          <FinInstrAutocomplete
            idFi={idFi}
            nodeId={nodeId}
            widget={Widget.TRADES}
          />
        }
        filters={
          <VolumeFilter
            idFi={idFi}
            nodeId={nodeId}
            tradesFeedSettings={tradesFeedSettings}
          />
        }
        widgetSettings={widgetSettings}
      />

      <ColumnSettingModal
        isOpen={isOpenColumnSettings}
        setIsOpen={setIsOpenColumnSettings}
        columnsSetting={columnsSetting}
        updateNode={updateNode}
        nodeId={nodeId}
        widgetType={Widget.TRADES}
      />

      {timerModalVisible && (
        <TradeFeedTimerModal
          onClose={() => setTimerModalVisible(false)}
          timer={timer}
          timerActive={timerActive}
          updateConfig={updateConfig}
        />
      )}
      {highlightModalVisible && (
        <TradeFeedHighlightModal
          onClose={() => setHighlightModalVisible(false)}
          active={highlightActive}
          highlightFrom={highlightFrom}
          updateHighlight={handleUpdateHighlight}
        />
      )}
    </>
  );
};

export const Header = memo(TradesFeedHeader);
