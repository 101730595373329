import { useMemo } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import {
  SecuritiesClientReference,
  SecuritiesManager,
  SecuritiesPlaceItem,
  SecuritiesUserType,
} from '@terminal/core/lib/rest/lkSecurities';

import {
  buildPersonalDocumentStr,
  COUNTERAGENT_TYPES,
  getDepoAccountLabel,
  getDepoSectionLabel,
  getMarket,
  isAccountTypeRequired,
  isConteragentAccDepoRequired,
  isCounterpartyRequired,
  isDepoCodeCounterpartyRequired,
  isDepoSectionCounterpartyRequired,
  isLegalFieldsRequired,
  isOrgNameRequired,
  isOtherPlaceNameRequired,
  isRequisitesRequired,
  isSwiftRequired,
  SecurityPlaceCode,
} from '../../shared';
// TODO: Заменить на FormControlSummary
import { SummaryField } from './SummaryField';

import styles from './Summary.module.css';

interface SummaryCounterpartyProps {
  title?: string;
  userTypeList?: SecuritiesUserType[];
  userTypeListPending: boolean;
  marketAlfa: string;
  selectedCounterpartyPlace?: SecuritiesPlaceItem | null;
  accountType: string;
  counteragentType?: COUNTERAGENT_TYPES | undefined;
  depoAccount: string;
  depoSection: string;
  depoCode: string;
  counterpartyPlace: string;
  counterpartyPlaceOther: string;
  conteragentAccDepo: string;
  counteragentAlfaBank: boolean;
  counteragentReferenceInfo?: SecuritiesClientReference;
  swift: string;
  ownershipChange: boolean;

  manager: SecuritiesManager | undefined;
  docNumber: string;
  docDate: string;
  docWhom: string;

  orgName: string;
  orgOGRN: string;
  orgWhen: string;
  orgWhom: string;
}

export const SummaryCounterparty = ({
  title,
  userTypeList,
  marketAlfa,
  selectedCounterpartyPlace,
  accountType,
  counteragentType,
  depoAccount,
  depoSection,
  depoCode,
  counterpartyPlace,
  counterpartyPlaceOther,
  conteragentAccDepo,
  counteragentAlfaBank,
  counteragentReferenceInfo,
  swift,
  ownershipChange,

  manager,
  docNumber,
  docDate,
  docWhom,

  orgName,
  orgOGRN,
  orgWhen,
  orgWhom,
}: SummaryCounterpartyProps) => {
  const counterpartyMarket = selectedCounterpartyPlace
    ? selectedCounterpartyPlace.placeCode
    : '';
  const MARKET = getMarket(marketAlfa, counterpartyMarket);

  const personalDocumentStr = buildPersonalDocumentStr(
    manager ? manager.managerDocType || '' : '',
    docNumber,
    docWhom,
    docDate
  );

  const userType = useMemo(() => {
    return userTypeList
      ? userTypeList.find((type) => type.name === accountType)
      : undefined;
  }, [userTypeList, accountType]);

  return (
    <>
      <Typography.Text weight="bold" tag="p">
        {title || 'Откуда'}
      </Typography.Text>
      <div className={styles.section}>
        {isCounterpartyRequired(
          counterpartyMarket,
          userType ? userType.name : '',
          counteragentAlfaBank
        ) && (
          <SummaryField
            label={
              counterpartyMarket === SecurityPlaceCode.REESTR
                ? 'Полное наименование контрагента'
                : 'Контрагент'
            }
            value={counterpartyPlace}
          />
        )}

        <SummaryField
          label="Место хранения"
          value={
            selectedCounterpartyPlace ? selectedCounterpartyPlace.name : ''
          }
        />

        {isAccountTypeRequired(counterpartyMarket, counteragentAlfaBank) && (
          <>
            <SummaryField
              label="Тип счета"
              value={userType ? userType.description : ''}
            />
            <SummaryField label="Лицевой счет" value={depoAccount} />
          </>
        )}

        {isOtherPlaceNameRequired(counterpartyMarket, counteragentAlfaBank) && (
          <SummaryField
            label="Наименование места хранения"
            value={counterpartyPlaceOther}
          />
        )}

        {((!isAccountTypeRequired(counterpartyMarket, counteragentAlfaBank) &&
          !isConteragentAccDepoRequired(counteragentAlfaBank)) ||
          isDepoSectionCounterpartyRequired(
            MARKET,
            marketAlfa,
            counteragentAlfaBank
          )) && (
          <>
            {!isAccountTypeRequired(counterpartyMarket, counteragentAlfaBank) &&
              !isConteragentAccDepoRequired(counteragentAlfaBank) &&
              !counteragentAlfaBank && (
                <SummaryField label="Счёт депо" value={depoAccount} />
              )}
            {isDepoSectionCounterpartyRequired(
              MARKET,
              marketAlfa,
              counteragentAlfaBank
            ) && (
              <SummaryField
                label={getDepoSectionLabel(marketAlfa)}
                value={depoSection}
              />
            )}
          </>
        )}

        {isDepoCodeCounterpartyRequired(
          MARKET,
          marketAlfa,
          counteragentAlfaBank
        ) && <SummaryField label="Идентификатор" value={depoCode} />}

        {isConteragentAccDepoRequired(counteragentAlfaBank) &&
          (!counteragentAlfaBank ? (
            <SummaryField
              label={getDepoAccountLabel(marketAlfa)}
              value={conteragentAccDepo}
            />
          ) : (
            <div className={styles.field}>&nbsp;</div>
          ))}

        {counteragentAlfaBank && Boolean(counteragentReferenceInfo) && (
          <>
            <SummaryField
              label="Счёт депо"
              value={counteragentReferenceInfo?.clientAcc}
            />
            <SummaryField
              label="Раздел счёта депо"
              value={counteragentReferenceInfo?.clientDvn}
            />
            <SummaryField
              label="Счёт депо в Go Invest"
              value={conteragentAccDepo}
            />
            <SummaryField
              label="Идентификатор"
              value={counteragentReferenceInfo?.clientCode}
            />
          </>
        )}

        {isSwiftRequired(MARKET, marketAlfa, counteragentAlfaBank) && (
          <SummaryField label="SWIFT" value={swift} />
        )}

        {isRequisitesRequired(counterpartyMarket) &&
          manager &&
          (isLegalFieldsRequired({
            counteragentType,
            manager,
            ownershipChange,
          }) ? (
            <>
              {isOrgNameRequired({
                accountType,
                manager,
                counteragentType,
              }) && (
                <SummaryField label="Полное наименование" value={orgName} />
              )}
              <SummaryField label="ОГРН №" value={orgOGRN} />
              <SummaryField label="Когда выдан" value={orgWhen} />
              <SummaryField label="Кем выдан" value={orgWhom} />
            </>
          ) : (
            <>
              <SummaryField label="ФИО" value={manager.managerFullName} />
              <SummaryField label="Документ" value={personalDocumentStr} />
            </>
          ))}
      </div>
    </>
  );
};
