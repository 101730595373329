import { IdentityDocumentType } from '../../model';

export const IDENTITY_DOCUMENT_NUMBER_MASK = {
  [IdentityDocumentType.RussianPassport]: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  [IdentityDocumentType.InternationalPassport]: [
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};
