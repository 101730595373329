import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { LimitRequestParams } from '@terminal/core/types/trading';

import { useLimits } from './useLimits';

import { useWarranty } from '../../../shared/hooks/useWarranty';

export const useTradeLimits = (
  selectedSubAccountId: number,
  selectedSubAccounts: string[],
  params: LimitRequestParams,
  idAccount?: number
) => {
  const { idFi, price } = params;
  const { useFinInfoExt } = useAlfaDirectContext();

  const finInfoExt = useFinInfoExt(idFi)[idFi];

  const warrantyBuy = useWarranty(idFi, price, {
    side: 'buy',
    finInfoExt,
    idAccount,
  });

  const warrantySell = useWarranty(idFi, price, {
    side: 'sell',
    finInfoExt,
    idAccount,
  });

  return useLimits(
    params,
    selectedSubAccountId,
    selectedSubAccounts,
    warrantyBuy,
    warrantySell
  );
};
