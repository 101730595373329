import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';

import { createDateRangeFilter } from '../../../lib/createDateRangeFilter';
import { RootStore } from '../../useStore';

/**
 * Выбирает операции за последние 2 суток
 * */
export function recentOperationsSelector(store: RootStore) {
  const { operations } = store;
  const startDate = startOfDay(subDays(new Date(), 2));

  return operations.filter(
    createDateRangeFilter('timeOperation', startDate, null)
  );
}
