import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { mapEducationStrategyDetails } from '../lib/mapEducationStrategyDetails';

import { EducationStrategyDetailsDTO } from './types/EducationStrategyDetailsDTO';
import { EducationStrategyDetailsParams } from './types/EducationStrategyDetailsParams';

export async function getEducationStrategyDetails(
  data: EducationStrategyDetailsParams
) {
  const res = await fetchLkResult<EducationStrategyDetailsDTO | LKResult>(
    qs.stringifyUrl({
      url: '/api/education/strategy',
      query: { ...data },
    })
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return mapEducationStrategyDetails(res);
}
