import { useCallback, useMemo } from 'react';

import { getStepDecimals } from '../../lib/format';
import { useFinInfoExt } from './useFinInfoExt';

type ReturnType = {
  /**
   * Шаг цены
   * */
  priceStep: number;
  /**
   * Количество десятичных знаков в цене
   * */
  decimalsNumber: number;
  /**
   * Количество минорных единиц в валюте
   * */
  minority: number;
  /**
   * Функция для форматирования цены
   * */
  format: (
    value: number,
    numberFormatOptions?: Intl.NumberFormatOptions
  ) => string;
};

/**
 * @param idFi идентификатор финансового инструмента
 * @returns характеристики цены указанного финансового инструмента
 * */
export function usePriceInfo(idFi: number): ReturnType {
  const { priceStep = 0 } = useFinInfoExt(idFi)[idFi] || {};
  const { priceDecimals, decimalsNumber } = getStepDecimals(
    priceStep || undefined
  );

  const format = useCallback<ReturnType['format']>(
    (
      value,
      numberFormatOptions = {
        minimumFractionDigits: decimalsNumber,
        maximumFractionDigits: decimalsNumber,
      }
    ) => new Intl.NumberFormat('RU', numberFormatOptions).format(value),
    [decimalsNumber]
  );

  return useMemo(
    () => ({
      priceStep,
      decimalsNumber,
      minority: priceDecimals,
      format,
    }),
    [decimalsNumber, format, priceDecimals, priceStep]
  );
}
