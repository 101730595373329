import { InputDesktop as Input } from '@alfalab/core-components/input/desktop';

import styles from './TextFilter.module.css';

type TextFilterProps = {
  filterValue?: string;
  onChange: (value: string) => void;
};

export const TextFilter = ({ filterValue, onChange }: TextFilterProps) => {
  return (
    <div className={styles.textFilter}>
      <Input
        value={filterValue}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Название содержит"
        size="s"
        block
      />
    </div>
  );
};
