import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { GetSelfTransferCheckDTO } from './types/GetSelfTransferCheckDTO';
import { GetSelfTransferCheckParams } from './types/GetSelfTransferCheckParams';

export const getSelfTransferCheck = async (
  data: GetSelfTransferCheckParams
): Promise<GetSelfTransferCheckDTO['result']> => {
  const url = qs.stringifyUrl({
    url: '/api/self-transfer/check',
    query: { ...data },
  });

  const res = await fetchLkResult<GetSelfTransferCheckDTO | LKResult>(url);

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res.result;
};
