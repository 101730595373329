import { useState } from 'react';
import { Badge } from '@alfalab/core-components/badge';
import { AlertCircleMIcon } from '@alfalab/icons-glyph/AlertCircleMIcon';
import { LockClosedMIcon } from '@alfalab/icons-glyph/LockClosedMIcon';
import { UserMIcon } from '@alfalab/icons-glyph/UserMIcon';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import {
  MainMenu,
  MainMenuItem,
} from '@terminal/common/components/MainMenu/MainMenu';

import { PasswordChange, ProfileContacts } from '../../features/Profile';

import { useInit } from '../../legacy/hooks';

import { ProfileSettingsMenuType } from './types';

import styles from './Profile.module.css';

interface ProfileProps {
  onClose: () => void;
}

export const Profile = ({ onClose }: ProfileProps) => {
  const [selected, setSelected] = useState<ProfileSettingsMenuType>(
    ProfileSettingsMenuType.Profile
  );

  const { data: init } = useInit();

  const menuItems: MainMenuItem<ProfileSettingsMenuType>[] = [
    {
      name: 'Основная информация',
      icon: <UserMIcon />,
      type: ProfileSettingsMenuType.Profile,
      rightIcon: !init?.emailConfirmed && (
        <Badge
          size="s"
          view="icon"
          iconColor="attention"
          content={<AlertCircleMIcon width="100%" height="100%" />}
        />
      ),
    },
    {
      name: 'Смена пароля',
      icon: <LockClosedMIcon />,
      type: ProfileSettingsMenuType.ChangePassword,
    },
  ];

  return (
    <FunctionalModal
      title="Настройки профиля"
      width={812}
      onClose={onClose}
      drawerId="profile-settings-modals"
      sidebar={
        <MainMenu
          items={menuItems}
          selected={selected}
          onItemSelect={(item) =>
            setSelected(item.type as ProfileSettingsMenuType)
          }
        />
      }
    >
      <div className={styles.container}>
        {selected === ProfileSettingsMenuType.ChangePassword && (
          <PasswordChange />
        )}
        {selected === ProfileSettingsMenuType.Profile && <ProfileContacts />}
      </div>
    </FunctionalModal>
  );
};
