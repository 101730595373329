import React, { useState } from 'react';
import { Loader } from '@alfalab/core-components/loader';

import { shallow, useStore } from '@terminal/core/store';
import {
  addAccountPreregisterGI,
  addAccountRegisterGI,
  AddAccountTypeGI,
  getAddAccountsConstraintsGI,
  useAddAccountConstraints,
} from '@terminal/lk-core';
import {
  AddAccountForm,
  AddAccountResult,
  ResultType,
} from '@terminal/lk-desktop-common';

import { AddAccountChoose } from '../../../../features/AddAccount';

import styles from './AddAccount.module.css';

export const AddAccount = React.memo(() => {
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [type, setType] = useState<AddAccountTypeGI | undefined>();

  const [setHeaderMenuOpen] = useStore(
    (state) => [state.setHeaderMenuOpen],
    shallow
  );

  const { data, isFetching: isConstraintsLoading } = useAddAccountConstraints(
    getAddAccountsConstraintsGI
  );

  const onSuccess = () => {
    setSuccess(true);
  };

  const onFailed = () => {
    setFailed(true);
  };

  const onResultClose = () => {
    setHeaderMenuOpen(null);
  };

  if (isConstraintsLoading) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  if (success) {
    return (
      <div className={styles.container}>
        <AddAccountResult
          onNewAccountModalClose={onResultClose}
          contentType={ResultType.Success}
        />
      </div>
    );
  }

  if (failed || (!isConstraintsLoading && !data)) {
    return (
      <div className={styles.container}>
        <AddAccountResult
          onNewAccountModalClose={onResultClose}
          contentType={ResultType.Failed}
        />
      </div>
    );
  }

  if (data && !data.newAccountIsPossible) {
    return (
      <div className={styles.container}>
        <AddAccountResult
          onNewAccountModalClose={onResultClose}
          contentType={ResultType.Rejected}
        />
      </div>
    );
  }

  if (type !== undefined) {
    return (
      <div className={styles.container}>
        <AddAccountForm
          type={type}
          requestPreregister={addAccountPreregisterGI}
          requestRegister={addAccountRegisterGI}
          onSuccess={onSuccess}
          onFailed={onFailed}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <AddAccountChoose onChoose={setType} />
    </div>
  );
});
