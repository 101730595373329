import {
  MIDDLE_API_INVEST_URI,
  MOBILE_CLUSTER_URI,
  USE_PASSPORT_CERBERUS_AUTH,
} from '../../../env';

export function getMiddleAPIURL(
  prefix: string,
  service: string,
  path: string,
  version: string = 'v1',
  useCerberusWeb = false
) {
  if (useCerberusWeb) {
    return `${prefix}/${service}-api${version === 'v1' ? '' : '-v2'}/${path}`;
  }

  return `${prefix}/api/${version}/${service}/${path}`;
}

/**
 * Возвращает ссылку API для кластера Альфа-Мобайл
 *
 * @return {string} - The API endpoint URL.
 */
export function getAMMiddleAPI(service: string, path: string, version: string) {
  const res = getMiddleAPIURL(
    `${MOBILE_CLUSTER_URI}${
      USE_PASSPORT_CERBERUS_AUTH ? '' : '/mobile-invest'
    }`,
    service,
    path,
    version,
    USE_PASSPORT_CERBERUS_AUTH
  );

  // костыль, одну ручку неправильно сконфигурировали, сделаем костыль пока не починим
  if (res.includes('/investments-recommendations-api/')) {
    return res.replace(
      '/investments-recommendations-api/',
      '/investments-recommendations/'
    );
  }

  return res;
}

/**
 * Возвращает ссылку API для кластера Альфа-Инвестиций
 *
 * @return {string} - The API endpoint URL.
 */
export function getAIMiddleAPI(service: string, path: string, version: string) {
  return getMiddleAPIURL(
    `${MIDDLE_API_INVEST_URI}${USE_PASSPORT_CERBERUS_AUTH ? '' : '/invest'}`,
    service,
    path,
    version,
    USE_PASSPORT_CERBERUS_AUTH
  );
}
