import cn from 'classnames';
import React from 'react';
import { Radio } from '@alfalab/core-components/radio';
import { Typography } from '@alfalab/core-components/typography';

import styles from './ConfigChoiceButton.module.css';

interface Props {
  title: string;
  subtitle?: React.ReactNode;
  Content?: React.FC<any>;
  contentClassname?: string;
  containerClassname?: string;
  containerActiveClassname?: string;
  isActive: boolean;
  onClick: () => void;
}

export const ConfigChoiceButton = ({
  title,
  subtitle,
  Content,
  isActive,
  onClick,
  contentClassname,
  containerClassname,
  containerActiveClassname,
}: Props) => {
  return (
    <div
      className={cn(
        styles.chooseButtonContainer,
        isActive && styles.isActive,
        isActive && containerActiveClassname,
        containerClassname
      )}
      onClick={onClick}
    >
      <Radio
        onChange={() => {}}
        checked={isActive}
        hint={
          subtitle ? (
            <Typography.Text view="secondary-small" color="secondary">
              {subtitle}
            </Typography.Text>
          ) : null
        }
        label={
          <Typography.Text view="secondary-large">{title}</Typography.Text>
        }
      />
      {Content && (
        <Content className={cn(styles.chooseButtonIcon, contentClassname)} />
      )}
    </div>
  );
};
