import { ECurrencyId } from '../../types/currencyId';

/**
 * @returns Прибыль(дн) в процентах (ПУ(дн)%)
 * */
export function getDailyPLPrecentToMarketCurPrice(
  BaseDailyPLtoMarketCurPrice: number | null,
  DailyPLtoMarketCurPrice: number | null,
  idObject?: number
): number | null {
  if (idObject === ECurrencyId.RUR) {
    return 0;
  }

  if (
    BaseDailyPLtoMarketCurPrice === null ||
    BaseDailyPLtoMarketCurPrice === null ||
    DailyPLtoMarketCurPrice === null
  ) {
    return null;
  }

  return BaseDailyPLtoMarketCurPrice === 0
    ? BaseDailyPLtoMarketCurPrice
    : (100 * DailyPLtoMarketCurPrice) / BaseDailyPLtoMarketCurPrice;
}
