import {
  useAlfaDirectContext,
  useWarranty as useWarrantyBase,
  WarrantyOptions as BaseOptions,
} from '@terminal/alfadirect/hooks';
import { AccountItem } from '@terminal/core/types/account';
import { InstrumentItem } from '@terminal/core/types/core';

import { useWidgetContext } from '../context';

type Options = Omit<
  BaseOptions,
  | 'isin'
  | 'idObjectGroup'
  | 'universalMarketCode'
  | 'isSingleFORTSMarket'
  | 'marginParamsFetcher'
> & {
  idAccount?: AccountItem['idAccount'];
};

export function useWarranty(
  idFi: InstrumentItem['idFI'],
  price: number,
  { idAccount = NaN, ...options }: Options = {}
) {
  const { marginParamsFetcher } = useWidgetContext();
  const {
    finInstrumentsTable,
    objectsTable,
    marketBoardsTable,
    objectTypesTable,
    accounts,
  } = useAlfaDirectContext();

  const { idObject = NaN, idMarketBoard = NaN } =
    finInstrumentsTable.get('idFI', idFi) || {};

  const marketBoard = marketBoardsTable.get('idMarketBoard', idMarketBoard);
  const object = objectsTable.get('idObject', idObject);
  const idObjectType = object?.idObjectType?.value ?? NaN;
  const objectType = objectTypesTable.get('idObjectType', idObjectType);

  const isSingleFORTSMarket = accounts.some(
    (account) => account.idAccount === idAccount && account.isSingleFORTSMarket
  );

  return useWarrantyBase(idAccount, price, {
    universalMarketCode: marketBoard?.universalMarketCode,
    isin: object?.isin,
    idObjectGroup: objectType?.idObjectGroup,
    isSingleFORTSMarket,
    marginParamsFetcher,
    ...options,
  });
}
