/**
 * @returns изменение цены за день в %
 * Изменение цены за день в % = (Текущая цена - Входящая цена) / Входящая цена) * 100
 * @param price текущая цена
 * @param prevPrice входящая цена
 * @see https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=503369004
 * */

export function getDailyPriceChange(price, prevPrice) {
  return ((price - prevPrice) / (prevPrice || 1)) * 100;
}
