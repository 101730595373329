import { useCallback } from 'react';

import {
  ConfigExperience,
  ConfigStyle,
  useOnboardingConfigStore,
} from '@terminal/desktop/entities/OnboardingConfig';

import { ConfigChoiceButton } from '../ConfigChoiceButton';
import { InfoHeading } from '../InfoHeading';

import styles from './OnboardingExperience.module.css';

export const OnboardingExperience = () => {
  const [configExperience, setConfigExperience, setConfigStyle] =
    useOnboardingConfigStore((store) => [
      store.configExperience,
      store.setConfigExperience,
      store.setConfigStyle,
    ]);

  const handleClick = useCallback(
    (experience: ConfigExperience) => {
      if (experience === ConfigExperience.Beginner) {
        setConfigStyle(ConfigStyle.BEGINNER);
      } else {
        setConfigStyle(ConfigStyle.SWING);
      }

      setConfigExperience(experience);
    },
    [setConfigExperience, setConfigStyle]
  );

  return (
    <>
      <InfoHeading
        title={
          <>
            Какой у вас опыт в инвестициях
            <br />и торговле?
          </>
        }
      />

      <div className={styles.actions}>
        <ConfigChoiceButton
          title="Я начинающий"
          subtitle="Вы торгуете недавно и только разбираетесь"
          isActive={configExperience === ConfigExperience.Beginner}
          onClick={() => handleClick(ConfigExperience.Beginner)}
        />
        <ConfigChoiceButton
          title="У меня есть опыт"
          subtitle="Вы торгуете давно и точно знаете, что вам нужно"
          isActive={configExperience === ConfigExperience.Advanced}
          onClick={() => handleClick(ConfigExperience.Advanced)}
        />
      </div>
    </>
  );
};
