import setProp from 'lodash/set';
import { GetState } from 'zustand';

import { RazdelGroupType } from '../../lib/client/entities';
import { storage, uiSettings } from '../../lib/services/storage';
import { setDirectionColors } from '../../lib/setDirectionColors';
import { RootStore, StoreSet } from '../useStore';

import {
  CommonSettings,
  HeaderMenuItem,
  OrderDimensionEnum,
  SettingType,
} from '../../types/ui';
import { WatchlistIds } from '../../types/watchList';

// убираем импорт из ЛК
// import { MainMenuType } from '../../../../../packages/lk/src/legacy/main/types';
export enum MainMenuType {
  Accounts = 'accounts',
  Documents = 'documents',
  Certificates = 'certificates',
  QualTesting = 'testing',
  CorporateActions = 'corporate-actions',
  FreeOrders = 'free-orders',
  Notifications = 'notifications',
  CodesTable = 'codes-table',
}

interface MarginCallAccount {
  code: string;
  idRazdelGroup: RazdelGroupType;
  placeCode: string;
}

export interface LkSubMenuValue {
  id: MainMenuType | null;
  options?: unknown;
}

export interface HeaderMenuParams {
  defaultSelectedAccountId?: number;
  topUpSum?: number;
}

export interface UiSlice {
  settings: CommonSettings;
  initSettings: (settings: CommonSettings) => void;
  setPwaGlobalSubAccount: (id: number, code: string) => void;
  pwaGlobalSubAccount?: { id: number; code: string };
  setSetting: (type: SettingType, key: string, value: any) => void;
  selectedListId: string;
  setSelectedListId: (id: string) => void;
  headerMenu?: HeaderMenuItem | null;
  headerMenuParams?: HeaderMenuParams | null;
  setHeaderMenuOpen: (
    menuId: HeaderMenuItem | null,
    params?: HeaderMenuParams
  ) => void;
  /** Массив меню у которых нужно показывать badge */
  headerMenuBadge: HeaderMenuItem[];
  setHeaderMenuBadge: (item: HeaderMenuItem) => void;
  removeHeaderMenuBadge: (item: HeaderMenuItem) => void;
  marginCallAccount?: MarginCallAccount | null;
  setMarginCallAccount: (account: MarginCallAccount | null) => void;
  lkSubMenuId: LkSubMenuValue;
  setLkSubMenuId: (lkSubMenuValue: LkSubMenuValue) => void;
}

export const initialSettings: CommonSettings = {
  design: {
    directionColors: 'greenAndRed',
  },
  notifications: {
    isShow: true,
    isShowTradeNotifications: true,
    isShowSystemNotifications: true,
    isShowBrokerNotifications: true,
    isShowNewMessages: true,
    maxCount: 7,
    time: 5000,
  },
  defaultValues: {
    alwaysConfirmOrders: true,
    orderDimension: OrderDimensionEnum.PIECE,
    selectedDisplayInstrumentType: 'name',
    selectedPLMode: 'money',
    quantityMap: {},
  },
};

const initSettings = () => {
  let settingsObject = { ...initialSettings };

  setDirectionColors(settingsObject.design.directionColors);

  return settingsObject as CommonSettings;
};

export const createUiSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): UiSlice => ({
  /**
   * Используется для установки выбранного субсчета в UI компонентах
   * @param idSubAccount - идентификатор субсчета
   * @param codeSubAccount - строковое представление субсчета
   */
  setPwaGlobalSubAccount: (idSubAccount, codeSubAccount: string) =>
    set((state) => {
      state.pwaGlobalSubAccount = { id: idSubAccount, code: codeSubAccount };
      uiSettings.lastSelectedSubAccount = {
        subAccountCode: codeSubAccount,
        subAccountId: idSubAccount,
      };
    }),
  settings: initSettings(),
  initSettings: (settings) => {
    set((state) => {
      state.settings = settings;
    });

    setDirectionColors(settings.design.directionColors);
  },
  setSetting: (type: SettingType, key: string, value: any) => {
    if (type === 'design' && key === 'directionColors') {
      setDirectionColors(value);
    }

    set((state) => {
      setProp(state.settings, `${type}.${key}`, value);
    });
  },
  selectedListId:
    storage.uiSettings.selectedWatchlistId || WatchlistIds.iMOEXList,
  setSelectedListId: (id) => {
    set((state) => {
      state.selectedListId = id;
      storage.uiSettings.selectedWatchlistId = id;
    });
  },
  setHeaderMenuOpen: (menuId, params?: HeaderMenuParams) => {
    set((state) => {
      state.headerMenu = menuId;

      state.headerMenuParams = menuId ? params || null : null;
    });
  },
  headerMenuBadge: [],
  setHeaderMenuBadge: (item) => {
    set((state) => {
      state.headerMenuBadge = [...state.headerMenuBadge, item];
    });
  },
  removeHeaderMenuBadge: (item) => {
    set((state) => {
      state.headerMenuBadge = state.headerMenuBadge.filter(
        (menu) => menu !== item
      );
    });
  },
  setMarginCallAccount: (account) => {
    set((state) => {
      state.marginCallAccount = account;
    });
  },
  lkSubMenuId: {
    id: null,
  },
  setLkSubMenuId: (lkSubMenuValue: LkSubMenuValue) => {
    set((state) => {
      state.lkSubMenuId = lkSubMenuValue;
    });
  },
});
