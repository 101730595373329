import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { SendSelfTransferOperationConfirmDTO } from './types/SendSelfTransferOperationConfirmDTO';
import { SendSelfTransferOperationConfirmParams } from './types/SendSelfTransferOperationConfirmParams';

export const sendSelfTransferOperationConfirm = async (
  data: SendSelfTransferOperationConfirmParams
): Promise<SendSelfTransferOperationConfirmDTO['reference']> => {
  const url = qs.stringifyUrl({
    url: '/api/self-transfer/operation',
    query: { ...data },
  });

  const res = await fetchLkResult<
    SendSelfTransferOperationConfirmDTO | LKResult
  >(url, 'PUT');

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res.reference;
};
