import cn from 'classnames';
import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';

import { SymbolCell } from '@terminal/common/features-DEPRECATED/Table';
import { useStore } from '@terminal/core/store';
import { currencyObjectByIdFi } from '@terminal/core/store/selectors/core/currencyObjectByIdFi';
import { marketBoardByIdFi } from '@terminal/core/store/selectors/core/marketBoardByIdFi';
import { objectByIdFiSelector } from '@terminal/core/store/selectors/core/objectByIdFiSelector';

import { ModalHeader } from '../../../legacy/components/ModalHeader';
import { PriceAmount } from '../../../shared/ui/PriceAmount';
import { Audio } from './Audio';
import { DismissButton } from './DismissButton';
import { Note } from './Note';
import { TableButton } from './TableButton';

import styles from './AlertNotifyModal.module.css';

export type AlertNotifyModalProps = Pick<BaseModalProps, 'open' | 'onClose'> & {
  /**
   * Идентификатор финансового инструмента
   * */
  idFi: number;
  /**
   * Целевая цена
   * */
  targetPrice: number;
  /**
   * Исходная цена
   * */
  initialPrice: number;
  /**
   * Обработчик нажатия на кнопку «В таблицу»
   * */
  onOpenListButtonClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Обработчик нажатия на кнопку «Снять»
   * */
  onDismissButtonClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Дополнительное информационное сообщение
   * */
  note?: ReactNode;
  /**
   * Если `true`, то проигрывается звуковой сигнал
   * @default false
   * */
  withSound?: boolean;
};

/**
 * Модальное окно с информацией о сработавшем алерте
 * */
export const AlertNotifyModal: FC<AlertNotifyModalProps> = (props) => {
  const {
    open,
    onClose,
    initialPrice,
    targetPrice,
    idFi,
    onOpenListButtonClick,
    onDismissButtonClick,
    children,
    note,
    withSound,
  } = props;
  const object = useStore(objectByIdFiSelector)(idFi);
  const currencyObject = useStore(currencyObjectByIdFi)(idFi);
  const marketBoard = useStore(marketBoardByIdFi)(idFi);
  const amountClassName = cn(styles.amount, {
    [styles.amount_color_red]: targetPrice > initialPrice,
    [styles.amount_color_green]: targetPrice <= initialPrice,
  });

  return (
    <BaseModal className={styles.root} open={open} disableBackdropClick>
      {withSound && <Audio />}
      <ModalHeader onClose={onClose} titleProps={{ view: 'primary-small' }}>
        Внимание!
      </ModalHeader>
      <div className={styles.info}>
        <SymbolCell
          idObject={object?.idObject}
          symbolObject={object?.symbolObject}
        />
        {marketBoard && (
          <span className={styles.market}>{marketBoard.nameMarketBoard}</span>
        )}
        <PriceAmount
          value={targetPrice}
          currency={currencyObject?.symbolObject}
          className={amountClassName}
        />
      </div>
      {children && <div className={styles.content}>{children}</div>}
      {note && <Note className={styles.note}>{note}</Note>}
      <div className={styles.buttons}>
        <TableButton onClick={onOpenListButtonClick} />
        <DismissButton onClick={onDismissButtonClick} />
      </div>
    </BaseModal>
  );
};
