import differenceInDays from 'date-fns/differenceInDays';

import { ObjectGroup } from '../client/entities';
import { PREV_LAST_DAYS_COMPARE } from './constants';

import { ECurrencyId } from '../../types/currencyId';

/**
 * @returns Текущую цену(Цена)
 * Формула описана тут - https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=503369004
 * */
export const getPrice = (
  PrevLastDate?: Date,
  PrevFairPriceDate?: Date,
  // TODO: отказаться от дефолтных значений
  // так как при их использовании получаем в результате 0,
  // когда данные не были получены и финальные расчеты отображаются не верно
  // до момента загрузки всех данных
  FairPrice: number | null = null,
  Last: number | null = null,
  PrevLast: number | null = null,
  idObject?: number,
  idObjectGroup?: number
): null | number => {
  // Для фьючей отдельный кейс
  if (idObjectGroup === ObjectGroup.Futures) {
    return Last !== null && Last !== 0 ? Last : PrevLast;
  }

  // Если рубль возвращаем единицу
  if (idObject === ECurrencyId.RUR) {
    return 1;
  }

  // Для всех остальных
  // Если есть Last не равный нулю возращаем его
  // Если Last=0, PrevLast<>0 и PrevLastDate не старше PrevFairPriceDate на 10 дней, то PrevLast
  // Во всех остальных случаях FairPrice
  if (Last) {
    return Last;
  } else if (
    PrevLast &&
    PrevLastDate &&
    PrevFairPriceDate &&
    Math.abs(differenceInDays(PrevLastDate, PrevFairPriceDate)) <
      PREV_LAST_DAYS_COMPARE
  ) {
    return PrevLast;
  } else {
    return FairPrice;
  }
};
