import { CIQ } from 'chartiq/js/componentUI';

import { featureFlags } from '@terminal/core/featureFlags';

import { WorkspaceConfigurationLS } from '../../store/slices/workspaceConfigurationsSlice';

import { AppState } from '../../types/app';
import { CommonSettings } from '../../types/ui';
import { WatchList } from '../../types/watchList';

export enum WindowsSyncCommitType {
  WORKSPACES = 'WORKSPACES',
  SETTINGS = 'SETTINGS',
  WATCHLISTS = 'WATCHLISTS',
}

export type WindowsSyncCommit =
  | {
      type: WindowsSyncCommitType.WORKSPACES;
      data: {
        workspaceConfigurations: WorkspaceConfigurationLS[];
        workspaceConfigurationActiveId: number;
      };
    }
  | {
      type: WindowsSyncCommitType.SETTINGS;
      data: CommonSettings;
    }
  | {
      type: WindowsSyncCommitType.WATCHLISTS;
      data: WatchList[];
    };

export type ChartsSyncCommit = {
  nodeId?: string;
  periodicity?: CIQ.ChartEngine.PeriodicityParameters;
  drawings?: any[];
  layout?: object;
};

export class WindowsSync {
  static isWaitingForSync = false;
  /** Канал для обмена настройками WindowsSyncCommit */
  static broadcast: BroadcastChannel | undefined;
  /** Канал для обмена настройками графиков ChartsSyncCommit,
   *  Нужен отдельный поток для синхроннного обновления графиков в мультиоконности */
  static broadcastCharts: BroadcastChannel | undefined;

  /** Делаем коммит если это новые изменения
   * Если это синхронизация с другой вкладкой - не делаем коммит
   * */
  static commit(
    appState: AppState,
    data: WindowsSyncCommit,
    isSynchronizationEnabled = featureFlags.SYNCHRONIZATION_ENABLED
  ) {
    if (appState !== AppState.READY || !isSynchronizationEnabled) {
      return;
    }

    if (!WindowsSync.isWaitingForSync) {
      WindowsSync.broadcast?.postMessage(data);
    } else {
      setTimeout(() => {
        WindowsSync.isWaitingForSync = false;
      }, 0);
    }
  }

  static eventListener(event: MessageEvent<WindowsSyncCommit>) {
    WindowsSync.isWaitingForSync = true;
    // @ts-expect-error
    // TODO выпилить использование стора из сервиса
    const store = window.__zsstore as unknown;
    switch (event.data.type) {
      case WindowsSyncCommitType.WORKSPACES: {
        // @ts-expect-error
        store.getState().workspaceConfigurationsInit(event.data.data);
        break;
      }
      case WindowsSyncCommitType.SETTINGS: {
        // @ts-expect-error
        store.getState().initSettings(event.data.data);
        break;
      }
      case WindowsSyncCommitType.WATCHLISTS: {
        // @ts-expect-error
        store.getState().initWatchLists(event.data.data);
        break;
      }
    }
  }
}

try {
  WindowsSync.broadcast = new BroadcastChannel('WindowsSync');
  WindowsSync.broadcastCharts = new BroadcastChannel('broadcastCharts');
  WindowsSync.broadcast.onmessage = WindowsSync.eventListener;
} catch (e) {
  console.error('BroadcastChannel is not supported!');
}
