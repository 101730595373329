export interface MarginCall {
  initialMargin: number;
  minimumMargin: number;
  liquedBalance: number;
  requirements: number;
  immediateRequirements: number;
}

export interface MarginCallInfo {
  [codeSubAccount: string]: MarginCall & {
    marketsMarginCall: { [idRazdelGroup: string]: MarginCall };
  };
}

export type RequirementsType =
  | 'requirements'
  | 'immediateRequirements'
  | undefined;

export function getRequirementsType(data: MarginCall): RequirementsType {
  if (data.immediateRequirements !== 0) {
    return 'immediateRequirements';
  }

  if (data.requirements !== 0) {
    return 'requirements';
  }

  return undefined;
}
