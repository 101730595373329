import cn from 'classnames';
import { Typography } from '@alfalab/core-components/typography';
import { ChartPieMIcon } from '@alfalab/icons-glyph/ChartPieMIcon';

import { isThemePassed } from '../../lib/isThemePassed';

import styles from './ThemeRecommendations.module.css';

interface ThemeRecommendationsProps {
  percent: number;
  recommendations: string | null;
  className?: string;
}

export const ThemeRecommendations = ({
  percent,
  recommendations,
  className,
}: ThemeRecommendationsProps) => {
  const pass = isThemePassed(percent);

  return (
    <div className={cn(styles.container, className)}>
      <Typography.Text
        className={styles.status}
        tag="div"
        view="secondary-small"
        weight="bold"
        color={pass ? 'positive' : 'negative'}
      >
        <ChartPieMIcon className={styles.statusIcon} width={16} height={16} />
        Правильных ответов: {percent}%
      </Typography.Text>
      <div className={styles.content}>
        <Typography.Text
          className={styles.title}
          view="secondary-small"
          weight="bold"
          tag="p"
        >
          Рекомендации
        </Typography.Text>
        {!recommendations && (
          <Typography.Text view="secondary-small">
            С этой темой у вас всё прекрасно. Рекомендуем перейти к следующей.
          </Typography.Text>
        )}
        {recommendations && (
          <Typography.Text view="secondary-small">
            {!pass && (
              <>
                Повторите тему, изучите дополнительные материалы. Сохраняйте
                спокойствие.{' '}
              </>
            )}
            Обратите внимание на следующие вопросы:
            <ul className={styles.list}>
              {recommendations.split(';').map((text, index) => (
                <li key={`recomend-${index}`}>{text}</li>
              ))}
            </ul>
          </Typography.Text>
        )}
      </div>
    </div>
  );
};
