import { localStorage, sessionStorage } from '../../storages';

const KEY_ONBOARDING_DONE = 'onboarding-done';
const KEY_POSTPONE_SUGGEST_PWA = 'postpone-suggest-pwa';
const KEY_HIDE_SUGGEST_PWA = 'hideSuggestAddStandaloneApp';

interface HideInfo {
  value: boolean;
  count: number;
}

const HIDE_FOREVER_FALSE: HideInfo = {
  value: false,
  count: 0,
};

export class Modal {
  // onboarding
  get onboardingDone(): boolean {
    return localStorage.getItem(KEY_ONBOARDING_DONE, false);
  }
  set onboardingDone(value: boolean) {
    localStorage.setItem(KEY_ONBOARDING_DONE, value);
  }
  // pwa
  get postponeSuggestPWA(): boolean {
    return localStorage.getItem(KEY_POSTPONE_SUGGEST_PWA, false);
  }
  set postponeSuggestPWA(value: boolean) {
    localStorage.setItem(KEY_POSTPONE_SUGGEST_PWA, value || undefined);
  }
  private get suggestPWAState(): HideInfo {
    return localStorage.getItem(KEY_HIDE_SUGGEST_PWA, HIDE_FOREVER_FALSE);
  }
  private set suggestPWAState(value: HideInfo | undefined) {
    localStorage.setItem(KEY_HIDE_SUGGEST_PWA, value);
  }
  get suggestPWAHidden(): boolean {
    return sessionStorage.getItem(KEY_HIDE_SUGGEST_PWA, false);
  }
  set suggestPWAHidden(value: boolean) {
    sessionStorage.setItem(KEY_HIDE_SUGGEST_PWA, value);
  }
  get suggestPWAHiddenForever(): boolean {
    return this.suggestPWAState.value;
  }
  set suggestPWAHiddenForever(value: boolean) {
    this.suggestPWAState = {
      ...this.suggestPWAState,
      value,
    };
  }
  get cancelPWATimes(): number {
    return this.suggestPWAState.count;
  }
  set cancelPWATimes(count: number) {
    this.suggestPWAState = {
      ...this.suggestPWAState,
      count,
    } as HideInfo;
  }
}

export const modal = new Modal();
