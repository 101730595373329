import cn from 'classnames';
import React, { FC } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop/Component.desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CurrencyCodes } from '@alfalab/data';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { Stepper } from '@terminal/common/components/Stepper';
import { MINORITY } from '@terminal/core/constants/ui';
import { OrderType } from '@terminal/core/lib/client/entities';
import { roundPrice } from '@terminal/core/lib/format';
import { plural } from '@terminal/core/lib/plural';
import { OrderSide } from '@terminal/core/types/trading';

import { AdditionalSlippageSettings } from '../AdditionalSlippageSettings';

import { useSlippageQuickControls } from '../../hooks/useSlippageQuickControls';

import styles from './SlippageInput.module.css';

interface Props {
  isChart: boolean;
  side: OrderSide;
  orderType: OrderType;
  slippage: number;
  setSlippage: (value: number) => void;
  price: number;
  symbol?: CurrencyCodes;
  priceStep: number;
  containerClassName?: string;
}

export const SlippageInput: FC<Props> = ({
  isChart,
  side,
  orderType,
  slippage,
  setSlippage,
  price,
  symbol,
  priceStep,
  containerClassName,
}) => {
  const {
    slippageAdditionalPercent,
    setSlippageAdditionalPercent,
    clearVolumeAdditional,
    adSuffix,
  } = useSlippageQuickControls({
    side,
    orderType,
    priceStep,
    price,
    setSlippage,
  });

  const handleAdd = () => {
    setSlippage(roundPrice(slippage + priceStep, priceStep));
    clearVolumeAdditional();
  };

  const handleSub = () => {
    setSlippage(Math.max(0, roundPrice(slippage - priceStep, priceStep)));
    clearVolumeAdditional();
  };

  return (
    <div className={containerClassName}>
      <Typography.Text
        view="secondary-small"
        color="secondary"
        tag="div"
        className={cn(styles.label, styles.graphic)}
      >
        Проскок&nbsp;
        <Tooltip
          targetClassName={styles.tooltipTarget}
          contentClassName={styles.tooltipContent}
          content={
            <>
              <Typography.Text
                weight="bold"
                view="secondary-small"
                className={styles.graphic}
              >
                Проскок
              </Typography.Text>
              <Typography.Text
                view="secondary-small"
                className={styles.graphic}
              >
                Величина сдвига цены лимитной заявки от цены активации. Задается
                в тиках – минимальных шагах цены
              </Typography.Text>
            </>
          }
          trigger="hover"
          position="top-start"
          offset={[-20, 16]}
        >
          <InformationCircleLineSIcon
            height={10}
            width={10}
            color="var(--color-dark-graphic-secondary)"
          />
        </Tooltip>
      </Typography.Text>

      <AmountInput
        className={styles.customInput}
        fieldClassName={styles.customInputField}
        block
        positiveOnly={false}
        suffix={`${plural(
          ['тик', 'тика', 'тиков'],
          Math.round(slippage / priceStep)
        )} ${adSuffix}`}
        placeholder="0 тиков"
        value={Math.round(slippage / priceStep)}
        minority={1}
        integersOnly
        onChange={(_, { value }) => {
          setSlippage(roundPrice((value || 0) * priceStep, priceStep));
        }}
        onFocus={() => setSlippageAdditionalPercent(null)}
        rightAddons={
          <div className={styles.priceAddons}>
            {!isChart && (
              <Stepper size="s" onAdd={handleAdd} onSub={handleSub} />
            )}
            <AdditionalSlippageSettings
              slippageAdditionalPercent={slippageAdditionalPercent}
              setSlippageAdditionalPercent={setSlippageAdditionalPercent}
            />
          </div>
        }
        dataTestId="priceInput"
      />

      {slippage ? (
        <Typography.Text
          view="secondary-small"
          color="attention"
          tag="div"
          className={styles.graphic}
        >
          Не {side === OrderSide.BUY ? 'более' : 'менее'}&nbsp;
          <Amount.Pure
            value={
              (price + (side === OrderSide.BUY ? 1 : -1) * slippage) * MINORITY
            }
            minority={MINORITY}
            view="withZeroMinorPart"
            currency={symbol}
          />
        </Typography.Text>
      ) : null}
    </div>
  );
};
