import { WatchList, WatchListType } from '../types/watchList';

export const defaultListId = 'default';
export const defaultWatchList: WatchList = {
  type: WatchListType.DEFAULT,
  name: 'Мой список',
  id: defaultListId,
  instruments: [
    {
      idFI: 144950,
      idObject: 285270,
      symbolObject: 'SBER',
      codeMarketBoard: 'TQxx',
    },
    {
      idFI: 146986,
      idObject: 174422,
      symbolObject: 'GAZP',
      codeMarketBoard: 'TQxx',
    },
    {
      idFI: 144942,
      idObject: 174425,
      symbolObject: 'LKOH',
      codeMarketBoard: 'TQxx',
    },
    {
      idFI: 146989,
      idObject: 174434,
      symbolObject: 'ROSN',
      codeMarketBoard: 'TQxx',
    },
    {
      idFI: 145164,
      idObject: 385612,
      symbolObject: 'MOEX',
      codeMarketBoard: 'TQxx',
    },
    {
      idFI: 162016,
      idObject: 400406,
      symbolObject: 'YNDX',
      codeMarketBoard: 'TQxx',
    },
    {
      idFI: 149262,
      idObject: 174366,
      symbolObject: 'USD',
      codeMarketBoard: 'CETS_TOM',
    },
    {
      idFI: 149270,
      idObject: 174367,
      symbolObject: 'EUR',
      codeMarketBoard: 'CETS_TOM',
    },
  ],
};

export const alfaWatchLists: WatchList[] = [
  {
    type: WatchListType.AFLA,
    name: 'Акции роста',
    id: 'alfa',
    instruments: [
      {
        idFI: 218291,
        idObject: 414106,
        symbolObject: 'MSFT',
        codeMarketBoard: 'SPFPA',
      },
    ],
  },
  {
    type: WatchListType.AFLA,
    name: 'Акции РФ: топ 50',
    id: 'alfa-shares-top-50',
    instruments: [
      {
        idFI: 146986,
        idObject: 174422,
        symbolObject: 'GAZP',
        codeMarketBoard: 'TQxx',
      },
    ],
  },
  {
    type: WatchListType.AFLA,
    name: 'Облигации РФ: топ 50',
    id: 'alfa-bonds-top-50',
    instruments: [
      {
        idFI: 144950,
        idObject: 285270,
        symbolObject: 'SBER',
        codeMarketBoard: 'TQxx',
      },
    ],
  },
  {
    type: WatchListType.AFLA,
    name: 'Акции США: топ 50',
    id: 'alfa-usa-top-50',
    instruments: [
      {
        idFI: 149259,
        idObject: 174366,
        symbolObject: 'USD',
        codeMarketBoard: 'CETS_TOD',
      },
    ],
  },
];
