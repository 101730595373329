import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { BlueprintTable, CellRenderProps } from '@terminal/common/ui/Table';
import { DEFAULT_TRADE_FEED_TIMER } from '@terminal/core/constants/tradesFeed';
import { SubscribeReturnData } from '@terminal/core/lib/services/streaming';
import { TradesService } from '@terminal/core/lib/services/trades';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import {
  defaultColumnFilterRender,
  useWidgetContext,
} from '../../../../shared';
import { TradesTableRow } from '../../model/tradesTableRow';
import { TradesTableCell, TradeTableCellProps } from '../TradesTableCell';
import { calculateDirection } from '../TradesTableCell/lib/calculateDirection';

import { useAllTrades } from '../../hooks/useAllTrades';
import { useTradesFeedContext } from '../../hooks/useTradesFeedContext';

export function TradesTable(): JSX.Element {
  const { getTradesFeedSettings } = useWidgetContext();
  const { TradesService } = useAlfaDirectContext();

  const tradesFeedsSettings = getTradesFeedSettings();

  const {
    columnsSetting,
    saveColumnSetting,
    idFi,
    nodeId,
    timer,
    timerActive,
    highlight,
  } = useTradesFeedContext();

  const { highlightActive, highlightFrom } = highlight?.[String(idFi)] || {};
  const tradesService = useRef<TradesService>();
  const tradesFeedSettings = tradesFeedsSettings?.[idFi!];

  const currentFilterVolume = useMemo(() => {
    const tradesFeedSettingsByNodeId = tradesFeedSettings?.find(
      (item) => item.nodeId === nodeId
    );

    return tradesFeedSettingsByNodeId?.volume;
  }, [nodeId, tradesFeedSettings]);

  const [allTrades, addTrades, resetTrades] = useAllTrades(currentFilterVolume);

  useEffect(() => {
    addTrades([]);

    if (!tradesService.current) {
      tradesService.current = new TradesService();
    }

    const tradesServiceCurrent = tradesService.current;

    const subscribeReturnData = tradesServiceCurrent?.subscribeToFI(
      idFi!,
      (data) => {
        if (data.length) {
          addTrades(data);
        }
      },
      500
    );

    return () => {
      tradesServiceCurrent?.unsubscribe(
        idFi!,
        subscribeReturnData as SubscribeReturnData<{}>
      );
    };
  }, [idFi, addTrades, TradesService]);

  useEffect(() => {
    if (!timerActive) {
      return;
    }

    const timerId = setInterval(
      () => resetTrades(),
      (timer || DEFAULT_TRADE_FEED_TIMER) * 1000
    );

    return () => clearInterval(timerId);
  }, [timer, timerActive, resetTrades]);

  // Каждый раз когда меняется инструмент, очищаем ленту сделок
  useEffect(() => {
    resetTrades();
  }, [idFi, resetTrades]);

  const normalizedTrades: TradesTableRow[] = allTrades.map<TradesTableRow>(
    (trade) => {
      const normalizedTrade: TradesTableRow = {
        ...trade,
        Qty: trade.Qty.toString(),
        TradeTime: trade.TradeTime.toLocaleTimeString(),
      };

      if (
        highlightActive &&
        highlightFrom &&
        Number(trade.Qty) >= highlightFrom
      ) {
        return {
          ...normalizedTrade,
          highlight: true,
        };
      }

      return normalizedTrade;
    }
  );

  const cellRender: CellRenderProps<TradesTableRow> = useCallback(
    (rowIndex, _, columnKey, data) => {
      const dataRow = data[rowIndex];
      const currentPrice = dataRow.Price;
      const prevPrice = data[rowIndex + 1]?.Price || currentPrice;

      const isSelectedPriceColumn =
        columnKey === TableColumnKey.TradePrice &&
        !columnsSetting.find(
          ({ key, selected }) => key === TableColumnKey.TradeBuySell && selected
        );
      const showDirection =
        isSelectedPriceColumn || columnKey === TableColumnKey.TradeBuySell;

      return (
        <TradesTableCell
          isBuying={dataRow.BuySell === 1}
          highlighted={Boolean(dataRow.highlight)}
          value={dataRow[columnKey]}
          columnKey={columnKey as TradeTableCellProps['columnKey']}
          {...(showDirection && {
            direction: calculateDirection(currentPrice, prevPrice),
          })}
        />
      );
    },
    [columnsSetting]
  );

  return (
    <BlueprintTable
      data={normalizedTrades}
      columnSetting={columnsSetting}
      cellRender={cellRender}
      columnFilterRender={defaultColumnFilterRender}
      onReorder={saveColumnSetting}
      onChangeWidth={saveColumnSetting}
      isBorderedRows
    />
  );
}
