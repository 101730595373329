import { smsSignOperation } from '@terminal/core/lib/rest/lkSmsSignOperation';
import { makeGetParams } from '@terminal/core/lib/url';

export async function addGoInvestAccountRegister(operationId: string) {
  const request = makeGetParams({ operationId });

  return await smsSignOperation(
    `/api/client/accounts/register-account?${request}`,
    'POST'
  );
}
