import { isAfter, isToday, isValid, parse } from 'date-fns';

import { CreateAttorneyFormValues, IdentityDocumentType } from '../../model';

type Errors = Partial<Record<keyof CreateAttorneyFormValues, string | boolean>>;

export const validateIdentityDocumentNumber = (
  type: CreateAttorneyFormValues['identityDocumentType'],
  value: CreateAttorneyFormValues['identityDocumentNumber']
) => {
  switch (Number(type)) {
    case IdentityDocumentType.RussianPassport:
      if (/^\d{4}\s\d{6}$/.test(value)) {
        return undefined;
      }

      break;
    case IdentityDocumentType.ForeignPassport:
      if (value.length > 4) {
        return undefined;
      }

      break;
    case IdentityDocumentType.DiplomaticPassport:
      if (value.length > 4) {
        return undefined;
      }

      break;
    case IdentityDocumentType.USSRPassport:
      if (/^[A-ZА-Я]+[А-Я]{2}\s\d{6}$/.test(value)) {
        return undefined;
      }

      break;
    case IdentityDocumentType.InternationalPassport:
      if (/^\d{2}\s\d{7}$/.test(value)) {
        return undefined;
      }

      break;
    default:
      return 'Неизвестный тип документа';
  }

  return 'Введите серию и номер документа';
};

const validateEndDate = (dateStr: CreateAttorneyFormValues['endDate']) => {
  const today = new Date();
  const dateFormat = 'dd.MM.yyyy';
  const date = parse(dateStr, dateFormat, new Date());

  if (!dateStr) {
    return 'Укажите дату';
  } else if (!isValid(date)) {
    return 'Срок действия задан неверно';
  } else if (isToday(date) || isAfter(today, date)) {
    return 'Дата окончания должна быть больше текущей';
  }
};

export const validate = (values: CreateAttorneyFormValues): Errors => {
  let errors: Errors = {};

  if (!values.fullName) {
    errors.fullName = 'Укажите Ф.И.О.';
  } else if (!/^.+\s.+$/.test(values.fullName)) {
    errors.fullName = 'Заполните полное имя';
  }

  if (!values.login) {
    errors.login = 'Укажите логин';
  } else if (!/^[a-zA-Z0-9_-]+$/.test(values.login)) {
    errors.login = 'Заполните логин корректно';
  }

  if (!values.identityDocumentType) {
    errors.identityDocumentType = 'Укажите документ';
  }

  const identityDocumentNumberError = validateIdentityDocumentNumber(
    values.identityDocumentType,
    values.identityDocumentNumber
  );

  if (identityDocumentNumberError) {
    errors.identityDocumentNumber = identityDocumentNumberError;
  }

  if (!values.accessLevel) {
    errors.accessLevel = 'Укажите уровень доступа';
  }

  const endDateError = validateEndDate(values.endDate);

  if (endDateError) {
    errors.endDate = endDateError;
  }

  return errors;
};
