import { ObjectGroup } from '../../lib/client/entities';
import { getCurrencyFi } from '../../lib/formulas/getCurrencyFi';
import { getPrice } from './getPrice';

import { FinInfoExtRecord } from '../../lib/services/finInfoExt/types';
import { ECurrencyId } from '../../types/currencyId';
import { FinInfo } from '../../types/quotes';

/**
 * @returns Текущую цену (Цена) для валют
 * Является оберткой над @method getPrice
 * Формула описана тут - https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=503369004
 * */
export function getCurrencyPrice(
  currenciesQuotes: Record<number, Partial<FinInfo>>,
  currenciesFinInfoExts: FinInfoExtRecord,
  idObjectCurrency?: number
): number {
  if (!idObjectCurrency || idObjectCurrency === ECurrencyId.RUR) {
    return 1;
  }

  const currencyIdFi = getCurrencyFi(idObjectCurrency);
  const {
    prevLastDate,
    prevFairPriceDate,
    fairPrice = 0,
    prevLast = 0,
  } = currenciesFinInfoExts[currencyIdFi] ?? {};

  return getPrice(
    prevLastDate,
    prevFairPriceDate,
    fairPrice,
    currenciesQuotes[currencyIdFi]?.last,
    prevLast,
    idObjectCurrency,
    ObjectGroup.Currency
  )!;
}
