import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getQuestion } from '@terminal/core/lib/rest/lkTesting';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../queryKeys';

export const useGetQuestion = (
  testId: number,
  questionNumber: number,
  sessionId: string | undefined,
  isTestAvailable: boolean
) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.TestingQuestion, sessionId],
    async () => getQuestion(testId, questionNumber, sessionId),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка начала сессии тестирования',
          text: error?.message || '',
        });
      },
      enabled: Boolean(sessionId) && questionNumber !== 0 && isTestAvailable,
    }
  );
};
