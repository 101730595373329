import { useEffect, VFC } from 'react';

import { useSoundManager } from '../../../../shared/lib/soundManager';

export const Audio: VFC = () => {
  const { play, stop } = useSoundManager();

  useEffect(() => {
    play('alert').then(() => null);

    return () => stop('alert');
  }, [play, stop]);

  return null;
};
