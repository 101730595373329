import isEmpty from 'lodash/isEmpty';

import {
  MIDDLE_API_INVEST_URI,
  MOBILE_CLUSTER_URI,
  USE_FETCH_PROXY,
  USE_PASSPORT_CERBERUS_AUTH,
} from '../env';
import { getAIMiddleAPI, getAMMiddleAPI } from './rest/getMiddleAPIURL';
import { parseMiddleUrl } from './rest/parseMIddleUrl';

// Превращает абсолютный URL в относительный ("http://example.com/imgs" -> "/imgs")
export const makeRelativeURL = (absoluteUrl: string) =>
  absoluteUrl.replace(/^.*\/\/[^/]+/, '');

/**
 * Функция преобразует урл ручки для работы с cerberus-web
 */
const normalizeCerberusUrl = (url: string) => {
  // костыль для публичных API
  if (url.includes('/invest-public/')) {
    return url;
  }

  url = url.replace('/api/lk/api/', '/api/lk/');
  url = url.replace('/api/alfadirect/api/', '/api/alfadirect/');

  if (url.includes('/')) {
    if (USE_PASSPORT_CERBERUS_AUTH) {
      const isMobileCluster = url.startsWith(MOBILE_CLUSTER_URI);
      const isInvestCluster = url.startsWith(MIDDLE_API_INVEST_URI);

      if (!isInvestCluster && !isMobileCluster) {
        return url;
      }

      // TODO перевести нэйминг ручек в проекте на новый формат
      // пока парсим текущий вариант для совместимости
      const { service, path, version } = parseMiddleUrl(url);

      return isMobileCluster
        ? getAMMiddleAPI(service, path, version)
        : getAIMiddleAPI(service, path, version);
    }
  }

  return url;
};

// Если приложение запущено на локалке, меняет URL чтобы не было проблем с CORS. Иначе оставляет без изменений
export const makeCORSSafeURL = (url: string) => {
  return USE_FETCH_PROXY ? makeRelativeURL(url) : normalizeCerberusUrl(url);
};

// Наша обёртка над fetch. Изменяет URL запроса если приложение на локалхосте, в остальном идентично обычному
export const fetchThroughProxy = (
  url: string,
  options: Record<string, any> = {}
) => fetch(makeCORSSafeURL(url), options);

// Преобразует объект в строку параметров GET запроса
/** @deprecated Используйте 'query-string' */
export const makeGetParams = (
  obj: any,
  arrayFormat: boolean = false
): string => {
  let request: string[] = [];

  for (let key in obj) {
    if (obj[key] === undefined) {
      continue;
    }

    if (Array.isArray(obj[key]) && arrayFormat) {
      if (!isEmpty(obj[key])) {
        const arrayParam = obj[key].map((value: any) => `${key}[]=${value}`);

        request.push(arrayParam.join('&'));
      }
    } else {
      request.push(key + '=' + encodeURIComponent(obj[key]));
    }
  }

  return request.join('&');
};

export const getParamsFromString = (search: string) => {
  const urlSearchParams = new URLSearchParams(search);

  return Object.fromEntries(urlSearchParams.entries());
};
