import { ECurrencyId } from '../../types/currencyId';

/**
 * @returns Прибыль/убыток по позиции с момента ее открытия
 * */
export function getNPLtoMarketCurPrice(
  TorgPosCost: number | null = null,
  TorgPosUchCost = 0,
  UchPrice = 0,
  idObject?: number
): number | null {
  if (idObject === ECurrencyId.RUR || UchPrice === 0) {
    return 0;
  }

  if (TorgPosCost === null) {
    return null;
  }

  return TorgPosCost - TorgPosUchCost;
}
