import React, { useEffect, useState } from 'react';
import {
  Tab,
  TabsDesktop,
  TabsDesktopProps,
} from '@alfalab/core-components/tabs/desktop';

import { LOCATIONS } from '@terminal/core/constants/analytics';
import { trackMainMenu } from '@terminal/core/lib/analytics';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { SelectTreaty } from '../components/SelectTreaty';
import { History } from './tabs/History';
import { Order } from './tabs/Order';
import { TaxInfo } from './tabs/TaxInfo';

import { DocumentsSubpage as Subpage } from './types';

import styles from './Documents.module.css';

export const Documents = React.memo(({ isMobile }: { isMobile?: boolean }) => {
  const [treaty, setTreaty] = useState<Treaty | null>(null);
  const [subpage, setSubpage] = useState<Subpage>(Subpage.TaxInfo);

  const handleChange: TabsDesktopProps['onChange'] = (_, { selectedId }) =>
    setSubpage(selectedId as Subpage);

  useEffect(() => {
    switch (subpage) {
      case Subpage.TaxInfo:
        trackMainMenu(LOCATIONS.LK_TAXES_INFO);
        break;
      case Subpage.Reports:
        trackMainMenu(LOCATIONS.LK_TAXES_REPORTS);
        break;
      case Subpage.Order:
        trackMainMenu(LOCATIONS.LK_TAXES_DOCS);
        break;
      case Subpage.History:
        trackMainMenu(LOCATIONS.LK_TAXES_HISTORY);
    }
  }, [subpage]);

  return (
    <div className={styles.container}>
      <div className={styles.treaty}>
        <SelectTreaty
          zeros={isMobile}
          hint="Все настройки будут применены к выбранному счету"
          onSelect={(treaty) => setTreaty(treaty)}
          mobile={isMobile}
          treatyFilter={(treaty) => !treaty.isMoneybox}
          label="Генеральное соглашение"
        />
      </div>
      <div className={styles.content}>
        <TabsDesktop
          selectedId={subpage}
          onChange={handleChange}
          scrollable={true}
          size="s"
        >
          <Tab
            title="Налоговая информация"
            id={Subpage.TaxInfo}
            className={styles.tab}
          >
            <TaxInfo treaty={treaty} isMobile={isMobile} />
          </Tab>
          <Tab
            title="Заказ документов"
            id={Subpage.Order}
            className={styles.tab}
          >
            <Order treaty={treaty} onSubpageChange={setSubpage} />
          </Tab>
          <Tab title="История" id={Subpage.History} className={styles.tab}>
            <History treaty={treaty} />
          </Tab>
        </TabsDesktop>
      </div>
    </div>
  );
});
