import cn from 'classnames';
import { Duration, format, sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { CalendarInputDesktop } from '@alfalab/core-components/calendar-input/desktop';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { InputDesktop } from '@alfalab/core-components/input/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import { SecuritiesHistoryFilter } from '@terminal/core/lib/rest/lkSecurities';

import {
  areDatesOverlapping,
  FilterButton,
  isDateValid,
  setFilterProp,
} from '../../../components/Filter';
import { getTransactionName } from '../../shared';
import { isFilterValid, STATUS_VARIANTS, TRANSACTION_TYPES } from './helpers';

import styles from './HistoryFilter.module.css';

export interface HistoryFilterProps {
  filter: SecuritiesHistoryFilter;
  onSetFilter: (filter: SecuritiesHistoryFilter) => void;
  onResetFilter: () => void;
  onClose: () => void;
}

export const HistoryFilter = ({
  filter,
  onSetFilter,
  onResetFilter,
  onClose,
}: HistoryFilterProps) => {
  const [localFilter, setLocalFilter] =
    useState<SecuritiesHistoryFilter>(filter);

  useEffect(() => {
    setLocalFilter(filter);
  }, [filter]);

  const setPeriod = (period: Duration) => {
    const endDate = new Date();
    const startDate = sub(endDate, period);

    setLocalFilter((prevFilter) => ({
      ...prevFilter,
      startDate: format(startDate, DEFAULT_VIEW_DATE_FORMAT),
      endDate: format(endDate, DEFAULT_VIEW_DATE_FORMAT),
    }));
  };

  const isPeriod = (period: Duration) => {
    const endDate = new Date();
    const startDate = sub(endDate, period);

    return (
      format(startDate, DEFAULT_VIEW_DATE_FORMAT) === localFilter.startDate &&
      format(endDate, DEFAULT_VIEW_DATE_FORMAT) === localFilter.endDate
    );
  };

  const setProp = (
    key: keyof SecuritiesHistoryFilter,
    value: string | number | undefined
  ) => {
    const newFilter = setFilterProp(localFilter, key, value, undefined, [
      'statuses',
    ]);

    setLocalFilter(newFilter);
  };

  const applyFilter = () => {
    onSetFilter(localFilter);
    onClose();
  };

  const resetFilter = () => {
    onResetFilter();
    onClose();
  };

  const datesOverlapping = areDatesOverlapping(
    localFilter.startDate,
    localFilter.endDate
  );

  const calendarFilterErrors = {
    startDate: !isDateValid(localFilter.startDate) || datesOverlapping,
    endDate: !isDateValid(localFilter.endDate) || datesOverlapping,
  };

  return (
    <div data-test-id="history-filter-body">
      <Typography.Text view="primary-small" weight="bold" tag="p">
        Фильтры
      </Typography.Text>

      <div className={styles.section}>
        <Typography.Text
          view="secondary-large"
          tag="p"
          color="secondary"
          weight="bold"
        >
          Период
        </Typography.Text>
        <div className={styles.types}>
          <FilterButton
            onClick={() => setPeriod({ weeks: 1 })}
            checked={isPeriod({ weeks: 1 })}
          >
            Неделя
          </FilterButton>
          <FilterButton
            onClick={() => setPeriod({ months: 1 })}
            checked={isPeriod({ months: 1 })}
          >
            Месяц
          </FilterButton>
        </div>
        <Row justify="left" gutter={16}>
          <Col width={5}>
            <CalendarInputDesktop
              label="Начало периода"
              labelView="outer"
              size="s"
              block
              value={localFilter.startDate}
              onChange={(e, { value }) => setProp('startDate', value)}
              error={calendarFilterErrors.startDate}
            />
          </Col>
          <Col width={5}>
            <CalendarInputDesktop
              label="Конец периода"
              labelView="outer"
              size="s"
              block
              value={localFilter.endDate}
              onChange={(e, { value }) => setProp('endDate', value)}
              error={calendarFilterErrors.endDate}
            />
          </Col>
        </Row>
      </div>

      <div className={styles.section}>
        <Typography.Text
          view="secondary-large"
          tag="p"
          color="secondary"
          weight="bold"
        >
          Наименование ценной бумаги
        </Typography.Text>
        <Row justify="left">
          <Col width={10}>
            <InputDesktop
              size="s"
              block
              value={localFilter.searchString}
              onChange={(e, { value }) => setProp('searchString', value)}
              placeholder="Все"
              hint="Наименование, номер гос. рег. или ISIN"
            />
          </Col>
        </Row>
      </div>

      <div className={styles.section}>
        <Typography.Text
          view="secondary-large"
          tag="p"
          color="secondary"
          weight="bold"
        >
          Тип операции
        </Typography.Text>
        <div className={styles.types}>
          <FilterButton
            checked={localFilter.tranType === undefined}
            onClick={() => setProp('tranType', undefined)}
          >
            Все
          </FilterButton>
          {TRANSACTION_TYPES.map((type) => (
            <FilterButton
              key={`filter-tranType-${type}`}
              checked={
                localFilter.tranType && localFilter.tranType.indexOf(type) >= 0
              }
              onClick={() => setProp('tranType', type)}
            >
              {getTransactionName(type)}
            </FilterButton>
          ))}
        </div>
      </div>

      <div className={styles.section}>
        <Typography.Text
          view="secondary-large"
          tag="p"
          color="secondary"
          weight="bold"
        >
          Статус поручения
        </Typography.Text>
        <div className={styles.types}>
          {STATUS_VARIANTS.map((status) => (
            <FilterButton
              key={`filter-status-${status.key}`}
              checked={Boolean(
                localFilter.statuses && localFilter.statuses === status.key
              )}
              onClick={() => setProp('statuses', status.key)}
            >
              {status.name}
            </FilterButton>
          ))}
        </div>
      </div>

      <div className={cn(styles.submit, styles.wide)}>
        <Row justify="left" gutter={16}>
          <Col width={6}>
            <ButtonDesktop
              size="s"
              block
              view="secondary"
              leftAddons={<CheckmarkMIcon width={18} height={18} />}
              onClick={applyFilter}
              disabled={!isFilterValid(localFilter)}
            >
              Применить
            </ButtonDesktop>
          </Col>
          <Col width={6}>
            <ButtonDesktop
              size="s"
              block
              view="tertiary"
              leftAddons={<CrossMIcon width={18} height={18} />}
              onClick={resetFilter}
            >
              Сбросить
            </ButtonDesktop>
          </Col>
        </Row>
      </div>
    </div>
  );
};
