import { SecurityPlaceCode } from '../../shared';
import { InfoButton } from './InfoButton';

// Пока нет текстов подсказок, добавлены заглушки
export const TooltipReason = () => null;

export const TooltipOwnerChange = () => (
  <InfoButton>
    Если вы переводите принадлежащие вам бумаги между своими счетами, выбирать
    смену прав собственности не требуется
  </InfoButton>
);

export const TooltipPositionAccCode = () => null;

export const TooltipPositionPlace = () => null;

export const TooltipConterpartyPlace = () => (
  <InfoButton>
    Как правило, рынок для нужной бумаги указан в вашем Портфеле у другого
    брокера или в депозитарной выписке.
  </InfoButton>
);

export const TooltipConteragent = () => (
  <InfoButton>
    Вы можете выбрать контрагента из списка или указать вручную, выбрав в списке
    Иной.
  </InfoButton>
);

export const TooltipConterpartyName = () => (
  <InfoButton>Наименование Депозитария/Брокера/Банка</InfoButton>
);

interface TooltipConterpartyDepoAccountProps {
  counterpartyMarket: SecurityPlaceCode;
}

export const TooltipConterpartyDepoAccount = ({
  counterpartyMarket,
}: TooltipConterpartyDepoAccountProps) => {
  let text = 'Номер счета депо контрагента (Депозитария/Брокера/Банка)';

  if (counterpartyMarket === SecurityPlaceCode.SPBEX_FSHR) {
    text = 'Клиентский счет депо контрагента в ПАО СПБ';
  } else if (counterpartyMarket === SecurityPlaceCode.REESTR) {
    text = 'Номер счета в реестре';
  }

  return <InfoButton>{text}</InfoButton>;
};

interface TooltipConterpartyDepoCodeprops {
  counterpartyMarket: SecurityPlaceCode;
}
export const TooltipConterpartyDepoCode = ({
  counterpartyMarket,
}: TooltipConterpartyDepoCodeprops) => {
  let text = 'Может также называться "Код депонента"';

  if (counterpartyMarket === SecurityPlaceCode.SPBEX_FSHR) {
    text = 'Идентификатор контрагента в ПАО СПБ';
  }

  return <InfoButton>{text}</InfoButton>;
};

interface TooltipConterpartyDepoSectionProps {
  counterpartyMarket: SecurityPlaceCode;
}
export const TooltipConterpartyDepoSection = ({
  counterpartyMarket,
}: TooltipConterpartyDepoSectionProps) => {
  let text =
    'Может также называться "Код раздела" или "№ (Торгового/Основного) раздела"';

  if (counterpartyMarket === SecurityPlaceCode.SPBEX_FSHR) {
    text = 'Раздел счета депо контрагента в ПАО СПБ';
  }

  return <InfoButton>{text}</InfoButton>;
};

export const TooltipSecurityParams = () => (
  <InfoButton>
    Все параметры должны совпадать во встречных поручениях
  </InfoButton>
);

export const TooltipReference = () => (
  <InfoButton>
    Должен совпадать в обоих встречных поручениях. Обязателен при переводе с
    участием ПАО "СПБ Банк"
  </InfoButton>
);
