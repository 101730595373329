import { LIMIT_SERVICE_UNAVAILABLE_KEY } from '@terminal/core/constants/balance';
import {
  ObjectGroup,
  RazdelGroupType,
} from '@terminal/core/lib/client/entities';
import { unifiedRubPositions } from '@terminal/core/lib/formulas';
import { ECurrencyId } from '@terminal/core/types/currencyId';
import { PositionItem } from '@terminal/core/types/position';
import { SubGTAccountItem } from '@terminal/core/types/subAccount';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';
import { OrderRequest } from '@terminal/core/types/trading';

const isLimitServiceUnavailable = () =>
  sessionStorage.getItem(LIMIT_SERVICE_UNAVAILABLE_KEY) === 'true';

interface CalculateLimitsProps {
  checkTradeLimits: (params: OrderRequest) => Promise<TradeLimitsResult>;
  params: OrderRequest;
  isEFRAccount: boolean;
  subGTAccounts: SubGTAccountItem[];
  positions: PositionItem[];
  warranty: number;
  selectedSubAccountId: number;
}

export const calculateLimits = ({
  checkTradeLimits,
  params,
  isEFRAccount,
  subGTAccounts,
  positions,
  warranty,
  selectedSubAccountId,
}: CalculateLimitsProps): Promise<TradeLimitsResult> | undefined => {
  // Если фьючерс и ЛС недоступен считаем лимиты сами
  // https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=1458984395 Раздел 3 - Лимиты
  if (isLimitServiceUnavailable()) {
    if (!warranty) {
      return;
    }

    if (
      !isEFRAccount &&
      params.razdel.idRazdelGroup === RazdelGroupType.DerivativesMarket
    ) {
      const marginRequirement =
        subGTAccounts.find(
          (account) =>
            account.idRazdelGroup === RazdelGroupType.DerivativesMarket
        )?.marginRequirement || 0;

      const rubleFORTSPosition = positions.find(
        ({ position }) =>
          position.idRazdel === RazdelGroupType.DerivativesMarket &&
          position.idObject === ECurrencyId.RUR
      );

      const torgPos = rubleFORTSPosition
        ? rubleFORTSPosition.position.backPos +
          rubleFORTSPosition.position.trnIn -
          rubleFORTSPosition.position.trnOut
        : 0;

      const availableMoney = torgPos - marginRequirement;

      return Promise.resolve({
        quantity: 0,
        quantityForOwnAssets: Math.floor(availableMoney / warranty),
        amount: 0,
        freeMoney: availableMoney,
      });
    }

    if (
      isEFRAccount &&
      params.orderParams.idObjectGroup === ObjectGroup.Futures
    ) {
      const torgPos = unifiedRubPositions(positions)[0].torgPos;
      const marginRequirement = subGTAccounts.reduce((acc, account) => {
        if (account.idSubAccount === selectedSubAccountId) {
          acc += account.marginRequirement || 0;
        }

        return acc;
      }, 0);

      const availableMoney = torgPos - marginRequirement;

      return Promise.resolve({
        quantity: 0,
        quantityForOwnAssets: Math.floor(availableMoney / warranty),
        amount: 0,
        freeMoney: availableMoney,
      });
    }
  } else {
    // В ином случае возвращаем ответ от ЛС
    return checkTradeLimits(params);
  }
};
