import { useState } from 'react';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

import { FormSectionHeader } from '../../../../shared/ui/FormSectionHeader';
import { Plate } from '../../../../shared/ui/Plate';

import styles from './CurrencyComision.module.css';

export const CurrencyComision: React.FC<{}> = (props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <FormSectionHeader
        tooltip={
          <TooltipDesktop
            contentClassName={styles.tooltipContent}
            open={open}
            content={
              <>
                Вывод USD, EUR = 1%
                <br />
                Вывод других валют = 0,6%
                <br />
                <br />
                Комиссия будет списываться в&nbsp;рублях и&nbsp;рассчитываться
                от&nbsp;суммы вывода по&nbsp;курсу ЦБ. Возможна комиссия банков.
                Брокер в&nbsp;полном размере удерживает комиссии сторонних
                кредитных организаций, включая комиссии иностранных банков
                за&nbsp;исполнение поручения клиента о&nbsp;переводе денежных
                средств в&nbsp;иностранной валюте, проведение расследований
                по&nbsp;переводам в&nbsp;иностранной валюте согласно тарифам
                на&nbsp;оказание брокерских услуг (пункт 14.2).
              </>
            }
            position="right"
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            fallbackPlacements={['bottom', 'top']}
          >
            <InformationCircleLineMIcon
              height={18}
              width={18}
              color="var(--color-light-graphic-secondary)"
            />
          </TooltipDesktop>
        }
      >
        Комиссия брокера
      </FormSectionHeader>

      <Row>
        <Col width={6}>
          <Plate label="Будет списано">{props.children}</Plate>
        </Col>
      </Row>
    </div>
  );
};
