import React, { VFC } from 'react';
import { Amount, AmountProps } from '@alfalab/core-components/amount';

import { MINORITY } from '@terminal/core/constants/ui';
import { isValidCurrency } from '@terminal/core/lib/currencies';
import { countDecimals } from '@terminal/core/lib/format';

export type PriceAmountProps = Omit<
  AmountProps,
  'minority' | 'view' | 'currency'
> & {
  currency?: AmountProps['currency'] | string;
};

export const PriceAmount: VFC<PriceAmountProps> = (props) => {
  const { value, currency, ...restProps } = props;
  const decimalCount = countDecimals(value);
  const decimalMultiplier =
    decimalCount > 2 ? Math.pow(10, decimalCount) : MINORITY;

  return (
    <Amount
      view="withZeroMinorPart"
      value={value * decimalMultiplier}
      currency={currency && isValidCurrency(currency) ? currency : undefined}
      rightAddons={
        currency && isValidCurrency(currency)
          ? undefined
          : Boolean(currency) && ` ${currency}`
      }
      minority={decimalMultiplier}
      bold="none"
      transparentMinor
      {...restProps}
    />
  );
};
