import { parse } from 'date-fns';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import { NotificationsFilter } from '@terminal/core/lib/rest/lkNotifications';

import { isDateValid } from '../../../components/Filter';

export const isFilterValid = (filter: NotificationsFilter): boolean => {
  return (
    isDateValid(filter.startDate || '') && isDateValid(filter.endDate || '')
  );
};

export const stringToDate = (date: string): number => {
  return parse(date, DEFAULT_VIEW_DATE_FORMAT, new Date()).valueOf();
};
