import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { groupBy } from 'lodash';
import { useCallback } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { Notification } from '@terminal/core/lib/rest/lkNotifications';

import { NotificationsItem } from './NotificationsItem';

import styles from './NotificationsList.module.css';

interface NotificationsListProps {
  notifications: Notification[] | null;
  onItemOpen?: (item: Notification) => void;
}

export const NotificationsList = ({
  notifications,
  onItemOpen,
}: NotificationsListProps) => {
  const dateGroups = groupBy(notifications, (item) => {
    return format(parseISO(item.dbTime), 'd MMMM yyyy', { locale: ru });
  });

  const getKeyTitle = useCallback((key: string) => {
    return key === format(new Date(), 'd MMMM yyyy', { locale: ru })
      ? 'Сегодня'
      : key;
  }, []);

  return (
    <div className={styles.container}>
      {Object.keys(dateGroups).map((key) => (
        <div className={styles.group} key={key}>
          <Typography.Text
            view="secondary-small"
            tag="div"
            className={styles.groupTitle}
          >
            {getKeyTitle(key)}
          </Typography.Text>
          {dateGroups[key].map((item) => (
            <NotificationsItem
              className={styles.item}
              key={`notification-${item.id}`}
              item={item}
              onClick={onItemOpen}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
