import cn from 'classnames';
import { useCallback } from 'react';
import { CurrencyCodes } from '@alfalab/core-components/amount';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import { ReceiptLineMIcon } from '@alfalab/icons-glyph/ReceiptLineMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { getActiveOrders } from '@terminal/core/store/selectors';
import { OrderItem } from '@terminal/core/types/order';

import { OrderContent, OrderInfo } from '../../Orders';

import styles from './LinkedOrder.module.css';

interface Props {
  idFi: number;
  linkedOrder?: OrderItem;
  setLinkedOrder: (item: OrderItem) => void;
  isBond: boolean;
  selectedSubAccountId: number;
  priceStep?: number;
  currencyCode?: CurrencyCodes;
}

export const LinkedOrder = ({
  idFi,
  linkedOrder,
  setLinkedOrder,
  isBond,
  selectedSubAccountId,
  priceStep,
  currencyCode,
}: Props) => {
  const { useOrders, finInstrumentsTable } = useAlfaDirectContext();
  const orders = useOrders();

  const activeOrders = getActiveOrders({
    idFI: idFi,
    orders,
    selectedSubAccountId,
    finInstruments: finInstrumentsTable,
  });

  const isError = !linkedOrder;

  const onOrderClick = (order: OrderItem) => {
    setLinkedOrder(order);
  };

  const renderLinkedOrderDropdown = useCallback(
    (isOpen: boolean) => (
      <>
        <ReceiptLineMIcon
          width={18}
          height={18}
          color="var(--color-dark-graphic-secondary)"
        />
        {linkedOrder ? (
          <OrderContent
            idFI={idFi}
            priceStep={priceStep || 0.01}
            currencyCode={currencyCode}
            order={linkedOrder}
          />
        ) : (
          <Typography.Text view="secondary-large">
            Выберите заявку
          </Typography.Text>
        )}
        <ChevronDownMIcon
          height={18}
          width={18}
          color="currentColor"
          className={cn(styles.chevron, isOpen && styles.chevronUp)}
        />
      </>
    ),
    [linkedOrder, idFi, priceStep, currencyCode]
  );

  return (
    <div>
      <div className={styles.textWithTooltip}>
        <Typography.Text view="secondary-large" weight="bold">
          Контрольная заявка
        </Typography.Text>

        <Tooltip
          contentClassName={styles.tooltipContent}
          content="Текущая заявка будет исполнена после успешного исполнения контрольной"
          trigger="hover"
          position="top-start"
          offset={[-20, 16]}
        >
          <InformationCircleLineSIcon
            height={12}
            width={12}
            color="var(--color-dark-graphic-secondary)"
          />
        </Tooltip>
      </div>

      {activeOrders && activeOrders.length > 0 ? (
        <>
          <OrderInfo
            orders={activeOrders}
            position="top"
            isBond={isBond}
            dropdownContent={renderLinkedOrderDropdown}
            currencyCode={currencyCode}
            fromInstructions
            onRowClick={(original) => {
              onOrderClick(original);
            }}
          />
          {isError && (
            <Typography.Text view="secondary-large" className={styles.error}>
              Выберите заявку
            </Typography.Text>
          )}
        </>
      ) : (
        <Typography.Text className={styles.error} view="secondary-large">
          Нет активных заявок. Создайте активную заявку, чтобы связать с ней эту
        </Typography.Text>
      )}
    </div>
  );
};
