import { tryCatch } from '@terminal/core/lib/tryCatch';

export const parseJSONFile = async <R>(file: File): Promise<R> => {
  if (file.type !== 'application/json') {
    throw new Error('Неверный формат файла. Требуется JSON');
  }

  return new Promise((res, rej) => {
    const onParseError = () => {
      rej(new Error('При загрузке конфигурации произошла ошибка'));
    };

    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result;

      const result = tryCatch(async () => {
        if (typeof content !== 'string') {
          return onParseError();
        }

        return JSON.parse(content);
      }, onParseError);

      res(result);
    };

    reader.onerror = onParseError;

    reader.readAsText(file);
  });
};
