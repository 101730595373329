import cn from 'classnames';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Indicator } from '@alfalab/core-components/indicator';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
import {
  Tab,
  TabsDesktop as Tabs,
} from '@alfalab/core-components/tabs/desktop';
import { SelectedId } from '@alfalab/core-components/tabs/typings';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import { TransferOfDebtMIcon } from '@alfalab/icons-glyph/TransferOfDebtMIcon';

import {
  getMarketName,
  getPlaceName,
  MarketGroupNamesMap,
  useRequirement,
} from '@terminal/alfadirect/hooks';
import { useSubAccountRazdel } from '@terminal/alfadirect/hooks';
import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import { MINORITY } from '@terminal/core/constants/ui';
import { ENABLE_LK_FEATURES } from '@terminal/core/env';
import { useSingleFORTSMarket } from '@terminal/core/hooks/useSingleFORTSMarket';
import { RazdelGroupType } from '@terminal/core/lib/client/entities';
import { shallow, useStore } from '@terminal/core/store';
import {
  MarginCall as MarginCallProps,
  MarginCallInfo,
} from '@terminal/core/types/marginCall';
import {
  getRequirementsType,
  RequirementsType,
} from '@terminal/core/types/marginCall';
import { HeaderMenuItem } from '@terminal/core/types/ui';
import { useTreaties } from '@terminal/lk/legacy/hooks';
import { AccountFilters } from '@terminal/widgets/src/features/AccountFilters';

import styles from './MarginCall.module.css';

export const MarginCall = () => {
  const [headerMenu, setHeaderMenuOpen, setMarginCallAccount] = useStore(
    (state) => [
      state.headerMenu,
      state.setHeaderMenuOpen,
      state.setMarginCallAccount,
    ],
    shallow
  );
  const onMenuClose = useCallback(() => {
    setHeaderMenuOpen(null);
    setMarginCallAccount(null);
  }, [setHeaderMenuOpen, setMarginCallAccount]);
  //Получаем информацию о стоимости портфеля, начальном уровень маржи, и маржинальным требованиям по всем счетам ползователя
  const subAccountRazdels = useSubAccountRazdel();
  const selectedSubAccounts = useMemo(
    () => Object.keys(groupBy(subAccountRazdels, 'codeSubAccount')),
    [subAccountRazdels]
  );
  const marginCall = useRequirement(selectedSubAccounts);

  //Оперделяем аккаунты с требованиями
  const accountWithRequirements = useMemo(
    () =>
      Object.entries(marginCall).reduce((acc, [codeSubAccount, value]) => {
        if (value.requirements || value.immediateRequirements) {
          return { ...acc, [codeSubAccount]: value };
        } else {
          return acc;
        }
      }, {} as MarginCallInfo),
    [marginCall]
  );
  const hasRequirements = useMemo(
    () => !isEmpty(accountWithRequirements),
    [accountWithRequirements]
  );

  // Устанавливаем высоту отображения маржин колла, так как layout имеет абсолютное позиционирование
  useEffect(() => {
    if (hasRequirements) {
      document.documentElement.style.setProperty(
        '--margin-call-height',
        '32px'
      );
    } else {
      document.documentElement.style.setProperty('--margin-call-height', '0px');
    }
  }, [hasRequirements]);

  // Определяем тип требований
  const requirementsType = useMemo(
    () =>
      Object.values(accountWithRequirements).some(
        ({ immediateRequirements }) => immediateRequirements! > 0
      )
        ? 'immediateRequirements'
        : 'requirements',
    [accountWithRequirements]
  );

  return (
    <>
      {hasRequirements && (
        <div className={styles.wrapper}>
          <InfoBlock
            accountWithRequirements={accountWithRequirements}
            requirementsType={requirementsType}
          />
        </div>
      )}

      <BaseModal
        open={headerMenu === HeaderMenuItem.MARGIN_CALL}
        onClose={onMenuClose}
        Backdrop={() => null}
      >
        <FunctionalModal
          title="Маржинальная торговля"
          onClose={onMenuClose}
          drawerId="margin-call-modals"
          width={400}
        >
          <MarginModalContent marginCall={marginCall} />
        </FunctionalModal>
      </BaseModal>
    </>
  );
};

interface InfoBlockProps {
  accountWithRequirements: MarginCallInfo;
  requirementsType: 'requirements' | 'immediateRequirements';
}

const InfoBlock = ({
  accountWithRequirements,
  requirementsType,
}: InfoBlockProps) => {
  const [setHeaderMenuOpen, setMarginCallAccount] = useStore(
    (state) => [state.setHeaderMenuOpen, state.setMarginCallAccount],
    shallow
  );

  const isSingleRequirement = useMemo(
    () => Object.keys(accountWithRequirements).length === 1,
    [accountWithRequirements]
  );

  const accountText = useMemo(() => {
    if (isSingleRequirement) {
      return `${
        requirementsType === 'immediateRequirements'
          ? 'Срочное требование'
          : 'Требование'
      } по счёту ${head(Object.keys(accountWithRequirements))}: внести`;
    } else {
      return `Требования по ${
        Object.keys(accountWithRequirements).length
      } cчетам: внести`;
    }
  }, [isSingleRequirement, requirementsType, accountWithRequirements]);

  //Сумма требований
  const requirement = useMemo(() => {
    if (isSingleRequirement) {
      const account = head(Object.values(accountWithRequirements));

      return account!.immediateRequirements || account!.requirements;
    } else {
      return Object.values(accountWithRequirements)
        .filter((i) => i.immediateRequirements !== null || !i.requirements)
        .reduce(
          (acc, { immediateRequirements, requirements }) =>
            acc + (immediateRequirements || requirements)!,
          0
        );
    }
  }, [isSingleRequirement, accountWithRequirements]);

  //Определяем аккаунт для отображения информации в модалке или для пополнения
  //Если по одному счету - берем его код, если по нескольким - смотрим у какого наибольшие требования
  const priorityAccount = useMemo(() => {
    if (isSingleRequirement) {
      return head(Object.keys(accountWithRequirements))!;
    } else {
      //Выбираем аккаунт с наибольшими требованиями
      const account = Object.entries(accountWithRequirements).reduce(
        (acc, [code, marginCall]) => {
          const requirement = marginCall[requirementsType];

          if (requirement === null) {
            return acc;
          }

          if (requirement >= acc.requirement) {
            return { code, requirement };
          } else {
            return acc;
          }
        },
        { code: '', requirement: 0 } as { code: string; requirement: number }
      );

      return account.code;
    }
  }, [accountWithRequirements, isSingleRequirement, requirementsType]);

  const onOpenModal = useCallback(() => {
    setMarginCallAccount({
      code: priorityAccount,
      placeCode: getPlaceName(RazdelGroupType.StocksAndBondsMarket),
      idRazdelGroup: RazdelGroupType.StocksAndBondsMarket,
    });
    setHeaderMenuOpen(HeaderMenuItem.MARGIN_CALL);
  }, [priorityAccount, setHeaderMenuOpen, setMarginCallAccount]);

  return (
    <Typography.Text
      view="secondary-large"
      weight="bold"
      className={styles.infoWrapper}
    >
      <span>{accountText}</span>&nbsp;
      {requirement === null ? (
        '-'
      ) : (
        <Amount.Pure
          value={requirement * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
          currency="RUB"
        />
      )}
      <div className={styles.buttonsWrapper}>
        {ENABLE_LK_FEATURES && (
          <Button
            className={styles.customButton}
            size="xs"
            view="primary"
            onClick={() => setHeaderMenuOpen(HeaderMenuItem.MONEY_TRANSFER)}
            leftAddons={<TransferOfDebtMIcon width={16} height={16} />}
          >
            Пополнить счёт
          </Button>
        )}
        <Button
          className={styles.customButton}
          size="xs"
          view="tertiary"
          onClick={onOpenModal}
          leftAddons={<InformationCircleLineMIcon width={16} height={16} />}
        >
          Подробнее
        </Button>
      </div>
    </Typography.Text>
  );
};

interface MarginModalContentProps {
  marginCall: MarginCallInfo;
}

const MarginModalContent = ({ marginCall }: MarginModalContentProps) => {
  const isMount = useRef(false);
  const [selectedId, setSelectedId] = useState<SelectedId>();
  const [selectedSubAccount, setSelectedSubAccount] = useState<string>('');
  const marginCallAccount = useStore((state) => state.marginCallAccount);
  const subAccountRazdels = useSubAccountRazdel();
  // TODO: Пока не понятно, где ещё, кроме бэка ЛК можно будет получить признак
  // счёта "для иностранных рынков". Если можно, нужно будет убрать этот запрос
  const { data: treaties } = useTreaties();
  // Группируем для получения всех возможных вариантов выбора в селекте
  const subAccounts = useMemo(() => {
    // Счета приходят не сразу, так что фильтруем undefined
    const filteredRazdels = subAccountRazdels.filter((item) => item);

    return groupBy(filteredRazdels, 'codeSubAccount');
  }, [subAccountRazdels]);

  const isEFRAccount = useSingleFORTSMarket();

  const treaty = useMemo(() => {
    if (treaties) {
      return treaties.find(
        (t) => selectedSubAccount.indexOf(t.treaty.toString()) === 0
      );
    }
  }, [treaties, selectedSubAccount]);

  const isForeignMarket = useMemo(() => {
    return Boolean(treaty?.isForeignMarket);
  }, [treaty]);

  useEffect(() => {
    const selected = marginCallAccount
      ? marginCallAccount.code
      : head(Object.keys(subAccounts))!;

    setSelectedSubAccount(selected);
  }, [marginCallAccount, subAccounts]);

  const handleChange = (
    event: React.MouseEvent<Element, MouseEvent>,
    { selectedId }: { selectedId: SelectedId }
  ) => setSelectedId(selectedId);

  // Требования по счету
  const accountMarginCall = useMemo(() => {
    return marginCall[selectedSubAccount];
  }, [marginCall, selectedSubAccount]);

  //Тип требования по аккаунту
  const accountRequirementsType = useMemo(() => {
    if (accountMarginCall && accountMarginCall.immediateRequirements !== 0) {
      return 'immediateRequirements';
    } else if (accountMarginCall && accountMarginCall.requirements !== 0) {
      return 'requirements';
    }
  }, [accountMarginCall]);

  // Требования по рынкам
  const marketsMarginCall = useMemo(() => {
    return marginCall[selectedSubAccount]?.marketsMarginCall;
  }, [marginCall, selectedSubAccount]);

  //Определяем рынки с требованиями
  const marketsWithRequirements = useMemo(
    () =>
      marketsMarginCall &&
      Object.entries(marketsMarginCall).reduce(
        (acc, [idRazdelGroup, value]) => {
          if (value.requirements || value.immediateRequirements) {
            return { ...acc, [idRazdelGroup]: value };
          } else {
            return acc;
          }
        },
        {} as { [idRazdelGroup: string]: MarginCallProps }
      ),
    [marketsMarginCall]
  );

  const marketsRequirementsText = useMemo(() => {
    if (marketsWithRequirements && accountRequirementsType) {
      const isSingleMarketRequirement =
        Object.keys(marketsWithRequirements).length === 1;

      if (isEFRAccount) {
        return 'Требования по счёту';
      }

      if (isSingleMarketRequirement) {
        return `${
          accountRequirementsType === 'immediateRequirements'
            ? 'Срочное требование'
            : 'Требование'
        } ${getMarketName(
          Number(Object.keys(marketsWithRequirements)[0])
        )}: внести`;
      } else {
        return `Требования по ${
          Object.keys(marketsWithRequirements).length
        } рынкам: внести`;
      }
    }
  }, [isEFRAccount, marketsWithRequirements, accountRequirementsType]);

  // Выбираем первый рынок по умолчанию (вкладку табов)
  useEffect(() => {
    if (marketsMarginCall && !isMount.current) {
      setSelectedId(head(Object.keys(marketsMarginCall)));
      isMount.current = true;
    }
  }, [marketsMarginCall]);

  return (
    <div className={styles.content}>
      <Typography.Text
        view="primary-small"
        color="primary"
        weight="bold"
        className={styles.accountTitle}
      >
        Счёт
      </Typography.Text>
      <AccountFilters
        classNames={{
          wrapper: styles.accountFiltersWrapper,
          selectButton: styles.accountFiltersSelectButton,
          container: '',
        }}
        selectedSubAccounts={[selectedSubAccount]}
        onOptionSelect={(accounts) => {
          setSelectedSubAccount(accounts[0]);
          isMount.current = false;
        }}
        isOrderBookVersion
        closeOnSelect
      />
      {isForeignMarket && (
        <NotAvailable
          title={
            <>
              Маржинальная торговля на счёте
              <br />
              Иностранный рынок недоступна
            </>
          }
        />
      )}
      {accountMarginCall && accountRequirementsType && !isForeignMarket && (
        <Typography.Text
          view="secondary-small"
          color={
            (accountRequirementsType === 'immediateRequirements' &&
              'negative') ||
            (accountRequirementsType === 'requirements' && 'attention') ||
            'secondary'
          }
          className={styles.accountRequirementByMarkets}
        >
          {marketsRequirementsText}
          &nbsp;
          {accountMarginCall[accountRequirementsType] === null ? (
            '-'
          ) : (
            <Amount.Pure
              value={accountMarginCall[accountRequirementsType]! * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency="RUB"
            />
          )}
        </Typography.Text>
      )}
      {marketsMarginCall &&
        !isForeignMarket &&
        isEFRAccount &&
        Boolean(Object.keys(marketsMarginCall).length) && (
          <>
            <Typography.Text
              view="primary-small"
              color="primary"
              className={styles.tabsWrapper}
            >
              Единый рынок
            </Typography.Text>
            <MarketModalContent
              idRazdelGroup={Object.keys(marketsMarginCall)[0]}
              data={Object.values(marketsMarginCall)[0]}
              requirementsType={getRequirementsType(
                Object.values(marketsMarginCall)[0]
              )}
              codeSubAccount={selectedSubAccount}
            />
          </>
        )}
      {marketsMarginCall &&
        !isForeignMarket &&
        !isEFRAccount &&
        Boolean(Object.keys(marketsMarginCall).length) && (
          <Tabs
            className={styles.tabsWrapper}
            selectedId={selectedId}
            onChange={handleChange}
            scrollable
            size="s"
          >
            {Object.entries(marketsMarginCall).map(([idRazdelGroup, data]) => {
              const requirementsType = getRequirementsType(data);

              return (
                <Tab
                  key={idRazdelGroup}
                  title={MarketGroupNamesMap.get(Number(idRazdelGroup))!}
                  id={idRazdelGroup}
                  rightAddons={
                    requirementsType && (
                      <Indicator
                        backgroundColor={
                          (requirementsType === 'immediateRequirements' &&
                            'var(--color-light-text-negative)') ||
                          (requirementsType === 'requirements' &&
                            'var(--color-light-text-attention)') ||
                          undefined
                        }
                      />
                    )
                  }
                >
                  <MarketModalContent
                    idRazdelGroup={idRazdelGroup}
                    data={data}
                    requirementsType={requirementsType}
                    codeSubAccount={selectedSubAccount}
                  />
                </Tab>
              );
            })}
          </Tabs>
        )}
    </div>
  );
};

interface MarketModalContentProps {
  idRazdelGroup: string;
  data: MarginCallProps;
  requirementsType?: RequirementsType;
  codeSubAccount: string;
}

const MarketModalContent = ({
  data,
  requirementsType,
  idRazdelGroup,
  codeSubAccount,
}: MarketModalContentProps) => {
  const [setHeaderMenuOpen, setMarginCallAccount] = useStore(
    (state) => [state.setHeaderMenuOpen, state.setMarginCallAccount],
    shallow
  );

  return (
    <div className={styles.marketWrapper}>
      <MarketChart data={data} requirementsType={requirementsType} />
      <Typography.Text
        view="secondary-small"
        color="secondary"
        className={styles.marketTitle}
      >
        Ликвидный портфель
      </Typography.Text>
      <Typography.Text
        view="secondary-large"
        color={
          (requirementsType === 'immediateRequirements' && 'negative') ||
          (requirementsType === 'requirements' && 'attention') ||
          'primary'
        }
        className={styles.marketValue}
      >
        {data.liquedBalance === null ? (
          '-'
        ) : (
          <Amount.Pure
            value={data.liquedBalance * MINORITY}
            minority={MINORITY}
            view="withZeroMinorPart"
            currency="RUB"
          />
        )}
      </Typography.Text>
      <Typography.Text
        view="secondary-small"
        color="secondary"
        className={styles.marketTitle}
      >
        Начальная маржа
      </Typography.Text>
      <Typography.Text view="secondary-large" className={styles.marketValue}>
        <Amount.Pure
          value={data.initialMargin * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
          currency="RUB"
        />
        <Tooltip
          targetClassName={styles.tooltipTarget}
          contentClassName={styles.tooltipContent}
          content="Начальная маржа - уровень риска портфеля, рассчитанный на основании стоимостей позиций и начальных дисконтов по инструментам. Если начальная маржа превышает стоимость портфеля, не разрешается дальнейшее наращивание позиций (увеличение рыночного риска)"
          trigger="hover"
          position="top-start"
          offset={[-20, 16]}
        >
          <InformationCircleLineSIcon className={styles.infoIcon} />
        </Tooltip>
      </Typography.Text>
      <Typography.Text
        view="secondary-small"
        color="secondary"
        className={styles.marketTitle}
      >
        Минимальная маржа
      </Typography.Text>
      <Typography.Text view="secondary-large" className={styles.marketValue}>
        <Amount.Pure
          value={data.minimumMargin * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
          currency="RUB"
        />
        <Tooltip
          targetClassName={styles.tooltipTarget}
          contentClassName={styles.tooltipContent}
          content="Минимальная маржа - уровень риска портфеля, рассчитанный на основании стоимостей позиций и минимальных дисконтов по инструментам. Если минимальная маржа превышает стоимость портфеля, брокер принудительно закрывает позиции (производит сокращение рыночного риска)"
          trigger="hover"
          position="top-start"
          offset={[-20, 16]}
        >
          <InformationCircleLineSIcon className={styles.infoIcon} />
        </Tooltip>
      </Typography.Text>
      {requirementsType && (
        <Typography.Text
          view="secondary-large"
          weight="medium"
          color={
            (requirementsType === 'immediateRequirements' && 'negative') ||
            (requirementsType === 'requirements' && 'attention') ||
            'secondary'
          }
          className={cn(styles.infoWrapper, styles.requirementSum)}
        >
          {`${
            requirementsType === 'immediateRequirements'
              ? 'Срочное требование'
              : 'Требование'
          }: внести`}
          &nbsp;
          {data[requirementsType] === null ? (
            '-'
          ) : (
            <Amount.Pure
              value={data[requirementsType]! * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency="RUB"
            />
          )}
        </Typography.Text>
      )}
      {requirementsType && (
        <Button
          leftAddons={<TransferOfDebtMIcon width={18} height={18} />}
          onClick={() => {
            setMarginCallAccount({
              code: codeSubAccount,
              placeCode: getPlaceName(Number(idRazdelGroup)),
              idRazdelGroup: Number(idRazdelGroup),
            });
            setHeaderMenuOpen(HeaderMenuItem.MONEY_TRANSFER);
          }}
          size="s"
          block
          className={styles.transferButtonModal}
          view="primary"
        >
          {Number(idRazdelGroup) === RazdelGroupType.StocksAndBondsMarket
            ? 'Пополнить счет'
            : 'Перевести'}
        </Button>
      )}
    </div>
  );
};

export interface MarketChartProps {
  data: MarginCallProps;
  requirementsType?: RequirementsType;
}

export const MarketChart = ({ requirementsType, data }: MarketChartProps) => {
  const { liquedBalance, minimumMargin, initialMargin } = data;

  const isZeroMargin = useMemo(
    () => minimumMargin === 0 && initialMargin === 0,
    [initialMargin, minimumMargin]
  );

  // TODO: Пока что нет формулы для определния 100% шкалы, спросить у Чаркина при необходимости доработок
  // Договорились в первой итерации захардкодить
  const liquedBalancePos = useMemo(() => {
    switch (requirementsType) {
      case 'immediateRequirements':
        return 10;
      case 'requirements':
        return 40;

      default:
        return 90;
    }
  }, [requirementsType]);

  return (
    <div className={styles.chartWrapper}>
      <div
        className={styles.liquedBalancePoint}
        style={{ left: `${liquedBalancePos}%` }}
      ></div>
      <div
        className={cn(
          styles.chartLiquedBalance,
          requirementsType === 'requirements' && styles.chartLiquedBalanceReq,
          requirementsType === 'immediateRequirements' &&
            styles.chartLiquedBalanceImm
        )}
        style={{ left: `${liquedBalancePos}%` }}
      >
        {liquedBalance === null ? (
          '-'
        ) : (
          <Amount.Pure
            value={liquedBalance * MINORITY}
            minority={MINORITY}
            view="withZeroMinorPart"
            currency="RUB"
          />
        )}
      </div>
      <ProgressBar
        size="s"
        className={styles.chart}
        value={liquedBalancePos}
        view={getProgressColor(requirementsType)}
      />
      {isZeroMargin ? (
        <>
          <div className={styles.marginPoint} />
          <div className={cn(styles.chartMargin, styles.chartZeroMargin)}>
            <Typography.Text
              view="secondary-medium"
              color="secondary"
              className={styles.chartMarginText}
            >
              Минимальная
              <br />и начальная маржа
            </Typography.Text>
            <Amount.Pure value={0} minority={MINORITY} currency="RUB" />
          </div>
        </>
      ) : (
        <>
          <div className={styles.marginPoint} style={{ left: '20%' }} />
          <div className={styles.chartMargin} style={{ left: '20%' }}>
            <Typography.Text
              view="secondary-medium"
              color="secondary"
              className={styles.chartMarginText}
            >
              Минимальная
              <br />
              маржа
            </Typography.Text>
            <Amount.Pure
              value={minimumMargin * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency="RUB"
            />
          </div>
          <div className={styles.marginPoint} style={{ left: '60%' }} />
          <div className={styles.chartMargin} style={{ left: '60%' }}>
            <Typography.Text
              view="secondary-medium"
              color="secondary"
              className={styles.chartMarginText}
            >
              Начальная
              <br />
              маржа
            </Typography.Text>
            <Amount.Pure
              value={initialMargin * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency="RUB"
            />
          </div>
        </>
      )}
    </div>
  );
};

export function getProgressColor(type: RequirementsType) {
  if (type === 'immediateRequirements') {
    return 'negative';
  }

  if (type === 'requirements') {
    return 'attention';
  }

  return 'positive';
}

interface NotAvailableProps {
  title: React.ReactNode;
}

export const NotAvailable = ({ title }: NotAvailableProps) => {
  return (
    <div className={styles.notAvailableContainer}>
      <SuperEllipse size={48}>
        <ExclamationMIcon />
      </SuperEllipse>
      <Typography.Text
        tag="p"
        view="primary-medium"
        defaultMargins={false}
        className={styles.notAvailableTitle}
        weight="bold"
      >
        {title}
      </Typography.Text>
    </div>
  );
};
