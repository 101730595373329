import { getFullFI } from '@terminal/core/lib/getFullFI';
import { shallow, useStore as useRootStore } from '@terminal/core/store';

import { BrokerRecommendation } from '../types';

// Хук получает необходимые данные о ФИ для отображения в рекомендации
export const useRecommendationOrder = (
  recommendation: BrokerRecommendation
) => {
  const [objects, objectTypes, finInstruments, marketBoards] = useRootStore(
    (state) => [
      state.objectsTable,
      state.objectTypesTable,
      state.finInstrumentsTable,
      state.marketBoardsTable,
    ],
    shallow
  );

  const fullFI = getFullFI(
    recommendation.idFi,
    objects,
    objectTypes,
    finInstruments,
    marketBoards
  );

  return {
    ...recommendation,
    ...fullFI,
  };
};
