export function decapitalize(text: string | undefined) {
  if (!text) {
    return text;
  }
  const firstWord = text.split(' ')[0];
  if (/\b[A-Z]+\b/.test(firstWord)) {
    return text;
  }
  if (isUpperCase(firstWord)) {
    return text;
  }
  const firstLetter = text[0].toLowerCase();
  return firstLetter + text.substring(1);
}

export function isUpperCase(str: string) {
  return str === str.toUpperCase();
}
