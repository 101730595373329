import {
  DocumentType,
  ExecutionType,
  LifeTime,
  OrderType,
  PriceType,
  QuantityType,
} from '../client/entities';

import { AllowedOrderParams } from '../../types/allowedOrderParams';

/*
AllowedOrderParamEntity - это матрица допустимых параметров
отфильтруешь  по idMarketBoard и idObjectGroup из инструмента и получишь список какие заявки можно подавать по инструменту
для торговых поручений отфильтруешь еще по DocumentType::TRD
и PriceType::QUO
Data::QuantityType::QTY
ну и если лимитную заявку подавать то найдешь в отфильтрованном OrderType LMT
*/

export const getAllowedOrderParamsId = (
  fi: { idMarketBoard: number; idObjectGroup: number },
  orderParams: AllowedOrderParams[],
  orderType: OrderType,
  executionType: ExecutionType,
  lifeTime: LifeTime,
  idQuantityType: QuantityType
): AllowedOrderParams | null => {
  //ФЕ присылает кучу AllowedOrderParamEntity, среди которых надо выбрать одну подходящую
  //по параметрам ObjectGroup, MarketBoardType, DocumentType, LifeTime, OrderType, PriceType, QuantityType
  let filteredParams = orderParams.filter(
    (param) =>
      param.idMarketBoard === fi.idMarketBoard &&
      param.idObjectGroup === fi.idObjectGroup &&
      param.idLifeTime === lifeTime &&
      param.idOrderType === orderType &&
      (idQuantityType === QuantityType.None
        ? true
        : param.idQuantityType === idQuantityType)
  );

  // пока тут подерживаем только Market и Limit
  if (orderType === OrderType.LMT || orderType === OrderType.MKT) {
    filteredParams = filteredParams.filter(
      (param) =>
        param.idDocumentType === DocumentType.TRD &&
        param.idPriceType === PriceType.QUO
    );
  }

  if (filteredParams.length) {
    // если вдруг подходят несколько, берём первую попавшуюся
    return filteredParams[0];
  } else {
    return null;
  }
};
