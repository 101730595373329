import { useEffect, useState } from 'react';

export interface ValidateResult {
  code: number;
  message: string;
}

interface ValidationProps {
  values: { [s: string]: string };
  transforms?: { [s: string]: (value: string) => string };
  validators: { [s: string]: (value: string) => ValidateResult };
  handlers: { [s: string]: ((value: string) => void) | undefined };
}

export const useValidation = ({
  values,
  transforms,
  validators,
  handlers,
}: ValidationProps) => {
  const [touched, setTouched] = useState<{ [s: string]: boolean }>({});
  const [results, setResults] = useState<{ [s: string]: ValidateResult }>({});

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value;
    if (!!transforms && !!transforms[event.target.name]) {
      value = transforms[event.target.name](value);
    }

    if (typeof handlers[event.target.name] === 'function') {
      (handlers[event.target.name] as Function)(value);
    }
  };

  const handleBlur = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!touched[event.target.name]) {
      setTouched((prev) => ({ ...prev, [event.target.name]: true }));
    }
  };

  const touchAll = () => {
    const touch: { [s: string]: boolean } = {};
    Object.keys(values).forEach((key) => {
      touch[key] = true;
    });
    setTouched(touch);
  };

  const isValid = () => {
    return !Object.values(results).some((res) => res.code !== 0);
  };

  useEffect(() => {
    const res: { [s: string]: ValidateResult } = {};
    Object.keys(values).forEach((key) => {
      if (!!validators[key]) {
        res[key] = validators[key](values[key]);
      }
    });
    setResults(res);
  }, [values, validators]);

  return {
    handleChange,
    handleBlur,
    touched,
    results,
    touchAll,
    isValid,
  };
};
