import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Space } from '@alfalab/core-components/space';

import { RequisitesValue } from './RequisitesValue';

import styles from './DetailRequisites.module.css';

export const BrokerRequisitesInfoRub = () => (
  <Space fullWidth={true} direction="vertical">
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Банк получателя"
          value="АЛЬФА-БАНК, г. Москва"
        />
      </Col>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue title="БИК" value="044525593" />
      </Col>
    </Row>
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Корреспондентский счет"
          value="30101810200000000593"
        />
      </Col>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue title="ИНН Банка" value="7728168971" />
      </Col>
    </Row>
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue title="КПП Банка" value="770801001" />
      </Col>
    </Row>
  </Space>
);

export const BrokerRequisitesInfoUsd = () => (
  <Space fullWidth={true} direction="vertical">
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Банк получателя / Beneficiary’s bank"
          value="ALFA-BANK"
        />
      </Col>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue title="Beneficiary SWIFT" value="ALFARUMM" />
      </Col>
    </Row>
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Correspondent bank"
          value="CITIBANK NA, 399 Park Avenue, New York, NY 10043, USA"
        />
      </Col>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue title="Correspondent SWIFT" value="CITIUS33" />
      </Col>
    </Row>
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Account with correspondent bank number"
          value="36310481"
        />
      </Col>
    </Row>
  </Space>
);

export const BrokerRequisitesInfoEur = () => (
  <Space fullWidth={true} direction="vertical">
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Банк получателя / Beneficiary’s bank"
          value="ALFA-BANK"
        />
      </Col>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue title="Beneficiary SWIFT" value="ALFARUMM" />
      </Col>
    </Row>
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Correspondent bank"
          value="COMMERZBANK AG, D-60261 Frankfurt am Main, Germany"
        />
      </Col>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue title="Correspondent SWIFT" value="COBADEFF" />
      </Col>
    </Row>
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Account with correspondent bank number"
          value="400886894501EUR"
        />
      </Col>
    </Row>
  </Space>
);

export const BrokerRequisitesInfoGbp = () => (
  <Space fullWidth={true} direction="vertical">
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Банк получателя / Beneficiary’s bank"
          value="ALFA-BANK"
        />
      </Col>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue title="Beneficiary SWIFT" value="ALFARUMM" />
      </Col>
    </Row>
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Correspondent bank"
          value="Bank of America N.A, 2 King Edward St, London, EC1A 1HQ, United Kingdom"
        />
      </Col>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue title="Correspondent SWIFT" value="BOFAGB22" />
      </Col>
    </Row>
    <Row>
      <Col
        className={styles.mobileColumn}
        width={{ desktop: 6, mobile: 12, tablet: 12 }}
      >
        <RequisitesValue
          title="Account with correspondent bank number"
          value="59238016"
        />
      </Col>
    </Row>
  </Space>
);

interface DepoRequisitesInfoProps {
  treaty: string;
  openDate: string;
  isGoInvest: boolean;
}
export const DepoRequisitesInfoMicexShr = (props: DepoRequisitesInfoProps) => {
  const data = props.isGoInvest
    ? {
        receiver: 'ООО "Го Инвест"',
        depoCode: 'MC0547300000',
        tradeDepoAccount: 'HL2204190051',
        sectionDepoAccount: '36MC0547300000F01',
        contract: '5473/ДНД от 18.04.2022',
      }
    : {
        receiver: 'АО «Альфа-Банк»',
        depoCode: 'МС0000500000',
        tradeDepoAccount: 'HL1212170625',
        sectionDepoAccount: '36MC0000500000F00',
        contract: '№5/ДМС-0 от 28.12.1998 г.',
      };

  return (
    <Space fullWidth={true} direction="vertical">
      <Row>
        <Col
          className={styles.mobileColumn}
          width={{ desktop: 6, mobile: 12, tablet: 12 }}
        >
          <RequisitesValue title="Получатель" value={data.receiver} />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Код депонента / Идентификатор"
            value={data.depoCode}
          />
        </Col>
      </Row>
      <Row>
        <Col
          className={styles.mobileColumn}
          width={{ desktop: 6, mobile: 12, tablet: 12 }}
        >
          <RequisitesValue
            title="Торговый счет депо"
            value={data.tradeDepoAccount}
          />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Раздел счета депо"
            value={data.sectionDepoAccount}
          />
        </Col>
      </Row>
      <Row>
        <Col
          className={styles.mobileColumn}
          width={{ desktop: 6, mobile: 12, tablet: 12 }}
        >
          <RequisitesValue
            title="Договор междепозитарного счета депо"
            value={data.contract}
          />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Генеральное соглашение"
            value={`${props.treaty} от ${props.openDate}`}
          />
        </Col>
      </Row>
    </Space>
  );
};

export const DepoRequisitesInfoEurotrade = (props: DepoRequisitesInfoProps) => (
  <Space fullWidth={true} direction="vertical">
    <Row>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue title="Получатель" value="АО «Альфа-Банк»" />
      </Col>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue title="Клиентский счет депо" value="№ 11247" />
      </Col>
    </Row>
    <Row>
      <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
        <RequisitesValue
          title="Генеральное соглашение"
          value={`${props.treaty} от ${props.openDate}`}
        />
      </Col>
    </Row>
  </Space>
);

interface DepoRequisitesInfoBebProps extends DepoRequisitesInfoProps {
  spbClientField: string;
}
export const DepoRequisitesInfoSpbexFshrBeb = (
  props: DepoRequisitesInfoBebProps
) => {
  const data = props.isGoInvest
    ? {
        receiver: 'ООО "Го Инвест"',
        clientDepoAccount: 'ANB00919',
        section: 'T00919',
        identificator: 'AINVM',
      }
    : {
        receiver: 'АО «Альфа-Банк»',
        clientDepoAccount: '№ ANB00505',
        section: 'T00505',
        identificator: 'ALFCM',
      };

  return (
    <Space fullWidth={true} direction="vertical">
      <Row>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue title="Получатель" value={data.receiver} />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Клиентский счет депо"
            value={data.clientDepoAccount}
          />
        </Col>
      </Row>
      <Row>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue title="Код клиента" value={props.spbClientField} />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue title="Раздел" value={data.section} />
        </Col>
      </Row>
      <Row>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue title="Идентификатор" value={data.identificator} />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Генеральное соглашение"
            value={`${props.treaty} от ${props.openDate}`}
          />
        </Col>
      </Row>
    </Space>
  );
};

export const DepoRequisitesInfoSpbexFshrNrd = (
  props: DepoRequisitesInfoProps
) => {
  const data = props.isGoInvest
    ? {
        receiver: 'ПАО СПБ',
        depoCode: 'MC0020800000',
        tradeDepoAccount: 'ML1405300022',
        section: '70000000000000090',
        contract: '800/ДМС­2 от 14.05.1999',
      }
    : {
        receiver: 'ПАО «Бест Эффортс Банк»',
        depoCode: 'MC0020800000',
        tradeDepoAccount: 'ML1405300022',
        section: '70000000000000090',
        contract: '№800/ДМС-2 от 14.05.1999',
      };

  return (
    <Space fullWidth={true} direction="vertical">
      <Row>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue title="Получатель" value={data.receiver} />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Код депонента / идентификатор"
            value={data.depoCode}
          />
        </Col>
      </Row>
      <Row>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Торговый счет депо"
            value={data.tradeDepoAccount}
          />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue title="Код раздела" value={data.section} />
        </Col>
      </Row>
      <Row>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Договор междепозитарного счёта депо"
            value={data.contract}
          />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Генеральное соглашение"
            value={`${props.treaty} от ${props.openDate}`}
          />
        </Col>
      </Row>
    </Space>
  );
};

export const DepoRequisitesInfoSpbexFshrEuroclear = (
  props: DepoRequisitesInfoProps
) => {
  if (props.isGoInvest) {
    return null;
  }

  return (
    <Space fullWidth={true} direction="vertical">
      <Row>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue title="Получатель" value="НКО АО НРД" />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue title="Клиентский счет депо" value="№ 46055" />
        </Col>
      </Row>
      <Row>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Генеральное соглашение"
            value={`${props.treaty} от ${props.openDate}`}
          />
        </Col>
      </Row>
    </Space>
  );
};

export const DepoRequisitesInfoSpbexFshrClearstream = (
  props: DepoRequisitesInfoProps
) => {
  if (props.isGoInvest) {
    return null;
  }

  return (
    <Space fullWidth={true} direction="vertical">
      <Row>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue title="Получатель" value="НКО АО НРД" />
        </Col>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue title="Клиентский счет депо" value="№ 73170" />
        </Col>
      </Row>
      <Row>
        <Col width={{ desktop: 6, mobile: 12, tablet: 12 }}>
          <RequisitesValue
            title="Генеральное соглашение"
            value={`${props.treaty} от ${props.openDate}`}
          />
        </Col>
      </Row>
    </Space>
  );
};
