import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import styles from './FormControlSummary.module.css';

export type FormControlSummaryProps = HTMLAttributes<HTMLDivElement> & {
  label: ReactNode;
};

/**
 * Используется для отображения заполненного значения поля формы
 * */
export const FormControlSummary: FC<FormControlSummaryProps> = (props) => {
  const { children, label, ...restProps } = props;

  return (
    <div {...restProps}>
      <Typography.Text
        view="secondary-medium"
        color="secondary"
        tag="p"
        defaultMargins={false}
        className={styles.label}
      >
        {label}
      </Typography.Text>
      <Typography.Text
        view="primary-small"
        color="primary"
        tag="p"
        defaultMargins={false}
      >
        {children}
      </Typography.Text>
    </div>
  );
};
