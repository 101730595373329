import {
  FrontEndType,
  NotificationActionFlags,
  NotificationSubscriptionEntity,
  NotificationSubscriptionState,
  NotificationSubscriptionStateEntity,
  NotificationType,
  Operation,
} from '../client/entities';
import { EntityService } from '../client/entityService';
import { EntityType } from '../client/entityTypes';
import { getRandomInt } from '../client/helpers';

interface NewNotificationSubscriptionStateEntity
  extends Partial<NotificationSubscriptionStateEntity> {}
interface NewNotificationSubscriptionEntity
  extends Pick<NotificationSubscriptionEntity, 'DeviceAddressMFM'> {}

class NotificationSubscriptionService extends EntityService {
  defaultEntity = EntityType.NotificationSubscriptionStateEntity;
  frontend = FrontEndType.AuthAndOperInitServer;
  operationIdField = 'IdRequest';

  stateSubscription: () => void;

  private createSubscriptionStateEntity(
    data: NewNotificationSubscriptionStateEntity,
    state: NotificationSubscriptionState,
    operation: Operation = Operation.Inserted
  ): Partial<NotificationSubscriptionStateEntity> {
    return {
      ...data,
      IdDevice: this.client.idDevice,
      Version: 0n,
      IdState: state,
      Operation: operation,
    };
  }

  private createRegisterDeviceEntity(
    notification: NewNotificationSubscriptionEntity,
    action: NotificationActionFlags
  ): NotificationSubscriptionEntity {
    return {
      ...notification,
      IdDevice: this.client.idDevice,
      Action: action,
      Token: '',
      IdRequest: getRandomInt(),
    };
  }

  subscribeToPriceAlerts() {
    this.enableNotificationSubscription(NotificationType.TrackPrice);
  }

  enableNotificationSubscription(value: NotificationType) {
    const data = this.createSubscriptionStateEntity(
      { IdNotification: value },
      NotificationSubscriptionState.Enabled,
      Operation.Updated
    );

    return this.postEntity(data);
  }

  registerDevice(deviceAddress: string) {
    const data = this.createRegisterDeviceEntity(
      { DeviceAddressMFM: deviceAddress },
      NotificationActionFlags.Subscribe
    );

    this.postEntity(data, EntityType.NotificationSubscriptionEntity);
  }

  onUpdateSubsciptions(
    cb: (data: NotificationSubscriptionStateEntity[]) => void
  ) {
    // на всякий случай запрещаем повторно подписываться на одни и те же события
    if (this.stateSubscription) {
      this.stateSubscription();
    }

    this.stateSubscription = this.subscribe(cb);
  }
}

const notificationSubscriptionService = new NotificationSubscriptionService();

export { notificationSubscriptionService as NotificationSubscriptionService };
