import React, { FC, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';

import { Dialog } from '../../../Dialog';
import { AssignmentScreen } from '../AssignmentScreen';
import { CertificateEnroll } from '../CertificateEnroll';
import { NoSmsScreen } from '../NoSmsScreen';

import { useCertificateEnrollStore } from '../../model/store';

import { CertificateEnrollStep } from '../../model/types';

import styles from './CertificateEnrollModal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CertificateEnrollModal: FC<Props> = ({ isOpen, onClose }) => {
  const [assignmentVisible, setAssignmentVisible] = useState<boolean>(false);
  const [noSmsDrawerVisible, setNoSmsDrawerVisible] = useState<boolean>(false);

  const [step, setStep, rejectingCertificate, assignment, clearError] =
    useCertificateEnrollStore((store) => [
      store.step,
      store.setStep,
      store.rejectingCertificate,
      store.assignment,
      store.clearError,
    ]);

  const handleClose = () => {
    clearError();
    onClose();
  };

  let backButton;

  if (step === CertificateEnrollStep.SelectRejecting && rejectingCertificate) {
    backButton = {
      onClick: () => setStep(CertificateEnrollStep.Reject),
      text: 'Назад',
    };
  }

  const bottomSlot = (
    <>
      <Gap size="xs" />
      <div className={styles.assignmentDocumentRow}>
        <Link
          pseudo
          view="secondary"
          onClick={() => {
            setNoSmsDrawerVisible(true);
          }}
        >
          <Typography.Text view="secondary-small">
            Не приходит сообщение?
          </Typography.Text>
        </Link>
      </div>

      {assignment && (
        <>
          <Gap size="xl" />

          <div className={styles.assignmentDocumentRow}>
            <Link
              pseudo
              view="secondary"
              leftAddons={<DocumentLinesMIcon width={16} height={16} />}
              onClick={() => {
                setAssignmentVisible(true);
              }}
            >
              <Typography.Text view="secondary-small">
                Текст поручения
              </Typography.Text>
            </Link>
          </div>

          <Gap size="m" />
        </>
      )}
    </>
  );

  const getDrawer = () => {
    if (noSmsDrawerVisible) {
      return {
        content: <NoSmsScreen onBack={() => setNoSmsDrawerVisible(false)} />,
        onClose: () => setNoSmsDrawerVisible(false),
      };
    } else if (assignmentVisible && assignment) {
      return {
        content: <AssignmentScreen assignment={assignment} />,
        onClose: () => setAssignmentVisible(false),
      };
    }

    return;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      width={494}
      minHeight={390}
      backButton={backButton}
      drawer={getDrawer()}
    >
      <div className={styles.container}>
        {isOpen && (
          <CertificateEnroll
            onSuccess={handleClose}
            signBottomSlot={bottomSlot}
          />
        )}
      </div>
    </Dialog>
  );
};
