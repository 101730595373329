import { FC } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { getRoundedBigSum } from '@terminal/core/lib/format';

import { useWidgetContext } from '../../../../shared';
import { InstrumentDataRowGrid } from '../../ui';
import { Chart } from '../../ui/Chart/Chart';
import { CompanyIndicatorsTable } from './CompanyIndicatorsTable';
import { FinanceProgressBar } from './FinanceProgressBar';
import {
  getFinancialStabilityDescription,
  getPriceValuationDescription,
} from './lib/getDescriptionByScoring';
import { isVisibleScoreProgressBar } from './lib/isVisibleScoreProgressBar';
import { TitleWithHint } from './TitleWithHint';

import { useBaseIsin } from '../../hooks/useBaseIsin';

import classes from './FinancesTab.module.css';

const tooltipInfo = {
  financialStability: {
    title: 'Финансовая устойчивость компании',
    content:
      'Устойчивость определяется доходами компании в сравнении с её долговыми обязательствами. Для оценки анализруются прибыль, выручка, долговая нагрузка, выплаты дивидендов и операционные денежные потоки компании за последние года. Компания получает максимальную оценку, если её доходы постоянно растут и они выше, чем долговая нагрузка компании.',
  },
  priceValuation: {
    title: 'Price valuation',
    content:
      'Критерий, который определяется сравнением мультипликаторов компании со средними показателями по сектору. Для оценки стоимости компании анализируются мультипликаторы P/E, P/S, P/B, EV/EBITDA за последние года. Более высокая оценка показывает, что компании недооценена рынком, а значит имеет больший потенциал к росту, чем её конкуренты.',
  },
};

export const FinancesTab: FC<{ idFi: number }> = ({ idFi }) => {
  const isin = useBaseIsin(idFi);
  const { useIssuerFinancials } = useWidgetContext();
  const { data } = useIssuerFinancials(isin, {
    scores: true,
    financialIndustryAvg: true,
    allHistoricalInfoRequired: true,
  });

  if (!data) {
    return null;
  }

  const { marketCapitalization, ttmEbitd, enterpriseValue, currency, scores } =
    data;

  const [roundedMarketCapitalization, marketCapitalizationPostfix] =
    getRoundedBigSum(
      marketCapitalization ? marketCapitalization * 1000000 : 0n
    );
  const [roundedTtmEbitd, ttmEbitdPostfix] = getRoundedBigSum(
    ttmEbitd ? ttmEbitd * 1000000 : 0n
  );
  const [roundedEnterpriseValue, enterpriseValuePostfix] = getRoundedBigSum(
    enterpriseValue ? enterpriseValue * 1000000 : 0n
  );

  const scoreRecordMap = new Map(
    (scores ?? []).map((score) => [
      score.scoreTypeName,
      { ...score, roundedScoreValue: Math.floor(score.scoreValue) },
    ])
  );
  const stability = scoreRecordMap.get('stability');
  const fundamentals = scoreRecordMap.get('fundamentals');

  return (
    <div>
      {isVisibleScoreProgressBar(stability) && (
        <FinanceProgressBar
          title="Финансовая устойчивость"
          step={stability.roundedScoreValue}
          description={getFinancialStabilityDescription(
            stability.roundedScoreValue
          )}
          tooltip={
            <>
              <b>{tooltipInfo.financialStability.title}</b>
              {tooltipInfo.financialStability.content}
            </>
          }
        />
      )}
      {isVisibleScoreProgressBar(fundamentals) && (
        <FinanceProgressBar
          title="Price valuation"
          step={fundamentals.roundedScoreValue}
          description={getPriceValuationDescription(
            fundamentals.roundedScoreValue
          )}
          tooltip={
            <>
              <b>{tooltipInfo.priceValuation.title}</b>
              {tooltipInfo.priceValuation.content}
            </>
          }
        />
      )}
      {data && <Chart data={data} hasDimesion />}

      <Typography.TitleMobile
        view="xsmall"
        tag="div"
        weight="bold"
        color="secondary"
        className={classes.costEstimateTitle}
      >
        Оценка стоимости
      </Typography.TitleMobile>
      <InstrumentDataRowGrid
        titleLeft="Капитализация"
        titleRight="Стоим. без долгов"
        valueLeft={
          <>
            <Amount.Pure
              value={
                roundedMarketCapitalization *
                (marketCapitalizationPostfix ? 100 : 1)
              }
              minority={marketCapitalizationPostfix ? 100 : 1}
              view="withZeroMinorPart"
              currency={currency}
            />
            &nbsp;{marketCapitalizationPostfix}
          </>
        }
        valueRight={
          <>
            <Amount.Pure
              value={
                roundedEnterpriseValue * (enterpriseValuePostfix ? 100 : 1)
              }
              minority={enterpriseValuePostfix ? 100 : 1}
              view="withZeroMinorPart"
              currency={currency}
            />
            &nbsp;{enterpriseValuePostfix}
          </>
        }
      />
      <InstrumentDataRowGrid
        titleLeft={
          <TitleWithHint
            title="EBITDA"
            gap="var(--gap-2xs)"
            contentClassName={classes.tooltipContent}
            hintContent={
              <>
                <b>
                  Earnings before interest, taxes, depreciation and amortization
                </b>
                Объём прибыли до вычета расходов по выплате процентов, налогов,
                износа и начисленной амортизации
              </>
            }
          />
        }
        valueLeft={
          <>
            <Amount.Pure
              value={roundedTtmEbitd * (ttmEbitdPostfix ? 100 : 1)}
              minority={ttmEbitdPostfix ? 100 : 1}
              view="withZeroMinorPart"
              currency={currency}
            />
            &nbsp;{ttmEbitdPostfix}
          </>
        }
      />
      <CompanyIndicatorsTable dataSource={data} />
    </div>
  );
};
