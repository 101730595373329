import { Row } from './row';

const DEFAULT_GLOBAL = {
  splitterSize: 4,
  tabEnableRename: false,
  splitterExtra: 0,
  tabSetEnableDrag: false,
  tabSetEnableMaximize: false,
  tabSetTabStripHeight: 24,
  tabSetMinWidth: 10,
  tabSetMinHeight: 10,
};

// класс для создания инстансов экрана
export class Layout {
  global: Record<string, unknown>;
  borders: unknown[];
  layout: Row;
  name: string;
  windows: boolean;

  constructor(
    name: string,
    layout: Row,
    windows = false,
    global = DEFAULT_GLOBAL
  ) {
    this.name = name;
    this.windows = windows;
    this.global = global;
    this.layout = layout;
    this.borders = [];
  }

  render() {
    return {
      global: this.global,
      borders: this.borders,
      layout: this.layout.render(),
    };
  }
}
