import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';

export const ServiceAttentionIcon = ({ className }: { className?: string }) => (
  <ExclamationMIcon
    width={10}
    height={10}
    color="#FDA136"
    className={className}
  />
);
