import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { mapInstruction } from '../lib/mapInstruction';

import { InstructionDTO } from './types/InstructionDTO';

export async function getInstructionsList() {
  const res = await fetchLkResult<InstructionDTO[] | LKResult>(
    `/api/instructions/list`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res.map(mapInstruction);
}
