import cn from 'classnames';
import { compareDesc, format, isToday, isYesterday } from 'date-fns';
import { ru } from 'date-fns/locale';
import { groupBy } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { FreeOrder } from '@terminal/core/lib/rest/lkFreeOrders';

import { FreeOrderDetailedFilter } from './FreeOrderDetailedFilter';
import { FreeOrderEmptyHistory } from './FreeOrderEmptyHistory';
import { FreeOrderFilter } from './FreeOrderFilter';
import { FreeOrderHistoryDetails } from './FreeOrderHistoryDetails';
import { FreeOrderListItem } from './FreeOrderListItem';

import { useGetFreeOrdersHistory } from '../hooks';

import { FilterActions, FilterActionsTypes, FilterState } from './types';

import styles from './FreeOrders.module.css';

export interface FreeOrderHistoryProps {
  treaty: Treaty | null;
}

const initialFilterState: FilterState = {
  text: null,
  endDate: undefined,
  startDate: undefined,
  status: [],
  isOpen: false,
  page: 1,
};

const filterReducer = (state: FilterState, action: FilterActions) => {
  switch (action.type) {
    case FilterActionsTypes.ToggleSideBar:
      return { ...state, isOpen: !state.isOpen };
    case FilterActionsTypes.ToggleStatus:
      return {
        ...state,
        status: state.status.includes(action.payload)
          ? state.status.filter((status) => status !== action.payload)
          : [...state.status, action.payload],
        page: 1,
      };
    case FilterActionsTypes.SetBlueprint:
      return {
        ...state,
        ...action.payload,
        isOpen: false,
        page: 1,
      };
    case FilterActionsTypes.SetPage:
      return { ...state, page: action.payload };
    case FilterActionsTypes.Reset:
      return initialFilterState;
    default:
      return state;
  }
};

export const FreeOrderHistory = ({ treaty }: FreeOrderHistoryProps) => {
  const [orderDetails, setOrderDetails] = useState<FreeOrder | null>(null);
  const [list, setList] = useState<
    Record<number, Array<FreeOrder>> | undefined
  >();

  const [filter, dispatch] = useReducer(filterReducer, initialFilterState);

  const { data, isLoading } = useGetFreeOrdersHistory({
    treaty: treaty?.treaty,
    endDate: filter.endDate ? format(filter.endDate, 'yyyyMMdd') : undefined,
    startDate: filter.startDate
      ? format(filter.startDate, 'yyyyMMdd')
      : undefined,
    statuses: filter.status.join(''),
    searchString: filter.text ?? '',
    page: filter.page,
  });

  const getNextHistoryPage = () => {
    dispatch({ type: FilterActionsTypes.SetPage, payload: filter.page + 1 });
  };

  useEffect(() => {
    setList(undefined);
  }, [filter.endDate, filter.text, filter.status, filter.startDate]);

  useEffect(() => {
    if (data) {
      setList((prevList) => ({
        ...prevList,
        [data?.pageNumber]: data?.orders,
      }));
    }
  }, [data]);

  const pages = list ? Object.keys(list) : [];

  const allPagesList = pages.reduce<Array<FreeOrder>>((acc, page) => {
    return list ? [...acc, ...list[Number(page)]] : [];
  }, []);

  const formattedOrders = allPagesList.map((order) => ({
    ...order,
    acceptDate: new Date(order.date).toDateString(),
  }));
  const groupedOrders = groupBy(formattedOrders, 'acceptDate');
  const orderDates = Object.keys(groupedOrders)
    .map((date) => new Date(date))
    .sort(compareDesc)
    .map((date) => date.toDateString());

  return (
    <>
      <FreeOrderFilter filter={filter} dispatch={dispatch} />
      {isLoading && <Loader />}
      {!allPagesList.length && !isLoading ? (
        <FreeOrderEmptyHistory />
      ) : (
        <>
          {!isLoading && data && (
            <div className={styles.scrollContainer}>
              <InfiniteScroll
                next={getNextHistoryPage}
                hasMore={data.pageCount !== data.pageNumber}
                loader={<Loader />}
                dataLength={10}
                height={'350px'}
              >
                {orderDates.map((orderDate) => {
                  let displayedDate: string;
                  const date = new Date(orderDate);
                  switch (true) {
                    case isToday(date):
                      displayedDate = 'Сегодня';
                      break;
                    case isYesterday(date):
                      displayedDate = 'Вчера';
                      break;
                    default:
                      displayedDate = format(date, 'dd MMMM yyyy', {
                        locale: ru,
                      });
                  }
                  return (
                    <React.Fragment key={displayedDate}>
                      <Typography.Text
                        className={styles.displayedDate}
                        view="secondary-small"
                        weight="bold"
                        color="secondary"
                      >
                        {displayedDate}
                      </Typography.Text>
                      {groupedOrders[orderDate]?.map((order) => (
                        <div className={cn(styles.listItemWrapper)}>
                          <FreeOrderListItem
                            key={order.id}
                            freeOrder={order}
                            setOrderDetails={setOrderDetails}
                          />
                        </div>
                      ))}
                    </React.Fragment>
                  );
                })}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={!!orderDetails}
          backLabel="История"
          onClose={() => setOrderDetails(null)}
        >
          {orderDetails && <FreeOrderHistoryDetails order={orderDetails} />}
        </FunctionalModalSidebar>
        <FunctionalModalSidebar
          open={filter.isOpen}
          backLabel="История"
          onClose={() => dispatch({ type: FilterActionsTypes.ToggleSideBar })}
        >
          <FreeOrderDetailedFilter filter={filter} dispatch={dispatch} />
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
