import { mapStrategyTheme } from './mapStrategyTheme';

import { EducationStrategyDetailsDTO } from '../api/types/EducationStrategyDetailsDTO';
import { EducationStrategyDetails } from '../model/types/EducationStrategyDetails';

export function mapEducationStrategyDetails(
  dto: EducationStrategyDetailsDTO
): EducationStrategyDetails {
  return {
    id: dto.id,
    name: dto.name,
    comment: dto.comment || '',
    dealsPerYear: dto.dealsPerYear,
    profitDeals: dto.profitDeals,
    profitability: dto.profitability,
    complexity: dto.complexity,
    progressTime: dto.progressTime,
    themes: dto.themes.map(mapStrategyTheme),
    registered: dto.registered,
    registrationEnabled: dto.registrationEnabled,
  };
}
