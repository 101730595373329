import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getIssuer } from '@terminal/core/lib/rest/investApi';
import { NotificationType } from '@terminal/core/types/ui';

export const useIssuer = (isin?: string) => {
  const addNotification = useNotification();

  return useQuery(['getIssuer', isin], () => getIssuer(isin || ''), {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса информации по эмитенту',
        text: error?.message || '',
      });
    },
    enabled: Boolean(isin),
  });
};
