import cn from 'classnames';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import styles from './FunctionalModal.module.css';

interface FunctionalModalProps {
  backdrop?: boolean;
  title: string;
  footer?: React.ReactNode;
  width?: number;
  height?: number;
  drawerId?: string;
  contentStyle?: React.CSSProperties;
  sidebar?: React.ReactNode;
  paddingSize?: 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl';
  children?: React.ReactNode;
  onClose: () => void;

  headerClassName?: string;
  containerClassName?: string;
  sidebarClassName?: string;
  innerClassname?: string;
}

export const FunctionalModal = ({
  backdrop = true,
  title,
  footer,
  onClose,
  paddingSize = 'm',
  headerClassName,
  containerClassName,
  sidebarClassName,
  innerClassname,
  ...props
}: FunctionalModalProps) => (
  <>
    {backdrop && <div className={styles.backdrop} onClick={onClose}></div>}
    <div
      className={cn(styles.container, containerClassName)}
      style={{ width: props.width || 586, height: props.height }}
    >
      <div className={cn(styles.header, headerClassName)}>
        <Typography.Text view="secondary-large" color="primary" weight="bold">
          {title}
        </Typography.Text>
        <IconButton
          icon={CrossMIcon}
          view="secondary"
          size="xs"
          onClick={onClose}
        />
      </div>
      <div className={styles.content}>
        {Boolean(props.sidebar) && (
          <div className={cn(styles.sidebar, sidebarClassName)}>
            {props.sidebar}
          </div>
        )}
        <div className={styles.body}>
          <div
            className={cn(styles.inner, innerClassname, {
              [styles.padd_xs]: paddingSize === 'xs',
              [styles.padd_s]: paddingSize === 's',
              [styles.padd_m]: paddingSize === 'm',
              [styles.padd_l]: paddingSize === 'l',
              [styles.padd_xl]: paddingSize === 'xl',
              [styles.padd_2xl]: paddingSize === '2xl',
            })}
            style={props.contentStyle}
          >
            {props.children}
          </div>
          {Boolean(props.drawerId) && (
            <div className={styles.drawer} id={props.drawerId}></div>
          )}
        </div>
      </div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  </>
);
