import { FilterTypes, TableColumnSetting } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

export const DISPLAY_NAME_COLUMN: TableColumnSetting = {
  key: TableColumnKey.SymbolObject,
  selected: true,
  width: 100,
  cellClassName: 'textAlignStart',
  headerCellClassName: 'alignStartColumnHeader',
  filterType: FilterTypes.Text,
};

export const PRICE_COLUMN: TableColumnSetting = {
  key: TableColumnKey.TargetPrice,
  selected: true,
  width: 120,
  cellClassName: 'textAlignStart',
  headerCellClassName: 'alignStartColumnHeader',
};

export const MESSAGE_COLUMN: TableColumnSetting = {
  key: TableColumnKey.Caption,
  selected: true,
  width: 160,
  cellClassName: 'textAlignStart',
  headerCellClassName: 'alignStartColumnHeader',
};

export const STATUS_COLUMN: TableColumnSetting = {
  key: TableColumnKey.IdState,
  selected: true,
  width: 100,
  cellClassName: 'textAlignStart',
  headerCellClassName: 'alignStartColumnHeader',
};

export const ACTIONS_COLUMN: TableColumnSetting = {
  key: TableColumnKey.IsSelected,
  selected: true,
  width: 80,
  headerCellClassName: 'alignStartColumnHeader',
};

export const COLUMNS: TableColumnSetting[] = [
  DISPLAY_NAME_COLUMN,
  PRICE_COLUMN,
  MESSAGE_COLUMN,
  STATUS_COLUMN,
  ACTIONS_COLUMN,
];
