import cn from 'classnames';
import React from 'react';
import { DocumentMIcon } from '@alfalab/icons-glyph/DocumentMIcon';
import { PencilHeavyMIcon } from '@alfalab/icons-glyph/PencilHeavyMIcon';

import { MoneyBankTemplate } from '@terminal/core/lib/rest/lkMoney';

import tablestyles from '../tables.module.css';
import styles from './TemplateItem.module.css';
import templatestabstyles from './templatesTable.module.css';

interface TemplateItemProps {
  item: MoneyBankTemplate;
  odd?: boolean;
  onSelect: (item: MoneyBankTemplate) => void;
  onRedact: () => void;
}

export const TemplateItem = ({
  item,
  odd,
  onSelect,
  onRedact,
}: TemplateItemProps) => {
  const redactClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRedact();
  };
  return (
    <div
      className={cn(
        styles.item,
        tablestyles.row,
        odd && tablestyles.rowOdd,
        odd && templatestabstyles.rowOdd
      )}
      onClick={() => onSelect(item)}
    >
      <div
        className={cn(
          tablestyles.cell,
          templatestabstyles.cell,
          templatestabstyles.cellName
        )}
      >
        <DocumentMIcon className={styles.docIcon} width={18} height={18} />
        <span className={styles.name}>{item.title}</span>
      </div>
      <div
        className={cn(
          tablestyles.cell,
          templatestabstyles.cell,
          templatestabstyles.cellAccount
        )}
      >
        {item.accountNumber}
      </div>
      <div
        className={cn(
          tablestyles.cell,
          templatestabstyles.cell,
          templatestabstyles.cellBank
        )}
      >
        {item.bankName}
      </div>
      <button
        className={styles.redact}
        onClick={redactClick}
        data-test-id="template-redact-btn"
      >
        <PencilHeavyMIcon width={18} height={18} />
      </button>
    </div>
  );
};
