import { Typography } from '@alfalab/core-components/typography';
import { CashMIcon } from '@alfalab/icons-glyph/CashMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';
import { ThumbUpMIcon } from '@alfalab/icons-glyph/ThumbUpMIcon';
import { TrashCanMIcon } from '@alfalab/icons-glyph/TrashCanMIcon';

import { SecuritiesDraftDetails } from '@terminal/core/lib/rest/lkSecurities';
import { SignOrderStatus } from '@terminal/core/lib/rest/lkSignOperation';

import { Result } from '../../../../shared/ui/Result';
import { TransferResult } from '../TransferResult';
import { DRAFT_ERRORS } from './helpers';

import { DraftCompleteResult } from './types';

import styles from './DraftCompleteScreen.module.css';

interface DraftCompleteScreenProps {
  draftComplete: DraftCompleteResult;
  onAction: () => void;
}

export const DraftCompleteScreen = ({
  draftComplete,
  onAction,
}: DraftCompleteScreenProps) => {
  const { type, result, errorCode, message } = draftComplete;

  if (type === 'reject') {
    return (
      <DraftRejectSuccess draft={draftComplete.draft} onAction={onAction} />
    );
  }

  if (type === 'confirm' && result === 'success') {
    return (
      <DraftConfirmSuccess
        orderStatus={draftComplete.status}
        onAction={onAction}
      />
    );
  }

  if (type === 'confirm' && errorCode === DRAFT_ERRORS.Limit) {
    return <DraftConfirmErrorLimits onAction={onAction} />;
  }

  if (type === 'confirm' && errorCode === DRAFT_ERRORS.Changed) {
    return <DraftConfirmErrorChanged onAction={onAction} />;
  }

  if (type === 'confirm' && errorCode === DRAFT_ERRORS.Sent) {
    return <DraftConfirmErrorSended onAction={onAction} />;
  }

  // Если не один из вариантов выше не сработал, значит что-то случилось
  return <DraftConfirmErrorDefault message={message} onAction={onAction} />;
};

// NOTE: Да, несколько компонентов в одном файле, но тут просто вариации финального экрана

interface DraftCompleteScreensProps {
  draft?: SecuritiesDraftDetails;
  message?: string | null;
  orderStatus?: SignOrderStatus | null;
  onAction: () => void;
}

export const DraftRejectSuccess = (props: DraftCompleteScreensProps) => (
  <Result title="Поручение отклонено" icon={<TrashCanMIcon />}>
    <TransferResult
      onAction={props.onAction}
      actionLabel="К списку поручений"
      content={
        props.draft && (
          <Typography.Text
            view="secondary-small"
            className={styles.text}
            tag="p"
          >
            Вы отклонили поручение на{' '}
            {props.draft.order.isin ? 'зачисление' : 'списание'}{' '}
            {props.draft.order.qty} шт {props.draft.order.assetName},
          </Typography.Text>
        )
      }
    />
  </Result>
);

export const DraftConfirmSuccess = (props: DraftCompleteScreensProps) => (
  <Result
    title={
      props.orderStatus === 'separated'
        ? 'Ценные бумаги будут зачислены и обособлены пока вы не подтвердите их приобретение у резидентов или нерезидентов из дружественных стран'
        : 'Поручение успешно подано'
    }
    icon={<ThumbUpMIcon />}
  >
    <TransferResult
      onAction={props.onAction}
      actionLabel="Подать новое поручение"
      orderStatus={props.orderStatus}
      content={
        <Typography.Text view="secondary-small" className={styles.text} tag="p">
          Посмотреть поручение вы можете в Истории переводов ценных бумаг
        </Typography.Text>
      }
    />
  </Result>
);

export const DraftConfirmErrorLimits = (props: DraftCompleteScreensProps) => (
  <Result title="Недостаточно лимита" icon={<CashMIcon />}>
    <TransferResult
      onAction={props.onAction}
      actionLabel="К списку поручений"
      content={
        <Typography.Text view="secondary-small" className={styles.text} tag="p">
          Проверьте остаток ценных бумаг и денежных средств.
          <br />
          Обратитесь к своему менеджеру.
        </Typography.Text>
      }
    />
  </Result>
);

export const DraftConfirmErrorChanged = (props: DraftCompleteScreensProps) => (
  <Result title="Поручение было изменено" icon={<PencilMIcon />}>
    <TransferResult
      onAction={props.onAction}
      actionLabel="К списку поручений"
      content={
        <Typography.Text view="secondary-small" className={styles.text} tag="p">
          Во время подписания поручение было изменено, свяжитесь со своим
          менеждером
        </Typography.Text>
      }
    />
  </Result>
);

export const DraftConfirmErrorSended = (props: DraftCompleteScreensProps) => (
  <Result
    title="Ваше поручение уже принято в работу"
    icon={<ExclamationMIcon />}
  >
    <TransferResult
      onAction={props.onAction}
      actionLabel="Подать новое поручение"
      content={
        <Typography.Text view="secondary-small" className={styles.text} tag="p">
          Скоро оно перестанет отображаться в списке.
          <br />
          Подписывать поручение снова не нужно.
        </Typography.Text>
      }
    />
  </Result>
);

export const DraftConfirmErrorDefault = (props: DraftCompleteScreensProps) => (
  <Result title="Не удалось подать поручение" icon={<CrossMIcon />}>
    <TransferResult
      onAction={props.onAction}
      actionLabel="Попробовать ещё раз"
      content={
        props.message && (
          <Typography.Text
            view="secondary-small"
            className={styles.text}
            tag="p"
          >
            {props.message}
          </Typography.Text>
        )
      }
    />
  </Result>
);
