import cn from 'classnames';

import { ActiveTransfer } from '@terminal/core/lib/rest/lkSecurities';

import { Active } from './Active';

import styles from './ActivesList.module.css';

interface ActivesListProps {
  actives: ActiveTransfer[];
  onRedact?: (index: number) => void;
  onDelete?: (index: number) => void;
  className?: string;
}

export const ActivesList = ({
  actives,
  onRedact,
  onDelete,
  className,
}: ActivesListProps) => {
  if (!actives.length) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.head}>
        <div className={cn(styles.cell, styles.cellName)}>Название</div>
        <div className={cn(styles.cell, styles.cellCount)}>Количество</div>
        {(Boolean(onRedact) || Boolean(onDelete)) && (
          <div className={cn(styles.cell, styles.cellAction)}>Действия</div>
        )}
      </div>
      {actives.map((act, index) => (
        <Active
          key={`active-${index}`}
          active={act}
          onRedact={onRedact ? () => onRedact(index) : undefined}
          onDelete={onDelete ? () => onDelete(index) : undefined}
        />
      ))}
      <div className={styles.footer}>
        <div className={cn(styles.cell, styles.cellFooter)}>
          Ценных бумаг в списке: {actives.length}
        </div>
      </div>
    </div>
  );
};
