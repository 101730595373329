import { UserCredentialsEntity } from '../lib/client/entities';

import { UserCredentials } from '../types/user';

export const userCredentialMap = (
  entity: UserCredentialsEntity
): UserCredentials => ({
  idAccount: entity.IdAccount,
  login: entity.Login,
  fullName: entity.FullName,
  demo: entity.Demo,
  cus: entity.Pin,
  zoneConnectionInfos: entity.ZoneConnectionInfos.map((zone) => ({
    FrontEndType: zone.FrontEndType,
    ConnectionKey: String(zone.ConnectionKey),
  })),
});
