import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import { useEffect, useMemo, useState } from 'react';

import { OrderBookLine } from '@terminal/core/types/orderBook';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { DefaultQuantity } from './useDefaultQuantity';

export const useOrderBookForm = (
  idFi: number,
  lines: OrderBookLine[],
  setPrice: (newPrice: number) => void,
  isLotCount?: boolean,
  finInfoExt?: FinInfoExt,
  defaultQuantity?: DefaultQuantity
) => {
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    setPrice(0);

    if (finInfoExt && !isLotCount) {
      setQuantity(defaultQuantity?.quantity ?? finInfoExt.lot);
    } else {
      setQuantity(defaultQuantity?.quantity ?? 1);
    }
  }, [defaultQuantity?.quantity, finInfoExt, idFi, isLotCount, setPrice]);

  const bestBid = useMemo(
    () =>
      maxBy(
        lines.filter((line) => line.BuyQty > 0),
        'Price'
      )?.Price || 0,
    [lines]
  );

  const bestAsk = useMemo(
    () =>
      minBy(
        lines.filter((line) => line.SellQty > 0),
        'Price'
      )?.Price || 0,
    [lines]
  );

  return {
    quantity,
    setQuantity,
    bestBid,
    bestAsk,
  };
};
