import { ANALYTICS, DIMENSIONS_SCHEMAS } from '../../../constants/analytics';
import { track } from '../core';
import type { LoadStatus } from '../model/main';

import type { Idea } from '../../../types/ideas';

/**
 * Место, где было совершено событие.
 * home - страница витрины
 * open - торговые идеи, таб открытые
 * closed - торговые идеи, таб закрытые
 * */
export type Place = 'home' | 'open' | 'closed';

export const getPlacedIdeaData = (place: Place, item: Idea) => {
  return {
    '1': place === 'home' ? 'Ideas wiget' : `${place} ideas`,
    '2': 'brokerPlus',
    '3': item.id.toString(),
    '4': item.tradeBtn.direction === 'buy' ? 'long' : 'short',
    '5': item.isin || '',
  };
};

export const getIdeaData = (item: Idea) => {
  return {
    '1': 'brokerPlus',
    '2': item.id.toString(),
    '3': item.tradeBtn.direction === 'buy' ? 'long' : 'short',
    '4': item.isin || '',
  };
};

export const list = {
  click: {
    idea: (place: Place, idea: Idea) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_LIST,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.IDEA,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: getPlacedIdeaData(place, idea),
          },
        ]
      );
    },
    swipeButton: (place: Place, idea: Idea, action: 'Buy' | 'Favorites') => {
      track(
        ANALYTICS.CATEGORY.IDEAS_LIST,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.IDEA,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              ...getPlacedIdeaData(place, idea),
              '6': action,
            },
          },
        ]
      );
    },
    favoriteList: (place: Place, idea: Idea, listName: string) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_LIST,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.FAVORITES_BS,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              ...getPlacedIdeaData(place, idea),
              '6': listName,
            },
          },
        ]
      );
    },
    chip: (place: Place, type: 'Stocks' | 'Currencies') => {
      track(
        ANALYTICS.CATEGORY.IDEAS_LIST,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.CHIPS,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              '1': place,
              '2': type,
            },
          },
        ]
      );
    },
    sort: (place: Place, type: 'Date' | 'Alphabetize' | 'Yield') => {
      track(
        ANALYTICS.CATEGORY.IDEAS_LIST,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.SORT_CHIP,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              '1': place,
              '2': type,
            },
          },
        ]
      );
    },
  },
  swipe: {
    idea: (place: Place, idea: Idea) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_LIST,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.IDEA,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: getPlacedIdeaData(place, idea),
          },
        ]
      );
    },
  },
  show: {
    favorites: (place: Place, idea: Idea) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_LIST,
        ANALYTICS.ACTION.SHOW,
        ANALYTICS.LABEL.FAVORITES_BS,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              ...getPlacedIdeaData(place, idea),
            },
          },
        ]
      );
    },
  },
  close: {
    favorites: (place: Place, idea: Idea) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_LIST,
        ANALYTICS.ACTION.CLOSE,
        ANALYTICS.LABEL.FAVORITES_BS,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              ...getPlacedIdeaData(place, idea),
              '6': 'dismiss',
            },
          },
        ]
      );
    },
  },
  insert: {
    search: (place: Place, text: string) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_LIST,
        ANALYTICS.ACTION.INSERT,
        ANALYTICS.LABEL.SEARCH_CHIP,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              '1': place,
              '2': text,
            },
          },
        ]
      );
    },
  },
};

export const screen = {
  load: (place: Place, status: LoadStatus) => {
    track(
      ANALYTICS.CATEGORY.IDEAS_LIST,
      ANALYTICS.ACTION.SHOW,
      null,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            '20': status,
          },
        },
      ]
    );
  },
  show: (place: Place): void => {
    track(
      ANALYTICS.CATEGORY.IDEAS_LIST,
      ANALYTICS.ACTION.LOAD,
      null,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            '20': 'Success',
          },
        },
      ]
    );
  },
};

export const page = {
  load: (idea: Idea, status: LoadStatus) => {
    track(
      ANALYTICS.CATEGORY.IDEAS_PAGE,
      ANALYTICS.ACTION.LOAD,
      ANALYTICS.LABEL.PAGE,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            ...getIdeaData(idea),
            '20': status,
          },
        },
      ]
    );
  },
  show: {
    favorites: (idea: Idea) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_PAGE,
        ANALYTICS.ACTION.SHOW,
        ANALYTICS.LABEL.FAVORITES_BS,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: getIdeaData(idea),
          },
        ]
      );
    },
  },
  close: {
    favorites: (idea: Idea) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_PAGE,
        ANALYTICS.ACTION.SHOW,
        ANALYTICS.LABEL.FAVORITES_BS,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              ...getIdeaData(idea),
              '5': 'dismiss',
            },
          },
        ]
      );
    },
  },
  click: {
    favorites: (idea: Idea) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_PAGE,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.FAVORITES_BS,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              ...getIdeaData(idea),
              '5': 'add',
            },
          },
        ]
      );
    },
    favoriteList: (idea: Idea, listName: string) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_PAGE,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.FAVORITES_BS,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              ...getIdeaData(idea),
              '5': listName,
            },
          },
        ]
      );
    },
    url: (idea: Idea, url: string) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_PAGE,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.MARKDOWN_LINK,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              ...getIdeaData(idea),
              '5': url,
            },
          },
        ]
      );
    },
    more: (idea: Idea, url: string) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_PAGE,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.MORE_BUTTON,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: {
              ...getIdeaData(idea),
              '5': url,
            },
          },
        ]
      );
    },
    buy: (idea: Idea) => {
      track(
        ANALYTICS.CATEGORY.IDEAS_PAGE,
        ANALYTICS.ACTION.CLICK,
        ANALYTICS.LABEL.BUY,
        null,
        null,
        [
          {
            schema: DIMENSIONS_SCHEMAS.CUSTOM,
            data: getIdeaData(idea),
          },
        ]
      );
    },
  },
};
