import { ECurrencyId } from '../../types/currencyId';

/*
 * Утилита для получения FI валюты по айдишнику объекта
 * @param idObjectCurrency: number
 * @return fi: number
 */
export function getCurrencyFi(idObjectCurrency: number): number {
  switch (idObjectCurrency) {
    case ECurrencyId.USD:
      return 149262;
    case ECurrencyId.EUR:
      return 149270;
    case ECurrencyId.GBP:
      return 169639;
    case ECurrencyId.CHF:
      return 216627;
    case ECurrencyId.CNY:
      return 1963534;
    case ECurrencyId.HKD:
      return 1960389;
  }

  return 0;
}
