import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import {
  AddAccountPreregister,
  AddAccountType,
} from './types/AddAccountPreregister';

export async function addAccountPreregister(
  type: AddAccountType
): Promise<AddAccountPreregister> {
  const res = await fetchLkResult<AddAccountPreregister | LKResult>(
    `/api/client/accounts/preregister/${type}`,
    'POST'
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}
