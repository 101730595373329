import { v4 } from 'uuid';

import { ElementType } from '@terminal/core/types/layout';

export abstract class BaseLayoutBlock {
  readonly type: ElementType;
  readonly id: string;

  protected constructor(type: ElementType) {
    this.id = v4();
    this.type = type;
  }

  abstract render(): unknown;
}
