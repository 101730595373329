import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { ContestBestTrade } from '../model/types/ContestBestTrade';
import { ContestParticipationProfileParams } from './types/ContestParticipationProfileParams';

export async function getContestBestTrades(
  data: ContestParticipationProfileParams
): Promise<ContestBestTrade[]> {
  const result = await fetchLkResult<ContestBestTrade[] | LKResult>(
    qs.stringifyUrl({
      url: '/api/contest/besttrades',
      query: { ...data },
    })
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result;
}
