import { mapColumnToDescription } from '../../constants/tableColumnsDescription';

import { TableColumnSetting } from '../../types/layout';

export function getColumnDescription(
  column: TableColumnSetting
): string | undefined {
  const { key } = column;
  const description = mapColumnToDescription[key];

  if (description) {
    return description;
  }

  if ('description' in column) {
    return column.description;
  }
}
