import { useCallback, useEffect, useState } from 'react';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { shallow, useStore } from '@terminal/core/store';

import { QualTestList } from './QualTestList';
import { QualTestPlayer } from './QualTestPlayer';

import { MainMenuType } from '../main/types';

import styles from './QualTesting.module.css';

export interface TestStatusItem {
  testId: number;
  testName: string;
  completed: boolean;
}

export function QualTesting() {
  const [selectedTestId, setSelectedTestId] = useState<number | null>(null);

  const [lkSubMenuId, setLkSubMenuId] = useStore(
    (state) => [state.lkSubMenuId, state.setLkSubMenuId],
    shallow
  );

  useEffect(() => {
    if (lkSubMenuId.options && typeof lkSubMenuId.options === 'number') {
      setSelectedTestId(lkSubMenuId.options);
    }
  }, [lkSubMenuId.options]);

  const redirectToOperations = useCallback(() => {
    setLkSubMenuId({ id: MainMenuType.Accounts, options: undefined });
  }, [setLkSubMenuId]);

  return (
    <>
      <div className={styles.container}>
        <QualTestList setSelectedTestId={setSelectedTestId} />
      </div>
      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={Boolean(selectedTestId)}
          onClose={() => setSelectedTestId(null)}
          backLabel="К списку тестов"
        >
          <div className={styles.container}>
            {selectedTestId && (
              <QualTestPlayer
                selectedTestId={selectedTestId}
                onCloseTest={() => setSelectedTestId(null)}
                redirectToOperations={redirectToOperations}
              />
            )}
          </div>
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
}

export default QualTesting;
