import { useState } from 'react';

import { MoneyLimitResult } from '@terminal/core/lib/rest/lkMoney';

import { MoneyLimitContext } from './useMoneyLimitContext';

interface MoneyLimitProviderProps {
  children?: React.ReactNode;
}

export const MoneyLimitProvider = ({ children }: MoneyLimitProviderProps) => {
  const [limit, setLimit] = useState<MoneyLimitResult | undefined>();

  return (
    <MoneyLimitContext.Provider value={{ limit, setLimit }}>
      {children}
    </MoneyLimitContext.Provider>
  );
};
