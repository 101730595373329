import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';

import styles from './FileDownloadLink.module.css';

interface FileDownloadLinkProps {
  name: string;
  pending: boolean;
  loadedUrl?: string;
  leftAddon?: React.ReactNode;
  onDownload: () => void;
}

export const FileDownloadLink = ({
  name,
  pending,
  loadedUrl,
  leftAddon,
  onDownload,
}: FileDownloadLinkProps) => {
  return (
    <div className={styles.container}>
      <Link
        className={styles.download}
        leftAddons={pending ? <Loader /> : leftAddon}
        onClick={onDownload}
      >
        {name}
      </Link>
      {!!loadedUrl && (
        <Link
          className={styles.open}
          href={loadedUrl}
          leftAddons={<OutsideMIcon width={18} height={18} />}
          target="_blank"
        >
          Открыть
        </Link>
      )}
    </div>
  );
};
