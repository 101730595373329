import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { Contest } from '../model/types/Contest';

export async function getContestList(): Promise<Contest[]> {
  const result = await fetchLkResult<Contest[] | LKResult>(
    `/api/contest/active`
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result;
}
