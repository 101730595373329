import { ChatGroupEntity } from '@terminal/core/lib/client/entities';

import { ChatGroup } from '../types';

export const chatGroupMapping = (entity: ChatGroupEntity): ChatGroup => ({
  idChatGroup: entity.IdChatGroup.toString(),
  nameChatGroup: entity.NameChatGroup,
  ifEnable: entity.ifEnable,
  idLoginChatGroup: entity.IdLoginChatGroup,
  confirmReading: entity.ConfirmReading,
  version: entity.Version?.toString() ?? '',
  operation: entity.Operation,
  idRole: entity.IdRole,
  idClientStrategy: entity.IdClientStrategy,
  nameClientStrategy: entity.NameClientStrategy,
  message: entity.Message,
  idState: entity.IdState,
  nextActiveDate: entity.NextActiveDate,
});
