import { OrderType } from '../../lib/client/entities';

//Для разных типов ордеров цена заполняется по разному
export function getOrderSendPrice(
  idOrderType: OrderType,
  inputPrice: number,
  lastPrice?: number | null,
  secondPrice?: number
) {
  let stopPrice: number | undefined;

  switch (idOrderType) {
    case OrderType.TRL:
      stopPrice = lastPrice || inputPrice;
      break;
    case OrderType.BSL:
    case OrderType.STL:
    case OrderType.STP:
    case OrderType.TRS:
      stopPrice = inputPrice;
      break;
    case OrderType.BRS:
    case OrderType.TBRS:
    case OrderType.MIT:
    case OrderType.LIT:
      stopPrice = secondPrice;
      break;

    default:
      break;
  }

  let limitPrice: number | undefined;

  switch (idOrderType) {
    case OrderType.STL:
      limitPrice = secondPrice;
      break;
    case OrderType.TRS:
      limitPrice = lastPrice || inputPrice;
      break;
    case OrderType.LMT:
    case OrderType.TRL:
    case OrderType.LIT:
    case OrderType.BRS:
    case OrderType.TBRS:
      limitPrice = inputPrice;
      break;

    default:
      break;
  }

  return { stopPrice, limitPrice };
}
