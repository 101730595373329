import { Client } from '@stomp/stompjs';

import { SmartTimer } from '@terminal/core/lib/smartTimer';

import { messageHistoryMock } from './__mocks__/messageHistory';

import {
  ChatGroupId,
  ChatMessage,
  IHistoryRequest,
  IUnreadMessageCountResponse,
} from '../types';

/**
 * Сервис чата
 *
 * Временно замокан
 *
 * @description
 * Класс реализует набор REST методов для получения информации по сообщениям: кол-во непрочитанных сообщений, истории сообщений, запроса новых непрочитанных
 *
 * Для лайв-подключения к чату необходимо пройти кросс-авторизацию чата с помощью токена паспорта и получить access-токен,
 * дальше с этим токеном открыть вебсокет соединение с сервисом чата.
 *
 * Тестовая авторизация:
 * https://testjmb.alfabank.ru/FT7JMB3/openid/crossauth?client_id=chat-api&scope=chat-api.read&refresh_token={{refreshToken}}
 * Прод:
 * https://alfa-mobile.alfabank.ru/ALFAJMB/openid/crossauth?client_id=chat-api&scope=chat-api.read&refresh_token={{refreshToken}}
 *
 * Путь для подключения к чату wss://{{host}}.ru/mobile-invest/chat/v2/ws ,
 *
 * Для разработки:
 * host = test-alfa-mobile.alfabank (testsense.alfabank - старый балансировщик)
 *
 * Для прода:
 * host = alfa-mobile.alfabank (sense.alfabank - старый балансировщик)
 *
 * API чата:
 * http://binary.moscow.alfaintra.net/artifactory/libs-snapshot-local/ru/alfabank/mobile/chat/docs/0.0.43-SNAPSHOT/docs-0.0.43-20191023.132210-1-docs.zip!/index.html
 * http://binary.moscow.alfaintra.net/artifactory/snapshots/ru/alfabank/mobile/chat/history/docs/0.0.4-SNAPSHOT/docs-0.0.4-20211127.164027-1-docs.zip!/index.html
 *
 * Нативный класс вебсокетов в вебе не умеет в заголовки запроса, поэтому используется либа SockJS, но у нас ничего не получилось)
 * Флоу SockJS описан в доке ниже:
 * https://jira.moscow.alfaintra.net/browse/CHAT-2676?focusedCommentId=2855101&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-2855101
 *
 *
 * Тестирование вебсокета: http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=869169175
 *
 * Механизм общения клиента с сервером подразумевает использование протокола STOMP, регулирующего общий вид сообщений
 * Дока по STOMP - https://stomp-js.github.io/stomp-websocket/codo/extra/docs-src/Usage.md.html
 */
class SupportService {
  private pollingTimer: SmartTimer | null = null;
  private client: Client | null = null;

  public connectChat = async (onMsg: (message: ChatMessage) => void) => {
    return;

    // TODO Доделать подключение

    // const client = new Client({
    //   brokerURL: 'wss://alfa-mobile.alfabank.ru/mobile/chat/v2/ws',
    //   reconnectDelay: 5000,
    //   heartbeatIncoming: 10000,
    //   heartbeatOutgoing: 10000,
    // });
    //
    // client.onConnect = function (event) {
    //   console.log('connect, ', event);
    // };
    // client.onStompError = function (event) {
    //   console.log('error, ', event);
    // };
    // client.onUnhandledMessage = function (event) {
    //   console.log('message, ', event);
    // };
    //
    // client.activate();
    //
    // this.client = client;
  };

  public disconnectChat = () => {
    return;

    // this.client?.deactivate();
    // this.client = null;
  };

  /**
   * Метод запрашивает историю сообщений для пользователя
   *
   * @param limit Кол-во сообщений
   * @param [messageId] Последнее сообщение, от которого запрашивать историю. По умолчанию возвращаются самые последние сообщения
   */
  public fetchChatHistory = async (
    { limit = 20, messageId }: IHistoryRequest = {
      limit: 20,
    }
  ): Promise<ChatMessage[]> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(messageId ? [] : messageHistoryMock());
      }, 3000);
    });

    // const res = await fetch(
    //   HISTORY_URL +
    //     `?sourceChannelId=AD_CHAT&channelApp=MT&limit=${limit}&${
    //       messageId ? `messageId=${messageId}` : ''
    //     }`,
    //   {
    //     method: 'GET',
    //     headers: getAuthorizationHeader(),
    //   }
    // );
    //
    // if (res.ok) {
    //   try {
    //     return res.json();
    //   } catch (error) {
    //     throw error;
    //   }
    // } else {
    //   throw new Error('Ошибка запроса истории сообщений');
    // }
  };

  /**
   * Метод запрашивает кол-во непрочитанных сообщений у пользователя
   */
  private fetchUnreadMsgCount = async ():
    | Promise<IUnreadMessageCountResponse>
    | never => {
    return new Promise<IUnreadMessageCountResponse>((resolve) =>
      setTimeout(() => resolve({ count: 0 }), 3000)
    );

    // const res = await fetch(
    //   UNREAD_MSG_COUNT_URL +
    //     '?' +
    //     new URLSearchParams({
    //       sourceChannelId: 'AD_CHAT',
    //       channelApp: 'MT',
    //     }),
    //   {
    //     method: 'GET',
    //     headers: getAuthorizationHeader(),
    //   }
    // );
    //
    // if (res.ok) {
    //   try {
    //     return await res.json();
    //   } catch (error) {
    //     throw error;
    //   }
    // } else {
    //   throw new Error('Ошибка запроса кол-ва непрочитанных сообщений');
    // }
  };

  /**
   * Метод отписывается от опроса кол-ва непрочитанных сообщений
   */
  private unsubscribeFromUnreadMsCount = () => {
    if (this.pollingTimer) {
      this.pollingTimer.clear();
      this.pollingTimer = null;
    }
  };

  /**
   * Метод подписывается на опрос кол-ва непрочитанных сообщений
   */
  subscribeToUnreadMsgCount = (
    setCb: (chatId: string, count: number) => void
  ): (() => void) => {
    let timer;

    if (this.pollingTimer) {
      timer = this.pollingTimer;
      timer.clear();
    } else {
      this.pollingTimer = timer = new SmartTimer(() => {
        this.fetchUnreadMsgCount().then(({ count }) =>
          setCb(ChatGroupId.TECH_SUPPORT, count)
        );
      }, 30000);
    }

    timer.start();

    return this.unsubscribeFromUnreadMsCount;
  };

  togglePolling = (active: boolean): boolean => {
    if (active) {
      this.pollingTimer?.resume();
    } else {
      this.pollingTimer?.pause();
    }

    return active;
  };
}

const supportService = new SupportService();
export { supportService as SupportService };
