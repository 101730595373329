import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useMemo } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { MINORITY } from '@terminal/core/constants/ui';
import { Coupons } from '@terminal/core/types/coupons';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { InstrumentDataRow } from '../../ui';

import classes from './NextPaymentBlock.module.css';

interface Props {
  coupons: Coupons;
  fullFI: ExtendedFI;
  finInfoExt?: FinInfoExt;
}

export const NextCouponsPaymentBlock = ({
  coupons,
  fullFI,
  finInfoExt,
}: Props) => {
  const { comingCoupons = [] } = coupons;
  const { objectsTable } = useAlfaDirectContext();
  const [comingCoupon] = comingCoupons;
  const { value, currency, minorUnits } = comingCoupon?.amount ?? {};
  const datePay = format(
    new Date(comingCoupon?.date ?? Date.now()),
    'dd MMMM yyyy',
    {
      locale: ru,
    }
  );
  const marketCurrency = useMemo(() => {
    const currencyObject = objectsTable.get(
      'idObject',
      fullFI?.idObjectCurrency
    );

    return currencyObject?.symbolObject;
  }, [fullFI?.idObjectCurrency, objectsTable]);

  return (
    <div className={classes.nextPaymentContainer}>
      <Typography.TitleMobile
        view="xsmall"
        tag="div"
        weight="bold"
        color="secondary"
        className={classes.nextPaymentTitle}
      >
        Ближайшая выплата
      </Typography.TitleMobile>
      <InstrumentDataRow title="Дата выплаты" value={datePay} />
      {comingCoupon?.rate ? (
        <InstrumentDataRow
          title="Доходность"
          value={<>{comingCoupon.rate} %</>}
        />
      ) : null}
      {value ? (
        <InstrumentDataRow
          title="Размер купона"
          value={
            <Amount.Pure
              value={Math.floor((value * MINORITY) / (minorUnits ?? 1))}
              currency={currency as 'RUB'}
              minority={MINORITY}
              view="withZeroMinorPart"
            />
          }
        />
      ) : null}
      {finInfoExt?.accruedInt ? (
        <InstrumentDataRow
          className={classes.beforeInfoMessage}
          title="Накопленный купонный доход"
          value={
            <Amount.Pure
              value={Math.floor((finInfoExt?.accruedInt ?? 0) * MINORITY)}
              currency={marketCurrency as 'RUB'}
              minority={MINORITY}
              view="withZeroMinorPart"
            />
          }
        />
      ) : null}
      <Typography.Text view="secondary-small" color="secondary">
        Купоны выплачиваются в соответствии с установленным облигацией планом
      </Typography.Text>
    </div>
  );
};
