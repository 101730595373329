import { useMemo, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Input } from '@alfalab/core-components/input';
import {
  PasswordInput,
  PasswordInputProps,
} from '@alfalab/core-components/password-input';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';

import { TradingCertificateLock } from '@terminal/common/features-DEPRECATED/TradingCertificateLock';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { useStore } from '@terminal/core/store';

import {
  PASSWORD_CHANGE_ERROR,
  usePasswordChange,
} from '../../../entities/Profile';
import { isFeatureHidden, useFeatureSettings } from '../../../shared';

export const PasswordChange = () => {
  const { terminalName } = useAppContext();

  const [passwords, setPasswords] = useState<{
    first?: string;
    second?: string;
    old?: string;
  }>({});
  const [errorOld, setErrorOld] = useState<string | null>(null);
  const [errorNew, setErrorNew] = useState<string | null>(null);
  const [errorConfirm, setErrorConfirm] = useState<string | null>(null);

  const { data: featureSettings = [] } = useFeatureSettings();

  const needOldPassword = useMemo(() => {
    return (
      featureSettings.length > 0 &&
      !isFeatureHidden('request-old-password', featureSettings)
    );
  }, [featureSettings]);

  const { isLoading, mutateAsync: sendPasswordChangeRequest } =
    usePasswordChange();

  const user = useStore.getState().user;

  const clearErrors = () => {
    setErrorNew(null);
    setErrorConfirm(null);
    setErrorOld(null);
  };

  const resetForm = () => {
    setPasswords({
      first: '',
      second: '',
      old: '',
    });
    clearErrors();
  };

  const handleChange: PasswordInputProps['onChange'] = (event, { value }) => {
    clearErrors();
    setPasswords((prev) => ({
      ...prev,
      [event.target.id]: value,
    }));
  };

  const isReady = () => {
    if (needOldPassword) {
      if (!passwords.old) {
        return false;
      }
    }

    return Boolean(passwords.first && passwords.second);
  };

  const handleSavePassword = async () => {
    if (passwords.first !== passwords.second) {
      setErrorConfirm('Пароли не совпадают');
    } else if (passwords.first === passwords.old) {
      setErrorNew('Текущий и новый пароли не должны совпадать');
    } else {
      clearErrors();

      if (passwords?.first?.trim()) {
        await sendPasswordChangeRequest({
          params: {
            password: passwords.first,
            oldPassword: needOldPassword ? passwords.old : undefined,
          },
          signOptions: {
            successTitle: 'Пароль успешно изменён',
            successText: `Пароль изменён для всех систем ${terminalName}`,
            successCallback: () => {
              resetForm();
            },
            errorCallback: (err) => {
              if (err?.code === PASSWORD_CHANGE_ERROR.WRONG_PASSWORD) {
                setErrorOld('Неверный пароль');
              } else if (err?.code === PASSWORD_CHANGE_ERROR.SAME_PASSWORD) {
                setErrorNew('Текущий и новый пароли не должны совпадать');
              }
            },
          },
        });
      }
    }
  };

  return (
    <Space fullWidth>
      <Typography.Text
        view="primary-medium"
        weight="bold"
        tag="p"
        defaultMargins={false}
      >
        Данные для входа в систему
      </Typography.Text>
      <span>
        <Input
          label="Логин"
          labelView="outer"
          size="s"
          disabled
          value={user?.login}
        />
      </span>
      {needOldPassword && (
        <span>
          <PasswordInput
            label="Текущий пароль"
            labelView="outer"
            placeholder="Введите текущий пароль"
            id="old"
            size="s"
            value={passwords.old}
            onChange={handleChange}
            error={errorOld}
          />
        </span>
      )}
      <span>
        <PasswordInput
          label="Новый пароль"
          labelView="outer"
          placeholder="Введите новый пароль"
          id="first"
          size="s"
          value={passwords.first}
          onChange={handleChange}
          error={errorNew}
        />
      </span>
      <span>
        <PasswordInput
          label="Повторите пароль"
          labelView="outer"
          placeholder="Введите новый пароль"
          id="second"
          size="s"
          value={passwords.second}
          onChange={handleChange}
          error={errorConfirm}
          hint={`Пароль будет изменен для всех систем ${terminalName}.`}
        />
      </span>
      <TradingCertificateLock>
        <Button
          size="s"
          leftAddons={
            <CheckmarkMIcon fill="var(--color-light-graphic-secondary)" />
          }
          disabled={!isReady()}
          onClick={handleSavePassword}
          loading={isLoading}
        >
          Сохранить
        </Button>
      </TradingCertificateLock>
    </Space>
  );
};
