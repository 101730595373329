import cn from 'classnames';
import { Duration, format, sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { CalendarInput } from '@alfalab/core-components/calendar-input';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Input } from '@alfalab/core-components/input';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import {
  MoneyHistoryFilter,
  MoneyHistorySettings,
} from '@terminal/core/lib/rest/lkMoney';

import { areDatesOverlapping } from '../../components/Filter';
import { FilterButton } from '../FilterButton';
import {
  isDateValid,
  isFilterValid,
  setFilterProp,
} from './filterManipulation';

import styles from './HistoryFilter.module.css';

const dateFormat = 'dd.MM.yyyy';

interface HistoryFilterProps {
  filter: MoneyHistoryFilter;
  settings: MoneyHistorySettings | null;
  settingsPending: boolean;
  open: boolean;
  onSetFilter: (filter: MoneyHistoryFilter) => void;
  onResetFilter: () => void;
  onClose: () => void;
}

// TODO: Возможно, компонент фильтра можно унифицировать для других моделей
export const HistoryFilter = ({
  filter,
  settings,
  settingsPending,
  open,
  onSetFilter,
  onResetFilter,
  onClose,
}: HistoryFilterProps) => {
  const [localFilter, setLocalFilter] = useState<MoneyHistoryFilter>(filter);

  useEffect(() => {
    setLocalFilter(filter);
  }, [filter]);

  const setPeriod = (period: Duration) => {
    const endDate = new Date();
    const startDate = sub(endDate, period);

    setLocalFilter((prevFilter) => ({
      ...prevFilter,
      startDate: format(startDate, dateFormat),
      endDate: format(endDate, dateFormat),
    }));
  };

  const isPeriod = (period: Duration) => {
    const endDate = new Date();
    const startDate = sub(endDate, period);

    return (
      format(startDate, dateFormat) === localFilter.startDate &&
      format(endDate, dateFormat) === localFilter.endDate
    );
  };

  const setProp = (key: keyof MoneyHistoryFilter, value: string) => {
    const newFilter = setFilterProp(localFilter, key, value);

    setLocalFilter(newFilter);
  };

  const applyFilter = () => {
    onSetFilter(localFilter);
    onClose();
  };

  const resetFilter = () => {
    onResetFilter();
    onClose();
  };

  const datesOverlapping = areDatesOverlapping(
    localFilter.startDate,
    localFilter.endDate
  );

  const calendarFilterErrors = {
    startDate: !isDateValid(localFilter.startDate) || datesOverlapping,
    endDate: !isDateValid(localFilter.endDate) || datesOverlapping,
  };

  return (
    <Portal id="money-transfer-modals">
      <FunctionalModalSidebar open={open} backLabel="История" onClose={onClose}>
        <div className={styles.body} data-test-id="history-filter-body">
          {settingsPending && <Loader dataTestId="history-filter-loader" />}
          {!settingsPending && settings && (
            <>
              <Typography.Text view="primary-small" weight="bold" tag="p">
                Фильтры
              </Typography.Text>

              <div className={styles.section}>
                <Typography.Text
                  view="secondary-large"
                  tag="p"
                  color="secondary"
                  weight="bold"
                >
                  Период
                </Typography.Text>
                <div className={cn(styles.types, styles.values)}>
                  <FilterButton
                    onClick={() => setPeriod({ weeks: 1 })}
                    checked={isPeriod({ weeks: 1 })}
                  >
                    Неделя
                  </FilterButton>
                  <FilterButton
                    onClick={() => setPeriod({ months: 1 })}
                    checked={isPeriod({ months: 1 })}
                  >
                    Месяц
                  </FilterButton>
                </div>
                <Row className={styles.fields} justify="left" gutter={16}>
                  <Col width={5}>
                    <CalendarInput
                      label="Начало периода"
                      labelView="outer"
                      size="s"
                      block
                      value={localFilter.startDate}
                      onChange={(e, { value }) => setProp('startDate', value)}
                      error={calendarFilterErrors.startDate}
                    />
                  </Col>
                  <Col width={5}>
                    <CalendarInput
                      label="Конец периода"
                      labelView="outer"
                      size="s"
                      block
                      value={localFilter.endDate}
                      onChange={(e, { value }) => setProp('endDate', value)}
                      error={calendarFilterErrors.endDate}
                    />
                  </Col>
                </Row>
              </div>

              <div className={styles.section}>
                <Typography.Text
                  view="secondary-large"
                  tag="p"
                  color="secondary"
                  weight="bold"
                >
                  Тип перевода
                </Typography.Text>
                <div className={cn(styles.types, styles.values)}>
                  {settings.types.map((type) => (
                    <FilterButton
                      key={`type-${type.name}`}
                      checked={
                        (localFilter.tranTypes || []).indexOf(type.name) >= 0
                      }
                      onClick={() => setProp('tranTypes', type.name)}
                    >
                      {type.description}
                    </FilterButton>
                  ))}
                </div>
              </div>

              <div className={styles.section}>
                <Typography.Text
                  view="secondary-large"
                  tag="p"
                  color="secondary"
                  weight="bold"
                >
                  Валюта
                </Typography.Text>
                <div className={cn(styles.types, styles.values)}>
                  {settings.currencies.map((c) => (
                    <FilterButton
                      key={`type-${c.code}`}
                      checked={
                        (localFilter.currencies || []).indexOf(c.code) >= 0
                      }
                      onClick={() => setProp('currencies', c.code)}
                    >
                      {c.name}
                    </FilterButton>
                  ))}
                </div>
              </div>

              <div className={styles.section}>
                <Typography.Text
                  view="secondary-large"
                  tag="p"
                  color="secondary"
                  weight="bold"
                >
                  Корпоративные выплаты
                </Typography.Text>
                <div className={cn(styles.types, styles.values)}>
                  {settings.incomes.map((inc) => (
                    <FilterButton
                      key={`type-${inc.name}`}
                      checked={
                        (localFilter.incomes || []).indexOf(inc.name) >= 0
                      }
                      onClick={() => setProp('incomes', inc.name)}
                    >
                      {inc.description}
                    </FilterButton>
                  ))}
                </div>
              </div>

              <div className={cn(styles.section, styles.wide)}>
                <Input
                  label="Комментарий"
                  labelView="outer"
                  block
                  size="s"
                  placeholder="Все"
                  value={localFilter.searchString}
                  onChange={(e, { value }) => setProp('searchString', value)}
                  addonsClassName={styles.inputSmallAddons}
                  rightAddons={<MagnifierMIcon width={18} height={18} />}
                />
              </div>

              <div className={cn(styles.submit, styles.wide)}>
                <Row className={styles.fields} justify="left" gutter={16}>
                  <Col width={6}>
                    <Button
                      size="s"
                      block
                      view="secondary"
                      leftAddons={<CheckmarkMIcon width={18} height={18} />}
                      onClick={applyFilter}
                      disabled={!isFilterValid(localFilter)}
                    >
                      Применить
                    </Button>
                  </Col>
                  <Col width={6}>
                    <Button
                      size="s"
                      block
                      view="tertiary"
                      leftAddons={<CrossMIcon width={18} height={18} />}
                      onClick={resetFilter}
                    >
                      Сбросить
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </FunctionalModalSidebar>
    </Portal>
  );
};
