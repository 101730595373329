import cn from 'classnames';
import { ReactNode } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';

import styles from './TradingStub.module.css';

interface Props {
  size?: 's' | 'xs';
  icon?: ReactNode;
  title: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
  isFullHeight?: boolean;
}

export const TradingStub = ({
  size = 's',
  icon,
  title,
  description,
  actions,
  isFullHeight,
}: Props) => {
  const isSizeXs = size === 'xs';
  const isSizeS = size === 's';

  return (
    <div
      className={cn(styles.container, {
        [styles.container_size_xs]: isSizeXs,
        [styles.container_size_s]: isSizeS,
        [styles.container_fullHeight]: isFullHeight,
      })}
    >
      {icon && (
        <div
          className={cn(styles.iconBlock, {
            [styles.iconBlock_small]: isSizeS || isSizeXs,
          })}
        >
          <SuperEllipse size={48}>{icon}</SuperEllipse>
        </div>
      )}

      <Gap size={isSizeXs ? 's' : 'm'} />

      {isSizeS && (
        <Typography.Text
          view="primary-medium"
          tag="div"
          weight="bold"
          color="primary"
          className={styles.sheetTitle}
        >
          {title}
        </Typography.Text>
      )}
      {isSizeXs && (
        <Typography.Text
          view="primary-small"
          tag="div"
          weight="bold"
          color="primary"
          className={styles.sheetTitle}
        >
          {title}
        </Typography.Text>
      )}

      <Gap size={isSizeXs ? '2xs' : 'xs'} />

      {isSizeS && (
        <Typography.Text
          view="secondary-large"
          color="secondary"
          className={styles.sheetText}
        >
          {description}
        </Typography.Text>
      )}
      {isSizeXs && (
        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={styles.sheetText}
        >
          {description}
        </Typography.Text>
      )}

      <Gap size={isSizeXs ? 's' : 'm'} />

      {actions}
    </div>
  );
};
