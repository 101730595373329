import cn from 'classnames';
import {
  SuperEllipse,
  SuperEllipseProps,
} from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';

import styles from './Result.module.css';

interface ResultProps {
  title: React.ReactNode;
  text?: React.ReactNode;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  maxWidth?: number;
  ellipseColor?: SuperEllipseProps['backgroundColor'];
  className?: string;
}

export const Result = ({
  title,
  text,
  icon,
  maxWidth = 240,
  ellipseColor,
  className,
  ...props
}: ResultProps) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.icon}>
      <SuperEllipse size={48} backgroundColor={ellipseColor}>
        {icon || <ExclamationMIcon />}
      </SuperEllipse>
    </div>
    <Typography.Text
      view="primary-medium"
      color="primary"
      tag="p"
      weight="bold"
      defaultMargins={false}
      className={styles.title}
      style={{ maxWidth: maxWidth }}
    >
      {title}
    </Typography.Text>
    {text && (
      <Typography.Text
        view="secondary-small"
        color="secondary"
        tag="p"
        defaultMargins={false}
        style={{ maxWidth: maxWidth }}
      >
        {text}
      </Typography.Text>
    )}
    <div className={styles.content}>{props.children}</div>
  </div>
);
