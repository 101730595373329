import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useRemoteConfig } from '@terminal/common/entities/RemoteConfig';
import {
  ServiceNotifications,
  TECH_MESSAGE_CODE,
} from '@terminal/common/entities/ServiceNotifications';
import {
  IS_NEW_DOMAIN_AVAILABLE,
  USE_PASSPORT_AUTH,
  USE_PASSPORT_CERBERUS_AUTH,
} from '@terminal/core/env';
import { localStorageBackup } from '@terminal/core/lib/localStorageBackup';
import { useStore } from '@terminal/core/store';
import { AppState } from '@terminal/core/types/app';

import { LoginPinCodeForm } from '../../features/Login';
import { FormContainer } from './FormContainer';
import { Login2faForm } from './Login2faForm';
import { LoginCaptchaForm } from './LoginCaptchaForm';
import { LoginForm } from './LoginForm';
import { LoginWithCerberus } from './LoginWithCerberus';
import { LoginWithPassport } from './LoginWithPassport';

import styles from './Login.module.css';

interface LoginProps {
  appLogo: JSX.Element | null;
  copyright: string;
  theme?: string;
}

const shouldRedirectToNewDomain =
  IS_NEW_DOMAIN_AVAILABLE && localStorageBackup.isBackupDone();

export function Login({ appLogo, copyright, theme }: LoginProps) {
  const clientState = useStore((store) => store.appState);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const state = (location.state ?? {}) as { redirect: string; search: string };
  const redirect = state?.redirect ?? searchParams.get('redirect') ?? '/';
  const search = state?.search ?? '';
  const fullRedirect = encodeURIComponent(`${redirect}${search}`);

  useEffect(() => {
    if (shouldRedirectToNewDomain) {
      window.location.href = localStorageBackup.getRedirectUrl(redirect);
    }
  }, [redirect]);

  if (shouldRedirectToNewDomain) {
    return null;
  }

  if (
    [
      AppState.AUTHORIZING_CREATE_PIN_CODE,
      AppState.AUTHORIZING_ENTER_PIN_CODE,
    ].includes(clientState)
  ) {
    return <LoginPinCodeForm theme={theme} />;
  }

  return (
    <>
      <ServiceNotifications
        code={TECH_MESSAGE_CODE.AUTHORIZATION}
        containerClassName={styles.fixedContainer}
        className={styles.notification}
        variant="banner"
        useRemoteConfig={useRemoteConfig}
      />
      <FormContainer appLogo={appLogo} copyright={copyright}>
        <LoginMethod redirect={fullRedirect} />
      </FormContainer>
    </>
  );
}

export function LoginMethod({ redirect }: { redirect: string }) {
  const clientState = useStore((store) => store.appState);

  if (USE_PASSPORT_AUTH && !USE_PASSPORT_CERBERUS_AUTH) {
    if (
      clientState === AppState.NOT_READY ||
      clientState === AppState.AUTHORIZING_PASSPORT
    ) {
      return (
        <LoginWithPassport
          redirect={redirect}
          inProgress={clientState === AppState.AUTHORIZING_PASSPORT}
        />
      );
    }
  }

  if (USE_PASSPORT_AUTH && USE_PASSPORT_CERBERUS_AUTH) {
    return <LoginWithCerberus />;
  }

  switch (clientState) {
    case AppState.NOT_READY:
    case AppState.AUTHORIZING_FRONTENDS:
    case AppState.AUTHORIZING_LK:
      return <LoginForm />;
    case AppState.AUTHORIZING_2FA:
      return <Login2faForm />;
    case AppState.AUTHORIZING_CAPTCHA:
      return <LoginCaptchaForm />;
  }

  return null;
}
