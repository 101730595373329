import cn from 'classnames';

import styles from './Summary.module.css';

interface SummaryFieldProps {
  label: React.ReactNode;
  value: React.ReactNode;
  fullWidth?: boolean;
}

export const SummaryField = ({
  label,
  value,
  fullWidth = false,
}: SummaryFieldProps) => (
  <div className={cn(styles.field, { [styles.fieldFull]: fullWidth })}>
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{value}</div>
  </div>
);
