import { useAlfaDirectContext, useFullFI } from '@terminal/alfadirect/hooks';
import { getStepDecimals } from '@terminal/core/lib/format';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import {
  getObjectTypeCategory,
  ObjectTypeCategory,
} from '@terminal/core/types/objectTypes';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { useBaseIsin } from './useBaseIsin';

export interface InstrumentInfo {
  fullFI: ExtendedFI;
  finInfoExt?: FinInfoExt;
  objectTypeCategory?: ObjectTypeCategory;
  priceDecimals: number;
  isin?: string;
}

export const useInstrumentInfo = (idFI: number): InstrumentInfo => {
  const { useFinInfoExt } = useAlfaDirectContext();

  const finInfoExt = useFinInfoExt(idFI);
  const finInfoExtByIdFi = finInfoExt[idFI];
  const { priceDecimals } = getStepDecimals(finInfoExtByIdFi?.priceStep);

  const fullFI = useFullFI(idFI);

  if (!fullFI) {
    throw new Error(
      'Не найдена необходимая информация о финансовом инструменте'
    );
  }

  const objectTypeCategory = getObjectTypeCategory(fullFI.idObjectType?.value);

  return {
    objectTypeCategory,
    fullFI,
    finInfoExt: finInfoExtByIdFi,
    priceDecimals,
    isin: useBaseIsin(idFI, objectTypeCategory),
  };
};
