import { Operation } from '../lib/client/entities';

export interface SubAccountPositionItem {
  isGift?: boolean;
  idGift?: string;
  idAccount: number;
  idSubAccount: number;
  idObject: number;
  idPosition: number;
  idFIBalance: number;
  backDate: Date;
  backUchPriceRur: number;
  backPos: number;
  awPriceBuy: number;
  awPriceSell: number;
  curRate: number;
  PSTNKD: number;
  trnIn: number;
  trnOut: number;
  buyQty: number;
  sellQty: number;
  trdPL: number;
  uchPriceRur: number;
  uchPriceEUR: number;
  uchPriceGBP: number;
  uchPriceUSD: number;
  uchPrice: number;
  idRazdel: number;
  marginDiscountBuyD0: number;
  marginDiscountSellD0: number;
  marginDiscountBuyDX: number;
  marginDiscountSellDX: number;
  variationMargin: number;
  sessionBuyQty: number;
  sessionBuyVal: number;
  sessionSellQty: number;
  sessionSellVal: number;
  subAccNalPos: number;
  version: string;
  operation: Operation;
}

export enum PUShowMode {
  ANY = 0,
  UP_DAY = 1,
  UP_ALL = 2,
  DOWN_DAY = 3,
  DOWN_ALL = 4,
}
