import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { AddAccountPreregisterAI } from '../model/types/AddAccountPreregisterAI';
import { AddAccountTypeAI } from '../model/types/AddAccountTypeAI';

export async function addAccountPreregisterAI(
  type: AddAccountTypeAI
): Promise<AddAccountPreregisterAI> {
  const res = await fetchLkResult<AddAccountPreregisterAI | LKResult>(
    `/api/client/accounts/preregister/${type}`,
    'POST'
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}
