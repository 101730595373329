import React, { FC, useCallback, useRef } from 'react';
import { Divider } from '@alfalab/core-components/divider';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { SettingsCompactSIcon } from '@alfalab/icons-glyph/SettingsCompactSIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';

import { useWidgetContext } from '../../shared';

import styles from './WidgetSettings.module.css';

export interface WidgetSettingsProps {
  open: boolean;
  groups: { id: string; title?: string; items: JSX.Element[] }[];
  onShowRequest: () => void;
  onHideRequest: () => void;
}

export const WidgetSettings: FC<WidgetSettingsProps> = ({
  open,
  groups,
  onShowRequest,
  onHideRequest,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const { DropdownComponent } = useWidgetContext();

  const toggleOpen = useCallback(() => {
    if (open) {
      onHideRequest();
    } else {
      onShowRequest();
    }
  }, [open, onHideRequest, onShowRequest]);

  useOnClickOutside(popupRef, (ev) => {
    if (wrapperRef.current?.contains(ev.target as Node)) {
      return;
    }

    onHideRequest();
  });

  const totalGroups = groups.length;

  return (
    <div ref={wrapperRef}>
      <IconButton
        ref={buttonRef}
        size="xs"
        view="secondary"
        icon={SettingsCompactSIcon}
        onClick={toggleOpen}
      />
      <div>
        <DropdownComponent
          ref={dropdownRef}
          anchorElement={buttonRef.current}
          open={open}
          offset={[0, 12]}
          position="bottom-start"
        >
          <div ref={popupRef}>
            {groups.map((group, i) => (
              <div key={group.id}>
                {group.title ? (
                  <Typography.Text
                    weight="bold"
                    tag="div"
                    view="secondary-large"
                    className={styles.groupTitle}
                  >
                    {group.title}
                  </Typography.Text>
                ) : null}
                {group.items.map((item: JSX.Element) => {
                  return item ? (
                    <Dropdown.Item
                      key={item.key}
                      className={styles.dropdownItem}
                      content={item}
                    />
                  ) : null;
                })}
                {i < totalGroups - 1 && <Divider className={styles.divider} />}
              </div>
            ))}
          </div>
        </DropdownComponent>
      </div>
    </div>
  );
};
