import { useQuery } from 'react-query';

import {
  BankCardsResponse,
  getBankCards,
} from '@terminal/core/lib/rest/lkBankCard';

import { LkQueryKeys } from '../../queryKeys';

export const useCardList = (treaty?: number) => {
  const query = useQuery<BankCardsResponse | null, Error>(
    [LkQueryKeys.CardList],
    async () => {
      if (!treaty) {
        return null;
      }
      return await getBankCards({ treaty });
    },
    {
      enabled: !!treaty,
      staleTime: 30 * 1000,
    }
  );

  return query;
};
