import React, { useEffect, useState, VFC } from 'react';
import { useLocation } from 'react-router-dom';
import { useInterval } from 'react-use';
import { Button } from '@alfalab/core-components/button/Component.responsive';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { NEW_APP_DOMAIN } from '@terminal/core/env';
import { localStorageBackup } from '@terminal/core/lib/localStorageBackup';
import { plural } from '@terminal/core/lib/plural';

import styles from './NewDomainMessage.module.css';

export const NewDomainMessage: VFC = () => {
  const [seconds, setSeconds] = useState<number>(5);
  const { state } = useLocation();
  const redirectUrl = localStorageBackup.getRedirectUrl(state?.redirect);

  useInterval(
    () => {
      const updatedSeconds = Math.max(0, seconds - 1);

      setSeconds(updatedSeconds);

      if (updatedSeconds === 0) {
        window.location.href = redirectUrl;
      }
    },
    seconds > 0 ? 1000 : null
  );

  useEffect(() => {
    localStorageBackup.markBackupAsDone();
  }, []);

  return (
    <div className={styles.root}>
      <Button href={redirectUrl} view="accent">
        Перейти на {NEW_APP_DOMAIN}
      </Button>
      <Gap size="s" />
      <Typography.Text tag="p" view="secondary-medium" defaultMargins={false}>
        Вы будете перенаправлены на новый домен {NEW_APP_DOMAIN} через {seconds}{' '}
        {plural(['секунду', 'секунды', 'секунд'], seconds)}
      </Typography.Text>
      <Gap size="l" />
    </div>
  );
};
