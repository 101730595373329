import { GetState } from 'zustand';

import { RootStore, StoreSet } from '../useStore';

import { UserCredentials } from '../../types/user';

export interface UserSlice {
  user: UserCredentials | null;
  setUser: (user: UserCredentials) => void;
  credentials: UserCredentials | null;
  setCredentials: (credentials: UserCredentials) => void;
}

export const createUserSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): UserSlice => ({
  user: null,
  setUser: (user: UserCredentials) =>
    set((state) => {
      state.user = user;
    }),
  credentials: null,
  setCredentials: (credentials: UserCredentials) =>
    set((state) => {
      state.credentials = credentials;
    }),
});
