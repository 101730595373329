// TODO: Удалить после обновления core-components до версии в которой эта иконка присутствует
import React, { SVGProps } from 'react';

export const ArrowDownBoldContainerMIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    role="img"
    focusable="false"
    fill="currentColor"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M6.35 8H9V2h6v6h2.65c.484 0 .75.562.442.935l-5.609 6.818A.669.669 0 0112 16a.669.669 0 01-.483-.247l-5.61-6.818A.572.572 0 016.35 8z" />
    <path d="M9.986 17.036L8.31 15H2v4a2 2 0 002 2h16a2 2 0 002-2v-4h-6.31l-1.676 2.036-.014.016c-.453.527-1.147.933-1.965.947L12 18h-.035c-.819-.015-1.512-.421-1.965-.948l-.014-.016z" />
  </svg>
);

export default ArrowDownBoldContainerMIcon;
