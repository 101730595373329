import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getDocumentsTheme } from '@terminal/core/lib/rest/lkDocuments';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';

export const useDocumentsTheme = (treaty: number | undefined) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.DocumentsTheme, treaty],
    async () => {
      if (!treaty) {
        return;
      }

      return await getDocumentsTheme({ treaty });
    },
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса списка документов',
          text: error?.message || '',
        });
      },
    }
  );
};
