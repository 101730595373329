import { useMemo, useState } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { SettingsItem } from '../../../../features/WidgetSettings';

import styles from './useSettings.module.css';

interface Settings {
  setTimerModalVisible: (visible: boolean) => void;
  timerActive: boolean;
  timer: number;
  setHighlightModalVisible: (visible: boolean) => void;
  setIsOpenColumnSettings: (state: boolean) => void;
  highlightActive: boolean;
  highlightFrom: number;
  fitColumns: () => void;
}

export const useSettings = ({
  setTimerModalVisible,
  timerActive,
  timer,
  setHighlightModalVisible,
  setIsOpenColumnSettings,
  highlightActive,
  highlightFrom,
  fitColumns,
}: Settings) => {
  const [isOpen, setIsOpen] = useState(false);

  const settingsGroups = useMemo(
    () => [
      {
        id: 'common',
        title: 'Общие',
        items: [
          <SettingsItem
            key="autoClear"
            className={styles.dropdownItemBody}
            onClick={() => {
              setTimerModalVisible(true);
              setIsOpen(false);
            }}
          >
            <Typography.Text view="secondary-large" tag="div">
              Автоочистка ленты
            </Typography.Text>
            {timerActive && timer && (
              <Typography.Text
                view="secondary-large"
                tag="div"
                color="secondary"
              >
                {timer} сек
              </Typography.Text>
            )}
          </SettingsItem>,
          <SettingsItem
            key="highlightValues"
            className={styles.dropdownItemBody}
            onClick={() => {
              setHighlightModalVisible(true);
              setIsOpen(false);
            }}
          >
            <Typography.Text view="secondary-large" tag="div">
              Подсветка значений...
            </Typography.Text>
            {Boolean(highlightActive && highlightFrom) && (
              <Typography.Text
                view="secondary-large"
                tag="div"
                color="secondary"
              >
                от&nbsp;{highlightFrom}&nbsp;шт
              </Typography.Text>
            )}
          </SettingsItem>,
        ],
      },
      {
        id: 'columns',
        title: 'Колонки',
        items: [
          <SettingsItem
            key="alignColumns"
            onClick={() => {
              fitColumns();
              setIsOpen(false);
            }}
          >
            Выровнять
          </SettingsItem>,
          <SettingsItem
            key="configureColumns"
            onClick={() => {
              setIsOpenColumnSettings(true);
              setIsOpen(false);
            }}
          >
            Настроить...
          </SettingsItem>,
        ],
      },
    ],
    [
      fitColumns,
      highlightActive,
      highlightFrom,
      setHighlightModalVisible,
      setIsOpenColumnSettings,
      setTimerModalVisible,
      timer,
      timerActive,
    ]
  );

  return useMemo(
    () => ({
      open: isOpen,
      groups: settingsGroups,
      onShowRequest: () => setIsOpen(true),
      onHideRequest: () => setIsOpen(false),
    }),
    [isOpen, settingsGroups]
  );
};
