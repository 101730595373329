import cn from 'classnames';
import { format } from 'date-fns';
import { memo, ReactNode, useRef } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { useOnHoldTouch } from '@terminal/core/hooks/useOnHoldTouch';

import { ChatMessage } from '../types';

import styles from './Message.module.css';

interface MessageProps {
  isMe: boolean;
  mobile: boolean;
  message: ChatMessage;
  hint?: ReactNode;
  onContext: (message: ChatMessage) => void;
}

export const Message = memo(
  ({ isMe, mobile, message, hint, onContext }: MessageProps) => {
    const contentRef = useRef<HTMLDivElement>(null);

    useOnHoldTouch(contentRef, () => onContext(message), 500, false);

    return (
      <div
        className={cn(
          styles.message,
          isMe ? styles.myMessage : styles.elseMessage,
          {
            [styles.withHint]: !!hint,
          }
        )}
        ref={contentRef}
      >
        <div className={styles.messageBubbleContainer}>
          <div className={styles.messageBubble}>
            <Typography.Text
              view={mobile ? 'primary-small' : 'secondary-large'}
              className={styles.text}
            >
              {message.text}
            </Typography.Text>
            <span className={styles.timeFix} />
            <Typography.Text
              view={mobile ? 'secondary-medium' : 'secondary-small'}
              color="secondary"
              className={styles.time}
            >
              {format(message.dtChatMessage, 'HH:mm')}
            </Typography.Text>
          </div>
        </div>
        {hint && (
          <Typography.Text
            view={mobile ? 'secondary-medium' : 'secondary-small'}
            className={styles.hint}
          >
            {hint}
          </Typography.Text>
        )}
      </div>
    );
  }
);
