import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getDraftList } from '@terminal/core/lib/rest/lkDrafts';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useDrafts = (treaty?: number) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.Drafts, treaty],
    async () => (treaty ? getDraftList(treaty) : Promise.resolve([])),
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса произвольных поручений',
          text: error?.message || '',
        });
      },
    }
  );
};
