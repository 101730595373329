import changelogJson from 'changelog.json';

import { MIDDLE_API_INVEST_URI } from '@terminal/core/env';
import { AppContext } from '@terminal/core/hooks/useAppContext';
import { joinPath } from '@terminal/core/lib/joinPath';
import { InstructionSupportBanner } from '@terminal/lk/entities/Education';
import { Main } from '@terminal/lk/legacy/main';
import { Securities } from '@terminal/lk/legacy/securities';
import { AddAccount } from '@terminal/lk/widgets/AddAccount';
import { Education } from '@terminal/lk/widgets/Education';
import { Manual } from '@terminal/lk/widgets/Manual';
import { MoneyTransfer } from '@terminal/lk/widgets/TransferGoinvest';

import {
  DEFAULT_ANALYTICS_DIMENSIONS,
  sendMetricsHandler,
} from '../../entities/Analytics';
import { ReactComponent as Logo } from './icons/appLogo.svg';
import { ReactComponent as MenuIcon } from './icons/menuIcon.svg';
import { ReactComponent as TextLogo } from './icons/textLogo.svg';
import lastStep from './img/onbording/lastStep.png';
import lastStepLight from './img/onbording/lastStepLight.png';
import layout from './img/onbording/layout.png';
import layoutLight from './img/onbording/layoutLight.png';
import links from './img/onbording/links.png';
import linksLight from './img/onbording/linksLight.png';
import welcome from './img/onbording/welcome.png';
import widgets from './img/onbording/widgets.png';
import widgetsFirstPart from './img/onbording/widgetsFirstPart.png';
import widgetsFirstPartLight from './img/onbording/widgetsFirstPartLight.png';
import widgetsLight from './img/onbording/widgetsLight.png';
import widgetsSecondPart from './img/onbording/widgetsSecondPart.png';
import widgetsSecondPartLight from './img/onbording/widgetsSecondPartLight.png';
import workspaces from './img/onbording/workspaces.png';
import workspacesLight from './img/onbording/workspacesLight.png';
import test1 from './img/tests/test1.png';
import test2 from './img/tests/test2.png';
import test3 from './img/tests/test3.png';
import test4 from './img/tests/test4.png';
import test5 from './img/tests/test5.png';
import test6 from './img/tests/test6.png';
import test7 from './img/tests/test7.png';
import test8 from './img/tests/test8.png';
import test9 from './img/tests/test9.png';
import test11 from './img/tests/test11.png';
import test12 from './img/tests/test12.png';
import test13 from './img/tests/test13.png';
import test14 from './img/tests/test14.png';
import test15 from './img/tests/test15.png';
import test16 from './img/tests/test16.png';

export const CONFIG: AppContext = {
  copyright: '© 2022 — 2024 Все права защищены',
  appLogo: <Logo />,
  textLogo: <TextLogo />,
  menuIcon: <MenuIcon />,
  headerLeftAddons: null,
  terminalName: 'GoInvest',
  educationDocumentLink:
    'https://cdncl.goinvest.ru/files/docs/65-go-invest-pro-instruction.pdf',
  onboarding: {
    showSecuritiesTransfersLink: true,
    images: {
      dark: {
        welcome,
        widgets,
        layout,
        lastStep,
        workspaces,
        links,
        widgetsFirstPart,
        widgetsSecondPart,
      },
      light: {
        welcome,
        widgets: widgetsLight,
        layout: layoutLight,
        lastStep: lastStepLight,
        workspaces: workspacesLight,
        links: linksLight,
        widgetsFirstPart: widgetsFirstPartLight,
        widgetsSecondPart: widgetsSecondPartLight,
      },
    },
  },
  supportChat: true,
  supportPhone: '8 (800) 101-41-01',
  supportLandlinePhone: '8 (495) 783 51 41',
  supportEmail: 'support@goinvest.ru',
  branchName: 'офис брокера',
  tests: {
    '1': test1,
    '2': test2,
    '3': test3,
    '4': test4,
    '5': test5,
    '6': test6,
    '7': test7,
    '8': test8,
    '9': test9,
    '11': test11,
    '12': test12,
    '13': test13,
    '14': test14,
    '15': test15,
    '16': test16,
  },
  changelog: changelogJson,
  withLk: true,
  moneyTransfer: <MoneyTransfer />,
  addAccount: <AddAccount />,
  securities: <Securities />,
  lkMain: <Main />,
  helpButtonWithPopover: true,
  manuals: {
    supportBanner: <InstructionSupportBanner />,
    widget: <Manual />,
  },
  education: {
    widget: <Education />,
  },
  brokerLink: 'http://goinvest.ru/aboutus',
  analytics: {
    handler: sendMetricsHandler,
    dimensions: DEFAULT_ANALYTICS_DIMENSIONS,
    snowplowLoader: () => import('../../../public/sp/sp.js'),
  },
  endpoints: {
    news: `${MIDDLE_API_INVEST_URI}/mobile/api/v1/news/external`,
    marginParams: joinPath(
      MIDDLE_API_INVEST_URI,
      'mobile/api/v1/client-risks/accounts/marginParams'
    ),
  },
};
