import { useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ListBulletedMIcon } from '@alfalab/icons-glyph/ListBulletedMIcon';

import {
  ResultModal,
  StrategyEnrollProperty,
  useEducationRegister,
} from '../../../../entities/Education';

import styles from './StrategyEnrollment.module.css';

interface StrategyEnrollmentProps {
  strategyId: number;
  onComplete?: () => void;
}

type ResultType = 'error' | 'success' | null;

export const StrategyEnrollment = ({
  strategyId,
  onComplete,
}: StrategyEnrollmentProps) => {
  const [resultType, setResultType] = useState<ResultType>(null);
  const [resultMessage, setResultMessage] = useState('');

  const { mutateAsync: enrollOnStrategy, isLoading: enrollPending } =
    useEducationRegister();

  const handleEnroll = async () => {
    const res = await enrollOnStrategy({ strategyId });

    setResultType(res.code === 0 ? 'success' : 'error');
    setResultMessage(res.message || '');
  };

  const handleClose = () => {
    setResultType(null);
    onComplete && onComplete();
  };

  return (
    <>
      <Typography.Text tag="div" className={styles.title} weight="bold">
        Как проходит работа в группах
      </Typography.Text>
      <div className={styles.properties}>
        <StrategyEnrollProperty index={1} className={styles.prop}>
          2-4 вводных часовых вебинара, посвященные теории самостоятельной
          торговли
        </StrategyEnrollProperty>
        <StrategyEnrollProperty index={2} className={styles.prop}>
          Восемь онлайн-встреч в группах до 5 человек, еженедельно по 1,5 часа
        </StrategyEnrollProperty>
        <StrategyEnrollProperty index={3} className={styles.prop}>
          Обсуждение и ответы на вопросы в закрытом телеграм-чате до 5 человек с
          трейдером-практиком
        </StrategyEnrollProperty>
        <StrategyEnrollProperty index={4} className={styles.prop}>
          Дополнительные материалы – видео и документация по выбранному вами
          методу
        </StrategyEnrollProperty>
      </div>

      <div className={styles.submit}>
        <ButtonDesktop
          block
          size="s"
          view="accent"
          onClick={handleEnroll}
          loading={enrollPending}
        >
          Записаться
        </ButtonDesktop>
      </div>

      <ResultModal
        open={resultType !== null}
        onClose={handleClose}
        title={
          resultType === 'success' ? 'Заявка принята' : 'Заявка не принята'
        }
        text={resultMessage}
        type={resultType}
      >
        <div className={styles.done}>
          <ButtonDesktop
            block
            size="s"
            view="tertiary"
            leftAddons={<ListBulletedMIcon width={18} height={18} />}
            onClick={handleClose}
          >
            К списку тем
          </ButtonDesktop>
        </div>
      </ResultModal>
    </>
  );
};
