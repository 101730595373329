import {
  ANALYTICS,
  DICTIONARIES,
  DIMENSIONS_SCHEMAS,
} from '../../constants/analytics';
import { IS_MOBILE_PWA } from '../../env';
import { getIsStandalone } from '../info';
import { track } from './core';
import { PortfolioParams } from './model/main';

/** ОШИБКИ ПРИЛОЖЕНИЯ */
export const trackApplicationError = (msg: string, trace?: string) => {
  const { href, search } = window.location;

  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.APP_ERROR,
    ANALYTICS.LABEL.ERROR,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: `Message=${msg}`,
          2: `Trace=${trace || 'No trace provided'}`,
          3: `Location=${href}${search}`,
        },
      },
    ]
  );
};

/** ЗАГРУЗКА СПРАВОЧНИКОВ */
export const trackDictionaryLoaded = (
  dictionaryName: (typeof DICTIONARIES)[keyof typeof DICTIONARIES],
  dictionaryLength: number
) => {
  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.DICTIONARY_LOADED,
    ANALYTICS.LABEL.TECH,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: `Name=${dictionaryName}`,
          2: `Length=${dictionaryLength}`,
        },
      },
    ]
  );
};

export const trackDictioanriesLoadingComplete = () => {
  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.ALL_DICTIONARIES_LOADED,
    ANALYTICS.LABEL.TECH
  );
};

/** готовность портфеля */
export const trackPortfolioLoaded = (portfolioParams: PortfolioParams) => {
  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.PORTFOLIO_LOADED,
    ANALYTICS.LABEL.TECH,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: `Accounts=${portfolioParams.accountsLength}`,
          2: `SubAccounts=${portfolioParams.subAccountsLength}`,
          3: `SubGTAccounts=${portfolioParams.subGTAccountsLength}`,
          4: `SubAccountRazdel=${portfolioParams.subAccountRazdelLength}`,
          5: `SubAccountPositions=${portfolioParams.subAccountPositionsLength}`,
          6: `Orders=${portfolioParams.ordersLength}`,
        },
      },
    ]
  );
};

export const trackAppIsOpened = () => {
  const fromInstalledPWA = IS_MOBILE_PWA && getIsStandalone();

  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.APP_IS_OPENED,
    ANALYTICS.LABEL.TECH,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: `appSource=${fromInstalledPWA ? 'PWA' : 'browser'}`,
        },
      },
    ]
  );
};

export const trackAppIsReady = () => {
  const fromInstalledPWA = IS_MOBILE_PWA && getIsStandalone();

  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.APP_IS_READY,
    ANALYTICS.LABEL.TECH,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: `appSource=${fromInstalledPWA ? 'PWA' : 'browser'}`,
        },
      },
    ]
  );
};

/** СЕРТИФИКАТЫ */
export const trackCertificateEnrollStarted = (certificatesNumber: number) => {
  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.ENROLL_STARTED,
    ANALYTICS.LABEL.CERTIFICATES,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: `AlreadyEnrolled=${certificatesNumber}`,
        },
      },
    ]
  );
};

export const trackCertificateKeysCreated = () => {
  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.KEYS_CREATED,
    ANALYTICS.LABEL.CERTIFICATES
  );
};

export const trackReadyToSendCertificate = () => {
  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.READY_TO_SEND,
    ANALYTICS.LABEL.CERTIFICATES
  );
};

export const trackCertificateEnrollError = (error: unknown) => {
  let message;

  if (error instanceof Error) {
    message = `${error.name} ${error.message} ${error.stack?.toString()}`;
  } else if (typeof error === 'string') {
    message = error;
  } else {
    message = 'Unknown error';
  }

  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.APP_ERROR,
    ANALYTICS.LABEL.CERTIFICATES,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: `Message=${message}`,
        },
      },
    ]
  );
};

export const trackCertificateEntitySent = () => {
  track(
    ANALYTICS.CATEGORY.TECH_TRACE,
    ANALYTICS.ACTION.ENTITY_SENT,
    ANALYTICS.LABEL.CERTIFICATES
  );
};
