import cn from 'classnames';
import { useState } from 'react';
import { Attach } from '@alfalab/core-components/attach';
import { Button } from '@alfalab/core-components/button/modern';
import { Checkbox, CheckboxProps } from '@alfalab/core-components/checkbox';
import { Divider } from '@alfalab/core-components/divider';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Typography } from '@alfalab/core-components/typography';

import { declension } from '@terminal/core/lib/declension';
import {
  SecuritiesTranferXmlMultipleResilt,
  SecuritiesTranferXmlMultipleSubmitRequest,
} from '@terminal/core/lib/rest/lkSecurities';

import { getOperationKey } from './helpers';
import { Operation } from './Operation';

import tablestyles from '../../../styles/tables.module.css';
import styles from './XmlOperations.module.css';

interface XmlOperationsProps {
  result: SecuritiesTranferXmlMultipleResilt;
  submitPending: boolean;
  pendingUpload: boolean;
  onFilesChange?: (files: File[]) => void;
  onSubmit?: (data: SecuritiesTranferXmlMultipleSubmitRequest) => void;
}

export const XmlOperations = ({
  result,
  submitPending,
  pendingUpload,
  onFilesChange,
  onSubmit,
}: XmlOperationsProps) => {
  const [selectedOperations, setSelectedOperations] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const toggleOperation = (id: string) => {
    setSelectedOperations((prev) => {
      const selected = [...prev];
      const index = selected.indexOf(id);
      if (index >= 0) {
        selected.splice(index, 1);
      } else {
        selected.push(id);
      }

      return selected;
    });
  };

  const toggleAllOperations: CheckboxProps['onChange'] = (_, payload) => {
    if (payload?.checked) {
      resetSelectedOperations();
    } else {
      setSelectedOperations([]);
    }
    setSelectAll(payload?.checked || false);
  };

  const resetSelectedOperations = () => {
    const selected: string[] = result.operations
      .filter((operation) => operation.code === 0)
      .map((operation) => getOperationKey(operation));
    setSelectedOperations(selected);
  };

  const handleSubmit = () => {
    if (!onSubmit) {
      return;
    }

    const data: SecuritiesTranferXmlMultipleSubmitRequest = {
      operations: result.operations.filter(
        (operation) =>
          selectedOperations.indexOf(getOperationKey(operation)) >= 0
      ),
    };

    onSubmit(data);
  };

  return (
    <div className={tablestyles.table}>
      <div className={tablestyles.head}>
        <div className={cn(tablestyles.row, tablestyles.rowHead)}>
          <div
            className={cn(
              tablestyles.cell,
              tablestyles.cellHead,
              styles.cellCheck
            )}
          >
            <Checkbox
              onChange={toggleAllOperations}
              checked={selectAll}
              size="s"
            />
          </div>
          <div
            className={cn(
              tablestyles.cell,
              tablestyles.cellHead,
              styles.cellType
            )}
          >
            Тип
          </div>
          <div
            className={cn(
              tablestyles.cell,
              tablestyles.cellHead,
              styles.cellIsin
            )}
          >
            ISIN
          </div>
          <div
            className={cn(
              tablestyles.cell,
              tablestyles.cellHead,
              styles.cellCount
            )}
          >
            Количество
          </div>
        </div>
      </div>

      <div className={tablestyles.body}>
        {result.operations.map((oper, index) => (
          <Operation
            key={oper.model.header.metadata.objectId}
            item={oper}
            checked={
              selectedOperations.indexOf(oper.model.header.metadata.objectId) >=
              0
            }
            odd={index % 2 !== 0}
            onChange={toggleOperation}
          />
        ))}
      </div>

      <div className={styles.summary}>
        <Typography.Text view="secondary-small" color="secondary" tag="div">
          Загружено {result.count}{' '}
          {declension(['позиция', 'позиции', 'позиций'], result.count)}
          <br />
          {result.countErrors}{' '}
          {declension(['ошибка', 'ошибки', 'ошибок'], result.countErrors)}
        </Typography.Text>
      </div>

      <Divider className={styles.divider} />

      <Row>
        <Col width={6}>
          <Button
            block
            view="secondary"
            size="xxs"
            disabled={selectedOperations.length === 0}
            loading={submitPending}
            onClick={handleSubmit}
          >
            Подать поручение{' '}
            {selectedOperations.length ? `(${selectedOperations.length})` : ''}
          </Button>
        </Col>
        <Col width={6}>
          <Attach
            multiple
            className={cn(styles.attach, styles.attachBlock)}
            size="xxs"
            buttonContent="Загрузить другие XML файлы"
            buttonProps={{
              block: true,
              view: 'tertiary',
              leftAddons: <div />,
              loading: pendingUpload,
            }}
            onChange={(_, { files }) => onFilesChange && onFilesChange(files)}
          />
        </Col>
      </Row>
    </div>
  );
};
