import { fetchLkResult } from '@terminal/core/lib/rest/lkApi';

import { AddAccountAdvisorDocumentsPreregister } from './types/AddAccountAdvisorDocumentsPreregister';

export async function getAdvisorDocuments(
  url: string
): Promise<AddAccountAdvisorDocumentsPreregister> {
  const res = await fetchLkResult<AddAccountAdvisorDocumentsPreregister>(url);

  return res;
}
