import debounce from 'lodash/debounce';
import log from 'loglevel';
import { z } from 'zod';

import { IS_AI, IS_GI } from '../../../env';
import { getWorkspace, saveWorkspace } from '../../rest/lkSettings';
import { storage } from '../../services/storage';
import { WindowsSync, WindowsSyncCommitType } from '../../services/windowsSync';
import { IDB } from '../';
import { WorkspaceConfigurationsZod_V1 } from './versions/version1';

import {
  getWorkspacesFromLS,
  saveWorkspacesToLS,
} from '../../../store/slices/helpers/workspaceConfigurationsHelpers';

import { AppState } from '../../../types/app';

export type WorkspaceConfigurationsTypeLast = z.infer<
  typeof WorkspaceConfigurationsZod_V1.configurations
>;

export class WorkspaceConfigurations {
  static ACTUAL_VERSION = 1;
  static isOnboarded = false;

  /** Определяем какой конфиг применить с сервера или из БД */
  static async init() {
    let actualConfig = {
      ...getWorkspacesFromLS(),
      saveTimestamp: Date.now(),
    };

    try {
      if (IS_GI) {
        const [localResult, serverResult] = await Promise.allSettled([
          this.get(),
          this.getFromServer(),
        ]);

        const localData =
          localResult.status === 'fulfilled' && localResult.value;
        const serverData =
          serverResult.status === 'fulfilled' && serverResult.value;

        const localTimestamp = localData ? localData.saveTimestamp : 0;
        const serverTimestamp = serverData ? serverData.saveTimestamp : 0;

        if (
          serverTimestamp ||
          storage.modal.onboardingDone ||
          localStorage.getItem('onboarding')
        ) {
          this.isOnboarded = true;
        }

        if (serverTimestamp > localTimestamp) {
          if (serverData) {
            actualConfig = serverData;
          }
        } else {
          if (localData) {
            actualConfig = localData;
          }
        }
      }
    } catch (e) {
      log.error('WorkspaceConfigurations init error: ', e);
    }

    return WorkspaceConfigurations.migration(actualConfig);
  }

  static migration(
    prevWorkspaceConfigurations: WorkspaceConfigurationsTypeLast
  ): WorkspaceConfigurationsTypeLast {
    return prevWorkspaceConfigurations;
  }

  static save = debounce(
    (
      workspaceConfigurations: WorkspaceConfigurationsTypeLast['workspaceConfigurations'],
      workspaceConfigurationActiveId: WorkspaceConfigurationsTypeLast['workspaceConfigurationActiveId']
    ) => {
      // @ts-ignore
      // TODO вынести логику про сохранение из useStore
      const { appState, credentials } = window.__zsstore.getState();

      if (
        appState !== AppState.READY ||
        !credentials ||
        !workspaceConfigurations[workspaceConfigurationActiveId]
      ) {
        return;
      }

      workspaceConfigurations[
        workspaceConfigurationActiveId
      ].lastAutoSavedConfig.timeStamp = Date.now();

      const config = {
        workspaceConfigurations,
        workspaceConfigurationActiveId,
        saveTimestamp: Date.now(),
      };

      if (IS_AI || WorkspaceConfigurations.ACTUAL_VERSION === 1) {
        saveWorkspacesToLS(config);
      }

      if (IS_AI) {
        return;
      }

      WindowsSync.commit(appState, {
        type: WindowsSyncCommitType.WORKSPACES,
        data: config,
      });

      if (WindowsSync.isWaitingForSync) {
        return;
      }

      saveWorkspace(config, WorkspaceConfigurations.ACTUAL_VERSION);

      return IDB.save<WorkspaceConfigurationsTypeLast>(
        'workspaceConfigurations',
        WorkspaceConfigurationsZod_V1.configurations,
        config
      );
    },
    100
  );

  static async get() {
    return IDB.get<WorkspaceConfigurationsTypeLast>('workspaceConfigurations');
  }

  static async getFromServer() {
    const data = await getWorkspace();

    if (data && data.hasOwnProperty('version')) {
      delete data.version;
    }

    return data;
  }
}
