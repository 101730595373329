import { useEffect } from 'react';

import { authorizeProlongation } from '@terminal/core/lib/rest/lkAuth';

const PROLONGATION_INTERVAL = 15 * 60 * 1000;

/**
 * В ЛК сессия авторизации существует ограниченное время и не продлевается самостоятельно.
 * Поэтому, чтобы пользователя не выкидывало, раз в 15 минут отправляется запрос на продление сессии
 */
export const LoginProlongation = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      authorizeProlongation().catch((err) => console.error(err));
    }, PROLONGATION_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return null;
};
