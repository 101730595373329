import { GetState } from 'zustand';

import { ChatStore, StoreSet } from '../useStore';

import { ChatGroup, ChatMessage, ChatSlice } from '../../types';

export const createChatSlice = (
  set: StoreSet,
  get: GetState<ChatStore>
): ChatSlice => ({
  chatGroups: {},
  setChatGroups: (groups: ChatGroup[]) => {
    set((state) => {
      const chatGroupsMap: ChatSlice['chatGroups'] = {};
      for (let group of groups) {
        chatGroupsMap[group.idChatGroup] = group;
      }

      state.chatGroups = { ...get().chatGroups, ...chatGroupsMap };
    });
  },
  openedChatId: null,
  setOpenedChatId: (chatId: string | null) =>
    set((state) => {
      state.openedChatId = chatId;
    }),
  unreadMsgCount: {},
  setUnreadMsgCount: (chatId: string, count: number) =>
    set((state) => {
      state.unreadMsgCount[chatId] = count;
    }),
  messagesLoading: false,
  historyError: false,
  allMessagesLoad: false,
  messages: {},
  setNewMessages: (chatId: string, messages: ChatMessage[]) => {
    set((state) => {
      if (!state.messages[chatId]) state.messages[chatId] = [];
      state.messages[chatId].push(...messages);
    });

    // Если чат открыт не считаем новые сообщения
    if (get().openedChatId === chatId) return;

    // Считаем кол-во сообщений, пришедших после localStorage.chatLastVisit[chatId]
    set((state) => {
      const chatLastVisit = JSON.parse(
        localStorage.getItem('chatLastVisit') || '{}'
      );

      if (chatLastVisit?.[chatId]) {
        let firstUnreadIndex = 0;

        for (
          firstUnreadIndex;
          firstUnreadIndex < messages.length;
          firstUnreadIndex++
        ) {
          if (
            messages[firstUnreadIndex].dtChatMessage >
            new Date(chatLastVisit[chatId])
          ) {
            break;
          }
        }
        state.unreadMsgCount[chatId] = messages.length - firstUnreadIndex;
      } else {
        state.unreadMsgCount[chatId] = messages.length;
      }
    });
  },
});
