import { useMemo, useState } from 'react';

import { SettingsItem, SwitchSettings } from '../../features/WidgetSettings';

interface Settings {
  isDoubleRowMode: boolean;
  switchDoubleRowMode: () => void;
  setIsOpenColumnSettings: (open: boolean) => void;
}

export const useSettings = ({
  isDoubleRowMode,
  switchDoubleRowMode,
  setIsOpenColumnSettings,
}: Settings) => {
  const [isOpen, setIsOpen] = useState(false);

  const settingsGroups = useMemo(
    () => [
      {
        id: 'table',
        title: 'Таблица',
        items: [
          <SwitchSettings
            key="doubleRow"
            checked={isDoubleRowMode}
            label="Двустрочное отображение"
            reversed
            onChange={() => {
              switchDoubleRowMode();
              setIsOpen(false);
            }}
          />,
        ],
      },
      {
        id: 'columns',
        title: 'Колонки',
        items: [
          <SettingsItem
            key="configureColumns"
            onClick={() => {
              setIsOpenColumnSettings(true);
              setIsOpen(false);
            }}
          >
            Настроить...
          </SettingsItem>,
        ],
      },
    ],
    [isDoubleRowMode, switchDoubleRowMode, setIsOpenColumnSettings]
  );

  return useMemo(
    () => ({
      open: isOpen,
      groups: settingsGroups,
      onShowRequest: () => setIsOpen(true),
      onHideRequest: () => setIsOpen(false),
    }),
    [isOpen, settingsGroups]
  );
};
