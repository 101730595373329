import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { FreeOrderSettingsParams } from '../api/types/FreeOrderSettingsParams';

export const useFreeOrderSettings = <R>(
  treaty: number | undefined,
  fetchFreeOrderSettings: (params: FreeOrderSettingsParams) => Promise<R>
) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'freeOrder/settings', { treaty }],
    async () => fetchFreeOrderSettings({ treaty: treaty || 0 }),
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса настроек произвольных поручений',
          text: error?.message || '',
        });
      },
    }
  );
};
