import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { changeTariff } from '@terminal/core/lib/rest/lkAccountInfo';
import { NotificationType } from '@terminal/core/types/ui';

export const useChangeTariff = () => {
  const addNotification = useNotification();

  return useMutation(changeTariff, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При смене тарифа произошла ошибка',
      });
    },
  });
};
