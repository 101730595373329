import { default as loglevel } from 'loglevel';

import { DEV_APP_DEVTOOLS } from '../env';

const addToDomLog = (...args: any) => {
  const el = document.getElementById('loglevel-mobile-debug');

  if (el) {
    const p = document.createElement('p');

    for (const arg of args) {
      p.append(`${arg}, `);
    }

    el.appendChild(p);
  }
};

let log = {
  ...loglevel,
};

if (DEV_APP_DEVTOOLS) {
  log = {
    ...loglevel,
    warn: (...args: any) => {
      loglevel.warn(...args);
      addToDomLog(...args);
    },
    debug: (...args: any) => {
      loglevel.warn(...args);
      addToDomLog(...args);
    },
    error: (...args: any) => {
      loglevel.warn(...args);
      addToDomLog(...args);
    },
  };
}

export default log;
