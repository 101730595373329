import isEqual from 'lodash/isEqual';
import React, { useMemo, useState } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Loader } from '@alfalab/core-components/loader';
import { Field } from '@alfalab/core-components/select/components/field';
import {
  SelectDesktop as Select,
  SelectDesktopProps as SelectProps,
} from '@alfalab/core-components/select/desktop';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Typography } from '@alfalab/core-components/typography';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import { useRequirement } from '@terminal/alfadirect/hooks';
import { MobileSelect } from '@terminal/common/components/MobileSelect';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { MarginCallInfo } from '@terminal/core/types/marginCall';

import {
  useInitialiseTreties,
  useRequirementBySubAccount,
} from '../../../entities/SelectTreaty';
import { getTreatyKey } from './helpers';

import { useTreaties } from '../../hooks';

import styles from './SelectTreaty.module.css';

export interface SelectTreatyProps {
  mobile?: boolean;
  hideLabel?: boolean;
  zeros?: boolean;
  selectedAccountId?: number;
  isDisplayRequirements?: boolean;
  treatyFilter?: (acc: Treaty) => boolean;
  hint?: React.ReactNode;
  onSelect?: (treaty: Treaty) => void;
  label?: string;
}

export const SelectTreaty = React.memo(
  ({ selectedAccountId, ...props }: SelectTreatyProps) => {
    const { onSelect, treatyFilter, mobile, hideLabel } = props;
    const [treaty, setTreaty] = useState<Treaty | null>(null);

    const { data: treaties, isFetching: pending } = useTreaties();

    const treatiesCodes = useMemo(
      () => (treaties ? treaties.map(({ name }) => name) : []),
      [treaties]
    );
    const marginCall = useRequirement(treatiesCodes);

    useInitialiseTreties({
      treaty,
      treaties,
      selectedAccountId,
      setTreaty,
      treatyFilter,
      onSelect,
    });

    return (
      <>
        {!mobile && !hideLabel && (
          <Typography.Text view="primary-small" weight="bold" tag="p">
            {props.label ?? 'Счёт'}
          </Typography.Text>
        )}
        <MemoSelect
          {...props}
          treaty={treaty}
          treaties={treaties}
          setTreaty={setTreaty}
          pending={pending}
          marginCall={marginCall}
        />
      </>
    );
  }
);

interface MemoSelectProps {
  treaty: Treaty | null;
  treaties?: Treaty[];
  setTreaty: (treaty: Treaty | null) => void;
  pending: boolean;
  marginCall: MarginCallInfo;
}

const MemoSelect = React.memo(
  ({
    mobile,
    zeros,
    treatyFilter,
    hint,
    onSelect,
    treaties,
    setTreaty,
    pending,
    treaty,
    marginCall,
    isDisplayRequirements = false,
  }: SelectTreatyProps & MemoSelectProps) => {
    const getRequirementBySubAccount = useRequirementBySubAccount({
      marginCall,
      mobile,
      isDisplayRequirements,
    });

    const requirementForSelected = useMemo(() => {
      if (treaty) {
        return getRequirementBySubAccount(treaty.name);
      }
    }, [getRequirementBySubAccount, treaty]);

    const hintContent = useMemo(() => {
      if (requirementForSelected && treaty) {
        return (
          <Typography.Text
            view="secondary-small"
            color={
              (requirementForSelected === 'immediateRequirements' &&
                'negative') ||
              (requirementForSelected === 'requirements' && 'attention') ||
              undefined
            }
          >
            {requirementForSelected === 'immediateRequirements'
              ? 'Срочное требование: внести'
              : 'Требование: внести'}
            &nbsp;
            {marginCall[treaty.name][requirementForSelected] === null ? (
              '-'
            ) : (
              <Amount.Pure
                value={marginCall[treaty.name][requirementForSelected]! * 100}
                minority={100}
                view="withZeroMinorPart"
                currency="RUB"
              />
            )}
          </Typography.Text>
        );
      } else {
        return hint;
      }
    }, [hint, marginCall, requirementForSelected, treaty]);

    const options = useMemo(() => {
      if (!treaties) {
        return [];
      }

      return treaties
        .filter(treatyFilter ? treatyFilter : () => true)
        .map((t) => {
          const hasRequirement = getRequirementBySubAccount(t.name);
          const content = zeros ? `${t.treaty} - 000` : String(t.treaty);

          return {
            key: getTreatyKey(t),
            code: t.name,
            contentSummary:
              isDisplayRequirements && mobile ? (
                <div className={styles.option}>{content}</div>
              ) : null,
            content: (
              <div className={styles.option}>
                {hasRequirement && (
                  <ExclamationCircleMIcon
                    className={styles.requirementIcon}
                    width={18}
                    height={18}
                    color={
                      hasRequirement === 'immediateRequirements'
                        ? 'var(--color-light-text-negative)'
                        : 'var(--color-light-text-attention)'
                    }
                  />
                )}
                {content}
                {t.isIis ? <span className={styles.iisLabel}>ИИС</span> : ''}
              </div>
            ),
          };
        });
    }, [
      treaties,
      treatyFilter,
      getRequirementBySubAccount,
      zeros,
      mobile,
      isDisplayRequirements,
    ]);

    const onChange: SelectProps['onChange'] = ({ selected }) => {
      const treaty = treaties?.find((t) => getTreatyKey(t) === selected?.key);

      if (treaty) {
        setTreaty(treaty);
        onSelect && onSelect(treaty);
      }
    };

    const CustomField = (props: FieldProps) => {
      const hasRequirement =
        //@ts-expect-error
        Boolean(props.selected?.code) &&
        getRequirementBySubAccount(
          //@ts-expect-error
          props.selected.code
        );

      return (
        <Field
          {...props}
          leftAddons={
            (pending && <Loader className={styles.loader} />) ||
            (props.selected && !hasRequirement && (
              <BriefcaseMIcon className={styles.icon} />
            ))
          }
        />
      );
    };

    const hasRequirement =
      mobile &&
      treaty &&
      isDisplayRequirements &&
      getRequirementBySubAccount(treaty.name);

    return !mobile ? (
      <Select
        size="s"
        block
        disabled={pending}
        options={options}
        selected={treaty ? getTreatyKey(treaty) : []}
        onChange={onChange}
        Field={CustomField}
        hint={hintContent}
      />
    ) : (
      <MobileSelect
        options={options}
        selectedKey={treaty ? getTreatyKey(treaty) : undefined}
        onChange={onChange}
        title="Счёт"
        hint={isDisplayRequirements ? hintContent : hint}
        leftAddon={
          (pending && <Loader className={styles.loader} />) ||
          (hasRequirement ? (
            <ExclamationCircleMIcon
              width={18}
              height={18}
              style={{ marginTop: 4 }}
              color={
                hasRequirement === 'immediateRequirements'
                  ? '#ef3124'
                  : '#cb830f'
              }
            />
          ) : (
            <BriefcaseMIcon className={styles.icon} />
          ))
        }
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);
