import cn from 'classnames';
import { useMemo } from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon';

import { getMinority } from '@terminal/core/lib/format';

import { getPricesRange, PricesRangeProps } from './PricesRange.helpers';

import styles from './PricesRange.module.css';

export const PricesRange = (
  props: PricesRangeProps & { currencyCode: CurrencyCodes; isBond?: boolean }
) => {
  const { rowStart, rowWidth, isGreen } = getPricesRange(props);

  const priceRowStyle = useMemo(() => {
    return {
      width: `${rowWidth}%`,
      marginLeft: `${rowStart}%`,
      background: isGreen ? 'var(--color-buy)' : 'var(--color-sell)',
    };
  }, [rowStart, rowWidth, isGreen]);

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.row, !isGreen && styles.rowInvert)}>
        <div>
          <div className={isGreen ? styles.label : styles.labelRight}>
            Открытие
          </div>
          <div>
            <Amount.Pure
              value={props.open! * getMinority(props.open)}
              minority={getMinority(props.open)}
              view="withZeroMinorPart"
              currency={props.isBond ? undefined : props.currencyCode}
            />
            {props.isBond && ' %'}
          </div>
        </div>
        <div>
          <div className={isGreen ? styles.labelRight : styles.label}>
            Последняя цена
          </div>
          <div className={styles.lastPrice}>
            <Amount.Pure
              value={props.last! * getMinority(props.last)}
              minority={getMinority(props.last)}
              view="withZeroMinorPart"
              currency={props.isBond ? undefined : props.currencyCode}
            />
            {props.isBond && ' %'}
          </div>
        </div>
      </div>

      <div className={styles.priceRowBackground}>
        <div className={styles.priceRow} style={priceRowStyle}>
          <div
            className={cn(
              styles.openPick,
              isGreen ? styles.pickLeft : styles.pickRight
            )}
          />
          <div
            className={cn(
              styles.lastPick,
              isGreen ? styles.pickRight : styles.pickLeft
            )}
          >
            <ArrowDownCompactXsIcon />
          </div>
        </div>
      </div>

      <div className={styles.row}>
        <div>
          <div>
            <Amount.Pure
              value={props.min! * getMinority(props.min)}
              minority={getMinority(props.min)}
              view="withZeroMinorPart"
              currency={props.isBond ? undefined : props.currencyCode}
            />
            {props.isBond && ' %'}
          </div>
        </div>
        <div>
          <div>
            <Amount.Pure
              value={props.max! * getMinority(props.max)}
              minority={getMinority(props.max)}
              view="withZeroMinorPart"
              currency={props.isBond ? undefined : props.currencyCode}
            />
            {props.isBond && ' %'}
          </div>
        </div>
      </div>
    </div>
  );
};
