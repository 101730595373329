import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon';
import { ArrowsLeftRightThinMIcon } from '@alfalab/icons-glyph/ArrowsLeftRightThinMIcon';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';

export const enum DIRECTION_TYPES {
  EXTRA_IN = 'EXTRA_IN',
  EXTRA_OUT = 'EXTRA_OUT',
  INTRA = 'INTRA',
}

export const TRANSFER_TYPE_ICON = {
  [DIRECTION_TYPES.EXTRA_IN]: ArrowDownCompactXsIcon,
  [DIRECTION_TYPES.EXTRA_OUT]: ArrowUpCompactXsIcon,
  [DIRECTION_TYPES.INTRA]: ArrowsLeftRightThinMIcon,
};

export const TRANSFER_TYPE_TEXT = {
  [DIRECTION_TYPES.EXTRA_IN]: 'Зачисление',
  [DIRECTION_TYPES.EXTRA_OUT]: 'Списание',
  [DIRECTION_TYPES.INTRA]: 'Внутренний перевод',
};
