import {
  ComponentType,
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
} from 'react';

import { AnalyticsInitParams } from '../lib/analytics';

import { Changelog as ChangelogType } from '../types/changelog';
import { ObjectItem } from '../types/core';

export type AppContext = {
  copyright: string;
  /**
   * Основной логотип приложения
   * */
  appLogo: JSX.Element | null;
  /**
   * Текстовый логотип приложения
   * */
  textLogo: JSX.Element | null;
  /**
   * Иконка главного меню
   * */
  menuIcon: JSX.Element | null;
  /**
   * Элемент хедера справа от кнопки главного меню
   * */
  headerLeftAddons: JSX.Element | null;
  /**
   * Имя терминала для информационных текстов
   * */
  terminalName: string;
  /**
   * Настройки онбординга
   * */
  onboarding: {
    showSecuritiesTransfersLink: boolean;
    images: {
      light: {
        welcome: string;
        widgets: string;
        layout: string;
        lastStep: string;
        workspaces: string;
        links: string;
        widgetsFirstPart: string;
        widgetsSecondPart: string;
      };
      dark: {
        welcome: string;
        widgets: string;
        layout: string;
        lastStep: string;
        workspaces: string;
        links: string;
        widgetsFirstPart: string;
        widgetsSecondPart: string;
      };
    };
  };
  /** Показывать чат поддержки */
  supportChat: boolean;
  /** Ссылка на документ с обучением */
  educationDocumentLink?: string;
  /**
   * Телефон поддержки
   * */
  supportPhone?: string;
  /**
   * Показывать модальное окно для кнопки помощи
   * */
  helpButtonWithPopover: boolean;
  /**
   * Городской телефон поддержки
   * */
  supportLandlinePhone?: string;
  /**
   * Альтернативный телефон поддержки в Москве
   * */
  supportMosсowAltPhone?: string;
  /**
   * Телефон поддержки в Москве для юр. лиц
   * */
  supportMosсowJurPhone?: string;
  /**
   * Почта поддержки
   * */
  supportEmail: string;
  /**
   * Название отделения для обращений
   */
  branchName: string;
  /**
   * Изображения для тестирования
   * */
  tests: {
    '1': string;
    '2': string;
    '3': string;
    '4': string;
    '5': string;
    '6': string;
    '7': string;
    '8': string;
    '9': string;
    '11': string;
    '12': string;
    '13': string;
    '14': string;
    '15': string;
    '16': string;
  };
  /**
   * Чейнджлоги приложения
   */
  changelog: ChangelogType;
  withLk: boolean;
  /**
   * Компонент денежных переводов
   */
  moneyTransfer: JSX.Element | null;
  /**
   * Компонент открытия нового счёта
   */
  addAccount?: JSX.Element | null;
  /**
   * Компонент депо-переводов
   */
  securities?: ReactNode;
  /**
   * Компонент модуля личного кабинета
   */
  lkMain?: ReactNode;
  /**
   * Компонент турнира инвесторов
   */
  Contest?: ComponentType<{
    getInstrumentIconURL: (object?: ObjectItem) => string | undefined;
  }> | null;
  /** Баннер турнира */
  contestWelcomeBanner?: React.ReactNode;
  manuals?: {
    supportBanner: JSX.Element | null;
    widget: JSX.Element | null;
  };
  education?: {
    widget: JSX.Element | null;
  };
  brokerLink: string;
  analytics?: AnalyticsInitParams;
  endpoints: {
    news: string;
    marginParams: string;
  };
  /** Блокирующий экран для мобильных устройств и планшетов */
  NotDesktopPlatformLock?: FC<PropsWithChildren<{}>>;
};

export const context = createContext<AppContext>({
  copyright: '',
  appLogo: null,
  textLogo: null,
  menuIcon: null,
  headerLeftAddons: null,
  terminalName: '',
  onboarding: {
    showSecuritiesTransfersLink: false,
    images: {
      light: {
        welcome: '',
        widgets: '',
        layout: '',
        lastStep: '',
        workspaces: '',
        links: '',
        widgetsFirstPart: '',
        widgetsSecondPart: '',
      },
      dark: {
        welcome: '',
        widgets: '',
        layout: '',
        lastStep: '',
        workspaces: '',
        links: '',
        widgetsFirstPart: '',
        widgetsSecondPart: '',
      },
    },
  },
  supportChat: false,
  supportEmail: '',
  branchName: '',
  tests: {
    '1': '',
    '2': '',
    '3': '',
    '4': '',
    '5': '',
    '6': '',
    '7': '',
    '8': '',
    '9': '',
    '11': '',
    '12': '',
    '13': '',
    '14': '',
    '15': '',
    '16': '',
  },
  changelog: {
    data: [],
  },
  helpButtonWithPopover: false,
  withLk: false,
  moneyTransfer: null,
  addAccount: null,
  securities: null,
  brokerLink: '',
  endpoints: {
    news: '',
    marginParams: '',
  },
});

/**
 * @returns контекст управления видом приложения (GO или AI)
 * */
export function useAppContext() {
  return useContext(context);
}
