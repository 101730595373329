import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import {
  getOperationsHistory,
  InvestQueryKeys,
} from '@terminal/core/lib/rest/investApi';
import { USE_PASSPORT } from '@terminal/core/lib/rest/lkApi';

import { useWidgetContext } from '../../../shared';
import { operationHistoryMap } from '../mapping/operationMapping';
import {
  OperationHistoryResult,
  OperationHistoryType,
} from '../model/operation';

export interface OperationsHistoryParams {
  subAccount: string; //456454-000
  dateFrom?: string; //UTC Date - 2022-01-26T05:34:20.680Z
  dateTo?: string; //UTC Date - 2022-02-26T05:34:20.680Z
}

export const useOperationsHistory = (params: OperationsHistoryParams) => {
  const { addNotification } = useWidgetContext();
  const { marketBoardsTable, objectsTable } = useAlfaDirectContext();

  const transformOperations = useCallback(
    (data: OperationHistoryResult[]) =>
      data.map((operation) => {
        const parsedObject = operationHistoryMap(operation);
        const object = parsedObject.operation.objectId
          ? objectsTable.get('idObject', parsedObject.operation.objectId)
          : undefined;

        const market = parsedObject.operation.marketId
          ? marketBoardsTable
              .toArray()
              .find(
                (market) =>
                  market.universalMarketCode === parsedObject.operation.marketId
              )
          : undefined;

        return {
          ...parsedObject,
          object,
          market,
        };
      }),
    [marketBoardsTable, objectsTable]
  );

  return useQuery(
    [InvestQueryKeys.OperationsHistory, params],
    () => {
      return getOperationsHistory({
        ...params,
        assetIdType: 'ID_OBJECT',
        maxElements: 100,
        operationTypes: [
          OperationHistoryType.E,
          OperationHistoryType.D,
          OperationHistoryType.S,
        ].join(','),
      });
    },
    {
      enabled: USE_PASSPORT,
      select: ({ operations }) => transformOperations(operations),
      onError: (error: any) => {
        addNotification({
          type: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе лимита',
        });
      },
    }
  );
};
