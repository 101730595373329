import { ObjectGroup } from '../client/entities';

/**
 * @returns Прибыль/убыток по позиции
 * */
export function getNPLRur(
  NPLtoMarketCurPrice: number | null = null,
  NominalCurPrice: number | null = null,
  MarketCurPrice: number | null = null,
  idObjectGroup?: ObjectGroup
): number | null {
  if (NPLtoMarketCurPrice === null) {
    return null;
  }

  if (idObjectGroup === ObjectGroup.Bonds) {
    return NominalCurPrice === null
      ? null
      : NPLtoMarketCurPrice * NominalCurPrice;
  }

  return MarketCurPrice === null ? null : NPLtoMarketCurPrice * MarketCurPrice;
}
