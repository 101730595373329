import { ExternalChatToken, getExternalChatToken } from '../rest/investApi';

import { useStore } from '../../store';

import { AppState } from '../../types/app';

const CASCANA_BUNDLE_URL = '/cascana/bundle.js';
const CASCANA_VERIFICATION_SERVICE = `https://omni.goinvest.ru/CEC.ChatBackend.VerificationService`;

class CascanaChatService {
  isLoaded = false;
  pendingLoad = false;
  client = null;
  externalToken: ExternalChatToken | null = null;
  tokens: null | { accessToken: string; refreshToken: string } = null;
  visible: boolean = false;

  initialize() {
    useStore.subscribe(
      (state) => state.appState,
      (state) => {
        if (state === AppState.NOT_READY && this.isLoaded) {
          this.hideChatWindow();
        }
      }
    );
  }

  async loadExternalToken() {
    if (this.externalToken) {
      return this.externalToken;
    }

    return getExternalChatToken().then((data) => {
      this.externalToken = data;

      return data;
    });
  }

  async loadChatTokens(external: string) {
    if (this.tokens) {
      return this.tokens;
    }

    let headers = new Headers({
      'Content-Type': 'application/json',
    });

    return fetch(`${CASCANA_VERIFICATION_SERVICE}/api/Token/external`, {
      method: 'POST',
      body: JSON.stringify({ accessToken: external }),
      headers: headers,
    })
      .then((result) => result.json())
      .then((result) => {
        this.tokens = result;

        return result;
      });
  }

  async loadClient(name = 'Аноним') {
    if (this.isLoaded || this.pendingLoad) {
      return true;
    }

    this.pendingLoad = true;

    return new Promise((resolve, reject) => {
      try {
        window.addEventListener('cascanaLoad', (event: any) => {
          event.cascana.init({
            name: name,
            accessToken: this.tokens?.accessToken,
            refreshToken: this.tokens?.refreshToken,
          });
          setTimeout(() => {
            resolve(true);
          }, 0);
        });

        window.addEventListener('cascanaChatVisible', (event: any) => {
          this.visible = event.cascana.visibleChat as boolean;
          const button: HTMLElement | undefined | null = document.querySelector(
            'div[title="Чат"]>button'
          )?.parentElement;

          if (button) {
            button.style.display = 'none';
          }
        });

        var scriptTag = document.createElement('script');

        scriptTag.type = 'text/javascript';
        scriptTag.src = CASCANA_BUNDLE_URL;

        scriptTag.onload = () => {
          this.pendingLoad = false;
          this.isLoaded = true;
        };

        scriptTag.onerror = (e) => {
          this.pendingLoad = false;
          reject(e);
        };

        document.body.appendChild(scriptTag);
      } catch (error) {
        reject(error);
      }
    });
  }

  showChatWindow() {
    if (this.visible) {
      // уже на экране
      return;
    }

    if ('cascanaChatToggle' in window) {
      (window as any).cascanaChatToggle();
    }
  }

  hideChatWindow() {
    if (!this.visible) {
      // уже закрыто
      return;
    }

    if ('cascanaChatToggle' in window) {
      (window as any).cascanaChatToggle();
    }
  }
}

export const cascanaChatService = new CascanaChatService();
