import { LinkName, Widget } from '@terminal/core/types/layout';
import { WatchlistIds } from '@terminal/core/types/watchList';

import { ConfigTemplate } from '../../../../index';
import { balanceLayout } from '../common/balanceLayout';
import {
  getCommonInstrumentsConfig,
  POSITIONS_COMMON_CONFIG,
  TRADE_HYBRIDORDER_CONFIG,
  TRADE_ORDERBOOK_CONFIG,
} from '../common/configs';
import {
  CHART_MIDDLE_CHRTIQ_SETTINGS,
  CHARTS__10MIN_CHARTIQ_SETTINGS,
  CHARTS__HOUR_CHARTIQ_SETTINGS,
  TRADE_BOTTOM_CHART_SETTING,
} from './configs';

import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

export const daytradeSmall = () => {
  const baseChartConfig = {
    symbol: 'SBER',
    idFi: 144950,
    link: LinkName.BLUE,
    tableProps: {},
  } as const;
  /** Возвращает layout Торговля */
  const makeTradeLayout = () => {
    const leftRow = new Row(30);
    const middleRow = new Row(20);
    const rightRow = new Row(50);

    const leftInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(LinkName.BLUE, WatchlistIds.iMOEXList)
    );

    const tradesTab = new Tab(Widget.TRADES, { link: LinkName.BLUE });

    const instrumentsTabSet = new TabSet(50);

    instrumentsTabSet.addChildren([leftInstrumentsTab, tradesTab]);

    const positionsTab = new Tab(Widget.POSITIONS, POSITIONS_COMMON_CONFIG);
    const operationTab = new Tab(Widget.OPERATIONS, {});

    const leftBottomTabSet = new TabSet(50);

    leftBottomTabSet.addChildren([positionsTab, operationTab]);

    leftRow.addChildren([instrumentsTabSet, leftBottomTabSet]);

    const orderbookTab = new Tab(Widget.ORDERBOOK, TRADE_ORDERBOOK_CONFIG);
    const orderbookTabset = new TabSet(20);
    const tradeTab = new Tab(Widget.HYBRIDORDER, TRADE_HYBRIDORDER_CONFIG);

    orderbookTabset.addChildren([orderbookTab, tradeTab]);

    middleRow.addChildren([orderbookTabset]);

    const rightChartTab1 = new Tab(
      Widget.CHART,
      baseChartConfig,
      TRADE_BOTTOM_CHART_SETTING
    );
    const rightChartTab2 = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHARTS__HOUR_CHARTIQ_SETTINGS
    );
    const rightChartTabset = new TabSet(100);

    rightChartTabset.addChildren([rightChartTab1, rightChartTab2]);

    rightRow.addChildren([rightChartTabset]);

    const tradeRow = new Row(100);

    tradeRow.addChildren([leftRow, middleRow, rightRow]);

    return new Layout('Торговля', tradeRow);
  };
  /** Возвращает layout Графики */
  const makeChartLayout = () => {
    const leftChartTab = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHARTS__10MIN_CHARTIQ_SETTINGS
    );
    const leftInstrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(LinkName.BLUE, WatchlistIds.iMOEXList)
    );
    const leftChartTabset = new TabSet(50);

    leftChartTabset.addChildren([leftChartTab, leftInstrumentsTab]);

    const rightChartTab1 = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHART_MIDDLE_CHRTIQ_SETTINGS
    );
    const rightChartTab2 = new Tab(
      Widget.CHART,
      baseChartConfig,
      CHARTS__HOUR_CHARTIQ_SETTINGS
    );
    const rightChartTabset = new TabSet(50);

    rightChartTabset.addChildren([rightChartTab1, rightChartTab2]);

    const chartRow = new Row(100);

    chartRow.addChildren([leftChartTabset, rightChartTabset]);

    return new Layout('Графики', chartRow);
  };

  const configuration = new DefaultConfiguration('Дневная торговля');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeChartLayout());
  configuration.addLayout(balanceLayout);

  return configuration as unknown as ConfigTemplate;
};
