import shallow from 'zustand/shallow';
import { PersonalManagerMIcon } from '@alfalab/icons-glyph/PersonalManagerMIcon';

import { ChatRoomListItem } from './ChatRoomListItem';
import { SupportPanel } from './SupportPanel';

import { useGetBrokerChatRooms } from '../../hooks/useGetBrokerChatRooms';

import { useStore } from '../../store/useStore';

import { ChatGroupId } from '../../types';

import styles from './ChatRoomList.module.css';

interface Props {
  mobile?: boolean;
  onEnterRoom: (id: string) => void;
  onClose?: () => void;
}

export const ChatRoomList = ({
  mobile = false,
  onEnterRoom,
  onClose,
}: Props) => {
  const [messages, chatGroups] = useStore(
    (store) => [store.messages, store.chatGroups || {}],
    shallow
  );

  const brokerChatList = useGetBrokerChatRooms(messages);

  return (
    <div className={styles.chatRoomList}>
      <SupportPanel mobile={mobile} onClose={onClose} />

      {brokerChatList
        .filter(
          ({ id }) => !Object.values(ChatGroupId).includes(id as ChatGroupId)
        )
        .map(({ id, description, missedCount }) => (
          <ChatRoomListItem
            key={id}
            id={id}
            mobile={mobile}
            title={chatGroups[id]?.nameChatGroup || 'Брокер РФ'}
            description={description}
            icon={PersonalManagerMIcon}
            missedCount={missedCount}
            onEnterRoom={onEnterRoom}
          />
        ))}
    </div>
  );
};
