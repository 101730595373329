import { CheckmarkCircleMIcon } from '@alfalab/icons-glyph/CheckmarkCircleMIcon';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';

import { isThemePassed } from '../../lib/isThemePassed';

interface ThemePassBadgeProps {
  percent: number;
}

export const ThemePassBadge = ({ percent }: ThemePassBadgeProps) => {
  const pass = isThemePassed(percent);
  const size = 12;

  const Icon = pass ? CheckmarkCircleMIcon : CrossCircleMIcon;
  const color = pass
    ? 'var(--color-light-graphic-positive)'
    : 'var(--color-light-graphic-negative)';

  return <Icon width={size} height={size} color={color} />;
};
