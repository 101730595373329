import { Component, ErrorInfo } from 'react';

import { IS_DEVELOPMENT } from '@terminal/core/env';
import { trackApplicationError } from '@terminal/core/lib/analytics';
import { Crash } from '@terminal/desktop/pages/CrashPage';

interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<{}, IErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  override componentDidCatch(error: Error, info: ErrorInfo) {
    const errorName = error?.name || 'Error';
    const errorMessage = error?.message || '';

    trackApplicationError(`${errorName}: ${errorMessage}`, info.componentStack);

    if (IS_DEVELOPMENT && errorMessage.includes("Cannot access 'useStore'")) {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } else {
      this.setState({ hasError: true });
    }
  }

  override render() {
    if (this.state.hasError) {
      return <Crash />;
    }

    return this.props.children;
  }
}
