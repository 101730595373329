import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getDraftDetail } from '@terminal/core/lib/rest/lkDrafts';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useDraft = (treaty: number, orderDraftId: number) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.Draft, treaty, orderDraftId],
    async () => getDraftDetail(treaty, orderDraftId),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса произвольного поручения',
          text: error?.message || '',
        });
      },
    }
  );
};
