import {
  ChatGroupState,
  ChatUserRoleType,
  Operation,
} from '@terminal/core/lib/client/entities';

import { BrokerChatMessage } from './personalBroker';

export enum ChatGroupId {
  UNKNOWN = '0',
  COMMON = '1',
  TECH_SUPPORT = '2',
  ANALYTICS = '3',
  EDUCATIONAL_CENTER = '4',
  SYSTEM = '5',
}

export interface ChatGroup {
  idChatGroup: string;
  nameChatGroup: string;
  ifEnable: number;
  idLoginChatGroup: number;
  confirmReading: boolean;
  version: string;
  operation: Operation;
  idRole: ChatUserRoleType;
  idClientStrategy: number;
  nameClientStrategy: string;
  message: string;
  idState: ChatGroupState;
  nextActiveDate: Date;
}

export type ChatMessage = BrokerChatMessage;

export type ChatMessagesGroup = ChatMessage[];
