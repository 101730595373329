import React, { useMemo, useRef, useState } from 'react';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronForwardMIcon } from '@alfalab/icons-glyph/ChevronForwardMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';

import { TradingCertificateLock } from '../../../features/TradingCertificateLock';
import { SettingsItem, SwitchSettings } from '../../../features/WidgetSettings';
import { useWidgetContext } from '../../../shared';

import styles from './useSettings.module.css';

interface Settings {
  hideActiveOrders: boolean;
  saveActiveOrders: (hide: boolean) => void;
  setIsOpenLimitsModal: (state: boolean) => void;
}

export const useSettings = ({
  hideActiveOrders,
  saveActiveOrders,
  setIsOpenLimitsModal,
}: Settings) => {
  const priceRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [priceMenuOpen, setPriceMenuOpen] = useState(false);

  const { DropdownComponent } = useWidgetContext();
  const settingsGroups = useMemo(
    () => [
      {
        id: 'common',
        title: 'Общие',
        items: [
          <SwitchSettings
            key="active"
            checked={!hideActiveOrders}
            label="Отображать активные заявки"
            reversed
            onChange={() => {
              saveActiveOrders(!hideActiveOrders);
              setIsOpen(false);
            }}
          />,
          <TradingCertificateLock key="info" hideMode>
            <SettingsItem
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                setIsOpenLimitsModal(true);
                setIsOpen(false);
              }}
            >
              Информационная строка...
            </SettingsItem>
          </TradingCertificateLock>,
        ],
      },
      {
        id: 'defaults',
        title: 'Значения по умолчанию',
        items: [
          <SettingsItem
            key="defaults"
            componentRef={priceRef}
            className={styles.priceSetting}
            onMouseEnter={() => setPriceMenuOpen(true)}
            onMouseLeave={() => setPriceMenuOpen(false)}
          >
            Цена
            <ChevronForwardMIcon
              height={18}
              width={18}
              color="var(--color-dark-graphic-secondary)"
            />
            <DropdownComponent
              anchorElement={priceRef.current}
              open={priceMenuOpen}
              position="right-start"
              offset={[0, 4]}
              popperClassName={styles.popover}
            >
              <Dropdown.Item
                rightIcon={<CheckmarkMIcon height={18} width={18} />}
                content={
                  <div className={styles.dropDownItemContent}>
                    Last Trade
                    <Tooltip
                      contentClassName={styles.tooltipContent}
                      content="Цена последней сделки"
                      trigger="hover"
                      position="top-start"
                      offset={[-20, 16]}
                    >
                      <InformationCircleLineSIcon
                        height={12}
                        width={12}
                        color="var(--color-dark-graphic-secondary)"
                      />
                    </Tooltip>
                  </div>
                }
              />
            </DropdownComponent>
          </SettingsItem>,
        ],
      },
    ],
    [
      hideActiveOrders,
      saveActiveOrders,
      setIsOpenLimitsModal,
      priceMenuOpen,
      DropdownComponent,
    ]
  );

  return useMemo(
    () => ({
      open: isOpen,
      groups: settingsGroups,
      onShowRequest: () => setIsOpen(true),
      onHideRequest: () => setIsOpen(false),
    }),
    [isOpen, settingsGroups]
  );
};
