import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { MINORITY } from '@terminal/core/constants/ui';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { InstrumentDataRow } from '../../ui';

import classes from './AboutIndexBlock.module.css';

interface Props {
  fullFI: ExtendedFI;
  finInfoExt?: FinInfoExt;
  priceDecimals?: number;
}

export const AboutIndexBlock: FC<Props> = ({ fullFI, finInfoExt }) => {
  const { objectsTable } = useAlfaDirectContext();

  const fullObject = objectsTable.get('idObject', fullFI?.idObject);
  const baseObject = objectsTable.get('idObject', fullObject?.idObjectBase);

  return (
    <div className={classes.aboutIndexContainer}>
      <Typography.TitleMobile
        view="xsmall"
        tag="div"
        weight="bold"
        color="secondary"
        className={classes.aboutIndexTitle}
      >
        Об индексе
      </Typography.TitleMobile>
      {baseObject?.nameObject && (
        <InstrumentDataRow
          title="Акций в списке"
          value={baseObject?.nameObject}
        />
      )}
      {fullObject?.idObjectType.name && (
        <InstrumentDataRow
          title="Ограничение на вес одного эмитента"
          value={fullObject?.idObjectType.name}
        />
      )}
      {fullObject?.matDateObject && (
        <InstrumentDataRow
          title="Последнее изменение списка"
          value={format(fullObject?.matDateObject, 'd MMMM yyyy', {
            locale: ru,
          })}
        />
      )}
      {fullFI?.type && (
        <InstrumentDataRow
          title="Начало расчета"
          value={
            <Amount.Pure
              value={(finInfoExt?.gtBuy ?? 0) * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency={fullFI?.currencyCode || 'RUB'}
              dataTestId="sizeInput"
            />
          }
        />
      )}
    </div>
  );
};
