/***
 * Устанавливает в LocalStorage по ключу onboarding поле field в значение value
 * @param field
 * @param value
 */
export const setOnboardigLocalStorage = (field: string, value: unknown) => {
  const result = localStorage.getItem('onboarding');

  if (result) {
    const currentOnboarding = JSON.parse(result);

    Object.assign(currentOnboarding, { [field]: value });
    localStorage.setItem('onboarding', JSON.stringify(currentOnboarding));
  } else {
    localStorage.setItem('onboarding', JSON.stringify({ [field]: value }));
  }
};
