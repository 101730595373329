import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { fortsRegistration } from '../api/fortsRegistration';

export const useFortsRegistration = () => {
  const addNotification = useNotification();

  return useMutation(fortsRegistration, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При регистрации произошла ошибка',
      });
    },
  });
};
