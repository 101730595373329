import { ObjectGroup } from '../client/entities';

import { PositionItem } from '../../types/position';

/**
 * @returns Стоимость всех позиций в портфеле в рублевом эквиваленте(Баланс)
 * */
export function calculateBalance(
  positions: PositionItem[],
  NKDBalance: number
) {
  const futures = positions.filter(
    (position) => position.objectType?.idObjectGroup === ObjectGroup.Futures
  );
  const otherPositions = positions.filter(
    (position) => position.objectType?.idObjectGroup !== ObjectGroup.Futures
  );
  // Сделал, чтобы баланс не превращался в 0 из-за иногда возникающих битых позиций на беке
  const futuresAmount = futures.reduce((sum, position) => {
    return sum + (position.dailyPLtoMarketCurPrice ?? 0);
  }, 0);
  const otherAmount = otherPositions.reduce((sum, position) => {
    return sum + (position.torgPosCostRur ?? 0);
  }, 0);

  return NKDBalance + futuresAmount + otherAmount;
}
