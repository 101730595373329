import { useMemo } from 'react';
import { CurrencyCodes } from '@alfalab/core-components/amount/types';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';

export const useCurrencyCodeByIdObject = (idObjectCurrency?: number) => {
  const { objectsTable } = useAlfaDirectContext();

  //Получаем код валюты
  return useMemo(
    () =>
      idObjectCurrency
        ? (objectsTable.get('idObject', idObjectCurrency)
            ?.symbolObject as CurrencyCodes)
        : undefined,
    [idObjectCurrency, objectsTable]
  );
};
