import { z, ZodObject, ZodRawShape } from 'zod';

import { FeatureFlags } from '@terminal/core/lib/featureFlags';

import { RemoteConfig } from '../api/types/RemoteConfig';

export const setFeatureFlags =
  <S extends ZodObject<ZodRawShape>, T extends Partial<z.infer<S>>>(
    featureFlags: FeatureFlags<S, T>
  ) =>
  (data: RemoteConfig<T>) => {
    if (data) {
      const entries = data?.entries ?? {};

      Object.entries(entries).forEach(([key, value]) => {
        try {
          featureFlags.emit(key as keyof T, JSON.parse(value));
        } catch (e) {
          featureFlags.emit(key as keyof T, value);
        }
      });
    }
  };
