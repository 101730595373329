import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import {
  DocumentsHistoryFilter,
  DocumentsHistoryStatus,
} from '@terminal/core/lib/rest/lkDocuments';

import { FilterIconButton } from '../../../../shared/ui/FilterIconButton';
import { FilterButton, setFilterProp } from '../../../components/Filter';

import styles from './HistoryFilterLine.module.css';

interface HistoryFilterLineProps {
  filter: DocumentsHistoryFilter;
  active: boolean;
  statuses?: DocumentsHistoryStatus[];
  onFilterOpen?: () => void;
  onSetFilter?: (filter: DocumentsHistoryFilter) => void;
}

export const HistoryFilterLine = ({
  filter,
  active,
  statuses,
  onFilterOpen,
  onSetFilter,
}: HistoryFilterLineProps) => {
  const setFilter = (key: keyof DocumentsHistoryFilter, value: string) => {
    if (onSetFilter) {
      const newFilter = setFilterProp(filter, key, value, key === 'statuses');
      onSetFilter(newFilter);
    }
  };
  const periodFormat = (): string => {
    if (
      !!filter.startDate &&
      !!filter.endDate &&
      filter.startDate === filter.endDate
    ) {
      return filter.startDate;
    } else if (filter.startDate || filter.endDate) {
      return `${filter.startDate || '...'} – ${filter.endDate || '...'}`;
    } else {
      return 'Выберите период';
    }
  };

  return (
    <div className={styles.container}>
      <FilterIconButton onClick={onFilterOpen} checked={active} />
      {statuses?.map((s) => (
        <FilterButton
          key={`filt-status-${s.value}`}
          checked={!!filter.statuses && filter.statuses?.indexOf(s.value) >= 0}
          onClick={() => setFilter('statuses', s.value)}
        >
          {s.text}
        </FilterButton>
      ))}

      <FilterButton
        checked={!!filter.startDate || !!filter.endDate}
        rightAddons={<ChevronDownMIcon width={18} height={18} />}
        onClick={onFilterOpen}
      >
        {periodFormat()}
      </FilterButton>
    </div>
  );
};
