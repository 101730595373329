import React, { VFC } from 'react';

export type SunIconProps = {};

export const SunIcon: VFC<SunIconProps> = (props) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m5.10938,0.802699l0.78124,0l0,1.5625l-0.78124,0l0,-1.5625z" />
      <path d="m2.375,5.099569l-1.5625,0l0,0.78125l1.5625,0l0,-0.78125z" />
      <path d="m5.10938,10.177699l0,-1.5625l0.78124,0l0,1.5625l-0.78124,0z" />
      <path d="m8.625,5.880829l1.5625,0l0,-0.78125l-1.5625,0l0,0.78125z" />
      <path d="m3.15625,5.490199c0,-1.29442 1.04933,-2.34375 2.34375,-2.34375c1.29442,0 2.34375,1.04933 2.34375,2.34375c0,1.29442 -1.04933,2.34375 -2.34375,2.34375c-1.29442,0 -2.34375,-1.04933 -2.34375,-2.34375zm2.34375,-1.5625c-0.86294,0 -1.5625,0.69956 -1.5625,1.5625c0,0.86295 0.69956,1.5625 1.5625,1.5625c0.86295,0 1.5625,-0.69955 1.5625,-1.5625c0,-0.86294 -0.69955,-1.5625 -1.5625,-1.5625z" />
      <path d="m2.46165,1.899369l1.10486,1.10485l-0.55243,0.55243l-1.10486,-1.10485l0.55243,-0.55243z" />
      <path d="m9.09078,2.451799l-0.55243,-0.55243l-1.10485,1.10485l0.55242,0.55243l1.10486,-1.10485z" />
      <path d="m3.01408,7.423639l-1.10486,1.10485l0.55243,0.55243l1.10485,-1.10485l-0.55242,-0.55243z" />
      <path d="m9.09078,8.528489l-0.55243,0.55243l-1.10485,-1.10485l0.55242,-0.55243l1.10486,1.10485z" />
    </svg>
  );
};
