import React, { useState } from 'react';
import { Attach } from '@alfalab/core-components/attach';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowRightSquareMIcon } from '@alfalab/icons-glyph/ArrowRightSquareMIcon';
import { ArrowUpLineDownMIcon } from '@alfalab/icons-glyph/ArrowUpLineDownMIcon';
import { DotsHorizontalMIcon } from '@alfalab/icons-glyph/DotsHorizontalMIcon';
import { FolderMIcon } from '@alfalab/icons-glyph/FolderMIcon';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';
import { TrashCanLineMIcon } from '@alfalab/icons-glyph/TrashCanLineMIcon';

import { DropdownMenu } from '@terminal/common/components/DropdownMenu';
import { useErrorNotification } from '@terminal/core/hooks/useNotifications';
import { getStringDate } from '@terminal/core/lib/format';
import { getBrowserInfo } from '@terminal/core/lib/info';
import { shallow, useStore } from '@terminal/core/store';
import { WorkspaceConfiguration } from '@terminal/core/store/slices/workspaceConfigurationsSlice';

import { PickerButton } from '../../../../shared/ui/PickerButton';
import { exportConfiguration } from '../../lib/exportConfiguration';
import { parseJSONFile } from '../../lib/parseJSONFile';
import { WorkspaceConfigurationModal } from '../WorkspaceConfigurationModal';
import { WorkspaceRenameModal } from '../WorkspaceConfigurationModal/WorkspaceRenameModal';

import { WorkspaceConfigurationExport } from '../../model/types';

import styles from './WorkspaceConfigurationCustomList.module.css';

const exportImportAvailable = ['Chrome', 'Safari'].includes(
  getBrowserInfo().name
);

interface Props {
  otherConfigurations: WorkspaceConfiguration[];
}

export const WorkspaceConfigurationCustomList = ({
  otherConfigurations,
}: Props) => {
  const [deleteModalConfig, setDeleteModalConfig] = useState({
    isOpen: false,
    id: '',
    name: '',
  });

  const [renameModalConfig, setRenameModalConfig] = useState({
    isOpen: false,
    id: '',
    value: '',
  });

  const {
    allConfigurations,
    setActiveConfigurationById,
    removeConfiguration,
    renameConfiguration,
    importConfiguration,
    importWatchLists,
  } = useStore(
    (state) => ({
      allConfigurations: state.workspaceConfigurations,
      setActiveConfigurationById: state.setActiveConfigurationById,
      removeConfiguration: state.removeConfiguration,
      renameConfiguration: state.renameConfiguration,
      importConfiguration: state.importConfiguration,
      importWatchLists: state.importWatchLists,
    }),
    shallow
  );

  const notifyError = useErrorNotification();

  const handleDeleteConfiguration = (id: string, name: string) =>
    setDeleteModalConfig({
      isOpen: true,
      id,
      name,
    });

  const handleRenameConfiguration = (id: string, name: string) =>
    setRenameModalConfig({
      isOpen: true,
      id,
      value: name,
    });

  const handleCloseDeleteConfigurationModal = () =>
    setDeleteModalConfig({
      isOpen: false,
      id: '',
      name: '',
    });

  const handleCloseRenameConfigurationModal = () =>
    setRenameModalConfig({
      isOpen: false,
      id: '',
      value: '',
    });

  const handleAcceptConfiguration = (id: string) => {
    setActiveConfigurationById(id);
  };

  const handleImportConfiguration = (file: File) => {
    parseJSONFile<WorkspaceConfigurationExport>(file)
      .then((result: WorkspaceConfigurationExport) => {
        if (!result.configuration) {
          throw new Error('Неверный формат файла. Требуется JSON');
        }

        if (
          !result.watchLists ||
          !Array.isArray(result.watchLists) ||
          !result.configuration
        ) {
          throw new Error('Неверный формат файла');
        }

        if (
          allConfigurations.some(
            (config) => config.id === result.configuration.id
          )
        ) {
          throw new Error(
            'Конфигурация с таким идентификатором уже существует'
          );
        }

        importWatchLists(result.watchLists);
        importConfiguration(result.configuration);
      })
      .catch((err) => notifyError('Ошибка', new Error(err.message)));
  };

  return (
    <>
      {exportImportAvailable && (
        <>
          <Attach
            size="xs"
            value={[]}
            buttonContent="Загрузить из файла"
            buttonProps={{
              block: true,
              view: 'secondary',
              leftAddons: (
                <FolderMIcon
                  width={16}
                  height={16}
                  color="var(--color-light-graphic-secondary)"
                />
              ),
            }}
            onChange={(_, { files: [file] }) => handleImportConfiguration(file)}
            fileClassName={styles.fileList}
            noFileText=""
          />

          <Gap size="m" />
        </>
      )}

      <div>
        {otherConfigurations.map((configuration) => {
          const { name, id, lastManualSavedConfig, lastAutoSavedConfig } =
            configuration;

          const manualSavedDateStr = getStringDate(
            new Date(lastManualSavedConfig.timeStamp)
          );

          const isChanged =
            lastManualSavedConfig.timeStamp !== lastAutoSavedConfig.timeStamp;

          const changedDate = isChanged
            ? getStringDate(new Date(lastAutoSavedConfig.timeStamp))
            : null;

          return (
            <div className={styles.otherConfigurationsRow} key={id}>
              <div>
                <Typography.Text
                  view="secondary-large"
                  color="primary"
                  tag="p"
                  defaultMargins={false}
                >
                  {name}
                </Typography.Text>
                <Typography.Text
                  view="secondary-small"
                  color="secondary"
                  tag="p"
                  defaultMargins={false}
                >
                  Cохранено {manualSavedDateStr}
                  {isChanged && changedDate && <>, изменено {changedDate}</>}
                </Typography.Text>
              </div>
              <div className={styles.otherConfigurationsButtons}>
                <TooltipDesktop
                  position="top"
                  trigger="hover"
                  content="Применить"
                  fallbackPlacements={['left', 'right']}
                >
                  <IconButton
                    size="xs"
                    view="secondary"
                    icon={ArrowRightSquareMIcon}
                    onClick={() => handleAcceptConfiguration(id)}
                  />
                </TooltipDesktop>

                <DropdownMenu
                  groups={[
                    {
                      key: 'actions',
                      items: [
                        {
                          key: 'reset',
                          type: 'button',
                          label: 'Переименовать',
                          Icon: PencilMIcon,
                          hideMenu: true,
                          onClick: () => handleRenameConfiguration(id, name),
                        },
                        {
                          key: 'delete',
                          type: 'button',
                          label: 'Удалить',
                          Icon: TrashCanLineMIcon,
                          hideMenu: true,
                          onClick: () => handleDeleteConfiguration(id, name),
                        },
                      ],
                    },
                    {
                      key: 'export',
                      items: [
                        {
                          key: 'to-file',
                          type: 'button',
                          Icon: ArrowUpLineDownMIcon,
                          label: 'Выгрузить в файл',
                          hint: 'Вы сможете загрузить конфигурацию на другом устройстве или в другом браузере.',
                          hideMenu: true,
                          onClick: () => exportConfiguration(configuration),
                        },
                      ],
                    },
                  ]}
                >
                  {(ref) => (
                    <PickerButton
                      ref={ref}
                      size="xs"
                      view="link"
                      className={styles.moreButton}
                    >
                      <IconButton
                        size="xs"
                        view="secondary"
                        icon={DotsHorizontalMIcon}
                      />
                    </PickerButton>
                  )}
                </DropdownMenu>
              </div>
            </div>
          );
        })}
      </div>

      <WorkspaceRenameModal
        open={renameModalConfig.isOpen}
        value={renameModalConfig.value}
        onChange={(value) =>
          setRenameModalConfig({ ...renameModalConfig, value })
        }
        onClose={handleCloseRenameConfigurationModal}
        onSubmit={() =>
          renameConfiguration(renameModalConfig.id, renameModalConfig.value)
        }
      />

      <WorkspaceConfigurationModal
        open={deleteModalConfig.isOpen}
        onClose={handleCloseDeleteConfigurationModal}
        title="Удалить конфигурацию?"
        actionButtonsEnd={[
          {
            children: 'Отмена',
            onClick: handleCloseDeleteConfigurationModal,
          },
          {
            children: 'Удалить',
            leftAddons: <TrashCanLineMIcon width={18} height={18} />,
            view: 'secondary',
            onClick: () => {
              removeConfiguration(deleteModalConfig.id);
              handleCloseDeleteConfigurationModal();
            },
          },
        ]}
      >
        Вы уверены, что хотите удалить конфигурацию «{deleteModalConfig.name}»?
        <br />
        Вы не сможете ее восстановить.
      </WorkspaceConfigurationModal>
    </>
  );
};
