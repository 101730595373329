export const getFinancialStabilityDescription = (score: number) => {
  switch (score) {
    case 1:
    case 2:
      return 'Компания испытывает проблемы с покрытием долговой нагрузки';
    case 3:
    case 4:
      return 'Доходы компании снижаются, а долговая нагрузка растет';
    case 5:
    case 6:
      return 'Компания имеет стабильные доходы, позволяющие покрыть долговую нагрузку';
    case 7:
    case 8:
      return 'Компания имеет стабильные доходы и низкую долговую нагрузку';
    case 9:
    case 10:
      return 'Компания имеет постоянно растущие доходы и низкую долговую нагрузку';
    default:
      return 'Недостаточно данных для оценки компании';
  }
};

export const getPriceValuationDescription = (score: number) => {
  switch (score) {
    case 1:
    case 2:
      return 'Компания сильно переоценена рынком в сравнении с другими компаниями в секторе';
    case 3:
    case 4:
      return 'Компания переоценена рынком в сравнении с другими компаниями в секторе';
    case 5:
    case 6:
      return 'Мультипликаторы компании средние по сектору';
    case 7:
    case 8:
      return 'Компания недооценена рынком в сравнении с другими компаниями в секторе';
    case 9:
    case 10:
      return 'Компания сильно недооценена рынком в сравнении с другими компаниями в секторе';
    default:
      return 'Недостаточно данных для оценки компании';
  }
};
