import { ReactNode } from 'react';
import {
  TagDesktop,
  TagDesktopProps,
} from '@alfalab/core-components/tag/desktop';

export interface ButtonMenuButtonProps {
  text: ReactNode;
  active?: boolean;
  disabled?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
  size?: TagDesktopProps['size'];
}

export const ButtonMenuButton = ({
  text,
  active,
  disabled,
  onClick,
  size = 's',
}: ButtonMenuButtonProps) => (
  <TagDesktop
    size={size}
    view="filled"
    onClick={onClick}
    disabled={disabled}
    checked={active}
  >
    {text}
  </TagDesktop>
);
