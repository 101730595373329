import cn from 'classnames';
import { useState } from 'react';
import { Loader } from '@alfalab/core-components/loader';
import { DocumentMIcon } from '@alfalab/icons-glyph/DocumentMIcon';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';

import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';

import { ArrowDownBoldContainerMIcon } from '../../../icons/ArrowDownBoldContainerMIcon';

import { useDownloadFile } from '../../../hooks';

import styles from './FaqFile.module.css';

interface FileLoading {
  pending: boolean;
  file: DownloadedFile | null;
}

interface FaqFileProps {
  name: string;
  url: string;
  className?: string;
}

export const FaqFile = ({ name, url, className }: FaqFileProps) => {
  const [file, setFile] = useState<FileLoading>({ pending: false, file: null });
  const { mutateAsync: downloadFile } = useDownloadFile();

  const requestFile = async () => {
    setFile({
      pending: true,
      file: null,
    });

    const file = await downloadFile(url);

    setFile({
      pending: false,
      file: file,
    });
  };

  return file.file ? (
    <a
      className={cn(styles.container, className)}
      href={file.file.url}
      rel="noreferrer"
      target="_blank"
    >
      <DocumentMIcon width={16} height={16} className={styles.glyph} />
      <div className={styles.name}>{name}</div>
      <OutsideMIcon width={16} height={16} className={styles.glyph} />
    </a>
  ) : (
    <button className={cn(styles.container, className)} onClick={requestFile}>
      {file.pending ? (
        <Loader className={styles.loader} />
      ) : (
        <DocumentMIcon width={16} height={16} className={styles.glyph} />
      )}
      <div className={styles.name}>{name}</div>
      <ArrowDownBoldContainerMIcon
        width={16}
        height={16}
        className={styles.glyph}
      />
    </button>
  );
};
