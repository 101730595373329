import { useField } from 'formik';
import React, { FC } from 'react';
import {
  UniversalDateInputDesktop as BaseUniversalDateInputDesktop,
  UniversalDateInputDesktopProps as BaseUniversalDateInputDesktopProps,
} from '@alfalab/core-components/universal-date-input/desktop';
import {
  UniversalDateInputConditionalProps as BaseUniversalDateInputConditionalProps,
  UniversalDateInputProps as BaseUniversalDateInputProps,
} from '@alfalab/core-components/universal-date-input/types';

import { useFieldOkState } from 'formik-alfalab-core-components/hooks/useFieldOkState';

type UniversalDateInputDesktopProps = Omit<
  BaseUniversalDateInputProps,
  'breakpoint' | 'defaultMatchMediaValue' | 'name' | 'onChange' | 'error'
> &
  BaseUniversalDateInputConditionalProps & {
    name: string;
  };

export const UniversalDateInput: FC<UniversalDateInputDesktopProps> = (
  props
) => {
  const { name, ...restProps } = props;
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;

  const { error } = useFieldOkState(props);

  const handleChange: BaseUniversalDateInputDesktopProps['onChange'] = (
    _,
    payload
  ) => {
    setValue(payload.value);
  };

  return (
    <BaseUniversalDateInputDesktop
      {...field}
      onChange={handleChange}
      error={error}
      {...restProps}
    />
  );
};
