import { Treaty } from '@terminal/core/lib/rest/lkCommon';

export function isIisAccount(treaty: Treaty | null | undefined): boolean {
  return !!treaty && !!treaty.isIis && !treaty.isFirm;
}

export function isMoneyboxAccount(treaty: Treaty | null): boolean {
  return !!treaty && !!treaty.isMoneybox;
}

export function isKickAccount(treaty: Treaty | null): boolean {
  return !!treaty && !!treaty.isKick;
}

export function isFirmAccount(treaty: Treaty | null): boolean {
  return !!treaty && !!treaty.isFirm;
}
