import { ECurrencyId } from '../../types/currencyId';

/**
 * @returns Цену валюты номинала
 * */
export function getNominalCurPrice(
  Price: number,
  PrevPrice: number,
  PriceStep: number,
  PriceStepCost: number,
  idObjectFaceUnit?: number
): number {
  if (!idObjectFaceUnit || idObjectFaceUnit === ECurrencyId.RUR) {
    return 1;
  }

  if (Price === 0) {
    return (PriceStepCost / PriceStep) * PrevPrice;
  }

  return (PriceStepCost / PriceStep) * Price;
}
