import log from 'loglevel';

import { featureFlags } from '@terminal/core/featureFlags';

import { IS_AI, IS_GI } from '../../env';
import { IDB } from '../db';
import { SettingsTypeLast } from '../db/settings';
import { WatchListsTypeLast } from '../db/watchLists';
import { WorkspaceConfigurationsTypeLast } from '../db/workspaceConfiguration';
import { callLkApi, fetchLkResult } from './lkApi';

import { CommonSettings } from '../../types/ui';

type WorkspaceConfigurationsServerResponse = WorkspaceConfigurationsTypeLast & {
  version?: number;
};
type WatchListsServerResponse = WatchListsTypeLast & { version?: number };
type SettingsServerResponse = SettingsTypeLast & { version?: number };

export async function getWorkspace() {
  if (IS_AI || !featureFlags.SYNCHRONIZATION_ENABLED) {
    return undefined;
  }

  return await fetchLkResult<WorkspaceConfigurationsServerResponse | undefined>(
    `/api/v1/go-terminal-settings/workspace`
  );
}

export function saveWorkspace(
  data: WorkspaceConfigurationsTypeLast,
  version = IDB.DB_APP_VERSION
) {
  if (IS_GI && featureFlags.SYNCHRONIZATION_ENABLED) {
    callLkApi(`/api/v1/go-terminal-settings/workspace`, 'POST', {
      ...data,
      version,
    }).catch(log.error);
  }
}

export async function getWatchLists() {
  if (IS_AI || !featureFlags.SYNCHRONIZATION_ENABLED) {
    return undefined;
  }

  return await fetchLkResult<WatchListsServerResponse | undefined>(
    `/api/v1/go-terminal-settings/watchlists`
  );
}

export function saveWatchLists(
  data: WatchListsTypeLast,
  version = IDB.DB_APP_VERSION
) {
  if (IS_GI && featureFlags.SYNCHRONIZATION_ENABLED) {
    callLkApi(`/api/v1/go-terminal-settings/watchlists`, 'POST', {
      ...data,
      version,
    }).catch(log.error);
  }
}

export async function getSettings() {
  if (IS_AI || !featureFlags.SYNCHRONIZATION_ENABLED) {
    return undefined;
  }

  return await fetchLkResult<SettingsServerResponse | undefined>(
    `/api/v1/go-terminal-settings/settings`
  );
}

export function saveSettings(
  data: CommonSettings,
  version = IDB.DB_APP_VERSION
) {
  if (IS_GI && featureFlags.SYNCHRONIZATION_ENABLED) {
    callLkApi(`/api/v1/go-terminal-settings/settings`, 'POST', {
      ...data,
      version,
    }).catch(log.error);
  }
}
