import cn from 'classnames';
import { useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';

import { lifeTimeOptions } from '@terminal/core/constants/orderBook';
import { orderTypeAbbrMap } from '@terminal/core/constants/orders';
import { LifeTime } from '@terminal/core/lib/client/entities';
import { getAssetUnits } from '@terminal/core/lib/format';
import { getOrderDisplayPrice } from '@terminal/core/lib/formulas';
import { CancelOrderFn } from '@terminal/core/lib/services/trading/useTradingService';
import { OrderItem } from '@terminal/core/types/order';

import { TextOrder } from '../TextOrder';

import styles from './CancelOrder.module.css';

export interface CancelOrderProps {
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
  order: OrderItem | null;
  onCloseRequest: () => void;
  cancelOrder: CancelOrderFn;
}

export const CancelOrder = (props: CancelOrderProps) => {
  const [isOpenTextOrder, setIsOpenTextOrder] = useState(false);
  // Формируем текст, который будет показан в плашке
  const order = props.order;
  let descripion = <></>;

  const lifeTimeId = order ? LifeTime[order.idLifeTime] : '';
  const lifeTime = lifeTimeOptions.find(
    (option) => option.key === lifeTimeId
  )?.content;

  if (order) {
    const orderType = orderTypeAbbrMap[order.idOrderType];
    const displayPrice = getOrderDisplayPrice(order);
    const quantity = order.quantity;
    const buySell =
      order.buySell === -1 ? (
        <ArrowDownCompactXsIcon color="#d91d0b" />
      ) : (
        <ArrowUpCompactXsIcon color="#2fc26e" />
      );

    const text = `${quantity} ${getAssetUnits(quantity)} ${
      displayPrice ? displayPrice : ''
    }`;

    descripion = (
      <Typography.Text view="secondary-large">
        {buySell}&nbsp;{text}&nbsp;
        <Typography.Text view="secondary-large" color="secondary">
          {lifeTime}&nbsp;{orderType}
        </Typography.Text>
      </Typography.Text>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Text view="primary-medium" weight="bold">
          Подтверждение
        </Typography.Text>
        <CrossMIcon
          className={styles.closeIcon}
          width={18}
          height={18}
          onClick={() => {
            props.onCloseRequest();
          }}
        />
      </div>
      <Typography.Text tag="div" view="secondary-large">
        Вы уверены, что хотите снять заявку?
        <Gap size="m" />
        {descripion}
      </Typography.Text>
      <Gap size="m" />
      {props.order && (
        <>
          <div
            className={styles.orderTextButton}
            onClick={() => setIsOpenTextOrder(true)}
          >
            <DocumentLinesMIcon height={16} width={16} />
            <Typography.Text view="secondary-small">
              Текстовое поручение
            </Typography.Text>
          </div>
          <TextOrder
            prevOrder={props.order}
            isOpen={isOpenTextOrder}
            onClose={() => setIsOpenTextOrder(false)}
            onConfirm={() => {
              props.setIsLoading(true);
              props.cancelOrder(props.order!);
            }}
          />
        </>
      )}
      <div className={styles.buttonToolbar}>
        <ButtonDesktop
          className={cn(styles.customButton, styles.customCancelButton)}
          view="ghost"
          type="submit"
          size="xxs"
          block
          onClick={() => {
            props.onCloseRequest();
          }}
          leftAddons={<CrossMIcon width={18} height={18} />}
        >
          Отмена
        </ButtonDesktop>
        <ButtonDesktop
          className={styles.customButton}
          loading={props.isLoading}
          size="xxs"
          view="primary"
          block
          onClick={() => {
            props.setIsLoading(true);
            props.cancelOrder(props.order!);
          }}
          leftAddons={<CheckmarkMIcon width={18} height={18} />}
        >
          Подтвердить
        </ButtonDesktop>
      </div>
    </div>
  );
};
