import { StrategyThemeInfoDTO } from '../api/types/StrategyThemeInfoDTO';
import { StrategyThemeInfo } from '../model/types/StrategyThemeInfo';

export function mapStrategyThemeInfo(
  dto: StrategyThemeInfoDTO
): StrategyThemeInfo {
  return {
    id: dto.id,
    name: dto.name,
    details: dto.details,
    bookMaterials: dto.bookMaterials || [],
    videoMaterials: dto.videoMaterials || [],
    passPercent: dto.passPercent,
    recommendations: dto.recommendations || '',
  };
}
