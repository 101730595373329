import { addDays, format, isSameDay, parseISO, startOfMonth } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@alfalab/core-components/button/modern';
import { Gap } from '@alfalab/core-components/gap';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Loader } from '@alfalab/core-components/loader';
import { Field } from '@alfalab/core-components/select/components/field';
import {
  SelectDesktop,
  SelectDesktopProps,
} from '@alfalab/core-components/select/desktop';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { MailMIcon } from '@alfalab/icons-glyph/MailMIcon';

import { TradingCertificateLock } from '@terminal/common/features-DEPRECATED/TradingCertificateLock';
import { dateToRequestFormat } from '@terminal/core/lib/rest/lkApi';
import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import {
  DocumentDepoAccount,
  DocumentReceivingType,
  DocumentsOrderRequest,
  DocumentTheme,
  ReportTheme,
} from '@terminal/core/lib/rest/lkDocuments';
import { useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';

import { ActiveFormType } from '../../../../entities/Documents';
import { getObtainHint, obtainRename, sortObtain } from '../../helpers';
import { isCountValid, isPeriodValid } from '../../validation';
import { HardcopyFields } from '../HardcopyFields';
import { OrderPeriod } from '../OrderPeriod';
import { OrderYear } from '../OrderYear';
import { ReceiveByMail } from '../ReceiveByMail';
import { ReceiveEmail } from '../ReceiveEmail';
import { ReceiveInOffice } from '../ReceiveInOffice';

import { useDocumentOrder } from '../../hooks/useDocumentOrder';

interface DocumentOrderFormProps {
  treaty: Treaty | null;
  theme: string;
  documentsTheme?: DocumentTheme[] | ReportTheme[];
  documentsThemePending: boolean;
  depoAccounts?: DocumentDepoAccount[];
  address?: string;
  email?: string;
  emailConfirmed: boolean;
  onFormApplied?: (state: boolean) => void;
  formType?: ActiveFormType;
}

export const DocumentOrderForm = ({
  treaty,
  theme,
  documentsTheme,
  documentsThemePending,
  depoAccounts,
  address,
  email,
  emailConfirmed,
  onFormApplied,
  formType,
}: DocumentOrderFormProps) => {
  let startDate = format(
    startOfMonth(addDays(new Date(), -1)),
    DEFAULT_VIEW_DATE_FORMAT
  );
  let endDate = format(addDays(new Date(), -1), DEFAULT_VIEW_DATE_FORMAT);

  const [periodStart, setPeriodStart] = useState<string>(startDate);
  const [periodEnd, setPeriodEnd] = useState<string>(endDate);
  const [year, setYear] = useState<string>('');
  const [selectedDepoAccount, setSelectedDepoAccount] = useState<string>('');
  const [obtaining, setObtaining] = useState<DocumentReceivingType>('online');
  const [city, setCity] = useState<string>('');
  const [office, setOffice] = useState<string>('');
  const [count, setCount] = useState<string>('1');
  const [comment, setComment] = useState<string>('');
  const [depoTouched, setDepoTouched] = useState(false);

  const setHeaderMenuOpen = useStore((state) => state.setHeaderMenuOpen);

  const { isLoading: documentOrderPending, mutateAsync: documentOrder } =
    useDocumentOrder();

  const selectedDocumentTheme = useMemo<DocumentTheme | undefined>(() => {
    if (documentsTheme && theme) {
      const selected = documentsTheme.filter(
        (doc) => doc.id.toString() === theme
      );

      return selected.length > 0 ? selected[0] : undefined;
    }

    return undefined;
  }, [documentsTheme, theme]);

  const obtainingOptions = useMemo(() => {
    if (selectedDocumentTheme) {
      return selectedDocumentTheme.allowedReceivingTypes
        .sort(sortObtain)
        .map(obtainRename)
        .map((ob) => ({
          key: ob.type as string,
          content: ob.description,
        }));
    }

    return [];
  }, [selectedDocumentTheme]);

  useEffect(() => {
    const selectedDocumentThemeEndDate =
      selectedDocumentTheme?.endDate && parseISO(selectedDocumentTheme.endDate);

    if (
      selectedDocumentTheme?.dateType === 'period' &&
      selectedDocumentThemeEndDate &&
      isSameDay(addDays(selectedDocumentThemeEndDate, -1), new Date())
    ) {
      setPeriodEnd(format(new Date(), DEFAULT_VIEW_DATE_FORMAT));
    }
  }, [selectedDocumentTheme]);

  useEffect(() => {
    if (obtainingOptions.length) {
      const filtred = obtainingOptions.filter(
        (obt) => obt.key && obt.key === obtaining
      );
      const obtainingList = filtred.length > 0 ? filtred : obtainingOptions;

      setObtaining(obtainingList[0].key as DocumentReceivingType);
    }
  }, [obtainingOptions, obtaining]);

  const depoaccOptions = useMemo(() => {
    if (depoAccounts) {
      return depoAccounts.map((account) => ({
        key: account.depoAccount,
        content: `${account.depoAccount}, ${account.placeName}`,
      }));
    }

    return [];
  }, [depoAccounts]);

  useEffect(() => {
    if (depoAccounts && depoTouched) {
      const filtred = depoAccounts.filter(
        (acc) => acc.depoAccount === selectedDepoAccount
      );
      const depoAccountsList = filtred.length > 0 ? filtred : depoAccounts;

      setSelectedDepoAccount(depoAccountsList[0].depoAccount);
    }
  }, [depoAccounts, depoTouched, selectedDepoAccount]);

  const handleDepoAccountChange: SelectDesktopProps['onChange'] = ({
    selected,
  }) => {
    setSelectedDepoAccount(selected?.key || '');
  };

  const handleObtainingChange: SelectDesktopProps['onChange'] = ({
    selected,
  }) => {
    setObtaining((selected?.key || '') as DocumentReceivingType);
  };

  const handleChangeEmail = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.PROFILE_SETTINS);
  }, [setHeaderMenuOpen]);

  const isReadyToOrder = useMemo(() => {
    const depoAccPass: boolean = selectedDocumentTheme
      ? !selectedDocumentTheme.needDepoAccount ||
        (selectedDocumentTheme.needDepoAccount && Boolean(selectedDepoAccount))
      : false;
    const dateValid = selectedDocumentTheme
      ? isPeriodValid(
          selectedDocumentTheme.dateType,
          periodStart,
          periodEnd,
          year,
          selectedDocumentTheme.startDate,
          selectedDocumentTheme.endDate
        )
      : false;

    switch (obtaining) {
      case 'electronic': {
        return Boolean(selectedDocumentTheme) && dateValid && depoAccPass;
      }
      case 'inbank': {
        return (
          Boolean(selectedDocumentTheme) &&
          dateValid &&
          Boolean(city) &&
          Boolean(office) &&
          isCountValid(count) &&
          depoAccPass
        );
      }
      case 'bymail': {
        return (
          Boolean(selectedDocumentTheme) &&
          dateValid &&
          Boolean(address) &&
          isCountValid(count) &&
          depoAccPass
        );
      }
      case 'inlk': {
        return Boolean(selectedDocumentTheme) && dateValid;
      }
    }

    return false;
  }, [
    selectedDocumentTheme,
    selectedDepoAccount,
    periodStart,
    periodEnd,
    year,
    city,
    office,
    count,
    address,
    obtaining,
  ]);

  const orderDocument = () => {
    if (treaty && selectedDocumentTheme) {
      let data: DocumentsOrderRequest = {
        treaty: treaty.treaty,
        dateType: selectedDocumentTheme.dateType,
        themeId: parseInt(theme),
        receivingType: obtaining,
      };

      if (selectedDocumentTheme.dateType === 'year') {
        data = {
          ...data,
          year: year,
        };
      } else if (selectedDocumentTheme.dateType === 'date') {
        data = {
          ...data,
          startDate: dateToRequestFormat(periodStart),
        };
      } else if (selectedDocumentTheme.dateType === 'period') {
        data = {
          ...data,
          startDate: dateToRequestFormat(periodStart),
          endDate: dateToRequestFormat(periodEnd),
        };
      }

      const depoAccPass: boolean =
        Boolean(selectedDocumentTheme) && selectedDocumentTheme.needDepoAccount;
      const selectedDepoAccountObj = depoAccounts?.find(
        (doc) => doc.depoAccount === selectedDepoAccount
      );

      if (depoAccPass && selectedDepoAccountObj) {
        data = {
          ...data,
          depoAccount: selectedDepoAccountObj.depoAccount,
          placeName: selectedDepoAccountObj.placeName,
        };
      }

      if (obtaining === 'bymail') {
        data = {
          ...data,
          count: parseInt(count),
          address: address,
          extraInfo: comment,
        };
      } else if (obtaining === 'inbank') {
        data = {
          ...data,
          count: parseInt(count),
          officeCity: city,
          office: office,
          extraInfo: comment,
        };
      } else if (obtaining === 'electronic') {
        data = {
          ...data,
          email: email,
        };
      }

      documentOrder({
        params: data,
        signOptions: {
          successTitle: !onFormApplied ? 'Документ заказан' : undefined,
          successCallback: () => {
            onFormApplied && onFormApplied(true);
          },
        },
      });
    }
  };

  return (
    <div>
      <Space direction="vertical" fullWidth>
        {selectedDocumentTheme?.dateType === 'year' && (
          <OrderYear
            documentTheme={selectedDocumentTheme}
            year={year}
            onYearChange={(year) => setYear(year)}
          />
        )}
        {(selectedDocumentTheme?.dateType === 'date' ||
          selectedDocumentTheme?.dateType === 'period') && (
          <OrderPeriod
            documentTheme={selectedDocumentTheme}
            periodStart={periodStart}
            periodEnd={periodEnd}
            onPeriodStartChange={(date) => setPeriodStart(date)}
            onPeriodEndChange={(date) => setPeriodEnd(date)}
          />
        )}

        {selectedDocumentTheme && selectedDocumentTheme.needDepoAccount && (
          <Row>
            <Col width={6}>
              <SelectDesktop
                label="Депо счет"
                labelView="outer"
                placeholder="Выберите депо счет"
                size="s"
                block
                options={depoaccOptions}
                selected={selectedDepoAccount}
                onChange={handleDepoAccountChange}
                onBlur={() => setDepoTouched(true)}
                Field={(props: FieldProps) => (
                  <Field
                    {...props}
                    leftAddons={documentsThemePending && <Loader />}
                  />
                )}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col width={12}>
            <SelectDesktop
              label="Способ получения"
              labelView="outer"
              placeholder="Выберите из списка"
              size="s"
              block
              options={obtainingOptions}
              selected={obtaining}
              onChange={handleObtainingChange}
              hint={getObtainHint(obtaining, formType)}
              error={
                !emailConfirmed && obtaining === 'electronic'
                  ? 'У вас нет подтвеждённого e-mail. Укажите его в Профиле'
                  : ''
              }
            />
          </Col>
        </Row>

        {obtaining === 'inbank' && (
          <ReceiveInOffice
            city={city}
            onCityChange={(city) => setCity(city)}
            office={office}
            onOfficeChange={(office) => setOffice(office)}
          />
        )}
        {obtaining === 'bymail' && <ReceiveByMail address={address} />}
        {obtaining === 'electronic' && (
          <ReceiveEmail
            email={email}
            emailConfirmed={emailConfirmed}
            onEmailChange={handleChangeEmail}
          />
        )}

        {(obtaining === 'inbank' || obtaining === 'bymail') && (
          <HardcopyFields
            count={count}
            onCountChange={(count) => setCount(count)}
            comment={comment}
            onCommentChange={(comment) => setComment(comment)}
            hideCount={formType === 'depoReport' && obtaining === 'bymail'}
            hideComment={formType === 'depoReport' && obtaining === 'bymail'}
          />
        )}
      </Space>

      <TradingCertificateLock>
        <Gap size="l" />
        <Row>
          <Col width={6}>
            {!emailConfirmed && obtaining === 'electronic' ? (
              <Button
                size="s"
                block
                leftAddons={
                  <MailMIcon
                    width={18}
                    height={18}
                    color="var(--color-light-graphic-secondary)"
                  />
                }
                onClick={handleChangeEmail}
              >
                Указать email
              </Button>
            ) : (
              <Button
                size="s"
                block
                view="accent"
                leftAddons={<CheckmarkMIcon width={18} height={18} />}
                loading={documentOrderPending}
                disabled={!isReadyToOrder}
                onClick={orderDocument}
              >
                Заказать
              </Button>
            )}
          </Col>
        </Row>
      </TradingCertificateLock>
    </div>
  );
};
