import cn from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import styles from './Heading.module.css';

interface Props {
  title: ReactNode;
  description?: ReactNode;
}

export const Heading: FC<Props> = ({ title, description }) => {
  return (
    <>
      <Typography.Title
        className={cn(styles.title, {
          [styles.bottomOffset]: !description,
        })}
        tag="h3"
        font="styrene"
        view="xsmall"
      >
        {title}
      </Typography.Title>

      <Gap size="s" />

      {description && (
        <Typography.Text
          tag="div"
          className={cn(styles.description, styles.bottomOffset)}
          view="secondary-large"
        >
          {description}
        </Typography.Text>
      )}
    </>
  );
};
