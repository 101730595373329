// TODO вынести из этого файла
//Получаем ордера для фильтра позиций
import memoize from 'lodash/memoize';

import { OrderStatus } from '../client/entities';
import { makeOrderKey } from './makeOrderKey';

import { AccountItem } from '../../types/account';
import { OrderItem } from '../../types/order';

export const getOrderMap = memoize(
  (orders: OrderItem[], accounts: AccountItem[]) => {
    const filteredOrders = orders.filter(
      (order) =>
        //счета пользователя, но без Инвесткопилок
        accounts.some((account) => account.idAccount === order.idAccount) &&
        //Также отсеиваем заявки, которые не в статусе Активные и Ожидают, они не учавствуют в рассчетах
        ![
          OrderStatus.Filled,
          OrderStatus.Cancelled,
          OrderStatus.Rejected,
        ].includes(order.idOrderStatus)
    );

    return filteredOrders.reduce((acc, order) => {
      const key = makeOrderKey(order, order.buySell);

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(order);

      return acc;
    }, {} as Record<string, OrderItem[]>);
  },
  //memoize By default, the first argument provided to the memoized function is used as the map cache key
  //Собираем кастомный cache key, чтобы остальные аргументы (не только первый) влияли на пересчет
  (orders, accounts) =>
    `${orders
      .map(({ clientNumEDocument }) => clientNumEDocument)
      .join('-')} ${accounts.map(({ idAccount }) => idAccount).join('-')}`
);
