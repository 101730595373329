import { isBefore } from 'date-fns';

import { DateRange } from '@terminal/core/types/ranges';

export const prepareDateRange = (value: DateRange) => {
  let dateFrom = value?.dateFrom;
  let dateTo = value?.dateTo;

  if (isBefore(dateTo, dateFrom)) {
    dateFrom = value?.dateTo;
    dateTo = value?.dateFrom;
  }

  return { dateFrom, dateTo, selectedInterval: value.selectedInterval };
};
