import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { lifeTimeOptions } from '../../constants/orderBook';
import { LifeTime, OrderStatus, OrderType } from '../../lib/client/entities';
import { getFICurrency } from '../../lib/currencies';
import { getInstrumentId } from '../../lib/domain/getInstrumentId';
import { getOrderDisplayPrice } from '../../lib/formulas';
import { isNotNull } from '../../lib/isNotNull';
import { useMarketBoardByIdMap } from './useMarketBoardByIdMap';
import { useObjectByIdMap } from './useObjectByIdMap';
import { useObjectTypeByIdMap } from './useObjectTypeByIdMap';
import { useOrders } from './useOrders';
import { useSubAccountRazdel } from './useSubAccountRazdel';

import { AccountItem } from '../../types/account';
import { IFilteredOrder, OrderDirectionMap } from '../../types/order';

type AvailableRazdels = Record<string, number>;

/**
 * Возвращает отфильтрованный по субсчету список заявок, дополненных значениями для отображения в UI
 * @param selectedSubAccounts - выбранный субсчет
 * @param orderStatus - массив фильтров по статусу заявки
 */
export const useFilteredOrders = (
  selectedSubAccounts: string[] | AccountItem[][],
  orderStatus: Array<OrderStatus>
): IFilteredOrder[] => {
  const orders = useOrders();
  const {
    accounts: clientAccounts,
    finInstrumentsTable,
    objectsTable: objects,
    useFinInfoExt,
  } = useAlfaDirectContext();
  const objectByIdMap = useObjectByIdMap();
  const objectTypesByIdMap = useObjectTypeByIdMap();
  const marketBoardByIdMap = useMarketBoardByIdMap();
  const clientOrders = useMemo(
    //clientAccounts - счета пользователя, но без Инвесткопилок
    () =>
      orders.filter((order) =>
        clientAccounts.some((account) => account.idAccount === order.idAccount)
      ),
    [clientAccounts, orders]
  );

  const finInfoExtRecord = useFinInfoExt(
    clientOrders.map((order) =>
      getInstrumentId(order.idObject, order.idMarketBoard, finInstrumentsTable)
    )
  );

  const subAccountRazdels = useSubAccountRazdel();
  // Группируем для получения всех возможных вариантов выбора в селект

  const selectedSubAccountIds = useMemo(() => {
    return selectedSubAccounts.map((account) => {
      return subAccountRazdels.find(
        (subRazdel) => subRazdel.codeSubAccount === account
      )?.idSubAccount;
    });
  }, [selectedSubAccounts, subAccountRazdels]);

  const filteredOrders = useMemo(() => {
    const selectedOrderByAcc = clientOrders.filter((order) =>
      selectedSubAccountIds.includes(order.idSubAccount)
    );

    if (orderStatus.length > 0) {
      return selectedOrderByAcc.filter((order) =>
        orderStatus.includes(order.idOrderStatus)
      );
    } else {
      return selectedOrderByAcc;
    }
  }, [clientOrders, orderStatus, selectedSubAccountIds]);

  return useMemo(() => {
    let availableAccountRazdel: AvailableRazdels = {};
    let availableSubAccountRazdel: AvailableRazdels = {};

    const data = filteredOrders
      .map((order) => {
        const object = objectByIdMap.get(order.idObject);
        const marketBoard = marketBoardByIdMap.get(order.idMarketBoard);

        if (!object || !marketBoard) {
          return null;
        }

        const lifeTimeId = LifeTime[order.idLifeTime];
        const lifeTime = lifeTimeOptions.find(
          (option) => option.key === lifeTimeId
        )?.content;
        const objectTypeValue = object.idObjectType.value;
        const objectGroup =
          objectTypesByIdMap.get(objectTypeValue)?.idObjectGroup;
        const idFi = getInstrumentId(
          order.idObject,
          order.idMarketBoard,
          finInstrumentsTable
        );
        const entities = {
          idFi,
          order,
          object,
          market: marketBoard,
          direction: OrderDirectionMap.get(order.buySell),
          subAccountRazdel: subAccountRazdels.find(
            (razdel) => razdel.idRazdel === order.idRazdel
          ),
          currency: getFICurrency(marketBoard, objects),
          lifeTime,
          operation: order.buySell === 1 ? 'Покупка' : 'Продажа',
          objectGroup,
          priceStep: finInfoExtRecord[idFi]?.priceStep,
        };

        let DisplayPrice = getOrderDisplayPrice(order);

        const fields = {
          executed: order.quantity - order.rest,
          displayPrice: DisplayPrice ?? 0,
          type: OrderType[order.idOrderType],
          withdrawTime: order.withdrawTime,
          status: OrderStatus[order.idOrderStatus],
          acceptTime: order.acceptTime,
          acceptDate: order.acceptTime,
        };

        const razdelByCode = entities.subAccountRazdel?.codeSubAccount ?? '';
        const razdelById = entities.subAccountRazdel?.idAccount ?? '';

        if (!availableSubAccountRazdel[razdelByCode]) {
          availableSubAccountRazdel[razdelByCode] = 1;
        } else {
          availableSubAccountRazdel[razdelByCode] += 1;
        }

        if (!availableAccountRazdel[razdelById]) {
          availableAccountRazdel[razdelById] = 1;
        } else {
          availableAccountRazdel[razdelById] += 1;
        }

        return { ...entities, ...fields };
      })
      .filter(isNotNull);

    let filteredData: typeof data = [];

    const selectedSubAccountsTyped = selectedSubAccounts as string[];

    if (!isEmpty(selectedSubAccountsTyped)) {
      filteredData = data
        .filter((row) =>
          selectedSubAccountsTyped.includes(
            String(row.subAccountRazdel?.codeSubAccount)
          )
        )
        .map((orderItem) => ({
          ...orderItem,
          acceptOnlyDate:
            orderItem?.acceptDate?.toDateString() ||
            orderItem.order.signTime.toDateString(),
        }));
    }

    return filteredData;
  }, [
    filteredOrders,
    selectedSubAccounts,
    objectByIdMap,
    marketBoardByIdMap,
    objectTypesByIdMap,
    finInstrumentsTable,
    subAccountRazdels,
    objects,
    finInfoExtRecord,
  ]);
};
