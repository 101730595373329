import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';

// Рынки, с которых нельзя делать маржинальный перевод
export const NON_MARGINAL_MARKET = ['MICEX_SELT_TOD', 'FORTS'];

/**
 * Проверяет счёт на доступность маржинального перевода
 * @param account счёт
 * @returns
 */
export function isAccountFitMarginal(account: TradeAccount) {
  return !NON_MARGINAL_MARKET.includes(account.placeCode);
}
