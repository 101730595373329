import isObject from 'lodash/isObject';

import { getAvailableObjects } from './getAvailableObjects';

import { ObjectItemsTable, ObjectTypeItemsTable } from '../../types/core';

export const getObjectInfo = (
  idObject: number,
  objects: ObjectItemsTable,
  objectTypes: ObjectTypeItemsTable,
  filterUnavailableObjects = true
) => {
  const object = filterUnavailableObjects
    ? getAvailableObjects(objects).get('idObject', idObject)
    : objects.get('idObject', idObject);

  const idObjectType = isObject(object?.idObjectType)
    ? object?.idObjectType?.value
    : object?.idObjectType;

  const objectType = objectTypes.get('idObjectType', idObjectType)!;

  return {
    object,
    objectType,
  };
};
