import {
  DataFlowSubscribeEntity,
  FrontEndType,
  MessageArrayPayload,
  MessageObjectPayload,
  SubscribeType,
} from './entities';
import { EntityType } from './entityTypes';
import { getEntityIdByName } from './serialization';

// Type guards
export function isObjectMessage(
  payload: MessageObjectPayload | MessageArrayPayload
): payload is MessageObjectPayload {
  return !Array.isArray(payload);
}

export function isArrayMessage(
  payload: MessageObjectPayload | MessageArrayPayload
): payload is MessageArrayPayload {
  return Array.isArray(payload);
}

export function makeDataFlowEntity(
  subscribeType: SubscribeType,
  frontend: FrontEndType,
  entity: EntityType,
  flowKeys: number[] = [],
  version: BigInt = 0n
) {
  const data = new DataFlowSubscribeEntity();

  data.DataFlowType = getEntityIdByName(entity)!;
  data.FlowKeys = flowKeys;
  data.SubscribeType = subscribeType;
  data.Delay = 0;
  data.Version = version;

  return {
    frontend,
    isArray: false,
    payload: {
      type: EntityType.DataFlowSubscribeEntity,
      data,
    },
  };
}

export function getRandomInt() {
  if (typeof crypto !== 'undefined' && crypto.getRandomValues) {
    const arr = new Int32Array(1);

    return crypto.getRandomValues(arr)[0];
  }

  return Math.floor(Math.random() * 100000);
}

export function getRandomBigInt() {
  if (typeof crypto !== 'undefined' && crypto.getRandomValues) {
    const arr = new BigInt64Array(1);

    return crypto.getRandomValues(arr)[0];
  }

  return BigInt(Math.floor(Math.random() * 100000));
}
