import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getTestingList } from '@terminal/core/lib/rest/lkTesting';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../queryKeys';

export const useTestingList = (treaty?: number, enabled: boolean = false) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.TestingList, { treaty }],
    async () => getTestingList({ treaty: treaty || 0 }),
    {
      enabled: Boolean(treaty) && enabled,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса списка тестов',
          text: error?.message || '',
        });
      },
    }
  );
};
