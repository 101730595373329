import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  AuthorizationStatusRequest,
  getAuthorizationStatus,
} from '@terminal/core/lib/rest/investApi';
import { NotificationType } from '@terminal/core/types/ui';

const key = 'pwa:phoneLogin';

export const usePhoneLogin = (
  data: AuthorizationStatusRequest,
  onError: () => void
) => {
  const addNotification = useNotification();

  return useQuery(
    [key, ...Object.values(data)],
    () => getAuthorizationStatus(data),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса пользовательских данных',
          text: error?.message || '',
        });

        onError();
      },
      enabled: Boolean(data?.phone),
    }
  );
};
