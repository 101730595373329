import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';
import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';

import type { ClientPerson } from '../model/types/ClientPerson';
import type { PasswordChangeParams } from '../model/types/PasswordChangeParams';

export async function sendPasswordChange(data: PasswordChangeParams) {
  return await signOperation(
    '/api/settings/save/password',
    'POST',
    data.params,
    data.signOptions
  );
}

export async function getManager(): Promise<ClientPerson> {
  const res = await fetchLkResult<ClientPerson & LKResult>(
    `/api/settings/manager`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}
