import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getBankByBic } from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';

export const useBankInfo = (bic: string) => {
  const addNotification = useNotification();

  return useQuery([LkQueryKeys.BankInfo, { bic }], () => getBankByBic(bic), {
    enabled: bic.length >= 9,
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При информации о банке произошла ошибка',
      });
    },
  });
};
