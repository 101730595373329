import cn from 'classnames';
import { Typography } from '@alfalab/core-components/typography';

import { Notification } from '@terminal/core/lib/rest/lkNotifications';

import styles from './NotificationsItem.module.css';

interface NotificationsItemProps {
  item: Notification;
  className?: string;
  onClick?: (item: Notification) => void;
}

export const NotificationsItem = ({
  item,
  className,
  onClick,
}: NotificationsItemProps) => {
  return (
    <div
      className={cn(styles.container, className)}
      onClick={() => onClick && onClick(item)}
    >
      {!item.isRead && (
        <div className={styles.status}>
          <span className={styles.marker} />
        </div>
      )}
      <div className={styles.description}>
        <Typography.Text view="secondary-large" className={styles.title}>
          {item.subject}
        </Typography.Text>
      </div>
    </div>
  );
};
