import { MoneyLimitResult } from '@terminal/core/lib/rest/lkMoney';

export function getLimit(
  limitResult: MoneyLimitResult,
  marginalOn: boolean = true
) {
  return limitResult.marginalOn && marginalOn
    ? limitResult.marginalLimit
    : limitResult.limit;
}
