import { CurrencyCodes } from '@alfalab/core-components/amount';
import { formatAmount } from '@alfalab/utils';

import {
  orderTypeAbbrMap,
  orderTypeNameMap,
} from '@terminal/core/constants/orders';
import { getMinority } from '@terminal/core/lib/format';
import { OrderSide } from '@terminal/core/types/trading';

import { getBracketPrice } from '../../../BracketPrice';

import { SLTPInstruction } from '../model/types';

export const getSLTPButtonText = (
  sltp: SLTPInstruction,
  mainPrice: number,
  side: OrderSide,
  priceStep?: number,
  symbol?: CurrencyCodes
): string => {
  const buttonContent: string[] = [];

  const minority = getMinority(priceStep);

  if (sltp.isSlEnabled && sltp.slPrice !== undefined) {
    const bracketPrice = getBracketPrice(
      mainPrice,
      sltp.slPriceType,
      sltp.slPrice,
      side === OrderSide.BUY ? -1 : 1,
      priceStep
    );

    const { formattedWithCurrency } = formatAmount({
      value: bracketPrice * minority,
      minority,
      currency: symbol,
      view: 'withZeroMinorPart',
    });

    buttonContent.push(
      `${
        (sltp.isTpEnabled ? orderTypeAbbrMap : orderTypeNameMap)[
          sltp.slOrderType
        ]
      } ${formattedWithCurrency}`
    );
  }

  if (sltp.isTpEnabled && sltp.tpPrice !== undefined) {
    const bracketPrice = getBracketPrice(
      mainPrice,
      sltp.tpPriceType,
      sltp.tpPrice,
      side === OrderSide.BUY ? 1 : -1,
      priceStep
    );

    const { formattedWithCurrency } = formatAmount({
      value: bracketPrice * minority,
      minority,
      currency: symbol,
      view: 'withZeroMinorPart',
    });

    buttonContent.push(
      `${sltp.isSlEnabled ? 'ТП' : 'Тейк-профит'} ${formattedWithCurrency}`
    );
  }

  if (buttonContent.length) {
    return buttonContent.join(' | ').replaceAll('.', ',');
  }

  return '';
};
