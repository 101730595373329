import { format, parseISO } from 'date-fns';
import qs from 'query-string';

import { LK_URI } from '@terminal/core/env';

const dateFormatQueryString = 'yyyy-MM-dd';

export function getQualInvestorPdfUrl(
  treaty: number,
  dateiso: string,
  reject: boolean = false
) {
  const date = format(parseISO(dateiso), dateFormatQueryString);

  const baseUrl = reject
    ? '/api/qual-investor/reject/pdf/'
    : '/api/qual-investor/pdf/';

  return qs.stringifyUrl({
    url: `${LK_URI}${baseUrl}${treaty}`,
    query: { date },
  });
}
