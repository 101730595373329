import { FormikConfig } from 'formik';

import { useStore } from '@terminal/core/store';
import { trackPriceSettingsModalStateSelector } from '@terminal/core/store/selectors/trackPrice/trackPriceSettingsModalStateSelector';
import { trackPriceCommonSettingsSelector } from '@terminal/core/store/selectors/workspaceConfigurations/trackPriceCommonSettingsSelector';
import { TrackPriceCommonSettings } from '@terminal/core/types/trackPrice';

type ReturnType = Pick<
  FormikConfig<TrackPriceCommonSettings>,
  'onSubmit' | 'initialValues'
>;

export function useForm(): ReturnType {
  const commonSettings = useStore(trackPriceCommonSettingsSelector);
  const { close } = useStore(trackPriceSettingsModalStateSelector);
  const setSettings = useStore((store) => store.setTrackPriceDefaultSettings);

  const onSubmit: ReturnType['onSubmit'] = async (values) => {
    setSettings(values);
    close();
  };

  return {
    onSubmit,
    initialValues: commonSettings,
  };
}
