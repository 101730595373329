import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { InstrumentTableHeaderItem } from '../../model/types';

import classes from './InstrumentsSimpleTable.module.css';

interface Props {
  columns: InstrumentTableHeaderItem[];
  data: ReactNode[][];
}

export const InstrumentsSimpleTable: FC<Props> = ({ columns, data }) => {
  return (
    <div className={classes.tableContainer}>
      <div className={cn(classes.tableRow, classes.tableHeaderRow)}>
        {columns.map(({ title, width, align = 'left' }) => (
          <div
            key={title}
            className={classes.tableColumn}
            style={{ justifyContent: align, flexBasis: `${width}%` }}
          >
            <Typography.Text
              view="secondary-large"
              weight="medium"
              color="secondary"
            >
              {title}
            </Typography.Text>
          </div>
        ))}
      </div>
      {data.map((value, index) => (
        <div
          key={index}
          className={cn(classes.tableRow, classes.tableContentRow)}
        >
          {columns.map(({ width, align, title }, idx) => (
            <div
              key={title}
              className={classes.tableColumn}
              style={{ justifyContent: align, flexBasis: `${width}%` }}
            >
              <Typography.Text view="secondary-large" color="primary">
                {value[idx]}
              </Typography.Text>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
