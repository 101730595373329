import { TabNode } from 'flexlayout-react';
import { Suspense } from 'react';
import { Spinner } from '@alfalab/core-components/spinner';

import { defaultTradesFeedProps } from '@terminal/core/constants/tableProps';
import { Widget } from '@terminal/core/types/layout';

import {
  isBalanceProps,
  isChartProps,
  isHybridOrderProps,
  isInstrumentInfoProps,
  isNewsProps,
  isOperationProps,
  isOrderBookProps,
  isOrderTableProps,
  isPositionProps,
  isTradesProps,
  isWatchListProps,
} from './helpers';
import {
  LazyAssets,
  LazyBalance,
  LazyCertificates,
  LazyChart,
  LazyDevtools,
  LazyHybridOrder,
  LazyInstrumentInfo,
  LazyNews,
  LazyOperations,
  LazyOrderBook,
  LazyOrderTable,
  LazyPositions,
  LazyTradesFeed,
  LazyWatchlist,
} from './lazyWidgets';

//TODO: Написать тесты
const lazyWidgetFactory = (node: TabNode) => {
  const component = node.getComponent();
  const config = node.getConfig();
  const nodeId = node.getId();

  if (!node.isVisible()) {
    return null;
  } else {
    switch (component) {
      case Widget.WATCHLIST:
        return isWatchListProps(config, component) ? (
          <LazyWatchlist {...config} nodeId={nodeId} />
        ) : null;

      case Widget.ASSETS:
        return <LazyAssets nodeId={nodeId} />;

      case Widget.DEVTOOLS:
        return <LazyDevtools nodeId={nodeId} />;

      case Widget.CERTIFICATES:
        return <LazyCertificates nodeId={nodeId} />;

      case Widget.NEWS:
        return isNewsProps(config, component) ? (
          <LazyNews {...config} nodeId={nodeId} />
        ) : null;

      case Widget.TRADES:
        return isTradesProps(config, component) ? (
          <LazyTradesFeed
            {...defaultTradesFeedProps}
            {...config}
            nodeId={nodeId}
          />
        ) : null;

      case Widget.ORDERBOOK:
        return isOrderBookProps(config, component) ? (
          <LazyOrderBook {...config} nodeId={nodeId} />
        ) : null;
      case Widget.CHART:
        return isChartProps(config, component) ? (
          <LazyChart {...config} nodeId={nodeId} />
        ) : null;

      case Widget.POSITIONS:
        return isPositionProps(config, component) ? (
          <LazyPositions {...config} nodeId={nodeId} />
        ) : null;

      case Widget.OPERATIONS:
        return isOperationProps(config, component) ? (
          <LazyOperations {...config} nodeId={nodeId} />
        ) : null;

      case Widget.ORDERS:
        return isOrderTableProps(config, component) ? (
          <LazyOrderTable {...config} nodeId={nodeId} />
        ) : null;

      case Widget.BALANCE:
        return isBalanceProps(config, component) ? (
          <LazyBalance {...config} nodeId={nodeId} />
        ) : null;

      case Widget.HYBRIDORDER:
        return isHybridOrderProps(config, component) ? (
          <LazyHybridOrder {...config} nodeId={node.getId()} />
        ) : null;
      case Widget.INSTRUMENT_INFO:
        return isInstrumentInfoProps(config, component) ? (
          <LazyInstrumentInfo {...config} nodeId={nodeId} />
        ) : null;
      default:
        return null;
    }
  }
};

export const widgetFactory = (node: TabNode) => {
  return (
    <Suspense
      fallback={
        <div
          style={{ display: 'grid', placeContent: 'center', height: '100%' }}
        >
          <Spinner size="m" visible />
        </div>
      }
    >
      {lazyWidgetFactory(node)}
    </Suspense>
  );
};
