import { useEffect } from 'react';

import { SupportService } from './api/chatService';
import { ChatProvider } from './app/providers/ChatProvider';
import { ChatWindow } from './components/ChatWindow';
import { ChatLayoutMobile } from './mobile/components/ChatLayoutMobile';
import { ChatContext } from './shared/lib/ChatContext';

import { useStore } from './store/useStore';

interface ChatProps extends ChatContext {
  mobile?: boolean;
}

export const Chat = ({
  mobile = false,
  supportChat,
  supportPhone,
  supportEmail,
  feedbackEmail,
}: ChatProps) => {
  const setUnreadMsgCount = useStore((store) => store.setUnreadMsgCount);

  useEffect(
    () => SupportService.subscribeToUnreadMsgCount(setUnreadMsgCount),
    [setUnreadMsgCount]
  );

  return (
    <ChatProvider
      supportChat={supportChat}
      supportPhone={supportPhone}
      supportEmail={supportEmail}
      feedbackEmail={feedbackEmail}
    >
      {mobile ? <ChatLayoutMobile /> : <ChatWindow />}
    </ChatProvider>
  );
};
