/**
 * @returns На Рынке ценных бумаг – Денежные средства, которые должны быть внесены в портфель для поддержания позиции. Рассчитывается как положительная разница между Начальной маржой и Стоимостью ликв.
 * На Срочном рынке – Денежные средства, которые должны быть внесены в портфель для компенсации отрицательной вариационной маржи текущей сессии. Рассчитывается как положительная разница между необходимым Гарантийным обеспечение и денежными средствами (рублями) с учётом отрицательной вариационной маржи за текущую сессию по всем позициям.
 * */
export function calculateRequirements(
  initialMargin: number,
  liquedBalance: number
) {
  return initialMargin - liquedBalance < 0 ? 0 : initialMargin - liquedBalance;
}
