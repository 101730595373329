import { createElementFromHTML } from './createElementFromHTML';

/**
 * Выполняет запрос без использования CORS, используя iframe и форму для отправки данных.
 * Этот метод позволяет обойти ограничения CORS, создавая форму внутри невидимого iframe и
 * программно отправляя эту форму. Поскольку браузеры не применяют политику CORS к запросам,
 * инициированным из форм, это позволяет отправить запрос на любой URL без необходимости
 * настройки CORS на сервере.
 *
 * @param {Object} options Объект с параметрами запроса.
 * @param {string} options.url URL, по которому будет отправлен запрос.
 * @param {'get' | 'post' | 'put' | 'patch' | 'delete'} options.method HTTP-метод запроса.
 * @param {Record<string, string>} [options.body={}] Объект, содержащий тело запроса в форме ключ-значение.
 * @param {() => void} options.onError Функция, которая будет вызвана в случае ошибки.
 * @param {number} [options.timeout=5000] Таймаут в миллисекундах, после которого запрос будет считаться неудачным.
 * @returns {Promise<void>} Промис, который разрешается при успешной отправке формы или отклоняется при ошибке.
 */
export const doNoCORSFetch = ({
  url,
  method,
  body = {},
  onError,
  timeout = 5000,
}: {
  url: string;
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  body: Record<string, string>;
  onError?: () => void;
  timeout?: number;
}): Promise<void> => {
  const formContainer = createElementFromHTML(`
    <div style="display: none;">
      <iframe name="${url}"></iframe>
    </div>
  `) as HTMLElement;

  document.body.appendChild(formContainer);
  const iframe = formContainer.querySelector('iframe');

  const form = createElementFromHTML(`
    <form
      action="${url}"
      method="${method}"
      target="${url}"
    >
      ${Object.entries(body).reduce(
        (html, [name, value]) => `${html}
      <input type="hidden" name="${name}" value="${value}" />`,
        ''
      )}  
    </form>
  `);

  formContainer.appendChild(form);

  const button = createElementFromHTML<HTMLButtonElement>(`<button
    type="submit"
  />`);

  form.appendChild(button);

  return new Promise((resolve, reject) => {
    button.click();

    const timeoutId = setTimeout(() => {
      document.body.removeChild(formContainer);
      onError?.();
      reject();
    }, timeout);

    if (iframe) {
      iframe.addEventListener(
        'load',
        () => {
          button.click();
          clearTimeout(timeoutId);
          document.body.removeChild(formContainer);
          resolve();
        },
        { once: true }
      );
    }
  });
};
