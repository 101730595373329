import { useMemo } from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { MINORITY } from '@terminal/core/constants/ui';
import { getStringDate } from '@terminal/core/lib/format';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfo, FinInfoExt } from '@terminal/core/types/quotes';

import { InstrumentDataRowGrid } from '../../ui';

import classes from './CouponsInfoBlock.module.css';

interface Props {
  fullFI: ExtendedFI;
  finInfoExt?: FinInfoExt;
  quote: Partial<FinInfo>;
}

export const CouponsInfoBlock = ({ fullFI, finInfoExt, quote }: Props) => {
  const { objectsTable } = useAlfaDirectContext();

  const baseCurrency = useMemo(() => {
    const currencyObject = objectsTable.get(
      'idObject',
      fullFI?.idObjectFaceUnit
    );

    return currencyObject?.symbolObject;
  }, [fullFI?.idObjectFaceUnit, objectsTable]);
  const marketCurrency = useMemo(() => {
    const currencyObject = objectsTable.get(
      'idObject',
      fullFI?.idObjectCurrency
    );

    return currencyObject?.symbolObject;
  }, [fullFI?.idObjectCurrency, objectsTable]);
  const matDateObject = getStringDate(fullFI?.matDateObject);
  const currencyCode =
    fullFI.idObjectCurrency === fullFI.idObjectFaceUnit
      ? fullFI.currencyCode
      : (baseCurrency as CurrencyCodes);

  return (
    <div>
      <Typography.TitleMobile
        view="xsmall"
        tag="div"
        weight="bold"
        color="secondary"
        className={classes.couponsBlockTitle}
      >
        Купоны
      </Typography.TitleMobile>
      <InstrumentDataRowGrid
        titleLeft="Купон"
        valueLeft={
          finInfoExt?.couponValue ? (
            <Amount.Pure
              value={finInfoExt.couponValue * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency={currencyCode}
              dataTestId="BondInfoCouponValue"
            />
          ) : null
        }
        titleRight="Доходность"
        valueRight={
          quote.yield ? (
            <Typography.Text
              view="secondary-large"
              color={quote?.yield > 0 ? 'positive' : 'negative'}
            >
              {quote?.yield || 0} %
            </Typography.Text>
          ) : null
        }
      />
      <InstrumentDataRowGrid
        titleLeft="Дата выплаты"
        valueLeft={matDateObject}
        titleRight="НКД"
        valueRight={
          finInfoExt?.accruedInt ? (
            <Amount.Pure
              value={finInfoExt.accruedInt * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency={marketCurrency as CurrencyCodes}
              dataTestId="BondInfoAccruedInt"
            />
          ) : null
        }
      />
    </div>
  );
};
