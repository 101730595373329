import { Gap } from '@alfalab/core-components/gap';

import { DirectionColorForm, ThemeForm } from '../../../DesignSetting';

export const Design = () => {
  return (
    <div>
      <ThemeForm />
      <Gap size="2xl" />
      <DirectionColorForm />
    </div>
  );
};
