import { GetState } from 'zustand';

import {
  IS_BROKEN_CERTIFICATE_MODAL_WAS_SHOWN_STORAGE_KEY,
  IS_NEED_CERTIFICATE_MODAL_WAS_SHOWN_STORAGE_KEY,
} from '../../constants/certificates';
import {
  CertificateActionType,
  CertificateStateType,
} from '../../lib/client/entities';
import { getAllRSAKeys } from '../../lib/db/rsaKeys';
import { getIsStandalone, getVisitorId } from '../../lib/info';
import { LKResult } from '../../lib/rest/lkApi';
import { CertifcatesService } from '../../lib/services/certificates';
import { RootStore, StoreSet } from '../useStore';

import { Certificate, CertificateEnrollStep } from '../../types/certificate';

// Тип модального окна
export enum SingletonModalType {
  // Нет активного окна
  NONE,

  // "Необходим сертификат электронной подписи"
  NeedCertificate,

  // "Новый сертификат готов"
  NewCertificateIsReady,

  // "Сертификат отозван"
  CertificateWasRejectModal,

  // "Истек сертификат электронной подписи"
  ExpiredCertificateModal,

  // "Сертификат электронной подписи недействителен" (проблема Safari)
  BrokenCertificateWindowVisible,

  // Предложение добавить PWA на раб. стол телефона
  SuggestAddStandaloneApp,

  // Инструкция для мобилы
  MobileOnboarding,

  // Необходимо подтвердить адрес электронной почты
  NeedConfirmEmail,

  // Ссылка подтверждения адреса электронной почты - просрочена
  NeedConfirmEmailExpiredLink,

  // Электронная почта успешно подтверждена
  EmailSuccessConfirmed,
}

export interface CertificatesSlice {
  certificates: Certificate[];
  certificatesReady: boolean;
  // в этом поле хранится объект сертификата, от которого у нас есть приватный ключ
  // в indexedDB. Говоря проще, если там есть что-нибудь значит пользователь может тороговать
  workingCertificate: Certificate | null;
  initCertificates: (certificates: Certificate[]) => Promise<void>;
  addOrUpdateCertificate: (certificate: Certificate) => Promise<void>;
  removeCertificate: (id: number) => void;
  // эта функция сверяет список сертификатов пользователя с приватными ключами
  // и проставляет working certificate если есть мэтч
  ensureLocalPrivateKeys: () => Promise<void>;
  // это флоу выпуска сертификата. Есть компонент CertifcatesProvider
  // в котором инкапуслирована логика по выпуску сертифкатов
  // он один на приложение, и есть много условных кнопок которые могут решить
  // выпустить сертификат, например несколько виджетов стаканов или торговых форм
  // поэтому состояние этого флоу вынесено на уровень стейт менеджера
  certificateEnrollStep: CertificateEnrollStep;
  setCertificateEnrollStep: (step: CertificateEnrollStep) => void;

  // Окно ввода СМС кода для подтверждения действия с сертификатом
  certificateSMSWindowProps: {
    isVisible: boolean;
    certificateId: number | undefined;
    certificateActionType?: CertificateActionType;
    onSuccess?: (result: LKResult) => void;
    onClose?: () => void;
    onError?: (err: any) => void;
  };

  enrollCertificate: (
    onSuccess?: (result: LKResult) => void,
    onClose?: () => void,
    onError?: (err: any) => void
  ) => void;

  generateCertificate: () => void;

  confirmCertificate: (
    certificateId: number,
    onSuccess?: (result: LKResult) => void,
    onClose?: () => void,
    onError?: (err: any) => void
  ) => void;

  rejectCertificate: (
    certificateId: number,
    onSuccess?: (result: LKResult) => void,
    onClose?: () => void,
    onError?: (err: any) => void
  ) => void;

  hideCertificateSMSWindow: () => void;
  completeCertificateFlow: () => void;

  // если пользователь выпустил сертификат, потом закрыл окошко с смс
  // то мы хотим чтоб он подтверждал уже выпущенный сертификат, а не просить новый
  certificateEnrollId?: number;
  setCertificateEnrollId: (certificateId?: number) => void;

  // Глобальное модальное окно (алерты)
  singletonModalType: SingletonModalType;
  setSingletonModalType: (type: SingletonModalType) => void;

  brokenCertificateId?: number;
  setBrokenCertificateId: (certificateId?: number) => void;

  expiredCertificateId?: number;
  setExpiredCertificateId: (certificateId?: number) => void;
}

export const createCertificatesSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): CertificatesSlice => ({
  certificates: [],
  certificatesReady: false,
  workingCertificate: null,
  certificateSMSWindowProps: { isVisible: false, certificateId: undefined },
  certificateEnrollStep: CertificateEnrollStep.Idle,
  singletonModalType: SingletonModalType.NONE,
  addOrUpdateCertificate: async (certificate: Certificate) => {
    set((state) => {
      const id = certificate.idCertificate;

      const old = get().certificates.findIndex((c) => c.idCertificate === id);

      if (old >= 0) {
        state.certificates[old] = {
          ...certificate,
        };
      } else {
        state.certificates.push(certificate);
      }
    });

    await get().ensureLocalPrivateKeys();
  },
  removeCertificate: (id: number) => {
    set((state) => {
      const old = get().certificates.findIndex((c) => c.idCertificate === id);

      if (old >= 0) {
        state.certificates.splice(old, 1);
      }
    });

    get().ensureLocalPrivateKeys();
  },
  initCertificates: async (certificates: Certificate[]) => {
    set((state) => {
      state.certificates = certificates;
    });

    await get().ensureLocalPrivateKeys();
    set((state) => {
      state.certificatesReady = true;
    });
    get().isCoreReady();
  },

  ensureLocalPrivateKeys: async () => {
    const certificates = get().certificates;
    const storedKeys = await getAllRSAKeys();
    const visitorId = await getVisitorId();

    let workingCertificate: Certificate | null = null;
    let singletonModalType: SingletonModalType | undefined;
    let brokenCertificateId: number | undefined;
    let expiredCertificateId: number | undefined;

    const readyCertificates = certificates.filter(
      (certificate) => certificate.state === CertificateStateType.Ready
    );

    const certifyingCertificates = certificates.filter(
      (certificate) => certificate.state === CertificateStateType.Certifying
    );

    for (const certificate of readyCertificates) {
      const isThisDeviceCertificate =
        certificate?.parsedPayload?.visitorId === visitorId;
      const storedCertificate = storedKeys.find(
        (keys) => keys.id === certificate.idCertificate
      );

      // Если ключи совпадают - считаем, что это рабочий сертификат
      if (storedCertificate) {
        workingCertificate = certificate;
        singletonModalType = SingletonModalType.NONE;

        const endDate = workingCertificate?.parsedPayload?.endDate?.getTime();

        if (endDate && endDate < Date.now()) {
          expiredCertificateId = storedCertificate.id;
          singletonModalType = SingletonModalType.ExpiredCertificateModal;
        }

        break;
      }

      // Если совпадает visitorId, но на клиенте нет подходящего сертификата
      //  Считаем, что он был удален с клиента и инициируем повторный выпуск
      const isBrokenCertificateModalWasShown =
        sessionStorage.getItem(
          IS_BROKEN_CERTIFICATE_MODAL_WAS_SHOWN_STORAGE_KEY
        ) === 'true';

      if (
        isThisDeviceCertificate &&
        !storedCertificate &&
        !isBrokenCertificateModalWasShown
      ) {
        singletonModalType = SingletonModalType.BrokenCertificateWindowVisible;
        brokenCertificateId = certificate.idCertificate;
      }
    }

    const isWasNeedCertificateShown =
      sessionStorage.getItem(
        IS_NEED_CERTIFICATE_MODAL_WAS_SHOWN_STORAGE_KEY
      ) === 'true';

    if (
      getIsStandalone() &&
      !workingCertificate &&
      !singletonModalType &&
      !isWasNeedCertificateShown
    ) {
      singletonModalType = SingletonModalType.NeedCertificate;
    }

    set((state) => {
      state.workingCertificate = workingCertificate;
      state.brokenCertificateId = brokenCertificateId;
      state.expiredCertificateId = expiredCertificateId;

      if (!state.singletonModalType && singletonModalType) {
        state.singletonModalType = singletonModalType;
      }
    });

    for (const certificate of certifyingCertificates) {
      if (storedKeys.find((keys) => keys.id === certificate.idCertificate)) {
        set((state) => {
          state.certificateEnrollId = certificate.idCertificate;
          state.certificateEnrollStep = CertificateEnrollStep.OnConfirmation;
        });

        break;
      }
    }
  },

  rejectCertificate: (
    certificateId: number,
    onSuccess?: (result: LKResult) => void,
    onClose?: () => void,
    onError?: (err: any) => void
  ) => {
    set((state) => {
      state.certificateSMSWindowProps = {
        isVisible: true,
        certificateId: certificateId,
        certificateActionType: CertificateActionType.Withdraw,
        onSuccess,
        onClose,
        onError,
      };
    });
  },

  confirmCertificate: (
    certificateId: number,
    onSuccess?: (result: LKResult) => void,
    onClose?: () => void,
    onError?: (err: any) => void
  ) => {
    set((state) => {
      state.certificateSMSWindowProps = {
        isVisible: true,
        certificateId: certificateId,
        certificateActionType: CertificateActionType.Certify,
        onSuccess,
        onClose,
        onError,
      };
    });
  },

  enrollCertificate: (
    onSuccess?: (result: LKResult) => void,
    onClose?: () => void,
    onError?: (err: any) => void
  ) => {
    const certificateEnrollStep = get().certificateEnrollStep;
    const certificateEnrollId = get().certificateEnrollId;

    if (
      certificateEnrollStep === CertificateEnrollStep.OnConfirmation &&
      certificateEnrollId
    ) {
      set((state) => {
        state.certificateSMSWindowProps = {
          isVisible: true,
          certificateId: certificateEnrollId,
          certificateActionType: CertificateActionType.Certify,
          onSuccess,
          onClose,
          onError,
        };
      });
    } else {
      set((state) => {
        state.certificateEnrollId = undefined;
        state.certificateEnrollStep = CertificateEnrollStep.InProgress;
        state.certificateSMSWindowProps = {
          isVisible: true,
          certificateId: undefined,
          certificateActionType: CertificateActionType.Certify,
          onSuccess,
          onClose,
          onError,
        };
      });

      get().generateCertificate();
    }
  },

  generateCertificate: () => {
    const user = get().user;
    const selectedAccount = get().selectedAccount;

    return CertifcatesService.enrollCertificate(user!, selectedAccount!);
  },

  completeCertificateFlow: () => {
    set((state) => {
      state.certificateSMSWindowProps = {
        isVisible: false,
        certificateId: undefined,
      };

      if (
        state.certificateSMSWindowProps.certificateActionType !==
        CertificateActionType.Withdraw
      ) {
        state.certificateEnrollStep = CertificateEnrollStep.Success;
        state.certificateEnrollId = undefined;
      }
    });
  },

  setCertificateEnrollId: (certificateId?: number) => {
    set((state) => {
      state.certificateEnrollId = certificateId;
    });
  },

  setCertificateEnrollStep: (step: CertificateEnrollStep) => {
    set((state) => {
      state.certificateEnrollStep = step;
    });
  },

  setSingletonModalType: (type: SingletonModalType) => {
    set((state) => {
      state.singletonModalType = type;
    });
  },

  setBrokenCertificateId: (certificateId?: number) => {
    set((state) => {
      state.brokenCertificateId = certificateId;
    });
  },

  setExpiredCertificateId: (certificateId?: number) => {
    set((state) => {
      state.expiredCertificateId = certificateId;
    });
  },

  hideCertificateSMSWindow: () => {
    set((state) => {
      state.certificateSMSWindowProps = {
        isVisible: false,
        certificateId: undefined,
      };
    });
  },
});
