import { ExtendedFI } from '../../../types/extendedFI';
import {
  FinInfo,
  FinInfoExt as FinInfoExtType,
  TradePeriodType,
} from '../../../types/quotes';

/*
 Работа с энтитями FinInfoExt

 Фактически эти данные живут в сторе зюстанда. Ниже есть листенер который при получении этих записей
 кладет их в стор.
*/

export enum TradingStatus {
  UNKNOWN,
  CLOSED,
  OPEN,
}

// Идут ли сейчас торги
// UnP и NegAu - Статусы при которых нельзя сказать однозначно идут ли торги
// По этому считаем, что идут, чтобы не блокировать действия пользователя в случае неопределенности
// Код взят из десктопного приложения, TradeStatus.cs
export function getTradingStatus(
  finInfoExt?: FinInfoExtType,
  quote?: Partial<FinInfo>,
  fullFI?: ExtendedFI
): TradingStatus {
  if (!quote || !finInfoExt) {
    return TradingStatus.UNKNOWN;
  }

  if (quote.idTradePeriodStatus === undefined) {
    return TradingStatus.CLOSED;
  }

  let idTradePeriodStatus: TradePeriodType;

  if (finInfoExt?.idTradePeriodStatus === TradePeriodType.Bloc) {
    idTradePeriodStatus = finInfoExt?.idTradePeriodStatus;
  } else if (
    finInfoExt?.idTradePeriodStatus === TradePeriodType.Auct &&
    quote.idTradePeriodStatus !== TradePeriodType.Break
  ) {
    idTradePeriodStatus = finInfoExt?.idTradePeriodStatus;
  } else {
    idTradePeriodStatus = quote.idTradePeriodStatus;
  }

  if (!idTradePeriodStatus) {
    return TradingStatus.CLOSED;
  }

  const isOpen = [
    TradePeriodType.Clrng,
    TradePeriodType.Opng,
    TradePeriodType.Clsng,
    TradePeriodType.Trdng,
    TradePeriodType.Evng,
    TradePeriodType.Mrng,
    TradePeriodType.Auct,
    TradePeriodType.Attn,
    TradePeriodType.ClsAu,
    TradePeriodType.DPAu,
    TradePeriodType.Trnsf,
    TradePeriodType.Exp,
    TradePeriodType.Price,
    TradePeriodType.UnP,
    TradePeriodType.NegAu,
  ].includes(idTradePeriodStatus);

  return isOpen ? TradingStatus.OPEN : TradingStatus.CLOSED;
}
