import { format, isAfter, subMinutes, subSeconds } from 'date-fns';
import { groupBy, mean } from 'lodash';

export class CallTracker {
  private calls: number[] = [];

  /**
   * Регистрирует вызов
   * */
  public track(): void {
    this.calls.push(Date.now());
  }

  /**
   * @returns количество вызовов за последнюю минуту
   * */
  public countLastMinuteCalls(): number {
    return this.countCallsSince(subMinutes(new Date(), 1));
  }

  /**
   * @returns количество вызовов за последнюю секунду
   * */
  public countLastSecondCalls(): number {
    return this.countCallsSince(subSeconds(new Date(), 1));
  }

  /**
   * @returns среднее количество вызовов в минуту
   * */
  public countAvgCallsPerMinute(): number {
    return this.countAvgCallsByDateFormat('hh:mm-dd.MM.yyyy');
  }

  /**
   * @returns среднее количество вызовов в секунду
   * */
  public countAvgCallsPerSecond(): number {
    return this.countAvgCallsByDateFormat('hh:mm:ss-dd.MM.yyyy');
  }

  private countAvgCallsByDateFormat(dateFormat: string): number {
    const groups = groupBy(this.calls, (value) => format(value, dateFormat));

    if (this.calls.length > 0) {
      delete groups[format(this.calls[0], dateFormat)];
    }

    delete groups[format(new Date(), dateFormat)];

    return mean(Object.values(groups).map((events) => events.length));
  }

  private countCallsSince(startDate: Date): number {
    let sum = 0;

    for (
      let i = this.calls.length - 1;
      i >= 0 && isAfter(this.calls[i], startDate);
      i--
    ) {
      sum++;
    }

    return sum;
  }
}
