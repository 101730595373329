import { RootStore } from '../../useStore';

import { ObjectTypeItem } from '../../../types/core';

/**
 * Выбирает тип ценной бумаги по идентификатору типа ценной бумаги
 * */
export function objectTypeById(
  store: RootStore
): (
  idObjectType: ObjectTypeItem['idObjectType']
) => ObjectTypeItem | undefined {
  return (idObjectType) =>
    store.objectTypesTable.get('idObjectType', idObjectType);
}
