import { OrderStatus } from './client/entities';

import { OrderItem } from '../types/order';

export const isPosActualQuantity = (
  orders: OrderItem[],
  idObject: number,
  torgPos: number
) => {
  const posDiff = orders
    .filter((o) => o.idObject === idObject)
    .reduce((q, o) => {
      if (
        o.idOrderStatus === OrderStatus.Filled ||
        o.idOrderStatus === OrderStatus.Working
      ) {
        return o.buySell === 1
          ? q - o.quantity + o.rest
          : q + o.quantity - o.rest;
      }

      return q;
    }, 0);

  return torgPos !== posDiff;
};
