import { VFC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';

import { Result } from '../../../../shared';

import styles from './OrderFormApplied.module.css';

interface FormAppliedProps {
  primaryButtonLabel: string;
  onPrimaryButtonClick: () => void;
  secondaryButtonLabel: string;
  onSecondaryButtonClick: () => void;
}

export const OrderFormApplied: VFC<FormAppliedProps> = ({
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
}) => (
  <Result
    title="Документ успешно заказан"
    text="Мы подготовим его в течение нескольких дней. Максимальный срок исполнения —&nbsp;30 дней. Отслеживать статус заказа вы можете в истории."
    icon={
      <SuperEllipse size={48}>
        <CheckmarkMIcon />
      </SuperEllipse>
    }
    maxWidth={360}
  >
    <div className={styles.buttonGroup}>
      <ButtonDesktop size="xs" view="accent" onClick={onPrimaryButtonClick}>
        {primaryButtonLabel}
      </ButtonDesktop>
      <ButtonDesktop size="xs" view="tertiary" onClick={onSecondaryButtonClick}>
        {secondaryButtonLabel}
      </ButtonDesktop>
    </div>
  </Result>
);
