import { TradingClosed } from './TradingClosed';

import styles from './TradingClosed.module.css';

export const TradingClosedDesktop = () => {
  return (
    <div className={styles.wrapperDesktop}>
      <TradingClosed />
    </div>
  );
};
