import React, { FC, MouseEventHandler } from 'react';
import {
  IconButton,
  IconButtonProps,
} from '@alfalab/core-components/icon-button';
import { PencilSIcon } from '@alfalab/icons-glyph/PencilSIcon';

import { useStore } from '@terminal/core/store';
import { TrackPrice, TrackPriceState } from '@terminal/core/types/trackPrice';

export type EditButtonProps = Omit<
  IconButtonProps,
  'icon' | 'size' | 'title' | 'onClick'
> & {
  trackPrice: TrackPrice;
};

export const EditButton: FC<EditButtonProps> = (props) => {
  const { trackPrice, ...restProps } = props;
  const openEditModal = useStore((store) => store.openEditTrackPriceModal);
  const isActive = trackPrice.IdState === TrackPriceState.Active;

  if (!isActive) {
    return null;
  }

  const handleClick: MouseEventHandler = () => {
    openEditModal(trackPrice);
  };

  return (
    <IconButton
      icon={PencilSIcon}
      size="xs"
      view="secondary"
      title="Редактировать"
      onClick={handleClick}
      {...restProps}
    />
  );
};
