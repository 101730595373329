import { Typography } from '@alfalab/core-components/typography';
import { BookMIcon } from '@alfalab/icons-glyph/BookMIcon';

import styles from './StrategyAddon.module.css';

interface StrategyAddonProps {
  name: string;
}

export const StrategyAddon = ({ name }: StrategyAddonProps) => {
  return (
    <div className={styles.container}>
      <BookMIcon className={styles.icon} />
      <Typography.Text view="secondary-small">{name}</Typography.Text>
    </div>
  );
};
