import { PositionItem } from '@terminal/core/types/position';

export function getGoFromPosition(position: PositionItem): number {
  try {
    const {
      finInfoExt: { gtBuy, gtSell },
      torgPos,
    } = position;

    const gt = torgPos < 0 ? gtSell : gtBuy;

    return Math.abs(torgPos * gt);
  } catch (e) {
    return 0;
  }
}
