import { FC } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { UniversalExchangeCode } from '@terminal/core/lib/client/entities';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { getAssetUnits, getExchangeName } from '@terminal/core/lib/format';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

import classes from './ExchangeInformation.module.css';

interface Props {
  fullFI?: ExtendedFI;
  finInfoExt?: FinInfoExt;
  priceDecimals?: number;
  isin?: string;
}

const ExchangeInformation: FC<Props> = ({
  fullFI,
  finInfoExt,
  priceDecimals,
  isin,
}) => {
  return (
    <div>
      <Typography.TitleMobile
        view="xsmall"
        tag="div"
        weight="bold"
        color="secondary"
        className={classes.exchangeInfo}
      >
        Биржевая информация
      </Typography.TitleMobile>
      <div>
        {fullFI && (
          <div className={classes.dataRow}>
            <Typography.Text color="secondary" view="secondary-large">
              Биржа
            </Typography.Text>
            <Typography.Text view="secondary-large">
              {Object.values(UniversalExchangeCode).some(
                (item) => item === fullFI.rCode
              )
                ? getExchangeName(fullFI.rCode)
                : fullFI?.nameMarketBoard}
            </Typography.Text>
          </div>
        )}
        {fullFI && finInfoExt && finInfoExt.lot ? (
          <div className={classes.dataRow}>
            <Typography.Text color="secondary" view="secondary-large">
              Размер лота
            </Typography.Text>
            <Typography.Text view="secondary-large">
              {finInfoExt.lot}{' '}
              {getAssetUnits(
                finInfoExt.lot,
                OrderDimensionEnum.PIECE,
                fullFI.idObjectType.value
              )}
            </Typography.Text>
          </div>
        ) : null}
        {finInfoExt && (
          <div className={classes.dataRow}>
            <Typography.Text color="secondary" view="secondary-large">
              Минимальный шаг цены
            </Typography.Text>
            <Typography.Text view="secondary-large">
              {finInfoExt.priceStep}
            </Typography.Text>
          </div>
        )}
        {finInfoExt && fullFI && priceDecimals && (
          <div className={classes.dataRow}>
            <Typography.Text color="secondary" view="secondary-large">
              Стоимость шага цены
            </Typography.Text>
            <Typography.Text view="secondary-large">
              <Amount.Pure
                value={finInfoExt.priceStepCost * priceDecimals}
                minority={priceDecimals}
                view="withZeroMinorPart"
                currency={getSymbolToShow(
                  fullFI.currencyCode,
                  fullFI.idObjectGroup
                )}
                dataTestId="FutureInfoPriceStepCost"
              />
            </Typography.Text>
          </div>
        )}
        {isin ? (
          <div className={classes.dataRow}>
            <Typography.Text color="secondary" view="secondary-large">
              ISIN
            </Typography.Text>
            <Typography.Text view="secondary-large">{isin}</Typography.Text>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { ExchangeInformation };
