import { RootStore } from '../../useStore';
import { trackPriceSettingsByIdSelector } from '../workspaceConfigurations/trackPriceSettingsByIdSelector';

import { TrackPrice } from '../../../types/trackPrice';

/**
 * Выбирает все алерты (в ожидании и сработавшие, но не снятые)
 * */
export function trackPricesSelector(store: RootStore): TrackPrice[] {
  const trackPriceSettingsById = trackPriceSettingsByIdSelector(store);

  return store.trackPrices.map((trackPrice) => ({
    ...trackPrice,
    settings: trackPriceSettingsById(trackPrice.IdTrackPrice),
  }));
}
