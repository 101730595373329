import { makeGetParams } from '../url';
import { fetchLkResult } from './lkApi';
import { BankAccount } from './lkMoney';
import { signOperation } from './lkSignOperation';

import { LkSignOptions } from '../../store/slices/lkSignSlice';

export interface AccountInfoParams {
  treaty: number;
}

export enum QualStatus {
  DEFAULT = 'default',
  IN_PROGRESS = 'inProgress',
  CANDIDATE = 'candidate',
  QUAL_INVESTOR = 'qualInvestor',
}

export interface AccountInfo {
  treaty: number;
  accountType: string;
  accountTypeCode: string;
  accountStatus: string;
  qualifiedInvestorDate: string | null;
  qualInvestorStatus: QualStatus;
  openDate: string;
  owner: string;
  tpCode: string;
  tarifName: string;
  indLimit: number;
  tpGroup: string;
  nextTpGroup: string;
  idAccountGroup: number;
  nameAccountGroup: string;
  nextTpCode: string | null;
  nextTarifName: string | null;
  taxRate: number;
  taxRateDate: string;
  iiaTypeId: number;
  spbClientField: string;
  subAccounts: SubAccount[];
  bankAccounts?: BankAccount[];
  depoAccounts?: DepoAccountInfo[];
  tarifs: AccountTarriff[];
  marginTypes: AccountMarginType[];
  treatyManagers: AccountConfidant[] | null;
  permissions: AccountPermissions;
  personalBroker: AccountPersonalBroker;
}

export interface SubAccount {
  treaty: number;
  accCode: string;
  accName: string;
  indLimit: number;
  software?: string;
  fortsAccount?: string;
}

export interface DepoAccountInfo {
  depoAccount: string;
  placeName: string;
  placeCode: string;
}

export interface AccountTarriff {
  tpCode: string;
  tpGroup: string;
  name: string;
}

export interface AccountPermissions {
  canCloseAccount: boolean;
  canAddSubAccount: boolean;
  canChangeTp: boolean;
  canChangeAccountGroup: boolean;
  canAddTreatyManager: boolean;
  canViewSubAccounts: boolean;
  canViewTreatyManagers: boolean;
}

export interface AccountMarginType {
  idAccountGroup: string;
  nameAccountGroup: string;
}

export interface AccountConfidant {
  sysName: string;
  fullName: string;
  accessLevel: string;
  accCode: string;
  accessDate: string;
}

export interface AccountPersonalBroker {
  canAcquire: boolean;
  isAcquired: boolean;
  inProcess: boolean;
  brokerInfo: AccountPersonalBrokerInfo | null;
}

export interface AccountPersonalBrokerInfo {
  fio: string;
  phone: string;
  email: string;
  sysname: string;
}

export async function getAccountInfo(
  params: AccountInfoParams
): Promise<AccountInfo> {
  const res = await fetchLkResult<AccountInfo>(
    `/api/client/accounts/info/${params.treaty}`
  );

  return res;
}

export interface AccountTarriffGI {
  tpGroup: string;
  name: string;
}

export interface AccountInfoGI extends Omit<AccountInfo, 'tarifs'> {
  tarifs: AccountTarriffGI[];
}

// В ГоИнвест используется другая версия api
export async function getAccountInfoGI(
  params: AccountInfoParams
): Promise<AccountInfoGI> {
  const res = await fetchLkResult<AccountInfoGI>(
    `/api/client/accounts/info/v2/${params.treaty}`
  );

  return res;
}

export interface TradingPlatformsStateParams {
  treaty: number;
}

export type TradingPlatformStateType =
  | 'NotRegistred'
  | 'Registred'
  | 'InProgress'
  | 'Declined';

export interface FatcaState {
  canSignFatca: boolean;
  state: TradingPlatformStateType;
  signTime: string | null;
  expirationDate: string | null;
}

export interface FortsPortfolioTrading {
  subAccount: string;
  state: TradingPlatformStateType;
}

export interface TradingPlatformsState {
  spbex: TradingPlatformStateType;
  forts: FortsPortfolioTrading[];
  selt: TradingPlatformStateType;
  fatca: FatcaState;
}

export async function getTradingPlatformsState(
  params: TradingPlatformsStateParams
): Promise<TradingPlatformsState> {
  const res = await fetchLkResult<TradingPlatformsState>(
    `/api/trading-platform/registration-state/${params.treaty}`
  );

  return res;
}

export interface ChangeTariffRequest {
  treaty: number;
  tariff: string;
}

export interface ChangeTariffParams {
  params: ChangeTariffRequest;
  signOptions?: LkSignOptions;
}

export async function changeTariff(data: ChangeTariffParams) {
  return await signOperation(
    '/api/client/accounts/v2/change-tariff',
    'POST',
    data.params,
    {
      ...data.signOptions,
      confirmation: {
        codes: [2],
      },
    }
  );
}

// В ГоИнвест используется другая версия api
export async function changeTariffGI(data: ChangeTariffParams) {
  return await signOperation(
    '/api/client/accounts/change-tariff/v2',
    'POST',
    data.params,
    {
      ...data.signOptions,
      confirmation: {
        codes: [2],
      },
    }
  );
}

export interface ChangeOperationModeRequest {
  treaty: number;
  operationMode: string;
}

export interface ChangeOperationModeParams {
  params: ChangeOperationModeRequest;
  signOptions?: LkSignOptions;
}

export async function changeOperationMode(data: ChangeOperationModeParams) {
  return await signOperation(
    '/api/client/accounts/change-operation-mode',
    'POST',
    data.params,
    data.signOptions
  );
}

export interface FortsRegistrationRequest {
  accCode: string;
}

export interface FortsRegistrationParams {
  params: FortsRegistrationRequest;
  signOptions?: LkSignOptions;
}

export async function fortsRegistration(data: FortsRegistrationParams) {
  const request = makeGetParams(data.params);
  return await signOperation(
    `/api/trading-platform/register-on-forts?${request}`,
    'GET',
    undefined,
    data.signOptions
  );
}

export interface SpbexRegistrationRequest {
  treaty: number;
}

export interface SpbexRegistrationParams {
  params: SpbexRegistrationRequest;
  signOptions?: LkSignOptions;
}

export async function spbexRegistration(data: SpbexRegistrationParams) {
  return await signOperation(
    `/api/trading-platform/register-on-spbex/${data.params.treaty}`,
    'GET',
    undefined,
    data.signOptions
  );
}

export interface AddSubaccountRequest {
  treaty: number;
}

export interface AddSubaccountParams {
  params: AddSubaccountRequest;
  signOptions?: LkSignOptions;
}

export async function addSubAccount(data: AddSubaccountParams) {
  return await signOperation(
    `/api/client/accounts/subaccount/add/${data.params.treaty}`,
    'POST',
    undefined,
    data.signOptions
  );
}

export interface SubAccountRedactRequest {
  treaty: number;
  subAccount?: string;
  nickName: string;
  limit: number;
}

export interface SubAccountRedactParams {
  params: SubAccountRedactRequest;
  signOptions?: LkSignOptions;
}

export async function updateSubAccount(data: SubAccountRedactParams) {
  return await signOperation(
    `/api/client/accounts/subaccount/update`,
    'POST',
    data.params,
    data.signOptions
  );
}
