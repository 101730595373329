import React, { ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import {
  Confirmation as ConfirmationOrigin,
  ConfirmationProps,
} from '@alfalab/core-components/confirmation';
import { ScreensMap } from '@alfalab/core-components/confirmation/shared';

import { ConfirmationHint } from './ConfirmationHint';

export type ConfirmationScreens = Record<
  string,
  (props: ConfirmationProps) => ReactNode
>;

interface Props extends ConfirmationProps {
  screens?: ConfirmationScreens;
}

export const screensMap =
  ({ screens = {}, ...props }: Props) =>
  (screensMap: ScreensMap) => {
    const screensWithProps = Object.fromEntries(
      Object.entries(screens).map(([key, fn]) => [key, () => fn(props)])
    );

    return {
      ...screensMap,
      HINT: () => <ConfirmationHint {...props} />,
      ...screensWithProps,
    };
  };

const Confirmation = (props: Props) => {
  return <ConfirmationOrigin getScreensMap={screensMap(props)} {...props} />;
};

export default Confirmation;
