import { SubAccountRazdelEntity } from '../lib/client/entities';

import { SubAccountRazdelItem } from '../types/subAccountRazdel';

export const subAccountRazdelMap = (
  entity: SubAccountRazdelEntity
): SubAccountRazdelItem => ({
  idSubAccount: entity.IdSubAccount,
  idRazdel: entity.IdRazdel,
  idRazdelType: entity.IdRazdelType,
  idDepoAccount: entity.IdDepoAccount,
  idAccount: entity.IdAccount,
  idGate: entity.IdGate,
  idRazdelGroup: entity.IdRazdelGroup,
  codeRazdelType: entity.CodeRazdelType,
  tradeAccount: entity.TradeAccount,
  clientCode: entity.ClientCode,
  codeSubAccount: entity.CodeSubAccount,
  codeDepoAccount: entity.CodeDepoAccount,
  corCodeRazdel: entity.CorCodeRazdel,
  nameClient: entity.NameClient,
  nameDeponent: entity.NameDeponent,
  nameCustodian: entity.NameCustodian,
  nameCorCustodian: entity.NameCorCustodian,
  rCode: entity.RCode,
  nameSubAccount: entity.NameSubAccount,
  nameRazdelType: entity.NameRazdelType,
  version: entity.Version?.toString() ?? '',
  operation: entity.Operation,
  idAccessType: entity.IdAccessType,
  frontID: entity.FrontID,
  idRazdelBalance: entity.IdRazdelBalance,
  idRazdelTypeBalance: entity.IdRazdelTypeBalance,
  universalExchangeCode: entity.UniversalExchangeCode,
});
