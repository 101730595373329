import { FC, useRef, useState } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { DotsHorizontalMIcon } from '@alfalab/icons-glyph/DotsHorizontalMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';

import styles from './AdditionalSlippageSettings.module.css';

interface Props {
  slippageAdditionalPercent: number | null;
  setSlippageAdditionalPercent: (slippage: number | null) => void;
}

export const AdditionalSlippageSettings: FC<Props> = ({
  slippageAdditionalPercent,
  setSlippageAdditionalPercent,
}) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const popoverRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(popoverRef, (e) => {
    const anchor = popoverRef.current;

    if (anchor && !anchor?.contains(e.target as Node)) {
      setPopoverVisible(false);
    }
  });

  return (
    <div ref={popoverRef} className={styles.button}>
      <DotsHorizontalMIcon
        width={12}
        height={12}
        color="var(--color-dark-graphic-secondary)"
        onClick={() => setPopoverVisible((prev) => !prev)}
      />

      <Dropdown
        anchorElement={popoverRef.current}
        open={popoverVisible}
        offset={[0, 12]}
        position="bottom-end"
        popperClassName={styles.popover}
      >
        {[0.05, 0.1, 0.15, 0.2, 0.25].map((percent) => (
          <Dropdown.Item
            key={percent}
            rightIcon={
              percent === slippageAdditionalPercent ? (
                <CheckmarkMIcon height={18} width={18} />
              ) : undefined
            }
            content={
              <div className={styles.dropDownItemContent}>
                {percent.toFixed(2)}
                <Typography.Text view="secondary-large" color="secondary">
                  %
                </Typography.Text>
              </div>
            }
            onClick={() => {
              setSlippageAdditionalPercent(percent);
            }}
          />
        ))}
      </Dropdown>
    </div>
  );
};
