import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { fetchLkFile } from '@terminal/core/lib/rest/lkApi';
import { NotificationType } from '@terminal/core/types/ui';

export const useMoneyHistoryFile = () => {
  const addNotification = useNotification();

  return useMutation(fetchLkFile, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса файла истории',
        text: error?.message || '',
      });
    },
  });
};
