import cn from 'classnames';
import { parse } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownBoldContainerMIcon } from '@alfalab/icons-glyph/ArrowDownBoldContainerMIcon';
import { CrossMediumMIcon } from '@alfalab/icons-glyph/CrossMediumMIcon';
import { UserMIcon } from '@alfalab/icons-glyph/UserMIcon';

import { ATTORNEY_EXPIRATION_PERIOD } from '../../lib/const/attorneyExpirationPeriod';
import { AttorneyTableItem } from './AttorneyTable';

import styles from './AttorneyTable.module.css';

interface AttorneyTableRowProps {
  attorney: AttorneyTableItem;
  showAccount?: boolean;
  onReject?: () => Promise<void> | void;
  onDownload?: () => Promise<void> | void;
}

export const AttorneyTableRow: FC<AttorneyTableRowProps> = ({
  attorney,
  showAccount,
  onReject,
  onDownload,
}) => {
  const [fileLoading, setFileLoading] = useState(false);

  const handleAttorneyDownload = useCallback(async () => {
    if (onDownload) {
      try {
        setFileLoading(true);
        await onDownload();
      } finally {
        setFileLoading(false);
      }
    }
  }, [onDownload]);

  const endDate = parse(attorney.endDate, 'dd.MM.yyyy', new Date());
  const durationTillEnd = endDate.valueOf() - new Date().valueOf();
  const dateColor =
    durationTillEnd >= 0 && durationTillEnd < ATTORNEY_EXPIRATION_PERIOD
      ? 'negative'
      : 'secondary';

  return (
    <tr key={attorney.key} className={styles.row}>
      <td className={styles.cell}>
        <div className={styles.accountCell}>
          <UserMIcon width={16} className={styles.userIcon} />
          <div className={styles.accountInfo}>
            <Typography.Text view="secondary-small" weight="medium" tag="div">
              {attorney.fullName}
            </Typography.Text>
            <Typography.Text view="secondary-small" tag="div">
              {showAccount && <>{attorney.account} </>}
              <Typography.Text
                view="secondary-small"
                tag="span"
                color="secondary"
              >
                {attorney.login}
              </Typography.Text>
            </Typography.Text>
          </div>
        </div>
      </td>
      <td className={styles.cell}>
        <Typography.Text view="secondary-small" weight="medium" tag="div">
          {attorney.accessLevel}
        </Typography.Text>
        <Typography.Text view="secondary-small" tag="div" color={dateColor}>
          {attorney.endDate}
        </Typography.Text>
      </td>
      <td className={cn(styles.cell, styles.actionCell)}>
        <div className={styles.actionButtons}>
          {onDownload && (
            <TooltipDesktop position="bottom" trigger="hover" content="Скачать">
              <IconButton
                view="secondary"
                size="xxs"
                icon={ArrowDownBoldContainerMIcon}
                onClick={handleAttorneyDownload}
                loading={fileLoading}
                disabled={fileLoading}
              />
            </TooltipDesktop>
          )}

          {onReject && (
            <TooltipDesktop
              position="bottom"
              trigger="hover"
              content="Отозвать"
            >
              <IconButton
                view="secondary"
                size="xxs"
                icon={CrossMediumMIcon}
                onClick={onReject}
              />
            </TooltipDesktop>
          )}
        </div>
      </td>
    </tr>
  );
};
