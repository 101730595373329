import { useMemo } from 'react';

import { OrderDirection, OrderStatus, OrderType } from '../lib/client/entities';
import { makeOrderKey } from '../lib/domain/makeOrderKey';
import { isPosActualQuantity } from '../lib/isPosActualQuantity';
import { useObjectByIdMap } from './domain/useObjectByIdMap';
import { useOrdersMap } from './useOrdersMap';

import { useStore } from '../store';

import { PositionItem } from '../types/position';

export const usePositionCancelable = (position?: PositionItem): boolean => {
  const orders = useStore((store) => store.orders);
  const ordersMap = useOrdersMap(orders);
  const objectByIdMap = useObjectByIdMap();

  // Существует промежуток между выполнением заявки по инструменту и обновлением
  // данных по позиции, что позволяет юзеру жать кнопки закрытия у позиции, которая уже может быть закрыта
  // Для этого нам нужна дополнительная проверка - берем выполненные и частично-выполненные ордера по инструменту,
  // и суммируем их кол-во лотов, которое не должно равняться torgPos в позиции
  // Работает с ненулевой позицией
  const isActualQuantity = useMemo(
    () =>
      position
        ? isPosActualQuantity(
            orders,
            position.position.idObject,
            position.torgPos
          )
        : false,
    [orders, position]
  );

  if (!position) {
    return false;
  }

  /** заявки на покупку */
  const orderLong =
    ordersMap[makeOrderKey(position.position, OrderDirection.Buy)] ?? [];
  /** заявки на продажу */
  const orderShort =
    ordersMap[makeOrderKey(position.position, OrderDirection.Sell)] ?? [];

  //Отсеиваем позицию рубля и сумма лонга и шорта которых равно 0 (не можем ни продать ни купить, позиция считается уже закрытой)
  //Также смотрим, есть ли уже заявка на закрытие позиции
  const isCancelOrderSend = (
    position.torgPos > 0 ? orderShort : orderLong
  ).find(
    (order) =>
      order.idOrderType === OrderType.MKT &&
      order.rest === Math.abs(position.torgPos) &&
      ![
        OrderStatus.Filled,
        OrderStatus.Cancelled,
        OrderStatus.Rejected,
      ].includes(order.idOrderStatus)
  );

  return (
    position.torgPos !== 0 &&
    isActualQuantity &&
    objectByIdMap.get(position.position.idObject)?.idObject !== 174368 &&
    !isCancelOrderSend
  );
};
