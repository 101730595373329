import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { fetchLkFile } from '@terminal/core/lib/rest/lkApi';
import { makeGetParams } from '@terminal/core/lib/url';
import { NotificationType } from '@terminal/core/types/ui';

import { LK_API } from '../const/externalUrls';

interface DepoPdfLinkParams {
  treaty: number;
  depoaccount: string;
  market?: string;
}

export const useDepoRequisitesFile = () => {
  const addNotification = useNotification();

  return useMutation(
    async (data: DepoPdfLinkParams) => {
      const pdfLinkRequest = makeGetParams(data);

      return await fetchLkFile(
        `${LK_API.REQUISITES_DEPO_PDF}?${pdfLinkRequest}`
      );
    },
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса реквизитов',
          text: error?.message || '',
        });
      },
    }
  );
};
