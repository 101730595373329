import cn from 'classnames';
import { Color, Typography } from '@alfalab/core-components/typography';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';

import { DocumentsHistoryItem } from '@terminal/core/lib/rest/lkDocuments';

import styles from './HistoryItem.module.css';

interface HistoryItemProps {
  item: DocumentsHistoryItem;
  treaty: number;
  odd?: boolean;
  onClick?: (item: DocumentsHistoryItem) => void;
}

const DOCUMENT_STATUS_COLOR: Record<string, Color> = {
  Исполнено: 'positive',
  Принято: 'secondary',
  Отклонено: 'negative',
};

export const HistoryItem = ({
  item,
  treaty,
  odd,
  onClick,
}: HistoryItemProps) => {
  const StatusColor = DOCUMENT_STATUS_COLOR[item.status];

  return (
    <div
      className={cn(styles.container, {
        [styles.odd]: Boolean(odd),
      })}
      onClick={() => onClick?.(item)}
    >
      <div className={styles.icon}>
        <DocumentLinesMIcon className={styles.glyph} height={18} width={18} />
      </div>
      <div className={styles.content}>
        <div>{item.theme}</div>
        <div className={styles.account}>{treaty}</div>
      </div>
      <div className={styles.status}>
        <Typography.Text view="secondary-small" color={StatusColor}>
          {item.status}
        </Typography.Text>
      </div>
    </div>
  );
};
