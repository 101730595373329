import { useMarketBoardByIdMap } from './useMarketBoardByIdMap';
import { useObjectByIdMap } from './useObjectByIdMap';

/**
 * @param idFi идентификатор финансового инструмента
 * @returns строку с кратким наименованием финансового инструмента и рынка
 * @example `RNFT=МБ ЦК`
 * */
export function useSymbolSpec(idFi: number): string {
  const objects = useObjectByIdMap();
  const marketBoards = useMarketBoardByIdMap();
  const { symbolObject = '' } = objects.get(idFi) || {};
  const { nameMarketBoard = '' } = marketBoards.get(idFi) || {};

  return [symbolObject, nameMarketBoard].join('=');
}
