import { add, isAfter } from 'date-fns';
import { EventEmitter } from 'eventemitter3';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import log from 'loglevel';
import { encode } from 'single-byte';

import {
  ActivationTime,
  TestLinksMap,
  TestRequiredCodes,
} from '../../../constants/orderBook';
import { trackLinkedOrder, trackTickerTypeOrder } from '../../analytics';
import { alfaDirectClient } from '../../client/client';
import { jsDateToTicks } from '../../client/encode';
import {
  CancelOrderFlags,
  ClientCancelOrderReq,
  ClientCancelOrderRsp,
  ClientEnterOrderReq,
  ClientEnterOrderRsp,
  EnterOrderFlags,
  ExecutionType,
  FrontEndType,
  LifeTime,
  Messages,
  OrderChannels,
  OrderDirection,
  OrderStopPriceDirection,
  OrderType,
  PriceControlType,
  QuantityType,
  SignType,
} from '../../client/entities';
import { EntityType } from '../../client/entityTypes';
import { createCMS } from '../../crypto/cms';
import { getRSAKey } from '../../db/rsaKeys';
import { getAllowedOrderParamsId } from '../../domain/getAllowedOrderParamsId';
import { getInstrumentId } from '../../domain/getInstrumentId';
import { roundFloatNumber, roundPrice } from '../../format';
import {
  getOrderDisplayPrice,
  getOrderSendPrice,
  getPrice,
} from '../../formulas';
import { getFullFI } from '../../getFullFI';
import { createNotification } from '../../notifications';
import { OrderNum } from '../orderNum';
import { getNotificationText, OrdersWatcherService } from '../ordersWatcher';
import { TextOrderService } from '../textOrder';
import { getServerSignTime } from '../time';
import { getBracketOrderParams } from './getBracketOrderParams';

import { useStore } from '../../../store';
import { selectActiveAndWaitingOrders } from '../../../store/selectors';

import { NewOrderType, OrderItem } from '../../../types/order';
import { FinInfoExt } from '../../../types/quotes';
import {
  BracketOrderParams,
  CommandTypeBase,
  InputClientEnterOrderReq,
  InputOrderTextParams,
  InputSendOrderParams,
  OrderRequest,
  SendOrderParams,
} from '../../../types/trading';
import {
  Notification,
  NotificationBadge,
  NotificationType,
  PendingOrder,
} from '../../../types/ui';

/* Сервис работы с заявками. Нужно создать инстанс сервер, подписаться на ивенты
service.on('success', () => {}) и service.on('error', (message:string) => {})

После завершения работы с инстансом сервиса нужно вызвать clear();
*/

/*
Сервис работы с заявками. Нужно создать инстанс сервер, подписаться на ивенты
service.on('success', () => {}) и service.on('error', (message:string) => {})

После завершения работы с инстансом сервиса нужно вызвать clear();
*/

const getOrderActivationTime = (serverTime: Date, type: ActivationTime) => {
  switch (type) {
    case ActivationTime.NOW:
      return;
    case ActivationTime.HOUR:
      return add(serverTime, { hours: 1 });
    case ActivationTime.DAY:
      return add(serverTime, { days: 1 });
    case ActivationTime.WEEK:
      return add(serverTime, { weeks: 1 });

    default:
      return;
  }
};

type CreateOrderRequestParams = {
  buy: boolean;
  quantity: number;
  idSubAccount: number;
  params: OrderRequest;
  idActivationTime: ActivationTime;
  activationTime?: Date;
  stopPrice?: number;
  withDrawTime?: Date;
  limitPrice?: number;
  limitLevelAlternative?: number;
  openQuantity?: number;
  linkedOrderId?: string;
  numEDocument?: string;
  activationPriceDirection?: OrderStopPriceDirection;
};

export class TradingService extends EventEmitter {
  textOrderService: TextOrderService;
  private readonly orderChannel: OrderChannels;

  constructor(
    textOrderService: TextOrderService,
    orderChannel?: OrderChannels
  ) {
    super();
    this.textOrderService = textOrderService || new TextOrderService();
    this.orderChannel = orderChannel ?? OrderChannels.AD4DT;
  }

  async prepareRequestParams(
    idFI: number,
    idSubaccount: number,
    idExecutionType: ExecutionType,
    idLifeTime: LifeTime,
    idOrderType: OrderType,
    idPriceControlType: PriceControlType,
    idQuantityType: QuantityType,
    idFIActivate?: number
  ): Promise<OrderRequest | undefined> {
    const store = useStore.getState();
    const user = store.user;

    //Проверяем данные о фин инструменте
    const fullFI = getFullFI(
      idFI,
      store.objectsTable,
      store.objectTypesTable,
      store.finInstrumentsTable,
      store.marketBoardsTable
    );

    if (!fullFI) {
      throw new Error(
        'Не найдена необходимая информация о финансовом инструменте'
      );
    }

    //Проверяем данные о фин инструменте
    const fullFIActivate = idFIActivate
      ? getFullFI(
          idFIActivate,
          store.objectsTable,
          store.objectTypesTable,
          store.finInstrumentsTable,
          store.marketBoardsTable
        )
      : undefined;

    if (idFIActivate && !fullFIActivate) {
      throw new Error(
        'Не найдена необходимая информация о финансовом инструменте Контролируемого Параметра'
      );
    }

    //Проверяем сертификаты
    const workingCertificate = store.workingCertificate;

    if (!workingCertificate) {
      throw new Error('Не найден валидный сертификат');
    }

    //Проверяем ключи сертификата
    const rsaKeys = await getRSAKey(workingCertificate?.idCertificate);

    if (!rsaKeys) {
      throw new Error('Не найден приватный ключ для выбранного сертификата');
    }

    // rCode содержится у MarketBoard на которую ссылается Object
    // находим раздел, где живет финансовый инструмент
    const razdel = store.subAccountRazdel
      .filter((razdel) => razdel.idSubAccount === idSubaccount)
      .find((razdel) => razdel.rCode === fullFI.rCode);

    if (!razdel) {
      throw new Error('Не найден раздел для финансового инструмента');
    }

    // id нашего аккаунта ака "Номер генерального соглашения"
    const subAccount = store.subAccounts.find(
      (subAccount) => subAccount.idSubAccount === idSubaccount
    );

    if (!subAccount) {
      throw new Error('Не найдены данные о генеральном соглашении');
    }

    const idAccount = subAccount.idAccount;

    //Проверяем допустимость параметров для заявки
    const orderParams = getAllowedOrderParamsId(
      fullFI,
      store.orderParams,
      idOrderType,
      idExecutionType,
      idLifeTime,
      idQuantityType
    );

    if (!orderParams) {
      throw new Error('Не найдены необходимые параметры для создания заявки');
    }

    if (isNull(user)) {
      throw new Error('Не найдены данные пользователя');
    }

    if (isUndefined(idPriceControlType)) {
      throw new Error('Не задан тип Контролируемого Параметра');
    }

    return {
      fullFIActivate,
      fullFI,
      workingCertificate,
      rsaKeys,
      razdel,
      idAccount,
      orderParams,
      idOrderType,
      fullName: user.fullName,
      login: user.login,
      idLifeTime,
      idPriceControlType,
      idFIActivate,
    };
  }

  getTextOrder(params: InputOrderTextParams) {
    try {
      const orderText = this.textOrderService.generateTextDocument(params);

      log.debug('ТЕКСТ ЗАЯВКИ');
      log.debug(orderText);

      return orderText;
    } catch (err) {
      if (err instanceof Error) {
        this.emit('error', err.message);
      }

      return;
    }
  }

  //Формирование данных запроса
  async createOrderRequest({
    limitPrice,
    stopPrice,
    buy,
    quantity,
    idSubAccount,
    params,
    idActivationTime,
    activationTime,
    withDrawTime,
    openQuantity,
    linkedOrderId,
    numEDocument,
    activationPriceDirection,
    limitLevelAlternative,
  }: CreateOrderRequestParams) {
    const orderPrefix = useStore.getState().orderPrefix;
    const {
      fullFI,
      workingCertificate,
      rsaKeys,
      razdel,
      idAccount,
      orderParams,
      login,
      fullName,
      idLifeTime,
      idPriceControlType,
      fullFIActivate,
      idFIActivate,
    } = params;
    // Время подписи заявки (серверное)
    const signTime = getServerSignTime();
    // Время активации
    const actTime =
      (activationTime && isAfter(activationTime, new Date())
        ? activationTime
        : undefined) ||
      getOrderActivationTime(signTime, idActivationTime || ActivationTime.NOW);
    // Номер заявки для нашего аккаунта
    const orderNum = OrderNum.nextOrderNum();
    const NumEDocumentBase = numEDocument;
    const orderFullNum = `${orderPrefix}-${orderNum}`;

    // Формируем текст поручения
    const orderTextDoc = this.getTextOrder({
      limitPrice,
      stopPrice,
      limitLevelAlternative,
      //CommandTypeBase.NEW - новая заявка
      //CommandTypeBase.KILL - удаление заявки
      //CommandTypeBase.EDIT - редактирование заявка
      command: CommandTypeBase.NEW,
      login,
      fullName,
      idAccount,
      signTime,
      orderNum: orderFullNum,
      razdel,
      orderParams,
      buy,
      fullFI,
      idLifeTime,
      quantity,
      idPriceControlType,
      activationTime: actTime,
      withDrawTime,
      openQuantity,
      linkedOrderId,
      activationPriceDirection,
      idFIActivate,
      fullFIActivate,
    });

    log.debug(orderTextDoc);

    if (!orderTextDoc) {
      return;
    }

    const orderTextDocWin1251 = encode('windows-1251', orderTextDoc);
    const signedOrderText = await createCMS(
      orderTextDocWin1251,
      workingCertificate.certificate,
      rsaKeys.keys.privateKey,
      'SHA-512'
    );

    return {
      orderRequest: createClientEnterOrderReq({
        signedOrderText,
        buy,
        idAccount,
        idSubAccount,
        idRazdel: razdel.idRazdel,
        idAllowedOrderParams: orderParams.idAllowedOrderParams,
        idCertificate: BigInt(workingCertificate.idCertificate),
        idObject: fullFI.idObject,
        signTime,
        orderNum,
        limitPrice,
        quantity,
        stopPrice,
        withDrawTime,
        activationTime: actTime,
        idPriceControlType,
        NumEDocumentBase,
        openQuantity,
        activationPriceDirection,
        idFIActivate,
        limitLevelAlternative,
        orderChannel: this.orderChannel,
      }),
      orderFullNum: orderFullNum,
    };
  }

  unsubscribe = (
    timeoutId: NodeJS.Timeout,
    clientOrderNum: number,
    onSuccess: (orderResult: ClientEnterOrderRsp) => void,
    onError: (
      m: ClientEnterOrderRsp | ClientCancelOrderRsp,
      order: PendingOrder
    ) => void
  ) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    OrdersWatcherService.unsubscribe(clientOrderNum, onSuccess, onError);
  };

  defaultSubscribeError(
    m: ClientEnterOrderRsp | ClientCancelOrderRsp,
    order: PendingOrder
  ) {
    const store = OrdersWatcherService.store.getState();

    if (TestRequiredCodes.includes(m.ErrorCode)) {
      let notificationObject: Notification;

      if (OrdersWatcherService.client.settings.isAI) {
        notificationObject = createNotification({
          title: 'Необходимо пройти тест',
          link: TestLinksMap.get(m.ErrorCode),
          type: NotificationType.TRADE,
          text: `${getNotificationText(
            order,
            OrdersWatcherService.store.getState()
          )} ${m.ErrorText}`,
          badge: 'negative' as NotificationBadge,
          orderNotification: true,
          order: order,
        });
      } else {
        // у гоинвеста нет тестов пока
        notificationObject = createNotification({
          title: 'Ошибка',
          type: NotificationType.TRADE,
          text: `${getNotificationText(
            order,
            OrdersWatcherService.store.getState()
          )} ${m.ErrorText}`,
          badge: 'negative' as NotificationBadge,
          orderNotification: true,
          order: order,
        });
      }

      //Обрабатываем сообщения о необходимости прохождения тестирования по коду ошибки
      if (!OrdersWatcherService.suspended) {
        store.addNotification(notificationObject);
      }
    } else {
      if (!OrdersWatcherService.suspended) {
        store.addNotification(
          createNotification({
            title: 'Ошибка',
            type: NotificationType.TRADE,
            text: `${getNotificationText(
              order,
              OrdersWatcherService.store.getState()
            )} ${m.ErrorText}`,
            badge: 'negative' as NotificationBadge,
            orderNotification: true,
            order: order,
          })
        );
      }
    }
  }

  async cancelOrder(
    order: OrderItem
  ): Promise<ClientCancelOrderRsp[] | undefined> {
    const store = useStore.getState();

    //Проверяем сертификаты
    const workingCertificate = store.workingCertificate;

    if (!workingCertificate) {
      this.emit('error', 'Не найден валидный сертификат');

      return;
    }

    //Проверяем ключи сертификата
    const rsaKeys = await getRSAKey(workingCertificate?.idCertificate);

    if (!rsaKeys) {
      this.emit('error', 'Не найден приватный ключ для выбранного сертификата');

      return;
    }

    if (!store.user) {
      this.emit('error', 'Не найдены данные о пользователе');

      return;
    }

    const razdel = store.subAccountRazdel.find(
      (razdel) => razdel.idRazdel === order.idRazdel
    );

    if (!razdel) {
      this.emit('error', 'Не найден раздел');
    }

    const deletedNowNumEDocument = order.numEDocument;
    const idFI = getInstrumentId(
      order.idObject,
      order.idMarketBoard,
      store.finInstrumentsTable
    );
    const activeAndWaitingOrders = selectActiveAndWaitingOrders(
      idFI,
      order.idSubAccount
    )(store);
    const dependentOrders = activeAndWaitingOrders.filter(
      (order) => order.numEDocumentBase === deletedNowNumEDocument
    );
    const ordersToDelete = [order, ...dependentOrders];

    return Promise.all(
      ordersToDelete.map((order) => {
        return new Promise<ClientCancelOrderRsp>(async (resolve, reject) => {
          // Игорь: я решил не реюзать методы типа createOrderRequestParams, потому что при отмене заявок меньше логики
          // и не надо вычислять все цепочки параметров и всякие idAllowedParams, поэтому тут все проще и прямолинейнее
          const signTime = getServerSignTime();
          const orderNum = OrderNum.nextOrderNum();

          const cancelOrderRequest = new ClientCancelOrderReq();

          cancelOrderRequest.IdAccount = order.idAccount;
          cancelOrderRequest.IdCertificate = BigInt(
            workingCertificate.idCertificate
          );
          cancelOrderRequest.ClientOrderNum = orderNum;
          cancelOrderRequest.IdSubAccount = order.idSubAccount;
          cancelOrderRequest.IdRazdel = order.idRazdel;
          cancelOrderRequest.OrderChannel = this.orderChannel;
          cancelOrderRequest.SignTime = BigInt(jsDateToTicks(signTime));
          cancelOrderRequest.SignType = SignType.UEP;
          cancelOrderRequest.IdFIActivate = 0;
          cancelOrderRequest.NumEDocumentBase = BigInt(order.numEDocument);
          cancelOrderRequest.ClientNumStrategy = 0;
          cancelOrderRequest.Flags = CancelOrderFlags.Unknown;

          const orderPrefix = useStore.getState().orderPrefix;
          // это типа исходящий номер текущего поручения
          const outOrderNum = `${orderPrefix}-${cancelOrderRequest.ClientOrderNum}`;

          const orderText = this.textOrderService.generateTextDocument({
            command: CommandTypeBase.KILL,
            login: store.user?.login!,
            fullName: store.user?.fullName!,
            idAccount: order.idAccount,
            clientNumEDocument: order.clientNumEDocument,
            signTime,
            orderNum: outOrderNum,
            razdel: razdel!,
            idOrderType: order.idOrderType,
          });

          log.debug('ТЕКСТ ЗАЯВКИ');
          log.debug(orderText);

          if (!orderText) {
            return reject();
          }

          try {
            const orderTextDocWin1251 = encode('windows-1251', orderText);

            cancelOrderRequest.BinaryEDocument = await createCMS(
              orderTextDocWin1251,
              workingCertificate.certificate,
              rsaKeys.keys.privateKey,
              'SHA-512'
            );
          } catch (err) {
            this.emit(
              'error',
              'Произошла ошибка при выполнении подписи отмены поручения'
            );
            log.error(err);

            return;
          }

          OrdersWatcherService.addPendingOrder({
            ...order,
            clientOrderNum: cancelOrderRequest.ClientOrderNum,
          });

          const subscriber = (orderResult: ClientCancelOrderRsp) => {
            OrdersWatcherService.unsubscribe(
              cancelOrderRequest.ClientOrderNum,
              subscriber,
              this.defaultSubscribeError
            );

            resolve(orderResult);
          };

          OrdersWatcherService.subscribe(
            cancelOrderRequest.ClientOrderNum,
            subscriber,
            this.defaultSubscribeError
          );

          alfaDirectClient.send({
            frontend: FrontEndType.OperServer,
            isArray: false,
            payload: {
              type: EntityType.ClientCancelOrderReq,
              data: cancelOrderRequest,
            },
          });
        });
      })
    );
  }

  async replaceOrder(
    idFi: number,
    prevOrder: OrderItem,
    newOrder: NewOrderType
  ) {
    const store = useStore.getState();
    const finInfoExt = store.finInfoExt[idFi];
    const activeAndWaitingOrders = selectActiveAndWaitingOrders(
      idFi,
      newOrder.idSubAccount
    )(store);

    const sentOrders = await this.cancelOrder(prevOrder);

    if (!sentOrders) {
      throw new Error();
    }

    return this.sendOrder(
      getReplaceOrderParams(
        idFi,
        newOrder,
        prevOrder.price,
        store.orders,
        activeAndWaitingOrders,
        finInfoExt
      )
    );
  }

  // Отправляет заявку на новый ордер
  async sendOrder({
    idFi,
    limitPrice,
    stopPrice,
    idSubaccount,
    idOrderType,
    buy,
    quantity,
    idLifeTime,
    idExecutionType,
    quantityType,
    idActivationTime,
    activationTime,
    withDrawTime,
    openQuantity,
    linkedOrderId,
    numEDocument,
    priceControlType,
    activationPriceDirection,
    idFIActivate,
    bracket,
    limitLevelAlternative,
    last,
  }: SendOrderParams): Promise<ClientEnterOrderRsp> {
    let idPriceControlType = priceControlType ?? PriceControlType.None;

    switch (idOrderType) {
      case OrderType.LMT:
      case OrderType.MKT:
        break;
      case OrderType.STP:
      case OrderType.TRS:
      case OrderType.TRL:
      case OrderType.BRS:
      case OrderType.BSL:
      case OrderType.TBRS:
      case OrderType.STL:
        idPriceControlType = PriceControlType.LAST;
        break;

      default:
        break;
    }

    let idQuantityType = quantityType ?? QuantityType.QTY;

    if (linkedOrderId) {
      idQuantityType = QuantityType.OTO;
    }

    return new Promise(async (resolve, reject) => {
      try {
        const requestParams = await this.prepareRequestParams(
          idFi,
          idSubaccount,
          idExecutionType,
          idLifeTime,
          idOrderType,
          idPriceControlType,
          idQuantityType,
          idFIActivate
        );

        if (requestParams) {
          try {
            const result = await this.createOrderRequest({
              limitPrice,
              stopPrice,
              buy,
              quantity,
              idSubAccount: idSubaccount,
              params: requestParams,
              idActivationTime,
              activationTime,
              withDrawTime,
              openQuantity,
              linkedOrderId,
              numEDocument,
              activationPriceDirection,
              limitLevelAlternative,
            });

            const orderRequest = result?.orderRequest;
            const orderFullNum = result?.orderFullNum;

            if (orderRequest) {
              OrdersWatcherService.addPendingOrder({
                ...orderRequest,
                ...requestParams,
              });

              let timeoutId: NodeJS.Timeout;

              const onSuccess = (orderResult: ClientEnterOrderRsp) => {
                this.unsubscribe(
                  timeoutId,
                  orderResult.ClientOrderNum,
                  onSuccess,
                  onError
                );
                resolve(orderResult);
              };

              const onError = (
                orderResult: ClientEnterOrderRsp | ClientCancelOrderRsp,
                order: PendingOrder
              ) => {
                this.unsubscribe(
                  timeoutId,
                  orderResult.ClientOrderNum,
                  onSuccess,
                  onError
                );

                if (
                  orderResult.ErrorCode ===
                  Messages.OrderRejected_UnapprovedQual
                ) {
                  reject({ message: orderResult.ErrorCode });
                } else {
                  this.defaultSubscribeError(orderResult, order);
                }
              };

              OrdersWatcherService.subscribe(
                orderRequest.ClientOrderNum,
                onSuccess,
                onError
              );

              // Oбработчик таймаута отправки ордера
              timeoutId = setTimeout(() => {
                reject('TIMEOUT');
                OrdersWatcherService.unsubscribe(
                  orderRequest.ClientOrderNum,
                  onSuccess,
                  onError
                );
              }, 10000);

              // Отправка оредера
              alfaDirectClient.send({
                frontend: FrontEndType.OperServer,
                isArray: false,
                payload: {
                  type: EntityType.ClientEnterOrderReq,
                  data: orderRequest,
                },
              });
              trackTickerTypeOrder(
                requestParams.orderParams.idObjectGroup,
                requestParams.fullFI.symbolObject,
                buy,
                requestParams.fullFI.rCode
              );
              trackLinkedOrder(linkedOrderId);
            }

            const andSendLinkedSLTP = bracket && !linkedOrderId;

            if (andSendLinkedSLTP) {
              const ordersUnsubscribe = useStore.subscribe(
                (state) => state.orders,
                (orders) => {
                  const baseOrder = orders.find(
                    (order) => order.clientNumEDocument === orderFullNum
                  );

                  if (!baseOrder) {
                    return;
                  }

                  ordersUnsubscribe();

                  this.sendOrder({
                    idFi,
                    idSubaccount,
                    buy: !buy,
                    quantity,
                    idLifeTime,
                    idExecutionType,
                    idActivationTime,
                    activationTime,
                    withDrawTime,
                    openQuantity,
                    linkedOrderId: orderFullNum,
                    numEDocument: baseOrder?.numEDocument,
                    ...bracket,
                    limitLevelAlternative: getLinkedLimitLevelAlternative(
                      idFi,
                      bracket.idOrderType,
                      baseOrder,
                      bracket.limitLevelAlternative,
                      last
                    ),
                  });
                }
              );
            }
          } catch (err) {
            reject(err);
            this.emit('error', err);

            return;
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          reject(err);
          this.emit('error', err.message);
        }

        return;
      }
    });
  }

  sendMultipleOrders(orders: SendOrderParams[]) {
    const orderRequests = orders.map((order) => this.sendOrder(order));

    return Promise.allSettled(orderRequests).then((results) =>
      results.reduce(
        (acc, res) => {
          if (res.status === 'fulfilled') {
            acc.success.push(res.value);
          } else {
            acc.fail.push(res.reason);
          }

          return acc;
        },
        { success: [] as ClientEnterOrderRsp[], fail: [] as Error[] }
      )
    );
  }

  getNewOrderText({
    idFi,
    idSubaccount,
    idOrderType,
    buy,
    quantity,
    idLifeTime,
    idActivationTime,
    withDrawTime,
    openQuantity,
    linkedOrderId,
    priceControlType,
    activationPriceDirection,
    idFIActivate,
    inputPrice,
    secondPrice,
    last,
    stopPrice,
    limitPrice,
    quantityType,
    limitLevelAlternative,
  }: SendOrderParams & InputSendOrderParams) {
    let idExecutionType = ExecutionType.ASIS;

    switch (idOrderType) {
      case OrderType.STP:
      case OrderType.LMT:
      case OrderType.TRS:
      case OrderType.TRL:
      case OrderType.BRS:
      case OrderType.TBRS:
      case OrderType.LIT:
      case OrderType.MIT:
        idExecutionType = ExecutionType.ASL;
        break;

      default:
        break;
    }

    if (openQuantity) {
      idExecutionType = ExecutionType.ASIS;
    }

    const { stopPrice: sendStopPrice, limitPrice: sendLimitPrice } =
      getOrderSendPrice(idOrderType, inputPrice, last, secondPrice);

    let idPriceControlType = priceControlType ?? PriceControlType.None;

    switch (idOrderType) {
      case OrderType.LMT:
      case OrderType.MKT:
        break;
      case OrderType.STP:
      case OrderType.TRS:
      case OrderType.TRL:
      case OrderType.BRS:
      case OrderType.BSL:
      case OrderType.TBRS:
      case OrderType.STL:
        idPriceControlType = PriceControlType.LAST;
        break;

      default:
        break;
    }

    let idQuantityType = quantityType ?? QuantityType.QTY;

    if (linkedOrderId) {
      idQuantityType = QuantityType.OTO;
    }

    const store = useStore.getState();
    const user = store.user;

    //Проверяем данные о фин инструменте
    const fullFI = getFullFI(
      idFi,
      store.objectsTable,
      store.objectTypesTable,
      store.finInstrumentsTable,
      store.marketBoardsTable
    );

    if (!fullFI) {
      throw new Error(
        'Не найдена необходимая информация о финансовом инструменте'
      );
    }

    //Проверяем данные о фин инструменте
    const fullFIActivate = idFIActivate
      ? getFullFI(
          idFIActivate,
          store.objectsTable,
          store.objectTypesTable,
          store.finInstrumentsTable,
          store.marketBoardsTable
        )
      : undefined;

    if (idFIActivate && !fullFIActivate) {
      throw new Error(
        'Не найдена необходимая информация о финансовом инструменте Контролируемого Параметра'
      );
    }

    // rCode содержится у MarketBoard на которую ссылается Object
    // находим раздел, где живет финансовый инструмент
    const razdel = store.subAccountRazdel
      .filter((razdel) => razdel.idSubAccount === idSubaccount)
      .find((razdel) => razdel.rCode === fullFI.rCode);

    if (!razdel) {
      throw new Error('Не найден раздел для финансового инструмента');
    }

    // id нашего аккаунта ака "Номер генерального соглашения"
    const subAccount = store.subAccounts.find(
      (subAccount) => subAccount.idSubAccount === idSubaccount
    );

    if (!subAccount) {
      throw new Error('Не найдены данные о генеральном соглашении');
    }

    const idAccount = subAccount.idAccount;

    //Проверяем допустимость параметров для заявки
    const orderParams = getAllowedOrderParamsId(
      fullFI,
      store.orderParams,
      idOrderType,
      idExecutionType,
      idLifeTime,
      idQuantityType
    );

    if (!orderParams) {
      throw new Error('Не найдены необходимые параметры для создания заявки');
    }

    if (isNull(user)) {
      throw new Error('Не найдены данные пользователя');
    }

    if (isUndefined(idPriceControlType)) {
      throw new Error('Не задан тип Контролируемого Параметра');
    }

    const orderPrefix = useStore.getState().orderPrefix;
    //Время подписи заявки (серверное)
    const signTime = getServerSignTime();
    // Время активации
    const activationTime = getOrderActivationTime(signTime, idActivationTime);
    // Номер заявки для нашего аккаунта
    const orderFullNum = `${orderPrefix}-XXX`;

    // Формируем текст поручения
    try {
      return this.textOrderService.generateTextDocument({
        limitPrice: limitPrice ?? sendLimitPrice,
        stopPrice: stopPrice ?? sendStopPrice,
        limitLevelAlternative,
        //CommandTypeBase.NEW - новая заявка
        command: CommandTypeBase.NEW,
        login: user.login,
        fullName: user.fullName,
        idAccount,
        signTime,
        orderNum: orderFullNum,
        razdel,
        orderParams,
        buy,
        fullFI,
        idLifeTime,
        quantity,
        idPriceControlType,
        activationTime,
        withDrawTime,
        openQuantity,
        linkedOrderId,
        activationPriceDirection,
        idFIActivate,
        fullFIActivate,
      });
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(err.message);
      }
    }
  }

  getKillOrderText(order: OrderItem) {
    const store = useStore.getState();

    if (!store.user) {
      throw new Error('Не найдены данные о пользователе');
    }

    const razdel = store.subAccountRazdel.find(
      (razdel) => razdel.idRazdel === order.idRazdel
    );

    if (!razdel) {
      throw new Error('Не найден раздел');
    }

    const deletedNowNumEDocument = order.numEDocument;
    const idFI = getInstrumentId(
      order.idObject,
      order.idMarketBoard,
      store.finInstrumentsTable
    );
    const activeAndWaitingOrders = selectActiveAndWaitingOrders(
      idFI,
      order.idSubAccount
    )(store);
    const dependentOrders = activeAndWaitingOrders.filter(
      (order) => order.numEDocumentBase === deletedNowNumEDocument
    );
    const ordersToDelete = [order, ...dependentOrders];

    try {
      return ordersToDelete.map((orderToDelete) => {
        const signTime = getServerSignTime();
        // это типа исходящий номер текущего поручения
        const outOrderNum = `${store.orderPrefix}-XXX`;

        return this.textOrderService.generateTextDocument({
          command: CommandTypeBase.KILL,
          login: store.user?.login!,
          fullName: store.user?.fullName!,
          idAccount: orderToDelete.idAccount,
          clientNumEDocument: orderToDelete.clientNumEDocument,
          signTime,
          orderNum: outOrderNum,
          razdel: razdel!,
          idOrderType: orderToDelete.idOrderType,
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        throw new Error(err.message);
      }
    }
  }
}

export function getReplaceOrderParams(
  idFi: number,
  order: NewOrderType,
  prevPrice: number,
  orders: OrderItem[],
  activeAndWaitingOrders: OrderItem[],
  finInfoExt?: FinInfoExt
): SendOrderParams {
  // Основная заявка
  const linkedOrderId = order.numEDocumentBase
    ? orders.find((o) => o.numEDocument === order.numEDocumentBase)
        ?.clientNumEDocument
    : undefined;

  const dependentOrders = activeAndWaitingOrders.filter(
    (o) => o.numEDocumentBase === order.numEDocument
  );

  let newBracket: BracketOrderParams = {
    idOrderType: OrderType.None,
  };

  if (dependentOrders[0]) {
    const priceChange =
      (order.stopPrice || order.limitPrice || order.price) / prevPrice;

    const { idOrderType, stopPrice, limitPrice, limitLevelAlternative } =
      dependentOrders[0];

    newBracket = getBracketOrderParams({
      slPrice: roundPrice(stopPrice * priceChange, finInfoExt?.priceStep),
      tpPrice: roundPrice(limitPrice * priceChange, finInfoExt?.priceStep),
      idOrderType,
    });

    if (newBracket.stopPrice && limitLevelAlternative) {
      newBracket.limitLevelAlternative = roundPrice(
        newBracket.stopPrice + limitLevelAlternative - stopPrice,
        finInfoExt?.priceStep
      );
    }
  }

  return {
    idFi,
    idSubaccount: order.idSubAccount,
    idOrderType: order.idOrderType,
    limitPrice: order.limitPrice,
    stopPrice: order.stopPrice,
    buy: order.buySell === OrderDirection.Buy,
    quantity: order.quantity,
    quantityType: order.idQuantityType,
    openQuantity: order.openQuantity,
    idLifeTime: order.idLifeTime,
    idExecutionType: order.idExecutionType,
    activationTime: order.activationTime,
    idActivationTime: order.idActivationTime || ActivationTime.NOW,
    withDrawTime: order.withdrawTime,
    bracket: newBracket,
    ...(order.numEDocumentBase &&
    order.numEDocumentBase !== '0' &&
    linkedOrderId
      ? {
          numEDocument: order.numEDocumentBase,
          linkedOrderId,
        }
      : {}),
    limitLevelAlternative: order.limitLevelAlternative,
  };
}

const createClientEnterOrderReq = ({
  signedOrderText,
  buy,
  idAccount,
  idSubAccount,
  idRazdel,
  idAllowedOrderParams,
  idCertificate,
  idObject,
  signTime,
  orderNum,
  limitPrice,
  limitLevelAlternative,
  quantity,
  openQuantity,
  stopPrice,
  withDrawTime,
  activationTime,
  idPriceControlType,
  NumEDocumentBase,
  activationPriceDirection,
  idFIActivate,
  orderChannel,
}: InputClientEnterOrderReq) => {
  const orderRequest = new ClientEnterOrderReq();

  orderRequest.BinaryEDocument = signedOrderText;
  orderRequest.BuySell = buy ? OrderDirection.Buy : OrderDirection.Sell;
  orderRequest.IdAccount = idAccount;
  orderRequest.IdSubAccount = idSubAccount;
  orderRequest.IdRazdel = idRazdel;
  orderRequest.IdAllowedOrderParams = idAllowedOrderParams;
  orderRequest.IdCertificate = idCertificate;
  orderRequest.IdObject = idObject;
  orderRequest.OrderChannel = orderChannel;
  orderRequest.SignTime = BigInt(jsDateToTicks(signTime));
  orderRequest.SignType = SignType.UEP;
  orderRequest.ClientOrderNum = orderNum;
  //IdSettleCode Всегда 0
  orderRequest.IdSettleCode = 0;
  orderRequest.LimitLevelAlternative = limitLevelAlternative ?? 0;
  orderRequest.LimitPrice = limitPrice ? roundFloatNumber(limitPrice) : 0;
  orderRequest.StopPrice = stopPrice ? roundFloatNumber(stopPrice) : 0;
  orderRequest.Quantity = quantity;
  orderRequest.OpenQuantity = openQuantity ?? 0;
  orderRequest.ActivationTime = activationTime
    ? BigInt(jsDateToTicks(activationTime))
    : 0n;
  orderRequest.WithdrawTime = withDrawTime
    ? BigInt(jsDateToTicks(withDrawTime))
    : 0n;
  orderRequest.IdPriceControlType = idPriceControlType;
  orderRequest.NumEDocumentBase = NumEDocumentBase
    ? BigInt(NumEDocumentBase)
    : 0n; // это используется в отмене заявок и думаю в каких то связанных заявках
  orderRequest.ActivationPriceDirection =
    activationPriceDirection ?? OrderStopPriceDirection.Undefined;
  orderRequest.IdFIActivate = idFIActivate ?? 0;

  //TODO: Выяснить что это такое
  orderRequest.ClientNumStrategy = 0;
  orderRequest.Comment = '';
  orderRequest.Value = 0;
  orderRequest.Flags = EnterOrderFlags.Unknown;

  return orderRequest;
};

// https://jira.moscow.alfaintra.net/browse/ADIRWEB-1729
const getLinkedLimitLevelAlternative = (
  idFi: number,
  linkedOrderType: OrderType,
  baseOrder: OrderItem,
  limitLevelAlternative?: number,
  last?: number
) => {
  if (linkedOrderType === OrderType.STL || linkedOrderType === OrderType.BSL) {
    return limitLevelAlternative;
  }

  if (linkedOrderType === OrderType.TBRS) {
    const store = useStore.getState();
    const fullFI = getFullFI(
      idFi,
      store.objectsTable,
      store.objectTypesTable,
      store.finInstrumentsTable,
      store.marketBoardsTable
    );
    const finInfoExt = store.finInfoExt[idFi];

    if (fullFI && finInfoExt) {
      //Если основная заявка не маркетная
      if (baseOrder.idOrderType !== OrderType.MKT) {
        //То берем цену заявки
        return getOrderDisplayPrice(baseOrder);
      } else {
        //Иначе Last
        return (
          getPrice(
            finInfoExt.prevLastDate,
            finInfoExt.prevFairPriceDate,
            finInfoExt.fairPrice,
            last,
            finInfoExt?.prevLast,
            fullFI.idObject,
            fullFI.idObjectGroup
          ) ?? undefined
        );
      }
    }
  }
};
