import { useMemo } from 'react';

import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';

import {
  isAccountFitMarginal,
  isCurrencyFitMarginal,
  isForeignMarketTreaty,
  isRepoTreaty,
  MARGIN_TEST_ID,
} from '../lib';

import { useTestingList } from '../../../legacy/hooks/useTestingList';
import { useTreaties } from '../../../legacy/hooks/useTreaties';

interface UseMarginControlParams {
  treaty: number;
  account: TradeAccount | null;
  marginalOn: boolean;
  currency: string;
  isDisallow: boolean;
}

export const useMarginControl = ({
  treaty,
  account,
  marginalOn,
  currency,
  isDisallow,
}: UseMarginControlParams) => {
  const { data: treaties = [] } = useTreaties();
  const { data: testingList, refetch: refetchTestingList } = useTestingList(
    treaty,
    Boolean(treaty)
  );

  const isMarginalTreaty = useMemo(
    () =>
      treaties.length &&
      !isRepoTreaty(treaty, treaties) &&
      !isForeignMarketTreaty(treaty, treaties),
    [treaty, treaties]
  );

  const isTest2Passed = Boolean(
    testingList?.testStatusItems?.find((test) => test.testId === MARGIN_TEST_ID)
      ?.completed
  );
  const isFirm = Boolean(testingList?.isFirm);
  const isUserFitMargin = Boolean(
    testingList?.qualifiedInvestorDate || isTest2Passed || isFirm
  );

  const marginCurrency = isCurrencyFitMarginal(currency);

  const marginAccount = account && isAccountFitMarginal(account);

  const showMarginTooltip =
    isUserFitMargin && marginalOn && marginCurrency && marginAccount;

  // Маржинальный перевод доступен если:
  //   у пользователя пройден тест, или он квал И
  //   выбранный счёт подходит для маржинального вывода И
  //   валюта на выбранном счёте подходит И
  //   рынок на выбранном счёте подходит И
  //   у счёта не стоит запрет
  const marginAvailable = Boolean(
    isUserFitMargin &&
      isMarginalTreaty &&
      marginCurrency &&
      marginAccount &&
      !isDisallow
  );

  return {
    isTestPassed: isUserFitMargin,
    showTestBanner: !isUserFitMargin && !isDisallow,
    refetchTestingList,
    marginCurrency,
    showMarginTooltip,
    marginAvailable,
  };
};
