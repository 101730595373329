import { TabNode } from 'flexlayout-react';
import isNull from 'lodash/isNull';
import { memo, useRef, useState } from 'react';

import { LinkName, WidgetLinkProps } from '@terminal/core/types/layout';

import { getLinkIcon, LinkDropdown } from './LinkDropdown';

interface LinkProps {
  node: TabNode | null;
  linkToGroup: (
    nodeId: string,
    linkName: LinkName,
    params: WidgetLinkProps
  ) => void;
}

export const Link = memo(({ node, linkToGroup }: LinkProps) => {
  const [isOpen, setIsOpen] = useState(false);
  //Якорь для позиционирования дропдауна
  const anchorRef = useRef<HTMLDivElement>(null);
  //Получаем пропсы виджета
  const config: null | WidgetLinkProps = node?.getConfig();
  const currentLink = config
    ? config.link || LinkName.DEFAULT
    : LinkName.DEFAULT;

  return (
    //onMouseDown это обязательный хак для того, чтобы можно было хэндлить собития клика в кнокпе таба (узнал через issue)
    <div ref={anchorRef} onMouseDown={(e) => e.stopPropagation()}>
      {getLinkIcon({
        linkName: currentLink,
        onClick: () => setIsOpen(true),
        withTooltip: true,
      })}
      <LinkDropdown
        isOpen={isOpen}
        anchor={anchorRef}
        currentLink={currentLink}
        onChooseLink={(linkName) => {
          if (!isNull(node)) {
            const config = node.getConfig() as WidgetLinkProps;

            linkToGroup(node.getId(), linkName, config);
          }
        }}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
});
