import { useState } from 'react';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';

import styles from './AboutLimits.module.css';

interface AboutLimitsProps {
  backLabel?: string;
}

export const AboutLimits = ({ backLabel }: AboutLimitsProps) => {
  const [open, setOpen] = useState(false);

  const title = 'Почему доступно меньше, чем в портфеле?';
  const text = (
    <Typography.Text className={styles.text} tag="div" view="secondary-small">
      <ol className={styles.list}>
        <li>
          Возможно, вы недавно совершали сделки. Нужно 1-2 рабочих дня для
          зачисления денег на брокерский счёт. За это время биржа завершает
          расчёты по сделке.
        </li>
        <li>
          У вас есть неисполненные заявки на покупку — деньги уже
          зарезервированы, поэтому перевести их нельзя. Внутри брокерского счёта
          можно переводить только собственные деньги.
        </li>
      </ol>
    </Typography.Text>
  );

  return (
    <>
      <Link view="secondary" onClick={() => setOpen(true)}>
        {title}
      </Link>
      <Portal id="money-transfer-modals">
        <FunctionalModalSidebar
          open={open}
          backLabel={backLabel}
          onClose={() => setOpen(false)}
        >
          <Typography.Text className={styles.title} weight="bold" tag="p">
            {title}
          </Typography.Text>
          {text}
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
