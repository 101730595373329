import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { AccountInfoParams } from '../api/types/AccountInfoParams';

export const useAccountInfo = <R>(
  treaty: number | undefined,
  fetchAccountInfo: (params: AccountInfoParams) => Promise<R>
) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'account/info', { treaty }],
    async () => fetchAccountInfo({ treaty: treaty || 0 }),
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message || 'При запросе информации о счете произошла ошибка',
        });
      },
    }
  );
};
