import log from 'loglevel';
import { v4 } from 'uuid';

import { defaultOrderStatusFilters } from '../../constants/orders';
import {
  ClientCancelOrderRsp,
  ClientEnterOrderReq,
  ClientEnterOrderRsp,
  ClientMessageEntity,
  MessageUnitedType,
  OrderType,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import AdirClientService from '../client/service';
import { getOrderDisplayPrice } from '../formulas';

import { ObjectItem } from '../../types/core';
import { OrderFullStatusMap, OrderItem } from '../../types/order';
import {
  Notification,
  NotificationType,
  OrderDimensionEnum,
  PendingOrder,
} from '../../types/ui';

type SubscriberFunc = (
  response: ClientCancelOrderRsp | ClientEnterOrderRsp
) => void;

type SubscribeErrorFunc = (
  response: ClientCancelOrderRsp | ClientEnterOrderRsp,
  order: PendingOrder
) => void;

type OrdersMap = Map<
  number,
  { onSuccess: SubscriberFunc; onError: SubscribeErrorFunc }[]
>;

/**
 * Сервис слушает ответы по подаче заявок и формирует тексты сообщений для нотификаций
 * Также сохраняет информацию об отправленных заявках для корректного отображения в плашках нотификаций
 * По сути частично копирует функционал TradingService, но так как этот сервис живет в рутовом компоненте, то оповещения будут видны всегда, даже с закрытыми виджетами торговли
 */
class ClientOrdersService extends AdirClientService {
  private ordersSubscribes: OrdersMap = new Map([]);

  // исходящие номера заявок, результаты которых мы ожидаем
  pendingOrders: PendingOrder[] = [];
  // Приостановка нотификаций. Нужна при массовой оптравке поручений
  // например, при ребалансировке портфеля
  suspended = false;

  override init() {
    this.addClientListener(EntityType.ClientEnterOrderRsp, this.onResponse);
    this.addClientListener(
      EntityType.ClientMessageEntity,
      this.onClientMessage
    );
    this.addClientListener(EntityType.ClientCancelOrderRsp, this.onResponse);
  }

  suspendNotifications() {
    this.suspended = true;
  }

  resumeNotifications() {
    this.suspended = false;
  }

  onClientMessage = (message: MessageUnitedType) => {
    const store = this.store.getState();

    const messages = message.data as ClientMessageEntity[];

    messages.forEach((m) => {
      // По словам Белова это устаревшая штука и в реальности больше не используется
      if (!this.suspended && m.MessageId >= 40000 && m.MessageId < 49999) {
        store.addNotification({
          id: v4(),
          type: NotificationType.TRADE,
          badge: 'negative',
          title: 'Ошибка',
          text: `Произошла ошибка. Код ошибки ${m.MessageId}`,
        });
        log.error(`Произошла ошибка. Код ошибки ${m.MessageId}`);
      }
    });
  };

  onResponse = <T extends ClientCancelOrderRsp | ClientEnterOrderRsp>(
    message: MessageUnitedType
  ) => {
    const messages = message.data as T[];

    messages.forEach((m) => {
      const order = this.pendingOrders.find(
        (order) =>
          ('ClientOrderNum' in order
            ? order.ClientOrderNum
            : order.clientOrderNum) === m.ClientOrderNum
      );

      if (order) {
        if (m.ErrorCode > 0) {
          const subscribers = this.ordersSubscribes.get(m.ClientOrderNum);

          if (subscribers) {
            subscribers.forEach((s) => s.onError(m, order));
            this.ordersSubscribes.delete(m.ClientOrderNum);
          }
        } else if (m.ErrorCode === 0) {
          const subscribers = this.ordersSubscribes.get(m.ClientOrderNum);

          if (subscribers) {
            subscribers.forEach((s) => s.onSuccess(m));
            this.ordersSubscribes.delete(m.ClientOrderNum);
          }
        }

        this.pendingOrders = this.pendingOrders.filter(
          (order) =>
            ('ClientOrderNum' in order
              ? order.ClientOrderNum
              : order.clientOrderNum) !== m.ClientOrderNum
        );
      }
    });
  };

  addPendingOrder = (newOrder: PendingOrder) => {
    this.pendingOrders = [...this.pendingOrders, newOrder];
  };

  subscribe(
    clientOrderNum: number,
    onSuccess: SubscriberFunc,
    onError: SubscribeErrorFunc
  ) {
    const prevSubs = this.ordersSubscribes.get(clientOrderNum);

    if (prevSubs) {
      prevSubs.push({ onSuccess, onError });
    } else {
      this.ordersSubscribes.set(clientOrderNum, [{ onSuccess, onError }]);
    }
  }

  unsubscribe(
    clientOrderNum: number,
    onSuccess: SubscriberFunc,
    onError: SubscribeErrorFunc
  ) {
    const prevSubs = this.ordersSubscribes.get(clientOrderNum);

    if (!prevSubs) {
      return;
    }

    const newSubs = prevSubs.filter(
      (cb) => cb.onSuccess !== onSuccess && cb.onError !== onError
    );

    if (newSubs.length > 0) {
      this.ordersSubscribes.set(clientOrderNum, newSubs);
    } else {
      this.ordersSubscribes.delete(clientOrderNum);
    }
  }

  clear = () => {
    this.removeClientListener(EntityType.ClientEnterOrderRsp, this.onResponse);
    this.removeClientListener(EntityType.ClientCancelOrderRsp, this.onResponse);
    this.removeClientListener(
      EntityType.ClientMessageEntity,
      this.onClientMessage
    );
  };
}

/**
 * Возвращает Notification по OrderItem
 */
export const getNotificationByOrderItem = (
  order: OrderItem,
  state: unknown
): Notification => {
  const isError = order.brokerErrorCode > 0;

  let text;
  let title;

  if (isError) {
    text = order.brokerComment;
    title = 'Заявка отменена';
  } else {
    title = getNotificationText(order, state);

    if (!order.operation) {
      text = order.brokerComment;
    } else if (
      defaultOrderStatusFilters['executed'].includes(order.idOrderStatus)
    ) {
      text = 'Исполнена полностью';
    } else {
      text = OrderFullStatusMap.get(order.idOrderStatus);
    }
  }

  const badge = isError ? 'negative' : 'positive';

  return {
    id: v4(),
    type: NotificationType.TRADE,
    badge,
    title,
    text,
    orderNotification: true,
    order,
  };
};

/**
 * Формируем текст который будет показан в плашке (в зависимости от типа сходной энтити)
 */
export const getNotificationText = (order: PendingOrder, state: any) => {
  const objects = state.objects as ObjectItem[];
  const orderDimension = state.settings.defaultValues
    .orderDimension as OrderDimensionEnum;
  const isLotCount = orderDimension === OrderDimensionEnum.LOT;

  //Если заявка была по типу OrderItem
  if ((order as OrderItem).clientOrderNum) {
    const orderType = OrderType[order.idOrderType];
    const displayPrice = getOrderDisplayPrice(order as OrderItem);
    const quantity = order.quantity
      ? `${order.quantity} ${isLotCount ? 'лот' : 'шт'}`
      : '';
    const buySell = order.buySell === -1 ? 'Продажа' : 'Покупка';
    const market = (order as any).fullFI?.nameMarketBoard || ' ';

    let ticker = '';
    const object = objects.find((object) => object.idObject === order.idObject);

    if (object) {
      ticker = object.symbolObject;
    }

    return `${buySell} ${ticker}${market}${quantity} ${
      displayPrice && orderType !== OrderType[OrderType.MKT]
        ? `@ ${displayPrice}`
        : 'по рынку'
    } ${orderType}`;
  }

  //Если заявка была по типу ClientEnterOrderReq
  if ((order as ClientEnterOrderReq).ClientOrderNum) {
    const orderType = OrderType[order.idOrderType];
    //Формируем из ClientEnterOrderReq в OrderItem
    const displayPrice = getOrderDisplayPrice({
      idOrderType: order.idOrderType,
      limitPrice: (order as ClientEnterOrderReq).LimitPrice,
      stopPrice: (order as ClientEnterOrderReq).StopPrice,
    } as OrderItem);

    const quantity = (order as ClientEnterOrderReq).Quantity
      ? `${(order as ClientEnterOrderReq).Quantity} ${
          isLotCount ? 'лот' : 'шт'
        }`
      : '';
    const buySell =
      (order as ClientEnterOrderReq).BuySell === -1 ? 'Продажа' : 'Покупка';

    let ticker = '';
    const market = (order as any).fullFI?.nameMarketBoard || '';
    const object = objects.find(
      (object) => object.idObject === (order as ClientEnterOrderReq).IdObject
    );

    if (object) {
      ticker = object.symbolObject;
    }

    return `${buySell} ${ticker} ${market} ${quantity} ${
      displayPrice ? `@ ${displayPrice}` : 'по рынку'
    } ${orderType}`;
  }
};

const OrdersWatcherService = new ClientOrdersService();

export { OrdersWatcherService };
export default ClientOrdersService;
