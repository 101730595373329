import { useCallback, useEffect, useMemo, useState } from 'react';

import { OrderType } from '@terminal/core/lib/client/entities';
import { roundToStep } from '@terminal/core/lib/format';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';

import {
  LimitPriceAdditionalType,
  PriceQuickControls,
} from '../../../features/PriceInput';

interface Props {
  side?: 'buy' | 'sell';
  orderType: OrderType;
  priceStep?: number;
  setPrice: (value: number) => void;
  isPriceStreamed: boolean;
  setSecondPrice: (value: number) => void;
  last?: number;
  bestAsk?: number;
  bestBid?: number;
  tradeLimits: TradeLimitsResult | null;
  setPriceStreamed: (stream: boolean) => void;
}

export const usePriceQuickControls = ({
  side,
  orderType,
  priceStep,
  setPrice,
  isPriceStreamed,
  setSecondPrice,
  last,
  bestAsk,
  bestBid,
  tradeLimits,
  setPriceStreamed,
}: Props) => {
  const [limitPriceAdditionalType, setLimitPriceAdditionalType] =
    useState<LimitPriceAdditionalType>(null);
  const [limitPriceAdditionalAsk, setLimitPriceAdditionalAsk] = useState<
    number | null
  >(null);
  const [limitPriceAdditionalBid, setLimitPriceAdditionalBid] = useState<
    number | null
  >(null);
  const [limitPriceAdditionalMkt, setLimitPriceAdditionalMkt] = useState<
    number | null
  >(null);

  const limitPriceAdditional: PriceQuickControls = useMemo(
    () => ({
      priceAdditionalType: limitPriceAdditionalType,
      setPriceAdditionalType: setLimitPriceAdditionalType,
      priceAdditionalAsk: limitPriceAdditionalAsk,
      setPriceAdditionalAsk: setLimitPriceAdditionalAsk,
      priceAdditionalBid: limitPriceAdditionalBid,
      setPriceAdditionalBid: setLimitPriceAdditionalBid,
      priceAdditionalMkt: limitPriceAdditionalMkt,
      setPriceAdditionalMkt: setLimitPriceAdditionalMkt,
    }),
    [
      limitPriceAdditionalType,
      limitPriceAdditionalAsk,
      limitPriceAdditionalBid,
      limitPriceAdditionalMkt,
    ]
  );

  useEffect(() => {
    if (limitPriceAdditionalType) {
      setPriceStreamed(false);
    }
  }, [limitPriceAdditionalType, setPriceStreamed]);

  const handleClearPricesAdditional = useCallback(() => {
    setLimitPriceAdditionalType(null);
    setLimitPriceAdditionalAsk(null);
    setLimitPriceAdditionalBid(null);
    setLimitPriceAdditionalMkt(null);
    setSecondPriceAdditionalType(null);
    setSecondPriceAdditionalMkt(null);
  }, []);

  const [secondPriceAdditionalType, setSecondPriceAdditionalType] =
    useState<LimitPriceAdditionalType>(null);
  const [secondPriceAdditionalMkt, setSecondPriceAdditionalMkt] = useState<
    number | null
  >(null);

  const secondPriceAdditional: PriceQuickControls = useMemo(
    () => ({
      priceAdditionalType: secondPriceAdditionalType,
      setPriceAdditionalType: setSecondPriceAdditionalType,
      priceAdditionalAsk: null,
      setPriceAdditionalAsk: () => {},
      priceAdditionalBid: null,
      setPriceAdditionalBid: () => {},
      priceAdditionalMkt: secondPriceAdditionalMkt,
      setPriceAdditionalMkt: setSecondPriceAdditionalMkt,
    }),
    [secondPriceAdditionalType, secondPriceAdditionalMkt]
  );

  useEffect(() => {
    if (isPriceStreamed) {
      handleClearPricesAdditional();
    }
  }, [isPriceStreamed, handleClearPricesAdditional]);

  useEffect(() => {
    handleClearPricesAdditional();
  }, [side, orderType, handleClearPricesAdditional]);

  useEffect(() => {
    if (!limitPriceAdditionalType) {
      return;
    }

    let nextPrice: number = 0;

    switch (limitPriceAdditionalType) {
      case 'last': {
        if (last) {
          nextPrice = last;
        }

        break;
      }
      case 'mid': {
        if (bestAsk && bestBid) {
          nextPrice = (bestAsk + bestBid) / 2;
        }

        break;
      }
      case 'ask': {
        if (bestAsk) {
          nextPrice = bestAsk;
        }

        break;
      }
      case 'ask+': {
        if (bestAsk) {
          if (limitPriceAdditionalAsk) {
            nextPrice = bestAsk * (1 + limitPriceAdditionalAsk / 100);
          } else {
            nextPrice = bestAsk;
          }
        }

        break;
      }
      case 'bid': {
        if (bestBid) {
          nextPrice = bestBid;
        }

        break;
      }
      case 'bid-': {
        if (bestBid) {
          if (limitPriceAdditionalBid) {
            nextPrice = bestBid * (1 - limitPriceAdditionalBid / 100);
          } else {
            nextPrice = bestBid;
          }
        }

        break;
      }
      case 'mkt': {
        // @ts-expect-error
        if ((side === 'buy') ^ (orderType === OrderType.BRS)) {
          if (bestAsk) {
            if (limitPriceAdditionalMkt) {
              nextPrice = bestAsk * (1 + limitPriceAdditionalMkt / 100);
            } else {
              nextPrice = bestAsk;
            }
          }
        } else {
          if (bestBid) {
            if (limitPriceAdditionalMkt) {
              nextPrice = bestBid * (1 + limitPriceAdditionalMkt / 100);
            } else {
              nextPrice = bestBid;
            }
          }
        }

        break;
      }
    }

    if (priceStep) {
      nextPrice = roundToStep(nextPrice, priceStep);
    }

    if (nextPrice) {
      setPrice(nextPrice);
    }
  }, [
    priceStep,
    orderType,
    setPrice,
    side,
    last,
    bestBid,
    bestAsk,
    limitPriceAdditionalType,
    limitPriceAdditionalAsk,
    limitPriceAdditionalBid,
    limitPriceAdditionalMkt,
  ]);

  useEffect(() => {
    if (
      (orderType !== OrderType.BRS && orderType !== OrderType.TBRS) ||
      !secondPriceAdditionalType
    ) {
      return;
    }

    let nextPrice: number = 0;

    switch (secondPriceAdditionalType) {
      case 'mkt': {
        if (
          // @ts-expect-error
          (side === 'buy') ^
          (orderType === OrderType.BRS || orderType === OrderType.TBRS)
        ) {
          if (bestAsk) {
            if (secondPriceAdditionalMkt) {
              nextPrice = bestAsk * (1 + secondPriceAdditionalMkt / 100);
            } else {
              nextPrice = bestAsk;
            }
          }
        } else {
          if (bestBid) {
            if (secondPriceAdditionalMkt) {
              nextPrice = bestBid * (1 + secondPriceAdditionalMkt / 100);
            } else {
              nextPrice = bestBid;
            }
          }
        }

        break;
      }
    }

    if (priceStep) {
      nextPrice = roundToStep(nextPrice, priceStep);
    }

    if (nextPrice) {
      setSecondPrice(nextPrice);
    }
  }, [
    priceStep,
    orderType,
    setSecondPrice,
    side,
    bestBid,
    bestAsk,
    secondPriceAdditionalType,
    secondPriceAdditionalMkt,
  ]);

  const getLimitPriceAdditionalSuffix = () => {
    switch (limitPriceAdditionalType) {
      case 'last': {
        return ' [Last]';
      }
      case 'mid': {
        return ' [Mid]';
      }
      case 'ask': {
        return ' [Ask]';
      }
      case 'ask+': {
        if (!limitPriceAdditionalAsk) {
          return '';
        }

        return ` [Ask + ${limitPriceAdditionalAsk.toFixed(1)}%]`;
      }
      case 'bid': {
        return ' [Bid]';
      }
      case 'bid-': {
        if (!limitPriceAdditionalBid) {
          return '';
        }

        return ` [Bid - ${limitPriceAdditionalBid.toFixed(1)}%]`;
      }
      case 'mkt': {
        if (!limitPriceAdditionalMkt) {
          return '';
        }

        return ` [${limitPriceAdditionalMkt > 0 ? '+' : '-'} ${Math.abs(
          limitPriceAdditionalMkt
        ).toFixed(1)}%]`;
      }

      default:
        return '';
    }
  };

  const getSecondPriceAdditionalSuffix = () => {
    switch (secondPriceAdditionalType) {
      case 'mkt': {
        if (!secondPriceAdditionalMkt) {
          return '';
        }

        return ` [${secondPriceAdditionalMkt > 0 ? '+' : '-'} ${Math.abs(
          secondPriceAdditionalMkt
        ).toFixed(1)}%]`;
      }

      default:
        return '';
    }
  };

  return {
    limitPriceAdditional,
    secondPriceAdditional,
    clearPricesAdditional: handleClearPricesAdditional,
    limPAdSuffix: getLimitPriceAdditionalSuffix(),
    secPAdSuffix: getSecondPriceAdditionalSuffix(),
    limitsDisabled: !((tradeLimits?.quantity || 0) > 0),
  };
};
