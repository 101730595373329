export interface PricesRangeProps {
  open: number;
  last: number;
  min: number;
  max: number;
}

export const getPricesRange = ({ open, last, min, max }: PricesRangeProps) => {
  const range = max - min;
  const isGreen = last > open;
  const rowStart = isGreen
    ? ((open - min) / range) * 100
    : ((last - min) / range) * 100;

  const rowWidth = isGreen
    ? ((last - open) / range) * 100
    : ((open - last) / range) * 100;

  return {
    isGreen,
    rowStart,
    rowWidth,
  };
};
