import { ObjectGroup } from '../../lib/client/entities';

/**
 * @returns стоимость входящей позиции в рублевом эквиваленте
 * */
export function getBackPosCostRur(
  BackPosCost = 0,
  NominalCurPrice = 0,
  MarketCurPrice = 0,
  idObjectGroup?: number
): number {
  if (idObjectGroup === ObjectGroup.Bonds) {
    return BackPosCost * NominalCurPrice;
  }

  return BackPosCost * MarketCurPrice;
}
