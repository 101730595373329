import { TabNode } from 'flexlayout-react';
import { useMemo } from 'react';

import { Widget, WidgetLinkProps } from '@terminal/core/types/layout';

import { getWidgetSubName } from '../lib/getWidgetSubName';

export const useWidgetTab = (node: TabNode) => {
  const component = node.getComponent() as Widget;
  const config: null | WidgetLinkProps = node.getConfig();
  const name = node.getName();
  const subName = useMemo(
    () => getWidgetSubName(component, config),
    [component, config]
  );

  return { name, subName };
};
