import React, { memo } from 'react';

import { Widget } from '@terminal/core/types/layout';

import { AccountFilters } from '../../../../features/AccountFilters';
import { FinInstrAutocomplete } from '../../../../features/FinInstrAutocomplete';
import { WidgetHeader } from '../../../../features/WidgetHeader';
import {
  TECH_MESSAGE_CODE,
  WidgetServiceNotification,
} from '../../../../features/WidgetServiceNotification';
import { useWidgetContext } from '../../../../shared';

import { useSettings } from '../../hooks';

interface Props {
  selectedSubAccounts: string[];
  nodeId?: string;
  idFi?: number;
  hideActiveOrders?: boolean;
  setIsOpenLimitsModal: (state: boolean) => void;
}

const HybridOrderHeader = ({
  nodeId,
  idFi,
  selectedSubAccounts,
  hideActiveOrders,
  setIsOpenLimitsModal,
}: Props) => {
  const { updateNode } = useWidgetContext();

  // Сохраняем отображение активных заявок
  const saveActiveOrders = (hideActiveOrders: boolean) => {
    if (nodeId) {
      updateNode(nodeId, { hideActiveOrders });
    }
  };

  const widgetSettings = useSettings({
    hideActiveOrders: hideActiveOrders ?? false,
    saveActiveOrders,
    setIsOpenLimitsModal,
  });

  return (
    <>
      <WidgetHeader
        inputs={
          <>
            <FinInstrAutocomplete
              idFi={idFi}
              nodeId={nodeId}
              widget={Widget.HYBRIDORDER}
            />
            <AccountFilters
              selectedSubAccounts={selectedSubAccounts}
              updateNode={updateNode}
              nodeId={nodeId}
              isOrderBookVersion
            />
          </>
        }
        widgetSettings={widgetSettings}
      />
      <WidgetServiceNotification
        code={TECH_MESSAGE_CODE.TRADE}
        variant="line"
        canBeShort
      />
    </>
  );
};

export const Header = memo(HybridOrderHeader);
