import { groupBy } from 'lodash';
import { GetState } from 'zustand';

import { ChatStore, StoreSet } from '../useStore';

import {
  BrokerChatMessage,
  BrokerRecommendation,
  ChatGroupId,
  ChatMessage,
  PersonalBrokerSlice,
} from '../../types';

export const createPersonalBrokerSlice = (
  set: StoreSet,
  get: GetState<ChatStore>
): PersonalBrokerSlice => ({
  setBrokerMessages: (messages: BrokerChatMessage[]) => {
    const messagesByGroup = groupBy(messages, (message) => message.idChatGroup);

    Object.entries(messagesByGroup).forEach(([chatId, chatMessages]) => {
      // Не сохраняем общие чаты
      if (Object.values(ChatGroupId).find((groupId) => groupId === chatId))
        return;

      get().setNewMessages(chatId, chatMessages as unknown as ChatMessage[]);
    });
  },
  brokerRecommendations: [],
  setBrokerRecommendations: (brokerRecommendation: BrokerRecommendation[]) => {
    console.log(brokerRecommendation);
  },
});
