import { addDays, parse, parseISO, startOfDay } from 'date-fns';
import { useMemo, useState } from 'react';
import { CalendarInputDesktop } from '@alfalab/core-components/calendar-input/desktop';
import { parseDateString } from '@alfalab/core-components/calendar-input/utils';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import {
  DocumentTheme,
  ReportTheme,
} from '@terminal/core/lib/rest/lkDocuments';

interface OrderPeriodProps {
  documentTheme?: DocumentTheme | ReportTheme;
  periodStart: string;
  periodEnd: string;
  onPeriodStartChange: (date: string) => void;
  onPeriodEndChange: (date: string) => void;
}

export const OrderPeriod = ({
  documentTheme,
  periodStart,
  periodEnd,
  onPeriodStartChange,
  onPeriodEndChange,
}: OrderPeriodProps) => {
  const [periodStartTouched, setPeriodStartTouched] = useState(false);
  const [periodEndTouched, setPeriodEndTouched] = useState(false);

  const minPeriodStart = startOfDay(
    documentTheme?.startDate
      ? parseISO(documentTheme.startDate)
      : parseISO('2001-01-01T00:00:00')
  ).getTime();
  const maxPeriodStart =
    documentTheme?.dateType === 'date'
      ? addDays(
          documentTheme?.endDate ? parseISO(documentTheme.endDate) : new Date(),
          -1
        ).getTime()
      : parse(periodEnd, DEFAULT_VIEW_DATE_FORMAT, new Date()).getTime();

  const minPeriodEnd = parse(
    periodStart,
    DEFAULT_VIEW_DATE_FORMAT,
    new Date()
  ).getTime();
  const maxPeriodEnd = addDays(
    documentTheme?.endDate ? parseISO(documentTheme.endDate) : new Date(),
    -1
  ).getTime();

  const errorStart = useMemo(() => {
    if (periodStart && periodStartTouched) {
      const date = parseDateString(periodStart);

      if (
        Number.isNaN(date.getTime()) ||
        date.getTime() < minPeriodStart ||
        date.getTime() > maxPeriodStart
      ) {
        return 'Некорректная дата';
      }
    }

    return '';
  }, [periodStart, periodStartTouched, minPeriodStart, maxPeriodStart]);

  const errorEnd = useMemo(() => {
    if (periodEnd && periodEndTouched) {
      const date = parseDateString(periodEnd);

      if (
        Number.isNaN(date.getTime()) ||
        date.getTime() < minPeriodEnd ||
        date.getTime() > maxPeriodEnd
      ) {
        return 'Некорректная дата';
      }
    }

    return '';
  }, [periodEnd, periodEndTouched, minPeriodEnd, maxPeriodEnd]);

  if (
    !documentTheme ||
    (documentTheme.dateType !== 'date' && documentTheme.dateType !== 'period')
  ) {
    return null;
  }

  return (
    <Row>
      <Col width={6}>
        <CalendarInputDesktop
          label={documentTheme.dateType === 'date' ? 'Дата' : 'Начало периода'}
          labelView="outer"
          size="s"
          block
          value={periodStart}
          minDate={minPeriodStart}
          maxDate={maxPeriodStart}
          onChange={(e, { value }) => onPeriodStartChange(value)}
          onInputChange={(e, { value }) => onPeriodStartChange(value)}
          onBlur={() => setPeriodStartTouched(true)}
          error={errorStart}
        />
      </Col>
      {documentTheme.dateType === 'period' && (
        <Col width={6}>
          <CalendarInputDesktop
            label="Конец периода"
            labelView="outer"
            size="s"
            block
            value={periodEnd}
            minDate={minPeriodEnd}
            maxDate={maxPeriodEnd}
            onChange={(e, { value }) => onPeriodEndChange(value)}
            onInputChange={(e, { value }) => onPeriodEndChange(value)}
            onBlur={() => setPeriodEndTouched(true)}
            error={errorEnd}
          />
        </Col>
      )}
    </Row>
  );
};
