import {
  Cell,
  Column,
  ColumnHeaderCell,
  Region,
  RegionCardinality,
  Regions,
  SelectionModes,
  Table2,
  TableProps,
  TruncatedFormat,
  Utils,
} from '@blueprintjs/table';
import cn from 'classnames';
import sum from 'lodash/sum';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';

import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { getColumnDescription } from '@terminal/core/lib/helpers/getColumnDescription';
import { getColumnName } from '@terminal/core/lib/helpers/getColumnName';
import { getIsColumnStatic } from '@terminal/core/lib/helpers/getIsColumnStatic';
import {
  ColumnFilterRenderProps,
  Filter,
  Sort,
  TableColumnSetting,
} from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import './BlueprintTable.css';
import styles from './BlueprintTable.module.css';

export type CellRenderProps<T> = (
  rowIndex: number,
  columnIndex: number,
  columnKey: TableColumnKey,
  data: T[],
  setHoverIndex: (rowIndex: number | null) => void,
  columnName: string | JSX.Element,
  isDoubleRowMode?: boolean,
  setContextMenuRow?: (rowIndex: number) => void
) => ReactNode;

interface Props<T> extends TableProps {
  columnSetting: TableColumnSetting[];
  data: T[];
  cellRender: CellRenderProps<T>;
  columnFilterRender: ColumnFilterRenderProps;
  onReorder?: (newColumnSetting: TableColumnSetting[]) => void;
  onChangeWidth?: (newColumnSetting: TableColumnSetting[]) => void;
  //Индексы выбранных по умолчанию строк в таблице
  defaultSelectedRows?: number[];
  onRowClick?: (row: T) => void;
  isMultiSelect?: boolean;
  fixedColumns?: TableColumnKey[]; // массив индексов колонок, которые нельзя реордерить
  sort?: Sort;
  onSort?: (key: string) => void;
  filter?: Filter;
  onFilter?: (filter?: Filter) => void;
  rerenderDepend?: any;
  hoverActions?: (
    data: T,
    onHover: (state: boolean) => void,
    viewportWidth?: number
  ) => JSX.Element;
  isDoubleRowMode?: boolean;
  contextMenuRow?: number | null;
  setContextMenuRow?: (rowIndex: number) => void;
  getIsSelectedRow?: (item: T) => boolean;
  // Если "true" - строки выделяются подчеркиваниями
  // Если "false" - строки выделяются разными цветами
  isBorderedRows?: boolean;
}

interface DoubleCellRender<T> {
  secondRowColumnKey: TableColumnKey | undefined;
  firstRowColumnKey: TableColumnKey;
  firstRowContent: React.ReactElement;
  rowIndex: number;
  columnIndex: number;
  data: T[];
  setHoverIndex: (rowIndex: number | null) => void;
  cellRender: CellRenderProps<T>;
}

export type DoubleCellRenderProps<T> = (
  props: DoubleCellRender<T>
) => ReactNode;

export const BlueprintTable = <T,>({
  columnSetting,
  data,
  columnFilterRender,
  cellRender,
  onReorder,
  defaultSelectedRows,
  onRowClick,
  isMultiSelect,
  fixedColumns,
  sort,
  onSort,
  filter,
  onFilter,
  onChangeWidth,
  rerenderDepend,
  hoverActions,
  isDoubleRowMode,
  contextMenuRow,
  setContextMenuRow,
  isBorderedRows,
  className,
  ...tableProps
}: Props<T>) => {
  const isMount = useRef(false);
  const tableRef = useRef<Table2>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [selectedRows, setSelecterRows] = useState<number[]>(
    defaultSelectedRows || []
  );

  useEffect(() => {
    if (defaultSelectedRows) {
      setSelecterRows(defaultSelectedRows);
    }
  }, [defaultSelectedRows]);

  const prevFilteredColumns = usePrevious(
    columnSetting.filter(
      (column) => Boolean(column.selected) && Boolean(column.key)
    )
  );
  const filteredColumns = useMemo(
    () =>
      columnSetting.filter(
        (column) => Boolean(column.selected) && Boolean(column.key)
      ),
    [columnSetting]
  );

  useEffect(() => {
    //Проверяем что таблица смонтировалась
    if (isMount.current) {
      const prevColumns = prevFilteredColumns?.map((column) => column.key);
      const columns = filteredColumns.map((column) => column.key);

      //Скроллим к началу таблицы если изменился набор колонок или их наполнение
      if (
        prevColumns &&
        !(
          columns.length === prevColumns.length &&
          prevColumns.every((key) => columns.includes(key))
        )
      ) {
        tableRef.current?.scrollToRegion(Regions.column(0));
      }
    }

    isMount.current = true;
  }, [filteredColumns, prevFilteredColumns]);

  const handleColumnsReordered = (
    oldIndex: number,
    newIndex: number,
    length: number
  ) => {
    if (oldIndex === newIndex) {
      return;
    }

    const nonSelectedColumns = columnSetting.filter(
      (column) => !column.selected
    );
    const nextFilteredColumns =
      Utils.reorderArray(filteredColumns, oldIndex, newIndex, length) || [];

    let nonReorderable = false;

    // Колонка с тикером должна быть всегда первой
    if (
      filteredColumns[oldIndex].key === TableColumnKey.SymbolObject ||
      filteredColumns[oldIndex].key === TableColumnKey.NameObject
    ) {
      nonReorderable = true;
    }

    // Колонки для которых явно отключен реордер
    if (fixedColumns && fixedColumns.includes(filteredColumns[oldIndex].key)) {
      nonReorderable = true;
    }

    if (nonReorderable) {
      return;
    }

    onReorder?.([...nextFilteredColumns, ...nonSelectedColumns]);
  };

  const onSelection = (regions: Region[]) => {
    if (regions.length > 0 && onRowClick) {
      const { rows } = regions[0];

      if (rows) {
        const index = rows[0];
        const row = data[index];
        const isPrevSelected = selectedRows.indexOf(index);

        if (isMultiSelect) {
          if (isPrevSelected === -1) {
            setSelecterRows((prevSelected) => [...prevSelected, index]);
          } else {
            setSelecterRows((prevSelected) =>
              prevSelected.filter((i) => i !== index)
            );
          }
        } else {
          setSelecterRows([index]);
        }

        onRowClick(row);
      }
    }
  };

  const onColumnWidthChanged = (index: number, size: number) => {
    //Если есть функция сохраннеия размеров колонок
    if (onChangeWidth) {
      const changedColumn = filteredColumns[index];

      if (!changedColumn) {
        return;
      }

      const newSettings = columnSetting.map((col) => {
        if (col.key === changedColumn.key) {
          //Сохраняем ширину в настройки
          return { ...col, width: size };
        } else {
          return col;
        }
      });

      onChangeWidth(newSettings);
    }
  };

  //Собираем размеры колонок из настроек
  const columnWidths: number[] = useMemo(
    () => filteredColumns.map((col) => col.width || 0),
    [filteredColumns]
  );

  // Blueprint не умеет динамически менять высоту строки и не дает никакого API для этого,
  // поэтому мы вызываем remount при изменении режима двухстрочный/однострочный
  // P.S. добавил rerenderDepend в зависимость
  const [forceTableUpdateKey, setForceTableUpdateKey] = useState(0);

  useEffect(() => {
    setForceTableUpdateKey((prev) => (prev === 1 ? 0 : 1));
  }, [isDoubleRowMode, rerenderDepend, filteredColumns]);

  useEffect(() => {
    tableRef.current?.forceUpdate();
  }, [hoverIndex, contextMenuRow]);

  const viewportWidth = tableRef.current?.state.viewportRect?.width ?? 0;
  const offset = tableRef.current?.state.viewportRect?.left ?? 0;
  const columnTotalWidth = sum(columnWidths);

  const fixedColumnWidths: number[] = useMemo(() => {
    return columnWidths.concat(
      columnTotalWidth < viewportWidth ? viewportWidth - columnTotalWidth : 0
    );
  }, [columnTotalWidth, columnWidths, viewportWidth]);

  const actionPos =
    columnTotalWidth <= viewportWidth
      ? columnTotalWidth
      : viewportWidth + offset;

  const getIsFirstColumn = (
    column: TableColumnSetting,
    columnIndex: number
  ) => {
    return (
      columnIndex === 0 ||
      [
        TableColumnKey.SymbolObject,
        TableColumnKey.NameObject,
        TableColumnKey.NameBalanceGroup,
        TableColumnKey.MarketNameMarketBoard,
      ].includes(column.key) ||
      fixedColumns?.includes(column.key)
    );
  };

  return (
    <Table2
      key={forceTableUpdateKey}
      ref={tableRef}
      numRows={data.length}
      enableRowResizing={false}
      enableRowHeader={false}
      enableMultipleSelection={false}
      defaultRowHeight={isDoubleRowMode ? 48 : 24}
      enableColumnReordering
      columnWidths={fixedColumnWidths}
      onColumnsReordered={handleColumnsReordered}
      selectionModes={
        onRowClick ? [RegionCardinality.CELLS] : SelectionModes.NONE
      }
      onSelection={onSelection}
      onColumnWidthChanged={onColumnWidthChanged}
      className={cn(styles.table, className)}
      {...tableProps}
    >
      {filteredColumns
        .map((column, index) => (
          <Column
            key={`${column.key}-${index}`}
            id={`${column.key}-${index}`}
            cellRenderer={(rowIndex: number, columnIndex: number) => {
              const isFirstColumn = getIsFirstColumn(column, columnIndex);
              const isSelected =
                selectedRows.includes(rowIndex) ||
                hoverIndex === rowIndex ||
                contextMenuRow === rowIndex;
              const isEven = (rowIndex + 1) % 2 === 0;
              const withBorder = isBorderedRows && rowIndex < data.length - 1;

              return (
                <Cell
                  className={cn(styles.cell, column.cellClassName, {
                    [styles.firstColumnCell]: isFirstColumn,
                    [styles.rowSelected]: isSelected,
                    [styles.doubleRowCell]: isDoubleRowMode,
                    [styles.borderedRowCell]: withBorder,
                    [styles.evenRowCell]: !isBorderedRows && isEven,
                  })}
                >
                  {cellRender(
                    rowIndex,
                    columnIndex,
                    column.key,
                    data,
                    setHoverIndex,
                    getColumnName(column),
                    isDoubleRowMode,
                    setContextMenuRow
                  )}
                  {hoverActions &&
                    columnIndex === 0 &&
                    hoverIndex === rowIndex &&
                    hoverActions(
                      data[rowIndex],
                      (hover: boolean) =>
                        hover ? setHoverIndex(rowIndex) : setHoverIndex(null),
                      actionPos
                    )}
                </Cell>
              );
            }}
            columnHeaderCellRenderer={(columnIndex: number) => {
              const className = column.headerCellClassName;
              const columnName = getColumnName(column);
              const columnDescription = getColumnDescription(column);
              const isFirstColumn = getIsFirstColumn(column, columnIndex);

              return (
                <ColumnHeaderCell
                  //Скрываем перенос для фиксированных колонок
                  className={cn(
                    'headerCell',
                    isDoubleRowMode && 'alignStartColumnHeader',
                    isDoubleRowMode && 'doubleRowHeaderCell',
                    isFirstColumn && 'firstColumnHeaderCell',
                    (getIsColumnStatic(column) ||
                      fixedColumns?.includes(column.key)) &&
                      'noReorder'
                  )}
                >
                  {typeof column.renderHeader === 'undefined' ? (
                    <div
                      className={className}
                      onClick={() => onSort?.(column.key)}
                    >
                      {/* Фильтр для колонки */}
                      {column.filterType &&
                        columnFilterRender(
                          column.key,
                          column.filterType,
                          filter,
                          column.filterSettings,
                          onFilter
                        )}
                      {/* Сортировки колонки (не отображаем для удаления и выделения) */}
                      {onSort && column.key !== TableColumnKey.IsSelected && (
                        <ArrowUpCompactXsIcon
                          className={cn(
                            'sortIcon',
                            sort?.key === column.key && styles.activeSort,
                            !sort?.asc &&
                              sort?.key === column.key &&
                              styles.sortDesc
                          )}
                          height={12}
                          width={12}
                        />
                      )}
                      {columnDescription ? (
                        <TooltipDesktop
                          targetClassName={styles.tooltipTarget}
                          contentClassName={styles.tooltipContent}
                          content={columnDescription}
                          trigger="hover"
                          position="top-start"
                          onOpenDelay={500}
                          offset={[-20, 16]}
                        >
                          <TruncatedFormat>{columnName}</TruncatedFormat>
                        </TooltipDesktop>
                      ) : (
                        <TruncatedFormat>{columnName}</TruncatedFormat>
                      )}
                    </div>
                  ) : (
                    <div className={className}>{column.renderHeader}</div>
                  )}
                  {/* Second row goes here */}
                  {isDoubleRowMode && column.secondRow && (
                    <div
                      className={cn(
                        styles.doubleRowHeaderCellSecondRow,
                        'bp4-table-truncated-format-text'
                      )}
                    >
                      {getColumnName(column.secondRow)}
                    </div>
                  )}
                </ColumnHeaderCell>
              );
            }}
          />
        ))
        // Table не хочет принимать children = elements[] + element
        .concat(
          <Column
            key="empty--1"
            id="empty"
            cellRenderer={(rowIndex: number) => {
              const isEven = (rowIndex + 1) % 2 === 0;

              return (
                <Cell
                  className={cn(styles.cell, {
                    [styles.doubleRowCell]: isDoubleRowMode,
                    [styles.evenRowCell]: !isBorderedRows && isEven,
                  })}
                />
              );
            }}
            columnHeaderCellRenderer={() => (
              <ColumnHeaderCell
                className={cn(
                  'headerCell',
                  isDoubleRowMode && 'alignStartColumnHeader',
                  isDoubleRowMode && 'doubleRowHeaderCell',
                  'noReorder'
                )}
              />
            )}
          />
        )}
    </Table2>
  );
};
