import {
  ClientGTSubAccountEntity,
  ClientSubAccountEntity,
} from '../lib/client/entities';

import { SubAccountItem, SubGTAccountItem } from '../types/subAccount';

export const subAccountMap = (
  entity: ClientSubAccountEntity
): SubAccountItem => ({
  idAccount: entity.IdAccount,
  idSubAccount: entity.IdSubAccount,
  backDate: entity.BackDate,
  backBal: entity.BackBal,
  backPL: entity.BackPL,
  version: entity.Version.toString(),
  operation: entity.Operation,
});

export const subGTAccountMap = (
  entity: ClientGTSubAccountEntity
): SubGTAccountItem => ({
  idAccount: entity.IdAccount,
  idSubAccount: entity.IdSubAccount,
  portfolioCost: entity.PortfolioCost,
  marginInitial: entity.MarginInitial,
  marginRequirement: entity.MarginRequirement,
  idMarginRequirement: entity.IdMarginRequirement,
  idRazdelGroup: entity.IdRazdelGroup,
  version: entity.Version.toString(),
  operation: entity.Operation,
});
