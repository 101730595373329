import React, { useMemo, VFC } from 'react';
import { ArrowsUpDownBoldMIcon } from '@alfalab/icons-glyph/ArrowsUpDownBoldMIcon';
import { BarChartMIcon } from '@alfalab/icons-glyph/BarChartMIcon';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { CheckmarkReceiptMIcon } from '@alfalab/icons-glyph/CheckmarkReceiptMIcon';
import { ClockMIcon } from '@alfalab/icons-glyph/ClockMIcon';
import { DollarRoundedMIcon } from '@alfalab/icons-glyph/DollarRoundedMIcon';
import { JapaneseCandlesMIcon } from '@alfalab/icons-glyph/JapaneseCandlesMIcon';
import { JudgeHammerMIcon } from '@alfalab/icons-glyph/JudgeHammerMIcon';
import { ListBulletedBoldMIcon } from '@alfalab/icons-glyph/ListBulletedBoldMIcon';
import { ReceiptMIcon } from '@alfalab/icons-glyph/ReceiptMIcon';
import { SignMIcon } from '@alfalab/icons-glyph/SignMIcon';
import { WorldMIcon } from '@alfalab/icons-glyph/WorldMIcon';

import {
  DropdownMenu,
  DropdownMenuProps,
} from '@terminal/common/components/DropdownMenu';
import { OrderBookIcon } from '@terminal/core/constants/icons';
import { DEV_APP_DEVTOOLS, DEV_CHARTIQ_ENABLED } from '@terminal/core/env';
import { trackTradingFormOpened } from '@terminal/core/lib/analytics';
import { TradingFormSource } from '@terminal/core/lib/analytics/model/main';
import { Widget } from '@terminal/core/types/layout';

export type WidgetPickerDropdownMenuProps = Omit<
  DropdownMenuProps,
  'groups'
> & {
  onPick: (widget: Widget) => void;
  label?: string;
};

export const WidgetPickerDropdownMenu: VFC<WidgetPickerDropdownMenuProps> = (
  props
) => {
  const { onPick, children, label, ...restProps } = props;

  const groups = useMemo<DropdownMenuProps['groups']>(
    () => [
      {
        key: '1',
        label,
        items: [
          {
            key: '1.1',
            type: 'button',
            Icon: ArrowsUpDownBoldMIcon,
            label: 'Торговля',
            hideMenu: true,
            onClick: () => {
              trackTradingFormOpened(TradingFormSource.newWidgetMenu);
              onPick(Widget.HYBRIDORDER);
            },
          },
          {
            key: '1.2',
            type: 'button',
            Icon: OrderBookIcon,
            label: 'Стакан',
            hideMenu: true,
            onClick: () => {
              onPick(Widget.ORDERBOOK);
            },
          },
          {
            key: '1.3',
            type: 'button',
            Icon: JapaneseCandlesMIcon,
            label: 'График',
            hidden: !DEV_CHARTIQ_ENABLED,
            hideMenu: true,
            onClick: () => {
              onPick(Widget.CHART);
            },
          },
          {
            key: '1.4',
            type: 'button',
            Icon: JudgeHammerMIcon,
            label: 'Лента сделок',
            hideMenu: true,
            onClick: () => {
              onPick(Widget.TRADES);
            },
          },
        ],
      },
      {
        key: '2',
        items: [
          {
            key: '2.1',
            type: 'button',
            Icon: ListBulletedBoldMIcon,
            label: 'Инструменты',
            hideMenu: true,
            onClick: () => {
              onPick(Widget.WATCHLIST);
            },
          },
          {
            key: '2.2',
            type: 'button',
            Icon: BarChartMIcon,
            label: 'Инфо',
            hideMenu: true,
            onClick: () => {
              onPick(Widget.INSTRUMENT_INFO);
            },
          },
        ],
      },
      {
        key: '3',
        items: [
          {
            key: '3.1',
            type: 'button',
            Icon: BriefcaseMIcon,
            label: 'Позиции',
            hideMenu: true,
            onClick: () => {
              onPick(Widget.POSITIONS);
            },
          },
          {
            key: '3.2',
            type: 'button',
            Icon: ReceiptMIcon,
            label: 'Заявки',
            hideMenu: true,
            onClick: () => {
              onPick(Widget.ORDERS);
            },
          },
          {
            key: '3.3',
            type: 'button',
            Icon: ClockMIcon,
            label: 'Операции',
            hideMenu: true,
            onClick: () => {
              onPick(Widget.OPERATIONS);
            },
          },
          {
            key: '3.4',
            type: 'button',
            Icon: DollarRoundedMIcon,
            label: 'Балансы',
            hideMenu: true,
            onClick: () => {
              onPick(Widget.BALANCE);
            },
          },
          {
            key: '3.5',
            type: 'button',
            Icon: SignMIcon,
            label: 'Сертификаты',
            hidden: !DEV_APP_DEVTOOLS,
            hideMenu: true,
            onClick: () => {
              onPick(Widget.CERTIFICATES);
            },
          },
          {
            key: '3.6',
            type: 'button',
            Icon: CheckmarkReceiptMIcon,
            label: 'Средства разработки',
            hidden: !DEV_APP_DEVTOOLS,
            hideMenu: true,
            onClick: () => {
              onPick(Widget.DEVTOOLS);
            },
          },
        ],
      },
      {
        key: '4',
        items: [
          {
            key: '4.1',
            type: 'button',
            Icon: WorldMIcon,
            label: 'Новости',
            hideMenu: true,
            onClick: () => {
              onPick(Widget.NEWS);
            },
          },
        ],
      },
    ],
    [label, onPick]
  );

  return (
    <DropdownMenu withGap groups={groups} {...restProps}>
      {children}
    </DropdownMenu>
  );
};
