import { TagDesktopProps } from '@alfalab/core-components/tag/desktop';

import { ButtonMenuButton } from './ButtonMenuButton';

import styles from './ButtonMenu.module.css';

export interface ButtonMenuItemProps {
  id?: string;
  type: string;
  name: string;
  disabled?: boolean;
  onClick?: () => void;
}

interface ButtonMenuProps {
  active: string[];
  items: ButtonMenuItemProps[];
  size?: TagDesktopProps['size'];
  margin?: 's' | 'm';
}

export const ButtonMenu = ({ active, items, ...props }: ButtonMenuProps) => (
  <div className={styles.container}>
    {items.map((item: ButtonMenuItemProps) => (
      <div key={`button-menu-item-${item.type}`} className={styles.item}>
        <ButtonMenuButton
          text={item.name}
          active={active.indexOf(item.type) >= 0}
          onClick={() => Boolean(item.onClick) && item.onClick?.()}
          disabled={Boolean(item.disabled)}
          size={props.size}
        />
      </div>
    ))}
  </div>
);
