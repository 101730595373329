import { TradingClosed } from './TradingClosed';

import styles from './TradingClosed.module.css';

export const TradingClosedMobile = () => {
  return (
    <div className={styles.wrapperMobile}>
      <TradingClosed />
    </div>
  );
};
