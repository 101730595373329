import { beginnerMedium } from './beginner/beginner_medium';
import { beginnerSmall } from './beginner/beginner_small';
import { daytradeLarge } from './daytrade/daytrade_large';
import { dayTradeMedium } from './daytrade/daytrade_medium';
import { daytradeSmall } from './daytrade/daytrade_small';
import { scalpMedium } from './scalping/scalp_medium';
import { scalpingLarge } from './scalping/scalping_large';
import { swingLarge } from './swing/swing_large';
import { swingSmall } from './swing/swing_small';
import { swingMedium } from './swing/wsing_medium';

import { ConfigurationsMatrix } from '../../../model/types/configurationsMatrix';

/***
 * Массив функций, строящих конфигурации в зависимости от выбранных параметров экрана и стиля торговли
 */
export const configurationMatrix: ConfigurationsMatrix = [
  [swingSmall, daytradeSmall, null, beginnerSmall],
  [swingMedium, dayTradeMedium, scalpMedium, beginnerMedium],
  [swingLarge, daytradeLarge, scalpingLarge, null],
];
