import qs from 'query-string';

import { LK_URI } from '@terminal/core/env';

export function getInstructionQuestionImageUrl(fileName: string) {
  return qs.stringifyUrl({
    url: LK_URI + '/api/instructions/picture',
    query: { fileName },
  });
}
