import React, { useEffect, useState } from 'react';

import { LOCATIONS } from '@terminal/core/constants/analytics';
import { trackMainMenu } from '@terminal/core/lib/analytics';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { SelectTreaty } from '../components/SelectTreaty';
import { NotificationsMain } from './tabs/NotificationsMain';

import styles from './Notifications.module.css';

interface NotificationsProps {
  notificationsCount?: number;
  onNotificationRead?: () => void;
}

export const Notifications = React.memo(
  ({ onNotificationRead, notificationsCount }: NotificationsProps) => {
    const [treaty, setTreaty] = useState<Treaty | null>(null);

    useEffect(() => {
      trackMainMenu(LOCATIONS.LK_NOTIFICATIONS);
    }, []);

    return (
      <div className={styles.container}>
        <div className={styles.treaty}>
          <SelectTreaty
            hideLabel
            zeros
            onSelect={(treaty) => setTreaty(treaty)}
          />
        </div>
        <div className={styles.content}>
          <NotificationsMain
            treaty={treaty}
            notificationsCount={notificationsCount}
            onNotificationRead={onNotificationRead}
          />
        </div>
      </div>
    );
  }
);
