// Сервис умеет возвращать условное "текущее" серверное время
// Оно используется при подаче ордеров
// Когда создается текст ордера нужно подставлять время сервера в московской таймзоне
// потому что сервер при проверке заявки будет брать свое текущее время и генерить свою версию текста заявки и сверять

import { addMilliseconds } from 'date-fns';

import {
  FrontEndType,
  HeartbeatEntity,
  MessageUnitedType,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import AdirClientService from '../client/service';

class Time extends AdirClientService {
  // Время сервера мы получаем по хартбитам и вычисляем дельту
  deltaMilliseconds: number = 0;
  private signDeltaMilliseconds: number = 0;

  override init() {
    this.addClientListener(
      EntityType.HeartbeatEntity,
      this.onHeartBeat.bind(this)
    );
  }

  private getDeltaFromHeartBeat(message: MessageUnitedType) {
    const heartbeats = message.data as HeartbeatEntity[];
    let delta = 0;

    for (const heartbeat of heartbeats) {
      // getTime возвращает количество миллисекунд от 1 янв 1970
      // сохраняем дельту разницы часов между временем клиента и сервера
      delta = heartbeat.ReceieveTime.getTime() - heartbeat.SendTime.getTime();
    }

    return delta;
  }

  private onHeartBeat(message: MessageUnitedType) {
    if (message.frontend === FrontEndType.OperServer) {
      this.signDeltaMilliseconds = this.getDeltaFromHeartBeat(message);
    } else {
      this.deltaMilliseconds = this.getDeltaFromHeartBeat(message);
    }
  }

  formatToServerTime(date: Date) {
    return addMilliseconds(date, this.deltaMilliseconds);
  }

  /**
   * Использовать вместо new Date, когда нужно передать серверу таймштамп
   * Нужно, чтобы передавалось правильное время во всех таймзонах и на устройствах с поломанным временем
   */
  getServerTime() {
    // возвращает текущее "серверное время"
    const now = new Date();

    return addMilliseconds(now, this.deltaMilliseconds);
  }

  getServerSignTime() {
    // возвращает текущее "серверное время" для подписи
    return addMilliseconds(new Date(), this.signDeltaMilliseconds);
  }
}

export const TimeService = new Time();

// добавляем экспорт getServerTime чтоб не переделывать множество импортов
export const getServerTime = () => TimeService.getServerTime();
export const getServerSignTime = () => TimeService.getServerSignTime();
