import cn from 'classnames';
import React, { FC, useRef, useState } from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop/Component.desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { Stepper } from '@terminal/common/components/Stepper';
import { MINORITY } from '@terminal/core/constants/ui';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { roundPrice } from '@terminal/core/lib/format';
import { plural } from '@terminal/core/lib/plural';
import { OrderSide } from '@terminal/core/types/trading';
import { SlippageType } from '@terminal/core/types/ui';

import styles from './SlippageInput.module.css';

const SlippageTypeMap = {
  [SlippageType.TICK]: () => 'Тики',
  [SlippageType.PERCENT]: (isSmall: boolean) => (isSmall ? '%' : 'Проценты'),
};

interface Props {
  disabled?: boolean;
  isSmall?: boolean;
  withSummary?: boolean;
  side: OrderSide;
  price?: number;
  priceStep?: number;
  symbol?: CurrencyCodes;
  slippageType: SlippageType;
  setSlippageType: (type: SlippageType) => void;
  slippageValue: number;
  setSlippageValue: (value: number) => void;
  containerClassName?: string;
}

export const SlippageInput: FC<Props> = ({
  disabled = false,
  isSmall = false,
  withSummary = false,
  side,
  price,
  priceStep = 0.01,
  symbol,
  slippageType,
  slippageValue,
  setSlippageType,
  setSlippageValue,
  containerClassName,
}) => {
  const [slippageTypeVisible, setSlippageTypeVisible] =
    useState<boolean>(false);

  const slippageTypeDropdown = useRef<HTMLDivElement>(null);

  useOnClickOutside(slippageTypeDropdown, () => {
    setSlippageTypeVisible(false);
  });

  const slippage =
    slippageType === SlippageType.TICK
      ? slippageValue * priceStep
      : ((price || 0) * slippageValue) / 100;

  const priceWithSlippage: number | null = price
    ? price + (side === OrderSide.BUY ? 1 : -1) * slippage
    : null;

  return (
    <div className={cn(containerClassName)}>
      <Typography.Text
        view="secondary-small"
        color="secondary"
        tag="div"
        className={cn(styles.label, styles.graphic)}
      >
        Проскок&nbsp;
        <Tooltip
          targetClassName={styles.tooltipTarget}
          contentClassName={styles.tooltipContent}
          content={
            <>
              <Typography.Text
                weight="bold"
                view="secondary-small"
                className={styles.graphic}
              >
                Проскок
              </Typography.Text>
              <Typography.Text
                view="secondary-small"
                className={styles.graphic}
              >
                Величина сдвига цены лимитной заявки от цены активации. Задается
                в тиках – минимальных шагах цены
              </Typography.Text>
            </>
          }
          trigger="hover"
          position="top-start"
          offset={[-20, 16]}
        >
          <InformationCircleLineSIcon
            height={10}
            width={10}
            color="var(--color-dark-graphic-secondary)"
          />
        </Tooltip>
      </Typography.Text>

      <div className={cn(styles.row, isSmall && styles.small)}>
        <div
          ref={slippageTypeDropdown}
          className={cn(styles.dropdownRef, disabled && styles.disabled)}
        >
          <div
            className={styles.dropDownContent}
            onClick={() => !disabled && setSlippageTypeVisible((prev) => !prev)}
          >
            <Typography.Text
              view="secondary-large"
              className={cn(styles.orderName, styles.graphic)}
            >
              {SlippageTypeMap[slippageType](isSmall)}
            </Typography.Text>
            <ChevronDownCompactMIcon
              height={16}
              width={16}
              className={cn(
                styles.chevron,
                slippageTypeVisible && styles.chevronUp
              )}
            />
          </div>
          <Dropdown
            useAnchorWidth
            anchorElement={slippageTypeDropdown.current}
            open={slippageTypeVisible}
            offset={[0, 4]}
            position="bottom-start"
            preventFlip={false}
            preventOverflow={false}
            popperClassName={cn(isSmall ? styles.popperSmall : styles.popper)}
          >
            {Object.values(SlippageType).map((type) => (
              <Dropdown.Item
                key={type}
                content={
                  <div className={styles.dropDownItemContent}>
                    {SlippageTypeMap[type](false)}
                  </div>
                }
                onClick={() => {
                  setSlippageType(type);
                  setSlippageTypeVisible(false);
                }}
                rightIcon={
                  slippageType === type ? (
                    <CheckmarkMIcon height={16} width={16} />
                  ) : undefined
                }
              />
            ))}
          </Dropdown>
        </div>
        <AmountInput
          block
          disabled={disabled}
          positiveOnly={false}
          suffix={getSuffix(slippageType, slippageValue, isSmall)}
          value={
            slippageType === SlippageType.TICK
              ? slippageValue
              : slippageValue * 100
          }
          minority={slippageType === SlippageType.TICK ? 1 : 100}
          integersOnly={slippageType === SlippageType.TICK}
          onChange={(_, { value }) => {
            if (typeof value === 'number') {
              setSlippageValue(
                roundPrice(
                  value / (slippageType === SlippageType.TICK ? 1 : 100),
                  slippageType === SlippageType.TICK ? 1 : 0.01
                )
              );
            }
          }}
          rightAddons={
            !isSmall ? (
              <Stepper
                onAdd={() => {
                  setSlippageValue(
                    (slippageValue || 0) +
                      (slippageType === SlippageType.TICK ? 1 : 0.01)
                  );
                }}
                onSub={() => {
                  setSlippageValue(
                    Math.max(
                      0,
                      (slippageValue || 0) -
                        (slippageType === SlippageType.TICK ? 1 : 0.01)
                    )
                  );
                }}
              />
            ) : null
          }
          className={styles.customInput}
          fieldClassName={styles.customInputField}
        />
      </div>

      {withSummary && priceWithSlippage ? (
        <Typography.Text
          view="secondary-small"
          color="attention"
          tag="div"
          className={styles.graphic}
        >
          Цена не {side === OrderSide.BUY ? 'более' : 'менее'}&nbsp;
          <Amount.Pure
            value={priceWithSlippage * MINORITY}
            minority={MINORITY}
            view="withZeroMinorPart"
            currency={symbol}
          />
        </Typography.Text>
      ) : null}
    </div>
  );
};

function getSuffix(
  slippageType: SlippageType,
  slippageValue: number,
  isSmall: boolean
) {
  if (slippageType === SlippageType.TICK) {
    return isSmall ? 'т' : plural(['тик', 'тика', 'тиков'], slippageValue);
  }

  return '%';
}
