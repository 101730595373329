import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { ContestRestrictedAsset } from '../model/types/ContestRestrictedAsset';
import { ContestRestrictedAssetsParams } from './types/ContestRestrictedAssetsParams';

export async function getContestRestrictedAssets(
  data: ContestRestrictedAssetsParams
): Promise<ContestRestrictedAsset[]> {
  const result = await fetchLkResult<ContestRestrictedAsset[] | LKResult>(
    qs.stringifyUrl({
      url: '/api/contest/restricted-assets',
      query: { ...data },
    })
  );

  if ('code' in result) {
    throw new Error(result.message || '');
  }

  return result;
}
