// TODO: Ждёт рефакторинга вместе с rest запросами
export class HttpStatusError extends Error {
  /** HTTP статус-код */
  public status: number;
  /** код ошибки с бэка */
  public code: number;
  /** объект с доп. данными по ошибке */
  public data: any;

  constructor(message: string, status: number, code: number = 0, data?: any) {
    super(message);
    Object.setPrototypeOf(this, HttpStatusError.prototype);
    this.name = 'HttpStatusError';

    this.code = code;
    this.data = data;
    this.status = status;
  }
}
