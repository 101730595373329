import {
  closestCenter,
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import isNumber from 'lodash/isNumber';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import {
  CSSProperties,
  FC,
  forwardRef,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { IconButton } from '@alfalab/core-components/icon-button';
import { SelectDesktop as Select } from '@alfalab/core-components/select/desktop';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { CrossSIcon } from '@alfalab/icons-glyph/CrossSIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import { PlusMIcon } from '@alfalab/icons-glyph/PlusMIcon';

import { WidgetsNameMap } from '@terminal/core/constants/Layout';
import {
  flattenColumns,
  makeDoubleColumns,
} from '@terminal/core/hooks/useDoubleRowMode';
import { getColumnDescription } from '@terminal/core/lib/helpers/getColumnDescription';
import { getColumnName } from '@terminal/core/lib/helpers/getColumnName';
import { getIsColumnStatic } from '@terminal/core/lib/helpers/getIsColumnStatic';
import {
  BaseWidgetConfig,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';
import { CommonSettings } from '@terminal/core/types/ui';

import { ReactComponent as DragIcon } from '../icons/drag_s.svg';

import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

import styles from './DoubleColumnSettingModal.module.css';

interface DoubleColumnSettingModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  columnsSetting: TableColumnSetting[];
  defaultColumnSetting: TableColumnSetting[];
  defaultTickerKey?: TableColumnKey;
  defaultNameKey?: TableColumnKey;
  updateNode: (
    nodeId: string,
    config: BaseWidgetConfig,
    newName?: string | undefined
  ) => void;
  nodeId?: string;
  widgetType: Widget;
  selectedDisplayInstrumentType: CommonSettings['defaultValues']['selectedDisplayInstrumentType'];
}

enum Position {
  Before = -1,
  After = 1,
}

type Column = TableColumnSetting & { id: UniqueIdentifier };

export const DoubleColumnSettingModal = ({
  isOpen,
  setIsOpen,
  columnsSetting,
  defaultColumnSetting,
  defaultTickerKey,
  defaultNameKey,
  widgetType,
  nodeId,
  updateNode,
  selectedDisplayInstrumentType,
}: DoubleColumnSettingModalProps) => {
  const isAnyRowDouble = Boolean(columnsSetting[0].secondRow);
  const startColumnSetting = (
    isAnyRowDouble
      ? (columnsSetting as Column[])
      : makeDoubleColumns(columnsSetting, widgetType)
  ).filter((item) => item.selected);

  const [items, setItems] = useState(startColumnSetting);
  const filteredItems = useMemo(
    () => items.filter(({ selected }) => Boolean(selected)),
    [items]
  );

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const flatColumnsSetting = flattenColumns(filteredItems, widgetType);

  const columnOptions = uniqBy(
    defaultColumnSetting.map((column) => {
      let result: TableColumnSetting = { ...column };

      if (
        column.key === TableColumnKey.SymbolObject ||
        column.key === TableColumnKey.NameObject
      ) {
        if (selectedDisplayInstrumentType === 'name') {
          result.key = defaultNameKey || TableColumnKey.NameObject;
        } else {
          result.key = defaultTickerKey || TableColumnKey.SymbolObject;
        }
      }

      return result;
    }),
    'key'
  );

  useEffect(() => {
    setItems(startColumnSetting);
    // если добавлять startColumnSetting будет бесконечный цикл
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsSetting]);

  useEffect(() => {
    const result = cloneDeep(items);

    if (widgetType !== Widget.BALANCE) {
      if (selectedDisplayInstrumentType === 'name') {
        result[0].key = defaultNameKey || TableColumnKey.NameObject;
      } else {
        result[0].key = defaultTickerKey || TableColumnKey.SymbolObject;
      }
    }

    setItems(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDisplayInstrumentType, widgetType]);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent, widgetType: Widget) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        setItems((items) => {
          const oldIndex = items.findIndex(({ id }) => id === active.id);
          const newIndex = items.findIndex(({ id }) => id === over!.id);
          const newItem = items.find(({ id }) => id === over!.id);

          if (getIsColumnStatic(newItem, widgetType)) {
            return items;
          }

          return arrayMove(items, oldIndex, newIndex);
        });
      }

      setActiveId(null);
    },
    []
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const handleRemove = useCallback(
    (columnId: UniqueIdentifier) =>
      setItems((items) =>
        items.map((item) => {
          if (item.id !== columnId) {
            return item;
          }

          return merge(
            { ...item, selected: false },
            { secondRow: item.secondRow ? { selected: false } : undefined }
          );
        })
      ),
    []
  );

  const handleSelection = useCallback(
    (
      columnId: UniqueIdentifier,
      setting?: TableColumnSetting,
      secondRow?: boolean
    ) => {
      const alreadySelected = !setting;

      setItems((prevItems) => {
        return prevItems.reduce((acc, column) => {
          if (column.id === columnId) {
            if (secondRow) {
              if (alreadySelected) {
                //Если вторая строка уже была выбрана, сохраняем выключенное состояние
                acc.push({ ...column, secondRow: undefined });
                acc.push({ ...column.secondRow!, selected: false, id: v4() });
              } else {
                //Иначе перезаписываем вторую ячейку
                acc.push({
                  ...column,
                  secondRow: { ...setting, selected: true },
                });
              }
            } else {
              if (alreadySelected) {
                //Если у колонки есть вторая строка, то ставим ее на первою строчку
                if (column.secondRow) {
                  acc.push({ ...column.secondRow, selected: true, id: v4() });
                }

                //Cохраняем выключенное состояние
                acc.push({
                  ...column,
                  selected: false,
                  secondRow: undefined,
                });
              } else {
                acc.push({
                  ...(setting as TableColumnSetting),
                  id: column.id,
                  secondRow: column.secondRow,
                  selected: true,
                });
              }
            }

            return acc;
          }

          acc.push(column);

          return acc;
        }, [] as Column[]);
      });
    },
    []
  );

  return (
    <BaseModal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      wrapperClassName={styles.containerModal}
    >
      <div className={styles.doubleRowWrapper}>
        <div className={styles.header}>
          <Typography.Text view="secondary-large" weight="bold">
            Настройка колонок {WidgetsNameMap.get(widgetType)}
          </Typography.Text>
          <IconButton
            size="xs"
            view="secondary"
            icon={CrossMIcon}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className={styles.doubleContent}>
          <SortableGrid
            items={filteredItems}
            flatItems={flatColumnsSetting}
            activeId={activeId}
            handleDragStart={handleDragStart}
            handleDragEnd={(e) => handleDragEnd(e, widgetType)}
            handleDragCancel={handleDragCancel}
            handleRemove={handleRemove}
            handleSelection={handleSelection}
            columnsSettingOptions={columnOptions}
            widgetType={widgetType}
          />
          <Button
            onClick={() =>
              //Для пустой колонки достаточно только айди, далее через селекты наполняем
              setItems((prevItems) => [
                ...prevItems,
                { id: v4(), selected: true } as Column,
              ])
            }
            view="tertiary"
            size="xxs"
            block={false}
            className={cn(styles.customButton, styles.addColumnButton)}
            leftAddons={
              <PlusMIcon
                color="var(--color-dark-graphic-secondary)"
                width={18}
                height={18}
              />
            }
            disabled={filteredItems.length >= 12}
          >
            Добавить колонку
          </Button>
        </div>
        <div className={styles.footer}>
          <Button
            onClick={() =>
              setItems(makeDoubleColumns(defaultColumnSetting, widgetType))
            }
            view="ghost"
            size="xxs"
            className={cn(styles.customButton, styles.ghostButton)}
          >
            Настройки по умолчанию
          </Button>
          <div className={styles.buttonsWrapper}>
            <Button
              onClick={() => {
                setItems(startColumnSetting as Column[]);
                setIsOpen(false);
              }}
              view="ghost"
              size="xxs"
              block
              className={styles.customButton}
            >
              Отмена
            </Button>
            <Button
              onClick={() => {
                const filledColumns = items.filter(
                  (column) => column.secondRow?.key || column.key
                );

                if (nodeId) {
                  updateNode(nodeId, {
                    tableProps: { columns: filledColumns },
                  });
                }

                setIsOpen(false);
              }}
              size="xxs"
              block
              className={styles.customButton}
              view="secondary"
            >
              Сохранить
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

interface SortableGridProps {
  items: Column[];
  flatItems: TableColumnSetting[];
  columnsSettingOptions: TableColumnSetting[];
  handleDragStart: (event: DragStartEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragCancel: (event: DragCancelEvent) => void;
  handleRemove: (columnId: UniqueIdentifier) => void;
  handleSelection: (
    columnId: UniqueIdentifier,
    setting?: TableColumnSetting,
    secondRow?: boolean
  ) => void;
  activeId: UniqueIdentifier | null;
  widgetType: Widget;
}

const SortableGrid = ({
  items,
  flatItems,
  columnsSettingOptions,
  handleDragStart,
  handleDragEnd,
  handleDragCancel,
  handleRemove,
  handleSelection,
  activeId,
  widgetType,
}: SortableGridProps) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const activeIndex = useMemo(
    () => items.findIndex(({ id }) => id === activeId),
    [activeId, items]
  );
  const overlayColumn = items.find(({ id }) => id === activeId);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <div className={styles.doubleRowGrid}>
          {items.map((column, index) => (
            <SortableItem
              key={column.id}
              id={String(column.id)}
              column={column}
              index={index}
              onRemove={
                getIsColumnStatic(column, widgetType)
                  ? undefined
                  : () => handleRemove(column.id)
              }
              activeIndex={activeIndex}
              columnsSettingOptions={columnsSettingOptions}
              items={items}
              flatItems={flatItems}
              handleSelection={handleSelection}
              widgetType={widgetType}
            />
          ))}
        </div>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
        {activeId ? (
          <Item
            isDragging
            id={String(activeId)}
            column={overlayColumn}
            index={activeIndex}
            columnsSettingOptions={columnsSettingOptions}
            items={items}
            flatItems={flatItems}
            handleSelection={handleSelection}
            widgetType={widgetType}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  items: Column[];
  flatItems: TableColumnSetting[];
  columnsSettingOptions: TableColumnSetting[];
  index?: number;
  activeIndex?: number;
  withOpacity?: boolean;
  isDragging?: boolean;
  column?: Column;
  onRemove?: () => void;
  handleSelection: (
    columnId: UniqueIdentifier,
    setting?: TableColumnSetting,
    secondRow?: boolean
  ) => void;
  listeners?: SyntheticListenerMap;
  insertPosition?: Position;
  widgetType: Widget;
};

const SortableItem: FC<ItemProps> = (props) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    over,
    index,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      listeners={listeners}
      //Этот параметр нужен для расчета "указателя" дропа (место помечается вертикальной чертой)
      {...(over?.id === props.id
        ? {
            insertPosition:
              index > props.activeIndex! ? Position.After : Position.Before,
          }
        : {})}
      {...props}
      {...attributes}
    />
  );
};

const Item = forwardRef<HTMLDivElement, ItemProps>(
  (
    {
      id,
      withOpacity,
      isDragging,
      style,
      column,
      index,
      onRemove,
      listeners,
      insertPosition,
      columnsSettingOptions,
      handleSelection,
      items,
      flatItems,
      widgetType,
      ...props
    },
    ref
  ) => {
    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? '0.5' : '1',
      cursor: isDragging ? 'grabbing' : undefined,
      boxShadow: isDragging ? 'var(--shadow-m)' : undefined,
      ...style,
    };

    return (
      <div
        ref={ref}
        style={inlineStyles}
        //Определяем нужен ли "указатель" дропа (место помечается вертикальной чертой)
        className={cn(
          styles.doubleRowSetting,
          insertPosition === Position.Before && styles.insertBefore,
          insertPosition === Position.After && styles.insertAfter
        )}
        {...props}
      >
        <div className={styles.doubleRowColumnHeader}>
          <Typography.Text
            view="secondary-small"
            color="secondary"
            weight="bold"
            className={styles.doubleRowColumnName}
          >
            {isNumber(index) ? index + 1 : ''} колонка
          </Typography.Text>
          {!getIsColumnStatic(column, widgetType) && (
            <DragIcon
              className={styles.doubleRowDragIcon}
              cursor={isDragging ? 'grabbing' : 'grab'}
              //Сейчас перенос только через иконку, если нужно будет сделать на всю колонку, то перенести listeners в нужный блок
              {...listeners}
            />
          )}

          {onRemove && (
            <CrossSIcon className={styles.deleteIcon} onClick={onRemove} />
          )}
        </div>
        <div className={styles.selectWrapper}>
          <ColumnSettingSelect
            columnsSettingOptions={columnsSettingOptions}
            column={column}
            items={items}
            flatItems={flatItems}
            handleSelection={(setting) =>
              column && handleSelection(column.id, setting)
            }
            widgetType={widgetType}
          />
          <ColumnSettingSelect
            columnsSettingOptions={columnsSettingOptions}
            column={column?.secondRow}
            items={items}
            flatItems={flatItems}
            handleSelection={(setting) =>
              column && handleSelection(column.id, setting, true)
            }
            widgetType={widgetType}
          />
        </div>
      </div>
    );
  }
);

interface ColumnSettingSelectProps {
  items: Column[];
  flatItems: TableColumnSetting[];
  columnsSettingOptions: TableColumnSetting[];
  column?: TableColumnSetting;
  handleSelection: (setting?: TableColumnSetting) => void;
  widgetType: Widget;
}

const ColumnSettingSelect = ({
  columnsSettingOptions,
  column,
  flatItems,
  handleSelection,
  widgetType,
}: ColumnSettingSelectProps) => {
  const isLockedColumn =
    column?.key === TableColumnKey.SymbolObject ||
    column?.key === TableColumnKey.NameObject ||
    column?.key === TableColumnKey.ShortDescription ||
    (widgetType === Widget.BALANCE &&
      column?.key === TableColumnKey.IdAccount) ||
    (widgetType === Widget.BALANCE &&
      column?.key === TableColumnKey.NameBalanceGroup);

  return (
    <Select
      allowUnselect
      block
      size="s"
      placeholder="Не выбрано"
      disabled={isLockedColumn}
      className={styles.select}
      selected={column?.key}
      valueRenderer={({ selected }) => {
        if (selected?.value) {
          return getColumnName(selected.value);
        }
      }}
      onChange={({ selected }) => {
        handleSelection(selected?.value);
      }}
      options={columnsSettingOptions.map((columnsSettingOption) => {
        const isSelected = flatItems.some(
          (item) => item.key === columnsSettingOption.key
        );
        const isCurrentColumn = columnsSettingOption.key === column?.key;
        const columnDescription = getColumnDescription(columnsSettingOption);

        return {
          key: columnsSettingOption.key,
          value: columnsSettingOption,
          disabled: isSelected && !isCurrentColumn,
          content: (
            <div className={styles.settingNameWrapper}>
              {getColumnName(columnsSettingOption)}
              {columnDescription && (
                <Tooltip
                  contentClassName={styles.tooltipContent}
                  content={columnDescription}
                  trigger="hover"
                  position="top-start"
                  offset={[-20, 16]}
                >
                  <InformationCircleLineSIcon
                    height={12}
                    width={12}
                    color="var(--color-dark-graphic-secondary)"
                  />
                </Tooltip>
              )}
            </div>
          ),
        };
      })}
    />
  );
};
