import { EventEmitter } from 'eventemitter3';

import {
  InitFlagType,
  MessageUnitedType,
  OrderBookEntity,
  OrderBookWithYieldEntity,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import { StreamingService, SubscribeReturnData } from './streaming';

import { OrderBookLine } from '../../types/orderBook';

type LineMap = Map<number, OrderBookLine>;

type Entity = OrderBookEntity | OrderBookWithYieldEntity;

export class OrderBookService extends EventEmitter {
  /**
   * Сейчас в стакане до 40 линий
   * */
  private readonly maxLines = 40;

  /**
   * Есть два варианта заполнения стакана: по ценам или по lineId, где lineId — это индекс в массиве
   * */
  private readonly idFIToLines: Map<number, LineMap> = new Map();

  private readonly idFIToListenersCount: Map<number, number> = new Map();

  /**
   * @param fi идентификатор фин. инструмента
   * */
  public subscribe(fi: number): SubscribeReturnData {
    if (!this.idFIToLines.has(fi)) {
      this.idFIToLines.set(fi, new Map());
      this.idFIToListenersCount.set(fi, 0);
    }

    const listenersCount = this.idFIToListenersCount.get(fi) ?? 0;
    const streamingEventHandler = this.createStreamingEventHandler(fi);

    this.idFIToListenersCount.set(fi, listenersCount + 1);

    StreamingService.addClientListener(
      EntityType.OrderBookEntity,
      streamingEventHandler
    );
    StreamingService.addClientListener(
      EntityType.OrderBookWithYieldEntity,
      streamingEventHandler
    );

    const subscribeReturnData = StreamingService.subscribe({
      entity: EntityType.AvailableOrderBookEntity,
      fi: [fi],
    });

    return {
      ...subscribeReturnData,
      subscribeHandler: streamingEventHandler,
    };
  }

  public unsubscribe(fi: number, subscribeReturnData: SubscribeReturnData) {
    const listenersCount = this.idFIToListenersCount.get(fi) ?? 0;
    const updatedListenersCount = Math.max(listenersCount - 1, 0);
    const { subscribeHandler } = subscribeReturnData;

    this.idFIToListenersCount.set(fi, updatedListenersCount);

    if (updatedListenersCount === 0) {
      this.idFIToLines.delete(fi);
      this.idFIToListenersCount.delete(fi);
    }

    StreamingService.unsubscribe(
      { fi: [fi], entity: EntityType.AvailableOrderBookEntity },
      subscribeReturnData
    );

    if (subscribeHandler) {
      StreamingService.removeClientListener(
        EntityType.OrderBookEntity,
        subscribeHandler
      );
      StreamingService.removeClientListener(
        EntityType.OrderBookWithYieldEntity,
        subscribeHandler
      );
    }
  }

  public getInitialData(fi: number): OrderBookLine[] {
    return this.getLines(fi);
  }

  private getLines(fi: number): OrderBookLine[] {
    return Array.from(this.idFIToLines.get(fi) ?? new Map(), ([, value]) => ({
      ...value,
    }));
  }

  private createStreamingEventHandler(idFi: number) {
    return (message: MessageUnitedType) => {
      message.data.forEach((obj) => {
        this.processOrderbookEntity(obj as Entity);
      });

      this.emit('update', {
        idFI: idFi,
        lines: this.getLines(idFi),
      });
    };
  }

  private processOrderbookEntity(orderbook: Entity) {
    if (!this.idFIToLines.has(orderbook.IdFI)) {
      // это данные другого фин. инструмента
      return;
    }

    const lines: LineMap = this.idFIToLines.get(orderbook.IdFI) ?? new Map();

    if (orderbook.Flags === InitFlagType.IsInit) {
      let byPrice = true;

      // логика тут такая - если lineID = -1
      // то стакан формируется по ценам
      // иначе апдейты идут по lineID
      if (orderbook.Lines && orderbook.Lines.some((line) => line.LineId > -1)) {
        byPrice = false;
      }

      if (orderbook.Lines && byPrice) {
        orderbook.Lines.forEach((line) => {
          lines.set(line.Price, { Yield: 0, ...line });
        });
      } else if (orderbook.Lines) {
        orderbook.Lines.forEach((line) => {
          lines.set(line.LineId, { Yield: 0, ...line });
        });
      } else {
        // Скорее всего, если апдейт пришел пустой, то заполняем всё нулями
        for (let i = 0; i < this.maxLines; i++) {
          lines.set(i, {
            Price: 0,
            BuyQty: 0,
            SellQty: 0,
            LineId: i,
            Revision: 0n,
            Yield: 0,
          });
        }
      }
    } else {
      orderbook.Lines.forEach((line) => {
        if (line.LineId === -1) {
          // удаление
          if (
            lines.has(line.Price) &&
            line.BuyQty === 0 &&
            line.SellQty === 0
          ) {
            lines.delete(line.Price);
          } else if (lines.has(line.OldPrice)) {
            // мерж цены
            lines.delete(line.OldPrice);
            lines.set(line.Price, { Yield: 0, ...line });
          } else {
            // вставка
            lines.set(line.Price, { Yield: 0, ...line });
          }
        } else {
          const localLine = lines.get(line.LineId);

          if (localLine && line.Revision > localLine?.Revision) {
            localLine.Price = line.Price;
            localLine.BuyQty = line.BuyQty;
            localLine.SellQty = line.SellQty;
            localLine.Revision = line.Revision;
          }
        }
      });
    }
  }
}

export const orderbookService = new OrderBookService();
