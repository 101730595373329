import React, { FC, memo, useMemo } from 'react';

import { context } from './context';

import { OrderBookContext } from './types/OrderBookContext';

export const OrderBookContextProvider: FC<OrderBookContext> = memo(
  ({ children, ...props }) => {
    const value = useMemo<OrderBookContext>(() => props, [props]);

    return <context.Provider value={value}>{children}</context.Provider>;
  }
);
