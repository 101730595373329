import cn from 'classnames';
import React, { ReactNode } from 'react';

import layoutMobileStyles from '@terminal/common/styles/mobile/layout.module.css';

interface Props {
  children: ReactNode;
  mobile?: boolean;
}

export const ScreenResponsiveContainer = ({ children, mobile }: Props) => (
  <div
    className={cn({
      [layoutMobileStyles.main]: mobile,
    })}
  >
    {children}
  </div>
);
