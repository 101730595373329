import { QuantityType } from '../client/entities';

import { NewOrderType, OrderItem } from '../../types/order';

type ReturnType = NewOrderType['idQuantityType'];

/**
 * @returns тип количества лотов (idQuantityType) для редактирования заявки
 * */
export function getEditOrderIdQuantityType(order: OrderItem): ReturnType {
  const { idQuantityType, openQuantity, rest } = order;

  if (idQuantityType === QuantityType.ICE && rest <= openQuantity) {
    return QuantityType.QTY;
  }

  return idQuantityType;
}
