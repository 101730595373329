import isNumber from 'lodash/isNumber';
import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';

export function useQuote(idFI) {
  const { useQuotes, useFinInfoExt } = useAlfaDirectContext();

  const quote = useQuotes(idFI);
  const quoteData = quote[idFI];

  const finInfoExt = useFinInfoExt(idFI);
  const finInfoExtByIdFi = finInfoExt[idFI];

  const dayPercentChange = useMemo(() => {
    if (
      quoteData &&
      finInfoExtByIdFi &&
      isNumber(quoteData.last) &&
      isNumber(finInfoExtByIdFi.prevQuote)
    ) {
      //Считаем процент изменения текущей цены от входящей
      return (
        ((quoteData.last - finInfoExtByIdFi.prevQuote) /
          (finInfoExtByIdFi.prevQuote || 1)) *
        100
      );
    }

    return null;
  }, [finInfoExtByIdFi, quoteData]);

  return {
    dayPercentChange,
    quoteData,
  };
}
