import { IMenuContext } from '@blueprintjs/table';
import cn from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { ArrowDownTriangleSIcon } from '@alfalab/icons-glyph/ArrowDownTriangleSIcon';
import { ArrowUpTriangleSIcon } from '@alfalab/icons-glyph/ArrowUpTriangleSIcon';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { AlfaDirectProvider } from '@terminal/alfadirect/provider/react';
import { ContextMenu } from '@terminal/common/features-DEPRECATED/Table';
import {
  BlueprintTable,
  Cell,
  CellRenderProps,
} from '@terminal/common/ui/Table';
import { ActionSources } from '@terminal/core/constants/analytics';
import { UNCANCELLABLE_ORDERS_TYPE } from '@terminal/core/constants/orders';
import { disallowOrderEditByOrderTypeSet } from '@terminal/core/constants/orderTable';
import { getDefaultOrderTableColumnSetting } from '@terminal/core/constants/tableProps';
import { useDoubleRowMode } from '@terminal/core/hooks/useDoubleRowMode';
import { useFilterTable } from '@terminal/core/hooks/useFilterTable';
import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { useTableColumns } from '@terminal/core/hooks/useTableColumns';
import {
  trackOrderEditSource,
  trackOrderEditType,
} from '@terminal/core/lib/analytics';
import { OrderStatus, OrderType } from '@terminal/core/lib/client/entities';
import { getInstrumentId } from '@terminal/core/lib/domain/getInstrumentId';
import {
  getMinority,
  getStringDate,
  getStringTime,
} from '@terminal/core/lib/format';
import { getOrderDisplayPrice } from '@terminal/core/lib/formulas';
import { useTradingService } from '@terminal/core/lib/services/trading/useTradingService';
import { MarketBoardItem, ObjectItem } from '@terminal/core/types/core';
import {
  Filter,
  OrderTableProps,
  Sort,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';
import {
  LifeTimeMap,
  OrderDirectionMap,
  OrderItem,
  OrderStatusMap,
} from '@terminal/core/types/order';
import { SubAccountRazdelItem } from '@terminal/core/types/subAccountRazdel';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { AvailableRazdels } from '../../../../features/AccountFilters';
import { CancelOrderModal, EditOrderModal } from '../../../../features/Orders';
import { SymbolCell } from '../../../../features/SymbolCell';
import { TradingCertificateLock } from '../../../../features/TradingCertificateLock';
import {
  defaultColumnFilterRender,
  useWidgetContext,
} from '../../../../shared';
import { Header } from '../../ui';

import { useSaveColumnSettings } from '../../../../shared/hooks/useSaveColumnSettings';
import { useSelectedAccount } from '../../../../shared/hooks/useSelectedAccount';
import { useSortTable } from '../../../../shared/hooks/useSortTable';

import styles from './OrderTable.module.css';

interface OrderRow {
  idFi: number;
  executed: number;
  displayPrice?: number;
  type: string;
  withdrawTime: Date;
  displayStatus?: string;
  //TODO: Удалить, когда не понадобится обратная совместимость
  status: string;
  acceptTime: Date;
  acceptDate: Date;
  order: OrderItem;
  object?: ObjectItem;
  market: MarketBoardItem;
  direction?: string;
  subAccountRazdel?: SubAccountRazdelItem;
  shortDescription?: string;
  nameObject?: string;
}

export function OrderTable({
  nodeId,
  tableProps,
  link,
  idFi,
}: OrderTableProps) {
  // Если нет сохраненного конфига, значит это первый выбор
  const orderStatus = useMemo(
    () => tableProps?.orderStatus || [],
    [tableProps?.orderStatus]
  );
  const [isOpenColumnSettings, setIsOpenColumnSettings] = useState(false);

  const { updateNode, addNotification, useSettings, onWidgetLoad } =
    useWidgetContext();
  const alfaDirectProps = useAlfaDirectContext();
  const {
    accounts: clientAccounts,
    finInstrumentsTable,
    useOrders,
    objectsTable,
    marketBoardsTable,
    subAccountRazdels,
  } = alfaDirectProps;

  const orders = useOrders();
  const {
    defaultValues: { selectedDisplayInstrumentType, alwaysConfirmOrders },
  } = useSettings();

  const defaultOrderTableColumnSetting = useMemo(
    () => getDefaultOrderTableColumnSetting(selectedDisplayInstrumentType),
    [selectedDisplayInstrumentType]
  );

  const columnsSetting = useTableColumns(
    tableProps?.columns,
    defaultOrderTableColumnSetting
  );

  //Фильтруем заявки, которые относятся к счетам с типом Инвесткопилка
  const clientOrders = useMemo(
    //clientAccounts - счета пользователя, но без Инвесткопилок
    () =>
      orders.filter((order) =>
        clientAccounts.some((account) => account.idAccount === order.idAccount)
      ),
    [clientAccounts, orders]
  );

  const { selectedAccount, selectedSubAccounts, selectedSubAccountId } =
    useSelectedAccount(tableProps);

  //Выделяем айдишники выбранных субаккаунтов
  const selectedSubAccountIds = useMemo(() => {
    return selectedSubAccounts.map((account) => {
      return subAccountRazdels.find(
        (subRazdel) => subRazdel.codeSubAccount === account
      )?.idSubAccount;
    });
  }, [selectedSubAccounts, subAccountRazdels]);

  // Фильтруем заявки по статусу и выбранному счету
  const filteredOrders = useMemo(() => {
    const selectedOrderByAcc = clientOrders.filter((order) =>
      selectedSubAccountIds.includes(order.idSubAccount)
    );

    if (orderStatus.length > 0) {
      return selectedOrderByAcc.filter((order) =>
        orderStatus.includes(order.idOrderStatus)
      );
    } else {
      return selectedOrderByAcc;
    }
  }, [clientOrders, orderStatus, selectedSubAccountIds]);

  const computedData = useMemo(() => {
    let availableAccountRazdel: AvailableRazdels = {};
    let availableSubAccountRazdel: AvailableRazdels = {};

    const data = filteredOrders.map((order) => {
      const entities = {
        idFi: getInstrumentId(
          order.idObject,
          order.idMarketBoard,
          finInstrumentsTable
        ),
        order,
        object: objectsTable.get('idObject', order.idObject),
        market: marketBoardsTable.get('idMarketBoard', order.idMarketBoard),
        direction: OrderDirectionMap.get(order.buySell),
        subAccountRazdel: subAccountRazdels.find(
          (razdel) => razdel.idRazdel === order.idRazdel
        ),
      };

      let DisplayPrice = getOrderDisplayPrice(order);

      const fields = {
        executed: order.quantity - order.rest,
        rest: order.rest,
        displayPrice: DisplayPrice ?? 0,
        type: OrderType[order.idOrderType],
        withdrawTime: order.withdrawTime,
        //TODO: Удалить, когда не понадобится обратная совместимость
        status: OrderStatus[order.idOrderStatus],
        displayStatus: OrderStatusMap.get(order.idOrderStatus),
        acceptTime: order.acceptTime,
        acceptDate: order.acceptTime,
        shortDescription: `${
          entities.object?.nameObject
        } ${entities.object?.idObjectType?.name?.toLowerCase()}${entities.object?.idObjectType?.name?.slice(
          1
        )}`,
        nameObject: entities.object?.nameObject,
      };

      const razdelByCode = entities.subAccountRazdel?.codeSubAccount ?? '';
      const razdelById = entities.subAccountRazdel?.idAccount ?? '';

      if (!availableSubAccountRazdel[razdelByCode]) {
        availableSubAccountRazdel[razdelByCode] = 1;
      } else {
        availableSubAccountRazdel[razdelByCode] += 1;
      }

      if (!availableAccountRazdel[razdelById]) {
        availableAccountRazdel[razdelById] = 1;
      } else {
        availableAccountRazdel[razdelById] += 1;
      }

      return { ...entities, ...fields };
    });

    let filteredData: typeof data = [];

    if (!isEmpty(selectedSubAccounts)) {
      filteredData = data.filter((row) =>
        selectedSubAccounts.includes(
          String(row.subAccountRazdel?.codeSubAccount)
        )
      );
    }

    return filteredData;
  }, [
    filteredOrders,
    selectedSubAccounts,
    finInstrumentsTable,
    objectsTable,
    marketBoardsTable,
    subAccountRazdels,
  ]);

  const updateNodeHandler = useCallback(
    (config) => {
      nodeId && updateNode(nodeId, config);
    },
    [nodeId, updateNode]
  );

  const saveFilter = useCallback(
    (filter?: Filter) => {
      updateNodeHandler({ tableProps: { filter } });
    },
    [updateNodeHandler]
  );

  const filterState = useMemo(() => tableProps?.filter, [tableProps?.filter]);
  const { filteredData, filter, onFilter } = useFilterTable({
    data: computedData,
    filterState,
    saveFilter,
  });

  const sort = useMemo(() => tableProps?.sort, [tableProps?.sort]);
  const setSort = useCallback(
    (sort?: Sort) => {
      updateNodeHandler({ tableProps: { sort } });
    },
    [updateNodeHandler]
  );
  const { sortedData, onSort } = useSortTable({
    data: filteredData,
    sort,
    setSort,
  });
  const [isDoubleRowMode, switchDoubleRowMode] = useDoubleRowMode(
    Widget.ORDERS,
    columnsSetting,
    defaultOrderTableColumnSetting,
    updateNodeHandler
  );

  const prevSelectedDisplayInstrumentType = usePrevious(
    selectedDisplayInstrumentType
  );

  useEffect(() => {
    if (prevSelectedDisplayInstrumentType !== selectedDisplayInstrumentType) {
      updateNodeHandler({
        tableProps: {
          columns: columnsSetting.map((column) => {
            if (
              column.key === TableColumnKey.SymbolObject ||
              column.key === TableColumnKey.NameObject
            ) {
              return {
                ...column,
                key:
                  selectedDisplayInstrumentType === 'name'
                    ? TableColumnKey.NameObject
                    : TableColumnKey.SymbolObject,
              };
            }

            return column;
          }),
        },
      });

      //Сбрасываем не совпадающие по ключу фильтры при переключении отображания тикера
      if (
        (filterState?.key === TableColumnKey.SymbolObject &&
          selectedDisplayInstrumentType === 'name') ||
        (filterState?.key === TableColumnKey.NameObject &&
          selectedDisplayInstrumentType !== 'name')
      ) {
        onFilter();
      }
    }
  }, [
    updateNodeHandler,
    selectedDisplayInstrumentType,
    prevSelectedDisplayInstrumentType,
    columnsSetting,
    filterState?.key,
    onFilter,
  ]);

  const saveColumnSetting = useSaveColumnSettings(nodeId);

  const cellRender: CellRenderProps<OrderRow> = (
    rowIndex,
    columnIndex,
    columnKey,
    data,
    setHoverIndex,
    columnName,
    isDoubleRowMode
  ) => {
    const {
      object,
      direction,
      order,
      acceptTime,
      acceptDate,
      withdrawTime,
      shortDescription,
      market,
    } = data[rowIndex];

    const secondRow: TableColumnSetting | undefined = isDoubleRowMode
      ? columnsSetting.find(({ key }) => key === columnKey)?.secondRow
      : undefined;

    const doubleRowCellRender = (
      secondRowColumnKey: TableColumnKey | undefined,
      firstRowColumnKey: TableColumnKey,
      firstRowContent: React.ReactElement
    ) => (
      <div className={styles.cellDoubleRow}>
        <div className={styles.firstRowContent}>{firstRowContent}</div>
        <div className={styles.secondRowWrapper}>
          {firstRowColumnKey === secondRowColumnKey ? (
            firstRowContent
          ) : (
            <>
              {secondRowColumnKey && (
                <div className={styles.secondRowContent}>
                  {cellRender(
                    rowIndex,
                    columnIndex,
                    secondRowColumnKey,
                    data,
                    setHoverIndex,
                    columnKey
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );

    switch (columnKey) {
      case TableColumnKey.ShortDescription:
        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <div className={styles.symbolCellSecondRow}>
                  {shortDescription}
                </div>
              )
            ) : (
              <div className={styles.symbolCellSecondRow}>
                {shortDescription}
              </div>
            )}
          </Cell>
        );
      case TableColumnKey.NameObject:
        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              <div className={styles.symbolCellDoubleRow}>
                <SymbolCell
                  nameMarketBoard={market.nameMarketBoard}
                  idObject={object?.idObject}
                  symbolObject={object?.symbolObject}
                  onlyIcon
                />
                <div className={styles.symbolCellDoubleRowRightSide}>
                  {doubleRowCellRender(
                    secondRow?.key,
                    columnKey,
                    <SymbolCell
                      nameMarketBoard={market.nameMarketBoard}
                      idObject={object?.idObject}
                      symbolObject={object?.symbolObject}
                      noIcon
                    />
                  )}
                </div>
              </div>
            ) : (
              <SymbolCell
                nameMarketBoard={market.nameMarketBoard}
                idObject={object?.idObject}
                symbolObject={object?.symbolObject}
              />
            )}
          </Cell>
        );
      case TableColumnKey.ReleaseNameObject:
        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            <span>{get(data[rowIndex], columnKey)}</span>
          </Cell>
        );
      case TableColumnKey.SymbolObject:
        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              <div className={styles.symbolCellDoubleRow}>
                <SymbolCell
                  nameMarketBoard={market.nameMarketBoard}
                  idObject={object?.idObject}
                  symbolObject={object?.symbolObject}
                  onlyIcon
                />
                <div className={styles.symbolCellDoubleRowRightSide}>
                  {doubleRowCellRender(
                    secondRow?.key,
                    columnKey,
                    <SymbolCell
                      nameMarketBoard={market.nameMarketBoard}
                      idObject={object?.idObject}
                      symbolObject={object?.symbolObject}
                      noIcon
                    />
                  )}
                </div>
              </div>
            ) : (
              <SymbolCell
                nameMarketBoard={market.nameMarketBoard}
                idObject={object?.idObject}
                symbolObject={object?.symbolObject}
              />
            )}
          </Cell>
        );
      //TODO: fix implement (lifeTime, order.NumEDocument)
      case TableColumnKey.AcceptTime:
        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <span>{getStringTime(acceptTime)}</span>
              )
            ) : (
              <span>{getStringTime(acceptTime)}</span>
            )}
          </Cell>
        );
      case TableColumnKey.AcceptDate:
        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <span>{getStringDate(acceptDate)}</span>
              )
            ) : (
              <span>{getStringDate(acceptDate)}</span>
            )}
          </Cell>
        );
      case TableColumnKey.WithdrawTime:
        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <span>{getStringDate(withdrawTime)}</span>
              )
            ) : (
              <span>{getStringDate(withdrawTime)}</span>
            )}
          </Cell>
        );
      case TableColumnKey.CodeSubAccount:
      case TableColumnKey.Quantity:
      case TableColumnKey.Executed:
      case TableColumnKey.Rest:
      case TableColumnKey.Type:
      case TableColumnKey.DisplayStatus:
      case TableColumnKey.OrderNumEDocument:
      case TableColumnKey.OrderLogin:
      case TableColumnKey.MarketNameMarketBoard:
      case TableColumnKey.OrderClientNumEDocument:
        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
            leftAlign
          >
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <span>{get(data[rowIndex], columnKey)}</span>
              )
            ) : (
              <span>{get(data[rowIndex], columnKey)}</span>
            )}
          </Cell>
        );
      case TableColumnKey.LifeTime:
        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <span>
                  {LifeTimeMap.get(data[rowIndex]?.order?.idLifeTime)}
                </span>
              )
            ) : (
              <span>{LifeTimeMap.get(data[rowIndex]?.order?.idLifeTime)}</span>
            )}
          </Cell>
        );
      case TableColumnKey.Direction: {
        const isBuy = direction === 'Покупка';
        const Icon = isBuy ? ArrowUpTriangleSIcon : ArrowDownTriangleSIcon;

        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <div
                  className={cn(
                    styles.directionCell,
                    isBuy ? styles.buy : styles.sell
                  )}
                >
                  {direction}
                  <Icon width={14} height={14} />
                </div>
              )
            ) : (
              <div
                className={cn(
                  styles.directionCell,
                  isBuy ? styles.buy : styles.sell
                )}
              >
                {direction}
                <Icon width={14} height={14} />
              </div>
            )}
          </Cell>
        );
      }
      case TableColumnKey.DisplayPrice:
        const displayPrice = get(data[rowIndex], columnKey);
        //Подсчитываем минорные единицы
        const priceMinority = getMinority(displayPrice);

        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <>
                  {order.idOrderType === OrderType.MKT ? (
                    'По рынку'
                  ) : (
                    <Amount.Pure
                      value={(displayPrice ?? 0) * priceMinority}
                      minority={priceMinority}
                      view="withZeroMinorPart"
                    />
                  )}
                </>
              )
            ) : (
              <>
                {order.idOrderType === OrderType.MKT ? (
                  'По рынку'
                ) : (
                  <Amount.Pure
                    value={(displayPrice ?? 0) * priceMinority}
                    minority={priceMinority}
                    view="withZeroMinorPart"
                  />
                )}
              </>
            )}
          </Cell>
        );
      case TableColumnKey.StopPrice:
      case TableColumnKey.LimitPrice:
        const value = get(data[rowIndex], columnKey);
        //Подсчитываем минорные единицы
        const minority = getMinority(value);

        return (
          <Cell
            onClick={() => onRowClick(data[rowIndex])}
            onMouseEnter={() => setHoverIndex(rowIndex)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            {isDoubleRowMode ? (
              doubleRowCellRender(
                secondRow?.key,
                columnKey,
                <Amount.Pure
                  value={value * minority}
                  minority={minority}
                  view="withZeroMinorPart"
                />
              )
            ) : (
              <Amount.Pure
                value={value * minority}
                minority={minority}
                view="withZeroMinorPart"
              />
            )}
          </Cell>
        );

      default:
        return <></>;
    }
  };

  const [orderToDelete, setOrderToDelete] = useState<OrderItem | null>(null);
  const onRowClick = useCallback(
    (row: OrderRow) => {
      const { idFi, object } = row;

      if (link && nodeId && object) {
        updateNode(
          nodeId,
          { idFi, symbol: object.symbolObject },
          object.symbolObject
        );
      }
    },
    [link, nodeId, updateNode]
  );

  const selectedRowIndex = useMemo(() => {
    if (idFi) {
      return [
        ...(sortedData as any[]).reduce((acc, row, index) => {
          if (row.idFi === idFi) {
            return [...acc, index];
          } else {
            return acc;
          }
        }, [] as any[]),
      ];
    }
  }, [idFi, sortedData]);

  const onOrderResponse = useCallback(() => {
    setIsOpenCancelModal(false);
    setIsLoadingCancelModal(false);
    setIsOpenEditModal(false);
    setIsLoadingEditModal(false);
  }, []);

  const onOrderError = useCallback(
    (message: string) => {
      addNotification({
        id: new Date().toString(),
        badge: 'negative',
        title: 'Ошибка',
        text: message,
      });

      setIsOpenCancelModal(false);
      setIsLoadingCancelModal(false);
      setIsOpenEditModal(false);
      setIsLoadingEditModal(false);
    },
    [addNotification]
  );

  const { replaceOrder, cancelOrder } = useTradingService(
    onOrderError,
    onOrderResponse
  );

  //Состояния для модальных окон отмены и редактирования заявок
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isLoadingCancelModal, setIsLoadingCancelModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isLoadingEditModal, setIsLoadingEditModal] = useState(false);

  useEffect(() => {
    setIsLoadingCancelModal(false);
  }, [orderToDelete]);

  const [orderToEdit, setOrderToEdit] = useState<OrderItem | null>(null);

  useEffect(() => {
    setIsLoadingEditModal(false);
  }, [orderToEdit]);

  useEffect(() => {
    if (nodeId) {
      onWidgetLoad(nodeId);
    }
  }, [nodeId, onWidgetLoad]);

  const hoverActions = useCallback(
    (data: OrderRow, onHover, viewportWidth) => {
      const isAllowEdit = !disallowOrderEditByOrderTypeSet.has(
        data.order.idOrderType
      );

      return (
        <TradingCertificateLock hideMode>
          {UNCANCELLABLE_ORDERS_TYPE.indexOf(data.order.idOrderStatus) >= 0 ? (
            <></>
          ) : (
            <div
              className={styles.hoverActions}
              style={{
                left: viewportWidth ? viewportWidth - 82 : 0,
                width: 82,
              }}
              onMouseEnter={() => onHover(true)}
              onMouseLeave={() => onHover(false)}
            >
              <div className={styles.iconsWrapper}>
                <Tooltip
                  targetClassName={styles.tooltipTarget}
                  contentClassName={styles.tooltipContent}
                  content="Снять заявку"
                  trigger="hover"
                  position="top"
                  preventOverflow={false}
                >
                  <CrossCircleMIcon
                    width={18}
                    height={18}
                    color="currentColor"
                    className={styles.deleteIcon}
                    onClick={(e: { stopPropagation: () => void }) => {
                      e.stopPropagation();

                      if (alwaysConfirmOrders) {
                        setOrderToDelete(data.order);
                        setIsOpenCancelModal(true);
                      } else {
                        cancelOrder(data.order);
                      }

                      onHover(false);
                    }}
                  />
                </Tooltip>

                {isAllowEdit && (
                  <Tooltip
                    targetClassName={styles.tooltipTarget}
                    contentClassName={styles.tooltipContent}
                    content="Редактировать заявку"
                    trigger="hover"
                    position="top"
                    preventOverflow={false}
                  >
                    <PencilMIcon
                      width={18}
                      height={18}
                      color="currentColor"
                      className={styles.deleteIcon}
                      onClick={(e: { stopPropagation: () => void }) => {
                        e.stopPropagation();
                        setOrderToEdit(data.order);
                        setIsOpenEditModal(true);
                        trackOrderEditSource(ActionSources.ISSUES);
                        trackOrderEditType(data.order.idOrderType);
                        onHover(false);
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          )}
        </TradingCertificateLock>
      );
    },
    [alwaysConfirmOrders, cancelOrder]
  );

  const bodyContextMenuRenderer = useCallback(
    (context: IMenuContext) => {
      const target = context.getTarget();
      const { rows } = target;

      if (rows) {
        const index = rows[0];
        const data = (sortedData as any[])[index];
        const { idFi, order } = data;

        const isAllowEdit = !disallowOrderEditByOrderTypeSet.has(
          data.order.idOrderType
        );

        // TODO: Требуется доработка таблички или меню
        // blueprint v4 не видит контекста для этого был сделан этот костыль
        // https://github.com/palantir/blueprint/issues/1121
        return (
          <AlfaDirectProvider value={alfaDirectProps}>
            <ContextMenu
              idFi={idFi}
              widget={Widget.ORDERS}
              nodeId={nodeId}
              setIsOpenColumnSettings={setIsOpenColumnSettings}
              link={link}
              isAllowEditOrder={isAllowEdit}
              onEditOrder={() => {
                if (
                  UNCANCELLABLE_ORDERS_TYPE.indexOf(order.idOrderStatus) >= 0
                ) {
                  return;
                } else {
                  setOrderToEdit(order);
                  setIsOpenEditModal(true);
                }
              }}
              onDeleteOrder={() => {
                if (
                  UNCANCELLABLE_ORDERS_TYPE.indexOf(order.idOrderStatus) >= 0
                ) {
                  return;
                } else {
                  setOrderToDelete(order);
                  setIsOpenCancelModal(true);
                }
              }}
            />
          </AlfaDirectProvider>
        );
      } else {
        return <></>;
      }
    },
    [link, nodeId, sortedData, alfaDirectProps]
  );

  const onCloseCancelModal = useCallback(() => {
    setIsOpenCancelModal(false);
    setOrderToDelete(null);
  }, []);

  const onCloseEditModal = useCallback(() => {
    setOrderToEdit(null);
  }, []);

  const onCancelOrder = useCallback(() => {
    if (alwaysConfirmOrders) {
      setOrderToDelete(orderToEdit);
      setIsOpenCancelModal(true);
    } else {
      orderToEdit && cancelOrder(orderToEdit);
    }
  }, [alwaysConfirmOrders, cancelOrder, orderToEdit]);

  const setIsLoading = useCallback(
    (state: boolean) => setIsLoadingCancelModal(state),
    []
  );

  return (
    <div className={styles.container}>
      <Header
        nodeId={nodeId}
        setIsOpenColumnSettings={setIsOpenColumnSettings}
        isDoubleRowMode={isDoubleRowMode}
        switchDoubleRowMode={switchDoubleRowMode}
        isOpenColumnSettings={isOpenColumnSettings}
        columnsSetting={columnsSetting}
        defaultOrderTableColumnSetting={defaultOrderTableColumnSetting}
        selectedSubAccounts={selectedSubAccounts}
        orders={filteredOrders}
        orderStatus={orderStatus}
        selectedDisplayInstrumentType={selectedDisplayInstrumentType}
      />
      <BlueprintTable<OrderRow>
        filter={filter}
        onFilter={onFilter}
        sort={sort}
        onSort={onSort}
        data={sortedData as any[]}
        columnSetting={columnsSetting}
        cellRender={cellRender}
        columnFilterRender={defaultColumnFilterRender}
        onReorder={saveColumnSetting}
        onChangeWidth={saveColumnSetting}
        defaultSelectedRows={selectedRowIndex}
        hoverActions={hoverActions}
        isDoubleRowMode={isDoubleRowMode}
        bodyContextMenuRenderer={bodyContextMenuRenderer}
      />

      <CancelOrderModal
        isOpen={isOpenCancelModal}
        cancelOrder={cancelOrder}
        order={orderToDelete}
        onCloseRequest={onCloseCancelModal}
        isLoading={isLoadingCancelModal}
        setIsLoading={setIsLoading}
      />

      <EditOrderModal
        isOpen={isOpenEditModal}
        order={orderToEdit}
        onClose={onCloseEditModal}
        selectedAccount={selectedAccount}
        onCancelOrder={onCancelOrder}
        selectedSubAccountId={selectedSubAccountId}
        replaceOrder={replaceOrder}
        isLoading={isLoadingEditModal}
        setIsLoading={setIsLoadingEditModal}
      />
    </div>
  );
}
