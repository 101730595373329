import cn from 'classnames';
import { memo, useMemo } from 'react';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { BanknotesMIcon } from '@alfalab/icons-glyph/BanknotesMIcon';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

import { isNotNull } from '@terminal/core/lib/isNotNull';
import { getDisplayMode } from '@terminal/core/lib/trade/getDisplayMode';
import { LimitsProps } from '@terminal/core/types/layout';
import { PositionItem } from '@terminal/core/types/position';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';

import { useWidgetContext } from '../../../../shared';
import { PositionBlock } from '../../../PositionBlock';
import { MEDIUM_NODE, SMALL_NODE } from '../../lib/sizes';
import { LimitsBlock } from '../LimitsBlock';
import { NPUBlock } from '../NPUBlock';

import styles from './MeasureContent.module.css';

interface MeasureContentProps {
  id: string;
  nodeId?: string;
  position?: PositionItem;
  tradeLimitsLong: TradeLimitsResult | null;
  tradeLimitsShort: TradeLimitsResult | null;
  idFi: number;
  displaySettings: LimitsProps;
}

export const MeasureContent = memo(
  ({
    id,
    nodeId,
    position,
    tradeLimitsLong,
    tradeLimitsShort,
    idFi,
    displaySettings,
  }: MeasureContentProps) => {
    const { getNode } = useWidgetContext();

    const nodeWidth = useMemo(
      () => getNode(nodeId)?.getRect().width || 0,
      [getNode, nodeId]
    );

    const { allowPosition, allowLimits, allowNPU } = getDisplayMode({
      displaySettings,
      tradeLimitsLong,
      tradeLimitsShort,
      position,
    });

    if (nodeWidth <= SMALL_NODE) {
      //Отображаем в виде тултипа
      return (
        <TooltipDesktop
          position="top"
          trigger="hover"
          targetClassName={styles.tooltipTarget}
          content={
            <div className={cn(styles.wrapper, styles.tooltipContent)} id={id}>
              {position && (allowPosition || allowNPU) && (
                <div className={cn(styles.positionWrapper, styles.row)}>
                  {allowPosition && <PositionBlock position={position} />}
                  {allowNPU && (
                    <NPUBlock
                      position={position}
                      displaySetting={displaySettings.showNPU}
                    />
                  )}
                </div>
              )}
              {isNotNull(tradeLimitsLong) &&
                isNotNull(tradeLimitsShort) &&
                allowLimits && (
                  <LimitsBlock
                    tradeLimitsLong={tradeLimitsLong}
                    tradeLimitsShort={tradeLimitsShort}
                    idFi={idFi}
                    tooltipView
                    displaySetting={displaySettings.showLimits}
                  />
                )}
            </div>
          }
          fallbackPlacements={['bottom']}
        >
          <div className={styles.tooltipWrapper}>
            {position && allowNPU ? (
              <BriefcaseMIcon width={18} height={18} className={styles.icon} />
            ) : (
              <BanknotesMIcon width={18} height={18} className={styles.icon} />
            )}
            {position && allowNPU ? (
              <NPUBlock
                position={position}
                block
                displaySetting={displaySettings.showNPU}
              />
            ) : (
              <Typography.Text
                view="secondary-large"
                className={cn(styles.amount, styles.growText)}
              >
                Лимиты
              </Typography.Text>
            )}
            <InformationCircleLineMIcon
              width={18}
              height={18}
              className={styles.icon}
            />
          </div>
        </TooltipDesktop>
      );
    }

    if (nodeWidth > SMALL_NODE && nodeWidth <= MEDIUM_NODE) {
      //Двустрочное отображение
      return (
        <div className={styles.wrapper} id={id}>
          {position && (allowPosition || allowNPU) && (
            <div className={styles.positionWrapper}>
              {allowPosition && <PositionBlock position={position} />}
              {allowNPU && (
                <NPUBlock
                  position={position}
                  displaySetting={displaySettings.showNPU}
                />
              )}
            </div>
          )}
          {isNotNull(tradeLimitsLong) &&
            isNotNull(tradeLimitsShort) &&
            allowLimits && (
              <LimitsBlock
                tradeLimitsLong={tradeLimitsLong}
                tradeLimitsShort={tradeLimitsShort}
                idFi={idFi}
                displaySetting={displaySettings.showLimits}
              />
            )}
        </div>
      );
    }

    //Для широких виджетов, в одну строку
    return (
      <div className={cn(styles.wrapper, styles.row)} id={id}>
        {position && allowPosition && <PositionBlock position={position} />}
        {isNotNull(tradeLimitsLong) &&
          isNotNull(tradeLimitsShort) &&
          allowLimits && (
            <LimitsBlock
              tradeLimitsLong={tradeLimitsLong}
              tradeLimitsShort={tradeLimitsShort}
              idFi={idFi}
              displaySetting={displaySettings.showLimits}
            />
          )}
        {position && allowNPU && (
          <NPUBlock
            position={position}
            displaySetting={displaySettings.showNPU}
          />
        )}
      </div>
    );
  }
);

MeasureContent.displayName = 'MeasureContent';
