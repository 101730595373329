import { FormikHelpers, useFormik } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

import { useSignSmsDialog } from '@terminal/lk/legacy/components/SmsSignDialog';

import { sendContestParticipation } from '../api/sendContestParticipation';
import { isRegistrationFatal } from '../lib/isRegistrationFatal';
import {
  setContestErrors,
  validateContestForm,
} from '../lib/validateContestForm';

import { ContestAccount } from '../model/types/ContestAccount';
import { ContestFormValues } from '../model/types/ContestFormValues';

interface Props {
  contestId: number;
  accounts: ContestAccount[];
  initEmail: string;
  initNickname?: string;
}

export const useContestForm = ({
  contestId,
  accounts,
  initEmail,
  initNickname,
}: Props) => {
  const formikHelpers = useRef<FormikHelpers<ContestFormValues> | null>(null);

  const smsSign = useSignSmsDialog({
    initAction: sendContestParticipation,
    successMsg: {
      title: `Успех!`,
    },
    errorMsg: {
      title: 'Провал',
    },
  });

  useEffect(() => {
    if (formikHelpers.current && smsSign.operation) {
      const error = {
        code: smsSign.operation.code,
        message: smsSign.operation.message,
      };
      const nickSuggest = smsSign.operation.data;

      formikHelpers.current.setErrors(
        setContestErrors(error, nickSuggest || [])
      );
    }
  }, [smsSign.operation]);

  const handleRegister = useCallback(
    async (
      values: ContestFormValues,
      helpers: FormikHelpers<ContestFormValues>
    ) => {
      formikHelpers.current = helpers;

      if (contestId) {
        await smsSign.create({
          contestId: contestId,
          nickName: values.nick,
          treaty: parseInt(values.account),
          email: values.email,
        });
      }
    },
    [contestId, smsSign]
  );

  const form = useFormik<ContestFormValues>({
    initialValues: {
      account: '',
      nick: initNickname ? initNickname : '',
      email: initEmail,
    },
    initialTouched: {
      account: true,
    },
    onSubmit: handleRegister,
    validate: validateContestForm,
  });

  const selectedAccount = accounts.find(
    (acc) => acc.treaty.toString() === form.values.account
  );

  const accountWithBlockedActives = selectedAccount?.containsBlockedActives;

  const fatalError =
    smsSign.createError &&
    smsSign.error &&
    isRegistrationFatal(smsSign.error.code);

  return {
    form,
    smsSign,
    accountWithBlockedActives,
    fatalError,
  };
};
