import cn from 'classnames';
import { Typography } from '@alfalab/core-components/typography';

import styles from './Plate.module.css';

interface PlateProps {
  label: string;
  className?: string;
  size?: 'xs' | 's';
}

export const Plate: React.FC<PlateProps> = ({
  label,
  size = 's',
  ...props
}) => (
  <div
    className={cn(styles.plate, props.className, {
      [styles.size_xs]: size === 'xs',
      [styles.size_s]: size === 's',
    })}
  >
    <Typography.Text view="secondary-large" color="secondary">
      {label}
    </Typography.Text>
    {props.children}
  </div>
);
