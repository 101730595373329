import cn from 'classnames';
import { useCallback, useMemo } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownTriangleSIcon } from '@alfalab/icons-glyph/ArrowDownTriangleSIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { SetOrderParams } from '@terminal/core/constants/orderBook';
import { OrderType } from '@terminal/core/lib/client/entities';
import { getAssetUnits, getMinority } from '@terminal/core/lib/format';
import { evaluatePricePosition } from '@terminal/core/lib/orderBook/evaluatePricePosition';
import { TradingStatus } from '@terminal/core/lib/services/finInfoExt/getTradingStatus';
import { ContextMenuProps } from '@terminal/core/types/layout';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { useWidgetContext } from '../../../../../../shared';
import { useOrderBookContext } from '../../../../context';

import { useIsLotCount } from '../../../../../../shared/hooks/useIsLotCount';
import { DefaultQuantity } from '../../../../hooks/useDefaultQuantity';

import styles from './ContextTradeButtons.module.css';

interface Props {
  price: number;
  quantity: number;
  finInfoExt: FinInfoExt;
  upSpreadPrice?: number;
  downSpreadPrice?: number;
  onClose: () => void;
  setOrderParams: (params: SetOrderParams) => void;
  contextMenuProps?: ContextMenuProps;
  defaultQuantity?: DefaultQuantity;
  tradingStatus: TradingStatus;
}

export const ContextTradeButtons = ({
  price,
  quantity,
  finInfoExt,
  downSpreadPrice,
  upSpreadPrice,
  onClose,
  setOrderParams,
  contextMenuProps,
  defaultQuantity,
  tradingStatus,
}: Props) => {
  const { useSettings, useConnectionError } = useWidgetContext();

  const { sendTrallingOrders } = useOrderBookContext();

  const settings = useSettings();
  const orderDimension = settings.defaultValues.orderDimension;
  const isLotCount = useIsLotCount();

  const isConnectionError = useConnectionError();

  const getQuantity = useCallback(() => {
    if (isLotCount) {
      const value = defaultQuantity?.quantity ?? quantity;

      return defaultQuantity || quantity
        ? value * finInfoExt.lot
        : finInfoExt.lot;
    } else {
      return defaultQuantity?.quantity ?? quantity ?? finInfoExt.lot;
    }
  }, [defaultQuantity, finInfoExt.lot, isLotCount, quantity]);

  const isQuantityNotMultipleOfALot = useMemo(
    () => Boolean(finInfoExt) && getQuantity() % finInfoExt.lot !== 0,
    [finInfoExt, getQuantity]
  );

  const isDisabledButtons =
    isConnectionError ||
    isQuantityNotMultipleOfALot ||
    tradingStatus === TradingStatus.CLOSED;

  const quantitySourceHint = useMemo(() => {
    if (defaultQuantity) {
      return 'Отображен объем по умолчанию, указанный в настройках виджета';
    } else if (quantity) {
      return 'Отображен объем, указанный в виджете Торговля';
    } else {
      return 'Отображен объем минимального лота';
    }
  }, [defaultQuantity, quantity]);

  const quantityText = useMemo(() => {
    if (defaultQuantity) {
      return (
        <Typography.Text view="secondary-large">
          <Amount.Pure
            value={
              defaultQuantity.quantity * getMinority(defaultQuantity.quantity)
            }
            minority={getMinority(defaultQuantity.quantity)}
            view="withZeroMinorPart"
          />
          &nbsp;
          {defaultQuantity.assetUnits}
        </Typography.Text>
      );
    } else {
      const value = quantity ?? (isLotCount ? 1 : finInfoExt.lot);
      const minority = getMinority(value);

      return (
        <Typography.Text view="secondary-large">
          {isLotCount ? (
            `${value} ${getAssetUnits(value, orderDimension)}`
          ) : (
            <>
              <Amount.Pure
                value={value * minority}
                minority={minority}
                view="withZeroMinorPart"
              />
              &nbsp;шт
            </>
          )}
        </Typography.Text>
      );
    }
  }, [defaultQuantity, quantity, finInfoExt.lot, isLotCount, orderDimension]);

  const { isAboveSpread, isBelowSpread } = evaluatePricePosition(price, {
    upperSpreadPrice: upSpreadPrice,
    maxBuyPrice: downSpreadPrice,
  });

  const priceDependButton = useMemo(() => {
    //Если выбранная цена ниже спреда
    if (isBelowSpread) {
      return (
        <Dropdown.Item
          content={
            <>
              Продать&nbsp;
              {quantityText}&nbsp;
              <Amount.Pure
                className={styles.amount}
                value={price * getMinority(price)}
                minority={getMinority(price)}
                view="withZeroMinorPart"
              />
              &nbsp;
              <span className={styles.secondaryText}>
                {sendTrallingOrders ? 'TRS' : 'STP'}
              </span>
            </>
          }
          leftIcon={
            <ArrowDownTriangleSIcon
              height={16}
              width={16}
              className={styles.triangleDown}
            />
          }
          className={cn(isDisabledButtons && styles.disabledContextButton)}
          onClick={() => {
            if (!isDisabledButtons) {
              setOrderParams({
                idOrderType: sendTrallingOrders ? OrderType.TRS : OrderType.STP,
                buy: false,
                quantity: getQuantity(),
                stopPrice: price,
                inputPrice: price,
              });
            }

            onClose();
          }}
        />
      );
    }

    if (isAboveSpread) {
      return (
        <Dropdown.Item
          content={
            <>
              Купить&nbsp;
              {quantityText}&nbsp;
              <Amount.Pure
                className={styles.amount}
                value={price * getMinority(price)}
                minority={getMinority(price)}
                view="withZeroMinorPart"
              />
              &nbsp;
              <span className={styles.secondaryText}>
                {sendTrallingOrders ? 'TRS' : 'STP'}
              </span>
            </>
          }
          leftIcon={
            <ArrowDownTriangleSIcon
              height={16}
              width={16}
              className={styles.triangleUp}
            />
          }
          className={cn(isDisabledButtons && styles.disabledContextButton)}
          onClick={() => {
            if (!isDisabledButtons) {
              setOrderParams({
                idOrderType: sendTrallingOrders ? OrderType.TRS : OrderType.STP,
                buy: true,
                quantity: getQuantity(),
                stopPrice: price,
                inputPrice: price,
              });
            }

            onClose();
          }}
        />
      );
    }

    return null;
  }, [
    price,
    quantityText,
    sendTrallingOrders,
    isDisabledButtons,
    onClose,
    setOrderParams,
    getQuantity,
    isAboveSpread,
    isBelowSpread,
  ]);

  return (
    <>
      {/* Покупка/продажа в зависимости от выбранной цены (ниже/выше/внутри спреда) */}
      <Dropdown.Block>
        <Dropdown.Item
          content={
            <>
              Купить&nbsp;{quantityText}&nbsp;
              <Amount.Pure
                className={styles.amount}
                value={price * getMinority(price)}
                minority={getMinority(price)}
                view="withZeroMinorPart"
              />
              &nbsp;
              <span className={styles.secondaryText}>
                {sendTrallingOrders ? 'TRL' : 'LMT'}
              </span>
            </>
          }
          leftIcon={
            <ArrowDownTriangleSIcon
              height={16}
              width={16}
              className={styles.triangleUp}
            />
          }
          className={cn(isDisabledButtons && styles.disabledContextButton)}
          onClick={() => {
            if (!isDisabledButtons) {
              setOrderParams({
                idOrderType: sendTrallingOrders ? OrderType.TRL : OrderType.LMT,
                buy: true,
                quantity: getQuantity(),
                inputPrice: price,
              });
            }

            onClose();
          }}
        />
        {priceDependButton}
        <Dropdown.Item
          content={
            <>
              Продать&nbsp;
              {quantityText}&nbsp;
              <Amount.Pure
                className={styles.amount}
                value={price * getMinority(price)}
                minority={getMinority(price)}
                view="withZeroMinorPart"
              />
              &nbsp;
              <span className={styles.secondaryText}>
                {sendTrallingOrders ? 'TRL' : 'LMT'}
              </span>
            </>
          }
          leftIcon={
            <ArrowDownTriangleSIcon
              height={16}
              width={16}
              className={styles.triangleDown}
            />
          }
          className={cn(isDisabledButtons && styles.disabledContextButton)}
          onClick={() => {
            if (!isDisabledButtons) {
              setOrderParams({
                idOrderType: sendTrallingOrders ? OrderType.TRL : OrderType.LMT,
                buy: false,
                quantity: getQuantity(),
                inputPrice: price,
              });
            }

            onClose();
          }}
        />
      </Dropdown.Block>
      {/* Покупка/продажа по best ask/best bid */}
      {(contextMenuProps?.showBestBuy ?? true) && (
        <Dropdown.Block>
          <Dropdown.Item
            content={
              <>
                Купить&nbsp;лучший&nbsp;
                {quantityText}
                &nbsp;<span className={styles.secondaryText}>Bid</span>
              </>
            }
            leftIcon={
              <ArrowDownTriangleSIcon
                height={16}
                width={16}
                className={styles.triangleUp}
              />
            }
            className={cn(isDisabledButtons && styles.disabledContextButton)}
            onClick={() => {
              if (!isDisabledButtons) {
                setOrderParams({
                  idOrderType: sendTrallingOrders
                    ? OrderType.TRL
                    : OrderType.LMT,
                  buy: true,
                  quantity: getQuantity(),
                  bestOffer: true,
                  inputPrice: price,
                });
              }

              onClose();
            }}
          />
          <Dropdown.Item
            content={
              <>
                Продать&nbsp;лучший&nbsp;
                {quantityText}
                &nbsp;<span className={styles.secondaryText}>Ask</span>
              </>
            }
            leftIcon={
              <ArrowDownTriangleSIcon
                height={16}
                width={16}
                className={styles.triangleDown}
              />
            }
            className={cn(isDisabledButtons && styles.disabledContextButton)}
            onClick={() => {
              if (!isDisabledButtons) {
                setOrderParams({
                  idOrderType: sendTrallingOrders
                    ? OrderType.TRL
                    : OrderType.LMT,
                  buy: false,
                  quantity: getQuantity(),
                  bestOffer: true,
                  inputPrice: price,
                });
              }

              onClose();
            }}
          />
        </Dropdown.Block>
      )}
      {/* Покупка/продажа по рыночным ценам */}
      {(contextMenuProps?.showMarketBuy ?? true) && (
        <Dropdown.Block>
          <Dropdown.Item
            content={
              <>
                Купить&nbsp;
                {quantityText}
                &nbsp;<span className={styles.secondaryText}>по рынку</span>
              </>
            }
            leftIcon={
              <ArrowDownTriangleSIcon
                height={16}
                width={16}
                className={styles.triangleUp}
              />
            }
            className={cn(isDisabledButtons && styles.disabledContextButton)}
            onClick={() => {
              if (!isDisabledButtons) {
                setOrderParams({
                  idOrderType: OrderType.MKT,
                  buy: true,
                  quantity: getQuantity(),
                  inputPrice: 0,
                });
              }

              onClose();
            }}
          />
          <Dropdown.Item
            content={
              <>
                Продать&nbsp;
                {quantityText}
                &nbsp;<span className={styles.secondaryText}>по рынку</span>
              </>
            }
            leftIcon={
              <ArrowDownTriangleSIcon
                height={16}
                width={16}
                className={styles.triangleDown}
              />
            }
            className={cn(isDisabledButtons && styles.disabledContextButton)}
            onClick={() => {
              if (!isDisabledButtons) {
                setOrderParams({
                  idOrderType: OrderType.MKT,
                  buy: false,
                  quantity: getQuantity(),
                  inputPrice: 0,
                });
              }

              onClose();
            }}
          />
        </Dropdown.Block>
      )}
      {isQuantityNotMultipleOfALot && (
        <Dropdown.Block>
          <Dropdown.Item
            content={
              <Typography.Text
                view="secondary-small"
                className={styles.quantityError}
              >
                1 лот = {finInfoExt.lot} шт. Объем должен быть кратным лоту
              </Typography.Text>
            }
          />
        </Dropdown.Block>
      )}
      <Dropdown.Block>
        <Dropdown.Item
          content={
            <Typography.Text
              view="secondary-small"
              className={styles.quantitySourceHint}
            >
              {quantitySourceHint}
            </Typography.Text>
          }
          withDivider
        />
      </Dropdown.Block>
    </>
  );
};
