import cn from 'classnames';
import isUndefined from 'lodash/isUndefined';
import { useRef, useState } from 'react';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import {
  defaultOrderStatusFilters,
  filtersNames,
} from '@terminal/core/constants/orders';
import { useOnClickOutside } from '@terminal/core/hooks';
import { OrderStatus } from '@terminal/core/lib/client/entities';
import { BaseWidgetConfig } from '@terminal/core/types/layout';
import { OrderItem } from '@terminal/core/types/order';

import { useWidgetContext } from '../../../../shared';

import styles from './OrderStatusFilters.module.css';

const minChipsFiltersWidth = 820;

interface Props {
  selectedFilters: OrderStatus[];
  updateNode: (
    nodeId: string,
    config: BaseWidgetConfig,
    newName?: string | undefined
  ) => void;
  nodeId?: string;
  orders: OrderItem[];
}

export const OrderStatusFilters = ({
  selectedFilters,
  updateNode,
  nodeId,
  orders,
}: Props) => {
  const { DropdownComponent: Dropdown, getNode } = useWidgetContext();
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const nodeWidth = getNode(nodeId)?.getRect().width || 0;
  const isShowChips = nodeWidth > minChipsFiltersWidth;
  const selectedOptions = Object.keys(defaultOrderStatusFilters).filter(
    (name) => {
      const filters = defaultOrderStatusFilters[name];

      return filters.some((filter) => selectedFilters.includes(filter));
    }
  );

  const saveFilters = (orderStatus: OrderStatus[]) => {
    if (!isUndefined(nodeId)) {
      updateNode(nodeId, { tableProps: { orderStatus } });
    }
  };

  useOnClickOutside([dropdownRef, filtersButtonRef], () => setIsOpen(false));

  const renderedFilters = Object.entries(defaultOrderStatusFilters).map(
    ([name, status]) => {
      const checked = status.some((s) => selectedFilters.includes(s));
      const count = orders.filter((order) =>
        status.includes(order.idOrderStatus)
      ).length;

      return (
        <Tag
          checked={checked}
          key={name}
          onClick={() => {
            if (checked) {
              saveFilters(
                selectedFilters.filter(
                  (statusFilter) => !status.includes(statusFilter)
                )
              );
            } else {
              saveFilters([...selectedFilters, ...status]);
            }
          }}
          size="xs"
          view="filled"
          className={cn(
            !isShowChips && styles.orderStatusFiltersFilterInSelect
          )}
        >
          {filtersNames[name]}{' '}
          <span className={styles.count}>{count || ''}</span>
        </Tag>
      );
    }
  );

  return (
    <div className={styles.wrapper} ref={anchorRef}>
      <div className={styles.filters}>
        {isShowChips ? (
          renderedFilters
        ) : (
          <>
            <Tag
              ref={filtersButtonRef}
              size="xs"
              view="filled"
              onClick={() => setIsOpen((prev) => !prev)}
              onMouseDown={(e) => e.stopPropagation()}
              rightAddons={
                <ChevronDownMIcon
                  height={14}
                  width={14}
                  className={cn(styles.chevron, isOpen && styles.chevronUp)}
                />
              }
            >
              {!selectedOptions.length
                ? 'Все заявки'
                : `Фильтры: ${selectedOptions.length}`}
            </Tag>
            <Dropdown
              ref={dropdownRef}
              anchorElement={anchorRef.current}
              open={isOpen}
              offset={[0, 4]}
              position="bottom-start"
              popperClassName={cn(
                styles.select,
                styles.orderStatusFiltersSelect
              )}
            >
              {renderedFilters}
            </Dropdown>
          </>
        )}
      </div>
    </div>
  );
};
