type ReturnType = {
  /**
   * Если `true`, то цена меньше нижней границы спреда
   * */
  isBelowSpread: boolean;
  /**
   * Если `true`, то цена больше верхней границы спреда
   * */
  isAboveSpread: boolean;
  /**
   * Если `true`, то цена находится в пределах спреда
   * */
  isWithinSpread: boolean;
};

type Options = {
  /**
   * Верхняя ценовая граница спреда
   * */
  upperSpreadPrice?: number;
  /**
   * Максимальная цена покупки (максимальная цена за нижней границей спреда)
   * */
  maxBuyPrice?: number;
};

/**
 * Определяет положение цены `price` относительно границ спреда
 * */
export function evaluatePricePosition(
  price: number,
  options: Options = {}
): ReturnType {
  const { upperSpreadPrice, maxBuyPrice } = options;

  if (price === 0) {
    return {
      isBelowSpread: false,
      isAboveSpread: false,
      isWithinSpread: false,
    };
  }

  return {
    isBelowSpread: Boolean(maxBuyPrice && price <= maxBuyPrice),
    isAboveSpread: Boolean(upperSpreadPrice && price > upperSpreadPrice),
    isWithinSpread: Boolean(
      maxBuyPrice &&
        upperSpreadPrice &&
        price > maxBuyPrice &&
        price <= upperSpreadPrice
    ),
  };
}
