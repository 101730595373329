import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';

import { AddSubaccountParams } from './types/AddSubaccountParams';

export async function addSubAccount(data: AddSubaccountParams) {
  return await signOperation(
    `/api/client/accounts/subaccount/add/${data.params.treaty}`,
    'POST',
    undefined,
    data.signOptions
  );
}
