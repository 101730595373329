import { CURRENT_PHONE_AUTH_STATUS_KEY } from '@terminal/core/constants/common';

import { PhoneLoginState } from '../model/types/PhoneLoginState';

const stateMapper = {
  AUTHORIZATION_NATIVE: {
    state: PhoneLoginState.AUTHORIZATION_NATIVE,
    parentState: PhoneLoginState.INIT_LOGIN,
    onDone: () => {
      localStorage.setItem(
        CURRENT_PHONE_AUTH_STATUS_KEY,
        PhoneLoginState.AUTHORIZATION_NATIVE
      );
    },
  },
  PASSPORT: {
    state: PhoneLoginState.PASSPORT_LOGIN,
    parentState: PhoneLoginState.AUTHORIZATION_NATIVE,
    onDone: () => {
      localStorage.setItem(
        CURRENT_PHONE_AUTH_STATUS_KEY,
        PhoneLoginState.PROCESS_LOGIN
      );
    },
  },
  BASE_LOGIN: {
    state: PhoneLoginState.BASE_LOGIN,
    parentState: PhoneLoginState.INIT_LOGIN,
    onDone: () => {
      localStorage.setItem(
        CURRENT_PHONE_AUTH_STATUS_KEY,
        PhoneLoginState.BASE_LOGIN
      );
    },
  },
  CONNECT_PASSPORT: {
    state: PhoneLoginState.CONNECT_PASSPORT,
    parentState: PhoneLoginState.BASE_LOGIN,
    onDone: () => {
      localStorage.setItem(
        CURRENT_PHONE_AUTH_STATUS_KEY,
        PhoneLoginState.PROCESS_LOGIN
      );
    },
  },
};

export const updateAuthPhoneLoginState = (status: string) => {
  const storageStage =
    localStorage.getItem(CURRENT_PHONE_AUTH_STATUS_KEY) ||
    PhoneLoginState.INIT_LOGIN;

  const currentLoginState = stateMapper[status];

  if (currentLoginState?.parentState === storageStage) {
    currentLoginState?.onDone();
  }
};
