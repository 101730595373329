import { Gap } from '@alfalab/core-components/gap';
import {
  SuperEllipse,
  SuperEllipseProps,
} from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';

import styles from './Plug.module.css';

interface PlugProps {
  title: React.ReactNode;
  text?: React.ReactNode;
  superellipseProps?: SuperEllipseProps;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

export const Plug = ({
  title,
  text,
  superellipseProps,
  icon,
  children,
}: PlugProps) => {
  return (
    <div className={styles.container}>
      <SuperEllipse size={48} {...superellipseProps}>
        {icon || (
          <ExclamationMIcon color="var(--color-light-graphic-primary)" />
        )}
      </SuperEllipse>
      <Gap size="xl" />
      <Typography.Text tag="div" view="primary-medium" weight="bold">
        {title}
      </Typography.Text>
      <Gap size="s" />
      {text && (
        <Typography.Text
          view="secondary-small"
          color="secondary"
          tag="p"
          defaultMargins={false}
        >
          {text}
        </Typography.Text>
      )}
      {children}
    </div>
  );
};
