import { useContestList } from './useContestList';

export const useContestSelector = () => {
  const {
    data: contestList,
    isFetching,
    isFetched,
    isSuccess,
  } = useContestList();

  return {
    contest: contestList && contestList.length ? contestList[0] : undefined,
    isFetching,
    isFetched,
    isSuccess,
  };
};
