import React, { FC, HTMLAttributes } from 'react';
import { Button as BaseButton } from '@alfalab/core-components/button';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';

export type DismissButtonProps = HTMLAttributes<HTMLButtonElement>;

export const DismissButton: FC<DismissButtonProps> = (props) => {
  return (
    <BaseButton
      size="xxs"
      leftAddons={<CheckmarkMIcon width={16} height={16} />}
      block
      {...props}
    >
      Снять
    </BaseButton>
  );
};
