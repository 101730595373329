import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowRightSquareMIcon } from '@alfalab/icons-glyph/ArrowRightSquareMIcon';
import { DotsHorizontalMIcon } from '@alfalab/icons-glyph/DotsHorizontalMIcon';

import styles from './WorkspaceConfigurationExportDoc.module.css';

export const WorkspaceConfigurationExportDoc = () => {
  return (
    <div>
      <Typography.Text
        view="primary-small"
        tag="div"
        weight="bold"
        className={styles.quaternary}
      >
        Как открыть конфигурацию на другом устройстве или в другом браузере
      </Typography.Text>
      <Gap size="xs" />
      <Typography.Text
        view="secondary-large"
        tag="div"
        weight="medium"
        className={styles.graphic}
      >
        На данный момент сохраненные конфигурации можно использовать в рамках
        одного устройства и браузера. Перенести конфигурацию можно с помощью
        ее экспорта в файл.
      </Typography.Text>

      <Gap size="xl" />

      <Typography.Text
        view="secondary-large"
        tag="div"
        weight="bold"
        className={styles.graphic}
      >
        1. Выгрузите конфигурацию в файл
      </Typography.Text>
      <Gap size="xs" />
      <Typography.Text
        view="secondary-large"
        tag="div"
        weight="medium"
        className={styles.graphic}
      >
        Для активной конфигурации выберите «Сохранить изменения» → «Выгрузить в
        файл».
        <Gap size="m" />
        Для других конфигураций выберите выгрузку в файл в меню действий&nbsp;
        <DotsHorizontalMIcon
          width={14}
          height={14}
          color="var(--color-dark-graphic-secondary)"
          className={styles.icon}
        />
        <Gap size="m" />
        Сохраните файл на своем устройстве. Если вы хотите открыть конфигурацию
        на другом устройстве, перенесите файл на это устройство.
      </Typography.Text>

      <Gap size="xl" />

      <Typography.Text
        view="secondary-large"
        tag="div"
        weight="bold"
        className={styles.graphic}
      >
        2. Загрузите файл конфигурации на другом устройстве (в другом браузере)
      </Typography.Text>
      <Gap size="xs" />
      <Typography.Text
        view="secondary-large"
        tag="div"
        weight="medium"
        className={styles.graphic}
      >
        Для этого вам необходимо перейти в Главное меню → Общие настройки и
        выбрать раздел «Конфигурации». На вкладке «Пользовательские
        конфигурации» выберите «Загрузить из файла», после чего выберите файл
        конфигурации для загрузки.
      </Typography.Text>

      <Gap size="xl" />

      <Typography.Text
        view="secondary-large"
        tag="div"
        weight="bold"
        className={styles.graphic}
      >
        3. Примените конфигурацию
      </Typography.Text>
      <Gap size="xs" />
      <Typography.Text
        view="secondary-large"
        tag="div"
        weight="medium"
        className={styles.graphic}
      >
        Примените загруженную конфигурацию, нажав кнопку «Применить»&nbsp;
        <ArrowRightSquareMIcon
          width={16}
          height={16}
          color="var(--color-dark-graphic-secondary)"
          className={styles.icon}
        />
      </Typography.Text>
      <Gap size="3xs" />
    </div>
  );
};
