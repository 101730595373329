import { Space } from '@alfalab/core-components/space';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';

import {
  EducationStrategy,
  StrategyDifficulty,
  StrategyIcon,
  StrategyPlate,
  StrategyProperty,
} from '../../../../entities/Education';

interface EducationStrategyItemProps {
  item: EducationStrategy;
  className?: string;
  onClick?: () => void;
}

export const EducationStrategyItem = ({
  item,
  className,
  onClick,
}: EducationStrategyItemProps) => {
  return (
    <StrategyPlate
      icon={<StrategyIcon size="s" strategyId={item.id} />}
      size="s"
      className={className}
      name={item.name}
      text={
        <Space direction="horizontal" size={16}>
          <StrategyProperty
            label="Ориентир доходности"
            special
            view="horizontal"
          >
            {item.profitability}%*
          </StrategyProperty>
          <StrategyProperty label="Сложность" view="horizontal">
            <StrategyDifficulty difficulty={item.complexity} />
          </StrategyProperty>
        </Space>
      }
      onClick={onClick}
      addons={<ChevronRightMIcon color="var(--color-light-graphic-tertiary)" />}
    />
  );
};
