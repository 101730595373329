import { LinkName, Widget } from '@terminal/core/types/layout';
import { WatchlistIds } from '@terminal/core/types/watchList';

import { ConfigTemplate } from '../../../../index';
import { balanceLayout } from '../common/balanceLayout';
import {
  BASE_CHART_CONFIG,
  COMMON_ORDERS_CONFIG,
  getCommonInstrumentsConfig,
  TRADE_ORDERBOOK_CONFIG,
} from '../common/configs';
import { TRADE_CHART_CONFIG } from '../swing/configs';
import {
  CHART_MIDDLE_CHRTIQ_SETTINGS,
  CHART_RIGHT_BOTTOM_CHRTIQ_SETTINGS,
  CHART_RIGHT_TOP_CHRTIQ_SETTINGS,
  TRADE_BOTTOM_CHART_SETTING,
  TRADE_TOP_CHART_SETTING,
} from './configs';

import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

export const dayTradeMedium = () => {
  /** Возвращает layout Торговля */
  const makeTradeLayout = () => {
    const ordersTab = new Tab(Widget.ORDERS, COMMON_ORDERS_CONFIG);
    const orderTabset = new TabSet(100);

    orderTabset.addChildren([ordersTab]);

    const futuresWatchListTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(LinkName.BLUE, WatchlistIds.featuresList)
    );
    const stocksWatchListTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(LinkName.BLUE, WatchlistIds.iMOEXList)
    );
    const watchListTabSet = new TabSet(50);

    watchListTabSet.addChildren([futuresWatchListTab, stocksWatchListTab]);

    const ordersRow = new Row(100);

    ordersRow.addChildren([orderTabset]);

    const watchlistRow = new Row(100);

    watchlistRow.addChildren([watchListTabSet]);

    const leftRow = new Row(20);

    leftRow.addChildren([ordersRow, watchlistRow]);

    const tradesTab = new Tab(Widget.TRADES, { link: LinkName.BLUE });
    const tradesTabSet = new TabSet(20);

    tradesTabSet.addChildren([tradesTab]);

    const leftMiddleRow = new Row(5);

    leftMiddleRow.addChildren([tradesTabSet]);

    const orderbookTab = new Tab(Widget.ORDERBOOK, TRADE_ORDERBOOK_CONFIG);
    const orderbookTabset = new TabSet(20);

    orderbookTabset.addChildren([orderbookTab]);

    const rightMiddleRow = new Row(5);

    rightMiddleRow.addChildren([orderbookTabset]);

    const chartiqTopTab = new Tab(
      Widget.CHART,
      BASE_CHART_CONFIG,
      TRADE_TOP_CHART_SETTING
    );
    const chartiqBottomTab = new Tab(
      Widget.CHART,
      BASE_CHART_CONFIG,
      TRADE_BOTTOM_CHART_SETTING
    );
    const chartiqTopTabSet = new TabSet(100);

    chartiqTopTabSet.addChildren([chartiqTopTab, chartiqBottomTab]);

    const rightRow = new Row(40);

    rightRow.addChildren([chartiqTopTabSet]);

    const tradeRow = new Row(100);

    tradeRow.addChildren([leftRow, leftMiddleRow, rightMiddleRow, rightRow]);

    return new Layout('Торговля', tradeRow);
  };

  /** Возвращает layout Графики */
  const makeChartLayout = () => {
    const chartWatchListTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(LinkName.BLUE, WatchlistIds.iMOEXList)
    );
    const chartStocksWatchListTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(LinkName.BLUE, WatchlistIds.featuresList)
    );
    const chartWatchListTabSet = new TabSet(100);

    chartWatchListTabSet.addChildren([
      chartWatchListTab,
      chartStocksWatchListTab,
    ]);

    const leftChartRow = new Row(20);

    leftChartRow.addChildren([chartWatchListTabSet]);

    const chartMiddleTab = new Tab(
      Widget.CHART,
      TRADE_CHART_CONFIG,
      CHART_MIDDLE_CHRTIQ_SETTINGS
    );
    const chartMiddleTabSet = new TabSet(100);

    chartMiddleTabSet.addChildren([chartMiddleTab]);

    const middleChartRow = new Row(50);

    middleChartRow.addChildren([chartMiddleTabSet]);

    const chartRightTopTab = new Tab(
      Widget.CHART,
      TRADE_CHART_CONFIG,
      CHART_RIGHT_TOP_CHRTIQ_SETTINGS
    );
    const chartRightTopTabSet = new TabSet(100);

    chartRightTopTabSet.addChildren([chartRightTopTab]);

    const chartRightBottomTab = new Tab(
      Widget.CHART,
      TRADE_CHART_CONFIG,
      CHART_RIGHT_BOTTOM_CHRTIQ_SETTINGS
    );
    const chartRightBottomTabSet = new TabSet(100);

    chartRightBottomTabSet.addChildren([chartRightBottomTab]);

    const chartRightRow = new Row(50);

    chartRightRow.addChildren([chartRightTopTabSet, chartRightBottomTabSet]);

    const chartRow = new Row(100);

    chartRow.addChildren([leftChartRow, middleChartRow, chartRightRow]);

    return new Layout('Графики', chartRow);
  };

  const configuration = new DefaultConfiguration('Дневная торговля');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeChartLayout());
  configuration.addLayout(balanceLayout);

  return configuration as unknown as ConfigTemplate;
};
