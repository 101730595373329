import addMilliseconds from 'date-fns/addMilliseconds';
import { maxTime, minTime } from 'date-fns/constants';
import isEqual from 'date-fns/isEqual';
import isWithinInterval from 'date-fns/isWithinInterval';
import max from 'date-fns/max';
import min from 'date-fns/min';
import subMilliseconds from 'date-fns/subMilliseconds';

/**
 * Создает фильтр для отсеивания объектов, у которых значение
 * в поле `key` находится за пределами заданного интервала.
 * @param key название поля типа Date (number), по которому будет происходить фильтрация
 * @param startDate дата начала допустимого интервала (включительно)
 * @param endDate дата конца допустимого интервала (включительно)
 * @param enabled если `false`, то фильтр отключен
 * @returns функцию-фильтр
 * */
export function createDateRangeFilter<K extends PropertyKey>(
  key: K,
  startDate: Date | null,
  endDate: Date | null,
  enabled = true
) {
  return (item: Record<K, Date | number>) => {
    const date = item[key];
    const start =
      startDate === null
        ? subMilliseconds(date, isEqual(date, minTime) ? 0 : 1)
        : startDate;
    const end =
      endDate === null
        ? addMilliseconds(date, isEqual(date, maxTime) ? 0 : 1)
        : endDate;

    return (
      !enabled ||
      isWithinInterval(date, {
        start: min([start, end]),
        end: max([start, end]),
      })
    );
  };
}
