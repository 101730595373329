import {
  SecuritiesHistoryFilter,
  SecuritiesHistoryStatusCode as StatusCode,
  SecuritiesOperationType,
} from '@terminal/core/lib/rest/lkSecurities';

import { areDatesOverlapping, isDateValid } from '../../../components/Filter';

export const isFilterValid = (filter: SecuritiesHistoryFilter): boolean => {
  return (
    isDateValid(filter.startDate) &&
    isDateValid(filter.endDate) &&
    !areDatesOverlapping(filter.startDate, filter.endDate)
  );
};

export const STATUS_VARIANTS = [
  {
    key: [
      StatusCode.Submitted,
      StatusCode.Transmitted,
      StatusCode.OrderIsAbsent,
      StatusCode.ParametersDiffer,
    ].join(''),
    name: 'В работе',
  },
  {
    key: StatusCode.Executed,
    name: 'Исполнено',
  },
  {
    key: StatusCode.Declined,
    name: 'Отвергнуто',
  },
  {
    key: StatusCode.Rejected,
    name: 'Отменено',
  },
];

export const TRANSACTION_TYPES: SecuritiesOperationType[] = [
  'EXTRA_IN',
  'EXTRA_OUT',
];
