import cn from 'classnames';
import { maxBy } from 'lodash';
import { Badge } from '@alfalab/core-components/badge';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronForwardCompactMIcon } from '@alfalab/icons-glyph/ChevronForwardCompactMIcon';

import {
  Changelog as ChangelogType,
  ChangelogEntry,
} from '@terminal/core/types/changelog';

import styles from './Changelog.module.css';

export interface Props {
  changelog: ChangelogType;
  onEntryClick: (entry: ChangelogEntry) => void;
  source: 'pro' | 'pwa';
}

export function Changelog(props: Props) {
  const { changelog, source, onEntryClick } = props;
  const entries = changelog.data;
  const isPwa = source === 'pwa';

  let lastVersion: ChangelogEntry | undefined = undefined;
  let oldVersions: ChangelogEntry[] = [];

  // находим версию с самой последней датой
  const lastVersionId = maxBy(
    entries.map((e) => ({
      date: Date.parse(e.date),
      id: e.id,
    })),
    (v) => v.date
  );

  lastVersion = entries.find((e) => e.id === lastVersionId?.id);

  // и остальные
  oldVersions = entries
    .filter((e) => e.id !== lastVersion?.id)
    .sort((e1, e2) => {
      const d1 = new Date(e1.date);
      const d2 = new Date(e2.date);

      return d2.getTime() - d1.getTime();
    });

  const headerClass = cn({ [styles.pwaGap]: isPwa });

  return (
    <div className={styles.container}>
      {lastVersion ? (
        <div>
          <span className={headerClass}>
            <Typography.Text weight="bold" color="primary">
              Текущая версия
            </Typography.Text>
          </span>
          <Entry
            withDot
            withSummary={!isPwa}
            entry={lastVersion}
            onEntryClick={onEntryClick}
          />
        </div>
      ) : null}
      <div>
        <span className={headerClass}>
          <Typography.Text weight="bold" color="primary">
            История
          </Typography.Text>
        </span>
        {oldVersions.map((entry) => (
          <Entry
            key={entry.id}
            withDot={false}
            withSummary={!isPwa}
            entry={entry}
            onEntryClick={onEntryClick}
          />
        ))}
      </div>
    </div>
  );
}

interface EntryProps {
  entry: ChangelogEntry;
  onEntryClick: (entry: ChangelogEntry) => void;
  withDot: boolean;
  withSummary: boolean;
}

function Entry(props: EntryProps) {
  const { entry, onEntryClick, withDot, withSummary } = props;
  const containerStyles = cn(styles.entry, {
    [styles.entryGap]: withDot,
  });

  return (
    <div className={containerStyles} onClick={() => onEntryClick(entry)}>
      {withDot && (
        <div className={styles.dotContainer}>
          <Badge view="count" visibleIconOutline />
        </div>
      )}
      <Typography.Text view="secondary-large" color="primary">
        Версия {entry.version}
      </Typography.Text>{' '}
      <Typography.Text view="secondary-large" color="secondary">
        {new Date(entry.date).toLocaleDateString()}
      </Typography.Text>
      {withSummary && entry.summary ? (
        <div className={styles.summary}>
          <Typography.Text view="secondary-large" color="secondary">
            {entry.summary}
          </Typography.Text>
        </div>
      ) : null}
      <div className={styles.iconForward}>
        <ChevronForwardCompactMIcon color="var(--color-light-graphic-secondary)" />
      </div>
    </div>
  );
}
