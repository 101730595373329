import { UAParser } from 'ua-parser-js';

import { APP_VERSION } from '@terminal/core/env';
import { getServerSignTime } from '@terminal/core/lib/services/time';
import { shallow, useStore } from '@terminal/core/store';
import { ConnectionStatus } from '@terminal/core/types/connection';

export const useSupportMessage = () => {
  const [user, connectionStatus] = useStore(
    (state) => [state.user, state.connectionStatus],
    shallow
  );

  return () => {
    const parser = new UAParser(navigator.userAgent);

    return `
Добавьте  скриншот проблемы при необходимости

Версия приложения: ${APP_VERSION}
Логин: ${user?.login}
Устройство: ${parser.getDevice().vendor || ''} ${parser.getDevice().model || ''}
Браузер: ${parser.getBrowser().name || ''} ${parser.getBrowser().version || ''}
Движок: ${parser.getEngine().name || ''} ${parser.getEngine().version || ''}
ОС: ${parser.getOS().name || ''} ${parser.getOS().version || ''}
Время: ${new Date().getTime()}
Время сервера (OpenServer): ${getServerSignTime().getTime()}
Статус работы серверов: ${
      connectionStatus === ConnectionStatus.CONNECTED ? '++++' : '----'
    }
`;
  };
};
