import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop/Component.desktop';
import { TooltipDesktopProps } from '@alfalab/core-components/tooltip/types';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import classes from './TitleWithHint.module.css';

type TitleWithHintProps = {
  title: ReactNode;
  hintContent: TooltipDesktopProps['content'];
  view?: TooltipDesktopProps['view'];
  gap?: string;
  iconSize?: number;
  iconColor?: string;
  contentClassName?: string;
};

export const TitleWithHint: FC<TitleWithHintProps> = ({
  title,
  hintContent,
  contentClassName,
  view = 'hint',
  iconSize = 12,
  gap = 'var(--gap-xs)',
  iconColor = 'var(--color-light-graphic-secondary)',
}) => {
  return (
    <div className={classes.title} style={{ gap }}>
      <div>{title}</div>
      <Tooltip
        view={view}
        trigger="hover"
        offset={[0, 10]}
        position="top-start"
        contentClassName={cn(classes.tooltipContent, contentClassName)}
        content={hintContent}
      >
        <InformationCircleLineSIcon
          height={iconSize}
          width={iconSize}
          color={iconColor}
        />
      </Tooltip>
    </div>
  );
};
