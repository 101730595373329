import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { securitiesActivesSearch } from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export interface SecuritySearchParams {
  treaty?: number;
  search?: string;
}

export const useSecuritiesSearch = (
  params: SecuritySearchParams,
  enable: boolean = true
) => {
  const addNotification = useNotification();

  const { treaty, search } = params;

  return useQuery(
    [LkQueryKeys.SecuritiesActivesSearch, { treaty, search }],
    async () => {
      if (!treaty || search === undefined) {
        return;
      }

      return await securitiesActivesSearch({ treaty, search });
    },
    {
      enabled: enable && Boolean(treaty) && search !== undefined,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка запросе активов',
        });
      },
    }
  );
};
