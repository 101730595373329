import { CIQ } from 'chartiq';
import { Actions, IJsonModel, Model, TabNode } from 'flexlayout-react';
import drop from 'lodash/drop';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';
import type { PartialDeep } from 'type-fest';
import { v4 } from 'uuid';
import { GetState } from 'zustand';

import { CHART_DEFAULT_THEMES } from '../../constants/chart';
import {
  importOptions,
  initialJson,
  initialLayoutLink,
  WidgetsNameMap,
  WidgetWithName,
} from '../../constants/Layout';
import { layoutTabWidth } from '../../constants/workspaceConfigurations';
import { wasShownReopenWorkspaceWindowsQuestionSessionKey } from '../../constants/workspaces';
import { move } from '../../lib/arrayMove';
import {
  linkToGroup,
  unlinkFromGroup,
  updateLinkProps,
} from '../../lib/linking';
import log from '../../lib/loglevel-mobile-debug';
import { selectUserSubAccounts } from '../selectors';
import { RootStore, StoreSet } from '../useStore';
import {
  createLayoutModels,
  parseWorkspaceConfig,
  parseWorkspaceConfigs,
} from './helpers/workspaceConfigurationsHelpers';

import {
  chartCustomSettingsFields,
  ChartSetting,
  ChartThemeSettings,
  IChartCustomSettings,
  IChartEngine,
} from '../../types/chart';
import {
  LinkGroups,
  LinkName,
  TableProps,
  Widget,
  WidgetLinkProps,
} from '../../types/layout';
import {
  TrackPrice,
  TrackPriceCommonSettings,
  TrackPriceSettings,
} from '../../types/trackPrice';
import { TradesFeedSettings, TradesFeedsSettings } from '../../types/tradeFeed';

const childWindowTabId = parseInt(
  window.sessionStorage.getItem('child_window_tab_id') || ''
);

export type TVolumeFilterSetting = {
  nodeId?: string;
  volume: number;
  color?: string;
};

export type TOrderBookFilterSetting = {
  nodeId?: string;
  enabled: boolean;
  type: 'volume' | 'maximum';
  value: number;
};

export type VolumeFiltersSettings = Record<number, TVolumeFilterSetting[]>;
export type OrderBookFiltersSettings = Record<number, TOrderBookFilterSetting>;

type TrackPriceSettingsConfiguration = {
  defaultSettings?: TrackPriceCommonSettings;
  settingsByTrackPriceId?: Record<string, TrackPriceSettings>;
};

type FavoritesConfiguration = {
  studies?: string[];
  drawings?: string[];
};

export type WorkspaceConfigLS = {
  timeStamp: number;
  layouts: IJsonModel[];
  layoutLinks: LinkGroups[];
  layoutNames: string[];
  // Какие окна открыты в отдельном окне
  layoutWindows: boolean[];
  activeLayoutKey: number;
  chart: ChartStorage;
  tradesFeedsSettings?: TradesFeedsSettings;
  volumeFiltersSettings?: VolumeFiltersSettings;
  orderBookFiltersSettings?: OrderBookFiltersSettings;
  /**
   * Настройки алертов
   * */
  trackPriceSettings?: TrackPriceSettingsConfiguration;
  favorites?: FavoritesConfiguration;
  chartThemes?: ChartThemeSettings[];
};

export type WorkspaceConfigurationLS = {
  name: string;
  /* Конфигурация сохраняемая автоматически после любого действия пользователя */
  lastAutoSavedConfig: WorkspaceConfigLS;
  /* Конфигурация сохраняемая когда юзер кликает на кнопку "Сохранить"
     Используется для сброса автоматически сохраненного конфига */
  lastManualSavedConfig: WorkspaceConfigLS;
  type: 'default' | 'custom';
  id: string;
};

export type WorkspaceConfigurationsLS = {
  workspaceConfigurations: WorkspaceConfigurationLS[];
  workspaceConfigurationActiveId: number;
};

/**
 * Типы для стора
 */
export type ChartStorage = { [key: string]: ChartSetting };

export type WorkspaceConfigModel = Model;

export type WorkspaceConfig = WorkspaceConfigLS & {
  models: Model[];
};

export type WorkspaceConfiguration = {
  name: string;
  /* Конфигурация сохраняемая автоматически после любого действия пользователя */
  lastAutoSavedConfig: WorkspaceConfig;
  /* Конфигурация сохраняемая когда юзер кликает на кнопку "Сохранить"
     Используется для сброса автоматически сохраненного конфига */
  lastManualSavedConfig: WorkspaceConfig;
  type: 'default' | 'custom';
  id: string;
};

/**
 * Стор
 */

export interface WorkspaceConfigurationsSlice {
  workspacesIsInitialized: boolean;
  workspaceConfigurations: WorkspaceConfiguration[];
  workspaceConfigurationActiveId: number;
  setActiveConfigurationById: (id: string) => void;

  workspaceConfigurationsInit: (data: WorkspaceConfigurationsLS) => void;

  manualSaveConfiguration: (id: number) => void;
  editConfiguration: (id: string, config: WorkspaceConfiguration) => void;
  renameConfiguration: (id: string, newName: string) => void;
  removeConfiguration: (key: string) => void;
  resetConfigurationById: (key: string) => void;
  addNewConfiguration: (key: string) => void;
  importConfiguration: (configuration: WorkspaceConfigurationLS) => void;
  setActiveLayoutKey: (newKey: number) => void;
  setLayoutJson: (key: number, newJson: IJsonModel) => void;
  updateNode: <T>(nodeId: string, config: T, symbol?: string) => void;
  createNewLayout: () => void;
  removeLayout: (keyToRemove: number) => void;
  setLayoutName: (key: number, value: string) => void;
  setLayoutPosition: (key: number, newPosition: number) => void;
  getActiveLayoutKey: () => number;
  linkToGroup: (
    nodeId: string,
    linkName: LinkName,
    params: WidgetLinkProps
  ) => void;
  unlinkFromGroup: (nodeId: string, linkName: LinkName) => void;

  setTradeFeedSettings: (
    idFI: number,
    nodeId?: string,
    idx?: number,
    volume?: number
  ) => void;
  setVolumeFilterSettings: (
    idFI: number,
    volumeFilters: TVolumeFilterSetting[],
    nodeId?: string
  ) => void;
  setOrderBookFilterSettings: (
    idFI: number,
    filter: TOrderBookFilterSetting,
    nodeId?: string
  ) => void;

  chartSetChartSetting: (nodeId: string, setting: ChartSetting) => void;
  chartRestoreSettings: (
    stxx?: IChartEngine,
    nodeId?: string,
    idFi?: number,
    symbol?: string,
    trueSymbol?: string
  ) => void;
  chartRestore: (stxx: IChartEngine, symbol: string, nodeId?: string) => void;
  chartSaveCustomSettings: (
    stxx?: IChartEngine,
    nodeId?: string,
    customPreferences?: Record<string, any>
  ) => void;
  chartSaveLayout: (stxx?: IChartEngine, nodeId?: string) => void;
  chartSavePreferences: (stxx?: IChartEngine, nodeId?: string) => void;
  chartSaveDrawings: (
    stxx: IChartEngine,
    symbol: string,
    nodeId?: string
  ) => void;
  chartSaveTheme: (nodeId: string, theme: string) => void;
  chartGetChartSetting: <K extends keyof ChartSetting>(
    nodeId: string,
    setting: K
  ) => ChartSetting[K] | undefined;
  chartDeleteChartSetting: (nodeId: string) => void;
  sanitizeUserData: () => void;
  forceSyncLayoutModelsAndJsons: () => void;

  earlyOpenWindowsTabsIds: number[];
  reopenWorkspaceWindows: () => void;
  reopenOtherWorkspaceWindows: () => void;
  resetWorkspaceWindows: () => void;
  openNewWindow: (tabId: number, isChainChildWindows?: boolean) => void;
  createNewConfiguration: (
    config: WorkspaceConfiguration,
    cb?: () => void
  ) => void;

  setTrackPriceDefaultSettings: (settings: TrackPriceCommonSettings) => void;
  setTrackPriceSettings: (
    trackPrice: TrackPrice,
    settings: TrackPriceSettings
  ) => void;

  setFavorites: (favorites: FavoritesConfiguration) => void;
  setChartThemes: (themes: ChartThemeSettings[]) => void;
}

const getEarlyOpenWindowsTabsIds = (
  configurations: WorkspaceConfiguration[],
  activeId: number
) => {
  return configurations[activeId]?.lastAutoSavedConfig?.layoutWindows
    .map((isWindow, idx) => (isWindow ? idx : undefined))
    .filter((number) => number !== undefined) as number[];
};

const copyHardConfig = (config: WorkspaceConfig) => {
  const withOutModels = {
    ...config,
    models: undefined,
  };

  const copiedObject: WorkspaceConfig = JSON.parse(
    JSON.stringify(withOutModels)
  );

  const newObjectWithModels: WorkspaceConfig = {
    ...copiedObject,
    models: createLayoutModels(copiedObject.layouts),
  };

  return newObjectWithModels;
};

export const createWorkspaceConfigurationsSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): WorkspaceConfigurationsSlice => ({
  workspacesIsInitialized: false,
  workspaceConfigurations: [],
  workspaceConfigurationActiveId: 0,
  earlyOpenWindowsTabsIds: [],

  workspaceConfigurationsInit: ({
    workspaceConfigurations,
    workspaceConfigurationActiveId,
  }: WorkspaceConfigurationsLS) => {
    const wcWithModels = parseWorkspaceConfigs(workspaceConfigurations);

    const earlyOpenWindowsTabsIds = getEarlyOpenWindowsTabsIds(
      wcWithModels,
      workspaceConfigurationActiveId
    );

    set((state) => {
      state.workspacesIsInitialized = true;
      state.workspaceConfigurations = wcWithModels;
      state.workspaceConfigurationActiveId = workspaceConfigurationActiveId;
      state.earlyOpenWindowsTabsIds = earlyOpenWindowsTabsIds;
    });
  },
  editConfiguration: (id: string, config: WorkspaceConfiguration) => {
    set((state) => {
      const index = get().workspaceConfigurations.findIndex(
        (config) => config.id === id
      );

      if (index === -1) {
        log.error(`editConfiguration error id=${id}`);

        return;
      }

      state.workspaceConfigurations[index] = {
        ...config,
        id: state.workspaceConfigurations[index].id,
      };
      state.workspaceConfigurationActiveId = index;
    });
  },
  renameConfiguration: (id: string, newName: string) => {
    set((state) => {
      const countedName = resolveSameNameCounter(
        get().workspaceConfigurations.filter((c) => c.id !== id),
        newName
      );

      const idx = get().workspaceConfigurations.findIndex(
        (config) => config.id === id
      );

      if (idx === -1) {
        log.error(`resetConfiguration error id=${id}`);

        return;
      }

      state.workspaceConfigurations[idx].name = countedName;
    });
  },
  removeConfiguration: (id: string) => {
    set((state) => {
      const idx = get().workspaceConfigurations.findIndex(
        (config) => config.id === id
      );

      if (idx === -1) {
        log.error(`resetConfiguration error idx=${idx}`);

        return;
      }

      state.workspaceConfigurations.splice(idx, 1);

      if (idx <= get().workspaceConfigurationActiveId) {
        state.workspaceConfigurationActiveId =
          get().workspaceConfigurationActiveId - 1;
      }
    });
  },
  resetConfigurationById: (configId: string) => {
    set((state) => {
      const id = get().workspaceConfigurations.findIndex(
        (config) => config.id === configId
      );

      if (id === -1) {
        log.error(`resetConfiguration error id=${id} name=${configId}`);

        return;
      }

      state.workspaceConfigurations[id].lastAutoSavedConfig = copyHardConfig(
        get().workspaceConfigurations[id].lastManualSavedConfig
      );
    });
  },
  setActiveConfigurationById: (id: string) => {
    set((state) => {
      if (childWindowTabId) {
        return;
      }

      const idx = get().workspaceConfigurations.findIndex(
        (config) => config.id === id
      );

      if (idx === -1) {
        log.error(`setActiveConfigurationById error id=${id}`);

        return;
      }

      state.workspaceConfigurationActiveId = idx;
      state.earlyOpenWindowsTabsIds = getEarlyOpenWindowsTabsIds(
        get().workspaceConfigurations,
        get().workspaceConfigurationActiveId
      );
    });
  },
  manualSaveConfiguration: (id: number) => {
    set((state) => {
      const { lastAutoSavedConfig } = get().workspaceConfigurations[id];

      state.workspaceConfigurations[id].lastManualSavedConfig = {
        ...lastAutoSavedConfig,
        timeStamp: Date.now(),
      };
    });
  },
  addNewConfiguration: (key: string) => {
    set((state) => {
      const currentConfiguration =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const keyId = get().workspaceConfigurations.findIndex(
        ({ name }) => name === key
      );
      const isKeyExist = keyId !== -1;

      const newActiveId = isKeyExist
        ? keyId
        : get().workspaceConfigurations.length;

      const newConfig = copyHardConfig({
        ...currentConfiguration,
        timeStamp: Date.now(),
      });

      state.workspaceConfigurations[newActiveId] = {
        name: key,
        type: 'custom',
        id: v4(),
        lastAutoSavedConfig: newConfig,
        lastManualSavedConfig: newConfig,
      };
      state.workspaceConfigurationActiveId = newActiveId;
    });
  },
  importConfiguration: (configuration: WorkspaceConfigurationLS) => {
    const configWithModels = parseWorkspaceConfig(configuration);

    const countedConfig = {
      ...configWithModels,
      name: resolveSameNameCounter(
        get().workspaceConfigurations,
        configWithModels.name
      ),
    };

    set((state) => {
      state.workspaceConfigurations = [
        countedConfig,
        ...state.workspaceConfigurations,
      ];
      state.workspaceConfigurationActiveId = 0;
    });
  },
  setActiveLayoutKey: (newKey: number) => {
    if (childWindowTabId) {
      return;
    }

    set((state) => {
      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.activeLayoutKey = newKey;
    });
  },
  setLayoutJson: (key, newJson) =>
    set((state) => {
      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layouts[key] = newJson;
    }),
  updateNode: (nodeId, config, symbol) =>
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const activeLayoutKey = get().getActiveLayoutKey();
      const layoutLinks = currentWorkspaceConfig.layoutLinks;
      const model = currentWorkspaceConfig.models[activeLayoutKey];
      //@ts-expect-error
      const node: TabNode = model.getNodeById(nodeId || '');
      const prevConfig: WidgetLinkProps | null = node.getConfig();
      const tableProps: TableProps = {
        ...prevConfig?.tableProps,
        //@ts-expect-error
        ...config.tableProps,
      };

      const newConfig = {
        ...prevConfig,
        ...config,
        tableProps,
      };

      //Если виджет привязан к группе линковки, то обновляем все виджеты в группе
      if (
        !isUndefined(prevConfig?.link) &&
        !isUndefined(newConfig?.link) &&
        isEqual(prevConfig?.link, newConfig.link) &&
        //Обновляем только управляемые пропсы
        (!isUndefined(
          (config as unknown as WidgetLinkProps).tableProps?.accounts
        ) ||
          !isUndefined((config as unknown as WidgetLinkProps).idFi) ||
          !isUndefined((config as unknown as WidgetLinkProps).symbol) ||
          !isUndefined((config as unknown as WidgetLinkProps).linkedPrice))
      ) {
        state.workspaceConfigurations[
          get().workspaceConfigurationActiveId
        ].lastAutoSavedConfig.layoutLinks = updateLinkProps(
          activeLayoutKey,
          newConfig.link,
          layoutLinks,
          config as unknown as WidgetLinkProps,
          model,
          symbol
        );
      } else {
        let name = node.getName();
        const widget = node.getComponent() as Widget;

        if (symbol && widget && WidgetWithName.includes(widget)) {
          name = WidgetsNameMap.get(widget) || 'Вкладка';
        }

        model.doAction(
          Actions.updateNodeAttributes(nodeId, {
            name,
            config: newConfig,
          })
        );
        state.workspaceConfigurations[
          get().workspaceConfigurationActiveId
        ].lastAutoSavedConfig.layouts[activeLayoutKey] = model.toJson();
      }
    }),
  createNewLayout: () =>
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const { layouts } = currentWorkspaceConfig;
      const newLayoutKey = layouts.length;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.activeLayoutKey = newLayoutKey;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutNames[newLayoutKey] = `Рабочий стол ${
        newLayoutKey + 1
      }`;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutWindows[newLayoutKey] = false;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layouts[newLayoutKey] = initialJson;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.models[newLayoutKey] = Model.fromJson(initialJson);

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutLinks[newLayoutKey] = initialLayoutLink;
    }),
  removeLayout: (selectedKey: number) =>
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const { layouts, activeLayoutKey } = currentWorkspaceConfig;

      const isCurrentTabLast = activeLayoutKey === layouts.length - 1;

      // Смещаемся влево когда сидим на последней вкладке, чтобы не вылететь за пределы массива
      if (isCurrentTabLast) {
        state.workspaceConfigurations[
          get().workspaceConfigurationActiveId
        ].lastAutoSavedConfig.activeLayoutKey = activeLayoutKey - 1;
      }

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layouts.splice(selectedKey, 1);

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutNames.splice(selectedKey, 1);

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutWindows.splice(selectedKey, 1);

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutLinks.splice(selectedKey, 1);

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.models.splice(selectedKey, 1);
    }),
  setLayoutName: (key, value) => {
    set((state) => {
      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutNames[key] = value;
    });
  },
  setLayoutPosition: (key, positionChange) =>
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const totalTabsWidth =
        currentWorkspaceConfig.models.length * layoutTabWidth;
      const startPosition = key * layoutTabWidth;
      const newPosition = startPosition + positionChange;
      let newIndex;

      if (newPosition < 0) {
        newIndex = 0;
      } else if (newPosition > totalTabsWidth) {
        newIndex = currentWorkspaceConfig.models.length - 1;
      } else {
        newIndex =
          positionChange > 0
            ? Math.floor(newPosition / layoutTabWidth)
            : Math.ceil(newPosition / layoutTabWidth);
      }

      if (newIndex === key) {
        return;
      }

      move(currentWorkspaceConfig.layouts, key, newIndex);
      move(currentWorkspaceConfig.models, key, newIndex);
      move(currentWorkspaceConfig.layoutNames, key, newIndex);
      move(currentWorkspaceConfig.layoutWindows, key, newIndex);
      move(currentWorkspaceConfig.layoutLinks, key, newIndex);

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig = {
        ...currentWorkspaceConfig,
        activeLayoutKey: newIndex,
      };
    }),
  getActiveLayoutKey: () => {
    const currentWorkspaceConfig =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig;

    return childWindowTabId || currentWorkspaceConfig.activeLayoutKey;
  },
  linkToGroup: (
    nodeId: string,
    linkName: LinkName,
    params: WidgetLinkProps | null
  ) => {
    const activeLayoutKey = get().getActiveLayoutKey();
    const layoutLinks =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig.layoutLinks;
    const updateNode = get().updateNode;

    const newLayoutLinks = linkToGroup(
      nodeId,
      activeLayoutKey,
      linkName,
      layoutLinks,
      updateNode,
      params
    );

    set((state) => {
      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutLinks = newLayoutLinks;
    });
  },
  unlinkFromGroup: (nodeId: string, linkName: LinkName) => {
    set((state) => {
      const activeLayoutKey = get().getActiveLayoutKey();
      const layoutLinks =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig.layoutLinks;
      const updateNode = get().updateNode;
      const newLayoutLinks = unlinkFromGroup(
        nodeId,
        activeLayoutKey,
        linkName,
        layoutLinks,
        updateNode
      );

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig = {
        ...get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig,
        layoutLinks: newLayoutLinks,
      };
    });
  },

  setTradeFeedSettings: (
    idFI: number,
    nodeId?: string,
    idx?: number,
    volume?: number
  ) => {
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      let tradesFeedsSettings = currentWorkspaceConfig.tradesFeedsSettings;

      tradesFeedsSettings ??= {};

      if (!tradesFeedsSettings[idFI]) {
        tradesFeedsSettings = {
          ...tradesFeedsSettings,
          [idFI]: [{ volume: 0 }, { volume: 0 }, { volume: 0 }],
        };
      }

      const newSettings = tradesFeedsSettings[idFI].map<TradesFeedSettings>(
        (item) => {
          if (item.nodeId === nodeId) {
            return { volume: item.volume };
          }

          return item;
        }
      );

      if (idx !== undefined && volume !== undefined) {
        newSettings[idx] = {
          volume,
          nodeId,
        };
      }

      tradesFeedsSettings = {
        ...tradesFeedsSettings,
        [idFI]: newSettings,
      };

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.tradesFeedsSettings = tradesFeedsSettings;
    });
  },

  setVolumeFilterSettings: (
    idFI: number,
    volumeFilters: TVolumeFilterSetting[]
  ) => {
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.volumeFiltersSettings = {
        ...(currentWorkspaceConfig.volumeFiltersSettings || {}),
        [idFI]: volumeFilters,
      };
    });
  },
  setOrderBookFilterSettings: (
    idFI: number,
    filter: TOrderBookFilterSetting
  ) => {
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.orderBookFiltersSettings = {
        ...(currentWorkspaceConfig.orderBookFiltersSettings || {}),
        [idFI]: filter,
      };
    });
  },

  chartSetChartSetting: (nodeId: string, setting: ChartSetting) => {
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const chartStorage = currentWorkspaceConfig.chart;

      const prevSettings = chartStorage[nodeId];

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.chart = {
        ...chartStorage,
        [nodeId]: {
          ...prevSettings,
          ...setting,
          customSettings: {
            panels:
              setting.customSettings?.panels ||
              prevSettings?.customSettings?.panels,
            ...chartCustomSettingsFields.reduce<IChartCustomSettings>(
              (newSettings, field) => {
                return {
                  ...newSettings,
                  [field]: setting?.customSettings?.hasOwnProperty(field)
                    ? setting.customSettings?.[field]
                    : prevSettings?.customSettings?.[field],
                };
              },
              {}
            ),
          },
        },
      };
    });
  },

  chartRestoreSettings: (
    stxx?: IChartEngine,
    nodeId?: string,
    idFi?: number,
    symbol?: string,
    trueSymbol?: string
  ) => {
    const currentWorkspaceConfig =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig;

    // Если у нас нет необходимых данных для восстановления -> early return
    if (!stxx || !nodeId || !idFi || !symbol) {
      return;
    }

    const savedLayout: any =
      Boolean(nodeId) && currentWorkspaceConfig.chart[nodeId]?.layout;
    const pref =
      Boolean(nodeId) && currentWorkspaceConfig.chart[nodeId]?.preferences;
    const chartConfig = savedLayout?.symbols[0]?.symbolObject;
    const isDifferentFis =
      chartConfig &&
      !(idFi === chartConfig?.id && symbol === chartConfig?.symbol);

    let newLayout = savedLayout;

    if (isDifferentFis && savedLayout) {
      // Обновляем инструмент, сохранив настройки графика для окна
      const newSymbols = {
        ...savedLayout.symbols[0],
        symbol,
        symbolObject: {
          periodicity: { ...savedLayout.symbols[0].periodicity },
          id: idFi,
          symbol: symbol,
          trueSymbol: trueSymbol ?? symbol,
        },
      };

      newLayout = {
        ...savedLayout,
        symbols: [newSymbols, ...drop(savedLayout.symbols, 1)],
      };

      get().chartSetChartSetting(nodeId, {
        layout: newLayout,
      });
    }

    if (newLayout) {
      stxx.importLayout(newLayout, importOptions);
      stxx.zoomSet?.(newLayout.candleWidth, stxx.chart);
    }

    if (pref) {
      stxx.importPreferences(pref);
    }
  },

  chartRestore: (stxx: IChartEngine, symbol: string, nodeId?: string) => {
    const currentChartsConfig =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig.chart;

    if (stxx && nodeId) {
      const customSettings = currentChartsConfig[nodeId]?.customSettings;

      if (customSettings?.panels) {
        Object.entries(customSettings.panels).forEach(([panelName, object]) => {
          if (stxx?.panels && stxx?.panels[panelName]) {
            // stxx.modifyPanel - почему-то не работает
            // @ts-expect-error
            stxx.panels[panelName].yAxis.zoom = object.yAxis.zoom;
          }
        });
      }

      const drawings =
        Boolean(nodeId) && currentChartsConfig[nodeId]?.drawings?.[symbol];

      if (drawings && isArray(drawings) && !stxx.drawingObjects?.length) {
        stxx.importDrawings(drawings);
        stxx.draw();
      }

      const symbols =
        // @ts-expect-error
        currentChartsConfig[nodeId]?.layout?.symbols?.slice(1) || [];

      symbols.forEach((s, i) => {
        const seriesList = Object.keys(
          stxx?.panels?.chart?.chart?.series || []
        ).filter((symbol) => !symbols.find(({ id }) => id === symbol));

        stxx?.addSeries(s.id, {
          isComparison: true,
          shareYAxis: true,
          color:
            CIQ.UI.defaultSwatchColors[
              (seriesList.length % CIQ.UI.defaultSwatchColors.length) + i
            ],
          width: 0.5,
          gapDisplayStyle: 'true',
          symbolObject: s.symbolObject,
        });
      });

      const theme = currentChartsConfig[nodeId]?.theme || '';

      if (CHART_DEFAULT_THEMES.includes(theme)) {
        stxx?.themes?.setDefaultTheme?.(theme);
      } else if (
        Object.keys(stxx?.themes?.params.customThemes || {}).includes(theme)
      ) {
        stxx?.themes?.setCustomTheme?.(theme);
      }
    }
  },

  chartSaveCustomSettings: (
    stxx?: IChartEngine,
    nodeId?: string,
    customPreferences?: Record<string, any>
  ) => {
    if (stxx && nodeId && stxx.panels) {
      const customSettings = Object.values<CIQ.ChartEngine.Panel>(
        stxx.panels
      ).reduce(
        (acc: IChartCustomSettings, panel) => {
          if (!acc.panels) {
            acc.panels = {};
          }

          if (!panel.name) {
            return acc;
          }

          acc.panels[panel.name] = {
            name: panel.name,
            yAxis: { zoom: panel.yAxis?.zoom || 0 },
          };

          return acc;
        },
        { panels: {}, ...customPreferences }
      );

      get().chartSetChartSetting(nodeId, {
        customSettings,
      });
    }
  },

  chartSaveLayout: (stxx?: IChartEngine, nodeId?: string) => {
    if (stxx && nodeId) {
      get().chartSetChartSetting(nodeId, {
        layout: stxx.exportLayout(true),
      });
    }
  },

  chartSavePreferences: (stxx?: IChartEngine, nodeId?: string) => {
    if (stxx && nodeId) {
      get().chartSetChartSetting(nodeId, {
        preferences: stxx.exportPreferences(),
      });
    }
  },

  chartSaveDrawings: (stxx: IChartEngine, symbol: string, nodeId?: string) => {
    const currentWorkspaceConfig =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig;

    if (stxx && nodeId) {
      const prevDrawings = currentWorkspaceConfig.chart[nodeId]?.drawings;
      const drawings =
        prevDrawings && !isEmpty(prevDrawings) && isObject(prevDrawings)
          ? { ...(prevDrawings as object), [symbol]: stxx.exportDrawings() }
          : { [symbol]: stxx.exportDrawings() };

      get().chartSetChartSetting(nodeId, {
        drawings,
        layout: stxx.exportLayout(true),
      });
    }
  },

  chartSaveTheme: (nodeId: string, theme: string) => {
    if (nodeId) {
      get().chartSetChartSetting(nodeId, {
        theme,
      });
    }
  },

  chartGetChartSetting: <K extends keyof ChartSetting>(
    nodeId: string,
    setting: K
  ): ChartSetting[K] | undefined => {
    const currentWorkspaceConfig =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig;
    const chartStorage = currentWorkspaceConfig.chart;

    return chartStorage[nodeId]?.[setting];
  },

  chartDeleteChartSetting: (nodeId: string) => {
    set((state) => {
      delete state.workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig.chart[nodeId];
    });
  },

  sanitizeUserData() {
    // Этот метод пробегается по всем конфигам всех виджетов и проверяет - корректные ли в них
    // счета пользователя. Могут быть ситуации когда на одном компе логиниться сначала один юзер
    // потом второй юзер и тогда второй видит сохраненые в виджетах счтета от первого юзера

    // потенциально могут появиться и другие "протуухшие данные"
    const { workspaceConfigurations, subAccountRazdel } = get();
    const subaccounts = selectUserSubAccounts(subAccountRazdel);
    let needToSave = false;
    const models: Model[] = [];

    workspaceConfigurations.forEach((config) => {
      config.lastAutoSavedConfig.models.forEach((model) => models.push(model));
      config.lastManualSavedConfig.models.forEach((model) =>
        models.push(model)
      );
    });

    models.forEach((model) => {
      model.visitNodes((node) => {
        const type = node.getType();

        if (type === 'tab') {
          const tabNode = node as TabNode;
          const config = tabNode.getConfig();
          const name = tabNode.getName();
          const id = tabNode.getId();

          if (config?.tableProps?.accounts) {
            const props = config.tableProps as TableProps; // тип приводиться на случай будущих рефакторингов
            // нашли какие то счета
            const accounts = props.accounts;
            let allSubaccountsIsValid = true;

            accounts?.forEach((account) => {
              if (!subaccounts.includes(account)) {
                allSubaccountsIsValid = false;
              }
            });

            // нашли чужой аккаунт или удаленны. Сбрасываем счета в этом виджете
            if (!allSubaccountsIsValid) {
              const { accounts, ...rest } = props;

              model.doAction(
                Actions.updateNodeAttributes(id, {
                  name,
                  config: {
                    ...config,
                    tableProps: rest,
                  },
                })
              );

              needToSave = true;
            }
          }

          if (needToSave) {
            const store = get();

            store.forceSyncLayoutModelsAndJsons();
          }
        }
      });
    });
  },

  forceSyncLayoutModelsAndJsons() {
    // У нас в сторе есть две структуры данных отвечающие за виджеты: это ILayoutJSON и Model
    // Жсон по сути просто сериаилзованая модель, а модель это инстанс класс FlexLayout
    // Обычно у нас такой флоу когда что-то меняется в конфигах: вызывается метод updateNode
    // который меняет объектную модель, объектная модель вызывает колбэк в файле Layout.tsx и
    // обновит там жсон, который в свою очередь будет сохранен в локал сторейдж

    // этот же метод перебирает все модели и каждую сериализует в жсон и каждый жсон сохраняет
    // метода нужен для кейса когда модель поменялась до того как компонент Layout.tsx был срендерен
    set((state) => {
      const workspaces: WorkspaceConfig[] = [];

      state.workspaceConfigurations.forEach((workspace) => {
        workspaces.push(workspace.lastAutoSavedConfig);
        workspaces.push(workspace.lastManualSavedConfig);
      });

      for (let i = 0; i < workspaces.length; i++) {
        const workspace = workspaces[i];
        // workspace.layouts = [];

        workspace.models.forEach((model) => {
          const json = model.toJson();
          const id = json.layout.id;

          if (!id) {
            return;
          }

          const jsonIndex = workspace.layouts.findIndex(
            (old) => old.layout.id === id
          );

          if (jsonIndex > -1) {
            workspace.layouts[jsonIndex] = json;
          }
        });
      }
    });
  },

  // Вызываем открытие первого дочернего окна
  //  Невозможно одновременно открыть несколько окон,
  //  потому что после открытия первого фокус переходит на следующее окно
  reopenWorkspaceWindows() {
    const earlyOpenWindowsTabsIds = get().earlyOpenWindowsTabsIds;

    const firstWindowTabId = earlyOpenWindowsTabsIds[0];

    get().openNewWindow(firstWindowTabId, true);
  },

  // Каждое дочернее окно - вызывает открытие следующего дочернего окна
  reopenOtherWorkspaceWindows() {
    const isHaveToOpenNextChildWindow = window.sessionStorage.getItem(
      'have_to_open_next_child_window'
    );

    if (!isHaveToOpenNextChildWindow) {
      return;
    }

    const earlyOpenWindowsTabsIds = get().earlyOpenWindowsTabsIds;

    const currentTabId = parseInt(
      window.sessionStorage.getItem('child_window_tab_id') || ''
    );

    if (!Number.isNaN(currentTabId)) {
      const currentTabIndex = earlyOpenWindowsTabsIds.findIndex(
        (v) => v === currentTabId
      );
      const nextTabId = earlyOpenWindowsTabsIds[currentTabIndex + 1];

      if (nextTabId !== undefined) {
        get().openNewWindow(nextTabId, true);
        window.sessionStorage.removeItem('have_to_open_next_child_window');
      }
    }
  },

  resetWorkspaceWindows() {
    set((state) => {
      const layoutWindows =
        state.workspaceConfigurations[state.workspaceConfigurationActiveId]
          .lastAutoSavedConfig.layoutWindows || [];

      state.workspaceConfigurations[
        state.workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutWindows = layoutWindows.map(() => false);
    });
  },

  openNewWindow(tabId: number, isChainChildWindows?: boolean) {
    const tabWindow = window.open(window.location.href, '_blank', 'popup');

    if (tabWindow) {
      tabWindow.sessionStorage.setItem('child_window_tab_id', tabId.toString());

      if (isChainChildWindows) {
        tabWindow.sessionStorage.setItem(
          'have_to_open_next_child_window',
          'true'
        );
      }

      window.sessionStorage.setItem(
        wasShownReopenWorkspaceWindowsQuestionSessionKey,
        'true'
      );

      set((state) => {
        const layoutWindows =
          state.workspaceConfigurations[state.workspaceConfigurationActiveId]
            .lastAutoSavedConfig.layoutWindows || [];

        layoutWindows[tabId] = true;

        state.workspaceConfigurations[
          state.workspaceConfigurationActiveId
        ].lastAutoSavedConfig.layoutWindows = layoutWindows;
      });
    }
  },

  createNewConfiguration(
    config: WorkspaceConfiguration,
    callback?: () => void
  ) {
    set((state) => {
      const keyId = get().workspaceConfigurations.findIndex(
        ({ id }) => id === config.id
      );
      const isKeyExist = keyId !== -1;

      const newActiveId = isKeyExist
        ? keyId
        : get().workspaceConfigurationActiveId + 1;

      state.workspaceConfigurations[newActiveId] = {
        name: config.name,
        id: config.id,
        type: config.type,
        lastAutoSavedConfig: {
          ...config.lastAutoSavedConfig,
          models: createLayoutModels(config.lastAutoSavedConfig.layouts),
        },
        lastManualSavedConfig: {
          ...config.lastAutoSavedConfig,
          models: createLayoutModels(config.lastAutoSavedConfig.layouts),
        },
      };

      state.workspaceConfigurationActiveId = newActiveId;

      if (callback) {
        callback();
      }
    });
  },

  setTrackPriceDefaultSettings(settings: TrackPriceCommonSettings) {
    set((state) => {
      const activeId = get().workspaceConfigurationActiveId;

      merge<WorkspaceConfiguration, PartialDeep<WorkspaceConfiguration>>(
        state.workspaceConfigurations[activeId],
        {
          lastAutoSavedConfig: {
            trackPriceSettings: {
              defaultSettings: settings,
            },
          },
        }
      );
    });
  },

  setTrackPriceSettings(trackPrice: TrackPrice, settings: TrackPriceSettings) {
    set((state) => {
      const id = trackPrice.IdTrackPrice.toString();
      const activeId = get().workspaceConfigurationActiveId;

      merge<WorkspaceConfiguration, PartialDeep<WorkspaceConfiguration>>(
        state.workspaceConfigurations[activeId],
        {
          lastAutoSavedConfig: {
            trackPriceSettings: {
              settingsByTrackPriceId: {
                [id]: settings,
              },
            },
          },
        }
      );
    });
  },

  setFavorites(favorites: FavoritesConfiguration) {
    set((state) => {
      const activeId = get().workspaceConfigurationActiveId;

      state.workspaceConfigurations[activeId].lastAutoSavedConfig.favorites = {
        ...(state.workspaceConfigurations[activeId].lastAutoSavedConfig
          .favorites || {}),
        ...favorites,
      };
    });
  },

  setChartThemes(themes) {
    set((state) => {
      const activeId = get().workspaceConfigurationActiveId;

      state.workspaceConfigurations[activeId].lastAutoSavedConfig.chartThemes =
        themes;
    });
  },
});

function resolveSameNameCounter(
  configurations: WorkspaceConfiguration[],
  newName
) {
  let number = 0;
  let uniqueName = newName;

  while (true) {
    const has = configurations.some((config) => config.name === uniqueName);

    if (has) {
      uniqueName = `${newName} (${++number})`;
    } else {
      return uniqueName;
    }
  }
}
