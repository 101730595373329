import { Button } from '@alfalab/core-components/button';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Draft } from '@terminal/core/lib/rest/lkDrafts';

export interface DraftModalProps {
  onClose: () => void;
  data?: Draft;
  isRejectLoading: boolean;
  isConfirmLoading: boolean;
  onReject: () => void;
  onConfirm: () => void;
}

export const DraftModal = ({
  data,
  onClose,
  isRejectLoading,
  isConfirmLoading,
  onReject,
  onConfirm,
}: DraftModalProps) => {
  return (
    <FunctionalModalSidebar open onClose={onClose}>
      <Space fullWidth size={20}>
        <Space fullWidth size={4}>
          <Typography.Text color="tertiary" view="secondary-small">
            Тема
          </Typography.Text>
          <Typography.Text color="secondary">
            {data?.orderDraftThemeName}
          </Typography.Text>
        </Space>
        <Space fullWidth size={4}>
          <Typography.Text color="tertiary" view="secondary-small">
            Поручение
          </Typography.Text>
          <Typography.Text color="secondary">{data?.textOrder}</Typography.Text>
        </Space>
        <Space fullWidth direction="horizontal" size={16}>
          <Button
            loading={isRejectLoading}
            disabled={isConfirmLoading}
            onClick={onReject}
            block
            size="xxs"
            view="outlined"
          >
            Отклонить поручение
          </Button>
          <Button
            loading={isConfirmLoading}
            disabled={isRejectLoading}
            onClick={onConfirm}
            block
            size="xxs"
            view="secondary"
          >
            Подать поручение
          </Button>
        </Space>
      </Space>
    </FunctionalModalSidebar>
  );
};
