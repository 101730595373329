import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import classes from './InstrumentDataRow.module.css';

interface Props {
  title: string;
  value: ReactNode;
  className?: string;
}

export const InstrumentDataRow: FC<Props> = ({ title, value, className }) => {
  return (
    <div className={cn([classes.dataRowContainer, className])}>
      {title && (
        <Typography.Text color="secondary" view="secondary-large">
          {title}
        </Typography.Text>
      )}
      {value && (
        <Typography.Text color="primary" view="secondary-large">
          {value}
        </Typography.Text>
      )}
    </div>
  );
};
