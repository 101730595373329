import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon';
import { ArrowsLeftRightThinMIcon } from '@alfalab/icons-glyph/ArrowsLeftRightThinMIcon';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { countDecimals } from '@terminal/core/lib/format';
import {
  MoneyHistoryItem,
  MoneyTransferType,
} from '@terminal/core/lib/rest/lkMoney';

import { MONEY_TRANSFER_TYPE_TEXT } from './constants';

import tablestyles from '../tables.module.css';
import styles from './HistoryItem.module.css';
import historytabtyles from './historyTables.module.css';

const MONEY_TRANSFER_TYPE_ICON = {
  EXTRA_IN: ArrowDownCompactXsIcon,
  EXTRA_OUT: ArrowUpCompactXsIcon,
  INTRA: ArrowsLeftRightThinMIcon,
};

export const getAmountFormated = (
  amount: number,
  currency: string,
  showDirection?: boolean
) => {
  const decimals = countDecimals(amount);
  const minority = Math.pow(10, decimals);
  return formatAmount({
    value: showDirection
      ? (amount || 0) * minority
      : (Math.abs(amount) || 0) * minority,
    currency: currency as CurrencyCodes,
    minority: minority,
    view: 'withZeroMinorPart',
  }).formattedWithCurrency;
};

interface HistoryItemProps {
  item: MoneyHistoryItem;
  odd?: boolean;
  onClick?: (item: MoneyHistoryItem) => void;
}

export const HistoryItem = ({ item, odd, onClick }: HistoryItemProps) => {
  const target = item.target || item.source;

  const getPlaceShortName = (name: string) => {
    switch (name) {
      case 'Рынок ценных бумаг':
        return 'РЦБ';
      case 'Фондовый рынок':
        return 'ФР';
      case 'Срочный рынок':
        return 'СР';
      default:
        return name
          .split(' ')
          .reduce(
            (res: string, word: string) =>
              res + word.replace(/[^А-Яа-я]/, '').substring(0, 1),
            ''
          )
          .toLocaleUpperCase();
    }
  };

  const getTime = (date: string) => {
    return format(parseISO(date), 'HH:mm:ss');
  };

  const getDirectionType = (type: MoneyTransferType) => {
    const Icon = MONEY_TRANSFER_TYPE_ICON[type];
    const text = MONEY_TRANSFER_TYPE_TEXT[type];

    return text && Icon ? (
      <span className={styles.direction}>
        <Icon
          className={styles.directionIcon}
          {...(type === 'INTRA' ? { width: 16, height: 16 } : {})}
        />
        <span className={styles.directionText}>{text}</span>
      </span>
    ) : (
      <></>
    );
  };

  return (
    <div
      className={cn(styles.item, tablestyles.row, odd && tablestyles.rowOdd)}
      onClick={onClick ? () => onClick(item) : undefined}
    >
      <div className={cn(tablestyles.cell, historytabtyles.cellAccount)}>
        {target?.accCode}
      </div>
      <div
        className={cn(
          tablestyles.cell,
          tablestyles.cellFade,
          historytabtyles.cellType
        )}
      >
        {getDirectionType(item.type)}
      </div>
      <div className={cn(tablestyles.cell, historytabtyles.cellAmount)}>
        {getAmountFormated(target?.amount || 0, item.currency)}
      </div>
      <div className={cn(tablestyles.cell, historytabtyles.cellPlace)}>
        {getPlaceShortName(target?.placeName || '')}
      </div>
      <div className={cn(tablestyles.cell, historytabtyles.cellTime)}>
        {getTime(item.date)}
      </div>
    </div>
  );
};
