import { useState } from 'react';
import { Loader } from '@alfalab/core-components/loader';
import { Field } from '@alfalab/core-components/select/components/field';
import {
  SelectDesktop,
  SelectDesktopProps,
} from '@alfalab/core-components/select/desktop';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';

import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import {
  OrderFormApplied,
  useDocumentsFormSelect,
} from '../../../../entities/Documents';
import { useFeatureSettings } from '../../../../shared';
import { DocumentOrderForm } from '../../components/DocumentOrderForm';
import { ReportsForm } from '../../components/ReportsForm';

import { useInit } from '../../../hooks';

import { DocumentsSubpage } from '../../types';

interface OrderProps {
  treaty: Treaty | null;
  onSubpageChange: (subpage: DocumentsSubpage) => void;
}

export const Order = ({ treaty, onSubpageChange }: OrderProps) => {
  const [formApplied, setFormApplied] = useState(false);
  const { documents, reports, activeForm, shouldDisplayReportSelector } =
    useDocumentsFormSelect(treaty);

  const { data: init } = useInit();
  const { data: featureSettings } = useFeatureSettings();

  const handleDocumentChange: SelectDesktopProps['onChange'] = ({
    selected,
  }) => {
    documents.setSelectedId(selected?.key || '');
  };

  const handleReportChange: SelectDesktopProps['onChange'] = ({ selected }) => {
    reports.setSelectedId(selected?.key || '');
  };

  if (formApplied) {
    return (
      <OrderFormApplied
        primaryButtonLabel="Заказать другой документ"
        onPrimaryButtonClick={() => {
          documents.setSelectedId('');
          setFormApplied(() => false);
        }}
        secondaryButtonLabel="Перейти в историю"
        onSecondaryButtonClick={() => {
          setFormApplied(() => false);
          onSubpageChange(DocumentsSubpage.History);
        }}
      />
    );
  }

  return (
    <Space direction="vertical" fullWidth>
      <SelectDesktop
        label="Документ"
        labelView="outer"
        placeholder="Выберите из списка"
        size="s"
        block
        disabled={documents.loading}
        options={documents.options}
        selected={documents.selectedId}
        onChange={handleDocumentChange}
        hint={documents.selectedTheme?.tooltip}
        Field={(props: FieldProps) => (
          <Field {...props} leftAddons={documents.loading && <Loader />} />
        )}
      />

      {shouldDisplayReportSelector && (
        <SelectDesktop
          label="Тип отчёта"
          labelView="outer"
          placeholder="Выберите из списка"
          size="s"
          block
          disabled={reports.loading}
          options={reports.options}
          selected={reports.selectedId}
          onChange={handleReportChange}
          hint={reports.selectedTheme?.tooltip}
          Field={(props: FieldProps) => (
            <Field {...props} leftAddons={reports.loading && <Loader />} />
          )}
        />
      )}

      {activeForm === 'report' && (
        <ReportsForm
          treaty={treaty}
          theme={reports.selectedId}
          reportsTheme={reports.rawData?.themes}
          address={reports.rawData?.address}
          email={reports.rawData?.email}
          emailConfirmed={reports.rawData?.emailConfirmed || false}
          featureSettings={featureSettings}
        />
      )}

      {activeForm === 'depoReport' && (
        <DocumentOrderForm
          treaty={treaty}
          theme={reports.selectedId}
          documentsTheme={reports.rawData?.themes}
          documentsThemePending={reports.loading}
          address={reports.rawData?.address}
          depoAccounts={reports.rawData?.depoAccounts}
          email={reports.rawData?.email}
          emailConfirmed={reports.rawData?.emailConfirmed || false}
          onFormApplied={setFormApplied}
          formType={activeForm}
        />
      )}

      {activeForm === 'document' && (
        <DocumentOrderForm
          treaty={treaty}
          theme={documents.selectedId}
          documentsTheme={documents.rawData?.themes}
          documentsThemePending={documents.loading}
          address={documents.rawData?.address}
          depoAccounts={documents.rawData?.depoAccounts}
          email={init?.email}
          emailConfirmed={init?.emailConfirmed || false}
          formType={activeForm}
        />
      )}
    </Space>
  );
};
