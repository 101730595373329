// Вкладка Баланс
import { Widget } from '@terminal/core/types/layout';

import {
  BALANCES_BALANCES_CONFIG,
  BALANCES_OPERATIONS_CONFIG,
  COMMON_ORDERS_CONFIG,
  POSITIONS_COMMON_CONFIG,
} from './configs';

import { Layout } from '../../../../model/types/layout';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

const balancesTab = new Tab(Widget.BALANCE, BALANCES_BALANCES_CONFIG);
const balancesTabSet = new TabSet(50);
const positionsTab = new Tab(Widget.POSITIONS, POSITIONS_COMMON_CONFIG);

balancesTabSet.addChildren([balancesTab, positionsTab]);

const operationsTab = new Tab(Widget.OPERATIONS, BALANCES_OPERATIONS_CONFIG);

const ordersTab = new Tab(Widget.ORDERS, COMMON_ORDERS_CONFIG);

const ordersBalanceTabSet = new TabSet(50);

ordersBalanceTabSet.addChildren([ordersTab, operationsTab]);

const topBalanceRow = new Row(50);

topBalanceRow.addChildren([balancesTabSet]);
const bottomBalanceRow = new Row(50);

bottomBalanceRow.addChildren([ordersBalanceTabSet]);

const verticalRow = new Row(100);

verticalRow.addChildren([topBalanceRow, bottomBalanceRow]);

const balanceRow = new Row(100);

balanceRow.addChildren([verticalRow]);

export const balanceLayout = new Layout('Баланс', balanceRow);
