import shallow from 'zustand/shallow';

import { Settings } from '../lib/db/settings';
import { WatchLists } from '../lib/db/watchLists';
import { WorkspaceConfigurations } from '../lib/db/workspaceConfiguration';
import { WorkspaceConfiguration } from './slices/workspaceConfigurationsSlice';
import { useStore } from './useStore';

let unsubscribeWorkspaces;
let unsubscribeWatchLists;
let unsubscribeSettings;
export function initWorkspaceSubscribes() {
  unsubscribeWorkspaces = useStore.subscribe(
    (state) =>
      [state.workspaceConfigurations, state.workspaceConfigurationActiveId] as [
        WorkspaceConfiguration[],
        number
      ],
    ([workspaceConfigurations, workspaceConfigurationActiveId]) => {
      const parsedWorkspaceConfigurations = workspaceConfigurations.map(
        (workspaceConfiguration) => ({
          ...workspaceConfiguration,
          lastAutoSavedConfig: {
            ...workspaceConfiguration.lastAutoSavedConfig,
            models: undefined,
          },
          lastManualSavedConfig: {
            ...workspaceConfiguration.lastManualSavedConfig,
            models: undefined,
          },
        })
      );

      WorkspaceConfigurations.save(
        parsedWorkspaceConfigurations,
        workspaceConfigurationActiveId
      );
    }
  );
  unsubscribeWatchLists = useStore.subscribe(
    (state) => state.watchLists,
    (watchLists) => {
      WatchLists.save(watchLists);
    },
    { equalityFn: shallow }
  );
  unsubscribeSettings = useStore.subscribe(
    (state) => state.settings,
    (settings) => {
      Settings.save(settings);
    },
    { equalityFn: shallow }
  );
}

const unsubscribeAll = () => {
  unsubscribeWorkspaces?.();
  unsubscribeWatchLists?.();
  unsubscribeSettings?.();
};
export { unsubscribeAll };
export { unsubscribeSettings };
export { unsubscribeWatchLists };
export { unsubscribeWorkspaces };
