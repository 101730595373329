import {
  endOfDay,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
// eslint-disable-next-line
import { CalendarInputProps } from '@alfalab/core-components/calendar-input';
import { CalendarRange } from '@alfalab/core-components/calendar-range';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop';

import { getStringDate } from '@terminal/core/lib/format';
import {
  dateFilters,
  DateFilterType,
  DateFilterValue,
  FiltersNameMap,
} from '@terminal/core/types/operation';

import styles from './DateFilter.module.css';

interface Props {
  dateFilter: DateFilterValue | null;
  setDateFilter: (value: DateFilterValue | null) => void;
}

export const DateFilter = ({ dateFilter, setDateFilter }: Props) => {
  const renderedFilters = dateFilters.map((type) => {
    const checked = type === dateFilter?.type;

    return (
      <Tag
        checked={checked}
        key={type}
        onClick={() => {
          if (checked) {
            saveFilter(DateFilterType.NONE);
          } else {
            saveFilter(type);
          }
        }}
        size="s"
        view="filled"
      >
        {FiltersNameMap.get(type)}
      </Tag>
    );
  });

  const saveFilter = (
    type: DateFilterType,
    dateFrom?: number | null,
    dateTo?: number | null,
    from?: string,
    to?: string
  ) => {
    let valueTo = dateFilter?.valueTo,
      valueFrom = dateFilter?.valueFrom;
    const currentDate = startOfDay(new Date());

    switch (type) {
      case DateFilterType.TODAY:
        valueFrom = {
          value: getStringDate(currentDate),
          date: currentDate.getTime(),
        };
        valueTo = {
          value: getStringDate(endOfDay(new Date())),
          date: endOfDay(new Date()).getTime(),
        };
        break;
      case DateFilterType.YESTERDAY:
        valueFrom = {
          value: getStringDate(subDays(currentDate, 1)),
          date: subDays(currentDate, 1).getTime(),
        };
        valueTo = {
          value: getStringDate(endOfDay(new Date())),
          date: endOfDay(new Date()).getTime(),
        };
        break;
      case DateFilterType['5DAYS']:
        valueFrom = {
          value: getStringDate(subDays(currentDate, 5)),
          date: subDays(currentDate, 5).getTime(),
        };
        valueTo = {
          value: getStringDate(endOfDay(new Date())),
          date: endOfDay(new Date()).getTime(),
        };
        break;
      case DateFilterType['30DAYS']:
        valueFrom = {
          value: getStringDate(subMonths(currentDate, 1)),
          date: subMonths(currentDate, 1).getTime(),
        };
        valueTo = {
          value: getStringDate(endOfDay(new Date())),
          date: endOfDay(new Date()).getTime(),
        };
        break;
      case DateFilterType.RANGE:
        if (dateTo) {
          valueTo = { value: to, date: dateTo };
        } else {
          valueTo = { value: to };
        }

        if (dateFrom) {
          valueFrom = { value: from, date: dateFrom };
        } else {
          valueFrom = { value: from };
        }

        break;
      default:
        setDateFilter(null);

        return;
    }

    setDateFilter({ type, valueTo, valueFrom });
  };

  const getInputProps = (inputType: 'from' | 'to'): CalendarInputProps => ({
    size: 's',
    calendarProps: {
      minDate: inputType === 'to' ? dateFilter?.valueFrom?.date : undefined,
      maxDate: inputType === 'from' ? dateFilter?.valueTo?.date : undefined,
      selectedFrom: dateFilter?.valueFrom?.date,
      selectedTo: dateFilter?.valueTo?.date,
    },
  });

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.filters}>{renderedFilters}</div>
      </div>

      <CalendarRange
        valueTo={dateFilter?.valueTo?.value}
        valueFrom={dateFilter?.valueFrom?.value}
        defaultMonth={startOfMonth(new Date()).getTime()}
        calendarPosition="popover"
        onChange={({ dateFrom, dateTo, valueFrom, valueTo }) => {
          if (
            !(
              dateFilter?.valueFrom?.value === valueFrom &&
              dateFilter?.valueTo?.value === valueTo
            )
          ) {
            saveFilter(
              DateFilterType.RANGE,
              dateFrom,
              dateTo,
              valueFrom,
              valueTo
            );
          }
        }}
        inputFromProps={getInputProps('from')}
        inputToProps={getInputProps('to')}
      />
    </>
  );
};
