import React, { SVGProps, VFC } from 'react';

export type MoonIconProps = SVGProps<SVGSVGElement>;

export const MoonIcon: VFC<MoonIconProps> = (props) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m1.774625,5.5c0,-2.15736 1.74888,-3.90625 3.90624,-3.90625c0.04127,0 0.0824,0.00064 0.12339,0.00191c0.13973,0.00435 0.26649,0.08303 0.33238,0.20633c0.06589,0.1233 0.06086,0.27241 -0.01318,0.39099c-0.28045,0.44917 -0.44259,0.97966 -0.44259,1.54921c0,1.61802 1.31167,2.92968 2.92969,2.92968c0.06667,0 0.13276,-0.00222 0.19821,-0.00658c0.13937,-0.00929 0.2731,0.05654 0.35073,0.17267c0.04512,0.0675 0.0673,0.14602 0.06581,0.22454c-0.00108,0.05656 -0.01443,0.11312 -0.04034,0.16556c-0.63714,1.28975 -1.9665,2.17819 -3.5041,2.17819c-2.15736,0 -3.90624,-1.74889 -3.90624,-3.90625zm3.36706,-3.07866c-1.46894,0.25548 -2.58582,1.53664 -2.58582,3.07866c0.00001,1.72589 1.39911,3.125 3.125,3.125c0.9998,0 1.89043,-0.46949 2.46273,-1.20098c-1.82907,-0.22964 -3.24398,-1.7905 -3.24398,-3.68183c0,-0.46489 0.08565,-0.91027 0.24207,-1.32085z" />
    </svg>
  );
};
