import orderBy from 'lodash/orderBy';
import { useCallback, useMemo, useState } from 'react';

import { Sort } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { useWidgetContext } from '../index';

interface Props<T> {
  sort?: Sort;
  data: T[];
  setSort?: (sort?: Sort) => void;
  onOrder?: (data: T, key?: string) => unknown;
}

// Хук для сортировки данных в колонке таблицы
// Параметры сортировки могут сохраняться как в настройки таблицы, так и в локальный стейт (например в модалке выбора фин инструмента)
export const useSortTable = <T>({ sort, data, setSort, onOrder }: Props<T>) => {
  const [localSort, setLocalSort] = useState<Sort | undefined>(sort);
  const isLocalSort = !setSort;
  const sortState = useMemo(
    () => (isLocalSort ? localSort : sort),
    [isLocalSort, localSort, sort]
  );

  const { useSettings } = useWidgetContext();
  const {
    defaultValues: { selectedDisplayInstrumentType },
  } = useSettings();

  const sortKey = useMemo(() => {
    return sortState?.key === TableColumnKey.SymbolObject &&
      selectedDisplayInstrumentType === 'name'
      ? TableColumnKey.NameObject
      : sortState?.key;
  }, [selectedDisplayInstrumentType, sortState?.key]);

  const onSort = useCallback(
    (key: string) => {
      if (sortState?.key === key) {
        setSort
          ? setSort({ key, asc: !sortState?.asc })
          : setLocalSort({ key, asc: !sortState?.asc });
      } else {
        setSort
          ? setSort({ key, asc: true })
          : setLocalSort({ key, asc: true });
      }
    },
    [setSort, sortState]
  );

  const sortedData = useMemo(() => {
    if (sortState) {
      return orderBy(
        data,
        onOrder ? (item) => onOrder(item, sortKey) : sortKey,
        sortState.asc ? 'asc' : 'desc'
      );
    } else {
      return data;
    }
  }, [sortState, data, onOrder, sortKey]);

  return { sort: sortState, onSort, sortedData };
};
