import { FC } from 'react';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import styles from './InfoButton.module.css';

export const InfoButton: FC = (props) => {
  const { children } = props;

  return (
    <Tooltip
      contentClassName={styles.content}
      targetClassName={styles.target}
      position="top"
      trigger="hover"
      content={children}
      fallbackPlacements={['left', 'right']}
    >
      <InformationCircleLineSIcon className={styles.icon} />
    </Tooltip>
  );
};
