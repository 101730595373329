import { APP_URI, NEW_APP_DOMAIN } from '../env';
import { localStorage } from '../lib/storages';

import {
  InstrumentItemsTable,
  MarketBoardItemsTable,
  ObjectItemsTable,
} from '../types/core';
import { WatchList, WatchListItem } from '../types/watchList';

class LocalStorageBackup {
  private readonly queryParamName = 'localStorageBackup';

  private readonly keys: string[] = [
    'authPhone',
    'onboarding-done',
    'hideSuggestAddStandaloneApp',
    'selected-watchlist-id',
    'PREFERRED_THEME',
    'settings',
  ];

  private readonly watchListsKey = 'watchLists';

  private readonly watchListsBackupKey = 'watchListsBackup';

  private readonly backupDoneKey = 'backupDone';

  /**
   * @returns ссылку для перехода в приложение на новом домене.
   * Ссылка содержит данные для восстановления состояния приложения на новом домене
   * */
  public getRedirectUrl(pathname?: string): string {
    const url = new URL(APP_URI);

    url.hostname = NEW_APP_DOMAIN;
    url.pathname = pathname ?? '';
    url.searchParams.append(this.queryParamName, this.serialize());

    return url.toString();
  }

  /**
   * Восстанавливает состояние старого приложения на новом домене
   * @param cb функция, которая будет вызвана в случае необходимости редиректа
   * */
  public hydrate(cb?: (url: string) => void): void {
    const url = new URL(window.location.href);
    const rawData = url.searchParams.get(this.queryParamName) ?? '';

    if (!rawData) {
      return;
    }

    url.searchParams.delete(this.queryParamName);

    if (this.isBackupDone()) {
      cb?.(url.toString());

      return;
    }

    try {
      const data = JSON.parse(rawData);

      for (const key of this.keys) {
        const value = data[key];

        if (value !== undefined) {
          localStorage.setItem(key, value);
        }
      }

      localStorage.setItem(
        this.watchListsBackupKey,
        this.hydrateWatchLists(data[this.watchListsKey])
      );
    } catch {
    } finally {
      this.markBackupAsDone();
      cb?.(url.toString());
    }
  }

  /**
   * @returns списки инструментов, восстановленные из бэкапа
   * */
  public getWatchListsBackup(
    instrumentsTable: InstrumentItemsTable,
    objectsTable: ObjectItemsTable,
    marketBoardsTable: MarketBoardItemsTable
  ): WatchList[] {
    const watchLists = localStorage.getItem(this.watchListsBackupKey, []);

    try {
      return watchLists.map((watchList: any) => ({
        ...watchList,
        instruments: (watchList.instruments as any[]).map<WatchListItem>(
          (idFi: number) => {
            const instrument = instrumentsTable.getColumn('idFI').get(idFi);
            const idObject = instrument?.idObject ?? NaN;
            const idMarketBoard = instrument?.idMarketBoard ?? NaN;
            const object = objectsTable.getColumn('idObject').get(idObject);
            const marketBoard = marketBoardsTable
              .getColumn('idMarketBoard')
              .get(idMarketBoard);

            return {
              idFI: idFi,
              idObject,
              codeMarketBoard: marketBoard?.codeMarketBoard ?? '',
              symbolObject: object?.symbolObject ?? '',
            };
          }
        ),
      }));
    } catch {
      return [];
    }
  }

  /**
   * Очищает бэкап списков инструментов
   * */
  public cleanupWatchListsBackup(): void {
    localStorage.removeItem(this.watchListsBackupKey);
  }

  /**
   * @returns `true`, если процесс создания бэкапа
   * или восстановления из бэкапа завершен
   * */
  public isBackupDone(): boolean {
    return Boolean(localStorage.getItem(this.backupDoneKey));
  }

  /**
   * Помечает процесс создания бэкапа или восстановления из бэкапа завершенным
   * */
  public markBackupAsDone(): void {
    localStorage.setItem(this.backupDoneKey, Date.now());
  }

  private serialize(): string {
    const data = {};

    for (const key of this.keys) {
      const value = localStorage.getItem(key);

      if (value !== undefined) {
        data[key] = value;
      }
    }

    data[this.watchListsKey] = this.serializeWatchLists();

    return JSON.stringify(data);
  }

  private serializeWatchLists(): unknown[] {
    try {
      const watchLists = localStorage.getItem(this.watchListsKey, []);

      return watchLists.map((watchList: any) => ({
        id: watchList.id,
        n: watchList.name,
        i: watchList.instruments.map((instrument: any) => instrument.idFI),
        t:
          (watchList.type === 'default' && 'd') ||
          (watchList.type === 'goinvest' && 'g') ||
          (watchList.type === 'custom' && 'c') ||
          watchList.type,
      }));
    } catch {
      return [];
    }
  }

  private hydrateWatchLists(input: unknown[]): unknown[] {
    try {
      return input.map((watchList: any) => ({
        id: watchList.id,
        name: watchList.n,
        instruments: watchList.i,
        type:
          (watchList.t === 'd' && 'default') ||
          (watchList.t === 'g' && 'goinvest') ||
          (watchList.t === 'c' && 'custom') ||
          watchList.t,
      }));
    } catch {
      return [];
    }
  }
}

export const localStorageBackup = new LocalStorageBackup();
