import React, { useState } from 'react';

export const useModal = () => {
  const [isOpen, setOpen] = useState(false);

  const onClose = () => setOpen(false);
  const handleOpenClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.stopPropagation();
    setOpen(true);
  };

  return [isOpen, onClose, handleOpenClick] as [
    boolean,
    () => void,
    () => void
  ];
};
