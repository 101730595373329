import { TabNode } from 'flexlayout-react';
import { Typography } from '@alfalab/core-components/typography';

import { useWidgetTab } from '../hooks/useWidgetTab';

import styles from './HeaderContent.module.css';

interface Props {
  node: TabNode;
}

export const HeaderContent = ({ node }: Props) => {
  const { name, subName } = useWidgetTab(node);

  return (
    <div className={styles.widgetNameWrapper}>
      <Typography.Text view="secondary-large" weight="bold">
        {name}
      </Typography.Text>
      <Typography.Text view="secondary-large" weight="medium" color="secondary">
        {subName}
      </Typography.Text>
    </div>
  );
};
