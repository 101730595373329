import { useCallback, useEffect, useState } from 'react';

import { OrderType } from '@terminal/core/lib/client/entities';
import { roundPrice } from '@terminal/core/lib/format';

interface Props {
  side?: 'buy' | 'sell';
  orderType: OrderType;
  priceStep: number;
  price: number;
  setSlippage: (slippage: number) => void;
}

export const useSlippageQuickControls = ({
  side,
  orderType,
  priceStep,
  price,
  setSlippage,
}: Props) => {
  const [slippageAdditionalPercent, setSlippageAdditionalPercent] = useState<
    number | null
  >(null);

  const handleClearVolumeAdditional = useCallback(() => {
    setSlippageAdditionalPercent(null);
  }, []);

  useEffect(() => {
    handleClearVolumeAdditional();
  }, [side, orderType, handleClearVolumeAdditional]);

  useEffect(() => {
    if (!slippageAdditionalPercent) {
      return;
    }

    setSlippage(
      roundPrice(price * (slippageAdditionalPercent / 100), priceStep)
    );
  }, [setSlippage, slippageAdditionalPercent, price, priceStep]);

  return {
    slippageAdditionalPercent,
    setSlippageAdditionalPercent,
    clearVolumeAdditional: handleClearVolumeAdditional,
    adSuffix: slippageAdditionalPercent
      ? ` [${slippageAdditionalPercent.toFixed(2)}%]`
      : '',
  };
};
