import { RootStore } from '../../useStore';
import { activeConfigurationSelector } from './activeConfigurationSelector';

/**
 * Выбирает неактивные конфигурации
 * */
export function inactiveConfigurationsSelector(store: RootStore) {
  const { workspaceConfigurations } = store;
  const activeConfiguration = activeConfigurationSelector(store);

  return workspaceConfigurations.filter(
    ({ id }) => id !== activeConfiguration.id
  );
}
