import React, { FC } from 'react';

import { TrackPriceCommonSettings } from '@terminal/core/types/trackPrice';

import {
  AlertSettingsForm,
  AlertSettingsFormProps,
  Cell,
  LineOpacitySelect,
  LineTypeSelect,
  NotifySwitch,
  TitlePositionSelect,
} from '../../../../legacy/components/AlertSettingsForm';

export type FormProps = AlertSettingsFormProps<TrackPriceCommonSettings>;

export const Form: FC<FormProps> = (props) => {
  return (
    <AlertSettingsForm {...props}>
      <Cell>
        <LineTypeSelect />
      </Cell>
      <Cell>
        <LineOpacitySelect />
      </Cell>
      <Cell>
        <TitlePositionSelect />
      </Cell>
      <Cell justify="center">
        <NotifySwitch />
      </Cell>
    </AlertSettingsForm>
  );
};
