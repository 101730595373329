import { THEME_PERCENTAGE_PASS } from './const/themePercentagePass';

/**
 * Проверяет пройден ли тест по процентам правильных ответов
 * @param percent - процент правильных ответов
 * @returns boolean
 */
export function isThemePassed(percent: number) {
  return percent >= THEME_PERCENTAGE_PASS;
}
