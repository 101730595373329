import { v4 } from 'uuid';

import { ID_DEVICE_STORAGE_KEY } from '../../constants/common';
import { AllowedOrderParamsService } from '../services/allowedOrderParams';
import { AuthService, AuthServiceEvents } from '../services/auth';
import { CertifcatesService } from '../services/certificates';
import { ConsoleApiService } from '../services/consoleAPI';
import { CoreService } from '../services/core';
import { FinInfoExtService } from '../services/finInfoExt/finInfoExt';
import { NotificationSubscriptionService } from '../services/notificationSubscriptionService';
import { OrderNum } from '../services/orderNum';
import { OrdersWatcherService } from '../services/ordersWatcher';
import { PortfolioService } from '../services/portfolio';
import { StreamingService } from '../services/streaming';
import { TimeService } from '../services/time';
import { localStorage } from '../storages';
import { alfaDirectClient } from './client';

import { AdirClientOptions } from './types';

function initServicesLinks() {
  AuthService.addListener(AuthServiceEvents.AUTH_ATTEMPT, () => {
    CertifcatesService.onReInit();
  });
}

// TODO необходимы доработки:
// 1. перенести регистрацию в хук и реализовать отписку
// 2. не все сервисы должны быть Singleton, нужно инициализировать эти сервисы по месту
// 3. Сервисы которые нужны во всех местах приложения, инициализировать в хуке initApp
// 4. Все сервисы которые используют AdirClient должны наследоваться от класса AdirClientService
alfaDirectClient.registerService(AuthService);
alfaDirectClient.registerService(StreamingService);
alfaDirectClient.registerService(OrderNum);
alfaDirectClient.registerService(CertifcatesService);
alfaDirectClient.registerService(AllowedOrderParamsService);
alfaDirectClient.registerService(CoreService);
alfaDirectClient.registerService(TimeService);
alfaDirectClient.registerService(PortfolioService);
alfaDirectClient.registerService(FinInfoExtService);
alfaDirectClient.registerService(ConsoleApiService);
alfaDirectClient.registerService(OrdersWatcherService);
alfaDirectClient.registerService(NotificationSubscriptionService);
initServicesLinks();

export default function init({
  allowMultipleSessions = true,
  heartBeatInterval = 50000,
  isAI = false,
  developerCode,
  feURI,
}: {
  allowMultipleSessions?: boolean;
  heartBeatInterval?: number;
  isAI?: boolean;
  developerCode: AdirClientOptions['developerCode'];
  feURI: AdirClientOptions['feURI'];
}) {
  const storage = allowMultipleSessions ? sessionStorage : localStorage;
  let idDevice = storage.getItem<string>(ID_DEVICE_STORAGE_KEY);

  if (!idDevice) {
    idDevice = v4();
    storage.setItem(ID_DEVICE_STORAGE_KEY, idDevice);
  }

  alfaDirectClient.settings = {
    heartBeatInterval,
    developerCode,
    isAI,
    lostConnectionTimeout: 15000,
    feURI,
  };

  alfaDirectClient.init(idDevice);
}
