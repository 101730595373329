import { useMemo } from 'react';

import { makeOrderKey } from '../lib/domain/makeOrderKey';

import { OrderItem } from '../types/order';

export function useOrdersMap(
  filteredOrders: OrderItem[]
): Record<string, OrderItem[]> {
  const orders = filteredOrders;

  return useMemo(() => {
    const map: Record<string, OrderItem[]> = {};

    orders.forEach((order) => {
      const key = makeOrderKey(order, order.buySell);

      if (!map[key]) {
        map[key] = [];
      }

      map[key].push(order);
    });

    return map;
  }, [orders]);
}
