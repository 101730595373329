import cn from 'classnames';
import { Loader } from '@alfalab/core-components/loader';

import { MoneyBankTemplate } from '@terminal/core/lib/rest/lkMoney';

import { TemplateItem } from './TemplateItem';

import tablestyles from '../tables.module.css';
import templatestabstyles from './templatesTable.module.css';

interface TemplatesListProps {
  templates: MoneyBankTemplate[] | null;
  pending?: boolean;
  onTemplateSelect: (template: MoneyBankTemplate) => void;
  onTemplateRedact: (template: MoneyBankTemplate) => void;
}

export const TemplatesList = ({
  templates,
  pending,
  onTemplateSelect,
  onTemplateRedact,
}: TemplatesListProps) => {
  return (
    <>
      <div className={tablestyles.table}>
        <div className={tablestyles.head}>
          <div className={cn(tablestyles.row, tablestyles.rowHead)}>
            <div
              className={cn(
                tablestyles.cell,
                tablestyles.cellHead,
                templatestabstyles.cellHead,
                templatestabstyles.cellName
              )}
            >
              Название
            </div>
            <div
              className={cn(
                tablestyles.cell,
                tablestyles.cellHead,
                templatestabstyles.cellHead,
                templatestabstyles.cellAccount
              )}
            >
              Счета
            </div>
            <div
              className={cn(
                tablestyles.cell,
                tablestyles.cellHead,
                templatestabstyles.cellHead,
                templatestabstyles.cellBank
              )}
            >
              Банк
            </div>
          </div>
        </div>
        <div className={tablestyles.body}>
          {templates &&
            templates.map((item, index) => (
              <TemplateItem
                key={item.id}
                item={item}
                odd={(index + 1) % 2 === 0}
                onSelect={() => onTemplateSelect(item)}
                onRedact={() => onTemplateRedact(item)}
              />
            ))}
          {Boolean(pending) && <Loader />}
        </div>
      </div>
    </>
  );
};
