import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getContestDigests } from '../api/getContestDigests';

export const useContestDigests = (contestid: number) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'contest/publicdigests', { contestid }],
    () => getContestDigests({ contestid }),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message ||
            'При запросе списка существующих дайджестов конкурса произошла ошибка',
        });
      },
    }
  );
};
