import { makeGetParams } from '../url';
import { fetchLkResult, LKResult } from './lkApi';

export type ParticipationType = 'Stock' | 'Meeting';

export interface CorporateActionParam {
  text: string;
  value: string;
}

export interface CorporateAction {
  id: string;
  date: string;
  title: string;
  content: string;
  participation?: string;
  participationType?: ParticipationType;
  isParticipationActive?: boolean;
  parameters?: CorporateActionParam[];
}

export async function getCorporateAction(id: string): Promise<CorporateAction> {
  const request = makeGetParams({ id });
  const res = await fetchLkResult<CorporateAction | LKResult>(
    `/api/corporate-action/detail?${request}`
  );
  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface CorporateActionsFilter {
  search: string;
  unreadOnly: boolean;
  from: string;
  to: string;
}

export interface CorporateActionsHeadersParams extends CorporateActionsFilter {
  treaty: number;
  page: number;
  count: number;
}

export interface CorporateActionHeader {
  id: string;
  date: string;
  title: string;
  isRead: boolean;
  participation?: string;
  participationType?: ParticipationType;
  isParticipationActive?: boolean;
  parameters?: CorporateActionParam[];
}

export interface CorporateActionsHeadersPage {
  headers: CorporateActionHeader[];
  pageCount: number;
}

export async function getCorporateActionHeaders(
  data: CorporateActionsHeadersParams
): Promise<CorporateActionsHeadersPage> {
  const request = makeGetParams(data);
  const res = await fetchLkResult<CorporateActionsHeadersPage | LKResult>(
    `/api/corporate-action/headers?${request}`
  );
  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

// История
export type CorporateActionHistoryHeaderStatus = 'S' | 'E' | 'R';

export interface CorporateActionHistoryHeader {
  orderNumber: string;
  date: string;
  status: CorporateActionHistoryHeaderStatus;
  statusText: string;
  title: string;
  subTitle: string;
  value: string;
}

export interface CorporateActionsHistoryHeadersPage {
  headers: CorporateActionHistoryHeader[];
  pageCount: number;
}

export interface CorporateActionsHistoryFilter {
  search: string;
  from: string;
  to: string;
  status?: CorporateActionHistoryHeaderStatus;
}

export interface CorporateActionsHistoryHeadersRequest
  extends CorporateActionsHistoryFilter {
  treaty: number;
  page: number;
  count: number;
}

export async function getCorporateActionHistoryHeaders(
  data: CorporateActionsHistoryHeadersRequest
): Promise<CorporateActionsHistoryHeadersPage> {
  const request = makeGetParams(data);
  const res = await fetchLkResult<
    CorporateActionsHistoryHeadersPage | LKResult
  >(`/api/corporate-action/history-headers?${request}`);
  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface CorporateActionHistoryDetailRequest {
  ordNo: string;
  treaty: number;
}

export interface CorporateActionHistoryDetail {
  date: Date;
  participationType: ParticipationType;
  mtgHeader: string;
  sertQuan: number;
  oneSertPrice: number;
  oneSertCurrency: string;
  reference: string;
  phone: string;
  additionalInfo: string;
  status: CorporateActionHistoryHeaderStatus;
  statusText: string;
  statusComment: string;
  parameters: CorporateActionParam[];
}

export async function getCorporateActionHistoryDetail(
  data: CorporateActionHistoryDetailRequest
): Promise<CorporateActionHistoryDetail> {
  const request = makeGetParams(data);
  const res = await fetchLkResult<CorporateActionHistoryDetail | LKResult>(
    `/api/corporate-action/history-detail?${request}`
  );
  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}
