import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getIssuerFinancials } from '@terminal/core/lib/rest/investApi';
import { NotificationType } from '@terminal/core/types/ui';

export const useIssuerFinancials = (
  isin?: string,
  dataOptions?: Parameters<typeof getIssuerFinancials>[1]
) => {
  const addNotification = useNotification();

  return useQuery(
    ['getIssuerFinancials', isin],
    () => getIssuerFinancials(isin || '', dataOptions),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса информации по финансовым показателям эмитента',
          text: error?.message || '',
        });
      },
      enabled: Boolean(isin),
    }
  );
};
