import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import { CurrencyCodes } from '@alfalab/utils';

import { BalanceGroupNames } from '../../constants/balance';
import {
  ObjectGroup,
  OrderDirection,
  OrderStatus,
  OrderType,
  RazdelGroupType,
} from '../client/entities';
import {
  formatNumber,
  formatNumberOrNull,
  getStringDate,
  roundFloatNumber,
} from '../format';
import {
  getBackPos,
  getBackPosCost,
  getBaseDailyPLtoMarketCurPrice,
  getCurrencyPrice,
  getDailyBuyVolume,
  getDailyPLPrecentToMarketCurPrice,
  getDailyPLtoMarketCurPrice,
  getDailySellVolume,
  getLiquedTorgPosCostRur,
  getMarketCurSymbol,
  getMarketPriceSymbol,
  getNKDRur,
  getNPLPercent,
  getNPLPoints,
  getNPLtoMarketCurPrice,
  getPrevPrice,
  getPrice,
  getTorgPos,
  getTorgPosCost,
  getTorgPosCostRur,
  getTorgUchPosCost,
  getUchPrice,
  unifiedRubPositions,
} from '../formulas';
import { getNPLRur } from '../formulas/getNPLRur';
import { isNotNull } from '../isNotNull';
import { isPosActualQuantity } from '../isPosActualQuantity';
import { makeOrderKey } from './makeOrderKey';

import { ECurrencyId } from '../../types/currencyId';
import {
  AvailableRazdels,
  PositionItem,
  PositionOptions,
  PositionRowsRaw,
} from '../../types/position';
import { SubAccountPositionItem } from '../../types/subAccountPosition';

export function makePositionRows(
  {
    positions,
    quotes,
    objectsMap,
    objectExtsMap,
    finInfoExts,
    marketBoardMap,
    subAccountRazdels,
    objectTypesMap,
    ordersMap,
    currenciesQuotes,
    currenciesFinInfoExts,
    positionsToIdFiBalances = new Map(),
  }: PositionRowsRaw,
  {
    selectedSubAccounts,
    isSingleFORTSMarketAvailable = false,
    isUnifyRubPositions = true,
  }: PositionOptions
): PositionItem[] {
  /**
   * для того, чтобы посчитать Долю Активов
   * сначала необходимо подсчитать каждый актив
   * собрать их сумму
   * и после этого подсчитать долю каждого актива в отношении к этой сумме
   * поэтому мы в первом цикле подсчитываем активы, складываем их в sumTorgPosCostRur
   * а вторым циклом уже вычисляем необходимые значения
   */
  let sumTorgPosCostRur = 0;

  let availableAccountRazdel: AvailableRazdels = {};
  let availableSubAccountRazdel: AvailableRazdels = {};

  const tempData: Partial<PositionItem>[] = positions
    .map((position: SubAccountPositionItem) => {
      // Свежая ссылка на документацию подсчета позиций
      // http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=503369004

      const object = objectsMap.get(position.idObject);
      const objectType = objectTypesMap.get(object?.idObjectType.value ?? NaN);

      if (!objectType) {
        return null;
      }

      /**
       * собираем все сущности, относящиеся к текущей позиции
       */
      const idBalance =
        positionsToIdFiBalances.get(position.idPosition) ??
        position.idFIBalance;
      const entities: Partial<PositionItem> & {
        position: SubAccountPositionItem;
      } = {
        position,
        quote: quotes[idBalance],
        object,
        objectType,
        objectExt: objectExtsMap.get(position.idObject),
        finInfoExt: finInfoExts[idBalance],
        subAccountRazdel: subAccountRazdels.find(
          (razdel) => razdel.idRazdel === position.idRazdel
        ),
      };

      // Если есть признак, то меняем у всех позиций на раздел
      if (isSingleFORTSMarketAvailable && entities.subAccountRazdel) {
        entities.subAccountRazdel = {
          ...entities.subAccountRazdel,
          idRazdelGroup: RazdelGroupType.StocksAndBondsMarket,
        };
      }

      const razdelByCode = entities.subAccountRazdel?.codeSubAccount ?? '';
      const razdelById = entities.subAccountRazdel?.idAccount ?? '';

      if (!availableSubAccountRazdel[razdelByCode]) {
        availableSubAccountRazdel[razdelByCode] = 1;
      } else {
        availableSubAccountRazdel[razdelByCode] += 1;
      }

      if (!availableAccountRazdel[razdelById]) {
        availableAccountRazdel[razdelById] = 1;
      } else {
        availableAccountRazdel[razdelById] += 1;
      }

      entities.marketBoard = marketBoardMap.get(
        entities.finInfoExt?.idBoard ?? 0
      );

      /**
       * выносим значения переменных, используемых в дальнейших формулах,
       * описанных в http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=503369004
       */
      const nominal = entities.object?.nominal ?? 0;

      const PriceStep = entities.finInfoExt?.priceStep ?? 0;
      const PriceStepCost = entities.finInfoExt?.priceStepCost ?? 0;
      const AccruedInt = entities.objectExt?.accruedIntT0 ?? 0;
      // замена валюты для облигаций, номинированных в одной,
      // а торгующихся в другой
      const isDifferentCurr =
        entities.finInfoExt?.idObjectCurrency !==
          entities.object?.idObjectFaceUnit &&
        entities.objectType?.idObjectGroup === ObjectGroup.Bonds;

      const currency =
        entities.finInfoExt?.idObjectCurrency && !isDifferentCurr
          ? (objectsMap.get(entities.finInfoExt?.idObjectCurrency)
              ?.symbolObject as CurrencyCodes)
          : (objectsMap.get(entities.object?.idObjectFaceUnit || 0)
              ?.symbolObject as CurrencyCodes);

      /** входящая позиция */
      // https://jira.moscow.alfaintra.net/browse/ADIRWEB-1247
      const BackPos = getBackPos(entities.position);

      const TrnIn = entities.position?.trnIn ?? 0;
      const TrnOut = entities.position?.trnOut ?? 0;
      /**
       * Учетная цена
       */
      const UchPrice = getUchPrice(
        entities.position,
        entities.objectType,
        entities.marketBoard
      );

      const marginDiscountBuyD0 = entities.position?.marginDiscountBuyD0 ?? 0;

      /** подсчитываем промежуточные переменные */

      const isRUR = entities.object?.idObject === ECurrencyId.RUR;

      /** Цена последней сделки по ликвидному рынку */
      const Price = getPrice(
        entities?.finInfoExt?.prevLastDate,
        entities?.finInfoExt?.prevFairPriceDate,
        entities?.finInfoExt?.fairPrice,
        entities.quote?.last,
        entities.finInfoExt?.prevLast,
        entities.object?.idObject,
        entities.objectType?.idObjectGroup
      );

      const TorgPos = getTorgPos(
        entities.position,
        BackPos,
        entities.objectType?.idObjectGroup,
        entities.subAccountRazdel?.idRazdelType
      );

      const PrevPrice = getPrevPrice(
        entities?.finInfoExt?.prevLastDate,
        entities?.finInfoExt?.prevFairPriceDate,
        entities?.finInfoExt?.prevFairPrice,
        entities.finInfoExt?.prevLast,
        entities.finInfoExt?.prevQuote,
        entities.object?.idObject,
        entities.objectType?.idObjectGroup
      );

      /** цена валюты номинала */
      const NominalCurPrice = getCurrencyPrice(
        currenciesQuotes,
        currenciesFinInfoExts,
        entities.object?.idObjectFaceUnit
      );

      /** цена валюты ликвидного рынка */
      const MarketCurPrice = getCurrencyPrice(
        currenciesQuotes,
        currenciesFinInfoExts,
        entities.marketBoard?.idObjectCurrency
      );

      /** Подсчитываем дневные объемы на покупку */
      const DailyBuyVolume = getDailyBuyVolume(
        entities.position,
        objectType,
        TorgPos,
        BackPos,
        PrevPrice,
        Price,
        nominal,
        NominalCurPrice,
        MarketCurPrice
      );

      /** Подсчитываем дневные объемы на продажу */
      const DailySellVolume = getDailySellVolume(
        entities.position,
        objectType,
        TorgPos,
        BackPos,
        PrevPrice,
        Price,
        nominal,
        NominalCurPrice,
        MarketCurPrice
      );

      /**
       * Стоимость чистого остатка за предыдущий день
       */
      const BackPosCost = getBackPosCost(
        objectType,
        BackPos,
        PrevPrice,
        nominal,
        PriceStep,
        PriceStepCost
      );

      /** Текущая стоимость */
      let TorgPosCost = getTorgPosCost(
        objectType,
        TorgPos,
        Price,
        nominal,
        PriceStep,
        PriceStepCost
      );

      /**
       * Прибыль/убыток по позиции за текущий день
       * по отношению к предыдущему торговому дню
       */
      const DailyPLtoMarketCurPrice = getDailyPLtoMarketCurPrice(
        objectType,
        Price,
        nominal,
        NominalCurPrice,
        MarketCurPrice,
        TorgPosCost,
        BackPosCost,
        DailySellVolume,
        DailyBuyVolume,
        TrnIn,
        TrnOut,
        PriceStep,
        entities.object?.idObject
      );

      /** База для рассчета ПУ(дн) в процентах */
      const BaseDailyPLtoMarketCurPrice = getBaseDailyPLtoMarketCurPrice(
        objectType,
        Price,
        nominal,
        NominalCurPrice,
        MarketCurPrice,
        BackPosCost,
        DailyBuyVolume,
        TrnIn
      );

      /** ПУ(дн) в процентах */
      const DailyPLPrecenttoMarketCurPrice = getDailyPLPrecentToMarketCurPrice(
        BaseDailyPLtoMarketCurPrice,
        DailyPLtoMarketCurPrice,
        entities.object?.idObject
      );

      /**
       * Стоимость чистого остатка по учетной цене
       */
      const TorgPosUchCost = getTorgUchPosCost(
        objectType,
        UchPrice,
        TorgPos,
        nominal,
        PriceStep,
        PriceStepCost,
        entities.object?.idObject
      );

      /**
       * Прибыль/убыток по позиции с момента ее открытия
       */
      const NPLtoMarketCurPrice = getNPLtoMarketCurPrice(
        TorgPosCost,
        TorgPosUchCost,
        UchPrice,
        entities.object?.idObject
      );

      /**
       * Прибыль/убыток по позиции в пунктах
       */
      const NPLPoints = getNPLPoints(TorgPos, Price, UchPrice, PriceStep);

      /** Валюта номинала */
      const MarketCurSymbol = getMarketCurSymbol(
        objectType,
        objectsMap,
        entities.object?.idObjectFaceUnit,
        entities.marketBoard?.idObjectCurrency
      );

      /** Валюта цены ликвидного рынка */
      const MarketPriceSymbol = getMarketPriceSymbol(
        objectType,
        objectsMap,
        entities.marketBoard?.idObjectCurrency
      );

      /**
       * стоимость актива
       * используется в дальнейшем для подсчёта суммы активов
       * и расчёта доли текущенго актива к общему значению
       * */
      let TorgPosCostRur = getTorgPosCostRur(
        objectType,
        TorgPosCost,
        NominalCurPrice,
        MarketCurPrice
      );

      /**
       * Стоимость положительных позиций ликвидных активов и отрицательных позиций
       * по всем активам с типом валюта, облигация, акция, пай, ETF.
       */
      const LiquedTorgPosCostRur = getLiquedTorgPosCostRur(
        objectType,
        TorgPos,
        TorgPosCost,
        marginDiscountBuyD0,
        NominalCurPrice,
        MarketCurPrice
      );

      /** Прибыль/убыток по позиции с момента ее открытия в процентах */
      const NPLPercent = getNPLPercent(TorgPosUchCost, NPLtoMarketCurPrice);
      /** Стоимость НКД на текущую дату */
      const NKDtoNominalCurPrice = AccruedInt * TorgPos;
      /** дневная прибыль позиции по отношению к входящей стоимости */

      /** суммируем значение текущего актива к общей сумме */
      if (TorgPosCostRur !== null) {
        sumTorgPosCostRur += TorgPosCostRur;
      }

      let dailyPLRur: number | null = null;

      if (DailyPLtoMarketCurPrice !== null) {
        dailyPLRur =
          entities.objectType?.idObjectGroup === ObjectGroup.Bonds
            ? DailyPLtoMarketCurPrice * NominalCurPrice
            : DailyPLtoMarketCurPrice * MarketCurPrice;
      }

      const NPLRur = getNPLRur(
        NPLtoMarketCurPrice,
        NominalCurPrice,
        MarketCurPrice,
        entities.objectType?.idObjectGroup
      );

      const torgPosUchCostRur =
        entities.objectType?.idObjectGroup === ObjectGroup.Bonds
          ? TorgPosUchCost * NominalCurPrice
          : TorgPosUchCost * MarketCurPrice;

      //Считаем только для валюты (рубля)
      const money = entities.object?.idObject === ECurrencyId.RUR ? TorgPos : 0;
      const moneyInitial =
        entities.object?.idObject === ECurrencyId.RUR ? BackPos : 0;

      const accruedInt = entities.objectExt?.accruedIntT0 ?? 0;
      const NKDRur = getNKDRur(TorgPos, accruedInt, NominalCurPrice);

      const backPosCostRur =
        entities.objectType?.idObjectGroup === ObjectGroup.Bonds
          ? BackPosCost * NominalCurPrice
          : BackPosCost * MarketCurPrice;

      const fields: Partial<PositionItem> = {
        price: Price,
        torgPos: roundFloatNumber(TorgPos),
        backPos: roundFloatNumber(BackPos),
        torgPosCost: formatNumberOrNull(TorgPosCost, 2),
        dailyPLtoMarketCurPrice: formatNumberOrNull(DailyPLtoMarketCurPrice, 2),
        NPLtoMarketCurPrice: formatNumberOrNull(NPLtoMarketCurPrice, 2),
        NPLPoints: formatNumberOrNull(NPLPoints, 2),
        uchPriceConvertedToLiquidMarketCur: roundFloatNumber(UchPrice),
        torgPosUchCost: formatNumber(TorgPosUchCost, 2),
        NPLPercent: formatNumberOrNull(NPLPercent, 2),
        inPrice: roundFloatNumber(PrevPrice),
        backPosCost: formatNumber(BackPosCost, 2),
        NKDtoNominalCurPrice: formatNumber(NKDtoNominalCurPrice, 2),
        marketCurSymbol: MarketCurSymbol,
        marketPriceSymbol: MarketPriceSymbol,
        torgPosCostRur: formatNumberOrNull(TorgPosCostRur, 2),
        liquedTorgPosCostRur: formatNumberOrNull(LiquedTorgPosCostRur, 2),
        dailyPLPrecenttoMarketCurPrice: formatNumberOrNull(
          DailyPLPrecenttoMarketCurPrice,
          2
        ),
        sessionQty:
          entities.position?.sessionBuyQty + entities.position?.sessionSellQty,
        sessionVal:
          entities.position?.sessionBuyVal + entities.position?.sessionSellVal,
        variationMargin: entities.position.variationMargin ?? 0,
        idBalanceGroup: entities.subAccountRazdel?.idRazdelGroup ?? 0,
        nameBalanceGroup: isSingleFORTSMarketAvailable
          ? 'ЕФР'
          : BalanceGroupNames[entities.subAccountRazdel?.idRazdelGroup ?? 0] ??
            'N/A',
        isMoney: isRUR,
        yield: entities.quote?.yield ?? 0,
        matDate: getStringDate(entities.object?.matDateObject),
        nextCoupon: getStringDate(entities.finInfoExt?.nextCoupon),
        currency,
        dailyBuyVolume: DailyBuyVolume,
        dailySellVolume: DailySellVolume,
        baseDailyPLtoMarketCurPrice: BaseDailyPLtoMarketCurPrice,
        shortDescription: `${
          entities.object?.nameObject
        } ${entities.objectType?.nameObjectType?.[0].toLowerCase()}${entities.objectType?.nameObjectType?.slice(
          1
        )}`,
        nameObject: entities.object?.nameObject,
        dailyPLRur,
        NPLRur,
        torgPosUchCostRur,
        marginDiscountBuyD0,
        money,
        moneyInitial,
        NKDRur,
        backPosCostRur,
        idObjectGroup: entities.objectType?.idObjectGroup,
      };

      /** заявки на покупку */
      const orderLong =
        ordersMap[makeOrderKey(position, OrderDirection.Buy)] ?? [];
      /** заявки на продажу */
      const orderShort =
        ordersMap[makeOrderKey(position, OrderDirection.Sell)] ?? [];

      /** сумма всех заявок */
      fields.planLong = sumBy(orderLong, 'rest');
      fields.planShort = sumBy(orderShort, 'rest');

      /** и их кол-во */
      fields.longOrders = orderLong.length;
      fields.shortOrders = orderShort.length;

      //Отсеиваем позицию рубля и сумма лонга и шорта которых равно 0 (не можем ни продать ни купить, позиция считается уже закрытой)
      //Также смотрим, есть ли уже заявка на закрытие позиции
      const isCancelOrderSend = (TorgPos > 0 ? orderShort : orderLong).find(
        (order) =>
          order.idOrderType === OrderType.MKT &&
          order.rest === Math.abs(TorgPos) &&
          ![
            OrderStatus.Filled,
            OrderStatus.Cancelled,
            OrderStatus.Rejected,
          ].includes(order.idOrderStatus)
      );

      fields.canBeCanceled =
        TorgPos !== 0 &&
        isPosActualQuantity(
          [...orderLong, ...orderShort],
          position.idObject,
          TorgPos
        ) &&
        entities.object?.idObject !== 174368 &&
        !isCancelOrderSend;

      fields.exchangeCode = entities.subAccountRazdel?.universalExchangeCode;

      return {
        ...entities,
        ...fields,
      };
    })
    .filter(isNotNull);

  let computedData = tempData.map((data: Partial<PositionItem>) => {
    data.assetsPart = formatNumber(
      (100 * (data.torgPosCostRur ?? 0)) / sumTorgPosCostRur,
      2
    );

    return data as PositionItem;
  });

  let filteredComputedData: PositionItem[] = [];

  if (!isEmpty(selectedSubAccounts)) {
    filteredComputedData = computedData.filter((tempRow) =>
      selectedSubAccounts.includes(
        String(tempRow.subAccountRazdel?.codeSubAccount)
      )
    );
  }

  if (isSingleFORTSMarketAvailable && isUnifyRubPositions) {
    if (isEmpty(selectedSubAccounts)) {
      filteredComputedData = unifiedRubPositions(filteredComputedData);
    } else {
      filteredComputedData = selectedSubAccounts.reduce<PositionItem[]>(
        (positions, subAccount) => {
          const positionsBySubAccount = filteredComputedData.filter(
            (position) =>
              subAccount === position.subAccountRazdel?.codeSubAccount
          );

          positions.push(...unifiedRubPositions(positionsBySubAccount));

          return positions;
        },
        []
      );
    }
  }

  return filteredComputedData;
}
