import { ComponentClass, FC, SVGProps } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';

import styles from './ErrorStub.module.css';

interface ErrorStubProps {
  title: string;
  description: string;
  icon: ComponentClass<SVGProps<SVGSVGElement>> | FC<SVGProps<SVGSVGElement>>;
  loading: boolean;
  onRetry: () => void;
}

export const ErrorStub = ({
  title,
  description,
  icon: Icon,
  loading,
  onRetry,
}: ErrorStubProps) => (
  <div className={styles.card}>
    <Icon className={styles.icon} color="#747474" />
    <Typography.Text
      tag="div"
      view="primary-large"
      color="tertiary-inverted"
      className={styles.title}
    >
      {title}
    </Typography.Text>
    <Typography.Text
      tag="div"
      view="secondary-large"
      color="secondary"
      className={styles.description}
    >
      {description}
    </Typography.Text>
    <Button
      size="xs"
      loading={loading}
      onClick={onRetry}
      className={styles.button}
    >
      Обновить
    </Button>
  </div>
);
