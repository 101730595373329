import { BaseModal } from '@alfalab/core-components/base-modal';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';

import { Result } from '../../../../shared/ui/Result';
import { EDUCATION_MODAL_DRAWER_ID } from '../../lib/const/educationModalDrawerId';

interface ResultModalProps {
  title: string;
  text?: React.ReactNode;
  modalTitle?: string;
  type: 'success' | 'error' | null;
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export const ResultModal = ({
  title,
  text,
  modalTitle = 'Методы торговли Go Invest',
  type,
  children,
  open,
  onClose,
}: ResultModalProps) => {
  const success = type === 'success';
  const bgColor = success
    ? 'var(--color-light-graphic-positive)'
    : 'var(--color-light-graphic-negative)';
  const iconColor = 'var(--color-light-graphic-primary)';
  const Icon = success ? CheckmarkMIcon : CrossMIcon;

  return (
    <BaseModal open={open} onClose={onClose}>
      <FunctionalModal
        title={modalTitle}
        width={520}
        onClose={onClose}
        drawerId={EDUCATION_MODAL_DRAWER_ID}
      >
        {type && (
          <Result
            title={title}
            text={text}
            icon={<Icon color={iconColor} />}
            ellipseColor={bgColor}
          >
            {children}
          </Result>
        )}
      </FunctionalModal>
    </BaseModal>
  );
};
