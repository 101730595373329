type Callback = (...props: any[]) => any;

/**
 * Умный таймер
 *
 * @description Умный таймер используется, когда нужна возможность приостановить и возобновить регулярное выполнение функции
 */
export class SmartTimer {
  private callbackStartTime: number;
  private remaining: number = 0;
  private paused: boolean = false;
  private timerId: NodeJS.Timeout;
  private readonly _callback: Callback;
  private readonly _delay: number;

  /**
   * Создание таймера
   * @param callback Вызываемый колбек
   * @param delay Дилей между вызовом колбека
   */
  constructor(callback: Callback, delay: number) {
    this._callback = callback;
    this._delay = delay;
  }

  private run() {
    this.callbackStartTime = new Date().getTime();
    this._callback();
  }

  public start() {
    this.clear();
    if (!this.paused) {
      this.run();
    }
    this.timerId = setInterval(() => {
      this.run();
    }, this._delay);
  }

  public pause(): void {
    if (!this.paused) {
      this.clear();
      this.remaining =
        this.callbackStartTime + this._delay - new Date().getTime();
      this.paused = true;
    }
  }

  public resume() {
    if (this.paused) {
      if (this.remaining) {
        setTimeout(() => {
          this.run();
          this.start();
          this.paused = false;
        }, this.remaining);
      } else {
        this.start();
        this.paused = false;
      }
    }
  }

  public clear() {
    clearInterval(this.timerId);
  }
}
