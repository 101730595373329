import { calculateImmediateRequirements } from '../../lib/formulas/calculateImmediateRequirements';
import { calculateInitialMargin } from '../../lib/formulas/calculateInitialMargin';
import { calculateLiquedBalance } from '../../lib/formulas/calculateLiquedBalance';
import { calculateMinimumMargin } from '../../lib/formulas/calculateMinimumMargin';
import { calculateRequirements } from '../../lib/formulas/calculateRequirements';
import { formatNumber } from '../format';

import { PositionItem } from '../../types/position';
import { SubGTAccountItem } from '../../types/subAccount';

interface RazdelRequirementProps {
  idRazdelGroup: number;
  idSubAccount?: number;
  subGTAccounts: SubGTAccountItem[];
  positions: PositionItem[];
}

export const getRazdelRequirement = ({
  idRazdelGroup,
  idSubAccount,
  subGTAccounts,
  positions,
}: RazdelRequirementProps) => {
  const initialMargin = calculateInitialMargin(
    idRazdelGroup,
    subGTAccounts,
    idSubAccount
  );
  const minimumMargin = calculateMinimumMargin(
    idRazdelGroup,
    subGTAccounts,
    idSubAccount
  );

  const liquedBalance = calculateLiquedBalance(
    Number(idRazdelGroup),
    subGTAccounts,
    positions,
    idSubAccount
  );

  const requirements = calculateRequirements(initialMargin, liquedBalance);
  const immediateRequirements = calculateImmediateRequirements(
    minimumMargin,
    liquedBalance
  );

  return {
    initialMargin,
    minimumMargin,
    liquedBalance,
    requirements: formatNumber(requirements, 2),
    immediateRequirements: formatNumber(immediateRequirements, 2),
  };
};
