import { makeGetParams } from '../url';
import { fetchLkResult, LKResult } from './lkApi';

export type UITheme = 'light' | 'dark' | 'system';

export interface LKInit {
  sitePath: string;
  fullName: string;
  appeal: string;
  login: string;
  email: string;
  emailConfirmed: boolean;
  phone: string;
  cus: string;
  isClient: boolean;
  isDemo: boolean;
  isResident: boolean;
  activated: string | null;
  signingProtocolName: string;
  signingProtocolNameInsecure: string;
  servicesOn: boolean;
  canMultipleMoneyTransfer: boolean;
  accessToXmlDepoUpload: boolean;
  twoFactorAuthenticationEnabled: boolean;
  isSecuritiesEnabled: boolean;
  isMigrationIisEnabled: boolean;
  theme: UITheme;
}

export async function getInit(): Promise<LKInit> {
  return await fetchLkResult<LKInit>(`/api/common/init`);
}

// Объект ген. соглашения
export interface Treaty {
  treaty: number;
  name: string;
  selected?: boolean; // Был выбран бользователем в прошлый раз
  isOwner?: boolean; // Текущий пользователь - владелец счёта
  isFirm: boolean; // Счёт юр. лица
  isKick?: boolean; // Счёт КИК
  isIis?: boolean; // Это ИИС
  isMoneybox?: boolean; // Счёт-копилка
  isRepo?: boolean; // РЕПО счёт
  isForeignMarket?: boolean; // Счёт иностранного рынка
  isCurrResident?: boolean; // Валютный резидент
}

interface TreatiesResult extends LKResult {
  items: Treaty[];
}

export async function getTreaties(): Promise<Treaty[]> {
  const res = await fetchLkResult<TreatiesResult>('/api/account/treaties');

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res.items;
}

export interface OwnTreatiesParams {
  treaty: number;
}

export async function getTreatiesByOwner(
  data: OwnTreatiesParams
): Promise<Treaty[]> {
  const params = makeGetParams(data);
  const res = await fetchLkResult<Treaty[] | LKResult>(
    `/api/account/treaties-by-owner?${params}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface BrokerRequisitesRequest {
  treaty: string;
  placeCode: string;
  currency: string;
  email: string;
  requisiteId?: number;
}

export async function sendBrokerRequisites(
  data: BrokerRequisitesRequest
): Promise<LKResult> {
  const res = await fetchLkResult<LKResult>(
    '/api/requisites/broker/email',
    'POST',
    data
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export type SpbMarketType = 'beb' | 'nrd' | 'euroclear' | 'clearstream';

export interface DepoRequisitesRequest {
  treaty: string;
  depoaccount: string;
  market?: SpbMarketType;
  email: string;
}

export async function sendDepoRequisites(
  data: DepoRequisitesRequest
): Promise<LKResult> {
  const res = await fetchLkResult<LKResult>(
    '/api/requisites/depo/email',
    'POST',
    data
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface EmailConfirmSendEmailRequest {
  email: string;
}

export async function sendEmailConfirmSendEmail(
  data: EmailConfirmSendEmailRequest
) {
  const res = await fetchLkResult<LKResult>(
    '/api/client/email/confirm/send-email',
    'POST',
    data
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}
