import { useEffect } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';
import { SignMIcon } from '@alfalab/icons-glyph/SignMIcon';

import { SmsSignCreated } from '@terminal/core/lib/rest/lkSmsSignOperation';
import { SmsSignDialog } from '@terminal/lk/legacy/components/SmsSignDialog';
import {
  AddAccountPreregisterAI,
  AddAccountPreregisterGI,
  AddAccountTypeAI,
  AddAccountTypeGI,
  useAddAccountForm,
} from '@terminal/lk-core';

import { ApplicationText } from '../../../../entities/AddAccount';

import styles from './AddAccountForm.module.css';

function getAccountTypeName(type: AddAccountTypeAI | AddAccountTypeGI) {
  if (type === 'iia') {
    return 'ИИС';
  }

  if (type === 'REPO') {
    return 'счёта РЕПО';
  }

  return 'брокерского счёта';
}

export interface AddAccountFormProps<
  T extends AddAccountTypeAI | AddAccountTypeGI,
  R extends AddAccountPreregisterAI | AddAccountPreregisterGI
> {
  type: T;
  requestPreregister: (type: T) => Promise<R>;
  requestRegister: (operationId: string) => Promise<SmsSignCreated>;
  onSuccess: () => void;
  onFailed: () => void;
}

export const AddAccountForm = <
  T extends AddAccountTypeAI | AddAccountTypeGI,
  R extends AddAccountPreregisterAI | AddAccountPreregisterGI
>({
  type,
  requestPreregister,
  requestRegister,
  onSuccess,
  onFailed,
}: AddAccountFormProps<T, R>) => {
  const {
    agree,
    toggleAgree,
    textOpen,
    setTextOpen,
    prereg,
    preregPending,
    failed,
    signAddAccount,
    smsSign,
  } = useAddAccountForm(type, requestPreregister, requestRegister);

  useEffect(() => {
    if (failed) {
      onFailed();
    }
  }, [failed, onFailed]);

  return (
    <div className={styles.container}>
      <Typography.Text
        view="primary-small"
        weight="bold"
        color="primary"
        tag="p"
        defaultMargins={false}
      >
        Для открытия {getAccountTypeName(type)} вам необходимо подписать всего
        один документ:
      </Typography.Text>
      <Gap size="xl" />
      <Space fullWidth={true} direction="vertical">
        {preregPending ? (
          <Loader />
        ) : (
          <Link
            leftAddons={
              <DocumentLinesMIcon
                className={styles.docIcon}
                width={18}
                height={18}
              />
            }
            onClick={() => setTextOpen(true)}
          >
            Заявление на обслуживание на финансовых рынках
          </Link>
        )}

        <Checkbox
          onChange={toggleAgree}
          checked={agree}
          size="s"
          align="center"
          label="С Заявлением ознакомлен"
        />

        <div className={styles.submit}>
          <ButtonDesktop
            size="s"
            block
            leftAddons={<SignMIcon width={18} height={18} />}
            disabled={!agree}
            onClick={signAddAccount}
            loading={smsSign.createPending || smsSign.smsSending}
          >
            Подписать
          </ButtonDesktop>
        </div>
      </Space>

      {prereg && (
        <ApplicationText
          text={prereg.document}
          backLabel="Открытие нового счета"
          open={textOpen}
          onClose={() => setTextOpen(false)}
        />
      )}

      <SmsSignDialog
        open={smsSign.openDialog}
        codeSending={smsSign.smsSending}
        error={smsSign.error}
        isError={
          smsSign.isCreateError ||
          smsSign.isSendSmsError ||
          smsSign.isCheckError
        }
        isSuccess={smsSign.isSuccess}
        assignment={smsSign.assignment}
        onCancel={smsSign.cancel}
        onResend={smsSign.resendSms}
        signCheck={smsSign.checkSmsCode}
        onComplete={onSuccess}
      />
    </div>
  );
};
