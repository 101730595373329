import cn from 'classnames';
import { FC, memo, ReactNode, useMemo, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { CurrencyCodes } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import {
  AdditionalInstruction,
  AdditionalInstructions,
  CurrentAdditionalInstruction,
} from '@terminal/core/types/additionalInstruction';
import { OrderSide } from '@terminal/core/types/trading';

import {
  ConditionData,
  ConditionModal,
  IcebergModal,
  instructionsOptionsParams,
  LinkedOrderModal,
  SLTPInstruction,
  SLTPModal,
} from '../../../../features';
import { useWidgetContext } from '../../../../shared';
import { useTradingContext } from '../../context';

import styles from './AdditionalInstructionsSelect.module.css';

interface Props {
  widgetContainer: HTMLDivElement | null;
  price: number;
  side: OrderSide;
  instructionsAvailability: AdditionalInstructions;
  isLotCount: boolean;
  priceStep?: number;
  lot?: number;
  symbol?: CurrencyCodes;
  currencyCode: CurrencyCodes;
  isBond: boolean;
  currentInstruction: CurrentAdditionalInstruction;
  onConditionSubmit: (data: ConditionData, buttonContent?: ReactNode) => void;
  containerClassName?: string;
}

export const AdditionalInstructionsSelect: FC<Props> = memo(
  ({
    widgetContainer,
    price,
    side,
    instructionsAvailability,
    isLotCount,
    priceStep,
    lot,
    symbol,
    currencyCode,
    isBond,
    currentInstruction,
    onConditionSubmit,
    containerClassName,
  }) => {
    const [
      isAdditionalInstructionSelectSheetOpen,
      setIsAdditionalInstructionSelectSheetOpen,
    ] = useToggle(false);
    const [
      isAdditionalInstructionSheetOpen,
      setIsAdditionalInstructionSheetOpen,
    ] = useState(false);

    const instructionDropdown = useRef<HTMLDivElement>(null);

    const { DropdownComponent } = useWidgetContext();

    useOnClickOutside(instructionDropdown, () =>
      setIsAdditionalInstructionSelectSheetOpen(false)
    );

    const {
      idFi,
      selectedSubAccountId,
      currentAdditionalInstructions,
      setCurrentAdditionalInstructions,
    } = useTradingContext();

    const handleCancelInstruction = () => {
      setCurrentAdditionalInstructions({});
      setIsAdditionalInstructionSheetOpen(false);
    };

    const availableAdditionalInstructions = useMemo(
      () =>
        instructionsAvailability
          .filter(({ available }) => available)
          .map(({ name }) => name),
      [instructionsAvailability]
    );

    const instructionModal: Record<AdditionalInstruction, JSX.Element> = {
      [AdditionalInstruction.ICEBERG]: (
        <IcebergModal
          open={isAdditionalInstructionSheetOpen}
          value={
            currentAdditionalInstructions?.[AdditionalInstruction.ICEBERG]?.data
              ?.visibleQuantity || 0
          }
          isLotCount={isLotCount}
          lot={lot}
          onSubmit={(data) => {
            setCurrentAdditionalInstructions({
              [AdditionalInstruction.ICEBERG]: {
                name: AdditionalInstruction.ICEBERG,
                data,
              },
            });
            setIsAdditionalInstructionSheetOpen(false);
          }}
          onClose={handleCancelInstruction}
          wrapperClassName={styles.containerModal}
          container={widgetContainer ? () => widgetContainer : undefined}
        />
      ),
      [AdditionalInstruction.SLTP]: (
        <SLTPModal
          open={isAdditionalInstructionSheetOpen}
          side={side}
          mainPrice={price}
          sltp={
            currentAdditionalInstructions?.[AdditionalInstruction.SLTP]
              ?.data as SLTPInstruction
          }
          priceStep={priceStep}
          symbol={symbol}
          onSubmit={(data) => {
            if (data) {
              setCurrentAdditionalInstructions({
                [AdditionalInstruction.SLTP]: {
                  name: AdditionalInstruction.SLTP,
                  data,
                },
              });
            } else {
              setCurrentAdditionalInstructions({});
            }

            setIsAdditionalInstructionSheetOpen(false);
          }}
          onClose={handleCancelInstruction}
          wrapperClassName={styles.containerModal}
          container={widgetContainer ? () => widgetContainer : undefined}
        />
      ),
      [AdditionalInstruction.LINKEDORDER]: (
        <LinkedOrderModal
          open={isAdditionalInstructionSheetOpen}
          idFi={idFi}
          isBond={isBond}
          selectedSubAccountId={selectedSubAccountId}
          priceStep={priceStep}
          currencyCode={currencyCode}
          onSubmit={(data) => {
            setCurrentAdditionalInstructions({
              [AdditionalInstruction.LINKEDORDER]: {
                name: AdditionalInstruction.LINKEDORDER,
                data,
              },
            });
            setIsAdditionalInstructionSheetOpen(false);
          }}
          onClose={handleCancelInstruction}
          wrapperClassName={styles.containerModal}
          container={widgetContainer ? () => widgetContainer : undefined}
        />
      ),
      [AdditionalInstruction.CONDITION]: (
        <ConditionModal
          open={isAdditionalInstructionSheetOpen}
          idFi={idFi}
          currencyCode={currencyCode}
          onSubmit={(data) => {
            onConditionSubmit(data);

            setIsAdditionalInstructionSheetOpen(false);
          }}
          onClose={handleCancelInstruction}
          wrapperClassName={styles.containerModal}
          container={widgetContainer ? () => widgetContainer : undefined}
        />
      ),
    };

    const CurrentAdditionalInstructionIcon =
      instructionsOptionsParams[currentInstruction?.name]?.icon;

    if (!availableAdditionalInstructions?.length) {
      return null;
    }

    const buttonContent = currentInstruction
      ? instructionsOptionsParams[currentInstruction.name].getButtonText(
          currentInstruction.data,
          {
            idFi,
            isLotCount,
            price,
            side,
            priceStep,
            symbol,
          },
          false
        )
      : null;

    return (
      <div
        ref={instructionDropdown}
        className={cn(styles.dropdownContainer, containerClassName)}
      >
        <div
          className={styles.dropDownContent}
          onClick={() => {
            setIsAdditionalInstructionSelectSheetOpen();
            setCurrentAdditionalInstructions(null);
          }}
        >
          {currentInstruction?.data && (
            <CurrentAdditionalInstructionIcon
              height={15}
              width={15}
              color="var(--color-light-graphic-secondary)"
            />
          )}
          <Typography.Text
            view="secondary-large"
            weight="medium"
            className={styles.instructionName}
          >
            {buttonContent || 'Дополнительные инструкции'}
          </Typography.Text>

          {!buttonContent && (
            <ChevronDownCompactMIcon
              height={16}
              width={16}
              color="var(--color-light-graphic-secondary)"
              className={cn(
                styles.chevron,
                isAdditionalInstructionSelectSheetOpen && styles.chevronUp
              )}
            />
          )}
        </div>
        <DropdownComponent
          useAnchorWidth
          anchorElement={instructionDropdown.current}
          open={isAdditionalInstructionSelectSheetOpen}
          offset={[0, 4]}
          position="top-start"
          headerDivider={false}
        >
          {availableAdditionalInstructions.map((instruction) => {
            return (
              <Dropdown.Item
                key={instructionsOptionsParams[instruction].text(false)}
                content={
                  <div>
                    {instructionsOptionsParams[instruction].text(false)}
                  </div>
                }
                onClick={() => {
                  setCurrentAdditionalInstructions({
                    [instruction]: {
                      name: instruction,
                      data: null,
                      buttonContent: null,
                    },
                  });
                  setIsAdditionalInstructionSelectSheetOpen(false);
                  setIsAdditionalInstructionSheetOpen(true);
                }}
              />
            );
          })}
        </DropdownComponent>

        {currentInstruction && instructionModal[currentInstruction.name]}
      </div>
    );
  }
);

AdditionalInstructionsSelect.displayName = 'AdditionalInstructionsSelect';
