import { GetState } from 'zustand';

import { RootStore, StoreSet } from '../useStore';

import { Notification, NotificationType } from '../../types/ui';

export interface NotificationSlice {
  notifications: Notification[];
  removeNotification: (id: string) => void;
  clearNotifications: () => void;
  addNotification: (notification: Notification) => void;
}

export const createNotificationSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): NotificationSlice => ({
  notifications: [],
  removeNotification: (id: string) => {
    set((state) => {
      const modNotifications = state.notifications.filter(
        (notification) => notification.id !== id
      );

      state.notifications = [...modNotifications];
    });
  },
  clearNotifications: () => {
    set((state) => {
      state.notifications = [];
    });
  },
  addNotification: (notification) => {
    set((state) => {
      const isAllowType =
        (notification.type === NotificationType.TRADE &&
          state.settings.notifications.isShowTradeNotifications) ||
        (notification.type === NotificationType.BROKER &&
          state.settings.notifications.isShowBrokerNotifications) ||
        (notification.type === NotificationType.SYSTEM &&
          state.settings.notifications.isShowSystemNotifications) ||
        (notification.type === NotificationType.CHAT &&
          state.settings.notifications.isShowNewMessages);

      if (state.settings.notifications.isShow && isAllowType) {
        state.notifications.push(notification);
      }
    });
  },
});
