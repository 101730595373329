import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getTradingPlatformsState } from '@terminal/core/lib/rest/lkAccountInfo';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useTradingPlatformsState = (treaty?: number) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.TradingPlatforms, { treaty }],
    async () => getTradingPlatformsState({ treaty: treaty || 0 }),
    {
      enabled: Boolean(treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message ||
            'При запросе информации о торговых площадках произошла ошибка',
        });
      },
    }
  );
};
