import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  cancelSecuritiesTranferXmlMultipleUpload,
  getSecuritiesTranferXmlMultipleOperations,
  getSecuritiesTranferXmlMultipleStatus,
  SecuritiesTranferXmlMultipleResilt,
  securitiesTranferXmlMultipleUpload,
  SecuritiesTransferXMLUploadStatus,
} from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

const CHECK_STATUS_INTERVAL = 1000;

export const useSecuritiesTranferXmlMultiple = () => {
  const addNotification = useNotification();

  const [status, setStatus] =
    useState<SecuritiesTransferXMLUploadStatus | null>(null);
  const [operationId, setOperationId] = useState('');
  const [process, setProcess] = useState(false);
  const [operations, setOperations] =
    useState<SecuritiesTranferXmlMultipleResilt | null>(null);

  const uploadMutation = useMutation(securitiesTranferXmlMultipleUpload, {
    onSuccess: (data) => {
      setStatus(data);
      setOperationId(data.id);
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Произошла ошибка при загрузке XML',
      });
    },
  });

  const statusMutation = useMutation(getSecuritiesTranferXmlMultipleStatus, {
    onSuccess: (data) => {
      setStatus(data);
      setOperationId(data.id);
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При проверке статуса произошла ошибка',
      });
    },
  });

  const operationsMutation = useMutation(
    getSecuritiesTranferXmlMultipleOperations,
    {
      onSuccess: (data) => {
        setOperations(data);
      },
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'При запросе операций произошла ошибка',
        });
      },
    }
  );

  const cancelMutation = useMutation(cancelSecuritiesTranferXmlMultipleUpload, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При отмене операции произошла ошибка',
      });
    },
  });

  useEffect(() => {
    if (
      status &&
      (status.state === 'InProgress' || status.state === 'Default')
    ) {
      setProcess(true);
      const timeout = setTimeout(() => {
        if (process) {
          statusMutation.mutateAsync(status.id);
        }
      }, CHECK_STATUS_INTERVAL);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [status, process, statusMutation]);

  useEffect(() => {
    if (status && status.state === 'Done') {
      operationsMutation.mutateAsync(status.id);
      setStatus(null);
      setProcess(false);
    }
  }, [status, operationsMutation]);

  const cancel = async () => {
    reset();

    if (status) {
      return await cancelMutation.mutateAsync(status.id);
    }
  };

  const reset = () => {
    setStatus(null);
    setOperationId('');
    setOperations(null);
    setProcess(false);
  };

  return {
    upload: uploadMutation.mutateAsync,
    uploadPending: uploadMutation.isLoading,
    status,
    operationId,
    operations,
    operationsPending: operationsMutation.isLoading,
    cancel,
    reset,
  };
};
