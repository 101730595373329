import { format, startOfDay, subDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  AmountInput,
  AmountInputProps,
} from '@alfalab/core-components/amount-input';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { CalendarInputDesktop } from '@alfalab/core-components/calendar-input/desktop';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { InputDesktop } from '@alfalab/core-components/input/desktop';
import {
  InputAutocompleteDesktop,
  InputAutocompleteDesktopProps,
} from '@alfalab/core-components/input-autocomplete/desktop';
import { Loader } from '@alfalab/core-components/loader';
import {
  SelectDesktop,
  SelectDesktopProps,
} from '@alfalab/core-components/select/desktop';
import { Space } from '@alfalab/core-components/space';
import { Switch, SwitchProps } from '@alfalab/core-components/switch';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkCompactMIcon } from '@alfalab/icons-glyph/CheckmarkCompactMIcon';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import {
  ActiveTransfer,
  SecuritiesActive,
  SecuritiesActiveIn,
  SecuritiesActivesInSearchRequest,
  SecuritiesPosition,
} from '@terminal/core/lib/rest/lkSecurities';

import {
  AMOUNT_MINORITY,
  isControlDatesRequired,
  isGboxIsin,
  isGboxRequired,
  isReferenceRequired,
  securityPaperDescription,
  securityPaperKey,
} from '../../shared';
import {
  getReferenceErrorMsg,
  isAmountFulfill,
  isAmountValid,
  isDateValid,
  isReferenceValid,
  isSecurityPaperEnabled,
  isSecurityPaperValid,
  toSimilarLat,
  upperCase,
  validateReference,
} from '../../validation';
import { TooltipReference, TooltipSecurityParams } from '../Tooltips';

import {
  useSecuritiesActives,
  useSecuritiesInSearch,
  useSecuritiesLimitOut,
  useValidation,
} from '../../hooks';

import styles from './SecurityActiveEditor.module.css';

interface SecurityActiveEditorProps {
  transferType: 'in' | 'out' | 'intra';
  position?: SecuritiesPosition | null;
  active?: ActiveTransfer;
  alfaMarket: string;
  counterpartyMarket: string;
  contrAlfaBank: boolean;
  generatedReference?: string;
  autosave?: boolean;
  onSave?: (act: ActiveTransfer) => void;
  onUnfilled?: () => void;
}

function getAmountInputError(
  value: number | null,
  touched: boolean
): AmountInputProps['error'] {
  if (!touched) {
    return '';
  }

  return isAmountValid((value || 0) / AMOUNT_MINORITY) ? '' : true;
}

export const SecurityActiveEditor = ({
  transferType,
  position,
  active,
  alfaMarket,
  counterpartyMarket,
  contrAlfaBank,
  generatedReference,
  autosave,
  onSave,
  onUnfilled,
}: SecurityActiveEditorProps) => {
  const [searchStr, setSearchStr] = useState(
    active ? securityPaperDescription(active.paper) : ''
  );
  const [selectedSecurityPaper, setSelectedSecurityPaper] = useState<
    SecuritiesActiveIn | SecuritiesActive | null
  >(active?.paper || null);
  const [amount, setAmount] = useState<number | null>(active?.count || null);
  const [dealDate, setDealDate] = useState(
    active?.dealDate || format(new Date(), DEFAULT_VIEW_DATE_FORMAT)
  );
  const [shipDate, setShipDate] = useState(
    active?.shipDate || format(new Date(), DEFAULT_VIEW_DATE_FORMAT)
  );
  const [reference, setReference] = useState(
    active?.reference || generatedReference || ''
  );
  const [gbox, setGbox] = useState(active?.gbox || '');

  const [searchTouched, setSearchTouched] = useState(false);
  const [amountTouched, setAmountTouched] = useState(false);
  const [referenceNeed, setReferenceNeed] = useState(
    active?.referenceNeed || false
  );

  // В реестре искать нечего
  const searchMarket = alfaMarket === 'REESTR' ? 'MICEX_SHR' : alfaMarket;

  const searchInParams = useMemo<SecuritiesActivesInSearchRequest>(() => {
    return {
      searchString: searchStr,
      placeCode: searchMarket,
    };
  }, [searchStr, searchMarket]);

  const [debouncedSearchInParams] =
    useDebounce<SecuritiesActivesInSearchRequest>(searchInParams, 250);

  const { data: activesIn } = useSecuritiesInSearch(
    debouncedSearchInParams,
    transferType === 'in'
  );
  const { data: actives } = useSecuritiesActives(
    position?.treaty,
    position?.accCode,
    position?.placeCode,
    transferType === 'out'
  );
  const { isFetching: limitsPending, data: limits } = useSecuritiesLimitOut(
    position?.accCode,
    position?.placeCode,
    selectedSecurityPaper?.isin,
    transferType === 'out'
  );

  const activesInItems = useMemo(() => {
    if (activesIn) {
      return activesIn.pages.flatMap((page) => page.actives);
    }

    return;
  }, [activesIn]);

  const values = useMemo(
    () => ({
      dealDate: dealDate,
      shipDate: shipDate,
      reference: reference,
    }),
    [dealDate, shipDate, reference]
  );
  const validators = useMemo(
    () => ({
      dealDate: isDateValid,
      shipDate: isDateValid,
      reference: validateReference,
    }),
    []
  );

  const { handleChange, handleBlur, touched, errors } = useValidation({
    values: values,
    validators: validators,
    transforms: {
      reference: (value) => toSimilarLat(upperCase(value)),
    },
    handlers: {
      dealDate: setDealDate,
      shipDate: setShipDate,
      reference: setReference,
    },
  });

  const onSearchInput: InputAutocompleteDesktopProps['onInput'] = (event) => {
    setSearchStr(event?.target?.value || '');
  };

  const onSearchClear = () => {
    setSearchStr('');
    setSelectedSecurityPaper(null);
  };

  const onSelectSecurity: InputAutocompleteDesktopProps['onChange'] = (
    payload
  ) => {
    const key = payload.selected?.key;
    const security =
      activesInItems?.find((active) => securityPaperKey(active) === key) ||
      actives?.find((active) => securityPaperKey(active) === key);

    if (security) {
      setSearchStr(securityPaperDescription(security));
      setSelectedSecurityPaper(security);
    }
  };

  const transferEverything = () => {
    setAmount((limits?.limit || 0) * AMOUNT_MINORITY);
  };

  const handleReferenceNeedChange: SwitchProps['onChange'] = (_, payload) => {
    const need = payload?.checked || false;

    setReferenceNeed(need);

    if (!need) {
      setReference('');
    }
  };

  const handleGboxChange: SelectDesktopProps['onChange'] = ({ selected }) => {
    setGbox(selected ? selected.key : '');
  };

  const activesOptions = useMemo<
    InputAutocompleteDesktopProps['options']
  >(() => {
    if (actives && (transferType === 'out' || transferType === 'intra')) {
      return actives.map((active) => ({
        key: securityPaperKey(active),
        content: securityPaperDescription(active),
      }));
    }

    if (activesInItems && transferType === 'in') {
      return activesInItems.map((active) => ({
        key: securityPaperKey(active),
        content: securityPaperDescription(active),
      }));
    }

    return [
      {
        key: 'loader-1',
        content: <Loader />,
      },
    ];
  }, [actives, activesInItems, transferType]);

  const typeText = transferType === 'in' ? 'зачисление' : 'перевод';
  let amoutHint = `Допускается ${typeText} только целого количества бумаг`;

  if (limits) {
    amoutHint = `Доступно: ${limits.limit}`;
  }

  let amountError = getAmountInputError(amount, amountTouched);

  if (amount && limits && amount / AMOUNT_MINORITY > limits.limit) {
    amountError = amoutHint;
  } else if (amount && !isAmountFulfill(amount / AMOUNT_MINORITY, limits)) {
    if (limits) {
      amountError = `Допускается ${typeText} только целого количества бумаг или дробного остатка целиком`;
    } else {
      amountError = `Допускается ${typeText} только целого количества бумаг`;
    }
  }

  const minDate = startOfDay(subDays(new Date(), 29)).getTime();

  const gboxOptions = useMemo(
    () => [
      { key: 'GBOX', content: 'GBOX' },
      { key: 'GBPX', content: 'GBPX' },
    ],
    []
  );

  useEffect(() => {
    if (selectedSecurityPaper && isGboxIsin(selectedSecurityPaper.isin)) {
      setGbox('GBOX');
    }
  }, [selectedSecurityPaper]);

  useEffect(() => {
    if (generatedReference) {
      setReference(generatedReference);
    }
  }, [generatedReference]);

  const referenceRequired = isReferenceRequired(
    alfaMarket,
    counterpartyMarket,
    contrAlfaBank
  );
  const datesRequired = isControlDatesRequired(counterpartyMarket);
  const gboxRequired = isGboxRequired(
    alfaMarket,
    selectedSecurityPaper?.isin || ''
  );

  const canSave: boolean = Boolean(
    amount &&
      (!limits || amount / AMOUNT_MINORITY <= limits.limit) &&
      isAmountFulfill(amount / AMOUNT_MINORITY, limits) &&
      (!(referenceRequired || referenceNeed) ||
        isReferenceValid(reference) ||
        // Если включен автосейв, то нужно сохранить, чтобы ушел запрос на референс
        autosave) &&
      (!datesRequired || isDateValid(dealDate)) &&
      (!datesRequired || isDateValid(shipDate))
  );

  const buildedActive: ActiveTransfer | undefined = useMemo(() => {
    if (canSave && selectedSecurityPaper) {
      return {
        paper: selectedSecurityPaper,
        count: amount || 0,
        referenceNeed: referenceNeed,
        reference: referenceRequired || referenceNeed ? reference : undefined,
        dealDate: datesRequired ? dealDate : undefined,
        shipDate: datesRequired ? shipDate : undefined,
        gbox: gboxRequired ? gbox : undefined,
      };
    }
  }, [
    canSave,
    selectedSecurityPaper,
    amount,
    referenceNeed,
    referenceRequired,
    reference,
    datesRequired,
    dealDate,
    shipDate,
    gboxRequired,
    gbox,
  ]);

  const handleSave = useCallback(
    (security: ActiveTransfer | undefined) => {
      if (security && onSave) {
        onSave(security);
      }
    },
    [onSave]
  );

  const handleUnfilled = useCallback(() => {
    if (onUnfilled) {
      onUnfilled();
    }
  }, [onUnfilled]);

  // Автосохранение
  useEffect(() => {
    if (autosave && buildedActive) {
      handleSave(buildedActive);
    } else if (autosave && !buildedActive) {
      handleUnfilled();
    }
  }, [autosave, buildedActive, handleSave, handleUnfilled]);

  let securityError: string | boolean =
    searchTouched && !isSecurityPaperValid(selectedSecurityPaper);

  if (selectedSecurityPaper && !isSecurityPaperEnabled(selectedSecurityPaper)) {
    securityError = 'Перевод этой ценной бумаги недоступен';
  }

  return (
    <>
      <Typography.Text view="primary-small" weight="bold" tag="p">
        Ценная бумага
      </Typography.Text>
      <Space fullWidth direction="vertical">
        <InputAutocompleteDesktop
          block
          inputProps={{
            size: 's',
            clear: true,
            onClear: onSearchClear,
          }}
          size="s"
          placeholder="Наименование"
          value={searchStr}
          onInput={onSearchInput}
          onChange={onSelectSecurity}
          onBlur={() => setSearchTouched(true)}
          closeOnSelect={true}
          error={securityError}
          options={activesOptions}
          optionsListWidth="field"
          hint="Вы можете ввести наименование, ISIN, номер государственной регистрации или тикер"
        />
        <Row>
          <Col width={6}>
            <AmountInput
              block
              bold={false}
              autoComplete="off"
              size="s"
              value={amount}
              suffix="шт."
              minority={AMOUNT_MINORITY}
              placeholder="Количество"
              onChange={(_, payload) => setAmount(payload.value)}
              onBlur={() => setAmountTouched(true)}
              integerLength={18}
              hint={amoutHint}
              error={amountError}
            />
          </Col>
          {Boolean(limits) && (
            <Col width={6}>
              <ButtonDesktop
                size="s"
                view="tertiary"
                block
                disabled={!limits || limits.limit < 0}
                loading={limitsPending}
                onClick={transferEverything}
              >
                Перевести всё
              </ButtonDesktop>
            </Col>
          )}
        </Row>

        <div>
          <Typography.Text
            view="primary-small"
            weight="bold"
            tag="div"
            className={styles.subtitle}
          >
            Параметры сделки для выбранной ценной бумаги&nbsp;&nbsp;
            <TooltipSecurityParams />
          </Typography.Text>
          <Space fullWidth direction="vertical">
            {datesRequired && (
              <Row>
                <Col width={6}>
                  <CalendarInputDesktop
                    label="Дата сделки"
                    labelView="outer"
                    size="s"
                    block
                    autoComplete="off"
                    minDate={minDate}
                    name="dealDate"
                    value={dealDate}
                    onChange={(e, { value }) => setDealDate(value)}
                    onInputChange={(e, { value }) => setDealDate(value)}
                    onBlur={handleBlur}
                    error={touched.dealDate ? errors.dealDate : ''}
                  />
                </Col>
                <Col width={6}>
                  <CalendarInputDesktop
                    label="Дата поставки"
                    labelView="outer"
                    size="s"
                    block
                    autoComplete="off"
                    minDate={minDate}
                    name="shipDate"
                    value={shipDate}
                    onChange={(e, { value }) => setShipDate(value)}
                    onInputChange={(e, { value }) => setShipDate(value)}
                    onBlur={handleBlur}
                    error={touched.shipDate ? errors.shipDate : ''}
                  />
                </Col>
              </Row>
            )}
            {gboxRequired && (
              <Row>
                <Col width={6}>
                  <SelectDesktop
                    label="Гербовый сбор"
                    labelView="outer"
                    size="s"
                    block
                    options={gboxOptions}
                    selected={gbox}
                    onChange={handleGboxChange}
                  />
                </Col>
              </Row>
            )}
            {!referenceRequired && (
              <>
                <Switch
                  checked={referenceNeed}
                  label="Требуется референс"
                  onChange={handleReferenceNeedChange}
                  addons={<TooltipReference />}
                />
              </>
            )}
            {(referenceRequired || referenceNeed) && (
              <Row>
                <Col width={6}>
                  <InputDesktop
                    label="Референс"
                    labelView="outer"
                    block
                    autoComplete="off"
                    size="s"
                    name="reference"
                    value={reference}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.reference ? errors.reference : ''}
                    hint={getReferenceErrorMsg()}
                  />
                </Col>
              </Row>
            )}
          </Space>
        </div>

        {!autosave && (
          <Row>
            <Col width={6}>
              <ButtonDesktop
                block
                size="s"
                disabled={!canSave}
                onClick={() => handleSave(buildedActive)}
                leftAddons={<CheckmarkCompactMIcon />}
              >
                Сохранить
              </ButtonDesktop>
            </Col>
          </Row>
        )}
      </Space>
    </>
  );
};
