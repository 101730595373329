import { Link } from '@alfalab/core-components/link';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { useAppContext } from '@terminal/core/hooks/useAppContext';

import { useThemeContext } from '../../../shared/lib/themeContext';

import { StepScreenProps } from '../types';

import styles from './Steps.module.css';

export const FifthStep = ({ openSettingsModal, style }: StepScreenProps) => {
  const { theme } = useThemeContext();
  const { onboarding } = useAppContext();
  const { workspaces } = onboarding.images[theme];

  return (
    <div style={style} className={styles.slideContainer}>
      <div className={styles.stepContainer}>
        <Space fullWidth>
          <Typography.Text view="primary-large" weight="bold">
            Сохранение рабочего пространства
          </Typography.Text>
          <Typography.Text view="secondary-large">
            У вас есть возможность сохранить конфигурацию рабочего пространства
            — это позволит быстро применять ранее сохраненные настройки рабочего
            стола, например, при работе с различными торговыми стратегиями.
          </Typography.Text>
          <Typography.Text view="secondary-large">
            Для этого кликните правой кнопкой мыши по вкладке, которую хотите
            сохранить и выберите «Сохранить конфигурацию».
          </Typography.Text>
          <Link onClick={openSettingsModal}>
            Перейти к настройке конфигураций
          </Link>
        </Space>
      </div>
      <div
        className={styles.fifthStepImage}
        style={{ backgroundImage: `url(${workspaces})` }}
      />
    </div>
  );
};
