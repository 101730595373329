import { mapColumnToName } from '../../constants/tableColumnsName';

import { TableColumnSetting } from '../../types/layout';

export function getColumnName(column: TableColumnSetting): string {
  const { key } = column;
  const name = mapColumnToName[key];

  if (name) {
    return name;
  }

  // TODO: временное решение для подстраховки
  if ('name' in column && column.name) {
    return column.name as string;
  }

  return 'Unknown Column Name';
}
