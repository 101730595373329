import React, { useState } from 'react';
import { Loader } from '@alfalab/core-components/loader';

import {
  AccountInfo,
  AccountInfoGI,
} from '@terminal/core/lib/rest/lkAccountInfo';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { isFeatureHidden, useFeatureSettings } from '@terminal/lk/shared';

import {
  ManagementResult,
  ManagementResultType,
} from '../../../../entities/Management';
import { AccessList } from '../AccessList';
import { Subaccounts } from '../Subaccounts';

import styles from './Management.module.css';

interface ManagementProps {
  treaty: Treaty | null;
  accountInfo?: AccountInfo | AccountInfoGI;
  refetchAccountInfo: () => void;
  accountInfoPending: boolean;
  onSubaccountAdd: () => void;
  onSubaccountRedacted: () => void;
}

export const Management = ({
  treaty,
  accountInfo,
  accountInfoPending,
  refetchAccountInfo,
  onSubaccountAdd,
  onSubaccountRedacted,
}: ManagementProps) => {
  const [managementResult, setManagementResult] =
    useState<ManagementResultType>();

  const { data: featureSettings, isFetching: areFeatureSettingsFetching } =
    useFeatureSettings();

  const hideAttorneyCreation = isFeatureHidden(
    'create-power-of-attorney',
    featureSettings
  );

  if (accountInfoPending || areFeatureSettingsFetching) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  if (!accountInfo || !treaty) {
    return null;
  }

  if (managementResult) {
    return (
      <ManagementResult
        icon={managementResult.icon}
        title={managementResult.message}
        description={managementResult.description}
        onClose={() => {
          setManagementResult(undefined);
        }}
      />
    );
  }

  return (
    <>
      <div className={styles.subaccounts}>
        <Subaccounts
          treaty={treaty}
          subaccounts={accountInfo.subAccounts}
          indLimit={accountInfo.indLimit}
          onSubaccountAdd={onSubaccountAdd}
          onSubaccountRedacted={onSubaccountRedacted}
        />
      </div>
      {accountInfo.treatyManagers ? (
        <div className={styles.access}>
          <AccessList
            managers={accountInfo.treatyManagers}
            treaty={treaty}
            subAccounts={accountInfo.subAccounts}
            setResult={setManagementResult}
            refetchAccountInfo={refetchAccountInfo}
            hideAttorneyCreation={hideAttorneyCreation}
          />
        </div>
      ) : null}
    </>
  );
};
