import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { sendDepoRequisites } from '@terminal/core/lib/rest/lkCommon';
import { NotificationType } from '@terminal/core/types/ui';

export const useSendDepoRequisites = () => {
  const addNotification = useNotification();

  return useMutation(sendDepoRequisites, {
    onSuccess: () => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'positive',
        title: 'Реквизиты отправлены',
      });
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При отправке реквизитов произошла ошибка',
      });
    },
  });
};
