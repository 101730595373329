import { GetState } from 'zustand';

import { SupportService } from '../../api/chatService';
import { ChatStore, StoreSet } from '../useStore';

import {
  ChatGroupId,
  ChatMessage,
  ESupportConnectionState,
  SupportSlice,
} from '../../types';

export const createSupportSlice = (
  set: StoreSet,
  get: GetState<ChatStore>
): SupportSlice => ({
  supportConnectionState: ESupportConnectionState.IDLE,
  setSupportConnectionState: (newState: ESupportConnectionState) =>
    set((state) => {
      state.supportConnectionState = newState;
    }),
  getSupportMessage: (message: ChatMessage) => {
    get().setNewMessages(ChatGroupId.TECH_SUPPORT, [message]);
  },
  connectToChat: async () => {
    try {
      get().setSupportConnectionState(ESupportConnectionState.CONNECTING);
      await SupportService.connectChat(get().getSupportMessage);
      get().setSupportConnectionState(ESupportConnectionState.OK);
      // TODO Если из памяти не выгружены сообщения, после подключения зафетчить новые непрочитанные /chat/api/fetch
    } catch (err) {
      get().setSupportConnectionState(ESupportConnectionState.ERROR);
    }
  },
  disconnectFromChat: () => {
    SupportService.disconnectChat();
    get().setSupportConnectionState(ESupportConnectionState.IDLE);
  },
  getChatHistory: async () => {
    set((state) => {
      state.messagesLoading = true;
      state.historyError = false;
    });

    const firstMessage = get().messages?.[ChatGroupId.TECH_SUPPORT]?.[0];

    try {
      const messages = await SupportService.fetchChatHistory({
        limit: 20,
        messageId: String(firstMessage?.idChatMessage),
      });

      set((state) => {
        if ((messages?.length || 0) === 0) {
          state.allMessagesLoad = true;
        } else {
          state.messages[ChatGroupId.TECH_SUPPORT].unshift(...messages);
        }

        state.messagesLoading = false;
      });
    } catch (err) {
      set((state) => {
        state.messagesLoading = false;
        state.historyError = true;
      });
    }
  },
});
