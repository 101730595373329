import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowsRightLeftCurvedMediumMIcon } from '@alfalab/icons-glyph/ArrowsRightLeftCurvedMediumMIcon';
import RepeatMIcon from '@alfalab/icons-glyph/RepeatMIcon';

import styles from './IntstrumentError.module.css';

interface props {
  onReload: () => void;
}

export const IntstrumentError = ({ onReload }: props) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconPlaceholder}>
        <RepeatMIcon
          color="var(--color-light-graphic-secondary)"
          width={36}
          height={36}
        />
      </div>
      <Typography.Title tag="div" color="secondary" view="xsmall">
        Ошибка загрузки
      </Typography.Title>
      <Typography.Text tag="div" color="tertiary" view="secondary-large">
        Не удалось получить данные. <br />
        Попробуйте обновить страницу
      </Typography.Text>
      <ButtonDesktop
        block
        size="xxs"
        className={styles.repeat}
        leftAddons={
          <ArrowsRightLeftCurvedMediumMIcon
            color="var(--color-light-graphic-secondary-inverted)"
            width={14}
            height={14}
          />
        }
        onClick={onReload}
      >
        Обновить
      </ButtonDesktop>
    </div>
  );
};
