import { RootStore } from '../../useStore';

type ReturnType = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

/**
 * Выбирает состояние модального окна «Общие настройки»
 * */
export function trackPriceSettingsModalStateSelector(
  store: RootStore
): ReturnType {
  return {
    isOpen: store.isTrackPriceSettingsModalOpen,
    open: store.openTrackPriceSettingsModal,
    close: store.closeTrackPriceSettingsModal,
  };
}
