import { WidgetsDefaultConfigMap } from '@terminal/core/constants/Layout';
import {
  defaultBalanceColumnSetting,
  getDefaultOperationsColumnSetting,
  getDefaultOrderTableColumnSetting,
  getDefaultPositionColumnSetting,
} from '@terminal/core/constants/tableProps';
import {
  LinkName,
  TradingLimitsShowMode,
  Widget,
} from '@terminal/core/types/layout';

export const getCommonInstrumentsConfig = (
  link?: LinkName,
  selectedListId: string = 'default'
) => ({
  ...WidgetsDefaultConfigMap.get(Widget.WATCHLIST),
  link,
  selectedListId,
});

export const POSITIONS_COMMON_CONFIG = {
  symbol: 'SBER',
  idFi: 144950,
  link: LinkName.BLUE,
  tableProps: {
    columns: getDefaultPositionColumnSetting(),
  },
};

export const BALANCES_BALANCES_CONFIG = {
  columns: defaultBalanceColumnSetting,
};

export const BALANCES_OPERATIONS_CONFIG = {
  tableProps: { columns: getDefaultOperationsColumnSetting() },
};

export const BALANCES_POSITIONS_CONFIG = {
  tableProps: {
    columns: defaultBalanceColumnSetting,
  },
};

export const COMMON_ORDERS_CONFIG = {
  tableProps: {
    columns: getDefaultOrderTableColumnSetting(),
  },
};

export const TRADE_ORDERBOOK_CONFIG = {
  symbol: 'SBER',
  id: 144950,
  link: LinkName.BLUE,
  limitsProps: {
    showLimits: TradingLimitsShowMode.NONE,
    showNPU: TradingLimitsShowMode.MONEY,
    showPosition: true,
  },
  tableProps: {},
  idFi: 144950,
} as const;

export const TRADE_HYBRIDORDER_CONFIG = {
  symbol: 'SBER',
  idFi: 144950,
  link: LinkName.BLUE,
  tableProps: {},
};

export const BASE_CHART_CONFIG = {
  symbol: 'SBER',
  idFi: 144950,
  link: LinkName.BLUE,
  tableProps: {},
} as const;
