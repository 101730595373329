import { useMemo } from 'react';

import { getAssetUnits } from '@terminal/core/lib/format';

import { useWidgetContext } from '../../../shared';
import { useOrderBookFinInfoExt } from './useOrderBookFinInfoExt';

import { useIsLotCount } from '../../../shared/hooks/useIsLotCount';

export interface DefaultQuantity {
  quantity: number;
  assetUnits: string;
}

/**
 * Хук возвращает умолчательный объем для подачи заявки по idFi
 */
export const useDefaultQuantity = (
  idFi: number
): DefaultQuantity | undefined => {
  const { useSettings } = useWidgetContext();
  const settings = useSettings();
  const { quantityMap, orderDimension } = settings.defaultValues;

  const isLotCount = useIsLotCount();

  const finInfoExt = useOrderBookFinInfoExt(idFi);

  const quantity = useMemo(() => {
    const defaultQuantity = quantityMap?.[idFi];

    if (defaultQuantity) {
      if (isLotCount && finInfoExt?.lot) {
        const defaultLotQuantity = defaultQuantity / finInfoExt.lot;

        return {
          quantity: defaultLotQuantity,
          assetUnits: getAssetUnits(defaultLotQuantity, orderDimension),
        };
      } else {
        return {
          quantity: defaultQuantity,
          assetUnits: getAssetUnits(defaultQuantity, orderDimension),
        };
      }
    }
  }, [finInfoExt?.lot, idFi, isLotCount, orderDimension, quantityMap]);

  return quantity;
};
