import cn from 'classnames';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { countDecimals } from '@terminal/core/lib/format';

import styles from './TotalItem.module.css';

interface TotalItemProps {
  amount: number;
  currency: string;
  className?: string;
}

export const TotalItem = ({ amount, currency, className }: TotalItemProps) => {
  const decimals = countDecimals(amount);
  const minority = Math.pow(10, decimals);
  const formatedBalance = formatAmount({
    value: (Math.abs(amount) || 0) * minority,
    currency: currency as CurrencyCodes,
    minority: minority,
    view: 'withZeroMinorPart',
  });

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.amount}>
        {formatedBalance.formattedWithCurrency}
      </div>
      <div className={styles.bar}></div>
    </div>
  );
};
