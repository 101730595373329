import { FC, PropsWithChildren, ReactNode } from 'react';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { IconButton } from '@alfalab/core-components/icon-button';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { BackButton, BackButtonProps } from './ui/BackButton';
import { Drawer } from './ui/Drawer';

import styles from './Dialog.module.css';

interface Props extends Pick<BaseModalProps, 'open' | 'onClose'> {
  width?: number;
  minHeight?: number;
  backButton?: BackButtonProps;
  drawer?: {
    content: ReactNode;
    onClose: () => void;
  };
}

export const Dialog: FC<PropsWithChildren<Props>> = ({
  open,
  onClose,
  width,
  minHeight,
  backButton,
  drawer,
  children,
}) => {
  const backButtonProps = (() => {
    if (backButton) {
      return backButton;
    }

    if (drawer) {
      return {
        onClick: drawer.onClose,
        text: 'Назад',
      };
    }
  })();

  return (
    <BaseModal open={open} onClose={onClose} className={styles.modal}>
      <div
        className={styles.container}
        style={{
          width,
          minHeight,
        }}
      >
        <div className={styles.header}>
          {backButtonProps ? <BackButton {...backButtonProps} /> : <div />}

          <IconButton
            // @ts-expect-error
            size="m"
            view="secondary"
            icon={CrossMIcon}
            onClick={onClose}
          />
        </div>

        <div className={styles.body}>
          {children}
          {drawer ? <Drawer>{drawer.content}</Drawer> : null}
        </div>
      </div>
    </BaseModal>
  );
};
