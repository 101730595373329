import cn from 'classnames';
import isNull from 'lodash/isNull';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { Amount } from '@alfalab/core-components/amount';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { Button } from '@alfalab/core-components/button/modern';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CalendarLineMIcon } from '@alfalab/icons-glyph/CalendarLineMIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { ClockLineMIcon } from '@alfalab/icons-glyph/ClockLineMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { TrashCanLineMIcon } from '@alfalab/icons-glyph/TrashCanLineMIcon';

import {
  useEditOrder,
  usePriceToMarketCurrency,
} from '@terminal/alfadirect/hooks';
import { Dropdown } from '@terminal/common/components/Dropdown';
import { Stepper } from '@terminal/common/components/Stepper';
import { Table } from '@terminal/common/ui/Table';
import {
  activationOptions,
  lifeTimeOptions,
} from '@terminal/core/constants/orderBook';
import { MINORITY } from '@terminal/core/constants/ui';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import {
  ObjectGroup,
  OrderDirection,
  OrderType,
} from '@terminal/core/lib/client/entities';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { roundPrice } from '@terminal/core/lib/format';
import { getOrderSendPrice } from '@terminal/core/lib/formulas';
import { getEditOrderIdQuantityType } from '@terminal/core/lib/formulas/getEditOrderIdQuantityType';
import { getPriceError } from '@terminal/core/lib/helpers/getPriceError';
import { ReplaceOrderFn } from '@terminal/core/lib/services/trading/useTradingService';
import { AccountItem } from '@terminal/core/types/account';
import { NewOrderType, OrderItem } from '@terminal/core/types/order';
import { OrderSide } from '@terminal/core/types/trading';
import { SlippageType } from '@terminal/core/types/ui';

import { useWidgetContext } from '../../../../shared';
import { getOrderBookColumns } from '../../../../shared/components/getOrderBookColumns';
import { SlippageInput } from '../../../SlippageInput';
import { SymbolCell } from '../../../SymbolCell';
import { TradingCertificateLock } from '../../../TradingCertificateLock';
import { ConfirmEditOrderModal } from '../ConfirmEditOrderModal';

import { useIsLotCount } from '../../../../shared/hooks/useIsLotCount';
import { useActivationTime } from '../../hooks/useActivationTime';

import styles from './EditOrderModal.module.css';

interface Props {
  idFi?: number;
  order: OrderItem | null;
  onClose: () => void;
  onCancelOrder: () => void;
  selectedAccount?: AccountItem;
  selectedSubAccountId: number;
  replaceOrder: ReplaceOrderFn;
  isOpen: boolean;
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
}

export const EditOrderModal = ({
  onClose,
  order,
  selectedAccount,
  onCancelOrder,
  selectedSubAccountId,
  replaceOrder,
  isOpen,
  isLoading,
  setIsLoading,
  ...props
}: Props) => {
  const lifeTimeDropdown = useRef<HTMLDivElement>(null);
  const activationDropdown = useRef<HTMLDivElement>(null);

  useOnClickOutside(lifeTimeDropdown, () => setLifeTimeOpen(false));
  useOnClickOutside(activationDropdown, () => setActivationOpen(false));

  const { useSettings, DropdownComponent } = useWidgetContext();
  const {
    defaultValues: {
      selectedDisplayInstrumentType,
      alwaysConfirmOrders,
      orderDimension,
    },
  } = useSettings();

  const [slippageType, setSlippageType] = useState<SlippageType>(
    SlippageType.TICK
  );
  const [lifeTimeOpen, setLifeTimeOpen] = useState(false);
  const [activationOpen, setActivationOpen] = useState(false);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  useEffect(() => {
    setIsOpenConfirm(false);
  }, [isOpen]);
  const [priceTouched, setPriceTouched] = useState(false);

  const isLotCount = useIsLotCount();

  const {
    currencyCode,
    priceDecimals,
    symbolObject,
    idFi,
    price,
    slippage,
    getQuantity,
    decimalsNumber,
    finInfoExt,
    setPrice,
    setSlippage,
    quantity,
    setQuantity,
    lifeTime,
    setLifeTime,
    activation,
    setActivation,
    isConfirmDisabled,
    takeLastPrice,
    fee,
    stopPrice,
    setStopPrice,
    limitPrice,
    setLimitPrice,
    isBond,
    fullFi,
  } = useEditOrder({
    idFiProp: props.idFi,
    order,
    isLotCount,
    selectedAccount,
  });

  const symbol = getSymbolToShow(fullFi?.currencyCode, fullFi?.idObjectGroup);

  const slippageValue =
    slippage /
    (slippageType === SlippageType.TICK
      ? finInfoExt?.priceStep || 0.01
      : price / 100);

  const handleSetSlippage = (slippage: number) => {
    if (slippageType === SlippageType.TICK) {
      setSlippage(slippage * (finInfoExt?.priceStep || 0.01));
    } else {
      setSlippage((price * slippage) / 100);
    }
  };

  const columns = useMemo(() => {
    let columnSettings = getOrderBookColumns(
      priceDecimals,
      orderDimension,
      finInfoExt?.lot,
      fullFi?.idObjectGroup === ObjectGroup.Bonds,
      currencyCode,
      undefined,
      undefined,
      true,
      false,
      order?.idOrderType
    );

    //В данном компоненте показываем Тикер и скрываем колонку Действий
    columnSettings = [
      {
        Header:
          selectedDisplayInstrumentType === 'name' ? 'Наименование' : 'Тикер',
        minWidth: 110,
        Cell: ({ row: { original } }: CellProps<OrderItem>) => (
          <SymbolCell
            idObject={original.idObject}
            symbolObject={symbolObject ? symbolObject : undefined}
          />
        ),
      },
      ...columnSettings.filter((col) => col.Header !== 'Действия'),
    ];

    return columnSettings;
  }, [
    priceDecimals,
    orderDimension,
    finInfoExt?.lot,
    fullFi?.idObjectGroup,
    currencyCode,
    order?.idOrderType,
    selectedDisplayInstrumentType,
    symbolObject,
  ]);

  const { displayedTotalPrice } = usePriceToMarketCurrency(
    fullFi,
    price,
    MINORITY,
    fullFi?.nominal,
    getQuantity(),
    finInfoExt?.priceStep,
    finInfoExt?.priceStepCost
  );

  const renderFee = () => (
    <div>
      ≈&nbsp;
      {fee ? (
        <Amount.Pure
          //Пока захардкодил как 1% комиссии
          //Комиссия всегда с точностью 2 знака после запятой
          value={fee * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
          //Комиссия отображается только в рублевом виде, независимо от валюты fi
          currency="RUB"
        />
      ) : (
        '0,00'
      )}
    </div>
  );
  const getActivationTime = useActivationTime();

  const newOrder: NewOrderType | null = order
    ? {
        ...order,
        quantity: getQuantity(),
        idLifeTime: lifeTime.value,
        idActivationTime: activation.value,
        activationTime: getActivationTime(activation.value),
        idQuantityType: getEditOrderIdQuantityType(order),
        ...(order.idOrderType === OrderType.BRS
          ? {
              limitPrice,
              stopPrice,
            }
          : getOrderSendPrice(order.idOrderType, price, takeLastPrice())),
        limitLevelAlternative:
          order.price +
          (order.buySell === OrderDirection.Buy ? 1 : -1) * slippage,
      }
    : null;

  if (newOrder?.stopPrice && finInfoExt) {
    const limitLevelAlternative =
      newOrder.stopPrice +
      (newOrder.buySell === OrderDirection.Buy ? 1 : -1) * slippage;

    newOrder.limitLevelAlternative = roundPrice(
      limitLevelAlternative,
      finInfoExt.priceStep
    );
  }

  return (
    <>
      {order && isOpen ? (
        <BaseModal open={isOpen} onClose={onClose}>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <Typography.Text view="secondary-large" weight="bold">
                Редактирование заявки
              </Typography.Text>
              <IconButton
                size="xs"
                view="secondary"
                icon={CrossMIcon}
                onClick={onClose}
              />
            </div>
            <Table
              columns={columns as Column<object>[]}
              data={[order]}
              tableClassname={styles.infoTable}
            />
            <div className={styles.form}>
              <div className={styles.formRow}>
                {order?.idOrderType === OrderType.BRS ||
                order?.idOrderType === OrderType.TBRS ? (
                  <>
                    <div>
                      <Typography.Text
                        tag="div"
                        view="secondary-small"
                        weight="medium"
                        className={styles.inputLabel}
                      >
                        Цена стоп
                      </Typography.Text>
                      <AmountInput
                        className={styles.customInput}
                        fieldClassName={styles.customInputField}
                        error={getPriceError(
                          price,
                          priceTouched,
                          finInfoExt?.priceStep
                        )}
                        block
                        suffix={isBond ? '%' : currencyCode || ''}
                        currency={isBond ? undefined : currencyCode}
                        value={stopPrice * priceDecimals}
                        minority={priceDecimals}
                        onBlur={() => !priceTouched && setPriceTouched(true)}
                        onChange={(e, { value }) =>
                          setStopPrice(
                            isNull(value)
                              ? 0
                              : Number(
                                  (value / priceDecimals).toFixed(
                                    decimalsNumber
                                  )
                                )
                          )
                        }
                        rightAddons={
                          <div>
                            <Stepper
                              onAdd={() => {
                                if (finInfoExt) {
                                  setStopPrice(
                                    roundPrice(
                                      stopPrice + finInfoExt.priceStep,
                                      finInfoExt.priceStep
                                    )
                                  );

                                  if (!priceTouched) {
                                    setPriceTouched(true);
                                  }
                                }
                              }}
                              onSub={() => {
                                if (finInfoExt) {
                                  setStopPrice(
                                    Math.max(
                                      0,
                                      roundPrice(
                                        stopPrice - finInfoExt.priceStep,
                                        finInfoExt.priceStep
                                      )
                                    )
                                  );

                                  if (!priceTouched) {
                                    setPriceTouched(true);
                                  }
                                }
                              }}
                            />
                          </div>
                        }
                      />
                    </div>
                    <div>
                      <Typography.Text
                        tag="div"
                        view="secondary-small"
                        weight="medium"
                        className={styles.inputLabel}
                      >
                        Цена тейк
                      </Typography.Text>
                      <AmountInput
                        className={styles.customInput}
                        fieldClassName={styles.customInputField}
                        error={getPriceError(
                          price,
                          priceTouched,
                          finInfoExt?.priceStep
                        )}
                        block
                        suffix={isBond ? '%' : currencyCode || ''}
                        currency={isBond ? undefined : currencyCode}
                        value={limitPrice * priceDecimals}
                        minority={priceDecimals}
                        onBlur={() => !priceTouched && setPriceTouched(true)}
                        onChange={(e, { value }) =>
                          setLimitPrice(
                            isNull(value)
                              ? 0
                              : Number(
                                  (value / priceDecimals).toFixed(
                                    decimalsNumber
                                  )
                                )
                          )
                        }
                        rightAddons={
                          <div>
                            <Stepper
                              onAdd={() => {
                                if (finInfoExt) {
                                  setLimitPrice(
                                    roundPrice(
                                      limitPrice + finInfoExt.priceStep,
                                      finInfoExt.priceStep
                                    )
                                  );

                                  if (!priceTouched) {
                                    setPriceTouched(true);
                                  }
                                }
                              }}
                              onSub={() => {
                                if (finInfoExt) {
                                  setLimitPrice(
                                    Math.max(
                                      0,
                                      roundPrice(
                                        limitPrice - finInfoExt.priceStep,
                                        finInfoExt.priceStep
                                      )
                                    )
                                  );

                                  if (!priceTouched) {
                                    setPriceTouched(true);
                                  }
                                }
                              }}
                            />
                          </div>
                        }
                      />
                    </div>
                    <div></div>
                  </>
                ) : (
                  <AmountInput
                    className={styles.customInput}
                    fieldClassName={styles.customInputField}
                    error={getPriceError(
                      price,
                      priceTouched,
                      finInfoExt?.priceStep
                    )}
                    block
                    suffix={isBond ? '%' : currencyCode || ''}
                    currency={isBond ? undefined : currencyCode}
                    value={price * priceDecimals}
                    minority={priceDecimals}
                    onBlur={() => !priceTouched && setPriceTouched(true)}
                    onChange={(e, { value }) =>
                      setPrice(
                        isNull(value)
                          ? 0
                          : Number(
                              (value / priceDecimals).toFixed(decimalsNumber)
                            )
                      )
                    }
                    rightAddons={
                      <div>
                        <Stepper
                          onAdd={() => {
                            if (finInfoExt) {
                              setPrice(
                                roundPrice(
                                  price + finInfoExt.priceStep,
                                  finInfoExt.priceStep
                                )
                              );

                              if (!priceTouched) {
                                setPriceTouched(true);
                              }
                            }
                          }}
                          onSub={() => {
                            if (finInfoExt) {
                              setPrice(
                                Math.max(
                                  0,
                                  roundPrice(
                                    price - finInfoExt.priceStep,
                                    finInfoExt.priceStep
                                  )
                                )
                              );

                              if (!priceTouched) {
                                setPriceTouched(true);
                              }
                            }
                          }}
                        />
                      </div>
                    }
                  />
                )}
                <AmountInput
                  //Проверяем кратность лоту
                  className={styles.customInput}
                  fieldClassName={styles.customInputField}
                  error={
                    !isLotCount &&
                    finInfoExt &&
                    quantity % finInfoExt.lot !== 0 ? (
                      <>Не кратно лоту</>
                    ) : undefined
                  }
                  block
                  suffix={isLotCount ? 'лот' : 'шт'}
                  value={quantity}
                  minority={1}
                  integersOnly
                  onChange={(e, { value }) => {
                    setQuantity(value || 0);
                  }}
                  onBlur={() => {
                    if (
                      finInfoExt &&
                      !isLotCount &&
                      quantity < finInfoExt?.lot
                    ) {
                      setQuantity(finInfoExt.lot);
                    }
                  }}
                  rightAddons={
                    <Stepper
                      onAdd={() => {
                        if (isLotCount) {
                          setQuantity((prevQuantity) => ++prevQuantity);
                        } else if (finInfoExt) {
                          setQuantity(quantity + finInfoExt.lot);
                        }
                      }}
                      onSub={() => {
                        if (isLotCount) {
                          setQuantity((prevQuantity) =>
                            prevQuantity > 1 ? --prevQuantity : prevQuantity
                          );
                        } else if (finInfoExt) {
                          setQuantity(
                            quantity > finInfoExt.lot
                              ? quantity - finInfoExt.lot
                              : finInfoExt.lot
                          );
                        }
                      }}
                    />
                  }
                  dataTestId="priceInput"
                />
              </div>
              <div className={styles.formRow}>
                {/* Выбираем дату активации заявки */}
                <div ref={activationDropdown} className={styles.dropdownRef}>
                  <div
                    className={styles.dropDownContent}
                    onClick={() => setActivationOpen((prev) => !prev)}
                  >
                    <ClockLineMIcon
                      height={18}
                      width={18}
                      color="var(--color-dark-graphic-secondary)"
                    />
                    <Typography.Text
                      view="secondary-large"
                      className={styles.orderName}
                    >
                      {activation.content}
                    </Typography.Text>
                    <ChevronDownMIcon
                      height={18}
                      width={18}
                      color="currentColor"
                      className={cn(
                        styles.chevron,
                        activationOpen && styles.chevronUp
                      )}
                    />
                  </div>
                  <DropdownComponent
                    useAnchorWidth
                    anchorElement={activationDropdown.current}
                    open={activationOpen}
                    offset={[0, 4]}
                    position="top-start"
                    header={
                      <div className={styles.menuTitleWrapper}>
                        <Typography.Text view="secondary-small" weight="bold">
                          Активация
                        </Typography.Text>
                      </div>
                    }
                    headerDivider={false}
                  >
                    {activationOptions.map((opt) => {
                      const isSelected = opt.key === activation.key;

                      return (
                        <Dropdown.Item
                          key={opt.key}
                          content={<div>{opt.content}</div>}
                          onClick={() => setActivation(opt)}
                          rightIcon={
                            isSelected ? (
                              <CheckmarkMIcon height={18} width={18} />
                            ) : undefined
                          }
                        />
                      );
                    })}
                  </DropdownComponent>
                </div>
                {/* Выбираем срок исполнения заявки */}
                <div ref={lifeTimeDropdown} className={styles.dropdownRef}>
                  <div
                    className={styles.dropDownContent}
                    onClick={() => setLifeTimeOpen((prev) => !prev)}
                  >
                    <CalendarLineMIcon
                      height={18}
                      width={18}
                      color="var(--color-dark-graphic-secondary)"
                    />
                    <Typography.Text
                      view="secondary-large"
                      className={styles.orderName}
                    >
                      {lifeTime.content}
                    </Typography.Text>
                    <ChevronDownMIcon
                      height={18}
                      width={18}
                      color="currentColor"
                      className={cn(
                        styles.chevron,
                        lifeTimeOpen && styles.chevronUp
                      )}
                    />
                  </div>
                  <DropdownComponent
                    useAnchorWidth
                    anchorElement={lifeTimeDropdown.current}
                    open={lifeTimeOpen}
                    offset={[0, 4]}
                    position="top-start"
                    header={
                      <div className={styles.menuTitleWrapper}>
                        <Typography.Text view="secondary-small" weight="bold">
                          Срок исполнения
                        </Typography.Text>
                      </div>
                    }
                    headerDivider={false}
                  >
                    {lifeTimeOptions.map((opt) => {
                      const isSelected = opt.key === lifeTime.key;

                      if (opt?.isHidden) {
                        return null;
                      }

                      return (
                        <Dropdown.Item
                          key={opt.key}
                          content={<div>{opt.content}</div>}
                          onClick={() => setLifeTime(opt)}
                          rightIcon={
                            isSelected ? (
                              <CheckmarkMIcon height={18} width={18} />
                            ) : undefined
                          }
                        />
                      );
                    })}
                  </DropdownComponent>
                </div>
              </div>
              {order.idOrderType === OrderType.STL && (
                <SlippageInput
                  withSummary
                  side={
                    order.buySell === OrderDirection.Buy
                      ? OrderSide.BUY
                      : OrderSide.SELL
                  }
                  price={price}
                  priceStep={finInfoExt?.priceStep}
                  symbol={symbol}
                  slippageType={slippageType}
                  setSlippageType={setSlippageType}
                  slippageValue={slippageValue}
                  setSlippageValue={handleSetSlippage}
                />
              )}
              <div>
                <div className={styles.formRow}>
                  <div className={styles.plate}>
                    <Typography.Text view="secondary-large" color="secondary">
                      Итог
                    </Typography.Text>
                    <Amount.Pure
                      value={displayedTotalPrice}
                      minority={MINORITY}
                      view="withZeroMinorPart"
                      currency={currencyCode}
                      dataTestId="sizeInput"
                    />
                  </div>
                  <div className={styles.plate}>
                    <Typography.Text view="secondary-large" color="secondary">
                      Комиссия
                    </Typography.Text>
                    {renderFee()}
                  </div>
                </div>
              </div>
            </div>
            <TradingCertificateLock>
              <div className={styles.footer}>
                <div
                  className={styles.cancelButton}
                  onClick={() => onCancelOrder()}
                >
                  <TrashCanLineMIcon width={16} height={16} />
                  <Typography.Text view="secondary-large">
                    Снять заявку
                  </Typography.Text>
                </div>
                <div className={styles.buttonToolbar}>
                  <Button
                    className={styles.customButton}
                    view="ghost"
                    size="xxs"
                    onClick={() => onClose()}
                  >
                    Отмена
                  </Button>
                  <Button
                    className={styles.customButton}
                    size="xxs"
                    view="primary"
                    onClick={() => {
                      if (alwaysConfirmOrders) {
                        setIsOpenConfirm(true);
                      } else {
                        order &&
                          newOrder &&
                          replaceOrder(idFi, order, newOrder);
                      }
                    }}
                    disabled={isConfirmDisabled}
                  >
                    Применить
                  </Button>
                </div>
              </div>
            </TradingCertificateLock>
          </div>
        </BaseModal>
      ) : null}
      {order && newOrder && isOpen && (
        <ConfirmEditOrderModal
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isOpen={isOpenConfirm}
          prevOrder={order}
          newOrder={newOrder}
          onCancel={() => setIsOpenConfirm(false)}
          selectedSubAccountId={selectedSubAccountId}
          idFi={idFi}
          replaceOrder={replaceOrder}
        />
      )}
    </>
  );
};
