import { ExtendedFI } from './extendedFI';
import { FinInfo, FinInfoExt } from './quotes';

export enum WatchlistIds {
  DJIList = 'DJIList',
  iMOEXList = 'iMOEXList',
  MOEX10List = 'MOEX10List',
  NDXList = 'NDXList',
  RGBITRList = 'RGBITRList',
  RUCBITRList = 'RUCBITRList',
  MOEXBMIList = 'MOEXBMIList',
  SP500List = 'SP500List',
  currenciesList = 'currenciesList',
  featuresList = 'featuresList',
  mixedBPIF = 'mixedBPIF',
  replacementBondsList = 'replacementBondsList',
  reliableBonds = 'reliableBonds',
  oilAndGasStocks = 'oilAndGasStocks',
  taxFreeIncome = 'taxFreeIncome',
  currencyIncomeBonds = 'currencyIncomeBonds',
  clientChoice = 'clientChoice',
}

export type WatchListItem = {
  idFI: number;
  idObject: number;
  symbolObject: string;
  codeMarketBoard: string;
  close?: number;
  change?: number;
  lastPrice?: number;
  objectGroup?: number;
  calculated?: boolean;
  shortDescription?: string;
  multimarketsCount?: number;
  onMultimarketSelect?: (oldFiId: number, newFiId: number) => void;
  watchlistInstruments?: WatchListItem[];
  daysToMat?: number;
} & Partial<FinInfo> &
  Partial<FinInfoExt> &
  Partial<ExtendedFI>;

export enum WatchListType {
  AFLA = 'alfa',
  DEFAULT = 'default',
  CUSTOM = 'custom',
  GOINVEST = 'goinvest',
}

export const EDITABLE_WATCH_LISTS = [
  WatchListType.CUSTOM,
  WatchListType.DEFAULT,
];

export interface WatchList {
  type: WatchListType;
  name: string;
  id: string;
  instruments: WatchListItem[];
}

export const WatchListGroupNameMap = new Map<string, string>([
  // [WatchListType.AFLA, 'Списки Инвестиций'],
  [WatchListType.DEFAULT, 'Списки по умолчанию'],
  [WatchListType.CUSTOM, 'Мои списки'],
]);
