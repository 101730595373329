import { Loader } from '@alfalab/core-components/loader';

import { SecuritiesTranferXmlMultipleSubmitRequest } from '@terminal/core/lib/rest/lkSecurities';

import { XmlOperations } from '../../components/XmlOperations';
import { XmlUpload } from '../../components/XmlUpload';
import { XmlUploadStatus } from '../../components/XmlUploadStatus';

import {
  useSecuritiesTranferXml,
  useSecuritiesTranferXmlMultiple,
  useSecuritiesTranferXmlMultipleSubmit,
} from '../../hooks';

import { TransferCompleteParams } from '../../types';

import styles from './SecuritiesXml.module.css';

interface SecuritiesXmlProps {
  onSuccess?: (params: TransferCompleteParams) => void;
}

export const SecuritiesXml = ({ onSuccess }: SecuritiesXmlProps) => {
  const { mutateAsync: requestTransferXml, isLoading: transferXmlPending } =
    useSecuritiesTranferXml();

  const {
    upload: requestTransferXmlMultiple,
    uploadPending: transferXmlMultiplePending,
    status: multipleStatus,
    operationId: multipleOperationId,
    cancel: cancelMultipleUpload,
    reset: resetMultipleUpload,
    operations: multipleOperations,
    operationsPending: multipleOperationsPending,
  } = useSecuritiesTranferXmlMultiple();

  const {
    mutateAsync: submitMultipleTransfers,
    isLoading: multipleTransfersPending,
  } = useSecuritiesTranferXmlMultipleSubmit();

  const onFilesChange = (files: File[]) => {
    resetMultipleUpload();

    if (files.length === 1) {
      const data = new FormData();

      data.append('file', files[0]);
      requestTransferXml({
        params: data,
        signOptions: {
          successTitle: `Поручение принято к исполнению`,
          successCallback: () =>
            onSuccess &&
            onSuccess({
              type: 'success',
            }),
        },
      });
    } else {
      const data = new FormData();

      files.forEach((file: File) => data.append('file[]', file));
      requestTransferXmlMultiple(data);
    }
  };

  const handleMultipleSubmit = (
    data: SecuritiesTranferXmlMultipleSubmitRequest
  ) => {
    if (multipleOperationId) {
      submitMultipleTransfers({
        params: data,
        id: multipleOperationId,
        signOptions: {
          successTitle: `Поручения приняты к исполнению`,
          successCallback: () =>
            onSuccess &&
            onSuccess({
              type: 'success',
              multiple: true,
            }),
        },
      });
    }
  };

  return (
    <div className={styles.container}>
      {multipleOperationsPending && <Loader />}

      {!multipleOperations && !multipleStatus && !multipleOperationsPending && (
        <XmlUpload
          onFilesChange={onFilesChange}
          pending={transferXmlPending || transferXmlMultiplePending}
        />
      )}

      {multipleOperations && (
        <XmlOperations
          result={multipleOperations}
          submitPending={multipleTransfersPending}
          pendingUpload={transferXmlPending || transferXmlMultiplePending}
          onFilesChange={onFilesChange}
          onSubmit={handleMultipleSubmit}
        />
      )}

      {multipleStatus && (
        <XmlUploadStatus
          title={`Обработка элементов: ${multipleStatus.currentItem} из ${multipleStatus.count}`}
          percent={Math.ceil(
            (multipleStatus.currentItem / multipleStatus.count) * 100
          )}
          onCancel={cancelMultipleUpload}
        />
      )}
    </div>
  );
};
