import cn from 'classnames';
import { Typography } from '@alfalab/core-components/typography';

import styles from './StrategyPlate.module.css';

interface StrategyPlateProps {
  name: string;
  text?: React.ReactNode;
  icon?: React.ReactNode;
  addons?: React.ReactNode;
  size?: 'm' | 's';
  className?: string;
  onClick?: () => void;
}

export const StrategyPlate = ({
  name,
  text,
  icon,
  addons,
  size = 'm',
  className,
  onClick,
}: StrategyPlateProps) => {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.active]: Boolean(onClick),
        [styles.size_m]: size === 'm',
        [styles.size_s]: size === 's',
      })}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.body}>
        <div className={styles.content}>
          <Typography.Text
            className={styles.title}
            view="secondary-large"
            weight="bold"
            tag="div"
          >
            {name}
          </Typography.Text>
          {text && (
            <Typography.Text
              className={styles.text}
              view="secondary-small"
              color="secondary"
              tag="div"
            >
              {text}
            </Typography.Text>
          )}
        </div>
        {addons && <div className={styles.addons}>{addons}</div>}
      </div>
    </div>
  );
};
