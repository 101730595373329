import { Button } from '@alfalab/core-components/button';

import { TradingPlatformStateType } from '@terminal/core/lib/rest/lkAccountInfo';

import { RegistrationState } from './RegistrationState';
import { getStateLabel } from './shared';

import formstyles from '../../../styles/forms.module.css';
import styles from './Platforms.module.css';

interface PlatformSpbexProps {
  spbexStatus: TradingPlatformStateType;
  registrationPending: boolean;
  onRegistration: () => void;
}

export const PlatformSpbex = ({
  spbexStatus,
  registrationPending,
  onRegistration,
}: PlatformSpbexProps) => (
  <div className={styles.platforms}>
    <div className={styles.platformItem}>
      <div className={styles.platformLabel}>СПБ Биржа</div>
      <div className={styles.platformStatus}>
        <RegistrationState
          className={styles.registrationState}
          state={spbexStatus}
          label={getStateLabel(spbexStatus)}
        />
        {spbexStatus === 'NotRegistred' && (
          <div className={styles.registrationState}>
            <Button
              block
              size="xxs"
              className={formstyles.buttonXS}
              onClick={onRegistration}
              loading={registrationPending}
            >
              Зарегистрировать
            </Button>
          </div>
        )}
      </div>
    </div>
  </div>
);
