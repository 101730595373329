import { useCallback, useMemo, useRef } from 'react';
import { Select } from '@alfalab/core-components/select';
import { OptionShape } from '@alfalab/core-components/select/typings';
import { Typography } from '@alfalab/core-components/typography';

import { trackSelectSize } from '@terminal/core/lib/analytics';
import { shallow, useStore } from '@terminal/core/store';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

import { MobileSelect } from '../../../components/MobileSelect';

import styles from './OrderDimension.module.css';

const options: OptionShape[] = [
  {
    key: String(OrderDimensionEnum.LOT),
    value: OrderDimensionEnum.LOT,
    content: 'Лоты',
  },
  {
    key: String(OrderDimensionEnum.PIECE),
    value: OrderDimensionEnum.PIECE,
    content: 'Штуки',
  },
];

export const OrderDimension = ({
  mobileVersion = true,
}: {
  mobileVersion?: boolean;
}) => {
  const [orderDimension, setSetting] = useStore(
    (state) => [state.settings.defaultValues.orderDimension, state.setSetting],
    shallow
  );
  const orderDimensionContainerRef = useRef<HTMLDivElement>(null);

  const onClickChange = useCallback(
    (orderDimension: OrderDimensionEnum) => {
      trackSelectSize(OrderDimensionEnum[orderDimension].toLowerCase());
      setSetting('defaultValues', 'orderDimension', orderDimension);
    },
    [setSetting]
  );

  const selected = useMemo(
    () => options.find((dimention) => dimention.value === orderDimension),
    [orderDimension]
  );

  if (mobileVersion) {
    return (
      <div className={styles.orderDimension}>
        <Typography.Text view="primary-medium">
          Объем заявок и позиций
        </Typography.Text>
        <MobileSelect
          className={styles.dimensionSelector}
          options={options}
          onChange={({ selected }) => onClickChange(selected?.value)}
          selectedKey={selected?.key}
          title="Объем заявок и позиций"
        />
      </div>
    );
  }

  return (
    <div>
      <Typography.Text view="primary-small" color="primary" weight="bold">
        Размерность инструментов
      </Typography.Text>
      <div className={styles.orderDimensionSelectTextContainer}>
        <Typography.Text view="secondary-small" color="secondary">
          Инструменты в списках будут отображаться в выбранных значениях
        </Typography.Text>
      </div>
      <div ref={orderDimensionContainerRef} className={styles.selectContainer}>
        <Select
          size="s"
          options={options}
          block
          selected={selected}
          onChange={({ selected }) => {
            if (selected) {
              onClickChange(selected.value);
            }
          }}
        />
      </div>
    </div>
  );
};
