import { MoneyLimitResult } from '@terminal/core/lib/rest/lkMoney';

import { getLimit } from './getLimit';

export function isAmountFitLimit(
  amount: number,
  limitResult?: MoneyLimitResult | null,
  marginalOn: boolean = false
) {
  if (limitResult) {
    const limit = getLimit(limitResult, marginalOn);

    return amount > 0 && !isNaN(limit) && !isNaN(amount) && amount <= limit;
  }

  return false;
}
