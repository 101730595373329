import isUndefined from 'lodash/isUndefined';

import { RazdelGroupType } from '../client/entities';

import { SubGTAccountItem } from '../../types/subAccount';

/**
 * @returns Минимальная маржа – уровень риска портфеля, рассчитанный на основании стоимостей позиций и минимальных дисконтов по инструментам.
 * Если минимальная маржа превышает стоимость портфеля, брокер принудительно закрывает позиции (производит сокращение рыночного риска) (МинМаржа)
 * */
export function calculateMinimumMargin(
  idRazdelGroup: RazdelGroupType,
  subGTAccounts: SubGTAccountItem[],
  IdSubAccount?: number,
  isUnifiedMarket = false
) {
  if (isUndefined(IdSubAccount)) {
    return 0;
  }

  const subGTAccountItem = subGTAccounts.find(
    (gtAccount) =>
      gtAccount.idSubAccount === IdSubAccount &&
      gtAccount.idRazdelGroup === Number(idRazdelGroup)
  );

  const marginRequirement = subGTAccountItem?.marginRequirement ?? 0;
  const marginInitial = subGTAccountItem?.marginInitial ?? 0;

  if (isUnifiedMarket) {
    return marginInitial !== 0 ? marginRequirement : marginRequirement * 0.8;
  }

  switch (idRazdelGroup) {
    case RazdelGroupType.DerivativesMarket:
      return marginInitial !== 0 ? marginRequirement : marginRequirement * 0.8;
    case RazdelGroupType.CurrencyMarket:
    case RazdelGroupType.StocksAndBondsMarket:
    case RazdelGroupType.RDC:
      return marginRequirement;
    default:
      return 0;
  }
}
