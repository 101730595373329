import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownTriangleSIcon } from '@alfalab/icons-glyph/ArrowDownTriangleSIcon';
import { ArrowUpTriangleSIcon } from '@alfalab/icons-glyph/ArrowUpTriangleSIcon';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';

import { ObjectTypes } from '@terminal/core/lib/client/entities';
import {
  getAssetUnits,
  getDimensionedUnit,
  getMinority,
} from '@terminal/core/lib/format';
import { ECurrencyId } from '@terminal/core/types/currencyId';
import { PositionItem } from '@terminal/core/types/position';

import { useWidgetContext } from '../../shared';

import { useIsLotCount } from '../../shared/hooks/useIsLotCount';

import styles from './PositionBlock.module.css';

export const PositionBlock = ({ position }: { position: PositionItem }) => {
  const { useSettings } = useWidgetContext();
  const {
    defaultValues: { orderDimension },
  } = useSettings();
  const { torgPos, torgPosCost, object, currency, finInfoExt } = position;

  const isLong = torgPos > 0;

  const dimensionedUnit = getDimensionedUnit(
    torgPos,
    finInfoExt?.lot,
    orderDimension
  );

  // является ли текущий инструмент валютой, юзается чтоб показать валюту в кмопненте Amount где есть символ валюты
  // Те для баксов будет чет типа "15$ = 1000Р" а для акций "20 акций = 1000Р"
  const isCurrency = object?.idObjectType?.value === ObjectTypes.CC;
  // является ли текущий инструмент рублем
  const isRub = object?.idObject === ECurrencyId.RUR;
  const isLotCount = useIsLotCount();

  return (
    <Typography.Text view="secondary-large" className={styles.amountWrapper}>
      <BriefcaseMIcon width={18} height={18} className={styles.icon} />
      {/* Направление позиции */}
      {isLong ? (
        <ArrowUpTriangleSIcon
          width={12}
          height={12}
          className={styles.triangleUp}
        />
      ) : (
        <ArrowDownTriangleSIcon
          width={12}
          height={12}
          className={styles.triangleDown}
        />
      )}
      &nbsp;
      {/* Количество */}
      {!isRub && (
        <>
          {isLotCount ? (
            <Amount.Pure
              value={dimensionedUnit * getMinority(dimensionedUnit)}
              minority={getMinority(dimensionedUnit)}
              view="withZeroMinorPart"
            />
          ) : (
            <Amount.Pure
              value={torgPos * getMinority(torgPos)}
              minority={getMinority(torgPos)}
              view="withZeroMinorPart"
              currency={
                isCurrency ? (object.symbolObject as CurrencyCodes) : undefined
              }
            />
          )}
          &nbsp;
          {!isCurrency
            ? getAssetUnits(
                dimensionedUnit,
                orderDimension,
                object?.idObjectType.value
              )
            : null}
        </>
      )}
      &nbsp;
      {/* Стоимость  */}
      {torgPosCost !== null && (
        <Amount.Pure
          className={styles.amount}
          value={torgPosCost * getMinority(torgPosCost)}
          minority={getMinority(torgPosCost)}
          view="withZeroMinorPart"
          currency={currency}
        />
      )}
    </Typography.Text>
  );
};
