import cn from 'classnames';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { CurrencyCodes } from '@alfalab/core-components/amount';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { Switch } from '@alfalab/core-components/switch';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop/Component.desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import {
  orderTypeAbbrMap,
  orderTypeMap,
  orderTypeNameMap,
} from '@terminal/core/constants/orders';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { OrderType } from '@terminal/core/lib/client/entities';
import { roundPrice } from '@terminal/core/lib/format';
import { getPriceError } from '@terminal/core/lib/helpers/getPriceError';
import { OrderSide } from '@terminal/core/types/trading';
import {
  BracketPriceType,
  SlippageType,
  StopOrderType,
} from '@terminal/core/types/ui';

import { BracketPrice, getBracketPrice } from '../../../../BracketPrice';
import { SlippageInput } from '../../../../SlippageInput';

import { SLTPInstruction } from '../../model/types';

import styles from './SLTP.module.css';

interface SLTPForm extends SLTPInstruction {
  slPriceTouched: boolean;
  tpPriceTouched: boolean;
  toggleSlEnabled: () => void;
  setSlOrderType: Dispatch<SetStateAction<StopOrderType | OrderType.TRS>>;
  setSlPriceType?: (type: BracketPriceType) => void;
  setSlPrice: (price: number) => void;
  setSlSlippageType: Dispatch<SetStateAction<SlippageType>>;
  setSlSlippageValue: Dispatch<SetStateAction<number>>;
  setSlPriceTouched: (touched: boolean) => void;
  toggleTpEnabled: () => void;
  setTpPriceType?: (type: BracketPriceType) => void;
  setTpPrice: (price: number) => void;
  setTpPriceTouched: (touched: boolean) => void;
}

interface Props {
  isSmall?: boolean;
  noTralling?: boolean;
  side: OrderSide;
  mainPrice: number;
  priceStep?: number;
  symbol?: CurrencyCodes;
  form: SLTPForm;
}

export const SLTP = ({
  isSmall = false,
  noTralling = false,
  side,
  mainPrice,
  priceStep = 0.01,
  symbol,
  form,
}: Props) => {
  const [stopOrderMenuVisible, setStopOrderMenuVisible] = useState(false);

  const stopOrderRef = useRef<HTMLDivElement>(null);
  const stopOrderRefButton = useRef<HTMLDivElement>(null);

  useOnClickOutside([stopOrderRef, stopOrderRefButton], () =>
    setStopOrderMenuVisible(false)
  );

  const slPrice = getBracketPrice(
    mainPrice,
    form.slPriceType,
    form.slPrice,
    side === OrderSide.BUY ? -1 : 1,
    priceStep
  );

  const handleSetSlSlippageType = (type: SlippageType) => {
    form.setSlSlippageType(type);

    if (
      type === SlippageType.TICK &&
      form.slSlippageType === SlippageType.PERCENT
    ) {
      form.setSlSlippageValue(
        Math.round((form.slSlippageValue * slPrice) / 100 / priceStep)
      );
    } else if (
      type === SlippageType.PERCENT &&
      form.slSlippageType === SlippageType.TICK
    ) {
      form.setSlSlippageValue(
        roundPrice((form.slSlippageValue * priceStep * 100) / slPrice, 0.01)
      );
    }
  };

  return (
    <div>
      <div>
        <Switch
          reversed
          checked={form.isSlEnabled}
          label={
            <Typography.Text
              view="secondary-large"
              weight="medium"
              className={styles.label}
            >
              Стоп-лосс&nbsp;
              <Tooltip
                targetClassName={styles.tooltipTarget}
                contentClassName={styles.tooltipContent}
                content={
                  <>
                    <Typography.Text
                      weight="bold"
                      view="secondary-small"
                      className={styles.graphic}
                    >
                      Стоп-лосс
                    </Typography.Text>
                    <Typography.Text
                      view="secondary-small"
                      className={styles.graphic}
                    >
                      Привязка дополнительной Стоп-маркет или Стоп-лимит заявки,
                      позволяющей ограничить убыток по указанным параметрам
                    </Typography.Text>
                  </>
                }
                trigger="hover"
                position="top-start"
                offset={[20, 16]}
              >
                <InformationCircleLineSIcon
                  height={12}
                  width={12}
                  color="var(--color-dark-graphic-secondary)"
                />
              </Tooltip>
            </Typography.Text>
          }
          onChange={() => form.toggleSlEnabled()}
          className={styles.switchSmall}
        />

        <div className={styles.slGrid}>
          <div
            ref={stopOrderRefButton}
            className={cn(
              styles.dropdownRef,
              styles.fullWidth,
              !form.isSlEnabled && styles.disabled
            )}
          >
            <div
              className={styles.dropDownContent}
              onClick={() =>
                form.isSlEnabled && setStopOrderMenuVisible((prev) => !prev)
              }
            >
              <Typography.Text
                view="secondary-large"
                className={styles.orderName}
              >
                {orderTypeNameMap[form.slOrderType]}
              </Typography.Text>
              <ChevronDownCompactMIcon
                height={16}
                width={16}
                className={cn(
                  styles.chevron,
                  stopOrderMenuVisible && styles.chevronUp
                )}
              />
            </div>
            <Dropdown
              ref={stopOrderRef}
              useAnchorWidth
              anchorElement={stopOrderRefButton.current}
              open={stopOrderMenuVisible}
              position="bottom-start"
              offset={[0, 4]}
              popperClassName={styles.dropdownPopover}
            >
              <Dropdown.Item
                rightIcon={
                  (form.slOrderType === OrderType.STP ||
                    form.slOrderType === OrderType.TRS) && (
                    <CheckmarkMIcon height={16} width={16} />
                  )
                }
                content={
                  <div className={styles.dropDownItemContent}>
                    {orderTypeMap.STP}&nbsp;
                    <Typography.Text
                      view="secondary-large"
                      className={styles.graphic}
                    >
                      {orderTypeAbbrMap[OrderType.STP]}
                    </Typography.Text>
                    <Tooltip
                      targetClassName={styles.tooltipTarget}
                      contentClassName={styles.tooltipContent}
                      content={
                        <>
                          <Typography.Text
                            weight="bold"
                            view="secondary-small"
                            className={styles.graphic}
                          >
                            Стоп-маркет
                          </Typography.Text>
                          <Typography.Text
                            view="secondary-small"
                            className={styles.graphic}
                          >
                            Заявка, которая будет исполнена по любой доступной в
                            моменте цене, если на рынке пройдет сделка по
                            указанной стоп-цене
                          </Typography.Text>
                        </>
                      }
                      trigger="hover"
                      position="top-start"
                      offset={[20, 16]}
                    >
                      <InformationCircleLineSIcon
                        height={12}
                        width={12}
                        color="var(--color-dark-graphic-secondary)"
                      />
                    </Tooltip>
                  </div>
                }
                onClick={() => {
                  form.setSlOrderType(OrderType.STP);
                  setStopOrderMenuVisible(false);
                }}
                className={styles.slOrderTypeDropdownItem}
              />
              <Dropdown.Item
                rightIcon={
                  form.slOrderType === OrderType.STL && (
                    <CheckmarkMIcon height={16} width={16} />
                  )
                }
                content={
                  <div className={styles.dropDownItemContent}>
                    {orderTypeMap.STL}&nbsp;
                    <Typography.Text
                      view="secondary-large"
                      className={styles.graphic}
                    >
                      {orderTypeAbbrMap[OrderType.STL]}
                    </Typography.Text>
                    <Tooltip
                      targetClassName={styles.tooltipTarget}
                      contentClassName={styles.tooltipContent}
                      content={
                        <>
                          <Typography.Text
                            weight="bold"
                            view="secondary-small"
                            tag="div"
                            className={styles.graphic}
                          >
                            Стоп-лимит
                          </Typography.Text>
                          <Typography.Text
                            view="secondary-small"
                            className={styles.graphic}
                          >
                            Лимитная заявка, которая будет выставлена по
                            стоп-цене + проскок (для покупок) или стоп-цене -
                            проскок (для продаж), если на рынке пройдет сделка
                            по указанной стоп-цене
                          </Typography.Text>
                        </>
                      }
                      trigger="hover"
                      position="top-start"
                      offset={[20, 16]}
                    >
                      <InformationCircleLineSIcon
                        height={12}
                        width={12}
                        color="var(--color-dark-graphic-secondary)"
                      />
                    </Tooltip>
                  </div>
                }
                onClick={() => {
                  form.setSlOrderType(OrderType.STL);
                  setStopOrderMenuVisible(false);
                }}
                className={styles.slOrderTypeDropdownItem}
              />
            </Dropdown>
          </div>

          <BracketPrice
            disabled={!form.isSlEnabled}
            isSmall={isSmall}
            side={side}
            brsSide="sl"
            price={mainPrice}
            value={form.slPrice}
            type={form.slPriceType}
            priceStep={priceStep}
            symbol={symbol}
            onTypeChange={form.setSlPriceType}
            onChange={form.setSlPrice}
            onBlur={() => !form.slPriceTouched && form.setSlPriceTouched(true)}
            error={getPriceError(form.slPrice, form.slPriceTouched, priceStep)}
            containerClassName={cn(styles.row, styles.fullWidth)}
          />

          {form.slOrderType === OrderType.STL && (
            <SlippageInput
              withSummary
              isSmall={isSmall}
              disabled={!form.isSlEnabled}
              side={side === OrderSide.BUY ? OrderSide.SELL : OrderSide.BUY}
              price={getBracketPrice(
                mainPrice,
                form.slPriceType,
                form.slPrice,
                side === OrderSide.BUY ? -1 : 1,
                priceStep
              )}
              priceStep={priceStep}
              symbol={symbol}
              slippageType={form.slSlippageType}
              setSlippageType={handleSetSlSlippageType}
              slippageValue={form.slSlippageValue}
              setSlippageValue={form.setSlSlippageValue}
              containerClassName={styles.fullWidth}
            />
          )}

          {!noTralling &&
            (form.slOrderType === OrderType.STP ||
              form.slOrderType === OrderType.TRS) && (
              <div className={styles.fullWidth}>
                <Checkbox
                  block
                  checked={form.slOrderType === OrderType.TRS}
                  disabled={!form.isSlEnabled}
                  size="s"
                  label={
                    <Typography.Text
                      view="secondary-large"
                      weight="medium"
                      className={styles.graphic}
                    >
                      Трейлинг цены&nbsp;
                      <Tooltip
                        targetClassName={styles.tooltipTarget}
                        contentClassName={styles.tooltipContent}
                        content={
                          <>
                            <Typography.Text
                              weight="bold"
                              view="secondary-small"
                              className={styles.graphic}
                            >
                              Трейлинг цены
                            </Typography.Text>
                            <Typography.Text
                              view="secondary-small"
                              className={styles.graphic}
                            >
                              Автоматическое перемещение заявки за ценой при ее
                              движении в направлении сделки на указанном
                              расстоянии от цены. При движении цены в
                              направлении потери прибыли заявка остается
                              неизменной
                            </Typography.Text>
                          </>
                        }
                        trigger="hover"
                        position="top-start"
                        offset={[20, 16]}
                      >
                        <InformationCircleLineSIcon
                          height={12}
                          width={12}
                          color="var(--color-dark-graphic-secondary)"
                        />
                      </Tooltip>
                    </Typography.Text>
                  }
                  onChange={(_, { checked }) => {
                    form.setSlOrderType(
                      checked ? OrderType.TRS : OrderType.STP
                    );
                  }}
                />
              </div>
            )}
        </div>
      </div>

      <div className={styles.blockDivider} />

      <div>
        <Switch
          reversed
          checked={form.isTpEnabled}
          label={
            <Typography.Text
              view="secondary-large"
              weight="medium"
              className={styles.label}
            >
              Тейк-профит&nbsp;
              <Tooltip
                targetClassName={styles.tooltipTarget}
                contentClassName={styles.tooltipContent}
                content={
                  <>
                    <Typography.Text
                      weight="bold"
                      view="secondary-small"
                      className={styles.graphic}
                    >
                      Тейк-профит
                    </Typography.Text>
                    <Typography.Text
                      view="secondary-small"
                      className={styles.graphic}
                    >
                      Привязка дополнительной лимитной заявки, позволяющей
                      зафиксировать прибыль по указанным параметрам
                    </Typography.Text>
                  </>
                }
                trigger="hover"
                position="top-start"
                offset={[20, 16]}
              >
                <InformationCircleLineSIcon
                  height={12}
                  width={12}
                  color="var(--color-dark-graphic-secondary)"
                />
              </Tooltip>
            </Typography.Text>
          }
          onChange={() => form.toggleTpEnabled()}
          className={styles.switchSmall}
        />

        <div className={styles.slGrid}>
          <BracketPrice
            disabled={!form.isTpEnabled}
            isSmall={isSmall}
            side={side}
            brsSide="tp"
            price={mainPrice}
            value={form.tpPrice}
            type={form.tpPriceType}
            priceStep={priceStep}
            symbol={symbol}
            onTypeChange={form.setTpPriceType}
            onChange={form.setTpPrice}
            onBlur={() => !form.tpPriceTouched && form.setTpPriceTouched(true)}
            error={getPriceError(form.tpPrice, form.tpPriceTouched, priceStep)}
            containerClassName={cn(styles.row, styles.fullWidth)}
          />
        </div>
      </div>
    </div>
  );
};
