import React, { useState } from 'react';
import { useToggle } from 'react-use';
import { BaseModal, BaseModalProps } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop/Component.desktop';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { CurrencyCodes } from '@alfalab/data';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import { OrderType } from '@terminal/core/lib/client/entities';
import { OrderSide } from '@terminal/core/types/trading';
import {
  BracketPriceType,
  SlippageType,
  StopOrderType,
} from '@terminal/core/types/ui';

import { SLTP } from '../SLTP';

import { SLTPInstruction } from '../../model/types';

interface Props extends BaseModalProps {
  side: OrderSide;
  mainPrice: number;
  priceStep?: number;
  symbol?: CurrencyCodes;
  stopOrderType?: StopOrderType;
  sltp?: SLTPInstruction;
  onSubmit: (data: SLTPInstruction | null) => void;
  onClose: () => void;
}

export const SLTPModal = ({
  side,
  mainPrice,
  stopOrderType,
  sltp,
  onSubmit,
  onClose,
  priceStep,
  symbol,
  ...baseModalProps
}: Props) => {
  const [isSlEnabled, toggleSlEnabled] = useToggle(sltp?.isSlEnabled ?? true);
  const [slOrderType, setSlOrderType] = useState<StopOrderType | OrderType.TRS>(
    sltp?.slOrderType || stopOrderType || OrderType.STP
  );
  const [slPriceType, setSlPriceType] = useState<BracketPriceType>(
    sltp?.slPriceType || BracketPriceType.PERCENT
  );
  const [slPrice, setSlPrice] = useState<number>(sltp?.slPrice ?? 2);
  const [slSlippageType, setSlSlippageType] = useState<SlippageType>(
    sltp?.slSlippageType ?? SlippageType.TICK
  );
  const [slSlippageValue, setSlSlippageValue] = useState<number>(
    sltp?.slSlippageValue ?? 1
  );

  const [isTpEnabled, toggleTpEnabled] = useToggle(sltp?.isTpEnabled ?? true);
  const [tpPriceType, setTpPriceType] = useState<BracketPriceType>(
    sltp?.tpPriceType || BracketPriceType.PERCENT
  );
  const [tpPrice, setTpPrice] = useState(sltp?.tpPrice ?? 2);

  const [slPriceTouched, setSlPriceTouched] = useState<boolean>(false);
  const [tpPriceTouched, setTpPriceTouched] = useState<boolean>(false);

  const form = {
    isSlEnabled,
    toggleSlEnabled,
    slOrderType,
    setSlOrderType,
    slPriceType,
    setSlPriceType,
    slPrice,
    setSlPrice,
    slSlippageType,
    setSlSlippageType,
    slSlippageValue,
    setSlSlippageValue,
    slPriceTouched,
    setSlPriceTouched,

    isTpEnabled,
    toggleTpEnabled,
    tpPriceType,
    setTpPriceType,
    tpPrice,
    setTpPrice,
    tpPriceTouched,
    setTpPriceTouched,
  };

  const handleSave = () => {
    const data = {
      isSlEnabled,
      slOrderType,
      slPriceType,
      slPrice,
      slSlippageType,
      slSlippageValue,
      slPriceTouched,
      isTpEnabled,
      tpPriceType,
      tpPrice,
      tpPriceTouched,
    };

    if (
      (data.isSlEnabled && data.slPrice) ||
      (data.isTpEnabled && data.tpPrice)
    ) {
      onSubmit(data);
    } else {
      onSubmit(null);
    }
  };

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <FunctionalModal
        backdrop={false}
        width={270}
        title="Стоп-лосс и Тейк-профит"
        paddingSize="xs"
        onClose={onClose}
        drawerId="sltp"
        footer={
          <Space direction="horizontal" size="m" align="center">
            <Button block view="ghost" size="xs" onClick={onClose}>
              <Typography.Text view="secondary-large">Отмена</Typography.Text>
            </Button>
            <Button block view="secondary" size="xs" onClick={handleSave}>
              Применить
            </Button>
          </Space>
        }
      >
        <SLTP
          side={side}
          mainPrice={mainPrice}
          priceStep={priceStep}
          symbol={symbol}
          form={form}
        />
      </FunctionalModal>
    </BaseModal>
  );
};
