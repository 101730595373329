import { error } from 'loglevel';
import { z, ZodObject, ZodRawShape } from 'zod';

type EmiterHandler<V = any> = (value: V) => void;

export class FeatureFlags<
  S extends ZodObject<ZodRawShape>,
  T extends Partial<z.infer<S>>
> {
  private readonly scheme: ZodObject<ZodRawShape>;
  private readonly featureFlags: T;
  private readonly emitHandlers: Record<keyof T, Set<EmiterHandler>>;

  constructor(
    scheme: S,
    defaultFeatureFlags: T,
    setterName: string = '__setFeatureFlags'
  ) {
    this.scheme = scheme;
    this.featureFlags = { ...defaultFeatureFlags };

    this.emitHandlers = Object.keys(this.featureFlags).reduce((acc, key) => {
      acc[key] = new Set([]);

      return acc;
    }, {} as Record<keyof T, Set<EmiterHandler>>);

    window[setterName] = (obj: T) => {
      Object.entries(obj).forEach(([name, value]) => {
        this.emit(name, value);
      });
    };
  }

  public emit<N extends keyof T, V extends T[N]>(name: N, value: V) {
    const obj = { [name]: value };

    const parseResult = this.scheme.partial().safeParse(obj);

    if (parseResult.success) {
      this.featureFlags[name] = value;
      (this.emitHandlers[name] ?? new Set([])).forEach((handler) =>
        handler(value)
      );
    } else {
      error(
        `invalid validation feature flag ${String(name)} with value ${value}`
      );
      error('feature flag error issues', parseResult.error?.issues);
    }
  }

  public on<N extends keyof T, V>(name: N, callback: (value: V) => void) {
    if (!this.emitHandlers[name]) {
      this.emitHandlers[name] = new Set([]);
    }

    this.emitHandlers[name].add(callback);
  }

  public off<N extends keyof T, V>(name: N, callback: (value: V) => void) {
    const handlers = this.emitHandlers[name];

    handlers.delete(callback);
  }

  public get<N extends keyof T>(name: N) {
    return this.featureFlags[name];
  }
}
