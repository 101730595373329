import qs from 'query-string';

import { PASSPORT_CLIENT_ID, PASSPORT_URI } from '@terminal/core/env';

import { getAppRedirectUri } from '../../../shared/lib/getAppRedirectUri';

export const getPassportUrl = (redirect: string): string => {
  return qs.stringifyUrl(
    {
      url: PASSPORT_URI,
      query: {
        client_id: PASSPORT_CLIENT_ID,
        scope: 'openid',
        redirect_uri: getAppRedirectUri(redirect),
      },
    },
    {
      encode: false,
    }
  );
};
