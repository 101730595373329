import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { FreeOrderSettingsAI } from '../model/types/FreeOrderSettingsAI';
import { FreeOrderSettingsParams } from './types/FreeOrderSettingsParams';

export async function getFreeOrdersSettingsAI(
  params: FreeOrderSettingsParams
): Promise<FreeOrderSettingsAI> {
  const res = await fetchLkResult<FreeOrderSettingsAI | LKResult>(
    qs.stringifyUrl({
      url: '/api/free-order/settings',
      query: { ...params },
    })
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}
