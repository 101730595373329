import { ReactElement, ReactNode } from 'react';
import { Button } from '@alfalab/core-components/button';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';

import styles from './WarningMessage.module.css';

interface WarningMessageProps {
  icon: ReactElement;
  title: ReactNode;
  subtitle: ReactNode;
  retry?: () => void;
  openSupport?: () => void;
}

export const WarningMessage = ({
  icon,
  title,
  subtitle,
  retry,
  openSupport,
}: WarningMessageProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <SuperEllipse size={64} className={styles.customSuperEllipse}>
          {icon}
        </SuperEllipse>
        <Typography.Title tag="h1" view="xsmall" className={styles.title}>
          {title}
        </Typography.Title>
        <Typography.Text view="secondary-large" className={styles.subtitle}>
          {subtitle}
        </Typography.Text>
      </div>
      {retry && (
        <Button onClick={retry} className={styles.button} block>
          Войти по-другому
        </Button>
      )}
      {openSupport && (
        <Button onClick={openSupport} className={styles.button} block>
          Войти по-другому
        </Button>
      )}
    </div>
  );
};
