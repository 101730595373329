import cn from 'classnames';
import { memo, useMemo } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Spinner } from '@alfalab/core-components/spinner';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { Confirmation } from '@terminal/common/components/Confirmation';
import { ConfirmationScreens } from '@terminal/common/components/Confirmation/Confirmation';

import { PIN_CODE_CREATE_SCREEN, PIN_CODE_ENTER_SCREEN } from '../../lib/const';
import { ConfirmationCreatePinCode } from '../ConfirmationCreatePinCode';
import { ConfirmationEnterPinCode } from '../ConfirmationEnterPinCode';

import { useLoginLK2faNew } from '../../hooks/useLoginLK2faNew';

import styles from './LoginPinCodeForm.module.css';

interface Props {
  theme?: string;
}

export const LoginPinCodeForm = memo(({ theme }: Props) => {
  const {
    authPending,
    confirmationScreen,
    confirmationState,
    setConfirmationState,
    setConfirmationScreen,
    error,
    clearError,
    createPinCode,
    validate2faCode,
    onForgot,
    onSkipPinCode,
  } = useLoginLK2faNew();

  const pinCodeScreens: ConfirmationScreens = useMemo(
    () => ({
      [PIN_CODE_CREATE_SCREEN]: () => (
        <ConfirmationCreatePinCode
          createPinCode={createPinCode}
          onSkipPinCode={onSkipPinCode}
          setConfirmationState={setConfirmationState}
        />
      ),
      [PIN_CODE_ENTER_SCREEN]: () => (
        <ConfirmationEnterPinCode
          onValidate={validate2faCode}
          onForgot={onForgot}
          error={error}
          clearError={clearError}
        />
      ),
    }),
    [
      createPinCode,
      error,
      clearError,
      onForgot,
      onSkipPinCode,
      setConfirmationState,
      validate2faCode,
    ]
  );

  return (
    <div className={styles.container}>
      <div className={cn(styles.card, theme === 'dark' && styles.dark)}>
        {authPending ? (
          <Spinner visible size="m" />
        ) : (
          <>
            <div className={styles.header}>
              <IconButton
                icon={() => (
                  <CrossMIcon color="var(--color-light-graphic-secondary)" />
                )}
                // @ts-ignore
                size="m"
                onClick={
                  confirmationScreen === PIN_CODE_CREATE_SCREEN
                    ? onSkipPinCode
                    : onForgot
                }
              />
            </div>
            <Confirmation
              screens={pinCodeScreens}
              screen={confirmationScreen}
              state={confirmationState}
              onChangeState={setConfirmationState}
              onChangeScreen={setConfirmationScreen}
              alignContent="center"
              texts={{
                codeError: error,
              }}
              countdownDuration={0}
              onInputFinished={validate2faCode}
              onSmsRetryClick={() => {}}
            />
          </>
        )}
      </div>
    </div>
  );
});
