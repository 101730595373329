// предыдущий список ISIN взят из https://iss.moex.com/iss/engines/stock/markets/shares/securities.json?index=MOEX10
// текущий - https://rusbonds.ru/markets/34317/structure от 24.03.2023
export const MOEX10List = [
  /* CHMF */ 'RU0009046510',
  /* GAZP */ 'RU0007661625',
  /* LKOH */ 'RU0009024277',
  /* MGNT */ 'RU000A0JKQU8',
  /* MTLR */ 'RU000A0DKXV5',
  /* NVTK */ 'RU000A0DKVS5',
  /* ROSN */ 'RU000A0J2Q06',
  /* SBER */ 'RU0009029540',
  /* VKCO */ 'RU000A106YF0',
  /* VTBR */ 'RU000A0JP5V6',
];
