import { OrderType } from '../../lib/client/entities';

import { NewOrderType, OrderItem } from '../../types/order';

// Возвращает цену для разные типов ордеров
export function getOrderDisplayPrice(
  order: NewOrderType | OrderItem
): number | undefined {
  switch (order.idOrderType) {
    case OrderType.LMT:
    case OrderType.MKT:
    case OrderType.TRL:
    case OrderType.LIT:
    case OrderType.BRS:
    case OrderType.BSL:
    case OrderType.TBRS:
      return order.limitPrice;
    case OrderType.STL:
      return order.stopPrice || order.limitLevelAlternative;
    case OrderType.STP:
      return order.stopPrice;
    case OrderType.TRS:
    case OrderType.TSL:
      if (order.price) {
        return Number(order.price.toFixed(10));
      }
  }
}
