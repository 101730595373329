import { ObjectGroup } from '../client/entities';

import { MarketBoardItem, ObjectTypeItem } from '../../types/core';
import { ECurrencyId } from '../../types/currencyId';
import { SubAccountPositionItem } from '../../types/subAccountPosition';

/**
 * @returns учетную цену
 * @param positionItem позиция в портфеле
 * @param objectTypeItem
 * @param marketBoardItem
 * */
export function getUchPrice(
  position?: SubAccountPositionItem,
  objectType?: ObjectTypeItem,
  marketBoard?: MarketBoardItem
): number {
  const {
    uchPrice = 0,
    uchPriceEUR = 0,
    uchPriceGBP = 0,
    uchPriceRur = 0,
    uchPriceUSD = 0,
  } = position ?? {};
  const { idObjectGroup } = objectType ?? {};
  const { idObjectCurrency } = marketBoard ?? {};

  if (
    idObjectGroup &&
    [
      ObjectGroup.Stocks,
      ObjectGroup.EquityParticipations,
      ObjectGroup.Receipts,
    ].includes(idObjectGroup)
  ) {
    if (idObjectCurrency === ECurrencyId.RUR) {
      return uchPriceRur;
    }

    if (idObjectCurrency === ECurrencyId.USD) {
      return uchPriceUSD;
    }

    if (idObjectCurrency === ECurrencyId.EUR) {
      return uchPriceEUR;
    }

    if (idObjectCurrency === ECurrencyId.GBP) {
      return uchPriceGBP;
    }
  }

  return uchPrice;
}
