import isNil from 'lodash/isNil';

import { ScoreRecordValue } from './types';

export const isVisibleScoreProgressBar = (
  score?: ScoreRecordValue
): score is ScoreRecordValue => {
  if (score) {
    const { scoreValue } = score;

    return !isNil(scoreValue) && scoreValue >= 0 && scoreValue <= 10;
  }

  return false;
};
