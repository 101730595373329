import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getInstructionTheme } from '../api';

export function useInstructionTheme(themeId: number) {
  const addNotification = useNotification();

  return useQuery(
    ['lk-instruction-theme', { themeId }],
    () => getInstructionTheme({ themeId }),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса темы',
          text: error?.message || '',
        });
      },
      enabled: Boolean(themeId),
    }
  );
}
