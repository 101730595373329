import cn from 'classnames';
import React, {
  ComponentPropsWithoutRef,
  ElementType,
  HTMLAttributes,
  ReactElement,
  RefObject,
} from 'react';

import styles from './SettingsItem.module.css';

type Props<C extends ElementType> = Pick<
  HTMLAttributes<HTMLElement>,
  'className'
> & {
  /**
   * Компонент, который будет отображен. По умолчанию `div`.
   * */
  Component?: C;
  componentRef?: RefObject<HTMLDivElement>;
};

export type SettingsItemProps<C extends ElementType> = Props<C> &
  Omit<ComponentPropsWithoutRef<C>, keyof Props<C>>;

export function SettingsItem<C extends ElementType>(
  props: SettingsItemProps<C>
): ReactElement | null {
  const { Component = 'div', className, componentRef, ...restProps } = props;
  const rootClassName = cn(styles.root, className);

  return (
    <Component ref={componentRef} className={rootClassName} {...restProps} />
  );
}
