import cn from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { CurrencyCodes } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { ReceiptLineMIcon } from '@alfalab/icons-glyph/ReceiptLineMIcon';

import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { OrderItem } from '@terminal/core/types/order';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { useWidgetContext } from '../../../../shared';
import { OrdersTable } from '../OrdersTable';

import styles from './OrderInfo.module.css';

interface OrderInfoProps {
  orders: OrderItem[];
  position: 'top' | 'bottom';
  currencyCode?: CurrencyCodes;
  isBond: boolean;
  finInfoExt?: FinInfoExt;
  //Признак для отображения в форме торговле, изменяет стили
  hybrid?: boolean;
  onCancelOrder?: (order: OrderItem) => void;
  onEditOrder?: (order: OrderItem) => void;
  onClick?: () => void;
  dropdownContent?: (open: boolean) => ReactNode;
  wrapperClassName?: string;
  fromInstructions?: boolean;
  onRowClick?: (order: OrderItem) => void;
  isOpen?: boolean;
  onClose?: () => void;
}

export const OrderInfo = ({
  orders,
  position,
  currencyCode,
  isBond,
  finInfoExt,
  hybrid,
  onCancelOrder,
  onEditOrder,
  onClick,
  dropdownContent,
  wrapperClassName,
  fromInstructions,
  onRowClick,
  onClose,
  ...props
}: OrderInfoProps) => {
  const [horizontalAlign, setHorizontalAlign] = useState<
    '-start' | '-end' | ''
  >('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { DropdownComponent: Dropdown } = useWidgetContext();

  useOnClickOutside(dropdownRef, (e) => {
    const anchor = wrapperRef.current;

    //Закрываем только если это НЕ клик в кнопку управляющую дропдауном
    if (anchor && !anchor?.contains(e.target as Node)) {
      setIsOpen(false);
    }
  });
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);

  useEffect(() => {
    if (!isOpen) {
      onClose?.();
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    if (props.isOpen) {
      setIsOpen(props.isOpen ?? false);
    }
  }, [props.isOpen]);

  const handleDropdownClick = () => {
    setIsOpen((prev) => !prev);

    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const rect = wrapperRef.current?.getBoundingClientRect();

    if (rect) {
      const { left, right } = rect;

      if (window.innerWidth - right > 250) {
        setHorizontalAlign('-start');
      } else if (left > 250) {
        setHorizontalAlign('-end');
      } else {
        setHorizontalAlign('');
      }
    }
  }, [isOpen]);

  const toggle = (
    <div className={styles.dropDownContent} onClick={handleDropdownClick}>
      {dropdownContent ? (
        dropdownContent(isOpen)
      ) : (
        <>
          <ReceiptLineMIcon
            width={18}
            height={18}
            color="var(--color-dark-graphic-secondary)"
          />
          <Typography.Text view="secondary-large" className={styles.orderName}>
            {orders.length} активные и отложенные заявки
          </Typography.Text>
          <ChevronDownMIcon
            height={18}
            width={18}
            color="currentColor"
            className={cn(styles.chevron, isOpen && styles.chevronUp)}
          />
        </>
      )}
    </div>
  );

  return (
    <div
      ref={wrapperRef}
      className={cn(
        styles.dropdownRef,
        hybrid && styles.activeOrdersDropdown,
        !hybrid && styles.orderInfoWrapper,
        wrapperClassName
      )}
    >
      <Dropdown
        anchorElement={wrapperRef.current}
        ref={dropdownRef}
        open={isOpen}
        offset={[0, 4]}
        toggle={toggle}
        onClose={handleDropdownClick}
        position={
          position === 'bottom'
            ? `top${horizontalAlign}`
            : `bottom${horizontalAlign}`
        }
        popperClassName={styles.popoverFull}
        preventOverflow={false}
        width={740}
      >
        <OrdersTable
          orders={orders}
          currencyCode={currencyCode}
          priceStep={finInfoExt?.priceStep}
          lot={finInfoExt?.lot}
          isBond={isBond}
          onCancelOrder={onCancelOrder}
          onEditOrder={onEditOrder}
          fromInstructions={fromInstructions}
          onRowClick={(original) => {
            if (onRowClick) {
              onRowClick(original);
              setIsOpen(false);
            }
          }}
        />
      </Dropdown>
    </div>
  );
};
