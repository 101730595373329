import { memo, useState } from 'react';

import {
  ColumnSettingModal,
  DoubleColumnSettingModal,
} from '@terminal/common/ui/Table';
import {
  BaseWidgetConfig,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';

import { AccountFilters } from '../../../../features/AccountFilters';
import { WidgetHeader } from '../../../../features/WidgetHeader';
import {
  TECH_MESSAGE_CODE,
  WidgetServiceNotification,
} from '../../../../features/WidgetServiceNotification';

import { useSettings } from '../../../../shared/hooks/useSettings';

interface HeaderProps {
  selectedSubAccounts: string[];
  columnsSetting: TableColumnSetting[];
  defaultColumnSetting: TableColumnSetting[];
  updateNode: (
    nodeId: string,
    config: BaseWidgetConfig,
    newName?: string | undefined
  ) => void;
  nodeId?: string;
  isDoubleRowMode: boolean;
  switchDoubleRowMode: () => void;
}

const BalanceHeader = ({
  selectedSubAccounts,
  columnsSetting,
  defaultColumnSetting,
  updateNode,
  nodeId,
  isDoubleRowMode,
  switchDoubleRowMode,
}: HeaderProps) => {
  const [isOpenColumnSettings, setIsOpenColumnSettings] = useState(false);

  const widgetSettings = useSettings({
    isDoubleRowMode,
    switchDoubleRowMode,
    setIsOpenColumnSettings,
  });

  return (
    <>
      <WidgetHeader
        inputs={
          <AccountFilters
            selectedSubAccounts={selectedSubAccounts}
            updateNode={updateNode}
            nodeId={nodeId}
          />
        }
        widgetSettings={widgetSettings}
      />
      {!isDoubleRowMode && (
        <ColumnSettingModal
          isOpen={isOpenColumnSettings}
          setIsOpen={setIsOpenColumnSettings}
          columnsSetting={columnsSetting}
          updateNode={updateNode}
          nodeId={nodeId}
          widgetType={Widget.BALANCE}
        />
      )}
      {isDoubleRowMode && (
        <DoubleColumnSettingModal
          isOpen={isOpenColumnSettings}
          setIsOpen={setIsOpenColumnSettings}
          defaultColumnSetting={defaultColumnSetting}
          columnsSetting={columnsSetting}
          updateNode={updateNode}
          nodeId={nodeId}
          widgetType={Widget.BALANCE}
          selectedDisplayInstrumentType="symbol"
        />
      )}
      <WidgetServiceNotification
        code={TECH_MESSAGE_CODE.POSITIONS_BALANCES}
        variant="line"
        canBeShort
      />
    </>
  );
};

export const Header = memo(BalanceHeader);
