//Получаем айди фин инструмента (по полям IdObject и IdMarketBoard. Эта пара однозначно определяет IdFi)
import { InstrumentItemsTable } from '../../types/core';

const _instrumentIdCache = {};

export const getInstrumentId = (
  idObject: number,
  idMarketBoard: number,
  finInstruments: InstrumentItemsTable
) => {
  const key = `${idMarketBoard}-${idObject}`;

  if (_instrumentIdCache[key]) {
    return _instrumentIdCache[key];
  }

  const instrumentsByMarketboard = finInstruments.get(
    'idMarketBoard',
    idMarketBoard
  );
  const instumentsByObjectId = finInstruments.get('idObject', idObject);
  const idFI = instrumentsByMarketboard?.find((instumentByMarketboard) =>
    instumentsByObjectId?.find(
      (instrumentByObject) =>
        instrumentByObject.idFI === instumentByMarketboard.idFI
    )
  )?.idFI;

  if (idFI) {
    _instrumentIdCache[key] = idFI;

    return idFI;
  }

  return 0;
};
