import { useCallback, useMemo, useRef, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { IS_GI } from '@terminal/core/env';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { declension } from '@terminal/core/lib/declension';
import {
  FortsPortfolioTrading,
  QualStatus,
} from '@terminal/core/lib/rest/lkAccountInfo';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { Test } from '@terminal/core/lib/rest/lkTesting';
import { shallow, useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';

import { InfoModal } from '../../../../shared/ui/InfoModal';
import { TestRequired } from '../../../../shared/ui/TestRequired';
import { RegistrationState } from './RegistrationState';

import { useSetQualInvestor } from '../../../qualinvestor/hooks';

import { MainMenuType } from '../../../main/types';

import styles from './Platforms.module.css';

interface PlatformFortsProps {
  fortsDisabled?: boolean;
  fortsState: FortsPortfolioTrading[];
  treaty: Treaty;
  testPassed: boolean;
  qualStatus: QualStatus;
  registrationPending: boolean;
  onRegistrateAccount: (account: string) => void;
  testItems: Test[];
}

export const PlatformForts = ({
  fortsDisabled,
  fortsState,
  treaty,
  testPassed,
  qualStatus,
  registrationPending,
  onRegistrateAccount,
  testItems,
}: PlatformFortsProps) => {
  const [openFortsReg, setOpenFortsReg] = useState(false);
  const [testBlock, setTestBlock] = useState(false);

  const fortsRef = useRef<HTMLDivElement>(null);
  const fortsPopupRef = useRef<HTMLDivElement>(null);

  const [setHeaderMenuOpen, setLkSubMenuId] = useStore(
    (state) => [state.setHeaderMenuOpen, state.setLkSubMenuId],
    shallow
  );

  const { mutateAsync: sendRequest } = useSetQualInvestor();
  const sendQualRequest = useCallback(() => {
    void sendRequest({ treaty: treaty.treaty });
  }, [sendRequest, treaty.treaty]);

  useOnClickOutside(fortsRef, () => setOpenFortsReg(false));

  const [fortsRegistred, fortsInprogress, fortsNotRegistred] = useMemo(
    () => [
      fortsState.filter((acc) => acc.state === 'Registred'),
      fortsState.filter((acc) => acc.state === 'InProgress'),
      fortsState.filter((acc) => acc.state === 'NotRegistred'),
    ],
    [fortsState]
  );

  const isTest2Passed = useMemo(
    () =>
      Boolean(testItems.find((test) => test.testId === 2 && test.completed)),
    [testItems]
  );
  const isTest3Passed = useMemo(
    () =>
      Boolean(testItems.find((test) => test.testId === 3 && test.completed)),
    [testItems]
  );

  const testRequiredText = useMemo(() => {
    if (testPassed || qualStatus === QualStatus.QUAL_INVESTOR) {
      return;
    } else {
      if (!isTest2Passed && !isTest3Passed) {
        return 'Необходимо пройти несколько тестов: «Необеспеченные (маржинальные) сделки» и «Производные финансовые инструменты»';
      } else if (isTest2Passed) {
        return 'Необходимо пройти ещё один тест: «Производные финансовые инструменты»';
      } else {
        return 'Необходимо пройти ещё один тест: «Необеспеченные (маржинальные) сделки»';
      }
    }
  }, [isTest2Passed, isTest3Passed, qualStatus, testPassed]);

  const onFortsRegClick = () => {
    if (testPassed || qualStatus === QualStatus.QUAL_INVESTOR) {
      setOpenFortsReg(true);
    } else {
      setTestBlock(true);
    }
  };

  const qualButtonProps = useMemo(() => {
    return qualStatus === QualStatus.CANDIDATE
      ? {
          text: 'Стать квалом',

          onClick: () => {
            setTestBlock(false);

            sendQualRequest();
          },
        }
      : undefined;
  }, [qualStatus, sendQualRequest]);

  const onTestRedirect = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.MAIN_LK);

    setLkSubMenuId({
      id: MainMenuType.QualTesting,
      options: isTest2Passed ? 3 : 2,
    });
  }, [isTest2Passed, setHeaderMenuOpen, setLkSubMenuId]);

  return (
    <>
      <div className={styles.platformItem}>
        <div className={styles.platformLabel}>Срочный рынок FORTS</div>
        <div className={styles.platformStatus}>
          {fortsRegistred.length > 0 && (
            <RegistrationState
              className={styles.registrationState}
              state="Registred"
              label={
                IS_GI
                  ? 'Счёт зарегистрирован'
                  : `${declension(
                      [
                        'Зарегистрирован',
                        'Зарегистрировано',
                        'Зарегистрировано',
                      ],
                      fortsRegistred.length
                    )} ${fortsRegistred.length} ${declension(
                      ['субсчёт', 'субсчёта', 'субсчетов'],
                      fortsRegistred.length
                    )}`
              }
            />
          )}
          {fortsInprogress.length > 0 && (
            <RegistrationState
              className={styles.registrationState}
              state="InProgress"
              label={
                IS_GI
                  ? 'Регистрация в процессе'
                  : `Регистрация ${fortsInprogress.length} ${declension(
                      ['субсчета', 'субсчетов', 'субсчетов'],
                      fortsInprogress.length
                    )}`
              }
            />
          )}
          {fortsNotRegistred.length > 0 && (
            <div className={styles.registrationState} ref={fortsRef}>
              <ButtonDesktop
                block
                size="xxs"
                onClick={onFortsRegClick}
                loading={registrationPending}
                disabled={fortsDisabled}
              >
                Зарегистрировать
              </ButtonDesktop>
              <Dropdown
                ref={fortsPopupRef}
                anchorElement={fortsRef.current}
                open={openFortsReg}
                position="top-start"
                offset={[0, 4]}
                useAnchorWidth={true}
                view="select"
              >
                {fortsNotRegistred.map((subacc) => (
                  <Dropdown.Item
                    key={`forts-subaccount-${subacc.subAccount}`}
                    content={subacc.subAccount}
                    onClick={() => onRegistrateAccount(subacc.subAccount)}
                  />
                ))}
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      <InfoModal
        open={testBlock}
        rounded={true}
        onClose={() => setTestBlock(false)}
      >
        <TestRequired
          title={
            'Регистрация на рынке FORTS доступна квалифицированным инвесторам, либо\n' +
            'клиентам, которые прошли тестирование'
          }
          text={testRequiredText}
          onTestRedirect={onTestRedirect}
          additionalButtonProps={qualButtonProps}
        />
      </InfoModal>
    </>
  );
};
