import { Link } from '@alfalab/core-components/link';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { useAppContext } from '@terminal/core/hooks/useAppContext';

import { useThemeContext } from '../../../shared/lib/themeContext';

import { StepScreenProps } from '../types';

import styles from './Steps.module.css';

export const SixthStep = ({
  openMoneyTransferModal,
  openSecuritiesTransferModal,
  style,
}: StepScreenProps) => {
  const { theme } = useThemeContext();
  const { onboarding } = useAppContext();
  const { images, showSecuritiesTransfersLink } = onboarding;
  const { lastStep } = images[theme];

  return (
    <div style={style} className={styles.slideContainer}>
      <div className={styles.stepContainer}>
        <Space fullWidth>
          <Typography.Text view="primary-large" weight="bold">
            Как пополнить счет и перевести ценные бумаги
          </Typography.Text>
          <Typography.Text view="secondary-large">
            Вы можете пополнить свой брокерский счет и перевести на него ценные
            бумаги через главное меню терминала.
          </Typography.Text>
          <Space size="s">
            <Link onClick={openMoneyTransferModal}>
              Перейти к пополнению счета
            </Link>
            {showSecuritiesTransfersLink && (
              <Link onClick={openSecuritiesTransferModal}>
                Перейти к переводам ценных бумаг
              </Link>
            )}
          </Space>
        </Space>
      </div>
      <div
        className={styles.sixthStepImage}
        style={{ backgroundImage: `url(${lastStep})` }}
      />
    </div>
  );
};
