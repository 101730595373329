import { Portal } from '@alfalab/core-components/portal';
import { Spinner } from '@alfalab/core-components/spinner';

import styles from './ActivitySpinner.module.css';

export const ActivitySpinner = ({
  testId = 'spinner',
}: {
  testId?: string;
}) => {
  return (
    <Portal>
      <div className={styles.spinnerContainer}>
        <div data-test-id={testId} className={styles.spinner}>
          <Spinner visible={true} size="m" />
        </div>
      </div>
    </Portal>
  );
};
