import { LinkName, Widget } from '@terminal/core/types/layout';
import { WatchlistIds } from '@terminal/core/types/watchList';

import { ConfigTemplate } from '../../../../index';
import { balanceLayout } from '../common/balanceLayout';
import {
  COMMON_ORDERS_CONFIG,
  getCommonInstrumentsConfig,
  POSITIONS_COMMON_CONFIG,
  TRADE_HYBRIDORDER_CONFIG,
  TRADE_ORDERBOOK_CONFIG,
} from '../common/configs';
import {
  CHART_LEFT_CHARTIQ_CONFIG,
  CHART_LEFT_CHARTIQ_SETTINGS,
  CHART_RIGHT_CHARTIQ_CONFIG,
  CHART_RIGHT_CHARTIQ_SETTINGS,
  CHART_WATCHLIST_CONFIG,
  TRADE_CHART_CONFIG,
  TRADE_CHART_SETTINGS,
} from './configs';

import { DefaultConfiguration } from '../../../../model/types/defaultConfiguration';
import { Layout } from '../../../../model/types/layout';
import { Row } from '../../../../model/types/row';
import { Tab } from '../../../../model/types/tab';
import { TabSet } from '../../../../model/types/tabSet';

export const swingMedium = () => {
  /** Возвращает layout Торговля */
  const makeTradeLayout = () => {
    const instrumentsTab = new Tab(
      Widget.WATCHLIST,
      getCommonInstrumentsConfig(LinkName.BLUE, WatchlistIds.iMOEXList)
    );
    const instrumentTabset = new TabSet(50);

    instrumentTabset.addChildren([instrumentsTab]);
    const ordersTab = new Tab(Widget.ORDERS, COMMON_ORDERS_CONFIG);
    const positionsTab = new Tab(Widget.POSITIONS, POSITIONS_COMMON_CONFIG);
    const positionsTabset = new TabSet(50);

    positionsTabset.addChildren([positionsTab, ordersTab]);

    const leftRow = new Row(30);

    leftRow.addChildren([instrumentTabset, positionsTabset]);

    // средняя колонка

    const orderbookTab = new Tab(Widget.ORDERBOOK, TRADE_ORDERBOOK_CONFIG);
    const tradeTab = new Tab(Widget.HYBRIDORDER, TRADE_HYBRIDORDER_CONFIG);
    const orderbookTabset = new TabSet(50);

    orderbookTabset.addChildren([orderbookTab, tradeTab]);
    const middleRow = new Row(20);

    middleRow.addChildren([orderbookTabset]);

    // правая колонка
    const chartiqTab = new Tab(
      Widget.CHART,
      TRADE_CHART_CONFIG,
      TRADE_CHART_SETTINGS
    );
    const chartiqTabSet = new TabSet(50);

    chartiqTabSet.addChildren([chartiqTab]);

    const layoutRow = new Row(3);

    layoutRow.addChildren([leftRow, middleRow, chartiqTabSet]);

    return new Layout('Торговля', layoutRow);
  };

  /** Возвращает layout Графики */
  const makeChartLayout = () => {
    const instrumentsChartTab = new Tab(
      Widget.WATCHLIST,
      CHART_WATCHLIST_CONFIG
    );
    const instrumentChartTabset = new TabSet(20);

    instrumentChartTabset.addChildren([instrumentsChartTab]);

    const leftChartTab = new Tab(
      Widget.CHART,
      CHART_LEFT_CHARTIQ_CONFIG,
      CHART_LEFT_CHARTIQ_SETTINGS
    );
    const leftChartTabset = new TabSet(40);

    leftChartTabset.addChildren([leftChartTab]);

    const rightChartTab = new Tab(
      Widget.CHART,
      CHART_RIGHT_CHARTIQ_CONFIG,
      CHART_RIGHT_CHARTIQ_SETTINGS
    );
    const rightChartTabset = new TabSet(40);

    rightChartTabset.addChildren([rightChartTab]);

    const chartRow = new Row(3);

    chartRow.addChildren([
      instrumentChartTabset,
      leftChartTabset,
      rightChartTabset,
    ]);

    return new Layout('Графики', chartRow);
  };

  const configuration = new DefaultConfiguration('Среднесрочная торговля');

  configuration.addLayout(makeTradeLayout());
  configuration.addLayout(makeChartLayout());
  configuration.addLayout(balanceLayout);

  return configuration as unknown as ConfigTemplate;
};
