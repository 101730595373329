import React, { HTMLAttributes, VFC } from 'react';
import { DisplayMIcon } from '@alfalab/icons-glyph/DisplayMIcon';

import { Form, MoonIcon, SunIcon } from '../../../../entities/DesignSetting';
import { useThemeContext } from '../../../../shared/lib/themeContext';

export type ThemeFormProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>;

export const ThemeForm: VFC<ThemeFormProps> = (props) => {
  const { setPreferredTheme, preferredTheme } = useThemeContext();

  return (
    <Form
      title="Тема интерфейса"
      subtitle={
        <>
          При выборе системной темы терминал автоматически подстроится
          <br />
          под тему, выбранную на устройстве
        </>
      }
      value={preferredTheme}
      onChange={setPreferredTheme}
      options={[
        {
          value: 'dark',
          label: 'Темная',
          icon: <MoonIcon />,
        },
        {
          value: 'light',
          label: 'Светлая',
          icon: <SunIcon />,
        },
        {
          value: 'auto',
          label: 'Системная',
          icon: <DisplayMIcon />,
        },
      ]}
      {...props}
    />
  );
};
