import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getCoupons } from '@terminal/core/lib/rest/investApi';
import { NotificationType } from '@terminal/core/types/ui';

export const useCoupons = (isin?: string) => {
  const addNotification = useNotification();

  return useQuery(['getCoupons', isin], () => getCoupons(isin || ''), {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса информации по купонам',
        text: error?.message || '',
      });
    },
    enabled: Boolean(isin),
  });
};
