import cn from 'classnames';
import React, { FC, HTMLAttributes, MouseEventHandler } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { TextProps, Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import styles from './ModalHeader.module.css';

export type ModalHeaderProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Обработчик нажатия на кнопку «Закрыть»
   * */
  onClose?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Пропсы, которые будут переданы в компонент заголовка
   * @default { view: 'secondary-large', weight: 'bold' }
   * */
  titleProps?: Partial<TextProps>;
};

export const ModalHeader: FC<ModalHeaderProps> = (props) => {
  const { children, onClose, className, titleProps, ...restProps } = props;
  const rootClassName = cn(className, styles.root);

  return (
    <header className={rootClassName} {...restProps}>
      <Typography.Text view="secondary-large" weight="bold" {...titleProps}>
        {children}
      </Typography.Text>
      <IconButton
        size="xs"
        view="secondary"
        icon={CrossMIcon}
        onClick={onClose}
      />
    </header>
  );
};
