import { RefObject } from 'react';

import { useEventListener } from './useEventListener';

type Handler = (event: MouseEvent) => void;

/**
 * Хук, просматривающий удержание пальцем на элементе
 *
 * @param ref Просматриваемый элемент
 * @param handler Колбек обработчик события
 * @param delay Время тача
 * @param [capture] Фаза погружения
 */
export function useOnHoldTouch<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  delay: number,
  capture = true
): void {
  let timerId: number;

  useEventListener(
    'touchstart',
    () => {
      const el = ref?.current;

      if (!el) return;

      timerId = setTimeout(handler, delay);
    },
    {
      capture,
      element: ref,
    }
  );

  useEventListener(
    'touchend',
    () => {
      timerId && clearTimeout(timerId);
    },
    { capture }
  );

  useEventListener('touchmove', () => {
    timerId && clearTimeout(timerId);
  });
}
