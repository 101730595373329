import cn from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Color, Typography } from '@alfalab/core-components/typography';

import styles from './InfoHeading.module.css';

type InfoHeadingProps = {
  title: ReactNode;
  description?: ReactNode;
  descriptionColor?: Color;
};

export const InfoHeading: FC<InfoHeadingProps> = (props) => {
  const { title, description, descriptionColor } = props;

  return (
    <>
      <Typography.Title
        className={cn(styles.title, {
          [styles.bottomOffset]: !description,
        })}
        tag="h2"
        font="styrene"
        view="small"
      >
        {title}
      </Typography.Title>
      {description && (
        <Typography.Text
          tag="p"
          className={cn(styles.description, styles.bottomOffset)}
          view="secondary-large"
          color={descriptionColor}
        >
          {description}
        </Typography.Text>
      )}
    </>
  );
};
