import { LK_URI } from '../../env';
import { fetchThroughProxy, makeGetParams } from '../url';
import { fetchLkResult, LKResult } from './lkApi';

export interface LKLoginResponse {
  type:
    | 'success'
    | 'error'
    | 'access_denied'
    | 'captcha'
    | '2fa'
    | '2fa_overlimit'
    | '2fa_blocked';
  data?: LK2faResponseData;
  message: string | null;
}

export interface LK2faResponseData {
  phone: string;
  id: string;
  secondsToUnblock?: number;
}

export async function authorize(
  login: string,
  password: string
): Promise<LKLoginResponse> {
  return await fetchLkResult<LKLoginResponse>(
    `/api/account/authorize`,
    'POST',
    { login, password }
  );
}

interface LK2faValidateParams {
  id: string;
  code: string;
}

export async function authorize2fa(
  data: LK2faValidateParams
): Promise<LKLoginResponse> {
  return await fetchLkResult<LKLoginResponse>(
    `/api/account/2fa/authorize`,
    'POST',
    data
  );
}

export async function sms2faResend(id: string): Promise<LKLoginResponse> {
  return await fetchLkResult<LKLoginResponse>(
    `/api/account/2fa/resend`,
    'POST',
    {
      id,
    }
  );
}

export interface LKCaptcha {
  captchaImage: string;
}

export async function getNewCaptcha(
  login: string
): Promise<LKCaptcha | LKResult> {
  const request = makeGetParams({ login });
  return await fetchLkResult<LKCaptcha | LKResult>(
    `/api/captcha/new?${request}`
  );
}

export async function captchaValidate(
  login: string,
  captcha: string
): Promise<LKCaptcha | LKResult> {
  return await fetchLkResult<LKCaptcha | LKResult>(
    `/api/captcha/validate`,
    'POST',
    { login, captcha }
  );
}

export async function authorizeProlongation(): Promise<LKResult> {
  return await fetchLkResult<LKResult>(
    `/api/account/token/prolongation`,
    'POST'
  );
}

/**
 * Прокси-метод разлогина в паспорте
 * @param token - refresh_token
 * @returns
 */
export async function passportProxyLogout(token: string): Promise<void> {
  let params: RequestInit = {
    credentials: 'include',
    cache: 'no-store',
    method: 'POST',
  };

  const response = await fetchThroughProxy(
    LK_URI + `/api/passport/logout?refresh_token=${token}`,
    params
  );

  if (response.ok) {
    return;
  } else {
    throw new Error(await response.text());
  }
}

/**
 * Изменение пароля - отправка СМС кода
 */

export type PasswordRestoreSendSmsRequest = {
  phone: string;
  lastName: string;
  firstName: string;
  patronymic: string;
};

export type PasswordRestoreSendSmsResponse = {
  formId?: string;
};

export async function passwordRestoreSendSms(
  data: PasswordRestoreSendSmsRequest
) {
  const res = await fetchLkResult<PasswordRestoreSendSmsResponse | LKResult>(
    '/api/registration/password/restore/send-sms',
    'POST',
    data,
    false,
    undefined,
    false
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

/**
 * Изменение пароля - переотправка СМС кода
 */

export async function passwordRestoreReSendSms(formId: string) {
  return fetchLkResult(
    '/api/registration/password/restore/resend-sms',
    'POST',
    { formId }
  );
}

/**
 * Изменение пароля - проверка СМС кода
 */

export type PasswordRestoreCheckSmsRequest = {
  formId: string;
  code: string;
};

export type PasswordRestoreCheckSmsResponse = {
  login?: string;
  code?: string;
  message?: string;
};

export async function passwordRestoreCheckSms(
  data: PasswordRestoreCheckSmsRequest
) {
  const res = await fetchLkResult<PasswordRestoreCheckSmsResponse>(
    '/api/registration/password/restore/check-sms',
    'POST',
    data,
    false,
    undefined,
    false
  );

  if (!('login' in res)) {
    throw new Error(res?.message || '');
  }

  return res;
}

/**
 * Изменение пароля
 */

export type PasswordRestoreChangeRequest = {
  formId: string;
  password: string;
  passportClientId: string | null;
  authorizeInPassport: boolean;
};

export type PasswordRestoreChangeResponse = {
  code?: string | number;
  message?: string;
  expires_in?: string;
};

export async function passwordRestoreChange(
  data: PasswordRestoreChangeRequest
) {
  return fetchLkResult<PasswordRestoreChangeResponse>(
    '/api/registration/password/restore/change',
    'POST',
    data,
    false,
    undefined,
    false
  );
}
