import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';

import { ChangeOperationModeParams } from './types/ChangeOperationModeParams';

export async function changeOperationMode(data: ChangeOperationModeParams) {
  return await signOperation(
    '/api/client/accounts/change-operation-mode',
    'POST',
    data.params,
    data.signOptions
  );
}
