import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAlfaDirectContext, useFullFI } from '@terminal/alfadirect/hooks';
import { OrderType } from '@terminal/core/lib/client/entities';
import { useTradingService } from '@terminal/core/lib/services/trading/useTradingService';
import { HybridOrderProps } from '@terminal/core/types/layout';
import { OrderSide } from '@terminal/core/types/trading';
import { NotificationType } from '@terminal/core/types/ui';

import { TradingCertificateLock } from '../../../features/TradingCertificateLock';
import { useWidgetContext } from '../../../shared';
import { TradingContextProvider } from '../context';
import { Header, OrdersInfo } from '../ui';
import { WidgetForm } from '../ui/WidgetForm';

import { useSelectedAccount } from '../../../shared/hooks/useSelectedAccount';
import { useTradingFormOrder } from '../hooks';

import { FormType } from '../types';

import styles from './HybridOrder.module.css';

export const HybridOrder = ({
  nodeId,
  idFi,
  tableProps,
  hideActiveOrders,
  linkedPrice,
  limitsProps,
}: HybridOrderProps) => {
  const { addNotification, onWidgetLoad, getNode } = useWidgetContext();
  const { useFinInfoExt } = useAlfaDirectContext();

  const fullFi = useFullFI(idFi);

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isLoadingCancelModal, setIsLoadingCancelModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isLoadingEditModal, setIsLoadingEditModal] = useState(false);
  const [isOpenLimitsModal, setIsOpenLimitsModal] = useState(false);

  const [secondPrice, setSecondPrice] = useState(0);
  const [priceTouched, setPriceTouched] = useState(false);

  const finInfoExtRecord = useFinInfoExt(idFi);
  const finInfoExt = finInfoExtRecord[idFi];

  const nodeWidth = getNode(nodeId || '')?.getRect().width || 0;

  const { selectedAccount, selectedSubAccounts, selectedSubAccountId } =
    useSelectedAccount(tableProps);
  const selectedSubAccount = useMemo(
    () => [selectedSubAccounts[0]],
    [selectedSubAccounts]
  );

  //Колбэки на успех и фейл
  const onOrderResponse = useCallback(() => {
    setIsOpenCancelModal(false);
    setIsLoadingCancelModal(false);
    setIsOpenEditModal(false);
    setIsLoadingEditModal(false);
  }, []);
  const onOrderError = useCallback(
    (message: string) => {
      addNotification({
        type: NotificationType.TRADE,
        id: new Date().toString(),
        badge: 'negative',
        title: 'Ошибка',
        text: message,
      });

      setIsOpenCancelModal(false);
      setIsLoadingCancelModal(false);
      setIsOpenEditModal(false);
      setIsLoadingEditModal(false);
    },
    [addNotification]
  );

  const { sendOrder, replaceOrder, cancelOrder } = useTradingService(
    onOrderError,
    onOrderResponse
  );

  const {
    side,
    orderType,
    price,
    slippage,
    quantity,
    last,
    currentAdditionalInstructions,
    lifeTime,
    activation,
    sendOrderParams,
    setQuantity,
    setPrice,
    setSlippage,
    setSide,
    setOrderType,
    setCurrentAdditionalInstructions,
    setLifeTime,
    setActivation,
    sendOrder: handleSendOrder,
  } = useTradingFormOrder({
    idFi,
    fullFI: fullFi,
    finInfoExt,
    defaultOrderType: OrderType.LMT,
    sendOrder,
    defaultSide: OrderSide.BUY,
    selectedSubAccountId,
    secondPrice,
    setSecondPrice,
    formType: FormType.WIDGET,
  });

  useEffect(() => {
    // Дефолтная цена при маунте компонента: либо 0 либо читаем из параметров
    const initPrice = linkedPrice;

    if (initPrice) {
      setPrice(initPrice);
      setPriceTouched(true);
    }

    setSecondPrice(0);
  }, [idFi, linkedPrice, setPrice]);

  useEffect(() => {
    if (nodeId) {
      onWidgetLoad(nodeId);
    }
  }, [nodeId, onWidgetLoad]);

  return (
    <TradingContextProvider
      idFi={idFi}
      selectedAccount={selectedAccount}
      selectedSubAccountId={selectedSubAccountId}
      side={side}
      setSide={setSide}
      orderType={orderType}
      setOrderType={setOrderType}
      price={price}
      setPrice={setPrice}
      priceTouched={priceTouched}
      setPriceTouched={setPriceTouched}
      secondPrice={secondPrice}
      setSecondPrice={setSecondPrice}
      slippage={slippage}
      setSlippage={setSlippage}
      last={last}
      quantity={quantity}
      setQuantity={setQuantity}
      lifeTime={lifeTime}
      setLifeTime={setLifeTime}
      activation={activation}
      setActivation={setActivation}
      currentAdditionalInstructions={currentAdditionalInstructions}
      setCurrentAdditionalInstructions={setCurrentAdditionalInstructions}
      sendOrderParams={sendOrderParams}
      sendOrder={handleSendOrder}
    >
      <div className={styles.container}>
        <Header
          nodeId={nodeId}
          idFi={idFi}
          selectedSubAccounts={selectedSubAccount}
          hideActiveOrders={hideActiveOrders}
          setIsOpenLimitsModal={setIsOpenLimitsModal}
        />
        <TradingCertificateLock size={nodeWidth < 240 ? 'xs' : 's'}>
          <div className={cn(styles.content)}>
            <div
              id={`trading-limits-${nodeId?.slice(1)}`}
              className={styles.limitsPortal}
            />
            <OrdersInfo
              finInfoExt={finInfoExt}
              currencyCode={fullFi?.currencyCode}
              hideActiveOrders={hideActiveOrders}
              replaceOrder={replaceOrder}
              cancelOrder={cancelOrder}
              isLoadingCancelModal={isLoadingCancelModal}
              setIsLoadingCancelModal={setIsLoadingCancelModal}
              isLoadingEditModal={isLoadingEditModal}
              setIsLoadingEditModal={setIsLoadingEditModal}
              isOpenCancelModal={isOpenCancelModal}
              setIsOpenCancelModal={setIsOpenCancelModal}
              isOpenEditModal={isOpenEditModal}
              setIsOpenEditModal={setIsOpenEditModal}
              idObjectGroup={fullFi?.idObjectGroup}
            />
            <WidgetForm
              nodeId={nodeId}
              fullFi={fullFi}
              finInfoExt={finInfoExt}
              selectedSubAccounts={selectedSubAccounts}
              isOpenLimitsModal={isOpenLimitsModal}
              setIsOpenLimitsModal={setIsOpenLimitsModal}
              limitsProps={limitsProps}
            />
          </div>
        </TradingCertificateLock>
      </div>
    </TradingContextProvider>
  );
};
