import cn from 'classnames';
import { FC, ReactNode } from 'react';
import {
  AmountInput,
  AmountInputProps,
} from '@alfalab/core-components/amount-input';
import { Typography } from '@alfalab/core-components/typography';
import { PauseCircleMIcon } from '@alfalab/icons-glyph/PauseCircleMIcon';
import { PfmPlayMIcon } from '@alfalab/icons-glyph/PfmPlayMIcon';

import { Stepper } from '@terminal/common/components/Stepper';
import { OrderType } from '@terminal/core/lib/client/entities';

import { AdditionalPriceSettings } from '../AdditionalPriceSettings';

import { PriceQuickControls } from '../../model/types';

import styles from './PriceInputComponent.module.css';

export interface PriceInputProps
  extends Pick<
    AmountInputProps,
    | 'disabled'
    | 'value'
    | 'minority'
    | 'suffix'
    | 'onChange'
    | 'onClick'
    | 'onFocus'
    | 'onBlur'
    | 'error'
    | 'currency'
  > {
  isChart: boolean;
  label?: ReactNode;
  orderType: OrderType;
  side: 'buy' | 'sell';
  withStepper?: boolean;
  onAdd: () => void;
  onSub: () => void;
  allowStreamPrice: boolean;
  isPriceStreamed: boolean;
  setPriceStreamed: (streamed: boolean) => void;
  limitPriceAdditional?: PriceQuickControls;
  withShortcuts?: boolean;
  containerClassName?: string;
}

export const PriceInputComponent: FC<PriceInputProps> = ({
  isChart,
  label,
  orderType,
  side,
  withStepper = true,
  onAdd,
  onSub,
  allowStreamPrice,
  isPriceStreamed,
  setPriceStreamed,
  limitPriceAdditional,
  withShortcuts = false,
  containerClassName,
  ...props
}) => {
  return (
    <div className={cn(containerClassName)}>
      {label ? (
        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={cn(styles.label, styles.graphic)}
        >
          {label}
        </Typography.Text>
      ) : null}

      <AmountInput
        className={cn(styles.customInput)}
        fieldClassName={styles.customInputField}
        block
        rightAddons={
          orderType !== OrderType.MKT && (
            <div className={styles.priceAddons}>
              {allowStreamPrice && (
                <div className={styles.playButton}>
                  {isPriceStreamed ? (
                    <PauseCircleMIcon
                      width={12}
                      height={12}
                      color="var(--color-light-graphic-secondary)"
                      onClick={() => setPriceStreamed(false)}
                    />
                  ) : (
                    <PfmPlayMIcon
                      width={10}
                      height={10}
                      color="var(--color-light-graphic-secondary)"
                      onClick={() => setPriceStreamed(true)}
                    />
                  )}
                </div>
              )}
              {withStepper && <Stepper size="s" onAdd={onAdd} onSub={onSub} />}
              {withShortcuts && limitPriceAdditional && (
                <AdditionalPriceSettings
                  isChart={isChart}
                  orderType={orderType}
                  side={side}
                  {...limitPriceAdditional}
                />
              )}
            </div>
          )
        }
        {...props}
      />
    </div>
  );
};
