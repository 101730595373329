import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { SubAccountRazdelItem } from '../../types/subAccountRazdel';

export const useFilteredAccounts = () => {
  const { subAccountRazdels, accounts: clientAccounts } =
    useAlfaDirectContext();

  return useMemo(() => {
    // Счета приходят не сразу, так что фильтруем undefined
    const filteredRazdels = subAccountRazdels.filter((item) => item);
    const accounts = groupBy(filteredRazdels, 'idAccount');

    return Object.entries(accounts).reduce((acc, [key, account]) => {
      const clientAccount = clientAccounts.find(
        (account) => account.idAccount === Number(key)
      );

      if (clientAccount && !clientAccount.microInvest) {
        return { ...acc, [key]: account };
      } else {
        return acc;
      }
    }, {} as Record<string | number, SubAccountRazdelItem[]>);
  }, [subAccountRazdels, clientAccounts]);
};
