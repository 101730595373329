import React, { useContext } from 'react';

import { defaultPropsMock } from '../mocks/props';
import { WidgetApi } from '../model/WidgetApi';

export function createWidgetContext() {
  const CreatedWidgetContext = React.createContext<WidgetApi>(defaultPropsMock);

  const useCreatedWidgetContext = () => useContext(CreatedWidgetContext);

  return {
    ...CreatedWidgetContext,
    WidgetContext: CreatedWidgetContext,
    useWidgetContext: useCreatedWidgetContext,
  };
}

export const { Provider, Consumer, WidgetContext, useWidgetContext } =
  createWidgetContext();
