import { ExecutionType, OrderType } from '../../lib/client/entities';

/**
  Определяем корректный idExecutionType для исходящей заявки
*/
export const getExecutionType = (
  idOrderType: OrderType,
  openQuantity?: number
) => {
  if (
    openQuantity ||
    ![
      OrderType.STP,
      OrderType.LMT,
      OrderType.TRS,
      OrderType.TRL,
      OrderType.BRS,
      OrderType.TBRS,
      OrderType.LIT,
      OrderType.MIT,
    ].includes(idOrderType)
  ) {
    return ExecutionType.ASIS;
  }

  return ExecutionType.ASL;
};
