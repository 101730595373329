import React, { FC, HTMLAttributes } from 'react';
import { Button as BaseButton } from '@alfalab/core-components/button';
import { ListBulletedMIcon } from '@alfalab/icons-glyph/ListBulletedMIcon';

export type TableButtonProps = HTMLAttributes<HTMLButtonElement>;

export const TableButton: FC<TableButtonProps> = (props) => {
  return (
    <BaseButton
      size="xxs"
      view="tertiary"
      leftAddons={<ListBulletedMIcon width={16} height={16} />}
      block
      {...props}
    >
      В таблицу
    </BaseButton>
  );
};
