import React from 'react';
import { CrossHeavyMIcon } from '@alfalab/icons-glyph/CrossHeavyMIcon';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';

import { ManagementResultType } from '../../../Management';

export const enum CreateAttorneyErrorCode {
  PassportDataOutdated = 3, // "Паспортные данные неактуальны"
  AttorneyAlreadyExists = 5, // "Доверенность уже существует"
  RightsAlreadyGranted = 6, // "Уже выданы права на субсчет"
}

export const CREATE_ATTORNEY_FULL_TEXT_ERROR_MAP: Record<
  CreateAttorneyErrorCode,
  ManagementResultType
> = {
  [CreateAttorneyErrorCode.PassportDataOutdated]: {
    icon: <CrossHeavyMIcon />,
    message: 'Мы не смогли оформить доверенность',
    description: 'Проверьте паспортные данные уполномоченного лица',
  },
  [CreateAttorneyErrorCode.AttorneyAlreadyExists]: {
    icon: <ExclamationMIcon />,
    message: 'Доверенность на указанное уполномоченное лицо была создана ранее',
    description: 'Отзовите текущую, чтобы создать новую',
  },
  [CreateAttorneyErrorCode.RightsAlreadyGranted]: {
    icon: <ExclamationMIcon />,
    message: 'Доверенность на указанное уполномоченное лицо была создана ранее',
    description: 'Отзовите текущую, чтобы создать новую',
  },
};
