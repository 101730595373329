import { TRACK_PRICE_SETTINGS_DEFAULT } from '../../../constants/trackPriceSettings';
import { RootStore } from '../../useStore';
import { trackPriceCommonSettingsSelector } from './trackPriceCommonSettingsSelector';

import { TrackPrice, TrackPriceSettings } from '../../../types/trackPrice';

/**
 * Выбирает настройки алерта по идентификатору алерта
 * */
export function trackPriceSettingsByIdSelector(
  store: RootStore
): (trackPriceId: TrackPrice['IdTrackPrice']) => TrackPriceSettings {
  const { workspaceConfigurationActiveId, workspaceConfigurations } = store;
  const configuration = workspaceConfigurations[workspaceConfigurationActiveId];
  const commonSettings = trackPriceCommonSettingsSelector(store);

  return (trackPriceId) =>
    configuration.lastAutoSavedConfig.trackPriceSettings
      ?.settingsByTrackPriceId?.[trackPriceId.toString()] ?? {
      ...TRACK_PRICE_SETTINGS_DEFAULT,
      ...commonSettings,
    };
}
