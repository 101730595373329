import React, { HTMLAttributes, ReactNode, VFC } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

export type TitleBlockProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Заголовок
   * */
  title: ReactNode;
  /**
   * Подзаголовок
   * */
  subtitle?: ReactNode;
};

/**
 * Отображает звголовок и подзаголовок блока
 * */
export const TitleBlock: VFC<TitleBlockProps> = (props) => {
  const { title, subtitle, ...restProps } = props;

  return (
    <div {...restProps}>
      <Typography.Text
        view="primary-small"
        color="primary"
        weight="bold"
        tag="p"
        defaultMargins={false}
      >
        {title}
      </Typography.Text>
      {subtitle && <Gap size="xs" />}
      {subtitle && (
        <Typography.Text
          view="secondary-small"
          color="secondary"
          tag="p"
          defaultMargins={false}
        >
          {subtitle}
        </Typography.Text>
      )}
    </div>
  );
};
