import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Input } from '@alfalab/core-components/input';
import { Loader } from '@alfalab/core-components/loader';
import { Select, SelectProps } from '@alfalab/core-components/select';
import { Field } from '@alfalab/core-components/select/components/field';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';
import { Switch } from '@alfalab/core-components/switch';
import { Textarea } from '@alfalab/core-components/textarea';

import {
  SecuritiesContragent,
  SecuritiesPlaceItem,
} from '@terminal/core/lib/rest/lkSecurities';

import { FormControlSummary } from '../../../../shared/ui/FormControlSummary';
import {
  TooltipConterpartyDepoAccount,
  TooltipConterpartyDepoSection,
  TooltipConterpartyName,
  TooltipConterpartyPlace,
} from '../../components/Tooltips';
import {
  isAccountTypeRequired,
  isConteragentAccDepoRequired,
  isContrAlfaBankShow,
  isContrTreatyRequired,
  isCounterpartyRequired,
  isDepoSectionCounterpartyRequired,
  isSwiftRequired,
  SecurityPlaceCode,
  securityPlaceKey,
} from '../../shared';

interface CounterpartyEuroclearFormProps {
  counteragentAlfaBank: boolean;
  onCounteragentAlfaBankChange: (check: boolean) => void;
  conteragents?: SecuritiesContragent[];
  conteragentsPending?: boolean;
  selectedCounterpartyPlace: SecuritiesPlaceItem | null;
  conteragentAccDepo: string;
  marketAlfa: string;
  selectedConteragentKey: string;
  userType: string;
  selectedRequisiteKey: string;
  depoAccount: string;
  depoSection: string;
  counterpartyPlace: string;
  swift: string;
  ownershipChange: boolean;
  transferType: 'in' | 'out';

  placeListOptions: { key: string; content: string }[];
  placeListPending: boolean;
  conteragentsOptions: { key: string; content: string }[];
  conteragentRequisitesOptions: { key: string; content: string }[];
  counterAlfaTreatyOptions: { key: string; content: string }[];
  conterpartyNameShow: boolean;
  conterpartyRequisitesEnabled: boolean;
  conterpartyDepoAccountShow: boolean;
  handlePlaceChange: SelectProps['onChange'];
  handleConteragentChange: SelectProps['onChange'];
  handleRequisiteChange: SelectProps['onChange'];
  handleCounterAlfaTreatyChange: SelectProps['onChange'];

  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleBlur: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  touched: { [s: string]: boolean };
  errors: { [s: string]: string | boolean };
}

export const CounterpartyEuroclearForm = ({
  counteragentAlfaBank,
  onCounteragentAlfaBankChange,
  conteragents,
  conteragentsPending,
  selectedCounterpartyPlace,
  conteragentAccDepo,
  marketAlfa,
  selectedConteragentKey,
  userType,
  selectedRequisiteKey,
  depoAccount,
  depoSection,
  counterpartyPlace,
  swift,
  ownershipChange,
  transferType,

  placeListOptions,
  placeListPending,
  conteragentsOptions,
  conteragentRequisitesOptions,
  counterAlfaTreatyOptions,
  conterpartyNameShow,
  conterpartyRequisitesEnabled,
  conterpartyDepoAccountShow,
  handlePlaceChange,
  handleConteragentChange,
  handleRequisiteChange,
  handleCounterAlfaTreatyChange,

  handleChange,
  handleBlur,
  touched,
  errors,
}: CounterpartyEuroclearFormProps) => {
  const counterpartyMarket = selectedCounterpartyPlace
    ? selectedCounterpartyPlace.placeCode
    : '';

  const showDepoSectionCounterparty =
    isDepoSectionCounterpartyRequired(
      counterpartyMarket,
      marketAlfa,
      counteragentAlfaBank
    ) && selectedCounterpartyPlace;
  const showSwift =
    isSwiftRequired(counterpartyMarket, marketAlfa, counteragentAlfaBank) &&
    selectedCounterpartyPlace;
  const showAccountType =
    isAccountTypeRequired(counterpartyMarket, counteragentAlfaBank) &&
    selectedCounterpartyPlace;
  const showConteragentAccDepo =
    isConteragentAccDepoRequired(counteragentAlfaBank) &&
    selectedCounterpartyPlace;
  const showCounterparty =
    isCounterpartyRequired(
      counterpartyMarket,
      userType,
      counteragentAlfaBank
    ) && selectedCounterpartyPlace;
  const showContrTreaty =
    isContrTreatyRequired(
      ownershipChange,
      transferType,
      counteragentAlfaBank
    ) && selectedCounterpartyPlace;

  const swiftNode = (
    <Input
      label="SWIFT"
      labelView="outer"
      block
      autoComplete="off"
      size="s"
      name="swift"
      value={swift}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched.swift ? errors.swift : ''}
      disabled={!conterpartyRequisitesEnabled}
    />
  );

  const contragentNameNode = (
    <Textarea
      label="Наименование контрагента"
      labelView="outer"
      block
      autoComplete="off"
      size="s"
      name="counterpartyPlace"
      value={counterpartyPlace}
      disabled={!conterpartyRequisitesEnabled}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched.counterpartyPlace ? errors.counterpartyPlace : ''}
      rightAddons={<TooltipConterpartyName />}
    />
  );

  return (
    <Space fullWidth direction="vertical">
      {isContrAlfaBankShow(ownershipChange, transferType) && (
        <Row>
          <Col width={12}>
            <Switch
              label="Контрагент в Go Invest"
              checked={counteragentAlfaBank}
              disabled={
                counterpartyMarket === SecurityPlaceCode.REESTR ||
                marketAlfa === SecurityPlaceCode.EUROTRADE
              }
              onChange={(_, payload) =>
                onCounteragentAlfaBankChange(payload ? payload.checked : false)
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col width={6}>
          <Select
            label="Место хранения"
            labelView="outer"
            placeholder="Выберите место хранения"
            size="s"
            block
            options={placeListOptions}
            selected={securityPlaceKey(selectedCounterpartyPlace)}
            onChange={handlePlaceChange}
            Field={(props: FieldProps) => (
              <Field
                {...props}
                leftAddons={placeListPending && <Loader />}
                rightAddons={<TooltipConterpartyPlace />}
              />
            )}
          />
        </Col>
        {showContrTreaty && (
          /** Выбирая Ген. соглашение - выбираем депо аккаунт */
          <Col width={6}>
            <Select
              label="Генеральное соглашение"
              labelView="outer"
              placeholder="Выберите генеральное соглашение"
              size="s"
              block
              options={counterAlfaTreatyOptions}
              selected={conteragentAccDepo}
              onChange={handleCounterAlfaTreatyChange}
            />
          </Col>
        )}
        {!showContrTreaty && showConteragentAccDepo && (
          <Col width={6}>
            <Input
              label="Счёт депо в Go Invest"
              labelView="outer"
              block
              autoComplete="off"
              size="s"
              name="conteragentAccDepo"
              value={conteragentAccDepo}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.conteragentAccDepo ? errors.conteragentAccDepo : ''
              }
            />
          </Col>
        )}
        {conteragentsOptions.length > 0 && (
          <Col width={6}>
            <Select
              label="Контрагент"
              labelView="outer"
              placeholder="Выберите контрагента"
              size="s"
              block
              options={conteragentsOptions}
              selected={selectedConteragentKey}
              onChange={handleConteragentChange}
              Field={(props: FieldProps) => (
                <Field
                  {...props}
                  leftAddons={conteragentsPending && <Loader />}
                />
              )}
            />
          </Col>
        )}
        {showCounterparty && !conteragents && conterpartyNameShow && (
          <Col width={6}>{contragentNameNode}</Col>
        )}
      </Row>
      {showContrTreaty && showConteragentAccDepo && (
        <Row>
          <Col width={6}>
            <FormControlSummary label="Счёт депо в Go Invest">
              {conteragentAccDepo}
            </FormControlSummary>
          </Col>
        </Row>
      )}
      {showCounterparty && Boolean(conteragents) && conterpartyNameShow && (
        <Row>
          <Col width={12}>{contragentNameNode}</Col>
        </Row>
      )}
      {((!showAccountType && !showConteragentAccDepo) ||
        showDepoSectionCounterparty) &&
        conterpartyDepoAccountShow && (
          <Row>
            {!showAccountType && !showConteragentAccDepo && (
              <Col width={6}>
                {conteragentRequisitesOptions &&
                conteragentRequisitesOptions.length > 1 ? (
                  <Select
                    label="Счёт"
                    labelView="outer"
                    placeholder="Выберите счёт"
                    size="s"
                    block
                    options={conteragentRequisitesOptions}
                    selected={selectedRequisiteKey}
                    onChange={handleRequisiteChange}
                    Field={(props: FieldProps) => (
                      <Field
                        {...props}
                        rightAddons={
                          <TooltipConterpartyDepoAccount
                            counterpartyMarket={
                              counterpartyMarket as SecurityPlaceCode
                            }
                          />
                        }
                      />
                    )}
                  />
                ) : (
                  <Input
                    label="Счёт"
                    labelView="outer"
                    block
                    autoComplete="off"
                    size="s"
                    name="depoAccount"
                    placeholder="Введите номер"
                    value={depoAccount}
                    disabled={!conterpartyRequisitesEnabled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.depoAccount ? errors.depoAccount : ''}
                    rightAddons={
                      <TooltipConterpartyDepoAccount
                        counterpartyMarket={
                          counterpartyMarket as SecurityPlaceCode
                        }
                      />
                    }
                  />
                )}
              </Col>
            )}
            {showDepoSectionCounterparty && (
              <Col width={6}>
                <Input
                  label="Субсчёт"
                  labelView="outer"
                  block
                  autoComplete="off"
                  size="s"
                  name="depoSection"
                  placeholder="Введите раздел"
                  value={depoSection}
                  disabled={!conterpartyRequisitesEnabled}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.depoSection ? errors.depoSection : ''}
                  rightAddons={
                    <TooltipConterpartyDepoSection
                      counterpartyMarket={
                        counterpartyMarket as SecurityPlaceCode
                      }
                    />
                  }
                />
              </Col>
            )}
            {!showDepoSectionCounterparty && showSwift && (
              <Col width={6}>{swiftNode}</Col>
            )}
          </Row>
        )}

      {showDepoSectionCounterparty && showSwift && (
        <Row>
          <Col width={6}>{swiftNode}</Col>
        </Row>
      )}
    </Space>
  );
};
