import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import { SecuritiesHistoryFilter } from '@terminal/core/lib/rest/lkSecurities';

import { FilterIconButton } from '../../../../shared/ui/FilterIconButton';
import { FilterButton, setFilterProp } from '../../../components/Filter';
import { STATUS_VARIANTS } from './helpers';

import styles from './HistoryFilterLine.module.css';

interface HistoryFilterLineProps {
  filter: SecuritiesHistoryFilter;
  active: boolean;
  onFilterOpen?: () => void;
  onSetFilter?: (filter: SecuritiesHistoryFilter) => void;
}

export const HistoryFilterLine = ({
  filter,
  active,
  onFilterOpen,
  onSetFilter,
}: HistoryFilterLineProps) => {
  const setFilter = (key: keyof SecuritiesHistoryFilter, value: string) => {
    if (onSetFilter) {
      const newFilter = setFilterProp(filter, key, value, undefined, [
        'statuses',
      ]);

      onSetFilter(newFilter);
    }
  };
  const periodFormat = (): string => {
    if (
      filter.startDate &&
      filter.endDate &&
      filter.startDate === filter.endDate
    ) {
      return filter.startDate;
    } else if (filter.startDate || filter.endDate) {
      return `${filter.startDate || '...'} – ${filter.endDate || '...'}`;
    } else {
      return 'Выберите период';
    }
  };

  return (
    <div className={styles.container}>
      <FilterIconButton onClick={onFilterOpen} checked={active} />
      {STATUS_VARIANTS.map((status) => (
        <FilterButton
          key={`filter-status-${status.key}`}
          checked={Boolean(filter.statuses && filter.statuses === status.key)}
          onClick={() => setFilter('statuses', status.key)}
        >
          {status.name}
        </FilterButton>
      ))}

      <FilterButton
        checked={Boolean(filter.startDate || filter.endDate)}
        rightAddons={<ChevronDownMIcon width={18} height={18} />}
        onClick={onFilterOpen}
      >
        {periodFormat()}
      </FilterButton>
    </div>
  );
};
