// эти функции загружают конфигурации из локал сторейджа
// для жсонов моделей FlexLayout создает объеты Model
import { IJsonModel, Model } from 'flexlayout-react';
import { v4 } from 'uuid';

import { workspaceConfigurationsLSDefault } from '../../../constants/workspaceConfigurations';
import log from '../../../lib/loglevel-mobile-debug';
import {
  WorkspaceConfigLS,
  WorkspaceConfiguration,
  WorkspaceConfigurationLS,
  WorkspaceConfigurationsLS,
} from '../workspaceConfigurationsSlice';

const keyLS = 'WorkspaceConfiguration';

export const createLayoutModels = (jsons: IJsonModel[]) =>
  jsons.map((json) => json && Model.fromJson(json));

export const parseWorkspaceConfig = (
  configuration: WorkspaceConfigurationLS
) => {
  const getLayoutWindows = (config: WorkspaceConfigLS) => {
    if (typeof config?.layoutWindows !== 'undefined') {
      return config.layoutWindows;
    }

    return config.layoutNames.map(() => false);
  };

  return {
    name: configuration.name,
    id: configuration.id || v4(),
    type: configuration.type || 'custom',
    lastAutoSavedConfig: {
      ...configuration.lastAutoSavedConfig,
      models: createLayoutModels(configuration.lastAutoSavedConfig.layouts),
      layoutWindows: getLayoutWindows(configuration.lastAutoSavedConfig),
    },
    lastManualSavedConfig: {
      ...configuration.lastManualSavedConfig,
      models: createLayoutModels(configuration.lastManualSavedConfig.layouts),
      layoutWindows: getLayoutWindows(configuration.lastManualSavedConfig),
    },
  };
};

export const parseWorkspaceConfigs = (
  configurations: WorkspaceConfigurationLS[]
) => {
  return configurations.map<WorkspaceConfiguration>(parseWorkspaceConfig);
};

export const getWorkspacesFromLS = () => {
  const stringConfig = localStorage.getItem(keyLS);

  if (stringConfig) {
    try {
      const parsedLS = JSON.parse(stringConfig) as WorkspaceConfigurationsLS;

      if (
        parsedLS.workspaceConfigurationActiveId >= 0 &&
        parsedLS.workspaceConfigurations.length > 0 &&
        parsedLS.workspaceConfigurationActiveId <
          parsedLS.workspaceConfigurations.length
      ) {
        return parsedLS;
      }
    } catch (e) {
      log.error('WorkspaceConfiguration is not valid object string');
    }
  }

  return workspaceConfigurationsLSDefault;
};

export const saveWorkspacesToLS = (workspaces: WorkspaceConfigurationsLS) => {
  window?.requestAnimationFrame(() => {
    localStorage.setItem(keyLS, JSON.stringify(workspaces));
  });
};
