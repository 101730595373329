import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';
import { LkSignOptions } from '@terminal/core/store/slices/lkSignSlice';

import { RejectAttorneyParams as RejectAttorneyRequest } from './types/RejectAttorneyParams';

export interface RejectAttorneyParams {
  params: RejectAttorneyRequest;
  signOptions?: LkSignOptions;
}

export const rejectAttorney = async (data: RejectAttorneyParams) => {
  return await signOperation(
    '/api/attorney/reject',
    'POST',
    data.params,
    data.signOptions
  );
};
