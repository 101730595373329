import { StoreSet } from '../useStore';

import { MarketBoardItem, ObjectExtItem, ObjectItem } from '../../types/core';
import { SubAccountItem } from '../../types/subAccount';
import { SubAccountRazdelItem } from '../../types/subAccountRazdel';

export const getMarketBoard = (market: string, source: MarketBoardItem[]) =>
  source.find((item) => item.nameMarketBoard === market);
export const getSubAccountRazdel = (
  gift: GiftItem,
  market: MarketBoardItem,
  source: SubAccountRazdelItem[]
) =>
  source.find(
    (item) =>
      item.codeSubAccount === gift.subAccount && item.idGate === market.idGate
  );

export const getSubAccount = (
  gift: GiftItem,
  subAccounts: SubAccountItem[],
  razdels: SubAccountRazdelItem[]
) => {
  const razdel = razdels.find(
    (item) => item.codeSubAccount === gift.subAccount
  );

  return subAccounts.find(
    (item) =>
      item.idAccount === razdel?.idAccount &&
      item.idSubAccount === razdel?.idSubAccount
  );
};

export const getGiftObject = (gift: GiftItem, objects: ObjectItem[]) => {
  return objects.filter(
    (item) =>
      (item.isin === gift.ticker ||
        item.isin === gift.assetId ||
        item.symbolObject === gift.ticker ||
        item.symbolObject === gift.assetId) &&
      Boolean(item.idObjectBase)
  )[0];
};

export const getGiftObjectExt = (
  gift: GiftItem,
  obj: ObjectItem,
  source: ObjectExtItem[]
) => {
  return source.find((item) => item.idObject === obj.idObject);
};

export interface GiftsSlice {
  gifts: GiftItem[];
  setGifts: (data: GiftItem[]) => void;
}

export interface GiftItem {
  assetId: string;
  campaignId: string;
  costFinInstrument: number;
  dateForSale: string;
  gettingDate: string;
  giftValue: number;
  id: string;
  logotype: string;
  prevPrice?: number;
  market: string;
  quantity: number;
  showGift: boolean;
  status?: string;
  subAccount: string;
  ticker: string;
}

// добавлен флаг changed
// так как сервер изначально присылает данные по нескольку раз,
// а они в свою очередь используются в нескольких виджетах,
// и при открытии выполнялось большое количество не нужных рендеров
export const createGiftSlice = (set: StoreSet): GiftsSlice => ({
  gifts: [],
  setGifts: (gifts: GiftItem[]) => {
    set((state) => {
      state.gifts = gifts;
    });
  },
});
