import React, { FC } from 'react';

import { useStore } from '@terminal/core/store';
import { isEditTrackPriceModalOpenSelector } from '@terminal/core/store/selectors/trackPrice/isEditTrackPriceModalOpenSelector';

import { Modal } from '../../../legacy/components/Modal';
import { ModalFooter } from '../ModalFooter';
import { FinInstrumentForm } from './FinInstrumentForm';
import { useModalTitle } from './useModalTitle';

import styles from './AlertEditModal.module.css';

/**
 * Модальное окно с формой редактирования алерта
 * */
export const AlertEditModal: FC = () => {
  const isOpen = useStore(isEditTrackPriceModalOpenSelector);
  const close = useStore((store) => store.closeEditTrackPriceModal);
  const title = useModalTitle();

  return (
    <Modal open={isOpen} onClose={close} title={title} className={styles.root}>
      <FinInstrumentForm>
        {(submitButtonProps) => (
          <ModalFooter
            withinBody
            actionButtonsEnd={[
              {
                children: 'Отмена',
                onClick: close,
              },
              {
                children: 'Сохранить',
                view: 'secondary',
                type: 'submit',
                ...submitButtonProps,
              },
            ]}
          />
        )}
      </FinInstrumentForm>
    </Modal>
  );
};
