import uniqWith from 'lodash/uniqWith';

import { RootStore } from '../../useStore';
import { trackPriceSettingsByIdSelector } from '../workspaceConfigurations/trackPriceSettingsByIdSelector';

import { TrackPrice } from '../../../types/trackPrice';

/**
 * Выбирает алерты, для которых нужно показать уведомления
 * */
export function trackPricesForTriggerSelector(store: RootStore): TrackPrice[] {
  const { trackPricesForTrigger } = store;
  const trackPriceSettingsById = trackPriceSettingsByIdSelector(store);

  // Одновременно могут сработать 2 или более одинаковых алерта
  const uniqTrackPricesForTrigger = uniqWith(
    trackPricesForTrigger,
    (a, b) => a.IdFI === b.IdFI && a.TargetPrice === b.TargetPrice
  );

  return uniqTrackPricesForTrigger.map((trackPrice) => ({
    ...trackPrice,
    settings: trackPriceSettingsById(trackPrice.IdTrackPrice),
  }));
}
