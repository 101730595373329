import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Gap } from '@alfalab/core-components/gap';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { countDecimals } from '@terminal/core/lib/format';
import { MoneyHistoryItem } from '@terminal/core/lib/rest/lkMoney';

import { FormControlSummary } from '../../../shared/ui/FormControlSummary';
import { MONEY_TRANSFER_TYPE_TEXT } from './constants';

import styles from './HistoryItemDetail.module.css';

interface HistoryItemDetailProps {
  item: MoneyHistoryItem | null;
  open?: boolean;
  onClose?: () => void;
}

export const HistoryItemDetail = ({
  item,
  open,
  onClose,
}: HistoryItemDetailProps) => {
  const target = item?.target || item?.source;

  const sourceDecimals = countDecimals(item?.source?.amount);
  const sourceMinority = Math.pow(10, sourceDecimals);
  const sourceFormated = formatAmount({
    value: Math.abs(item?.source?.amount || 0) * sourceMinority,
    currency: 'RUR' as CurrencyCodes,
    minority: sourceMinority,
    view: 'withZeroMinorPart',
  });

  const targetDecimals = countDecimals(item?.target?.amount);
  const targetMinority = Math.pow(10, targetDecimals);
  const targetFormated = formatAmount({
    value: Math.abs(item?.target?.amount || 0) * targetMinority,
    currency: 'RUR' as CurrencyCodes,
    minority: targetMinority,
    view: 'withZeroMinorPart',
  });

  const date = item ? parseISO(item.date) : new Date();

  return (
    <Portal id="money-transfer-modals">
      <FunctionalModalSidebar open={open} backLabel="История" onClose={onClose}>
        {!item ? null : (
          <div className={styles.body}>
            <Typography.Text
              view="primary-small"
              color="primary"
              tag="p"
              weight="bold"
            >
              {MONEY_TRANSFER_TYPE_TEXT[item.type] || ''}
            </Typography.Text>

            <Space fullWidth={true} direction="vertical">
              {Boolean(target?.accountNumber) && (
                <FormControlSummary label="Номер">
                  {target?.accountNumber}
                </FormControlSummary>
              )}
              {Boolean(target?.bankName) && (
                <FormControlSummary label="Банк">
                  {target?.bankName}
                </FormControlSummary>
              )}
              {Boolean(item.comment) && (
                <FormControlSummary label="Комментарий">
                  {item.comment}
                </FormControlSummary>
              )}
            </Space>

            {item.type === 'INTRA' && <Gap size="m" />}
            {item.type === 'INTRA' && (
              <Space fullWidth={true} direction="vertical">
                <FormControlSummary label="Дата">
                  {format(date, 'd MMMM yyyy, HH:mm:ss', { locale: ru })}
                </FormControlSummary>

                {Boolean(item.source) && (
                  <Row gutter={16}>
                    <Col width={6}>
                      <FormControlSummary label="Счёт списания">
                        {item.source?.accCode}
                        <span className={styles.secondary}>
                          {item.source?.placeName}
                        </span>
                      </FormControlSummary>
                    </Col>
                    <Col width={6}>
                      <FormControlSummary label="Сумма списания">
                        {sourceFormated.formattedWithCurrency}
                      </FormControlSummary>
                    </Col>
                  </Row>
                )}

                {Boolean(item.target) && (
                  <Row gutter={16}>
                    <Col width={6}>
                      <FormControlSummary label="Счёт зачисления">
                        {item.target?.accCode}
                        <span className={styles.secondary}>
                          {item.target?.placeName}
                        </span>
                      </FormControlSummary>
                    </Col>
                    <Col width={6}>
                      <FormControlSummary label="Сумма зачисления">
                        {targetFormated.formattedWithCurrency}
                      </FormControlSummary>
                    </Col>
                  </Row>
                )}
              </Space>
            )}
          </div>
        )}
      </FunctionalModalSidebar>
    </Portal>
  );
};
