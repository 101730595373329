import {
  SecuritiesDraftOpType,
  SecuritiesDraftOpTypeFilter,
} from '@terminal/core/lib/rest/lkSecurities';

import { DIRECTION_TYPES } from '../Direction';

export const DIRECTION_BY_DRAFT_OP_TYPE: Record<
  SecuritiesDraftOpType,
  DIRECTION_TYPES
> = {
  in: DIRECTION_TYPES.EXTRA_IN,
  out: DIRECTION_TYPES.EXTRA_OUT,
};

export const TRANSACTION_TYPES: SecuritiesDraftOpTypeFilter[] = [
  'all',
  'in',
  'out',
];

export const TRANSACTION_NAMES = {
  in: 'Зачисление',
  out: 'Списание',
  all: 'Все',
};

export enum DRAFT_ERRORS {
  Limit = 100,
  Changed = 200,
  Sent = 300,
}
