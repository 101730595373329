import cn from 'classnames';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { MINORITY } from '@terminal/core/constants/ui';
import { MarginCall } from '@terminal/core/types/marginCall';

import styles from './RequirementHint.module.css';

interface RequirementHintProps {
  requirements: {
    requirement: MarginCall | undefined;
    requirementType: 'immediateRequirements' | 'requirements' | undefined;
  };
}

export const RequirementHint = ({ requirements }: RequirementHintProps) => {
  const { requirementType, requirement } = requirements;

  if (!requirementType || !requirement) {
    return null;
  }

  const requirementValue = requirement[requirementType];

  if (requirementValue === null) {
    return null;
  }

  return (
    <Typography.Text
      view="secondary-small"
      className={cn(
        requirementType === 'immediateRequirements' && styles.immediateColor,
        requirementType === 'requirements' && styles.requirementColor
      )}
    >
      {requirementType === 'immediateRequirements'
        ? 'Срочное требование: внести'
        : 'Требование: внести'}
      &nbsp;
      <Amount.Pure
        value={requirementValue * MINORITY}
        minority={MINORITY}
        view="withZeroMinorPart"
        currency="RUB"
      />
    </Typography.Text>
  );
};
