import { Notification, NotificationType } from '../../types/ui';
import { CreateNotificationParams } from './types';

export function createNotification({
  text,
  type = NotificationType.SYSTEM,
  badge = 'attention',
  ...params
}: CreateNotificationParams): Notification {
  return {
    id: Date.now().toString(),
    type,
    badge,
    text: text || '',
    ...params,
  };
}
