import { ObjectGroup } from '../client/entities';

import { ObjectTypeItem } from '../../types/core';

/**
 * @returns Входящую стоимость (ВхСтоимость)
 * */
export function getBackPosCost(
  objectType: ObjectTypeItem,
  BackPos: number,
  PrevPrice: number,
  nominal: number,
  PriceStep: number,
  PriceStepCost: number
): number {
  switch (objectType?.idObjectGroup) {
    case ObjectGroup.Bonds: {
      return BackPos * ((PrevPrice * nominal) / 100);
    }
    case ObjectGroup.Futures: {
      return (BackPos * PrevPrice * PriceStepCost) / PriceStep;
    }
    case ObjectGroup.Stocks:
    case ObjectGroup.EquityParticipations:
    case ObjectGroup.Receipts:
    case ObjectGroup.Currency:
    case ObjectGroup.Metals:
    default:
      return BackPos * PrevPrice;
  }
}
