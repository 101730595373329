import UAParser from 'ua-parser-js';

import { OSType } from '../lib/client/entities';

export function detectOSType() {
  try {
    const uaParser = new UAParser();
    const os = uaParser.getOS().name || '';

    if (['iOS'].includes(os)) {
      return OSType.iOS;
    }

    if (['Android'].includes(os)) {
      return OSType.Android;
    }

    if (['Windows'].includes(os)) {
      return OSType.Windows;
    }

    if (['Mac OS'].includes(os)) {
      return OSType.MacOS;
    }

    if (['Linux', 'Arch', 'CentOS', 'Mint', 'Ubuntu'].includes(os)) {
      return OSType.Linux;
    }

    if (['Windows [Phone/Mobile]'].includes(os)) {
      return OSType.WindowsMobile;
    }
  } catch {}

  return OSType.Unknown;
}
