import React, { useMemo, useRef, useState } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronForwardMIcon } from '@alfalab/icons-glyph/ChevronForwardMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import {
  orderTypeAbbrMap,
  orderTypeMap,
} from '@terminal/core/constants/orders';
import { OrderType } from '@terminal/core/lib/client/entities';
import { getMinority } from '@terminal/core/lib/format';
import { plural } from '@terminal/core/lib/plural';
import { SlippageType, StopOrderType } from '@terminal/core/types/ui';

import { SettingsItem, SwitchSettings } from '../../../../features';
import { TradingCertificateLock } from '../../../../features/TradingCertificateLock';
import { DefaultQuantity } from '../useDefaultQuantity';

import styles from './useSettings.module.css';

interface Settings {
  autoCenter: boolean;
  hideForm: boolean;
  showSpread: boolean;
  showEmptyPrice: boolean;
  sendTrallingOrders: boolean;
  stopOrderType: StopOrderType;
  showYield: boolean;
  saveForm: (
    form: boolean,
    spread: boolean,
    empty: boolean,
    tralling: boolean,
    centering: boolean,
    stopOrderType: StopOrderType,
    showYield: boolean
  ) => void;
  setIsOpenLimitsModal: (state: boolean) => void;
  setIsOpenContextMenuModal: (state: boolean) => void;
  setIsOpenQuantityModal: (state: boolean) => void;
  setIsOpenSlippageModal: (state: boolean) => void;
  defaultQuantity?: DefaultQuantity;
  slippageType?: SlippageType;
  slippageValue?: number;
}

export const useSettings = ({
  autoCenter,
  hideForm,
  showSpread,
  showEmptyPrice,
  sendTrallingOrders,
  stopOrderType,
  showYield,
  saveForm,
  setIsOpenLimitsModal,
  setIsOpenContextMenuModal,
  setIsOpenQuantityModal,
  setIsOpenSlippageModal,
  defaultQuantity,
  slippageType = SlippageType.TICK,
  slippageValue,
}: Settings) => {
  const stopOrderRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [stopOrderMenuOpen, setStopOrderMenuOpen] = useState(false);

  const settingsGroups = useMemo(
    () => [
      {
        id: 'common',
        title: 'Общие настройки',
        items: [
          <SwitchSettings
            key="tradePlatform"
            checked={!hideForm}
            label="Торговая форма"
            reversed
            onChange={() =>
              saveForm(
                !hideForm,
                showSpread,
                showEmptyPrice,
                sendTrallingOrders,
                autoCenter,
                stopOrderType,
                showYield
              )
            }
          />,
          <SwitchSettings
            key="defaultTrailing"
            checked={sendTrallingOrders}
            label={
              <div>
                Трейлинг по умолчанию&nbsp;
                <Tooltip
                  targetClassName={styles.tooltipTarget}
                  contentClassName={styles.tooltipContent}
                  content="Режим, при котором для заявок типов Лимит (LMT) и Стоп (STP) по умолчанию будет применен трейлинг. Изменения отобразятся на торговых кнопках в форме торговли и в контекстном меню"
                  trigger="hover"
                  position="top-start"
                  offset={[-20, 16]}
                >
                  <InformationCircleLineSIcon
                    height={12}
                    width={12}
                    color="var(--color-dark-graphic-secondary)"
                  />
                </Tooltip>
              </div>
            }
            reversed
            onChange={() =>
              saveForm(
                hideForm,
                showSpread,
                showEmptyPrice,
                !sendTrallingOrders,
                autoCenter,
                stopOrderType,
                showYield
              )
            }
          />,
          <SettingsItem
            key="stop-order"
            componentRef={stopOrderRef}
            onMouseEnter={() => setStopOrderMenuOpen(true)}
            onMouseLeave={() => setStopOrderMenuOpen(false)}
          >
            Тип стоп-заявки
            <div className={styles.right}>
              {stopOrderType ? (
                <Typography.Text
                  view="secondary-large"
                  className={styles.suffix}
                >
                  {orderTypeAbbrMap[stopOrderType]}
                </Typography.Text>
              ) : null}
              <ChevronForwardMIcon
                height={16}
                width={16}
                color="var(--color-dark-graphic-secondary)"
              />
            </div>
            <Dropdown
              anchorElement={stopOrderRef.current}
              open={stopOrderMenuOpen}
              position="right-start"
              offset={[-4, 8]}
              popperClassName={styles.popover}
            >
              <Dropdown.Item
                rightIcon={
                  stopOrderType === OrderType.STL && (
                    <CheckmarkMIcon height={16} width={16} />
                  )
                }
                content={
                  <div className={styles.dropDownItemContent}>
                    {orderTypeMap.STL}&nbsp;
                    <Typography.Text
                      view="secondary-large"
                      className={styles.suffix}
                    >
                      {orderTypeAbbrMap[OrderType.STL]}
                    </Typography.Text>
                    <Tooltip
                      contentClassName={styles.tooltipContent}
                      content={
                        <>
                          <Typography.Text
                            weight="bold"
                            color="secondary"
                            view="secondary-large"
                            tag="div"
                          >
                            Стоп-лимит
                          </Typography.Text>
                          <Typography.Text
                            color="secondary"
                            view="secondary-small"
                          >
                            Лимитная заявка, которая будет выставлена по
                            стоп-цене + проскок (для покупок) или стоп-цене -
                            проскок (для продаж), если на рынке пройдет сделка
                            по указанной стоп-цене
                          </Typography.Text>
                        </>
                      }
                      trigger="hover"
                      position="top-start"
                      offset={[-20, 16]}
                    >
                      <InformationCircleLineSIcon
                        height={12}
                        width={12}
                        color="var(--color-dark-graphic-secondary)"
                      />
                    </Tooltip>
                  </div>
                }
                onClick={() =>
                  saveForm(
                    hideForm,
                    showSpread,
                    showEmptyPrice,
                    sendTrallingOrders,
                    autoCenter,
                    OrderType.STL,
                    showYield
                  )
                }
              />
              <Dropdown.Item
                rightIcon={
                  stopOrderType === OrderType.STP && (
                    <CheckmarkMIcon height={16} width={16} />
                  )
                }
                content={
                  <div className={styles.dropDownItemContent}>
                    {orderTypeMap.STP}&nbsp;
                    <Typography.Text
                      view="secondary-large"
                      className={styles.suffix}
                    >
                      {orderTypeAbbrMap[OrderType.STP]}
                    </Typography.Text>
                    <Tooltip
                      contentClassName={styles.tooltipContent}
                      content={
                        <>
                          <Typography.Text
                            weight="bold"
                            color="secondary"
                            view="secondary-large"
                          >
                            Стоп-маркет
                          </Typography.Text>
                          <Typography.Text
                            color="secondary"
                            view="secondary-small"
                          >
                            Заявка, которая будет исполнена по любой доступной в
                            моменте цене, если на рынке пройдет сделка по
                            указанной стоп-цене
                          </Typography.Text>
                        </>
                      }
                      trigger="hover"
                      position="top-start"
                      offset={[-20, 16]}
                    >
                      <InformationCircleLineSIcon
                        height={12}
                        width={12}
                        color="var(--color-dark-graphic-secondary)"
                      />
                    </Tooltip>
                  </div>
                }
                onClick={() =>
                  saveForm(
                    hideForm,
                    showSpread,
                    showEmptyPrice,
                    sendTrallingOrders,
                    autoCenter,
                    OrderType.STP,
                    showYield
                  )
                }
              />
            </Dropdown>
          </SettingsItem>,
        ],
      },
      {
        id: 'orderBookSettings',
        items: [
          <SwitchSettings
            key="showSpread"
            checked={showSpread}
            label="Показывать спред"
            reversed
            onChange={() =>
              saveForm(
                hideForm,
                !showSpread,
                showEmptyPrice,
                sendTrallingOrders,
                autoCenter,
                stopOrderType,
                showYield
              )
            }
          />,
          <SwitchSettings
            key="emptyPrice"
            checked={showEmptyPrice}
            label={
              <div>
                Разреженный стакан&nbsp;
                <Tooltip
                  targetClassName={styles.tooltipTarget}
                  contentClassName={styles.tooltipContent}
                  content="Отображение пустых ценовых уровней в стакане"
                  trigger="hover"
                  position="top-start"
                  offset={[-20, 16]}
                >
                  <InformationCircleLineSIcon
                    height={12}
                    width={12}
                    color="var(--color-dark-graphic-secondary)"
                  />
                </Tooltip>
              </div>
            }
            reversed
            onChange={() =>
              saveForm(
                hideForm,
                showSpread,
                !showEmptyPrice,
                sendTrallingOrders,
                autoCenter,
                stopOrderType,
                showYield
              )
            }
          />,
          <SwitchSettings
            key="showYield"
            checked={showYield}
            label={
              <div>
                Доходность облигаций&nbsp;
                <Tooltip
                  targetClassName={styles.tooltipTarget}
                  contentClassName={styles.tooltipContent}
                  content="Сколько процентов годовых вы можете заработать, если будете держать бумагу до погашения или оферты. "
                  trigger="hover"
                  position="top-start"
                  offset={[-20, 16]}
                >
                  <InformationCircleLineSIcon
                    height={12}
                    width={12}
                    color="var(--color-dark-graphic-secondary)"
                  />
                </Tooltip>
              </div>
            }
            reversed
            onChange={() =>
              saveForm(
                hideForm,
                showSpread,
                showEmptyPrice,
                sendTrallingOrders,
                autoCenter,
                stopOrderType,
                !showYield
              )
            }
          />,
        ],
      },
      {
        id: 'trading',
        items: [
          <TradingCertificateLock key="info" hideMode>
            <SettingsItem
              onClick={() => {
                setIsOpenLimitsModal(true);
                setIsOpen(false);
              }}
            >
              Информационная строка...
            </SettingsItem>
          </TradingCertificateLock>,
          <SettingsItem
            key="contextMenu"
            onClick={() => {
              setIsOpenContextMenuModal(true);
              setIsOpen(false);
            }}
          >
            Контекстное меню...
          </SettingsItem>,
        ],
      },
      {
        id: 'orderBookActions',
        items: [
          <SwitchSettings
            key="centering"
            label="Центрировать автоматически"
            checked={autoCenter}
            reversed
            onChange={() => {
              saveForm(
                hideForm,
                showSpread,
                showEmptyPrice,
                sendTrallingOrders,
                !autoCenter,
                stopOrderType,
                showYield
              );
            }}
          />,
        ],
      },
      {
        id: 'defaults',
        title: 'Значения по умолчанию',
        items: [
          <SettingsItem
            key="capacity"
            onClick={() => {
              setIsOpenQuantityModal(true);
              setIsOpen(false);
            }}
          >
            Объем
            {defaultQuantity ? (
              <Typography.Text view="secondary-large" className={styles.suffix}>
                <Amount.Pure
                  value={
                    defaultQuantity.quantity *
                    getMinority(defaultQuantity.quantity)
                  }
                  minority={getMinority(defaultQuantity.quantity)}
                  view="withZeroMinorPart"
                />
                &nbsp;
                {defaultQuantity.assetUnits}
              </Typography.Text>
            ) : null}
          </SettingsItem>,
          <SettingsItem
            key="slippage"
            onClick={() => {
              setIsOpenSlippageModal(true);
              setIsOpen(false);
            }}
          >
            Проскок
            {slippageValue ? (
              <Typography.Text view="secondary-large" className={styles.suffix}>
                <Amount.Pure
                  value={
                    slippageType === SlippageType.TICK
                      ? slippageValue
                      : slippageValue * 100
                  }
                  minority={slippageType === SlippageType.TICK ? 1 : 100}
                  view={
                    slippageType === SlippageType.TICK
                      ? 'default'
                      : 'withZeroMinorPart'
                  }
                />
                &nbsp;
                {slippageType === SlippageType.TICK
                  ? plural(['тик', 'тика', 'тиков'], slippageValue)
                  : '%'}
              </Typography.Text>
            ) : null}
          </SettingsItem>,
        ],
      },
    ],
    [
      hideForm,
      sendTrallingOrders,
      stopOrderType,
      stopOrderMenuOpen,
      showSpread,
      showEmptyPrice,
      showYield,
      autoCenter,
      defaultQuantity,
      slippageValue,
      slippageType,
      saveForm,
      setIsOpenLimitsModal,
      setIsOpenContextMenuModal,
      setIsOpenQuantityModal,
      setIsOpenSlippageModal,
    ]
  );

  return useMemo(
    () => ({
      open: isOpen,
      groups: settingsGroups,
      onShowRequest: () => setIsOpen(true),
      onHideRequest: () => setIsOpen(false),
    }),
    [isOpen, settingsGroups]
  );
};
