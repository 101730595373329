import qs from 'query-string';

import { LK_URI } from '@terminal/core/env';

export function getEducationThemeDocumentUrl(linkId: number) {
  return qs.stringifyUrl({
    url: LK_URI + '/api/education/pdf',
    query: { linkId },
  });
}
