import cn from 'classnames';
import React, { useState } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import styles from './AccordionItem.module.css';

export interface AccordionItemProps {
  header: React.ReactNode;
  content: React.ReactNode;
  className?: string;
}

export const AccordionItem = ({
  header,
  content,
  className,
}: AccordionItemProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={cn(styles.container, className, { [styles.open]: open })}>
      <div className={styles.header} onClick={() => setOpen((open) => !open)}>
        <Typography.Text
          view="primary-small"
          className={styles.title}
          tag="div"
        >
          {header}
        </Typography.Text>
        <ChevronDownMIcon className={styles.trigger} />
      </div>
      <div className={styles.content}>
        <Typography.Text view="primary-small" color="secondary">
          {content}
        </Typography.Text>
      </div>
    </div>
  );
};
