import { endOfDay, parseISO, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { CalendarInput } from '@alfalab/core-components/calendar-input';
import { Checkbox, CheckboxProps } from '@alfalab/core-components/checkbox';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Input } from '@alfalab/core-components/input';
import { Space } from '@alfalab/core-components/space';
import { Textarea } from '@alfalab/core-components/textarea';

import { SecuritiesManager } from '@terminal/core/lib/rest/lkSecurities';

import { COUNTERAGENT_TYPES, isOrgNameRequired } from '../../shared';
import {
  isDateValid,
  isFieldFilled,
  removeUnwantedChar,
} from '../../validation';

import { useValidation } from '../../hooks';

export interface RequisitesOrganizationProps {
  market: string;
  accountType: string;
  counteragentType?: COUNTERAGENT_TYPES | undefined;
  manager: SecuritiesManager | undefined;
  orgName: string;
  onOrgNameChange: (v: string) => void;
  orgOGRN: string;
  onOrgOGRNChange: (v: string) => void;
  orgWhen: string;
  onOrgWhenChange: (v: string) => void;
  orgWhom: string;
  onOrgWhomChange: (v: string) => void;
  requisitesCorrect: boolean;
  onRequisitesCorrectChange: (check: boolean) => void;
}

export const RequisitesOrganization = ({
  accountType,
  counteragentType,
  manager,
  orgName,
  onOrgNameChange,
  orgOGRN,
  onOrgOGRNChange,
  orgWhen,
  onOrgWhenChange,
  orgWhom,
  onOrgWhomChange,
  requisitesCorrect,
  onRequisitesCorrectChange,
}: RequisitesOrganizationProps) => {
  const values = useMemo(
    () => ({
      orgName,
      orgOGRN,
      orgWhen,
      orgWhom,
    }),
    [orgName, orgOGRN, orgWhen, orgWhom]
  );
  const validators = useMemo(
    () => ({
      orgName: isFieldFilled,
      orgOGRN: isFieldFilled,
      orgWhen: isDateValid,
      orgWhom: isFieldFilled,
    }),
    []
  );

  const { handleChange, handleBlur, touched, errors } = useValidation({
    values: values,
    transforms: {
      docNumber: (value) => removeUnwantedChar(value),
      docDate: (value) => removeUnwantedChar(value),
      orgWhom: (value) => removeUnwantedChar(value),
    },
    validators: validators,
    handlers: {
      orgName: onOrgNameChange,
      orgOGRN: onOrgOGRNChange,
      orgWhen: onOrgWhenChange,
      orgWhom: onOrgWhomChange,
    },
  });

  const handleRequisitesCorrect: CheckboxProps['onChange'] = (e, payload) => {
    onRequisitesCorrectChange(payload?.checked || false);
  };

  const minOrgWhen = startOfDay(parseISO('1900-01-01T00:00:00')).getTime();
  const maxOrgWhen = endOfDay(new Date()).getTime();

  return (
    <Space fullWidth direction="vertical">
      {isOrgNameRequired({ accountType, manager, counteragentType }) && (
        <Input
          label="Полное наименование"
          labelView="outer"
          block
          size="s"
          name="orgName"
          value={orgName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.orgName ? errors.orgName : ''}
        />
      )}
      <Row>
        <Col width={6}>
          <Input
            label="ОГРН №"
            labelView="outer"
            block
            size="s"
            name="orgOGRN"
            value={orgOGRN}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.orgOGRN ? errors.orgOGRN : ''}
          />
        </Col>
        <Col width={6}>
          <CalendarInput
            label="Когда выдан"
            labelView="outer"
            size="s"
            block
            minDate={minOrgWhen}
            maxDate={maxOrgWhen}
            name="orgWhen"
            value={orgWhen}
            onChange={(e, { value }) => onOrgWhenChange(value)}
            onInputChange={(e, { value }) => onOrgWhenChange(value)}
            onBlur={handleBlur}
            error={touched.orgWhen ? errors.orgWhen : ''}
          />
        </Col>
      </Row>
      <Textarea
        label="Кем выдан"
        labelView="outer"
        block
        size="s"
        name="orgWhom"
        value={orgWhom}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.orgWhom ? errors.orgWhom : ''}
        maxLength={200}
      />
      <Checkbox
        onChange={handleRequisitesCorrect}
        checked={requisitesCorrect}
        size="s"
        label="Наименование организации и ОГРН указаны верно"
        align="center"
      />
    </Space>
  );
};
