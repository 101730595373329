import { useField } from 'formik';
import {
  AmountInput as BaseAmountInput,
  AmountInputProps as BaseAmountInputProps,
} from 'formik-alfalab-core-components/AmountInput';
import React, { FC } from 'react';

import { usePriceInfo } from '@terminal/alfadirect/hooks';
import { Stepper } from '@terminal/common/components/Stepper';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { useStore } from '@terminal/core/store';
import { currencyObjectByIdFi } from '@terminal/core/store/selectors/core/currencyObjectByIdFi';
import { objectTypeByIdFi } from '@terminal/core/store/selectors/core/objectTypeByIdFi';

export type AmountInputProps = Omit<BaseAmountInputProps, 'name'> & {
  idFi: number;
};

const NAME = 'targetPrice';

export const AmountInput: FC<AmountInputProps> = (props) => {
  const { className, idFi, ...restProps } = props;
  const [{ value }, , { setValue }] = useField(NAME);
  const currencyObject = useStore(currencyObjectByIdFi)(idFi);
  const objectType = useStore(objectTypeByIdFi)(idFi);
  const { minority, priceStep } = usePriceInfo(idFi);

  return (
    <div className={className}>
      <BaseAmountInput
        name={NAME}
        label="Значение"
        labelView="outer"
        size="s"
        currency={getSymbolToShow(
          currencyObject?.symbolObject,
          objectType?.idObjectGroup
        )}
        suffix={getSymbolToShow(
          currencyObject?.symbolObject,
          objectType?.idObjectGroup
        )}
        minority={minority}
        rightAddons={
          <Stepper
            onAdd={() => setValue(value + priceStep * minority)}
            onSub={() => setValue(value - priceStep * minority)}
          />
        }
        block
        {...restProps}
      />
    </div>
  );
};
