import cn from 'classnames';
import { FC } from 'react';
import { AddLargeMIcon } from '@alfalab/icons-glyph/AddLargeMIcon';
import { MinusMIcon } from '@alfalab/icons-glyph/MinusMIcon';

import styles from './Stepper.module.css';

export interface StepperProps {
  /**
   * Функция, вызываемая по нажатию на кнопку "—"
   * */
  onSub?: () => void;
  /**
   * Функция, вызываемая по нажатию на кнопку "+"
   * */
  onAdd?: () => void;
  /**
   * Размер
   * - `s` — используется в desktop-версии
   * - `m` — используется в mobile-версии
   * @default s
   * */
  size?: 's' | 'm';
}

export const Stepper: FC<StepperProps> = (props) => {
  const { onSub, onAdd, size = 's' } = props;
  const isSizeS = size === 's';
  const isSizeM = size === 'm';
  const rootClassName = cn(styles.root, {
    [styles.root_size_s]: isSizeS,
    [styles.root_size_m]: isSizeM,
  });
  const iconClassName = cn(styles.icon, {
    [styles.icon_size_s]: isSizeS,
    [styles.icon_size_m]: isSizeM,
  });
  const dividerClassName = cn(styles.divider, {
    [styles.divider_small]: isSizeS,
  });

  return (
    <div className={rootClassName}>
      <MinusMIcon className={iconClassName} onClick={onSub} />
      <div className={dividerClassName} />
      <AddLargeMIcon className={iconClassName} onClick={onAdd} />
    </div>
  );
};
