import { useCallback, useMemo } from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';

import { wasShownReopenWorkspaceWindowsQuestionSessionKey } from '@terminal/core/constants/workspaces';
import { isNotUndefined } from '@terminal/core/lib/isNotUndefined';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';

import styles from './SingletonModalProvider.module.css';

export const ReopenWorkspaceWindowsQuestion = ({
  setIsShowReopenWorkspace,
}: {
  setIsShowReopenWorkspace: (isVisible: boolean) => void;
}) => {
  const { reopenWorkspaceWindows, resetWorkspaceWindows } = useStore(
    (state) => ({
      reopenWorkspaceWindows: state.reopenWorkspaceWindows,
      resetWorkspaceWindows: state.resetWorkspaceWindows,
    }),
    shallow
  );

  const { lastAutoSavedConfig } = useStore(activeConfigurationSelector);

  const windowsNames = useMemo(() => {
    const { layoutNames, layoutWindows } = lastAutoSavedConfig;

    if (!layoutNames || !layoutWindows) {
      return [];
    }

    return layoutWindows
      .map((isWindow, idx) => (isWindow ? layoutNames[idx] : undefined))
      .filter(isNotUndefined);
  }, [lastAutoSavedConfig]);

  const handleClose = useCallback(() => {
    resetWorkspaceWindows();
    setIsShowReopenWorkspace(false);
    window.sessionStorage.setItem(
      wasShownReopenWorkspaceWindowsQuestionSessionKey,
      'true'
    );
  }, [resetWorkspaceWindows, setIsShowReopenWorkspace]);

  const handleOpen = useCallback(() => {
    reopenWorkspaceWindows();
    setIsShowReopenWorkspace(false);
    window.sessionStorage.setItem(
      wasShownReopenWorkspaceWindowsQuestionSessionKey,
      'true'
    );
  }, [reopenWorkspaceWindows, setIsShowReopenWorkspace]);

  return (
    <BaseModal
      open={true}
      // onClose={onClose}
      wrapperClassName={styles.modalWrapperClassName}
      contentClassName={styles.contentClassName}
      className={styles.baseModalClassName}
    >
      <Typography.Title view="small" tag="div" className={styles.title}>
        Вы хотите заново открыть рабочие столы в отдельных окнах?
      </Typography.Title>

      <ul className={styles.list}>
        {windowsNames.map((name) => (
          <li>
            <Typography.Text view="secondary-medium" tag="div">
              {name}
            </Typography.Text>
          </li>
        ))}
      </ul>

      <div className={styles.buttonsRow}>
        <Button view="primary" size="xs" onClick={handleOpen} block>
          Переоткрыть
        </Button>

        <Button view="secondary" size="xs" onClick={handleClose} block>
          Отменить
        </Button>
      </div>
    </BaseModal>
  );
};
