import { AccessLevel, AttorneyStatus, IdentityDocumentType } from '../../model';

export const ACCESS_LEVELS_MAP: Record<AccessLevel, string> = {
  [AccessLevel.Controller]: 'Контролер',
  [AccessLevel.Trader]: 'Трейдер',
  [AccessLevel.SeniorTrader]: 'Старший трейдер',
};
export const ATTORNEY_STATUSES_MAP: Record<AttorneyStatus, string> = {
  [AttorneyStatus.ACTIVE]: 'Активные',
  [AttorneyStatus.ARCHIVE]: 'Архив',
};
export const IDENTITY_DOCUMENT_TYPE_MAP: Record<IdentityDocumentType, string> =
  {
    [IdentityDocumentType.RussianPassport]: 'Паспорт гражданина РФ',
    [IdentityDocumentType.ForeignPassport]: 'Иностранный паспорт',
    [IdentityDocumentType.DiplomaticPassport]: 'Дип. паспорт гражданина РФ',
    [IdentityDocumentType.USSRPassport]: 'Паспорт гражданина СССР',
    [IdentityDocumentType.InternationalPassport]: 'Загранпаспорт гражданина РФ',
  };
