import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { currenciesFI } from '../../constants/FIModal';
import { getClientOrders } from '../../lib/domain/getClientOrders';
import { makeOrderKey } from '../../lib/domain/makeOrderKey';
import {
  quoteLastSelector,
  quotePositionSelector,
} from '../../lib/domain/quoteSelector';
import { useIdFiBalances } from './useIdFiBalances';
import { usePositionNew } from './usePositionNew';

import { OrderItem } from '../../types/order';
import { SubAccountPositionItem } from '../../types/subAccountPosition';

export const useComputedPositions = (
  selectedSubAccounts: string[],
  positions: SubAccountPositionItem[]
) => {
  const {
    accounts: clientAccounts,
    subAccountRazdels,
    useQuotes,
    useFinInfoExt,
    useOrders,
  } = useAlfaDirectContext();
  const orders = useOrders();

  const currenciesQuotes = useQuotes(currenciesFI, {
    selector: quoteLastSelector,
  });
  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);

  const positionsToIdFiBalances = useIdFiBalances(positions);
  const fi = useMemo(
    () => Array.from(new Set(positionsToIdFiBalances.values())),
    [positionsToIdFiBalances]
  );
  const finInfoExts = useFinInfoExt(fi);
  const quotes = useQuotes(fi, {
    selector: quotePositionSelector,
  });

  const filteredOrders = getClientOrders(orders, clientAccounts);

  const ordersMap = useMemo(() => {
    const map: Record<string, OrderItem[]> = {};

    filteredOrders.forEach((order) => {
      const key = makeOrderKey(order, order.buySell);

      if (!map[key]) {
        map[key] = [];
      }

      map[key].push(order);
    });

    return map;
  }, [filteredOrders]);

  return usePositionNew(
    {
      positions,
      finInfoExts,
      subAccountRazdels,
      ordersMap,
      currenciesQuotes,
      currenciesFinInfoExts,
      quotes,
      positionsToIdFiBalances,
    },
    { selectedSubAccounts }
  );
};
