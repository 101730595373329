import { createBeginnerConfig } from './beginner_base';

export const beginnerSmall = () => {
  return createBeginnerConfig({
    tradeLeftWeight: 22,
    tradeRightWeight: 15,
    newsLeftWeight: 22,
    newsRightWeight: 22,
  });
};
