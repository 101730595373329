import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { mapThemeQuestion } from '../lib/mapThemeQuestion';

import { ThemeQuestionDTO } from './types/ThemeQuestionDTO';
import { ThemeQuestionsParams } from './types/ThemeQuestionsParams';

export async function getInstructionThemeQuestions(data: ThemeQuestionsParams) {
  const res = await fetchLkResult<ThemeQuestionDTO[] | LKResult>(
    qs.stringifyUrl({
      url: '/api/instructions/questions',
      query: { ...data },
    })
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res.map(mapThemeQuestion);
}
