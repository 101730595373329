import { useState } from 'react';

export const useRefCallback = <T extends (...args: any[]) => any>(
  callback: T
): T => {
  const [callbackState] = useState({
    current: callback,
    target: ((...args) => callbackState.current(...args)) as T,
  });

  callbackState.current = callback;

  return callbackState.target;
};
