import { useEffect, useState } from 'react';
import { AmountInputProps } from '@alfalab/core-components/amount-input';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Typography } from '@alfalab/core-components/typography';
import { RepeatMIcon } from '@alfalab/icons-glyph/RepeatMIcon';
import { TransferOfDebtMIcon } from '@alfalab/icons-glyph/TransferOfDebtMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import useNotification from '@terminal/core/hooks/useNotification';
import { trackRefillFromCard } from '@terminal/core/lib/analytics';
import { countDecimals } from '@terminal/core/lib/format';
import { BankCardTransactionExecuteResult } from '@terminal/core/lib/rest/lkBankCard';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { CardTransaction } from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

import { useCreateCardTransaction } from '../../../../entities/TransferGoinvest';
import { Result } from '../../../../shared';
import { ThreeDS } from '../../ThreeDS';
import { MoneyInCardsParameters } from './MoneyInCardsParameters';

import styles from './MoneyInCards.module.css';

interface MoneyInCardsProps {
  mobile?: boolean;
  treaty: Treaty | null;
  defaultTopUpSum?: number;
}

const MAX_DAILY_AMOUNT = 200_000;

export const MoneyInCards = ({
  treaty,
  mobile,
  defaultTopUpSum,
}: MoneyInCardsProps) => {
  const addNotification = useNotification();

  const [amount, setAmount] = useState<number | null>(defaultTopUpSum || 0);
  const [amountError, setAmountError] = useState(false);
  const [transaction, setTransaction] = useState<CardTransaction | null>(null);

  const {
    mutateAsync: createTransaction,
    isLoading,
    isError,
  } = useCreateCardTransaction();

  const onAmountChange: AmountInputProps['onChange'] = (e, { value }) => {
    if (value !== amount) {
      setAmount(value);
      setAmountError(false);
    }
  };

  useEffect(() => {
    if (amount && amount / MINORITY > MAX_DAILY_AMOUNT) {
      setAmountError(true);
    }
  }, [amount]);

  const createCardTransaction = async () => {
    if (treaty?.treaty && amount) {
      const resAmount = (amount || 0) / MINORITY;
      const result = await createTransaction({
        treaty: treaty.treaty,
        amount: resAmount,
        returnUrl: `https://${window.location.host}/money-in-result/complete`,
      });

      trackRefillFromCard(amount / MINORITY);
      setTransaction(result as CardTransaction);
    }
  };

  const cancelTransaction = () => {
    setTransaction(null);
  };

  const onTransactionComplete = (res: BankCardTransactionExecuteResult) => {
    const resAmount = res?.amount || (amount || 0) / MINORITY;
    const resAmountDecimals = countDecimals(resAmount);
    const resAmountMinority = Math.pow(10, resAmountDecimals);
    const transferedAmount = formatAmount({
      value: (resAmount || 0) * resAmountMinority,
      currency: (res?.currency || 'RUR') as CurrencyCodes,
      minority: resAmountMinority,
      view: 'withZeroMinorPart',
    });

    if (res.code === 0) {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'positive',
        title: 'Брокерский счёт пополнен',
        text: `Перевод на сумму ${transferedAmount.formattedWithCurrency} успешно выполнен`,
      });
    } else {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'attention',
        title: `Перевод на сумму ${transferedAmount.formattedWithCurrency} не выполнен`,
        text: 'Повторите перевод позже',
      });
    }

    setTransaction(null);
  };

  if (isError) {
    return (
      <Result title="Зачисления временно недоступны">
        <ButtonDesktop
          size="xxs"
          leftAddons={<RepeatMIcon width={18} height={18} />}
          onClick={createCardTransaction}
        >
          Обновить
        </ButtonDesktop>
      </Result>
    );
  }

  const props = {
    amount,
    amountMinority: MINORITY,
    onAmountChange,
    amountError,
  };

  return (
    <>
      <MoneyInCardsParameters {...props} />

      {transaction && (
        <ThreeDS
          transaction={transaction!}
          onCancel={cancelTransaction}
          onComplete={onTransactionComplete}
        />
      )}
      <div className={styles.footer}>
        <Row gutter={16}>
          <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
            <ButtonDesktop
              block
              size={mobile ? 'xs' : 's'}
              disabled={!amount || amountError}
              onClick={createCardTransaction}
              leftAddons={<TransferOfDebtMIcon />}
              loading={isLoading}
            >
              Пополнить
            </ButtonDesktop>
            <Typography.Text
              view="secondary-small"
              color="secondary"
              tag="p"
              defaultMargins={false}
              style={{ marginTop: 'var(--gap-2xs)' }}
            >
              Продолжая, я подтверждаю, что пополняю свой брокерский счет со
              своей банковской карты
            </Typography.Text>
          </Col>
        </Row>
      </div>
    </>
  );
};
