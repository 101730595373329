import { createBeginnerConfig } from './beginner_base';

export const beginnerMedium = () => {
  return createBeginnerConfig({
    tradeLeftWeight: 19,
    tradeRightWeight: 13,
    newsLeftWeight: 19,
    newsRightWeight: 19,
  });
};
