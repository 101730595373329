import { useReducer, useState } from 'react';

import { useConfirmation } from '@terminal/common/components/Confirmation';
import { USE_PASSPORT_AUTH } from '@terminal/core/env';
import { shallow, useStore } from '@terminal/core/store';
import { AppState } from '@terminal/core/types/app';
import { useLoginPinCodeStore } from '@terminal/lk-core';

import { getPassportUrl } from '../../../entities/Login';
import { PIN_CODE_CREATE_SCREEN, PIN_CODE_ENTER_SCREEN } from '../lib/const';

export const useLoginLK2faNew = () => {
  const [authPending, setAuthPending] = useState(false);
  const [triesCount, decreaseTriesCount] = useReducer((c) => c - 1, 2);

  const [setPinCode, resetPinCode, verifyPinCode] = useLoginPinCodeStore(
    (store) => [store.setPinCode, store.resetPinCode, store.verifyPinCode]
  );

  const [
    appState,
    setAppState,
    loginLK2faNew,
    lkAuthError,
    setLkAuthError,
    clearLkAuthError,
    loginComplete,
    logout,
  ] = useStore(
    (store) => [
      store.appState,
      store.setAppState,
      store.loginLK2faNew,
      store.lkAuthError,
      store.setLkAuthError,
      store.clearLkAuthError,
      store.loginComplete,
      store.logout,
    ],
    shallow
  );

  const confirmationScreen =
    appState === AppState.AUTHORIZING_CREATE_PIN_CODE
      ? PIN_CODE_CREATE_SCREEN
      : PIN_CODE_ENTER_SCREEN;

  const { confirmationState, setConfirmationState, setConfirmationScreen } =
    useConfirmation();

  const createPinCode = async (code: string) => {
    await setPinCode(code);
    (USE_PASSPORT_AUTH ? loginComplete : loginLK2faNew)?.();
  };

  const validate2faCode = async (code: string) => {
    decreaseTriesCount();
    setAuthPending(true);

    try {
      if (await verifyPinCode(code)) {
        (USE_PASSPORT_AUTH ? loginComplete : loginLK2faNew)?.();
      } else {
        throw new Error('Неверный пин-код');
      }
    } catch (err) {
      if (!triesCount) {
        setAppState(AppState.NOT_READY);
        resetPinCode();
        logout(true);
      } else {
        setLkAuthError((err as Error).message);
      }
    } finally {
      setAuthPending(false);
    }
  };

  const onForgot = () => {
    resetPinCode();
    setAppState(AppState.LOADING_DICTIONARY);
    logout(true, getPassportUrl('/'));
  };

  const onSkipPinCode = () => {
    (USE_PASSPORT_AUTH ? loginComplete : loginLK2faNew)?.();
  };

  return {
    authPending,
    confirmationState,
    confirmationScreen,
    setConfirmationState,
    setConfirmationScreen,
    error: lkAuthError,
    clearError: clearLkAuthError,
    createPinCode,
    validate2faCode,
    onForgot,
    onSkipPinCode,
  };
};
