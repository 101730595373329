import cn from 'classnames';
import { Badge } from '@alfalab/core-components/badge';

import { CorporateActionHeader } from '@terminal/core/lib/rest/lkCorporateActions';

import styles from './MessageItem.module.css';

interface MessageItemProps {
  item: CorporateActionHeader;
  odd?: boolean;
  onClick?: (item: CorporateActionHeader) => void;
}

export const MessageItem = ({ item, odd, onClick }: MessageItemProps) => {
  return (
    <div
      className={cn(styles.container, {
        [styles.odd]: !!odd,
        [styles.even]: !odd,
      })}
      onClick={() => onClick && onClick(item)}
    >
      <div className={styles.status}>
        {!item.isRead && (
          <Badge view="count" visibleIconOutline color="accent" />
        )}
      </div>
      <div className={styles.description}>
        <div className={styles.title}>{item.title}</div>
      </div>
    </div>
  );
};
