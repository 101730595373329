import { useCallback, useEffect, useMemo, useState } from 'react';

import { wasShownReopenWorkspaceWindowsQuestionSessionKey } from '@terminal/core/constants/workspaces';
import { LK_CERTIFICATES } from '@terminal/core/env';
import { useCertificateEnrollFlow } from '@terminal/core/hooks/useCertificate';
import { shallow, useStore } from '@terminal/core/store';
import { SingletonModalType } from '@terminal/core/store/slices/certificatesSlice';
import { CertificateSMSConfirmModal } from '@terminal/lk/legacy/components/CertificateSMSConfirmModal';

// eslint-disable-next-line
import { CodeConfirmationModalOld } from '../../../../legacy/widgets/certificates/CodeConfirmationModalOld';
import { NeedCertificateModal } from './NeedCertificateModal';
import { ReopenWorkspaceWindowsQuestion } from './ReopenWorkspaceWindowsQuestion';

export const SingletonModalProvider = () => {
  useCertificateEnrollFlow();

  const {
    certificateId,
    modalVisible,
    hideCertificateSMSWindow,
    singletonModalType,
    earlyOpenWindowsTabsIds,
  } = useStore(
    (state) => ({
      certificateId: state.certificateEnrollId,
      modalVisible: state.certificateSMSWindowProps.isVisible,
      hideCertificateSMSWindow: state.hideCertificateSMSWindow,
      singletonModalType: state.singletonModalType,
      earlyOpenWindowsTabsIds: state.earlyOpenWindowsTabsIds,
    }),
    shallow
  );

  const [isShowReopenWorkspace, setIsShowReopenWorkspace] = useState(false);

  // Модальное окно с вопросом: Нужно ли переоткрывать окна рабочих столов, которые были открыты в прошлый раз
  useEffect(() => {
    const wasOpen =
      window.sessionStorage.getItem(
        wasShownReopenWorkspaceWindowsQuestionSessionKey
      ) === 'true';

    const child_window_tab_id = window.sessionStorage.getItem(
      'child_window_tab_id'
    );
    const isParentWindow =
      child_window_tab_id === null || child_window_tab_id === undefined;

    if (isParentWindow && !wasOpen && earlyOpenWindowsTabsIds.length > 0) {
      setIsShowReopenWorkspace(true);
    }
  }, [earlyOpenWindowsTabsIds, setIsShowReopenWorkspace]);

  const modal = useMemo(() => {
    switch (singletonModalType) {
      case SingletonModalType.NeedCertificate: {
        return <NeedCertificateModal />;
      }

      // todo: пока не понятно где должна быть реализована эта часть интерфейса
      //  в веб терминале или в ЛК
      // case SingletonModalType.NeedConfirmEmail: {
      //   return <NeedConfirmEmail />;
      // }
      // case SingletonModalType.NeedConfirmEmailExpiredLink: {
      //   return <NeedConfirmEmailExpiredLink />;
      // }
      // case SingletonModalType.EmailSuccessConfirmed: {
      //   return <EmailSuccessConfirmed />;
      // }
      default: {
        return null;
      }
    }
  }, [singletonModalType]);

  const onModalCloseRequest = useCallback(() => {
    hideCertificateSMSWindow();
  }, [hideCertificateSMSWindow]);

  const confirmModal = useMemo(() => {
    if (LK_CERTIFICATES) {
      return <CertificateSMSConfirmModal />;
    } else {
      return (
        <CodeConfirmationModalOld
          isOpened={modalVisible}
          onCloseRequest={onModalCloseRequest}
          certificateId={certificateId}
        />
      );
    }
  }, [modalVisible, onModalCloseRequest, certificateId]);

  return (
    <>
      {modal}
      {confirmModal}
      {earlyOpenWindowsTabsIds.length > 0 && isShowReopenWorkspace && (
        <ReopenWorkspaceWindowsQuestion
          setIsShowReopenWorkspace={setIsShowReopenWorkspace}
        />
      )}
    </>
  );
};
