import React, { HTMLAttributes, VFC } from 'react';

import { OrderItem } from '@terminal/core/types/order';

import { OrdersCountLabel } from './OrdersCountLabel';

export type BuySellCellProps = HTMLAttributes<HTMLDivElement> & {
  idFI: number;
  price: number;
  orders: OrderItem[];
  desktopVersion: boolean;
  setSelectedOrdersIds?: (orders: string[]) => void;
};

export const BuySellCell: VFC<BuySellCellProps> = (props) => {
  const {
    idFI,
    price,
    orders,
    desktopVersion,
    setSelectedOrdersIds,
    ...restProps
  } = props;

  return (
    <div {...restProps}>
      <OrdersCountLabel
        idFI={idFI}
        orders={orders}
        desktopVersion={desktopVersion}
        price={price}
        setSelectedOrdersIds={setSelectedOrdersIds}
      />
    </div>
  );
};
