import qs from 'query-string';

import { LK_URI, PASSPORT_CLIENT_ID } from '@terminal/core/env';

import { getAppRedirectUri } from '../../../shared/lib/getAppRedirectUri';

export const getLKPasswrodResetUrl = (phone: string, redirect: string) => {
  return qs.stringifyUrl(
    {
      url: `${LK_URI}/restore-password`,
      query: {
        mt: 'mt',
        crossauth_client_id: PASSPORT_CLIENT_ID,
        phone: phone,
        redirect_uri: getAppRedirectUri(redirect),
      },
    },
    {
      encode: false,
    }
  );
};
