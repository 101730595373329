import { useMemo } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { useRemoteConfig } from '@terminal/common/entities/RemoteConfig';
import {
  ServiceNotifications,
  TECH_MESSAGE_CODE,
} from '@terminal/common/entities/ServiceNotifications';
import { isFeatureHidden, useFeatureSettings } from '@terminal/lk/shared';
import { AddAccountTypeGI } from '@terminal/lk-core';
import { AddAccountVariant } from '@terminal/lk-desktop-common';

import styles from './AddAccountChoose.module.css';

interface AddAccountChooseProps {
  onChoose: (type: AddAccountTypeGI) => void;
}

export const AddAccountChoose = ({ onChoose }: AddAccountChooseProps) => {
  const { data: featureSettings = [] } = useFeatureSettings();

  const canOpenForeignAccount = useMemo(() => {
    return (
      featureSettings.length > 0 &&
      !isFeatureHidden('foreign-account-opening', featureSettings)
    );
  }, [featureSettings]);

  return (
    <div>
      <ServiceNotifications
        variant="banner"
        containerClassName={styles.nofiticationsContainer}
        code={TECH_MESSAGE_CODE.OPEN_ACCOUNT}
        useRemoteConfig={useRemoteConfig}
      />
      <Gap size="xl" />
      <Typography.Text
        view="primary-small"
        tag="p"
        weight="bold"
        color="primary"
        defaultMargins={false}
      >
        Выберите тип счёта для открытия в Go Invest
      </Typography.Text>
      <Gap size="xl" />
      <div className={styles.items}>
        <AddAccountVariant
          className={styles.item}
          title="Брокерский счёт"
          text="Для торговли на фондовом и валютном рынках"
          onSelect={() => onChoose(null)}
        />
        <AddAccountVariant
          className={styles.item}
          title="Счёт РЕПО"
          text="Для совершения особых видов сделок"
          tooltip="РЕПО — соглашение о выкупе. Это двойная сделка — продажа актива с обязательством обратного выкупа его через определенное время."
          onSelect={() => onChoose('REPO')}
        />
        {canOpenForeignAccount && (
          <AddAccountVariant
            className={styles.item}
            title="Иностранный рынок"
            text="Для торговли на иностранных рынках"
            onSelect={() => onChoose('FOREING_MARKET')}
          />
        )}
      </div>
    </div>
  );
};
