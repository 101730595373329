import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { AddAccountConstraintsAI } from '../model/types/AddAccountConstraintsAI';

export const getAddAccountsConstraintsAI = async () => {
  const res = await fetchLkResult<AddAccountConstraintsAI | LKResult>(
    `/api/account/can-open-account`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};
