import { FC } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { BestBidBestAskAndSpread } from '@terminal/common/components/BestBidBestAskAndSpread';
import { PricesRange } from '@terminal/common/components/PricesRange';
import { useOrderBookService } from '@terminal/core/hooks/useOrderBookService';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { getMinority, getRoundedBigSum } from '@terminal/core/lib/format';
import { ObjectTypeCategory } from '@terminal/core/types/objectTypes';

import { CouponsInfoBlock } from '../../features';
import { InstrumentDataRowGrid } from '../../ui';

import { useInstrumentInfo } from '../../hooks/useInstrumentInfo';
import { useQuote } from '../../hooks/useQuote';

import classes from './IndicatorsTab.module.css';

export const IndicatorsTab: FC<{ idFi: number }> = ({ idFi }) => {
  const { priceDecimals, fullFI, objectTypeCategory, finInfoExt } =
    useInstrumentInfo(idFi);

  const { quoteData } = useQuote(idFi);
  const { bestBid, bestAsk, spread } = useOrderBookService(idFi, priceDecimals);

  const isBond = objectTypeCategory === ObjectTypeCategory.Bond;
  const [roundedVolume, volumePostfix] = getRoundedBigSum(
    quoteData?.valToday || 0n
  );

  if (!finInfoExt || !quoteData || !fullFI || !priceDecimals) {
    return null;
  }

  const isValidCurrencyCode = /\b[A-Z]{3}\b/i.test(fullFI.currencyCode);

  return (
    <div>
      <BestBidBestAskAndSpread
        bestBid={bestBid?.Price}
        bidQty={bestBid?.BuyQty}
        bestAsk={bestAsk?.Price}
        askQty={bestAsk?.SellQty}
        spread={spread}
        priceDecimals={priceDecimals}
        currencyCode={getSymbolToShow(
          fullFI.currencyCode,
          fullFI.idObjectGroup
        )}
        isBond={isBond}
      />
      <Typography.TitleMobile
        view="xsmall"
        color="secondary"
        tag="div"
        weight="bold"
        className={classes.rangeTitle}
      >
        Дневной диапазон
      </Typography.TitleMobile>
      <PricesRange
        open={quoteData?.open || 0}
        last={quoteData?.last || 0}
        min={quoteData?.low || 0}
        max={quoteData?.high || 0}
        currencyCode={getSymbolToShow(
          fullFI.currencyCode,
          fullFI.idObjectGroup
        )}
        isBond={isBond}
      />
      <Typography.TitleMobile
        view="xsmall"
        color="secondary"
        tag="div"
        weight="bold"
        className={classes.keyIndicatorsTitle}
      >
        Ключевые показатели
      </Typography.TitleMobile>
      <InstrumentDataRowGrid
        titleLeft="Пред. закрытие"
        valueLeft={
          <>
            <Amount.Pure
              value={(finInfoExt?.prevQuote || 0) * priceDecimals}
              minority={priceDecimals}
              view="withZeroMinorPart"
              currency={getSymbolToShow(
                fullFI.currencyCode,
                fullFI.idObjectGroup
              )}
              dataTestId="SummaryInfoPrevQuote"
            />
            {isBond && ' %'}
          </>
        }
        titleRight="Открытие"
        valueRight={
          <>
            <Amount.Pure
              value={(quoteData?.open || 0) * getMinority(quoteData?.open || 0)}
              minority={getMinority(quoteData?.open || 0)}
              view="withZeroMinorPart"
              currency={
                isBond
                  ? undefined
                  : getSymbolToShow(fullFI.currencyCode, fullFI.idObjectGroup)
              }
            />
            {isBond && ' %'}
          </>
        }
      />
      <InstrumentDataRowGrid
        titleLeft="Минимум"
        valueLeft={
          <>
            <Amount.Pure
              value={(quoteData?.low || 0) * getMinority(quoteData?.low || 0)}
              minority={getMinority(quoteData?.low || 0)}
              view="withZeroMinorPart"
              currency={
                isBond
                  ? undefined
                  : getSymbolToShow(fullFI.currencyCode, fullFI.idObjectGroup)
              }
            />
            {isBond && ' %'}
          </>
        }
        titleRight="Максимум"
        valueRight={
          <>
            <Amount.Pure
              value={(quoteData?.high || 0) * getMinority(quoteData?.high || 0)}
              minority={getMinority(quoteData?.high || 0)}
              view="withZeroMinorPart"
              currency={
                isBond
                  ? undefined
                  : getSymbolToShow(fullFI.currencyCode, fullFI.idObjectGroup)
              }
            />
            {isBond && ' %'}
          </>
        }
      />
      <InstrumentDataRowGrid
        blockEnd
        titleLeft="Оборот"
        valueLeft={
          <>
            <Amount.Pure
              value={roundedVolume * (volumePostfix ? 100 : 1)}
              minority={volumePostfix ? 100 : 1}
              view="withZeroMinorPart"
              currency={isValidCurrencyCode ? fullFI?.currencyCode : undefined}
              dataTestId="SummaryInfoVolume"
            />
            {volumePostfix && ` ${volumePostfix}`}
          </>
        }
      />
      {isBond && (
        <CouponsInfoBlock
          fullFI={fullFI}
          finInfoExt={finInfoExt}
          quote={quoteData}
        />
      )}
    </div>
  );
};
