import { useEffect, useMemo, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { Checkbox, CheckboxProps } from '@alfalab/core-components/checkbox';
import { Divider } from '@alfalab/core-components/divider';
import { Input, InputProps } from '@alfalab/core-components/input';
import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { SignMIcon } from '@alfalab/icons-glyph/SignMIcon';

import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { signFatcaSms } from '@terminal/core/lib/rest/lkDocuments';

import { FormatedText } from '../../../../shared';
import {
  SmsSignDialog,
  useSignSmsDialog,
} from '../../../components/SmsSignDialog';
import { EXTERNAL_LINKS } from '../../../const/externalUrls';
import { validateInn } from './helpers';

import { useFatcaText } from '../../hooks';

import styles from './FatcaForm.module.css';

interface FatcaFormProps {
  treaty: Treaty | null;
  isOpen: boolean;
  onComplete: () => void;
}

export const FatcaForm = ({ treaty, isOpen, onComplete }: FatcaFormProps) => {
  const [inn, setInn] = useState('');
  const [agree, setAgree] = useState(false);

  const { data: fatcaText, isFetching: fatcaTextPending } = useFatcaText(
    treaty?.treaty,
    isOpen
  );

  const {
    create: requestSignFatca,
    createPending,
    isCreateError,
    smsSending,
    isSendSmsError,
    resendSms,
    openDialog: openSignDialog,
    checkSmsCode,
    isCheckError,
    isSuccess,
    error,
    cancel: cancelAccountRegistration,
    assignment,
  } = useSignSmsDialog({
    initAction: signFatcaSms,
    successMsg: {
      title: 'FATCA (W-8BEN) принята к рассмотрению',
      text: 'Срок — до 5 рабочих дней',
    },
    errorMsg: {
      title: 'Ошибка подписи FATCA (W-8BEN)',
      text: 'Попробуйте ещё раз или напишите нам в чат',
    },
  });

  useEffect(() => {
    if (fatcaText && fatcaText.inn) {
      setInn(fatcaText.inn);
    }
  }, [fatcaText]);

  const handleInnChange: InputProps['onChange'] = (e, { value }) => {
    const inn = value.replace(/[^\d]/g, '');

    setInn(inn);
  };

  const handleAgreeChange: CheckboxProps['onChange'] = (e, payload) => {
    setAgree(payload?.checked || false);
  };

  const sendSignFatca = () => {
    if (treaty && inn) {
      requestSignFatca({
        treaty: treaty.treaty,
        inn,
      });
    }
  };

  const innValid = useMemo(() => {
    return validateInn(inn);
  }, [inn]);

  const formReady = agree && innValid.code === 0;

  return (
    <div className={styles.container}>
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        color="primary"
        defaultMargins={false}
      >
        FATCA (W-8BEN)
      </Typography.Text>
      <div className={styles.text}>
        {fatcaTextPending ? (
          <Loader />
        ) : (
          <FormatedText text={fatcaText?.document || ''} />
        )}
      </div>
      {Boolean(fatcaText && fatcaText.status === 'NotRegistred') && (
        <>
          <Divider className={styles.divider} />
          <Space fullWidth size="l">
            <div className={styles.field}>
              <Input
                label="ИНН"
                labelView="outer"
                block
                size="s"
                value={inn}
                onChange={handleInnChange}
                maxLength={12}
                error={innValid.code !== 0 ? innValid.message : ''}
                hint={
                  <Link
                    className={styles.tooltipLink}
                    href={EXTERNAL_LINKS.INN_SERVICE}
                    target="_blank"
                  >
                    Узнать ИНН на сайте ФНС России
                  </Link>
                }
              />
            </div>
            <Checkbox
              className={styles.checkbox}
              onChange={handleAgreeChange}
              checked={agree}
              label="Подтверждаю ответственность за предоставление и электронное подписание достоверных сведений в Форме W-8BEN"
            />
            <div className={styles.submit}>
              <Button
                size="s"
                block
                leftAddons={<SignMIcon width={18} height={18} />}
                disabled={!formReady}
                onClick={sendSignFatca}
                loading={createPending || smsSending}
              >
                Подписать
              </Button>
            </div>
          </Space>
        </>
      )}

      <SmsSignDialog
        open={openSignDialog}
        codeSending={smsSending}
        error={error}
        isError={isCreateError || isSendSmsError || isCheckError}
        isSuccess={isSuccess}
        assignment={assignment}
        onCancel={cancelAccountRegistration}
        onResend={resendSms}
        signCheck={checkSmsCode}
        onComplete={onComplete}
      />
    </div>
  );
};
