import { useContext } from 'react';

import { TradesFeedContext } from '../model/context';

export const useTradesFeedContext = () => {
  const tradesFeedData = useContext(TradesFeedContext);

  if (tradesFeedData === null) {
    throw new Error('Context TradesFeedContext contains null value');
  }

  return tradesFeedData;
};
