import { forwardRef, useCallback } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowsOutSquareMIcon } from '@alfalab/icons-glyph/ArrowsOutSquareMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useStore } from '@terminal/core/store';

import { ContextMenuHeaderTabConfig } from '../../model/types';

import styles from './TabContextMenu.module.css';

type TabContextMenuProps = {
  tabId: number;
  clientX: number;
  clientY: number;
  setHeaderTabContextMenuConfig: (config: ContextMenuHeaderTabConfig) => void;
};

export const TabContextMenu = forwardRef<HTMLDivElement, TabContextMenuProps>(
  ({ tabId, clientX, clientY, setHeaderTabContextMenuConfig }, ref) => {
    const openNewWindow = useStore((store) => store.openNewWindow);

    const handleOpenNewWindow = useCallback(() => {
      openNewWindow(tabId);

      setHeaderTabContextMenuConfig({
        isShow: false,
        clientX: 0,
        tabId: 0,
        clientY: 0,
      });
    }, [tabId, setHeaderTabContextMenuConfig, openNewWindow]);

    return (
      <div
        ref={ref}
        className={styles.contextMenu}
        style={{
          top: clientY,
          left: clientX + 10,
        }}
      >
        <Typography.Text
          view="primary-small"
          weight="bold"
          className={styles.contextMenuHeader}
        >
          Действия с рабочим столом
        </Typography.Text>
        <Dropdown.Item
          content={
            <Typography.Text view="secondary-medium" tag="div">
              Открыть в новом окне
            </Typography.Text>
          }
          leftIcon={
            <ArrowsOutSquareMIcon
              height={18}
              width={18}
              color="var(--color-light-graphic-secondary)"
            />
          }
          onClick={handleOpenNewWindow}
        />
      </div>
    );
  }
);
