import cn from 'classnames';
import React, { ComponentType, SVGProps } from 'react';
import {
  TooltipDesktop,
  TooltipDesktopProps,
} from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleMIcon } from '@alfalab/icons-glyph/InformationCircleMIcon';

import styles from './FieldTooltip.module.css';

export type FieldTooltipProps = Omit<
  TooltipDesktopProps,
  'children' | 'content'
> & {
  title?: string;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  iconSize?: number;
  iconColor?: 'promary' | 'secondary' | 'tertiary' | 'quartinary';
  maxWidth?: number;
  children: React.ReactNode;
};

export const FieldTooltip = ({
  title,
  Icon = InformationCircleMIcon,
  iconSize = 20,
  iconColor = 'secondary',
  maxWidth = 360,
  children,
  ...props
}: FieldTooltipProps) => {
  return (
    <TooltipDesktop
      zIndex={200}
      {...props}
      targetClassName={cn(styles.target, props.targetClassName)}
      content={
        <div style={{ maxWidth: maxWidth }}>
          {title && (
            <Typography.Title tag="h4" view="xsmall" className={styles.title}>
              {title}
            </Typography.Title>
          )}
          <Typography.Text view="secondary-large" color="secondary" tag="p">
            {children}
          </Typography.Text>
        </div>
      }
    >
      <Icon
        width={iconSize}
        height={iconSize}
        className={styles.glyph}
        color={`var(--color-light-graphic-${iconColor})`}
      />
    </TooltipDesktop>
  );
};
