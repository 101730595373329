import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getSecuritiesHistoryDetail } from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesHistoryDetail = (
  trnNo?: number,
  aDate?: string,
  trnSrc?: number,
  treaty?: number,
  deponet?: boolean
) => {
  const addNotification = useNotification();

  return useQuery(
    [
      LkQueryKeys.SecuritiesHistoryDetail,
      { trnNo, aDate, trnSrc, treaty, deponet },
    ],
    async () => {
      if (trnNo && aDate && treaty && deponet !== undefined) {
        return getSecuritiesHistoryDetail({
          trnNo,
          aDate,
          trnSrc,
          treaty,
          deponet,
        });
      }
    },
    {
      enabled: Boolean(trnNo && aDate && treaty && deponet !== undefined),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text:
            error?.message || 'Произошла ошибка при запросе детальной истории',
        });
      },
    }
  );
};
