import { useMemo } from 'react';

import { SubAccountPositionItem } from '../../types/subAccountPosition';

// Данный хук делает то же самое что и useIdFiBalances
// но его цель починить неудобный API
export const useIdFiBalancesList = (
  positionItems: SubAccountPositionItem[],
  useIdFiBalances: (positions: SubAccountPositionItem[]) => Map<number, number>
) => {
  const positionsToIdFiBalances = useIdFiBalances(positionItems);

  return useMemo(
    () => Array.from(new Set(positionsToIdFiBalances.values())),
    [positionsToIdFiBalances]
  ) as number[];
};
