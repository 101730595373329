import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';

import { MarginCallInfo } from '@terminal/core/types/marginCall';

export type UseRequirementBySubAccountProps = {
  marginCall: MarginCallInfo;
  mobile?: boolean;
  isDisplayRequirements?: boolean;
};

export const useRequirementBySubAccount = (
  props: UseRequirementBySubAccountProps
) => {
  const { marginCall, mobile, isDisplayRequirements } = props;

  return useCallback(
    (code: string) => {
      if (!isEmpty(marginCall) && (!mobile || isDisplayRequirements)) {
        const marginCallForAcc = marginCall[code];

        if (marginCallForAcc) {
          if (marginCallForAcc.immediateRequirements !== 0) {
            return 'immediateRequirements';
          } else if (marginCallForAcc.requirements !== 0) {
            return 'requirements';
          }
        }
      }
    },
    [marginCall, mobile, isDisplayRequirements]
  );
};
