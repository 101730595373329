import { RootStore } from '../../useStore';

type ReturnType = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

/**
 * Выбирает состояние модального окна «Таблица алертов»
 * */
export function trackPriceListModalStateSelector(store: RootStore): ReturnType {
  return {
    isOpen: store.isTrackPriceListModalOpen,
    open: store.openTrackPriceListModal,
    close: store.closeTrackPriceListModal,
  };
}
