import { useMemo } from 'react';

import { useCertificates } from '@terminal/core/hooks';
import {
  ConfigExperience,
  ConfigStep,
  useOnboardingConfigStore,
} from '@terminal/desktop/entities/OnboardingConfig';

export function useOnboardingSteps(): ConfigStep[] {
  const { isNeedIssueCertificate, isNeedConfirmCertificate } =
    useCertificates();
  const [configExperience] = useOnboardingConfigStore((store) => [
    store.configExperience,
  ]);

  return useMemo(() => {
    const result: ConfigStep[] = [ConfigStep.Experience, ConfigStep.Size];

    // Для опытных пользователей доступен выбор конфигурации рабочего стола
    if (configExperience === ConfigExperience.Advanced) {
      result.push(ConfigStep.Style);
    }

    result.push(ConfigStep.Theme);

    // Если у пользователя нет валидного сертификата или его нужно подтвердить, добавляем шаг с сертификатом
    if (isNeedIssueCertificate || isNeedConfirmCertificate) {
      result.push(ConfigStep.Certificate);
    }

    return result;
  }, [configExperience, isNeedConfirmCertificate, isNeedIssueCertificate]);
}
