export const DJIList = [
  'AAPL',
  'AMGN',
  'AXP',
  'BA',
  'CAT',
  'CRM',
  'CSCO',
  'CVX',
  'DIS',
  'DOW',
  'GS',
  'HD',
  'HON',
  'IBM',
  'INTC',
  'JNJ',
  'JPM',
  'KO',
  'MCD',
  'MMM',
  'MRK',
  'MSFT',
  'NKE',
  'PG',
  'TRV',
  'UNH',
  'V',
  'VZ',
  'WBA',
  'WMT',
];
