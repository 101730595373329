import { useEffect, useMemo, useState } from 'react';

import { loadMultimarkets } from '../lib/services/multimarkets';

import { FIModalItem } from '../types/FIModal';

const MAX_MULTIMARKETS_LIMIT = 100;

type FILiquidMarket = { liquidMarket: boolean; universalMarketCode?: string };
export type FIModalItemExt = FIModalItem & FILiquidMarket;

// хук принимает на вход результат функции lookupFininstrument
// На входе хука FIModalItem[]
// На выходе FIModalItem[], пересортированный и обогащенный полем liquidMarket
export const useMultimarketLiquidity = (instruments: FIModalItem[]) => {
  const [liquidMarkets, setLiquidMarkets] = useState<Map<
    string,
    string
  > | null>(null);

  const isins = useMemo(() => {
    const isins = instruments
      .filter((instrument) => instrument.isin)
      .map((instrument) => instrument.isin!);

    return Array.from(new Set(isins));
  }, [instruments]);

  useEffect(() => {
    setLiquidMarkets(null);
  }, [instruments]);

  useEffect(() => {
    if (isins.length === 0 || isins.length > MAX_MULTIMARKETS_LIMIT) {
      return;
    }

    loadMultimarkets(isins).then((result) => {
      // Map ликвидных рынков. Для каждого isin находится ликвидный universalMarketCode
      const liquidMarkets = result.reduce((acc, inst) => {
        inst.exchanges.forEach((exchange) => {
          const isLiquidExchange = inst.defaultExchangeId === exchange.id;

          exchange.markets.forEach((market) => {
            const isLiquidMarket = exchange.liquidMarketId === market.id;
            const isLiquid = isLiquidExchange && isLiquidMarket;

            if (isLiquid) {
              acc.set(inst.isin, market.id);
            }
          });
        });

        return acc;
      }, new Map<string, string>());

      setLiquidMarkets(liquidMarkets);
    });
  }, [instruments, isins]);

  const markets = useMemo(() => {
    // Сканируем инструменты и обновляем флаг ликвидности
    const result = instruments.map((inst) => {
      const liquidMarket = liquidMarkets
        ? liquidMarkets.get(inst.isin ?? '') === inst.universalMarketCode
        : false;

      return { ...inst, liquidMarket };
    });

    return result;
  }, [instruments, liquidMarkets]);

  return markets;
};
