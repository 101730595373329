import React, { useEffect, useRef, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { CodeInputDesktop } from '@alfalab/core-components/code-input/desktop';
import { CustomInputRef } from '@alfalab/core-components/code-input/typings';
import { ConfirmationState } from '@alfalab/core-components/confirmation/types';
import { Typography } from '@alfalab/core-components/typography';

import styles from './ConfirmationCreatePinCode.module.css';

interface Props {
  createPinCode: (code: string) => void;
  onSkipPinCode: () => void;
  setConfirmationState: (state: ConfirmationState) => void;
}

export const ConfirmationCreatePinCode = ({
  createPinCode,
  onSkipPinCode,
  setConfirmationState,
}: Props) => {
  const [pinCode, setPinCode] = useState<string>('');

  const inputRef = useRef<CustomInputRef>(null);

  const handleSave = () => {
    if (pinCode.length === 4) {
      createPinCode(pinCode);
    } else {
      setConfirmationState('CODE_ERROR' as ConfirmationState);
    }
  };

  useEffect(() => {
    inputRef.current?.focus?.();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Typography.Title
        view="xsmall"
        tag="div"
        font="styrene"
        className={styles.title}
      >
        Придумайте код
        <br /> для быстрого входа
      </Typography.Title>

      <CodeInputDesktop
        ref={inputRef}
        fields={4}
        onChange={setPinCode}
        className={styles.codeInput}
      />

      <Typography.Text view="secondary-small" tag="div">
        Установленный код можно будет использовать
        <br /> для быстрого входа в терминал. Он будет работать
        <br /> в рамках текущего устройства и браузера
      </Typography.Text>

      <div className={styles.buttons}>
        <ButtonDesktop
          block
          view="ghost"
          type="button"
          size="m"
          onClick={onSkipPinCode}
        >
          Пропустить
        </ButtonDesktop>
        <ButtonDesktop
          block
          view="accent"
          type="button"
          size="m"
          disabled={pinCode.length !== 4}
          onClick={handleSave}
        >
          Сохранить&nbsp;код
        </ButtonDesktop>
      </div>
    </div>
  );
};
