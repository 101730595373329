import {
  FinInstrumentEntity,
  MarketBoardEntity,
  ObjectEntity,
  ObjectExtEntity,
  ObjectTypeEntity,
} from '../lib/client/entities';

import {
  BoardType,
  BoardTypeMap,
  MarketBoardTypeMap,
  ObjectTypes,
  ObjectTypesMap,
} from '../types/core';

export const objectMap = ({ IdObjectType, ...entity }: ObjectEntity) => ({
  idObject: entity.IdObject,
  idObjectFaceUnit: entity.IdObjectFaceUnit,
  idObjectBase: entity.IdObjectBase,
  idObjectType: {
    value: IdObjectType,
    name:
      ObjectTypesMap.get(IdObjectType) || ObjectTypesMap.get(ObjectTypes.NA)!,
  },
  nameObject: entity.NameObject,
  symbolObject: entity.SymbolObject,
  descObject: entity.DescObject,
  matDateObject: entity.MatDateObject,
  expired: entity.Expired,
  nominal: entity.Nominal,
  isin: entity.Isin,
  version: entity.Version?.toString() ?? '',
});

export const InstrumentItemMap = (entity: FinInstrumentEntity) => ({
  idObject: entity.IdObject,
  idFI: entity.IdFI,
  idMarketBoard: entity.IdMarketBoard,
  version: entity.Version?.toString() ?? '',
});

export const marketBoardMap = ({
  IdBoardType,
  Type,
  ...entity
}: MarketBoardEntity) => ({
  idGate: entity.IdGate,
  idMarketBoard: entity.IdMarketBoard,
  idMarketType: entity.IdMarketType,
  idBoardType: {
    value: IdBoardType,
    name:
      BoardTypeMap.get(IdBoardType) ||
      BoardTypeMap.get(BoardType.Unidentified)!,
  },
  type: {
    value: Number(Type),
    name: MarketBoardTypeMap.get(Number(Type)),
  },
  codeMarketBoard: entity.CodeMarketBoard,
  nameMarketBoard: entity.NameMarketBoard,
  descMargetBoard: entity.DescMargetBoard,
  placeCode: entity.PlaceCode,
  universalMarketCode: entity.UniversalMarketCode,
  rCode: entity.RCode,
  idObjectCurrency: entity.IdObjectCurrency,
  version: entity.Version?.toString() ?? '',
});

export const ObjectTypeMap = (entity: ObjectTypeEntity) => ({
  idObjectType: entity.IdObjectType,
  idObjectGroup: entity.IdObjectGroup,
  codeObjectType: entity.CodeObjectType,
  nameObjectType: entity.NameObjectType,
  shortNameObjectType: entity.ShortNameObjectType,
  version: entity.Version?.toString() ?? '',
});

export const ObjectExtMap = (entity: ObjectExtEntity) => ({
  idObject: entity.IdObject,
  idFIBalance: entity.IdFIBalance,
  idMarketBoardBalance: entity.IdMarketBoardBalance,
  accruedIntT0: entity.AccruedIntT0,
  version: entity.Version?.toString() ?? '',
});
