import { EventEmitter } from 'eventemitter3';
import throttle from 'lodash/throttle';

import { AllTradeEntity } from '../client/entities';
import { EntityType } from '../client/entityTypes';
import { StreamingService, SubscribeReturnData } from './streaming';

import { TradePeriodType, TradeType } from '../../types/quotes';

export interface TradeInfo {
  IdFI: number;
  IdTradeType: TradeType;
  IdTradePeriodStatus: TradePeriodType;
  TradeNo: string;
  TradeTime: Date;
  BuySell: number;
  Value: number;
  Qty: BigInt;
  Price: number;
  highlight?: true;
}

export class TradesService extends EventEmitter {
  subscribeToFI(
    fi: number,
    updater: (trades: TradeInfo[]) => void,
    timeout = 100
  ): SubscribeReturnData {
    let resultCache: TradeInfo[] = [];
    const throttledUpdater = throttle(() => {
      updater(resultCache);
      resultCache = [];
    }, timeout);

    return StreamingService.subscribe(
      { entity: EntityType.AllTradeEntity, fi: [fi] },
      (message) => {
        message.data.forEach((obj) => {
          const entity = obj as AllTradeEntity;

          if (entity.IdFI === fi) {
            resultCache.push({
              IdFI: entity.IdFI,
              IdTradeType: entity.IdTradeType,
              IdTradePeriodStatus: entity.IdTradePeriodStatus,
              TradeNo: entity.TradeNo.toString(),
              TradeTime: entity.TradeTime,
              BuySell: entity.BuySell,
              Value: entity.Value,
              Qty: entity.Qty,
              Price: entity.Price,
            });
          }
        });

        throttledUpdater();
      }
    );
  }

  unsubscribe(fi: number, subscribeReturnData: SubscribeReturnData) {
    StreamingService.unsubscribe(
      { fi: [fi], entity: EntityType.AllTradeEntity },
      subscribeReturnData
    );
  }
}
