import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';
import shallow from 'zustand/shallow';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';

import { roundFloatNumber } from '../lib/format';

import { OrderBookLine } from '../types/orderBook';

export interface SubscribeReturnData {
  uuid: string;
  subscribeHandler?: (data: any) => void;
}

/**
 * Возвращает данные по инструменту из OrderBookService
 */
export const useOrderBookService = (
  idFi: number,
  priceDecimals: number,
  timeout = 1000
) => {
  const { OrderBookService: orderbookService } = useAlfaDirectContext();
  const savedIdFi = useRef<number>();
  const [result, setResult] = useState<{
    bestBid?: OrderBookLine;
    bestAsk?: OrderBookLine;
    spread: number;
  }>({ spread: 0 });
  const updater = useThrottledCallback((lines: OrderBookLine[]) => {
    const bestBid = maxBy(
      lines.filter((line) => line.BuyQty > 0),
      'Price'
    );

    const bestAsk = minBy(
      lines.filter((line) => line.SellQty > 0),
      'Price'
    );

    const spread = roundFloatNumber(
      ((bestAsk?.Price || 0) - (bestBid?.Price || 0)) * priceDecimals
    );

    if (
      !shallow(result.bestBid, bestBid) ||
      !shallow(result.bestAsk, bestAsk)
    ) {
      setResult({
        bestBid,
        bestAsk,
        spread,
      });
    }
  }, timeout);

  //Сбрасываем данные при смене инструмента
  useEffect(() => {
    if (idFi !== savedIdFi.current) {
      //Заканчиваем стримить апдейты
      updater.cancel();
    }
  }, [idFi, updater]);

  //Подписывается на данные
  useEffect(() => {
    if (idFi !== savedIdFi.current) {
      let subscribeReturnData: SubscribeReturnData;

      subscribeReturnData = orderbookService.subscribe(idFi);

      return () => {
        if (subscribeReturnData) {
          orderbookService.unsubscribe(idFi, subscribeReturnData);
        }
      };
    }
  }, [idFi, orderbookService]);

  const updateLines = useCallback(
    ({
      idFI: receivedIdFi,
      lines: newLines,
    }: {
      idFI: number;
      lines: OrderBookLine[];
    }) => {
      if (receivedIdFi === savedIdFi.current) {
        updater(newLines);
      }
    },
    [updater]
  );

  //Обновляем строчки
  useEffect(() => {
    if (idFi !== savedIdFi.current) {
      orderbookService.on('update', updateLines);
    }

    savedIdFi.current = idFi;

    return () => {
      orderbookService.off('update', updateLines);
    };
  }, [idFi, orderbookService, updateLines]);

  useEffect(() => {
    savedIdFi.current = idFi;
  }, [idFi]);

  return result;
};
