import { useCallback } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { SignMIcon } from '@alfalab/icons-glyph/SignMIcon';
import { SmileSadMIcon } from '@alfalab/icons-glyph/SmileSadMIcon';
import { ThumbUpMIcon } from '@alfalab/icons-glyph/ThumbUpMIcon';

import { shallow, useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';

import { BacklToListButton } from './BacklToListButton';
import { decapitalize } from './shared';

import { useIntegratedTesting } from './hooks/useIntegratedTesting';

import { MainMenuType } from '../main/types';

import styles from './ResultScreen.module.css';

interface ResultScreenProps {
  isFailed?: boolean;
  children?: React.ReactNode;
  testName?: string | undefined;
  testId?: number;
  onBackToList?: () => void;
  redirectToOperations?: () => void;
}

export const ResultScreen = ({
  isFailed,
  children,
  testName,
  testId,
  onBackToList,
  redirectToOperations,
}: ResultScreenProps) => {
  const lkSubMenuId = useStore((store) => store.lkSubMenuId);

  const { backButton } = useIntegratedTesting();

  const [setHeaderMenuOpen, setLkSubMenuId] = useStore(
    (store) => [store.setHeaderMenuOpen, store.setLkSubMenuId],
    shallow
  );

  const toTestList = useCallback(() => {
    setHeaderMenuOpen(HeaderMenuItem.MAIN_LK);

    setLkSubMenuId({
      id: MainMenuType.QualTesting,
    });
  }, [setHeaderMenuOpen, setLkSubMenuId]);

  // Если к контексте существует кнопка обратного перехода - это интегрированный тест,
  // значит, чтобы вернуться к списку тестов, нужно перейти в модальное окно тестирования
  const backListAction = backButton ? toTestList : onBackToList;

  let successMessage: React.ReactNode;

  const returnButton = backButton ? (
    <ButtonDesktop
      view="secondary"
      size="xxs"
      leftAddons={backButton.icon}
      onClick={backButton.onClick}
    >
      {backButton.label}
    </ButtonDesktop>
  ) : null;

  switch (testId) {
    case 2: {
      successMessage = (
        <div>
          <div className={styles.successMessage}>
            <Typography.Text
              view="secondary-small"
              className={styles.successText}
            >
              Теперь вам доступны необеспеченные <br />
              (маржинальные) сделки
            </Typography.Text>
          </div>
          <div className={styles.resultButtonBlock}>
            {returnButton}
            {onBackToList && (
              <BacklToListButton block onClick={backListAction} />
            )}
          </div>
        </div>
      );
      break;
    }
    case 3: {
      successMessage = (
        <div>
          <div className={styles.successMessage}>
            <Typography.Text
              view="secondary-small"
              className={styles.successText}
            >
              Теперь вы можете зарегистрироваться <br />
              на срочном рынке (FORTS)
            </Typography.Text>
          </div>
          <div className={styles.resultButtonBlock}>
            {returnButton}
            {lkSubMenuId?.options === 3 && (
              <ButtonDesktop
                size="s"
                block
                view="secondary"
                leftAddons={<SignMIcon width={11} height={11} />}
                onClick={redirectToOperations}
              >
                Зарегистрироваться
              </ButtonDesktop>
            )}
            {onBackToList && (
              <BacklToListButton block onClick={backListAction} />
            )}
          </div>
        </div>
      );
      break;
    }

    default: {
      successMessage = (
        <>
          <div className={styles.successMessage}>
            <Typography.Text
              view="secondary-small"
              className={styles.successText}
            >
              {`Теперь вам доступны ${decapitalize(testName)}`}
            </Typography.Text>
          </div>
          <div className={styles.resultButtonBlock}>
            {returnButton}
            {onBackToList && (
              <BacklToListButton block onClick={backListAction} />
            )}
          </div>
        </>
      );
    }
  }

  return (
    <section className={styles.container}>
      <SuperEllipse className={styles.icon} size={48}>
        {isFailed ? (
          <SmileSadMIcon className={styles.glyph} />
        ) : (
          <ThumbUpMIcon className={styles.glyph} />
        )}
      </SuperEllipse>
      <Typography.Text view="primary-medium" className={styles.title}>
        {isFailed ? 'К сожалению, тест не пройден' : 'Тест пройден успешно'}
      </Typography.Text>
      {!isFailed && successMessage}
      {children}
    </section>
  );
};
