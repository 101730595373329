import cn from 'classnames';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import styles from './BestBidBestAskAndSpread.module.css';

interface BestBidBestAskAndSpreadProps {
  isMobileVersion?: boolean;
  bestBid: number | undefined;
  bidQty: number | undefined;
  bestAsk: number | undefined;
  askQty: number | undefined;
  spread: number | undefined;
  priceDecimals: number;
  currencyCode: CurrencyCodes | undefined;
  onBidClick?: () => void;
  onAskClick?: () => void;
  onSpreadClick?: () => void;
  isBond?: boolean;
}

export const BestBidBestAskAndSpread = ({
  isMobileVersion,
  bestBid,
  bidQty,
  bestAsk,
  askQty,
  spread,
  priceDecimals,
  currencyCode,
  onBidClick,
  onAskClick,
  onSpreadClick,
  isBond,
}: BestBidBestAskAndSpreadProps) => {
  return (
    <div
      className={cn(styles.wrapper, isMobileVersion && styles.mobileVersion)}
    >
      <div className={styles.spreadPrice} onClick={onSpreadClick}>
        <Amount.Pure
          value={spread || 0}
          minority={priceDecimals}
          view="withZeroMinorPart"
          currency={isBond ? undefined : currencyCode}
        />
        {isBond && ' %'}
      </div>
      <div className={styles.bestPriceBox} onClick={onBidClick}>
        <Typography.Text view="secondary-small">Best Bid</Typography.Text>
        <div className={styles.bestPrice}>
          <Amount.Pure
            className={styles.bidPrice}
            value={(bestBid || 0) * priceDecimals}
            minority={priceDecimals}
            view="withZeroMinorPart"
            currency={isBond ? undefined : currencyCode}
          />
          {isBond && <span className={styles.bidPrice}>%</span>}
          &nbsp;
          <Typography.Text view="secondary-small">
            {bidQty || 0}
          </Typography.Text>
        </div>
      </div>
      <div
        className={cn(styles.bestPriceBox, styles.askBox)}
        onClick={onAskClick}
      >
        <Typography.Text view="secondary-small">Best Ask</Typography.Text>
        <div className={styles.bestPrice}>
          <Typography.Text view="secondary-small">
            {askQty || 0}
          </Typography.Text>
          &nbsp;
          <Amount.Pure
            className={styles.askPrice}
            value={(bestAsk || 0) * priceDecimals}
            minority={priceDecimals}
            view="withZeroMinorPart"
            currency={isBond ? undefined : currencyCode}
          />
          {isBond && <span className={styles.askPrice}>%</span>}
        </div>
      </div>
    </div>
  );
};
