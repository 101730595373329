import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

export const useAddAccountPreregister = <T, R>(
  type: T,
  fetchPreregister: (type: T) => Promise<R>
) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'addAccount/preregister', { type }],
    () => fetchPreregister(type),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'При запросе заявления произошла ошибка',
        });
      },
    }
  );
};
