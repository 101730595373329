import { DBSchema, IDBPDatabase, openDB } from 'idb';

import { APP_DB_NAME } from '../../../env';

interface TerminalRsaKeysDBv1 extends DBSchema {
  'rsa-keys': {
    key: number;
    value: {
      id: number;
      keys: CryptoKeyPair;
      dateCreated: Date;
    };
  };
}

let db: IDBPDatabase<TerminalRsaKeysDBv1>;
const DB_NAME = APP_DB_NAME;
const DB_VERSION = 1;

/** Оставляем отдельную БД под хранение ключей, чтобы сохранить обратную совместимость при откате релизов
 *  Таким образом решаем проблему когда старый пользовательский код пытается получить ключи из базы с верскией > 1
 *  Не может этого сделать и падает */
export async function initRsaKeysDB() {
  try {
    db = await openDB<TerminalRsaKeysDBv1>(DB_NAME, DB_VERSION, {
      upgrade(db) {
        db.createObjectStore('rsa-keys', {
          keyPath: 'id',
          autoIncrement: false,
        });
      },
    });
  } catch (e) {
    console.error(
      `Ошибка инициализации базы данных ${DB_NAME}, version: ${DB_VERSION}. `,
      e
    );
  }
}

export async function addRSAKeyPair(keys: CryptoKeyPair, id: number) {
  await db.add('rsa-keys', { id: id, keys, dateCreated: new Date() });
}

export async function removeRSAPair(id: number) {
  const tx = db.transaction('rsa-keys', 'readwrite');
  const store = tx.objectStore('rsa-keys');
  const val = (await store.get(id)) || null;
  if (val) {
    await store.delete(id);
  }
  await tx.done;
}

export async function getAllRSAKeys() {
  return await db.getAll('rsa-keys');
}

export async function getRSAKey(id: number) {
  return await db.get('rsa-keys', id);
}
