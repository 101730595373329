import cn from 'classnames';
import { useState } from 'react';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { SecuritiesDraftListItem } from '@terminal/core/lib/rest/lkSecurities';

import { DraftItem } from './DraftItem';
import { DraftItemDetail } from './DraftItemDetail';

import { useSecuritiesDraftDetails, useSecuritiesPlaceList } from '../../hooks';

import { DraftCompleteResult } from './types';

import draftstable from './draftsTable.module.css';

interface DraftsListProps {
  drafts: SecuritiesDraftListItem[];
  treaty: Treaty | null;
  onComplete?: (params: DraftCompleteResult) => void;
}

export const DraftsList = ({ drafts, treaty, onComplete }: DraftsListProps) => {
  const [selected, setSelected] = useState<SecuritiesDraftListItem | null>(
    null
  );

  const { isFetching: placeListPending, data: placeList } =
    useSecuritiesPlaceList(treaty?.treaty);

  const { isFetching: detailsPending, data: details } =
    useSecuritiesDraftDetails(treaty?.treaty || 0, selected?.id || 0);

  return (
    <>
      <div className={draftstable.table}>
        <div className={cn(draftstable.row, draftstable.rowHead)}>
          <div
            className={cn(
              draftstable.cell,
              draftstable.cellHead,
              draftstable.cellName
            )}
          >
            Ценная бумага
          </div>
          <div
            className={cn(
              draftstable.cell,
              draftstable.cellHead,
              draftstable.cellType
            )}
          >
            Направление
          </div>
          <div
            className={cn(
              draftstable.cell,
              draftstable.cellHead,
              draftstable.cellAmount
            )}
          >
            Количество
          </div>
          <div
            className={cn(
              draftstable.cell,
              draftstable.cellHead,
              draftstable.cellAction
            )}
          ></div>
        </div>
        <div className={draftstable.body}>
          {drafts.map((item, index) => (
            <DraftItem
              key={`securities-draft-item-${item.id}`}
              item={item}
              odd={index % 2 !== 0}
              onClick={(item) => setSelected(item)}
            />
          ))}
        </div>
      </div>

      <Portal id="securities-transfer-modals">
        <FunctionalModalSidebar
          open={Boolean(selected)}
          backLabel="Назад"
          onClose={() => setSelected(null)}
        >
          <DraftItemDetail
            draft={details}
            pending={placeListPending || detailsPending}
            treaty={treaty}
            placeList={placeList || []}
            onComplete={onComplete}
          />
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
