import React, { FC } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { useBadBrowser } from '@terminal/core/hooks/useBadBrowser';

import styles from './BadBrowserLock.module.css';

export const BadBrowserLock: FC = (props) => {
  const { children } = props;
  const { reason, error, description } = useBadBrowser();
  const { appLogo, supportEmail, supportPhone } = useAppContext();

  if (!reason) {
    return <>{children}</>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo}>{appLogo}</div>
      <div className={styles.description}>
        <Typography.TitleMobile tag="div" view="small">
          {error}
        </Typography.TitleMobile>
      </div>
      <div className={styles.subDescription}>
        <Typography.Text view="primary-small" color="secondary">
          {description}
        </Typography.Text>
      </div>
      <div className={styles.supportFooter}>
        <Typography.Text view="primary-small" color="secondary">
          Не получается войти? Обратитесь в службу технической поддержки
        </Typography.Text>
        <div className={styles.contacts}>
          {supportPhone ? (
            <div>
              <Typography.Text
                view="primary-small"
                color="secondary"
                weight="bold"
              >
                {supportPhone}{' '}
              </Typography.Text>
              <Typography.Text view="primary-small" color="secondary">
                для звонков по России
              </Typography.Text>
            </div>
          ) : null}
          <Typography.Text view="primary-small" color="secondary" weight="bold">
            {supportEmail}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
