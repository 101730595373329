import { RefObject, useEffect, useRef } from 'react';
import { useThrottledCallback } from 'use-debounce';

interface Position {
  elX: number;
  elY: number;
  elH: number;
  elW: number;
}

/** Проверяем, находится ли указатель внутри элемента */
const checkIsHover = ({ elX, elY, elW, elH }: Position) =>
  !(elX === elW || elX === 0 || elY === elH || elY === 0);

/**
 * Хук управляет состоянием ховера на области элемента
 *
 * @param ref Отслеживаемый элемент
 * @param onShow Колбек при наведении на область элемента
 * @param onHide Колбек при покидании курсора области элемента
 *
 * @description Сделано для того, чтобы определять позицию мыши на элементе и отслеживать дочерние элементы,
 * которые позиционируются абсолютно или в порталах (например дропдауны)
 */
export const useOnElemRectHover = (
  ref: RefObject<Element>,
  onShow: () => void,
  onHide: () => void
) => {
  const isHoveredRef = useRef(false);

  const handleMouseMove = (event: MouseEvent) => {
    if (ref && ref.current) {
      const {
        left: posX,
        top: posY,
        width: elW,
        height: elH,
      } = ref.current.getBoundingClientRect();
      const elX = event.pageX - posX;
      const elY = event.pageY - posY;

      const isHovered = checkIsHover({
        elW,
        elH,
        elX: Math.max(0, Math.min(elX, elW)),
        elY: Math.max(0, Math.min(elY, elH)),
      });

      if (!isHoveredRef.current && isHovered) {
        onShow();
        isHoveredRef.current = true;
      }

      if (isHoveredRef.current && !isHovered) {
        onHide();
        isHoveredRef.current = false;
      }
    }
  };

  const throttledHandleMouseMove = useThrottledCallback(handleMouseMove, 150);

  useEffect(() => {
    const handleHide = () => {
      if (isHoveredRef.current) {
        onHide();
        isHoveredRef.current = false;
      }
    };

    document.addEventListener('mousemove', throttledHandleMouseMove);
    document.body.addEventListener('mouseleave', handleHide);

    return () => {
      document.removeEventListener('mousemove', throttledHandleMouseMove);
      document.body.removeEventListener('mouseleave', handleHide);
    };
  }, [throttledHandleMouseMove, onHide]);
};
