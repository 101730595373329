import cn from 'classnames';
import React from 'react';
import { Button } from '@alfalab/core-components/button';
import { ChevronBackMIcon } from '@alfalab/icons-glyph/ChevronBackMIcon';

import styles from './AssignmentTextModal.module.css';

export interface AssignmentTextModalProps {
  backLabel?: string;
  open?: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

export const AssignmentTextModal = ({
  children,
  ...props
}: AssignmentTextModalProps) => {
  return (
    <div className={cn(styles.sidebar, props.open && styles.sidebarOpen)}>
      <div className={styles.inner}>
        <div className={styles.head}>
          <div className={styles.title}>
            {props.backLabel || 'Текстовое поручение'}
          </div>
          <Button
            className={styles.back}
            view="ghost"
            onClick={props.onClose}
            leftAddons={
              <div className={styles.backCircle}>
                <ChevronBackMIcon width={18} height={18} />
              </div>
            }
          >
            {props.backLabel || 'Назад'}
          </Button>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};
