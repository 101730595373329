import cn from 'classnames';
import { CheckmarkOnCircleMIcon } from '@alfalab/icons-glyph/CheckmarkOnCircleMIcon';
import { ClockLineMIcon } from '@alfalab/icons-glyph/ClockLineMIcon';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';

import { TradingPlatformStateExtendedType } from './types';

import styles from './RegistrationState.module.css';

interface RegistrationStateProps {
  state: TradingPlatformStateExtendedType;
  label: string;
  className?: string;
}

export const RegistrationState = ({
  state,
  label,
  className,
}: RegistrationStateProps) => {
  if (state === 'Declined' || state === 'NotRegistred') {
    return null;
  }

  return (
    <div className={cn(styles.state, className)}>
      <div
        className={cn(styles.icon, state === 'Registred' && styles.iconSuccess)}
      >
        {(() => {
          switch (state) {
            case 'InProgress':
              return <ClockLineMIcon width={16} height={16} />;
            case 'Registred':
              return <CheckmarkOnCircleMIcon width={16} height={16} />;
            case 'NotAvailable':
              return (
                <CrossCircleMIcon
                  width={16}
                  height={16}
                  color="var(--color-light-graphic-negative)"
                />
              );
          }
        })()}
      </div>
      <div className={styles.name}>{label}</div>
    </div>
  );
};
