import React from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';

import { CancelOrder, CancelOrderProps } from '../CancelOrder';

interface Props extends CancelOrderProps {
  isOpen: boolean;
}

export const CancelOrderModal = (props: Props) => {
  if (!props.order || !props.isOpen) {
    return null;
  }

  return (
    <BaseModal open={props.isOpen} onClose={props.onCloseRequest}>
      <CancelOrder {...props} />
    </BaseModal>
  );
};
