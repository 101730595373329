import { FC } from 'react';
import { Link } from '@alfalab/core-components/link';

import { Heading } from '../Heading';

import { useCertificateEnrollStore } from '../../model/store';

import { CertificateEnrollStep } from '../../model/types';

export const CertificateHeading: FC = () => {
  const [step, setStep, rejectingCertificate] = useCertificateEnrollStore(
    (store) => [store.step, store.setStep, store.rejectingCertificate]
  );

  return (
    <>
      {step === CertificateEnrollStep.SelectRejecting && (
        <Heading
          title={
            <>
              Выберите сертификат, который
              <br />
              хотите отозвать
            </>
          }
        />
      )}
      {step === CertificateEnrollStep.Confirm && (
        <Heading
          title={
            <>
              Введите код из сообщения,
              <br />
              чтобы выпустить новый сертификат
            </>
          }
          description="Без него не получится торговать на бирже и работать с личным кабинетом."
        />
      )}
      {step === CertificateEnrollStep.Reject && (
        <Heading
          title={
            <>
              Введите код из сообщения,
              <br />
              чтобы отозвать сертификат
            </>
          }
          description={
            <>
              {rejectingCertificate?.description}
              <br />
              Если хотите удалить другой сертификат,{' '}
              <Link
                pseudo
                view="primary"
                onClick={() => setStep(CertificateEnrollStep.SelectRejecting)}
              >
                нажмите здесь
              </Link>
            </>
          }
        />
      )}
    </>
  );
};
