import { useEffect, useState } from 'react';

import {
  activationOptions,
  ActivationTime,
  LifeTimeOptionShape,
} from '@terminal/core/constants/orderBook';
import { OrderType } from '@terminal/core/lib/client/entities';
import { MarketBoard } from '@terminal/core/types/core';
import { OrderSide } from '@terminal/core/types/trading';

import { useFormOptions } from './useFormOptions';

interface TradeFormProps {
  setSecondPrice: (value: number) => void;
  idMarketBoard?: MarketBoard;
  defaultOrderType?: OrderType;
  defaultSide?: OrderSide;
}

export const useTradeFormControls = ({
  setSecondPrice,
  idMarketBoard,
  defaultOrderType = OrderType.LMT,
  defaultSide = OrderSide.BUY,
}: TradeFormProps) => {
  const { isMarketMB_VR, lifeTimeOptions, defaultLifeTime, orderTypeOptions } =
    useFormOptions(idMarketBoard);

  const [side, setSide] = useState<OrderSide>(defaultSide);
  const [orderType, setOrderType] = useState<OrderType>(defaultOrderType);
  const [lifeTime, setLifeTime] =
    useState<LifeTimeOptionShape>(defaultLifeTime);
  const [activation, setActivation] = useState(
    () => activationOptions.find((opt) => opt.value === ActivationTime.NOW)!
  );

  useEffect(() => {
    if (isMarketMB_VR) {
      setOrderType(orderTypeOptions[0].key);
      setLifeTime(lifeTimeOptions[0]);
    } else {
      setOrderType(defaultOrderType);
      setLifeTime(defaultLifeTime);
    }
  }, [
    defaultLifeTime,
    defaultOrderType,
    isMarketMB_VR,
    lifeTimeOptions,
    orderTypeOptions,
  ]);

  const handleSetOrderType = (orderType: OrderType) => {
    setOrderType(orderType);

    if (orderType !== OrderType.BRS && orderType !== OrderType.TBRS) {
      setSecondPrice(0);
    }
  };

  return {
    side,
    setSide,
    orderType,
    setOrderType: handleSetOrderType,
    lifeTime,
    setLifeTime,
    activation,
    setActivation,
    orderTypeOptions,
    lifeTimeOptions,
  };
};
