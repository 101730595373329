import { IJsonModel } from 'flexlayout-react';

import {
  defaultBalanceColumnSetting,
  defaultTradesFeedProps,
  getDefaultWatchListColumnSetting,
} from './tableProps';
import { defaultListId as selectedListId } from './WatchList';

import { ElementType, LinkGroups, LinkName, Widget } from '../types/layout';

export const WidgetsNameMap = new Map<Widget, string>([
  [Widget.WATCHLIST, 'Инструменты'],
  [Widget.TRADES, 'Лента сделок'],
  [Widget.ORDERBOOK, 'Стакан'],
  [Widget.ASSETS, 'Инструменты'],
  [Widget.CHART, 'График'],
  [Widget.POSITIONS, 'Позиции'],
  [Widget.OPERATIONS, 'Операции'],
  [Widget.ORDERS, 'Заявки'],
  [Widget.BALANCE, 'Балансы'],
  [Widget.DEVTOOLS, 'Средства разработки'],
  [Widget.CERTIFICATES, 'Сертификаты'],
  [Widget.HYBRIDORDER, 'Торговля'],
  [Widget.INSTRUMENT_INFO, 'Инфо'],
  [Widget.OPERATIONS_HISTORY, 'История операций'],
  [Widget.NEWS, 'Новости'],
]);
export const initialJson: IJsonModel = {
  global: {
    splitterSize: 4,
    splitterExtra: 0,
    tabSetTabStripHeight: 24,
    tabEnableRename: false,
    tabSetEnableDrag: false,
    tabSetEnableMaximize: false,
    tabSetMinHeight: 0,
    tabSetMinWidth: 0,
    enableEdgeDock: true,
  },
  layout: {
    type: ElementType.ROW,
    weight: 3,
    children: [
      {
        type: ElementType.TABSET,
        weight: 1,
        children: [
          {
            type: ElementType.TAB,
            name: WidgetsNameMap.get(Widget.TRADES),
            component: Widget.TRADES,
            config: {
              idFi: 144950,
              symbol: 'SBER',
            },
          },
          {
            type: ElementType.TAB,
            name: WidgetsNameMap.get(Widget.TRADES),
            component: Widget.TRADES,
            config: {
              idFi: 146986,
              symbol: 'GAZP',
            },
          },
        ],
      },
      {
        type: ElementType.ROW,
        weight: 2,
        children: [
          {
            type: ElementType.TABSET,
            weight: 1,
            children: [
              {
                type: ElementType.TAB,
                name: WidgetsNameMap.get(Widget.CHART),
                component: Widget.CHART,
                config: {
                  symbol: 'SBER',
                  idFi: 144950,
                },
              },
              {
                type: ElementType.TAB,
                name: WidgetsNameMap.get(Widget.CHART),
                component: Widget.CHART,
                config: {
                  symbol: 'GAZP',
                  idFi: 146986,
                },
              },
            ],
          },
          {
            type: ElementType.TABSET,
            weight: 1,
            children: [
              {
                type: ElementType.TAB,
                name: WidgetsNameMap.get(Widget.WATCHLIST),
                component: Widget.WATCHLIST,
                config: {
                  selectedListId,
                },
              },
            ],
          },
        ],
      },
      {
        type: ElementType.TABSET,
        weight: 1,
        children: [
          {
            type: ElementType.TAB,
            name: WidgetsNameMap.get(Widget.ORDERBOOK),
            component: Widget.ORDERBOOK,
            config: {
              idFi: 144950,
              symbol: 'SBER',
            },
          },
          {
            type: ElementType.TAB,
            name: WidgetsNameMap.get(Widget.ORDERBOOK),
            component: Widget.ORDERBOOK,
            config: {
              idFi: 146986,
              symbol: 'GAZP',
            },
          },
        ],
      },
    ],
  },
};

export const importOptions = {
  managePeriodicity: true,
  preserveTicksAndCandleWidth: true,
};

export const initialLayoutLink: LinkGroups = {
  [LinkName.BLUE]: {
    nodeIds: [],
  },
  [LinkName.GREEN]: {
    nodeIds: [],
  },
  [LinkName.RED]: {
    nodeIds: [],
  },
  [LinkName.YELLOW]: {
    nodeIds: [],
  },
};

export const WidgetWithName = [
  Widget.CHART,
  Widget.TRADES,
  Widget.ORDERBOOK,
  Widget.HYBRIDORDER,
];

export const WidgetsDefaultConfigMap = new Map<string, any>([
  [
    Widget.WATCHLIST,
    { selectedListId, columns: getDefaultWatchListColumnSetting() },
  ],
  [
    Widget.ORDERBOOK,
    {
      idFi: 144950,
      symbol: 'SBER',
    },
  ],
  [Widget.ASSETS, null],
  [
    Widget.CHART,
    {
      symbol: 'SBER',
      idFi: 144950,
    },
  ],
  [Widget.POSITIONS, null],
  [Widget.OPERATIONS, null],
  [Widget.ORDERS, null],
  [Widget.BALANCE, { columns: defaultBalanceColumnSetting }],
  [Widget.TRADES, defaultTradesFeedProps],
  [
    Widget.HYBRIDORDER,
    {
      idFi: 144950,
      symbol: 'SBER',
    },
  ],
  [
    Widget.INSTRUMENT_INFO,
    {
      idFi: 144950,
      symbol: 'SBER',
    },
  ],
  [
    Widget.NEWS,
    {
      symbol: 'SBER',
      idFi: 144950,
    },
  ],
]);
