import { PreferredTheme } from './types/PreferredTheme';
import { Theme } from './types/Theme';

/**
 * @param preferredTheme предпочтительная тема
 * @param prefersDark предпочитает ли система пользователя темные цвета
 * @returns тему в зависимости от предпочтительной темы и настроек системы
 * */
export function getThemeByPreference(
  preferredTheme: PreferredTheme,
  prefersDark: boolean
): Theme {
  if (preferredTheme === 'auto') {
    return prefersDark ? 'dark' : 'light';
  }

  return preferredTheme;
}
