import cn from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import styles from './Note.module.css';

export type NoteProps = HTMLAttributes<HTMLDivElement>;

export const Note: FC<NoteProps> = (props) => {
  const { children, className, ...restProps } = props;
  const rootClassName = cn(className, styles.root);

  return (
    <div className={rootClassName} {...restProps}>
      <ExclamationCircleMIcon className={styles.icon} />
      <Typography.Text view="secondary-small">{children}</Typography.Text>
    </div>
  );
};
