import cn from 'classnames';
import { Fragment, memo, useEffect, useMemo } from 'react';
import {
  Cell,
  Column as TableColumn,
  ColumnInstance,
  Row,
  TableRowProps,
  useRowSelect,
  useTable,
  UseTableOptions,
} from 'react-table';
import { Typography } from '@alfalab/core-components/typography';

import { IS_MOBILE_PWA } from '@terminal/core/env';

import styles from './Table.module.css';

const filterHiddenColumns = <D extends object>(columns: readonly Column<D>[]) =>
  columns
    .filter((col) => col.isVisible === false)
    .map((col) => col.id || col.accessor) as any;

interface TableProps<D extends object> extends UseTableOptions<D> {
  columns: Column<D>[];
  wrapperClassname?: string;
  tableClassname?: string;
  headerClassName?: string;
  rowSize?: 's' | 'xs';
  selectionColumn?: ColumnInstance<D>;
  getRowProps?: (row: Row<D>) => TableRowProps;
  dataTestId?: string;
  onWheel?: React.WheelEventHandler<HTMLDivElement>;
  desktopVersion?: boolean;
}

export type Column<D extends object> = TableColumn<D> & {
  cellClassName?: false | string;
  headerCellClassName?: false | string;
  className?: string;
  onCellClick?: (cell: Cell<D>) => void;
  isVisible?: boolean;
};

const TableComponent = <D extends object>({
  columns,
  selectionColumn,
  data,
  wrapperClassname,
  tableClassname,
  headerClassName,
  rowSize = 'xs',
  getRowProps,
  dataTestId,
  onWheel,
  desktopVersion,
}: TableProps<D>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
  } = useTable<D>(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: filterHiddenColumns<D>(columns),
      },
    },
    useRowSelect,
    (hooks) => {
      selectionColumn &&
        hooks.visibleColumns.push((columns) => [selectionColumn, ...columns]);
    }
  );

  useEffect(() => {
    setHiddenColumns(filterHiddenColumns<D>(columns));
  }, [setHiddenColumns, columns]);

  const trClassName = useMemo(() => {
    if (IS_MOBILE_PWA) {
      return styles.rowExtraSmallMobile;
    } else {
      return rowSize === 'xs' ? styles.rowExtraSmall : styles.rowSmall;
    }
  }, [rowSize]);

  return (
    <div
      className={cn(styles.wrapper, wrapperClassname)}
      data-test-id={dataTestId}
      onWheel={onWheel}
    >
      <table {...getTableProps()} className={cn(styles.table, tableClassname)}>
        <thead
          className={cn(
            styles.head,
            headerClassName && styles[headerClassName]
          )}
        >
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const headerColumn = column as unknown as Column<D>;

                return (
                  <th
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth },
                    })}
                    className={styles[headerColumn.headerCellClassName || '']}
                  >
                    <Typography.Text view="secondary-large">
                      {column.render('Header')}
                    </Typography.Text>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const customProps = getRowProps?.(row);

            return (
              <Fragment key={row.id}>
                <tr
                  {...row.getRowProps(customProps)}
                  className={cn(
                    styles.row,
                    trClassName,
                    customProps?.className
                  )}
                >
                  {row.cells.map((cell) => {
                    const column = cell.column as Column<D>;

                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            maxWidth: cell.column.maxWidth,
                          },
                        })}
                        onClick={() => column.onCellClick?.(cell)}
                        className={cn(
                          styles.cell,
                          styles[column.cellClassName || ''],
                          column.className
                        )}
                      >
                        <Typography.Text
                          view={
                            desktopVersion ? 'primary-small' : 'secondary-large'
                          }
                          weight={desktopVersion ? 'bold' : undefined}
                        >
                          {cell.render('Cell')}
                        </Typography.Text>
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const genericMemo: <T>(component: T) => T = memo;

export const Table = genericMemo(TableComponent);
