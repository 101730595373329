import { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionShape } from '@alfalab/core-components/select/typings';

import { MAX_CERTIFICATES_COUNT } from '../constants/certificates';
import { CertificateStateType } from '../lib/client/entities';
import { getStringDate } from '../lib/format';

import { shallow, useStore } from '../store';

import { Certificate, CertificateEnrollStep } from '../types/certificate';

export const useCertificates = () => {
  const [
    workingCertificate,
    certificateEnrollId,
    certificates,
    certificateEnrollStep,
    enrollCertificate,
    confirmCertificate,
    rejectCertificate,
  ] = useStore(
    (state) => [
      state.workingCertificate,
      state.certificateEnrollId,
      state.certificates,
      state.certificateEnrollStep,
      state.enrollCertificate,
      state.confirmCertificate,
      state.rejectCertificate,
    ],
    shallow
  );

  const isAllowIssueCertificate =
    Boolean(certificateEnrollId) ||
    certificates.length < MAX_CERTIFICATES_COUNT;

  const isEnrollStepInProgress =
    certificateEnrollStep === CertificateEnrollStep.InProgress;
  const isEnrollStepSuccess =
    certificateEnrollStep === CertificateEnrollStep.Success;

  const isNeedConfirmCertificate =
    !workingCertificate &&
    certificateEnrollId &&
    certificateEnrollStep === CertificateEnrollStep.OnConfirmation;

  const isNeedIssueCertificate =
    !workingCertificate && !isNeedConfirmCertificate;

  const iconColor = workingCertificate
    ? 'var(--color-light-graphic-positive, #2FC26E)'
    : 'var(--color-light-graphic-attention, #F6BF65)';

  const onClickCertificateIssue = useCallback(() => {
    enrollCertificate();
  }, [enrollCertificate]);

  const onClickCertificateConfirm = useCallback(() => {
    if (certificateEnrollId) {
      confirmCertificate(certificateEnrollId);
    }
  }, [confirmCertificate, certificateEnrollId]);

  const onClickCertificateReject = useCallback(() => {
    // Отзываем либо действующий, либо ожидающий подтверждения
    const certificateId =
      workingCertificate?.idCertificate || certificateEnrollId;

    if (certificateId) {
      rejectCertificate(certificateId);
    }
  }, [workingCertificate, certificateEnrollId, rejectCertificate]);

  const startDate = getStringDate(workingCertificate?.parsedPayload?.startDate);
  const endDate = getStringDate(workingCertificate?.parsedPayload?.endDate);

  return {
    workingCertificate,
    certificates,
    iconColor,
    startDate,
    endDate,
    onClickCertificateConfirm,
    onClickCertificateReject,
    onClickCertificateIssue,
    isNeedIssueCertificate,
    isNeedConfirmCertificate,
    isEnrollStepInProgress,
    isEnrollStepSuccess,
    isAllowIssueCertificate,
  };
};

export const useCertificatesAll = (onSuccess?: () => void) => {
  const [
    certificates,
    certificateEnrollStep,
    confirmCertificate,
    rejectCertificate,
  ] = useStore(
    (state) => [
      state.certificates,
      state.certificateEnrollStep,
      state.confirmCertificate,
      state.rejectCertificate,
    ],
    shallow
  );

  const isEnrollStepInProgress =
    certificateEnrollStep === CertificateEnrollStep.InProgress;

  const certificatesCountRest = MAX_CERTIFICATES_COUNT - certificates.length;
  const [selectedCertificate, setSelectedCertificate] = useState<
    Certificate | undefined
  >(certificates[0]);

  useEffect(() => {
    setSelectedCertificate(certificates[0]);
  }, [certificates]);

  const certificatesOptions: OptionShape[] = useMemo(
    () =>
      certificates.map((certificate) => {
        const key = certificate.idCertificate.toString();

        return {
          key,
          content: `${key}, ${certificate.description}`,
        };
      }),
    [certificates]
  );

  const selectedOptionKey = useMemo(
    () => selectedCertificate?.idCertificate.toString(),
    [selectedCertificate]
  );

  const selectedCertificateStateStr = useMemo(() => {
    switch (selectedCertificate?.state) {
      case CertificateStateType.Ready:
        return 'Сертификат готов к использованию';
      case CertificateStateType.Withdrawing:
        return 'Сертификат отозван пользователем или закончился срок действия';
      case CertificateStateType.Verifying:
        return 'Сертификат проходит проверку на соответствие требованиям';
      case CertificateStateType.Certifying:
        return 'Сертификат ожидает удостоверения';
      case CertificateStateType.Malformed:
        return 'Сертификат не удовлетворяет требованиям';
      default:
        return null;
    }
  }, [selectedCertificate]);

  const onClickOptionSelect = useCallback(
    ({ selected }: { selected: OptionShape | null }) => {
      if (selected) {
        const certificate = certificates.find(
          (certificate) => certificate.idCertificate.toString() === selected.key
        );

        if (certificate) {
          setSelectedCertificate(certificate);
        }
      }
    },
    [certificates, setSelectedCertificate]
  );

  const onClickCertificateConfirm = useCallback(() => {
    if (selectedCertificate) {
      confirmCertificate(selectedCertificate.idCertificate);
    }
  }, [selectedCertificate, confirmCertificate]);

  const onClickCertificateReject = useCallback(() => {
    if (selectedCertificate) {
      rejectCertificate(selectedCertificate.idCertificate, onSuccess);
    }
  }, [selectedCertificate, rejectCertificate, onSuccess]);

  const iconColor = useMemo(() => {
    switch (selectedCertificate?.state) {
      case CertificateStateType.Ready:
        return 'var(--color-light-graphic-positive, #2FC26E)';
      case CertificateStateType.Certifying:
        return 'var(--color-light-graphic-attention, #F6BF65)';
      default:
        return '#8D8D93';
    }
  }, [selectedCertificate]);

  const startDate = getStringDate(
    selectedCertificate?.parsedPayload?.startDate
  );
  const endDate = getStringDate(selectedCertificate?.parsedPayload?.endDate);

  return {
    certificates,
    selectedCertificate,
    iconColor,
    isEnrollStepInProgress,
    certificatesCountRest,
    certificatesOptions,
    selectedOptionKey,
    selectedCertificateStateStr,
    onClickOptionSelect,
    onClickCertificateConfirm,
    onClickCertificateReject,
    startDate,
    endDate,
  };
};
