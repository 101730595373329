import React, { FC } from 'react';

import { plural } from '@terminal/core/lib/plural';
import { useStore } from '@terminal/core/store';
import { trackPriceListModalStateSelector } from '@terminal/core/store/selectors/trackPrice/trackPriceListModalStateSelector';
import { trackPricesForTriggerSelector } from '@terminal/core/store/selectors/trackPrice/trackPricesForTriggerSelector';

import { AlertNotifyModal } from '../../../legacy/components/AlertNotifyModal';

export const AlertNotifications: FC = () => {
  const { open: openListModal } = useStore(trackPriceListModalStateSelector);
  const trackPricesForTrigger = useStore(trackPricesForTriggerSelector);
  const dismissTrackPrice = useStore((state) => state.dismissTrackPrice);
  const snoozeTrackPrice = useStore((state) => state.snoozeTrackPrice);
  const [trackPriceForTrigger] = trackPricesForTrigger;
  const count = trackPricesForTrigger.length;
  const note = `Сработали одновременно ${count} ${plural(
    ['алерт', 'алерта', 'алертов'],
    count
  )}`;

  const handleModalClose = () => {
    snoozeTrackPrice(trackPriceForTrigger);
  };

  const handleDismissButtonClick = () => {
    snoozeTrackPrice(trackPriceForTrigger);
    dismissTrackPrice(trackPriceForTrigger);
  };

  const handleOpenTableButtonClick = () => {
    trackPricesForTrigger.forEach((trackPrice) => {
      snoozeTrackPrice(trackPrice);
    });
    openListModal();
  };

  if (!trackPriceForTrigger) {
    return null;
  }

  return (
    <AlertNotifyModal
      open
      withSound={trackPriceForTrigger.settings?.notify}
      idFi={trackPriceForTrigger.IdFI}
      initialPrice={trackPriceForTrigger.InitialPrice}
      note={count > 1 ? note : undefined}
      targetPrice={trackPriceForTrigger.TargetPrice}
      onClose={handleModalClose}
      onOpenListButtonClick={handleOpenTableButtonClick}
      onDismissButtonClick={handleDismissButtonClick}
    >
      {trackPriceForTrigger.settings?.title}
    </AlertNotifyModal>
  );
};
