import { useEffect, useMemo, useState } from 'react';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Loader } from '@alfalab/core-components/loader';
import { Select, SelectProps } from '@alfalab/core-components/select';
import { Field } from '@alfalab/core-components/select/components/field';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { SecuritiesPosition } from '@terminal/core/lib/rest/lkSecurities';

import { isPositionsEuqal, sortPlaces } from '../../shared';
import { TooltipPositionAccCode, TooltipPositionPlace } from '../Tooltips';

interface SecurityPlaceSectionProps {
  title?: string;
  positions?: SecuritiesPosition[];
  positionsPending: boolean;
  selectedPosition: SecuritiesPosition | null;
  onPositionSelect: (pos: SecuritiesPosition) => void;
}

export const SecurityPlaceSection = ({
  title,
  positions,
  positionsPending,
  selectedPosition,
  onPositionSelect,
}: SecurityPlaceSectionProps) => {
  const [subaccount, setSubaccount] = useState(selectedPosition?.accCode || '');
  const [market, setMarket] = useState(
    selectedPosition?.placeDescription || ''
  );

  const subaccountOptions = useMemo(() => {
    if (positions) {
      return positions
        .filter((position) => position.accCode.indexOf('-000') > 0)
        .map((position) => position.accCode)
        .filter((accCode, index, self) => self.indexOf(accCode) === index)
        .sort()
        .map((accCode: string) => ({
          content: accCode.split('-')[0],
          key: accCode,
        }));
    }

    return [];
  }, [positions]);

  // выбор первого субсчёта из них, если текущий не входит в доступные
  useEffect(() => {
    if (subaccountOptions.length) {
      const selected = subaccountOptions.find((opt) => opt.key === subaccount);

      if (!selected) {
        setSubaccount(subaccountOptions[0].key);
      }
    }
  }, [subaccountOptions, subaccount]);

  const marketOptions = useMemo(() => {
    if (positions) {
      return positions
        .filter((position) => position.accCode === subaccount)
        .sort(sortPlaces)
        .map((position) => position.placeDescription)
        .filter(
          (placeDescription, index, self) =>
            self.indexOf(placeDescription) === index
        )
        .map((placeDescription: string) => ({
          content: placeDescription,
          key: placeDescription,
        }));
    }

    return [];
  }, [positions, subaccount]);

  // выбор первого доступного рынка, если текущий не входит в доступные
  useEffect(() => {
    if (marketOptions.length) {
      const selected = marketOptions.find((opt) => opt.key === market);

      if (!selected) {
        setMarket(marketOptions[0].key);
      }
    }
  }, [marketOptions, market]);

  useEffect(() => {
    if (subaccount && market && positions) {
      const position = positions.find(
        (pos) => pos.accCode === subaccount && pos.placeDescription === market
      );

      if (position && !isPositionsEuqal(position, selectedPosition)) {
        onPositionSelect(position);
      }
    }
  }, [subaccount, market, positions, selectedPosition, onPositionSelect]);

  const handleSubaccountChange: SelectProps['onChange'] = ({ selected }) => {
    setSubaccount(selected?.key || '');
  };

  const handleMarketChange: SelectProps['onChange'] = ({ selected }) => {
    setMarket(selected?.key || '');
  };

  return (
    <>
      <Typography.Text view="primary-small" weight="bold" tag="p">
        {title || 'Откуда'}
      </Typography.Text>
      <Space fullWidth direction="vertical">
        <Row>
          <Col width={6}>
            <Select
              label="Счёт"
              labelView="outer"
              placeholder="Выберите счёт"
              size="s"
              block
              options={subaccountOptions}
              selected={subaccount}
              onChange={handleSubaccountChange}
              Field={(props) => (
                <Field
                  {...props}
                  leftAddons={positionsPending && <Loader />}
                  rightAddons={<TooltipPositionAccCode />}
                />
              )}
            />
          </Col>
          <Col width={6}>
            <Select
              label="Место хранения"
              labelView="outer"
              placeholder="Выберите место хранения"
              size="s"
              block
              options={marketOptions}
              selected={market}
              onChange={handleMarketChange}
              Field={(props: FieldProps) => (
                <Field
                  {...props}
                  leftAddons={positionsPending && <Loader />}
                  rightAddons={<TooltipPositionPlace />}
                />
              )}
            />
          </Col>
        </Row>
      </Space>
    </>
  );
};
