import React, { FC, Profiler, ProfilerProps } from 'react';

import { IS_DEVELOPMENT } from '@terminal/core/env';

import { featureFlags } from '../../../shared/lib/featureFlags';
import { CallTracker } from './CallTracker';

const callTracker = new CallTracker();

const handleProfilerRender: ProfilerProps['onRender'] = () => {
  callTracker.track();
};

// @ts-expect-error для доступа к трекеру из консоли
window.__renderTracker = callTracker;

/**
 * Подсчитывает количество рендеров поддерева в целях отладки.
 * Работает в dev-окружении при включенном фича-флаге `PRO_RENDER_COUNT_PROFILER_ENABLED`.
 * Трекер доступен в консоли браузера в переменной `window.__renderTracker`:
 * - `window.__renderTracker.countLastMinuteCalls()` — количество рендеров за последнюю минуту
 * - `window.__renderTracker.countAvgCallsPerMinute()` — среднее количество рендеров в минуту
 * - `window.__renderTracker.countLastSecondCalls()` — количество рендеров за последнюю секунду
 * - `window.__renderTracker.countAvgCallsPerSecond()` — среднее количество рендеров в секунду
 * */
export const RenderCountProfiler: FC = (props) => {
  const { children } = props;

  if (
    !IS_DEVELOPMENT ||
    !featureFlags.get('PRO_RENDER_COUNT_PROFILER_ENABLED')
  ) {
    return <>{children}</>;
  }

  return (
    <Profiler id="RenderCountProfiler" onRender={handleProfilerRender}>
      {children}
    </Profiler>
  );
};
