import { sub } from 'date-fns';

import { ChatMessage } from '../../types';

export const messageHistoryMock = (): ChatMessage[] => [
  {
    idChatMessage: 1,
    idSender: 1,
    senderNickname: 'Анастасия',
    idRecipient: 111,
    recipientNickname: 'Nickname',
    idChatGroup: '1',
    dtChatMessage: sub(new Date(), {
      days: 2,
      hours: 2,
      minutes: 11,
    }),
    text: 'Здравствуйте, задайте ваш вопрос.',
    version: '11111111111111111111111',
    operation: 0,
    idBrokerRecommendation: 0,
    brokerRecommendations: [],
  },
];
