import { ReactNode } from 'react';
import { ButtonMobile } from '@alfalab/core-components/button/mobile';
import { ModalMobile } from '@alfalab/core-components/modal/mobile';
import { SystemMessageMobile } from '@alfalab/core-components/system-message/mobile';

import cup1x from './cup1x.png';
import cup2x from './cup2x.png';

import styles from './NetworkErrorModal.module.css';

interface Props {
  open: boolean;
  onClose: () => void;
  header: ReactNode;
}

export const NetworkErrorModal = (props: Props) => {
  const { open, onClose, header } = props;

  return (
    <ModalMobile
      open={open}
      onClose={onClose}
      contentClassName={styles.wrapper}
      transitionProps={{ timeout: 0 }}
    >
      {header}
      <ModalMobile.Content className={styles.content}>
        <SystemMessageMobile fullHeight>
          <SystemMessageMobile.Graphic>
            <img
              width={80}
              height={80}
              src={cup1x}
              srcSet={`${cup2x} 2x`}
              alt=""
            />
          </SystemMessageMobile.Graphic>
          <SystemMessageMobile.Title>
            Не получилось загрузить
          </SystemMessageMobile.Title>
          <SystemMessageMobile.Subtitle>
            Уже знаем, в чём дело, и чиним.
            <br />
            Попробуйте зайти позже
          </SystemMessageMobile.Subtitle>
          <SystemMessageMobile.Controls>
            <ButtonMobile
              view="secondary"
              size="m"
              color="black"
              onClick={onClose}
            >
              Понятно
            </ButtonMobile>
          </SystemMessageMobile.Controls>
        </SystemMessageMobile>
      </ModalMobile.Content>
    </ModalMobile>
  );
};
