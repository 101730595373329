import { ECurrencyId } from '../../types/currencyId';

/**
 * @returns Цену валюты ликвидного рынка
 * */
export function getMarketCurPrice(
  Price: number,
  PrevPrice: number,
  PriceStep: number,
  PriceStepCost: number,
  idObjectCurrency?: number
): number {
  if (!idObjectCurrency || idObjectCurrency === ECurrencyId.RUR) {
    return 1;
  }

  if (Price === 0) {
    return (PriceStepCost / PriceStep) * PrevPrice;
  }

  return (PriceStepCost / PriceStep) * Price;
}
