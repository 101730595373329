import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import Decimal from 'decimal.js';
import fromExponential from 'from-exponential';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import round from 'lodash/round';

import { MINORITY } from '../constants/ui';
import { UniversalExchangeCode } from './client/entities';
import { plural } from './plural';

import { ObjectTypes } from '../types/core';
import { OrderDimensionEnum } from '../types/ui';

const DATE_FORMAT = 'dd.LL.uuuu';
const TIME_FORMAT = 'HH:mm:ss';

/**
 * Функция для получения строки денежной суммы в виде 192.3 млн.
 */
export const getRoundedBigSum = (
  number: number | Number | bigint | BigInt
): [number, string | undefined] => {
  let bigInt =
    typeof number !== 'bigint'
      ? BigInt(Math.round(number as number).valueOf())
      : number;

  const isNegative = bigInt < 0;

  if (isNegative) {
    bigInt *= -1n;
  }

  if (bigInt >= 1000000000000) {
    return [
      round(Number(bigInt / 1000000000n) / 1000, 2) * (isNegative ? -1 : 1),
      'трлн.',
    ];
  }

  if (bigInt >= 1000000000) {
    return [
      round(Number(bigInt / 1000000n) / 1000, 2) * (isNegative ? -1 : 1),
      'млрд.',
    ];
  }

  if (bigInt >= 1000000) {
    return [
      round(Number(bigInt / 1000n) / 1000, 2) * (isNegative ? -1 : 1),
      'млн.',
    ];
  } else {
    return [Number(number) * (isNegative ? -1 : 1), undefined];
  }
};

export const convertPostfixToChar = (postfix?: string) => {
  if (postfix === 'трлн.') {
    return 'T';
  }

  if (postfix === 'млрд.') {
    return 'B';
  }

  if (postfix === 'млн.') {
    return 'M';
  }

  return undefined;
};

/**
 * Функция для сокращения чисел до аббревиатур (1 K, 1 M и т.д.)
 */
export const abbreviateNumber = (value: string) => {
  const suffixes = [' ', 'K', 'M', 'B', 'T'];
  const suffixNum = Math.floor(String(value).length / 3);
  let shortValue: number | string = parseFloat(
    (suffixNum !== 0
      ? Number(value) / Math.pow(1000, suffixNum)
      : Number(value)
    ).toPrecision(2)
  );

  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }

  return `${shortValue} ${suffixes[suffixNum]}`;
};

/**
 * Функция подсчета кол-ва знаков после запятой
 */
export const countDecimals = (value?: number) => {
  if (value) {
    return fromExponential(value).split('.')[1]?.length || 0;
  }

  return 0;
};

export const getStepDecimals = (priceStep?: number) => {
  const decimalsNumber = countDecimals(priceStep);
  const priceDecimals = !isUndefined(priceStep)
    ? //Подсчитываем кол-во знаков после запятой и возводим 10 в эту степень для определения minority компонента Amount
      Math.pow(10, decimalsNumber)
    : MINORITY;

  return { decimalsNumber, priceDecimals };
};

export enum ERoundType {
  ROUND = 'round',
  CEIL = 'ceil',
  FLOOR = 'floor',
}
/**
 * Функция округляет число до шага.
 */
export const roundToStep = (
  num: number,
  step: number,
  type = ERoundType.ROUND
): number =>
  step === 0 ? num : new Decimal(num).div(step)[type]().mul(step).toNumber();

/**
 * Функция округляет числа с плавающей запятой.
 * Например выражение 23.5 - 20.4 - 0.001 = 3.0990000000000015, однако функция вернет корректное значение 3.099.
 */
export const roundFloatNumber = (value: number) =>
  parseFloat(value.toPrecision(12));

/**
 * Функция округляет цену к шагу цены инструмента.
 */
export const roundPrice = (price?: number, priceStep?: number) =>
  Number(roundToStep(price || 0, priceStep || 0.01));

/**
 * Функция для подсчета размерности минорных единиц чисел
 */
export const getMinority = (value?: number) => {
  if (isNumber(value)) {
    return Math.pow(10, countDecimals(value));
  }

  return 0;
};

/**
 * Функция для форматирования даты в DD.MM.YYYY
 */
export const getStringDate = (date?: Date | string): string => {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    date = parseISO(date);
  }

  return format(date, DATE_FORMAT);
};

/**
 * Функция для форматирования времени в HH:MM:SS
 */
export const getStringTime = (date?: Date) =>
  date ? format(date, TIME_FORMAT) : '';

export function formatNumber(number: number, length: number): number {
  return Math.round(number * Math.pow(10, length)) / Math.pow(10, length);
}

export const formatNumberOrNull = (value: number | null, length: number) =>
  value ? formatNumber(value, 2) : value;

export function percentTwo(number: number): string {
  return `${formatNumber(number, 2)}%`;
}

export function formatOrderDate(
  orderDate: Date,
  restFormat: string = 'yyyy HH:mm'
): string {
  // прикол в том что месяц в date-fns не такой как у нас на сервере
  // у date-fns есть точки и местами в четыре буквы. И второй прикол что кажется шарп тоже на разных девайсах себя по-разному ведет
  // короч поэтому тупо хардкодим месяца
  const months = [
    'янв',
    'фев',
    'мар',
    'апр',
    'май',
    'июн',
    'июл',
    'авг',
    'сен',
    'окт',
    'ноя',
    'дек',
  ];
  const days = format(orderDate, 'dd', {
    locale: ru,
  }).toLowerCase();

  const month = months[orderDate.getMonth()];

  const rest = format(orderDate, restFormat, {
    locale: ru,
  }).toLowerCase();

  return `${days} ${month} ${rest}`;
}

export function formatDateBefore(
  orderDate: Date,
  restFormat: string = 'yyyy HH:mm'
): string {
  // прикол в том что месяц в date-fns не такой как у нас на сервере
  // у date-fns есть точки и местами в четыре буквы. И второй прикол что кажется шарп тоже на разных девайсах себя по-разному ведет
  // короч поэтому тупо хардкодим месяца
  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];
  const days = format(orderDate, 'dd', {
    locale: ru,
  }).toLowerCase();

  const month = months[orderDate.getMonth()];

  const rest = format(orderDate, restFormat, {
    locale: ru,
  }).toLowerCase();

  return `${days} ${month} ${rest}`;
}

/**
 * Функция деления с устранением ошибок плавающей точки.
 */
export const divideFloats = (a: number, b: number) => {
  const decimalPower = Math.max(countDecimals(a), countDecimals(b));

  return (a * Math.pow(10, decimalPower)) / (b * Math.pow(10, decimalPower));
};

export const getDimensionedUnit = (
  count: number,
  lot: number | undefined,
  orderDimension: OrderDimensionEnum | undefined
) => {
  if (lot && orderDimension === OrderDimensionEnum.LOT) {
    return divideFloats(count, lot);
  }

  return count;
};

export const getGiftedString = (count: number): string =>
  plural(['подарочная', 'подарочные', 'подарочных'], count);

export const getAssetUnits = (
  count: number,
  orderDimension?: OrderDimensionEnum,
  idObjectType?: ObjectTypes
) => {
  if (orderDimension === OrderDimensionEnum.LOT) {
    return plural(['лот', 'лота', 'лотов'], count);
  }

  switch (idObjectType) {
    // Акции
    case ObjectTypes.AO:
    case ObjectTypes.AP:
      return plural(['акция', 'акции', 'акций'], count);
    // Облигации
    case ObjectTypes.BC:
    case ObjectTypes.BB:
    case ObjectTypes.BG:
    case ObjectTypes.BS:
    case ObjectTypes.BM:
    case ObjectTypes.BR:
    case ObjectTypes.BI:
    case ObjectTypes.BE:
    case ObjectTypes.BF:
    case ObjectTypes.SB:
      return plural(['облигация', 'облигации', 'облигаций'], count);
    // ФОНДЫ
    case ObjectTypes.PO:
    case ObjectTypes.PI:
    case ObjectTypes.PZ:
      return plural(['пай', 'пая', 'паев'], count);
    //Валюта
    case ObjectTypes.CC:
      return 'ед';
    //Фьючерсы и опционы
    case ObjectTypes.FR:
    case ObjectTypes.FD:
    case ObjectTypes.FP:
    case ObjectTypes.VF:
    case ObjectTypes.VM:
    case ObjectTypes.OE:
    case ObjectTypes.PP:
    case ObjectTypes.PM:
    case ObjectTypes.CP:
    case ObjectTypes.CM:
      return plural(['лот', 'лота', 'лотов'], count);
    // РАСПИСКИ
    case ObjectTypes.RA:
    case ObjectTypes.RG:
      return plural(['расписка', 'расписки', 'расписок'], count);
    default:
      return 'шт';
  }
};

export const getExchangeName = (code: string | undefined) => {
  switch (code) {
    case UniversalExchangeCode.MICEX:
    case UniversalExchangeCode.SELT:
      return 'Московская биржа';
    case UniversalExchangeCode.SPBEX:
    case UniversalExchangeCode.SPBR:
      return 'СПБ Биржа';
    case UniversalExchangeCode.ECL:
      return 'Euroclear';
    case UniversalExchangeCode.OTC:
      return 'Внебиржевой рынок OTC';
    default:
      return 'Неизвестная биржа';
  }
};

export const getShortExchangeName = (code: string | undefined) => {
  switch (code) {
    case UniversalExchangeCode.MICEX:
    case UniversalExchangeCode.SELT:
      return 'МБ';
    case UniversalExchangeCode.SPBEX:
    case UniversalExchangeCode.SPBR:
      return 'СПБ';
    case UniversalExchangeCode.ECL:
      return 'ECLR';
    case UniversalExchangeCode.OTC:
      return 'OTC';
    default:
      return 'UNKN';
  }
};

/**
 * Форматирует номер телефона
 * @param phone номер телефон
 * @param mask скрывать часть номера "звездочками"
 * @param hyphenate использовать дефисы для разделения
 * @returns
 */
export function phoneFormat(
  phone: string,
  mask: boolean = false,
  hyphenate: boolean = true
): string {
  const cleaned = String(phone).replace(/\D/g, '');
  const match = cleaned.match(/^7?(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    if (mask) {
      match[1] = '···';
      match[2] = '···';
    }

    const divider = hyphenate ? '-' : ' ';

    return `+7 ${match[1]} ${match[2]}${mask ? ' ' : divider}${
      match[3]
    }${divider}${match[4]}`;
  }

  return '+' + phone;
}
