import cn from 'classnames';
import { memo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon'; // TODO Поменять на ArrowDownTriangleMIcon при обновлении либы
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon'; // TODO Поменять на ArrowUpTriangleMIcon при обновлении либы
import { ChevronForwardMIcon } from '@alfalab/icons-glyph/ChevronForwardMIcon';

import { SymbolCell } from '@terminal/common/features-DEPRECATED/Table';
import {
  BrokerRecommendationFlags,
  OrderDirection,
  OrderType,
} from '@terminal/core/lib/client/entities';

import { ROUTE } from '../routes';

import { useRecommendationOrder } from '../hooks/useRecommendationOrder';

import { BrokerRecommendation } from '../types';

import styles from './BrokerRecommendationBubble.module.css';
import messageStyles from './Message.module.css';

const getOperationText = (
  direction: OrderDirection,
  orderType: OrderType,
  mobile: boolean
) => {
  const orderTypeAbbreviation = Object.entries(OrderType).find(
    ([id]) => String(orderType) === id
  )?.[1];

  switch (direction) {
    case OrderDirection.Buy:
      return (
        <div className={styles.directionRow}>
          <ArrowUpCompactXsIcon
            width={10}
            height={10}
            color="#13A463"
            className={styles.directionIcon}
          />
          <Typography.Text
            view={mobile ? 'secondary-medium' : 'secondary-small'}
          >
            Покупка
          </Typography.Text>
          {orderTypeAbbreviation && (
            <Typography.Text
              view={mobile ? 'secondary-medium' : 'secondary-small'}
              className={styles.operationType}
            >
              {orderTypeAbbreviation}
            </Typography.Text>
          )}
        </div>
      );
    case OrderDirection.Sell:
      return (
        <div className={styles.directionRow}>
          <ArrowDownCompactXsIcon
            width={10}
            height={10}
            color="#D91D0B"
            className={styles.directionIcon}
          />
          <Typography.Text
            view={mobile ? 'secondary-medium' : 'secondary-small'}
          >
            Продажа
          </Typography.Text>
          {orderTypeAbbreviation && (
            <Typography.Text
              view={mobile ? 'secondary-medium' : 'secondary-small'}
              className={styles.operationType}
            >
              {orderTypeAbbreviation}
            </Typography.Text>
          )}
        </div>
      );
    default:
      return null;
  }
};

interface MessageProps {
  isMe: boolean;
  recommendation: BrokerRecommendation;
  mobile: boolean;
}

export const BrokerRecommendationBubble = memo(
  ({ isMe, recommendation, mobile }: MessageProps) => {
    const navigate = useNavigate();
    const contentRef = useRef<HTMLDivElement>(null);

    const allowRedirectToOrder = mobile;

    const recommendedOrder = useRecommendationOrder(recommendation);

    const handleRedirectToOrder = () => {
      if (!allowRedirectToOrder) {
        return;
      }

      if (recommendedOrder?.idFI) {
        let op;

        if (recommendedOrder.flags === BrokerRecommendationFlags.OpenPosition) {
          op = 'buy';
        } else if (
          recommendedOrder.flags === BrokerRecommendationFlags.ClosePosition
        ) {
          op = 'sell';
        } else {
          return;
        }

        navigate(
          `${ROUTE.BROKER_CHAT.INSTRUMENT_TRADES.FULL_PATH.replace(
            ':idFi',
            String(recommendedOrder.idFI)
          )}?side=${op}&orderType=${recommendedOrder.idOrderType}&quantity=${
            recommendedOrder.quantity
          }`,
          {
            state: { idFi: recommendedOrder.idFi },
          }
        );

        // TODO Возврат к чату на то же сообщение
      }
    };

    return (
      <div
        className={cn(
          styles.message,
          isMe ? messageStyles.myMessage : messageStyles.elseMessage
        )}
        ref={contentRef}
        onClick={handleRedirectToOrder}
      >
        <div className={messageStyles.messageBubbleContainer}>
          <div className={messageStyles.messageBubble}>
            <div className={styles.recommendationContainer}>
              <SymbolCell
                idObject={recommendedOrder?.idObject}
                symbolObject={recommendedOrder?.symbolObject}
                onlyIcon
                isMobile
              />
              <div />
              <div>
                <div>
                  <Typography.Text
                    view={mobile ? 'primary-small' : 'secondary-large'}
                  >
                    {recommendedOrder?.symbolObject}
                  </Typography.Text>
                  <Typography.Text
                    view={mobile ? 'primary-small' : 'secondary-large'}
                    className={styles.marketBoard}
                  >
                    {recommendedOrder?.nameMarketBoard}
                  </Typography.Text>
                </div>
                {getOperationText(
                  recommendedOrder.buySell,
                  recommendedOrder.idOrderType,
                  mobile
                )}
                <div className={styles.directionRow}>
                  <Amount
                    className={styles.amount}
                    value={
                      recommendedOrder.price
                        ? recommendedOrder.price * 100
                        : recommendedOrder.price
                    }
                    view="withZeroMinorPart"
                    transparentMinor={false}
                    currency={recommendedOrder.currencyCode}
                    minority={100}
                  />
                </div>
              </div>
              <div />
              {allowRedirectToOrder && <ChevronForwardMIcon color="#747474" />}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
