/**
 * @returns Накопленный купонный доход (НКД)
 * */
export function getNKDRur(
  TorgPos: number,
  accruedInt: number,
  NominalCurPrice: number
): number {
  return TorgPos * accruedInt * NominalCurPrice;
}
