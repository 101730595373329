import { ReactNode } from 'react';

import { USE_PASSPORT_AUTH } from '@terminal/core/env';

type Props = {
  children: ReactNode;
};

// HOC для того чтобы не рендерить компонент, если авторизация не через паспорт
// Нужно для компонентов, которые ходят в REST
export const RestLock = ({ children }: Props) => {
  if (USE_PASSPORT_AUTH) {
    return <>{children}</>;
  }

  return null;
};
