import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getQualPdf } from '@terminal/core/lib/rest/lkQualInvestor';
import { NotificationType } from '@terminal/core/types/ui';

export const useGetQualPdf = () => {
  const addNotification = useNotification();

  return useMutation(getQualPdf, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Ошибка запроса уведомления',
      });
    },
  });
};
