import { useMemo } from 'react';

import { useStore } from '@terminal/core/store';
import { objectByIdFiSelector } from '@terminal/core/store/selectors/core/objectByIdFiSelector';
import { displayInstrumentTypeSelector } from '@terminal/core/store/selectors/settings/displayInstrumentTypeSelector';
import { trackPricesSelector } from '@terminal/core/store/selectors/trackPrice/trackPricesSelector';
import { TrackPrice } from '@terminal/core/types/trackPrice';

type TrackPriceExtended = TrackPrice & {
  displayName: string;
};

/**
 * @returns список расширенных алертов
 * (с дополнительным полем `displayName` для фильтрации и сортировки)
 * */
export function useTrackPricesExtended(): TrackPriceExtended[] {
  const trackPrices = useStore(trackPricesSelector);
  const objectByIdFi = useStore(objectByIdFiSelector);
  const displayInstrumentType = useStore(displayInstrumentTypeSelector);

  return useMemo(
    () =>
      trackPrices.map((trackPrice) => {
        const { nameObject = '', symbolObject = '' } =
          objectByIdFi(trackPrice.IdFI) || {};

        return {
          ...trackPrice,
          displayName:
            displayInstrumentType === 'name' ? nameObject : symbolObject,
        };
      }),
    [displayInstrumentType, objectByIdFi, trackPrices]
  );
}
