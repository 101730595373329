import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { LightningMIcon } from '@alfalab/icons-glyph/LightningMIcon';

import { trackPersBrokerChatEnter } from '@terminal/core/lib/analytics';

import {
  MessagesList,
  messagesListEventEmitter,
  MessagesListEvents,
} from '../components/MessagesList';
import { ChatRoomLayout } from '../components/rooms/ChatRoomLayout';
import { BrokerRecommendationService } from '../services/broker';

import { useStore } from '../store/useStore';

import styles from './ChatScreen.module.css';

type RouteParams = {
  id: string;
};

type Props = {
  id?: string;
  mobile?: boolean;
  onBack: () => void;
};

const PERS_BROKER_CHAT_ID = '142';
const PERS_BROKER_READ_ONLY_CHAT_ID = '100';

export const BrokerChat = ({ id: propId, mobile = false, onBack }: Props) => {
  const { id: routeId } = useParams<keyof RouteParams>() as RouteParams;

  const id = propId || routeId;

  const [
    messages,
    setOpenedChatId,
    setUnreadMsgCount,
    setNewMessages,
    chatGroups,
  ] = useStore(
    (store) => [
      store.messages[id],
      store.setOpenedChatId,
      store.setUnreadMsgCount,
      store.setNewMessages,
      store.chatGroups,
    ],
    shallow
  );

  const setLastVisit = useCallback((id: string) => {
    const currentLastVisits = localStorage.getItem('chatLastVisit');

    localStorage.setItem(
      'chatLastVisit',
      JSON.stringify({
        ...(currentLastVisits ? JSON.parse(currentLastVisits) : {}),
        [id]: new Date(),
      })
    );
  }, []);

  useEffect(() => {
    if (id === PERS_BROKER_CHAT_ID) {
      trackPersBrokerChatEnter();
    }
  }, [id]);

  const handleSendMessage = useCallback(
    async (text: string) => {
      await BrokerRecommendationService.sendMessage(text, id, setNewMessages);
      messagesListEventEmitter.emit(MessagesListEvents.MSG_LIST_SCROLL_BOTTOM);
    },
    [id, setNewMessages]
  );

  // При входе в комнату сбрасываем непрочитанные
  useEffect(() => {
    setUnreadMsgCount(id, 0);
  }, [setUnreadMsgCount, id]);

  useEffect(() => {
    setOpenedChatId(id);

    return () => setOpenedChatId(null);
  }, [id, setOpenedChatId]);

  // Обновляем дату последнего посещения комнаты
  useEffect(() => {
    setLastVisit(id);

    return () => setLastVisit(id);
  }, [id, setLastVisit]);

  return (
    <ChatRoomLayout
      mobile={mobile}
      chatRoomTitle={chatGroups[id]?.nameChatGroup || 'Брокер РФ'}
      chatRoomConnection={
        <>
          <LightningMIcon
            height={12}
            width={12}
            className={styles.onlineBadge}
          />
          На связи
        </>
      }
      sendMessage={
        id !== PERS_BROKER_READ_ONLY_CHAT_ID ? handleSendMessage : undefined
      }
      onBack={onBack}
    >
      <MessagesList mobile={mobile} readMessages={messages} />
    </ChatRoomLayout>
  );
};
