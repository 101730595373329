// временный костыль для перехода на церберус
export const normalizeLkAPIURI = (url: string) =>
  url.replace('/api/lk/api', '/api/lk');

export const normalizeLkAPIURIS = (urlsObject: Record<string, string>) => {
  for (const key in urlsObject) {
    if (typeof urlsObject[key] === 'string') {
      urlsObject[key] = normalizeLkAPIURI(urlsObject[key]);
    }
  }

  return urlsObject;
};
