import React, { FC, ReactNode, useState } from 'react';

import { NEW_TRACK_PRICE_DEFAULT_ID_FI } from '@terminal/core/constants/trackPriceSettings';
import { Widget } from '@terminal/core/types/layout';
import { FinInstrAutocomplete } from '@terminal/widgets/features/FinInstrAutocomplete';

export type FinInstrumentFormProps = {
  children: (idFi: number) => ReactNode;
};

export const FinInstrumentForm: FC<FinInstrumentFormProps> = (props) => {
  const { children } = props;
  const [idFi, setIdFi] = useState<number>(NEW_TRACK_PRICE_DEFAULT_ID_FI);

  return (
    <>
      <FinInstrAutocomplete
        widget={Widget.CHART}
        idFi={idFi}
        isSmall={false}
        label="Ценная бумага"
        labelView="outer"
        onChange={(fi) => {
          setIdFi(fi.idFI);
        }}
      />
      {children(idFi)}
    </>
  );
};
