import { ConfigStyle } from '@terminal/desktop/entities/OnboardingConfig';

export const MAP_STYLE_TO_WIDGETS_LIST: Record<ConfigStyle, string[]> = {
  [ConfigStyle.BEGINNER]: [
    'Инструменты',
    'График',
    'Стакан',
    'Заявки',
    'Позиции',
    'Операции',
  ],
  [ConfigStyle.SWING]: [
    'Однострочные котировки',
    'Стакан',
    'Заявки',
    'Позиции',
    'График (D)',
  ],
  [ConfigStyle.DAYTRADE]: [
    'Заявки',
    'Однострочные котировки',
    'Лента сделок',
    'Стакан',
    'График (H1)',
    'График (М5)',
  ],
  [ConfigStyle.SCALPING]: [
    'Лента сделок',
    'Однострочные котировки',
    'Скальперский стакан',
    'График (5М)',
    'Заявки',
  ],
};
