import cn from 'classnames';
import { PlateDesktop } from '@alfalab/core-components/plate/desktop';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { StatusBadge } from '@alfalab/core-components-status-badge';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import styles from './ShortLineBanner.module.css';

interface ServiceLineBannerProps {
  message: string;
  short?: boolean;
  className?: string;
  shortText?: string;
}

export const ShortLineBanner = ({
  short,
  message,
  className,
  shortText,
}: ServiceLineBannerProps) => (
  <PlateDesktop
    view="attention"
    border={false}
    contentClassName={styles.content}
    className={cn(styles.plate, className)}
    leftAddons={
      <div className={styles.addon}>
        <StatusBadge
          className={styles.badge}
          size={16}
          view="attention-alert"
        />
      </div>
    }
    subAddons={
      <div className={styles.addon}>
        <TooltipDesktop content={message}>
          <InformationCircleLineSIcon />
        </TooltipDesktop>
      </div>
    }
    rounded={false}
  >
    <Typography.Text className={styles.message} view="secondary-large">
      {short ? shortText : message}
    </Typography.Text>
  </PlateDesktop>
);
