/**
 * Типизированная надстройка над local/session storage. Использует JSON для хранения.
 *
 * const num = localStorage.getItem('key', 5);      => number, 5 если записи нет
 * const val = localStorage.getItem<number>('key'); => number | undefined
 * const wtf = localStorage.getItem('key');         => unknown, так делать не надо
 *
 */

export class Storage {
  constructor(private readonly api: globalThis.Storage) {}
  getItem<T>(name: string, defaultValue: T): T;
  getItem<T>(name: string, defaultValue: void): T | undefined;
  getItem<T>(name: string, defaultValue: T | void): T | undefined {
    try {
      return JSON.parse(this.api.getItem(name) || '') as T;
    } catch (e) {
      return typeof defaultValue === 'undefined' ? undefined : defaultValue;
    }
  }
  setItem<T>(name: string, value?: T, stringify: boolean = true): void {
    // если упадет тут, значит, что-то не так, пусть падает
    const isRemove = value === undefined;

    // try {
    if (isRemove) {
      this.api.removeItem(name);
    } else {
      const storageValue = stringify ? JSON.stringify(value) : String(value);

      this.api.setItem(name, storageValue);
    } // тут не будет undefined
    // } catch (err) {
    // тут может быть недостаточно места для записи или ошибка записи в инкогнито (в каких-то сафари была такая тема).
    // возможно, надо по-разному реагировать. если места нет - throw, если инкогнито - то сохранение в локальный справочник
    // throw err;
    // }
  }
  removeItem(name: string): void {
    this.setItem(name);
  }
  hasItem(name: string): boolean {
    return typeof this.getItem(name) !== 'undefined';
  }
}

export const localStorage = new Storage(globalThis.localStorage);
export const sessionStorage = new Storage(globalThis.sessionStorage);
