import {
  ActionSources,
  ANALYTICS,
  DIMENSIONS_SCHEMAS,
  LOCATIONS,
} from '../../constants/analytics';
import { IS_AI, IS_DESKTOP } from '../../env';
import { ObjectGroup, OrderType } from '../client/entities';
import { track } from './core';
import { TradingFormSource } from './model/main';

/** Авторизация в приложении */
export const trackAuth = () => {
  if (IS_DESKTOP) {
    track(
      ANALYTICS.CATEGORY.APP_START,
      ANALYTICS.ACTION.APP_START_WT,
      ANALYTICS.LABEL.USER,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            '1': 'authorized',
          },
        },
      ]
    );
  } else {
    track(ANALYTICS.CATEGORY.APP_START, ANALYTICS.ACTION.AUTH);
  }
};

/** Выставление заявки */
export const trackOrder = (buy: boolean, orderType: number, source: string) => {
  const codeType = OrderType[orderType].toLowerCase();
  const actionSell = IS_DESKTOP
    ? ANALYTICS.ACTION.CREATE_ORDER_SELL_WT
    : ANALYTICS.ACTION.CREATE_ORDER_SELL;
  const actionBuy = IS_DESKTOP
    ? ANALYTICS.ACTION.CREATE_ORDER_BUY_WT
    : ANALYTICS.ACTION.CREATE_ORDER_BUY;

  track(
    ANALYTICS.CATEGORY.ORDER_TYPE,
    buy ? actionBuy : actionSell,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          '1': codeType.toUpperCase(),
          '2': source,
        },
      },
    ]
  );
};

/** Топ по торгуемым заявкам и тикерам */
export const trackTickerTypeOrder = (
  tickerCategory: number,
  ticker: string,
  buy: boolean,
  rCode: string
) => {
  const tickerCat = ObjectGroup[tickerCategory];
  const trading = buy ? 'buy' : 'sell';

  track(
    ANALYTICS.CATEGORY.TICKER_CATEGORY,
    ANALYTICS.ACTION.TICKER_BUY_SELL_WT,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          '1': tickerCat,
          '2': ticker,
          '3': trading,
          '4': rCode,
        },
      },
    ]
  );
};

/** Выбор вотчлиста */
export const trackWatchListSelect = (watchlistType: string) => {
  const watchListDescription =
    watchlistType === ('goinvest' || 'default')
      ? 'стандартные списки'
      : 'собственный список';

  track(
    ANALYTICS.CATEGORY.WATCH_LISTS,
    ANALYTICS.ACTION.CHOOSE_WATCHLIST_WT,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          '1': watchListDescription,
        },
      },
    ]
  );
};

/** Выбор шт/лоты в настройках */
export const trackSelectSize = (setting: string) => {
  const action = IS_DESKTOP
    ? ANALYTICS.ACTION.SELECT_SIZE_WT
    : ANALYTICS.ACTION.SELECT_SIZE;

  track(
    ANALYTICS.CATEGORY.SETTINGS_INSTRUMENT,
    action,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: `setting=${setting}` },
      },
    ]
  );
};

/** Выбор тикер/наименование в настройках */
export const trackSelectName = (setting: string) => {
  const action = IS_DESKTOP
    ? ANALYTICS.ACTION.SELECT_NAME_WT
    : ANALYTICS.ACTION.SELECT_NAME;

  track(
    ANALYTICS.CATEGORY.SETTINGS_INSTRUMENT,
    action,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: `setting=${setting}` },
      },
    ]
  );
};

/** Наличие допинструкций в заявке */
export const trackIssueApprove = (setting: 'ask' | 'notask') => {
  const action = IS_DESKTOP
    ? ANALYTICS.ACTION.ISSUE_APPROVE_WT
    : ANALYTICS.ACTION.ISSUE_APPROVE;

  track(
    ANALYTICS.CATEGORY.SETTINGS_INSTRUMENT,
    action,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: `setting=${setting}` },
      },
    ]
  );
};

/** Связанные заявки */
export const trackLinkedOrder = (linkedOrderId: string | undefined) => {
  track(
    ANALYTICS.CATEGORY.ADD_INSTRUMENT,
    IS_DESKTOP ? ANALYTICS.ACTION.ADD_INSTR_WT : ANALYTICS.ACTION.ADD_INSTR,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: `conditions=${linkedOrderId ? 'aditional' : 'no'}`,
        },
      },
    ]
  );
};

/** Источник открытия окна редактирования заявки */
export const trackOrderEditSource = (source: ActionSources) => {
  track(
    ANALYTICS.CATEGORY.ORDER_TYPE,
    IS_DESKTOP
      ? ANALYTICS.ACTION.EDIT_ORDER_SOURCE_WT
      : ANALYTICS.ACTION.EDIT_ORDER_SOURCE,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: `Source=${ActionSources[source]?.toLowerCase()}` },
      },
    ]
  );
};

/** Тип редактируемых заявок */
export const trackOrderEditType = (idOrderType: OrderType) => {
  track(
    ANALYTICS.CATEGORY.ORDER_TYPE,
    IS_DESKTOP
      ? ANALYTICS.ACTION.EDIT_ORDER_TYPE_WT
      : ANALYTICS.ACTION.EDIT_ORDER_TYPE,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: `CodeType=${OrderType[idOrderType]?.toUpperCase()}` },
      },
    ]
  );
};

/** Пополнение с карт */
export const trackRefillFromCard = (amount: number) => {
  if (IS_DESKTOP) {
    track(
      ANALYTICS.CATEGORY.TRANSFERS,
      ANALYTICS.ACTION.REFILL_FROM_CARD,
      ANALYTICS.LABEL.USER,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: { 1: `Amount=${amount}` },
        },
      ]
    );
  }
};

/** Отправка кода в СМС (авторизация) */
export const trackPwaPasswordSend = () => {
  if (IS_AI) {
    track(
      ANALYTICS.CATEGORY.APP_START,
      ANALYTICS.ACTION.AUTH_PASSWORD,
      ANALYTICS.LABEL.USER
    );
  }
};

/** Показ окна ввода СМС (авторизация) */
export const trackPwaShowSms = () => {
  if (IS_AI) {
    track(
      ANALYTICS.CATEGORY.APP_START,
      ANALYTICS.ACTION.SMS_SHOW,
      ANALYTICS.LABEL.USER
    );
  }
};

/** Ввод кода СМС (авторизация) */
export const trackPwaSmsEntered = () => {
  if (IS_AI) {
    track(
      ANALYTICS.CATEGORY.APP_START,
      ANALYTICS.ACTION.SMS_ENTERED,
      ANALYTICS.LABEL.USER
    );
  }
};

/** Открытие чата персброкера */
export const trackPersBrokerChatEnter = () => {
  track(
    ANALYTICS.CATEGORY.PERSBROKER,
    ANALYTICS.ACTION.OPEN_CHAT,
    ANALYTICS.LABEL.USER
  );
};

/** Источник открытия торговой формы */
export const trackTradingFormOpened = (from: TradingFormSource) => {
  track(
    ANALYTICS.CATEGORY.TRADING_FORM,
    ANALYTICS.ACTION.ACTION_TAP_WT,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: from },
      },
    ]
  );
};

/** Пункты главного меню */
export const trackMainMenu = (
  locations: (typeof LOCATIONS)[keyof typeof LOCATIONS]
) => {
  track(
    ANALYTICS.CATEGORY.MAIN_MENU,
    ANALYTICS.ACTION.MAIN_MENU_TAP_WT,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: locations },
      },
    ]
  );
};

/** Изменение таймфрейма чартика */
export const trackChartiqTimeframe = (timeframe: string) => {
  track(
    ANALYTICS.CATEGORY.CHARTIQ,
    ANALYTICS.ACTION.TIMEFRAME,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: timeframe },
      },
    ]
  );
};

/** Изменение типа чартика */
export const trackChartiqChartType = (chartStyle: string) => {
  track(
    ANALYTICS.CATEGORY.CHARTIQ,
    ANALYTICS.ACTION.CHART_STYLE,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: chartStyle },
      },
    ]
  );
};

export const trackClickOnSummaryAbout = () => {
  track(
    ANALYTICS.CATEGORY.SUMMARY,
    ANALYTICS.ACTION.OPEN_ABOUT,
    ANALYTICS.LABEL.USER
  );
};

export const trackClickOnSummaryIndicators = () => {
  track(
    ANALYTICS.CATEGORY.SUMMARY,
    ANALYTICS.ACTION.OPEN_INDICATORS,
    ANALYTICS.LABEL.USER
  );
};

export const trackClickOnSummaryFinancial = () => {
  track(
    ANALYTICS.CATEGORY.SUMMARY,
    ANALYTICS.ACTION.OPEN_FINANCIALS,
    ANALYTICS.LABEL.USER
  );
};

export const trackClickOnSummaryNews = () => {
  track(
    ANALYTICS.CATEGORY.SUMMARY,
    ANALYTICS.ACTION.OPEN_NEWS,
    ANALYTICS.LABEL.USER
  );
};

export const trackClickOnMenu = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.MENU
  );
};

export const trackClickOnConnectionStatus = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.CONNTECTION_STATUS
  );
};

export const trackClickOnDesktop = (desktopName: string) => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.DESKTOP,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: desktopName },
      },
    ]
  );
};

export const trackOnAddDesktop = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.ADD_DESKTOP
  );
};

export const trackOnAddWidget = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.ADD_WIDGET
  );
};

export const trackOnMoneyTransfer = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.MONEY_TRANSFER
  );
};

export const trackClickOnChat = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.CHAT
  );
};

export const trackClickOnHelp = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.HELP
  );
};

export const trackClickOnManual = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL_HELP,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.MANUAL
  );
};

export const trackOnOnboarding = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL_HELP,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.ONBOARDING
  );
};

export const trackClickOnManuelLK = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_PANEL_HELP,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.MANUAL_LK
  );
};

export const trackClickOnMenuManuelLK = () => {
  track(
    ANALYTICS.CATEGORY.MAIN_MENU,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.MANUAL_LK
  );
};
