import { OrderStatus } from '../client/entities';

import { AccountItem } from '../../types/account';
import { OrderItem } from '../../types/order';

export const getClientOrders = (
  orders: OrderItem[],
  clientAccounts: AccountItem[]
) =>
  orders.filter(
    (order) =>
      clientAccounts.some((account) => account.idAccount === order.idAccount) &&
      //Также отсеиваем заявки, которые не в статусе Активные и Ожидают, они не учавствуют в рассчетах
      ![
        OrderStatus.Filled,
        OrderStatus.Cancelled,
        OrderStatus.Rejected,
      ].includes(order.idOrderStatus)
  );
