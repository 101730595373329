import { format } from 'date-fns';
import { useMemo } from 'react';
import { Link } from '@alfalab/core-components/link';
import { Notification } from '@alfalab/core-components/notification';
import { NotificationManager as Manager } from '@alfalab/core-components/notification-manager';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon'; // TODO Поменять на ArrowDownTriangleMIcon при обновлении либы
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon'; // TODO Поменять на ArrowUpTriangleMIcon при обновлении либы
import { CheckmarkCircleMIcon } from '@alfalab/icons-glyph/CheckmarkCircleMIcon';
import { CommentWithTextMIcon } from '@alfalab/icons-glyph/CommentWithTextMIcon';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import { useNotifications } from '@terminal/core/hooks/useNotifications';
import { ClientEnterOrderReq } from '@terminal/core/lib/client/entities';
import { getServerTime } from '@terminal/core/lib/services/time';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { PendingOrder } from '@terminal/core/types/ui';

import { SymbolCell } from '../Table';

import styles from './DesktopNotificationManager.module.css';

type Order = undefined | (PendingOrder & { fullFI: ExtendedFI });

export const DesktopNotificationManager = () => {
  const { isShowNotifications, notificationsShowList, onRemoveNotification } =
    useNotifications();

  const notificationElements = useMemo(() => {
    return notificationsShowList.map((props) => {
      const { orderNotification, title, id, text, link, badge, closeTime } =
        props;

      const autoCloseDelay = closeTime - Date.now();
      const order = props.order as Order;
      const time = format(new Date(getServerTime()), 'HH:mm:ss');

      return (
        <Notification
          {...props}
          id={id}
          key={id}
          autoCloseDelay={autoCloseDelay}
          className={styles.notification}
          title={undefined}
          badge={undefined}
          colors="inverted"
          children={
            link ? (
              <>
                <div className={styles.notificationContent}>
                  <div className={styles.notificationContentIconContainer}>
                    <ExclamationCircleMIcon fill="var(--color-light-graphic-secondary)" />
                  </div>
                  <div className={styles.notificationContentMain}>
                    <div className={styles.notificationContentHeader}>
                      <div className={styles.notificationContentTitle}>
                        {title}
                      </div>
                      <div className={styles.notificationContentTime}>
                        {time}
                      </div>
                    </div>
                    <div>
                      {text}
                      <br />
                      <Link
                        href={link}
                        view="default"
                        rel="noopener"
                        target="_blank"
                      >
                        Пройти тест
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.notificationContent}>
                {orderNotification && order && (
                  <div className={styles.notificationContentIconContainer}>
                    <SymbolCell
                      onlyIcon
                      idObject={order.fullFI?.idObject || order.idObject}
                      symbolObject={order.fullFI?.symbolObject}
                      classNameIcon={styles.notificationContentInstrumentIcon}
                    />
                    {((order as ClientEnterOrderReq).ClientOrderNum
                      ? (order as ClientEnterOrderReq).BuySell
                      : order.buySell) === -1 ? (
                      <ArrowDownCompactXsIcon
                        color="#EF3124"
                        className={styles.orderBuySellIcon}
                      />
                    ) : (
                      <ArrowUpCompactXsIcon
                        color="#13A463"
                        className={styles.orderBuySellIcon}
                      />
                    )}
                  </div>
                )}
                {!orderNotification && badge && (
                  <div className={styles.notificationContentIconContainer}>
                    {badge === 'positive' && (
                      <CheckmarkCircleMIcon fill="var(--color-light-graphic-secondary)" />
                    )}
                    {['attention', 'negative'].includes(badge) && (
                      <ExclamationCircleMIcon fill="var(--color-light-graphic-secondary)" />
                    )}
                    {badge === 'chat' && (
                      <CommentWithTextMIcon fill="var(--color-light-graphic-secondary)" />
                    )}
                  </div>
                )}
                <div className={styles.notificationContentMain}>
                  <div className={styles.notificationContentHeader}>
                    <div className={styles.notificationContentTitle}>
                      {title}
                    </div>
                    <div className={styles.notificationContentTime}>{time}</div>
                  </div>
                  <div>{text}</div>
                </div>
              </div>
            )
          }
        />
      );
    });
  }, [notificationsShowList]);

  if (!isShowNotifications) {
    return null;
  }

  return (
    <>
      <Manager
        className={styles.manager}
        notifications={notificationElements}
        onRemoveNotification={onRemoveNotification}
      />
    </>
  );
};
