import React, { useEffect, useMemo } from 'react';

import { ButtonMenu, ButtonMenuItemProps } from '../components/ButtonMenu';

interface MoneyInMenuProps {
  subPage: string;
  twoFactorEnabled?: boolean;
  accessCard?: boolean;
  accessRequisites?: boolean;
  accessXpay?: boolean;
  onItemSelect: (type: string) => void;
}

export const MoneyInMenu = ({
  subPage,
  twoFactorEnabled,
  accessCard,
  accessRequisites,
  onItemSelect,
}: MoneyInMenuProps) => {
  const buttomMenu = useMemo<ButtonMenuItemProps[]>(() => {
    const buttomMenu: ButtonMenuItemProps[] = [];

    if (accessCard) {
      buttomMenu.push({
        name: 'С банковской карты',
        type: 'in',
        id: 'bank-card-tab',
        disabled: !twoFactorEnabled,
        onClick: () => onItemSelect('in'),
      });
    }

    if (accessRequisites) {
      buttomMenu.push({
        name: 'По реквизитам',
        type: 'requisites',
        id: 'requisites-tab',
        onClick: () => onItemSelect('requisites'),
      });
    }

    return buttomMenu;
  }, [accessCard, accessRequisites, twoFactorEnabled, onItemSelect]);

  useEffect(() => {
    const currentMenu = buttomMenu.filter((m) => m.type === subPage);

    if (currentMenu.length === 0 && buttomMenu.length > 0) {
      onItemSelect(buttomMenu[0].type);
    }
  }, [subPage, onItemSelect, buttomMenu]);

  if (buttomMenu.length < 2) {
    return null;
  }

  return <ButtonMenu items={buttomMenu} active={[subPage]} />;
};
