import { MouseEventHandler } from 'react';

import { useTrackPriceDeleteAll } from '@terminal/core/lib/services/trackPrice/hooks/useTrackPriceDeleteAll';
import { trackPriceService } from '@terminal/core/lib/services/trackPrice/trackPriceService';
import { useStore } from '@terminal/core/store';

import { ModalProps } from '../../../legacy/components/Modal';

type ReturnValue = Pick<ModalProps, 'actionButtonsStart' | 'actionButtonsEnd'>;

export function useModalActionButtons(isNoTrackPrices: boolean): ReturnValue {
  const openEditModal = useStore((store) => store.openEditTrackPriceModal);
  const trackPriceDeleteAll = useTrackPriceDeleteAll();
  const dismissTrackPriceAll = useStore((store) => store.dismissTrackPriceAll);

  const openCreationModal: MouseEventHandler = () => {
    openEditModal(trackPriceService.getEmptyAlert());
  };

  if (isNoTrackPrices) {
    return {
      actionButtonsEnd: [
        {
          children: 'Новый алерт',
          view: 'secondary',
          onClick: openCreationModal,
        },
      ],
    };
  }

  return {
    actionButtonsStart: [
      {
        children: 'Удалить все',
        loading: trackPriceDeleteAll.isLoading,
        onClick: () => trackPriceDeleteAll.mutateAsync(),
      },
    ],
    actionButtonsEnd: [
      {
        children: 'Снять сработавшие',
        onClick: dismissTrackPriceAll,
      },
      {
        children: 'Новый алерт',
        view: 'secondary',
        onClick: openCreationModal,
      },
    ],
  };
}
