import cn from 'classnames';
import { Link } from '@alfalab/core-components/link';

import styles from './AccountRequisitesLink.module.css';

interface AccountRequisitesLinkProps {
  name: string;
  info: string;
  className?: string;
  onClick?: () => void;
}

export const AccountRequisitesLink = ({
  name,
  info,
  className,
  onClick,
}: AccountRequisitesLinkProps) => (
  <div className={cn(styles.container, className)}>
    <Link onClick={onClick}>{name}</Link>
    <span className={styles.info}>{info}</span>
  </div>
);
