import cn from 'classnames';
import { FC, memo, useCallback } from 'react';
import { ChevronDownCompactSIcon } from '@alfalab/icons-glyph/ChevronDownCompactSIcon';
import { ChevronUpCompactSIcon } from '@alfalab/icons-glyph/ChevronUpCompactSIcon';

import { Cell } from '@terminal/common/ui/Table';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { TradeDirection } from '../../model/tradesTableRow';

import styles from './TradesTableCell.module.css';

type TradeTableCellValue =
  | {
      columnKey: TableColumnKey.TradePrice | TableColumnKey.TradeBuySell;
      value: number;
    }
  | {
      columnKey: TableColumnKey.TradeQty | TableColumnKey.TradeTime;
      value: string;
    };

export type TradeTableCellProps = {
  highlighted: boolean;
  direction?: TradeDirection | null;
  isBuying: boolean;
} & TradeTableCellValue;

export const TradesTableCell: FC<TradeTableCellProps> = memo(
  ({ columnKey, value, isBuying, highlighted, direction }) => {
    const buySellClassName = isBuying ? styles.buy : styles.sell;

    const getDirectionChevron = useCallback(
      (direction?: TradeDirection | null) => {
        switch (direction) {
          case 'up':
            return <ChevronUpCompactSIcon className={styles.direction} />;
          case 'down':
            return <ChevronDownCompactSIcon className={styles.direction} />;
          case 'equal':
            return <div className={styles.directionEmpty} />;
          default:
            return null;
        }
      },
      []
    );

    switch (columnKey) {
      case TableColumnKey.TradePrice: {
        return (
          <span className={buySellClassName}>
            {getDirectionChevron(direction)}
            {value}
          </span>
        );
      }
      case TableColumnKey.TradeQty:
        return (
          <Cell
            className={cn(highlighted && styles.highlightedCell)}
            data-test-id="trades-feed-qty"
          >
            <span
              className={cn(
                // TODO этот CSS удалили, точно ли он нужен тут
                // styles.quantityCell,
                buySellClassName
              )}
            >
              {value}
            </span>
          </Cell>
        );
      case TableColumnKey.TradeTime:
        return <span className={buySellClassName}>{value}</span>;
      case TableColumnKey.TradeBuySell:
        return (
          <span className={styles.buySell}>
            {getDirectionChevron(direction)}
            <span className={buySellClassName}>{isBuying ? 'К' : 'П'}</span>
          </span>
        );

      default:
        return null;
    }
  }
);
