import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { NotificationType } from '@terminal/core/types/ui';

import { useWidgetContext } from '../../../shared';
import { useCheckSms } from './useCheckSms';
import { useSendSms } from './useSendSms';

import { useCertificateEnrollStore } from '../model/store';

export const useSignCertificate = ({
  certificateId,
  initAction,
  onSuccess,
  successMsg,
  errorMsg,
}) => {
  const { addNotification } = useWidgetContext();

  const [operation, setOperation] = useCertificateEnrollStore((store) => [
    store.operation,
    store.setOperation,
  ]);

  const { sendSmsQuery, resendSms } = useSendSms(certificateId);
  const { isLoading, checkSmsCode } = useCheckSms({
    successMsg,
    errorMsg,
    onSuccess,
  });

  // Создание операции с сертификатом
  const { refetch: createCertOp } = useQuery({
    queryKey: ['OnboardingCertificate', certificateId],
    enabled: false,
    queryFn: () => initAction(certificateId!),
    onSuccess: (data: any) => {
      if (data?.code === 0) {
        setOperation(data);
        sendSmsQuery.mutate(data.url);
      }
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При создании операции произошла ошибка',
      });
    },
  });

  const prevCertificateId = usePrevious(certificateId);

  useEffect(() => {
    if (
      prevCertificateId !== certificateId &&
      certificateId &&
      initAction &&
      !operation
    ) {
      createCertOp();
    }
  }, [prevCertificateId, certificateId, initAction, createCertOp, operation]);

  return {
    resendSms,
    checkSmsCode,
    isLoading,
  };
};
