import cn from 'classnames';
import { useCallback } from 'react';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { CheckboxGroup } from '@alfalab/core-components/checkbox-group';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import styles from './MultiSelectFilter.module.css';

type MultiSelectFilterProps = {
  filterItems?: string[];
  filterValue?: string[];
  onChange: (value: string[]) => void;
};

export const MultiSelectFilter = ({
  filterItems,
  filterValue,
  onChange,
}: MultiSelectFilterProps) => {
  const onArrayChange = useCallback(
    (_, payload) => {
      let items = Array.isArray(filterValue) ? [...filterValue] : [];

      if (!items.includes(payload.name)) {
        items.push(payload.name);
      } else {
        items.splice(items.indexOf(payload.name), 1);
      }

      onChange(items);
    },
    [filterValue, onChange]
  );

  return (
    <Space size={0} className={styles.wrapper} fullWidth>
      <CheckboxGroup className={styles.filterItems} onChange={onArrayChange}>
        {filterItems?.map((item, index) => (
          <Checkbox
            key={index}
            label={
              <Typography.Text className={styles.text}>{item}</Typography.Text>
            }
            boxClassName={styles.checkbox}
            contentClassName={styles.checkBoxContent}
            className={cn(styles.filterWrapper, styles.checkboxContent)}
            name={item}
            checked={
              Array.isArray(filterValue) ? filterValue?.includes(item) : false
            }
            block
          />
        ))}
      </CheckboxGroup>
    </Space>
  );
};
