import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getSecuritiesCurrencyList } from '@terminal/core/lib/rest/lkSecurities';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

export const useSecuritiesCurrencyList = () => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.SecuritiesCurrencyList],
    getSecuritiesCurrencyList,
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе списка валют',
        });
      },
    }
  );
};
