import { useEffect, useState } from 'react';
import { InputDesktopProps } from '@alfalab/core-components/input/desktop';
import { SwitchProps } from '@alfalab/core-components/switch';
import { usePrevious } from '@alfalab/hooks';

import { MoneyBankTemplate } from '@terminal/core/lib/rest/lkMoney';

interface Params {
  templates?: MoneyBankTemplate[];
  selectedTemplate?: MoneyBankTemplate;
}

export const useSaveAsTemplate = (
  formValues: {
    bic: string;
    accountNumber: string;
  },
  params: Params
) => {
  const prevTemplatesCount = usePrevious(params.templates?.length);
  const defaultTemplateName = params.templates
    ? `Шаблон ${params.templates.length + 1}`
    : '';

  const [saveAsTemplateDisabled, setSaveAsTemplateDisabled] = useState(false);
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [templateName, setTemplateName] = useState<string>();
  const [templateNameTouched, setTemplateNameTouched] = useState(false);

  useEffect(() => {
    if (
      !templateNameTouched &&
      params.templates &&
      prevTemplatesCount !== params.templates.length
    ) {
      setTemplateName(defaultTemplateName);
    }
  }, [
    saveAsTemplate,
    params.templates,
    templateNameTouched,
    prevTemplatesCount,
    defaultTemplateName,
  ]);

  useEffect(() => {
    if (
      params.selectedTemplate ||
      !(formValues.bic && formValues.accountNumber)
    ) {
      setSaveAsTemplate(false);
      setSaveAsTemplateDisabled(true);
    } else {
      setSaveAsTemplateDisabled(false);
    }
  }, [params.selectedTemplate, formValues.bic, formValues.accountNumber]);

  const handleSaveAsTemplateChange: SwitchProps['onChange'] = (_, payload) => {
    if (payload) {
      setSaveAsTemplate(payload.checked);
    }
  };

  const handleTemplateNameChange: InputDesktopProps['onChange'] = (
    _,
    { value }
  ) => {
    setTemplateName(value);
  };

  const handleTemplateNameBlur: InputDesktopProps['onBlur'] = () => {
    setTemplateNameTouched(true);
  };

  return {
    saveAsTemplate,
    saveAsTemplateDisabled,
    templateName,
    onTemplateNameChange: handleTemplateNameChange,
    onTemplateNameBlur: handleTemplateNameBlur,
    onSaveAsTemplateChange: handleSaveAsTemplateChange,
  };
};
