import cn from 'classnames';
import { Duration, format, sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button } from '@alfalab/core-components/button';
import { CalendarInput } from '@alfalab/core-components/calendar-input';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/rest/lkApi';
import {
  DocumentsHistoryFilter,
  DocumentsHistoryStatus,
  DocumentsHistoryTheme,
} from '@terminal/core/lib/rest/lkDocuments';

import {
  FilterButton,
  isDateValid,
  setFilterProp,
} from '../../../components/Filter';
import { isFilterValid } from './helpers';

import styles from './HistoryFilter.module.css';

export interface HistoryFilterProps {
  filter: DocumentsHistoryFilter;
  pending: boolean;
  statuses?: DocumentsHistoryStatus[];
  themes?: DocumentsHistoryTheme[];
  onSetFilter: (filter: DocumentsHistoryFilter) => void;
  onResetFilter: () => void;
  onClose: () => void;
}

export const HistoryFilter = ({
  filter,
  pending,
  statuses,
  themes,
  onSetFilter,
  onResetFilter,
  onClose,
}: HistoryFilterProps) => {
  const [localFilter, setLocalFilter] =
    useState<DocumentsHistoryFilter>(filter);

  useEffect(() => {
    setLocalFilter(filter);
  }, [filter]);

  const setPeriod = (period: Duration) => {
    const endDate = new Date();
    const startDate = sub(endDate, period);
    setLocalFilter((prevFilter) => ({
      ...prevFilter,
      startDate: format(startDate, DEFAULT_VIEW_DATE_FORMAT),
      endDate: format(endDate, DEFAULT_VIEW_DATE_FORMAT),
    }));
  };

  const isPeriod = (period: Duration) => {
    const endDate = new Date();
    const startDate = sub(endDate, period);
    return (
      format(startDate, DEFAULT_VIEW_DATE_FORMAT) === localFilter.startDate &&
      format(endDate, DEFAULT_VIEW_DATE_FORMAT) === localFilter.endDate
    );
  };

  const setProp = (
    key: keyof DocumentsHistoryFilter,
    value: string | number
  ) => {
    const newFilter = setFilterProp(localFilter, key, value);
    setLocalFilter(newFilter);
  };

  const applyFilter = () => {
    onSetFilter(localFilter);
    onClose();
  };

  const resetFilter = () => {
    onResetFilter();
    onClose();
  };

  return (
    <div data-test-id="history-filter-body">
      {pending ? (
        <Loader dataTestId="history-filter-loader" />
      ) : (
        <>
          <Typography.Text view="primary-small" weight="bold" tag="p">
            Фильтры
          </Typography.Text>

          {!!themes && (
            <div className={styles.section}>
              <Typography.Text
                view="secondary-large"
                tag="p"
                color="secondary"
                weight="bold"
              >
                Тип документа
              </Typography.Text>
              <div className={styles.types}>
                {themes.map((theme) => (
                  <FilterButton
                    key={`type-${theme.id}`}
                    checked={(localFilter.theme || []).indexOf(theme.id) >= 0}
                    onClick={() => setProp('theme', theme.id)}
                  >
                    {theme.theme}
                  </FilterButton>
                ))}
              </div>
            </div>
          )}

          <div className={styles.section}>
            <Typography.Text
              view="secondary-large"
              tag="p"
              color="secondary"
              weight="bold"
            >
              Период
            </Typography.Text>
            <div className={styles.types}>
              <FilterButton
                onClick={() => setPeriod({ weeks: 1 })}
                checked={isPeriod({ weeks: 1 })}
              >
                Неделя
              </FilterButton>
              <FilterButton
                onClick={() => setPeriod({ months: 1 })}
                checked={isPeriod({ months: 1 })}
              >
                Месяц
              </FilterButton>
            </div>
            <Row className={styles.fields} justify="left" gutter={16}>
              <Col width={5}>
                <CalendarInput
                  label="Начало периода"
                  labelView="outer"
                  size="s"
                  block
                  value={localFilter.startDate}
                  onChange={(e, { value }) => setProp('startDate', value)}
                  error={!isDateValid(localFilter.startDate || '')}
                />
              </Col>
              <Col width={5}>
                <CalendarInput
                  label="Конец периода"
                  labelView="outer"
                  size="s"
                  block
                  value={localFilter.endDate}
                  onChange={(e, { value }) => setProp('endDate', value)}
                  error={!isDateValid(localFilter.endDate || '')}
                />
              </Col>
            </Row>
          </div>

          {!!statuses && (
            <div className={styles.section}>
              <Typography.Text
                view="secondary-large"
                tag="p"
                color="secondary"
                weight="bold"
              >
                Статус документа
              </Typography.Text>
              <div className={styles.types}>
                {statuses.map((status) => (
                  <FilterButton
                    key={`status-${status.value}`}
                    checked={localFilter.statuses.indexOf(status.value) >= 0}
                    onClick={() => setProp('statuses', status.value)}
                  >
                    {status.text}
                  </FilterButton>
                ))}
              </div>
            </div>
          )}

          <div className={cn(styles.submit, styles.wide)}>
            <Row className={styles.fields} justify="left" gutter={16}>
              <Col width={6}>
                <Button
                  size="s"
                  block
                  view="secondary"
                  leftAddons={<CheckmarkMIcon width={18} height={18} />}
                  onClick={applyFilter}
                  disabled={!isFilterValid(localFilter)}
                >
                  Применить
                </Button>
              </Col>
              <Col width={6}>
                <Button
                  size="s"
                  block
                  view="tertiary"
                  leftAddons={<CrossMIcon width={18} height={18} />}
                  onClick={resetFilter}
                >
                  Сбросить
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};
