export enum AppState {
  NOT_READY = 0,
  READY = 1,
  LOADING_DICTIONARY = 3,
  AUTHORIZING_FRONTENDS = 4,
  AUTHORIZING_LK = 5,
  AUTHORIZING_2FA = 6,
  AUTHORIZING_CAPTCHA = 7,
  AUTHORIZING_PASSPORT = 8,
  AUTHORIZING_CREATE_PIN_CODE = 9,
  AUTHORIZING_ENTER_PIN_CODE = 10,
}
