import { Typography } from '@alfalab/core-components/typography';

import { ActiveTransfer } from '@terminal/core/lib/rest/lkSecurities';

import { ActivesList } from '../ActivesList';

interface SummarySecuritiesProps {
  selectedActives?: ActiveTransfer[];
}

export const SummarySecurities = ({
  selectedActives,
}: SummarySecuritiesProps) => {
  return (
    <>
      <Typography.Text weight="bold" tag="p">
        Ценная бумага
      </Typography.Text>
      <ActivesList actives={selectedActives || []} />
    </>
  );
};
