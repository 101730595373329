import cn from 'classnames';
import React, { FC } from 'react';
import { Switch } from '@alfalab/core-components/switch';

import { MenuItem } from '../MenuItem';

import { SwitchItem as SwitchItemType } from '../types/SwitchItem';

import styles from './SwitchItem.module.css';

export type SwitchItemProps = Omit<
  SwitchItemType,
  'key' | 'Icon' | 'hideMenu' | 'hidden'
>;

export const SwitchItem: FC<SwitchItemProps> = (props) => {
  const { className, label, children, type, ...restProps } = props;
  const rootClassName = cn(className, styles.root);

  return (
    <MenuItem
      Component={Switch}
      label={label}
      className={rootClassName}
      align="center"
      reversed
      block
      {...restProps}
    />
  );
};
