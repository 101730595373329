import EventEmitter from 'eventemitter3';
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';
import { CopyLineMIcon } from '@alfalab/icons-glyph/CopyLineMIcon';
import { ShareMIcon } from '@alfalab/icons-glyph/ShareMIcon';

import {
  BottomSheet,
  BottomSheetBlock,
} from '@terminal/common/components/BottomSheet';
import { Spinner } from '@terminal/common/components/Spinner';
import { useEventListener } from '@terminal/core/hooks';
import { copyToClipboard } from '@terminal/core/lib/copyToClipboard';

import { ChatHistoryError } from './ChatHistoryError';
import { MessagesDay } from './MessagesDay';

import { useDivideMessagesIntoGroups } from '../hooks/useDivideMessagesIntoGroups';

import { ChatMessage } from '../types';

import styles from './MessagesList.module.css';

export enum MessagesListEvents {
  MSG_LIST_SCROLL_BOTTOM = 'MSG_LIST_SCROLL_BOTTOM',
}

export const messagesListEventEmitter = new EventEmitter<MessagesListEvents>();

interface MessagesListProps {
  mobile: boolean;
  allLoaded?: boolean;
  loading?: boolean;
  moreError?: boolean;
  readMessages: ChatMessage[];
  unreadMessages?: ChatMessage[];
  loadMore?: () => Promise<void>;
}

export const MessagesList = ({
  mobile,
  allLoaded = true,
  loading = false,
  moreError = false,
  readMessages,
  unreadMessages = [],
  loadMore,
}: MessagesListProps) => {
  const [showDates, setShowDates] = useState<boolean>(false);

  const [scrollButtonVisible, setScrollButtonVisible] =
    useState<boolean>(false);
  const [messageContext, setMessageContext] = useState<ChatMessage | null>(
    null
  );

  const groupedReadMessages = useDivideMessagesIntoGroups(readMessages);
  const groupedUnreadMessages = useDivideMessagesIntoGroups(unreadMessages);

  /**
   * Элемент для отображения дат
   */
  const listRef = useRef<HTMLDivElement>(null);
  const handleHideDates = useDebouncedCallback(() => setShowDates(false), 1000);
  useEventListener(
    'scroll',
    () => {
      if (!showDates) {
        setShowDates(true);
      }

      handleHideDates();
    },
    { element: listRef }
  );

  /**
   * Элемент для дозагрузки истории
   */
  const topObserver: MutableRefObject<IntersectionObserver | undefined> =
    useRef();
  const pageTopRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (topObserver.current) topObserver.current.disconnect();

      if (loadMore) {
        topObserver.current = new IntersectionObserver((entries) => {
          if (
            entries[0].isIntersecting &&
            !loading &&
            !allLoaded &&
            readMessages?.length + unreadMessages?.length > 0
          ) {
            loadMore(); // TODO .then(подскролл обратно к бывшему первому из сообщений)
          }
        });
        if (node) topObserver.current.observe(node);
      }
    },
    [loadMore, loading, allLoaded, readMessages?.length, unreadMessages?.length]
  );

  /**
   * Элемент для перемотки вниз
   */
  const bottomObserver: MutableRefObject<IntersectionObserver | undefined> =
    useRef();
  const listBottomRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    bottomObserver.current = new IntersectionObserver(
      ([e]) => {
        setScrollButtonVisible(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );
    if (listBottomRef.current)
      bottomObserver.current.observe(listBottomRef.current);

    return () => {
      if (bottomObserver.current) bottomObserver.current.disconnect();
    };
  }, []);

  const handleOpenContextMenu = useCallback(
    (message: ChatMessage): void => setMessageContext(message),
    []
  );

  const handleCopyText = useCallback((): void => {
    messageContext?.text && copyToClipboard(messageContext.text);
    setMessageContext(null);
  }, [messageContext?.text]);

  useEffect(() => {
    (loadMore ? loadMore() : Promise.resolve()).then(() =>
      messagesListEventEmitter.emit(MessagesListEvents.MSG_LIST_SCROLL_BOTTOM)
    );
  }, [loadMore]);

  const handleScrollBottom = useCallback(() => {
    listBottomRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    messagesListEventEmitter.on(
      MessagesListEvents.MSG_LIST_SCROLL_BOTTOM,
      handleScrollBottom
    );

    return () => {
      messagesListEventEmitter.off(
        MessagesListEvents.MSG_LIST_SCROLL_BOTTOM,
        handleScrollBottom
      );
    };
  }, [handleScrollBottom]);

  return (
    <>
      <div className={styles.list} ref={listRef}>
        <div ref={pageTopRef} className={styles.topBlock} />
        {loading && (
          <div className={styles.spinnerContainer}>
            <Spinner size="36px" color="#8D8D93" />
          </div>
        )}
        {moreError && loadMore && <ChatHistoryError onRetry={loadMore} />}
        {Object.entries(groupedReadMessages).map(([date, groups]) => (
          <MessagesDay
            key={date}
            mobile={mobile}
            showDate={showDates}
            groups={groups}
            date={date}
            onContext={handleOpenContextMenu}
          />
        ))}
        {Object.keys(groupedUnreadMessages).length > 0 && (
          <>
            <div className={styles.divider}>
              <Typography.Text view="secondary-medium" color="secondary">
                Новые сообщения
              </Typography.Text>
            </div>
            {Object.entries(groupedUnreadMessages).map(([date, groups]) => (
              <MessagesDay
                mobile={mobile}
                showDate={showDates}
                key={date}
                groups={groups}
                date={date}
                onContext={handleOpenContextMenu}
              />
            ))}
          </>
        )}
        <div ref={listBottomRef} />
        <BottomSheet
          open={!!messageContext}
          onClose={() => setMessageContext(null)}
        >
          <BottomSheetBlock
            title="Сообщение"
            items={[
              {
                key: 'copy',
                text: 'Скопировать',
                leftIcon: <CopyLineMIcon fill="currentColor" />,
                onClick: handleCopyText,
              },
              {
                key: 'share',
                text: 'Поделиться',
                leftIcon: <ShareMIcon fill="currentColor" />,
                onClick: () => {},
              },
            ]}
          />
        </BottomSheet>
      </div>
      {scrollButtonVisible && (
        <ChevronDownMIcon
          width={24}
          height={24}
          className={styles.scrollButton}
          color="#fff"
          onClick={() =>
            listBottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
          }
        />
      )}
    </>
  );
};
