import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  AmountInput,
  AmountInputProps,
} from '@alfalab/core-components/amount-input';
import { Button } from '@alfalab/core-components/button/modern';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import {
  InputDesktop,
  InputDesktopProps,
} from '@alfalab/core-components/input/desktop';
import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { usePrevious } from '@alfalab/core-components/select/utils';
import { Space } from '@alfalab/core-components/space';
import { Switch } from '@alfalab/core-components/switch';
import { Textarea, TextareaProps } from '@alfalab/core-components/textarea';
import { AddCircleMIcon } from '@alfalab/icons-glyph/AddCircleMIcon';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';
import { SquareAcademicCapMIcon } from '@alfalab/icons-glyph/SquareAcademicCapMIcon';
import { TransferOfDebtMIcon } from '@alfalab/icons-glyph/TransferOfDebtMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { TradingCertificateLock } from '@terminal/common/features-DEPRECATED/TradingCertificateLock';
import { MINORITY } from '@terminal/core/constants/ui';
import {
  MoneyBankTemplate,
  TradeAccount,
} from '@terminal/core/lib/rest/lkMoney';

import {
  getLimit,
  isAmountFitLimit,
  isBankAccountNumberValid,
  MarginBanner,
  MarginTooltip,
  MoneyLimitQueryParams,
  MoneyLimits,
  MoneyTaxDetail,
  TransferCompleteParams,
  useBankInfo,
  useBankTemplates,
  useMarginControl,
  useMoneyLimitContext,
  useMoneyLimits,
  useMoneyTransferToBank,
  useSaveAsTemplate,
  validateBankAccountNumber,
  validateBic,
} from '../../../../entities/TransferGoinvest';
import { Templates } from '../../../../legacy/transfergoinvest/Templates';
import { FormControlSummary } from '../../../../shared/ui/FormControlSummary';
import { FormSectionHeader } from '../../../../shared/ui/FormSectionHeader';
import { MarginIntegratedTest } from '../MarginIntegratedTest';

import { useTreaties } from '../../../../legacy/hooks';

import styles from './MoneyToOther.module.css';

interface Props {
  account: TradeAccount | null;
  onSuccess?: (params: TransferCompleteParams) => void;
}

export const MoneyToOtherRub = ({ account, onSuccess }: Props) => {
  const [bic, setBic] = useState('');
  const [bicErrorShow, setBicErrorShow] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [accountNumberErrorShow, setAccountNumberErrorShow] = useState(false);
  const [addRequisites, setAddRequisites] = useState('');
  const [showAddRequisites, setShowAddRequisites] = useState(false);
  const [amount, setAmount] = useState<number | null>(0);
  const [templatesOpen, setTemplatesOpen] = useState(false);
  const [marginTestOpen, setMarginTestOpen] = useState(false);
  const [lastTemplateLoaded, setLastTemplateLoaded] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<MoneyBankTemplate>();

  const treaty = useMemo<number>(() => {
    if (account) {
      const [treaty] = account.subAccount.split('-');

      return parseInt(treaty);
    }

    return 0;
  }, [account]);

  const prevTreaty = usePrevious(treaty);

  const { data: treaties } = useTreaties();
  const accountTreaty = useMemo(
    () => treaties?.find((t) => treaty === t.treaty),
    [treaties, treaty]
  );

  const limitParams = useMemo<MoneyLimitQueryParams>(() => {
    return {
      treaty: treaty,
      accountFrom: account,
      type: 'EXTRA_OTHER',
      amount: (amount || 0) / MINORITY,
    };
  }, [treaty, account, amount]);

  const [debouncedLimitParams] = useDebounce<MoneyLimitQueryParams>(
    limitParams,
    250
  );
  const { data: limits, isFetching: limitsPending } =
    useMoneyLimits(debouncedLimitParams);
  const { data: templates, isFetching: templatesPending } = useBankTemplates(
    treaty,
    true
  );

  const {
    templateName,
    saveAsTemplate,
    onTemplateNameChange,
    onTemplateNameBlur,
    onSaveAsTemplateChange,
    saveAsTemplateDisabled,
  } = useSaveAsTemplate(
    { bic, accountNumber },
    { templates: templates, selectedTemplate }
  );

  const { limit: contextLimit, setLimit } = useMoneyLimitContext();

  useEffect(() => {
    if (limits && !isEqual(limits, contextLimit)) {
      setLimit(limits);
    }
  }, [limits, contextLimit, setLimit]);

  const {
    isTestPassed,
    showTestBanner,
    refetchTestingList,
    marginCurrency,
    showMarginTooltip,
    marginAvailable,
  } = useMarginControl({
    treaty,
    account,
    marginalOn: Boolean(limits?.marginalOn),
    currency: account?.currency || '',
    isDisallow: Boolean(account?.isDisallow),
  });

  const marginCurrencyWarning = marginCurrency
    ? null
    : 'Для выбранной валюты вывод с плечом недоступен';

  const {
    data: bankInfo,
    isFetching: bankInfoPending,
    remove: clearBankInfo,
  } = useBankInfo(bic);

  const { mutateAsync: moneyTransferToBank, isLoading: transferPending } =
    useMoneyTransferToBank();

  const onBicChange: InputDesktopProps['onChange'] = (e, { value }) => {
    setSelectedTemplate(undefined);
    const bic = value.replace(/[^0-9]/g, '');

    setBic(bic);
    clearBankInfo();
  };

  const onAccountNumberChange: InputDesktopProps['onChange'] = (
    e,
    { value }
  ) => {
    setSelectedTemplate(undefined);
    const cleared = value.replace(/[^0-9]/g, '');

    setAccountNumber(cleared);
  };

  const onAddRequisitesChange: TextareaProps['onChange'] = (e, { value }) => {
    setAddRequisites(value);
  };

  const onAmountChange: AmountInputProps['onChange'] = (e, { value }) => {
    if (value !== amount) {
      setAmount(value);
    }
  };

  const onTemplatesOpen = useCallback(() => {
    setTemplatesOpen(true);
  }, []);

  const transferEverything = () => {
    const amount = limits ? getLimit(limits, false) * MINORITY : null;

    setAmount(amount);
  };

  const setFieldsFromTemplate = (temp: MoneyBankTemplate) => {
    setBic(temp.bic);
    setAccountNumber(temp.accountNumber);
    setAddRequisites(temp.other?.comments || '');
    setSelectedTemplate(temp);

    setAccountNumberErrorShow(true);
  };

  useEffect(() => {
    if (
      !lastTemplateLoaded &&
      !templatesPending &&
      templates &&
      templates.length > 0
    ) {
      setFieldsFromTemplate(templates[0]);
      setLastTemplateLoaded(true);
    }
  }, [templatesPending, templates, lastTemplateLoaded]);

  useEffect(() => {
    if (selectedTemplate && treaty !== prevTreaty) {
      setBic('');
      setAccountNumber('');
      setAddRequisites('');
      setSelectedTemplate(undefined);
      setLastTemplateLoaded(false);
    }
  }, [selectedTemplate, treaty, prevTreaty]);

  const handleTemplateChoose = (temp: MoneyBankTemplate) => {
    setFieldsFromTemplate(temp);
    setTemplatesOpen(false);
  };

  const amountValid = isAmountFitLimit(
    (amount || 0) / MINORITY,
    limits,
    marginAvailable
  );

  const isReadyToGo = (): boolean => {
    if (!limits) {
      return false;
    }

    return (
      amountValid &&
      Boolean(account) &&
      isBankAccountNumberValid(accountNumber, accountTreaty) &&
      Boolean(bankInfo)
    );
  };

  const requestTransfer = async () => {
    if (!account || !amount || !accountNumber || !bankInfo) {
      return;
    }

    const transferAmount = formatAmount({
      value: amount,
      currency: account.currency as CurrencyCodes,
      minority: MINORITY,
      view: 'withZeroMinorPart',
    });

    moneyTransferToBank({
      params: {
        portfolio: account.subAccount,
        placeCode: account.placeCode,
        currency: account.currency,
        amount: amount / MINORITY,
        moneyAccount: accountNumber,
        bank: bankInfo,
        comments: addRequisites,
        saveTemplate: saveAsTemplate,
        templateName: templateName,
        templateId: selectedTemplate && selectedTemplate.id,
      },
      signOptions: {
        successTitle: `Перевод на сумму ${transferAmount.formattedWithCurrency} выполнен`,
        successText: `Средства будут зачислены на счёт в ${bankInfo.name}`,
        successCallback: (status) => {
          setAmount(0);
          onSuccess &&
            onSuccess({
              type: 'success',
              status: status?.data?.orderStatus,
              currency: account.currency as CurrencyCodes,
              amount: amount,
              fromAccount: account.subAccount,
              saveTemplate: saveAsTemplate,
              templateName: templateName,
            });
        },
      },
    });
  };

  const limitNode = (
    <MoneyLimits
      limits={limits}
      pending={limitsPending}
      currency={account ? account.currency : ''}
      marginAvailable={marginAvailable}
    />
  );

  const bicValid = validateBic(bic);
  const bicError: string =
    bicValid.code !== 0 && bicErrorShow ? bicValid.message : '';

  const accountNumberValid = validateBankAccountNumber(
    accountNumber,
    accountTreaty
  );
  const accountNumberError: string =
    accountNumberValid.code !== 0 && accountNumberErrorShow
      ? accountNumberValid.message
      : '';

  return (
    <>
      <FormSectionHeader
        rightAddons={
          <Link
            view="secondary"
            leftAddons={
              <DocumentLinesMIcon
                width={18}
                height={18}
                className={styles.glyph}
              />
            }
            onClick={onTemplatesOpen}
          >
            Мои шаблоны
          </Link>
        }
      >
        Куда
      </FormSectionHeader>

      <Space fullWidth direction="vertical" className={styles.section}>
        <Row>
          <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
            <InputDesktop
              label="БИК"
              labelView="outer"
              block
              size="s"
              placeholder="Укажите БИК банка"
              value={bic}
              onChange={onBicChange}
              onBlur={() => setBicErrorShow(true)}
              maxLength={9}
              error={bicError}
              hint="Состоит из 9 цифр"
            />
          </Col>
          <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
            <InputDesktop
              label="Cчёт"
              labelView="outer"
              block
              size="s"
              placeholder="Укажите номер счёта"
              value={accountNumber}
              onChange={onAccountNumberChange}
              onBlur={() => setAccountNumberErrorShow(true)}
              maxLength={20}
              error={accountNumberError}
              hint="Состоит из 20 цифр"
            />
          </Col>
        </Row>

        {(bankInfo || bankInfoPending) && (
          <Row>
            {bankInfo && (
              <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
                <FormControlSummary label="Банк">
                  {bankInfo.name}
                </FormControlSummary>
              </Col>
            )}
            {bankInfoPending && (
              <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
                <Loader />
              </Col>
            )}
          </Row>
        )}

        <Row>
          <Col width={12}>
            {showAddRequisites ? (
              <>
                <Textarea
                  label="Назначение платежа"
                  labelView="outer"
                  block
                  size="s"
                  maxLength={104}
                  value={addRequisites}
                  onChange={onAddRequisitesChange}
                />
              </>
            ) : (
              <Link
                view="secondary"
                leftAddons={
                  <AddCircleMIcon
                    width={18}
                    height={18}
                    className={styles.glyph}
                  />
                }
                onClick={() => setShowAddRequisites(true)}
              >
                Дополнительные реквизиты
              </Link>
            )}
          </Col>
        </Row>
        <Row align="middle">
          <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
            <Switch
              block={true}
              reversed={true}
              checked={saveAsTemplate}
              disabled={saveAsTemplateDisabled}
              label="Сохранить как шаблон"
              onChange={onSaveAsTemplateChange}
            />
          </Col>
          {saveAsTemplate && (
            <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <InputDesktop
                block
                placeholder="Введите название шаблона"
                size="s"
                value={templateName}
                onChange={onTemplateNameChange}
                onBlur={onTemplateNameBlur}
              />
            </Col>
          )}
        </Row>
      </Space>

      <FormSectionHeader
        tooltip={
          showMarginTooltip && <MarginTooltip className={styles.titleTooltip} />
        }
      >
        Сумма
      </FormSectionHeader>

      <div className={styles.section}>
        <Row>
          <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
            <AmountInput
              block
              autoComplete="off"
              size="s"
              bold={false}
              currency="RUR"
              value={amount}
              minority={MINORITY}
              placeholder="0"
              onChange={onAmountChange}
              integerLength={12}
              hint={
                isTestPassed
                  ? marginCurrencyWarning || limitNode
                  : marginCurrencyWarning
              }
              error={
                amount && amount > 0 && !amountValid ? limitNode : undefined
              }
            />
          </Col>
          <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
            <Button
              size="s"
              view="tertiary"
              block
              disabled={!account || !limits || limits.limit < 0}
              onClick={transferEverything}
            >
              Перевести всё
            </Button>
          </Col>
        </Row>
      </div>

      {showTestBanner && (
        <div className={styles.section}>
          <MarginBanner
            title="Для вывода с плечом пройдите тест"
            btnLabel="Пройти тест"
            btnIcon={<SquareAcademicCapMIcon />}
            onClick={() => setMarginTestOpen(true)}
          />
        </div>
      )}

      <MarginIntegratedTest
        open={marginTestOpen}
        onClose={() => setMarginTestOpen(false)}
        backLabel="К переводам"
        onComplete={() => {
          setMarginTestOpen(false);
          refetchTestingList();
        }}
      />

      <MoneyTaxDetail taxes={limits?.taxes} className={styles.taxDetail} />

      <Templates
        treaty={treaty}
        backLabel="Переводы в другой банк"
        open={templatesOpen}
        onTemplateChoose={handleTemplateChoose}
        onClose={() => setTemplatesOpen(false)}
      />

      <div className={styles.footer}>
        <TradingCertificateLock>
          <Row>
            <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <Button
                size="s"
                block
                leftAddons={<TransferOfDebtMIcon width={18} height={18} />}
                loading={transferPending}
                disabled={!isReadyToGo()}
                onClick={requestTransfer}
              >
                Перевести
              </Button>
            </Col>
          </Row>
        </TradingCertificateLock>
      </div>
    </>
  );
};
