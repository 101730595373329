import { AccordionItem, AccordionItemProps } from './AccordionItem';

import styles from './Accordion.module.css';

interface AccordionProps {
  items: AccordionItemProps[];
}

export const Accordion = ({ items }: AccordionProps) => {
  return (
    <div>
      {items.map((item, index) => (
        <AccordionItem className={styles.item} key={index} {...item} />
      ))}
    </div>
  );
};
