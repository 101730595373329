type IndexName<Key> = `by${Capitalize<string & Key>}`;

export type ArrayIndices<Row, Field extends keyof Row> = {
  [Key in Field as IndexName<Key>]-?: Map<Row[Key], Row>;
};

export const createIndices = <Row, Field extends keyof Required<Row> = any>(
  fields: readonly Field[]
) => {
  type Result = ArrayIndices<Row, Field>;
  //
  let source: Row[] | undefined;
  let result: Result | undefined;
  //
  const fieldsMap = fields.map((field) => {
    //
    const fieldName = `by${(field as string).charAt(0).toUpperCase()}${(
      field as string
    ).slice(1)}` as unknown as IndexName<Field>;

    return {
      source: field,
      target: fieldName,
    };
  });

  //
  return (value: Row[]) => {
    if (source !== value) {
      source = value;
      //
      const initialIndex = fieldsMap.reduce((result, field) => {
        result[field.target as IndexName<Field>] = new Map<
          Row[Field],
          Row
        >() as unknown as ArrayIndices<Row, Field>[IndexName<Field>];

        return result;
      }, {} as Result);

      //
      result = value.reduce((acc, row) => {
        //
        fieldsMap.forEach((field) => {
          const key = row[field.source];

          if (typeof key !== 'undefined' && key !== null) {
            const t = acc[field.target] as Map<Row[Field], Row>;

            t.set(key, row);
          }
        });

        return acc;
      }, initialIndex);
    }

    return result!;
  };
};
