import {
  Actions,
  DockLocation,
  DropInfo,
  I18nLabel,
  Model,
  Node,
  Orientation,
  Rect,
} from 'flexlayout-react';

import {
  BalanceProps,
  ChartProps,
  ElementType,
  HybridOrderProps,
  IJsonRowNode,
  InstrumentInfoProps,
  OperationsProps,
  OrderBookProps,
  OrderTableProps,
  PositionProps,
  TradesProps,
  WatchListProps,
  Widget,
} from '@terminal/core/types/layout';

export const setWidgetTabHeight = (model: Model, height: number) =>
  model.doAction(
    Actions.updateModelAttributes({
      tabSetTabStripHeight: height,
    })
  );

export const isDropAllow = (
  dragNode: Node,
  dropInfo: DropInfo,
  { row, col }: { row: number; col: number },
  mainId: string
) => {
  const dropNode = dropInfo.node;
  const dropOrientation = dropInfo.location.getOrientation();
  const dropLocation = dropInfo.location;
  const parent = dropNode.getParent();

  // Добавление вкладки (блокировать не нужно)
  if (dropLocation === DockLocation.CENTER) {
    //Если НЕ перемещаем внутри уже существующей группы
    if (dragNode.getParent()?.getId() !== dropInfo.node.getId()) {
      const dropRect = dropNode.getRect();

      dropInfo.rect = new Rect(dropRect.x, dropRect.y, dropRect.width, 24);
    }

    return true;
  }

  // Блокировка добавления более 4х столбцов
  if (
    col > 3 &&
    dropOrientation === Orientation.HORZ &&
    dropNode.getId() === mainId
  ) {
    return false;
  }

  // Блокировка добавления более 4х строк
  if (
    row > 3 &&
    dropOrientation === Orientation.VERT &&
    dropNode.getId() === mainId
  ) {
    return false;
  }

  //Если создаем новую колонку (глобальную)
  if (
    dropInfo.className === 'flexlayout__outline_rect_edge' &&
    dropOrientation === Orientation.HORZ &&
    col <= 3
  ) {
    return true;
  }

  //Если создаем новую строку (глобальную)
  if (
    dropInfo.className === 'flexlayout__outline_rect_edge' &&
    dropOrientation === Orientation.VERT &&
    row <= 3
  ) {
    return true;
  }

  // Блокировка добавления более 4х табов в столбец
  if (
    parent?.getType() === ElementType.ROW &&
    Number(parent?.getChildren()?.length) > 3 &&
    mainId !== parent?.getId()
  ) {
    return false;
  }

  // Блокировка добавления более 4х столбцов
  return !(
    col >= 4 &&
    (dropNode.getType() === ElementType.ROW ||
      dropOrientation === Orientation.HORZ)
  );
};

export const i18nMapper = (id: I18nLabel, param?: string) => {
  if (id === I18nLabel.Move_Tab) {
    return param;
  }

  if (id === I18nLabel.Close_Tab) {
    return 'Закрыть';
  }

  if (id === I18nLabel.Overflow_Menu_Tooltip) {
    return 'Скрытые вкладки';
  }

  return id;
};

//Важная информация из библиотеки flex-layout
//row - rows contains a list of tabsets and child rows,
//the top level 'row' will render horizontally(unless the global attribute rootOrientationVertical is set),
//child 'rows' will render in the opposite orientation to their parent
const countRow = (
  el: IJsonRowNode,
  orientation: 'hor' | 'vert' = 'hor',
  row = 0
): number => {
  //Если это row с горизонтальной ориентацией и в ней только табы, то считаем все их за одну строку
  if (
    el.children.every(({ type }) => type === ElementType.TABSET) &&
    orientation === 'hor'
  ) {
    return ++row;
    //Если это row с вертикальной ориентацией и в ней только табы, то считаем сравнием с текущим кол-вом строк в этом блоке
  } else if (
    el.children.every(({ type }) => type === ElementType.TABSET) &&
    orientation === 'vert'
  ) {
    return row > el.children.length ? row : el.children.length;
  } else {
    //Если есть и строки и/или табсеты то сравниваем по колонкам, в какой будет больше строк
    return el.children?.reduce((acc, child) => {
      //Отдельный табсет в вертикальной ориентации считается за отдельную строку
      if (child.type === ElementType.TABSET && orientation === 'vert') {
        return ++acc;
      } else if (child.type === ElementType.ROW) {
        //Подсчитываем строки в блоке
        const childRow = countRow(
          child,
          orientation === 'hor' ? 'vert' : 'hor',
          acc
        );

        //Возвращаем наибольшее значение
        return childRow < acc ? acc : childRow;
      } else {
        return acc;
      }
    }, row);
  }
};

//Важная информация из библиотеки flex-layout
//row - rows contains a list of tabsets and child rows,
//the top level 'row' will render horizontally(unless the global attribute rootOrientationVertical is set),
//child 'rows' will render in the opposite orientation to their parent
const countCol = (
  el: IJsonRowNode,
  orientation: 'hor' | 'vert' = 'hor',
  col = 0
): number => {
  //Если это row с горизонтальной ориентацией и в ней только табы, то считаем сравнием с текущим кол-вом колонок в этом блоке
  if (
    el.children.every(({ type }) => type === ElementType.TABSET) &&
    orientation === 'hor'
  ) {
    return col > el.children.length ? col : el.children.length;
    //Если это row с вертикальной ориентацией и в ней только табы, то считаем все их за одну колонку
  } else if (
    el.children.every(({ type }) => type === ElementType.TABSET) &&
    orientation === 'vert'
  ) {
    return ++col;
  } else {
    //Если есть и строки и/или табсеты то сравниваем по колонкам, в какой будет больше строк
    return el.children?.reduce((acc, child) => {
      //Отдельный табсет в горизонтальной ориентации считается за отдельную строку
      if (child.type === ElementType.TABSET && orientation === 'hor') {
        return ++acc;
      } else if (child.type === ElementType.ROW) {
        //Подсчитываем строки в блоке
        const childRow = countCol(
          child,
          orientation === 'hor' ? 'vert' : 'hor',
          acc
        );

        //Возвращаем наибольшее значение
        return childRow < acc ? acc : childRow;
      } else {
        return acc;
      }
    }, col);
  }
};

export const countRowAndCol = (el: IJsonRowNode) => {
  const col = countCol(el);
  const row = countRow(el);

  return { row, col };
};

export const isOrderBookProps = (
  props: any,
  type: Widget
): props is OrderBookProps => type === Widget.ORDERBOOK;

export const isTradesProps = (props: any, type: Widget): props is TradesProps =>
  type === Widget.TRADES;

export const isChartProps = (props: any, type: Widget): props is ChartProps =>
  type === Widget.CHART;

export const isWatchListProps = (
  props: any,
  type: Widget
): props is WatchListProps => type === Widget.WATCHLIST;

export const isBalanceProps = (
  props: any,
  type: Widget
): props is BalanceProps => type === Widget.BALANCE;

export const isOrderTableProps = (
  props: any,
  type: Widget
): props is OrderTableProps => type === Widget.ORDERS;

export const isPositionProps = (
  props: any,
  type: Widget
): props is PositionProps => type === Widget.POSITIONS;

export const isOperationProps = (
  props: any,
  type: Widget
): props is OperationsProps => type === Widget.OPERATIONS;

export const isHybridOrderProps = (
  props: any,
  type: Widget
): props is HybridOrderProps => type === Widget.HYBRIDORDER;

export const isInstrumentInfoProps = (
  props: any,
  type: Widget
): props is InstrumentInfoProps => type === Widget.INSTRUMENT_INFO;

export const isNewsProps = (props: any, type: Widget) => type === Widget.NEWS;
