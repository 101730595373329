import { useMutation } from 'react-query';

import {
  getPortfolioAnalytics,
  InvestQueryKeys,
} from '@terminal/core/lib/rest/investApi';

import { useWidgetContext } from '../../../shared';

export const usePortfolioAnalytics = () => {
  const { addNotification } = useWidgetContext();

  const mutation = useMutation(getPortfolioAnalytics, {
    mutationKey: InvestQueryKeys.PortfolioAnalytics,
    onError: (error: any) =>
      addNotification({
        id: new Date().toString(),
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Произошла ошибка при запросе',
      }),
  });

  return mutation;
};
