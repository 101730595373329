import chunk from 'lodash/chunk';
import pLimit from 'p-limit';

import { getMutimarketAssets, MultimarketsItem } from '../rest/investApi';

const CACHE_TTL = 86400000; // 60 * 60 * 24 * 1000;
const MAX_ISINS_PER_SINGLE_QUERY = 400;
const MAX_CONCURRENCY_LIMIT = 4; // number of parallel http requests

const limit = pLimit(MAX_CONCURRENCY_LIMIT);

const multimarketsCache = new Map<
  string,
  { expires: number; item: MultimarketsItem }
>();

export const loadMultimarkets = async (
  isins: string[]
): Promise<MultimarketsItem[]> => {
  // Исключаем RUB
  isins = isins.filter((isin) => isin !== 'RUB');

  // Часть ISINs может быть в кеше, не запрашиваем их с сервера
  const { cachedIsins, missedIsins } = isins.reduce<{
    cachedIsins: string[];
    missedIsins: string[];
  }>(
    (acc, isin) => {
      if (multimarketsCache.has(isin)) {
        acc.cachedIsins.push(isin);
      } else {
        acc.missedIsins.push(isin);
      }

      return acc;
    },
    {
      cachedIsins: [],
      missedIsins: [],
    }
  );

  // Часть ответа из кеша
  let result = cachedIsins.map(
    (isin: string) => multimarketsCache.get(isin)?.item as MultimarketsItem
  );

  // Часть ответа из ответа сервера
  if (missedIsins.length > 0) {
    const loadRequests: Promise<MultimarketsItem[]>[] = [];
    const chunks = chunk(missedIsins, MAX_ISINS_PER_SINGLE_QUERY);

    chunks.forEach((chunk) => {
      loadRequests.push(limit(() => getMutimarketAssets(chunk)));
    });

    const response = await Promise.all(loadRequests);
    const responseCombined = response.flat();

    responseCombined.forEach((item) =>
      multimarketsCache.set(item.isin, {
        expires: Date.now() + CACHE_TTL,
        item,
      })
    );
    result = [...result, ...responseCombined];
  }

  return result;
};
