import { useMemo, useRef } from 'react';

export function useNumberArrayMemo(
  callback: () => number[],
  deps: Array<unknown>
) {
  const prevValue = useRef<number[]>([]);

  const newValue = useMemo(() => {
    return callback().filter(onlyUnique).sort();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, deps);

  const arraysAreSame = prevValue.current.join(',') === newValue.join(',');
  if (arraysAreSame) {
    return prevValue.current;
  } else {
    prevValue.current = newValue;
    return newValue;
  }
}

function onlyUnique(value: unknown, index: number, self: unknown[]) {
  return self.indexOf(value) === index;
}
