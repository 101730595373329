import cn from 'classnames';
import { PlateDesktop } from '@alfalab/core-components/plate/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { StatusBadge } from '@alfalab/core-components-status-badge';

import styles from './LineBanner.module.css';

interface LineBannerProps {
  message: string;
  short?: boolean;
  className?: string;
  shortText?: string;
}

export const LineBanner = ({ message, className }: LineBannerProps) => (
  <PlateDesktop
    hasCloser
    view="attention"
    border={false}
    contentClassName={styles.content}
    className={cn(styles.plate, className)}
    rounded={false}
  >
    <div className={styles.container}>
      <StatusBadge className={styles.badge} size={16} view="attention-alert" />
      <Typography.Text
        className={styles.message}
        weight="medium"
        view="secondary-large"
      >
        {message}
      </Typography.Text>
    </div>
  </PlateDesktop>
);
