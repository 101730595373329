import { ObjectGroup } from '../client/entities';

import { ObjectItem, ObjectTypeItem } from '../../types/core';

/**
 * @returns Валюту цены ликвидного рынка
 * */
export function getMarketPriceSymbol(
  objectType: ObjectTypeItem,
  objectsMap: Map<number | undefined, ObjectItem>,
  idObjectCurrency = 0
) {
  if (objectType?.idObjectGroup === ObjectGroup.Bonds) {
    return '%';
  }

  if (objectType?.idObjectGroup === ObjectGroup.Futures) {
    return 'Pt';
  }

  return objectsMap.get(idObjectCurrency)?.symbolObject;
}
