/**
 * @deprecated использовать компонент `Switch` из `@alfalab/core-components/switch`
 * */
export const ToggleOff = () => (
  <svg
    width="28"
    height="16"
    viewBox="0 0 28 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="27" height="15" rx="7.5" fill="#6D7986" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      fill="white"
    />
  </svg>
);

/**
 * @deprecated использовать компонент `Switch` из `@alfalab/core-components/switch`
 * */
export const ToggleOn = () => (
  <svg
    width="28"
    height="16"
    viewBox="0 0 28 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="27"
      height="15"
      rx="7.5"
      fill="var(--control-accent-bg-color)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 14C23.3137 14 26 11.3137 26 8C26 4.68629 23.3137 2 20 2C16.6863 2 14 4.68629 14 8C14 11.3137 16.6863 14 20 14Z"
      fill="white"
    />
  </svg>
);

export const OrderBookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 2.75C2.92157 2.75 2.25 3.42157 2.25 4.25V14.75C2.25 15.5784 2.92157 16.25 3.75 16.25H14.25C15.0784 16.25 15.75 15.5784 15.75 14.75V4.25C15.75 3.42157 15.0784 2.75 14.25 2.75H3.75ZM9 4.25H13.5V5.75H9V4.25ZM9 7.25H13.5V8.75H9V7.25ZM9 13.25H4.5V14.75H9V13.25ZM4.5 10.25H9V11.75H4.5V10.25Z"
      fill="currentColor"
    />
  </svg>
);

export const ChartPieLineMIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3033 3.6967C12.8968 2.29018 10.9891 1.5 9 1.5V9L14.3033 3.6967Z"
      fill="currentColor"
    />
    <path
      d="M1.5 8.99994C1.5 5.3715 4.07664 2.34487 7.5 1.64996V3.18896C4.91216 3.85503 3 6.20418 3 8.99994C3 12.3136 5.68629 14.9999 9 14.9999C12.3137 14.9999 15 12.3136 15 8.99994C15 7.88722 14.6971 6.84525 14.1693 5.95198L15.2574 4.86385C16.0427 6.0495 16.5 7.47131 16.5 8.99994C16.5 13.1421 13.1421 16.4999 9 16.4999C4.85786 16.4999 1.5 13.1421 1.5 8.99994Z"
      fill="currentColor"
    />
  </svg>
);

export const MarginCallIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M14.6096 8.86985C14.8012 7.41484 14.5073 5.93729 13.7735 4.66634L8 7.99967L8 1.33301C6.53244 1.33301 5.10589 1.81726 3.94159 2.71065C2.7773 3.60405 1.94033 4.85666 1.5605 6.27422C1.29142 7.27842 1.26241 8.32565 1.46802 9.33301H6C6.73638 9.33301 7.33334 9.92996 7.33334 10.6663V14.6329C7.84173 14.684 8.35736 14.6768 8.87018 14.6093C10.3252 14.4178 11.6763 13.7514 12.714 12.7137C13.7518 11.676 14.4181 10.3249 14.6096 8.86985Z"
      fill="currentColor"
    />
    <path d="M6 10.6663H0.666668V11.9997H6V10.6663Z" fill="currentColor" />
    <path d="M0.666668 13.333H6V14.6663H0.666668V13.333Z" fill="currentColor" />
  </svg>
);
