import { BaseModal } from '@alfalab/core-components/base-modal';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import styles from './QrModal.module.css';

interface QrModalProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export const QrModal = ({ open, onClose, children }: QrModalProps) => (
  <BaseModal open={open} onClose={onClose} Backdrop={() => null}>
    <div className={styles.qrBackdrop} onClick={onClose}></div>
    <div className={styles.qrModal}>
      <div className={styles.qrHeader}>
        <CrossMIcon className={styles.qrCloseIcon} onClick={onClose} />
      </div>
      <div className={styles.qrImageWrap}>{children}</div>
    </div>
  </BaseModal>
);
