import { RootStore } from '../../useStore';

import { InstrumentItem } from '../../../types/core';

/**
 * Выбирает финансовый инструмент по идентификатору финансового инструмента
 * */
export function finInstrumentByIdSelector(
  store: RootStore
): (idFi?: InstrumentItem['idFI']) => InstrumentItem | undefined {
  return (idFi) =>
    idFi ? store.finInstrumentsTable.get('idFI', idFi) : undefined;
}
