import { useState } from 'react';
import { Spinner } from '@alfalab/core-components/spinner';

import {
  getInstructionQuestionImageUrl,
  TestPlayer,
  TestResultModal,
  ThemeCheckResult,
  ThemeQuestionAnswers,
  useCheckInstructionThemeAnswers,
  useInstructionThemeQuestions,
} from '../../../../entities/Education';

import { useTestProcess } from '../../hooks/useTestProcess';

interface InstructionThemeTestProps {
  themeId: number;
  title: string;
  onNextTheme?: () => void;
  onThemeList?: () => void;
}

export const InstructionThemeTest = ({
  themeId,
  title,
  onNextTheme,
  onThemeList,
}: InstructionThemeTestProps) => {
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState<ThemeCheckResult>();

  const { data: questions, isFetching: loading } =
    useInstructionThemeQuestions(themeId);

  const {
    mutateAsync: requestCheckAnswers,
    isLoading: checkAnswersInProgress,
  } = useCheckInstructionThemeAnswers();

  const handleAllQuestionsAnswered = async (
    answers: ThemeQuestionAnswers[]
  ) => {
    const res = await requestCheckAnswers({
      themeId,
      answers,
    });

    setShowResult(true);
    setResult(res);
  };

  const {
    current,
    setCurrent,
    setTestAnswers,
    currentQuestion,
    currentAnswers,
    setCurrentAnswer,
    handleNext,
  } = useTestProcess(questions, handleAllQuestionsAnswered);

  const closeResult = () => {
    setShowResult(false);
    setResult(undefined);
  };

  const handleRetry = () => {
    closeResult();
    setCurrent(0);
    setTestAnswers({});
  };

  const closeTest = () => {
    closeResult();
    onThemeList && onThemeList();
  };

  return (
    <>
      {currentQuestion && questions && (
        <TestPlayer
          title={title}
          questions={questions}
          currentQuestionNum={(current || 0) + 1}
          currentQuestion={currentQuestion}
          currentAnswers={currentAnswers}
          setCurrentAnswer={setCurrentAnswer}
          onNextClick={handleNext}
          loading={checkAnswersInProgress}
          getImageUrl={getInstructionQuestionImageUrl}
        />
      )}

      <Spinner visible={loading} />

      <TestResultModal
        modalTitle="Руководство по использованию веб-терминала"
        percent={result?.percent || 0}
        open={showResult}
        onClose={closeTest}
        onNext={onNextTheme}
        onRetry={handleRetry}
        onList={onThemeList}
        nextAvailable={result?.nextThemeAvailable}
        successText={result?.comment}
        errorText="Тема изучена не очень хорошо, попробуйте ещё раз отвтетить на вопросы для самопроверки"
      />
    </>
  );
};
