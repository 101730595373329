import { EventEmitter, ValidEventTypes } from 'eventemitter3';

import type { AdirAPI } from './client';
import { EntityType } from './entityTypes';

import type { useStore } from '../../store';

import { AdirApiEvent } from './types';

/**
 * Базовый сервис для работы с клиентом
 * Все сервисы которым нужен AdirAPI должен наследоваться от него
 */
export default class AdirClientService<
  T extends ValidEventTypes = string | symbol
> extends EventEmitter<T> {
  // исходящие номера заявок, результаты которых мы ожидаем
  private _client: AdirAPI;

  addClientListener(
    event: EntityType | AdirApiEvent,
    fn: (...args: any[]) => void,
    context?: any
  ) {
    // TODO сохранять все подписки от сервиса и предоставлять отладочную информацию
    this.client.addListener(event, fn, context);
  }

  removeClientListener(
    event: EntityType | AdirApiEvent,
    fn: (...args: any[]) => void,
    context?: any
  ) {
    this.client.removeListener(event, fn, context);
  }

  init() {}

  setClient(client: AdirAPI) {
    this._client = client;
  }

  /**
   * @deprecated Используй this.emit('event-name', data)
   */
  get store(): typeof useStore {
    // @ts-expect-error прокидываем стор через глобальную переменную
    // чиним проблему с циклическими зависимостями
    return window.__zsstore;
  }

  get client(): AdirAPI {
    if (!this._client?.initialized) {
      throw Error("AdirClient wasn't inited");
    }

    return this._client;
  }
}
