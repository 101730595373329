import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { currenciesFI } from '../../constants/FIModal';
import { getOrderMap } from '../../lib/domain/getOrderMap';
import { makePositionRows } from '../../lib/domain/makePositionRows';
import { useFinInfoExt } from './useFinInfoExt';
import { useFinInstrumentByIdMap } from './useFinInstrumentByIdMap';
import { useIdFiBalances } from './useIdFiBalances';
import { useMarketBoardByIdMap } from './useMarketBoardByIdMap';
import { useObjectByIdMap } from './useObjectByIdMap';
import { useObjectExtByIdMap } from './useObjectExtByIdMap';
import { useObjectTypeByIdMap } from './useObjectTypeByIdMap';
import { useOrders } from './useOrders';
import { usePositions } from './usePositions';
import { useSubAccountRazdel } from './useSubAccountRazdel';

import { ECurrencyId } from '../../types/currencyId';
import { PositionItem } from '../../types/position';
import { FinInfo } from '../../types/quotes';

export const useInstrumentPosition = (idFi?: number, idSubAccount?: number) => {
  const { accounts: clientAccounts, useFinInfoExtsList } =
    useAlfaDirectContext();
  const orders = useOrders();
  const currentStateOfFinInfoExt = useFinInfoExtsList();
  const subAccountRazdels = useSubAccountRazdel();

  const finInstrumentsTable = useFinInstrumentByIdMap();
  const objectByIdMap = useObjectByIdMap();
  const objectExtByIdMap = useObjectExtByIdMap();
  const objectTypeByIdMap = useObjectTypeByIdMap();
  const marketBoardByIdMap = useMarketBoardByIdMap();

  const positions = usePositions();
  const positionsToIdFiBalances = useIdFiBalances(positions);
  const fi = useMemo(
    () => Array.from(new Set(positionsToIdFiBalances.values())),
    [positionsToIdFiBalances]
  );
  const finInfoExts = useFinInfoExt(fi);

  return useMemo(() => {
    if (!idFi || !idSubAccount) {
      return;
    }

    const selectedFI = finInstrumentsTable.get(idFi);

    if (!selectedFI) {
      return;
    }

    const quotes: Record<number, Partial<FinInfo>> = fi.reduce((record, id) => {
      record[id] = finInstrumentsTable.get(id);

      return record;
    }, {});

    const currenciesQuotes: Record<
      number,
      Partial<FinInfo>
    > = currenciesFI.reduce((record, id) => {
      record[id] = finInstrumentsTable.get(id);

      return record;
    }, {});

    const currenciesFinInfoExts = currenciesFI.reduce(
      (acc, fi) => ({ ...acc, [fi]: currentStateOfFinInfoExt[fi] }),
      {} as typeof currentStateOfFinInfoExt
    );

    const filteredRazdels = subAccountRazdels.filter((item: any) => item);
    const subAccounts = groupBy(filteredRazdels, 'codeSubAccount');
    const selectedSubAccount = Object.keys(subAccounts).find(
      (subAccountKey) =>
        subAccounts[subAccountKey][0].idSubAccount === idSubAccount
    );

    const ordersMap = getOrderMap(orders, clientAccounts);

    if (!selectedSubAccount) {
      return;
    }

    // Данные Позиций как в таблице
    const positionsData: PositionItem[] = makePositionRows(
      {
        positions,
        quotes,
        objectsMap: objectByIdMap,
        objectExtsMap: objectExtByIdMap,
        finInfoExts,
        marketBoardMap: marketBoardByIdMap,
        subAccountRazdels,
        objectTypesMap: objectTypeByIdMap,
        ordersMap,
        currenciesQuotes,
        currenciesFinInfoExts,
        positionsToIdFiBalances,
      },
      { selectedSubAccounts: [selectedSubAccount] }
    );

    if (!positionsData) {
      return;
    }

    const relevantPositions = positions.filter(
      (position: any) =>
        position.idSubAccount === idSubAccount &&
        position.idObject !== ECurrencyId.RUR
    );

    const instrumentPositions = relevantPositions
      .filter((position) => {
        const positionFI = finInstrumentsTable.get(position.idFIBalance);

        if (!positionFI) {
          return false;
        }

        const marketBoard = marketBoardByIdMap.get(positionFI?.idMarketBoard);

        return (
          marketBoard?.idMarketBoard === selectedFI.idMarketBoard &&
          position.idObject === selectedFI.idObject
        );
      })
      .reverse();

    for (const position of instrumentPositions) {
      const pos = positionsData?.find(
        (dataRow) => dataRow.position.idPosition === position.idPosition
      );

      if (pos) {
        return pos;
      }
    }
  }, [
    idFi,
    idSubAccount,
    finInstrumentsTable,
    fi,
    subAccountRazdels,
    orders,
    clientAccounts,
    positions,
    objectByIdMap,
    objectExtByIdMap,
    finInfoExts,
    marketBoardByIdMap,
    objectTypeByIdMap,
    positionsToIdFiBalances,
    currentStateOfFinInfoExt,
  ]);
};
