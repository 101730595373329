import { LinkName } from '@terminal/core/types/layout';

import { Row } from '../model/types/row';
import { Tab } from '../model/types/tab';
import { TabSet } from '../model/types/tabSet';

/***
 * Рекурсивно обходит конфигурацию и собирает линковку из элементов Tab,
 * возвращает объект для вставки в конфигурацию (layoutLinks)
 * @param element
 */
export const getLinks = (element: Row) => {
  const links: Array<{ link: LinkName; id: string }> = [];
  const collectLinks = (element: Tab | TabSet | Row): void => {
    if (element instanceof Tab) {
      if (element.config.link) {
        links.push({ link: element.config.link, id: element.id });
      }
    } else {
      element.children.forEach((child) => {
        collectLinks(child);
      });
    }
  };

  collectLinks(element);

  return links.reduce(
    (acc, el) => {
      acc[el.link].nodeIds.push(el.id);

      return acc;
    },
    {
      blue: { nodeIds: [] },
      green: { nodeIds: [] },
      red: { nodeIds: [] },
      yellow: { nodeIds: [] },
    }
  );
};
