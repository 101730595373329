import { dateToRequestFormat } from '@terminal/core/lib/rest/lkApi';
import {
  ActiveTransfer,
  SecuritiesDocumentType,
  SecuritiesManager,
  SecuritiesPlaceItem,
  SecuritiesPosition,
  SecuritiesReasonDocument,
  SecuritiesTransferInActive,
  SecuritiesTransferInActiveBase,
  SecuritiesUserType,
} from '@terminal/core/lib/rest/lkSecurities';

import {
  AMOUNT_MINORITY,
  COUNTERAGENT_TYPES,
  getMarket,
  isAccountTypeRequired,
  isConteragentAccDepoRequired,
  isCounterpartyRequired,
  isDepoAccountCounterpartyRequired,
  isDepoCodeCounterpartyRequired,
  isDepoSectionCounterpartyRequired,
  isDocumentsRequired,
  isLegalFieldsRequired,
  isOrgNameRequired,
  isOtherPlaceNameRequired,
  isPersonalAccount,
  isRequisitesRequired,
  isSwiftRequired,
  SecurityDeponentPlaceCode,
} from '../../shared';
import {
  isAccountTypeValid,
  isDateValid,
  isDepoAccountValid,
  isDepoCodeValid,
  isDepoSectionValid,
  isFieldFilled,
  isReasonDocumentsValid,
  isSwiftValid,
  validateConteragentAccDepo,
  validateCounterpartyPlace,
  validateCounterpartyPlaceOther,
} from '../../validation';

export interface SecuritiesInFormData {
  selectedActives: ActiveTransfer[];
  positionTo: SecuritiesPosition | null;
  ownershipChange: boolean;
  marketFrom: string;
  accountType: string;
  counterpartyFrom: string;
  counterpartyFromOther: string;
  depoCodeFrom: string;
  depoAccountFrom: string;
  depoSectionFrom: string;
  contragentAccDepoFrom: string;
  contrAlfaBank: boolean;
  swift: string;
  persDocNumber: string;
  persDocDate: string;
  persDocOrg: string;
  orgName: string;
  orgOGRN: string;
  orgWhen: string;
  orgWhom: string;
  reasonDocuments: SecuritiesReasonDocument[];
  reasonOther: string;
  dealSumm: number | null;
  dealCurrency: string;
}

export interface TragetStepValidateProps {
  positionTo: SecuritiesPosition | null;
  ownershipChange: boolean;
  reasonDocuments: SecuritiesReasonDocument[];
  dealSumm: number | null;
  dealCurrency: string;
  availableDocumentTypes: SecuritiesDocumentType[] | undefined;
  dealSummRequired: boolean;
}

export function validateTargetStep(data: TragetStepValidateProps) {
  return (
    Boolean(data.positionTo) &&
    (!isDocumentsRequired(data.ownershipChange) ||
      isReasonDocumentsValid(
        data.reasonDocuments,
        data.availableDocumentTypes
      )) &&
    (!data.dealSummRequired ||
      Boolean(data.dealSumm && data.dealSumm > 0 && data.dealCurrency))
  );
}

export interface SecurityStepValidateProps {
  selectedActives: ActiveTransfer[];
  marketFrom: string;
}

export function validateSecurityStep(data: SecurityStepValidateProps) {
  return data.selectedActives.length > 0 && Boolean(data.marketFrom);
}

export interface SourceStepValidateProps {
  positionTo: SecuritiesPosition | null;
  placeFrom: SecuritiesPlaceItem | null;
  marketFrom: string;
  accountType: string;
  counterpartyFrom: string;
  counterpartyFromOther: string;
  depoCodeFrom: string;
  depoAccountFrom: string;
  depoSectionFrom: string;
  contragentAccDepoFrom: string;
  contrAlfaBank: boolean;
  swift: string;
  persDocNumber: string;
  persDocDate: string;
  persDocOrg: string;
  orgName: string;
  orgOGRN: string;
  orgWhen: string;
  orgWhom: string;
  requisitesCorrect: boolean;
  manager: SecuritiesManager | undefined;
  counteragentType: COUNTERAGENT_TYPES | undefined;
  ownershipChange: boolean;
}

export function validateSourceStep(data: SourceStepValidateProps) {
  const MARKET = getMarket(
    data.positionTo ? data.positionTo.placeCode : '',
    data.marketFrom
  );
  const MARKET_ALFA = data.positionTo ? data.positionTo.placeCode : '';
  const DEPONENT_PLACE_CODE_ALFA = data.placeFrom
    ? (data.placeFrom.deponetPlaceCode as SecurityDeponentPlaceCode)
    : SecurityDeponentPlaceCode.EMPTY;

  const otherPlaceNameRequired = isOtherPlaceNameRequired(
    data.marketFrom,
    data.contrAlfaBank
  );
  const counterpartyRequired = isCounterpartyRequired(
    data.marketFrom,
    data.accountType,
    data.contrAlfaBank
  );
  const depoAccountCounterpartyRequired = isDepoAccountCounterpartyRequired(
    data.contrAlfaBank
  );
  const depoCodeCounterpartyRequired = isDepoCodeCounterpartyRequired(
    MARKET,
    MARKET_ALFA,
    data.contrAlfaBank
  );
  const depoSectionCounterpartyRequired = isDepoSectionCounterpartyRequired(
    MARKET,
    MARKET_ALFA,
    data.contrAlfaBank
  );
  const accountTypeRequired = isAccountTypeRequired(
    data.marketFrom,
    data.contrAlfaBank
  );
  const requisitesRequired = isRequisitesRequired(data.marketFrom);
  const legalFieldsRequired = isLegalFieldsRequired({
    manager: data.manager,
    counteragentType: data.counteragentType,
    ownershipChange: data.ownershipChange,
  });

  return (
    Boolean(data.positionTo) &&
    Boolean(data.marketFrom) &&
    (!otherPlaceNameRequired ||
      validateCounterpartyPlaceOther(data.counterpartyFromOther) === '') &&
    (!counterpartyRequired ||
      validateCounterpartyPlace(data.counterpartyFrom) === '') &&
    (!depoCodeCounterpartyRequired ||
      isDepoCodeValid(data.depoCodeFrom, data.marketFrom)) &&
    (!depoAccountCounterpartyRequired ||
      isDepoAccountValid(data.depoAccountFrom, MARKET)) &&
    (!depoSectionCounterpartyRequired ||
      isDepoSectionValid(
        data.depoSectionFrom,
        data.marketFrom,
        MARKET_ALFA,
        DEPONENT_PLACE_CODE_ALFA
      )) &&
    (!isConteragentAccDepoRequired(data.contrAlfaBank) ||
      validateConteragentAccDepo(data.contragentAccDepoFrom) === '') &&
    (!isSwiftRequired(MARKET, MARKET_ALFA, data.contrAlfaBank) ||
      isSwiftValid(data.swift)) &&
    (!accountTypeRequired || isAccountTypeValid(data.accountType)) &&
    (!requisitesRequired ||
      !isPersonalAccount({
        manager: data.manager,
        counteragentType: data.counteragentType,
      }) ||
      (data.manager && Boolean(data.manager.managerFullName))) &&
    (!requisitesRequired ||
      !isPersonalAccount({
        manager: data.manager,
        counteragentType: data.counteragentType,
      }) ||
      (isFieldFilled(data.persDocNumber) &&
        isFieldFilled(data.persDocOrg) &&
        isDateValid(data.persDocDate))) &&
    (!requisitesRequired ||
      !isOrgNameRequired({
        accountType: data.accountType,
        manager: data.manager,
        counteragentType: data.counteragentType,
      }) ||
      isFieldFilled(data.orgName)) &&
    (!requisitesRequired ||
      !legalFieldsRequired ||
      isFieldFilled(data.orgOGRN)) &&
    (!requisitesRequired ||
      !legalFieldsRequired ||
      isDateValid(data.orgWhen)) &&
    (!requisitesRequired ||
      !legalFieldsRequired ||
      isFieldFilled(data.orgWhom)) &&
    (!requisitesRequired || data.requisitesCorrect)
  );
}

export function validateForm(
  data: TragetStepValidateProps &
    SecurityStepValidateProps &
    SourceStepValidateProps
) {
  return (
    validateTargetStep(data) &&
    validateSecurityStep(data) &&
    validateSourceStep(data)
  );
}

interface BuildTransferData extends SecuritiesInFormData {
  treaty: number;
  target: SecuritiesPosition;
  source: SecuritiesPlaceItem;
  userType: SecuritiesUserType | undefined;
  manager: SecuritiesManager | undefined;
  counteragentType: COUNTERAGENT_TYPES | undefined;
  dealSummShow: boolean;
}

export function buildTransferData({
  selectedActives,
  positionTo,
  ownershipChange,
  marketFrom,
  accountType,
  counterpartyFrom,
  counterpartyFromOther,
  depoCodeFrom,
  depoAccountFrom,
  depoSectionFrom,
  contragentAccDepoFrom,
  contrAlfaBank,
  swift,
  persDocNumber,
  persDocDate,
  persDocOrg,
  orgName,
  orgOGRN,
  orgWhen,
  orgWhom,
  reasonDocuments,
  reasonOther,
  dealSumm,
  dealCurrency,

  treaty,
  target,
  source,
  userType,
  manager,
  counteragentType,
  dealSummShow,
}: BuildTransferData): SecuritiesTransferInActive[] {
  const MARKET = getMarket(positionTo ? positionTo.placeCode : '', marketFrom);
  const MARKET_ALFA = positionTo ? positionTo.placeCode : '';
  const documentsRequired = isDocumentsRequired(ownershipChange);
  const documents = reasonDocuments.map((document) => {
    return {
      ...document,
      date: dateToRequestFormat(document.date),
    };
  });
  const personalDocRequired =
    isRequisitesRequired(marketFrom) &&
    isPersonalAccount({
      manager,
      counteragentType,
    });

  const legalFieldsRequired =
    isRequisitesRequired(marketFrom) &&
    isLegalFieldsRequired({
      manager,
      counteragentType,
      ownershipChange,
    });

  const accountTypeRequired = isAccountTypeRequired(marketFrom, contrAlfaBank);

  const actives = [...selectedActives];

  const baseData: SecuritiesTransferInActiveBase = {
    treaty: treaty,
    targetAccCode: target.accCode,
    targetPlaceCode: target.placeCode,
    targetPlaceName: target.placeName,
    targetDeponetPlaceCode: target.deponetPlaceCode,
    targetDepoAccount: target.depoAccount,
    targetDepositary: target.depositary,
    targetDpName: target.dpName,

    changeOfQwner: ownershipChange,
    srcPlaceCode: source.placeCode,
    srcDeponetPlaceCode: source.deponetPlaceCode,
    srcPlaceName: source.name,
    srcOtherPlace: isOtherPlaceNameRequired(marketFrom, contrAlfaBank)
      ? counterpartyFromOther
      : undefined,
    srcContragent: isCounterpartyRequired(
      marketFrom,
      accountType,
      contrAlfaBank
    )
      ? counterpartyFrom
      : undefined,
    srcDeponentCode: isDepoCodeCounterpartyRequired(
      MARKET,
      MARKET_ALFA,
      contrAlfaBank
    )
      ? depoCodeFrom
      : undefined,
    srcContragentAccount: isDepoAccountCounterpartyRequired(contrAlfaBank)
      ? depoAccountFrom
      : '',
    srcDepoAccountPart: isDepoSectionCounterpartyRequired(
      MARKET,
      MARKET_ALFA,
      contrAlfaBank
    )
      ? depoSectionFrom
      : undefined,
    srcContragentAccDepo: isConteragentAccDepoRequired(contrAlfaBank)
      ? contragentAccDepoFrom
      : undefined,
    swiftCode: isSwiftRequired(MARKET, MARKET_ALFA, contrAlfaBank)
      ? swift
      : undefined,
    isInternal: contrAlfaBank,

    // Реестр
    srcAccountType: accountTypeRequired ? accountType : undefined,
    srcAccountTypeDescription: accountTypeRequired
      ? userType?.description || ''
      : undefined,
    srcPersonalName: personalDocRequired
      ? manager?.managerFullName || ''
      : undefined,
    srcPersonalDocNumber: personalDocRequired ? persDocNumber : undefined,
    srcPersonalDocDate: personalDocRequired ? persDocDate : undefined,
    srcPersonalDocOrg: personalDocRequired ? persDocOrg : undefined,
    srcOrgName:
      isRequisitesRequired(marketFrom) &&
      isOrgNameRequired({ accountType, manager, counteragentType })
        ? orgName
        : undefined,
    srcOrgOgrn: legalFieldsRequired ? orgOGRN : undefined,
    srcOrgRegDate: legalFieldsRequired
      ? dateToRequestFormat(orgWhen)
      : undefined,
    srcOrgRegOrg: legalFieldsRequired ? orgWhom : undefined,

    comment: reasonOther,
    documents: documentsRequired ? documents : undefined,

    // Отправляем параметры сделки, если заполнена сумма
    tradeVolume:
      dealSummShow && dealSumm ? (dealSumm || 0) / AMOUNT_MINORITY : undefined,
    tradeCurrCode: dealSummShow && dealSumm ? dealCurrency : undefined,
  };

  return actives.map((active) => ({
    ...baseData,
    pCode: active.paper.pCode,
    isin: active.paper.isin,
    qty: (active.count || 0) / AMOUNT_MINORITY,
    tradeDate: dateToRequestFormat(active.dealDate),
    tradeDateDelivery: dateToRequestFormat(active.shipDate),
    reference: active.reference,
    gbox: active.gbox,
  }));
}
