import { useEffect, useState } from 'react';

import { ContestStep } from '../lib/const/ContestStep';
import { useContestInfo } from './useContestInfo';

export const useContestProvider = (contestid: number) => {
  const [step, setStep] = useState<ContestStep>(ContestStep.Load);

  const {
    data: info,
    refetch: refetchContestInfo,
    isError,
  } = useContestInfo(contestid);

  useEffect(() => {
    if (info) {
      if (info.status === 'notRegistered') {
        setStep(ContestStep.Form);
      } else if (info.status === 'registered') {
        setStep(ContestStep.Registred);
      } else if (info.status === 'blocked') {
        setStep(ContestStep.Blocked);
      } else if (info.status === 'activeAccountBlocked') {
        setStep(ContestStep.ActiveAccountBlocked);
      }
    }
  }, [info]);

  useEffect(() => {
    if (isError) {
      setStep(ContestStep.Error);
    }
  }, [isError]);

  return {
    step,
    setStep,
    info,
    refetchContestInfo,
  };
};
