/**
 * Функция сортирует массив объектов по переданному полю даты.
 *
 * @param fieldName Имя поля даты.
 */
function sortByDate<T extends string>(
  fieldName: T
): <R extends Record<T, Date>>(obj1: R, obj2: R) => number;
function sortByDate<T extends string>(fieldName: T) {
  return <R extends Record<T, Date>>(obj1: R, obj2: R) =>
    Number(obj1[fieldName]) - Number(obj2[fieldName]);
}

export { sortByDate };
