export enum EntityType {
  // @see Entities
  // Click for details
  // -
  UnknownEntity = 'UnknownEntity',

  Int32Element = 'Int32Element',

  ADField = 'ADField',

  ADMessage = 'ADMessage',

  VariantTypeElement = 'VariantTypeElement',

  ZoneConnectionInfo = 'ZoneConnectionInfo',

  VolumeForPrice = 'VolumeForPrice',

  ClientRestrictions = 'ClientRestrictions',

  ChartSequenceBegin = 'ChartSequenceBegin',

  ClientEnterOrderReq = 'ClientEnterOrderReq',

  ClientCancelOrderReq = 'ClientCancelOrderReq',

  ClientEnterOrderRsp = 'ClientEnterOrderRsp',

  ClientCancelOrderRsp = 'ClientCancelOrderRsp',

  ChartSequenceEnd = 'ChartSequenceEnd',

  ClientUchPosEntity = 'ClientUchPosEntity',

  ProtocolInfo = 'ProtocolInfo',

  // ответ на некоторую команду
  ClientMessageEntity = 'ClientMessageEntity',

  // запрос истории по графикам
  ArchiveRequestEntity = 'ArchiveRequestEntity',

  // аутентификация пользователя
  AuthenticationRequestEntity = 'AuthenticationRequestEntity',

  // идентификация пользователя
  AuthorizeWithConnectionKeyEntity = 'AuthorizeWithConnectionKeyEntity',

  // подача клиентского поручения
  ClientOrderEntity = 'ClientOrderEntity',

  // управление подпиской на данные
  DataFlowSubscribeEntity = 'DataFlowSubscribeEntity',

  // явное отключения от фронтенда
  DisconnectEntity = 'DisconnectEntity',

  // регистрация сертификата
  EnrollCertificateEntity = 'EnrollCertificateEntity',

  // подержание соединения
  HeartbeatEntity = 'HeartbeatEntity',

  // запрос торговых лимитов
  TradeLimitRequestEntity = 'TradeLimitRequestEntity',

  // управление сертификатом
  UpdateCertificateEntity = 'UpdateCertificateEntity',

  //	допустимый дисконт для борда
  AllowedDiscountTypeEntity = 'AllowedDiscountTypeEntity',

  //	допустимые параметры ордера
  AllowedOrderParamEntity = 'AllowedOrderParamEntity',

  //	допустимый штраф для борда
  AllowedPenaltyTypeEntity = 'AllowedPenaltyTypeEntity',

  //	допустимое условие для борда
  AllowedPriceControlTypeEntity = 'AllowedPriceControlTypeEntity',

  //	допустимый код расчетов для борда
  AllowedSettleCodeEntity = 'AllowedSettleCodeEntity',

  //	административный режим
  AllowOrderStatusEntity = 'AllowOrderStatusEntity',

  //	сделка
  AllTradeEntity = 'AllTradeEntity',

  //	история графиков
  ChartArchiveEntity = 'ChartArchiveEntity',

  //	параметры сертификата
  ClientCertificateEntity = 'ClientCertificateEntity',

  //
  ClientGTSubAccountEntity = 'ClientGTSubAccountEntity',

  //	клиентская балансовая операция
  ClientOperationEntity = 'ClientOperationEntity',

  //
  ClientObjectBalanceIdFIEntity = 'ClientObjectBalanceIdFIEntity',

  //	позиция
  ClientPositionEntity = 'ClientPositionEntity',

  //	клиентские ограничения
  ClientRestrictionsEntity = 'ClientRestrictionsEntity',

  //
  ClientSubAccountEntity = 'ClientSubAccountEntity',

  //	контрагент
  ContragentEntity = 'ContragentEntity',

  //	дополнительная валюта котировок
  CurrencyMarketBoardEntity = 'CurrencyMarketBoardEntity',

  //	биржа
  ExchangeEntity = 'ExchangeEntity',

  //	квота
  FinInfoEntity = 'FinInfoEntity',

  //	расширенные данные инструмента
  FinInfoExtEntity = 'FinInfoExtEntity',

  //	финансовый инструмент
  FinInstrumentEntity = 'FinInstrumentEntity',

  //	конец инициализации таблицы
  InitFinishedEntity = 'InitFinishedEntity',

  //	превышение клиентских ограничений
  LimitsOverflowEntity = 'LimitsOverflowEntity',

  //	режим рынка
  MarketBoardEntity = 'MarketBoardEntity',

  //	рынок
  MarketEntity = 'MarketEntity',

  //	новость
  NewsEntity = 'NewsEntity',

  //	объект
  ObjectEntity = 'ObjectEntity',

  //	группа выпусков
  ObjectGroupEntity = 'ObjectGroupEntity',

  //	тип объекта
  ObjectTypeEntity = 'ObjectTypeEntity',

  //	стакан
  OrderBookEntity = 'OrderBookEntity',

  OrderBookLevelEntity = 'OrderBookLevelEntity',

  OrderBookWithYieldEntity = 'OrderBookWithYieldEntity',

  OrderBookLevelWithYieldEntity = 'OrderBookLevelWithYieldEntity',

  AvailableOrderBookEntity = 'AvailableOrderBookEntity',

  //	ордер
  OrderEntity = 'OrderEntity',

  //	код расчета
  SettleCodeEntity = 'SettleCodeEntity',

  //	раздел акаунта
  SubAccountRazdelEntity = 'SubAccountRazdelEntity',

  //	торговые лимиты
  TradeLimitResponseEntity = 'TradeLimitResponseEntity',

  //	статус режима торговли
  TradePeriodStatusEntity = 'TradePeriodStatusEntity',

  //
  TSAccount4LSEntity = 'TSAccount4LSEntity',

  //	данные пользователя
  UserCredentialsEntity = 'UserCredentialsEntity',

  IntervalInfoEntity = 'IntervalInfoEntity',

  ResponseForCertificateActionSMSEntity = 'ResponseForCertificateActionSMSEntity',

  ResponseForCertificateActionWithSMSEntity = 'ResponseForCertificateActionWithSMSEntity',

  OhlcvDataPointEntity = 'OhlcvDataPointEntity',

  ClientSubAccPositionEntity = 'ClientSubAccPositionEntity',

  BaseOperationEntity = 'BaseOperationEntity',

  ClientAccountEntity = 'ClientAccountEntity',

  ProtocolInfoEntity = 'ProtocolInfoEntity',

  CertificateRequestEntity = 'CertificateRequestEntity',

  CertificateResponseEntity = 'CertificateResponseEntity',

  RequestForCertificateActionSMSEntity = 'RequestForCertificateActionSMSEntity',

  CandleStandardDbEntity = 'CandleStandardDbEntity',

  RequestForCertificateActionWithSMSEntity = 'RequestForCertificateActionWithSMSEntity',

  ArchiveRequest3Entity = 'ArchiveRequest3Entity',

  PassportAuthenticationRequestEntity = 'PassportAuthenticationRequestEntity',

  BrokerRecommendationEntity = 'BrokerRecommendationEntity',

  ChatMessageEntity = 'ChatMessageEntity',

  ChatGroupEntity = 'ChatGroupEntity',

  //расширенный объект (используем отсюда НКД)
  ObjectExtEntity = 'ObjectExtEntity',

  MpvDataPointEntity = 'MpvDataPointEntity',

  ResetDataRequestEntity = 'ResetDataRequestEntity',

  TrackPriceEntity = 'TrackPriceEntity',

  NotificationSubscriptionEntity = 'NotificationSubscriptionEntity',

  NotificationSubscriptionStateEntity = 'NotificationSubscriptionStateEntity',
}
