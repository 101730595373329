import { Space } from '@alfalab/core-components/space';
import { Switch } from '@alfalab/core-components/switch';
import { Textarea } from '@alfalab/core-components/textarea';
import { Typography } from '@alfalab/core-components/typography';

import {
  SecuritiesDocumentType,
  SecuritiesReasonDocument,
} from '@terminal/core/lib/rest/lkSecurities';

import { TooltipOwnerChange, TooltipReason } from '../Tooltips';
import { ReasonDocuments } from './ReasonDocuments';

interface SecurityReasonSectionProps {
  ownershipChange: boolean;
  ownershipDisabled?: boolean;
  onOwnershipChange?: (ownership: boolean) => void;
  reasonDocuments: SecuritiesReasonDocument[];
  onReasonDocumentChange: (
    index: number,
    doc: SecuritiesReasonDocument
  ) => void;
  onAddReasonDocument: (doc: SecuritiesReasonDocument) => void;
  onDeleteReasonDocument: (index: number) => void;
  reasonOther: string;
  onReasonOtherChange: (value: string) => void;
  documentTypes?: SecuritiesDocumentType[];
  documentTypesPending: boolean;
  operationDirection: 'in' | 'out';
}

export const SecurityReasonSection = ({
  ownershipChange,
  ownershipDisabled,
  onOwnershipChange,
  reasonDocuments,
  onReasonDocumentChange,
  onAddReasonDocument,
  onDeleteReasonDocument,
  reasonOther,
  onReasonOtherChange,
  documentTypes,
  documentTypesPending,
  operationDirection,
}: SecurityReasonSectionProps) => {
  return (
    <>
      <Typography.Text
        view="secondary-large"
        color="primary"
        weight="bold"
        tag="p"
      >
        Основание операции
        <TooltipReason />
      </Typography.Text>
      <Space fullWidth direction="vertical">
        {ownershipChange !== undefined && (
          <Switch
            checked={ownershipChange}
            disabled={ownershipDisabled}
            label="Смена прав собственности на ценную бумагу"
            addons={<TooltipOwnerChange />}
            onChange={(_, payload) => {
              if (onOwnershipChange) {
                onOwnershipChange(payload?.checked || false);
              }
            }}
          />
        )}

        <ReasonDocuments
          ownershipChange={ownershipChange}
          reasonDocuments={reasonDocuments}
          onDocumentChange={onReasonDocumentChange}
          onAddDocument={onAddReasonDocument}
          onDeleteDocument={onDeleteReasonDocument}
          documentTypes={documentTypes}
          documentTypesPending={documentTypesPending}
          operationDirection={operationDirection}
        />
        <Textarea
          label="Комментарий"
          labelView="outer"
          block={true}
          size="s"
          placeholder="Оставьте комментарий"
          name="counterpartyPlaceOther"
          value={reasonOther}
          onChange={(_, { value }) => onReasonOtherChange(value)}
          hint="При необходимости перечислите дополнительные документы, наименования места хранения и прочие примечания"
        />
      </Space>
    </>
  );
};
