import { ValidateResult } from '../../../../shared/hooks/useValidation';

export function validateBic(bic: string): ValidateResult {
  if (!bic.length) {
    return {
      code: 1,
      message: 'Введите БИК',
    };
  } else if (!/^[0-9]{9}$/.test(bic)) {
    return {
      code: 2,
      message: 'Состоит из 9 цифр',
    };
  }

  return {
    code: 0,
    message: '',
  };
}

export function isBicValid(bic: string): boolean {
  return validateBic(bic).code === 0;
}
