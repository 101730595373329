import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { Divider } from '@alfalab/core-components/divider';
import { Radio } from '@alfalab/core-components/radio';

import { Stepper } from '@terminal/common/components/Stepper';
import { ListPicker } from '@terminal/common/ui/ListPicker';
import { getAssetUnits } from '@terminal/core/lib/format';
import { TradesFeedSettings } from '@terminal/core/types/tradeFeed';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

import { useWidgetContext } from '../../../../shared';

import { useIsLotCount } from '../../../../shared/hooks/useIsLotCount';

import styles from './VolumeFilter.module.css';

export const VolumeFilter = ({
  idFi,
  nodeId,
  tradesFeedSettings,
}: {
  idFi: number | undefined;
  nodeId: string | undefined;
  tradesFeedSettings: TradesFeedSettings[] | undefined;
}) => {
  const { setTradeFeedSettings } = useWidgetContext();

  const isLotCount = useIsLotCount();

  const suffix = isLotCount ? 'лот' : 'шт';

  const initialCheckedFilterId = useMemo<number | undefined>(() => {
    if (tradesFeedSettings && nodeId !== undefined) {
      return tradesFeedSettings.reduce((acc, item, idx) => {
        if (item.nodeId === nodeId) {
          return idx;
        }

        return acc;
      }, undefined as FiltersState['checkedFilterId']);
    }

    return undefined;
  }, [nodeId, tradesFeedSettings]);

  const initialValues = useMemo<number[]>(() => {
    if (tradesFeedSettings && nodeId !== undefined) {
      const values = tradesFeedSettings.map((item) => {
        return item.volume;
      });

      return values;
    }

    return [0, 0, 0];
  }, [nodeId, tradesFeedSettings]);

  useEffect(() => {
    setValues([...initialValues]);
  }, [initialValues]);

  useEffect(() => {
    setCheckedFilterId(initialCheckedFilterId);
  }, [initialCheckedFilterId]);

  const [isOpen, setIsOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [values, setValues] = useState<number[]>([]);
  const [checkedFilterId, setCheckedFilterId] = useState<number | undefined>(
    undefined
  );

  const setValuesCallback = useCallback(
    (newValues: number[]) => {
      setValues(newValues);
      setIsChanged(true);
    },
    [setValues, setIsChanged]
  );
  const setCheckedFilterIdCallback = useCallback(
    (newCheckedFilterId: number | undefined) => {
      setCheckedFilterId(newCheckedFilterId);
      setIsChanged(true);
    },
    [setCheckedFilterId, setIsChanged]
  );

  const onCloseDropdown = useCallback(() => {
    setValues([...initialValues]);
    setCheckedFilterId(initialCheckedFilterId);
    setIsChanged(false);
  }, [initialCheckedFilterId, initialValues]);

  const handleCancelClick = useCallback(() => {
    onCloseDropdown();
    setIsOpen(false);
  }, [onCloseDropdown]);

  const handleConfirmClick = useCallback(() => {
    if (idFi) {
      const volume =
        checkedFilterId !== undefined ? values[checkedFilterId] : undefined;

      setTradeFeedSettings(idFi, nodeId, checkedFilterId, volume);
    }

    setIsChanged(false);
    setIsOpen(false);
  }, [idFi, checkedFilterId, nodeId, values, setTradeFeedSettings]);

  const title = useMemo(() => {
    const count =
      typeof checkedFilterId === 'number' && initialValues[checkedFilterId];

    if (count) {
      const rightSuffix = getAssetUnits(
        count,
        isLotCount ? OrderDimensionEnum.LOT : OrderDimensionEnum.PIECE
      );

      return `от ${count} ${rightSuffix}`;
    } else {
      return 'Объем';
    }
  }, [checkedFilterId, initialValues, isLotCount]);

  return (
    <ListPicker
      title={title}
      onCloseDropdown={onCloseDropdown}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <VolumeFilterItem
        filterId={0}
        values={values}
        checkedFilterId={checkedFilterId}
        minority={1}
        suffix={suffix}
        setValues={setValuesCallback}
        setCheckedFilterId={setCheckedFilterIdCallback}
      />
      <VolumeFilterItem
        filterId={1}
        values={values}
        checkedFilterId={checkedFilterId}
        minority={1}
        suffix={suffix}
        setValues={setValuesCallback}
        setCheckedFilterId={setCheckedFilterIdCallback}
      />
      <VolumeFilterItem
        filterId={2}
        values={values}
        checkedFilterId={checkedFilterId}
        minority={1}
        suffix={suffix}
        setValues={setValuesCallback}
        setCheckedFilterId={setCheckedFilterIdCallback}
      />
      <Divider />

      <div className={styles.volumeFilterButtons}>
        <Button
          onClick={handleCancelClick}
          size="s"
          view="ghost"
          disabled={!isChanged}
          block
        >
          Сбросить
        </Button>
        <Button
          onClick={handleConfirmClick}
          size="s"
          disabled={!isChanged}
          view="accent"
          block
        >
          Применить
        </Button>
      </div>
    </ListPicker>
  );
};

type FiltersState = {
  checkedFilterId: number | undefined;
  values: number[];
};

type VolumeFilterItemProps = {
  filterId: number;
  values: number[];
  setValues: (newValues: number[]) => void;
  checkedFilterId: number | undefined;
  setCheckedFilterId: (newCheckedFilterId: number | undefined) => void;
  minority: number;
  suffix: string;
};

export const VolumeFilterItem = ({
  filterId,
  values,
  setValues,
  checkedFilterId,
  setCheckedFilterId,
  minority,
  suffix,
}: VolumeFilterItemProps) => {
  const isChecked = filterId === checkedFilterId;
  const value = values[filterId];

  const handleRadioClick = useCallback(() => {
    if (checkedFilterId === filterId) {
      setCheckedFilterId(undefined);
    } else {
      setCheckedFilterId(filterId);
    }
  }, [checkedFilterId, filterId, setCheckedFilterId]);

  const handleAmountChange = useCallback(
    (newValue) => {
      values[filterId] = newValue;
      setValues([...values]);
    },
    [filterId, setValues, values]
  );

  const onAdd = useCallback(() => {
    values[filterId] += 1;
    setValues([...values]);
  }, [values, filterId, setValues]);

  const onSub = useCallback(() => {
    values[filterId] = Math.max(0, values[filterId] - 1);
    setValues([...values]);
  }, [values, filterId, setValues]);

  return (
    <div className={styles.volumeFilter}>
      <Radio
        onClick={handleRadioClick}
        checked={isChecked}
        value={filterId}
        label={`Фильтр ${filterId + 1}`}
        className={styles.volumeFilterRadio}
      />
      <AmountInput
        label="Объем, от"
        labelView="outer"
        size="s"
        bold={false}
        integersOnly
        onChange={(_, { value }) => handleAmountChange(value || 0)}
        disabled={!isChecked}
        value={value}
        minority={minority}
        suffix={suffix}
        fieldClassName={styles.volumeFilterAmount}
        rightAddons={<Stepper onAdd={onAdd} onSub={onSub} />}
      />
    </div>
  );
};
