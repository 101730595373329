import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useMedia } from 'react-use';

import { context } from './context';
import { getThemeByPreference } from './getThemeByPreference';

import { PreferredTheme } from './types/PreferredTheme';
import { Theme } from './types/Theme';
import { ThemeContext } from './types/ThemeContext';

export type ThemeContextProviderProps = {
  children: (theme: Theme) => ReactNode;
  /**
   * Прредпочтительная тема по умолчанию
   * @default `auto`
   * */
  preferredThemeDefault?: PreferredTheme;
  /**
   * Обработчик изменения предпочтительной темы.
   * Может использоваться для сохранения настройки во внешнее хранилище.
   * */
  onPreferredThemeChange?: (preferredTheme: PreferredTheme) => void;
};

/**
 * Контекст темы приложения
 * */
export const ThemeContextProvider: FC<ThemeContextProviderProps> = (props) => {
  const {
    preferredThemeDefault = 'auto',
    onPreferredThemeChange,
    children,
  } = props;
  const prefersDark = useMedia('(prefers-color-scheme: dark)', false);
  const [preferredTheme, setPreferredTheme] = useState(preferredThemeDefault);

  const value = useMemo<ThemeContext>(
    () => ({
      theme: getThemeByPreference(preferredTheme, prefersDark),
      preferredTheme,
      setPreferredTheme: (preferredThemeChanged) => {
        setPreferredTheme(preferredThemeChanged);
        onPreferredThemeChange?.(preferredThemeChanged);
      },
    }),
    [preferredTheme, prefersDark, onPreferredThemeChange]
  );

  return (
    <context.Provider value={value}>{children(value.theme)}</context.Provider>
  );
};
