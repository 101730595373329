import { CertificateStateType, CertificateType } from '../lib/client/entities';

export interface Certificate {
  idLogin: number;
  idCertificate: number;
  alias: string;
  description: string;
  state: CertificateStateType;
  type: CertificateType;
  certificate: ArrayBuffer;
  idDevice: string;
  parsedPayload?: {
    startDate?: Date;
    endDate?: Date;
    owner?: string;
    visitorId?: string;
  };
}

export enum CertificateEnrollStep {
  Idle,
  InProgress,
  OnConfirmation,
  Error,
  Success,
}
