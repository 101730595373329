import { format, parseISO } from 'date-fns';

import {
  SecuritiesActive,
  SecuritiesActiveIn,
  SecuritiesActivePlaceItem,
  SecuritiesContragent,
  SecuritiesContragentRequisites,
  SecuritiesDocumentType,
  SecuritiesManager,
  SecuritiesOperationType,
  SecuritiesPlaceItem,
  SecuritiesPosition,
  SecuritiesReasonDocument,
} from '@terminal/core/lib/rest/lkSecurities';

export enum SecurityPlaceCode {
  MICEX_SHR = 'MICEX_SHR',
  EUROTRADE = 'EUROTRADE',
  SPBEX_FSHR = 'SPBEX_FSHR',
  OTC_SPBEX_NOTRADE = 'OTC_SPBEX_NOTRADE',
  OTHER = 'OTHER',
  REESTR = 'REESTR',
}

export enum SecurityDeponentPlaceCode {
  NRD = 'NRD',
  RDC = 'RDC',
  SPB = 'SPB',
  OTC_SPBEX_NOTRADE = 'OTC_SPBEX_NOTRADE',
  EUROCLEAR = 'EUROCLEAR',
  // Вот эти 3 возможно ни когда и не появятся
  CREST = 'CREST',
  DTC = 'DTC',
  FRANKFURT = 'FRANKFURT',

  REESTR = 'REESTR',
  OTHER = 'OTHER',
  EMPTY = '',
}

export const SECURITY_DEPONENT_PLACE_TO_ALTA: { [s: string]: string } = {
  [SecurityDeponentPlaceCode.NRD]: SecurityPlaceCode.MICEX_SHR,
  [SecurityDeponentPlaceCode.RDC]: SecurityPlaceCode.SPBEX_FSHR,
  [SecurityDeponentPlaceCode.SPB]: SecurityPlaceCode.SPBEX_FSHR,
  [SecurityDeponentPlaceCode.OTC_SPBEX_NOTRADE]:
    SecurityPlaceCode.OTC_SPBEX_NOTRADE,
  [SecurityDeponentPlaceCode.EUROCLEAR]: SecurityPlaceCode.EUROTRADE,
  [SecurityDeponentPlaceCode.CREST]: SecurityPlaceCode.EUROTRADE,
  [SecurityDeponentPlaceCode.DTC]: SecurityPlaceCode.EUROTRADE,
  [SecurityDeponentPlaceCode.FRANKFURT]: SecurityPlaceCode.EUROTRADE,
  [SecurityDeponentPlaceCode.OTHER]: SecurityPlaceCode.OTHER,
  [SecurityDeponentPlaceCode.REESTR]: SecurityPlaceCode.REESTR,
};

export const SECURITY_PLACES_MAP: {
  [s: string]: Array<SecurityDeponentPlaceCode>;
} = {
  [SecurityDeponentPlaceCode.NRD]: [
    SecurityDeponentPlaceCode.NRD,
    SecurityDeponentPlaceCode.REESTR,
  ],
  [SecurityDeponentPlaceCode.SPB]: [
    SecurityDeponentPlaceCode.NRD,
    SecurityDeponentPlaceCode.RDC,
    SecurityDeponentPlaceCode.SPB,
    SecurityDeponentPlaceCode.EUROCLEAR,
  ],
  [SecurityDeponentPlaceCode.RDC]: [
    SecurityDeponentPlaceCode.NRD,
    SecurityDeponentPlaceCode.RDC,
    SecurityDeponentPlaceCode.SPB,
    SecurityDeponentPlaceCode.EUROCLEAR,
  ],
  [SecurityDeponentPlaceCode.OTC_SPBEX_NOTRADE]: [
    SecurityDeponentPlaceCode.RDC,
    SecurityDeponentPlaceCode.SPB,
  ],
};

export const SECURITY_PLACES_INNER_BROKER_MAP: {
  [s: string]: Array<SecurityDeponentPlaceCode>;
} = {
  [SecurityDeponentPlaceCode.NRD]: [SecurityDeponentPlaceCode.NRD],
  [SecurityDeponentPlaceCode.SPB]: [
    SecurityDeponentPlaceCode.NRD,
    SecurityDeponentPlaceCode.SPB,
  ],
  [SecurityDeponentPlaceCode.RDC]: [
    SecurityDeponentPlaceCode.NRD,
    SecurityDeponentPlaceCode.SPB,
  ],
  [SecurityDeponentPlaceCode.OTC_SPBEX_NOTRADE]: [
    SecurityDeponentPlaceCode.OTC_SPBEX_NOTRADE,
  ],
  [SecurityDeponentPlaceCode.EUROCLEAR]: [SecurityDeponentPlaceCode.EUROCLEAR],
};

const placeOrder: string[] = [
  SecurityPlaceCode.MICEX_SHR,
  SecurityPlaceCode.SPBEX_FSHR,
  SecurityPlaceCode.OTC_SPBEX_NOTRADE,
  SecurityPlaceCode.EUROTRADE,
];

export function sortPlaces(a: SecuritiesPosition, b: SecuritiesPosition) {
  if (
    placeOrder.indexOf(a.placeCode) < 0 &&
    placeOrder.indexOf(b.placeCode) >= 0
  ) {
    return 1;
  } else if (
    placeOrder.indexOf(a.placeCode) >= 0 &&
    placeOrder.indexOf(b.placeCode) < 0
  ) {
    return -1;
  }

  return placeOrder.indexOf(a.placeCode) - placeOrder.indexOf(b.placeCode);
}

export const OTHER_CONTERAGENT = 'OTHER';

export const AMOUNT_MINORITY = 100;

export const ALFABANK_CONTRAGENT_NAME = 'АО «Альфа-Банк»';

export const securityPaperKey = (
  paper: SecuritiesActiveIn | SecuritiesActive
): string => {
  if ('accCode' in paper) {
    return paper.accCode + paper.placeCode + paper.isin + paper.regCode;
  } else {
    return paper.placeCode + paper.isin + paper.regCode;
  }
};

export const securityPaperDescription = (
  security: SecuritiesActiveIn | SecuritiesActive
): string => {
  if ('actFullName' in security) {
    return `${security.pName}, ${securityRegNumbers(
      security.regCode,
      security.isin
    )}, ${security.actFullName}`;
  } else {
    return `${security.pName}, ${securityRegNumbers(
      security.regCode,
      security.isin
    )}`;
  }
};

export const securityRegNumbers = (regcode: string, isin: string) => {
  return regcode === '' && isin === ''
    ? ''
    : regcode !== '' && isin !== ''
    ? regcode === isin
      ? regcode
      : regcode + ' / ' + isin
    : regcode + isin;
};

export const securityPlaceKey = (place: SecuritiesPlaceItem | null) => {
  return place ? place.id.toString() : '';
};

export const isPlaceFitActive = (
  place: SecuritiesActivePlaceItem,
  active: SecuritiesActive | SecuritiesActiveIn | null
) => {
  if (!active) {
    return true;
  }

  return !(isRuSecurity(active) && place.placeCode === 'EUROTRADE');
};

export const isRuSecurity = (
  paper: SecuritiesActive | SecuritiesActiveIn
): boolean => {
  return paper.isin.toLowerCase().indexOf('ru') === 0;
};

export const isConteragentAccDepoRequired = (contrAlfaBank: boolean) => {
  return contrAlfaBank;
};

export const isAccountTypeRequired = (
  market: string,
  contrAlfaBank: boolean
) => {
  return market === 'REESTR' && !contrAlfaBank;
};

export const isOtherPlaceNameRequired = (
  market: string,
  contrAlfaBank: boolean
): boolean => {
  return market === 'OTHER' && !contrAlfaBank;
};

export const isCounterpartyRequired = (
  market: string,
  accountType: string,
  contrAlfaBank: boolean
): boolean => {
  return (
    (market !== 'REESTR' || (market === 'REESTR' && accountType !== 'OWNER')) &&
    !contrAlfaBank
  );
};

export const enum COUNTERAGENT_TYPES {
  NOT_SELECTED = 'NOT_SELECTED',
  PERSON = 'PERSON',
  LEGAL = 'LEGAL',
}

/**
 * Показываем ли выбор типа контрагента для реестра (физ./юр.)
 * @param {boolean} ownershipChange  - смена прав собственности
 * @param {string} market  - рынок конрагента
 * @returns {boolean}
 */
export const isCounteragentTypeShow = (
  ownershipChange: boolean,
  market: string
): boolean => ownershipChange && market === SecurityPlaceCode.REESTR;

/**
 * Показываем ли тумблер "Контрагент в Альфа-Банке"
 * @param ownershipChange - смена прав собственности
 * @param transferType - тип перевода
 */
export const isContrAlfaBankShow = (
  ownershipChange: boolean,
  transferType: 'in' | 'out'
) => {
  return transferType === 'out' || (transferType === 'in' && ownershipChange);
};

/**
 * Показываем ли выбор ген. соглашения
 * @param ownershipChange - смена прав собственности
 * @param transferType - тип перевода
 * @param contrAlfaBank - контрагет - АльфаБанк
 */
export const isContrTreatyRequired = (
  ownershipChange: boolean,
  transferType: 'in' | 'out',
  contrAlfaBank: boolean
) => {
  return !ownershipChange && transferType === 'out' && contrAlfaBank;
};

/** Показываем ли список контрагентов
 * @param {string} market - рынок конрагента
 * @param {string} alfaMarket - рынок выбранной позиции в альфе
 * @param {boolean} contrAlfaBank - контрагет - АльфаБанк
 * @returns {boolean}
 */
export const isConteragentShow = (
  market: string,
  alfaMarket: string,
  contrAlfaBank: boolean
): boolean => {
  return (
    market === alfaMarket &&
    market !== SecurityPlaceCode.REESTR &&
    alfaMarket !== SecurityPlaceCode.EUROTRADE &&
    !contrAlfaBank
  );
};

export const getDepoAccountLabel = (alfaMarket: string) => {
  return alfaMarket === SecurityPlaceCode.EUROTRADE ? 'Счёт' : 'Счёт депо';
};

export const isDepoAccountCounterpartyRequired = (contrAlfaBank: boolean) => {
  return !contrAlfaBank;
};

export const getDepoSectionLabel = (alfaMarket: string) => {
  return alfaMarket === SecurityPlaceCode.EUROTRADE
    ? 'Субсчёт'
    : 'Раздел счёта депо';
};

export const isDepoSectionCounterpartyRequired = (
  market: string,
  alfaMarket: string,
  contrAlfaBank: boolean
) => {
  return (
    (((market === SecurityPlaceCode.MICEX_SHR ||
      market === SecurityPlaceCode.SPBEX_FSHR) &&
      alfaMarket !== SecurityPlaceCode.EUROTRADE) ||
      market === SecurityDeponentPlaceCode.DTC) &&
    !contrAlfaBank
  );
};

export const isDepoCodeCounterpartyRequired = (
  market: string,
  alfaMarket: string,
  contrAlfaBank: boolean
) => {
  return (
    (((market === SecurityPlaceCode.MICEX_SHR ||
      market === SecurityPlaceCode.SPBEX_FSHR) &&
      alfaMarket !== SecurityPlaceCode.EUROTRADE) ||
      market === SecurityDeponentPlaceCode.DTC) &&
    !contrAlfaBank
  );
};

/** Нужен ли SWIFT код
 * @param {string} alfaMarket - рынок выбранной позиции в альфе
 * @returns {boolean}
 */
export const isSwiftRequired = (
  market: string,
  alfaMarket: string,
  contrAlfaBank: boolean
): boolean => {
  return (
    (alfaMarket === SecurityPlaceCode.EUROTRADE ||
      market === SecurityPlaceCode.EUROTRADE) &&
    !contrAlfaBank
  );
};

/** Нужен ли Гербовый сбор
 * @param {string} alfaMarket - рынок выбранной позиции в альфе
 * @returns {boolean}
 */
export const isGboxRequired = (market: string, alfaMarket: string): boolean => {
  return (
    market === SecurityPlaceCode.EUROTRADE ||
    alfaMarket === SecurityPlaceCode.EUROTRADE
  );
};

export const isGboxIsin = (isin: string): boolean => {
  const ISIN_START = ['GB', 'IE'];

  return ISIN_START.some((i) => isin.indexOf(i) === 0);
};

export const isGboxShow = (
  isin: string,
  market: string,
  alfaMarket: string
): boolean => {
  return isGboxRequired(market, alfaMarket) && isGboxIsin(isin);
};

export const isRequisitesRequired = (market: string): boolean => {
  return market === 'REESTR';
};

interface IsLegalFieldsRequiredProps {
  /** Тип контрагента ('PERSONAL' | 'LEGAL') */
  counteragentType?: COUNTERAGENT_TYPES;
  /** Данные пользователя */
  manager: SecuritiesManager | undefined;
  /** Смена прав собственности */
  ownershipChange: boolean;
}

/**
 * Определяет, нужно ли заполнять поля юр. лица
 * @param {object} param - параметры
 * @returns {boolean}
 */
export const isLegalFieldsRequired = ({
  counteragentType,
  manager,
  ownershipChange,
}: IsLegalFieldsRequiredProps): boolean => {
  if (ownershipChange) {
    return counteragentType === COUNTERAGENT_TYPES.LEGAL;
  }

  return !isPersonalAccount({ manager, counteragentType });
};

interface IsOrgNameRequiredProps {
  /** Тип счёта */
  accountType: string;
  /** Тип контрагента ('PERSONAL' | 'LEGAL') */
  counteragentType?: COUNTERAGENT_TYPES;
  /** Данные пользователя */
  manager: SecuritiesManager | undefined;
}

export const isOrgNameRequired = ({
  accountType,
  counteragentType,
  manager,
}: IsOrgNameRequiredProps): boolean => {
  return (
    accountType === 'OWNER' && !isPersonalAccount({ manager, counteragentType })
  );
};

interface IsPersonalAccountProps {
  /** Тип контрагента ('PERSONAL' | 'LEGAL') */
  counteragentType?: COUNTERAGENT_TYPES;
  /** Данные пользователя */
  manager: SecuritiesManager | undefined;
}

export const isPersonalAccount = ({
  counteragentType,
  manager,
}: IsPersonalAccountProps): boolean => {
  return (
    counteragentType === COUNTERAGENT_TYPES.PERSON ||
    (counteragentType === COUNTERAGENT_TYPES.NOT_SELECTED &&
      Boolean(manager && !manager.isFirm))
  );
};

export function buildPersonalDocumentStr(
  type: string,
  num: string,
  org: string,
  date: string
): string {
  return `${type}, ${num}, выдан ${org}, ${date}`;
}

// Позиции
export function isPositionsEuqal(
  a: SecuritiesPosition | null,
  b: SecuritiesPosition | null
) {
  return (
    a &&
    b &&
    a.accCode === b.accCode &&
    a.placeDescription === b.placeDescription
  );
}

// Контрагенты
export const conteragentKey = (agent: SecuritiesContragent) =>
  agent.srcPlaceCode + agent.srcContragent;
export const conteragentRequisitesKey = (req: SecuritiesContragentRequisites) =>
  req.srcContragentAccount + req.srcDepoAccountPart + req.srcDeponentCode;

// Документы
export const isDocumentsRequired = (ownershipChange: boolean): boolean => {
  return ownershipChange;
};

export const filtSortDocumentTypes = (
  docTypes: SecuritiesDocumentType[],
  ownershipChange: boolean,
  forReestr: boolean = false
): SecuritiesDocumentType[] => {
  return docTypes
    .filter((doc) =>
      ownershipChange ? doc.isChangeOfQwnerDoc : doc.isNoChangeOfQwnerDoc
    )
    .filter((doc) => (forReestr ? doc.forReestr : true))
    .sort((docA, docB) => docA.sortNumber - docB.sortNumber);
};

export const buildReasonDocumentStr = (doc: SecuritiesReasonDocument) => {
  return `${doc.docName} №${doc.number} от ${doc.date}`;
};

// Контрольная информация
export const isControlDatesRequired = (market: string): boolean => {
  return Boolean(market) && market !== 'REESTR';
};

// NOTE: У АИ и ГИ разные требования
export const isReferenceRequired = (
  market: string,
  marketCounterparty: string,
  ownershipChange: boolean
): boolean => {
  return (
    !ownershipChange &&
    (market === SecurityPlaceCode.SPBEX_FSHR ||
      market === SecurityPlaceCode.OTC_SPBEX_NOTRADE) &&
    marketCounterparty === SecurityPlaceCode.SPBEX_FSHR
  );
};

export const generateReference = (treaty: number) => {
  let addon = '';
  for (let i = 0; i < 7; i++) {
    addon += Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
  }

  return treaty + 'N' + addon;
};

export const getMarket = (_: string, marketCounterparty: string) => {
  // TODO: EUROTRADE - особая логика, но его пока не будет
  // if (marketSelf === 'EUROTRADE' && marketCounterparty === 'MICEX_SHR') {
  //   return 'EUROTRADE';
  // }
  return marketCounterparty;
};

/**
 * Функция подсчета кол-ва знаков после запятой
 */
export const countDecimals = (value?: number) => {
  if (value && Math.floor(value) !== value) {
    return value.toString().split('.')[1].length || 0;
  }

  return 0;
};

/** Функция возвращает имя операции по типу */
export const getTransactionName = (type: SecuritiesOperationType) => {
  switch (type) {
    case 'EXTRA_OUT':
      return 'Списание';
    case 'EXTRA_IN':
      return 'Зачисление';
    case 'INTRA':
      return 'Перевод внутри счёта';
    default:
      return '-';
  }
};

/**
 * Функция возвращает форматированное время из ISO даты
 * @param date - Дата в формате ISO
 * @returns
 */
export const getTime = (date: string) => {
  return format(parseISO(date), 'HH:mm:ss');
};
