import { TabNode } from 'flexlayout-react';
import { ITabRenderValues } from 'flexlayout-react/declarations/view/Layout';

import { Link } from '@terminal/common/components/Link/Link';
import { useStore } from '@terminal/core/store';

import { HeaderContent } from '../ui/HeaderContent';

export const renderTab = (node: TabNode, renderValues: ITabRenderValues) => {
  renderValues.content = <HeaderContent node={node} />;
  renderValues.leading = node.isVisible() ? (
    <Link node={node} linkToGroup={useStore.getState().linkToGroup} />
  ) : null;
};
