import isUndefined from 'lodash/isUndefined';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { quoteLastSelector } from '../../lib/domain/quoteSelector';
import { getPrice } from '../../lib/formulas';
import { useFullFI } from './useFullFI';

/**
 * Возвращает last для переданного idFI. Хук сам подписывается на серверные данные
 * И возвращает правильный last с учетом есть или нет торги и какой тип у фининструмента
 * Младший брат хука useWatchlist, делает то же самое, но для одного инструмента и не возвращает расширеную инфу
 * тут только цена. Если надо change или дополнительные информационные поля, используй хук useWatchlist
 */
export function useLast(
  idFI: number,
  throttleTimeout = 1000
): number | undefined {
  const { useQuotes, useFinInfoExt } = useAlfaDirectContext();

  const quotes = useQuotes(idFI, {
    throttleTimeout,
    selector: quoteLastSelector,
  });

  const extRecords = useFinInfoExt(idFI);

  const fullFI = useFullFI(idFI);

  let last = quotes[idFI]?.last;
  const finInfoExt = extRecords[idFI];

  if (finInfoExt && fullFI) {
    const { prevLastDate, prevFairPriceDate, fairPrice, prevLast } = finInfoExt;

    const isLastAvaliable =
      prevLastDate &&
      prevFairPriceDate &&
      !isUndefined(fairPrice) &&
      !isUndefined(fullFI.idObjectGroup);

    if (isLastAvaliable) {
      return getPrice(
        prevLastDate,
        prevFairPriceDate,
        fairPrice,
        last,
        prevLast,
        fullFI?.idObject,
        fullFI?.idObjectGroup
      )!;
    } else {
      return last;
    }
  } else if (last) {
    return last;
  }
}
