import { useState } from 'react';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Select, SelectProps } from '@alfalab/core-components/select';
import { OptionShape } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';
import { Textarea } from '@alfalab/core-components/textarea';

import { MINORITY } from '@terminal/core/constants/ui';

import { StaticField } from '../../../../shared/ui/StaticField';
import {
  OVER_WITHELD_TAX_REFUND_THEME_ID,
  TERMINATION_TAX_REFUND_THEME_ID,
} from '../../constants';
import { SendButton } from '../SendButton';
import { getStaticText, getTemplate, REFUND_TYPE } from './helpers';

interface FreeOrderFormTaxRefundProps {
  themeId: number;
  treaty: number;
  sending?: boolean;
  onSend: (text: string) => void;
}

const DEFAULT_YEAR = '2023';

const REFUND_TYPE_OPTIONS: OptionShape[] = [
  {
    key: REFUND_TYPE.BROKER,
    content: 'На брокерский счёт',
  },
  {
    key: REFUND_TYPE.REQUISITES,
    content: 'По реквизитам',
  },
];

const YEAR_OPTIONS: OptionShape[] = [
  {
    key: DEFAULT_YEAR,
    content: DEFAULT_YEAR,
  },
];

export const FreeOrderFormTaxRefund = ({
  themeId,
  treaty,
  sending,
  onSend,
}: FreeOrderFormTaxRefundProps) => {
  const [amount, setAmount] = useState<number | null>(null);
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [type, setType] = useState<REFUND_TYPE>(REFUND_TYPE.BROKER);
  const [requisites, setRequisites] = useState('');

  const onChangeYear: SelectProps['onChange'] = ({ selected }) => {
    setYear(selected?.key || DEFAULT_YEAR);
  };

  const onChangeType: SelectProps['onChange'] = ({ selected }) => {
    setType((selected?.key as REFUND_TYPE) || REFUND_TYPE.BROKER);
  };

  const handleSend = () => {
    if (treaty && year && amount) {
      const template = getTemplate(themeId, type);

      const text = template
        .replace('#AMOUNT#', `${(amount || 0) / MINORITY}р`)
        .replace('#YEAR#', year)
        .replace('#TREATY#', treaty.toString())
        .replace('#REQUISITES#', requisites);

      onSend(text);
    }
  };

  const isReady = Boolean(
    year &&
      amount &&
      (requisites ||
        (themeId === OVER_WITHELD_TAX_REFUND_THEME_ID &&
          type === REFUND_TYPE.BROKER))
  );

  return (
    <Space fullWidth size="l">
      <StaticField label="Поручение">
        {getStaticText(themeId, treaty)}
      </StaticField>
      <Row>
        <Col width={6}>
          <AmountInput
            value={amount}
            labelView="outer"
            label="В сумме"
            minority={MINORITY}
            bold={false}
            block
            size="s"
            onChange={(_, { value }) => setAmount(value)}
          />
        </Col>
        <Col width={6}>
          <Select
            label="За год"
            labelView="outer"
            size="s"
            block
            options={YEAR_OPTIONS}
            selected={year}
            onChange={onChangeYear}
          />
        </Col>
      </Row>
      {themeId === OVER_WITHELD_TAX_REFUND_THEME_ID && (
        <Row>
          <Col width={6}>
            <Select
              label="Куда"
              labelView="outer"
              size="s"
              block
              options={REFUND_TYPE_OPTIONS}
              selected={type}
              onChange={onChangeType}
            />
          </Col>
        </Row>
      )}

      {(type === REFUND_TYPE.REQUISITES ||
        themeId === TERMINATION_TAX_REFUND_THEME_ID) && (
        <Textarea
          label="Реквизиты"
          labelView="outer"
          size="s"
          onChange={(_, { value }) => setRequisites(value)}
          value={requisites}
          block
          placeholder="Введите реквизиты"
        />
      )}

      <Row>
        <Col width={6}>
          <SendButton
            block
            disabled={!isReady}
            loading={sending}
            onClick={handleSend}
          />
        </Col>
      </Row>
    </Space>
  );
};
