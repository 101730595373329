import React, { SVGProps, VFC } from 'react';

export type CandlesIconProps = SVGProps<SVGSVGElement> & {
  /**
   * Цвет покупки
   * */
  colorBuy?: string;
  /**
   * Цвет продажи
   * */
  colorSell?: string;
};

export const CandlesIcon: VFC<CandlesIconProps> = (props) => {
  const { colorBuy, colorSell, ...restProps } = props;

  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M6.625 0.25H5.875V1.75H5.125C4.91791 1.75 4.75 1.91791 4.75 2.125V6.25H5.875V7.75H6.625V6.25H7.75V2.125C7.75 1.91791 7.58209 1.75 7.375 1.75H6.625V0.25Z"
        fill={colorSell}
      />
      <path
        d="M2.125 0.25H1.375V3.25H0.625C0.417908 3.25 0.25 3.41791 0.25 3.625V6.25H1.375V7.75H2.125V6.25H3.25V3.625C3.25 3.41791 3.08209 3.25 2.875 3.25H2.125V0.25Z"
        fill={colorBuy}
      />
    </svg>
  );
};
