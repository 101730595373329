/**
 * @returns Начальная маржа – уровень риска портфеля, рассчитанный на основании стоимостей позиций и начальных дисконтов по инструментам.
 * Если начальная маржа превышает стоимость портфеля, не разрешается дальнейшее наращивание позиций (увеличение рыночного риска)
 * */
export function calculateImmediateRequirements(
  minimumMargin: number,
  liquedBalance: number
) {
  return minimumMargin - liquedBalance < 0 ? 0 : minimumMargin - liquedBalance;
}
