import { useEffect, useRef, useState } from 'react';
import { Textarea } from '@alfalab/core-components/textarea';
import { PaperclipMIcon } from '@alfalab/icons-glyph/PaperclipMIcon';
import { SendMIcon } from '@alfalab/icons-glyph/SendMIcon';

import styles from './InputControl.module.css';

interface InputControlProps {
  mobile: boolean;
  disabled?: boolean;
  attachFiles?: () => Promise<void>;
  sendMessage: (text: string) => Promise<void>;
}

export const InputControl = ({
  mobile,
  attachFiles,
  sendMessage,
  disabled,
}: InputControlProps) => {
  const [inputValue, setInputValue] = useState<string>('');

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSendMessage = () => {
    sendMessage(inputValue).then(() => setInputValue(''));
  };

  return (
    <div className={styles.controlRow}>
      {attachFiles && (
        <PaperclipMIcon width={18} height={18} className={styles.attach} />
      )}
      <Textarea
        maxRows={8}
        value={inputValue}
        placeholder="Введите сообщение..."
        onChange={(event) => setInputValue(event.target.value)}
        className={mobile ? styles.inputMobile : styles.input}
        ref={inputRef}
        disabled={disabled}
      />
      {inputValue.length > 0 && (
        <SendMIcon
          width={18}
          height={18}
          className={styles.send}
          onClick={handleSendMessage}
        />
      )}
    </div>
  );
};
